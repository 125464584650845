
<template>
  <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%">
    <div class="tab-component-wrapper">
      <div v-if="loading">
        <div style="position: relative; padding: 200px 0;">
          <div class="thrive-loader"></div>
        </div>
      </div>
      <div class="no-data-message" v-if="loading == false && packages.length == 0">
        <p> {{ $t('memberships.noPacakgesFoundFor', $store.state.locale) }} {{ $store.state.activeCompany.name }}.</p>
        <v-btn fab darken color="secondary" @click="addSubscription()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>

      <div v-if="!loading && packages.length > 0">
        <div class="mx-3 mt-4 pb-3">
          <v-container grid-list-md class="pa-0" fluid>
            <v-layout row wrap>
              <v-flex md6 xs12>
                <v-text-field
                  class="mx-1"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  v-model="search"
                  dense
                  hide-details
                  name="searchfield"
                  :label="$t('general.search', $store.state.locale)"
                  background-color="background"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  outlined
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex md6 xs12>
                <div class="text-right">
                  <v-btn 
                    fab 
                    dark
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary"  
                    class="mx-3"
                    style="margin-left:10px;"
                    v-if="selected.length > 0"
                    @click="deletePackage()">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-1" 
                    dark
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    @click="addSubscription()"
                    >
                    <v-icon left>mdi-plus</v-icon>{{ $t('memberships.newSubscription', $store.state.locale) }}
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-divider></v-divider>

        <div v-if="packages.length > 0">
          <v-data-table
            ref="packageTable"
            :headers="headers"
            :items="customFilter"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            no-data-text="Er zijn geen pakketten gevonden"
            :items-per-page="-1"
            :width="1"
            hide-default-footer
            v-model="selected"
            show-select
            :search="search"
            dense
            class="dense-table"
            >
            <template v-slot:item="{ item , isSelected, select }">
              <tr>
                <td>
                  <v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
                  <v-icon @click="select(false)" v-if="isSelected" >mdi-check-box-outline</v-icon>
                </td>
                <td>{{ getPackageName(item.name) }}</td>
                <td>{{ item.category.name}}</td>
                <td></td>
                <td><v-icon class="px-2" :color="activeColor(item.active)" small>mdi-checkbox-blank-circle</v-icon></td>
                <td>
                  <v-btn style="float: right;" icon class="mx-0" @click="editSubscription(item)">
                    <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <!-- / Subscription Dialog \ -->
      <membershipModal
        ref="addSubscription"
        @addMembership="getPackages"
      ></membershipModal>
    <!-- \ Subscription Dialog / -->

    <!-- / Edit subscription dialog \ -->
      <!-- <edit-subscription-dialog
        ref="editSubscription"
        @editSubscriptionDialog="getEditedSubscription"
      ></edit-subscription-dialog> -->
    <!-- \ Edit subscription dialog / -->
  </v-card>
</template>

<script>
  // import '@firebase/firestore';
  import db from "../firebase/init";
  import { bus } from "./../main";
  import membershipModal from "../components/modals/membershipModal.vue";
  import Subscription from "./Subscription.vue";
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default {

    data(){
      return{
        changes: 0,
        isSelected:false,
        addSubscriptionDialog: false,
        editSubscriptionDialog: false,
        search:'',
        filterByCategory: null,
        categoriesLoading:true,
        categories:new Array(),
        packages: new Array(),
        selected: new Array(),
        selectedCategories:new Array(),
        loading: true,
        headers:[
          { text: 'Naam', value: 'name'},
          { text: 'Categorie', value: 'category.name'},
          { text: 'Abonnees', value: 'subscribers'},
          { text: 'Status', value: 'status'},
          { text: '', value: 'edit', width: "40px;"},
        ],
        editData:null,
        originalData: null
      }
    },

    components: {
      'membershipModal': membershipModal
    },

    created(){
      this.getCategories();
      this.getPackages();
      bus.$on("newPackageAdded",done=>{
        if(done){
          this.laoding = true;
          this.packages = [];
          this.getPackages();
        }
      });
      bus.$on("itemChanged",done=>{
        if(done){
          this.loading = true;
          this.packages = new Array();
          this.getPackages();
        }
      });
    },

    methods:{
      addSubscription(){
        bus.$emit("subscriptionModal", null, 'new');
      },

      editSubscription(item){
        db.collection('vat').get()
        .then(querySnapshot=>{
          querySnapshot.forEach(doc=>{
            if(item.vat === doc.id){
              let extraVat = (doc.data().percentage * item.amount) / 100;
              item.totalPrice = Number((extraVat + item.amount).toFixed(2));
              item.vat = {
                id: doc.id,
                percentage:doc.data().percentage
              }
            }
          })
        })
        .then(()=>{
          bus.$emit("subscriptionModal", item, 'edit');
        })
        .catch(err=>{
          console.error("Error bij binnenhalen VAT-items", err);
        })
      },
    //Load categories from DB and fetch it into select element
      getCategories(){
        this.filterByCategory = new Array();
        db.collection('membershipCategories')
          .get()
          .then(querySnapshot=>{
            querySnapshot.forEach(doc=>{
              this.categories.push({
                id:doc.id,
                name: doc.data().name
              });
              this.filterByCategory.push(doc.id)
            })
          })
          .then(()=>{
            this.categoriesLoading = false;
          })
          .catch(err=>{
            console.error("Error bij binnenhalen membershipCategories", err);
          })
      },

      getPackageName(name){
        if(name[this.$store.state.nativeLanguage]){
          return name[this.$store.state.nativeLanguage]
        }
        else{
          return name.nl;
        }
      },

      getPackages(){
        this.packages = new Array();
        this.loading = true;

        db.collection('membershipPackages')
        .where("companyId","==",this.$store.state.activeCompany.id)
        // .where("deleted","==",false)
        .get()
        .then(querySnapshot=>{
          querySnapshot.forEach(doc=>{
            let packageItem = doc.data()
            packageItem.id = doc.id,
            this.packages.push(packageItem);
          })
        })
        .then(()=>{
          this.loading = false;
        })
        .catch(err=>{
          console.error("Error on loading Packages: ", err);
        })
      },

      

      deletePackage(){
        Swal.fire({
            title: 'bevestig verwijderen?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Verwijderen',
            cancelButtonText: 'Annuleren'
            })
            .then((result) => {
              if (result.value) {
                this.selected.forEach(item=>{
                  db
                  .collection('membershipPackages')
                  .doc(item.id)
                  .update({
                    deleted:true
                  })
                  .catch(err=>{
                    console.error(err)
                  })
                })
                Swal.fire(
                  'Verwijderd!',
                  '',
                  'success'
                )
                this.dataloaded=false;
                this.packages = [];
                this.getPackages();
                this.selected = [];
              } 
              else if (result.dismiss === Swal.DismissReason.cancel) {
                  Swal.fire(
                  'Geannuleerd',
                  '',
                  'error'
                )
              }
            })
      },

      getEditedSubscription(id, item){
        console.log("getEditedSubscription: ", id, item)
        this.loading = true;
        item.id = id;
        let index = _.findIndex(this.packages, ['id', id]);
        if(index >= 0){
          this.packages.splice(index, 1, item)
        }
        this.loading = false;
      },

      activeColor(active){ // Set color green for active or red for inactive
        return active ? "#81C784" : "#EF5350"; 
      },

    },

    computed: {
      packagesFiltered(){
        // package.forEach(package)
        return this.packages;
      },
      customFilter(){
        if(this.filterByCategory && this.filterByCategory.length > 0){
          let filterdItems = new Array();

          this.packages.forEach(item=>{
            this.filterByCategory.forEach(category=>{
              if(item.category.id === category){
                filterdItems.push(item);
              }
            })
          })
          return filterdItems;
        }
        else{
          return this.packages;
        }
      },
    }
  };
</script>