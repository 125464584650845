<template>
	<div v-if="!branchDataLoading">
		<v-container grid-list-md class="ma-0 pa-0" fluid>
			<v-layout row wrap class="py-0">
				<v-flex xs12 class="text-right py-0">
					<v-btn
						small
						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						:dark="changed"
						:loading="updatePOSsettingsBusy"
						:disabled="!changed"
						:depressed="!changed"
						@click="updateSettings()"
						>
						<div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right small>mdi-chevron-right</v-icon></div>
						<div v-else>{{ $t("general.saved", $store.state.locale) }}<v-icon right small>mdi-check</v-icon></div>
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>

		<v-container grid-list-md class="pa-0 pt-4 ma-0">
        	<v-layout row wrap>
          		<v-flex md6 xs12 class="pr-2">

					<!-- / Register locations \ -->
					<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
						<div class="pa-3">
							<v-avatar
								size="36"
								light
								style="float: left"
								class="ma-0 mr-2 custom-avatar-style" 
								rounded="lg"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
								:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
								>
								<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-register</v-icon>
							</v-avatar>
							<h3 class="pt-1 pb-4">{{ $t('payment.registerLocations', $store.state.locale) }}</h3>
							<v-divider></v-divider>

							</div>

							<v-data-table
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader"
								:headers="posLocationsHeaders"
								:items-per-page="-1"
								:items="filteredLocations"
								hide-default-footer
								>
								<template v-slot:item="{item}">
								<tr>
									<td v-if="item.id != 'general'">{{ item.name }}</td>
									<td v-else>{{ $t('general.general', $store.state.locale) }}</td>
									<td v-if="item.id != 'general'" class="text-center">
										<v-switch
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											v-model="item.active"
											hide-details
											dense
											inset
											v-on:change="changeLocationsStatus(item)"
											class="ma-0 pa-0 mt-1"
										></v-switch>
									</td>
									<td v-else>
										<v-switch
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											v-model="item.active"
											hide-details
											dense
											disabled
											inset
											class="ma-0 pa-0 mt-1"
										></v-switch>
									</td>
									<td><v-btn icon small @click="editLocation(item)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon></v-btn></td>
									<td v-if="item.id != 'general'"><v-btn icon small @click="deleteRegisterLocation.dialog = true, deleteRegisterLocation.id = item.id"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn></td>
									<td v-else></td>
								</tr>
								</template>
							</v-data-table>
					
							<v-divider></v-divider>

							<div class="text-center pa-4 pt-3">
								<v-btn fab small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addPosLocation()"><v-icon>mdi-plus</v-icon></v-btn>
							</div>
						
					</v-card>
					<!-- \ Register locations / -->

					<!-- / Betaalmogelijkheden bij vestiging \ -->
					<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-5" :class="$store.state.companyTheme">
						<div class="pa-3">
							<v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-0 mr-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-credit-card</v-icon>
                            </v-avatar>
							<h3 class="pt-1 pb-4">{{ $t('payment.pinDevices', $store.state.locale) }}</h3>
							<v-divider></v-divider>

							<v-select
								:label="$t('payment.pinDevicesBranch', $store.state.locale)"
								class="mt-4"
								v-model="activeBranchData.paymentOptions"
								:items="$store.state.activeCompany.paymentOptions"
								item-text="name"
								item-value="id"
								outlined
								multiple
								chips
								small-chips
								dense
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								hide-details 
							></v-select>
						</div>

						<div v-if="!activeBranchData.paymentOptions || activeBranchData.paymentOptions.length == 0" class="text-center pa-1 pt-0">
							<v-avatar 
								size="46"
								light
								class="ma-4 custom-avatar-style" 
								rounded="lg"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
								:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].secondary}" 
								>
								<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-alert</v-icon>
							</v-avatar>
							<p :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].secondary}">{{ $t('payment.pinDevicesAlert', $store.state.locale) }}</p>
						</div>
					</v-card>
					<!-- \ Betaalmogelijkheden bij vestiging / -->
				</v-flex>

              	<v-flex md6 xs12 class="pl-2">
					<!-- / Bon weergave \ -->
					<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-0" :class="$store.state.companyTheme">
						<div class="pa-3">
							<v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-0 mr-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-receipt</v-icon>
                            </v-avatar>
							<h3 class="pt-1 pb-4">{{ $t('payment.receiptDisplay', $store.state.locale) }} </h3>
							<v-divider></v-divider>
						</div>

						<v-layout row wrap class="pa-3 pb-0">
          					<v-flex xs8 class="pr-3">
								<v-select
									:label="$t('payment.receiptDisplay', $store.state.locale)"
									v-model="activeBranchData.posSettings.receiptTemplate"
									:items="receiptTemplates"
									item-text="title"
									item-value="id"
									:disabled="receiptTemplatesLoading || receiptTemplates.length == 1"
									:loading="receiptTemplatesLoading"
									outlined
									dense
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									hide-details 
								></v-select>
							</v-flex>
							<v-flex xs4>
								<v-btn class="mt-1" @click="previewReceiptTemplate(activeBranchData.posSettings.receiptTemplate)" depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark block>{{ $t('general.preview', $store.state.locale) }} <v-icon right>mdi-eye</v-icon></v-btn>
							</v-flex>
						</v-layout>

						<div class="pt-5">
							<p class="text-center ma-0 pt-4"><strong>{{ $t('payment.customReceiptDisplay', $store.state.locale) }}</strong></p>

							<div v-if="filteredReceiptTemplates && filteredReceiptTemplates.length > 0">					
								<v-data-table				
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader"
									:headers="receiptTemplatesHeaders"
									:items-per-page="-1"
									:items="filteredReceiptTemplates"
									hide-default-footer
									style="background-color: rgba(0,0,0,0.0)"
									>
									<template v-slot:item="{item}">
									<tr>
										<td>{{ item.title }}</td>
										<td><v-btn icon small @click="previewReceiptTemplate(item.id)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-eye</v-icon></v-btn></td>
										<td><v-btn icon small @click="editReceiptTemplate(item.id)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon></v-btn></td>
										<td><v-btn icon small @click="deleteSelectedTemplate.dialog = true, deleteSelectedTemplate.id = item.id"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn></td>
									</tr>
									</template>
								</v-data-table>
								<v-divider></v-divider>
							</div>

							<div v-else class="text-center pa-1 pt-0">
								<v-avatar 
									size="46"
									light
									class="ma-4 custom-avatar-style" 
									rounded="lg"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
									:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
									>
									<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-receipt</v-icon>
								</v-avatar>
								<p class="ma-0">{{ $t('payment.noCustomReceipts', $store.state.locale) }}</p>
							</div>
							
						</div>
						<div class="text-center pa-5 pt-3">
							<v-btn fab small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="newReceiptTemplate.dialog = true"><v-icon>mdi-plus</v-icon></v-btn>
						</div>
					</v-card>
					<!-- \ Bon weergave / -->

			  	</v-flex>
			</v-layout>
		</v-container>

		<!-- / Delete ReceiptTemplate Dialog \ -->
		<v-dialog v-model="deleteSelectedTemplate.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
			<v-card>
				<v-card-text class="text-center ma-0 pt-4">
				<v-avatar 
					size="56"
					light
					class="ma-4 custom-avatar-style" 
					rounded="lg"
					:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
					:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
					>
					<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
				</v-avatar>
				<!-- <h2 class="mb-4"><strong>{{ $t('rooms.removeRoom', $store.state.locale) }}</strong></h2> -->
				<p class="ma-0">{{ $t('branch.removeReceiptTemplateInfo', $store.state.locale) }}</p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-container grid-list-md fluid class="ma-0 pa-0">
					<v-layout row wrap class="ma-0 pa-0">
					<v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
						<v-btn outlined depressed :disabled="deleteSelectedTemplate.deleting" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="deleteSelectedTemplate.dialog = false">
						{{ $t('general.cancel', $store.state.locale) }}
						</v-btn>
					</v-flex>
					<v-flex xs12 md6 class="text-right">
						<v-btn dark :loading="deleteSelectedTemplate.deleting" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeReceiptTemplate(deleteSelectedTemplate.id)">
						{{ $t('branch.removeButton', $store.state.locale) }}
						</v-btn>
					</v-flex>
					</v-layout>
				</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Delete ReceiptTemplate Dialog / -->

		<!-- / Delete Register location Dialog \ -->
		<v-dialog v-model="deleteRegisterLocation.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
			<v-card>
				<v-card-text class="text-center ma-0 pt-4">
				<v-avatar 
					size="56"
					light
					class="ma-4 custom-avatar-style" 
					rounded="lg"
					:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
					:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
					>
					<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
				</v-avatar>
				<!-- <h2 class="mb-4"><strong>{{ $t('rooms.removeRoom', $store.state.locale) }}</strong></h2> -->
				<p class="ma-0">{{ $t('payment.removeRegisterLocationInfo', $store.state.locale) }}</p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-container grid-list-md fluid class="ma-0 pa-0">
					<v-layout row wrap class="ma-0 pa-0">
					<v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
						<v-btn outlined depressed :disabled="deleteRegisterLocation.deleting" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="deleteRegisterLocation.dialog = false">
						{{ $t('general.cancel', $store.state.locale) }}
						</v-btn>
					</v-flex>
					<v-flex xs12 md6 class="text-right">
						<v-btn dark :loading="deleteRegisterLocation.deleting" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeLocation()">
						{{ $t('branch.removeButton', $store.state.locale) }}
						</v-btn>
					</v-flex>
					</v-layout>
				</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Delete Register Location Dialog / -->

		<!-- / POS Location Dialog \ -->
		<v-dialog v-model="posLocation.dialog" scrollable @click:outside="closeNewPosLocation" max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`"> 
			<v-card>
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
				<h3 v-if="posLocation.type == 'new'">{{ $t('payment.newPosLocation', $store.state.locale) }}</h3>
				<h3 v-else> {{ $t('payment.editPosLocation', $store.state.locale) }}</h3>
				<v-spacer></v-spacer>
				<v-btn icon dark @click="closeNewPosLocation()">
					<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
				</v-btn>
				</v-card-title>

				<v-alert v-if="posLocation.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ posLocation.feedback }}</v-alert>

				<v-divider></v-divider>

				<v-card-text class="modal-card ma-0 pa-3">
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
						<v-flex xs12>
							<v-text-field
							:label="$t('general.name', $store.state.locale)"
							hide-details
							v-model="posLocation.name"
							outlined
							dense
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
							>
							</v-text-field>
							<v-switch
								:label="$t('general.active', $store.state.locale)"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								v-model="posLocation.active"
								hide-details
								class="ma-0 pa-0 mt-4"
							></v-switch>
						</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-container grid-list-md fluid class="ma-0 pa-0">
					<v-layout row wrap class="ma-0 pa-0">
						<v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
							<v-btn
							outlined
							depressed
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
							@click="closeNewPosLocation()"
							>
							{{ $t('general.cancel', $store.state.locale) }}
							</v-btn>
						</v-flex>
						<v-flex xs12 md8 class="text-right" v-if="posLocation.type == 'new'">
							<v-btn
								:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								:disabled="!posLocation.name"
								:dark="posLocation.name && posLocation.name.length > 0"
								@click="addNewPosLocation()"
								>
								{{ $t('general.add', $store.state.locale) }}
								<v-icon right dark>mdi-chevron-right</v-icon>
							</v-btn>
						</v-flex>
						<v-flex xs12 md8 class="text-right" v-else>
							<v-btn
							:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
							:disabled="!posLocation.name || !posLocationChanged"
							:dark="(posLocation.name.length > 0) && posLocationChanged"
							@click="updatePosLocation()"
							>
							{{ $t('general.save', $store.state.locale) }}
							<v-icon right dark>mdi-chevron-right</v-icon>
							</v-btn>
						</v-flex>
					</v-layout>
				</v-container>
				</v-card-actions>

			</v-card>
		</v-dialog>
		<!-- \ POS Location Dialog / -->     

		<!-- / Add Receipt Template Dialog \ -->
		<v-dialog v-model="newReceiptTemplate.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`" @click:outside="closeNewReceiptTemplate">
			<v-card>
				<v-card-text class="text-center ma-0 pt-4">
				<v-avatar 
					size="56"
					light
					class="ma-4 custom-avatar-style" 
					rounded="lg"
					:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
					:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
					>
					<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-receipt</v-icon>
				</v-avatar>
				<h3 class="mb-4">{{ $t('payment.newReceiptTemplate', $store.state.locale) }}</h3> 

				<v-alert v-if="newReceiptTemplate.feedback" class="ma-0 pa-2 mb-4" text type="error">{{ newReceiptTemplate.feedback }}</v-alert>

				<v-text-field
					:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
					:label="$t('payment.receiptTemplateName', $store.state.locale)"
					v-model="newReceiptTemplate.title"
					fullWidth
					dense
					outlined
					hide-details
				></v-text-field>     
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-container grid-list-md fluid class="ma-0 pa-0">
					<v-layout row wrap class="ma-0 pa-0">
					<v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
						<v-btn outlined depressed  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="newReceiptTemplate.dialog = false">
						{{ $t('general.cancel', $store.state.locale) }}
						</v-btn>
					</v-flex>
					<v-flex xs12 md6 class="text-right">
						<v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="createNewReceiptTemplate()">
						{{ $t('general.add', $store.state.locale) }} 
						</v-btn>
					</v-flex>
					</v-layout>
				</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Add Receipt Template Dialog / -->

		<!-- / Edit Receipt Template \ -->
		<receipt-editor @updated="loadReceiptTemplates"></receipt-editor>
		<!-- \ Edit Receipt Template / -->

		<!-- / Preview Receipt Template \ -->
		<receipt-preview></receipt-preview>
		<!-- \ Preview Receipt Template / -->
	</div>

	
</template>

	<script>
	import "@firebase/firestore";
	import db from "@/firebase/init";
	import { bus } from "@/main";
	import ReceiptEditor from "@/views/ReceiptEditor.vue";
	import ReceiptPreview from "@/views/ReceiptPreview.vue";
	var uniqid = require("uniqid");

	export default {
		components: {
			"receipt-editor": ReceiptEditor,
			"receipt-preview": ReceiptPreview
		},

		name: "branch-pos-settings",
		data() {
			return {
				activeBranchData: new Object(),
				activeBranchDataOriginal: new Object(),
				branchDataLoading: true,
				updatePOSsettingsBusy: false, 
				posLocationsHeaders: [
					{
						text: this.$t('general.name', this.$store.state.locale),
						align: "Left",
						value: "primary",
						width: "",
						sortable: false,
					},
					{
						text: this.$t('general.active', this.$store.state.locale),
						align: "left",
						value: "primary",
						width: "80px",
						sortable: false,
					},
					{
						text: "",
						align: "center",
						value: "primary",
						width: "40px",
						sortable: false,
					}, 
					{
						text: "",
						align: "center",
						value: "primary",
						width: "40px",
						sortable: false,
					}  
				],
				deleteSelectedTemplate: {
					dialog: false,
					id: null,
					deleting: false
				}, 
				deleteRegisterLocation: {
					dialog: false,
					id: null,
					deleting: false
				}, 
				receiptTemplatesHeaders: [
					{
						text: this.$t('general.name', this.$store.state.locale),
						align: "Left",
						value: "primary",
						width: "",
						sortable: false,
					},
					{
						text: "",
						align: "center",
						value: "primary",
						width: "40px",
						sortable: false,
					},
					{
						text: "",
						align: "center",
						value: "primary",
						width: "40px",
						sortable: false,
					},
					{
						text: "",
						align: "center",
						value: "primary",
						width: "40px",
						sortable: false,
					}    
				],
				posLocation: {
					name: "",
					dialog: false,
					feedback: null,
					active: true
				},
				newReceiptTemplate: {
					title: "",
					dialog: false,
					feedback: null,
				},
				receiptTemplates: new Array(),
				receiptTemplatesLoading: false,
			};
		},

		created(){	
			bus.$on("loadBranchData", () => {
				this.loadBranchData();
			});
			this.loadBranchData();
		},

		methods: {
			loadBranchData(){
				this.branchDataLoading = true;
				this.activeBranchData = _.cloneDeep(this.$store.state.activeBranch);
				this.activeBranchDataOriginal = _.cloneDeep(this.activeBranchData);
				this.loadReceiptTemplates();
				this.branchDataLoading = false;
			},

			async updateSettings(){
				this.updatePOSsettingsBusy = true;
				await db.collection("branches")
				.doc(this.activeBranchData.id)
				.set({
					posSettings: this.activeBranchData.posSettings,
					paymentOptions: this.activeBranchData.paymentOptions
				},{merge: true})
				this.updatePOSsettingsBusy = false;
				this.activeBranchDataOriginal = _.cloneDeep(this.activeBranchData)
			},

			async changeLocationsStatus(){
				await db.collection("branches")
				.doc(this.activeBranchData.id)
				.update({"posSettings.locations": this.activeBranchData.posSettings.locations})
				this.activeBranchDataOriginal.posSettings.locations = _.cloneDeep(this.activeBranchData.posSettings.locations);
			},

			editLocation(item){
				this.posLocation = {
					name: item.name ? item.name : "",
					nameOriginal: _.cloneDeep(item.name),
					id: item.id,
					active: item.active,
					activeOriginal: _.cloneDeep(item.active),
					type: 'edit',
					dialog: true
				}
			},

			removeLocation(){
				this.deleteRegisterLocation.deleting = true;
				this.activeBranchData.posSettings.locations = this.activeBranchData.posSettings.locations.map(location => {
					let newLocation = _.cloneDeep(location)
					if(location.id == this.deleteRegisterLocation.id ){
						newLocation.deleted = true
					}
					return newLocation

				});

				console.log("this.activeBranchData.posSettings.location: ", _.cloneDeep(this.activeBranchData.posSettings.locations))
				this.changeLocationsStatus();
				this.deleteRegisterLocation.id = null;
				this.deleteRegisterLocation.deleting = false;
				this.deleteRegisterLocation.dialog = false;
			},

			updatePosLocation(){
				let newLocation = {
					id: this.posLocation.id,
					name: this.posLocation.name,
					active: this.posLocation.active,
					deleted: false
				}
				this.activeBranchData.posSettings.locations = this.activeBranchData.posSettings.locations.map(location => location.id == newLocation.id ? location = _.cloneDeep(newLocation) :  location = location);
				this.changeLocationsStatus()
				this.posLocation.dialog = false;
			},

			async addNewPosLocation(){
				this.activeBranchData.posSettings.locations.push({
					id: uniqid(),
					name: this.posLocation.name,
					active: this.posLocation.active,
					deleted: false
				})
				await db.collection("branches")
				.doc(this.activeBranchData.id)
				.update({
					"posSettings.locations": this.activeBranchData.posSettings.locations,
				})
				this.activeBranchDataOriginal.posSettings.locations = _.cloneDeep(this.activeBranchData.posSettings.locations);
				this.closeNewPosLocation();
			},

			addPosLocation(){
				this.posLocation.dialog = true;
				this.posLocation.type = 'new';
			},

			closeNewPosLocation(){
				this.posLocation.dialog = false;
				this.posLocation.name = "";
				this.posLocation.feedback = null;
				this.posLocation.active = true;
			},

			editReceiptTemplate(id){ // Open ReceiptEditor.vue in Dialog with bus
				bus.$emit("editReceiptTemplate", id, 'user'); // Send Receipt Template Id and Type to ReceiptEditor.vue (id: Admin has global variables to use)
			},

			previewReceiptTemplate(id){ // Open ReceiptPreview.vue in Dialog with bus
				bus.$emit("previewReceiptTemplate", id); // Send Receipt Template Id to ReceiptPreview.vue
			},

			async removeReceiptTemplate(id){
				this.deleteSelectedTemplate.deleting = true;
				await db.collection("receiptTemplates").doc(id).set({
					deleted: {
						deleted: true,
						timestamp: new Date(),
						deletedBy: this.$store.state.activeUserId
					}
				})
				this.receiptTemplates = this.receiptTemplates.filter( item => {return item.id != id})

				if(this.activeBranchData.posSettings.receiptTemplate == id){
					this.activeBranchData.posSettings.receiptTemplate = null;
				}
				this.deleteSelectedTemplate.id = null;
				this.deleteSelectedTemplate.deleting = false;
				this.deleteSelectedTemplate.dialog = false;
			},

			async createNewReceiptTemplate(){
				this.newReceiptTemplate.feedback = null;
				if(this.newReceiptTemplate.title){
					await db.collection("receiptTemplates").add({
						templateType: 'custom',
						title: this.newReceiptTemplate.title,
						receiptHeader: {
							show: false,
							content: new Object()
						},
						receiptLogo: {
							show: false,
							align: 'center',
							url: null,
							width: 50
						},
						receiptFooter: {
							show: false,
							content: new Object()
						},
						barcode: {
							show: false,
						},
						deleted: false,
						companyId: this.$store.state.activeCompany.id
					})
					this.loadReceiptTemplates(); 
					this.closeNewReceiptTemplate();
				}
				else{
					this.newReceiptTemplate.feedback = this.$t('payment.newReceiptTemplateNoTitle', this.$store.state.locale);
				}
			},

			closeNewReceiptTemplate(){
				this.newReceiptTemplate.dialog = false;
				this.newReceiptTemplate.title = null;
				this.newReceiptTemplate.feedback = null;	
			},

			async loadReceiptTemplates(){ // Load all Receipt Templates from this company
				this.receiptTemplatesLoading = true;
				this.receiptTemplates = new Array();
				this.receiptTemplates.push({
					title: 'Thrive Standaard',
					id: null
				});
				await db.collection("receiptTemplates")
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.get()
				.then(snap => {
					snap.forEach(doc => {         
						let template = doc.data();
						template.id = doc.id;
						this.receiptTemplates.push(template);
					});
				})
				this.receiptTemplatesLoading = false;
			},
		},

		computed: {
			filteredReceiptTemplates(){
				return this.receiptTemplates.filter( item => {return item.id} )
			},
			changed() {
				if(_.isEqual(this.activeBranchData.posSettings, this.activeBranchDataOriginal.posSettings) == false  || _.isEqual(this.activeBranchData.paymentOptions, this.activeBranchDataOriginal.paymentOptions) == false ) {
					return true;
				}
				else {
					return false;
				}
			},

			posLocationChanged(){
				return (this.posLocation.nameOriginal != this.posLocation.name || this.posLocation.active != this.posLocation.activeOriginal) ? true: false;
			},

			filteredLocations(){
				return this.activeBranchData.posSettings.locations.filter(item => item.deleted == false)
			}
		},
	};
</script>
