<template>
  <div v-if="!branchDataLoading">
    <v-container grid-list-md class=" ma-0 pa-0" fluid>
      <v-layout row wrap class="py-0">
        <!-- <v-flex md4 xs12>
          <h2>{{ $t('branch.widgetSettings', $store.state.locale) }}</h2>
        </v-flex> -->
        <v-flex xs12 class="text-right py-0">
          <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small :dark="changed" :loading="updateWidgetSettingsBusy" :disabled="!changed" :depressed="!changed" @click="updateSettings()">
            <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale)}} <v-icon right small>mdi-chevron-right</v-icon></div>
            <div  v-else>{{ $t('general.saved', $store.state.locale)}}   <v-icon right small>mdi-check</v-icon></div>  
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container grid-list-md class="pa-0 pt-4 ma-0">
      <v-layout row wrap>
        <v-flex md6 xs12 class="pr-3">

          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
						<div class="pa-3">
							<v-avatar
                size="36"
                light
                style="float: left"
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-check-outline</v-icon>
              </v-avatar>
							<h3 class="pt-1 pb-4">{{ $t('branch.extraWidgetSettings', $store.state.locale)}} </h3>
							
              <v-divider></v-divider>

              <v-select
                :items="bookingTreatmentFilters"
                v-model="activeBranchData.widgetSettings.bookingTreatmentFilter"
                :label="$t('calenderSettings.bookingTreatmentFilters', $store.state.locale)"
                :item-text="item => $t(`${item.name}`, $store.state.locale)"
                item-value="id"
                prepend-inner-icon="mdi-sort"
                required
                dense
                hide-details
                outlined
                class="mt-4"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-select> 

              <v-switch
                class="mt-4 pa-0"
                :label="$t('branch.useCompanySettings', $store.state.locale)"
                v-model="activeBranchData.widgetSettings.useCompanySettings"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              ></v-switch>

              <v-alert v-if="generalExtraSettingsWidget.data && activeBranchData.widgetSettings.useCompanySettings" type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-2 mb-0 custom-alert">
                <h3>{{ $t('branch.generalWidgetSettings', $store.state.locale) }}</h3>
                <span>{{ $t('branch.generalWidgetSettingsTextPart1', $store.state.locale) }} <span v-if="!generalExtraSettingsWidget.allowAppointments"><strong>{{ $t('branch.generalWidgetSettingsTextNo', $store.state.locale) }}</strong></span> {{ $t('branch.generalWidgetSettingsTextPart2', $store.state.locale) }}<span v-if="generalExtraSettingsWidget.allowAppointments && generalExtraSettingsWidget.allowAppointmentsFromActive"><strong> {{ $t('branch.generalWidgetSettingsTextPart3', $store.state.locale) }} {{ generalExtraSettingsWidget.allowAppointmentsFrom | moment("D MMMM YYYY")}}</strong></span>.</span>
          
                <!-- <span :style="{color:$vuetify.theme.themes[theme].primary}"  v-html="$t('userRules.userAccountBranchHint', $store.state.locale)"></span> -->
              </v-alert>

              <v-alert v-if="generalExtraSettingsWidget.data && activeBranchData.widgetSettings.useCompanySettings" type="info" prominent :icon="false" text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-2 mb-0 custom-alert">
                <h3>{{ $t('branch.generalWidgetMessage', $store.state.locale) }}</h3>
                <span v-if="!generalExtraSettingsWidget.showNotification">{{ $t('branch.noGeneralWidgetMessage', $store.state.locale) }}</span>
                <span v-else v-html="generalExtraSettingsWidget.notification"></span>
              </v-alert>

              <v-card flat class="pa-3" v-if="!activeBranchData.widgetSettings.useCompanySettings">
                <h3>{{ $t('branch.customWidgetSettings', $store.state.locale)}} {{ activeBranchData.name }}</h3>

                <v-switch
                  class="mt-4 pa-0"
                  :label="$t('conversion.bookingsAvailable', $store.state.locale)"
                  v-model="activeBranchData.widgetSettings.active"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-switch>

                <v-switch
                  class="mt-4 pa-0"
                  :label="$t('conversion.bookingsAvailableFrom', $store.state.locale)"
                  v-model="activeBranchData.widgetSettings.startDateActive"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-switch>

                <v-menu
                  v-if="activeBranchData.widgetSettings.startDateActive"
                  :close-on-content-click="false"
                  v-model="widgetStartDialog"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  >
                  <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    style="max-width: 450px"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-on="on"
                    dense
                    class="mt-2"
                    background-color="background"
                    v-model="widgetStartDate.readable"
                    :label="$t('conversion.bookingsAvailableFromDate', $store.state.locale)"
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    readonly
                  ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="widgetStartDateRaw"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    no-title
                    @input="widgetStartDialog = false"
                  ></v-date-picker>
                </v-menu>

                <v-switch
                  class="mt-4 pa-0"
                  :label="$t('conversion.showNotification', $store.state.locale)"
                  v-model="activeBranchData.widgetSettings.notification.show"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-switch>

                <div v-if="activeBranchData.widgetSettings.notification.show">
                  <v-select
                    dense
                    class="mt-1"
                    :item-text="item => item.name[($store.state.activeUserLanguage).toLowerCase()]"
                    item-value="id"
                    :items="availableLanguages"
                    v-model="selectedLanguage"
                    :label="$t('conversion.selectLanguage', $store.state.locale)"
                    outlined
                    hide-details
                    background-color="background"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                    <template v-slot:prepend-inner>
                      <div style="margin: 2px 0 0 0">
                        <div v-if="selectedLanguage == 'en'" class="language-flag uk"></div>
                        <div v-if="selectedLanguage == 'nl'" class="language-flag nl"></div>
                      </div>
                    </template>
                  </v-select>

                  <v-textarea
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    class="mt-4"
                    rows="12"
                    background-color="background"
                    v-model="activeBranchData.widgetSettings.notification.message[(selectedLanguage).toLowerCase()]"
                    :label="$t('conversion.notification', $store.state.locale)"
                    hide-details
                  ></v-textarea>
                </div>

                <div class="color-box-wrapper clear" style="padding: 25px 0 15px 0">
                  <v-container grid-list-md class="pa-0" fluid>
                    <v-layout row wrap>
                      <v-flex xs6>
                        <div class="color-boxes-icon">
                          <v-icon>mdi-palette</v-icon>
                        </div>
                        <div class="color-boxes clear">
                          <p class="ma-0">{{ $t('conversion.selectWidgetPrimaryColor', $store.state.locale) }}</p>
                          <div class="color-box" v-bind:style="{ backgroundColor: activeBranchData.widgetSettings.primaryColor.hex}" @click="widgetColor.primary.dialog = true"></div>
                        </div>
                      </v-flex>
                      <v-flex xs6>
                        <div class="color-boxes-icon">
                          <v-icon>mdi-palette</v-icon>
                        </div>
                        <div class="color-boxes clear">
                          <p class="ma-0">{{ $t('conversion.selectWidgetSecondaryColor', $store.state.locale) }}</p>
                          <div class="color-box" v-bind:style="{ backgroundColor: activeBranchData.widgetSettings.secondaryColor.hex }" @click="widgetColor.secondary.dialog = true"></div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </div>
                
              </v-card>
            </div>
          </v-card>

          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-4" :class="$store.state.companyTheme">

            <div class="pa-3">
							<v-avatar
                size="36"
                light
                style="float: left"
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-code-tags</v-icon>
              </v-avatar>
							<h3 class="pt-1 pb-4">{{ $t('branch.trackingWidgetSettings', $store.state.locale)}} </h3>
							
              <v-divider></v-divider>

              <v-switch
                class="mt-4 mb-2 pa-0"
                :label="$t('branch.useCompanyGtmCode', $store.state.locale)"
                v-model="activeBranchData.widgetSettings.gtmTracking.useCompanyCode"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
              ></v-switch>

              <v-alert v-if="activeBranchData.widgetSettings.gtmTracking.useCompanyCode" type="info" :icon="false" text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-2 mb-0 custom-alert">
                <h4>{{ $t('branch.generalGTMCode', $store.state.locale) }}</h4>
                <span v-if="$store.state.activeCompany.conversion.widget.gtmTracking.gtmCode">{{ $t('branch.companyGeneralGTMCode', $store.state.locale) }} <strong><i>{{ $store.state.activeCompany.conversion.widget.gtmTracking.gtmCode }}</i></strong></span>
                <span v-else v-html="$t('branch.noCompanyGeneralGTMCode', $store.state.locale)"> </span>
              </v-alert>

              <v-text-field
                v-if="!activeBranchData.widgetSettings.gtmTracking.useCompanyCode"
                :label="$t('branch.gtmCode', $store.state.locale)"
                v-model="activeBranchData.widgetSettings.gtmTracking.gtmCode"
                :placeholder="`${$t('general.preview', $store.state.locale)}: GTM-TD2WRGD`"
                :persistent-placeholder="true"
                autocomplete
                outlined
                required
                dense
                style="max-width: 350px"
                class="mt-4"
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              ></v-text-field>
            </div>
          </v-card>
        </v-flex>
        <v-flex md6 xs12>
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
						<div class="pa-3">
							<v-avatar
                size="36"
                light
                style="float: left"
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-fast</v-icon>
              </v-avatar>
							<h3 class="pt-1 pb-4">{{ $t('branch.downPayments', $store.state.locale)}}</h3>
							
              <v-divider></v-divider>
     

              <v-switch
                class="mt-4 pa-0"
                :label="$t('branch.downPaymentsActive', $store.state.locale)"
                v-model="activeBranchData.widgetSettings.downPayments.active"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              ></v-switch>

              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"  class="pa-3" v-if="activeBranchData.widgetSettings.downPayments.active">
                <h3>{{ $t('branch.downPaymentSettingsFor', $store.state.locale)}} {{ activeBranchData.name }} </h3>

                <h4 class="mt-4">{{ $t('branch.downPaymentsForWho', $store.state.locale)}}</h4>

                <v-select
                  class="my-4 pa-0"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="menu"  
                  :items="clientConditions"
                  :item-text="item => item.name[($store.state.activeUserLanguage).toLowerCase()]"
                  item-value="id"
                  v-model="activeBranchData.widgetSettings.downPayments.clientsCondition"
                  :label="$t('branch.downPaymentsForWhoLabel', $store.state.locale)"
                  outlined
                  hide-details
                  dense
                ></v-select>

                <div v-if="activeBranchData.widgetSettings.downPayments.clientsCondition != 'all'">
                  <v-switch
                    class="mt-2 pa-0"
                    :label="$t('branch.downPaymentNewClients', $store.state.locale)"
                    v-model="activeBranchData.widgetSettings.downPayments.clientsConditionUseNewClients"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                  ></v-switch>

                  <v-switch
                    class="mt-4 pa-0"
                    :label="$t('branch.downPaymentNoShows', $store.state.locale)"
                    v-model="activeBranchData.widgetSettings.downPayments.clientsConditionUseNoShows"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                  ></v-switch>

                  <v-select
                    v-if="activeBranchData.widgetSettings.downPayments.clientsConditionUseNoShows"
                    class="my-4 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="menu"  
                    :items="noShowConditions"
                    :item-text="item => item.name[($store.state.activeUserLanguage).toLowerCase()]"
                    item-value="id"
                    v-model="activeBranchData.widgetSettings.downPayments.clientsConditionNoShows"
                    :label="$t('branch.downPaymentNoShowsConditions', $store.state.locale)"
                    outlined
                    hide-details
                    dense>
                  </v-select>

                  <v-switch
                    class="mt-4 pa-0"
                    :label="$t('branch.downPaymentCustomTags', $store.state.locale)"
                    v-model="activeBranchData.widgetSettings.downPayments.clientsConditionUseTags"
                    
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                  ></v-switch>

                  <v-select
                    v-if="activeBranchData.widgetSettings.downPayments.clientsConditionUseTags"
                    class="my-4 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="menu"  
                    :items="$store.state.activeCompanyTags"
                    multiple
                    chips
                    small-chips
                    :item-text="item => $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, item.name)"
                    item-value="id"
                    v-model="activeBranchData.widgetSettings.downPayments.clientsConditionTags"
                    :label="$t('general.tags', $store.state.locale)"
                    outlined
                    :hint="$store.state.activeCompanyTags.length == 0 ? $t('tags.addTagsDirections', $store.state.locale) : ''"
                    :persistent-hint="$store.state.activeCompanyTags.length == 0 ? true : false"
                    :hide-details="$store.state.activeCompanyTags.length == 0 ? false : true"

                    dense
                  >
                  <template v-slot:selection="{ item, index }">
                    <v-chip class="custom-chip-style" small label :color="$themes[$store.state.companyTheme][$store.state.themeModus][item.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][item.color]"> 
                      <span>{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, item.name) }}</span>
                    </v-chip>
                  </template>  
                </v-select>
                </div>


                

                <h4 class="mt-4">{{ $t('branch.downPaymentsWhen', $store.state.locale)}}</h4>

                <v-select
                  class="my-4 pa-0"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="menu"  
                  :items="treatmentsConditions"
                  :item-text="item => item.name[($store.state.activeUserLanguage).toLowerCase()]"
                  item-value="id"
                  v-model="activeBranchData.widgetSettings.downPayments.treatmentsCondition"
                  :label="$t('branch.downPaymentsWhenLabel', $store.state.locale)"
                  outlined
                  hide-details
                  dense
                ></v-select>

                <v-select
                  v-if="activeBranchData.widgetSettings.downPayments.treatmentsCondition == 'certainTreatmentsAmount'"
                  class="mt-2 my-4 pa-0"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="menu"  
                  :items="certainTreatmentsAmounts"
                  item-text="name"
                  item-value="id"
                  v-model="activeBranchData.widgetSettings.downPayments.certainTreatmentsFromAmountValue"
                  :label="$t('branch.downPaymentCertainTreatmentsAmount', $store.state.locale)"
                  outlined
                  hide-details
                  dense
                ></v-select>

                <v-card flat color="menu" class="pa-3">
                  <h3>{{ $t('branch.downPaymentAmount', $store.state.locale)}}</h3>
                  <v-select
                    class="mt-4 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"  
                    :items="downPaymentPercentages"
                    item-text="name"
                    item-value="id"
                    v-model="activeBranchData.widgetSettings.downPayments.percentage"
                    :label="$t('branch.downPaymentPercentage', $store.state.locale)"
                    outlined
                    hide-details
                    dense
                  ></v-select>

                  <v-select
                    class="mt-4 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"  
                    :items="downPaymentMinimums"
                    item-text="name"
                    item-value="id"
                    v-model="activeBranchData.widgetSettings.downPayments.minimum"
                    :label="$t('branch.downPaymentMinimum', $store.state.locale)"
                    outlined
                    hide-details
                    dense
                  ></v-select>

                  <v-select
                    class="mt-4 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"  
                    :items="downPaymentMaximums"
                    item-text="name"
                    item-value="id"
                    v-model="activeBranchData.widgetSettings.downPayments.maximum"
                    :label="$t('branch.downPaymentMaximum', $store.state.locale)"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                </v-card>
                <div class="my-5 mb-2">
                  <h4>{{ $t('branch.downPaymentAutoCancelTreatment', $store.state.locale)}}</h4>
                  <p>{{ $t('branch.manualBookingTimeToCancelInfo', $store.state.locale)}}</p>
                  <v-select
                    class="my-4 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :items="cancelDownPaymentOptionTimes"
                    item-text="name"
                    item-value="id"
                    v-model="activeBranchData.widgetSettings.downPayments.manualBookingTimeToCancel"
                    :label="$t('branch.manualBookingTimeToCancel', $store.state.locale)"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                </div>

                <div class="mt-5 mb-2">
                  <h4>{{ $t('branch.downPaymentMessageInWidget', $store.state.locale)}}</h4>
                  <p>{{ $t('branch.downPaymentMessageInWidgetInfo', $store.state.locale)}}</p>

                  <v-alert type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-2 mb-0">
                    <span :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }" v-html="parse($t('branch.downPaymentMessageInWidgetDefault', $store.state.locale))"></span>
                  </v-alert>

                  <v-switch
                    class="mt-4 pa-0"
                    :label="$t('branch.downPaymentMessageInWidgetUseExtraMessage', $store.state.locale)"
                    v-model="activeBranchData.widgetSettings.downPayments.useExtraInformation"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                  ></v-switch>

                  <v-textarea
                    v-if="activeBranchData.widgetSettings.downPayments.useExtraInformation"
                    :label="$t('branch.downPaymentMessageInWidgetExtraMessage', $store.state.locale)"
                    v-model="activeBranchData.widgetSettings.downPayments.extraInformation"
                    autocomplete
                    dense
                    rows="3"
                    required
                    outlined
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"            
                    class="mt-4"
                  ></v-textarea>
                </div>
              </v-card>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog width="225px" v-model="widgetColor.primary.dialog" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <chrome-picker v-model="activeBranchData.widgetSettings.primaryColor"></chrome-picker>
    </v-dialog>
    <v-dialog width="225px" v-model="widgetColor.secondary.dialog" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <chrome-picker v-model="activeBranchData.widgetSettings.secondaryColor"></chrome-picker>
    </v-dialog>

  </div>
</template>


<script>
  import '@firebase/firestore';
  import db from "../firebase/init";
  import { bus } from "../main";
  import moment from "moment";
  import { Chrome } from "vue-color";

  export default {
    name: "branch-widget-settings",
    components: {
      "chrome-picker": Chrome,
    },

    data: () => ({
      activeBranchData: new Object(),
      activeBranchDataOriginal: new Object(),
      branchDataLoading: true,
      selectedLanguage: "nl",
      widgetColor: {
        primary: {
          dialog: false,
        },
        secondary: {
          dialog: false,
        }
      },

      bookingTreatmentFilters: [
        {
          id: 'gender',
          name: 'general.gender'
        },
        {
          id: 'treatmentTypes',
          name: 'admin.treatmentTypes'
        },
        {
          id: 'customTreatmentTypes',
          name: 'treatments.headers.customTreatmentTypes'
        },
        {
          id: 'none',
          name: 'general.none'
        }
      ],

      clientConditions: [
        {
          id: 'all',
          name: {
            nl: 'Alle cliënten',
            en: 'All cliënts'
          }
        },
        {
          id: 'specific',
          name: {
            nl: 'Specifiek',
            en: 'Specific'
          }
        }
      ],

      noShowConditions: [
        {
          id: 1,
          name: {
            nl: 'Cliënt is de laatste keer niet gekomen',
            en: "Client didn't show last time",
            de: "Der Kunde kam das letzte Mal nicht"
          }
        },
        {
          id: 2,
          name: {
            nl: 'Cliënt is van de laatste 2 afspraken 1x niet gekomen',
            en: 'Client has not attended 1 of the last 2 appointments',
            de: "Der Kunde ist zu einem der letzten 2 Termine nicht erschienen"
          }
        },
        {
          id: 3,
          name: {
            nl: 'Cliënt is van de laatste 3 afspraken 1x niet gekomen',
            en: 'Client has not attended 1 of the last 3 appointments',
            de: "Der Kunde ist zu einem der letzten 3 Termine nicht erschienen"
          }
        }
      ],

      treatmentsConditions: [
        {
          id: 'all',
          name: {
            nl: 'Alle behandelingen',
            en: 'All treatments'
          }
        },
        {
          id: 'certainTreatmentsAmount',
          name: {
            nl: 'Vanaf een bepaalde prijs',
            en: 'From a certain price'
          }
        }
      ],
      availableLanguages: [
        {
          id: 'nl',
          name: {
            nl: 'Nederlands',
            en: 'Dutch'
          }
        },
        {
          id: 'en',
          name: {
            nl: 'Engels',
            en: 'English'
          }
        }
      ],
      cancelDownPaymentOptionTimes: [
        {
          id: 3,
          name: "3 uur"
        }, 
        {
          id: 6,
          name: "6 uur"
        },  
        {
          id: 12,
          name: "12 uur"
        },  
        {
          id: 24,
          name: "24 uur"
        },
        {
          id: 48,
          name: "48 uur"
        },
        
      ],
      certainTreatmentsAmounts: [
        {
          id: 50,
          name: "€50"
        },
        {
          id: 60,
          name: "€60"
        },
        {
          id: 70,
          name: "€70"
        },
        {
          id: 80,
          name: "€80"
        },
        {
          id: 90,
          name: "€90"
        },
        {
          id: 100,
          name: "€100"
        },
        {
          id: 150,
          name: "€150"
        },
        {
          id: 250,
          name: "€250"
        },
        {
          id: 500,
          name: "€500"
        }
      ],
      downPaymentPercentages: [
        {
          id: 5,
          name: "5%"
        },
        {
          id: 10,
          name: "10%"
        },
        {
          id: 15,
          name: "15%"
        },
        {
          id: 20,
          name: "20%"
        },
        {
          id: 30,
          name: "30%"
        },
        {
          id: 40,
          name: "40%"
        },
        {
          id: 50,
          name: "50%"
        }
      ],
      downPaymentMinimums: [
        {
          id: 5,
          name: "€5"
        },
        {
          id: 10,
          name: "€10"
        },
        {
          id: 15,
          name: "€15"
        },
        {
          id: 20,
          name: "€20"
        }
      ],
      downPaymentMaximums: [
        {
          id: 50,
          name: "€50"
        },
        {
          id: 100,
          name: "€100"
        },
        {
          id: 150,
          name: "€150"
        },
        {
          id: 250,
          name: "€250"
        },
        {
          id: 500,
          name: "€500"
        }
      ],
      widgetStartDialog: false,
      widgetStartDateRaw: null,
      updateWidgetSettingsBusy: false,
    }),

    created() {
      bus.$on("loadBranchData", () => {
        this.loadBranchData();
      });
      this.loadBranchData();
    },

    methods: {

      parse(string) {
        return string.replace(/{{.*?}}/g, match => {
            var expression = match.slice(2, -2);
            return this.evalInContext(expression);
        });
      },
      evalInContext(string) {
        try {
          let data = eval("this." + string);
          return data == undefined ? '<strong>variable not found</strong>' : data;
        }
        catch (error) {
          return '<strong>variable not found</strong>';
        }
      },

      loadBranchData(){
        this.branchDataLoading = true;
        this.activeBranchData = _.cloneDeep(this.$store.state.activeBranch);
        this.activeBranchDataOriginal = _.cloneDeep(this.activeBranchData);
        this.branchDataLoading = false;
      },

      async updateSettings() {
        this.updateWidgetSettingsBusy = true;

        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "widgetSettingUpdated",
          actionItem: this.activeBranchData.name,
          extra:[
            {
              name: "originalData",
              value: ""
            },
            {
              name: "widgetSettings",
              value: JSON.stringify(this.activeBranchDataOriginal.widgetSettings)
            },
            {
              name: "changedData",
              value: ""
            },
            {
              name: "widgetSettings",
              value: JSON.stringify(this.activeBranchData.widgetSettings)
            },
          ]
        };

        try {
          await db.collection("branches")
          .doc(this.activeBranchData.id)
          .set({
            widgetSettings: {
              useCompanySettings: this.activeBranchData.widgetSettings.useCompanySettings,
              notification: this.activeBranchData.widgetSettings.notification,
              startDateActive: this.activeBranchData.widgetSettings.startDateActive,
              startDate: this.widgetStartDate.firebase,
              active: this.activeBranchData.widgetSettings.active,
              primaryColor: this.activeBranchData.widgetSettings.primaryColor,
              secondaryColor: this.activeBranchData.widgetSettings.secondaryColor,
              downPayments: this.activeBranchData.widgetSettings.downPayments,
              gtmTracking:{
                useCompanyCode: this.activeBranchData.widgetSettings.gtmTracking.useCompanyCode, 
                gtmCode: this.activeBranchData.widgetSettings.gtmTracking.gtmCode ? this.activeBranchData.widgetSettings.gtmTracking.gtmCode.replace(/\s/g, '') : null,
              },
              bookingTreatmentFilter: this.activeBranchData.widgetSettings.bookingTreatmentFilter,
            }
          }, { merge: true })

          this.activeBranchDataOriginal = _.cloneDeep(this.activeBranchData);
        }
        catch (error) {
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }

        this.updateWidgetSettingsBusy = false;
      },
  
    },

    computed: {
      generalExtraSettingsWidget(){
        let settings = {
          data: false,
          allowAppointments: null,
          allowAppointmentsFromActive: null,
          allowAppointmentsFrom: null,
          showNotification: null,
          notification: null
        }

        if(this.$store.state.activeCompany.conversion.widget && this.$store.state.activeCompany.conversion.widget ){
          settings.data = true;
          settings.allowAppointments = this.$store.state.activeCompany.conversion.widget.active ? this.$store.state.activeCompany.conversion.widget.active : false;
          settings.allowAppointmentsFromActive = this.$store.state.activeCompany.conversion.widget.startDateActive ? this.$store.state.activeCompany.conversion.widget.startDateActive : true;
          settings.allowAppointmentsFrom = this.$store.state.activeCompany.conversion.widget.startDate ? this.$store.state.activeCompany.conversion.widget.startDate.seconds : moment().format('YYYY-MM-DD');
          settings.showNotification = this.$store.state.activeCompany.conversion.widget.notification.show ? this.$store.state.activeCompany.conversion.widget.notification.show  : false;
          settings.notification = this.$store.state.activeCompany.conversion.widget.notification.message[(this.$store.state.activeUserLanguage).toLowerCase()] ? this.$store.state.activeCompany.conversion.widget.notification.message[(this.$store.state.activeUserLanguage).toLowerCase()] : null;
        }
        return settings;
      },

      widgetStartDate(){
        let date = {
          readable: "",
          formatted: "",
          firebase: null
        }
        if(this.widgetStartDateRaw){
          date.readable = moment(this.widgetStartDateRaw).locale('nl').format("D MMMM YYYY");
          date.formatted = moment(this.widgetStartDateRaw).format("YYYY/MM/DD");
          date.firebase = new Date(moment(this.widgetStartDateRaw).format("YYYY/MM/DD"));
          return date;
        }
        else if(this.activeBranchData.widgetSettings.startDate){
          date.readable = moment.unix(this.activeBranchData.widgetSettings.startDate.seconds).locale('nl').format("D MMMM YYYY");
          date.formatted = moment.unix(this.activeBranchData.widgetSettings.startDate.seconds).format("YYYY/MM/DD");
          date.firebase = new Date(moment(date.formatted).format("YYYY/MM/DD"));
          return date;
        }
        else {
          return date;
        }
      },
      changed(){
        if( _.isEqual(this.activeBranchData.widgetSettings, this.activeBranchDataOriginal.widgetSettings) == false){
          return true
        } else{
          return false
        }
      },
    }
  };
</script>
<style>
.custom-alert h1,
.custom-alert h2,
.custom-alert h3,
.custom-alert h4,
.custom-alert p,
.custom-alert span{
  color: var(--primary-color)!important;
}
</style>

