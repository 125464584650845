<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h2>{{ $t('leadCampaign.editLeadCampaign', $store.state.locale)}}</h2>
        <v-spacer></v-spacer>
        <v-btn v-if="!savingCampaign" icon dark @click="closeEditCampaignDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" v-html="feedback" style="border-radius: 0" type="warning"></v-alert>

      <v-tabs :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="tab">
        <v-tab>{{ $t('leadCampaign.general', userLanguage)}}</v-tab>
        <v-tab>{{ $t('leadCampaign.followUp', userLanguage)}}</v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-card-text class="ma-0 pa-0">
        <v-tabs-items v-model="tab">
          <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition" class="pa-4">
            <v-text-field
              class="mt-4"
              :label="$t('general.name', $store.state.locale)"
              v-model="selectedCampaign.name"
              autocomplete
              required
              dense
              background-color="background"
              outlined
              hide-details
            ></v-text-field>

            <v-select
              :items="campaignTypes"
              v-model="selectedCampaign.type"
              :label="$t('leadCampaign.goal', $store.state.locale)"
              :item-text="item => item.name[($store.state.locale).toLowerCase()]"
              item-value="id"
              required
              dense
              outlined
              class="mt-4"
              hide-details
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              background-color="background"
            ></v-select>

            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-4 pb-4 px-4 pt-1">
              <v-switch 
                class="ma-0 mt-4 pa-0 pb-1"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :label="$t('leadCampaign.selectTreatmantAsGoal', $store.state.locale)" 
                v-model="useTreatmentGoal"
                hide-details
                v-on:change="selectedCampaign.treatment = null"
              ></v-switch>

              <v-select
                v-if="useTreatmentGoal"
                class="mt-4"
                :items="filteredTreatments"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"     
                item-text="treatmentTitle"
                item-value="id"
                hide-details
                dense
                clearable
                v-model="selectedCampaign.treatment"
                :no-data-text="$t('leadCampaign.noTreatmentFound', $store.state.locale)" 
                :label="$t('leadCampaign.selectTreatement', $store.state.locale)" 
                outlined>
                <template v-slot:prepend-item>
                  <v-list-item class="pa-0 ma-0" inactive>
                    <v-list-item-content  class="pa-0 ma-0 px-2 pb-2" >
                      <v-text-field
                        outlined
                        background-color="background"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :placeholder="$t('leadCampaign.search', $store.state.locale)" 
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        v-model="searchTreatments"
                        hide-details
                        dense
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <div v-if="searchTreatments && searchTreatments.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredTreatments.length }} {{ $t('leadCampaign.of', $store.state.locale) }} {{ treatments.length }} {{ $t('leadCampaign.treatments', $store.state.locale) }}</div>
                  <v-divider v-if="searchTreatments && searchTreatments.length > 0"></v-divider>
                </template>
              </v-select>
            </v-card>

            <v-container grid-list-md fluid class="ma-0 mt-4 pa-0">
              <h4 class="pt-2"><strong>{{ $t('leadCampaign.formIntro', $store.state.locale)}}</strong></h4>
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="pl-0 ml-0">
                  <v-textarea
                    v-model="selectedCampaign.formIntro.nl"
                    background-color="background"
                    outlined
                    rows="5"
                    hide-details
                    >
                    <template v-slot:prepend-inner>
                      <div style="margin: 2px 0 0 0">
                        <div class="language-flag nl"></div>
                      </div>
                    </template>
                  </v-textarea>
                </v-flex>
                <v-flex xs12 md6  class="pr-0 mr-0">
                  <v-textarea
                    v-model="selectedCampaign.formIntro.en"
                    background-color="background"
                    outlined
                    rows="5"
                    hide-details
                    >
                    <template v-slot:prepend-inner>
                      <div style="margin: 2px 0 0 0">
                        <div class="language-flag uk"></div>
                      </div>
                    </template>
                  </v-textarea>
                </v-flex>
              </v-layout>
            </v-container>

            <v-card flat color="background" class="mt-4 pb-4">
              <div class="text-center pa-4">
                <h3 class="pt-2"><strong>{{ $t('leadCampaign.loadQuestions', $store.state.locale)}}</strong></h3>
                <p class="ma-0 pa-0 pb-2"><i>{{ $t('leadCampaign.loadQuestionsHint', $store.state.locale)}}</i></p>
              </div> 

              <div v-if="selectedCampaign.questions.length > 0">
                <v-list flat dense class="ma-0 pa-0">
                <draggable v-model="selectedCampaign.questions" v-bind="{group:'rules'}" style="min-height: 10px">     
                  <v-list-item-group  v-for="(question, index) in selectedCampaign.questions" :key="index" class="ma-0 pa-0">
                    <v-divider></v-divider>
                    <v-list-item :style="{backgroundColor: $vuetify.theme.themes[theme].background}" class="ma-0">
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-arrow-all</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ question[($store.state.locale).toLowerCase()]}}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ questionType(question.type) }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="editQuestionDialog(question, index)">
                          <v-icon small color="text">mdi-pencil</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn icon @click="removeQuestion(index)">
                          <v-icon small color="text">mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                  <v-divider></v-divider>
                </draggable>
              </v-list>
              </div>

              <div class="text-center mt-4">
                <v-btn fab dark small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="openNewQuestionDialog()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-card>

            <v-switch 
              class="ma-0 mt-4 pa-0"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :label="$t('general.active', $store.state.locale)"
              v-model="selectedCampaign.active"
              hide-details
            ></v-switch>
          </v-tab-item>

          <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition" class="pa-4">
            <v-select
              :items="campaignSpeedItems"
              v-model="selectedCampaign.actionSpeed"
              :label="$t('leadCampaign.actionSpeed', $store.state.locale)"
              :item-text="item => item.name[($store.state.locale).toLowerCase()]"
              item-value="id"
              required
              outlined
              dense
              :hint="$t('leadCampaign.actionSpeedHint', $store.state.locale)"
              persistent-hint
              class="mt-4"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              background-color="background"
            ></v-select>

            <div class="mt-4">
              <h4 class="pt-2"><strong>{{ $t('leadCampaign.callScript', $store.state.locale)}}</strong></h4>
              <p class="ma-0 pa-0 pb-2">{{ $t('leadCampaign.callScriptHint', $store.state.locale)}}</p> 
              <div v-if="editorMode == 'wysiwyg'" class="custom-tiptap-wrapper" :class="theme">
                <tiptap-vuetify
                  v-model="selectedCampaign.customCallScripts.start"
                  :toolbar-attributes="{ color: 'menu' }"
                  :card-props="{ flat: true, color: 'background' }"
                  :extensions="extensions"
                />
              </div>
              <div v-else>
                <v-textarea
                  v-model="selectedCampaign.customCallScripts.start"
                  background-color="background"
                  outlined
                  rows="6"
                  hide-details
                ></v-textarea>
              </div>
              <!-- <div class="text-center mt-2">
                <a @click="editorMode == 'wysiwyg' ? editorMode = 'html' : editorMode = 'wysiwyg'">{{ editorMode == "wysiwyg" ? "Bekijk source" : "Bekijk wysiwyg" }}</a>
              </div> -->
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                :disabled="savingCampaign"
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeEditCampaignDialog()"
                >
                {{ $t('general.cancel', $store.state.locale)}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn
                :dark="!campaignChanged"  
                :depressed="campaignChanged"  
                :disabled="campaignChanged"  
                :loading="savingCampaign" 
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateCampaign()"
                >
                {{ $t('leadCampaign.editQuestionButton', $store.state.locale)}}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>

    <!-- / New Question Dialog \ -->
    <v-dialog v-model="newQuestion.dialog" persistent scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t('leadCampaign.addNewQuestion', userLanguage) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeNewQuestionDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-alert v-if="newQuestion.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ newQuestion.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0">
          <v-form ref="newSubtreatmentForm">
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md12>
                  <v-text-field 
                    v-model="newQuestion.question.nl" 
                    required
                    counter="50"
                    outlined
                    dense
                    class="mt-4 mb-2"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                    <template v-slot:prepend-inner>
                      <div style="margin: 2px 0 0 0">
                        <div class="language-flag nl"></div>
                      </div>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex md12>
                  <v-text-field 
                    v-model="newQuestion.question.en" 
                    required
                    counter="50"
                    outlined
                    dense
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                    <template v-slot:prepend-inner>
                      <div style="margin: 2px 0 0 0">
                        <div class="language-flag uk"></div>
                      </div>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex md12>
                  <v-select
                    :items="questionTypes"
                    v-model="newQuestion.question.type"
                    :label="$t('leadCampaign.questionType', $store.state.locale)"
                    :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                    item-value="id"
                    required
                    dense
                    outlined
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                  ></v-select>
                 </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                  @click="closeNewQuestionDialog()"
                  >
                  {{ $t('general.cancel', userLanguage) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  dark  
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  @click="addNewQuestion()"
                  >
                  {{ $t('leadCampaign.addNewQuestionButton', userLanguage) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ New Question Dialog / -->

    <!-- / Edit Question Dialog \ -->
    <v-dialog v-model="editQuestion.dialog" persistent scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t('leadCampaign.editQuestion', userLanguage) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeEditQuestionDialog()">
            <v-icon dark color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-alert v-if="newQuestion.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ editQuestion.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0">
          <v-form ref="newSubtreatmentForm">
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md12>
                  <v-text-field 
                    v-model="editQuestion.question.nl" 
                    required
                    counter="50"
                    outlined
                    dense
                    class="mt-4 mb-2"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                    <template v-slot:prepend-inner>
                      <div style="margin: 2px 0 0 0">
                        <div class="language-flag nl"></div>
                      </div>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex md12>
                  <v-text-field 
                    v-model="editQuestion.question.en" 
                    required
                    counter="50"
                    outlined
                    dense
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                    <template v-slot:prepend-inner>
                      <div style="margin: 2px 0 0 0">
                        <div class="language-flag uk"></div>
                      </div>
                    </template>
                  </v-text-field>
                </v-flex>
                 <v-flex md12>
                  <v-select
                    :items="questionTypes"
                    v-model="editQuestion.question.type"
                    :label="$t('leadCampaign.questionType', $store.state.locale)"
                    :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                    item-value="id"
                    required
                    dense
                    outlined
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                  ></v-select>
                 </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                  @click="closeEditQuestionDialog()"
                  >
                  {{ $t('general.cancel', userLanguage) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  :dark="!editQuestionChanged"  
                  :depressed="editQuestionChanged"  
                  :disabled="editQuestionChanged"  
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  @click="saveEditQuestion()"
                  >
                  {{ $t('leadCampaign.editQuestionButton', userLanguage) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ Edit Question Dialog / -->

  </v-dialog>
</template>


<script>
import db from "../../firebase/init";
import { bus } from "../../main";
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import draggable from "vuedraggable";

export default {
  name: "edit-campaign-form",
  components: { 
    TiptapVuetify,
    draggable
  },
  props: {
    campaignTypes: Array,
    campaignSpeedItems: Array,
    treatments: Array,
  },

  data() {
    return {
      showDialog: false,
      tab: 0,
      tabTransition: false,
      showDialog: false,
      searchTreatments: "",
      selectedCampaign: {
        questions: [],
        customCallScripts: {},
        formIntro: {}
      },
      selectedCampaignOriginal: new Object(),
      editorMode: "html",
      feedback: null,
       newQuestion: {
        feedback: "",
        dialog: false,
        type: "yesNo",
        question: {
          nl: "",
          en: ""
        }
      },
      useTreatmentGoal: false,
      questionTypes:[
        {
          name: {
            en: "Yes/no",
            nl: "Ja/Nee"
          },
          id: "yesNo"
        },
        {
          name: {
            en: "Open question",
            nl: "Open vraag"
          },
          id: "open"
        }
      ],
      editQuestion: {
        feedback: "",
        dialog: false,
        question: {
          nl: "",
          en: "",
          type: "yesNo",
        }
      },
      editQuestionOriginal: {
        question: {
          nl: "",
          en: ""
        }
      },
      savingCampaign: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3, 4]
          }
        }],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  created(){
    bus.$on('editLeadCampaign',(campaign)=>{
      this.selectedCampaign = campaign;
      this.selectedCampaignOriginal = _.cloneDeep(campaign)
      this.showDialog = true;
      this.savingCampaign = false;
      if(this.selectedCampaign.treatment){
        this.useTreatmentGoal = true;
      }
    });
  },

  methods: {
    closeEditCampaignDialog() {
      this.showDialog = false;
    },

    openNewQuestionDialog(){
      this.newQuestion.question.en = "";
      this.newQuestion.question.nl = "";
      this.newQuestion.question.type = "yesNo";
      this.newQuestion.dialog = true;
      this.newQuestion.feedback = "";
    },

    addNewQuestion(){
      if(this.newQuestion.question.en && this.newQuestion.question.nl){
        let question = {
          en: this.newQuestion.question.en,
          nl: this.newQuestion.question.nl,
          type: this.newQuestion.type
        }
        this.selectedCampaign.questions.push(question);
        this.newQuestion.dialog = false;
        this.newQuestion.feedback = "";
      }
      else{
        this.newQuestion.feedback  = this.$t('leadCampaign.addQuestionFeedback', this.userLanguage);
      }
    },

    closeNewQuestionDialog(){
      this.newQuestion.dialog = false;
      this.newQuestion.feedback = "";
    },

    closeEditQuestionDialog(){
      this.editQuestion.dialog = false;
      this.editQuestion.question = {};
      this.editQuestion.index = null;
      this.editQuestion.feedback = "";
    },

    editQuestionDialog(item, index){
      this.editQuestion.feedback = "";
      this.editQuestion.question = item;
      this.editQuestion.index = index;
      this.editQuestion.dialog = true;
      this.editQuestionOriginal = _.cloneDeep(this.editQuestion);
    },

    saveEditQuestion(){
      if(this.editQuestion.question.en && this.editQuestion.question.nl){
        let question = {
          en: this.editQuestion.question.en,
          nl: this.editQuestion.question.nl,
          type: this.editQuestion.question.type
        };
        this.selectedCampaign.questions[this.editQuestion.index] = question;
        this.editQuestion.dialog = false;
        this.editQuestion.question = {};
        this.editQuestion.index = null;
      }
      else{
        this.editQuestion.feedback = this.$t('leadCampaign.addQuestionFeedback', this.userLanguage);
      }  
    },

    removeQuestion(index){
      this.selectedCampaign.questions.splice(index, 1);
    },

    questionType(id){
      let type = this.questionTypes.find(element => element.id == id); 
      return type ? type.name[this.userLanguage.toLowerCase()] : null;
    },

    async updateCampaign(){
      if (this.selectedCampaign.name && this.selectedCampaign.type) {
        this.savingCampaign = true;

        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "leadCampaignEditted",
          actionItem: this.selectedCampaignOriginal.name,
          extra:[
            {
              name: "originalData",
              value: ""
            },
            {
              name: "name",
              value: this.selectedCampaignOriginal.name
            },
            {
              name: "type",
              value: this.selectedCampaignOriginal.type 
            },
            {
              name: "changedData",
              value: ""
            },
            {
              name: "name",
              value: this.selectedCampaign.name
            },
            {
              name: "type",
              value: this.selectedCampaign.type 
            }
          ]
        };

        try{
          await db.collection("leadCampaigns")
          .doc(this.selectedCampaign.id)
          .set({
            companyId: this.$store.state.activeCompany.id,
            deleted: {
              deleted: false
            },
            name: this.selectedCampaign.name,
            formIntro: this.selectedCampaign.formIntro,
            type: this.selectedCampaign.type,
            treatment: this.selectedCampaign.treatment,
            actionSpeed: this.selectedCampaign.actionSpeed,
            active: this.selectedCampaign.active,
            questions: this.selectedCampaign.questions,
            customCallScripts: this.selectedCampaign.customCallScripts
          }, {merge: true})

          await this.$createNewLog("info", logData);
          this.$emit("leadCampaignEdited", this.selectedCampaign);
          this.closeEditCampaignDialog();
          this.savingCampaign = false;

        }
        catch(error){
          console.error("Error updating lead campaign: ", error);
          this.feedback = this.$t("general.addError", this.$store.state.locale) ;
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
          this.savingCampaign = false;
        }
        
      }
      else{
        this.feedback = this.$t('leadCampaign.editCampaignFeedback', this.userLanguage);
      }
    }
  },
  computed: {
    filteredTreatments(){
      if (this.searchTreatments && this.searchTreatments.length > 0){
        return this.treatments.filter(treatment => {
          return treatment.treatmentTitle.toLowerCase().includes(this.searchTreatments.toLowerCase());
        });
      } else {
        return this.treatments;
      }
    },
    campaignChanged(){
      return _.isEqual(this.selectedCampaign, this.selectedCampaignOriginal) ? true : false;
    },
    editQuestionChanged(){ 
      return _.isEqual(this.editQuestion.question, this.editQuestionOriginal.question) &&  _.isEqual(this.editQuestion.type, this.editQuestionOriginal.type) ? true : false;
    },
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    changed() {
      if (
        _.isEqual(this.selectedAbility, this.selectedAbilityOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      if(this.$store.state.companyTheme){
        return this.$store.state.companyTheme;
      }
      else{
        return "thrive"
      }
    }
  }
};
</script>