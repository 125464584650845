<template>
  <div class="tab-component-wrapper">
    <div v-if="!loading">
      <div class="no-data-message" v-if="vatItems.length == 0">
        <p>{{ $t('admin.noVatRates', $store.state.locale) }}</p>
        <v-btn fab darken :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addVat()">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </div>

      <v-card v-else flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
        <v-container grid-list-md class=" ma-0 pa-3" fluid>
          <v-layout row wrap>
            <v-flex md4 xs12>
              <v-text-field
                class="mx-1"
                dense
                v-model="search"
                name="searchfield"
                :label="$t('general.search', $store.state.locale)"
                prepend-inner-icon="mdi-magnify"
                single-line
                hide-details
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex md8 xs12 class="text-right">
              <v-btn class="px-2" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addVat()">
                <v-icon left>mdi-plus</v-icon> {{ $t('admin.newVatRate', $store.state.locale) }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="vatItems"
          :search="search"
          item-key="name"
          dense
          :itemsPerPage.sync="pages"
          hide-default-footer
          :no-results-text="`${$t('treatments.noResultsFoundFor', $store.state.locale)} ${search}`"
          class="dense-table"
          >
          <template v-slot:item="{item }">
            <tr>
              <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name)  }}</td>
              <td>{{ item.percentage }}%</td>
              <td>{{ getCounty(item.country).countryName[$store.state.locale.toLowerCase()]}}</td>
              <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.description)  }}</td>
              <td>
                <v-btn icon @click="editVat(item)">
                  <v-icon small color="text">mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>

    <!-- / Vat Dialog \ -->
      <vat-form @updateData="loadVatItems()"></vat-form>
    <!-- \ Vat Dialog / -->
  </div>
</template>

<script>
  import { bus } from "../main";
  import db from "../firebase/init";
  import VatForm from "@/components/modals/VatForm.vue";

  export default {
    components: {
      "vat-form": VatForm
    },
    name: "vat-rates",
    data() {
      return {
        search: "",
        pages: -1,
        loading: true,
        vatItems: new Array(),
        headers: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "name"
          },
          {
            text: this.$t('admin.percentage', this.$store.state.locale),
            align: "left",
            value: "percentage"
          },
          {
            text: this.$t('general.country', this.$store.state.locale),
            align: "left",
            value: "country.name"
          },
          {
            text: this.$t('admin.vatDescription', this.$store.state.locale),
            align: "left",
            value: "description"
          },
          {
            text: "",
            align: "right",
            value: "edit",
            width: "60px"
          }
        ],
      }
    },

    created() {
      this.loadVatItems();
    },

    methods: {
      addVat() {
        bus.$emit("vatModal", null, "new");
      },

      editVat(item) {
        bus.$emit("vatModal", item, "edit");
      },

      getCounty(country){
        return this.$countries.find(item => item.countryCode == country)
      },

      loadVatItems() {
        this.loading = true;
        db.collection("vat")
          .get()
          .then(snap => {
            this.vatItems = new Array();
            snap.forEach(doc => {
              let vat = doc.data();
              vat.id = doc.id;
              this.vatItems.push(vat);
            });
          })
          .then(()=>{
            this.loading = false;
          })
          .catch(error=>{
            console.error("Fout bij binnenladen van vat: ", error);
            this.loading = false;
          });
      }
    }
  };
</script>
