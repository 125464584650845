<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>{{ $t("user.editUser", $store.state.locale) }}</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeEditUserDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text>
      <v-form ref="EditUserForm" v-model="valid" lazy-validation>
        <v-text-field
          :label="$t('general.email', $store.state.locale)"
          v-model.trim="userFormInfo.email"
          autocomplete
          prepend-inner-icon="mdi-email"
          disabled
          outlined
          dense
          class="mt-2"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>

        <v-text-field
          :label="$t('general.name', $store.state.locale)"
          v-model.trim="userFormInfo.firstName"
          autocomplete
          prepend-inner-icon="mdi-account"
          :rules="[rules.required]"
          required
          dense
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>

        <v-text-field
          :label="$t('general.lastName', $store.state.locale)"
          v-model.trim="userFormInfo.lastName"
          autocomplete
          prepend-inner-icon="mdi-account"
          :rules="[rules.required]"
          required
          dense
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>

        <v-select
          :label="$t('general.language', $store.state.locale)"
          :hint="$t('user.languageHint', $store.state.locale)"
          outlined
          dense
          class="mt-4"
          content-class="pa-0 ma-0 mr-4"
          :items="availableUserlanguages"
          v-model="userFormInfo.language"
          item-value="languageCode"
          item-text="code"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          hide-details
          >
          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
            <country-flag :country="data.item.flagCode" size="small"/>
            <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
          </template>
          <template slot="item" slot-scope="data">
            <country-flag :country="data.item.flagCode" size="small"/>
            <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
          </template>
        </v-select>

        <v-select
          :items="userRoles"
          v-model.trim="userFormInfo.userRole"
          :label="$t('general.role', $store.state.locale)"
          item-text="name"
          item-value="id"
          prepend-inner-icon="mdi-shield-check"
          :rules="[rules.required]"
          required
          dense
          outlined
          class="mt-4"
          hide-details
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-select>

        <v-autocomplete
          clearable
          class="mt-4"
          v-model="userFormInfo.companyId"
          :items="companies"
          item-text="name"
          item-value="id"
          :label="$t('general.company', $store.state.locale)"
          outlined
          dense
          :hint="$t('user.companyHint', $store.state.locale)"
          persistent-hint
          prepend-inner-icon="mdi-domain"
          :rules="[rules.required]"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
          no-data-text="Geen bedrijven gevonden."
        ></v-autocomplete>

        <v-autocomplete
          clearable
          class="mt-4"
          v-model="userFormInfo.accessCompanies"
          :items="companies"
          item-text="name"
          item-value="id"
          chips
          dense
          small-chips
          :label="$t('user.access', $store.state.locale)"
          outlined
          :hint="$t('user.accessHint', $store.state.locale)"
          no-data-text="Geen bedrijven gevonden."
          multiple
          deletable-chips
          persistent-hint
          prepend-inner-icon="mdi-check"
          :rules="[rules.required]"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-autocomplete>

        <v-switch class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('general.active', $store.state.locale)" v-model="userFormInfo.active" hide-details></v-switch>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  @click="closeEditUserDialog()">
              {{ $t("general.cancel", $store.state.locale) }}
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              :disabled="!changed"
              :depressed="!changed"
              :dark="changed"
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              @click="updateUser()"
            >
              <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }} <v-icon right>mdi-chevron-right</v-icon></div>
              <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
  import slugify from "slugify";
  import axios from "axios";
  import db from "../../firebase/init";
  import "@firebase/firestore";
  import firebase from "firebase/app";
  import "firebase/auth";

  export default {
    name: "edit-user",
    props: {
      userFormInfoOriginal: Object,
      userFormInfo: Object,
      companies: Array,
    },
    data() {
      return {
        searchCompanies: "",
        searchCompaniesAccess: "",
        valid: false,
        feedback: null,
        userRoles: [
          {
            name: "Developer",
            id: "developer",
            disabled: this.$store.state.userRoleNumber > 0
          },
          {
            name: "Superadmin",
            id: "superadmin",
          },
          {
            name: "Admin",
            id: "admin",
          },
        ],
        feedback: null,
        errors: [],
        rules: {
          required: (value) => !!value || this.$t("general.required", this.$store.state.locale),
          min: (v) => v.length >= 6 || this.$t("user.sixCharactersPassword", this.$store.state.locale),
        },
      };
    },

    methods: {
      searchResult(name) {
        if (name.toLowerCase().includes(this.searchCompaniesAccess.toLowerCase())) {
          console.log("true");
          return true;
        } else {
          console.log("false");
          return false;
        }
      },

      resetForm() {
        this.$refs.EditUserForm.reset();
      },

      closeEditUserDialog() {
        this.showEditDialog = false;
        this.resetForm();
        this.$emit("userEditDialogChange", this.showEditDialog);
      },

      async updateUser() {
        if (this.$refs.EditUserForm.validate()) {
          await db.collection("users")
          .doc(this.userFormInfo.id)
          .update({
            firstName: this.userFormInfo.firstName,
            lastName: this.userFormInfo.lastName,
            userRole: this.userFormInfo.userRole,
            companyId: this.userFormInfo.companyId,
            language: this.userFormInfo.language,
            accessCompanies: this.userFormInfo.accessCompanies,
            active: this.userFormInfo.active,
          })
    
          if (this.userFormInfo.active != this.userFormInfoOriginal.active) {
            // User status changed
            let userFormInfo = this.userFormInfo.active;
            let idToken = await firebase.auth().currentUser.getIdToken(true);
            if (userFormInfo) {            
              await axios.post(`${this.$store.state.restApi.url}/auth`, {
                token: idToken,
                type: "enableUser",
                uid: this.userFormInfo.user_id,
                employeeId: this.userFormInfo.employeeId,
              })
              .catch((error)=>{
                console.error("Error enabling User Account", error);
              })
            }
            else{
              await axios.post(`${this.$store.state.restApi.url}/auth`, {
                token: idToken,
                type: "disableUser",
                uid: this.userFormInfo.user_id,
                employeeId: this.userFormInfo.employeeId,
              })
              .catch((error)=>{
                console.error("Error disabling User Account", error);
              })
            }
          }
    
          this.$emit("updateData");
          this.closeEditUserDialog();
          this.$refs.EditUserForm.reset();
          this.feedback = null; 
        }
        else {
          this.feedback = this.$t("general.requiredField", this.$store.state.locale);
        }
      },
    },
    computed: {
      availableUserlanguages(){
        return this.$countries.filter(language => { // Filter rich languages data from languages
          if(language.userLanguage){ return language }
        });
      },
      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
      changed() {
        if (_.isEqual(this.userFormInfo, this.userFormInfoOriginal) == false) {
          return true;
        } else {
          return false;
        }
      },
      filteredCompaniesAccess() {
        if (this.searchCompaniesAccess && this.searchCompaniesAccess.length > 0) {
          return this.companies.filter((company) => {
            return company.name.toLowerCase().includes(this.searchCompaniesAccess.toLowerCase());
          });
        } else {
          return this.companies;
        }
      },
      filteredCompanies() {
        if (this.searchCompanies && this.searchCompanies.length > 0) {
          return this.companies.filter((company) => {
            return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
          });
        } else {
          return this.companies;
        }
      },
    },
  };
</script>
