<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type == 'new'">{{ $t('admin.editComponent', $store.state.locale)}}</h3>
        <h3 v-if="type == 'add'">{{ $t('admin.addNewComponent', $store.state.locale)}}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeSubtreatmentTypeDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>
        <v-form lazy-validation>
          <v-text-field
            class="mt-2"
            :label="$t('general.name', $store.state.locale) + ` (${getLanguageName('nl').languageName[$store.state.locale.toLowerCase()]})`"
            v-model="subtreatmentType.name.nl"
            outlined
            required
            dense
            hide-details
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            >
            <template v-slot:prepend-inner >
              <country-flag style="margin: -9px -5px 0 -10px" country="nl" size="small"/>
            </template>
          </v-text-field>

          <v-text-field
            class="mt-4"
            :label="$t('general.name', $store.state.locale) + ` (${getLanguageName('en').languageName[$store.state.locale.toLowerCase()]})`"
            v-model="subtreatmentType.name.en"
            outlined
            required
            dense
            hide-details
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            >
            <template v-slot:prepend-inner >
              <country-flag style="margin: -9px -5px 0 -10px" country="gb" size="small"/>
            </template>
          </v-text-field>
  
          <v-select
            class="mt-4"
            :items="subtreatmentTypeCategories"
            item-text="name"
            item-value="id"
            v-model="subtreatmentType.category"
            outlined
            dense
            hide-details
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :label="$t('admin.selectMainComponent', $store.state.locale)"
            >
            <template slot="selection" slot-scope="data">
              {{ data.item.name[($store.state.locale).toLowerCase()] }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.name[($store.state.locale).toLowerCase()] }}
            </template>
          </v-select>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeSubtreatmentTypeDialog()"
                >
                {{ $t('general.cancel', $store.state.locale)}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type == 'new'"
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addSubtreatmentType()"
                >
                {{ $t('general.add', $store.state.locale)}}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn
                v-if="type == 'edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateSubtreatmentType()"
                >
                <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right>mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
  import { bus } from "../../main";
  import db from "../../firebase/init";
  import '@firebase/firestore';

  export default {
    name: "subtreatmenttype-form",
    props: {
      subtreatmentTypeCategories: Array
    },

    data() {
      return {
        type: 'new',
        showDialog: false,
        subtreatmentType: { name: new Object()},
        subtreatmentTypeOriginal: new Object(),
        feedback: null
      };
    },

    created() {
      bus.$on("SubtreatmentType", (item, category, type) => {
        this.type = type;
        if(type == "edit"){
          this.subtreatmentType = _.cloneDeep(item); 
          this.subtreatmentTypeOriginal = _.cloneDeep(this.subtreatmentType);
        }
        else{
          this.type = 'new'
          this.resetSubtreatmentType();
          this.subtreatmentType.category = category;
        }
        this.showDialog = true;
      });
    },

    methods: {
      getLanguageName(item){
        return this.$countries.find(language => { return language.id == item });
      },

      closeSubtreatmentTypeDialog() {
        this.showDialog = false;
        this.resetSubtreatmentType();
      },

      resetSubtreatmentType(){
        this.feedback = null;
        this.subtreatmentType = {
          name: new Object(),
          category: null,
        };
      },

      updateSubtreatmentType() {
        db.collection("subtreatmentTypes")
          .doc(this.subtreatmentType.id)
          .update({
            name: this.subtreatmentType.name,
            category: this.subtreatmentType.category
          })
          .then(() => {
            this.$emit("updateData");
            this.closeSubtreatmentTypeDialog();
          })
          .catch(error => {
            console.error("Error updating Subreatmenttype: ", error);
          });
      },

      addSubtreatmentType() {
        if (this.subtreatmentType.name && this.subtreatmentType.category) {
          db.collection("subtreatmentTypes")
            .add({
              name: this.subtreatmentType.name,
              category: this.subtreatmentType.category,
            })
            .then(() => {
              this.$emit("updateData");
              this.closeSubtreatmentTypeDialog();
            })
            .catch(error => {
              console.error("Error adding SubtreatmentType: ", error);
            });
        }
        else {
          this.feedback = "Vul alle velden in om een nieuw onderdeel toe te voegen.";
        }
      }
    },
    computed: {
      changed() {
        return _.isEqual(this.subtreatmentType, this.subtreatmentTypeOriginal) == false ? true : false;
      }
    }
  };
</script>