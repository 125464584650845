export function accountancyLedgers() {
    

   const accountancyObj = {
        

        accountancyNumbersExpense: [
          {id: "other", ledgerNumber: 0},
          {id:"canteenExpense", ledgerNumber: 0},
          {id: "cleaningSupplies", ledgerNumber: 0},
          {id: "lecture", ledgerNumber: 0},
          {id: "officeExpense", ledgerNumber: 0},
          {id: "stamps", ledgerNumber: 0},
          {id: "interiorCost", ledgerNumber: 0},
          {id: "advertisementCost", ledgerNumber: 0},
          {id: "mealAllowance", ledgerNumber: 0},
          {id: "otherPersonnelExpense", ledgerNumber: 0},
          {id: "privateWithdrawel", ledgerNumber: 0},
          {id: "depositToBank", ledgerNumber: 0},
          {id: "cashDifference", ledgerNumber: 0}
        ],
        accountancyNumbersSales: [
          {id: "treatmentsHighRate", ledgerNumber: 0},
          {id: "treatmentsLowRate", ledgerNumber: 0},
          {id: "treatmentsZeroRate", ledgerNumber: 0},
          {id: "productsHighRate", ledgerNumber: 0},
          {id: "productsLowRate", ledgerNumber: 0},
          {id: "productsZeroRate", ledgerNumber: 0},
          {id: "withoutCounterpartLowRate", ledgerNumber: 0},
          {id: "withoutZeroRate", ledgerNumber: 0},
          {id: "directDebit", ledgerNumber: 0},
          {id: "sellPrepaidCredit", ledgerNumber: 0},
          {id: "usePrepaidCredit", ledgerNumber: 0},
          {id: "expiredPrepaidCredit", ledgerNumber: 0}
        ],
        accountancyNumbersCash: [
          {id: "accountancyNumbersExpenseCash", ledgerNumber: 0},
          {id: "cashShortfall", ledgerNumber: 0},
          {id: "cashSurplus", ledgerNumber: 0},
          {id: "depositToCash", ledgerNumber: 0},
          {id: "depositToBank", ledgerNumber: 0}
        ],

        accountancyNumbersPayments: [
          {id: "sellPrepaid", ledgerNumber: 0},
          {id: "creditcard", ledgerNumber: 0},
          {id: "transferToBank", ledgerNumber: 0},
          {id: "perDirectDebit", ledgerNumber: 0},
          {id: "other", ledgerNumber: 0}
        ],

        accountancyNumbersCrossReferences: [
          {id: "sellPrepaidCreditDelayed", ledgerNumber: 0},
          {id: "usePrepaidCreditDelayed", ledgerNumber: 0},
          {id: "expiredPrepaidCreditDelayed", ledgerNumber: 0},
          {id: "sellGiftCards", ledgerNumber: 0},
          {id: "redeemGiftCards", ledgerNumber: 0},
          {id: "notPayed", ledgerNumber: 0},
          {id: "paidOutstandingBills", ledgerNumber: 0}
        ]


      }
  
    return accountancyObj;
  }
  