<template>
  <div>
    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" v-if="!loading && devices.length > 0">
      <v-container grid-list-md class=" ma-0 pa-3" fluid>
        <v-layout row wrap>
          <v-flex md4 xs12>
            <v-text-field
              class="px-2"
              v-model="search"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              name="searchfield"
              :placeholder="$t('general.search', $store.state.locale)"
              outlined
              dense
              hide-details                                            
              prepend-inner-icon="mdi-magnify"
              autocomplete="disabled"
              clearable
              single-line>
            </v-text-field>
          </v-flex>

          <v-flex md8 xs12 class="text-right">
            <v-btn class="px-2" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addDeviceType()">
              <v-icon left>mdi-plus</v-icon> {{ $t('admin.newType', $store.state.locale) }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>

      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="devices"
        :search="search"
        :itemsPerPage.sync="pages"
        hide-default-footer
        item-key="name"
        dense
        :no-results-text="`${$t('treatments.noResultsFoundFor', $store.state.locale) } ${search}`" 
        class="dense-table"
        >
        <template v-slot:item="{item }">
          <tr>
            <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name)  }}</td>
            <td>
              <v-btn icon class="mx-0" @click="editDeviceType(item)">
                <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" >mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <div v-else>
      <div class="no-data-message" v-if="!loading && devices.length == 0">
        <p>Er zijn nog geen apparaat-types toegevoegd.</p>
        <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addDeviceType()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>

    <!-- / DeviceType Dialog \ -->
    <device-form
      @editDeviceTypeDialogChange="editDeviceTypeDialog = $event"
      @updateData="loadDevices()"
    ></device-form>
    <!-- \ DeviceType Dialog / -->
  </div>
</template>
     
<script>
  import { bus } from "../main";
  import '@firebase/firestore';
  import db from "../firebase/init";
  import DeviceForm from "../components/modals/DeviceTypeForm.vue";

  export default {
    components: {
      "device-form": DeviceForm
    },
    name: "device-types",
    data() {
      return {
        search: "",
        loading: true,
        devices: new Array(),
        pages: -1,
        pagination: {
          sortBy: "deviceName",
          rowsPerPage: 20,
          descending: false
        },
        headers: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "deviceName"
          },
          {
            text: "",
            align: "right",
            value: "edit",
            width: "60px"
          }
        ]
      }
    },

    created() {
      this.loadDevices();
    },

    methods: {

      addDeviceType(){
        bus.$emit("DeviceType", null,  'new');
      },

      editDeviceType(item) {
        bus.$emit("DeviceType", item,  'edit');
      },

      async loadDevices() {
        this.loading = true;
        await db.collection("deviceTypes")
        .get()
        .then(snap => {
          this.devices = [];
          snap.forEach(doc => {
            let deviceCollection = doc.data();
            deviceCollection.id = doc.id;
            this.devices.push(deviceCollection);
          });
          this.devices.sort((a, b) => a.name[(this.$store.state.locale).toLowerCase()].localeCompare(b.name[(this.$store.state.locale).toLowerCase()]));
        })
        .catch(error =>{
          console.error("Error bij binnenhalen devices: ", error);
          this.loading = false;
        });
        this.loading = false;
      }
    },
  };
</script>
