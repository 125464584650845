import moment from 'moment';
import axios from 'axios';
import store from "../store";
import { autoLanguageSelector } from './autoLanguageSelector';
import db from "../firebase/init";

// GENERAL FUNCTION FOR CHECKING IF DOWNPAYMENT IS MANDATORY

export function downPaymentInformation(chosenStartTime, clientData, treatmentPrice) {

  let downPaymentMandatory = true;
  let downPaymentRequired = false;
  let downPaymentReasons =  new Array();
  let downPaymentAmount = 0;

  async function checkDownPaymentSettings(chosenStartTime, clientData, treatmentPrice){ // Check if downpayment is requiered for this client (based on branch settings)
    console.log("checkDownPaymentSettings")
    
    let bookingScheduledInThePast = moment().add(15, 'minutes').format("YYYY-MM-DDTHH:mm") > chosenStartTime ? true : false; // No downpayment needed if booking in scheduled in the past

    if(store.state.activeBranch.widgetSettings && store.state.activeBranch.widgetSettings.downPayments && store.state.activeBranch.widgetSettings.downPayments.active && !bookingScheduledInThePast){
      if(store.state.activeBranch.widgetSettings.downPayments.clientsCondition != 'all'){ // Check if downpayment has specific client conditions
        let conditionMatch = false;
        let lastPayment = false;
        let newClient = true; // Someone who has not paid for anything yet is considered to be a new client

        if(clientData.id){
          const config = {
            headers: { "Content-Type": "application/json" },
          };

          let clientDataCheck = {
            companyId: store.state.activeCompany.id,
            clientId: clientData.id,
          };

          await axios.post(`${store.state.restApi.url}/postClientExist`, clientDataCheck, config)
          .then((response) => {
            newClient = response.data.lastPayment ? false : true;
            lastPayment = response.data.lastPayment ? response.data.lastPayment : false;
            console.log("response: postClientExist", response.data)
          })
          .catch(error=>{
            console.error("postClientExist error", error)    
          })
        }

        if(store.state.activeBranch.widgetSettings.downPayments.clientsConditionUseNewClients){ // Check if downpayment conditions is for new clients
          let explaination = {
            conditionType: 'client',
            type: 'newClient',
          };
          downPaymentReasons.push(explaination);
          conditionMatch = newClient ? true : conditionMatch;
        }

        if(store.state.activeBranch.widgetSettings.downPayments.clientsConditionUseNoShows && clientData.id){ // Check if downpayment conditions is for now show clients
          let previousTreatments = new Array();
          await db.collection("bookingsummary")
          .where("clientId","==",clientData.id)
          .where("companyId","==",store.state.activeCompany.id)
          .where("deleted","==",false)
          .where("start","<",moment().format("YYYY-MM-DDTHH:mm"))
          .orderBy("start", "desc")
          .limit(store.state.activeBranch.widgetSettings.downPayments.clientsConditionNoShows)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let treatment = doc.data();
              treatment.id = doc.id;
              if((doc.data().downPayment.active && !doc.data().downPayment.paid) || doc.data().status == 'noShow'){
                let explaination = {
                  conditionType: 'client',
                  type: 'noShow',
                  branchId: doc.data().branchId,
                  start: doc.data().start
                };
                downPaymentReasons.push(explaination);
                conditionMatch = true;
              }
              previousTreatments.push(treatment);
            });
          })

          console.log("previousTreatments: ", previousTreatments)
        } 

        if(store.state.activeBranch.widgetSettings.downPayments.clientsConditionUseTags){ // Check if downpayment conditions is for specific tagged clients
          if(store.state.activeBranch.widgetSettings.downPayments.clientsConditionTags && store.state.activeBranch.widgetSettings.downPayments.clientsConditionTags.length > 0){
            if(clientData.tags && clientData.tags.length > 0){
              store.state.activeBranch.widgetSettings.downPayments.clientsConditionTags.forEach(tag=>{
                clientData.tags.forEach(clientTag=>{

                  if(clientTag.id == tag){

                    let tagData = store.state.activeCompanyTags.find((companyTag) => { return companyTag.id == tag});

                    console.log("tagData: ", tagData)
                    let explaination = {
                      conditionType: 'client',
                      type: 'tag',
                      tagType: tagData.type,
                      id: tagData.id,
                      name: autoLanguageSelector(store.state.locale, store.state.companyLanguages, tagData.name)
                    };
                    downPaymentReasons.push(explaination);
                    conditionMatch = true
                  }
                })
              })
            }
          } 
        } 

        downPaymentMandatory = conditionMatch ? true : false;
      }
      else{
        let explaination = {
          conditionType: 'client',
          type: 'allClients',
        };
        downPaymentReasons.push(explaination);
      }
    }
    else{
      downPaymentMandatory = false;
    }
    if(downPaymentMandatory){
      // Check if Mollie settings are there
      let settingsSource = store.state.activeBranch.connections && store.state.activeBranch.connections.mollie && store.state.activeBranch.connections.mollie.useBranchConnection ? 'activeBranch': 'activeCompany';

      if(store.state[settingsSource].connections && store.state[settingsSource].connections.mollie && store.state[settingsSource].connections.mollie.refreshToken && store.state[settingsSource].connections.mollie.profileId){ // Controleren of Mollie actief is
        await axios.post(`${store.state.connectionsApi.url}/mollie/checkup`, {refreshToken:store.state[settingsSource].connections.mollie.refreshToken})
        .then((response) => {
          console.log("Mollie checkup Response: ", response)
          downPaymentRequired = true;
        })
        .catch(error=> {  
          console.error("Error checkup Mollie", error)
          downPaymentRequired = false;
        })
      }
      else{ // Mollie is niet actief
        console.log("Mollie is niet actief of niet goed ingesteld")
        downPaymentRequired = false;
      }
    }
    else{ // Client already known
      downPaymentRequired = false;
    }
    
    await calculateDownPaymentAmount(treatmentPrice);
    return {
      downPaymentMandatory: downPaymentMandatory,
      downPaymentRequired: downPaymentRequired,
      downPaymentReasons: downPaymentReasons,
      downPaymentAmount: downPaymentAmount,
    }
  }

  async function calculateDownPaymentAmount(treatmentPrice){
    downPaymentAmount = 0;
    if(downPaymentRequired){
      let price = treatmentPrice;
      let treatmentDownPayment = true
      if(store.state.activeBranch.widgetSettings.downPayments.treatmentsCondition != 'all'){
        if(store.state.activeBranch.widgetSettings.downPayments.treatmentsCondition == 'certainTreatmentsAmount'){
          if(price.toFixed(2) < store.state.activeBranch.widgetSettings.downPayments.certainTreatmentsFromAmountValue){
            treatmentDownPayment = false
          }
          else {
            let explaination = {
              conditionType: 'treatment',
              type: 'certainTreatmentAmount',
            };
            downPaymentReasons.push(explaination);
          } 
        }
      }
      else{
        let explaination = {
          conditionType: 'treatment',
          type: 'allTreatments',
        };
        this.downPaymentReasons.push(explaination);
      }
      if(treatmentDownPayment){
        downPaymentAmount = (price / 100) * store.state.activeBranch.widgetSettings.downPayments.percentage;
        downPaymentAmount = downPaymentAmount > store.state.activeBranch.widgetSettings.downPayments.maximum ? store.state.activeBranch.widgetSettings.downPayments.maximum : downPaymentAmount;
        downPaymentAmount = downPaymentAmount < store.state.activeBranch.widgetSettings.downPayments.minimum ? store.state.activeBranch.widgetSettings.downPayments.minimum : downPaymentAmount;
      }
      else{
        downPaymentAmount = 0;
        downPaymentRequired = false;
      }
      return
    }   
  }

  return checkDownPaymentSettings(chosenStartTime, clientData, treatmentPrice)

}