<template>
  <v-dialog v-model="dialogPause" persistent scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="!note">{{ $t('pause.bookTime', $store.state.locale) }}</h3>
        <div v-if="note">
          <h3 v-if="this.selectedType.id == 'task'">{{ $t('pause.task', $store.state.locale) }}</h3>
          <h3 v-if="this.selectedType.id == 'note'">{{ $t('pause.note', $store.state.locale) }}</h3>
        </div>
       
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closedialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>
        <v-container grid-list-md class="ma-0 pa-0">
          <v-layout row wrap class="pt-3">
            <v-flex xs6>
              <v-select
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                item-text="name"
                item-value="id"
                hide-details
                dense
                :items="timeOptions"
                v-model="selectedType"
                :label="$t('general.type', $store.state.locale)"
                return-object
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-menu v-model="chosenDateDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="chosenDateReadable"
                    :label="$t('general.date', $store.state.locale)"
                    prepend-inner-icon="mdi-calendar-today"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  no-title scrollable :first-day-of-week="1" :min="min" :max="max" v-model="chosenDate" @input="chosenDateDialog = false"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                prepend-inner-icon="mdi-clock-outline"
                :label="$t('pause.startTime', $store.state.locale)"
                :value="start"
                dense
                type="time"
                suffix="uur"
                hide-details
                class="mt-4"
                v-model="start"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                prepend-inner-icon="mdi-clock-outline"
                :label="$t('pause.endTime', $store.state.locale)"
                :value="end"
                dense
                type="time"
                suffix="uur"
                class="mt-4"
                hide-details
                v-model="end"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea outlined hide-details class="mt-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  background-color="background" name="input-5-1" :label="$t('pause.description', $store.state.locale)" value rows="3" v-model="notes"></v-textarea>
            </v-flex>
          </v-layout>
          <v-flex xs12>
            <v-select
              v-if="!note"
              outlined
              class="mt-4"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :items="employees"
              item-text="title"
              item-value="docId"
              dense
              v-model="selectedEmployees"
              :menu-props="{maxHeight: '300'}"
              :label="$t('general.employees', $store.state.locale)"
              multiple
              hide-details
            ></v-select>
          </v-flex>
          <v-flex xs12>
            <v-switch v-if="!note" class="ma-0 mt-4 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  :label="$t('pause.overwritable', $store.state.locale)" v-model="bookingOverride"> </v-switch>
          </v-flex>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left">
              <div class="text-left">
                <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closedialog()"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
              </div>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                :dark="checked"
                :depressed="!checked"
                :disabled="!checked"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                @click="bookStaffbreak()"
                >
                {{ $t('general.save', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style></style>

<script>
  import moment from "moment";
  import db from "../../firebase/init";
  import { bus } from "../../main";
  import _ from "lodash";

  export default {
    name: "addPause",
    props: ["activeBranch", "activeCompData", "startTime", "endTime", "dialogPause"],
    data() {
      return {
        chosenDateDialog: false,
        feedback: null,
        fromCal: false,
        min: moment().format("YYYY-MM-DD"), //Vanaf vandaag
        max: moment().add(9, "w").format("YYYY-MM-DD"), //Maximaal vooruitplannen.
        chosenDate: null, //Gekozen door calander
        value: "12:30:00",
        start: null,
        end: null,
        employees: [],
        selectedEmployees: new Array(),
        preSelectedClientId: null,
        lead: null,
        speedSelect: {
          active: false,
          id: null
        },
        note: false,
        timeOptions: [
          { name: this.$t('pause.pause', this.$store.state.locale), id: "pause", type: "pause" },
          { name: this.$t('pause.meeting', this.$store.state.locale),  id: "meeting", type: "occupied" },
          { name: this.$t('pause.occupied', this.$store.state.locale),  id: "occupied", type: "occupied" },
          { name: this.$t('pause.task', this.$store.state.locale),  id: "task", type: "occupied" },
        ],
        selectedType: "pause",
        notes: "",
        bookingOverride: false,
      };
    },

    mounted() {
      bus.$on("chosenDate", (date, selectedResource, type, lead, treatment, clientId, employees) => {
        this.note = type && (type =='task' || type =='note') ? true : false;

        console.log("chosenDate type: ", type)
        this.reset();
        this.chosenDate = date;
        this.start = moment(this.startTime).format("HH:mm");
        this.end = moment(this.endTime).format("HH:mm");
        this.employees = employees;
        this.selectedEmployees.push(selectedResource._resource.id);
        this.preSelectedClientId = clientId ? clientId : null;
        if(type =='trial'){
          if(lead){
            this.lead = lead;
            this.timeOptions.push({ name: this.$t('pause.followUp', this.$store.state.locale),  id: "lead", type: "occupied" });
            this.selectedType = this.timeOptions[this.timeOptions.length - 1];
          }          
        }
        else if(type =='task'){
          this.timeOptions = [{ name: this.$t('pause.task', this.$store.state.locale),  id: "task", type: "occupied"}, { name: this.$t('pause.note', this.$store.state.locale),  id: "note", type: "occupied"}];
          this.selectedType = this.timeOptions[0];       
        }
        else if(type =='note'){
          this.timeOptions = [{ name: this.$t('pause.note', this.$store.state.locale),  id: "note", type: "occupied"},{ name: this.$t('pause.task', this.$store.state.locale),  id: "task", type: "occupied"}];
          this.selectedType = this.timeOptions[0];       
        }
        else{
          this.timeOptions = [
            { name: this.$t('pause.pause', this.$store.state.locale), id: "pause", type: "pause" },
            { name: this.$t('pause.meeting', this.$store.state.locale),  id: "meeting", type: "occupied" },
            { name: this.$t('pause.occupied', this.$store.state.locale),  id: "occupied", type: "occupied" },
            { name: this.$t('pause.task', this.$store.state.locale),  id: "task", type: "occupied" },
          ];
          this.lead = null;
          this.speedSelect.active = false;
        }
      });
    },

    methods: {
      reset() {
        this.min = moment().format("YYYY-MM-DD"); //Vanaf vandaag
        this.max = moment().add(9, "w").format("YYYY-MM-DD"); //Maximaal vooruitplannen.
        this.chosenDate = null; //Gekozen door calander
        this.value = "12:30:00";
        this.start = moment().format("HH:mm");
        this.end = moment().add(1, "hours").format("HH:mm");
        this.selectedEmployees = [];
        this.selectedType = this.timeOptions[0];
        this.notes = "";
        this.feedback = null;
        this.bookingOverride = false;
      },

      closedialog() {
        this.$emit("DialogPauseChange", false);
        this.reset();
      },

      async bookStaffbreak() {
        let uniqid = require("uniqid");
        let uniqidBooking = uniqid();

        if (this.selectedType.id != "task" || (this.selectedType.id == "task" && this.notes)) {
          this.feedback = null;
          let bookingcolor = "rgb(204, 204, 204)";
          let extra = { type: this.selectedType.id };
          if (this.selectedType.id == "task") {
            extra.completed = false;
            extra.completedBy = null;
            extra.completedOn = null;
          }

          if (this.bookingOverride) {
            bookingcolor = "rgba(204, 204, 204, 0.50)";
          }

          let cacheObject = {
            uniqIdStamp: uniqidBooking,
            found: 0,
            bookings: new Array(),
            resources: new Array()
          };
   

          let counter = 0;
          this.selectedEmployees.forEach(employee => {
            let employeeInfo = this.employees.find((emp) => emp.docId === employee);

            console.log("employeeInfo: ", employeeInfo)

            let logData = {
              superAdminAction: false, // Is action only accessible by superadmin?
              actionType: "userAction",
              action: "pauseAdded",
              actionItem: employeeInfo ? employeeInfo.name : "Notities",
              extra:[
                {
                  name: "name",
                  value: employeeInfo ? employeeInfo.name : "Notities"
                },
                {
                  name: "type",
                  value: this.selectedType.id
                },
                {
                  name: "date",
                  value: this.chosenDate
                },
                {
                  name: "start",
                  value: this.start
                },
                {
                  name: "end",
                  value: this.end
                },
                {
                  name: "notes",
                  value: this.notes
                }
              ]
            };

            db.collection("bookingen")
            .add({
              resourceDocId: employee,
              start: this.chosenDate + "T" + this.start,
              end: this.chosenDate + "T" + this.end,
              dateBooking: this.chosenDate,
              created: moment().format("YYYY-MM-DDTHH:mm"),
              companyId: this.$store.state.activeCompany.id,
              branchId: this.$store.state.activeBranch.id,
              title: this.selectedType.name,
              clientname:  employeeInfo ? employeeInfo.title : "",
              description: this.notes,
              type: this.selectedType.id,
              extra: extra,
              eventColor: bookingcolor,
              uniqIdStamp: uniqidBooking,
              modifiedStamp: new Date(),
              bookingOverride: this.bookingOverride, // Overheen boeken mogelijk? False = Nee, True = Ja
              leadExtra: this.lead && this.preSelectedClientId ? { leadId: this.lead, cliendId: this.preSelectedClientId}: null // Post lead info if lead
            })
            .then((docRef) => {              
              cacheObject.bookings.push(docRef.id)
              cacheObject.resources.push(employee)
              counter++
              if(counter == this.selectedEmployees.length){
                bus.$emit("newBookingCacheUpdate", cacheObject);
                console.log("emit")
                bus.$emit("calendarAction", "addPause", this.selectedType.id);
                this.closedialog();
              }

              this.$createNewLog("info", logData);
            })
            .catch((error) => {
              console.error("Error adding pause: ", error);
              logData.extra.push({ name: "error", value: JSON.stringify(error) })
              this.$createNewLog("error", logData);
            });
          });
        }
        else {
          this.feedback = this.$t('pause.required', this.$store.state.locale);
        }
      },
    },
    computed: {
      chosenDateReadable() {
        return this.chosenDate ? moment(this.chosenDate).format("D MMM YYYY") : "";
      },
      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      checked() {
        return this.selectedEmployees.length > 0 && this.selectedType && this.chosenDate ? true : false;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      }
    },
  };
</script>
