import i18n from "../i18n";
import store from "../store";

export default 

{
  presenceItems: [
    {
      name: i18n.t('employees.extraHours', store.state.locale),
      id: "extraHours",
    },
    {
      name: i18n.t('employees.exchangeService', store.state.locale),
      id: "exchangeService",
    },
    {
      name: i18n.t('employees.workTherapeutically', store.state.locale),
      id: "workTherapeutically",
    },
    {
      name: i18n.t('employees.training', store.state.locale),
      id: "training",
    },
    {
      name: i18n.t('employees.overtime25', store.state.locale),
      id: "overtime25",
    },
    {
      name: i18n.t('employees.overtime100', store.state.locale),
      id: "overtime100",
    }
  ],
  absentItems: [
    {
      name: i18n.t('employees.leave', store.state.locale),
      id: "leave",
    },
    {
      name: i18n.t('employees.loanToOtherSalon', store.state.locale),
      id: "loanToOtherSalon",
    },
    {
      name: i18n.t('employees.alternativeActivities', store.state.locale),
      id: "alternativeActivities",
    },
    {
      name: i18n.t('employees.exchangeService', store.state.locale),
      id: "exchangeService",
    },
    {
      name: i18n.t('employees.sickness', store.state.locale),
      id: "sickness",
    },
    { divider: true },
    { header: i18n.t('employees.marriage', store.state.locale)},
    {
      name: i18n.t('employees.betrothal', store.state.locale),
      id: "betrothal",
    },
    {
      name: i18n.t('employees.cohabitationContract', store.state.locale),
      id: "cohabitationContract",
    },
    {
      name: i18n.t('employees.ownMarriage', store.state.locale),
      id: "ownMarriage",
    },
    {
      name: i18n.t('employees.marriageImmediateFamily', store.state.locale),
      id: "marriageImmediateFamily",
    },

    { divider: true },
    { header: i18n.t('employees.pregnancy', store.state.locale)},
    {
      name: i18n.t('employees.pregnancyLeave', store.state.locale),
      id: "pregnancyLeave",
    },
    {
      name: i18n.t('employees.childbirthPartner', store.state.locale),
      id: "childbirthPartner",
    },

    { divider: true },
    { header: i18n.t('employees.anniversary', store.state.locale)},
    {
      name: i18n.t('employees.anniversary125inService', store.state.locale),
      id: "anniversary125inService",
    },
    {
      name: i18n.t('employees.anniversaryOwnMarriage', store.state.locale),
      id: "anniversaryOwnMarriage",
    },
    {
      name: i18n.t('employees.anniversaryDirectFamilyMarriage', store.state.locale),
      id: "anniversaryDirectFamilyMarriage",
    },

    { divider: true },
    { header: i18n.t('employees.decease', store.state.locale)},

    {
      name: i18n.t('employees.deceasePartnerOrChild', store.state.locale),
      id: "deceasePartnerOrChild",
    },
    {
      name: i18n.t('employees.deceaseDirectFamily', store.state.locale),
      id: "deceaseDirectFamily",
    },
    {
      name: i18n.t('employees.funeralDirectFamily', store.state.locale),
      id: "funeralDirectFamily",
    },

    { divider: true },
    { header: i18n.t('employees.medical', store.state.locale)},

    {
      name: i18n.t('employees.doctorDentistVisit', store.state.locale),
      id: "doctorDentistVisit",
    },
    {
      name: i18n.t('employees.medicalSpecialistVisit', store.state.locale),
      id: "medicalSpecialistVisit",
    },

    { divider: true },
    { header: i18n.t('general.others', store.state.locale)},

    {
      name: i18n.t('employees.exam', store.state.locale),
      id: "exam",
    },
    {
      name: i18n.t('employees.moving', store.state.locale),
      id: "moving",
    },
    {
      name: i18n.t('employees.searchNewJob', store.state.locale),
      id: "searchNewJob",
    },
    {
      name: i18n.t('employees.unionLeave', store.state.locale),
      id: "unionLeave",
    },
  ],
};
