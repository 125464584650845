export default 
  [
    {
      id: "advertisement",
      name: {
        en: "Advertisement",
        nl: "Advertentie",
        de: "Anzeige"
      },
    },
    {
      id: "bringFriend",
      name: {
        en: "Bring a friend",
        nl: "Bring a friend",
        de: "Bring a friend"
      },
    },
    {
      id: "email",
      name: {
        en: "E-mail",
        nl: "E-mail",
        de: "E-mail",
      },
    },
    {
      id: "website",
      name: {
        en: "Website",
        nl: "Website",
        de: "Webseite",
      },
    },
    {
      id: "flyer",
      name: {
        en: "Flyer",
        nl: "Flyer",
        de: "Pamphlet",
      },
    },
    {
      id: "wordOfMonth",
      name: {
        en: "Word of month",
        nl: "Mond tot mond",
        de: "Mund zu Mund"
      },
    },
    {
      id: "referral",
      name: {
        en: "Referral",
        nl: "Doorverwijzing",
        de: "Verweisung"
      },
    },
    {
      id: "social",
      name: {
        en: "Social Media",
        nl: "Social Media",
        de: "Sozialen Medien",
      },
    },
    {
      id: "formerClient",
      name: {
        en: "Former client",
        nl: "Voormalige klant",
        de: "Ehemaliger Kunde"
      },
    },
  ]
;
