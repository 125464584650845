<template>
  <div>
    <div v-if="!loading">
      <div class="no-data-message" v-if="organizedTreatmentTypes.length == 0">
        <p v-html="noMainComponentsAdded"></p>
        <v-btn
          dark
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          @click="newSubtreatmentTypeCategory()"
          >
          <v-icon darken>mdi-plus</v-icon> {{ $t("admin.addMainComponent", $store.state.locale) }}
        </v-btn>
      </div>

      <v-card v-else flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
        <v-card class="ma-4" outlined v-for="(category, index) in organizedTreatmentTypes" :key="index"  :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px">
          <v-container grid-list-md fluid>
            <v-layout row wrap>
              <v-flex xs8 class="pt-3">
                <h3>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, category.name) }}</h3>
              </v-flex>
              <v-flex xs4>
                <v-btn
                  style="float: right;"
                  icon
                  class="mx-0"
                  @click="editSubtreatmentTypeCategory(category)"
                  >
                  <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" >mdi-pencil</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
  
          <div class="text-center pt-4" v-if="!category.types || category.types.length == 0">
            <p class="ma-0" v-html="noComponentsAdded"></p>
          </div>

          <div v-else>
            <v-data-table
              :headers="headers"
              :items="category.types"
              :itemsPerPage.sync="pages"
              hide-default-footer
              :search="search"
              sort-by="name"
              item-key="name"
              dense
              class="dense-table"
              :no-results-text="`Geen resultaten gevonden voor: ${search}`"
              style="background: none!important"
              >
              <template v-slot:item="{item }">
                <tr>
                  <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) }}</td>
                  <td>
                    <v-btn icon class="mx-0" @click="editSubtreatmentType(item)">
                      <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </div>

          <div class="text-center ma-4">
            <v-btn fab small depressed  dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="newSubtreatmentType(category.id)">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
         <div v-if="organizedTreatmentTypes.length > 0" class="text-center">
          <v-btn
            class="ma-4"
            dark
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            @click="newSubtreatmentTypeCategory()"
            >
            <v-icon darken>mdi-plus</v-icon> {{ $t("admin.addMainComponent", $store.state.locale) }}
          </v-btn>
        </div>
      </v-card>
    </div>

    <div v-if="loading == true">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>

    <!-- / SubtreatmentType Dialog \ -->
    <subtreatmenttype-form
      :subtreatmentTypeCategories="subtreatmentTypeCategories"
      @updateData="loadSubtreatmentTypes()"
    ></subtreatmenttype-form>
    <!-- \ SubtreatmentType Dialog / -->

    <!-- / SubtreatmentType Dialog \ -->
    <subtreatmenttype-category-form
      @updateData="loadSubtreatmentTypes()"
    ></subtreatmenttype-category-form>
    <!-- \ SubtreatmentTypeCategory Dialog / -->
  </div>
</template>
     

<script>
  import '@firebase/firestore';
  import db from "../firebase/init";
  import _ from "lodash";
  import { bus } from "../main";
  import SubtreatmentTypeForm from "../components/modals/SubtreatmentTypeForm.vue";
  import SubtreatmentTypeCategoryForm from "../components/modals/SubtreatmentTypeCategoryForm.vue";

  export default {
    components: {
      "subtreatmenttype-form": SubtreatmentTypeForm,
      "subtreatmenttype-category-form": SubtreatmentTypeCategoryForm
    },
    name: "SubtreatmentTypes",
    data() {
      return {
        search: "",
        searchCategories: "",
        organizedTreatmentTypes: new Array(),
        loading: true,
        pages: -1,
        subtreatmentTypes: new Array(),
        subtreatmentTypeCategories: new Array(),
        pagination: {
          descending: false,
          rowsPerPage: -1,
          sortBy: "name"
        },
        headers: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "name"
          },
          {
            text: "",
            align: "right",
            value: "edit",
            width: "60px"
          }
        ],
        headersCategories: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "name"
          },
          {
            text: "",
            align: "right",
            value: "edit",
            width: "60px"
          }
        ]
      }
    },

    created() {
      this.loadSubtreatmentTypes();
    },

    methods: {

      newSubtreatmentType(category){
        bus.$emit("SubtreatmentType", null, category, 'new');
      },

      editSubtreatmentType(item) {
        bus.$emit("SubtreatmentType", item, null, 'edit');
      },

      newSubtreatmentTypeCategory(){
        bus.$emit("SubtreatmentTypeCategory", null, 'new');
      },

      editSubtreatmentTypeCategory(item) {
        bus.$emit("SubtreatmentTypeCategory", item, 'edit');
      },

      loadSubtreatmentTypes() {
        this.loading = true;
        db.collection("subtreatmentTypes")
        .get()
        .then(snap => {
          this.subtreatmentTypes = [];
          snap.forEach(doc => {
            let subtreatmentType = doc.data();
            subtreatmentType.id = doc.id;
            this.subtreatmentTypes.push(subtreatmentType);
          });
        })
        .then(() => {
          this.loadSubtreatmentTypeCategories();
        })
        .catch(error => {
          console.error("Error bij binnenhalen subtreatmentTypes: ", error);
          this.loading = false;
        });
      },
      loadSubtreatmentTypeCategories() {
        this.loading = true;
        db.collection("subtreatmentTypeCategories")
        .get()
        .then(snap => {
          this.subtreatmentTypeCategories = [];
          snap.forEach(doc => {
            let subtreatmentTypeCategory = doc.data();
            subtreatmentTypeCategory.id = doc.id;
            this.subtreatmentTypeCategories.push(subtreatmentTypeCategory);
          });
        })
        .then(() => {
          this.organizeTreatmentTypes();
        })
        .catch(error => {
          console.error("Error bij binnenhalen subtreatmentTypesCategories: ", error);
          this.loading = false;
        });
      },

      organizeTreatmentTypes(){
        let vm = this;
        this.organizedTreatmentTypes = [];
        this.subtreatmentTypeCategories.forEach(category=>{
          let categoryItems = [];
          this.subtreatmentTypes.forEach(type=>{
            if(type.category == category.id){
              categoryItems.push(type)
            }
          })
            categoryItems = _.sortBy(categoryItems, [function(o) { return o.name[(vm.$store.state.locale).toLowerCase()]; }]);
            category.types = categoryItems;
            this.organizedTreatmentTypes.push(category)
        })
        this.loading = false;
        this.organizedTreatmentTypes = _.sortBy(this.organizedTreatmentTypes, [function(o) { return o.name[(vm.$store.state.locale).toLowerCase()]; }]);
      },
    }
  };
</script>
