<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2 class="white--text">Bedrijf toevoegen</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeAddCompDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text>
      <v-form  ref="AddCompForm" lazy-validation>
        <v-text-field
          label="Bedrijfsnaam"
          v-model="newComp.name"
          autocomplete
          prepend-inner-icon="mdi-domain"
          required
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>
        <v-text-field
          label="Email"
          v-model="newComp.email"
          :rules="emailRules"
          autocomplete
          required
          prepend-inner-icon="mdi-email"
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>
        <v-text-field
          label="Telefoonnummer"
          v-model="newComp.phone"
          prepend-inner-icon="mdi-phone"
          required
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>
        <v-text-field
          label="KvK-nummer"
          v-model="newComp.coc"
          autocomplete
          required
          prepend-inner-icon="mdi-web"
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>
         <v-text-field
          label="BTW-nummer"
          v-model="newComp.vat"
          autocomplete
          required
          prepend-inner-icon="mdi-web"
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>     
      </v-form>

      <!-- CONTACT -->
      <h3 class="client-overview-subtitle pt-4 pb-2">Adresgegevens</h3>  
      <v-container grid-list-md class="pa-0" fluid>
        <v-layout row wrap>

          <v-flex xs8>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="Straat"
              v-model="newComp.address.street"
              outlined
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>

          <v-flex xs4>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="huisnummer"
              v-model="newComp.address.housenr"
              outlined
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>

          <v-flex xs4>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="Postcode"
              v-model="newComp.address.postalCode"
              outlined
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>

          <v-flex xs8>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="Plaats"
              v-model="newComp.address.city"
              outlined
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="Land"
              v-model="newComp.address.country"
              outlined
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>

          <v-flex xs6>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="Telefoonnummer"
              v-model="newComp.phone"
              autocomplete
              required
              outlined
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
              @click="closeAddCompDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md8 class="text-right">
            <v-btn
              dark
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              @click="addCompany()"
              >
              Bedrijf toevoegen
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>


<script>
import db from "../../firebase/init";
import '@firebase/firestore';

export default {
  name: "add-comp-client-form",
  data() {
    return {
      newComp: {
        name:null,
        phone: null,
        email: null,
        coc: null,
        vat: null,
        address: {}
      },
      feedback: null,
      emailRules: [
        v => !!v || "E-mail is verplicht",
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid"
      ]
    };
  },

  methods: {
    closeAddCompDialog() {
      this.$emit("newClientCompDialogChange", false);
    },

    async addCompany() {
      this.feedback = null;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "clientCompanyAdded",
        actionItem: `${this.newClient.name} ${this.newClient.surname}`,
        extra: [
          {
            name: "name",
            value: this.newComp.name 
          },
          {
            name: "email",
            value: this.newComp.email 
          },
          {
            name: "cocNumber",
            value: this.newComp.coc 
          },
          {
            name: "vatNumber",
            value: this.newComp.vat 
          },
        ]
      };
      if (this.newComp.name && this.newComp.email) {
        try {
          await db.collection("companyClients")
          .doc()
          .set({
            // Basic Company Settings
            companyId: this.$store.state.activeCompany.id,
            name: this.newComp.name,
            email: this.newComp.email,
            phone: this.newComp.phone,
            coc: this.newComp.coc,
            vat: this.newComp.vat,
            address: this.newComp.address,
          })

          await this.$createNewLog("info", logData);
          this.$emit("updateData");
          this.closeAddCompDialog();
          console.log("Company(client) succesfully added");
        }
        catch (error) {
          this.feedback = this.$t("general.addError", this.$store.state.locale) ;
          console.error("Error adding company(client): ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      }
      else {
        this.feedback = "Vul alle velden in om een bedrijf toe te voegen.";
      }
    }
  }
};
</script>
