




// let chosenTemplate = {
//    fields: [
//       {
//          typeField: "open",
//          label: {description: "Inwerktijd", prefix: "", suffix: "", fieldCharacters: "default" },
//          input: "",
//          optionsSingleChoice: [],
//          optionsMultiChoice: [],
//          uniqId: "Bstja1wxxl13ttu20"
//       },
//       {
//          typeField: "open",
//          label: {description: "Omschrijving soort", prefix: "", suffix: "", fieldCharacters: "default" },
//          input: "",
//          optionsSingleChoice: [],
//          optionsMultiChoice: [],
//          uniqId: "Astja1wxxl13ttu29"
//       },
     
//       {
//          typeField: "open",
//          label: {description: "Klant Rating", prefix: "", suffix: "", fieldCharacters: "default" },
//          input: "",
//          optionsSingleChoice: [1,2,3,4,5],
//          optionsMultiChoice: ["dit is joe", "dit is Sven", "dit is Eva"],
//          uniqId: "Cstja1wxxl13ttu29"
         
//       }
//    ]
   
// }
// // If chosenTemplate is not found.. Change chosenTemplate from ExistingDoc 
// let chosenTemplate = {
// 	fields: [
// 		{
// 			input: "",
// 			label: {
// 				description: {
// 					en: "This is a closed questions",
// 					nl: "Dit is een gesloten vraag",
// 				},
// 				fieldCharacters: null,
// 				prefix: "",
// 				suffix: "",
// 			},
// 			optionsClosed: [
// 				{
// 					en: "Yes",
// 					id: "ert1",
// 					nl: "Ja",
// 					value: true,
// 					scoreValue: 0,
// 				},
// 				{
// 					en: "No",
// 					id: "ert2",
// 					nl: "Nee",
// 					value: false,
// 					scoreValue: 8,
// 				},
// 			],

// 			relatedTo: [],
// 			required: true,
// 			typeField: "closed",
// 			uniqId: "ABC2",
// 		},
// 		{
// 			typeField: "open",
// 			label: { description: { nl: "Nieuwe Item", prefix: "", suffix: "", fieldCharacters: "default" } },
// 			input: "",
// 			optionsClosed: [],
// 			uniqId: "AAABstja1wxxl13ttu20",
// 			relatedTo: [{ id: "ert1", action: "doWebhook", actionParameter: "hogans.nl", actionParameter2: "hogans2.nl" }],
// 			scoreValue: 7,
// 		},
// 		{
// 			typeField: "open",
// 			label: { description: { nl: "Omschrijving soort", prefix: "", suffix: "", fieldCharacters: "default" } },
// 			input: "",
// 			optionsSingleChoice: [],
// 			optionsMultiChoice: [],
// 			optionsClosed: [],
// 			uniqId: "Bstja1wxxl13ttu20",
// 			relatedTo: [{ id: "ert1", action: "doWebhook", actionParameter: "hogans.nl", actionParameter2: "hogans2.nl" }],
// 		},
// 		{
// 			typeField: "open",
// 			label: { description: { nl: "Inwerk tijd", prefix: "", suffix: "", fieldCharacters: "default" } },
// 			input: "",
// 			optionsSingleChoice: [],
// 			optionsMultiChoice: [],
// 			optionsClosed: [],
// 			relatedTo: [],
// 			uniqId: "Astja1wxxl13ttu29",
// 		},

// 		{
// 			typeField: "open",
// 			label: { description: { nl: "Klant Rating", prefix: "", suffix: "", fieldCharacters: "default" } },
// 			input: "",
// 			optionsSingleChoice: [
// 				{ nl: "Keuze A", en: "Choice A", id: "uu1" },
// 				{ nl: "Keuze 8", en: "Choice B", id: "uu2" },
// 			],
// 			optionsMultiChoice: [
// 				{ nl: "Keuze A", en: "Choice A", id: "uu1" },
// 				{ nl: "Keuze B", en: "Choice B", id: "uu2" },
// 				{ nl: "Keuze C", en: "Choice C", id: "uu3" },
// 			],
// 			optionsClosed: [],
// 			relatedTo: [],
// 			uniqId: "Cstja1wxxl13ttu29",
// 		},
// 	],
// };

// let existingDoc = {
// 	fields: [
// 		{
// 			input: "",
// 			label: {
// 				description: {
// 					en: "This is a closed questions",
// 					nl: "Dit is een gesloten vraag",
// 				},
// 				fieldCharacters: null,
// 				prefix: "",
// 				suffix: "",
// 			},
// 			optionsClosed: [
// 				{
// 					en: "Yes",
// 					id: "ert1",
// 					nl: "Ja",
// 					value: true,
// 					scoreValue: 0,
// 				},
// 				{
// 					en: "No",
// 					id: "ert2",
// 					nl: "Nee",
// 					value: false,
// 					scoreValue: 5,
// 				},
// 			],

// 			relatedTo: [],
// 			required: true,
// 			typeField: "closed",
// 			uniqId: "ABC2",
// 		},

// 		{
// 			typeField: "open",
// 			label: { description: { nl: "Inwerk tijd", prefix: "", suffix: "", fieldCharacters: "default" } },
// 			input: "",
// 			optionsSingleChoice: [],
// 			optionsMultiChoice: [],
// 			optionsClosed: [],
// 			relatedTo: [],
// 			uniqId: "Astja1wxxl13ttu29",
// 		},
// 		{
// 			typeField: "open",
// 			label: { description: { nl: "Nieuwe Item", prefix: "", suffix: "", fieldCharacters: "default" } },
// 			input: "",
// 			optionsClosed: [],
// 			uniqId: "AAABstja1wxxl13ttu20",
// 			relatedTo: [{ id: "ert1", action: "doWebhook", actionParameter: "hogans.nl", actionParameter2: "hogans2.nl" }],
// 			scoreValue: 85,
// 		},

// 		{
// 			typeField: "open",
// 			label: {
// 				description: {
// 					nl: "Klant Rating",
// 					prefix: "",
// 					suffix: "",
// 					fieldCharacters: "default",
// 					joe: { naam: "test", joeDeep: { testDeep: "deep" } },
// 				},
// 			},
// 			input: "",
// 			optionsSingleChoice: [
// 				{ nl: "Keuze A", en: "Choice A", id: "uu1" },
// 				{ nl: "Keuze B", en: "Choice B", id: "uu2" },
// 				{ nl: "Keuze C", en: "Choice C", id: "uu3" },
// 			],
// 			optionsMultiChoice: [
// 				{ nl: "Keuze d", en: "Choice A", id: "uu1" },
// 				{ nl: "Keuze B", en: "Choice B", id: "uu2" },
// 				{ nl: "Keuze C", en: "Choice C", id: "uu3" },
// 				{ nl: "Keuze D", en: "Choice D", id: "uu8" },
// 			],
// 			optionsClosed: [],
// 			relatedTo: [],
// 			uniqId: "Cstja1wxxl13ttu29",
// 		},
// 	],
// };


// let relatedToRegister = chosenTemplate.fields.map(field => field.relatedTo).flat();

// let uniqueIds = [...new Set(relatedToRegister.map(item => item.id))]

// let uniqueObjects = uniqueIds.map(id => relatedToRegister.find(item => item.id === id));



// buildUpForm(chosenTemplate, existingDoc);



export function buildUpForm(chosenTemplate, existingDoc) {


   if(existingDoc === null) {

     //register all relatedTo array to one array

     
       /* Creating an array of objects that are relatedTo questions the chosen template. */
       let relatedToRegister = chosenTemplate.fields.map(field => field.relatedTo).flat();

       //Remove duplicate and make uniq by id 
       let uniqueIds = relatedToRegister.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
       //Get the objects with the unique id from uniqueIds and create an array from it.
       let uniqueObjects = uniqueIds.map(id => relatedToRegister.find(item => item.id === id));

       chosenTemplate.relatedToRegister = uniqueObjects;

       console.log(chosenTemplate.relatedToRegister, 'tmpBuildedDoc met Null', );

     

     return chosenTemplate;
   } else {
     return mergeForm(chosenTemplate, existingDoc);
   }

   function mergeForm(chosenTemplate, existingDoc) {

      let tmpBuildedDoc = JSON.parse(JSON.stringify(existingDoc)) //?
      //Wipe out fields for a new build up.
      tmpBuildedDoc.fields = [];

      chosenTemplate.fields.forEach((chosenField) => {
         //Match Fields
         
         chosenField.uniqId //?
         let existingFoundField = existingDoc.fields.find(existingField => chosenField.uniqId === existingField.uniqId)

         existingFoundField //?

         if(existingFoundField) {
            //Found the field are there new changes?
            
            existingFoundField.label.prefix = chosenField.label.prefix ? chosenField.label.prefix : existingFoundField.label.prefix;
            existingFoundField.label.suffix = chosenField.label.suffix ? chosenField.label.suffix : "";
            
            //Icons
            existingFoundField.label.prependIcon = chosenField.label.prependIcon ? chosenField.label.prependIcon : "";
            existingFoundField.label.appendIcon = chosenField.label.appendIcon ? chosenField.label.appendIcon : "";
            
            //Score
            existingFoundField.scoreValue = chosenField.scoreValue ? chosenField.scoreValue : 0;
                     
            
            //OPTIONS CHECKBOX
            //Add new Options if needed
            if(existingFoundField.optionsMultiChoice) {

               if(existingFoundField.optionsMultiChoice.length === 0) {
                  //Add all new options
                  existingFoundField.optionsMultiChoice = chosenField.optionsMultiChoice
               } else if (existingFoundField.optionsMultiChoice.length !== 0) {
               //Add new options but keep the old one. 
                  
                  /* The code below is adding the optionsMultiChoice field to the newOptionsCheckbox array. */
                  let newOptionsCheckbox = existingFoundField.optionsMultiChoice;
   
                  chosenField.optionsMultiChoice.forEach(fieldTemplate => newOptionsCheckbox.push(fieldTemplate))
   
                  /* Removing duplicates from the array. */
                  
                     //Get unique values by id from the array.
                     let uniqueIds = newOptionsCheckbox.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index);
                     //Get the objects with the unique id from uniqueIds and create an array from it.
                     let uniqueObjects = uniqueIds.map(id => newOptionsCheckbox.find(item => item.id === id));


                     existingFoundField.optionsMultiChoice = uniqueObjects;
                     existingFoundField.optionsMultiChoice = updateDataWithSameId(existingFoundField.optionsMultiChoice, chosenField.optionsMultiChoice);
   
               }

            }
            

            //OPTIONS DROPDOWN
            //Add new Options if needed
            if(existingFoundField.optionsSingleChoice) {

               if(existingFoundField.optionsSingleChoice.length === 0) {
                  //Add all new options
                  existingFoundField.optionsSingleChoice = chosenField.optionsSingleChoice
               } else if (existingFoundField.optionsSingleChoice.length !== 0) {
               //Add new options but keep the old one. 
                  
                  /* The code below is adding the optionsSingleChoice field to the newOptionsCheckbox array. */
                  let newoptionsSingleChoice = existingFoundField.optionsSingleChoice;
   
                  chosenField.optionsSingleChoice.forEach(fieldTemplate => newoptionsSingleChoice.push(fieldTemplate));


   
               /* Removing duplicates from the array. */

               //Get unique values by id from the array.
               let uniqueIds = newoptionsSingleChoice.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index);
               //Get the objects with the unique id from uniqueIds and create an array from it.
               let uniqueObjects = uniqueIds.map(id => newoptionsSingleChoice.find(item => item.id === id));

               existingFoundField.optionsSingleChoice = uniqueObjects;
                   //Arrays are the same length. Check if there are any property changes.
                  existingFoundField.optionsSingleChoice = updateDataWithSameId(existingFoundField.optionsSingleChoice, chosenField.optionsSingleChoice);      
               }
            }

            //Options Closed
            if(existingFoundField.optionsClosed) {
              
               if(existingFoundField.optionsClosed.length === 0) {
                  //Add all new options
                  existingFoundField.optionsClosed = chosenField.optionsClosed
               } else if (existingFoundField.optionsClosed.length !== 0) {
									//Add new options but keep the old one.

									/* The code below is adding the optionsSingleChoice field to the newOptionsCheckbox array. */
									let newoptionsClosed = existingFoundField.optionsClosed;

									chosenField.optionsClosed.forEach((fieldTemplate) => newoptionsClosed.push(fieldTemplate));

									/* Removing duplicates from the array. */

									//Get unique values by id from the array.
									let uniqueIds = newoptionsClosed.map((item) => item.id).filter((value, index, self) => self.indexOf(value) === index);
									//Get the objects with the unique id from uniqueIds and create an array from it.
									let uniqueObjects = uniqueIds.map((id) => newoptionsClosed.find((item) => item.id === id));

									existingFoundField.optionsClosed = uniqueObjects;

                           //Arrays are the same length. Check if there are any property changes.
                           existingFoundField.optionsClosed = updateDataWithSameId(existingFoundField.optionsClosed, chosenField.optionsClosed);

								}
            }
            
            //Related To
            if(existingFoundField.relatedTo) {

               if(existingFoundField.relatedTo.length === 0) {
                  //Add all new options
                  existingFoundField.relatedTo = chosenField.relatedTo
               } else if (existingFoundField.relatedTo.length !== 0) {
               //Add new options but keep the old one. 
                  
                  /* The code below is adding the optionsSingleChoice field to the newOptionsCheckbox array. */
                  let newrelatedTo = existingFoundField.relatedTo;
   
                  chosenField.relatedTo.forEach(fieldTemplate => newrelatedTo.push(fieldTemplate));

               
                  //Remove duplicate and make uniq by id 
                  let uniqueIds = newrelatedTo.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
                  //Get the objects with the unique id from uniqueIds and create an array from it.
                  let uniqueObjects = uniqueIds.map(id => newrelatedTo.find(item => item.id === id));

                  existingFoundField.relatedTo = uniqueObjects;
                  existingFoundField.relatedTo = updateDataWithSameId(existingFoundField.relatedTo, chosenField.relatedTo);
   
               }
            }
            
            
            //Push the builded field to tmpBuildedDoc
            tmpBuildedDoc.fields.push(existingFoundField);
            
         } else {
            //Field not found in existingDoc
            tmpBuildedDoc.fields.push(chosenField);

         }

         


      })


      //Add existing fields that are not added via chosenTemplate 
      existingDoc.fields.forEach((fieldExisting) => {
         tmpBuildedDoc.fields
         //Check if field is Already added by UniqId

         let foundField = tmpBuildedDoc.fields.find(buildedField => buildedField.uniqId === fieldExisting.uniqId)
         
         //Field not found in tmpBuildedDoc so push
         if(!foundField) {
            tmpBuildedDoc.fields.push(fieldExisting);
         }

      })

      //register all relatedTo array to one array
      let relatedToRegister = tmpBuildedDoc.fields.map(field => field.relatedTo).flat();

      tmpBuildedDoc.relatedToRegister = [...new Set(relatedToRegister)];

      console.log(tmpBuildedDoc.relatedToRegister, 'tmpBuildedDoc');

      return tmpBuildedDoc; //?


   }

 
  /**
   * It takes two arrays of objects, and returns a new array of objects that contains the objects from
   * the first array that have the same id as the objects in the second array, and the objects from the
   * second array that don't have the same id as the objects in the first array
   * @param existing - The existing data that you want to update.
   * @param newData - The new data that you want to add to the existing data.
   * @returns an array of objects.
   */
   function updateDataWithSameId(existing, newData) {
			for (let i = 0; i < existing.length; i++) {
				for (let j = 0; j < newData.length; j++) {
					if (existing[i].id === newData[j].id) {
						for (const prop in existing[i]) {
							if (existing[i][prop] !== newData[j][prop]) {
								existing[i] = newData[j];
								break;
							}
						}
						break;
					}
				}
			}
			return existing.concat(newData.filter((obj) => !existing.some((existingObj) => existingObj.id === obj.id)));
		}


   
}