<template>
  <div>
    <v-container v-if="!loading" grid-list-md class="pa-0 ma-0 mb-3" >
      <v-layout row wrap>
        <v-flex  md3 xs12 v-if="$store.state.userRoleNumber < 2">
          <v-select
            rounded
            :label="$t('admin.logtype', $store.state.locale)"
            :items="logTypes"
            item-text="name"
            item-value="id"
            v-on:input="loadLog();"
            v-model="logFilter"
            class="ma-0"
            outlined
            dense
            hide-details
            :color="$vuetify.theme.themes[theme].primary"
            :item-color="$vuetify.theme.themes[theme].primary"
            :background-color="$vuetify.theme.themes[theme].menu"       
          ></v-select>
        </v-flex>
        <v-flex  md3 xs12 v-if="companies.length > 1 && logFilter == 'User Action'">
          <v-select
            rounded
            :items="filteredCompanies"
            :color="$vuetify.theme.themes[theme].primary"
            :item-color="$vuetify.theme.themes[theme].primary"
            :background-color="$vuetify.theme.themes[theme].menu"
            item-text="name"
            item-value="id"
            hide-details
            v-on:input="loadLog()"
            v-model="activeComp"
            :no-data-text="$t('general.noCompanies', $store.state.locale)"
            :label="$t('general.selectCompany', $store.state.locale)"
            outlined
            dense
            >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content  class="pt-0" >
                  <v-text-field
                    :color="$vuetify.theme.themes[theme].primary"
                    :placeholder="$t('general.search', $store.state.locale)"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    v-model="searchCompanies"
                    hide-details
                    dense
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <div v-if="searchCompanies && searchCompanies.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredCompanies.length }} van {{ companies.length }} bedrijven</div>
              <v-divider v-if="searchCompanies && searchCompanies.length > 0"></v-divider>
            </template>
          </v-select>
        </v-flex>
        <v-flex md3 xs12></v-flex>
        <v-flex md3 xs12>
          <v-btn
            v-if="$store.state.userRoleNumber < 2 && selected.length > 0"
            fab
            style="margin: -3px 10px 0 10px; float: right"
            small
            depressed
            dark
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary"
            @click="deleteLog()"
            >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
          <v-text-field
            rounded
            :placeholder="$t('general.search', $store.state.locale)"
            v-model="search"
            name="searchfield"
            style="display: inline-block; float: right"
            prepend-inner-icon="mdi-magnify"
            class="ma-0"
            clearable
            outlined
            dense
            hide-details
            :color="$vuetify.theme.themes[theme].primary"
            :background-color="$vuetify.theme.themes[theme].menu"       
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="loading && !deleting">
      <div class="loading-dots">
        <div class="loading-dots-loader"></div>
        <p class="loading-dots-text"></p>
      </div>
    </div>

    <div v-if="deleting">
      <div class="loading-dots">
        <div class="loading-dots-loader"></div>
        <p class="loading-dots-text">{{ deleteCounter }} {{ $t("admin.logOf", $store.state.locale) }} {{ selected.length }} {{ $t("admin.logDeleted", $store.state.locale) }}.</p>
      </div>
    </div>

    <!-- / User Logs  \ -->
    <div v-if="!loading && !deleting && logFilter == 'User Action'">
      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
        <v-data-table
          :headers="headers"
          :items="userLog"
          :search="search"
          single-expand
          :items-per-page="-1"
          show-expand
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
          v-model="selected"
          hide-default-footer
          dense
          class="dense-table"
          :style="{'background-color' : $themes[$store.state.companyTheme][$store.state.themeModus].card}"
          >
          <template v-slot:item="{item, isExpanded, expand , isSelected, select }">
            <tr>
              <td v-if="$store.state.userRoleNumber < 2">
                <v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
                <v-icon @click="select(false)" v-if="isSelected" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
              </td>
              <td>{{ item.time.seconds | moment("D MMM YYYY - H:mm") }}u</td>
              <td>
                <v-icon size="18" v-if="item.logType == 'loginerror'" color="red">mdi-alert-outline</v-icon>
                <v-icon size="18" v-if="item.logType == 'error'" color="red">mdi-alert-outline</v-icon>
                <v-icon size="18" v-if="item.logType == 'info'" color="grey lighten-1">mdi-information-outline</v-icon> 
              </td>
              <td>
                <span v-if="item.logType == 'loginerror'">
                  <span v-for="user in users" :key="user.email">
                    <span
                      v-if="item.loginEmail && (user.email == item.loginEmail)"
                      >
                      <span v-for="company in companies" :key="company.id">
                        <span v-if="company.id == user.company" :style="{color: $vuetify.theme.themes[theme].text}">{{ company.name }}</span>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span v-for="company in companies" :key="company.id">
                    <span v-if="company.id == item.company" :style="{color: $vuetify.theme.themes[theme].text}">{{ company.name }}</span>
                  </span>
                </span>
              </td>
              <td>
                <span v-if="item.logType == 'loginerror'">
                  <span v-for="user in users" :key="user.email">
                    <span 
                      :style="{color: $vuetify.theme.themes[theme].text}"
                      v-if="item.loginEmail && (user.email == item.loginEmail)"
                    >{{ user.firstName }} {{ user.lastName }}</span>
                  </span>
                </span>
                <span v-else>{{ item.user }}</span>
              </td>
              <td>
                <span v-if="item.logType == 'loginerror'">
                  <span v-for="user in users" :key="user.email">
                    <span v-if="item.loginEmail && (user.email == item.loginEmail)">
                      <span v-for="role in userRoles" :key="role.id">
                        <span v-if="role.id == user.userRole">{{ role.name }}</span>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span v-for="role in userRoles" :key="role.id">
                    <span v-if="role.id == item.userType">{{ role.name }}</span>
                  </span>
                </span>
              </td>
              <td>
                <span v-if="item.applicationType =='smarthphone-application'">Smartphone</span>
                <span v-else>Desktop</span>
              </td>
              <td>{{ item.action }}</td>
              <td>
                <v-btn icon @click="expand(true)" v-if="item.extra && !isExpanded"><v-icon  dark>mdi-menu-down</v-icon></v-btn>
                <v-btn icon @click="expand(false)" v-if="item.extra && isExpanded"><v-icon  dark>mdi-menu-up</v-icon></v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 0!important; margin: 0">
              <v-card flat class="log-extra-card ma-0 pa-0">
                <v-card-text class="ma-0">
                  <div class="mb-2">
                    <p class="ma-0"><strong>Software: </strong>{{item.software}}</p>
                    <p class="ma-0"><strong>Browser: </strong>{{item.browser}}</p>
                  </div>
                  <div v-html="item.extra "></div>
                </v-card-text>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-card>

      <div v-if="userLog.length >= 100" class="text-center" style="padding: 20px;">
        <v-btn v-if="extraLoading == false && userLog.length < 10000" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click.native="loadMoreUserLogs()">
          {{ $t("admin.loadMoreLogs", $store.state.locale) }}
        </v-btn>
        <div v-if="extraLoading == true" class="extra-loading-dots"></div>
      </div>
    </div>
    <!-- \ User Logs  / -->

    <!-- / Server Logs \ -->
    <div v-if="!loading && !deleting && logFilter == 'Server Action'">
      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
        <v-data-table
          :headers="headersServer"
          :items="serverLog"
          :search="search"
          v-model="selected"
          select-all
          :items-per-page="-1"
          single-expand
          show-expand
          hide-default-footer
          dense
          class="dense-table"
          :style="{'background-color' : $themes[$store.state.companyTheme][$store.state.themeModus].card}"
          >
          <template v-slot:item="{item, isExpanded, expand , isSelected, select }">
            <td>
              <v-icon @click="select(true)" v-if="!isSelected">checkbox-blank-outline</v-icon>
              <v-icon @click="select(false)" v-if="isSelected" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">check-box-outline</v-icon>
            </td>
            <td>{{ item.time.seconds | moment("D MMM YYYY - H:mm:ss") }}u</td>
            <td>
              <v-icon v-if="item.logType == 'error'" color="red">mdi-alert-outline</v-icon>
              <v-icon v-if="item.logType == 'info'" color="grey lighten-1">mdi-information-outline</v-icon>
            </td>
            <td>{{ item.companyName }}</td>
            <td>{{ item.action }}</td>
            <td>
              <span
                v-if="item.surveyDetails"
              >{{ item.surveyDetails.clients.length }}</span>
              <span v-if="item.number">{{ item.number }}</span>
            </td>
            <td>
              <span v-if="item.surveyDetails || item.details">
                <v-btn icon @click="expand(true)" v-if="!isExpanded"><v-icon  dark>mdi-menu-down</v-icon></v-btn>
                <v-btn icon @click="expand(false)" v-if="isExpanded"><v-icon  dark>mdi-menu-up</v-icon></v-btn>
              </span>
            </td>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 0;">
              <v-card flat>
                <v-card-text v-if="item.surveyDetails">
                  <span v-for="(client, index) in item.surveyDetails.clients" :key="client">
                    <p style="margin-bottom: 0;">{{ index +1 }}. {{ client }}</p>
                  </span>
                </v-card-text>
                <v-card-text v-if="item.details">
                  <div v-html="item.details"></div>
                </v-card-text>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-card>

      <div v-if="serverLog.length >= 50" class="text-center" style="padding: 20px;">
        <v-btn v-if="extraLoading == false && serverLog.length < 10000" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click.native="loadMoreServerLogs()">
          {{ $t("admin.loadMoreLogs", $store.state.locale) }}
        </v-btn>
        <div v-if="extraLoading == true" class="extra-loading-dots"></div>
      </div>
    </div>
    <!-- \ Server Logs  / -->
  </div>
</template>

<script>
  import '@firebase/firestore';
  import db from "../firebase/init";

  export default {
    name: "log",
    data() {
      return {
        search: "",
        selected: new Array(),
        deleteCounter: 0,
        loading: true,
        extraLoading: false,
        deleting: false,
        editCompDialog: false,
        newCompDialog: false,
        activeComp: null,
        searchCompanies: "",
        activeCompName: null,
        logTypes: [
          {
            name: this.$t('admin.logUserAction', this.$store.state.locale),
            id: "User Action"
          },
          {
            name: this.$t('admin.logServerAction', this.$store.state.locale),
            id: "Server Action"
          }
        ],
        headers: [
          {
            text: this.$t('admin.logTime', this.$store.state.locale),
            align: "left",
            value: "time.seconds",
            width: "160px"
          },
          {
            text: "",
            align: "left",
            value: "logType",
            width: "40px"
          },
          {
            text: this.$t('admin.logCompany', this.$store.state.locale),
            align: "left",
            value: "company",
            width: "120px"
          },
          {
            text: this.$t('admin.user', this.$store.state.locale),
            align: "left",
            value: "user"
          },
          {
            text: this.$t('admin.logUserType', this.$store.state.locale),
            align: "left",
            value: "user-type",
            sortable: false
          },
          {
            text: this.$t('admin.applicationType', this.$store.state.locale),
            align: "left",
            value: "application-type",
            sortable: false
          },
          {
            text: this.$t('admin.action', this.$store.state.locale),
            align: "left",
            value: "action",
            sortable: false
          },
          {
            text: "",
            align: "left",
            value: "extra",
            sortable: false
          }
        ],
        logFilter: "User Action",
        userLog: new Array(),
        serverLog: new Array(),
        serverLogContinueAt: new Object(),
        userLogContinueAt: new Object(),
        pagination: {
          rowsPerPage: "20000",
          sortBy: "time.seconds",
          descending: true
        },
        headersServer: [
          {
            text: this.$t('admin.logTime', this.$store.state.locale),
            align: "left",
            value: "time.seconds"
          },
          {
            text: this.$t('admin.logCompany', this.$store.state.locale),
            align: "left",
            value: "company"
          },
          {
            text: this.$t('admin.action', this.$store.state.locale),
            align: "left",
            value: "action"
          },
          {
            text: this.$t('admin.clients', this.$store.state.locale),
            align: "left",
            value: "clients"
          },
          {
            text: "",
            align: "left",
            value: "numbers"
          },
          {
            text: "",
            align: "left",
            value: "extra"
          }
        ],
        userRoles: [
          {
            name: "Admin",
            id: "admin"
          },
          {
            name: "Super Admin",
            id: "superadmin"
          },
          {
            text: this.$t('admin.logCompany', this.$store.state.locale),
            id: "company"
          },
          {
            text: this.$t('admin.employee', this.$store.state.locale),
            id: "employee"
          }
        ],
        companies: new Array(),
        users: new Array()
      }
    },

    created() {
      this.loadUserComp();
    },

    methods: {
      async loadUserComp() {
        this.activeComp = this.userCompany
        this.companies = new Array();

        await new Promise ((resolve) => {
          if(this.$store.state.userRoleNumber < 2){
            db.collection("companies")
            .get()
            .then(snap => {
              snap.forEach(doc => {
                let compCollection = doc.data();
                compCollection.id = doc.id;
                this.companies.push(compCollection);
              });
            })
            .then(()=>{
              resolve()
            })
          }
          else {
            db.collection("companies")
              .doc(this.userCompany)
              .get()
              .then(doc => {
                let company = new Object();
                company.name = doc.data().name;
                company.id = doc.id;
                this.companies.push(company);
              })
              .then(()=>{
                resolve()
              })
            }
        })
        this.loadUsers();
        this.loadLog();
      },

      loadLog() {
        this.selected = new Array();
        this.search = "";
        this.loading = true;

        if (this.logFilter == "User Action") {
          this.userLog = new Array()   
          db.collection("log")
          .where("actionType", "==", "User Action")
          .where("company", "==", this.activeComp)
          .orderBy("time", "desc")
          .limit(100)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let logCollection = doc.data();
              logCollection.id = doc.id;
              this.userLog.push(logCollection);
            });
            this.userLogContinueAt = snap.docs[snap.docs.length - 1];
          })
          .then(() => {
            this.loading = false;
          }); 
        }
        else if (this.logFilter == "Server Action") {
          this.serverLog = new Array()
          db.collection("log")
          .where("actionType", "==", "Server Action")
          .orderBy("time", "desc")
          .limit(100)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let logCollection = doc.data();
              logCollection.id = doc.id;
              this.serverLog.push(logCollection);
            });
            this.serverLogContinueAt = snap.docs[snap.docs.length - 1];
          })
          .then(() => {
            this.loading = false;
          });
        }
      },

      deleteLog() {
        this.deleting = true;
        new Promise((resolve, reject) => {
          let counter = 0;
          this.selected.forEach(element => {
            db.collection("log")
            .doc(element.id)
            .delete()
            .then(() => {
              counter++;
              this.deleteCounter++;
              if (counter == this.selected.length) {
                resolve();
              }
            })
            .catch(error => {
              console.error("Error removing log: ", error);
              counter++;
              this.deleteCounter++;
              if (counter == this.selected.length) {
                resolve();
              }
            });
          });
        })
        .then(() => {
          this.selected = new Array();
          this.deleteCounter = 0;
          this.loadLogAfterRemoval();
          this.deleting = false;
        });
      },

      loadUsers() {
        this.users = new Array();
        if(this.$store.state.userRoleNumber < 2){
          db.collection("users")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let user = doc.data();
              this.users.push(user);
            });
          });
        } else {
          db.collection("users")
          .where("company", "==", this.activeComp)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let user = doc.data();
              this.users.push(user);
            });
          });
        }
      },

      loadMoreServerLogs() {
        this.extraLoading = true;
        db.collection("log")
        .where("actionType", "==", "Server Action")
        .orderBy("time", "desc")
        .startAfter(this.serverLogContinueAt)
        .limit(50)
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let logCollection = doc.data();
            logCollection.id = doc.id;
            this.serverLog.push(logCollection);
          });
          this.serverLogContinueAt = snap.docs[snap.docs.length - 1];
        })
        .then(() => {
          this.extraLoading = false;
        })
        .catch(error => {
          console.error(error);
        });
      },

      loadMoreUserLogs() {
        this.extraLoading = true;
        db.collection("log")
        .where("actionType", "==", "User Action")
        .orderBy("time", "desc")
        .startAfter(this.userLogContinueAt)
        .limit(100)
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let logCollection = doc.data();
            logCollection.id = doc.id;
            this.userLog.push(logCollection);
          });
          this.userLogContinueAt = snap.docs[snap.docs.length - 1];
        })
        .then(() => {
          this.extraLoading = false;
        })
        .catch(error => {
          console.error(error);
        });
      },

      loadLogAfterRemoval() {
        this.search = "";
        if (this.logFilter == "User Action") {
          this.loading = true;
          db.collection("log")
          .where("actionType", "==", "User Action")
          .orderBy("time", "desc")
          .limit(50)
          .get()
          .then(snap => {
            this.userLog = new Array();
            snap.forEach(doc => {
              let logCollection = doc.data();
              logCollection.id = doc.id;
              this.userLog.push(logCollection);
            });
            this.userLogContinueAt = snap.docs[snap.docs.length - 1];
          })
          .then(() => {
            this.loading = false;
          });
        }
        else if (this.logFilter == "Server Action") {
          this.loading = true;
          db.collection("log")
          .where("actionType", "==", "Server Action")
          .orderBy("time", "desc")
          .limit(50)
          .get()
          .then(snap => {
            this.serverLog = new Array();
            snap.forEach(doc => {
              let logCollection = doc.data();
              logCollection.id = doc.id;
              this.serverLog.push(logCollection);
            });
            this.serverLogContinueAt = snap.docs[snap.docs.length - 1];
          })
          .then(() => {
            this.loading = false;
          });
        }
      }
    },

    computed: {
      version() {
        return this.$store.state.version;
      },
      userLanguage(){
        return this.$store.state.locale ? this.$store.state.locale : 'NL';
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : 'thrive';
      },
      filteredCompanies(){
        if (this.searchCompanies && this.searchCompanies.length > 0){
          return this.companies.filter(company => {
            return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
          });
        }
        else {
          return this.companies;
        }
      }
    }
  };
</script>

<style>
.log-extra-card{
  background-color: var(--v-background-base)!important;
}

.log-extra-card,
.log-extra-card div,
.log-extra-card p{
  color: var(--v-text-base)!important;
  font-size: 12px;
}
</style>
