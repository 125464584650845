<template>
  <div> 
    <v-container grid-list-md class="pa-4 ma-0">
      <v-layout row wrap>
        <v-flex md6 xs12>
          <h1 class="tab-title">Kalender en afspraken instellingen</h1>
        </v-flex>
        <v-flex md6 xs12 >
          <div style="display: inline-block; float: right;">
            <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :dark="changed" :loading="updateCalendarSettingsBusy" :disabled="!changed" :depressed="!changed" @click="updateSettings()">
              <div v-if="changed">Wijzigingen opslaan <v-icon righy>mdi-chevron-right</v-icon></div>
              <div  v-else>Opgeslagen  <v-icon right>mdi-check</v-icon></div>  
            </v-btn>
          </div>
        </v-flex>
        <v-flex md6 xs12>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select
                :items="appointmentTimeIterations"
                v-model="activeCompData.calendarSettings.timeIteration"
                label="Tijdstappen voor afspraken"
                item-text="name"
                item-value="time"
                prepend-inner-icon="mdi-clock-outline"
                required
                outlined
                class="mt-4"
                persistent-hint
                hint="Stappen in de afspraakwidget"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-select>
              <v-switch
                class="ma-0 my-4 pa-0"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                label="Aansluitend plannen"
                v-model="activeCompData.calendarSettings.appointmentJoining"
                persistent-hint
                hint="Tijden weergeven die aansluiten op geplande behandelingen"
              ></v-switch>
              <v-select
                :items="calendarTimeIterations"
                v-model="activeCompData.calendarSettings.timeIterationCalendar"
                label="Tijdstappen voor kalenderweergave"
                item-text="name"
                item-value="time"
                prepend-inner-icon="mdi-clock-outline"
                required
                outlined
                class="my-4"
                hide-details
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-select>
              <v-select
                :items="cancelTimeLimitItems"
                v-model="activeCompData.calendarSettings.cancelTimeLimit"
                label="Minimale tijd tussen annuleren en start behandeling"
                item-text="name"
                item-value="value"
                prepend-inner-icon="mdi-cancel"
                required
                outlined
                class="mt-4"
                hide-details
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md6 xs12>

        </v-flex>
      </v-layout>
    </v-container>

  </div>
</template>


<script>
import '@firebase/firestore';
import db from "../firebase/init";
import { bus } from "../main";

export default {
  props: {
    activeCompData: Object,
    activeCompDataOriginal: Object,
  },

  name: "calendar-settings",
  data: () => ({
    cancelTimeLimitItems: [
      {
        name: "Geen",
        value: 0
      },
      {
        name: "12 uur",
        value: 12
      },
      {
        name: "24 uur",
        value: 24
      },
      {
        name: "36 uur",
        value: 36
      },
      {
        name: "48 uur",
        value: 48
      },
    ],
    appointmentTimeIterations: [
      {
        name: "15 minuten",
        time: 15
      },
      {
        name: "30 minuten",
        time: 30
      },
      {
        name: "45 minuten",
        time: 45
      },
      {
        name: "60 minuten",
        time: 60
      },
    ],
    calendarTimeIterations: [
      {
        name: "5 minuten",
        time: "00:05:00"
      },
      {
        name: "10 minuten",
        time: "00:10:00"
      },
      {
        name: "15 minuten",
        time: "00:15:00"
      },
      {
        name: "30 minuten",
        time: "00:30:00"
      },
    ],
    updateCalendarSettingsBusy: false,
  }),

  created() {},

  methods: {

    async updateSettings() {
      this.updateCalendarSettingsBusy = true;

      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "updatingCalendarSettingsCompany",
        actionItem: this.activeCompData.name,
        extra:[
          {
            name: "originalData",
            value: ""
          },
          {
            name: "name",
            value:  JSON.stringify(this.activeCompDataOriginal.calendarSettings)
          },
          {
            name: "changedData",
            value: ""
          },
          {
            name: "widgetSettings",
            value:  JSON.stringify(this.activeCompData.calendarSettings)
          },
        ]
      };
      try{
        await db.collection("companies")
        .doc(this.activeCompData.id)
        .set({
          calendarSettings: this.activeCompData.calendarSettings,
        }, { merge: true })

        console.log("Calendar Settings succesfully updated");
        this.activeCompDataOriginal.calendarSettings = _.cloneDeep(this.activeCompData.calendarSettings)
      }
      catch (error) {
        console.error("Error updating Calendar Settings: ", error);
        logData.extra.push({ name: "error", value: JSON.stringify(error) })
        await this.$createNewLog("error", logData);
      }

      this.updateCalendarSettingsBusy = false;
    },
 
  },

  computed: {
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },
    changed(){
      if( _.isEqual(this.activeCompData.calendarSettings, this.activeCompDataOriginal.calendarSettings) == false){
        return true
      } else{
        return false
      }
    },
  }
};
</script>

