<template>
  <div>
    <div>    
      <div v-if="!loading">
        <div class="no-data-message text-center" v-if="treatmentTypes.length == 0">
          <v-avatar
            size="58"
            light
            class="ma-0 mr-2 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
          </v-avatar>
          <p>{{ $t("admin.noTypesAddedYet", $store.state.locale) }}</p>
          <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addTreatmentType()">
            <v-icon dark>mdi-plus</v-icon>{{ $t("admin.newType", $store.state.locale) }}
          </v-btn>
        </div>

        <v-card v-else flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
          <v-container grid-list-md class=" ma-0 pa-3" fluid>
            <v-layout row wrap>
              <v-flex md4 xs12>
                <v-text-field
                  class="mx-1"
                  dense
                  v-model="search"
                  name="searchfield"
                  :label="$t('general.search', this.$store.state.locale)"
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                  outlined
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex md8 xs12 class="text-right">
                <v-btn class="px-2" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addTreatmentType()">
                  <v-icon left>mdi-plus</v-icon> {{ $t("admin.newType", $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="treatmentTypes"
            :search="search"
            :itemsPerPage.sync="pages"
            hide-default-footer
            item-key="name"
            dense
            :no-results-text="`Geen resultaten gevonden voor: ${search}`"
            :style="{'background-color' : $themes[$store.state.companyTheme][$store.state.themeModus].card}"
            class="dense-table"
            >
            <template v-slot:item="{item }">
              <tr>
                <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) }}</td>
                <td>
                  <v-btn icon @click="editTreatmentType(item)"> 
                    <v-icon small color="text">mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>

      <div v-if="loading">
        <div style="position: relative; padding: 200px 0;">
          <div class="thrive-loader"></div>
        </div>
      </div>
    </div>
    
    <!-- / TreatmentType Dialog \ -->
    <treatmenttype-form
      @editTreatmentTypeDialogChange="editTreatmentTypeDialog = $event"
      @updateData="loadTreatmentTypes()"
    ></treatmenttype-form>
    <!-- \ TreatmentType Dialog / -->
  </div>
</template>
     


<script>
  import { bus } from "../main";
  import '@firebase/firestore';
  import db from "@/firebase/init";
  import TreatmentTypeForm from "@/components/modals/TreatmentTypeForm.vue";

  export default {
    components: {
      "treatmenttype-form": TreatmentTypeForm
    },
    name: "TreatmentTypes",
    data() {
      return {
        search: "",
        loading: true,
        treatmentTypes: [],
        pages: -1,
        headers: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "name"
          },
          {
            text: "",
            align: "right",
            value: "edit",
            width: "60px"
          }
        ]
      }
    },

    created() {
      this.loadTreatmentTypes();
    },

    methods: {
      addTreatmentType() {
        bus.$emit("TreatmentTypeModal", null, "new");
      },

      editTreatmentType(item) {
        bus.$emit("TreatmentTypeModal", item, "edit");
      },

      loadTreatmentTypes() {
        this.loading = true;
        db.collection("treatmentTypes")
        .get()
        .then(snap => {
          this.treatmentTypes = new Array();
          snap.forEach(doc => {
            let treatmentType = doc.data();
            treatmentType.id = doc.id;
            this.treatmentTypes.push(treatmentType);
          });
        })
        .then(()=>{
          this.treatmentTypes.sort((a, b) => a.name[(this.$store.state.locale).toLowerCase()].localeCompare(b.name[(this.$store.state.locale).toLowerCase()]));
          this.loading = false;
        })
        .catch(error =>{
          console.error("Error bij binnenhalen treatmentTypes: ", error);
          this.loading = false;
        });
      }
    }
  };
</script>
