<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type =='new'" >{{ $t('admin.addNewDeviceType', $store.state.locale)}}</h3>
        <h3 v-if="type =='edit'" >{{ $t('admin.editDeviceType', $store.state.locale)}}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDeviceDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>
      
      <v-card-text>
        <v-text-field
          class="mt-4"
          :label="$t('general.name', $store.state.locale) + ` (${getLanguageName('nl').languageName[$store.state.locale.toLowerCase()]})`"
          v-model="deviceType.name.nl"
          outlined
          required
          dense
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          >
          <template v-slot:prepend-inner >
            <country-flag style="margin: -9px -5px 0 -10px" country="nl" size="small"/>
          </template>
        </v-text-field>

        <v-text-field
          class="mt-4"
          :label="$t('general.name', $store.state.locale) + ` (${getLanguageName('en').languageName[$store.state.locale.toLowerCase()]})`"
          v-model="deviceType.name.en"
          outlined
          required
          dense
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          >
          <template v-slot:prepend-inner >
            <country-flag style="margin: -9px -5px 0 -10px" country="gb" size="small"/>
          </template>
        </v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeDeviceDialog()"
                >
                {{ $t('general.cancel', $store.state.locale)}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type =='edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateDevice()"
                >
                <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right>mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>  
              </v-btn>
              <v-btn
              v-if="type =='new'"
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addDevice()"
                >
                {{ $t('admin.addDeviceType', $store.state.locale)}}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
  import { bus } from "../../main";
  import db from "../../firebase/init";
  import '@firebase/firestore';

  export default {
    name: "device-form",

    data() {
      return {
        feedback: null,
        showDialog: false,
        type: 'new',
        deviceType: { name: new Object() },
        deviceTypeOriginal: new Object()
      };
    },

    created() {
      bus.$on("DeviceType", (item, type) => {
        this.type = type;
        if(type == "edit"){
          this.deviceType = _.cloneDeep(item); 
          this.deviceTypeOriginal = _.cloneDeep(this.deviceType);
        }
        else{
          this.type = 'new'
          this.resetDeviceType();
        }
        this.showDialog = true;
      });
    },

    methods: {
      closeDeviceDialog() {
        this.showDialog = false;
        this.resetDeviceType()
      },

      resetDeviceType(){
        this.feedback = null;
        this.deviceType = { name: new Object() };
      },

      getLanguageName(item){
        return this.$countries.find(language => { return language.id == item });
      },

      updateDevice() {
        db.collection("deviceTypes")
        .doc(this.deviceType.id)
        .update({
          name: this.deviceType.name
        })
        .then(() => {
          this.$emit("updateData");
          this.closeDeviceDialog();
        })
        .catch(error => {
          console.error("Error updating DeviceType: ", error);
        });
      },

      addDevice() {
        if (this.deviceType.name) {
          db.collection("deviceTypes")
          .add({
            name: this.deviceType.name,
          })
          .then(() => {
            this.$emit("updateData");
            this.closeDeviceDialog();
          })
          .catch(error => {
            console.error("Error adding DeviceType: ", error);
          });
        }
        else {
          this.feedback = "Vul alle velden in om een nieuw type apparaat toe te voegen.";
        }
      }
    },
    computed: {
      changed() {
        return _.isEqual(this.deviceType, this.deviceTypeOriginal) == false ? true : false;
      },
    }
  };
</script>