<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>{{selectedContentType}} toevoegen</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeAddNewsDialog()">
        <v-icon dark color="white">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert
      class="ma-0 pa-2"
      style="border-radius: 0"
      v-if="feedback"
      :value="true"
      type="warning">
      {{ feedback }} 
    </v-alert>

    <v-divider></v-divider>

    <v-card-text>
      <v-select
        class="mt-4"
        hide-details
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
        v-model="addNewsForm.chooseType"
        :items="chooseType"
        label="Bericht categorie"
        item-text="name"
        item-value="id"
        required
      ></v-select>

      <v-text-field 
        class="mt-4"
        hide-details
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
        v-model="addNewsForm.title" 
        label="Titel" 
        :rules="requiredRules" 
        required
        :readonly="update"
        :disable="update"
      ></v-text-field>

      <v-text-field
        class="mt-4"
        hide-details
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
        v-if="addNewsForm.chooseType == 'update'"
        label="Versienummer"
        v-model="addNewsForm.version"
        :rules="requiredRules"
        required
        v-on:input="createTitle()"
      ></v-text-field>

      <v-container v-if="addNewsForm.chooseType == 'update'" grid-list-md fluid class="pa-1" style="margin: 15px 0;" :style="{background: $vuetify.theme.themes[theme].background}">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex md6>
            <span style="font-size: 16px; font-weight: bold;">Versienummering Policy</span>
          </v-flex>
          <v-flex md6>
            <span style="font-size: 16px; font-weight: bold;">Laatste versies Thrive</span>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex md6 style="font-size: 12px;">
            <span style="font-size: 14px; font-weight: bold;">Major</span> - <span style="font-size: 14px;"><strong>1</strong>.2.5</span><br>
            Ingrijpende aanpassing zoals:<br>
            -Toevoeging van nieuwere en betere API's<br>
            -Afschrijving van verschillende functies<br><br>
            <span style="font-size: 14px; font-weight: bold;">Minor</span> - <span style="font-size: 14px;">1.<strong>2</strong>.5</span><br>
            Kleinere aanpassing zoals:<br>
            -Release van nieuwe functionaliteit(en)<br><br>
            <span style="font-size: 14px; font-weight: bold;">Release</span> - <span style="font-size: 14px;">1.2.<strong>5</strong></span><br>
            Aanpassing zoals:<br>
            -Kleine aanpassingen of verbeteringen<br>
            -Bugfixes
          </v-flex>

          <v-flex md6 style="font-size: 11px;">
            <div v-for="(version, index) in versions" :key="index" class="software-version">
              <span class="software-version-number">{{version.version}}</span>
              <span class="software-version-date">{{version.date}}</span>
              <span class="software-version-author">{{version.author}}</span>
            </div>
          </v-flex>
        </v-layout>
      </v-container>

      <v-textarea
        rows="3"
        counter="150"
        auto-grow
        v-model="addNewsForm.intro"
        label="Intro"
        :rules="requiredRules"
        required
        class="my-4"
        hide-details
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
      ></v-textarea>

      <div v-if="addNewsForm.chooseType != 'update'" class="upload-news-image-wrapper clear mb-4">
        <div class="upload-image">
          <p class="ma-0">Upload afbeelding</p>
          <div v-if="addNewsForm.imageUrl" class="logo-upload-preview">
            <v-img 
              :src="addNewsForm.imageUrl"
              aspect-ratio="1"
              class="grey lighten-2"
              >
              <template slot:placeholder>
                <v-layout
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                </v-layout>
              </template>
            </v-img>
            <div v-if="uploading == true" class="upload-bar">
              <p>{{ uploadProgress }} %</p>
              <div class="upload-bar-inner" style="background-color:#00adef;"></div>
            </div>
          </div>
          <v-btn class="ma-0" raised :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="onFileSelect">Kies afbeelding</v-btn>
          <input
            type="file"
            style="display: none;"
            ref="fileInput"
            accept="image/*"
            @change="onFilePicked">
        </div>
      </div>

      <div style="max-width: 100%; overflow: hidden;">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <label>
              Nieuwsbericht
              <v-icon v-on="on" small right>mdi-help-circle</v-icon>
            </label>
          </template>
          <span style="color: #fff;">Omschrijving van Nieuwsbericht voor gebruikers.</span>
        </v-tooltip>
        <div v-if="editorMode == 'wysiwyg'" class="custom-tiptap-wrapper" :class="theme">
          <tiptap-vuetify
            v-model="addNewsForm.description"
            :toolbar-attributes="{ color: 'menu' }"
            :card-props="{ flat: true, color: 'background' }"
            :extensions="extensions"
          />
        </div>
        <div v-else>
          <v-textarea
            v-model="addNewsForm.description"
            background-color="background"
            outlined
            rows="6"
            hide-details
          ></v-textarea>
        </div>
        <div class="text-center mt-2">
          <a @click="editorMode == 'wysiwyg' ? editorMode = 'html' : editorMode = 'wysiwyg'">{{ editorMode == "wysiwyg" ? "Bekijk source" : "Bekijk wysiwyg" }}</a>
        </div>
      </div>

      <div v-if="addNewsForm.chooseType == 'update'" style="margin: 0 0 15px 0; max-width: 100%; overflow: hidden;">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <label class="custom-label">
              Interne opmerkingen
              <v-icon v-on="on" small right>mdi-help-circle</v-icon>
            </label>
          </template>
          <span style="color: #fff;">Extra informatie of uitleg voor intern gebruik.</span>
        </v-tooltip>
        <div v-if="editorMode == 'wysiwyg'" class="custom-tiptap-wrapper" :class="theme">
          <tiptap-vuetify
            v-model="addNewsForm.extra"
            :toolbar-attributes="{ color: 'menu' }"
            :card-props="{ flat: true, color: 'background' }"
            :extensions="extensions"
          />
        </div>
        <div v-else>
          <v-textarea
            v-model="addNewsForm.extra"
            background-color="background"
            outlined
            rows="6"
            hide-details
          ></v-textarea>
        </div>
        <div class="text-center mt-2">
          <a @click="editorMode == 'wysiwyg' ? editorMode = 'html' : editorMode = 'wysiwyg'">{{ editorMode == "wysiwyg" ? "Bekijk source" : "Bekijk wysiwyg" }}</a>
        </div>
      </div>

      <v-text-field
        v-if="addNewsForm.chooseType == 'update'"
        style="margin: 0 0 15px 0;"
        label="Auteur Update"
        v-model="addNewsForm.updateAuthor"
        :rules="requiredRules"
        hint="Wie heeft de update gedaan?"
        persistent-hint
        required
        class="mt-4"
        hide-details
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
      ></v-text-field>

      <v-text-field
        style="margin: 15px 0;"
        type="number"
        label="Aantal fictieve likes"
        v-model.number="addNewsForm.fictionalLikes"
        v-on:input="createFictionalLikes()"
        hint="Fictieve likes toevoegen om bericht imposanter te doen liken."
        persistent-hint
        class="mt-4"
        hide-details
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
      ></v-text-field>

      <v-switch
        v-model="addNewsForm.published"
        class="pa-0"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        >
        <template v-slot:label>
          <span style="margin-right: 5px;">Publiceren</span>
        </template>
      </v-switch>   


    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
              @click="closeAddNewsDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md8 class="text-right">
            <v-btn
              dark
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              @click="addNews()"
              >
              {{selectedContentType}} toevoegen
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>

<script>
import _ from 'lodash'
import db from "../../firebase/init";
import firebase from "firebase/app";
import "firebase/storage";
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import platform from 'platform'; 

export default {
  name: "add-news-form",
  components: { 
    TiptapVuetify,
  },
  props: {
    versions: Array
  },
  data: function() {
    return {
      editorMode: 'wysiwyg',
      addNewsForm: {
        title: "",
        intro: "",
        description: "",
        fictionalLikes: 0,
        extra: "",
        chooseType: "news",
        versions: null,
        views: [],
        updateAuthor: null,
        published: true,
        imageUrl: null,
        likes: [],
      },
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3, 4]
          }
        }],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      valid: true,
      filename: null,
      image: null,
      chooseType: [
        {
          name: "Nieuwsbericht",
          id: "news"
        },
        {
          name: "Tip",
          id: "tip"
        },
        {
          name: "Actie",
          id: "action"
        },
        {
          name: "Succesverhaal",
          id: "casestudie"
        },
        {
          name: "Software update",
          id: "update"
        }
      ],
      requiredRules: [v => !!v || "Dit veld is verplicht"],
      feedback: null,
      uploading: false,
      uploadProgress: null,
      file: {}
    };
  },

  created() {},

  methods: {
    closeAddNewsDialog() {
      // Closing the add news dialog and resetting the values.
      this.$emit("addNewsDialogChange", false);
      this.addNewsForm = {
        title: null,
        type: "news",
        version: null,
        intro: null,
        fictionalLikes: 0,
        description: "",
        published: true,
        updateAuthor: null,
        imageUrl: null,
        extra: "",
        views: [],
        likes: []
      }
      this.feedback = null
    },

    createTitle() {
      // Generating the title if the (news) type software update is.
      this.addNewsForm.title = "Thrive versie " + this.addNewsForm.version;
    },

    onFileSelect() {
      // Opening selection folder in the browser.
      this.$refs.fileInput.click();
    },
   
    onFilePicked(event) {
      // Selecting picture.
      const files = event.target.files;
      this.file = files[0];
      let filename = files[0].name;
      this.filename = filename;
      console.log("files: " + filename);
      if (filename.lastIndexOf(".") <= 0) {
        this.feedback = "Voeg een geldig bestand toe.";
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.addNewsForm.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      this.feedback = null;
    },

    createFictionalLikes(){
      // Creating fictional likes.
      this.addNewsForm.likes = _.times(this.addNewsForm.fictionalLikes , _.constant("fictionalLike"))
    },

    addNews() {
      this.feedback = null
      if (this.addNewsForm.chooseType == "update") {
        // type is software update. Picture will is pre-fixed. 
        if (
          this.addNewsForm.version &&
          this.addNewsForm.intro &&
          this.addNewsForm.description &&
          this.addNewsForm.updateAuthor){
          db.collection("news").add({
            title: this.addNewsForm.title,
            intro: this.addNewsForm.intro,
            description: this.addNewsForm.description,
            version: this.addNewsForm.version,
            published: this.addNewsForm.published,
            extra: this.addNewsForm.extra,
            views: [],
            dateAdded: new Date(),
            chooseType: this.addNewsForm.chooseType,
            updateAuthor: this.addNewsForm.updateAuthor,
            likes: this.addNewsForm.likes,
            imageUrl: "https://firebasestorage.googleapis.com/v0/b/bookingapp-hogans.appspot.com/o/code-bg-thrive.png?alt=media&token=d566688e-a319-4bd5-a82f-ff2e9b606272",
            fictionalLikes: Number(this.addNewsForm.fictionalLikes),
            user: this.userFirstName + " " + this.userLastName,
          })
          .then(() => {
            this.$emit("updateData");
            this.closeAddNewsDialog();
            console.log(this.selectedContentType + " succesfully added");
          })
        } else {
          this.feedback = "Vul alle velden in om een software update toe te voegen."
        }
      } 
      else {
        // type isn't software update. Selecting a picture is obligated.
        if(this.addNewsForm.imageUrl ){
          if(this.addNewsForm.description && this.addNewsForm.intro && this.addNewsForm.title) {
            db.collection("news").add({
              title: this.addNewsForm.title,
              intro: this.addNewsForm.intro,
              description: this.addNewsForm.description,
              extra: this.addNewsForm.extra,
              published: this.addNewsForm.published,
              chooseType: this.addNewsForm.chooseType,
              likes: this.addNewsForm.likes,
              views: [], 
              fictionalLikes: Number(this.addNewsForm.fictionalLikes),
              dateAdded: new Date(),
              user: this.userFirstName + " " + this.userLastName,
            })
            .then(async(docRef) => {
              if(this.filename) {
                await this.uploadNewsImage(docRef.id);
              }
            })
            .then(() => {
              this.$emit("updateData");
              this.closeAddNewsDialog();
              console.log("news succesfully added");
            })
          } else {
            this.feedback = "Vul alle velden in om een " + this.selectedContentType + " toe te voegen."
          }
        } 
        else {
          this.feedback = "Voeg een afbeelding toe."
        }
      }
    },

    uploadNewsImage(docId) {
      // Uploading Image.
      return new Promise((resolve, reject) => {
        console.log("start storen");
        const file = this.file;
        const filename = this.filename;
        const ext = filename.slice(filename.lastIndexOf("."));
        const fileUrl =  "newsimages/" + docId + ext;
        this.uploading = true;
        let uploadTask = firebase
          .storage()
          .ref(fileUrl)
          .put(file);

        uploadTask.on(
          "state_changed",
          taskSnapshot => {
            var progress = Math.ceil((taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100);
            this.uploadProgress = progress;
          },
          error => {
            console.error(error);
            reject();
          },
          () => {
            console.log("The upload is compleet");
            uploadTask.snapshot.ref
            .getDownloadURL()
            .then(url => {
              db.collection("news")
                .doc(docId)
                .update({
                  imageUrl: url
                })
              .then(() => {
                resolve();
                this.uploading = false;
                this.uploadProgress = null;
              })
              .catch(error => {
                console.error(error)
                reject();
                this.uploading = false;
                this.uploadProgress = null;
              });
            })
            .catch(error => {
              console.error(error)
              reject();
              this.uploading = false;
              this.uploadProgress = null;
            });
          }
        );
      });
    },

  },

  computed: {
    selectedContentType(){
      // Name of the selected content type.
      let contentType
      this.chooseType.map(type => {
        if (type.id == this.addNewsForm.chooseType) {
          contentType = type.name;
        }
      });
      return contentType
    },

    update(){
      return this.addNewsForm.chooseType == 'update' ? true : false; // This is getting used for the disable prop in the title.
    },
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
  }
};
</script>

<style>
.software-version-number{
  width: 35px;
  display: inline-block;
  margin: 4px 4px 4px 0;
  font-size: 14px;
  font-weight: bold;
}

.logo-upload-preview {
  position: relative;
  width: 150px;
  height: 150px;
  float: left;
  margin: 0 15px 0 0;
  overflow: hidden;
  background-color: #f6f7fb;
}

.upload-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: rgba(150, 150, 150, 0.8);
}

.upload-bar p {
  z-index: 99;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  line-height: 20px;
  width: 100%;
  height: 20px;
  color: #fff;
  font-weight: 600;
}

.upload-bar-inner {
  z-index: 9;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20px;
}


.software-version-date{
  display: inline-block;
  font-size: 12px;
  width: 120px;
}
.software-version-author{
  display: inline-block;
  font-size: 12px;
  width: 110px;
}
</style>