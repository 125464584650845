<template>
  <div>

    <v-container grid-list-md class=" ma-0 pa-0" fluid>
      <v-layout row wrap class="py-0">
        <v-flex xs12 class="text-right py-0">
          <v-btn small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :dark="changed" :loading="updateUserRulesSettingsBusy" :disabled="!changed" :depressed="!changed" @click="updateSettings()">
            <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale)}} <v-icon righy>mdi-chevron-right</v-icon></div>
            <div  v-else>{{ $t('general.saved', $store.state.locale)}}<v-icon small right>mdi-check</v-icon></div>  
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container grid-list-md class="pa-0 pt-4 ma-0">
      <v-layout row wrap>
        <v-flex md6 xs12 class="pr-2">
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
            <div class="pa-3">
							<v-avatar
                size="36"
                light
                style="float: left"
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-book-edit</v-icon>
              </v-avatar>
							<h3 class="pt-1 pb-4"> Uitgaven regels </h3>
              <p class="ma-0 mb-2">Grootboekrekeningschema voor uitgaven</p>
							<v-divider></v-divider>

              <v-container grid-list-md class="pa-0 ma=0">
                <v-row>
                  <v-col sm="6" v-for="(expense, index) in accountancyObj.accountancyNumbersExpense" :key="index">
                    <v-text-field
                      class="mt-4"
                      v-model.number="expense.ledgerNumber"
                      :label="$t(`accountancyNumbersExpense.${expense.id}`, $store.state.locale)"
                      :placeholder="expense.ledgerNumber === null ? 'Voer een nummer in' : ''" 
                      hint
                      dense
                      type="number"
                      outlined
                      hide-details
                      required
                      :rules="ledgerRules"
                      @change="ledgerRulesValid(expense.ledgerNumber)"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </v-col>
                </v-row>      
              </v-container>
            </div>
          </v-card>

        </v-flex>
        <v-flex md6 xs12 class="pl-2">
        </v-flex>
      </v-layout>
    </v-container>
  </div>
  
</template>


<script>
import '@firebase/firestore';
import db from "../firebase/init";
import { bus } from "../main";
import { accountancyLedgers } from "../modules/ledgers";


export default {
  props: {
    activeCompData: Object,
    activeCompDataOriginal: Object,
    typeCompanyOrBranch: String
  },
 

  name: "accountancy-settings-company",
  data(){
    return{

      valid: true,
      ledgerRules: [v => !!v || 'Voer een grootboeknummer in'],

      
      accountancyObjOriginal: new Object(),
      
      useBranch: false, //For Branch Settings
      showLedgers: false,
      saveChanges: true,
      updateUserRulesSettingsBusy: false,
      accountancyObj: {

        accountancyNumbersExpense: [
        {id: "other", ledgerNumber:null},
        {id: "canteenExpense", ledgerNumber:null},
        {id: "cleaningSupplies", ledgerNumber:null},
        {id: "lecture", ledgerNumber:null},
        {id: "officeExpense", ledgerNumber:null},
        {id: "stamps", ledgerNumber:null},
        {id: "interiorCost", ledgerNumber:null},
        {id: "advertisementCost", ledgerNumber:null},
        {id: "mealAllowance", ledgerNumber:null},
        {id: "travelReimbursement", ledgerNumber:null},
        {id: "parkingFee", ledgerNumber:null},
        {id: "otherPersonnelExpense", ledgerNumber:null},
        {id: "privateWithdrawel", ledgerNumber:null},
        {id: "depositToBank", ledgerNumber:null},
        {id: "cashDifference", ledgerNumber:null},
        ],
        accountancyNumbersSales: [
        {id: "treatmentsHighRate", ledgerNumber:null},
        {id: "treatmentsLowRate", ledgerNumber:null},
        {id: "treatmentsZeroRate", ledgerNumber:null},
        {id: "productsHighRate", ledgerNumber:null},
        {id: "productsLowRate", ledgerNumber:null},
        {id: "productsZeroRate", ledgerNumber:null},
        {id: "withoutCounterpartLowRate", ledgerNumber:null},
        {id: "withoutZeroRate", ledgerNumber:null},
        {id: "directDebit", ledgerNumber:null},
        {id: "sellPrepaidCredit", ledgerNumber:null},
        {id: "usePrepaidCredit", ledgerNumber:null},
        {id: "expiredPrepaidCredit", ledgerNumber:null},
        ],
        accountancyNumbersPayments: [
        {id: "cashShortfall", ledgerNumber:null},
        {id: "cashSurplus", ledgerNumber:null},
        {id: "depositToCash", ledgerNumber:null},
        {id: "depositToBank", ledgerNumber:null},
        ],
        accountancyNumbersCrossReferences: [
        {id: "sellPrepaidCreditDelayed", ledgerNumber:null},
        {id: "usePrepaidCreditDelayed", ledgerNumber:null},
        {id: "expiredPrepaidCreditDelayed", ledgerNumber:null},
        {id: "sellGiftCards", ledgerNumber:null},
        {id: "redeemGiftCards", ledgerNumber:null},
        {id: "notPayed", ledgerNumber:null},
        {id: "paidOutstandingBills", ledgerNumber:null},
        ],

      }
    }
  },

  mounted() {
    console.log("typeCompanyOrBranch", this.typeCompanyOrBranch);
    //TODO BRANCH APPARTE SETUP
    if(this.typeCompanyOrBranch === "company" || this.typeCompanyOrBranch === "branch") {

      this.accountancyObj = accountancyLedgers();
      this.downloadLedgerRules();
      this.showLedgers = true;
    }
    
  },

  methods: {

    ledgerRulesValid(ledgerNumber) { 

      if(typeof ledgerNumber == "number") {
        this.valid = true;
      } else {
        this.valid = false;
      }
    
    },

    checkDataUserRules(){
      if(!this.activeCompData.userBranchRules){
        this.activeCompData.userBranchRules = _.cloneDeep(this.userRules)
      }
    },

    downloadLedgerRules() {

      //TODO MET BRANCH VARIATIE

      db.collection("companies")
        .doc(this.userCompany)
        .collection("accountancySettings")
        .doc(this.userCompany)
        .get()
        .then(doc => {
         
         
         if(!doc.exists) {
           //Not Found Create one!
           console.log("No Ledgers found for:", this.userCompany)
         
           db.collection("companies")
          .doc(this.userCompany)
          .collection("accountancySettings")
          .doc(this.userCompany)
          .set(this.accountancyObj)
          .then(() => {"Created a new Accountancy Doc"})
          .catch(err => {
            console.error("Can't create a new Accountancy Doc", err);
          })

         } else{

           let accountancyFirebase = doc.data();
            
            console.log("accountancyFirebase",doc.data() )
           //Update Doc with new Ledger Rules and Merge with Doc

          //   for (const key in this.accountancyObj) {
          //     this.accountancyObj[key].forEach((ledger) => {
          //       //Key exist in Database?
          //       if(accountancyFirebase[key]){
          //         let findLedger = accountancyFirebase[key].find(accountancyFB => accountancyFB.id === ledger.id);
          //         if(!findLedger) {
          //             accountancyFirebase[key].push(ledger);
          //         }
          //       } else {
          //         //Create new Array with Objects
          //         accountancyFirebase[key] = this.accountancyObj[key];
          //       }
                
          //     })
          //   }

          // //Merge customised numbers
          // this.accountancyObj = _.merge(this.accountancyObj,accountancyFirebase);
          // this.accountancyObjOriginal = _.cloneDeep(this.accountancyObj);


          /* A loop that iterates through the array `database` and `accountancyOBJ` and assigns the ledgerNumber
          from `database` to `accountancyOBJ` if the id is the same and the ledgerNumber is null. */

          //Loop Thru Accountancy Types
          for (const type in accountancyFirebase) {

            for (let index = 0; index < accountancyFirebase[type].length; index++) {

                for (let indexCoded = 0; indexCoded < this.accountancyOBJ[type].length; indexCoded++) {
                
                  //Match and Merge
                  if(this.accountancyOBJ[type][indexCoded].id === accountancyFirebase[type][index].id && this.accountancyOBJ[type][indexCoded].ledgerNumber === null) { 
                      
                      this.accountancyOBJ[type][indexCoded].ledgerNumber = accountancyFirebase[type][index].ledgerNumber; 
                  }
                
                }
                
            }

}


          console.log("Last Output: ", this.accountancyObj)

         }

        })
               
        .catch(err => {
          console.error(err);
        })

    },

    // downloadLedgerRules() {
    //   db.collection("companies")
    //     .doc(this.userCompany)
    //     .collection("accountancySettings")
    //     .doc(this.activeBranch)
    //     .get()
    //     .then((doc) => {
    //       if (doc.exists) {
    //         this.expenses.typeExpenses = doc.data().expensesNumbers;
    //         console.log("Ledger Numbers for Branch Found.");
    //       } else {
    //         // doc.data() will be undefined in this case
    //         console.log("No ledger numbers for Branch Found");

    //         db.collection("companies")
    //           .doc(this.userCompany)
    //           .collection("accountancySettings")
    //           .doc(this.userCompany)
    //           .get()
    //           .then((doc) => {
    //             this.expenses.typeExpenses = doc.data().expensesNumbers;

    //             console.log(doc.data().expensesNumbers);
    //             console.log("Ledger Numbers for Company Found");
    //           })
    //           .catch((err) => {
    //             console.error("Company Ledger:", err);
    //           });
    //       }
    //     })

    //     .catch((err) => {
    //       console.error("Branch Ledger:", err);
    //     });
    // },

    updateSettings() {
      this.updateUserRulesSettingsBusy = true;

      db.collection("companies")
        .doc(this.userCompany)
        .collection("accountancySettings")
        .doc(this.userCompany)
        .set(this.accountancyObj, { merge: true })
        .then(() => {
          console.log("Accountancy updated")
          this.updateUserRulesSettingsBusy = false;
              this.accountancyObj = accountancyLedgers();
              this.downloadLedgerRules();
        })
        .catch(error => {
          console.error("Error Accountancy update: ", error);
          
        });
    },
 
  },

  computed: {

    

    // changed(){
    //   if( _.isEqual(this.accountancyObj, this.accountancyObjOriginal) == false){
    //    return true;
    //   } else{
    //    return false;
    //   }
    // },
    changed() {
			// if (this.branchInfoChanged || this.branchTimesChanged) {
			// return true;
			// } else {
			// return false;
			// }
			return false;
		},
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
    
  }
};
</script>

