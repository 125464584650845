<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h3>{{ $t('devices.editDevice', $store.state.locale) }}</h3>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeEditDeviceDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text class="ma-0">
      <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="$store.state.activeUserRoleNumber < 2">
        <v-btn class="ma-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="color: #ffffff; text-transform: none;"><strong>{{selectedDevice.id}}</strong></v-btn>
        <div style="display: inline-block" v-if="selectedDeviceIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(selectedDevice.id)"><v-icon small>mdi-content-copy</v-icon> Kopieër DocId</div>
        <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
      </v-card>

      <v-form ref="EditDeviceForm" lazy-validation>
        <v-text-field 
          :label="$t('general.name', $store.state.locale)" 
          v-model.trim="selectedDevice.name"
          autocomplete 
          required
          dense
          outlined
          class="mt-4"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
          hide-details
        ></v-text-field>
        <v-select
          clearable
          chips
          dense
          deletable-chips
          small-chips
          :items="deviceTypes"
          item-value="id"
          :item-text="(item) => $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) "
          :label="$t('devices.deviceType', $store.state.locale)" 
          v-model="selectedDevice.deviceTypes"
          multiple
          outlined
          class="mt-4"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
          hide-details
        ></v-select>
        <v-select
          clearable
          :items="rooms"
          item-text="name"
          item-value="id"
          :label="$t('general.room', $store.state.locale)" 
          v-model="selectedDevice.rooms"
          outlined
          multiple
          dense
          chips
          deletable-chips
          small-chips
          class="mt-4"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          hide-details
        ></v-select>

        <v-select
          :items="$store.state.userBranches"
          item-text="name"
          item-value="id"
          item-disabled="inactive"
          v-model="selectedDevice.branches[0]"
          :label="$t('devices.selectBranch', $store.state.locale)" 
          outlined
          dense
          class="mt-4"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          hide-details
        ></v-select>

        <v-card class="mt-4" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
          <v-container class="pa-4 ma-0 ">
            <h3>{{ $t('devices.defaultDays', $store.state.locale) }}</h3>
            <v-layout row wrap class="pa-0 ma-0" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">
              <v-flex xs3 class="py-2"><strong>{{ $t('general.day', $store.state.locale) }}</strong></v-flex>
              <v-flex xs9 class="py-2"><strong>{{ $t('general.available', $store.state.locale) }}</strong></v-flex>
              <v-flex xs3>{{ $t('general.sunday', $store.state.locale) }}</v-flex>
              <v-flex xs9>
                <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedDevice.defaultDays.Sun"></v-switch>
              </v-flex>
              <v-flex xs3>{{ $t('general.monday', $store.state.locale) }}</v-flex>
              <v-flex xs9>
                <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedDevice.defaultDays.Mon"></v-switch>
              </v-flex>
              <v-flex xs3>{{ $t('general.tuesday', $store.state.locale) }}</v-flex>
              <v-flex xs9>
                <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedDevice.defaultDays.Tue"></v-switch>
              </v-flex>
              <v-flex xs3>{{ $t('general.wednesday', $store.state.locale) }}</v-flex>
              <v-flex xs9>
                <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedDevice.defaultDays.Wed"></v-switch>
              </v-flex>
              <v-flex xs3>{{ $t('general.thursday', $store.state.locale) }}</v-flex>
              <v-flex xs9>
                <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedDevice.defaultDays.Thu"></v-switch>
              </v-flex>
              <v-flex xs3>{{ $t('general.friday', $store.state.locale) }}</v-flex>
              <v-flex xs9>
                <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedDevice.defaultDays.Fri"></v-switch>
              </v-flex>
              <v-flex xs3>{{ $t('general.saturday', $store.state.locale) }}</v-flex>
              <v-flex xs9>
                <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedDevice.defaultDays.Sat"></v-switch>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-form>

      <v-switch 
        :label="$t('general.active', $store.state.locale)"  
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        v-model="selectedDevice.active"
        hide-details
      ></v-switch>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
              @click="closeEditDeviceDialog()"
              >
              {{ $t('general.cancel', $store.state.locale) }}
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              :disabled="!changed" 
              :depressed="!changed"  
              :dark=" changed"  
              :loading="busy"
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              @click="updateDevice()"
              >
              <div v-if="changed">{{ $t('general.saveChanged', $store.state.activeUserLanguage) }} <v-icon right >mdi-chevron-right</v-icon></div>
              <div  v-else> {{ $t('general.saved', $store.state.activeUserLanguage) }} <v-icon right >mdi-check</v-icon></div>   
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>
  </v-card>
</template>


<script>
  import db from "../../firebase/init";
  export default {
    name: "Edit-Device",
    props: {
      selectedDevice: Object,
      selectedDeviceOriginal: Object,
      deviceTypes: Array,
      rooms: Array
    },
    data() {
      return {
        devices: new Array(),
        busy: false,
        showNewDialog: false,
        feedback: null,
        selectedDeviceIdCopy: false
      };
    },

    methods: {
      closeEditDeviceDialog() {
        this.$emit("editDeviceDialogChange", false);
      },

      copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }

        navigator.clipboard.writeText(text).then(()=> {
          this.selectedDeviceIdCopy = true
          setTimeout(()=>{ 
            this.selectedDeviceIdCopy = false
          }, 1000);
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      },

      async updateDevice() {
        if(this.selectedDevice.name) {
          let logData = {
            superAdminAction: true, // Is action only accessible by superadmin?
            actionType: "userAction",
            action: "deviceEditted",
            actionItem: this.selectedDeviceOriginal.name,
            extra:[
              {
                name: "originalData",
                value: ""
              },
              {
                name: "name",
                value: this.selectedDeviceOriginal.name
              },
              {
                name: "companyId",
                value: this.selectedDeviceOriginal.companyId
              },
              {
                name: "branchId",
                value: this.selectedDeviceOriginal.branches
              },
              {
                name: "deviceTypes",
                value: this.selectedDeviceOriginal.deviceTypes
              },
              {
                name: "rooms",
                value: this.selectedDeviceOriginal.rooms
              },
              {
                name: "defaultDays",
                value: JSON.stringify(this.selectedDeviceOriginal.defaultDays)
              },
              {
                name: "active",
                value: this.selectedDeviceOriginal.active
              },
              {
                name: "changedData",
                value: ""
              },
              {
                name: "name",
                value: this.selectedDevice.name
              },
              {
                name: "companyId",
                value: this.selectedDevice.companyId
              },
              {
                name: "branchId",
                value: this.selectedDevice.branches
              },
              {
                name: "deviceTypes",
                value: this.selectedDevice.deviceTypes
              },
              {
                name: "rooms",
                value: this.selectedDevice.rooms
              },
              {
                name: "defaultDays",
                value: JSON.stringify(this.selectedDevice.defaultDays)
              },
              {
                name: "active",
                value: this.selectedDevice.active
              }
            ]
          }

          this.busy = true;
          try {
            await db.collection("devices")
            .doc(this.selectedDevice.id)
            .update({
              name: this.selectedDevice.name,
              active: this.selectedDevice.active,
              deviceTypes: this.selectedDevice.deviceTypes,
              rooms: this.selectedDevice.rooms,
              branches: this.selectedDevice.branches,
              defaultDays: this.selectedDevice.defaultDays
            })

            await this.$createNewLog("info", logData);
            this.$emit("updateData");
            this.closeEditDeviceDialog();
          }
          catch(error){
            this.feedback = this.$t("general.addError", this.$store.state.locale) ;
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
          }
          this.busy = false;
        }
        else {
          this.feedback = this.$t('device.required', this.$store.state.locale);
        }
      }
    },
    computed: {
      changed() {
        if (_.isEqual(this.selectedDevice, this.selectedDeviceOriginal) == false) {
          return true;
        }
        else {
          return false;
        }
      },
    }
  };
</script>
