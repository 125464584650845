<template>
  <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>Lichaamsamenstelling bewerken</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeEditBodyCompositionDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert class="pa-2 ma-0" v-if="feedback" :value="true" type="warning">{{ feedback }} </v-alert>

    <v-divider></v-divider>

    <v-card-text>
      <v-form>
        <div class="input-content-wrapper padding-top space">
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6>
                <v-menu
                  ref="dateDialog"
                  :close-on-content-click="false"
                  v-model="dateDialog"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  >
                  <template v-slot:activator="{on}">
                    <v-text-field
                      class="mt-2"
                      outlined
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      v-on="on"
                      slot="activator"
                      v-model="addedDateFormatted"
                      label="Datum meting"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="date" no-title @input="dateDialog = false" :max="max" :show-current="true"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md6 >
                <v-text-field
                  class="mt-2"
                  label="Tijdstip"
                  v-model="selectedComposition.time"
                  prepend-inner-icon="mdi-clock-outline"
                  type="time"
                  suffix="uur"
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        <div v-if="selectedComposition.type == 'bia'" class="bia-view-wrapper">
          <div class="input-content-wrapper padding-top space">
            <div class="input-wrapper">

          <div class="body-measurement-item-wrapper clear">
            <div  class="body-measurement-icon scale" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Gewicht"
                v-model.number="selectedComposition.weight"
                v-on:input="setBiaWeight()"
                outlined
                background-color="background"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                type="number"
                suffix="kg"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon fat" ></div>
            <div class="body-measurement-item no-label clear">
              <p class="custom-measurement-label ma-0">Vet</p>
              <div class="measurement-input-field-one">
                <v-text-field
                  :disabled="biaReadOnly"
                  v-model.number="selectedComposition.fat.weight"
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  dense
                  v-on:input="calculateFatPercentage()"
                  type="number"
                  suffix="kg"
                ></v-text-field>
              </div>
              <div class="measurement-input-field-two">
                <v-text-field
                  :disabled="biaReadOnly"
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  dense
                  v-model.number="selectedComposition.fat.percentage"
                  v-on:input="calculateFatWeight()"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon muscle" ></div>
            <div class="body-measurement-item no-label clear">
                <p class="custom-measurement-label ma-0">Spieren</p>
                <div class="measurement-input-field-one">
                  <v-text-field
                    :disabled="biaReadOnly"
                    outlined
                    background-color="background"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    dense
                    v-model.number="selectedComposition.muscle.weight"
                    v-on:input="calculateMusclePercentage()"
                    type="number"
                    suffix="kg"
                  ></v-text-field>
                </div>
                <div class="measurement-input-field-two">
                  <v-text-field
                    :disabled="biaReadOnly"
                    outlined
                    background-color="background"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    dense
                    v-model.number="selectedComposition.muscle.percentage"
                    v-on:input="calculateMuscleWeight()"
                    type="number"
                    suffix="%"
                  ></v-text-field>
                </div>
              
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon vis-fat" ></div>
            <div class="body-measurement-item no-label clear">
                <p class="custom-measurement-label ma-0">Visceraal vet</p>
                <div class="measurement-input-field-one">
                  <v-text-field
                    :disabled="biaReadOnly"
                    outlined
                    background-color="background"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    dense
                    v-model.number="selectedComposition.visFat.weight"
                    v-on:input="calculateVisFatPercentage()"
                    type="number"
                    suffix="kg"
                  ></v-text-field>
                </div>
                <div class="measurement-input-field-two">
                  <v-text-field
                    :disabled="biaReadOnly"
                    outlined
                    background-color="background"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    dense
                    v-model.number="selectedComposition.visFat.percentage"
                    v-on:input="calculateVisFatWeight()"
                    type="number"
                    suffix="%"
                  ></v-text-field>
                </div>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon bone" ></div>
            <div class="body-measurement-item no-label clear">
              <p class="custom-measurement-label ma-0">Botmassa</p>
              <div class="measurement-input-field-one">
                <v-text-field
                  :disabled="biaReadOnly"
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  dense
                  v-model.number="selectedComposition.bone.weight"
                  v-on:input="calculateBonePercentage()"
                  type="number"
                  suffix="kg"
                ></v-text-field>
              </div>
              <div class="measurement-input-field-two">
                <v-text-field
                  :disabled="biaReadOnly"
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  dense
                  v-model.number="selectedComposition.bone.percentage"
                  v-on:input="calculateBoneWeight()"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon water" ></div>
            <div class="body-measurement-item no-label clear">
                <p class="custom-measurement-label ma-0">Vocht</p>
                <div class="measurement-input-field-one">
                  <v-text-field
                    :disabled="biaReadOnly"
                    outlined
                    background-color="background"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    dense
                    v-model.number="selectedComposition.water.weight"
                    v-on:input="calculateWaterPercentage()"
                    type="number"
                    suffix="kg"
                  ></v-text-field>
                </div>
                <div class="measurement-input-field-two">
                  <v-text-field
                    :disabled="biaReadOnly"
                    outlined
                    background-color="background"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    dense
                    v-model.number="selectedComposition.water.percentage"
                    v-on:input="calculateWaterWeight()"
                    type="number"
                    suffix="%"
                  ></v-text-field>
                </div>
            </div>
          </div>

            </div>
          </div>
        </div>

        <div v-if="selectedComposition.type == 'skinfold'" class="skinfold-view-wrapper">

          <div class="input-content-wrapper padding-top space">
            <div class="input-wrapper">
            <div class="skinfold-progress-wrapper">
              <p>Voortgang huidplooimeting</p>
              <div class="skinfold-progress-bar">
                <div class="skinfold-progress-bar-inner" v-bind:style="{ width: skinfoldProgress }"></div>
              </div>
            </div>

            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon scale" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  label="Gewicht"
                  v-model.number="selectedComposition.weight"
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  v-on:input="setSkinfoldWeight()"
                  type="number"
                  suffix="kg"
                ></v-text-field>
              </div>
            </div>
        
            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon biciptal" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  :disabled="skinfoldReadOnly"
                  label="Biciptale plooi"
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  v-model.number="selectedComposition.biciptal"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>

            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon tricipital" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  :disabled="skinfoldReadOnly"
                  label="Tricipitale plooi"
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  v-model.number="selectedComposition.tricipital"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>

            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon subscapulair" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  :disabled="skinfoldReadOnly"
                  label="Subscapulaire plooi"
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  v-model.number="selectedComposition.subscapulair"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>

            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon suprailiacal" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  :disabled="skinfoldReadOnly"
                  label="Supra-iliacale plooi"
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  v-model.number="selectedComposition.suprailiacal"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>

         
          </div>

          </div>
        </div>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
              @click="closeEditBodyCompositionDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              :dark="changed"
              :depressed="!changed"
              :disabled="!changed"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              @click="updateComposition()"
              >
              <div v-if="changed">Wijzigingen opslaan <v-icon right>mdi-chevron-right</v-icon></div>
              <div v-else>Opgeslagen <v-icon right>mdi-check</v-icon></div>  
              
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>


<script>
import db from "../../firebase/init";
import '@firebase/firestore';
import moment from "moment";
// import _ from "lodash";

export default {
  name: "edit-body-composition-form",
  props: {
    clientInfo: Object, 
    selectedComposition: Object,
    selectedCompositionOriginal: Object,
    date: String,
    dateOriginal: String,
  },

  data() {
    return {
      max: null,
      retryLoading: false,
      feedback: null,
      dateDialog: false,
    };
  },

  created() {
    this.max = moment().format("YYYY-MM-DD");
  },

  methods: {
    // Dialog sluiten
    closeEditBodyCompositionDialog() {
      this.$emit("closeEditBodyCompositionDialogChange", false);
      this.$emit("clearEditCompositionData");
    },

    // Alle waarden op NULL zetten als er een nieuw gewicht wordt ingevoerd.
    setBiaWeight() {
      this.selectedComposition.fat.weight = null;
      this.selectedComposition.fat.percentage = null;
      this.selectedComposition.muscle.weight = null;
      this.selectedComposition.muscle.percentage = null;
      this.selectedComposition.visFat.weight = null;
      this.selectedComposition.visFat.percentage = null;
      this.selectedComposition.bone.weight = null;
      this.selectedComposition.bone.percentage = null;
      this.selectedComposition.water.weight = null;
      this.selectedComposition.water.percentage = null;
    },

    // Alle waarden op NULL zetten als er een nieuw gewicht wordt ingevoerd.
    setSkinfoldWeight() {
      this.selectedComposition.biciptal = null;
      this.selectedComposition.tricipital = null;
      this.selectedComposition.subscapulair = null;
      this.selectedComposition.suprailiacal = null;
    },

    // Kijken naar welke type meting geselecteerd is en deze opslaan als vereiste velden ingevuld zijn.
    updateComposition(){
      if(this.selectedComposition.type == 'bia'){
        if(this.addedDateFormatted && this.selectedComposition.weight){
          this.updateMeasurementBia()
        } else {
          this.feedback = "Vul datum en gewicht in om op te slaan."
        }
      } else {
        if(this.selectedComposition.weight && this.selectedComposition.biciptal && this.selectedComposition.tricipital && this.selectedComposition.subscapulair && this.selectedComposition.suprailiacal){
          this.updateMeasurementSkinfold()
        } else {
          this.feedback = "Vul alle velden in om op te slaan."
        }
      }
    },

    // Data van geselecteerde lichaamsamenstelling binnenhalen
    getComposition() {
      this.loading = true;
      this.feedback = null
      this.retryLoading = false;
      db.collection("compositions")
        .doc(this.selectedCompositionId)
        .get()
        .then(doc => {
          this.loading = false;
          this.date = moment.unix(doc.data().dateAdded.seconds).format("YYYY-MM-DD");
          this.dateOriginal = _.cloneDeep(this.date);
          this.selectedComposition = doc.data();
          this.selectedComposition.id = doc.id;
          this.selectedCompositionOriginal = _.cloneDeep(this.selectedComposition);
        })
        .catch(error => {
          this.loading = false;
          this.retryLoading = true;
        })
    },

    // Bereken Vetprecentage als vetgewicht wordt ingevoerd (op basis van ingevoerde vetgewicht en totale lichaamsgewicht)
    calculateFatPercentage() {
      if (this.selectedComposition.fat.weight) {
        if (this.selectedComposition.weight) {
          let selectedCompositionFatPercentage = (
            (this.selectedComposition.fat.weight / this.selectedComposition.weight) *
            100
          ).toFixed(2);
          this.selectedComposition.fat.percentage = Number(selectedCompositionFatPercentage);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.selectedComposition.fat.weight = null;
        this.selectedComposition.fat.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Vetgewicht als vetpercentage wordt ingevoerd (op basis van ingevoerde vetpercentage en totale lichaamsgewicht)
    calculateFatWeight() {
      if (this.selectedComposition.fat.percentage) {
        if (this.selectedComposition.fat) {
          let selectedCompositionFatWeight = (
            (this.selectedComposition.fat.percentage / 100) *
            this.selectedComposition.weight
          ).toFixed(2);
          this.selectedComposition.fat.weight = Number(selectedCompositionFatWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
          this.selectedComposition.fat.percentage = null;
        }
      } else {
        this.selectedComposition.fat.weight = null;
        this.selectedComposition.fat.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Spierprecentage als spiergewicht wordt ingevoerd (op basis van ingevoerde spiergewicht en totale lichaamsgewicht)
    calculateMusclePercentage() {
      if (this.selectedComposition.muscle.weight) {
        if (this.selectedComposition.weight) {
          let selectedCompositionMusclePercentage = (
            (this.selectedComposition.muscle.weight / this.selectedComposition.weight) *
            100
          ).toFixed(2);
          this.selectedComposition.muscle.percentage = Number(selectedCompositionMusclePercentage);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.selectedComposition.muscle.weight = null;
        this.selectedComposition.muscle.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Spiergewicht als spierpercentage wordt ingevoerd (op basis van ingevoerde spierpercentage en totale lichaamsgewicht)
    calculateMuscleWeight() {
      if (this.selectedComposition.muscle.percentage) {
        if (this.selectedComposition.muscle) {
          let selectedCompositionMuscleWeight = (
            (this.selectedComposition.muscle.percentage / 100) *
            this.selectedComposition.weight
          ).toFixed(2);
          this.selectedComposition.muscle.weight = Number(selectedCompositionMuscleWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.selectedComposition.muscle.weight = null;
        this.selectedComposition.muscle.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Visceraalvetprecentage als Visceraalvetgewicht wordt ingevoerd (op basis van ingevoerde Visceraalvetgewicht en totale lichaamsgewicht)
    calculateVisFatWeight() {
      if (this.selectedComposition.visFat.percentage) {
        if (this.selectedComposition.visFat) {
          let selectedCompositionVisFatPercentage = (
            (this.selectedComposition.visFat.percentage / 100) *
            this.selectedComposition.weight
          ).toFixed(2);

          this.selectedComposition.visFat.weight = Number(selectedCompositionVisFatPercentage);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.selectedComposition.visFat.percentage = null;
        this.selectedComposition.visFat.weight = null;
        this.feedback = null;
      }
    },

    // Bereken Visceraalvetgewicht als Visceraalvetpercentage wordt ingevoerd (op basis van ingevoerde Visceraalvetpercentage en totale lichaamsgewicht)
    calculateVisFatPercentage() {
      if (this.selectedComposition.visFat.weight) {
        if (this.selectedComposition.weight) {
          let selectedCompositionVisFatWeight = (
            (this.selectedComposition.visFat.weight / this.selectedComposition.weight) *
            100
          ).toFixed(2);
          this.selectedComposition.visFat.percentage = Number(selectedCompositionVisFatWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.selectedComposition.visFat.percentage = null;
        this.selectedComposition.visFat.weight = null;
        this.feedback = null;
      }
    },

    // Bereken Botpercentage als Botgewicht wordt ingevoerd (op basis van ingevoerde Botgewicht en totale lichaamsgewicht)
    calculateBoneWeight() {
      if (this.selectedComposition.bone.percentage) {
        if (this.selectedComposition.weight) {
          let selectedCompositionBonePercentage = (
            (this.selectedComposition.bone.percentage / 100) *
            this.selectedComposition.weight
          ).toFixed(2);
          this.selectedComposition.bone.weight = Number(selectedCompositionBonePercentage);
        } else {
          tthis.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.selectedComposition.bone.weight = null;
        this.selectedComposition.bone.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Botgewicht als Botpercentage wordt ingevoerd (op basis van ingevoerde Botpercentage en totale lichaamsgewicht)
    calculateBonePercentage() {
      if (this.selectedComposition.bone.weight) {
        if (this.selectedComposition.weight) {
          let selectedCompositionBoneWeight = (
            (this.selectedComposition.bone.weight / this.selectedComposition.weight) *
            100
          ).toFixed(2);
          this.selectedComposition.bone.percentage = Number(selectedCompositionBoneWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.selectedComposition.bone.weight = null;
        this.selectedComposition.bone.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Vochtpercentage als Vochtgewicht wordt ingevoerd (op basis van ingevoerde Vochtgewicht en totale lichaamsgewicht)
    calculateWaterPercentage() {
      if (this.selectedComposition.water.weight) {
        if (this.selectedComposition.weight) {
          let selectedCompositionWaterPercentage = (
            (this.selectedComposition.water.weight / this.selectedComposition.weight) *
            100
          ).toFixed(2);

          this.selectedComposition.water.percentage = Number(selectedCompositionWaterPercentage);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.selectedComposition.water.percentage = null;
        this.selectedComposition.water.weight = null;
        this.feedback = null;
      }
    },

    // Bereken Vochtgewicht als Vochtpercentage wordt ingevoerd (op basis van ingevoerde Vochtpercentage en totale lichaamsgewicht)
    calculateWaterWeight() {
      if (this.selectedComposition.water.percentage) {
        if (this.selectedComposition.weight) {
          let selectedCompositionWaterWeight = (
            (this.selectedComposition.water.percentage / 100) *
            this.selectedComposition.weight
          ).toFixed(2);

          this.selectedComposition.water.weight = Number(selectedCompositionWaterWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.selectedComposition.water.weight = null;
        this.selectedComposition.water.percentage = null;
        this.feedback = null;
      }
    },

    // Terug naar het Lichaamssamenstelling overzichtspagina
    goToCompositionOverview() {
      this.$router.push("/bodycomposition-overview");
    },

    // Datum omvormen naar Europese leesbare datum
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    // Update BIA-meting in de  database en log deze actie. Ook eventuele errors worden gelogd
    updateMeasurementBia() {
      let time = this.selectedComposition.time ? this.selectedComposition.time : null;
      db.collection("compositions")
        .doc(this.selectedComposition.id)
        .update({
          // companyId: this.userCompanyId,
          dateAdded: new Date(this.firebaseDateFormatted),
          time: time,
          type: "bia",
          weight: this.selectedComposition.weight,
          fat: {
            weight: this.selectedComposition.fat.weight,
            percentage: this.selectedComposition.fat.percentage
          },
          muscle: {
            weight: this.selectedComposition.muscle.weight,
            percentage: this.selectedComposition.muscle.percentage
          },
          visFat: {
            weight: this.selectedComposition.visFat.weight,
            percentage: this.selectedComposition.visFat.percentage
          },
          bone: {
            weight: this.selectedComposition.bone.weight,
            percentage: this.selectedComposition.bone.percentage
          },
          water: {
            weight: this.selectedComposition.water.weight,
            percentage: this.selectedComposition.water.percentage
          }
        })
        .then(() => {
          this.$emit("updateData");
          this.$emit("clearEditCompositionData");
          this.closeEditBodyCompositionDialog()
          console.log("Body Composition BIA Updated Successfull");
        })
        .catch(error => {
          this.feedback = "Opslaan is niet gelukt. Probeer opnieuw";
          console.error("Error updating BIA measurement: ", error);
        });
    },

    // Update Huidplooimeting in de  database en log deze actie. Ook eventuele errors worden gelogd
    updateMeasurementSkinfold() {
       let time = this.selectedComposition.time ? this.selectedComposition.time : null;
      db.collection("compositions")
        .doc(this.selectedComposition.id)
        .update({
          dateAdded: new Date(this.firebaseDateFormatted),
          time: time,
          weight: this.selectedComposition.weight,
          biciptal: this.selectedComposition.biciptal,
          tricipital: this.selectedComposition.tricipital,
          subscapulair: this.selectedComposition.subscapulair,
          suprailiacal: this.selectedComposition.suprailiacal,
          fat: {
            weight: this.skinfoldFatWeight,
            percentage: this.skinfoldFatPercentage
          }
        })
        .then(() => {
          this.$emit("updateData");
          this.$emit("clearEditCompositionData");
          this.closeEditBodyCompositionDialog()
          console.log("Body Composition Skinfold Updated Successfull");
        })
        .catch(error => {
          this.feedback = "Opslaan is niet gelukt. Probeer opnieuw";
          console.error("Error updating skinfold measurement: ", error);
        });
    }
  },

  computed: {
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    
    // Berekenen van totale vetprecentage op basis van de huidplooimeting
    skinfoldFatWeight() {
      if (this.selectedComposition.type == "skinfold") {
        if (
          this.selectedComposition.weight &&
          this.selectedComposition.biciptal &&
          this.selectedComposition.tricipital &&
          this.selectedComposition.subscapulair &&
          this.selectedComposition.suprailiacal
        ) {
          let averageFatPercentage =
            (this.selectedComposition.biciptal +
              this.selectedComposition.tricipital +
              this.selectedComposition.subscapulair +
              this.selectedComposition.suprailiacal) /
            4;
          let averageFatWeigt = (averageFatPercentage / 100) * this.selectedComposition.weight;
          return Number(averageFatWeigt.toFixed(2));
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    // Berekenen van totale vetgewicht op basis van de huidplooimeting
    skinfoldFatPercentage() {
      if (this.selectedComposition.type == "skinfold") {
        if (
          this.selectedComposition.weight &&
          this.selectedComposition.biciptal &&
          this.selectedComposition.tricipital &&
          this.selectedComposition.subscapulair &&
          this.selectedComposition.suprailiacal
        ) {
          let averageFatPercentage =
            (this.selectedComposition.biciptal +
              this.selectedComposition.tricipital +
              this.selectedComposition.subscapulair +
              this.selectedComposition.suprailiacal) /
            4;
          return Number(averageFatPercentage.toFixed(2));
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    // Voorgang van huidplooimeting berekenen
    skinfoldProgress() {
      let progress = 0;
      if (this.selectedComposition.type == "skinfold") {
        if (this.selectedComposition.weight) {
          progress += 20;
        }
        if (this.selectedComposition.biciptal) {
          progress += 20;
        }
        if (this.selectedComposition.tricipital) {
          progress += 20;
        }
        if (this.selectedComposition.subscapulair) {
          progress += 20;
        }
        if (this.selectedComposition.suprailiacal) {
          progress += 20;
        }
      }
      return String(progress) + "%";
    },

    // Kijken naar het geslacht van de geselecteerde klant. Op basis hiervan worden de iconen voor man of vrouw weergegeven
    activeGender() {
      if (this.clientInfo.gender == 1) {
        return "man";
      } else {
        return "woman";
      }
    },

    // Datum omvormen naar Europese leesbare datum
    addedDateFormatted() {
      return this.formatDate(this.date);
    },

    // Datum BIA-meting omvormen naar juist datum voor Firebase Database
    firebaseDateFormatted() {
      return new Date(this.date);
    },

    // Kijken of de minimale velden voor de BIA-meting is ingevuld
    biaReadOnly() {
      if (this.selectedComposition.type == "bia") {
        if (this.selectedComposition.weight) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    // Kijken of de minimale velden voor de Huidplooimeting is ingevuld
    skinfoldReadOnly() {
      if (this.selectedComposition.type == "skinfold") {
        if (this.selectedComposition.weight) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },

    // Kijken of er aanpassingen zijn gedaan. Pas dan wordt de 'Opslaan' knop getoond
    changed() {
      if (
        _.isEqual(this.selectedComposition, this.selectedCompositionOriginal) == false ||
        this.date != this.dateOriginal
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

