<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type =='new'">Nieuwe lidmaatschapscategorie</h3>
        <h3 v-if="type =='edit'">Lidmaatschapscategorie bewerken</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeCategoryDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" flat icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="AddCategoryForm" lazy-validation> 
          <v-text-field
            v-model="name"
            :counter="20"
            label="Naam"
            required
            dense
            outlined
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            class="mt-4"
          ></v-text-field>

          <v-text-field
            v-model="description"
            :counter="50"
            label="Beschrijving"
            required
            dense
            outlined
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            class="mt-4"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                @click="closeCategoryDialog()"
                >
                {{ $t('general.cancel', $store.state.locale)}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn
                v-if="type == 'new'"
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addCategory()"
                >
                Categorie toevoegen
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>

              <v-btn
                v-if="type == 'edit'"
                :disabled=" !changed" 
                :depressed="!changed"  
                :dark="changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="editCategory()"
                >
                <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right>mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
  import db from "../../firebase/init";
  import { bus } from "../../main";
  import _ from 'lodash'

  export default {
    name: "add-membership-category",

    data() {
      return {
        showDialog: false,
        type: 'new',
        id: null,
        name:null,
        description:null,
        feedback:null,
      }
    },

    created(){
      bus.$on("membershipCategory", (item, type) => { 
        this.resetForm();
        this.type = type;

        if(type == 'edit'){
          this.id = item.id;
          this.name = item.name;
          this.description = item.description;
          this.nameOriginal = _.cloneDeep(item.name);
          this.descriptionOriginal = _.cloneDeep(item.description);
        }
        else{
          this.type = 'new';
        }
        this.showDialog = true;  
      });
    },

    methods:{

      closeCategoryDialog(){ // Close dialog en reset data
        this.showDialog = false;  
        this.resetForm();
      },

      resetForm(){ // Reset data
        this.id = null;
        this.type = "new";
        this.name = null;
        this.description = null;
        this.nameOriginal = null;
        this.descriptionOriginal = null;
        this.feedback = null;
      },

      async addCategory(){ // Add new category to datebase
        this.feedback = null;
        if(this.name?.length > 0 && this.description?.length > 0){
          await db.collection('membershipCategories')
          .add({
            name:this.name,
            description: this.description
          })
          .then(()=>{
            this.$emit("addMembershipCategoryDialog",false);
            bus.$emit("addCategory",true);
            this.resetForm();
          })
          .catch(err=>{
            this.feedback = this.$t('admin.menuSaveError', this.$store.state.locale);
            console.error("Error adding membership category: ",err);
          })
          this.$emit("updateData");
          this.showDialog = false;  
        }
        else{
          this.feedback = this.$t('general.requiredField', this.$store.state.locale);
        }
        
        
      },

      async editCategory(){ // Save changes of existing category to datebase
        this.feedback = null;
        await db.collection('membershipCategories').doc(this.id).set({
          name: this.name,
          description: this.description
        },{merge: true})
        .catch(err=>{
          this.feedback = this.$t('admin.menuSaveError', this.$store.state.locale);
          console.error("Error saving membership category:",err);
        })
        this.$emit("updateData");
        this.showDialog = false;  
      }
    },

    computed: {
      changed(){ // Check if existing data has changed
        let nameChanged =  this.nameOriginal !== this.name;
        let descriptionChanged =  this.descriptionOriginal !== this.description;
        return (nameChanged || descriptionChanged) && true;
      },
    }
  };
</script>
