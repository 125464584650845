<template>
  <v-dialog v-model="cashUpDialog" scrollable max-width="1100px" height="700px">
    <v-card v-if="cashUpDialog">
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3>{{ $t('payment.newRegisterCheck', $store.state.locale) }}, {{ today }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeCashUp('close')">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="ma-0 pa-0">
        <v-form ref="addCashUp" lazy-validation>
          <v-card flat class="ma-4 mb-0 px-0 py-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" v-if="$store.state.userRoleNumber < 2 && !$store.state.demoMode">
            <v-container class="px-1">					
              <v-row>
                <v-col cols="6" class="pa-2">
                  <v-row class="mx-3">
                    <v-col class="ma-0 pa-0 py-1" cols="6"><strong>Laatste kasopmaak:</strong></v-col><v-col cols="6" v-if="lastCashUpTimestamp" class="text-right ma-0 pa-0 py-1">{{ lastCashUpTimestamp | moment("D MMMM YYYY") }} om {{ lastCashUpTimestamp | moment("H:mm") }}u</v-col><v-col cols="6" class="text-right ma-0 pa-0 py-1" v-else>-</v-col>
                    <v-col class="ma-0 pa-0 py-1" cols="9"><strong>Eindsaldo vorige opmaak:</strong></v-col><v-col cols="3" v-if="lastCashUpTimestamp" class="text-right ma-0 pa-0 py-1">&euro; {{ latestCashUpCash.toFixed(2) }}</v-col><v-col cols="3" v-else class="text-right ma-0 pa-0 py-1" >-</v-col>
                    <v-col class="ma-0 pa-0 py-1" cols="9"><strong>Verwachte cash inkomsten:</strong></v-col><v-col cols="3" class="text-right ma-0 pa-0 py-1">&euro; {{ cashIncomeStatement.toFixed(2) }}</v-col>
                    <v-col class="ma-0 pa-0 py-1" cols="9"><strong>Afronding cash:</strong></v-col><v-col cols="3" class="text-right ma-0 pa-0 py-1">&euro; {{ latestCashRounding.toFixed(2) }}</v-col>
                    <v-col class="ma-0 pa-0 py-1" cols="9"><strong>Verwacht eindsaldo kassa:</strong></v-col><v-col cols="3" class="text-right ma-0 pa-0 py-1">&euro; {{ (cashStatement + latestCashRounding).toFixed(2) }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pa-2">
                  <v-row class="mx-3">
                    <v-col class="ma-0 pa-0 py-1" cols="9"><strong>Verwachte PIN-inkomsten:</strong></v-col><v-col cols="3" class="text-right ma-0 pa-0 py-1">&euro; {{ pinIncomeStatement.toFixed(2) }}</v-col>
                    <v-col class="ma-0 pa-0 py-1" cols="9"><strong>Verwachte Creditcard inkomsten:</strong></v-col><v-col cols="3" class="text-right ma-0 pa-0 py-1">&euro; {{ creditcardIncomeStatement.toFixed(2) }}</v-col>
                    <v-col class="ma-0 pa-0 py-1" cols="9"><strong>Verschil cash:</strong></v-col><v-col cols="3" class="text-right ma-0 pa-0 py-1">&euro; {{ differenceCash.toFixed(2) }}</v-col>
                    <v-col class="ma-0 pa-0 py-1" cols="9"><strong>Verschil PIN:</strong></v-col><v-col cols="3" class="text-right ma-0 pa-0 py-1">&euro; {{ differencePin.toFixed(2) }}</v-col>
                    <v-col class="ma-0 pa-0 py-1" cols="9"><strong>Verschil Creditcard:</strong></v-col><v-col cols="3" class="text-right ma-0 pa-0 py-1">&euro; {{ differenceCreditcard.toFixed(2) }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-container grid-list-md class="pt-0">
            <v-row>
              <!-- / Column 1: Munten \ -->
              <v-col cols="4">
                <p class="mt-4"><strong>{{ $t('payment.newRegisterCheckCoins', $store.state.locale) }}</strong></p>
                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-1-cent-coin"></div>
                  <div class="cash-field-text">1 cent</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.oneEuroCent"
                      @input="calculateCashStatement(cashUp.oneEuroCent, 'oneEuroCent')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.oneEuroCent * cashConvFilter(0.01)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-2-cent-coin"></div>
                  <div class="cash-field-text">2 cent</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.twoEuroCent"
                      @input="calculateCashStatement(cashUp.twoEuroCent, 'twoEuroCent')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.twoEuroCent * cashConvFilter(0.02)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-5-cent-coin"></div>
                  <div class="cash-field-text">5 cent</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.fiveEuroCent"
                      @input="calculateCashStatement(cashUp.fiveEuroCent, 'fiveEuroCent')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.fiveEuroCent * cashConvFilter(0.05)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-10-cent-coin"></div>
                  <div class="cash-field-text">10 cent</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.tenEuroCent"
                      @input="calculateCashStatement(cashUp.tenEuroCent, 'tenEuroCent')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.tenEuroCent * cashConvFilter(0.10)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-20-cent-coin"></div>
                  <div class="cash-field-text">20 cent</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.twentyEuroCent"
                      @input="calculateCashStatement(cashUp.twentyEuroCent, 'twentyEuroCent')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.twentyEuroCent * cashConvFilter(0.20)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-50-cent-coin"></div>
                  <div class="cash-field-text">50 cent</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.fiftyEuroCent"
                      @input="calculateCashStatement(cashUp.fiftyEuroCent, 'fiftyEuroCent')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.fiftyEuroCent * cashConvFilter(0.50)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-1-coin"></div>
                  <div class="cash-field-text">1 euro</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.oneEuro"
                      @input="calculateCashStatement(cashUp.oneEuro, 'oneEuro')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.oneEuro * cashConvFilter(1)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-2-coin"></div>
                  <div class="cash-field-text">2 euro</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.twoEuro"
                      @input="calculateCashStatement(cashUp.twoEuro, 'twoEuro')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.twoEuro * cashConvFilter(2)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
              <!-- \ Column 1: Munten / -->

              <!-- / Column 2: Biljetten \ -->
              <v-col cols="4">
                <p class="mt-4"><strong>{{ $t('payment.newRegisterCheckBanknotes', $store.state.locale) }}</strong></p>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-5-bill"></div>
                  <div class="cash-field-text">5 euro</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.fiveEuro"
                      @input="calculateCashStatement(cashUp.fiveEuro, 'fiveEuro')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.fiveEuro * cashConvFilter(5)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-10-bill"></div>
                  <div class="cash-field-text">10 euro</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.tenEuro"
                      @input="calculateCashStatement(cashUp.tenEuro, 'tenEuro')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.tenEuro * cashConvFilter(10)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-20-bill"></div>
                  <div class="cash-field-text">20 euro</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.twentyEuro"
                      @input="calculateCashStatement(cashUp.twentyEuro, 'twentyEuro')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.twentyEuro * cashConvFilter(20)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-50-bill"></div>
                  <div class="cash-field-text">50 euro</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.fiftyEuro"
                      @input="calculateCashStatement(cashUp.fiftyEuro, 'fiftyEuro')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.fiftyEuro * cashConvFilter(50)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-100-bill"></div>
                  <div class="cash-field-text">100 euro</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.hundredEuro"
                      @input="calculateCashStatement(cashUp.hundredEuro, 'hundredEuro')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.hundredEuro * cashConvFilter(100)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-200-bill"></div>
                  <div class="cash-field-text">200 euro</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.twoHundredEuro"
                      @input="calculateCashStatement(cashUp.twoHundredEuro, 'twoHundredEuro')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.twoHundredEuro * cashConvFilter(200)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image euro-500-bill"></div>
                  <div class="cash-field-text">500 euro</div>
                  <div class="cash-field-x">x</div>
                  <div class="cash-field-number">
                    <v-text-field
                      dense
                      v-model.number="cashUp.fiveHundredEuro"
                      @input="calculateCashStatement(cashUp.fiveHundredEuro, 'fiveHundredEuro')"
                      hide-details
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                  <div class="cash-field-equals">=</div>
                  <div class="cash-field-sum">
                    <v-text-field
                      dense
                      disabled
                      :value="Number(cashUp.fiveHundredEuro * cashConvFilter(500)).toFixed(2)"
                      prefix="€"
                      hide-details
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
              <!-- \ Column 2: Biljetten / -->

              <!-- / Column 3: Pin & Kassa \ -->
              <v-col cols="4">
                <p class="mt-4"><strong>{{ $t('payment.newRegisterCheckPaymentsByCard', $store.state.locale) }}</strong></p>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image pin"></div>
                  <div class="cash-field-text card">{{ $t('payment.newRegisterCheckPaymentsByPin', $store.state.locale) }}</div>
                  <div class="cash-field-number card">
                    <v-text-field
                      style="width: 100%"
                      dense
                      v-model.number="cashUp.pin"
                      @input="calculateCashStatement(cashUp.pin, 'pin')"
                      hide-details
                      prefix="€"
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                </div>

                <div class="cash-field-wrapper">
                  <div class="cash-field-image creditcard"></div>
                  <div class="cash-field-text card">{{ $t('payment.newRegisterCheckPaymentsByCreditcard', $store.state.locale) }}</div>
                  <div class="cash-field-number card">
                    <v-text-field
                      style="width: 100%"
                      dense
                      v-model.number="cashUp.creditcard"
                      @input="calculateCashStatement(cashUp.creditcard, 'creditcard')"
                      hide-details
                      prefix="€"
                      type="number"
                      outlined
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-text-field>
                  </div>
                </div>

                <!-- Indicator -->
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" v-if="cashUpFeedback.box === 'neutral'" class="pa-4 text-center" v-html="cashUpFeedback.message"></v-card>
                <v-card flat color="#00C853" v-if="cashUpFeedback.box === 'green'" class="pa-3 my-3 text-center" v-html="cashUpFeedback.message"></v-card> 
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background"  v-if="differencePin" style="font-size: 12px; line-height: 1.5;" class="my-3 pa-4" v-html="$t('payment.newRegisterCheckPaymentsByPinDifference', $store.state.locale)"></v-card>
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background"  v-if="differenceCreditcard" class="my-3 pa-3" v-html="$t('payment.newRegisterCheckPaymentsByCreditcardDifference', $store.state.locale)"></v-card>

                <v-card flat color="error" v-if="cashUpFeedback.box === 'red'" class="my-3 pa-3">
                  <div style="color: #ffffff; font-size: 12px; line-height: 1.5;" v-html="cashUpFeedback.message"></div>
                  <v-btn
                    class="mt-3"
                    block
                    depressed
                    color="white"
                    right
                    outlined
                    @click="saveCashUp('force')"
                    >
                    {{ $t('payment.newRegisterCheckForceContinue', $store.state.locale) }}					
                  </v-btn>
                </v-card>

                <!-- <div v-if="cashUpFeedback.box === 'red'" class="wrap-cashUpField">
                  <div class="cashDifference red">
                  <div v-html="cashUpFeedback.message"></div>
                  <v-btn
                    style="margin: 6px 0 0 0"
                    block
                    depressed
                    color="error"
                    right
                    dark
                    @click="saveCashUp('force')"
                    >
                    Doorgaan forceren
                  </v-btn>
                  </div>
                </div> -->

                <!-- <div v-if="cashUpFeedback.box === 'green'" class="wrap-cashUpField">
                  <div class="cashDifference correct">
                  <div v-html="cashUpFeedback.message"></div>
                  </div>
                </div> -->

                <!-- Indicator -->
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" class="my-3 pa-3">
                  <div class="textfieldCashStatement">
                    <strong>{{ $t('payment.newRegisterCheckSaysInRegister', $store.state.locale) }}</strong><br>
                    <i>{{ $t('payment.newRegisterCheckSaysInRegisterMax', $store.state.locale) }} €{{ (cashUpSumCash).toFixed(2) }}</i>
                  </div>
                  <v-text-field
                    dense
                    v-model.number="cashStatementStay.value"
                    @input="correctCashUpNumbers()"
                    type="number"
                    prefix="€"
                    outlined
                    required
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="white"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :hint="cashStatementStay.feedback"
                    :error="cashStatementStay.error"
                  ></v-text-field>

                  <strong> {{ $t('payment.newRegisterDepositRoBank', $store.state.locale) }}</strong>
                  <v-text-field
                    class="mb-1"
                    disabled
                    dense
                    :value="cashUpDepositToBank.toFixed(2)"
                    hide-details
                    prefix="€"
                    outlined
                    required
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :error="cashStatementStay.error"
                  ></v-text-field>
                </v-card>

                <!-- <div class="wrap-stayInCash mt-4">
                  <div class="textfieldCashStatement">
                  <strong>Blijft in kas</strong> Max. beschikbaar:
                  <strong>€{{ (cashUpSumCash).toFixed(2) }}</strong>
                  </div>
                  <v-text-field
                  dense
                  v-model.number="cashStatementStay.value"
                  @input="correctCashUpNumbers()"
                  type="number"
                  prefix="€"
                  outlined
                  required
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :hint="cashStatementStay.feedback"
                  :error="cashStatementStay.error"
                  ></v-text-field>

                  <div class="textfieldCashStatement">Afstorten</div>

                  <v-text-field
                  class="mb-2"
                  disabled
                  dense
                  :value="cashUpDepositToBank.toFixed(2)"
                  hide-details
                  hint="Neem het bedrag hierboven uit de kassa om af te storten."
                  prefix="€"
                  outlined
                  required
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :error="cashStatementStay.error"
                  ></v-text-field>
                </div> -->
              </v-col>
              <!-- \ Column 3: Pin & Kassa / -->
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeCashUp('reset')" class="mr-2">
              {{ $t('payment.newRegisterCheckClear', $store.state.locale) }}
              </v-btn>
              <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeCashUp('close')">
              {{ $t('general.cancel', $store.state.locale) }} 
              </v-btn>
            </v-flex>

            <v-flex xs12 md8 class="text-right">
              <v-btn 
                :disabled="cashUpFeedback.box === 'red' || cashUpFeedback.box === null"
                :dark="cashUpFeedback.box === 'green' || cashUpFeedback.box === 'neutral'"
                :loading="cashUpFeedback.loading"
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="saveCashUp('default')"
                >
                {{ $t('payment.newRegisterCheckSave', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import db from "../../firebase/init";
import '@firebase/firestore';
import moment from "moment";
import { bus } from "../../main";
import { cashConv } from "../../modules/quickWins";

export default {
  name: "cash-up-modal",
  data() {
    return {
      cashUpDialog: false,
      feedback: null,
      today: moment().format("dddd D MMMM YYYY"),
			now: moment().format("YYYY-MM-DD"),
      lastCashUpTimestamp: null,
      pinIncomeStatement: 0,
			cardStatement: 0,
			creditcardIncomeStatement: 0,
      cashIncomeStatement: 0,
      cashStatement: 0,
			cashStatementStay: {
				value: 0,
				feedback: "",
				error: false,
				depositMoneyToBank: 0,
			},
      cashUpSumCash: 0,
			cashUpSumTotal: 0,
			cashUpSumByCard: {
				byPIN: 0,
				byCreditcard: 0,
			},
      selectedCashRegister: "general", // Selected register of branch 
      cashUpFeedback: { box: null, message: null, loading: false },
      differenceCash: 0,
			differencePin: 0,
			differenceCreditcard: 0,
			latestCashUpCash: 0,
			latestCashRounding: 0,
			cashStatementStayManualChanged: false,
      paidReceiptsCashUp: new Array(),
      cashUp: {
				oneEuroCent: 0,
				twoEuroCent: 0,
				fiveEuroCent: 0,
				tenEuroCent: 0,
				twentyEuroCent: 0,
				fiftyEuroCent: 0,
				oneEuro: 0,
				twoEuro: 0,
				fiveEuro: 0,
				tenEuro: 0,
				twentyEuro: 0,
				fiftyEuro: 0,
				hundredEuro: 0,
				twoHundredEuro: 0,
				fiveHundredEuro: 0,
				pin: 0,
				creditcard: 0,
			}
    }
  },

  created(){
    bus.$on("openCashUpModal",()=>{
      this.openCashUp()
    })
  },

  methods: {
    closeEditVatDialog() {
      this.$emit("editVatDialogChange", false);
    },

    async openCashUp() {
			this.cashStatementStay.value = 0;
			this.cardStatement = 0;
			this.cashStatement = 0;
			this.cashUpDepositToBank = 0;
			this.paidReceiptsCashUp = new Array();
			let lastCashUp = null

			await db.collection("cashRegister")
			.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
			.get()
			.then((doc) => {
				if (doc.exists) {
					this.cashStatement = doc.data().cash;
					this.correctCashUpNumbers('first');
					this.cashUpDialog = true;
				}
				else{
					this.createCashRegister('openCashUp') // Create cashRegister Doc
				}
			})
			.catch((error) => {
				console.error("Error getting cashRegister Statement", error);
				this.cashUpDialog = false;
			});

      await db.collection("cashRegister")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("branchId", "==", this.$store.state.activeBranch.id)
      .where("type", "==", "cashUp")
      .where("register", "==", this.selectedCashRegister)
      .orderBy("created", "desc")
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log("cashRegister data", doc.id, " => ", doc.data());
          lastCashUp = doc.data();
          lastCashUp.docId = doc.id;

          this.lastCashUpTimestamp = moment(doc.data().created.seconds, "X").format("YYYY-MM-DDTHH:mm:ss");
          this.latestCashUpCash = doc.data().cashUpSum;
          this.latestCashRounding = doc.data().cashRounding;
        });
      })
      .catch((error) => { // CashUp error
        console.error("Error cashUp documents: ", error);
      });

      if(lastCashUp){ // Get all PIN transactions after last Cashup
        await db.collection("receiptsPaid")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("branchId", "==", this.$store.state.activeBranch.id)
        .where("register", "==", this.selectedCashRegister)
        .where("created", ">", lastCashUp.created)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((docReceipt) => {
            console.log(docReceipt.id, " => ", docReceipt.data());
            let paidReceiptByCard = new Object();
            if (docReceipt.data().paymentTableObj.pin != 0) {
              paidReceiptByCard.pin = docReceipt.data().paymentTableObj.pin;
              paidReceiptByCard.docId = docReceipt.id;
              this.paidReceiptsCashUp.push(paidReceiptByCard);
            }
          });
        })
        .catch((error) => { // paidReceipts error
          console.error("Error getting paidReceipts documents: ", error);
        });
      }
      else{ // No Last Cashup
        await db.collection("receiptsPaid") // Get all PIN transactions
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("branchId", "==", this.$store.state.activeBranch.id)
        .where("register", "==", this.selectedCashRegister)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((docReceipt) => {
            let paidReceiptByCard = new Object();
            if (docReceipt.data().paymentTableObj.pin != 0) {
              paidReceiptByCard.pin = docReceipt.data().paymentTableObj.pin;
              paidReceiptByCard.docId = docReceipt.id;
              this.paidReceiptsCashUp.push(paidReceiptByCard);
            }
          });
        })
        .catch((error) => { // paidReceipts error
          console.error("Error getting paidReceipts documents: ", error);
        });	
      }
      
      // Sum Up the pins to cardStatement	
      this.cardStatement = this.paidReceiptsCashUp.reduce((total, currentValue) => total + Number(currentValue.pin), 0);
      this.pinIncomeStatement = this.paidReceiptsCashUp.reduce((total, currentValue) => total + Number(currentValue.pin), 0);
      this.creditcardIncomeStatement = this.paidReceiptsCashUp.reduce((total, currentValue) => total + currentValue.creditcard ? Number(currentValue.creditcard) : 0 , 0);
      this.calculateCashStatement(0, "oneEuroCent");
    },

    async createCashRegister(functionName){
			await db.collection("cashRegister").doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`).set({
				cash: 0,
				cashRounding: 0,
				cashUpCash: 0,
				cashUpTimestamp: null,
				timestamp: new Date()
			})
			.catch((error) => {
				console.error("Error creating cashRegister", error);
			});
			this[functionName]()
		},

    correctCashUpNumbers(type) {
      console.log("correctCashUpNumbers: ", type)
      if(!type || type != 'first'){
        this.cashStatementStayManualChanged = true;
      }
      if (this.cashStatementStay.value > this.cashUpSumCash + this.cashStatement) {
        this.cashStatementStay.value = this.cashUpSumCash + this.cashStatement;
        this.cashStatementStay.error = true;
        this.cashStatementStay.feedback = "Het ingevoerde bedrag is te hoog, bedrag is aangepast.";
        this.cashUpDepositToBank = this.cashStatementStay.value > 0 ? this.cashUpSumCash - this.cashStatementStay.value : 0 ;
      }
      else {
        this.cashStatementStay.error = false;
        this.cashStatementStay.feedback = "";
        this.cashUpDepositToBank = this.cashStatementStay.value > 0 ? this.cashUpSumCash - this.cashStatementStay.value : 0 ;
      }
    },

		closeCashUp(type) {
			this.cashUp = {
				oneEuroCent: 0,
				twoEuroCent: 0,
				fiveEuroCent: 0,
				tenEuroCent: 0,
				twentyEuroCent: 0,
				fiftyEuroCent: 0,
				oneEuro: 0,
				twoEuro: 0,
				fiveEuro: 0,
				tenEuro: 0,
				twentyEuro: 0,
				fiftyEuro: 0,
				hundredEuro: 0,
				twoHundredEuro: 0,
				fiveHundredEuro: 0,
				pin: 0,
				creditcard: 0,
			};
			//Refresh and Wipe-out History
			this.calculateCashStatement(0, "oneEuroCent");

			this.cashUpDifference = 0;
			this.cashUpDepositToBank = 0;
			this.cashStatement = 0;
			this.cashStatementStay.value = 0;
			this.cashUpDifference = 0;

			if (type === "close") {
				this.cashUpDialog = false;
				this.cashUpFeedback = { box: null, message: null, loading: false };
			}
      else {
				//Reset
				// this.calculateCashStatement();
				this.cashUpFeedback = { box: null, message: null, loading: false };
			}
		},

    cashConvFilter(amount) {
			//For showing the ZERO's
			return cashConv(amount, "EUR", 2);
		},

    saveCashUp(type) {
			this.cashUpFeedback.loading = true;

			//When Forced
			let newCashStatement = 0;
			if (this.cashUpDifference > 0) {
				newCashStatement = this.cashStatementStay.value + this.cashUpDifference;
			}

			// 1. Update Cash Statement
			db.collection("cashRegister")
				.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
				.update({
					cash: this.cashStatementStay.value,
          cashUpCash: this.cashStatementStay.value,
          cashRounding: 0,
          cashUpTimestamp: new Date(),
          timestamp: new Date(),
				})
				.then(() => {
					db.collection("cashRegister") // 2. Add CashUp
						.add({
							postToTable: "thrive_cashRegister_v1",
							cashUp: JSON.stringify(this.cashUp), // Object to BQ as String: Object with all counted cash types, pin and creditcard
							cashUpSum: cashConv(this.cashUpSumCash,"EUR",2), // BQ : Total Cash amount
							cashUpSumTotal: cashConv(this.cashUpSumTotal,"EUR",2),  // BQ: Total income (cash + pin + creditcard)
							cashUpDepositToBank: cashConv(this.cashUpDepositToBank,"EUR",2), //  BQ: Deposit to bank
							companyId: this.$store.state.activeCompany.id,
							branchId: this.$store.state.activeBranch.id,
							byResource: this.userId,
							created: new Date(),
							type: "cashUp",
							register: this.selectedCashRegister,
							cashRounding: this.latestCashRounding, // BQ: Profit or loss by rounding cash amount in change
							previousCashUpSum: cashConv(this.latestCashUpCash, "EUR", 2), // BQ: Starting cash amount  in register
							cashStatement: cashConv(this.cashStatement - this.latestCashUpCash, "EUR", 2), // BQ: Payment amount in cash
							cardStatement: cashConv(this.cashUp.pin + this.cashUp.creditcard ,"EUR", 2), // BQ: Payment Amount by Cards (Pin and Creditcard)
							cashSurplus: this.differenceCash > 0 ? cashConv(this.differenceCash, "EUR", 2) : 0, // BQ
							cashDeficit: this.differenceCash < 0 ? cashConv(Math.abs(this.differenceCash), "EUR", 2) : 0, // BQ
							pinSurplus: this.differencePin > 0 ? cashConv(this.differencePin, "EUR", 2) : 0, // BQ
							pinDeficit: this.differencePin < 0 ? cashConv(Math.abs(this.differencePin), "EUR", 2) : 0, // BQ
							creditcardSurplus: this.differenceCreditcard > 0 ? cashConv(this.differenceCreditcard, "EUR", 2) : 0, // BQ
							creditcardDeficit: this.differenceCreditcard < 0 ? cashConv(Math.abs(this.differenceCreditcard), "EUR", 2) : 0 // BQ
						})
						.then((docRef) => {
							console.log("Cash Up =>", docRef.id);
							if (type === "force") {
								//With force to Promise
								let countForcePossibllity = 0;
								let countForcePossibllityDone = 0;
								new Promise((resolve) => {
									if (this.cashUpdifference > 0) {
										if (this.cashUpSumCash - this.cashStatement > 0) {
											countForcePossibllity++;
											db.collection("cashRegister")
												.add({
													postToTable: "thrive_cashRegister_v1",
													byResource: this.userId,
													depositName: "Forced Cash Surplus",
													deposit: this.cashUpSumCash - this.cashStatement,
													depositType: "cash",
													type: "deposit",
													register: this.selectedCashRegister,
													branchId: this.$store.state.activeBranch.id,
													companyId: this.$store.state.activeCompany.id,
													created: new Date(),
												})
												.then((docRefDeposit) => {
													console.log("Deposit saved to database =>", docRefDeposit.id);
													if (countForcePossibllityDone == countForcePossibllity) {
														resolve();
													}
												})
												.catch((err) => {
													console.error("Can't save to database", err);
												});
										}

										//CARD Deposit
										if (this.cashUpSumByCard.byPIN > 0) {
											//PIN
											countForcePossibllity++;
											db.collection("cashRegister")
												.add({
													postToTable: "thrive_cashRegister_v1",
													byResource: this.userId,
													depositName: "Forced Pin Surplus",
													deposit: this.cashUpSumByCard.byPIN,
													depositType: "card",
													type: "deposit",
													register: this.selectedCashRegister,
													branchId: this.$store.state.activeBranch.id,
													companyId: this.$store.state.activeCompany.id,
													created: new Date(),
												})
												.then((docRefDeposit) => {
													console.log("Deposit saved to database =>", docRefDeposit.id);
													if (countForcePossibllityDone == countForcePossibllity) {
														resolve();
													}
												})
												.catch((err) => {
													console.error("Can't save to database", err);
												});
										}
										if (this.cashUpSumByCard.byCreditcard > 0) {
											countForcePossibllity++;
											//Creditcard
											db.collection("cashRegister")
												.add({
													postToTable: "thrive_cashRegister_v1",
													byResource: this.userId,
													depositName: "Forced Creditcard Surplus",
													deposit: this.cashUpSumByCard.byCreditcard,
													depositType: "card",
													type: "deposit",
													register: this.selectedCashRegister,
													branchId: this.$store.state.activeBranch.id,
													companyId: this.$store.state.activeCompany.id,
													created: new Date(),
												})
												.then(() => {
													console.log("Deposit saved to database  =>", docRefDeposit.id);
													if (countForcePossibllityDone == countForcePossibllity) {
														resolve();
													}
												})
												.catch((err) => {
													console.error("Can't save to database", err);
												});
										}
									} else {
										//Expense negative
										db.collection("cashRegister")
											.add({
												postToTable: "thrive_cashRegister_v1",
												byResource: this.userId,
												expenseName: "Forced Cash Difference",
												typeExpenses: "cashDifference",
												type: "expense",
												expense: Math.abs(this.cashUpDifference),
												register: this.selectedCashRegister,
												branchId: this.$store.state.activeBranch.id,
												companyId: this.$store.state.activeCompany.id,
												created: new Date(),
											})
											.then((docRefExpense) => {
												console.log("Expense saved to database =>", docRefExpense.id);
												resolve();
											})
											.catch((err) => {
												console.error("Can't save to database", err);
											});
									}
								}).then(() => {
									// Wipe out
									console.log("Force done...");
									//Overall Deposit positive
									this.closeCashUp("close");
								});
								//Without Force
							} else {
								// . Wipe Out
								this.closeCashUp("close");
							}
						})
						.catch((err) => {
							console.error("Can't add Cash Up", err);
						});
				})
				.catch((err) => {
					console.error("Can't Update CashRegister", err);
				});
		},

    checkDepositNumbers(times, orginDeposit) { // Kan het bedrag gestort worden met deze kasinhoud. Voorbeeld: Met 4 x 20 euro biljetten in de kassa kan je geen €50 storten
      let cashNumbers = new Array();
      for(var i=0; i<(this.cashUp.fiveEuro); i++){cashNumbers.push(5)}
      for(var i=0; i<(this.cashUp.tenEuro); i++){cashNumbers.push(10)}
      for(var i=0; i<(this.cashUp.twentyEuro); i++){cashNumbers.push(20)}
      for(var i=0; i<(this.cashUp.fiftyEuro); i++){cashNumbers.push(50)}
      for(var i=0; i<(this.cashUp.hundredEuro); i++){cashNumbers.push(100)}
      for(var i=0; i<(this.cashUp.twoHundredEuro); i++){cashNumbers.push(200)}
      for(var i=0; i<(this.cashUp.fiveHundredEuro); i++){cashNumbers.push(500)}
      console.log("cashNumbers: ", cashNumbers)


      function getFirstSubset(array, sum) {

          function fork(i = 0, s = 0, t = []) {
              if (s === sum) {
                  return  t;
              }
              if (i === array.length) {
                  return;
              }
              return fork(i + 1, s + array[i], t.concat(array[i]))
                  || fork(i + 1, s, t);
          }

          return fork();
      }

      let depositCombination = getFirstSubset(cashNumbers, this.cashUpDepositToBank)


      if(!depositCombination || depositCombination.length == 0){
       
        if(this.cashStatementStay.value > 110){
          let addAmount = 10
          if(times == 1){
            addAmount = 5
          }
          if(times == 3){
            addAmount = 20
          }
          if(times == 4){
            addAmount = 50
          }
          if(times == 5){
            addAmount = 100
          }
          if(times == 6){
            addAmount = 200
          }
          if(times == 7){
            addAmount = 500
          }
          if(times < 8){
            console.log("Kan niet gestort worden. Poging: ", times, "Storten", this.cashUpDepositToBank)
            this.cashUpDepositToBank = orginDeposit + addAmount
            this.cashStatementStay.value = cashConv(this.cashUpSumCash - this.cashUpDepositToBank, "EUR", 2) 
            this.checkDepositNumbers(times+1, orginDeposit)
          }
          else{
            console.log("Alle hope is gone")
            this.cashUpDepositToBank = 0;
            this.cashStatementStay.value = cashConv(this.cashUpSumCash - this.cashUpDepositToBank, "EUR", 2) 
          }
        }
        else{
          this.cashUpDepositToBank = 0;
          this.cashStatementStay.value = cashConv(this.cashUpSumCash - this.cashUpDepositToBank, "EUR", 2) 
        }
        
      }
      else{
        console.log("checkDepositNumbers: ", orginDeposit, "Poging: ", times)
        console.log("Kan gestort worden. Poging: ", times)
      }
    },

    calculateCashStatement(value, moneyName) {
			if (value < 0) {
				this.cashUp[moneyName] = Number(0);
			}

			for (const key in this.cashUp) {
				if (this.cashUp[key] !== 0) {
					this.cashUpFeedback.message = null;
					this.cashUpFeedback.box = null;
				}
			}

			this.cashUpSumTotal =
				this.cashUp.oneEuroCent * cashConv(0.01, "EUR", 2) +
				this.cashUp.twoEuroCent * cashConv(0.02, "EUR", 2) +
				this.cashUp.fiveEuroCent * cashConv(0.05, "EUR", 2) +
				this.cashUp.tenEuroCent * cashConv(0.1, "EUR", 2) +
				this.cashUp.twentyEuroCent * cashConv(0.2, "EUR", 2) +
				this.cashUp.fiftyEuroCent * cashConv(0.5, "EUR", 2) +
				this.cashUp.oneEuro * cashConv(1, "EUR", 2) +
				this.cashUp.twoEuro * cashConv(2, "EUR", 2) +
				this.cashUp.fiveEuro * cashConv(5, "EUR", 2) +
				this.cashUp.tenEuro * cashConv(10, "EUR", 2) +
				this.cashUp.twentyEuro * cashConv(20, "EUR", 2) +
				this.cashUp.fiftyEuro * cashConv(50, "EUR", 2) +
				this.cashUp.hundredEuro * cashConv(100, "EUR", 2) +
				this.cashUp.twoHundredEuro * cashConv(200, "EUR", 2) +
				this.cashUp.fiveHundredEuro * cashConv(500, "EUR", 2) +
				this.cashUp.pin +
				this.cashUp.creditcard;

			// TODO onderverdelen PIN en CREDITCARD

			this.cashUpSumByCard.byPIN = this.cashUp.pin;
			this.cashUpSumByCard.byCreditcard = this.cashUp.creditcard;

			// Maximum Cash Up

			// TODO Optellen zonder pin en creditcard

			this.cashUpSumCash =
        this.cashUp.oneEuroCent * cashConv(0.01, "EUR", 2) +
        this.cashUp.twoEuroCent * cashConv(0.02, "EUR", 2) +
        this.cashUp.fiveEuroCent * cashConv(0.05, "EUR", 2) +
        this.cashUp.tenEuroCent * cashConv(0.1, "EUR", 2) +
        this.cashUp.twentyEuroCent * cashConv(0.2, "EUR", 2) +
        this.cashUp.fiftyEuroCent * cashConv(0.5, "EUR", 2) +
        this.cashUp.oneEuro * cashConv(1, "EUR", 2) +
        this.cashUp.twoEuro * cashConv(2, "EUR", 2) +
        this.cashUp.fiveEuro * cashConv(5, "EUR", 2) +
        this.cashUp.tenEuro * cashConv(10, "EUR", 2) +
        this.cashUp.twentyEuro * cashConv(20, "EUR", 2) +
        this.cashUp.fiftyEuro * cashConv(50, "EUR", 2) +
        this.cashUp.hundredEuro * cashConv(100, "EUR", 2) +
        this.cashUp.twoHundredEuro * cashConv(200, "EUR", 2) +
        this.cashUp.fiveHundredEuro * cashConv(500, "EUR", 2);

      // Hoeveel blijft er in de kassa? (Maximaal 150 euro)
			console.log("cashStatementStayManualChanged: ", this.cashStatementStayManualChanged)

      if(!this.cashStatementStayManualChanged){
				console.log("Wat blijft erin de kassa?")
        let smallChange = cashConv(
          (this.cashUp.oneEuroCent * 0.01) +
          (this.cashUp.twoEuroCent * 0.02) +
          (this.cashUp.fiveEuroCent * 0.05) +
          (this.cashUp.tenEuroCent * 0.1) +
          (this.cashUp.twentyEuroCent * 0.2) +
          (this.cashUp.fiftyEuroCent * 0.5) +
          (this.cashUp.oneEuro * 1) +
          (this.cashUp.twoEuro * 2),"EUR", 2);

        if((this.cashUpSumCash - smallChange) > 150){
          this.cashStatementStay.value = cashConv(150 + smallChange, "EUR", 2);
          
          this.cashUpDepositToBank = cashConv(this.cashUpSumCash - smallChange - 150, "EUR", 2);
          this.checkDepositNumbers(1, _.cloneDeep(this.cashUpDepositToBank)); // Kan het bedrag gestort worden met deze kasinhoud. Voorbeeld: Met 4 x 20 euro biljetten in de kassa kan je geen €50 storten
        }
        else{
          this.cashStatementStay.value = cashConv(this.cashUpSumCash, "EUR", 2);
          this.cashUpDepositToBank = cashConv(0, "EUR", 2);
        }
      }
      else{
        this.cashUpDepositToBank = this.cashStatementStay.value > 0 ? cashConv(this.cashUpSumCash - this.cashStatementStay.value, "EUR", 2) : 0;
      }
      
      let statements = this.pinIncomeStatement + this.cashStatement;
      console.log("statements:", statements)

      this.differenceCash = 0;
      if(this.cashStatement >= 0){
        this.differenceCash = cashConv(this.cashUpSumCash - (this.cashStatement + this.latestCashRounding), "EUR", 2);
      }
      else{
        this.differenceCash = cashConv((this.cashStatement + this.latestCashRounding) + (this.cashUpSumCash - (this.cashStatement + this.latestCashRounding)), "EUR", 2);
      }

      this.differencePin = 0;
      if(this.pinIncomeStatement >= 0){
        this.differencePin = cashConv(this.cashUp.pin - this.pinIncomeStatement, "EUR", 2);
      }
      else{
        this.differencePin = cashConv(this.cashStatement + (this.cashUp.pin - this.pinIncomeStatement), "EUR", 2);
      }

      // let differencePin =  this.pinIncomeStatement - this.cashUp.pin;
      let difference = (this.differenceCash + this.differencePin);

      console.log("difference:", difference)

			if (difference != 0 && difference >= -10 && difference <= 10) {
				this.cashUpFeedback.box = "neutral";
				if(difference < 0){
						this.cashUpFeedback.message = `<span style="font-size:18px; font-weight: bold"> €${Math.abs(difference).toFixed(2)}</span> kas tekort`
				}
				else{
						this.cashUpFeedback.message = `<span style="font-size:18px; font-weight: bold"> €${Math.abs(difference).toFixed(2)}</span> kas teveel`
				}
			}
			else if (difference > 10) {
				this.cashUpFeedback.message = `Kasverschil <BR> <span style="font-size:24px; font-weight: bold"> €${Math.abs(difference).toFixed(
					2
				)} Kas teveel </span> <BR> Zorg dat de kassalade volledig is geteld. Zijn alle bonnen en uitgaven goed ingevoerd? Is dit niet het geval, klik dan op annuleer en voer deze eerst in.`;
				this.cashUpFeedback.box = "red";
			}
			else if (difference < -10) {
				this.cashUpFeedback.message = `Kasverschil <BR> <span style="font-size:24px; font-weight: bold">  €${Math.abs(difference).toFixed(
					2
				)} Kas tekort </span> <BR> Zorg dat de kassalade volledig is geteld. Zijn alle bonnen en uitgaven goed ingevoerd? Is dit niet het geval, klik dan op annuleer en voer deze eerst in.`;
				this.cashUpFeedback.box = "red";
			}
			else if (difference === 0) {
				console.log("actief controlleren");
				let checkEverythingisFilledRight = false;

				for (const key in this.cashUp) {
					if (this.cashUp[key] !== 0) {
						checkEverythingisFilledRight = true;
						break;
					}
				}

				if (checkEverythingisFilledRight === true) {
					this.cashUpFeedback.message = `<span style="font-size: 18px; color: #ffffff"><strong>Geen kasverschil!</strong></span>`;
					this.cashUpFeedback.box = "green";
				}
			}
		},
  },
  computed:{
    userId() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
		},
  }
};
</script>