<template>
  <v-dialog persistent v-model="showDialog" scrollable max-width="700px" max-height="900px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card v-if="treatment">
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type == 'edit'">{{ $t("treatments.editTreatment", $store.state.locale) }}</h3>
        <h3 v-if="type == 'new'">{{ $t("treatments.addTreatment", $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeTreatmentDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-alert v-if="feedbackTreatment" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning" v-html="feedbackTreatment"></v-alert>

      <v-tabs :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="tab">
        <v-tab><v-icon left>mdi-information</v-icon> {{ $t('treatments.basic', $store.state.locale)}}</v-tab>
        <v-tab><v-icon left>mdi-calendar-blank</v-icon>{{ $t('treatments.schedule', $store.state.locale)}}</v-tab>
        <v-tab><v-icon left>mdi-package-variant-closed</v-icon>{{ $t('treatments.usage', $store.state.locale)}}</v-tab>
        <v-tab><v-icon left>mdi-cash-multiple</v-icon>{{ $t('general.price', $store.state.locale)}}</v-tab>
        <v-tab><v-icon left>mdi-office-building</v-icon>{{ $t('general.branches', $store.state.locale)}}</v-tab>
        <v-tab v-if="!treatment.eventBooking"><v-icon left>mdi-account</v-icon>{{ $t('general.employees', $store.state.locale)}}</v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-card-text v-show="treatment" style="height: 600px; padding: 0; margin: 0;" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].card }">
        <v-tabs-items v-model="tab">
          <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">

              <v-card outlined color="background" v-if="type == 'edit' && $store.state.userRoleNumber < 2 && !$store.state.demoMode">
                <v-btn @click="copyTextToClipboard(treatment.id)" class="ma-1 mb-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="color: #ffffff; text-transform: none;"><strong>{{treatment.id}}</strong></v-btn>
                <div style="display: inline-block" v-if="treatmentIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(treatment.id)"><v-icon small color="#333">mdi-content-copy</v-icon>Kopieër DocId</div>
                <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
              </v-card>

                <!-- / Select imput language \ -->
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="pa-2 mb-4">
                  <v-layout row wrap class="ma-0 pa-0">
                    <v-flex xs12> 
                      <v-avatar
                        size="54"
                        light
                        style="float: left"
                        class="ma-0" 
                        rounded="lg"
                        color="#ffffff" 
                        >
                        <country-flag class="ma-0 pa-0" :country="getFlagCode(selectedLanguage)" />
                      </v-avatar>
                      <v-select
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :disabled="availableLanguages.length == 1"
                        :items="availableLanguages"
                        :item-text="(item) => item.languageName[$store.state.locale.toLowerCase()]"
                        :label="$t('general.language', $store.state.locale)"
                        item-value="id"
                        dense
                        outlined
                        hide-details
                        class="mt-2 pl-2"
                        v-model="selectedLanguage"
                      ></v-select> 
                    </v-flex>
                  </v-layout>
                </v-card>
                <!-- \ Select imput language / -->

              <v-container grid-list-md class="ma-0 pa-0">
                <v-text-field
                  :label="$t('general.name', $store.state.locale) + `(${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
                  v-model="treatment.treatmentTitle[selectedLanguage]"
                  v-on:input="copyTreatmentTitle()"
                  counter
                  maxlength="70"
                  autocomplete
                  outlined
                  dense
                  required
                  class="mt-4"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  
                ></v-text-field>

                <v-text-field
                  :label="$t('treatments.nameIntern', $store.state.locale) + `(${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
                  v-model="treatment.treatmentTitleIntern[selectedLanguage]"
                  counter
                  v-on:input="titleInternChanged = true"
                  maxlength="70"
                  autocomplete
                  required
                  dense
                  outlined
                  class="mt-0"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                ></v-text-field>

                <v-text-field
                  :label="$t('treatments.nameReceipt', $store.state.locale) + `(${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
                  v-model="treatment.treatmentTitleReceipt[selectedLanguage]"
                  
                  required
                  counter
                  maxlength="70"
                  outlined
                  dense
                  class="mt-0"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                ></v-text-field>

                <v-layout row wrap>
                  <v-flex xs6>
                    <v-select
                      :label="$t('general.type', $store.state.locale)"
                      v-model="treatment.treatmentType"
                      :items="treatmentTypes"
                      :item-text="(item) => $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name)"
                      item-value="id"
                      class="mt-0"
                      dense
                      outlined
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-select>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      v-if="customTreatmentTypes.length > 0"
                      :label="$t('treatments.headers.customTreatmentType', $store.state.locale)"
                      v-model="treatment.customTreatmentType"
                      :items="customTreatmentTypes"
                      :item-text="(item) => $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name)"
                      item-value="id"
                      class="mt-0"
                      dense
                      outlined
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-select>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :label="$t('general.gender', $store.state.locale)"
                      v-model="treatment.treatmentGender"
                      :items="genders"
                      item-text="name"
                      item-value="id"
                      class="mt-4"
                      dense
                      outlined
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-select>
                  </v-flex>
                  <v-flex xs6></v-flex>
                </v-layout>

                <v-text-field
                  :label="$t('treatments.shortDescription', $store.state.locale) + `(${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
                  v-model="treatment.treatmentDesc[selectedLanguage]"
                  counter
                  maxlength="56"
                  required
                  dense
                  outlined
                  class="mt-4"
                  hide-details
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                ></v-text-field>

                <v-layout row wrap>
                  <v-flex xs3>
                    <v-switch 
                      class="ma-0 mt-4 pa-0"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :label="$t('general.active', $store.state.locale)"
                      v-model="treatment.treatmentActive"
                      hide-details
                    ></v-switch>
                  </v-flex>
                  <v-flex xs4 v-if="treatment.treatmentActive">
                    <v-switch
                      class="ma-0 mt-4 pa-0"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :label="$t('treatments.showOnline', $store.state.locale)"
                      v-model="treatment.treatmentOnline"
                      hide-details
                    ></v-switch>
                  </v-flex>
                  <v-flex xs5>
                    <v-switch
                      class="ma-0 mt-4 pa-0"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :label="$t('treatments.treatmentIdSubscription', $store.state.locale)"
                      v-model="treatment.eventBooking"
                      hide-details
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </v-tab-item>

          <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-select
                v-model="treatment.bookingTimeLimit"
                :label="$t('treatments.timelimit', $store.state.locale)"
                :items="bookingTimeLimitItems"
                item-text="name"
                item-value="value"
                outlined
                dense
                :hint="$t('treatments.timelimitHint', $store.state.locale)"
                persistent-hint
                class="my-4"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              ></v-select>

              <v-switch
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                v-model="treatment.customTimeIteration"
                v-on:change="setCustomTimeIteration()"
                >
                <template slot='label'>
                  <span style="margin-right: 5px;">{{ $t('treatments.uniqueTimesteps', $store.state.locale) }}</span>
                  <v-tooltip class="ma-0 pa-0" bottom>
                    <template v-slot:activator="{on}">
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">mdi-help-circle</v-icon>
                    </template>
                    <span style="color: #fff;">
                      {{ $t('treatments.uniqueTimestepsHintOne', $store.state.locale) }}
                      <i>{{ $t('treatments.uniqueTimestepsHintTwo', $store.state.locale) }}</i>.
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              
              <v-select
                v-if="treatment.customTimeIteration"
                v-model="treatment.timeIteration"
                :items="appointmentTimeIterations" 
                :label="$t('treatments.widgetTimesteps', $store.state.locale)"
                item-text="name"
                item-value="time"
                prepend-inner-icon="mdi-clock-outline"
                required
                dense
                outlined
                class="mb-4"
                persistent-hint
                :hint="$t('treatments.widgetTimestepsHint', $store.state.locale)"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                
              ></v-select>

              <div v-if="treatment.subTreatments.length == 0" class="text-center"><strong>{{ $t('treatments.addSubtreatments', $store.state.locale) }}</strong></div>

              <v-card flat outlined id="subtreatments" class="subtreatments-wrapper" v-if="treatment.subTreatments.length > 0">
                <draggable v-model="treatment.subTreatments" @start="drag=true" @end="drag=false">
                  <div class="subtreatment clear" v-for="(subtreatment, index) in treatment.subTreatments" :key="index">
                  
                    <div>
                      <div class="handle">
                        <div class="icon">
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-arrow-all</v-icon>
                        </div>
                      </div>
                      <div class="color">
                        <div
                          class="color-square"
                          v-bind:style="{ background: subtreatment.eventColor ?  subtreatment.eventColor : $store.state.activeBranch.calendarSettings.colorWithPreference }"
                        ></div>
                      </div>
                      <div class="description" :style="{color: $vuetify.theme.themes[theme].text}">
                        
                        <b>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, subtreatment.name) }}</b> 
                        <br>
                        {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, subtreatment.description) }}
                        <br>
                        {{ subtreatment.time }} {{ $t('general.minutes', $store.state.locale) }}
                      </div>
                      <div class="type">
                        <span v-if="subtreatment.device == 1 || subtreatment.device == 3 || subtreatment.device == 4 || subtreatment.device == 5" :style=" { color: $vuetify.theme.themes[theme].tertiary }">
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-account</v-icon> {{ $t('general.employee', $store.state.locale) }}
                        </span>
                        <span v-if="subtreatment.device == 2 || subtreatment.device == 3" :style=" { color: $vuetify.theme.themes[theme].tertiary }">
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-power-plug</v-icon> {{ $t('general.device', $store.state.locale) }}
                        </span>
                        <span v-if="subtreatment.device == 5 || subtreatment.device == 6" :style=" { color: $vuetify.theme.themes[theme].tertiary }">
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-square-outline</v-icon> {{ $t('general.room', $store.state.locale) }}
                        </span>
                      </div>
                      <div class="edit" @click="editSubTreatment(subtreatment, index)">
                        <div class="icon">
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                        </div>
                      </div>
                      <div class="close" @click="removeSubTreatment(index)">
                        <div class="icon">
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon>
                        </div>
                      </div>
                    </div>
                    <v-divider v-if="index > 0"></v-divider>
                  </div>
                </draggable>
              </v-card>
              <div class="text-center mt-4">
                <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="newSubTreatmentDialog = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </div>
          </v-tab-item>

          <v-tab-item :value="2" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">

              <div class="py-4 text-center"><h3>{{ $t('treatments.addUsageProducts', $store.state.locale) }}</h3></div>

              <v-card flat outlined v-if="treatment.usage && treatment.usage.length > 0" style="overflow: hidden" :key="treatment.usage.length">
                <div v-for="(usedProduct, index) in treatment.usage" :key="index">
                  <v-divider v-if="index > 0"></v-divider>
                  <v-list class="pa-0" dense>
                    <v-list-item v-if="getProductInfo(usedProduct.product)">
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>{{ getProductInfo(usedProduct.product).name }} </strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ getProductInfo(usedProduct.product).content }}
                        </v-list-item-subtitle> 
                      </v-list-item-content>
                      <v-list-item-avatar
                        size="42"
                        light
                        class="my-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                      {{ usedProduct.percentage }}%
                      </v-list-item-avatar>
                      <v-list-item-action class="mx-1 ml-4 px-0">
                        <v-btn icon outlined class="mx-0 px-0">
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small @click="editUsage(usedProduct, index)">mdi-pencil</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action class="mx-1 px-0">
                        <v-btn icon outlined class="mx-0 px-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" small @click="removeUsage(index)">mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </div>
              </v-card>

              <div class="text-center pa-4">
                <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="productUsageDialog()">
                  <v-icon >mdi-plus</v-icon>
                </v-btn>
              </div>

            </div>
          </v-tab-item>
          
          <v-tab-item :value="3" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-switch
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                v-model="treatment.free"
                >
                <template slot='label'>
                  <span style="margin-right: 5px;">{{ $t('treatments.treatmentFree', $store.state.locale) }}</span>
                  <v-tooltip class="ma-0 pa-0" right>
                    <template v-slot:activator="{on}">
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">mdi-help-circle</v-icon>
                    </template>
                    <span style="color: #fff;">{{ $t('treatments.treatmentFreeHint', $store.state.locale) }}</span>
                  </v-tooltip>
                </template>
              </v-switch>

              <div v-if="!treatment.free" >
                <v-select
                  v-model="treatment.vat"
                  :label="$t('treatments.vatRate', $store.state.locale)"
                  :items="$store.state.vatItems"
                  :item-text="(item)=> item.percentage + '%'"
                  item-value="id"
                  v-on:input="setTreatmentVatPercentage()"
                  outlined
                  dense
                  class="my-4"
                  hide-details
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                ></v-select>


                <v-container grid-list-md class="ma-0 pa-2">
                  <v-alert
                    v-if="!treatment.vat && treatment.subTreatments.length > 0"
                    :value="true"
                    type="warning"
                    class="ma-0  mb-3 px-2"
                    v-html="$t('treatments.vatRateAlert', $store.state.locale)"
                    >
                  </v-alert>

                  <p style="text-align: center; padding: 10px;" class="ma-0" v-if="treatment.subTreatments.length == 0 || !treatment.vat">
                    <v-icon small color="secondary">mdi-alert</v-icon> <span v-html="$t('treatments.addSubtreatmentsHint', $store.state.locale)"></span>
                  </p>
                  <div v-else>
                    <v-layout row wrap v-for="(subTreatment, index) in treatment.subTreatments" :key="index">
                      <v-flex md4>
                        <v-text-field
                          :background-color="subTreatment.eventColor"
                          :label="`${subTreatment.time} ${$t('general.minutes', $store.state.locale)}`"
                          v-model="subTreatment.name[($store.state.locale).toLowerCase()]"
                          readonly
                          class="ma-0 mt-2 pa-0"
                          outlined
                          dense
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4>
                        <v-text-field
                          style="background-color: #fff;"
                          :label="$t('treatments.nettoPrice', $store.state.locale)"
                         
                          :value="roundCashNumber(subTreatment.priceNetto)"
                          disabled
                          dont-fill-mask-blanks
                          prefix="€"
                          class="ma-0 mt-2 pa-0"
                          v-on:input="calculatePricesSubtreatment(index, 'netto')"
                          outlined
                          dense
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4>
                        <v-text-field
                          style="background-color: #fff;"
                          :label="$t('treatments.grossPrice', $store.state.locale)"
                          v-model.number="subTreatment.priceBruto"
                          dont-fill-mask-blanks
                          prefix="€"
                          dense
                          class="ma-0 mt-2 pa-0"
                          v-on:input="calculatePricesSubtreatment(index, 'bruto')"
                          outlined
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        ></v-text-field>
                      </v-flex>
                    </v-layout>

                    <v-layout style="margin: 15px 0 5px 0">
                      <v-flex md4><p class="ma-0" style="font-size: 16px;">{{ $t('treatments.total', $store.state.locale) }}</p></v-flex>
                      <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ roundCashNumber(treatment.treatmentPrice) }}</p></v-flex>
                      <v-flex md4><p class="ma-0" style="font-size: 16px;">&euro; {{ roundCashNumber(treatment.brutoPrice) }}</p></v-flex>
                    </v-layout>

                  </div>
                </v-container>
  
                <h3 class="py-4">{{ $t('treatments.priceAdjustments.title', $store.state.locale) }}</h3>

                <div v-if="showDialog && treatment.brutoPrice && treatment.branches.length > 0 && Object.keys(treatment.branchPriceAdjustments).length > 0">
                  <v-row v-for="(branch, index) in treatment.branches" :key="index" align="center" >
                    <v-col cols="3" class="py-2">
                      <strong>{{ getBranchName(branch)}}</strong>
                    </v-col>
                    <v-col cols="3" class="py-2">
                      <v-select
                        v-model="treatment.branchPriceAdjustments[branch].adjustmentType"
                        :label="$t('general.adjustments', $store.state.locale)"
                        :items="priceAdjustmentTypes"
                        item-text="name"
                        item-value="id"
                        v-on:input="setTreatmentPriceAdjustment(branch, 'adjustmentTypeChange')"
                        outlined
                        dense
                        class="ma-0"
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      ></v-select>
                    </v-col>
                    <v-col cols="3" class="py-2">
                      <v-text-field
                        v-if="treatment.branchPriceAdjustments[branch].adjustmentType != 'noChanges'"
                        style="background-color: #fff;"
                        :label="$t('general.amount', $store.state.locale)"
                        v-model.number="treatment.branchPriceAdjustments[branch].numberBruto"
                        dont-fill-mask-blanks
                        prefix="€"
                        dense
                        class="ma-0"
                        v-on:input="setTreatmentPriceAdjustment(branch)"
                        outlined
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="py-2" :key="treatmentPriceAdjustments">
                      <v-text-field
                        v-if="treatment.branchPriceAdjustments[branch].adjustmentType != 'noChanges'"
                        style="background-color: #fff;"
                        :label="$t('general.total', $store.state.locale)"
                        v-model.number="treatment.branchPriceAdjustments[branch].totalAmountBruto"
                        dont-fill-mask-blanks
                        prefix="€"
                        dense
                        readonly
                        class="ma-0"
                        outlined
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <v-alert v-else type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-2 mb-0 custom-alert">
                  <h4>{{ $t('treatments.priceAdjustments.noAdjustmentsTitle', $store.state.locale) }}</h4>
                  <span>{{ $t('treatments.priceAdjustments.noAdjustmentsTitleExtra', $store.state.locale) }}</span>
                </v-alert>

          
              </div>
            </div>
          </v-tab-item>

          <v-tab-item :value="4" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-select
                :label="$t('general.branches', $store.state.locale)"
                v-model="treatment.branches"
                :items="$store.state.userBranches"
                multiple
                item-text="name"
                item-value="id"
                outlined
                dense
                v-on:change="setTreatmentPriceAdjustmentFirst()"
                small-chips
                chips
                class="my-4"
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :no-data-text=" $t('branch.noBranchesFound', $store.state.locale)" 
              ></v-select>
            </div>
          </v-tab-item>

          <v-tab-item :value="5" :transition="tabTransition" :reverse-transition="tabTransition" v-if="!treatment.eventBooking">
            <div class="tab-component-content">
              <v-select
                :label="$t('general.employees', $store.state.locale)"
                v-model="treatment.employees"
                :items="employees"
                multiple
                item-text="name"
                item-value="id"
                :hint="$t('treatments.employeesHint', $store.state.locale)"
                persistent-hint
                required
                chips
                dense
                small-chips
                outlined
                class="my-4"
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :no-data-text=" $t('employees.noEmployeesFound', $store.state.locale)"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              ></v-select>

              <v-switch v-if="treatment.employees.length > 1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="treatment.preferredPractitioner">
                <template slot='label'>
                  <span style="margin-right: 5px;">{{ $t('treatments.employeesSort', $store.state.locale) }}</span>
                  <v-tooltip class="ma-0 pa-0" bottom>
                    <template v-slot:activator="{on}">
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">mdi-help-circle</v-icon>
                    </template>
                    <span style="color: #fff;" v-html="$t('treatments.employeesSortHint', $store.state.locale)"></span>
                  </v-tooltip>
                </template>
              </v-switch>

              <v-radio-group v-model="treatment.competenceSort">
                <v-radio
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :label="$t('treatments.employeeNotByCompetence', $store.state.locale)"
                  value="noCompetency"
                ></v-radio>
                <v-radio
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :label="$t('treatments.employeeByCompetence', $store.state.locale)"
                  value="alwaysUseCompetency"
                ></v-radio>
                <v-radio
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :label="$t('treatments.employeeByCompetenceNewClients', $store.state.locale)"
                  value="useCompetencyByNewClientsOnly"
                ></v-radio>
              </v-radio-group>

              <v-card outlined :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }" v-show="treatment.employees.length > 1 && treatment.preferredPractitioner">
                <h4 class="pa-2">{{ $t('treatments.employeesSortDrag', $store.state.locale) }}</h4>
                <v-list flat dense class="ma-0 pa-0">
                  <draggable v-model="treatment.employees" style="min-height: 10px">     
                    <v-list-item-group  v-for="(item, index) in treatment.employees" :key="index" class="ma-0 pa-0">
                      <v-divider></v-divider>
                      <v-list-item :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }" class="ma-0">
                        <v-list-item-icon>
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-arrow-all</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-for="(employee, index) in employees" :key="index">
                              <span v-if="employee.id == item">{{ employee.name}} </span>
                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </draggable>
                </v-list> 
              </v-card>

            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeTreatmentDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type == 'edit'"
                :disabled=" !changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateTreatment()"
                >
                <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>
              </v-btn>

              <v-btn
                v-if="type == 'new'"
                dark  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addTreatment()"
                >
                {{ $t('treatments.saveTreatment', $store.state.locale) }}  
                <v-icon right dark>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

      <!-- / Product Usage Dialog \ -->
      <v-dialog v-model="productUsage.dialog" persistent scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h2 v-if="productUsage.type == 'new'">{{ $t('treatments.addProductUsage', $store.state.locale) }}</h2>
            <h2 v-else>{{ $t('treatments.editProductUsage', $store.state.locale) }}</h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="productUsage.dialog = false">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          
          <v-alert v-if="productUsage.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ productUsage.feedback }}</v-alert>

          <v-divider></v-divider>

          <v-card-text class="pa-0 ma-0">

            

            <v-form ref="newSubtreatmentForm">
              <v-container class="pa-0">
                <v-layout row wrap class="ma-0 custom-algolia ">
                  <v-flex xs12>

                    <v-text-field
                      class="pa-3"
                      dense
                      outlined
                      :placeholder="$t('payment.searchProduct', $store.state.locale)"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      prepend-inner-icon="mdi-magnify"
                      hide-details
                      :append-icon="productSearch && productSearch.length > 0 ? 'mdi-close' : ''"
                      @click:append="productSearch = '' "
                      v-model="productSearch"
                    ></v-text-field>

                    <v-divider></v-divider>
                    <div style="max-height: 250px; overflow-y: scroll" >
                      <ais-instant-search v-if="productSearch && productSearch.length > 0" :search-client="algoSearch" index-name="thrive_products" class="pa-0 ma-0">
                        <v-list class="pa-0 ma-0" >
                          <ais-hits class="pa-0 ma-0" >
                            <template slot="item" slot-scope="{ item }">
                              <v-list-item class="pa-0 px-3 ma-0" @click="productUsage.selectedProduct = item.objectID">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <ais-highlight attribute="name" :hit="item" highlightedTagName="mark" />
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    <ais-highlight attribute="price" :hit="item" highlightedTagName="mark" />
                                    {{  item.vat }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </ais-hits>
                        </v-list>
                        <ais-state-results>
                          <p class="text-center pa-2 pt-4 pb-1" slot-scope="{ state: { query }, results: { hits } }" v-show="!hits.length">
                            <i>{{ $t("clients.noResultsFoundFor", $store.state.locale) }} {{ query }}</i>
                          </p>
                        </ais-state-results>
                        <ais-configure :query="productSearch" :facetFilters="[`companyId:${this.$store.state.activeCompany.id}`]" />
                      </ais-instant-search>

                      <v-list v-else style="max-height: 250px; overflow-y: scroll" class="pa-0 ma-0" >
                        <div v-for="(item, index) in products" :key="index">
                          <v-divider v-if="index > 0"></v-divider>
                          <v-list-item class="pa-0 px-3 ma-0" @click="productUsage.selectedProduct = item.objectID">
                            <v-list-item-content>
                              <v-list-item-title>
                                <ais-highlight attribute="name" :hit="item" highlightedTagName="mark">
                                {{ item.name }}
                                </ais-highlight>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          
                        </div>
                      </v-list>
                    </div>
                    <v-divider></v-divider>
                  </v-flex>

                  <v-flex md12 class="pa-2">
                    <div v-if="productUsage.selectedProduct && getProductInfo(productUsage.selectedProduct)">
                      <h3 class="text-center pa-4 pb-2"> {{ $t('treatments.selectedProductUsage', $store.state.locale) }}</h3>
                      <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                        <v-list class="pa-0" dense>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                <strong>{{ getProductInfo(productUsage.selectedProduct).name }}</strong>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ getProductInfo(productUsage.selectedProduct).content }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card> 
                    </div>

                    <v-text-field
                      :label="$t('treatments.usagePercentage', $store.state.locale)"
                      v-model.number="productUsage.usagePercentage"
                      autocomplete
                      outlined
                      suffix="%"
                      dense
                      required
                      class="mt-4"
                      :hint="$t('treatments.usagePercentageHint', $store.state.locale)"
                      persistent-hint
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn
                    outlined
                    depressed
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                    @click="productUsage.dialog = false"
                    >
                    {{ $t('general.cancel', $store.state.locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn
                    v-if="productUsage.type == 'new'"
                    :dark="productUsageValid"
                    :disabled="!productUsageValid"
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="addTreatmentUsage()"
                    >
                    {{ $t('treatments.addUsage', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    :dark="usageChanged"
                    :disabled="!usageChanged"
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="editTreatmentUsage()"
                    >
                    {{ $t('general.saveChanged', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <!-- \ Product Usage Dialog / -->

      <!-- / New Subtreatment Dialog \ -->
      <v-dialog v-model="newSubTreatmentDialog" persistent scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h2>{{ $t('treatments.addItem', $store.state.locale) }}</h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="newSubTreatmentDialog = false, resetSubtreatmentTypes(), feedbackSubtreatment = null">
              <v-icon dark color="white">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          
          <v-alert v-if="feedbackSubtreatment" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedbackSubtreatment }}</v-alert>

          <v-divider></v-divider>

          <v-card-text class="pa-0">
            <v-form ref="newSubtreatmentForm">
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex md12 >
                    <!-- / Select imput language \ -->
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="pa-2 mb-0">
                      <v-layout row wrap class="ma-0 pa-0">
                        <v-flex xs12> 
                          <v-avatar
                            size="54"
                            light
                            style="float: left"
                            class="ma-0" 
                            rounded="lg"
                            color="#ffffff" 
                            >
                            <country-flag class="ma-0 pa-0" :country="getFlagCode(selectedLanguage)" />
                          </v-avatar>
                          <v-select
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :disabled="availableLanguages.length == 1"
                            :items="availableLanguages"
                            :item-text="(item) => item.languageName[$store.state.locale.toLowerCase()]"
                            :label="$t('general.language', $store.state.locale)"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            class="mt-2 pl-2"
                            v-model="selectedLanguage"
                          ></v-select>       
                        </v-flex>
                      </v-layout>
                    </v-card>
                    <!-- \ Select imput language / -->
                  </v-flex>

                  <v-flex md12>
                    <v-text-field
                      :label="$t('general.description', $store.state.locale) + `(${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
                      v-model="newSubtreatment.description[selectedLanguage]" 
                      required
                      counter="50"
                      outlined
                      class="mt-3"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      >
                    </v-text-field>
                  </v-flex>

                  <v-flex md12 @click="newSubTreatmentTypeDialog = true">
                    <v-menu
                      ref="subTreatmentTypeDialog"
                      v-model="newSubTreatmentTypeDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y
                      min-width="478px"
                      max-width="478px"
                      max-height="275px"
                      >
                      <template v-slot:activator="{on}">
                        <v-text-field
                          v-on="on"
                          v-model="newSubtreatment.name[($store.state.locale).toLowerCase()]" 
                          :label="$t('general.item', $store.state.locale)"
                          readonly
                          hide-details
                          outlined
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          dense
                          append-icon="mdi-menu-down"
                          class="custom-selector mt-3"
                        ></v-text-field>
                      </template>

                      <div v-for="(category,index) in organizedTreatmentTypes" :key="index + modifier" :style="{backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].card}">
                        <div class="pa-2" style="font-size: 16px; font-weight: bold;" :style="{backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background}">{{category.name[($store.state.locale).toLowerCase()]}}</div>
                        <v-divider></v-divider>
                        <div v-for="(type,i) in category.types" :key="i + modifier">
                          <div v-if="category.id == type.category">
                            <v-list class="py-0 custom-selector-item" style="cursor: pointer" :class="{chosen: type.chosen}" >
                              <v-list-item dense class="py-0 px-2" @click="selectSubtreamentItem(type, 'new')">
                      
                                <v-list-item-content class="pa-0">
                                <div style="font-size: 14px;" class="item-text">{{type.name[($store.state.locale).toLowerCase()]}}</div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                          </div>
                        </div>
                      </div>
                    </v-menu>
                  </v-flex>
                  
                  <v-flex md6>
                    <v-text-field
                      :label="$t('general.time', $store.state.locale)"
                      :hint="$t('treatments.timeHint', $store.state.locale)"
                      persistent-hint
                      v-model.number="newSubtreatment.time"
                      :suffix="$t('general.minutes', $store.state.locale)"
                      required
                      outlined
                      class="mt-3"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      >
                      </v-text-field>
                  </v-flex>

                  <v-flex md6></v-flex>

                  <v-flex md12>
                    <v-select
                      :label="$t('treatments.itemType', $store.state.locale)"
                      v-model="newSubtreatment.device"
                      :items="occupationTypes"
                      item-text="name"
                      item-value="id"
                      v-on:input="checkSubtreatmentDevice('new')"
                      required
                      outlined
                      class="mt-3"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      ></v-select>

                    <v-select
                      v-if="newSubtreatment.device == 2 || newSubtreatment.device == 3"
                      :label="$t('treatments.selectDevice', $store.state.locale)"
                      v-model="newSubtreatment.deviceId"
                      :items="deviceTypes"
                      :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                      item-value="id"
                      required
                      outlined
                      class="mt-3"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-select>

                    <v-select
                      v-if="newSubtreatment.device == 5 || newSubtreatment.device == 6"
                      :label="$t('treatments.selectRoom', $store.state.locale)"
                      v-model="newSubtreatment.rooms"
                      :items="rooms"
                      item-text="name"
                      item-value="data"
                      required
                      multiple
                      chips
                      small-chips
                      clearable
                      outlined
                      class="mt-3"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-select>

                    <v-switch
                      v-if="newSubtreatment.device == 4"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      v-model="newSubtreatment.withEventBlock"
                      v-on:change="setEventColor('new')"
                      hide-details
                      >
                      <template slot='label'>
                        <span style="margin-right: 5px;" v-html="$t('treatments.showInCalendar', $store.state.locale)"></span>
                        <v-tooltip class="ma-0 pa-0" right>
                          <template v-slot:activator="{on}">
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span style="color: #fff;" v-html="$t('treatments.showInCalendarHint', $store.state.locale)"></span>
                        </v-tooltip>
                      </template>
                    </v-switch>

                    <v-switch
                      v-if="newSubtreatment.device != 2 && newSubtreatment.device != 6 && newSubtreatment.withEventBlock"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      v-model="newSubtreatment.apprenticeTreatment"
                      hide-details
                      >
                      <template slot='label'>
                        <span style="margin-right: 5px;" v-html="$t('treatments.pushTreatmentToApprentice', $store.state.locale)"></span>
                        <v-tooltip class="ma-0 pa-0" right>
                          <template v-slot:activator="{on}">
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span style="color: #fff;" v-html="$t('treatments.pushTreatmentToApprenticeHint', $store.state.locale)"></span>
                        </v-tooltip>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex md12 v-if="newSubtreatment.device != 4 || (newSubtreatment.device == 4 && newSubtreatment.withEventBlock)">
                    <div class="clear">
                      <label>{{ $t('general.color', $store.state.locale) }}</label>
                      
                      <v-switch
                        :input-value="newSubtreatment.eventColor ? false : true"
                        @change="setCustomEventColor(newSubtreatment.eventColor, 'new')"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        hide-details
                        class="mb-2 mt-0 pt-1"
                        :label="$t('treatments.useBranchColor', $store.state.locale)"
                        >
                      </v-switch>
                      <div class="color-field" v-if="newSubtreatment.eventColor">
                        <swatches
                          v-model="newSubtreatment.eventColor"
                          :swatches="colors"
                          :wrapper-style="wrapperStyle"
                          :swatch-style="swatchStyle"
                          swatch-size="36"
                          show-border
                          inline
                        ></swatches>
                      </div>
                      <v-card outlined class="my-2 pa-2" v-else>
                        <div style="display: inline-block; width: 36px;height: 36px; border-radius: 9px; margin: 0 10px 0 0 " :style="{backgroundColor: $store.state.activeBranch.calendarSettings.colorWithPreference}"></div>
                        <div style="display: inline-block; width: calc(100% - 46px);text-align: left !important" v-html="$t('treatments.useBranchColorHint', $store.state.locale)"></div>
                      </v-card>
                      <div class="custom-hint">{{ $t('treatments.colorHint', $store.state.locale) }}</div>
                    </div>
                  
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn
                    outlined
                    depressed
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                    @click="newSubTreatmentDialog = false, resetSubtreatmentTypes(), feedbackSubtreatment = null"
                    >
                    {{ $t('general.cancel', $store.state.locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn
                    dark
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="addSubtreatment()"
                    >
                    {{ $t('general.add', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <!-- \ New Subtreatment Dialog / -->

      <!-- / Edit Subtreatment Dialog \ -->
      <v-dialog v-model="editSubTreatmentDialog" persistent max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h2>{{ $t('treatments.editItem', $store.state.locale) }}</h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="editSubTreatmentDialog = false, resetSubtreatmentTypes(), feedbackSubtreatment = null">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-alert v-if="feedbackSubtreatment" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedbackSubtreatment }}</v-alert>

          <v-divider></v-divider>

          <v-card-text class="pa-0">
            <v-form ref="editSubtreatmentForm">
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex md12 >
                    <!-- / Select imput language \ -->
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="pa-2 mb-0">
                      <v-layout row wrap class="ma-0 pa-0">
                        <v-flex xs12> 
                          <v-avatar
                            size="54"
                            light
                            style="float: left"
                            class="ma-0" 
                            rounded="lg"
                            color="#ffffff" 
                            >
                            <country-flag class="ma-0 pa-0" :country="getFlagCode(selectedLanguage)" />
                          </v-avatar>
                          <v-select
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :disabled="availableLanguages.length == 1"
                            :items="availableLanguages"
                            :item-text="(item) => item.languageName[$store.state.locale.toLowerCase()]"
                            :label="$t('general.language', $store.state.locale)"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            class="mt-2 pl-2"
                            v-model="selectedLanguage"
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-card>
                    <!-- \ Select imput language / -->
                  </v-flex>

                  <v-flex md12>
                    <v-text-field
                      :label="$t('general.description', $store.state.locale) + `(${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
                      v-model="editSubtreatmentItems.description[selectedLanguage]"
                      required
                      counter="50"
                      outlined
                      class="mt-3"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex md12 @click="editSubTreatmentTypeDialog = true">
                    <v-menu
                      ref="subTreatmentTypeDialog"
                      v-model="editSubTreatmentTypeDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y
                      min-width="478px"
                      max-width="478px"
                      max-height="275px"
                      >
                      <template v-slot:activator="{on}">
                        <v-text-field
                          v-on="on"
                          v-model="editSubtreatmentItems.name[($store.state.locale).toLowerCase()]" 
                          :label="$t('general.item', $store.state.locale)"
                          readonly
                          hide-details
                          outlined
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          dense
                          append-icon="mdi-menu-down"
                          class="custom-selector mt-3"
                        ></v-text-field>
                      </template>

                      <div v-for="(category,index) in organizedTreatmentTypes" :key="index + modifier" :style=" {backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].card}">
                        <div class="pa-2" style="font-size: 16px; font-weight: bold;" :style=" {backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }">{{category.name[($store.state.locale).toLowerCase()]}}</div>
                        <v-divider></v-divider>
                        <div v-for="(type,i) in category.types" :key="i + modifier">
                          <div v-if="category.id == type.category">
                            <v-list class="py-0 custom-selector-item" style="cursor: pointer" :class="{chosen: type.chosen}" >
                              <v-list-item dense class="py-0 px-2" @click="selectSubtreamentItem(type, 'edit')">
                      
                                <v-list-item-content class="pa-0">
                                <div style="font-size: 14px;" class="item-text">{{type.name[($store.state.locale).toLowerCase()]}}</div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                          </div>
                        </div>
                      </div>
                    </v-menu>
                  </v-flex>

                  <v-flex md6>
                    <v-text-field
                      :label="$t('general.time', $store.state.locale)"
                      :hint="$t('treatments.timeHint', $store.state.locale)"
                      persistent-hint
                      v-model.number="editSubtreatmentItems.time"
                      :suffix="$t('general.minutes', $store.state.locale)"
                      required
                      outlined
                      class="mt-3"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                    ></v-text-field>
                  </v-flex>

                  <v-flex md6></v-flex>

                  <v-flex md12>
                    <v-select
                      v-on:input="checkSubtreatmentDevice('edit')"
                      :label="$t('treatments.itemType', $store.state.locale)"
                      v-model="editSubtreatmentItems.device"
                      :items="occupationTypes"
                      item-text="name"
                      item-value="id"
                      required
                      outlined
                      class="mt-3"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-select>

                    <v-select
                      v-if="editSubtreatmentItems.device == 2 || editSubtreatmentItems.device == 3"
                      :label="$t('treatments.selectDevice', $store.state.locale)"
                      v-model="editSubtreatmentItems.deviceId"
                      :items="deviceTypes"
                      :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                      item-value="id"
                      required
                      outlined
                      class="mt-4"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-select>

                    <v-select
                      v-if="editSubtreatmentItems.device == 5 || editSubtreatmentItems.device == 6"
                      :label="$t('treatments.selectRoom', $store.state.locale)"
                      v-model="editSubtreatmentItems.rooms"
                      :items="rooms"
                      item-text="name"
                      item-value="data"
                      required
                      multiple
                      chips
                      small-chips
                      clearable
                      outlined
                      class="mt-3"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dense
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-select>

                    <v-switch
                      v-if="editSubtreatmentItems.device == 4"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      v-model="editSubtreatmentItems.withEventBlock"
                      v-on:change="setEventColor('edit')"
                      hide-details
                      >
                      <template slot='label'>
                        <span style="margin-right: 5px;" v-html="$t('treatments.showInCalendar', $store.state.locale)"></span>
                        <v-tooltip class="ma-0 pa-0" right>
                          <template v-slot:activator="{on}">
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span style="color: #fff;" v-html="$t('treatments.showInCalendarHint', $store.state.locale)"></span>
                        </v-tooltip>
                      </template>
                    </v-switch>

                    <v-switch
                      v-if="editSubtreatmentItems.device != 2 && editSubtreatmentItems.device != 6 && editSubtreatmentItems.withEventBlock"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      v-model="editSubtreatmentItems.apprenticeTreatment"
                      >
                      <template slot='label'>
                        <span style="margin-right: 5px;" v-html="$t('treatments.pushTreatmentToApprentice', $store.state.locale)"></span>
                        <v-tooltip class="ma-0 pa-0" right>
                          <template v-slot:activator="{on}">
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span style="color: #fff;" v-html="$t('treatments.pushTreatmentToApprenticeHint', $store.state.locale)"></span>
                        </v-tooltip>
                      </template>
                    </v-switch>
                  </v-flex>
                  <v-flex md12 v-if="editSubtreatmentItems.device != 4 || (editSubtreatmentItems.device == 4 && editSubtreatmentItems.withEventBlock)">
                    <div class="clear">
                      <label>{{ $t('general.color', $store.state.locale) }}</label>
                      <v-switch
                        :input-value="editSubtreatmentItems.eventColor ? false : true"
                        @change="setCustomEventColor(editSubtreatmentItems.eventColor, 'edit')"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        hide-details
                        class="mt-0 mb-2 pt-1"
                        :label="$t('treatments.useBranchColor', $store.state.locale)"
                        >
                      </v-switch>
                      <div class="color-field" v-if="editSubtreatmentItems.eventColor">
                        <swatches
                          v-model="editSubtreatmentItems.eventColor"
                          :swatches="colors"
                          :wrapper-style="wrapperStyle"
                          :swatch-style="swatchStyle"
                          swatch-size="36"
                          show-border
                          inline
                        ></swatches>
                      </div>
                      <v-card outlined class="my-2 pa-2" v-else>
                        <div style="display: inline-block; width: 36px;height: 36px; border-radius: 9px; margin: 0 10px 0 0 " :style="{backgroundColor: $store.state.activeBranch.calendarSettings.colorWithPreference}"></div>
                        <div style="display: inline-block; width: calc(100% - 46px);text-align: left !important" v-html="$t('treatments.useBranchColorHint', $store.state.locale)"></div>
                      </v-card>
                      <div class="custom-hint">{{ $t('treatments.colorHint', $store.state.locale) }}</div>
                    </div>
                  
                  </v-flex>
                </v-layout>
              </v-container>

            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn
                    outlined
                    depressed
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                    @click="editSubTreatmentDialog = false, resetSubtreatmentTypes(), feedbackSubtreatment = null"
                    >
                    {{ $t('general.cancel', $store.state.locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn 
                    :disabled=" !subtreatmentChanged" 
                    :depressed="!subtreatmentChanged"
                    :dark=" subtreatmentChanged"
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="updateSubtreatment()"
                    >
                    <div v-if="subtreatmentChanged">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                    <div v-else> {{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <!-- \ Edit Subtreatment Dialog / -->
    </v-card>
  </v-dialog>
</template>

<script>
  import db from "../../firebase/init";
  import { bus } from "../../main";
  import draggable from "vuedraggable";
  import moment from "moment";
  import Swatches from "vue-swatches";
  import "vue-swatches/dist/vue-swatches.css"
  import { cashConv } from "../../modules/quickWins";
  import algoliasearch from "algoliasearch/lite";
  import "instantsearch.css/themes/satellite-min.css";

  // import "vue-swatches/dist/vue-swatches.min.css";

  export default {
    name: "treatment-form",
    components: {
      draggable,
      Swatches
    },
    props: {
      rooms: Array,
      abilities: Array,
      deviceTypes: Array,
      organizedTreatmentTypes: Array,
      employees: Array,
      treatmentTypes: Array,
      subtreatmentTypes: Array,
      subtreatmentTypeCategories: Array,
      customTreatmentTypes: Array,
      occupationTypes: Array,
      appointmentTimeIterations: Array,
      bookingTimeLimitItems: Array,
      colors: Array,
      genders: Array
    },

    data(){
      return{
        tab: '1',
        type: 'new',
        showDialog: false,
        titleInternChanged: false,
        treatment: new Object(),
        treatmentOriginal: new Object(),
        algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
        tabTransition: false,
        watcher: 0,
        productSearch: "",
        headers: [
          {
            text: "",
            align: "left",
            sortable: false,
            value: "index",
            width: "40px"
          },
          {
            text: this.$t('general.employee', this.$store.state.locale),
            align: "left",
            sortable: false,
            value: "index"
          }
        ],
        modifier: 0,
        products: new Array(),
        productCount: 0,
        editSubTreatmentTypeDialog: false,
        newSubTreatmentTypeDialog: false,
        deviceInPlanningWatch: new Array(),
        newSubTreatmentDialog: false,
        editSubTreatmentDialog: false,
        editSubtreatmentItems: {
          name: new Object(),
          description: new Object()
        },
        editSubtreatmentItemsOriginal: new Object(),
        productUsage: {
          step: 1,
          index: null,
          dialog: false,
          selectedProduct: null,
          selectedCategory: null,
          usagePercentage: null,
          feedback: null,
          type: 'new',
        },
        selectedLanguage: null,
        productUsageOriginal: new Object(),
        treatmentIdCopy: false,
      
        newSubtreatment: {
          weight: "",
          type: null,
          name: new Object(),
          description: new Object(),
          device: 1,
          deviceId: null,
          rooms: null,
          eventColor: null,
          priceNetto: null,
          priceBruto: null,
          apprenticeTreatment: false,
          withEventBlock: true
        },
        newSubtreatmentOriginal: new Object(),
        
        wrapperStyle: { paddingTop: "0", paddingLeft: "0" },
        swatchStyle: { marginBottom: "0" },
        feedbackTreatment: null,
        feedbackSubtreatment: null,
        treatmentPriceAdjustments: 0,
        priceAdjustmentTypes: [
          {
            id: 'noChanges',
            name: this.$t('treatments.priceAdjustments.noChanges', this.$store.state.locale),
          },
          {
            id: 'setAmount',
            name: this.$t('treatments.priceAdjustments.setAmount', this.$store.state.locale),
          },
          {
            id: 'raiseAmount',
            name: this.$t('treatments.priceAdjustments.raiseAmount', this.$store.state.locale),
          },
          {
            id: 'lowerAmount',
            name: this.$t('treatments.priceAdjustments.lowerAmount', this.$store.state.locale),
          }
        ]
        
      }
    },

    beforeUpdate() {
      this.updateTreatmentData();
    },
    

    watch: {
      productUsage: {
        // Kijk of barcode de juiste lengte heeft voor EAN8 of EAN13
        handler: function () {
          if(this.productSearch && this.productSearch.length > 3){
            this.searchProductsAlgolia()
          }
          else if(!this.productSearch || this.productSearch.length == 0){
            this.loadProducts() 
          }
        },
        deep: true,
      }
    },

    created(){
      this.setTreatmentData()
      this.now = moment().format("YYYY-MM-DD");
      this.selectedLanguage = this.$store.state.activeCompany.languages[0]; // Set language to company default language
      this.editSubtreatmentItemsOriginal = _.cloneDeep(this.editSubtreatmentItems);
      this.newSubtreatmentOriginal = _.cloneDeep(this.newSubtreatment);

      bus.$on("treatmentModal", (treatment, type) => {
        // this.branchSet()
        this.tab = 0;
        this.type = type;

        if(type == "edit"){
          this.treatment = _.cloneDeep(treatment); 
          this.updateTreatmentData();
          this.checkTreatmentData();
          this.calculateTotalPrice();
          this.setTreatmentPriceAdjustmentFirst();
          this.treatmentOriginal = _.cloneDeep(this.treatment);
        }
        else if(type == "clone"){
          this.type = 'new'
          this.treatment = _.cloneDeep(treatment);
          this.updateTreatmentData();
          this.checkTreatmentData();
          this.calculateTotalPrice();
          this.setTreatmentPriceAdjustmentFirst();
          this.treatmentOriginal = new Object();
        }
        else{
          this.type = 'new'
          this.setTreatmentData()
        }

        this.showDialog = true;
        
      });
    },

    methods: {

      getBranchName(branchId){
        let branchName = branchId;
        let branch = this.$store.state.companyBranches.find((branch)=> {return branchId == branch.id})
        if(branch != undefined){
          branchName = branch.name;
        }
        return branchName;
      },

      

      setTreatmentPriceAdjustmentFirst(){
        this.treatment.branchPriceAdjustments = this.treatment.branchPriceAdjustments ? this.treatment.branchPriceAdjustments : new Object();

        if(this.treatment.branches && this.treatment.branches.length > 0 && this.treatment.vat && this.treatment.brutoPrice){
          this.treatment.branches.forEach((branch)=>{
            if(!this.treatment.branchPriceAdjustments[branch]){
              this.treatment.branchPriceAdjustments[branch] = {
                adjustmentType: 'noChanges', 
                number: 0,
                numberBruto: 0,
                totalAmountNetto: 0,
                totalAmountBruto: 0
              }
            }
            else{
              this.treatment.branchPriceAdjustments[branch].numberBruto = null;
              this.treatment.branchPriceAdjustments[branch].totalAmountBruto = null;
              this.setTreatmentPriceAdjustment(branch, 'first')
            }
          })
        }
      },

      roundCashNumber(number){
        return cashConv(number, "EUR",2);
      },

      setTreatmentPriceAdjustment(branchId, type){

        this.treatmentPriceAdjustments++

        let vatPercentage = this.$store.state.vatItems.find((vat)=>{return vat.id == this.treatment.vat});
        let numberBruto = cashConv((this.treatment.branchPriceAdjustments[branchId].numberBruto), "EUR",2);
        if(type == 'first'){
          numberBruto = cashConv((this.treatment.branchPriceAdjustments[branchId].numberBruto ? this.treatment.branchPriceAdjustments[branchId].numberBruto : this.treatment.branchPriceAdjustments[branchId].number * ((vatPercentage.percentage/100)+1)), "EUR",2);
        }
        if(this.treatment.branchPriceAdjustments[branchId].adjustmentType == 'noChanges'){
          this.treatment.branchPriceAdjustments[branchId] = {
            adjustmentType: 'noChanges', 
            number: 0,
            numberBruto: 0,
            totalAmountNetto: 0,
            totalAmountBruto: 0
          };
        }

        if(numberBruto && this.treatment.brutoPrice){
          if(this.treatment.branchPriceAdjustments[branchId].adjustmentType == 'setAmount'){
            this.treatment.branchPriceAdjustments[branchId].number = cashConv(numberBruto / ((vatPercentage.percentage / 100 ) + 1), "EUR",4); 
            this.treatment.branchPriceAdjustments[branchId].adjustmentType = 'setAmount';
            this.treatment.branchPriceAdjustments[branchId].totalAmountNetto = cashConv(Number(numberBruto / ((vatPercentage.percentage / 100 ) + 1)), "EUR",4)
            this.treatment.branchPriceAdjustments[branchId].totalAmountBruto = cashConv(Number(numberBruto), "EUR",2);
          }

          if(this.treatment.branchPriceAdjustments[branchId].adjustmentType == 'raiseAmount'){
            this.treatment.branchPriceAdjustments[branchId].number = cashConv(numberBruto / ((vatPercentage.percentage / 100 ) + 1), "EUR",4); 
            this.treatment.branchPriceAdjustments[branchId].adjustmentType = 'raiseAmount';
            this.treatment.branchPriceAdjustments[branchId].totalAmountNetto = cashConv(Number(this.treatment.treatmentPrice) + Number(numberBruto / ((vatPercentage.percentage / 100 ) + 1)), "EUR",4)
            this.treatment.branchPriceAdjustments[branchId].totalAmountBruto = cashConv(Number(this.treatment.brutoPrice) + Number(numberBruto), "EUR",2);
          }

          if(this.treatment.branchPriceAdjustments[branchId].adjustmentType == 'lowerAmount'){
            this.treatment.branchPriceAdjustments[branchId].number = cashConv(numberBruto / ((vatPercentage.percentage / 100 ) + 1), "EUR",4); 
            this.treatment.branchPriceAdjustments[branchId].adjustmentType = 'lowerAmount';
            this.treatment.branchPriceAdjustments[branchId].totalAmountNetto = cashConv(Number(this.treatment.treatmentPrice)  - Number(numberBruto / ((vatPercentage.percentage / 100 ) + 1)), "EUR",4); 
            this.treatment.branchPriceAdjustments[branchId].totalAmountBruto = cashConv(Number(this.treatment.brutoPrice) - Number(numberBruto), "EUR",2); 
          }
        }
        else{
          this.treatment.branchPriceAdjustments[branchId].number = 0;
          this.treatment.branchPriceAdjustments[branchId].totalAmountNetto = 0;
          this.treatment.branchPriceAdjustments[branchId].totalAmountBruto = 0; 
        }
        if(!this.treatment.branchPriceAdjustments[branchId].numberBruto){
          this.treatment.branchPriceAdjustments[branchId].numberBruto = cashConv((this.treatment.branchPriceAdjustments[branchId].number * ((vatPercentage.percentage/100)+1)), "EUR",2);
        }
      },

      async checkTreatmentData() {
        if(this.treatment.vat){
          let counter = 0
          await new Promise((resolve)=>{
            this.$store.state.vatItems.forEach(vatItem=>{
              if(vatItem.id == this.treatment.vat){
                this.treatment.vatPercentage = (vatItem.percentage / 100) + 1
              }
              counter++
              if(counter == this.$store.state.vatItems.length){
                resolve()
              }
            })
          })
          .then(()=>{
            let counter = 0 
            new Promise(resolve=>{
              this.treatment.subTreatments.forEach((subTreatment, index)=>{
                this.treatment.subTreatments[index].priceBruto = Number((this.treatment.subTreatments[index].priceNetto * this.treatment.vatPercentage).toFixed(2))
                counter++
                if(counter == this.treatment.subTreatments.length){
                  resolve()
                }
              })
            })
            .then(()=>{
              // Prijzen van subtreatments optellen met reduce()
              this.treatment.nettoPrice = cashConv((this.treatment.subTreatments.reduce((acc, subtreatment) =>  acc + subtreatment.priceNetto, 0)), "EUR",4);
              this.treatment.brutoPrice = (this.treatment.nettoPrice  * this.treatment.vatPercentage).toFixed(2)
            })
          })
        } 
      },

      setTreatmentData(){
        this.treatment = {
          // Basic
        treatmentTitle: new Object(),
        treatmentTitleIntern: new Object(),
        treatmentTitleReceipt: new Object(),
        treatmentType: null,
        treatmentGender: "unisex",
        treatmentDesc: new Object(),
        treatmentActive: true,
        usage: null,
        treatmentOnline: false,
        timeIteration: null,
        customTimeIteration: false,
        timeRestriction: null,
        customTreatmentType: null,
        //Apparaat
        device: false,
        deviceId: null,

        // Prijs
        free: false,
        treatmentPrice: null,
        brutoPrice: null,
        nettoDiscount: null,
        brutoDiscount: null,
        nettoTotalPrice: null,
        brutoTotalPrice: null,
        vat: null,
        vatPercentage: null,
        eventBooking: false,
        branchPriceAdjustments: new Object(),
        // Planning
        subTreatments: [],
        time: null,
        bookingTimeLimit: null,

        // Gebruik
        usage: new Array(),

        // Vestigingen
        branches: [],

        // Medewerkers
        employees: [],
        preferredPractitioner: false,
        competenceSort: "noCompetency"
        }
      },

      copyTreatmentTitle(){ // Kopieer treatment title als de interne titel nog niet is gewijzigd.   
        if(!this.titleInternChanged && this.type =='new'){
          this.treatment.treatmentTitleIntern = _.cloneDeep(this.treatment.treatmentTitle)
        }
      },
      
      setCustomTimeIteration(){
        if(this.treatment.customTimeIteration){
          this.treatment.timeIteration = 15;
        } 
        else{
          this.treatment.timeIteration = null;
        }
      },

      resetSubtreatmentTypes(){
        this.subtreatmentTypes.forEach(type=>{
          type.chosen = false;
          this.modifier++
        })
      },

      async loadProducts(){
        this.products = new Array();
        let index =  this.algoSearch.initIndex("thrive_products");
        var FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
        await index.search("", {
          facetFilters: [FacetFilterString],
        },)
        .then(({ hits, nbHits }) => {
          this.productCount = nbHits
          hits.forEach((product) => {
            this.products.push(product)
          });
        });
      },

      async searchProductsAlgolia() { // Get client data from Algolia
        this.products = new Array();
        let index =  this.algoSearch.initIndex("thrive_products");
        var FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
        await index.search(this.productSearch, {
          facetFilters: [FacetFilterString],
        },)
        .then(({ hits, nbHits }) => {
          this.productCount = nbHits;
          hits.forEach((product) => {
            this.products.push(product)
          });
        });
      },

      selectSubtreamentItem(selectedType, type){
        if(type == 'new'){
          this.newSubtreatment.type = selectedType.id;
          this.newSubtreatment.name = selectedType.name;
        }
        else{
          this.editSubtreatmentItems.type = selectedType.id;
          this.editSubtreatmentItems.name = selectedType.name;
        }

        this.subtreatmentTypes.forEach(type=>{
          if(type.id == selectedType.id){
            type.chosen = true;
            this.modifier++
          } else{
            type.chosen = false;
            this.modifier++
          }
        })
        if(type == 'new'){
          this.newSubTreatmentTypeDialog = false;
        }
        else{
          this.editSubTreatmentTypeDialog = false;
        }
      },
      
      async updateTreatmentData() {
        // Update time en weight of subreatments
        if (this.treatment.subTreatments.length > 0) {
         await new Promise((resolve, reject) => {
            let totalTime = 0;
            let counter = 0;
            const subtreatments = this.treatment.subTreatments;

            subtreatments.forEach(item => {
              item.weight = subtreatments.indexOf(item);
              totalTime += parseInt(item.time);
              counter++;

              if (counter === subtreatments.length) {
                resolve(totalTime);
              }
            });
          })
          .then(totalTime => {
            this.treatment.treatmentTime = totalTime;
          });
        }
        return
      },

      setSubtreatmentTypeName(){
        if(this.newSubTreatmentDialog){
          this.subtreatmentTypes.forEach(subtreatmentType=>{
            if(subtreatmentType.id == this.newSubtreatment.type){
              this.newSubtreatment.name = subtreatmentType.name
            }
          })
        } else if(this.editSubTreatmentDialog){
          this.subtreatmentTypes.forEach(subtreatmentType=>{
            if(subtreatmentType.id == this.newSubtreatment.type){
              this.editSubtreatmentItems.name = subtreatmentType.name
            }
          })
        }
      },

      editSubTreatment(item, index) {
        this.editSubTreatmentDialog = true;
        this.editSubtreatmentItems = _.cloneDeep(item);
        this.editSubtreatmentItemsOriginal = _.cloneDeep(item);
        this.editSubtreatmentItems.index = index;
        this.subtreatmentTypes.forEach(type=>{
          if(type.id == this.editSubtreatmentItems.type){
            type.chosen = true;
            this.modifier++
          } else{
            type.chosen = false;
            this.modifier++
          }
        })
      },

      setCustomEventColor(color, type){
        console.log("color, type: ", color, type)
        if(color){
          if(type == 'new'){
            this.newSubtreatment.eventColor = null;
          }
          else{
            this.editSubtreatmentItems.eventColor = null;
          }
        }
        else{
          if(type == 'new'){
            this.newSubtreatment.eventColor = this.colors[0];
          }
          else{
            this.editSubtreatmentItems.eventColor = this.colors[0];
          }
        }
      },

      getProductInfo(id){
        if(id){   
          let info = null;
          let index = _.findIndex(this.products, {'objectID': id})
          if(index >= 0){
            info =  this.products[index];
          }
          return info;
        }
        else{
          return new Object();
        }
      },

      addTreatmentUsage(){
        this.productUsage.feedback = null;
        if(this.productUsage.selectedProduct && this.productUsage.usagePercentage){
          let usage = {
            product: this.productUsage.selectedProduct,
            percentage: this.productUsage.usagePercentage
          }
          if(!this.treatment.usage){
            this.treatment.usage = new Array();
          }
          this.treatment.usage.push(usage);
          this.productUsage.dialog = false;
        }
        else{
          this.productUsage.feedback = this.$t('treatments.usageInvalidFieldsFeedback', this.$store.state.locale)
        }   
      },

      productUsageDialog(){ 
        this.productUsage.step = 1;
        this.productUsage.index = null;
        this.productUsage.feedback = null;
        this.productUsage.selectedProduct = null;
        this.productUsage.selectedCategory = null;
        this.productUsage.usagePercentage = null;
        this.productSearch = null;
        this.productUsage.type = 'new';
        this.productUsage.dialog = true;
      },

      removeUsage(index){
        this.treatment.usage.splice(index, 1);
      },

      editUsage(product, index){
        this.productUsage.step = 1;
        this.productUsage.index = index;
        this.productUsage.feedback = null;
        this.productUsage.selectedProduct = _.cloneDeep(product.product);
        this.productUsage.selectedCategory = null;
        this.productUsage.usagePercentage = _.cloneDeep(product.percentage);
        this.productSearch = null;
        this.productUsage.type = 'edit';
        this.productUsage.dialog = true;
        this.productUsageOriginal = _.cloneDeep(this.productUsage);
      },

      editTreatmentUsage(){
        this.treatment.usage[this.productUsage.index].product = _.cloneDeep(this.productUsage.selectedProduct);
        this.treatment.usage[this.productUsage.index].percentage = _.cloneDeep(this.productUsage.usagePercentage);
        this.productUsage.dialog = false;
      },

      checkSubtreatmentDevice(type){
        if(type == 'new'){
          if(this.newSubtreatment.device != 2 || this.newSubtreatment.device != 3){
            this.newSubtreatment.deviceId = null;
          }
          if(this.newSubtreatment.device != 6 && this.newSubtreatment.device != 6){
            this.newSubtreatment.rooms = null;
          }
          if(this.newSubtreatment.device == 2 || this.newSubtreatment.device == 6){
            this.newSubtreatment.apprenticeTreatment = false;
          }
          if(this.newSubtreatment.device == 4) {
            this.newSubtreatment.withEventBlock = false;
            this.newSubtreatment.eventColor= "#eeeeee"
          }
          else{
            this.newSubtreatment.withEventBlock = true;
            this.newSubtreatment.eventColor = null;
          }
        }
        else{
          if(this.editSubtreatmentItems.device != 2 && this.editSubtreatmentItems.device != 3){
            this.editSubtreatmentItems.deviceId = null;
          }
          if(this.editSubtreatmentItems.device != 6 && this.editSubtreatmentItems.device != 6){
            this.editSubtreatmentItems.rooms = null;
          }
          if(this.editSubtreatmentItems.device == 2 || this.editSubtreatmentItems.device == 6){
            this.editSubtreatmentItems.apprenticeTreatment = false;
          }
          if(this.editSubtreatmentItems.device == 4) {
            this.editSubtreatmentItems.withEventBlock = false;
            this.editSubtreatmentItems.eventColor= "#eeeeee"
          }
          else{
            this.editSubtreatmentItems.withEventBlock = true;
            this.editSubtreatmentItems.eventColor = null;
          }
        }
      },

      setEventColor(type){
        if(type == 'new'){
          if(!this.newSubtreatment.withEventBlock){
            this.newSubtreatment.eventColor= "#eeeeee";
          }
          else{
            this.newSubtreatment.eventColor = null;
          }
        }
        else{
          if(!this.editSubtreatmentItems.withEventBlock){
            this.editSubtreatmentItems.eventColor= "#eeeeee";
          }
          else{
            this.editSubtreatmentItems.eventColor = null;
          }
        }
      },

      removeSubTreatment(item) { // Remove Subtreatment from Subtreatment array   
        const subtreatments = this.treatment.subTreatments;
        subtreatments.splice(item, 1);
        this.treatment.noEmployeeSubtreatment = this.checkDeviceOnly() ? true : false;
      },

      setTreatmentVatPercentage(){
        this.treatment.vatPercentage = 0
        this.$store.state.vatItems.forEach(vatItem=>{
          if(vatItem.id == this.treatment.vat){
            this.treatment.vatPercentage = (vatItem.percentage / 100) + 1
          }
        })

        this.setTreatmentPriceAdjustmentFirst();
        
        // Controleer of er al prijzen zijn ingevoerd en bereken deze met het ingestelde BTW-tarief
        if(this.treatment.subTreatments.length > 0){
          let counter = 0 
          new Promise(resolve=>{
            this.treatment.subTreatments.forEach((subTreatment, index)=>{
              if(subTreatment.priceNetto){
                this.calculatePricesSubtreatment(index, "netto")
              }
              else{
                if(subTreatment.priceBruto){
                  this.calculatePricesSubtreatment(index, "bruto")
                }
              }
              counter++
              if(counter == this.treatment.subTreatments.length){
                resolve()
              }
            })
          })
          .then(()=>{
            this.calculateTotalPrice()
          })
        }
      },

      calculatePricesSubtreatment(index, type){
        if(type == "netto"){
          this.treatment.subTreatments[index].priceBruto = Number((this.treatment.subTreatments[index].priceNetto * this.treatment.vatPercentage).toFixed(2))
        } 
        else {
          // this.treatment.subTreatments[index].priceNetto = Number((this.treatment.subTreatments[index].priceBruto / this.treatment.vatPercentage).toFixed(2))
          this.treatment.subTreatments[index].priceNetto= cashConv((this.treatment.subTreatments[index].priceBruto / this.treatment.vatPercentage), "EUR",4);
        }
        this.calculateTotalPrice();
      },

      calculateTotalPrice(){
        if(this.treatment.vat){
          // Prijzen van subtreatments optellen met reduce()
          // this.treatment.treatmentPrice = Number(this.treatment.subTreatments.reduce((acc, subtreatment) => acc + subtreatment.priceNetto, 0).toFixed(2))
          this.treatment.treatmentPrice = cashConv((this.treatment.subTreatments.reduce((acc, subtreatment) => acc + subtreatment.priceNetto, 0)), "EUR",4);
          this.treatment.brutoPrice = cashConv((this.treatment.treatmentPrice * this.treatment.vatPercentage), "EUR",2);
        }
        this.setTreatmentPriceAdjustmentFirst();
      },

      updateSubtreatment() {
        // Edit Subtreatment in Subtreatment array
        if (this.editSubtreatmentItems.name && this.editSubtreatmentItems.time && this.editSubtreatmentItems.device) {
          if(((this.editSubtreatmentItems.device == 1 || this.editSubtreatmentItems.device == 4 || this.editSubtreatmentItems.device == 5 || this.editSubtreatmentItems.device == 6) || this.editSubtreatmentItems.deviceId)){
            if(((this.editSubtreatmentItems.device == 1 || this.editSubtreatmentItems.device == 2 || this.editSubtreatmentItems.device == 3 || this.editSubtreatmentItems.device == 4) || this.editSubtreatmentItems.rooms)){
              this.treatment.subTreatments[this.editSubtreatmentItems.weight] = this.editSubtreatmentItems;
              this.editSubTreatmentDialog = false;
              this.feedbackSubtreatment = null;
              this.treatment.changed = true;
              this.treatment.noEmployeeSubtreatment = this.checkDeviceOnly() ? true : false;
            }
            else {
              this.feedbackSubtreatment = this.$t('treatments.selectRoomToSave', this.$store.state.locale);
            }
          }
          else {
            this.feedbackSubtreatment = this.$t('treatments.selectDeviceToSave', this.$store.state.locale);
          }
        }
        else {
          this.feedbackSubtreatment = this.$t('general.requiredField', this.$store.state.locale);
        }
      },

      addSubtreatment() {
        // Push new Subtreatment to Subtreatment array
        if (this.newSubtreatment.name && this.newSubtreatment.time && this.newSubtreatment.device) {
          if((this.newSubtreatment.device == 1 || this.newSubtreatment.device == 4 || this.newSubtreatment.device == 5 || this.newSubtreatment.device == 6) || this.newSubtreatment.deviceId){
            if((this.newSubtreatment.device == 1 || this.newSubtreatment.device == 2 || this.newSubtreatment.device == 3 || this.newSubtreatment.device == 4) || this.newSubtreatment.rooms){
              this.treatment.subTreatments.push(this.newSubtreatment);
              this.newSubTreatmentDialog = false;
              this.treatment.noEmployeeSubtreatment = this.checkDeviceOnly() ? true : false;
              this.newSubtreatment = {
                apprenticeTreatment: false,
                weight: "",
                type: null,
                rooms: null,
                description: new Object(),
                name: new Object(),
                priceNetto: null,
                priceBruto: null,
                device: 1,
                deviceId: null,
                eventColor: null,
                widthEventBlock: true
              };
              this.feedbackSubtreatment = null;
              this.resetSubtreatmentTypes();
              this.calculateTotalPrice()
            }
            else {
              this.feedbackSubtreatment = this.$t('treatments.selectRoomToSave', this.$store.state.locale);
            }
          }
          else {
            this.feedbackSubtreatment = this.$t('treatments.selectDeviceToSave', this.$store.state.locale);
          }
        }
        else {
          this.feedbackSubtreatment = this.$t('general.requiredField', this.$store.state.locale);
        }
      },

      checkDeviceOnly(){
        // Check if treatment occupation type is Room(6), Device(2) or both
        if (this.treatment.subTreatments.every((subTreatment) => subTreatment.device == 2 || subTreatment.device == 6 )) {
          return true;
        } else {
          return false;
        }
      },

      copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }

        navigator.clipboard.writeText(text).then(()=> {
          this.treatmentIdCopy = true
          setTimeout(()=>{ 
            this.treatmentIdCopy = false
          }, 1000);

        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      },

      closeTreatmentDialog() {
        this.showDialog = false;
        this.tab = 0;
        this.feedbackTreatment = null;
      },

      selectedProduct(id){
        return id == this.productUsage.selectedProduct ? true : false;
      },

      addTreatment() {
        this.feedbackTreatment = null;
        let employeeValid = true;

        if(this.treatment.subTreatments.length > 0){
          this.treatment.subTreatments.forEach(subtreatment=>{
            if(subtreatment.device == 1 || subtreatment.device == 3 || subtreatment.device == 4 || subtreatment.device == 5){
              if(!this.treatment.employees || this.treatment.employees.length == 0){
                employeeValid = false;
              }
            }
          })
        }
        if(
          this.treatment.treatmentTitle &&
          this.treatment.treatmentTitleIntern &&
          this.treatment.treatmentType &&
          this.treatment.subTreatments.length > 0 &&
          employeeValid
        ) {

          if(!this.treatment.free && (!this.treatment.vat || this.treatment.nettoPrice == 0)){
            this.feedbackTreatment = this.$t('treatments.treatmentNoPrice', this.$store.state.locale);
          } 
          else {
            this.postTreatment();
          }        
        } 
        else {
          this.feedbackTreatment = this.$t('treatments.treatmentAllFieldRequiredToSave', this.$store.state.locale);
        }
      },

      async postTreatment() {
        await this.updateTreatmentData(); // Calculate total treatment time 

        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "treatmentAdded",
          actionItem: this.treatment.treatmentTitle,
          extra:[
            {
              name: "name",
              value: this.treatment.treatmentTitle 
            },
            {
              name: "treatmentTitleIntern",
              value: this.treatment.treatmentTitleIntern 
            },
            {
              name: "treatmentDesc",
              value: this.treatment.treatmentDesc 
            },
            {
              name: "branches",
              value: this.treatment.branches 
            },
            {
              name: "employees",
              value: this.treatment.employees 
            },
            {
              name: "company",
              value: this.$store.state.activeCompany.id
            },
            {
              name: "active",
              value: this.treatment.treatmentActive 
            },
            {
              name: "employees",
              value: this.treatment.employees 
            },
            {
              name: "gender",
              value: this.treatment.treatmentGender 
            },

            {
              name: "online",
              value: this.treatment.treatmentOnline 
            },
            {
              name: "vat",
              value: this.treatment.vat 
            },
            {
              name: "nettoPrice",
              value: this.treatment.treatmentPrice
            },
            {
              name: "time",
              value: Number(this.treatment.treatmentTime)
            },
            {
              name: "device",
              value: this.treatment.device 
            },
            {
              name: "deviceIdId",
              value: this.treatment.deviceId 
            },
            {
              name: "subTreatments",
              value: JSON.stringify(this.treatment.subTreatments)
            }
          ]
        };

        let branchPriceAdjustments = null;

        if(Object.keys(this.treatment.branchPriceAdjustments).length > 0){
          branchPriceAdjustments = new Object();
          for (const [key, value] of Object.entries(this.treatment.branchPriceAdjustments)) {
            branchPriceAdjustments[key] = {
                adjustmentType: this.treatment.branchPriceAdjustments[key].adjustmentType,
                number: this.treatment.branchPriceAdjustments[key].number,
                totalAmountNetto: this.treatment.branchPriceAdjustments[key].totalAmountNetto
              }
          }
        }

        try{
          await db.collection("treatments")
          .add({
            modified: new Date(),
            treatmentActive: this.treatment.treatmentActive,
            treatmentDesc: this.treatment.treatmentDesc,
            treatmentGender: this.treatment.treatmentGender,
            treatmentType: this.treatment.treatmentType,
            treatmentOnline: this.treatment.treatmentOnline,
            treatmentPrice: this.treatment.treatmentPrice,
            treatmentTime: Number(this.treatment.treatmentTime),
            treatmentTitle: this.treatment.treatmentTitle,
            treatmentTitleIntern: this.treatment.treatmentTitleIntern,
            treatmentTitleReceipt: this.treatment.treatmentTitleReceipt,
            customTreatmentType: this.treatment.customTreatmentType,
            bookingTimeLimit: this.treatment.bookingTimeLimit,
            customTimeIteration: this.treatment.customTimeIteration,
            timeIteration: this.treatment.timeIteration,
            branches: this.treatment.branches,
            employees: this.treatment.employees,
            timeRestriction: this.treatment.timeRestriction,
            preferredPractitioner: this.treatment.preferredPractitioner,
            competenceSort: this.treatment.competenceSort,
            companyId: this.$store.state.activeCompany.id,
            usage: this.treatment.usage,
            device: this.treatment.device,
            deviceId: this.treatment.deviceId,
            subTreatments: this.treatment.subTreatments,
            free: this.treatment.free,
            noEmployeeSubtreatment: this.treatment.noEmployeeSubtreatment,
            vat: this.treatment.vat,
            branchPriceAdjustments: branchPriceAdjustments,
            eventBooking: this.treatment.eventBooking
          })

          this.titleInternChanged = false;
          await this.$createNewLog("info", logData);
          this.setTreatmentData()
          this.$emit("updateData");
          this.closeTreatmentDialog();

        }
        catch(error){
          console.error("Error adding treatment: ", error);
          this.feedbackTreatment = this.$t('general.addError', this.$store.state.locale);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      },

      updateTreatment() {
        this.feedbackTreatment = null;
        let employeeValid = true;

        if(this.treatment.subTreatments.length > 0){
          this.treatment.subTreatments.forEach(subtreatment=>{
            if(subtreatment.device == 1 || subtreatment.device == 3 || subtreatment.device == 4 || subtreatment.device == 5){
              if(!this.treatment.employees || this.treatment.employees.length == 0){
                employeeValid = false;
              }
            }
          })
        }

        if (
          this.treatment.treatmentTitle &&
          this.treatment.treatmentTitleIntern &&
          this.treatment.treatmentType &&
          this.treatment.subTreatments.length > 0 &&
          employeeValid
        ) {
          let subTreatmentsWithDevice = _.filter(
            this.treatment.subTreatments,
            function(subtreatment) {
              return subtreatment.device == 2 || subtreatment.device == 3;
            }
          );

          if(!this.treatment.free && (!this.treatment.vat || this.treatment.treatmentPrice == 0)){
            this.feedbackTreatment = this.$t('treatments.treatmentNoPrice', this.$store.state.locale);
          } 
          else {
            this.postUpdateTreatment();
          }
        }
        else {
          this.feedbackTreatment = this.$t('treatments.treatmentAllFieldRequiredToSave', this.$store.state.locale);
        }
      },

      async postUpdateTreatment() {
        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
            action: "treatmentEditted",
            actionItem: this.treatmentOriginal.treatmentTitle,
            extra:[
              {
                name: "originalData",
                value: ""
              },
              {
                name: "name",
                value: this.treatmentOriginal.treatmentTitle 
              },
              {
                name: "treatmentTitleIntern",
                value: this.treatmentOriginal.treatmentTitleIntern 
              },
              {
                name: "treatmentDesc",
                value: this.treatmentOriginal.treatmentDesc 
              },
              {
                name: "branches",
                value: this.treatmentOriginal.branches 
              },
              {
                name: "employees",
                value: this.treatmentOriginal.employees 
              },
              {
                name: "company",
                value: this.treatmentOriginal.companyId 
              },
              {
                name: "active",
                value: this.treatmentOriginal.treatmentActive 
              },
              {
                name: "employees",
                value: this.treatmentOriginal.employees 
              },
              {
                name: "gender",
                value: this.treatmentOriginal.treatmentGender 
              },
              {
                name: "online",
                value: this.treatmentOriginal.treatmentOnline 
              },
              {
                name: "vat",
                value: this.treatmentOriginal.vat 
              },
              {
                name: "nettoPrice",
                value: this.treatmentOriginal.nettoPrice 
              },
              {
                name: "time",
                value: this.treatmentOriginal.treatmentTime 
              },
              {
                name: "device",
                value: this.treatmentOriginal.device 
              },
              {
                name: "deviceIdId",
                value: this.treatmentOriginal.deviceId 
              },
              {
                name: "subTreatments",
                value: JSON.stringify(this.treatmentOriginal.subTreatments)
              },
              {
                name: "changedData",
                value: ""
              },
              {
                name: "name",
                value: this.treatment.treatmentTitle 
              },
              {
                name: "treatmentTitleIntern",
                value: this.treatment.treatmentTitleIntern 
              },
              {
                name: "treatmentDesc",
                value: this.treatment.treatmentDesc 
              },
              {
                name: "branches",
                value: this.treatment.branches 
              },
              {
                name: "employees",
                value: this.treatment.employees 
              },
              {
                name: "company",
                value: this.treatment.companyId 
              },
              {
                name: "active",
                value: this.treatment.treatmentActive 
              },
              {
                name: "employees",
                value: this.treatment.employees 
              },
              {
                name: "gender",
                value: this.treatment.treatmentGender 
              },
              {
                name: "online",
                value: this.treatment.treatmentOnline 
              },
              {
                name: "vat",
                value: this.treatment.vat 
              },
              {
                name: "nettoPrice",
                value: this.treatment.nettoPrice 
              },
              {
                name: "time",
                value: this.treatment.treatmentTime 
              },
              {
                name: "device",
                value: this.treatment.device 
              },
              {
                name: "deviceIdId",
                value: this.treatment.deviceId 
              },
              {
                name: "subTreatments",
                value: JSON.stringify(this.treatment.subTreatments)
              }
            ]
        };
        let branchPriceAdjustments = null;

        if(Object.keys(this.treatment.branchPriceAdjustments).length > 0){
          branchPriceAdjustments = new Object();
          for (const [key, value] of Object.entries(this.treatment.branchPriceAdjustments)) {
            branchPriceAdjustments[key] = {
                adjustmentType: this.treatment.branchPriceAdjustments[key].adjustmentType,
                number: this.treatment.branchPriceAdjustments[key].number,
                totalAmountNetto: this.treatment.branchPriceAdjustments[key].totalAmountNetto
              }
          }
        }

        console.log("branchPriceAdjustments: ", branchPriceAdjustments)

        try{
          await db.collection("treatments")
          .doc(this.treatment.id)
          .update({
            modified: new Date(),
            treatmentActive: this.treatment.treatmentActive,
            treatmentDesc: this.treatment.treatmentDesc,
            treatmentGender: this.treatment.treatmentGender,
            treatmentOnline: this.treatment.treatmentOnline,
            treatmentPrice: this.treatment.treatmentPrice,
            treatmentType: this.treatment.treatmentType,
            treatmentDesc: this.treatment.treatmentDesc,
            treatmentTime: Number(this.treatment.treatmentTime),
            treatmentTitle: this.treatment.treatmentTitle,
            treatmentTitleIntern: this.treatment.treatmentTitleIntern,
            customTreatmentType: this.treatment.customTreatmentType ? this.treatment.customTreatmentType : null,
            branches: this.treatment.branches,
            customTimeIteration: this.treatment.customTimeIteration,
            timeIteration: this.treatment.timeIteration,
            bookingTimeLimit: this.treatment.bookingTimeLimit,
            employees: this.treatment.employees,
            timeRestriction: this.treatment.timeRestriction,
            preferredPractitioner: this.treatment.preferredPractitioner,
            competenceSort: this.treatment.competenceSort,
            device: this.treatment.device,
            usage: this.treatment.usage ? this.treatment.usage : new Array(),
            deviceId: this.treatment.deviceId,
            branchPriceAdjustments: branchPriceAdjustments,
            subTreatments: this.treatment.subTreatments,
            noEmployeeSubtreatment: this.treatment.noEmployeeSubtreatment ? this.treatment.noEmployeeSubtreatment : false,
            free: this.treatment.free,
            vat: this.treatment.vat,
            eventBooking: this.treatment.eventBooking ? this.treatment.eventBooking : false
          })

          await this.$createNewLog("info", logData);
          this.$emit("updateData");
          this.closeTreatmentDialog();

        }
        catch(error){
          console.error("Error updating treatment: ", error);
          this.feedbackTreatment = this.$t('general.addError', this.$store.state.locale);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      },

      getFlagCode(selectedLanguage){
        return selectedLanguage ? this.$countries.find(language => { return language.id == selectedLanguage }).flagCode : "";
      }
    },

    computed: {
      selectedLanguageObject(){
        return this.selectedLanguage ? this.$countries.find(language => { return language.id == this.selectedLanguage }) : {languageName:new Object()};
      },

      productUsageValid(){
        if(this.productUsage.usagePercentage && String(this.productUsage.usagePercentage).length > 0 && this.productUsage.selectedProduct && this.productUsage.selectedProduct.length > 0){
          return true
        }
        else{
          return false
        }
      },

      availableLanguages(){
        let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
          if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
        });
        // Show languages in the same order as company languages
        return availableLanguages.sort((a, b) => this.$store.state.activeCompany.languages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
      },

      companyLanguages() {
        return this.$store.state.companyLanguages ? this.$store.state.companyLanguages : "none";
      },

      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },

      selectedCategoryProducts(){
        let products = new Array();
        if(this.productUsage.selectedCategory){
          this.products.forEach(product=>{
            if(product.category && product.category.length > 0){
              product.category.forEach(category=>{
                if(category == this.productUsage.selectedCategory){
                  products.push(product)
                }
              })
            }
          })
          return products;
        }
        else{
          return new Array()
        }
      },

      subtreatmentChanged() {
        if (
          _.isEqual(this.editSubtreatmentItems, this.editSubtreatmentItemsOriginal) == false
        ) {
          return true;
        } else {
          return false;
        }
      },

      changed() {
        this.subtreatmentChanged;
        if (
          _.isEqual(this.treatment, this.treatmentOriginal) == false 
        ) {
          return true;
        } else {
          return false;
        }
      },
      usageChanged() {
        if (
          _.isEqual(this.productUsage, this.productUsageOriginal) == false 
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
</script>
