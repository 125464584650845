<template>
  <div>
    <div v-if="loading">
      <div style="position: relative; padding: 200px 0">
        <div class="thrive-loader"></div>
      </div>
    </div>

    <div class="no-data-message" v-if="!loading && users.length == 0">
      <p>Er zijn nog geen gebruikers toegevoegd.</p>
      <v-btn fab dark flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="newUserDialog = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-container grid-list-md fluid class="ma-0 pa-0" v-if="!loading && users.length > 0">
      <v-layout row wrap class="ma-0 pa-0">
        <v-flex md2 xs6>
         <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
          <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
            <v-list-item class="pa-0 pl-4 ma-0">
              <v-list-item-content>
                <v-list-item-title>
                  <h2>{{ filteredUsersDevelopers }}</h2>
                </v-list-item-title>
                <v-list-item-subtitle>Developers</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar
                size="56"
                light
                class="ma-4 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuBackground" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].menuBackground}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuBackground">mdi-skull</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card>
        </v-flex>
        <v-flex md2 xs6>
         <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
          <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
            <v-list-item class="pa-0 pl-4 ma-0">
              <v-list-item-content>
                <v-list-item-title>
                  <h2>{{ filteredUsersSuperadmins }}</h2>
                </v-list-item-title>
                <v-list-item-subtitle>Superadmins</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar
                size="56"
                light
                class="ma-4 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card>
        </v-flex>
        <v-flex md2 xs6>
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
              <v-list-item class="pa-0 pl-4 ma-0">
                <v-list-item-content>
                  <v-list-item-title>
                    <h2>{{ filteredUsersAdmins }}</h2>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="filteredUsersAdmins == 1">Admin</span>
                    <span v-else>Admins</span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  size="56"
                  light
                  class="ma-4 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].secondary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-account</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
        <v-flex md2 xs6>
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
              <v-list-item class="pa-0 pl-4 ma-0">
                <v-list-item-content>
                  <v-list-item-title>
                    <h2>{{ filteredUsersManagers }}</h2>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="filteredUsersManagers == 1">Manager</span>
                    <span v-else>Managers</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar
                  size="56"
                  light
                  class="ma-4 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-account</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
        <v-flex md2 xs6>
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
              <v-list-item class="pa-0 pl-4 ma-0">
                <v-list-item-content>
                  <v-list-item-title>
                    <h2>{{ filteredUsersBranches }}</h2>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="filteredUsersBranches == 1">Vestiging</span>
                    <span v-else> {{ $t("general.branches", $store.state.locale) }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  size="56"
                  light
                  class="ma-4 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].quaternary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary">mdi-account</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>

        <v-flex md2 xs6>
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
              <v-list-item class="pa-0 pl-4 ma-0">
                <v-list-item-content>
                  <v-list-item-title>
                    <h2>{{ filteredUsersEmployees }}</h2>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="filteredUsersEmployees == 1">{{ $t("general.employee", $store.state.locale) }}</span>
                    <span v-else>{{ $t("general.employees", $store.state.locale) }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  size="56"
                  light
                  class="ma-4 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuBackground" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].menuBackground}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuBackground">mdi-account</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>

        <v-flex xs12>
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-4" :class="$store.state.companyTheme">
            <v-card-title>
              <v-text-field
                style="max-width: 300px"
                v-model="search"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :placeholder="$t('general.search', $store.state.locale)"
                outlined
                dense
                hide-details
                background-color="background"
                prepend-inner-icon="mdi-magnify"
                autocomplete="disabled"
                clearable
              >
              </v-text-field>
              <v-spacer></v-spacer>
              <div class="header-edit-button">
                <v-btn class="mx-2" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="newUserDialog = true">
                <v-icon left>mdi-plus</v-icon>  
                  {{ $t("user.newUser", $store.state.locale) }}
                </v-btn>
                <v-btn small fab darken :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" v-if="selected.length > 0" @click="deleteUser()">
                  <v-icon darken>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="headers"
              :items="filteredUsers"
              :items-per-page="userPages"
              v-model="selected"
              item-key="email"
              show-select
              hide-default-footer
              :no-results-text="`Geen resultaten gevonden voor: ${search}`"
              :style="{'background-color' : $themes[$store.state.companyTheme][$store.state.themeModus].card}"
              class="dense-table"
              >
              <template v-slot:item="{ item, isSelected, select }">
                <tr>
                  <td>
                    <v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
                    <v-icon @click="select(false)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="isSelected">mdi-checkbox-marked</v-icon>
                  </td>
                  <td class="circle-wrapper">
                    <div class="circle" v-bind:class="{ active: item.active, disabled: !item.active }"></div>
                  </td>
                  <td>{{ item.firstName }} {{ item.lastName }}</td>
                  <td>
                    <country-flag v-if="item.language" class="mt-0 pt-0" :country="userAccountLanguage(item.language).flagCode" size='small' />
                    <country-flag v-else class="mt-0 pt-0" country="NL" size='small' />
                  </td>
                  <td>{{ userAccountRole(item.userRole).name }}</td>
                  <td>{{ userAccountCompany(item.companyId).name }}</td>
                  <td>
                    <span v-for="company in item.accessCompanies" :key="company.id">
                      <div>&#8226; {{ userAccountCompany(company).name }}</div>
                    </span>
                  </td>
                  <td>
                    <v-btn style="float: right" icon class="mx-0" @click="seeUserLog(item)">
                      <v-icon small color="text">mdi-text</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn icon @click="editUser(item)">
                      <v-icon small color="text">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- / New User Dialog \ -->
    <v-dialog v-model="newUserDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <add-user-form :companies="companies" @newUserDialogChange="newUserDialog = $event" @updateData="loadUsers()"></add-user-form>
    </v-dialog>
    <!-- \ New User Dialog / -->
    <!-- / Edit User Dialog \ -->
    <v-dialog v-model="editUserDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <edit-user-form
        :showEditDialog="editUserDialog"
        :companies="companies"
        :userFormInfoOriginal="editUserInfoOriginal"
        :userFormInfo="editUserInfo"
        @userEditDialogChange="editUserDialog = $event"
        @updateData="loadUsers()"
      ></edit-user-form>
    </v-dialog>
    <!-- \ Edit User Dialog / -->

    <!-- / View User Logs \ -->
    <view-user-logs :selectedUserCompany="selectedUserCompany"></view-user-logs>
    <!-- \ View User Logs / -->
  </div>
</template>

<script>
  import db from "@/firebase/init";
  import axios from "axios";
  import "@firebase/firestore";
  import firebase from "firebase/app";
  import "firebase/auth";
  import { bus } from "@/main";
  import AddUserForm from "@/components/modals/AddUserForm.vue";
  import EditUserForm from "@/components/modals/EditUserForm.vue";
  import ViewUserLogs from "@/components/modals/ViewUserLogs.vue";

  export default {
    components: {
      "add-user-form": AddUserForm,
      "edit-user-form": EditUserForm,
      "view-user-logs": ViewUserLogs,
    },
    name: "users",
    data() {
      return {
        userPages: -1,
        loading: true,
        search: "",
        selectedUserCompany: null,
        editUserDialog: false,
        newUserDialog: false,
        editUserInfoOriginal: {
          accessCompanies: new Array(),
          company: "",
          firstName: "",
          lastName: "",
          id: "",
          userRole: "",
          user_id: "",
        },
        editUserInfo: {
          accessCompanies: new Array(),
          company: "",
          firstName: "",
          lastName: "",
          id: "",
          userRole: "",
          user_id: "",
        },
        removeBookings: "",
        selected: new Array(),
        companies: new Array(),
        users: new Array(),
        languages: [
          {
            name: "Nederlands",
            id: "nl",
            code: "NL",
          },
          {
            name: "English",
            id: "gb",
            code: "EN",
          },
        ],
        userRoles: [
          {
            name: "Admin",
            id: "admin",
          },
          {
            name: "Super Admin",
            id: "superadmin",
          },
          {
            name: "Developer",
            id: "developer",
          },
          {
            name: "Bedrijf",
            id: "company",
          },
          {
            name: "Vestiging",
            id: "branch",
          },
          {
            name: "Manager",
            id: "manager",
          },
          {
            name: this.$t("general.employee", this.$store.state.locale),
            id: "employee",
          },
        ],
        headers: [
          {
            text: "",
            align: "left",
            value: "active",
            width: "20px",
          },
          {
            text: this.$t("user.name", this.$store.state.locale),
            align: "left",
            value: "firstName",
            width: "400px",
          },
          {
            text: this.$t("user.language", this.$store.state.locale),
            align: "left",
            value: "language",
            width: "120px",
          },
          {
            text: "Type",
            align: "left",
            value: "userRole",
          },
          {
            text: this.$t("user.company", this.$store.state.locale),
            align: "left",
            value: "company",
          },
          {
            text: this.$t("user.access", this.$store.state.locale),
            align: "left",
            value: "companies",
          },
          {
            text: "",
            align: "right",
            value: "logs",
            width: "40px",
          },
          {
            text: "",
            align: "right",
            value: "edit",
            width: "40px",
          },
        ],
      };
    },

    created() {
      this.loadCompanies();
    },

    methods: {
      loadCompanies() {
        this.loading = true;
        db.collection("companies")
          .get()
          .then((snap) => {
            this.companies = [];
            snap.forEach((doc) => {
              let compCollection = {};
              compCollection.name = doc.data().name;
              compCollection.id = doc.id;
              this.companies.push(compCollection);
            });
          })
          .then(() => {
            this.loadUsers();
          });
      },
      closeUserDialog() {
        this.newUserDialog = false;
        this.editUserDialog = false;
        // this.$emit('resetForm');
      },

      seeUserLog(item) {
        this.selectedUserCompany = this.userAccountCompany(item.company).name;
        let username = item.firstName + " " + item.lastName;
        bus.$emit("getUserLogs", username);
      },

      editUser(item) {
        this.editUserDialog = true;
        this.editedIndex = this.users.indexOf(item);
        this.editUserInfo = Object.assign({}, item);
        this.editUserInfoOriginal = Object.assign({}, item);
      },

      userAccountLanguage(code) {
        
        let accountData = {};
        let data = _.find(this.availableLanguages, ["languageCode", code]);
        if (data) {
          accountData = data;
        }
        return accountData;
      },
      userAccountRole(id) {
        let userRoleData = {};
        let data = _.find(this.userRoles, ["id", id]);
        if (data) {
          userRoleData = data;
        }
        return userRoleData;
      },
      
      userAccountCompany(id) {
        let comanyData = {};
        let data = _.find(this.companies, ["id", id]);
        if (data) {
          comanyData = data;
        }
        return comanyData;
      },

      async deleteUser() {
        this.selected.forEach((element) => {
          let logData = {
            superAdminAction: false, // Is action only accessible by superadmin?
            actionType: "userAction",
            action: "userDeleted",
            actionItem: `${element.firstName} ${element.lastName}`,
            extra: [
              {
                name: "name",
                value: `${element.firstName} ${element.lastName}`
              },
              {
                name: "role",
                value: element.role
              },
              {
                name: "email",
                value: element.email
              },
              {
                name: "company",
                value: element.company
              },
              {
                name: "access",
                value: element.accessCompanies
              }
            ]
          };

          db.collection("users")
            .doc(element.id)
            .delete()
            .then(() => {
              this.$emit("updateData", this.productSupplierId); // Log Action
            })
            .then(() => {
              //  Delete User from Firebase Auth
              firebase
                .auth()
                .currentUser.getIdToken(true)
                .then((idToken) => {
                  axios
                    .post(`${this.$store.state.restApi.url}/auth`, {
                      token: idToken,
                      type: "deleteUser",
                      uid: element.user_id,
                      employeeId: element.employeeId,
                    })
                    .then(
                      (response) => {
                        console.log(response);
                      },
                      (error) => {
                        console.error("Error deleting User Account", error);
                      }
                    );
                })
                .catch((error) => {
                  console.error("Error getting ID Token", error);
                });

              this.selected = [];
              console.log("User successfully deleted!");
              this.loadUsers();
            })
            .catch((error) => {
              this.selected = [];
              console.error("Error removing user: ", error);
            });
        });
      },

      loadUsers() {
        db.collection("users")
          .orderBy("companyId")
          .get()
          .then((snap) => {
            this.users = [];
            snap.forEach((doc) => {
              let userCollection = doc.data();
              userCollection.id = doc.id;
              this.users.push(userCollection);
            });
          })
          .then(() => {
            this.loading = false;
          });
      },
    },

    computed: {
      availableLanguages(){
        return this.$countries;
      },

      filteredUsers() {
        if (this.search) {
          return this.users.filter((user) => {
            return user.firstName.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || user.lastName.toLowerCase().indexOf(this.search.toLowerCase()) >= 0;
          });
        } else {
          return this.users;
        }
      },
      filteredUsersDevelopers() {
        let counter = 0;
        this.filteredUsers.forEach((user) => {
          if (user.userRole == "developer") {
            counter++;
          }
        });
        return counter;
      },
      filteredUsersSuperadmins() {
        let counter = 0;
        this.filteredUsers.forEach((user) => {
          if (user.userRole == "superadmin") {
            counter++;
          }
        });
        return counter;
      },
      filteredUsersAdmins() {
        let counter = 0;
        this.filteredUsers.forEach((user) => {
          if (user.userRole == "admin") {
            counter++;
          }
        });
        return counter;
      },

      filteredUsersManagers() {
        let counter = 0;
        this.filteredUsers.forEach((user) => {
          if (user.userRole == "manager") {
            counter++;
          }
        });
        return counter;
      },

      filteredUsersEmployees() {
        let counter = 0;
        this.filteredUsers.forEach((user) => {
          if (user.userRole == "employee") {
            counter++;
          }
        });
        return counter;
      },

      filteredUsersBranches() {
        let counter = 0;
        this.filteredUsers.forEach((user) => {
          if (user.userRole == "branch" ) {
            counter++;
          }
        });
        return counter;
      },
    },
  };
</script>
<style scoped>
  .usertype-total {
    padding: 10px;
  }

  .usertype-total-number {
    text-align: center;
    font-size: 38px;
  }
  .usertype-total-desc {
    text-align: center;
    font-size: 16px;
  }
</style>
