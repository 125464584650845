<template>
  <div>
    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>

    <v-card v-if="!loading" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
      <v-container grid-list-md class="ma-0 pa-0">
        <v-layout class="mx-4 my-2 pa-0" d-flex> <!-- Standaard berichten -->
          <v-flex xs4 d-flex>
            <h3>{{ $t('emails.defaultMessages', $store.state.locale) }}</h3>
          </v-flex>
        </v-layout>
      </v-container>

      <v-divider class="ma-0"></v-divider>

      <v-data-table
        :headers="defaultMessagesHeader"
        :items="defaultMessages"
        item-key="name"
        class="dense-table"
        hide-default-footer
        :itemsPerPage.sync="pages"
        > 
        <template v-slot:item="{item}">
          <tr >
            <td><strong>{{ item.name[($store.state.locale).toLowerCase()] }}</strong></td>
            <td>
              <div v-if="item.messageTypes.email">
                <div v-if="item.messages.email" style="min-height: 35px">
                  <v-btn small dark class="mt-1 mr-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editEmail(item.messages.email)">
                    <v-icon small left>mdi-pencil</v-icon> {{ $t('emails.edit', $store.state.locale) }}
                  </v-btn>
                  <v-btn small outlined class="mt-1 mr-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="previewMessage(item.messages.email, 'email')">
                    <v-icon small left>mdi-eye</v-icon> {{ $t('general.view', $store.state.locale) }}
                  </v-btn>
                </div>
                <div v-else class="mt-2" style="min-height: 35px">
                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small outlined color="red" @click="addNewDefaultMessage(item.type, 'email')">{{ $t('general.add', $store.state.locale) }}</v-btn>
                    </template>
                    <span style="color: #fff; font-size: 11px">{{ $t('emails.defaultEmailNotSet', $store.state.locale) }}</span>
                  </v-tooltip>
                </div>
              </div>
            </td>
            <td>
              <div v-if="item.messageTypes.text">
                <div v-if="item.messages.text" style="min-height: 35px">
                  <v-btn small dark class="mt-1 mr-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editText(item.messages.text)">
                    <v-icon small left>mdi-pencil</v-icon> {{ $t('emails.edit', $store.state.locale) }}
                  </v-btn>
                  <v-btn small outlined class="mt-1 mr-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="previewMessage(item.messages.text, 'text')">
                    <v-icon small left>mdi-eye</v-icon> {{ $t('general.view', $store.state.locale) }}
                  </v-btn>
                </div>
                <div v-else class="mt-2" style="min-height: 35px">
                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small outlined color="red" @click="addNewDefaultMessage(item.type, 'text')">{{ $t('general.add', $store.state.locale) }}</v-btn>
                    </template>
                    <span style="color: #fff; font-size: 11px">{{ $t('emails.defaultTextNotSet', $store.state.locale) }}</span>
                  </v-tooltip>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
  </v-card>
    

    <!-- / Add Message Dialog \ -->
    <v-dialog v-model="newDefaultMessage.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon v-if="newDefaultMessage.messageType == 'email'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-email</v-icon>
            <v-icon v-if="newDefaultMessage.messageType == 'text'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-cellphone-text</v-icon>
          </v-avatar>
          <h3 class="mb-4">{{ getMessageName(newDefaultMessage.type) }}</h3> 
          <p v-if="newDefaultMessage.messageType == 'email'" class="ma-0">E-mail</p>    
          <p v-else class="ma-0">{{ $t('emails.textMessage', $store.state.locale) }}</p>        
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="newDefaultMessage.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="createNewDefaultMail()">
                   {{ $t('general.add', $store.state.locale) }} 
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Add Message Dialog / -->

    <!-- / Edit Email \ -->
    <email-editor :activeCompData="activeCompData" @updated="refreshDefaultMessages()"></email-editor>
    <!-- \ Edit Email / -->

     <!-- / Edit SMS \ -->
    <sms-editor :activeCompData="activeCompData"></sms-editor>
    <!-- \ Edit SMS / -->

    <!-- / Email Preview \ -->
    <email-preview :activeCompData="activeCompData"></email-preview>
    <!-- \ Email Preview / -->
  </div>
</template>


<script>
import '@firebase/firestore';
import db from "@/firebase/init";
import { bus } from "@/main";
import EmailEditor from "@/views/EmailEditor.vue";
import SmsEditor from "@/views/SmsEditor.vue";
import EmailPreview from "@/views/EmailPreview.vue";

export default {
  components: {
    "email-editor": EmailEditor,
    "email-preview": EmailPreview,
    "sms-editor": SmsEditor
  },
  name: "defaultMessages",
  data() {
    return {
      activeCompData: { // Default Company Data Thrive 365
        id: 'yGAsIMS9W0rudo6SP5nS',
        name: "Thrive"
      },
      companyPages: 30,
      search: "",
      editCompDialog: false,
      newCompDialog: false,
      selectedCompanyInfo: new Object(),
      selectedCompanyInfoOriginal: new Object(),
      removeBookings: "",
      loading: true,
      selected: [],
      branches: [],
      companies: [],
      pagination: {
        sortBy: "name",
        rowsPerPage: -1,
        descending: false
      },
      pages: -1,
      defaultMessages: [
        {
          name: {
            nl: 'Afspraakbevestiging',
            en: 'Appointment Confirmation'
          },
          type: "appointmentConfirm",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Verzoek om aanbetaling als bevestiging',
            en: 'Request for deposit as confirmation'
          },
          type: "appointmentConfirmDownPayment",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Afspraakherinnering',
            en: 'Appointment reminder'
          },
          type: "appointmentReminder",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Aanbetaling herinnering',
            en: 'Desposit reminder'
          },
          type: "downPaymentReminder",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Afspraak wijziging',
            en: 'Appointment changes'
          },
          type: "appointmentChanges",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Afspraak annulering',
            en: 'Appointment cancelation'
          },
          type: "appointmentCancel",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Afspraak annulering (geen aanbetaling ontvangen)',
            en: 'Appointment cancelation (no deposit received)'
          },
          type: "appointmentCancelNoDownPayment",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Inschrijfbevestiging',
            en: 'Subscription Confirmation'
          },
          type: "eventConfirm",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Herinnering inschrijving',
            en: 'Subscription reminder'
          },
          type: "eventReminder",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Inschrijving wijziging',
            en: 'Event changes'
          },
          type: "eventChanges",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Inschrijving annulering of uitschrijving',
            en: 'Subscription cancelation or unsubscription'
          },
          type: "eventCancel",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Digitale bon na afrekenen',
            en: 'Digital receipt after payment'
          },
          type: "receipt",
          messageTypes: {
            email: true,
            text:  false
          },
          messages: {
            email: null,
            text:  null
          }
        },
      ], 
      customMessages: new Array(),
      defaultMessagesHeader: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "name",
          width: "400px"
        },
        {
          text: this.$t('general.email', this.$store.state.locale),
          align: "left",
          value: "name",
          sortable: false
        },
        {
          text: this.$t('emails.textMessage', this.$store.state.locale),
          align: "left",
          value: "name",
          sortable: false
        }
      ],

      newDefaultMessage: {
        dialog: false,
        type: null,
        messageType: null,
      },
      
    }
  },

  created() {
    this.loadDefaultMesssages()
  },

  methods: {
    getMessageName(type){
      let name = null;
      let counter = 0;
      new Promise((resolve)=>{
        this.defaultMessages.forEach(message => {
          if(type == message.type){
            name = message.name[(this.$store.state.locale).toLowerCase()];
          }
          counter++
          if(counter == this.defaultMessages.length){
            resolve()
          }
        });
      })
      return name
    },
    
    refreshDefaultMessages(){
      this.loadDefaultMesssages();
    },

    editEmail(id){ // Open EditEmail.vue in Dialog with bus
      console.log("editEmail: ", id)
      bus.$emit("editEmailTemplate", id, 'admin'); // Send Message Id and Type to EditEmail.vue (Admin type had global variables to use)
    },

    previewMessage(id, messageType){
      console.log("id, messageType: ", id, messageType)
      let receipt = messageType == 'receipt' ? receiptPreview :  "";
      bus.$emit("previewMessageTemplate", id, "w09SClkDxMoYN7EOSU8B", null, null, null, messageType, receipt);
    },

    editText(id){ // Open EditSMS.vue in Dialog with bus
      bus.$emit("editTextTemplate", id, 'admin'); // Send Message Id and Type to SendEmail.vue (id: Admin has global variables to use)
    },

    addNewDefaultMessage(type, messageType){
      this.newDefaultMessage.dialog = true;
      this.newDefaultMessage.messageType = messageType;
      this.newDefaultMessage.type = type;
    },

    async loadDefaultMesssages(){
      this.loading = true;
      let defaultTemplates = new Array();
      await db.collection("messages")
      .where("templateType", "==", "general")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let messageTemplate = new Object();
          messageTemplate.type = doc.data().type;
          messageTemplate.messageType = doc.data().messageType;
          messageTemplate.id = doc.id;
          defaultTemplates.push(messageTemplate);
        });
      })

      if(defaultTemplates.length > 0){
        await new Promise((resolve)=>{
          let counter = 0;
          this.defaultMessages.forEach(message => {
            defaultTemplates.forEach(defaultMessage => {
              if(defaultMessage.type == message.type){
                message.messages[defaultMessage.messageType] = defaultMessage.id
              }
            })
            counter++
            if(counter == this.defaultMessages.length){
              resolve()
            }
          });
        })
        this.loading = false;

      }
      else{
        this.loading = false;
      }
    },

    async createNewDefaultMail(){
      let docId = null;
      await db.collection("messages")
      .add({
        templateType: 'general',
        type: this.newDefaultMessage.type,
        messageType: this.newDefaultMessage.messageType,
        campaignType: 'transactional', // Transactional email to inform the client, no promotion
        content: this.newDefaultMessage.messageType == 'email' ? null : new Object(),
        subject: new Object(),
        companyId: 'thrive'
      })
      .then((docRef) => {
        docId = docRef.id
      })

      await new Promise((resolve)=>{
        let counter = 0;
        this.defaultMessages.forEach(message => {
          if(this.newDefaultMessage.type == message.type){
            message.id = docId
          }
          counter++
          if(counter == this.defaultMessages.length){
            resolve()
          }
        });
      })

      this.newDefaultMessage.dialog = false;
      this.newDefaultMessage.messageType = null;
      this.newDefaultMessage.type = null;
      this.refreshDefaultMessages()
    }

  }
};
</script>
