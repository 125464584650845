<template>
  <div>
    <div v-if="loading">
        <div style="position: relative; padding: 200px 0;">
          <div class="thrive-loader"></div>
        </div>
      </div>
    <v-container v-else grid-list-md class="pa-0 ma-0" fluid>
      <v-layout row wrap>
        <v-flex md6 xs12 class="pr-5">
          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 ma-0" :class="$store.state.companyTheme" style="width: 100%">
            <h3>Algemeen</h3>
            <v-divider class="ma-0 pa-0 pt-3 mt-3"></v-divider>

            <div><p class="my-2 pa-0"><strong>Naam:</strong> {{ selectedMembership.name[userLanguage.toLowerCase()] }} </p></div>
            <div><p class="my-2 mb-5 pa-0"><strong>Omschrijving:</strong> {{ selectedMembership.posDescription[userLanguage.toLowerCase()] }} </p></div>
            <div><p class="my-2 pa-0"><strong>Start:</strong> {{ selectedMembership.start }} </p></div>
            <div><p class="my-2 pa-0"><strong>Periode:</strong> {{ paymentPeriode }} </p></div>
            <div><p class="my-2 pa-0"><strong>Looptijd:</strong> {{ selectedMembership.duration }} {{ durationSuffix}}</p></div>
            <div><p class="my-2 pa-0"><strong>Betaling verwerken op:</strong> {{ selectedMembership.paymentDay }}e van de {{ paymentPeriode }}</p></div>
            <div>
              <p class="my-1 pa-0"><strong>Automatisch verlengen: </strong>

              <v-chip small block label v-if="selectedMembership.autoRenew" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >Ja</v-chip>
              <v-chip small block label v-else color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-0 custom-chip-style" >Nee</v-chip>
              </p>
            </div>
            </v-card>


            <!-- <v-row >
              <v-col class="d-flex" sm="12">
                <v-text-field
                  label="Betaalmethodes"
                  item-text="name"
                  item-value="id"
                  :value="selectedMembership.paymentTypes"
                  disabled
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  hide-details
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row> -->
  
          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 mt-5 obvious" :class="$store.state.companyTheme" style="width: 100%">
            <h3 :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary + '!important'}">Prijs per {{ paymentPeriode }}</h3>
            <v-divider class="py-3 my-3"></v-divider>

            <v-container class="pa-0">
              <v-layout row>
                <v-flex md4>
                  <v-text-field
                    style="width: 100%"
                    label="BTW"
                    :value="getVatPercentage(selectedMembership.vat)"
                    readonly
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    suffix="%"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </v-flex>

                <v-flex md4>
                  <v-text-field
                    style="width: 100%"
                    label="prijs netto"
                    :value="selectedMembership.amount"
                    readonly
                    prefix="€"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                
                <v-flex md4>
                  <v-text-field
                    style="width: 100%"
                    label="prijs bruto"
                    :value="price.netto"
                    readonly
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    type="number"
                    prefix="€"
                    hide-details
                  ></v-text-field>
                </v-flex>

              </v-layout>
              <v-layout style="margin: 10px 0 5px 0" v-if="!selectedMembership.periodicPaymentDiscount.active">
                <v-flex md4>
                  <p class="ma-0" style="font-size: 14px;">
                    <strong>Totale kosten</strong><br>
                    {{ selectedMembership.duration }} {{ durationSuffix}}
                  </p>
                </v-flex>
                <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;">&euro; {{ price.netto }} <span style="font-size: 12px; font-style: italic">excl. BTW</span></p></v-flex>
                <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;"><strong>&euro; {{ price.bruto }}</strong>  <span style="font-size: 12px; font-style: italic">incl. BTW</span></p></v-flex>
              </v-layout>

              <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card pa-3 mt-3" :class="$store.state.companyTheme" style="width: 100%"  v-if="selectedMembership.singlePaymentDiscount.active">
                <h3 class="pb-3">Prijs bij éénmalige betaling</h3>
                <v-layout>
                  <v-flex md4>
                    <p class="ma-0" style="font-size: 14px;">
                      <strong>Totale korting</strong>
                    </p>
                  </v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ price.singlePaymentDiscount.netto }}</p></v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px;"><strong>&euro; {{ price.singlePaymentDiscount.bruto }}</strong></p></v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4>
                    <p class="ma-0" style="font-size: 14px;">
                      <strong>Totale kosten</strong><br>
                      {{ selectedMembership.duration }} {{ durationSuffix}}
                    </p>
                  </v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;">&euro; {{ price.singlePaymentNetto }} <span style="font-size: 12px; font-style: italic">excl. BTW</span></p></v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;"><strong>&euro; {{ price.singlePaymentBruto }}</strong> <span style="font-size: 12px; font-style: italic">incl. BTW</span></p></v-flex>
                </v-layout>
              </v-card>

              <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card pa-3 mt-4" :class="$store.state.companyTheme" style="width: 100%"  v-if="selectedMembership.periodicPaymentDiscount.active">
                <h3 class="pb-3">Prijs bij periodiek betalen</h3>
                <v-layout>
                  <v-flex md4>
                    <p class="ma-0" style="font-size: 14px;">
                      <strong>Korting per {{ paymentPeriode }}</strong>
                    </p>
                  </v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ price.periodicPaymentDiscount.periodic.netto }}</p></v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px;"><strong>&euro; {{ price.periodicPaymentDiscount.periodic.bruto }}</strong></p></v-flex>
                </v-layout>

                <v-layout>
                  <v-flex md4>
                    <p class="ma-0" style="font-size: 14px;">
                      <strong>Prijs per {{ paymentPeriode }}</strong>
                    </p>
                  </v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ (selectedMembership.amount - price.periodicPaymentDiscount.periodic.netto).toFixed(2) }}</p></v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px;"><strong>&euro; {{ (selectedMembership.totalPrice - price.periodicPaymentDiscount.periodic.bruto).toFixed(2) }}</strong></p></v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4>
                    <p class="ma-0" style="font-size: 14px;">
                      <strong>Totale kosten</strong><br>
                      {{ selectedMembership.duration }} {{ durationSuffix}}
                    </p>
                  </v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;">&euro; {{ price.periodicPaymentNetto }} <span style="font-size: 12px; font-style: italic">excl. BTW</span></p></v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;"><strong>&euro; {{ price.periodicPaymentBruto }}</strong> <span style="font-size: 12px; font-style: italic">incl. BTW</span></p></v-flex>
                </v-layout>
              </v-card>

            </v-container>
          </v-card>
          
          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 mt-5 " :class="$store.state.companyTheme" style="width: 100%">
            <h3>Kortingen op behandelingen en producten</h3>
            <v-divider class="ma-0 pa-0 pt-3 mt-3"></v-divider>

            <!-- <v-switch
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              v-if="selectedMembership.paymentTypes == 'single' || selectedMembership.paymentTypes == 'singleAndPeriodic'"
              label="Korting bij eenmalige betaling"
              v-model="selectedMembership.singlePaymentDiscount.active"
              readonly
              inset
              hide-details
              class="mb-2"
            ></v-switch>
            <v-container grid-list-md v-if="selectedMembership.singlePaymentDiscount.active" class="pt-0 px-0">
              <v-layout row>
                <v-flex md6>
                  <v-text-field
                    class="mt-4"
                    :value="selectedMembership.singlePaymentDiscount.type"
                    label="Type korting"
                    disabled
                    dense
                    hide-details
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  ></v-text-field>
                </v-flex>
                <v-flex md6>
                  <v-text-field
                    class="mt-4"
                    :label="singleDiscountInfo.label"
                    :value="selectedMembership.singlePaymentDiscount.number"
                    :prefix="singleDiscountInfo.prefix"
                    :suffix="singleDiscountInfo.suffix"
                    hide-details
                    dense
                    outlined
                    disabled
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>

            <v-switch 
              v-if="selectedMembership.paymentTypes == 'periodic' || selectedMembership.paymentTypes == 'singleAndPeriodic'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              label="Periodieke korting"
              :v-model="selectedMembership.periodicPaymentDiscount.active"
              readonly
              inset
              hide-details
              class="mb-2"
            ></v-switch>

            <v-container grid-list-md v-if="selectedMembership.periodicPaymentDiscount.active" class="pt-0 px-0">
              <v-text-field
                label="Type korting"
                :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                :value="selectedMembership.periodicPaymentDiscount.discountType"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                outlined
                disabled
                dense
                class="mt-4"
                hide-details
              ></v-text-field>

              <v-layout row v-if="selectedMembership.periodicPaymentDiscount.discountType == 'general'">
                <v-flex md6>
                  <v-text-field
                    class="mt-4"
                    :value="selectedMembership.periodicPaymentDiscount.type"
                    label="Type korting"
                    dense
                    disabled
                    hide-details
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  ></v-text-field>
                </v-flex>
                <v-flex md6>
                  <v-text-field
                    class="mt-4"
                    :label="periodicDiscountInfo.label"
                    :value="selectedMembership.periodicPaymentDiscount.number"
                    hide-details
                    outlined
                    dense
                    disabled
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row v-else>
                <v-flex md12>
                  <v-alert
                    dense
                    text
                    style="width: 100%;"
                    class="mt-2 ma-0"
                    outlined
                    type="info" 
                    prominent 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    >
                    Korting is bij het afsluiten van de cliënt ingesteld. 
                  </v-alert>
                </v-flex>
                <v-flex md6>
                  <v-text-field
                    :label="`Maximale korting per ${paymentPeriode}`"
                    type="number"
                    prefix="€"
                    dense
                    disabled
                    :value="selectedMembership.periodicPaymentDiscount.max"
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    class="mt-4"
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex>
                  <v-switch
                    v-if="selectedMembership.autoRenew" 
                    label="Periodieke korting blijft na automatische verlenging"
                    :value="selectedMembership.periodicPaymentDiscount.continue"
                    disabled
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    class="mb-2"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-container> -->

            <v-switch
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              label="Extra kortingen"
              inset
              v-model="selectedMembership.separatedDiscount.active"
              readonly
              hide-details
              class="ma-0 pa-0 mb-2 ml-1"
            ></v-switch>

            <v-container v-if="selectedMembership.separatedDiscount.active" class="pt-0 px-0">             
              <v-layout row>
                <v-flex xs12>
                  <!-- <v-alert
                    v-if="!selectedMembership.separatedDiscount.splitDiscounts"
                    dense
                    text
                    style="width: 100%;"
                    class="mt-2 ma-0"
                    outlined
                    type="info" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    >
                    Deze kortingen worden <strong>altijd toegepast</strong> gedurende de abonnementsduur.
                  </v-alert>
                  <v-alert
                    v-if="selectedMembership.separatedDiscount.splitDiscounts"
                    dense
                    text
                    style="width: 100%;"
                    class="mt-2 ma-0"
                    outlined
                    type="info" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    >
                    Deze kortingen worden alleen toegepast bij bezoek op moment dat het abonnement <strong>geldig</strong> is.
                  </v-alert> -->

                  <div class="mt-1" v-if="selectedMembership.separatedDiscount.discountsInsideSubscriptionDays && selectedMembership.separatedDiscount.discountsInsideSubscriptionDays.length > 0">
                    <v-chip label v-for="(discount, index) in selectedMembership.separatedDiscount.discountsInsideSubscriptionDays" :key="index" :color="$themes[$store.state.companyTheme][$store.state.themeModus][getDiscountName(discount).color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][getDiscountName(discount).color]" light class="mr-2 my-1 custom-chip-style" >{{ getDiscountName(discount).name }}</v-chip>
                  </div>

                </v-flex>
                <v-flex md12>
                  <v-switch
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    label="Andere kortingen gebruiken bij bezoek op moment dat abonnement niet geldig is."
                    v-model="selectedMembership.separatedDiscount.splitDiscounts"
                    readonly
                    inset
                    hide-details
                    class="pa-0 ma-0 mt-1 mb-1 ml-1"
                  ></v-switch>
                </v-flex>
                <v-flex xs12>
                  <!-- <v-alert
                    v-if="selectedMembership.separatedDiscount.splitDiscounts"
                    dense
                    text
                    style="width: 100%;"
                    class="mt-2 ma-0"
                    outlined
                    type="info" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    >
                    Deze kortingen worden alleen toegepast bij bezoek op moment dat het abonnement <strong>niet geldig</strong> is.
                  </v-alert> -->

                  <div class="mt-1" v-if="selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays && selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays.length > 0">
                    <v-chip label v-for="(discount, index) in selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays" :key="index" :color="$themes[$store.state.companyTheme][$store.state.themeModus][getDiscountName(discount).color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][getDiscountName(discount).color]" light class="mr-2 my-1 custom-chip-style" >{{ getDiscountName(discount).name }}</v-chip>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>

          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 mt-5" :class="$store.state.companyTheme" style="width: 100%"  v-if="selectedMembership.paymentTypes == 'periodic'">
              <h3>Proefperiode</h3>
              <v-divider class="py-3 my-3"></v-divider>
              <v-switch 
                label="Proefperiode"
                :value="selectedMembership.freePeriod.active"
                disabled
                hide-details
                class="mb-2"
              ></v-switch>

              <div v-if="selectedMembership.freePeriod.active">

                <v-text-field
                  label="Type proefperiode"
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  :value="selectedMembership.freePeriod.type"
                  disabled
                  outlined
                  hide-details
                ></v-text-field>

                <v-row v-if="selectedMembership.freePeriod.type == 'general'" class="ma-0">
                  <v-flex md12>
                    <v-alert
                      dense
                      text
                      style="width: 100%;"
                      class="mt-2 ma-0"
                      outlined
                      type="info" 
                      prominent 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      >
                      Proefperiode geldt voor <strong>iedereen</strong> die het abonnement afsluit.
                    </v-alert>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      label="Proefperiode"
                      type="number"
                      :value="selectedMembership.freePeriod.number"
                      disabled
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      :suffix="durationSuffix"
                      class="mt-4"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-row>

                <v-row  v-else class="ma-0">
                  <v-flex md12>
                    <v-alert
                      dense
                      text
                      style="width: 100%;"
                      class="mt-2 ma-0"
                      outlined
                      type="info" 
                      prominent 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      >
                      Proefperiode kan bij het versturen <strong>per cliënt</strong> ingesteld worden. 
                    </v-alert>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      label="Maximale proefperiode"
                      type="number"
                      :value="selectedMembership.freePeriod.number"
                      disabled
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      :suffix="durationSuffix"
                      class="mt-4"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-row>
              </div>
          </v-card>

          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 mt-5" :class="$store.state.companyTheme" style="width: 100%" v-if="selectedMembership.treatments && selectedMembership.treatments.active && selectedMembership.treatments.treatments">
            <h3 v-if="selectedMembership.treatments.treatments.length > 1" class="client-overview-subtitle pa-0 ma-0">Gekoppelde behandelingen</h3>
            <h3 v-else class="pa-0 ma-0">Gekoppelde behandeling</h3>
            <v-divider class="ma-0 pa-0 pt-3 mt-3"></v-divider>
            <div class="mt-1 mb-2">
              <v-chip label v-for="(treatment, index) in selectedMembership.treatments.treatments" :key="index" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 my-1 custom-chip-style" >{{ treatment.title }}</v-chip>
            </div>
            <div v-if="selectedMembership.treatments.limit">
              <p class="mb-2" style="font-size: 13px"><i>Mag {{ selectedMembership.treatments.limitNumber }}x <span v-if="selectedMembership.treatments.limitType == 'period'"> per {{ getPeriodName(selectedMembership.paymentPeriod) }}</span> gebruikt worden.</i></p>
            </div>
          </v-card>

            <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pt-3 mt-5" :class="$store.state.companyTheme" style="width: 100%" v-if="selectedMembership.days">
              <h3 class="pt-0 pb-2 px-3">Geldigheid</h3>
              
              <v-container grid-list-md class="pa-0 ma-0" fluid>
                <v-divider class="ma-0 pa-0"></v-divider>
                <v-layout row wrap class="px-3">
                  <v-flex xs3><p class="ma-0 mt-1"><strong>{{ $t("general.monday", $store.state.locale) }}</strong></p></v-flex>
                  <v-flex xs3>
                    <v-chip small block label v-if="selectedMembership.days.Mon.active && selectedMembership.days.Mon.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >Gehele dag geldig</v-chip>
                    <v-chip small block label v-if="selectedMembership.days.Mon.active && !selectedMembership.days.Mon.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" >Beperkt geldig</v-chip>
                    <v-chip small block label v-if="!selectedMembership.days.Mon.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >Niet geldig</v-chip>
                  </v-flex>
                  <v-flex xs6>
                    <p style="font-size: 13px" v-if="selectedMembership.days.Mon.active && !selectedMembership.days.Mon.allDay" class="ma-0 pa-0 mt-2">Geldig van {{ selectedMembership.days.Mon.start }}u tot {{ selectedMembership.days.Mon.end }}u </p>
                  </v-flex>
                </v-layout>
                
                <v-divider class="pa-0 ma-0"></v-divider>

                <v-layout row wrap class="px-3">
                  <v-flex xs3><p class="ma-0 mt-1"><strong>{{ $t("general.tuesday", $store.state.locale) }}</strong></p></v-flex>
                  <v-flex xs3>
                    <v-chip small label v-if="selectedMembership.days.Tue.active && selectedMembership.days.Tue.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >Gehele dag geldig</v-chip>
                    <v-chip small label v-if="selectedMembership.days.Tue.active && !selectedMembership.days.Tue.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" >Beperkt geldig</v-chip>
                    <v-chip small label v-if="!selectedMembership.days.Tue.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >Niet geldig</v-chip>
                  </v-flex>
                  <v-flex xs6>
                    <p style="font-size: 13px" v-if="selectedMembership.days.Tue.active && !selectedMembership.days.Tue.allDay" class="ma-0 mt-2">Geldig van {{ selectedMembership.days.Tue.start }}u tot {{ selectedMembership.days.Tue.end }}u </p>
                  </v-flex>
                </v-layout>
                <v-divider class="pa-0 ma-0"></v-divider>

                <v-layout row wrap class="px-3">
                  <v-flex xs3><p class="ma-0 mt-1"><strong>{{ $t("general.wednesday", $store.state.locale) }}</strong></p></v-flex>
                  <v-flex xs3>
                    <v-chip small label v-if="selectedMembership.days.Wed.active && selectedMembership.days.Wed.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >Gehele dag geldig</v-chip>
                    <v-chip small label v-if="selectedMembership.days.Wed.active && !selectedMembership.days.Wed.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" >Beperkt geldig</v-chip>
                    <v-chip small label v-if="!selectedMembership.days.Wed.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >Niet geldig</v-chip>
                  </v-flex>
                  <v-flex xs6>
                    <p style="font-size: 13px" v-if="selectedMembership.days.Wed.active && !selectedMembership.days.Wed.allDay" class="ma-0 mt-2">Geldig van {{ selectedMembership.days.Wed.start }}u tot {{ selectedMembership.days.Wed.end }}u </p>
                  </v-flex>
                </v-layout>
                <v-divider class="pa-0 ma-0"></v-divider>

                <v-layout row wrap class="px-3">
                  <v-flex xs3><p class="ma-0 mt-1"><strong>{{ $t("general.thursday", $store.state.locale) }}</strong></p></v-flex>
                  <v-flex xs3>
                    <v-chip small label v-if="selectedMembership.days.Thu.active && selectedMembership.days.Thu.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >Gehele dag geldig</v-chip>
                    <v-chip small label v-if="selectedMembership.days.Thu.active && !selectedMembership.days.Thu.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" >Beperkt geldig</v-chip>
                    <v-chip small label v-if="!selectedMembership.days.Thu.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >Niet geldig</v-chip>
                  </v-flex>
                  <v-flex xs6>
                    <p  style="font-size: 13px"  v-if="selectedMembership.days.Thu.active && !selectedMembership.days.Thu.allDay" class="ma-0 mt-2">Geldig van {{ selectedMembership.days.Thu.start }}u tot {{ selectedMembership.days.Thu.end }}u </p>
                  </v-flex>
                </v-layout>
                <v-divider class="pa-0 ma-0"></v-divider>

                <v-layout row wrap class="px-3">
                  <v-flex xs3><p class="ma-0 mt-1"><strong>{{ $t("general.friday", $store.state.locale) }}</strong></p></v-flex>
                  <v-flex xs3>
                    <v-chip small label v-if="selectedMembership.days.Fri.active && selectedMembership.days.Fri.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >Gehele dag geldig</v-chip>
                    <v-chip small label v-if="selectedMembership.days.Fri.active && !selectedMembership.days.Fri.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" >Beperkt geldig</v-chip>
                    <v-chip small label v-if="!selectedMembership.days.Fri.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-2 custom-chip-style" >Niet geldig</v-chip>
                  </v-flex>
                  <v-flex xs6>
                    <p  style="font-size: 13px"  v-if="selectedMembership.days.Fri.active && !selectedMembership.days.Fri.allDay" class="ma-0 mt-1">Geldig van {{ selectedMembership.days.Fri.start }}u tot {{ selectedMembership.days.Fri.end }}u </p>
                  </v-flex>
                </v-layout>
                <v-divider class="pa-0 ma-0"></v-divider>

                <v-layout row wrap class="px-3">
                  <v-flex xs3><p class="ma-0 mt-1"><strong>{{ $t("general.saturday", $store.state.locale) }}</strong></p></v-flex>
                  <v-flex xs3>
                    <v-chip small label v-if="selectedMembership.days.Sat.active && selectedMembership.days.Sat.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >Gehele dag geldig</v-chip>
                    <v-chip small label v-if="selectedMembership.days.Sat.active && !selectedMembership.days.Sat.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" >Beperkt geldig</v-chip>
                    <v-chip small label v-if="!selectedMembership.days.Sat.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >Niet geldig</v-chip>
                  </v-flex>
                  <v-flex xs6>
                    <p  style="font-size: 13px" v-if="selectedMembership.days.Sat.active && !selectedMembership.days.Sat.allDay" class="ma-0 mt-2">Geldig van {{ selectedMembership.days.Sat.start }}u tot {{ selectedMembership.days.Sat.end }}u </p>
                  </v-flex>
                </v-layout>
                <v-divider class="pa-0 ma-0"></v-divider>

                <v-layout row wrap class="px-3">
                  <v-flex xs3><p class="ma-0 mt-1"><strong>{{ $t("general.sunday", $store.state.locale) }}</strong></p></v-flex>
                  <v-flex xs3>
                    <v-chip small label v-if="selectedMembership.days.Sun.active && selectedMembership.days.Sun.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >Gehele dag geldig</v-chip>
                    <v-chip small label v-if="selectedMembership.days.Sun.active && !selectedMembership.days.Sun.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" >Beperkt geldig</v-chip>
                    <v-chip small label v-if="!selectedMembership.days.Sun.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >Niet geldig</v-chip>
                  </v-flex>
                  <v-flex xs6>
                    <p style="font-size: 13px" v-if="selectedMembership.days.Sun.active && !selectedMembership.days.Sun.allDay" class="ma-0 mt-2">Geldig van {{ selectedMembership.days.Sun.start }}u tot {{ selectedMembership.days.Sun.end }}u </p>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
        </v-flex>

        <v-flex md6 xs12 v-if="selectedMembership.usage">
          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 pb-0" :class="$store.state.companyTheme" style="width: 100%">
            <h3 :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  class="client-overview-subtitle pt-0 pb-1">Gebruik</h3>
            <p v-if="selectedMembership.usage.length > 0">Het abonnement is {{ selectedMembership.usage.length }}x gebruikt.</p>
            <v-divider class="pt-3 mt-3"></v-divider>

            <div v-if="selectedMembership.usage && selectedMembership.usage.length > 0">
              <v-timeline class="pa-0 custom-dense-timeline" dense>
                <v-timeline-item v-for="(usage, index) in selectedMembership.usage.slice().reverse()" :key="index" small right :color="$themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)]"  class="py-4">
                  <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)]"  :style="{'borderColor': $themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)] + '!important'}" outlined>
                    <v-card-title class="py-0 px-2">
                      <v-list two-line class="pa-0 ma-0" style="width: 100%" :color="$themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)]"  disabled>
                        <v-list-item class="pa-0 ma-0">
                          <v-list-item-action class="ma-0 mr-2">
                            <v-icon v-if="usage.override" color="white">mdi-lightning-bolt</v-icon>
                            <v-icon v-else color="white">mdi-check-bold</v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="pa-0 ma-0">
                            <v-list-item-title class="white--text" style="font-weight: bold">
                              {{ usage.date | moment("dddd D MMMM YYYY") }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="usage.override">
                              <span class="white--text" style="font-size: 12px; line-height: 14px">Gebruik is geforceerd</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action v-if="usage.receiptId">
                            <v-btn icon>
                              <v-icon color="white">mdi-receipt</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-title>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </div>
            <div v-else class="py-4 text-center">

              <v-avatar 
                size="76"
                light
                class="ma-4 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clipboard-outline</v-icon>
              </v-avatar>
              
              <div class="pb-4">Er is nog geen gebruik gemaakt van dit abonnement.</div>
            </div>

          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import '@firebase/firestore';
import db from "../firebase/init";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { bus } from "../main";

const moment = extendMoment(Moment);

export default {
  name: "MembershipsInformation",

  props:{
    selectedMembership: Object
  },

  data(){
    return{
      overview: true,
      memberships:[],
      membershipsPerCompany:[],
      loading: true,
      search: null,
      membershipSearch: null,
      clientsFetched:false,
      newSubscription: {
        dialog: false,
        feedback: null,
        startDate: null,
        subscription: null,
        newclient: false,
      },
      selectedClient: new Object(),
      selectedClientOriginal: new Object(),
      customerDialog: false,
      genderSelect: 0,
      menuStartDate: false,
      valid: false,
      packagesLoading: true,
      membershipPackages: new Array(),
      clientData: {
        firstName: "",
        firstNameRules: [(v) => !!v || this.$t('booking.firstnameRequired', this.$store.state.locale)],
        surname: "",
        surnameRules: [(v) => !!v || this.$t('booking.lastnameRequired', this.$store.state.locale)],
        email: "",
        id: null,
        phone: "",
        emailRules: [(v) => !!v || this.$t('booking.emailRequired', this.$store.state.locale), (v) => /.+@.+/.test(v) || this.$t('booking.emailInvalid', this.$store.state.locale)],
      },
      membershipStartMinDate: null,
      headers:[
        { 
          text: this.$t('memberships.client', this.$store.state.locale),
          value: 'clientName'
        },
        { 
          text: this.$t('memberships.plan', this.$store.state.locale),
          value: 'ClientId'
        },
        { 
          text: this.$t('general.startDate', this.$store.state.locale),
          value: 'start'
        },
        { 
          text: this.$t('memberships.expiresOn', this.$store.state.locale),
          value: 'expireDate'
        },
        { 
          text: this.$t('general.status', this.$store.state.locale),
          value: 'status.status'
        },
        { 
          text: '', 
          value: 'edit'
        }
      ],
      price: {
        bruto: 0,
        netto: 0,
        periodicPaymentDiscount: {
          periodic: {
            bruto: 0,
            netto: 0,
          },
          total: {
            bruto: 0,
            netto: 0,
          }
        },
        singlePaymentDiscount: {
          bruto: 0,
          netto: 0,
        },
        periodicPaymentBruto: 0,
        periodicPaymentNetto: 0,
        singlePaymentBruto: 0,
        singlePaymentNetto: 0,
      },
      trialTypes:[
        {
          id:'general',
          name:'Algemeen'
        },
        {
          id:'individual',
          name:'Instellen per cliënt'
        }
      ],
      vatItems: new Array(),
      treatments: new Array(),
      discounts: new Array(),
      treatmentTypesLoading: true,
      discountsLoading: true,
      vatItemsLoading: true,
    }
  },

  async created(){
    await this.getTreatments();
    await this.getVat();
    await this.loadDiscounts();
    bus.$on("openMembershipInformation", () => { 
      this.loading = true;
      this.calculateBrutoPrice();
    })
    this.loading = true;
    this.calculateBrutoPrice();
  },

  methods:{
    // loadFirstData(){

    // }

    getDiscountName(discount){
      let discountItem =  discount && this.discounts.find(({ id }) => id === discount) ? this.discounts.find( ({ id }) => id === discount ): null;
      if(discountItem){
        return {
          name : discountItem.name,
          color: 'tertiary'
        }
      }
      else{
        return {
          name : 'Niet gevonden',
          color: 'secondary'
        }
      }
    },

    getPeriodName(paymentPeriod){
      let paymentPeriods = {
        daily: {nl: 'dag', en: 'a day'},
        everyWeek: {nl: 'per week', en: ' week'},
        everyFourWeeks: {nl: 'per vier weken', en: 'four weeks'},
        everyMonth: {nl: 'maand', en: 'month'},
        everyQuarter: {nl: 'kwartaal', en: 'quarter'},
        everyHalfYear: {nl: 'half jaar', en: 'half year'},
        everyYear: {nl: 'jaarlijk', en: 'year'},
      }    
      return paymentPeriods[paymentPeriod] ? paymentPeriods[paymentPeriod][(this.$store.state.locale).toLowerCase()] : null;
    },

    activeColor(active){ // Set color green for active or red for inactive
      return active ? "#81C784" : "#EF5350"; 
    },

    getVatPercentage(vat){
      let vatPercentage =  vat && this.vatItems.find(({ id }) => id === vat) ? this.vatItems.find( ({ id }) => id === vat ): null;
      if(vatPercentage && vatPercentage.percentage){
        return vatPercentage.percentage
      }
      else{
        return " - "
      }
    },

    calculateBrutoPrice(){
      let vatPercentage =  this.selectedMembership.vat && this.vatItems.find(({ id }) => id === this.selectedMembership.vat) ? this.vatItems.find( ({ id }) => id === this.selectedMembership.vat ): null;

       if(vatPercentage && vatPercentage.percentage){
          let extraVat = (vatPercentage.percentage * this.selectedMembership.amount) / 100;
          this.selectedMembership.totalPrice = Number((extraVat + this.selectedMembership.amount).toFixed(2));
       }
      this.calculateTotalPrice() 
    },

    calculateTotalPrice(){
      let vatPercentage =  this.selectedMembership.vat && this.vatItems.find(({ id }) => id === this.selectedMembership.vat) ? this.vatItems.find( ({ id }) => id === this.selectedMembership.vat ): null;

      this.price.bruto = (this.selectedMembership.duration * this.selectedMembership.totalPrice).toFixed(2);
      this.price.netto = (this.selectedMembership.duration * this.selectedMembership.amount).toFixed(2);
      
      
      if(this.selectedMembership.periodicPaymentDiscount.active && this.selectedMembership.periodicPaymentDiscount.number > 0){
        if(this.selectedMembership.periodicPaymentDiscount.type == 'percentage'){
          this.price.periodicPaymentDiscount.periodic.bruto = ((this.selectedMembership.amount) /100 * this.selectedMembership.periodicPaymentDiscount.number).toFixed(2)
          if(this.price.periodicPaymentDiscount.periodic.bruto > 0){
            this.price.periodicPaymentDiscount.periodic.netto =  (this.price.periodicPaymentDiscount.periodic.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2)
            this.price.periodicPaymentDiscount.total.bruto = (this.price.periodicPaymentDiscount.periodic.bruto * this.selectedMembership.duration).toFixed(2)
            this.price.periodicPaymentDiscount.total.netto = (this.price.periodicPaymentDiscount.total.netto * this.selectedMembership.duration).toFixed(2)
          }
          else{
            this.price.periodicPaymentDiscount.periodic.bruto = 0;
            this.price.periodicPaymentDiscount.periodic.netto = 0;
            this.price.periodicPaymentDiscount.total.bruto = 0;
            this.price.periodicPaymentDiscount.total.netto = 0;
          }
        }
        else{
          this.price.periodicPaymentDiscount.periodic.bruto = (this.selectedMembership.periodicPaymentDiscount.number).toFixed(2)
          if(this.price.periodicPaymentDiscount.periodic.bruto > 0){
            this.price.periodicPaymentDiscount.periodic.netto = (this.price.periodicPaymentDiscount.periodic.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2)
            this.price.periodicPaymentDiscount.total.bruto = (this.price.periodicPaymentDiscount.periodic.bruto * this.selectedMembership.duration).toFixed(2)
            this.price.periodicPaymentDiscount.total.netto = (this.price.periodicPaymentDiscount.total.netto * this.selectedMembership.duration).toFixed(2)
          }
          else{
            this.price.periodicPaymentDiscount.periodic.bruto = 0;
            this.price.periodicPaymentDiscount.periodic.netto = 0;
            this.price.periodicPaymentDiscount.total.bruto = 0;
            this.price.periodicPaymentDiscount.total.netto = 0;
          }
        }
        this.price.periodicPaymentBruto = (this.price.bruto - this.price.periodicPaymentDiscount.total.bruto).toFixed(2);
        this.price.periodicPaymentNetto = Number((this.price.netto - this.price.periodicPaymentDiscount.total.netto).toFixed(2));
      }
       else{
        this.price.periodicPaymentBruto = this.price.bruto;
        this.price.periodicPaymentNetto = this.price.netto;
        this.price.periodicPaymentDiscount.total.netto = 0;
        this.price.periodicPaymentDiscount.total.bruto = 0;
        this.price.periodicPaymentDiscount.periodic.netto = 0;
        this.price.periodicPaymentDiscount.periodic.bruto = 0;
      }

      if(this.selectedMembership.singlePaymentDiscount.active && this.selectedMembership.singlePaymentDiscount.number > 0){
        if(this.selectedMembership.singlePaymentDiscount.type == 'percentage'){
          this.price.singlePaymentDiscount.bruto = ((this.selectedMembership.totalPrice * this.selectedMembership.duration) /100 * this.selectedMembership.singlePaymentDiscount.number).toFixed(2)
          if(this.price.singlePaymentDiscount.bruto > 0){
            this.price.singlePaymentDiscount.netto =  Number((this.price.singlePaymentDiscount.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2));
          }
          else{
            this.price.singlePaymentDiscount.bruto = 0;
            this.price.singlePaymentDiscount.netto = 0;
          }
        }
        else{
          this.price.singlePaymentDiscount.bruto = (this.selectedMembership.singlePaymentDiscount.number).toFixed(2)
          if(this.price.singlePaymentDiscount.bruto > 0){
            this.price.singlePaymentDiscount.netto =  Number((this.price.singlePaymentDiscount.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2));
          }
          else{
            this.price.singlePaymentDiscount.bruto = 0;
            this.price.singlePaymentDiscount.netto = 0;
          }
        }
        this.price.singlePaymentBruto = (this.price.bruto - this.price.singlePaymentDiscount.bruto).toFixed(2);
        this.price.singlePaymentNetto = Number((this.price.netto - this.price.singlePaymentDiscount.netto).toFixed(2));
      }
      else{
        this.price.singlePaymentBruto = this.price.bruto;
        this.price.singlePaymentNetto = this.price.netto;
        this.price.singlePaymentDiscount.bruto = 0;
        this.price.singlePaymentDiscount.netto = 0;
      }
      this.loading = false;
    },

    usageColor(usage){
      if(usage.override){
        return 'secondary';
      }
      else{
        return 'primary';
      }
    },

    async getCategories(){ // Load categories from DB and fetch it into select element
      this.categories = new Array();
      await db.collection('membershipCategories')
      .get()
      .then(querySnapshot=>{
        querySnapshot.forEach(doc=>{
          this.categories.push({
            id:doc.id,
            name: doc.data().name
          });
        })
      })
      .then(()=>{
        this.categories_Loading = false;
      })
      .catch(err=>{
        console.error(err);
      })
      return
    },
   
    async getTreatments(){ // Load treatments from DB and fetch it into select element
      this.treatments = new Array();
      await db.collection('treatments')
      .where("companyId","==", this.$store.state.activeCompany.id)
      .get()
      .then(snap=>{
        snap.forEach(doc=>{
          this.treatments.push({
            id: doc.id,
            title: doc.data().treatmentTitle
          });
        })
      })
      .then(()=>{
        this.treatmentTypesLoading = false;
      })
      .catch(err=>{
        console.error(err);
      })
      return
    },

    async getVat(){ //Load BTW from DB and fetch it into select element
      this.vatItems = new Array();
      await db.collection('vat').get()
      .then(snap=>{
        snap.forEach(doc=>{
          this.vatItems.push({
            id:doc.id,
            percentage: doc.data().percentage
          });
        })
      })
      .then(()=>{
        this.vatItemsLoading = false;
      })
      .catch(err=>{
        console.error(err);
      })
      return 
    },

    async loadDiscounts(){
      this.discounts = new Array();
      await db.collection('modifiers')
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("active", "==", true)
      .get()
      .then(querySnapshot=>{
          querySnapshot.forEach(doc=>{
            let discount = doc.data();
            discount.id = doc.id;
            this.discounts.push(discount);
          })
      })
      .then(()=>{
        this.discountsLoading = false;
      })
      .catch(err=>{
        console.error(err);
      })
      return
    }
  },

  computed: {
    paymentPeriode(){
      if(this.selectedMembership.paymentPeriod == 'daily'){
        return "dag"
      }
      else if(this.selectedMembership.paymentPeriod == 'everyWeek'){
        return "week"
      }
      else if(this.selectedMembership.paymentPeriod == 'everyFourWeeks'){
        return "vierweken"
      }
      else if(this.selectedMembership.paymentPeriod == 'everyMonth'){
        return "maand"
      }
      else if(this.selectedMembership.paymentPeriod == 'everyQuarter'){
        return "kwartaal"
      }
      else if(this.selectedMembership.paymentPeriod == 'everyHalfYear'){
        return "halfjaar"
      }
      else if(this.selectedMembership.paymentPeriod == 'everyYear'){
        return "jaar"
      }
    },

    durationSuffix() {
      if(this.selectedMembership.paymentPeriod == 'daily'){
        if(this.selectedMembership.duration == 1){
          return "dag"
        }
        else{
          return "dagen"
        }
      }

      else if(this.selectedMembership.paymentPeriod == 'everyWeek'){
        if(this.selectedMembership.duration == 1){
          return "week"
        }
        else{
          return "weken"
        }
      }

      else if(this.selectedMembership.paymentPeriod == 'everyFourWeeks'){
        return "vierweken"
      }

      else if(this.selectedMembership.paymentPeriod == 'everyMonth'){
        if(this.selectedMembership.duration == 1){
          return "maand"
        }
        else{
          return "maanden"
        }
      }
      else if(this.selectedMembership.paymentPeriod == 'everyQuarter'){
        if(this.selectedMembership.duration == 1){
          return "kwartaal"
        }
        else{
          return "kwartalen"
        }
      }
      else if(this.selectedMembership.paymentPeriod == 'everyHalfYear'){
        if(this.selectedMembership.duration == 1){
          return "halfjaar"
        }
        else{
          return "halfjaren"
        }
      }
      else if(this.selectedMembership.paymentPeriod == 'everyYear'){
        if(this.selectedMembership.duration == 1){
          return "jaar"
        }
        else{
          return "jaren"
        }
      }
      else{
        return `${this.selectedMembership.paymentPeriod}`;
      }
    },

    singleDiscountInfo(){
      if(this.selectedMembership.singlePaymentDiscount.type == "amount"){
        return {
          prefix: "€",
          suffix: null,
          label: "Kortingsbedrag",
          mask: "####"
        }
      }
      else {
        return {
          prefix: null,
          suffix: "%",
          label: "Kortingspercentage",
          mask: "##"
        }
      }
    },
    periodicDiscountInfo(){
      if(this.selectedMembership.singlePaymentDiscount.type == "amount"){
        return {
          prefix: "€",
          suffix: null,
          label: "Kortingsbedrag",
          mask: "####"
        }
      } 
      else {
        return {
          prefix: null,
          suffix: "%",
          label: "Kortingspercentage",
          mask: "##"
        }
      }
    },

    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    
    version() {
      return this.$store.state.version;
    },
    userCompanies() {
      return this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
      companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },
  }
};
</script>
