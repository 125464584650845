<template>
  <div>
    <div class="tab-component-wrapper">
      <v-container grid-list-md class="pa-4 ma-0">
        <v-layout row wrap>
          <v-flex md6 xs12>
            <h1 class="tab-title">Social instellingen</h1>
          </v-flex>
          <v-flex md6 xs12 >
            <div style="display: inline-block; float: right;">
              <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :dark="branchSocialChanged" :disabled="!branchSocialChanged" :depressed="!branchSocialChanged" @click="updateBranchSocial()">
                <div v-if="branchSocialChanged">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else> {{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>  
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div class="tab-component-content">
      <v-card flat class="my-2 pa-3" style="width: 100%;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
          <h3><v-icon>mdi-facebook</v-icon> Facebook</h3>
    
          <v-switch
            class="pa-0 ma-0 mt-2"
            hide-details
            :label="$t('general.active', $store.state.locale)"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            v-model="activeBranchData.social.facebook"
          ></v-switch>

          <v-text-field
            v-if="activeBranchData.social.facebook == true && activeBranchData"
            :label="`${ $t('social.linkTo', $store.state.locale)} Facebookpagina`"
            :hint="`${ $t('social.linkHint', $store.state.locale)} https://www.facebook.com/account/`"
            outlined
            dense
            background-color="menu"
            class="mt-2"
            v-model="activeBranchData.social.facebookUrl"
            persistent-hint
          ></v-text-field>
      </v-card>

      <v-card flat class="my-2 pa-3" style="width: 100%;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
        <h3> <v-icon>mdi-instagram</v-icon> Instagram</h3>

        <v-switch
          :label="$t('general.active', $store.state.locale)"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          v-model="activeBranchData.social.instagram"
          class="pa-0 ma-0 mt-2"
          hide-details
        ></v-switch>

        <v-text-field
          v-if="activeBranchData.social.instagram == true"
          :label="`${ $t('social.linkTo', $store.state.locale)} Instagrampagina`"
          :hint="`${ $t('social.linkHint', $store.state.locale)} https://www.instagram.com/account/`"
          outlined
          dense
          background-color="menu"
          class="mt-2"
          v-model="activeBranchData.social.instagramUrl"
          persistent-hint
        ></v-text-field>
      </v-card>

      <v-card flat class="my-2 pa-3" style="width: 100%;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
        <h3><v-icon>mdi-twitter</v-icon> Twitter</h3>
        <v-switch
          :label="$t('general.active', $store.state.locale)"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          v-model="activeBranchData.social.twitter"
          class="pa-0 ma-0 mt-2"
          hide-details
        ></v-switch>

        <v-text-field
          v-if="activeBranchData.social.twitter == true"
          :label="`${ $t('social.linkTo', $store.state.locale)} Twitterpagina`"
          :hint="`${ $t('social.linkHint', $store.state.locale)} https://www.twitter.com/account/`"
          v-model="activeBranchData.social.twitterUrl"
          persistent-hint
          dense
          outlined
          background-color="menu"
          class="mt-2"
        ></v-text-field>
      </v-card>

      <v-card flat class="my-2 pa-3" style="width: 100%;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
        <h3><v-icon>mdi-pinterest</v-icon> Pinterest</h3>

        <v-switch
          :label="$t('general.active', $store.state.locale)"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          v-model="activeBranchData.social.pinterest"
          class="pa-0 ma-0 mt-2"
          hide-details
        ></v-switch>
  
        <v-text-field
          v-if="activeBranchData.social.pinterest == true"
          :label="`${ $t('social.linkTo', $store.state.locale)} Pinterestpagina`"
          :hint="`${ $t('social.linkHint', $store.state.locale)} https://www.pinterest.com/account/`"
          v-model="activeBranchData.social.pinterestUrl"
          persistent-hint
          outlined
          dense
          background-color="menu"
          class="mt-2"
        ></v-text-field>
      </v-card>

      <!-- <v-card outlined class="my-2" style="width: 100%;" color="background">
        <v-card-text>
          <div class="social-header clear">
            <div class="social-header-icon googleplus"></div>
            <h3>Google +</h3>
          </div>
      
          <v-switch
            class="pa-0"
            hide-details
            :label="$t('general.active', $store.state.locale)"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            v-model="activeBranchData.social.googleplus"
          ></v-switch>
  
          <v-text-field
            v-if="activeBranchData.social.googleplus == true"
            :label="`${ $t('social.linkTo', $store.state.locale)} Pinterestpagina`"
            :hint="`${ $t('social.linkHint', $store.state.locale)} https://plus.google.com/bedrijf/`"
            v-model="activeBranchData.social.googleplusUrl"
            persistent-hint
            outlined
            background-color="menu"
            class="mt-4"
          ></v-text-field>
        </v-card-text>
      </v-card> -->
    </div>
  </div>
</template>

<script>
import db from "../firebase/init";

export default {
  props: {
    activeCompData: Object,
    activeBranchData: Object,
    activeBranchDataOriginal: Object,
    branchSocialChanged: Boolean
  },
  components: {},
  name: "Social",
  data: () => ({
    valid: false
  }),

  methods: {
    updateBranchSocial() {
      db.collection("branches")
        .doc(this.activeBranchData.id)
        .update({
          social: this.activeBranchData.social
        })
        .then(() => {
          this.$emit("updateData");
          console.log("BranchSettings succesfully updated");
        })
        .catch(error => {
          console.error("Error updating BranchSettings: ", error);
        });
    }
  },

  computed: {
    userCompanies() {
      return this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
  }
};
</script>

<style scoped>
.survey-page-button {
  padding: 16px 0;
}

p.settings-tab-introtext {
  padding: 10px 0 0 0;
  font-size: 16px;
}

.social-item-wrapper .form-active-switch {
  padding: 10px 0 0 0;
  margin: 0 0 0 0;
}

.social-item-wrapper {
  margin: 8px;
  padding: 10px 15px;
  background-color: rgba(246, 247, 251, 0.3) !important;
  width: 100% !important;
}

.social-header-icon {
  width: 30px;
  height: 30px;
  float: left;
  margin: 0 5px 0 0;
}

.social-header-icon.facebook {
  background: url("../assets/social-icons/social-icon-facebook.svg") left center
    no-repeat;
  background-size: 28px auto;
}

.social-header-icon.instagram {
  background: url("../assets/social-icons/social-icon-instagram.png") left
    center no-repeat;
  background-size: 28px auto;
}

.social-header-icon.pinterest {
  background: url("../assets/social-icons/social-icon-pinterest.png") left
    center no-repeat;
  background-size: 28px auto;
}

.social-header-icon.googleplus {
  background: url("../assets/social-icons/social-icon-google-plus.png") left
    center no-repeat;
  background-size: 28px auto;
}

.social-header-icon.twitter {
  background: url("../assets/social-icons/social-icon-twitter.png") left center
    no-repeat;
  background-size: 28px auto;
}

.social-header h3 {
  float: left;
  padding: 4px;
}
</style>

