<template>
	<div>
		<div id="media-container">
			<div id="dropzone" class="media-drop-overlay">
				<div class="drop-text text-center">
					<v-icon color="#ffffff" size="46">mdi-file</v-icon>
					<p class="ma-0 mt-4">{{ $t('library.dropFiles', $store.state.locale) }}</p>
				</div>
			</div>
			<div class="media-wrapper" draggable="false">
				<div class="media-header" draggable="false">
					<v-row class="ma-0 pa-4" draggable="false">
						<v-col md6 xs12 class="ma-0 pa-0" draggable="false">
							<v-text-field
								v-if="selectedMedia.length == 0"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								v-model="searchMedia"
								dense
								outlined
								clearable
								:placeholder="$t('general.search', $store.state.locale)"
								append-icon="mdi-magnify"
								hide-details
							></v-text-field>
							<div v-if="selectedMedia.length > 0">
								{{ selectedMedia.length }}
								<span v-if="selectedMedia.length == 1">{{ $t('library.item', $store.state.locale) }}</span>
								<span v-else>{{ $t('library.items', $store.state.locale) }}</span>
								{{ $t('library.selected', $store.state.locale) }}
								<v-btn class="ml-3" @click="selectedMedia = new Array()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark depressed>Deselect all</v-btn>
							</div>
						</v-col>
						<v-col md6 xs12 class="text-right ma-0 pa-0" draggable="false">
							<v-btn class="ml-4" v-if="selectedMedia.length == 1" @click="showMediaDetails()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" light depressed>
								{{ $t('library.details', $store.state.locale) }}
							</v-btn>
							<v-btn
								class="ml-4"
								v-if="selectedMedia.length > 0"
								@click="removeMedia.dialog = true"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
								light
								depressed
							>
							{{ $t('general.delete', $store.state.locale) }}
							</v-btn>
							<v-btn class="ml-4" v-if="selectedMedia.length == 1" @click="fileSelect" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark depressed>								
								{{ $t('library.insert', $store.state.locale) }}
							</v-btn>
							<v-btn
								class="ml-4"
								v-if="selectedMedia.length == 0"
								@click="onFileSelect"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								:disabled="mediaLoading"
								:dark="!mediaLoading"
								depressed
								>
								{{ $t('library.upload', $store.state.locale) }}
								<v-icon right>mdi-upload</v-icon>
							</v-btn>
							<input id="file-input" type="file" style="display: none" ref="fileInput" multiple :accept="fileRules.accept" @change="onFilePicked" />
						</v-col>
					</v-row>
					<v-divider></v-divider>
				</div>

				<v-alert v-show="fileFeedback.show == true" :type="fileFeedback.type" transition="fade-transition" prominent>
					{{ fileFeedback.message }}
				</v-alert>
				<div class="media-content">
					<div v-if="mediaLoading">
						<v-progress-circular
							style="position: absolute; top: 50%; left: 50%; margin: -18px 0 0 -18px"
							indeterminate
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						></v-progress-circular>
					</div>
					<div v-else>
						<div v-if="media.length > 0" class="pa-4">
							<div class="pb-4 text-right" style="width: 100%">
								<v-select
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									style="max-width: 300px; display: inline-block"
									:items="mediaSortOptions"
									item-text="name"
									item-value="id"
									dense
									outlined
									hide-details
									v-model="mediaSortBy"
								></v-select>
							</div>

							<v-row>
								<v-col
									v-for="(mediaItem, index) in filteredMedia"
									:key="index"
									class="d-flex child-flex"
									:cols="3"
									:xs="4"
									:md="3"
									:lg="2"
									@click="selectMediaItem(mediaItem.id)"
								>
									<div class="media-item-wrapper">
										<div v-if="selectedMediaItem(mediaItem.id)" class="media-selected">
											<v-icon color="#333333">mdi-checkbox-marked</v-icon>
										</div>
										<div v-if="selectedMediaItem(mediaItem.id)" class="media-selected-overlay"></div>
										<v-img contain :src="mediaItem.urlThumbnail" aspect-ratio="1" class="grey lighten-2 ma-0 pa-0">
											<template v-slot:placeholder>
												<v-row class="fill-height ma-0" align="center" justify="center">
													<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
												</v-row>
											</template>
										</v-img>
									</div>
								</v-col>
							</v-row>
						</div>
						<div v-else class="ma-4 text-center" style="padding: 150px">
							<v-avatar
								size="56"
								light
								class="ma-4 custom-avatar-style"
								rounded="lg"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								:style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }"
								>
								<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-file</v-icon>
							</v-avatar>
							<p>{{ $t('library.noFiles', $store.state.locale) }}</p>
						</div>
					</div>
					<!-- / Upload progress \ -->
					<v-menu v-model="uploadProgressDialog" :close-on-click="false" :close-on-content-click="false" offset-x style="max-width: 600px">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" style="position: absolute; bottom: 10px; right: 10px; width: 1px; height: 1px"></div>
						</template>

						<v-card style="width: 500px">
							<v-card-title class="pa-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
								<h3>{{ $t('library.uploading', $store.state.locale) }}</h3>
								<v-spacer></v-spacer>
								<v-btn icon dark @click="uploadProgressDialog = false">
									<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
								</v-btn>
							</v-card-title>

							<v-divider></v-divider>

							<v-card-text class="ma-0 pa-0">
								<v-list class="pa-0 py-4 ma-0" disabled>
									<v-list-item v-for="(image, index) in filesUploadData" :key="index">
										<v-list-item-avatar tile :size="60">
											<v-img :src="image.fileExample"></v-img>
										</v-list-item-avatar>

										<v-list-item-content>
											<v-list-item-title v-html="image.name"></v-list-item-title>
											<v-progress-linear
												v-if="image.uploadProgress < 100"
												class="mt-2"
												rounded
												height="10"
												v-model="image.uploadProgress"
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
												:buffer-value="100"
											></v-progress-linear>
										</v-list-item-content>
										<v-list-item-icon>
											<v-icon v-if="image.uploaded" color="#56ca00">mdi-progress-check</v-icon>
											<v-icon v-else-if="image.error" color="#ff4c51">mdi-progress-close</v-icon>
											<v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-progress-upload</v-icon>
										</v-list-item-icon>
									</v-list-item>
								</v-list>
							</v-card-text>
						</v-card>
					</v-menu>
					<!-- \ Upload progress / -->
				</div>
			</div>
		</div>
		<!-- / Delete Item Dialog \ -->
		<v-dialog v-model="removeMedia.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
			<v-card>
				<v-card-text class="text-center ma-0 pt-4">
					<v-avatar
						size="56"
						light
						class="ma-4 custom-avatar-style"
						rounded="lg"
						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						:style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }"
					>
						<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
					</v-avatar>
					<h2 class="mb-4">Are you sure?</h2>
					<p class="ma-0">
						{{ $t('library.noFiles', $store.state.locale) }}
						<span v-if="selectedMedia.length == 1">{{ $t('library.thisFile', $store.state.locale) }}</span>
						<span v-else> {{ $t('library.theseFiles', $store.state.locale) }}</span>
						{{ $t('library.deleteExplaining', $store.state.locale) }}
					</p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
								<v-btn outlined depressed :disabled="mediaLoading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="removeMedia.dialog = false">
									{{ $t("general.cancel", $store.state.locale) }}
								</v-btn>
							</v-flex>
							<v-flex xs12 md6 class="text-right">
								<v-btn
									dark
									:loading="mediaLoading"
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									@click="removeMediaItems()"
								>
									{{ $t("rooms.removeRoomButton", $store.state.locale) }}
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Delete Item Dialog / -->

		<!-- / Item Details Dialog \ -->
		<v-dialog v-model="mediaDetails.dialog" fullscreen scrollable content-class="custom-shape">
			<div style="background-color: rgba(0, 0, 0, 0.7); position: absolute; width: 100%; height: 100%; top: 0; left: 0">
				<v-row class="ma-0 pa-0" style="height: 100%" v-if="mediaDetails.item">
					<v-col :cols="9" fill-height class="ma-0 pa-0">
						<v-row class="ma-0 pa-4">
							<v-col :cols="4" class="text-left">
								<v-btn class="mr-4" @click="closeMediaDetails()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" light>{{ $t('general.back', $store.state.locale) }}</v-btn>
							</v-col>
							<v-col :cols="8" class="text-right">
								<v-btn class="ml-4" @click="copyUrl(mediaDetails.item.url)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" light>{{ $t('general.copyUrl', $store.state.locale) }}</v-btn>
								<v-btn class="ml-4" @click="copyUrl(mediaDetails.item.urlOriginal)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" light>{{ $t('library.copyUrlOriginal', $store.state.locale) }}</v-btn>
								<v-btn class="ml-4" @click="fileSelect" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark>{{ $t('library.insert', $store.state.locale) }}</v-btn>
							</v-col>
						</v-row>
						<v-row class="ma-0 pa-4" align="center" justify="center">
							<v-img contain style="max-width: 90%; max-height: 500px" :src="mediaDetails.item.url" class="py-4 mt-4">
								<template v-slot:placeholder>
									<v-row class="fill-height ma-0" align="center" justify="center">
										<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
									</v-row>
								</template>
							</v-img>
						</v-row>
					</v-col>
					<v-col :cols="3" style="background-color: #ffffff; height: 100%" class="pa-0 ma-0">
						<div class="pa-4 ma-0">
							<h4 class="ma-0 pa-0">{{ mediaDetails.item.name }}</h4>
							<p class="ma-0 pa-0 py-1">
								<!-- <a >Rename</a> &#183; -->
								<a :href="mediaDetails.item.url" target="_blank" download :style="{'color':$themes[$store.state.companyTheme][$store.state.themeModus].primary}" >{{ $t('library.download', $store.state.locale) }}</a>
								&#183;
								<a @click="removeMedia.dialog = true" :style="{'color':$themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('general.delete', $store.state.locale) }}</a>
							</p>
						</div>
						<v-divider></v-divider>
						<v-row class="pa-4 ma-0">
							<v-col :cols="4" class="text-left ma-0 pa-2">
								<strong>{{ $t('library.created', $store.state.locale) }}:</strong>
							</v-col>
							<v-col :cols="8" class="text-right ma-0 pa-2">{{ mediaDetails.item.created.seconds | moment("D MMM YYYY H:mm") }}u</v-col>
							<v-col :cols="4" class="text-left ma-0 pa-2">
								<strong>{{ $t('library.dimensions', $store.state.locale) }}:</strong>
							</v-col>
							<v-col :cols="8" class="text-right ma-0 pa-2">{{ mediaDetails.item.width }} x {{ mediaDetails.item.width }}px</v-col>
							<v-col :cols="4" class="text-left ma-0 pa-2">
								<strong>{{ $t('library.size', $store.state.locale) }}:</strong>
							</v-col>
							<v-col :cols="8" class="text-right ma-0 pa-2">{{ (mediaDetails.item.size / 1024).toFixed(0) }} kb</v-col>
							<v-col :cols="4" class="text-left ma-0 pa-2">
								<strong>{{ $t('library.uploadedBy', $store.state.locale) }}:</strong>
							</v-col>
							<v-col :cols="8" class="text-right ma-0 pa-2">{{ mediaDetails.item.uploadedBy.name }} </v-col>
						</v-row>

						<v-divider></v-divider>
					</v-col>
				</v-row>
			</div>
		</v-dialog>
		<!-- \ Item Details Dialog / -->
	</div>
</template>

<script>
import db from "../../firebase/init";
import firebase from "firebase/app";
import axios from "axios";
import moment from "moment";
import "firebase/storage";
import { bus } from "../../main";
var uniqid = require("uniqid");

export default {
	name: "MediaLibrary",
	props: {
		assetType: String,
		assetGroup: String,
		fileRules: Object,
	},
	data() {
		return {
			removeMedia: {
				dialog: false,
			},
			fileFeedback: {
				show: false,
				message: "",
				type: "error",
			},
			dropZone: null,
			libraryHeight: 0,
			searchMedia: null,
			filename: "",
			files: null,
			image: null,
			media: new Array(),
			mediaLoading: true,
			selectedMedia: new Array(),
			filesUploadData: new Array(),
			uploadProgressDialog: false,
			mediaSortOptions: [
				{
					id: "latest",
					name: this.$t('library.latest', this.$store.state.locale)
				},
				{
					id: "oldest",
					name: this.$t('library.oldest', this.$store.state.locale)
				},
				{
					id: "largest",
					name: this.$t('library.largest', this.$store.state.locale)
				},
				{
					id: "smallest",
					name: this.$t('library.smallest', this.$store.state.locale)
				},
				{
					id: "az",
					name: this.$t('library.az', this.$store.state.locale)
				},
				{
					id: "za",
					name: this.$t('library.za', this.$store.state.locale)
				},
			],
			detailsDialog: false,
			mediaSortBy: "latest",
			mediaDetails: {
				dialog: false,
				item: null,
			},
		};
	},

	created() {
		this.loadFiles();
		bus.$on("openMediaLibrary", this.loadFiles);
		bus.$on("clearMediaLibrarySelection", this.clearSelection);
	},

	methods: {
		onFileSelect() {
			this.$refs.fileInput.click();
		},

		async onFilePicked(event) {
			let vm = this;
			// this.files = event.target.files;

			// Taking the files from the input and storing them in the files array.
			this.files = await Promise.all([...event.target.files]);
			this.filesUploadData = new Array();

			new Promise((resolve) => {
				let counter = 0;

				//convert this.files.forEach((file) to async loop with index

				this.files.map(async (file) => {
					// Checking the file type and if it is not accepted it will push the file and message to the
					// fileFeedback array.
					if (this.fileRules.accept.includes(file.type) && file.size < this.fileRules.fileSize) {
						await processImage(file);
						counter++;
					} else {
						let tmpFeedbackMessage = new Array();

						if (!this.fileRules.accept.includes(file.type)) {
							tmpFeedbackMessage.push(`${file.name} ${ this.$t('library.fileTypeNotAccepted', this.$store.state.locale) }`);
						}

						if (file.size > this.fileRules.fileSize) {
							tmpFeedbackMessage.push(`${file.name} ${ this.$t('library.fileToLarge', this.$store.state.locale) }`);
						}

						tmpFeedbackMessage = tmpFeedbackMessage.join(", and ");
						this.fileFeedback.type = "error";
						this.fileFeedback.message = tmpFeedbackMessage;
						this.fileFeedback.show = true;
						//Time out for v-alert
						setTimeout(() => {
							this.fileFeedback.show = false;
						}, 8000);

						counter++;
					}

					if (counter == this.files.length) {
						resolve();
					}
				});
			})
			.then(() => {
				if (this.filesUploadData.length > 0) {
					this.uploadFiles();
				} else {
					// console.log("No files to upload");
					this.fileFeedback.type = "warning";
					this.fileFeedback.message += this.$t('library.noFilesUploaded', this.$store.state.locale) ;
					this.fileFeedback.show = true;
					//Time out for v-alert
					setTimeout(() => {
						this.fileFeedback.show = false;
					}, 8000);
				}
			});

			async function processImage(file) {
				return new Promise((resolve, reject) => {
					let reader = new FileReader();
					reader.readAsDataURL(file);

					reader.onload = () => {
						var uploadImage = new Image();
						uploadImage.src = reader.result;

						uploadImage.onload = () => {
							let imageData = {
								name: file.name,
								uploading: false,
								uploaded: false,
								error: false,
								uploadProgress: 0,
								url: null,
								file: file,
								width: uploadImage.width,
								height: uploadImage.height,
								size: file.size,
								fileExample: reader.result,
							};

							//if image is to big more then 2mb continue the loop and show error
							if (file.size > vm.fileRules.fileSize) {
								let tmpFeedbackMessage = new Array();
								tmpFeedbackMessage.push(`${file.name} ${ this.$t('library.fileToLarge', this.$store.state.locale) }`);

								tmpFeedbackMessage = tmpFeedbackMessage.join(this.$t('library.and', this.$store.state.locale));
								vm.fileFeedback = tmpFeedbackMessage;
								vm.type = "error";
								vm.fileFeedback.show = true;
								setTimeout(() => {
									vm.fileFeedback.show = false;
								}, 8000);
								resolve();
							}

							vm.filesUploadData.push(imageData);
							resolve();
						};
					};
				});
			}
		},

		showMediaDetails() {
			this.mediaDetails.item = this.media.find((item) => {
				return item.id == this.selectedMedia[0];
			});
			this.mediaDetails.dialog = true;
		},

		closeMediaDetails() {
			this.mediaDetails.item = null;
			this.mediaDetails.dialog = false;
		},

		selectMediaItem(id) {
			if (this.selectedMedia.includes(id)) {
				this.selectedMedia = this.selectedMedia.filter((item) => {
					return item != id;
				});
			} else {
				this.selectedMedia.push(id);
			}
		},

		selectedMediaItem(id) {
			if (this.selectedMedia.includes(id)) {
				return true;
			} else {
				return false;
			}
		},

		clearSelection() {
			this.selectedMedia = new Array();
		},

		fileSelect() {
			let mediaItem = this.media.find((item) => item.id == this.selectedMedia[0]);
			this.$emit("mediaFileSelect", mediaItem);
		},

		enableDropzone() {
			this.dropZone = document.getElementById("dropzone");
			let vm = this;

			function showDropZone() {
				vm.dropZone.style.display = "block";
			}
			function hideDropZone() {
				vm.dropZone.style.display = "none";
			}

			function allowDrag(e) {
				if (true) {
					// Test that the item being dragged is a valid one
					e.dataTransfer.dropEffect = "copy";
					e.preventDefault();
				}
			}

			function handleDrop(e) {
				e.preventDefault();
				hideDropZone();
				let event = {
					target: {
						files: e.dataTransfer.files,
					},
				};
				vm.onFilePicked(event);
			}

			window.addEventListener("dragenter", function (e) {
				showDropZone();
			});

			this.dropZone.addEventListener("dragenter", allowDrag);
			this.dropZone.addEventListener("dragover", allowDrag);

			this.dropZone.addEventListener("dragleave", function (e) {
				console.log("dragleave");
				hideDropZone();
			});

			this.dropZone.addEventListener("drop", handleDrop);
		},

		disableDropzone() {
			this.dropZone.removeEventListener("dragenter", allowDrag);
			this.dropZone.removeEventListener("dragover", allowDrag);
			window.removeEventListener("dragenter", function (e) {
				showDropZone();
			});
			this.dropZone.removeEventListener("dragleave", function (e) {
				hideDropZone();
			});
			this.dropZone.removeEventListener("drop", handleDrop);
			this.dropZone.removeEventListener("drop", handleDrop);
			this.dropZone = null;
		},

		async uploadFiles() {
			let vm = this;
			let getNewFileDocId = null;
			this.uploadProgressDialog = true;

			//async this.filesUploadData.map as promise
			let countUploads = 0;
			let uploadedFiles = await new Promise((resolve) => {
				this.filesUploadData.map(async (imageData) => {
					const imageDataObj = {
						name: imageData.name,
						uploading: true,
						uploaded: false,
						error: false,
						uploadProgress: 0,
						url: null,
						file: imageData.file,
						width: imageData.width,
						height: imageData.height,
						size: imageData.size,
						fileExample: imageData.fileExample,
					};

					//ImageDataObj to formdata
					let formData = new FormData();
					formData.append("file", imageData.file);
					formData.append("name", imageData.name);
					formData.append("width", imageData.width);
					formData.append("height", imageData.height);
					formData.append("size", imageData.size);
					formData.append("fileExample", imageData.fileExample);
					formData.append("assetGroup", this.assetGroup);
					formData.append("assetType", this.assetType); //Dynamisch maken van de assetType
					formData.append("companyId", this.$store.state.activeCompany.id);
					formData.append("userId", this.userId);
					formData.append("username",  this.userFirstName + " " + this.userLastName);

					try {
						let response = await axios.post(`${this.$store.state.uploadApi.url}/upload`, formData);
						//Push to media library
						console.log(response.data);
						let docId = response.data;

						db.collection("media")
						.doc(docId)
						.get()
						.then((doc) => {
							if (doc.exists) {
								let mediaItem = doc.data();
								mediaItem.id = doc.id;
								this.media.push(mediaItem);
							} else {
								console.log("No such document!");
							}
						})
						.catch((error) => {
							console.log("Error getting document:", error);
						});

						countUploads++;
						if (countUploads == this.filesUploadData.length) {
							// console.log(response.data);
							resolve();
						}
					} catch (error) {
						console.error("Error Upload", error);
						countUploads++;
						if (countUploads == this.filesUploadData.length) {
							resolve();
						}
						throw new Error(error);
					}

					return 
					
				});
			});
			this.uploadProgressDialog = false;

			//Get firebase media doc from collection "media"

			//promise all with uploadedImages

			// uploadFunctionFirebaseNative()
			// .then(()=>{
			//   this.uploadProgressDialog = false;
			// })
			function uploadFunctionFirebaseNative() {
				return new Promise((resolve) => {
					let counter = 0;
					vm.filesUploadData.forEach((image) => {
						let ext = "";
						const filename = image.name;

						if (filename.includes(".")) {
							ext = filename.slice(filename.lastIndexOf("."));
						}
						// console.log("ext: ", ext)
						const fileUrl = vm.$store.state.activeCompany.id + "/media/" + uniqid.process() + uniqid() + ext;
						image.uploading = true;

						let uploadTask = firebase.storage().ref(fileUrl).put(image.file);

						uploadTask.on(
							"state_changed",
							(taskSnapshot) => {
								var progress = Math.ceil((taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100);
								image.uploadProgress = progress;
								console.log("progress: ", progress);
							},
							(error) => {
								console.log(error);
							},
							() => {
								// console.log("The upload is compleet");
								uploadTask.snapshot.ref
									.getDownloadURL()
									.then((url) => {
										let data = {
											companyId: vm.$store.state.activeCompany.id,
											uploadedBy: `${vm.userFirstName} ${vm.userLastName}`,
											created: new Date(),
											name: image.name,
											width: image.width,
											height: image.height,
											size: image.size,
											url: url,
										};
										db.collection("media")
											.add(data)
											.then((docRef) => {
												image.uploading = false;
												image.uploaded = true;
												image.uploadProgress = null;
												data.id = docRef.id;
												data.created = {
													seconds: moment().format("X"),
												};
												vm.media.push(data);

												counter++;
												if (counter == vm.filesUploadData.length) {
													resolve();
												}
											})
											.catch((error) => {
												console.log("Error adding image to firebase:", error);
												image.uploading = false;
												image.uploaded = true;
												image.error = true;
												counter++;
												if (counter == vm.filesUploadData.length) {
													resolve();
												}
											});
									})
									.catch((error) => {
										console.log("Error adding image to firestore:", error);
										image.uploading = false;
										image.uploaded = true;
										image.error = true;
										counter++;
										if (counter == vm.filesUploadData.length) {
											resolve();
										}
									});
							}
						);
					});
				});
			}
		},

		loadFiles() {
			console.log("this.assetType: ", this.assetType)
			this.mediaLoading = true;
			this.media = new Array();
			db.collection("media")
			.where("companyId", "==", this.$store.state.activeCompany.id)
			.get()
			.then((snap) => {
				snap.forEach((doc) => {
					let mediaItem = doc.data();
					mediaItem.id = doc.id;
					this.media.push(mediaItem);
				});
			})
			.then(() => {
				this.mediaLoading = false;
				if (!this.dropZone) {
					this.enableDropzone();
				}
			})
			.catch((error) => {
				console.error("Error loading media: ", error);
			});
		},

		copyUrl(url) {
			if (!navigator.clipboard) {
				fallbackCopyTextToClipboard(url);
				return;
			}
			navigator.clipboard.writeText(url).then(() => {},
				function (err) {
					console.error("Async: Could not copy url: ", err);
				}
			);
		},

		removeMediaItems() {
			this.mediaLoading = true;
			let counter = 0;
			new Promise((resolve) => {
				this.selectedMedia.forEach((media) => {
					db.collection("media")
					.doc(media)
					.delete()
					.then(() => {
						counter++;
						this.media = this.media.filter((item) => {
							return item.id != media;
						});
						if (counter == this.selectedMedia.length) {
							resolve();
						}
					})
					.catch((error) => {
						console.error("Error removing media: ", error);
						counter++;
						if (counter == this.selectedMedia.length) {
							resolve();
						}
					});
				});
			})
			.then(() => {
				this.selectedMedia = new Array();
				this.mediaLoading = false;
				this.removeMedia.dialog = false;
				this.closeMediaDetails();
			});
		},
	},

	computed: {
		filteredMedia() {
			let media = new Array();

			if (this.mediaSortBy == "latest") {
				media = this.media.sort((a, b) => b.created.seconds - a.created.seconds);
			} else if (this.mediaSortBy == "oldest") {
				media = this.media.sort((a, b) => a.created.seconds - b.created.seconds);
			} else if (this.mediaSortBy == "largest") {
				media = this.media.sort((a, b) => b.size - a.size);
			} else if (this.mediaSortBy == "smallest") {
				media = this.media.sort((a, b) => a.size - b.size);
			} else if (this.mediaSortBy == "az") {
				media = this.media.sort((a, b) => {
					let nameA = a.name.toLowerCase(),
						nameB = b.name.toLowerCase();
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				});
			}
			else if (this.mediaSortBy == "za") {
				media = this.media.sort((a, b) => {
					let nameA = a.name.toLowerCase(),
						nameB = b.name.toLowerCase();
					if (nameA < nameB) {
						return 1;
					}
					if (nameA > nameB) {
						return -1;
					}
					return 0;
				});
			}

			if (this.searchMedia) {
				return media.filter((item) => {
					return item.name.toLowerCase().indexOf(this.searchMedia.toLowerCase()) >= 0;
				});
			}
			else {
				return media;
			}
		},

		userFirstName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
		},
		userLastName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
		},
	
		userId() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
		},
	},
};
</script>

<style>
#media-container {
	position: absolute;
	width: 100%;
	height: 100%;
}
.media-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.media-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 72px;
}
.media-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	margin: 72px 0 0 0;
	height: calc(100% - 72px);
	/* background-color: orange; */
	overflow-y: scroll;
}

.media-item-wrapper {
	position: relative;
	cursor: pointer;
}

.media-selected {
	position: absolute;
	width: 50px;
	height: 50px;
	top: 10px;
	left: 10px;
	z-index: 9;
}
.media-selected-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 7;
	background-color: rgba(225, 225, 225, 0.5);
}

/* .media-drop-overlay{
    display: none;
  }

  #media-container.dropzone.drop-active * {pointer-events: none;} */

.media-drop-overlay {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: white;
	/*background-color: red;*/
	background-color: rgba(0, 0, 0, 0.67);
	z-index: 999;
}

.media-drop-overlay .drop-text {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 150px;
	width: 300px;
	margin: -40px 0 0 -150px;
	pointer-events: none;
}

.media-drop-overlay .drop-text p {
	font-size: 20px;
	color: #ffffff;
}
</style>
