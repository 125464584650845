
<template>
  <div>
    <div class="tab-component-wrapper">
      <div v-if="loading">
        <div style="position: relative; padding: 200px 0;">
          <div class="thrive-loader"></div>
        </div>
      </div>
      
      <v-card v-else  flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
        <div v-if="activeCompData.paymentOptions.length > 0">
          <v-container grid-list-md class=" ma-0 pa-3" fluid>
            <v-layout row wrap>
              <v-flex md4 xs12>
              </v-flex>
               <v-flex md8 xs12 class="text-right">
                <v-btn dark height="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="newPaymentOption.dialog = true">
                  <v-icon left>mdi-plus</v-icon> {{ $t('payment.newPaymentTypeShort', this.$store.state.locale), }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
          
          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="$store.state.activeCompany.paymentOptions"
            :itemsPerPage.sync="pages"
            hide-default-footer
            dense
            class="dense-table"
            >
            <template v-slot:item="{item, index }">
              <tr>
                <td>{{ item.name }}</td>
                <td>
                  <span v-if="item.transactionCost > 0"> 
                    <span v-if="item.type == 'amount' "> &euro; {{ item.transactionCost.toFixed(2) }}</span>
                    <span v-else>{{ item.transactionCost.toFixed(2) }}% </span>
                  </span>
                  <span v-else> - </span>
                </td>
                <td>
                  <v-btn icon class="mx-0" @click="editCompanyPaymentOption(index)">
                    <v-icon small  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>

        <div class="no-data-message" v-else>
          <p>{{ $t('payment.noPaymentTypesFor', $store.state.locale) }} {{ activeCompData.name }}.</p>
          <v-btn fab dark  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="newPaymentOption.dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>

    <!-- / New PaymentOption Dialog \ -->
    <v-dialog v-model="newPaymentOption.dialog" scrollable max-width="700px" max-height="900px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t('payment.newPaymentType', $store.state.locale) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeNewPaymentDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="newPaymentOption.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ newPaymentOption.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="EditVatForm" lazy-validation>
            <v-container grid-list-md class="pa-0" fluid>
              <v-layout row wrap>
                <v-flex md6>
                  <v-text-field
                    :label="$t('general.name', $store.state.locale)"
                    v-model="newPaymentOption.name"
                    required
                    dense
                    outlined
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    background-color="background"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex md12>
                  <v-switch
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="newPaymentOption.intermediate"
                    >
                    <template slot='label'>
                      <span style="margin-right: 5px;">{{ $t('payment.escrowActive', $store.state.locale) }}</span>
                      <v-tooltip class="ma-0 pa-0" bottom >
                        <template v-slot:activator="{on}">
                          <v-icon  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" v-on="on">mdi-help-circle</v-icon>
                        </template>
                         <span>{{ $t('payment.escrowActiveHint', $store.state.locale) }}</span>
                      </v-tooltip>
                    </template>
                  </v-switch>
                </v-flex>
              </v-layout>
              <v-layout v-if="newPaymentOption.intermediate" row wrap>
                <v-flex md6>
                <v-select
                  :items="paymentTypes"
                  item-text="name"
                  item-value="id"
                  v-model="newPaymentOption.type"
                  :label="$t('payment.costType', $store.state.locale)"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  outlined
                  class="mt-4"
                  hide-details
                  dense
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  background-color="background"
                ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-text-field
                    :label="$t('payment.transactionCosts', $store.state.locale)"
                    v-model.number="newPaymentOption.transactionCost"
                    :prefix="newPaymentOptionTransactionCostType.prefix"
                    :suffix="newPaymentOptionTransactionCostType.suffix"
                    required
                    dense
                    :hint="$t('payment.ifApplicable', $store.state.locale)"
                    persistent-hint
                    outlined
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    background-color="background"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                  @click="closeNewPaymentDialog()"
                  >
                  {{ $t('general.cancel', $store.state.locale) }} 
                </v-btn>
              </v-flex>
              <v-flex xs12 md8 class="text-right">
                <v-btn
                  dark
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  @click="addNewPayment()"
                  >
                  {{ $t('general.add', $store.state.locale) }} 
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ New PaymentOption Dialog / -->

    <!-- / Edit PaymentOption Dialog \ -->
    <v-dialog v-if="activeCompData.paymentOptions.length > 0" v-model="editPaymentOption.dialog" scrollable max-width="700px" max-height="900px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t('payment.editPaymentType', $store.state.locale) }} </h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeEditPaymentDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="editPaymentOption.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ editPaymentOption.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text>
          <v-form  ref="EditVatForm" lazy-validation>
            <v-container grid-list-md class="pa-0" fluid>
              <v-layout row wrap>
                <v-flex md8>
                  <v-text-field
                    :label="$t('general.name', $store.state.locale)"
                    v-model="editPaymentOption.name"
                    required
                    outlined
                    class="mt-4"
                    hide-details
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    background-color="background"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex md12>
                  <v-switch
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="editPaymentOption.intermediate"
                    >
                    <template slot='label'>
                      <span style="margin-right: 5px;">{{ $t('payment.escrowActive', $store.state.locale) }}</span>
                      <v-tooltip class="ma-0 pa-0" bottom >
                        <template v-slot:activator="{on}">
                          <v-icon  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span>{{ $t('payment.escrowActiveHint', $store.state.locale) }}</span>
                      </v-tooltip>
                    </template>
                  </v-switch>
                </v-flex>
              </v-layout>

              <v-layout v-if="editPaymentOption.intermediate" row wrap>
                <v-flex md6>
                <v-select
                  :items="paymentTypes"
                  item-text="name"
                  item-value="id"
                  v-model="editPaymentOption.type"
                  :label="$t('payment.costType', $store.state.locale)"
                  outlined
                  class="mt-4"
                  dense
                  hide-details
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  background-color="background"
                ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-text-field
                    :label="$t('payment.transactionCosts', $store.state.locale)"
                    v-model.number="editPaymentOption.transactionCost"
                    :prefix="editPaymentOptionTransactionCostType.prefix"
                    :suffix="editPaymentOptionTransactionCostType.suffix"
                    required
                    :hint="$t('payment.ifApplicable', $store.state.locale)"
                    persistent-hint
                    outlined
                    dense
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    background-color="background"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  color="tertiary"
                  @click="closeEditPaymentDialog()"
                  >
                  {{ $t('general.cancel', $store.state.locale) }} 
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  :disabled=" !changed" 
                  :depressed="!changed"  
                  :dark=" changed"  
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                  @click="updatePayment()"
                  >
                  <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                  <div v-else> {{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>  
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Edit PaymentOption Dialog / -->
  </div>
</template>

<script>
import db from "../firebase/init";
import { bus } from "../main";
import slugify from "slugify";

export default {
  name: "company-paymeny-settings",
  data() {
    return {
      activeCompData: new Object(),
      paymentTypes: [
        {
          name: this.$t('general.amount', this.$store.state.locale),
          id: 'amount'
        },
        {
          name: this.$t('general.percentage', this.$store.state.locale),
          id: 'percentage'
        }
      ],
      pages: -1,
      newPaymentOption: {
        name: null,
        id: null,
        type: 'amount',
        intermediate: false,
        feedback: null,
        transactionCost: 0,
        dialog: false,
      },
      editPaymentOption: {
        index: 0,
        name: null,
        id: null,
        type: 'amount',
        intermediate: false,
        feedback: null,
        transactionCost: 0,
        dialog: false,
      },
      editPaymentOptionOriginal: null,
      pagination: {
        rowsPerPage: -1,
        descending: false
      },
      loading: false,
      headers: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t('payment.costs', this.$store.state.locale),
          align: "left",
          value: "transactionCost"
        },
        {
          text: "",
          align: "left",
          value: "edit",
          width: "60px"
        }
      ]
    }
  },

  created(){
    bus.$on("companyUpdate", () => {
      this.loadData();
    });
    this.loadData()
  },

  methods: {
    loadData(){
      this.loading = true;
      this.activeCompData = _.cloneDeep(this.$store.state.activeCompany)
      this.loading = false;
    },
    editCompanyPaymentOption(index){
      this.editPaymentOption = {
        index: index,
        dialog: true,
        name: this.activeCompData.paymentOptions[index].name,
        id: this.activeCompData.paymentOptions[index].id,
        type: this.activeCompData.paymentOptions[index].type,
        transactionCost: this.activeCompData.paymentOptions[index].transactionCost,
        intermediate: this.activeCompData.paymentOptions[index].intermediate,
        feedback: null
      }
      this.editPaymentOptionOriginal = _.cloneDeep(this.editPaymentOption)
    },

    closeNewPaymentDialog(){
      this.newPaymentOption = {
        name: null,
        id: null,
        type: 'amount',
        intermediate: false,
        feedback: null,
        transactionCost: 0,
        dialog: false,
      }
    },

    closeEditPaymentDialog(){
      this.editPaymentOption = {
        index: 0,
        feedback: null,
        dialog: false,
      }
    },

    addNewPayment(){
      let paymentOption = {}
      paymentOption.name = this.newPaymentOption.name  
      paymentOption.intermediate = this.newPaymentOption.intermediate
      
      if(this.newPaymentOption.intermediate){
        paymentOption.type = this.newPaymentOption.type
        paymentOption.transactionCost = this.newPaymentOption.transactionCost
      } else{
        paymentOption.transactionCost = null
        paymentOption.type = null
      }

      paymentOption.id = slugify(this.newPaymentOption.name, {
        replacement: "",
        remove: /[!@#$%^&*()"';:.,]/g,
        lower: true
      });
      this.activeCompData.paymentOptions.push(paymentOption)

      db.collection("companies")
        .doc(this.activeCompData.id)
        .set({
          paymentOptions: this.activeCompData.paymentOptions
        }, { merge: true })
        .then(()=>{
          this.closeNewPaymentDialog()
        })
        .catch(error =>{
          this.newPaymentOption.feedback = this.$t('payment.feedBackSaveError', $store.state.locale);
        })
    },

    updatePayment(){
      if(this.editPaymentOption.name){
        this.editPaymentOption.feedback = null;
        let paymentOption = {}
        paymentOption.id = this.editPaymentOption.id  
        paymentOption.name = this.editPaymentOption.name  
        paymentOption.intermediate = this.editPaymentOption.intermediate
        
        if(this.editPaymentOption.intermediate){
          paymentOption.type = this.editPaymentOption.type
          paymentOption.transactionCost = this.editPaymentOption.transactionCost
        } else{
          paymentOption.transactionCost = null
          paymentOption.type = null
        }

        this.activeCompData.paymentOptions[this.editPaymentOption.index] = paymentOption
        db.collection("companies")
          .doc(this.activeCompData.id)
          .set({
            paymentOptions: this.activeCompData.paymentOptions
          }, { merge: true })
          .then(()=>{
            this.closeEditPaymentDialog()
            this.loading = true;  
            this.$nextTick(() => {
              this.loading = false
            });  
          })
          .catch(error =>{
            this.editPaymentOption.feedback = this.$t('payment.feedBackSaveError', $store.state.locale);
            console.error("Update issue: ", error )
          })
      }
      else{
        this.editPaymentOption.feedback = this.$t('payment.feedBackSaveNoValid', $store.state.locale);
      }
      
    }
  },
  computed: {
    newPaymentOptionTransactionCostType(){
      let information = {
        prefix: null,
        suffix: null
      }
      if(this.newPaymentOption.type == 'amount'){
        information.prefix = "€"
        information.suffix = null
      }else{
        information.prefix = null
        information.suffix = "%"
      }
      return information
    },
    changed() {
      if (
        _.isEqual(this.editPaymentOption, this.editPaymentOptionOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    editPaymentOptionTransactionCostType(){
      let information = {
        prefix: null,
        suffix: null
      }
      if(this.editPaymentOption.type == 'amount'){
        information.prefix = "€"
        information.suffix = null
      }else{
        information.prefix = null
        information.suffix = "%"
      }
      return information
    }
  }
};
</script>
