<template>
  <div>
    <div class="tab-component-wrapper">
      <div v-if="loading">
        <div style="position: relative; padding: 200px 0;">
          <div class="thrive-loader"></div>
        </div>
      </div>

      <div v-if="!loading">
        <v-card v-if="productSuppliers.length > 0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
          <div class="mx-3 mt-4 pb-3">
            <v-container grid-list-md class="pa-0" fluid>
              <v-layout row wrap>
                <v-flex md6 xs12>
                  <v-text-field
                    class="mx-1"
                    v-model="search"
                    dense
                    hide-details
                    name="searchfield"
                    :label="$t('general.search', $store.state.locale)"
                    background-color="background"
                    prepend-inner-icon="mdi-magnify"
                    single-line
                    outlined
                    clearable
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12 v-if="productSuppliers.length > 0">
                  <div class="text-right" >
                    <v-btn  
                      dark
                      height="40px"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      @click="newProductSupplier()"
                      class="mx-1"
                      >
                      <v-icon left>mdi-plus</v-icon> {{ $t('products.newProductSupplier', $store.state.locale) }}
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </div>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="productSuppliers"
            :search="search"
            hide-default-footer
            single-expand
            show-expand
            :itemsPerPage.sync="pages"
            dense
            class="dense-table"
            :no-results-text="`${$t('products.noResultsFound', $store.state.locale)} ${search}`"
            > 
            <template v-slot:item="{ item, isExpanded, expand }">
              <tr>
                <td>{{ item.name }}</td>
                <td>
                  <v-chip
                    small 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    light 
                    class="mr-2 my-1 custom-chip-style" >
                    {{ item.companies.length }}&nbsp; <span v-if="item.companies.length == 1" style="text-transform: lowercase; font-weight: bold;"> {{$t('general.company', $store.state.locale)}}</span><span style="text-transform: lowercase; font-weight: bold;" v-else> {{$t('general.companies', $store.state.locale)}}</span> 
                  </v-chip>
                </td>
                <td>
                  <v-chip
                    small 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    light 
                    class="mr-2 my-1 custom-chip-style" >
                    {{ item.groups.length }}&nbsp; <span style="text-transform: lowercase; font-weight: bold;" v-if="item.groups.length == 1"> {{ $t('products.group', $store.state.locale)}}</span><span style="text-transform: lowercase; font-weight: bold;" v-else> {{$t('products.groups', $store.state.locale)}}</span>
                  </v-chip>
                </td>
                <td>
                  <v-btn icon  @click="editProductSupplier(item)" >
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small>mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn icon @click="expand(true)" v-if="!isExpanded"><v-icon  dark>mdi-menu-down</v-icon></v-btn>
                  <v-btn icon @click="expand(false)" v-if="isExpanded"><v-icon  dark>mdi-menu-up</v-icon></v-btn>
                </td>
              </tr>
            </template>
            
            <template v-slot:expanded-item="{ headers, item }" >
              <td :colspan="headers.length" class="ma-0 pa-0" :key="changes">
                <v-card flat class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader">
                  <v-card-text class="ma-0 pa-0">

                    <div class="no-data-message pa-6" v-if="item.groups.length == 0">
                      <v-avatar
                        size="38"
                        light
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alpha-s-circle</v-icon>
                      </v-avatar>
                      <p class="pa-4 ma-0"> {{ $t('products.noSupplierProductGroupFound', $store.state.locale) }} {{ item.name }}</p>
                      <v-btn small dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="newSupplierProductGroup(item.id)">
                        <v-icon left >mdi-plus</v-icon> {{ $t('products.newProductGroup', $store.state.locale) }}
                      </v-btn>
                    </div>
                    <div v-if="item.groups.length > 0">
                      <v-row align="center" no-gutters class="map-0 pa-0">
                        <v-col style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                          <div v-for="(group, index) in item.groups" :key="index">
                            <v-row align="center" no-gutters>
                              <v-col cols="10" class="px-2 py-1">{{ group.name }}</v-col>
                            
                              <v-col cols="1"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small @click="editSupplierProductGroup(group, item.id)">mdi-pencil</v-icon></v-col>
                              <v-col cols="1"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small @click="showGroupDeleteDialog(item.id, group.id)">mdi-delete</v-icon></v-col>
                            </v-row>
                          <v-divider v-if="index < item.groups.length -1"></v-divider>
                        </div>
                        </v-col>
                        <v-col cols="3" class="pa-2 px-4">
                          <v-btn  block small dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="newSupplierProductGroup(item.id)">
                            <v-icon left >mdi-plus</v-icon> {{ $t('products.newProductGroup', $store.state.locale) }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                  

                  <v-divider></v-divider>
                </v-card>
              </td>
            
            </template>
          </v-data-table>
        </v-card>

        <div class="no-data-message" v-if="productSuppliers.length == 0">
          <v-avatar
            size="58"
            light
            class="ma-0 mr-2 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alpha-s-circle</v-icon>
          </v-avatar>
          <p> {{ $t('products.newProductSupplierFound', $store.state.locale) }}</p>
          <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="newProductSupplier()">
            <v-icon left >mdi-plus</v-icon> {{ $t('products.newProductSupplier', $store.state.locale) }}
          </v-btn>
        </div>
      </div>
    </div>

    <!-- / ProductSupplier Dialog \ -->
    <product-supplier-form
      :companies="companies"
      @updateData="loadData()"
    ></product-supplier-form>
    <!-- \ ProductSupplier Dialog / -->

    <!-- / Supplier Product Group Dialog \ -->
    <supplier-product-group-form
      @updateData="loadSupplierProductGroup"
    ></supplier-product-group-form>
    <!-- \ Supplier Product Group Dialog / -->

    <!-- / Delete Product Group Dialog \ -->
    <v-dialog v-model="deleteSelectedGroup.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <h3 class="mb-4">{{ $t('products.removeProductGroup', $store.state.locale) }}</h3> 
          <p class="ma-0">{{ $t('products.removeProductGroupInfo', $store.state.locale) }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="deleteSelectedGroup.deleting" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="hideGroupDeleteDialog()">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="deleteSelectedGroup.deleting" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleteGroup()">
                   {{ $t('rooms.removeRoomButton', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Product Group Dialog / -->

  </div>
</template>
     


<script>
import '@firebase/firestore';
import db from "../firebase/init";
import moment from "moment";
import { bus } from "../main";
import SupplierProductGroupForm from "../components/modals/SupplierProductGroupForm.vue";
import ProductSupplierForm from "../components/modals/ProductSupplierForm.vue";

export default {
  components: {
    "product-supplier-form": ProductSupplierForm,
    "supplier-product-group-form": SupplierProductGroupForm
  },
  name: "product-suppliers",
  data(){
    return{
      search: "",
      loading: true,
      pages: -1,
      companies:[],
      editProductSupplierDialog: false,
      newProductSupplierDialog: false,
      selectedProductSupplierInfo: {},
      selectedProductSupplierInfoOriginal: {},
      selected: [],
      pagination: {
        sortBy: "name",
        rowsPerPage: 20,
        descending: false
      },
      changes: 0,
      productSuppliers: [],
      headers: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "name"
        },
        {
          text: this.$t('general.companies', this.$store.state.locale),
          align: "left",
          value: "companies",
          width: "150px"
        },
        {
          text: this.$t('products.groups', this.$store.state.locale),
          align: "left",
          value: "groups",
          width: "150px"
        },
        {
          text: "",
          align: "right",
          value: "edit",
          width: "60px"
        },
        { 
          text: '', 
          value: 'data-table-expand',
          width: "60px"
        },
      ],
      deleteSelectedGroup:{
        dialog: false,
        groupId: null,
        supplierId: null,
        deleting: false
      }
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData(){
      this.loading = true;
      await Promise.all([this.loadCompanies(), this.loadProductSuppliers()])
      console.log("Alles geladen")
      this.loading = false;
    },

    showGroupDeleteDialog(supplierId, groupId){
      this.deleteSelectedGroup.dialog = true;
      this.deleteSelectedGroup.supplierId = supplierId;
      this.deleteSelectedGroup.groupId = groupId;
      this.deleteSelectedGroup.deleting = false;
    },

    hideGroupDeleteDialog(){
      this.deleteSelectedGroup.dialog = false;
      this.deleteSelectedGroup.supplierId = null;
      this.deleteSelectedGroup.groupId = null;
      this.deleteSelectedGroup.deleting = false;
    },

    async deleteGroup(){
      this.deleteSelectedGroup.deleting = true;
      await db.collection("productSuppliers")
      .doc(this.deleteSelectedGroup.supplierId)
      .collection("groups")
      .doc(this.deleteSelectedGroup.groupId)
      .set({
        deleted: {
          deleted: true,
          deletedOn: moment().format("YYYY-MM-DDTHH:mm"),
          deletedBy: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`
        }
      },{merge: true})
      this.deleteSelectedGroup.deleting = false;
      this.loadSupplierProductGroup(this.deleteSelectedGroup.supplierId)
      this.hideGroupDeleteDialog()
    },

    editProductSupplier(item) {
      bus.$emit("ProductSupplierModal", item, 'edit');
    },

    newProductSupplier(){
      bus.$emit("ProductSupplierModal", null, 'new');
    },

    newSupplierProductGroup(id){
      bus.$emit("supplierProductGroupModal", null, id, 'new');
    },

    editSupplierProductGroup(group, id){
      bus.$emit("supplierProductGroupModal", group, id, 'edit');
    },

    async loadProductSuppliers() {
      this.loading = true;
      await db.collection("productSuppliers")
        .get()
        .then(snap => {
          this.productSuppliers = new Array();
          snap.forEach(doc => {
            let productSupplier = doc.data();
            productSupplier.id = doc.id;
            this.productSuppliers.push(productSupplier);
          });
        })
        .catch(error=>{
          console.error("Error getting productsuppliers: ", error)
          this.loading = false;
        })
        console.log("suppliers geladen")
        await this.loadSupplierProductGroup()
        console.log("groups geladen")
        return
    },

    async loadCompanies() {
      await db.collection("companies")
      .get()
      .then(snap => {
        this.companies = new Array();
        snap.forEach(doc => {
          let compCollection = doc.data();
          compCollection.id = doc.id;
          this.companies.push(compCollection);
        });
      })
      console.log("commpanies geladen")
      return
    },

    async loadSupplierProductGroup(id){
      if(id){
        let index =  this.productSuppliers.findIndex((item) => id === item.id)
        if(index >=0){
          this.productSuppliers[index].groups = new Array();
          await db.collection("productSuppliers")
          .doc(this.productSuppliers[index].id)
          .collection("groups")
          .where("deleted.deleted", "==", false)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let group = doc.data();
              group.id = doc.id;
              this.productSuppliers[index].groups.push(group);
            });
          })
          this.productSuppliers[index].groups.sort((a, b) => a.name.localeCompare(b.name));
          this.changes++
          return
        }
        else{
          return
        }
      }
      else{
        await new Promise((resolve)=>{
          for (let index = 0; index < this.productSuppliers.length; index++) {
            this.productSuppliers[index].groups = new Array();
            db.collection("productSuppliers")
            .doc(this.productSuppliers[index].id)
            .collection("groups")
            .where("deleted.deleted", "==", false)
            .get()
            .then(snap => {
              snap.forEach(doc => {
                let group = doc.data();
                group.id = doc.id;
                this.productSuppliers[index].groups.push(group);
              });
            })
            .then(()=>{
              this.productSuppliers[index].groups.sort((a, b) => a.name.localeCompare(b.name));
              if(index == this.productSuppliers.length -1 ){
                resolve()
              }
            })
          }
        })
        return 
      }

    }
  }
};
</script>
