<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <div v-if="type =='new'">
          <h3 v-if="!menuItem.parent" style="font-size: 20px; font-weight: 600" class="ma-0" > {{ $t('admin.addMenuItem', $store.state.locale) }}</h3>
          <h3 v-else  class="ma-0" >{{ $t('admin.addSubmenuItem', $store.state.locale) }}</h3>
        </div>
        <div v-if="type =='edit'">
          <h3 v-if="!menuItem.parent" style="font-size: 20px; font-weight: 600" class="ma-0" > {{ $t('admin.editMenuItem', $store.state.locale) }}</h3>
          <h3 v-else  class="ma-0" >{{ $t('admin.editSubmenuItem', $store.state.locale) }}</h3>
        </div>
        
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeMenuItemDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider class="chart-divider" :class="$store.state.companyTheme"></v-divider>

      <v-card-text v-if="menuItem.name" style="height: 500px;" class="px-4 py-2 ma-0" >
      <!-- / Select imput language \ -->
        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="pa-2 mb-4">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12> 
              <v-avatar
                size="54"
                light
                style="float: left"
                class="ma-0" 
                rounded="lg"
                color="#ffffff" 
                >
                <country-flag class="ma-0 pa-0" :country="getFlagCode(selectedLanguage)" />
              </v-avatar>
              <v-select
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :disabled="availableLanguages.length == 1"
                :items="availableLanguages"
                :item-text="(item) => item.languageName[$store.state.locale.toLowerCase()]"
                :label="$t('general.language', $store.state.locale)"
                item-value="id"
                dense
                outlined
                hide-details
                class="mt-2 pl-2"
                v-model="selectedLanguage"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card>
        <!-- \ Select imput language / -->

        <v-select
          v-if="!menuItem.parent"
          class="mt-4"
          hide-details
          outlined
          dense
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          v-model="menuItem.type"
          :items="menuItemTypes"
          :label="$t('admin.menuType', $store.state.locale)"
          item-text="name"
          item-value="id"
          required
          @change="resetMenuItemUrl()"
        ></v-select>

        <v-text-field 
          :label="$t('general.name', $store.state.locale) + ` (${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
          class="mt-4"
          hide-details
          dense
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          v-model="menuItem.name[selectedLanguage]" 
          autocomplete
          required
          outlined
        ></v-text-field>

        <v-text-field
          v-if="menuItemType == 'group'" 
          :label="$t('admin.menuDescription', $store.state.locale) + ` (${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
          class="mt-4"
          hide-details
          dense
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          v-model="menuItem.description[selectedLanguage]" 
          autocomplete
          required
          outlined
        ></v-text-field>

        <v-text-field 
          :label="$t('admin.menuUrl', $store.state.locale)"
          class="mt-4"
          hide-details
          dense
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          v-model="menuItem.url" 
          autocomplete
          required
          outlined
        ></v-text-field>

        <v-text-field 
          :label="$t('admin.menuIcon', $store.state.locale)"
          class="mt-4"
          hide-details
          dense
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          v-model="menuItem.icon" 
          autocomplete
          required
          outlined
          :append-icon="menuItem.icon ? menuItem.icon : null"
        ></v-text-field>

        <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 mt-4 py-2 pb-4" v-model="menuItem.permissionLimit" hide-details v-on:change="resetMenuItemPermissions()" style="display: table">
          <template slot="label">
            <span style="color: #333; margin-right: 5px">{{ $t('admin.menuSetPermissions', $store.state.locale) }}</span>
            <v-tooltip class="ma-0 pa-0" right>
              <template v-slot:activator="{ on }">
                <v-icon color="#687387" small v-on="on">mdi-help-circle</v-icon>
              </template>
              <span style="color: #fff">{{ $t('admin.menuRolesHint', $store.state.locale) }}</span>
            </v-tooltip>
          </template>
        </v-switch>

        <v-select
          v-if="menuItem.permissionLimit"
          class="mb-4"
          hide-details
          outlined
          dense
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          v-model="menuItem.permissionsRoles"
          :items="userRoles"
          :label="$t('admin.menuRoles', $store.state.locale)"
          item-text="name"
          item-value="number"
          required
        ></v-select>

        <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 py-2 pb-4" v-model="menuItem.packageLimit" hide-details v-on:change="resetMenuItemPackages()" style="display: table">
          <template slot="label">
            <span style="color: #333; margin-right: 5px">{{ $t('admin.menuSetPackages', $store.state.locale) }}</span>
            <v-tooltip class="ma-0 pa-0" right>
              <template v-slot:activator="{ on }">
                <v-icon color="#687387" small v-on="on">mdi-help-circle</v-icon>
              </template>
              <span style="color: #fff">{{ $t('admin.menuPackagesHint', $store.state.locale) }}</span>
            </v-tooltip>
          </template>
        </v-switch>

        <v-select
          v-if="menuItem.packageLimit"
          class="mb-4"
          hide-details
          outlined
          dense
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          v-model="menuItem.packages"
          :items="packageItems"
          :label="$t('admin.menuPackages', $store.state.locale)"
          item-text="name"
          item-value="id"
          required
        ></v-select>

         <v-switch
          style="display: table"
          class="ma-0 mt-2 pa-0"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          :label="$t('admin.menuActive', $store.state.locale)"
          hide-details
          v-model="menuItem.active"
        ></v-switch>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="px-3">
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeMenuItemDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn
                v-if="type =='edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :loading="busy"
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateMenuItem()"
                >
                <div v-if="changed">{{ $t('general.saveChanged', userLanguage) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else> {{ $t('general.saved', userLanguage) }} <v-icon right >mdi-check</v-icon></div>   
              </v-btn>
              <v-btn
                v-if="type =='new'"
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addMenuItem()"
                >
                {{ $t('admin.addMenuItemSmall', $store.state.locale) }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
  import db from "../../firebase/init";
  import { bus } from "../../main";

  export default {
    name: "edit-menu-item-form",
    props: {
      packageItems: Array,
      userRoles: Array
    },
    data() {
      return {
        menuItem: {
          name: new Object(),
          description:  new Object(),
        },
        menuItemOriginal: new Object(),
        showDialog: false,
        parent: null,
        busy: false,
        type: 'new',
        menuItemType: "group",
        selectedLanguage: null,
        feedback: null,
        menuItemTypes: [
          {
            id: "group",
            name: this.$t('admin.menuGroep', this.$store.state.locale) 
          },
          {
            id: "single",
            name: this.$t('admin.menuLink', this.$store.state.locale) 
          }
        ],
      };
    },

    created(){
      bus.$on("menuItem", (item, menuItemType, parent, type) => { // Executed on opening the dialog in Menu.vue
        this.type = type;
        this.resetMenuItem();
        this.selectedLanguage = this.$store.state.locale.toLowerCase(); // Set language to user default language

        if(type == 'edit'){
          this.menuItem = item;
          this.menuItemOriginal = _.cloneDeep(this.menuItem);
        }
        else{
          this.menuItemType = menuItemType;
          this.parent = parent;
        }
        
        this.showDialog = true;  
      });
    },

    methods: {

      resetMenuItem(){
        this.menuItem = {
          name: new Object(),
          description:  new Object(),
          icon: null,
          permissionLimit: false,
          permissionsRoles: null,
          packageLimit: false,
          packages: null,
          url: null,
          active: true
        };
      },

      closeMenuItemDialog() { // Close Dialog Modal
        this.showDialog = false;
        this.menuItem = new Object();
        this.menuItemOriginal = new Object();
      },

      resetMenuItemPermissions(){ // Empty selected permissons on toggle
        this.menuItem.permissionsRoles = null;
      },

      resetMenuItemPackages(){ // Empty selected packages on toggle
        this.menuItem.packages = null;
      },

      resetMenuItemUrl(){ // Empty url on toggle
        this.menuItem.url = null;
      },

      updateMenuItem() { // Add Menu item to database
        this.busy = true;
        this.feedback = null;

        db.collection("menuItems").doc(this.menuItem.id).set({
          type: this.menuItem.type,
          name: this.menuItem.name,
          description: this.menuItem.description,
          icon: this.menuItem.icon,
          url: this.menuItem.url,
          permissionLimit: this.menuItem.permissionLimit,
          packages: this.menuItem.packages,
          permissionLimit: this.menuItem.permissionLimit,
          permissionsRoles: this.menuItem.permissionsRoles,
          active: this.menuItem.active,
        }, {merge: true})
        .then(() => {
          this.$emit("updateData");
          this.busy = false;
          this.closeMenuItemDialog()
        })
        .catch(error => {
          this.busy = false;
          this.feedback = this.$t('admin.menuSaveError', this.$store.state.locale) ;
          console.error("Error adding menu item: ", error);
        });
      },
      addMenuItem() { // Add Menu item to database
        db.collection("menuItems").add({
          type: this.menuItemType,
          parent: this.parent,
          name: this.menuItem.name,
          description: this.menuItem.description,
          icon: this.menuItem.icon,
          url: this.menuItem.url,
          packageLimit: this.menuItem.packageLimit,
          packages: this.menuItem.packages,
          permissionLimit: this.menuItem.permissionLimit,
          permissionsRoles: this.menuItem.permissionsRoles,
          active: this.menuItem.active,
        })
        .then(() => {
          this.$emit("updateData");
          this.closeMenuItemDialog();
        })
        .catch(error => {
          this.feedback = this.$t('admin.menuSaveError', this.$store.state.locale) ;
          console.error("Error adding menu item: ", error);
        });
      },
      getFlagCode(selectedLanguage){
        return selectedLanguage ? this.$countries.find(language => { return language.id == selectedLanguage }).flagCode : "";
      }
    },
    computed: {
      selectedLanguageObject(){
        return this.selectedLanguage ? this.$countries.find(language => { return language.id == this.selectedLanguage }) : {languageName:new Object()};
      },

      availableLanguages(){
        return this.$countries.filter(language => { // Filter rich languages data from user languages
          if(language.userLanguage){return language.id }
        });
      },
      
      changed() {
        return _.isEqual(this.menuItemOriginal, this.menuItem) ? false : true;
      },
    }
  };
</script>
