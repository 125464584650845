<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card style="height: 600px; overflow: hidden">
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="bookingType == 'new'">{{ $t('rooms.book', $store.state.locale) }} {{ roomName }}</h3>
        <h3 v-else>{{ $t('rooms.edit', $store.state.locale) }} {{ selectedResourceInfo.name }}</h3>
        <v-spacer></v-spacer>
        <v-btn v-if="!loading" icon dark @click="closedialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" :size="25" :width="3"></v-progress-circular>
      </v-card-title>

      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text class="pa-0 ma-0">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs6>
              <v-text-field class="mt-4" dense outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('general.from', $store.state.locale)" v-model="roomBooking.start" type="time" :suffix="$t('general.hour', $store.state.locale)"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field class="mt-4" dense outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('general.till', $store.state.locale)" v-model="roomBooking.end" type="time" :suffix="$t('general.hour', $store.state.locale)"></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-textarea
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                name="input-5-1"
                :label="$t('rooms.notes', $store.state.locale)"
                value=""
                rows="3"
                persistent-hint
                :hint="$t('rooms.notesHint', $store.state.locale)"
                v-model="roomBooking.description"
              ></v-textarea>
            </v-flex>

            <v-flex xs6>
              <v-switch class="pt-0 mt-0" :label="$t('rooms.overwritable', $store.state.locale)" v-model="bookingOverride"  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-switch>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left">
              <div class="text-left">
                <div v-if="bookingType != 'new'" style="display: inline-block; margin: 0 5px">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" text icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0 ma-0">
                      <v-list-item-group v-for="(item, index) in treatmentOptions" :key="index">
                        <v-list-item @click.native="deleter()">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index < treatmentOptions.length"></v-divider>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>

                <v-btn :loading="loading" outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closedialog()"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
              </div>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn v-if="bookingType == 'new'" dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="bookroom('new')">
                {{ $t('general.add', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn v-else :depressed="!bookingChanged" :disabled="!bookingChanged" :loading="loading" :dark="bookingChanged"  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="bookroom('existing')">
                <div v-if="bookingChanged">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { bus } from "../../main";
  import moment from "moment";
  import db from "../../firebase/init";
  export default {
    props: ["selectRoom", "localetime", "selectedResourceInfo", "selectedStart", "selectedEnd", "bookingen"],

    data() {
      return {
        dialog: false,
        feedback: null,
        loading: false,
        treatmentOptions: [{ name: this.$t('general.remove', this.$store.state.locale), id: "1" }],
        roomName: null,
        datepickerdialog: false,
        bookingOverride: false,
        selectedResourceId: null,
        disableTimechange: false,
        bookingDocId: null,
        eventData: null,
        bookingType: "new",
        loading: false,
        roomBooking: {
          start: null,
          end: null,
          description: null,
          type: "",
        },
        roomBookingOriginal: {},
      };
    },

    mounted() {
      bus.$on("bookRoom", (selectedResource) => {
        this.selectedResourceId = selectedResource
        this.loadSelectedData(this.selectedStart, this.selectedEnd, this.selectRoom);
      });

      bus.$on("editRoomBooking", (roomBooking, selectedResource) => {
        this.roomBooking = roomBooking;
        this.selectedResourceId = selectedResource
        this.roomBookingOriginal = _.cloneDeep(roomBooking);
        this.loadSelectedEvent();
      });
    },

    methods: {
      // Gebruikt bij het maken van een Event
      loadSelectedData(timestart, timeend, roomname) {
        this.bookingType = "new";
        this.roomName = roomname;
        this.roomBooking.start = moment(_.cloneDeep(timestart)).format("HH:mm");
        this.roomBooking.end = moment(_.cloneDeep(timeend)).format("HH:mm");
        this.roomBooking.description = "";
        this.roomBooking.type = "roombooking";
        this.dialog = true;
      },

      // Gebruikt bij het openen van een bestaande event
      loadSelectedEvent() {
        this.bookingType = "edit";
        this.disableTimechange = true;
        this.bookingDocId = this.roomBooking.eventId;
        this.roomName = this.roomBooking.room;
        this.bookingOverride = this.roomBooking.bookingOverride;
        this.eventData = this.roomBooking.eventData;
        this.roomBooking.type = this.roomBooking.type;
        this.dialog = true;
      },

      async bookroom(type) {
        this.loading = true;
        let bookingcolor = null;
        let bookingOverride = null;
        if (this.bookingOverride) {
          bookingcolor = "rgba(243, 148, 136, 0.50)";
          bookingOverride = true;
        }
        else {
          bookingcolor = "rgb(243, 148, 136)";
          bookingOverride = false;
        }

        if(type == "new") {
          let uniqid = require("uniqid");
          let uniqidBooking = uniqid()
          const bookingSummary = await db.collection("bookingsummary")
          .add({
            company: this.$store.state.activeCompany.name,
            companyId: this.$store.state.activeCompany.id,
            created: moment().format("YYYY-MM-DDTHH:mm"),
            createdby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")",
            visit: moment(this.localetime).format("YYYY-MM-DD") + "T" + this.roomBooking.start,
            treatmentTitle: "",
            // resourceId: Number(this.selectedResourceInfo.id),
            // resourceName: this.roomName,
            branchId: this.$store.state.activeBranch.id,
            type: "roombooking",
          })
          .catch((error) => {
            console.error("Error created document bookingsummary ", error);
          });

          const booking = await db.collection("bookingen")
          .add({
            bookingId: bookingSummary.id,
            resourceDocId: this.selectedResourceInfo.id,
            start: moment(this.localetime).format("YYYY-MM-DD") + "T" + this.roomBooking.start,
            end: moment(this.localetime).format("YYYY-MM-DD") + "T" + this.roomBooking.end,
            dateBooking: moment(this.localetime).format("YYYY-MM-DD"), 
            branchId: this.$store.state.activeBranch.id,
            title: this.roomName + " gereserveerd",
            clientname: this.roomName + " gereserveerd",
            description: this.roomBooking.description,
            type: "roombooking",
            resourceType: "room",
            deleted: false,
            eventColor: bookingcolor,
            bookingOverride: bookingOverride, // Overheen boeken mogelijk? False = Nee, True = Ja
            // roomId: this.selectedResourceInfo.id,
            // roomName: this.roomName,
            
            uniqIdStamp: uniqidBooking,
            modifiedStamp: new Date()
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });

          let cacheObject = {
            uniqIdStamp: uniqidBooking,
            found: 0,
            bookings: [booking.id],
            resources: [this.selectedResourceId]
          };

          bus.$emit("newBookingCacheUpdate", cacheObject);

          // bus.$emit("cacheUpdate", [this.selectedResourceInfo.extendedProps.docId]);
        }

        else{
          await db.collection("bookingen")
          .doc(this.bookingDocId)
          .update({
            start: moment(this.localetime).format("YYYY-MM-DD") + "T" + this.roomBooking.start,
            end: moment(this.localetime).format("YYYY-MM-DD") + "T" + this.roomBooking.end,
            description: this.roomBooking.description,
            bookingOverride: bookingOverride,
            eventColor: bookingcolor,
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
          bus.$emit("calendarAction", "editCustom");
          bus.$emit("cacheUpdate", [this.selectedResourceId]);
        }
        this.closedialog();
        this.bookingDocId = null;
        this.loading = false;
      },

      async deleter() {
        this.loading = true;

        await db.collection("bookingen")
        .doc(this.bookingDocId)
        .update({
          dateBooking: "deleted",
          deleted: {
            normalDatebooking: moment(this.localetime).format("YYYY-MM-DD"),
            deletedby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")", //Dynamisch maken via Rollen systeem
            deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
          },
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

        this.dialog = false;
        this.devicesLoadedFiltered = new Array();
        this.disableTimechange = false;
        this.loading = false;
        bus.$emit("cacheUpdate", [this.selectedResourceInfo.id]);
        bus.$emit("calendarAction", "bookingDelete");
      },

      closedialog() {
        this.dialog = false;
        this.devicesLoadedFiltered = new Array();
        this.disableTimechange = false;
      },

    },
    computed: {
      bookingChanged() {
        return _.isEqual(this.roomBooking, this.roomBookingOriginal) ? false : true
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
    },
  };
</script>
