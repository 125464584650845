<template>
  <v-dialog v-model="editPauseModal" persistent scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title v-if="selectedPause.title" class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3> {{ $t('general.edit', $store.state.locale)}} {{$t(`tasks.${selectedPause.title.id}`, $store.state.locale)}}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closedialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>

        <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" v-if="$store.state.userRoleNumber < 2 && selectedPauseOriginal && !$store.state.demoMode && selectedPauseOriginal.id" class="mb-3">
            <v-btn @click="copyTextToClipboard(selectedPauseOriginal.id)" class="ma-1 mb-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="color: #ffffff; text-transform: none;"><strong>{{selectedPauseOriginal.id}}</strong></v-btn>
            <div style="display: inline-block" v-if="selectedPauseIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(selectedPauseOriginal.id)"><v-icon small color="#333">mdi-content-copy</v-icon> Kopieër Booking DocId</div>
            <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
          </v-card>

        <v-container grid-list-md class="ma-0 pa-0">
          <v-layout row wrap v-if="!loading" class="pt-4">
            <v-flex xs6>
              <v-select
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                dense
                item-text="name"
                hide-details
                :items="timeOptions"
                v-model="selectedPause.title"
                label="Type"
                return-object
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                outlined
                disabled
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                dense
                v-model="chosenDateReadable"
                :label="$t('general.date', $store.state.locale)"
                prepend-inner-icon="mdi-calendar-today"
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 v-if="selectedPause.type =='task' && selectedPause.clientName">
              <v-switch
                class="my-0 mt-2 pl-1"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                dense
                inset
                hide-details                  
                v-model="selectedPause.allDay"
                @change="setTimesAndOverwrite()"
                :label="$t('tasks.allDay', $store.state.locale) "
              ></v-switch>
            </v-flex>
            <v-flex xs6 v-if="!selectedPause.allDay">
              <v-text-field
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                prepend-inner-icon="mdi-clock-outline"
                dense
                :label="$t('general.start', $store.state.locale)"
                type="time"
                suffix="uur"
                hide-details
                class="mt-4"
                v-model="selectedPause.startFormatted"
                v-on:input="changes++"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 v-if="!selectedPause.allDay">
              <v-text-field
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                prepend-inner-icon="mdi-clock-outline"
                dense
                :label="$t('general.end', $store.state.locale)"
                type="time"
                suffix="uur"
                class="mt-4"
                hide-details
                v-model="selectedPause.endFormatted"
                v-on:input="changes++"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea outlined hide-details class="mt-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('general.description', $store.state.locale)" value rows="3" v-model="selectedPause.description"></v-textarea>
            </v-flex>

            <v-flex xs12 v-if="selectedPause.type != 'note' && (selectedPause.type != 'task' && !selectedPause.clientName)">
              <v-text-field
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                readonly
                dense
                :label="$t('general.employees', $store.state.locale)"
                disabled
                class="mt-4"
                hide-details
                :value="findEmployeeName(selectedPause.resourceDocId).title"
              >
              </v-text-field>
            </v-flex>

            <v-flex xs12 v-if="!selectedPause.allDay && selectedPause.type != 'note' && (selectedPause.type != 'task' && !selectedPause.clientName)">
              <v-switch class="ma-0 mt-4 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('tasks.overwritable', $store.state.locale)" v-model="selectedPause.bookingOverride"> </v-switch>
            </v-flex>
            <v-flex xs12 v-if="selectedPause.extra && selectedPause.extra.completed && selectedPause.extra.completedOn">
              Taak afgevinkt op <strong>{{ formatDate(selectedPause.extra.completedOn.seconds) }}</strong> door <strong>{{ selectedPause.extra.completedBy }}</strong>.
            </v-flex>
          </v-layout>
          <div v-else>
            <div style="position: relative; padding: 100px 0">
              <div class="thrive-loader" :class="companyTheme"></div>
            </div>
          </div>
        </v-container>
      </v-card-text>

      

      <v-divider></v-divider>

      <v-card-actions v-if="!loading">
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left">
              <div class="text-left">
                <div v-show="!loading && !deleting" style="display: inline-block; margin: 0 5px">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" text icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                      <v-list-item @click="deletePause()">
                        <v-list-item-title>{{ $t('general.delete', $store.state.locale) }} {{ selectedPause.title.name }} </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <v-btn v-if="!loading" outlined :disabled="deleting" depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  @click="closedialog()"> {{ $t('general.cancel', $store.state.locale) }}  </v-btn>
              </div>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                :dark="changed"
                :depressed="!changed"
                :disabled="!changed"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                @click="updatePause()"
                >
                <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style></style>

<script>
  import moment from "moment";
  import db from "../../firebase/init";
  import { bus } from "../../main";
  import _ from "lodash";

  export default {
    name: "edit-pause",
    data() {
      return {
        loading: true,
        deleting: false,
        editPauseModal: false,
        selectedPause: new Object(),
        selectedPauseOriginal: new Object(),
        changes: 0,
        selectedPauseIdCopy: false, 
        chosenDateDialog: false,
        startTime: null,
        endTime: null,
        feedback: null,
        employees: new Array(),
        selectedEmployees: new Array(),
        timeOptions:  [
          { name: "Pauze", id: "pause", type: "pauze" },
          { name: "Bespreking", id: "meeting", type: "occupied" },
          { name: "Bezet", id: "occupied", type: "occupied" },
          { name: "Taak", id: "task", type: "occupied" },
        ]
      };
    },

    created() {
      bus.$on("editPause", (data, type, employees) => {

        console.log("editPause: ", type)
        this.employees = employees;
        this.loading = true;
        if(type == 'database'){
          this.getPause(data);
        }
        else{
          this.selectedPause = data;
          this.selectedPause.startFormatted = moment(this.selectedPause.start, "YYYY-MM-DDTHH:mm").format("HH:mm");
          this.selectedPause.endFormatted = moment(this.selectedPause.end, "YYYY-MM-DDTHH:mm").format("HH:mm");
          if(this.selectedPause.type == 'note' || (this.selectedPause.type == 'task' && !this.selectedPause.clientName)){
            this.timeOptions = [{ name: this.$t('pause.note', this.$store.state.locale),  id: "note", type: "occupied"},{ name: this.$t('pause.task', this.$store.state.locale),  id: "task", type: "occupied"}];
          }
          else{
            this.timeOptions = [
              { name: "Pauze", id: "pause", type: "pauze" },
              { name: "Bespreking", id: "meeting", type: "occupied" },
              { name: "Bezet", id: "occupied", type: "occupied" },
              { name: "Taak", id: "task", type: "occupied" },
            ];
          }

          let titleIndex = _.findIndex(this.timeOptions, { name: this.selectedPause.title });
          if (titleIndex >= 0) {
            this.selectedPause.title = this.timeOptions[titleIndex];
          }
          else {
            this.selectedPause.title = this.timeOptions[0];
          }
          this.selectedPauseOriginal = _.cloneDeep(this.selectedPause);

          this.loading = false;
        }
        
        this.editPauseModal = true;
      });
    },

    methods: {
      updateCacheTrigger() {
        bus.$emit("cacheUpdate", [this.selectedPause.resourceDocId]);
      },

      formatDate(date){
        return moment(date, 'X').format("dddd D MMMM YYYY")
      },
      
      copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(()=> {
          this.selectedPauseIdCopy = true
          setTimeout(()=>{ 
            this.selectedPauseIdCopy = false;
          }, 1000);

        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      },

      getPause(id) {
        db.collection("bookingen")
        .doc(id)
        .get()
        .then((doc) => {
          this.selectedPause = doc.data();
          this.selectedPause.id = doc.id;
          this.selectedPause.startFormatted = moment(doc.data().start, "YYYY-MM-DDTHH:mm").format("HH:mm");
          this.selectedPause.endFormatted = moment(doc.data().end, "YYYY-MM-DDTHH:mm").format("HH:mm");
          if(this.selectedPause.type == 'note' || (this.selectedPause.type == 'task' && !this.selectedPause.clientName)){
            this.timeOptions = [{ name: this.$t('pause.note', this.$store.state.locale),  id: "note", type: "occupied"},{ name: this.$t('pause.task', this.$store.state.locale),  id: "task", type: "occupied"}];
          }
          else{
            this.timeOptions = [
              { name: "Pauze", id: "pause", type: "pauze" },
              { name: "Bespreking", id: "meeting", type: "occupied" },
              { name: "Bezet", id: "occupied", type: "occupied" },
              { name: "Taak", id: "task", type: "occupied" },
            ];
            
          }
          let titleIndex = _.findIndex(this.timeOptions, { name: this.selectedPause.title });
          if (titleIndex >= 0) {
            this.selectedPause.title = this.timeOptions[titleIndex];
          } else {
            this.selectedPause.title = this.timeOptions[0];
          }

          if (this.selectedPause.extra.completed && this.selectedPause.extra.completedOn) {
            this.selectedPause.taskCompletedOn = moment(this.selectedPause.extra.completedOn.seconds, "X").locale(this.$store.state.locale.toLowerCase()).format("dddd D MMMM YYYY");
          }

          this.selectedPauseOriginal = _.cloneDeep(this.selectedPause);
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error getting pause doc: ", error);
        });
      },

      setTimesAndOverwrite(){
        if(this.selectedPause.allDay){
          this.selectedPause.endFormatted = "00:15"
          this.selectedPause.startFormatted = "00:05"
          this.selectedPause.bookingOverride = true;
        }
        else{
          this.selectedPause.endFormatted = "00:00"
          this.selectedPause.startFormatted = "00:00"
          this.selectedPause.bookingOverride = false;
        }
      },

      async deletePause() {
        if(this.selectedPause.schedulePauseId){
          bus.$emit("removeSchedulePause",  this.selectedPause.schedulePauseId);
        }
        else{
          await db.collection("bookingen")
          .doc(this.selectedPause.id)
          .delete()
        }
        this.closedialog();
        this.updateCacheTrigger();
        bus.$emit("calendarAction", "deletePause", this.selectedPause.title.id);
      },

      closedialog() {
        this.editPauseModal = false;
        this.loading = true;
      },

      findEmployeeName(id) {
        console.log("findEmployeeName: ", id)
        let employee = this.employees.find(employee => { return employee.id == id })
        
        // (id) => _.find(this.employees, ["docId", id]);
        
        return employee != undefined ? employee : new Object()
      },

      updatePause() {
        if (this.selectedPause.type != "task" || (this.selectedPause.type == "task" && this.selectedPause.description)) {
          let extra = new Object();
          let bookingcolor = "rgb(161, 161, 161)";
          if (this.selectedPause.extra) {
            extra = this.selectedPause.extra;
          }
          if (this.selectedPause.type == "task" && !this.selectedPause.extra.completed) {
            extra.completed = false;
            extra.completedBy = null;
            extra.completedOn = null;
          }

          if (this.selectedPause.bookingOverride) {
            bookingcolor = "rgba(161, 161, 161, 0.50)";
          }

          db.collection("bookingen")
            .doc(this.selectedPause.id)
            .set({
              start: this.selectedPause.dateBooking + "T" + this.selectedPause.startFormatted,
              end: this.selectedPause.dateBooking + "T" + this.selectedPause.endFormatted,
              title: this.selectedPause.title.name,
              description: this.selectedPause.description,
              bookingOverride: this.selectedPause.bookingOverride,
              allDay: this.selectedPause.allDay ? this.selectedPause.allDay : false,
              eventColor: bookingcolor,
              extra: extra,
              type: this.selectedPause.title.id,
            },
            { merge: true })
            .then(() => {
              this.updateCacheTrigger();
              this.closedialog();
              bus.$emit("calendarAction", "updatePause", this.selectedPause.title.id);
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        } else {
          this.feedback = "Vul beschrijving in om tijd vrij te maken.";
        }
      },
    },
    computed: {
      chosenDateReadable() {
        if (this.selectedPause.dateBooking) {
          return moment(this.selectedPause.dateBooking).format("D MMM YYYY");
        } else {
          return "";
        }
      },

      checked() {
        if (this.selectedEmployees.length > 0 && this.selectedType && this.chosenDate) {
          return true;
        } else {
          return false;
        }
      },
      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
      changed() {
        this.changes;
        return _.isEqual(this.selectedPause, this.selectedPauseOriginal) ? false : true
      },
    },
  };
</script>
