export default 
   {
    2023: [
        {
            date: "01-01",
            description: {
                nl: "Nieuwjaarsdag",
                en: "New Yearsday"
            }
        },
        {
            date: "04-07",
            description: {
                nl: "Goede vrijdag",
                en: "Good Friday"
            }
        },
        {
            date: "04-09",
            description: {
                nl: "Eerste Paasdag",
                en: "Easter Sunday"
            }
        },
        {
            date: "04-10",
            description: {
                nl: "Tweede Paasdag",
                en: "Easter Monday"
            }
        },
        {
            date: "04-27",
            description: {
                nl: "Koningsdag",
                en: "Kingsday"
            }
        },
        {
            date: "05-18",
            description: {
                nl: "Hemelvaart",
                en: "Good Friday"
            }
        },
        {
            date: "05-28",
            description: {
                nl: "Eerste Pinksterdag",
                en: "Pentecost Sunday"
            }
        },
        {
            date: "05-29",
            description: {
                nl: "Tweede Pinksterdag",
                en: "Pentecost Monday"
            }
        },
        {
            date: "12-25",
            description: {
                nl: "Eerste Kerstdag",
                en: "First Christmas Day"
            }
        },
        {
            date: "12-26",
            description: {
                nl: "Tweede Kerstdag",
                en: "Second Christmas Day"
            }
        },
    ],
    2024: [
        {
            date: "01-01",
            description: {
                nl: "Nieuwjaarsdag",
                en: "New Yearsday"
            }
        },
        {
            date: "03-29",
            description: {
                nl: "Goede vrijdag",
                en: "Good Friday"
            }
        },
        {
            date: "03-31",
            description: {
                nl: "Eerste Paasdag",
                en: "Easter Sunday"
            }
        },
        {
            date: "04-01",
            description: {
                nl: "Tweede Paasdag",
                en: "Easter Monday"
            }
        },
        {
            date: "04-27",
            description: {
                nl: "Koningsdag",
                en: "Kingsday"
            }
        },
        {
            date: "05-9",
            description: {
                nl: "Hemelvaart",
                en: "Good Friday"
            }
        },
        {
            date: "05-19",
            description: {
                nl: "Eerste Pinksterdag",
                en: "Pentecost Sunday"
            }
        },
        {
            date: "05-20",
            description: {
                nl: "Tweede Pinksterdag",
                en: "Pentecost Monday"
            }
        },
        {
            date: "12-25",
            description: {
                nl: "Eerste Kerstdag",
                en: "First Christmas Day"
            }
        },
        {
            date: "12-26",
            description: {
                nl: "Tweede Kerstdag",
                en: "Second Christmas Day"
            }
        },
    ]
   }
;
