
<template>
  <v-dialog v-model="receiptPreviewDialog" max-width="400px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h2>{{ $t('payment.receiptPreview', $store.state.locale) }}</h2>
        <v-spacer></v-spacer>
        <v-btn icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closePreviewReceiptDialog()"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-4 ma-0" style="position: relative" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }">

        <div v-if="loading">
          <div style="position: relative; padding: 200px 0;">
            <div class="thrive-loader"></div>
          </div>
        </div>
      
        <div class="pa-0" v-if="!loading">
          <v-container grid-list-md class="pa-0 ma-0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-select
                  :label="$t('general.language', $store.state.locale)"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :items="languages"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                  v-model="selectedLanguage"
                  @change="generatedReceipt"
                ></v-select>
                <div class="receipt-preview-wrapper">
                  <div class="receipt-preview-content">

                    <!-- / Receipt Preview Table \ -->
                    <div v-html="receiptHtmlCode"></div>
                    <!-- \ Receipt Table / -->

                  </div>
                </div>

              </v-flex>
            </v-layout>
          </v-container>
        </div>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import db from "../firebase/init";
  import { bus } from "../main";
  import { receiptBuilder } from "../modules/receipt";

  export default {
    name:"previewReceiptTemplate",
    props: {
      activeBranchData: Object
    },

    data(){
      return{
        receiptPreviewDialog: false,
        selectedLanguage: 'nl',
        loading: true,
        receiptHtmlCode: "",
        selectedTemplate: null,
        languages: [
          { id: "nl", name: "Nederlands"},
          { id: "en", name: "English"}
        ],
      }
    },

    created(){
      bus.$on("previewReceiptTemplate", (id) => { 
        this.loadReceiptTemplate(id);
      })
    },

    methods:{
      openReceiptPreviewDialog(){
        this.receiptPreviewDialog = true;
      },

      closePreviewReceiptDialog(){
        this.receiptPreviewDialog = false;
        this.receiptHtmlCode = "";
        this.selectedTemplate = null;
      },

      async loadReceiptTemplate(id){
        this.loading = true;
        this.openReceiptPreviewDialog();

        if(!id){ // Use Thrive Default Template if no ID is specified
          await db.collection('receiptTemplates')
          .where('companyId', '==', 'thrive')
          .where('templateType', '==', 'general')
          .get()
          .then(snap => {
            snap.forEach(doc => {
              this.selectedTemplate = doc.data();
            });
          })
        }
        else{
          await db.collection('receiptTemplates').doc(id)
          .get()
          .then(doc => {
              this.selectedTemplate = doc.data();
          })
        }
        this.generatedReceipt() 
      },

      async generatedReceipt(){
        this.loading = true;

        // Data for Preview
        let receiptTemplate = this.selectedTemplate;
        let typePayment = "PIN"; // PIN or CASH
        let receiptType = "deposit";
        let selectedItems = [
          {
            name: "Behandeling",
            type: "treatment",
            typeOfPayment: "deposit",
            priceBruto: 150.00
          },
          {
            name: "Product",
            type: "product",
            typeOfPayment: "deposit",
            priceBruto: 21.95
          }
        ];
        let usedModifiers = new Array();
        let totalBruto = 171.95;
        let totalBrutoWithoutDiscount = 171.95;
        let totalDiscountVAT = 0;
        let totalNetto = 144.11;
        let totalVAT = 27.84;
        let totalVats = [
          { percentageVat: 21, totalVat: 26.03 },
          { percentageVat: 9, totalVat: 1.81 }
        ];

        this.receiptHtmlCode = Buffer.from(await receiptBuilder( // Genereate Receipt Html Code based on template
          this.$store.state.activeBranch,
          this.selectedLanguage,
          receiptTemplate,
          typePayment,
          receiptType,
          selectedItems,
          usedModifiers,
          totalBruto,
          totalBrutoWithoutDiscount,
          totalDiscountVAT,
          totalNetto,
          totalVAT,
          totalVats,
          1234567890,
          'print'
        ), 'base64').toString('utf-8');

        this.loading = false;
      }
    }
  };
</script>