import axios from 'axios';
import store from "../store";
import db from "../firebase/init";

// GENERAL FUNCTION FOR CREATING LOGS

// Usage: await this.$createNewLog("info", data);

export async function createNewLog(logType, data, adminAction) { 
  let status = null;
    // await this.$createNewLog("info", data);

  if(logType && data.actionItem, data.actionType && data.action){
   await db.collection("log").add({
      time: new Date(),
      logType: logType, // Type of log (info or error)
      software: String(platform.os),
      browser: `${platform.name} ${platform.version}`,
      userName: `${store.state.activeUserFirstName} ${store.state.activeUserLastName}`,
      userId: store.state.activeUserId,
      userType: store.state.activeUserRole,
      companyId: data.superAdminAction ? store.state.activeUserCompany : store.state.activeCompany.id,
      superAdminAction: data.superAdminAction,
      actionType: data.actionType, // Type of action (userAction or systemAction )
      action: data.action, // camelCased string (read for translation) example: productEditted
      actionItem: data.actionItem, // Name of the item. Example: product name
      extra: data.extra ? data.extra : new Array() // Array with extra fields
    })
    .then(()=>{
      status = "Log saved succesfully"
    })
    .catch((error) => {
      status = "Log not saved due to an error";
      console.error("Error creating new log: ", error)
    })
  }
  else{
    status = "Not all required data present"
  }
  return status   
}
  
