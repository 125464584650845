<template>
  <div>
    <div class="tab-component-wrapper">
      <div v-if="loading">
        <div style="position: relative; padding: 200px 0;">
          <div class="thrive-loader"></div>
        </div>
      </div>

      <div v-if="!loading">
        <v-card v-if="productCategories.length > 0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
          <div class="mx-3 mt-4 pb-3">
            <v-container grid-list-md class="pa-0" fluid>
              <v-layout row wrap>
                <v-flex md6 xs12>
                  <v-text-field
                    class="mx-1"
                    v-model="search"
                    dense
                    hide-details
                    name="searchfield"
                    :label="$t('general.search', $store.state.locale)"
                    background-color="background"
                    prepend-inner-icon="mdi-magnify"
                    single-line
                    outlined
                    clearable
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12 v-if="productCategories.length > 0">
                  <div class="text-right" >
                    <v-btn 
                      dark
                      height="40px"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      @click="addProductCategory()"
                      class="mx-1"
                      >
                      <v-icon left>mdi-plus</v-icon> {{ $t('products.newProductCategory', $store.state.locale) }}
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </div>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="productCategories"
            :search="search"
            hide-default-footer
            dense
            class="dense-table"
            :itemsPerPage.sync="pages"
            :no-results-text=" `${$t('products.noResultsFound', $store.state.locale)} ${search}`"
            > 
            <template v-slot:item="{item}">
              <tr>
                <td>{{ item.name[($store.state.locale).toLowerCase()] }}</td>
                <td>
                  <v-btn icon  @click="editProductCategory(item)" >
                    <v-icon color="text" small>mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

        <div class="no-data-message" v-if="productCategories.length == 0">
          <v-avatar
            size="58"
            light
            class="ma-0 mr-2 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-flag</v-icon>
          </v-avatar>
          <p> {{ $t('products.noProductsCategoryFound', $store.state.locale) }}</p>
          <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addProductCategory()">
            <v-icon left >mdi-plus</v-icon> {{ $t('products.newProductCategory', $store.state.locale) }}
          </v-btn>
        </div>

      </div>
    </div>

    
    <!-- /  ProductCategory  \ -->
    <product-category-form @updateData="loadProductCategories()"></product-category-form>
    <!-- \ ProductCategory Dialog / -->
    
  </div>
</template>
     


<script>
  import '@firebase/firestore';
  import db from "../firebase/init";
  import { bus } from "../main";
  import ProductCategoryForm from "../components/modals/ProductCategoryForm.vue";

  export default {
    components: {
      "product-category-form": ProductCategoryForm
    },
    name: "product-categories",
    data(){
      return{
        search: "",
        editProductCategoryDialog: false,
        newProductCategoryDialog: false,
        selectedProductCategoryInfo: new Object(),
        selectedProductCategoryInfoOriginal: new Object(),
        selected: new Array(),
        pages: -1,
        loading: true,
        pagination: {
          sortBy: "name",
          rowsPerPage: 20,
          descending: false
        },
        productCategories: new Array(),
        headers: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "name"
          },
          {
            text: "",
            align: "right",
            value: "edit",
            width: "60px"
          }
        ]
      }
    },

    created() {
      this.loadProductCategories();
    },

    methods: {
      addProductCategory() {
        bus.$emit("ProductCategoryModal", null, "new");
      },

      editProductCategory(item) {
        bus.$emit("ProductCategoryModal", item, "edit");
      },

      loadProductCategories() {
        this.loading = true;
        db.collection("productCategories")
          .get()
          .then(snap => {
            this.productCategories = new Array();
            snap.forEach(doc => {
              let productCategory = doc.data();
              productCategory.id = doc.id;
              this.productCategories.push(productCategory);
            });
          })
          .then(()=>{
            this.productCategories.sort((a, b) => a.name[(this.$store.state.locale).toLowerCase()].localeCompare(b.name[(this.$store.state.locale).toLowerCase()]));
            this.loading = false;
          })
          .catch(error=>{
            console.error("Error getting productCategories: ", error)
            this.loading = false;
          })
    
      }
    },
  };
</script>
