<template>
  <div>
    <div class="tab-component-wrapper">
      <div v-if="loading">
        <div style="position: relative; padding: 200px 0">
          <div class="thrive-loader"></div>
        </div>
      </div>
      <div class="no-data-message" v-if="!loading && modifiers.length == 0">
        <p>Er zijn nog geen kortingen voor {{ $store.state.activeCompany.name }}.</p>
        <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addModifier()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <div v-if="!loading && modifiers.length > 0">
        <div v-if="deleting" class="pb-4">
          <div class="loading-dots">
            <div class="loading-dots-loader"></div>
            <p class="loading-dots-text">{{ deleteCounter }} {{ $t("conversion.of", $store.state.locale) }} {{ selected.length }} {{ $t("conversion.deleted", $store.state.locale) }}.</p>
          </div>
        </div>
        <div v-else>
          <div class="tab-component-header clear">
            <div class="tab-component-header-title"></div>
            <div class="tab-component-header-edit">
              <div class="tab-component-header-edit-button">
                <v-btn dark height="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.stop="addModifier()">
                  <v-icon left>mdi-plus</v-icon> Nieuwe korting
                </v-btn>
                <v-btn class="ml-2" fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" v-if="selected.length > 0" @click="removeModifiersDialog = true">
                  <v-icon >mdi-delete</v-icon>
                </v-btn>
              </div>
              <div class="tab-component-header-edit-search">
                <v-text-field dense v-model="search" outlined background-color="background" name="searchfield" hide-details label="Zoeken" append-icon="mdi-magnify" single-line></v-text-field>
              </div>
            </div>
          </div>

          <div class="tab-component-content no-padding">
            <v-data-table :headers="headers" :items="modifiers" v-model="selected" show-select hide-default-footer :no-results-text="`Geen resultaten gevonden voor: ${search}`" dense class="dense-table">
              <template v-slot:item="{ item, isSelected, select }">
                <tr>
                  <td>
                    <v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
                    <v-icon @click="select(false)" v-if="isSelected" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>
                    {{  $store.state.activeBranch.name }}
                    <!-- <span v-for="activeBranch in item.branches" :key="activeBranch">
                      <span v-for="branch in branches" :key="branch.id">
                        <v-chip v-if="activeBranch == branch.id" :key="index" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 my-1 custom-chip-style">{{ branch.name }}</v-chip>
                      </span>
                    </span> -->
                  </td>
                  <td>
                    
                      <span>{{ item.modifierGroup | modifierGroupName }}</span>
                    
                  </td>
                  <td>
                    <span v-for="modifierType in modifierTypes" :key="modifierType.id">
                      <span v-if="item.type == modifierType.id">{{ modifierType.name }}</span>
                    </span>
                  </td>
                  <td>
                    <span v-for="discountType in typeDiscountTypes" :key="discountType.id">
                      <span v-if="item.typeDiscount.type == discountType.id">{{ discountType.name }}</span>
                    </span>
                  </td>
                  <td>
                    <v-btn icon class="mx-0" @click="editModifier(item)">
                      <v-icon small color="text">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>

    <!-- / New Modifier Dialog \ -->
    <add-modifier-form
      :filterItemActions="filterItemActions"
      :filterProductenTypes="filterProductenTypes"
      :filterTreatmentsTypes="filterTreatmentsTypes"
      :modifierTypes="modifierTypes"
      :modifiers="modifiers"
      :typeDiscountTypes="typeDiscountTypes"
      :numberLimitTypes="numberLimitTypes"
      :discountActionTypes="discountActionTypes"
      :quantityDiscountTypes="quantityDiscountTypes"
      @updateData="loadModifiers()"
    ></add-modifier-form>
    <!-- \ New Modifier Dialog / -->


    <!-- / Delete source dialog \ -->
    <v-dialog v-model="removeModifiersDialog" width="400">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $vuetify.theme.themes[theme].primary }">
          <h2 class="white--text">
            <span style="color: #fff; text-transform: capitalize" v-if="selected.length == 1">{{ $t("financial.discount", $store.state.locale) }}</span
            ><span style="color: #fff; text-transform: capitalize" v-else>{{ $t("financial.discounts", $store.state.locale) }}</span> {{ $t("financial.delete", $store.state.locale) }}
          </h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="removeModifiersDialog = false">
            <v-icon dark color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <span style="display: block; text-align: center !important"
            >{{ $t("financial.deleteSure", $store.state.locale) }} <span v-if="selected.length == 1">{{ $t("financial.discount", $store.state.locale) }}</span
            ><span v-else>{{ $t("financial.discounts", $store.state.locale) }}</span> {{ $t("financial.deleteForever", $store.state.locale) }}</span
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed color="tertiary" @click="removeModifiersDialog = false">
                  {{ $t("general.cancel", $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark rounded :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" color="secondary" @click="deleteModifiers()">
                  {{ $t("general.remove", $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete source dialog / -->
  </div>
</template>

<script>
  import db from "../firebase/init";
  import { bus } from "../main";
  import moment from "moment";
  import AddModifierForm from "../components/modals/AddModifierForm.vue";


  export default {
    components: {
      "add-modifier-form": AddModifierForm,
    },
    name: "Modifiers",
    data: () => ({
      modifiers: new Array(),

      loading: true,
      search: "",
      selected: [],
      deleting: false,
      deleteCounter: 0,

      removeModifiersDialog: false,

      pagination: {
        sortBy: "name",
        rowsPerPage: -1,
        descending: false,
      },

      headers: [
        {
          text: "Naam",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Vestigingen",
          align: "left",
          value: "branches",
        },
         {
          text: "Kortingsgroep",
          align: "left",
          value: "modifierGroup",
        },
        {
          text: "Toepassing op",
          align: "left",
          value: "type",
        },
        {
          text: "Configuratie",
          align: "left",
          value: "typeDiscount",
        },
        {
          text: "",
          align: "left",
          value: "edit",
          width: "60px",
        },
      ],
      filterItemActions: [
        {
          name: "Komt overeen met selectie",
          id: "include",
        },
        {
          name: "Kom niet overeen met selectie",
          id: "exclude",
        },
      ],
      filterProductenTypes: [
        {
          name: "Specifieke producten",
          id: "specific",
        },
        {
          name: "Filter op merk",
          id: "brands",
        },
        {
          name: "Filter op categorie",
          id: "categories",
        },
      ],
      filterTreatmentsTypes: [
        {
          name: "Specifieke behandelingen",
          id: "specific",
        },
        {
          name: "Filter op categorie",
          id: "categories",
        },
      ],
      modifierTypes: [
        {
          name: "Producten en behandelingen",
          id: "all",
        },
        {
          name: "Alleen producten",
          id: "product",
        },
        {
          name: "Alleen behandelingen",
          id: "treatment",
        },
      ],
      typeDiscountTypes: [
        {
          name: "Vastgestelde korting",
          id: "fixedAmount",
        },
        // { Legacy
        //   name: "Percentage",
        //   id: "percentage",
        // },
        {
          name: "Bundelkorting",
          id: "quantityDiscount",
        },
        {
          name: "Koop X krijg X",
          id: "buyXX",
        },
        {
          name: "Koop X krijg Y",
          id: "buyXY",
        },
      ],
      quantityDiscountTypes: [
        {
          name: "Bedrag",
          id: "amount",
        },
        {
          name: "Percentage",
          id: "percentage",
        },
      ],
      discountActionTypes: [
        {
          name: "Bedrag",
          id: "amount",
        },
        {
          name: "Percentage",
          id: "percentage",
        },
        {
          name: "Gratis",
          id: "free",
        },
      ],
      numberLimitTypes: [
        {
          id: "max",
          name: "Maximaal gebruik van korting",
        },
        {
          id: "maxClient",
          name: "Maximaal gebruik van korting per cliënt",
        },
      ],
    }),

    created() {
      bus.$on("companyUpdate", this.loadData);
      this.loadData();
    },

    filters: {
      modifierGroupName: function (value) {

        //  {id: "defaultModifier", name: "Algemene korting"},
        //   {id: "membershipModifier", name: "Abonnementskorting"},
        //   {id: "loyaltyModifier", name: "Spaarpunten"}

        //TODO Multilanguage support
        if(value === "defaultModifier" ) {
          
          return "Standaard";
        } else if(value ==="membershipModifier") {
          return "Abonnementskorting";
        } else if(value ==="loyaltyModifier") {
          return "Spaarpunten";
        } else {
          return "Onbekend";
        }


        
      }
    },

    methods: {
      loadData() {
        this.loading = true;
        this.loadModifiers();
      },

      async loadModifiers() {
        await db.collection("modifiers")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("active", "==", true)
        .where("deleted", "==", false)
        .get()
        .then((snap) => {
          this.modifiers = [];
          snap.forEach((doc) => {
            let modifier = doc.data();
            modifier.id = doc.id;
            if(!modifier.barcode) {
              modifier.barcode = {
                type: 'undefined',
                code: null
              }
            }
            this.modifiers.push(modifier);
          });
        })
        this.loading = false;
      },

      addModifier() {
        bus.$emit("addModifierModal");
      },

      editModifier(item) {
        bus.$emit("editModifierModal", _.cloneDeep(item));
      },

      deleteModifiers() {
        this.removeModifiersDialog = false;
        this.deleting = true;
        this.deleteCounter = 0;
        this.selected.forEach((element) => {
          db.collection("modifiers")
            .doc(element.id)
            .set(
              {
                deleted: {
                  deleted: true,
                  deletedBy: this.userFirstName + " " + this.userLastName, //Dynamisch maken via Rollen systeem
                  deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
                },
              },
              { merge: true }
            )
            .then(() => {
              this.deleteCounter++;
              if (this.deleteCounter == this.selected.length) {
                this.deleting = false;
                this.selected = new Array();
                this.loadModifiers();
              }
            });
        });
      },
    },

    computed: {
      companyName() {
        return this.$store.state.companyName;
      },
      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        if(this.$store.state.companyTheme){
          return this.$store.state.companyTheme;
        }
        else{
          return "thrive"
        }
      }
    },
  };
</script>
