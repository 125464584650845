<template>
  <div>
    <div v-if="loading" style="position: relative; padding: 200px 0;">
      <div class="thrive-loader"></div>
    </div>
    <div v-show="!loading">
      <v-card v-if="devices.length > 0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
        <v-container grid-list-md class=" ma-0 pa-3" fluid>
          <v-layout row wrap>
            <v-flex md4 xs12>
              <v-text-field
                class="mx-1"
                v-model="search"
                name="searchfield"
                :label="$t('general.search', $store.state.locale)"
                background-color="background"
                prepend-inner-icon="mdi-magnify"
                single-line
                dense
                hide-details
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex md8 xs12 class="text-right">
              <v-btn 
                dark
                height="36"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addNewDevice()"
                class="mx-1"
                >
                <v-icon left>mdi-plus</v-icon> {{ $t('devices.newDevice', $store.state.locale) }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider> </v-divider>

        <v-data-table
          :headers="headers"
          :items="devices"
          :search="search"
          class="dense-table devices-table"
          dense
          hide-default-footer
          :itemsPerPage.sync="pages"
          :no-results-text="`${$t('devices.noResultsFoundFor', $store.state.locale)} ${search}`"
          > 
          <template v-slot:item="{item, index}" >
            <tr :key="watcher + index">
              <td class="grap">
                <v-icon size="20" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-arrow-all</v-icon>
              </td> 
              <td>{{ item.name }}
                <div>
                  <v-chip v-if="!$store.state.demoMode && $store.state.userRoleNumber == 0" dark label small  light class="mr-2 mb-1 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="copyVariableToClipboard( item.id, index )">
                    <span v-if="!item.copy">{{ item.id }}</span><span v-else>{{ $t('emails.copied', $store.state.locale)}}</span><v-icon v-if="!item.copy" right size="12">mdi-content-copy</v-icon>
                  </v-chip>
                </div>
              </td>
              <td v-if="!loading">
                <v-chip v-for="(device, index) in item.deviceTypes" :key="index" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" light class="mr-2 my-1 custom-chip-style">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, deviceTypeName(device)) }}</v-chip>
              </td>
              <td v-if="!loading">
                <v-chip v-for="(room, index) in item.rooms" :key="index" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mr-2 my-1 custom-chip-style">{{ roomName(room) }}</v-chip>
              </td>

              <td class="text-center">
                <v-icon :size="14" class="mt-0 px-2" :color="activeColor(item.active)" >mdi-checkbox-blank-circle</v-icon>
              </td>
              <td class="text-center">
                <v-btn icon class="mx-0 mr-1" @click="editDevice(item)">
                  <v-icon small color="text">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon class="mx-0" @click="deleteDevice.dialog = true; deleteDevice.data = item;">
                  <v-icon small color="text">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <div class="no-data-message" v-else>
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-power-plug</v-icon>
        </v-avatar>
        <p>{{ $t('devices.noResultsFound', $store.state.locale) }} {{ $store.state.activeBranch.name }} {{ $t('devices.added', $store.state.locale) }}</p>
        <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addNewDevice()">
          <v-icon left>mdi-plus</v-icon>{{ $t('devices.newDevice', $store.state.locale) }} 
        </v-btn>
      </div>
    </div>

    <!-- / New Device Dialog \ -->
      <add-device-form
        :nextResourceWeight="nextResourceWeight"
        :nextResourceId="nextResourceId"
        :rooms="rooms"
        :deviceTypes="deviceTypes"
        :branches="branches"
        :activeBranches="activeBranches"
        :abilities="abilities"
        @updateData="loadDevices()"
      ></add-device-form>  
    <!-- \ New Device Dialog / -->

    <!-- / Edit Device Dialog \ -->
    <v-dialog v-model="editDeviceDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <edit-device-form
        :rooms="rooms"
        :deviceTypes="deviceTypes"
        :selectedDevice="selectedDeviceInfo"
        :selectedDeviceOriginal="selectedDeviceInfoOriginal"
        @editDeviceDialogChange="editDeviceDialog = $event"
        @updateData="loadDevices()"
      >></edit-device-form>
    </v-dialog>
    <!-- \ Edit Device Dialog / -->

    <!-- / Delete Room Dialog \ -->
    <v-dialog v-model="deleteDevice.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <h3 class="mb-4">{{ $t('devices.removeDevice', $store.state.locale) }}</h3>
          <p class="ma-0">{{ $t('devices.removeDeviceInfo', $store.state.locale) }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="deleteDevice.deleting" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="deleteDevice.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="deleteDevice.deleting" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleteSelectedDevice()">
                   {{ $t('general.delete', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Room Dialog / -->

  </div>
</template>

<script>
import db from "@/firebase/init";
import { bus } from "@/main";
import Sortable from "sortablejs";
import draggable from "vuedraggable";
import AddDeviceForm from "@/components/modals/AddDeviceForm.vue";
import EditDeviceForm from "@/components/modals/EditDeviceForm.vue";
export default {
  components: {
    "add-device-form": AddDeviceForm,
    "edit-device-form": EditDeviceForm
  },
  name: "Devices",
  data(){
    return{
      watcher: 0,
      devices: new Array(),
      branchDevices: new Array(),
      deviceTypes: new Array(),
      selectedDeviceInfo: {
        branches: new Array(),
        defaultDays: {
          Sun: false,
          Mon: false,
          Tue: false,
          Wed: false,
          Thu: false,
          Fri: false,
          Sat: false
        }
      },
      deleteDevice: { 
        dialog: false,
        data: null,
        deleting: false,
      },
      sortBy: 'weight',
      pages: -1,
      selectedDeviceInfoOriginal: new Object(),
      search: "",
      editDeviceDialog: false,
      selected: new Array(),
      abilities: new Array(),
      companies: new Array(),
      branches: new Array(),
      rooms: new Array(),
      nextDeviceId: null,
      nextResourceId: null,
      nextResourceWeight: 0,
      activeBranches: new Object(),
      pagination: {
        rowsPerPage: "200"
      },
      headers: [
        {
          text: "",
          align: "left",
          value: "weight",
          sortable: false,
          width: "60px"
        },
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "name",
          width: "250px",
          sortable: false
        },
        {
          text: this.$t('devices.functions', this.$store.state.locale),
          align: "left",
          value: "deviceTypes",
          sortable: false
        },
        {
          text: this.$t('devices.rooms', this.$store.state.locale),
          align: "left",
          value: "rooms",
          sortable: false
        },
        {
          text: this.$t('general.active', this.$store.state.locale),
          align: "center",
          value: "active",
          sortable: false,
          width: "100px"
        },
        {
          text: "",
          align: "left",
          value: "delete",
          sortable: false,
          width: "120px"
        }
      ],
      valid: false,
      loading: false
    }
  },

  created() {
    // bus.$on("branchChanged", this.loadData);
    this.loadData();
  },

  mounted() {
    this.$root.$on("branchChanged", () => {
      this.loadData();
    });
  },

  methods: {
    loadData() {
      this.loading = true;
      Promise.all([
        this.loadDeviceTypes(), 
        this.loadBranches(), 
        this.loadDevices(),
      ])
      .then(() => {
        this.loading = false;
      })
    },

    closeDeviceDialog() {
      this.editDeviceDialog = false;
    },

    addNewDevice(){
      bus.$emit('addNewDevice', this.$store.state.activeBranch.id);
    },

    roomName(roomId){
      return this.rooms.find(room => room.id == roomId).name
    },

    deviceTypeName(device){
      return this.deviceTypes.find(type => type.id == device).name
    },

    opyVariableToClipboard(text, index){
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(()=> {
        this.devices.forEach(device =>{
          device.copy = false;
        })
        this.devices[index].copy = true;
        setTimeout(()=>{ 
          this.devices[index].copy = false;
        }, 1000);

      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },


    async loadDeviceTypes() {
      this.loading = true;
      await db.collection("deviceTypes")
      .get()
      .then(snap => {
        this.deviceTypes = new Array();
        snap.forEach(doc => {
          let deviceType = doc.data();
          deviceType.id = doc.id;
          this.deviceTypes.push(deviceType);
        });
      })
      return
    },

    async loadBranches() {
      await db.collection("branches")
      .doc(this.$store.state.activeBranch.id)
      .get()
      .then(doc => {
        this.branches = new Array();
        let branch = doc.data();
        branch.id = doc.id;
        branch.inactive = false;
        this.branches.push(branch);
      })
      return
    },

    activeColor(active){ // Set color green for active or red for inactive
      return active ? "#81C784" : "#EF5350"; 
    },

    async loadDevices() {
      this.loading = true;
      this.devices = new Array();
      this.resources = new Array();
      this.branchDevices = new Array();
      await db.collection("devices")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("branches", "array-contains", this.$store.state.activeBranch.id)
      .orderBy("weight")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          if(!doc.data().resourceDeleted) {
            let device = doc.data();
            device.id = doc.id;
            device.copy = false;
            this.devices.push(device);
          }
        });
      })
      
      await this.loadRooms();
      if (this.devices.length > 1) {
        this.devices.sort(this.sortDevices);
        this.orderDevices();
      }
      this.loading = false;
    },

    sortDevices(a, b) {
      const genreA = a.weight;
      const genreB = b.weight;

      let comparison = 0;
      if(genreA > genreB) {
        comparison = 1;
      } else if (genreA < genreB) {
        comparison = -1;
      }
      return comparison;
    },

    orderDevices() {
      let element = document.querySelector(".devices-table tbody");
      const vm = this;
      Sortable.create(element, {
        handle: ".grap",
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = vm.devices.splice(oldIndex, 1)[0]; // Get the selected row and remove it
          vm.devices.splice(newIndex, 0, rowSelected); // Move it to the new index
          vm.updateOrderDevices();
        }
      });
    },

    updateOrderDevices() {
      this.watcher++
      let devices = this.devices;
      devices.forEach(item => {
        let index = devices.indexOf(item);
        this.devices[index].weight = index;
        db.collection("devices") // Post new weight
        .doc(item.id)
        .update({
          weight: index
        });
      });
      this.devices.sort(this.sortDevices);
    },

    async loadRooms() {
      await db.collection("rooms")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("branches", "array-contains", this.$store.state.activeBranch.id)
      .get()
      .then(snap => {
        this.rooms = new Array();
        snap.forEach(doc => {
          let room = doc.data();
          room.id = doc.id;
          this.rooms.push(room);
        });
      })
      this.loading = false;
    },

    editDevice(item) {
      this.selectedDeviceInfo =  _.cloneDeep(item);
      this.selectedDeviceInfoOriginal = _.cloneDeep(this.selectedDeviceInfo);
      this.editDeviceDialog = true;
    },

    async deleteSelectedDevice() {
      this.deleteDevice.deleting = true;
      await db.collection("devices")
      .doc(this.deleteDevice.data.id)
      .update({
        resourceDeleted: true,
        deleted: {
          deletedTime: new Date(),
          deletedby: `${this.userFirstName} ${this.userLastName} (${this.userCompany})`
        }
      })
      .catch((error) => {
        console.error("Error on removing device: ", error);
      })
      await db.collection("log") // Log Action    
      .add({
        time: new Date(),
        logType: "info",
        browser: `${platform.name} ${platform.version}`,
        user: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
        userType: this.$store.state.activeUserRole,
        company: this.$store.state.userCompany,
        actionType: "userAction",
        action: "deviceDeleted",
        actionItem: this.deleteDevice.data.name,
        extra:[
          {
            name: "name",
            value: this.deleteDevice.data.name
          }
        ]
      })
      .catch((error) => {
        console.error("Error on logging removing device: ", error);
      });
      this.loadDevices();
      this.deleteDevice = {
        dialog: false,
        data: null,
        deleting: false
      };
    },
  },
};
</script>
