<template>
  <v-dialog v-model="newDevice.dialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3>{{ $t('devices.addDevice', $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeAddDeviceDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text class="ma-0">
        <v-form ref="AddDeviceForm" lazy-validation>
          <v-text-field 
            :label="$t('general.name', $store.state.locale)" 
            v-model.trim="newDevice.name" 
            autocomplete 
            required
            dense
            outlined
            class="mt-4"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            hide-details
          ></v-text-field>

          <v-select
            clearable
            multiple
            chips
            dense
            deletable-chips
            small-chips
            :items="deviceTypes"
            :item-text="(item) => $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) "
            item-value="id"
            :label="$t('devices.deviceType', $store.state.locale)" 
            v-model="newDevice.deviceTypes"
            outlined
            class="mt-4"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            hide-details
          ></v-select>

          <v-select
            clearable
            :items="rooms"
            item-text="name"
            item-value="id"
            :label="$t('general.room', $store.state.locale)" 
            v-model="newDevice.rooms"
            class="mt-4"
            outlined
            multiple
            chips
            dense
            deletable-chips
            small-chips=""
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            hide-details
          ></v-select>

          <v-select
            :items="$store.state.activeUserBranches"
            item-text="name"
            item-value="id"
            item-disabled="inactive"
            v-model="newDevice.branches[0]"
            :label="$t('devices.selectBranch', $store.state.locale)" 
            class="mt-4"
            outlined
            dense
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            hide-details
          ></v-select>
          
          <v-card class="mt-4" outlined color="background">
            <v-container class="pa-4 ma-0 ">
              <h3>{{ $t('devices.defaultDays', $store.state.locale) }}</h3>
              <v-layout row wrap class="pa-0 ma-0" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">
                <v-flex xs3 class="py-2"><strong>{{ $t('general.day', $store.state.locale) }}</strong></v-flex>
                <v-flex xs9 class="py-2"><strong>{{ $t('general.available', $store.state.locale) }}</strong></v-flex>
                <v-flex xs3>{{ $t('general.sunday', $store.state.locale) }}</v-flex>
                <v-flex xs9>
                  <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newDevice.defaultDays.Sun"></v-switch>
                </v-flex>
                <v-flex xs3>{{ $t('general.monday', $store.state.locale) }}</v-flex>
                <v-flex xs9>
                  <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newDevice.defaultDays.Mon"></v-switch>
                </v-flex>
                <v-flex xs3>{{ $t('general.tuesday', $store.state.locale) }}</v-flex>
                <v-flex xs9>
                  <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newDevice.defaultDays.Tue"></v-switch>
                </v-flex>
                <v-flex xs3>{{ $t('general.wednesday', $store.state.locale) }}</v-flex>
                <v-flex xs9>
                  <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newDevice.defaultDays.Wed"></v-switch>
                </v-flex>
                <v-flex xs3>{{ $t('general.thursday', $store.state.locale) }}</v-flex>
                <v-flex xs9>
                  <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newDevice.defaultDays.Thu"></v-switch>
                </v-flex>
                <v-flex xs3>{{ $t('general.friday', $store.state.locale) }}</v-flex>
                <v-flex xs9>
                  <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newDevice.defaultDays.Fri"></v-switch>
                </v-flex>
                <v-flex xs3>{{ $t('general.saturday', $store.state.locale) }}</v-flex>
                <v-flex xs9>
                  <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newDevice.defaultDays.Sat"></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>

          <v-switch
            :label="$t('general.active', $store.state.locale)"  
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            v-model="newDevice.active"
            hide-details
          ></v-switch>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeAddDeviceDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addDevice()"
                >
                {{ $t('devices.addDeviceButton', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import db from "../../firebase/init";
import { bus } from "../../main";
export default {
  name: "Add-Device",
  props: {
    deviceTypes: Array,
    rooms: Array,
    nextResourceWeight: Number,
  },
  data() {
    return {
      feedback: null,
      newDevice: {
        name: "",
        dialog: false,
        branch: null,
        branches: [],
        deviceTypes: [],
        active: true,
        rooms: [],
        defaultDays: {
          Sun: false,
          Mon: false,
          Tue: false,
          Wed: false,
          Thu: false,
          Fri: false,
          Sat: false
        }
      }
    };
  },
  created(){
    bus.$on("addNewDevice",(activeBranchId)=>{
      this.newDevice.branches = new Array();
      this.newDevice.branches.push(activeBranchId);
      this.newDevice.dialog = true;
    })
  },

  methods: {
    closeAddDeviceDialog() {
      this.newDevice.name = "";
      this.newDevice.dialog = false;
      this.newDevice.defaultDays = {
          Sun: false,
          Mon: false,
          Tue: false,
          Wed: false,
          Thu: false,
          Fri: false,
          Sat: false
        }
    },
    

    setBranches() {
      this.newDevice.branches = new Array();
      this.newDevice.branches.push(this.$store.state.activeBranch.id);
    },

    async ddDevice() {
      this.feedback = null;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "deviceAdded",
        actionItem: this.newDevice.name,
        extra:[
          {
            name: "name",
            value: this.newDevice.name
          },
          {
            name: "companyId",
            value: this.$store.state.activeCompany.id
          },
          {
            name: "branches",
            value: this.newDevice.branches
          },
          {
            name: "deviceTypes",
            value: this.newDevice.deviceTypes
          },
          {
            name: "rooms",
            value: this.newDevice.rooms
          },
          {
            name: "defaultDays",
            value: JSON.stringify(this.newDevice.defaultDays)
          },
          {
            name: "active",
            value: this.newDevice.active
          },
        ]
      };

      if (this.newDevice.name) {
        try {
          await db.collection("devices")
          .add({
            name: this.newDevice.name,
            active: this.newDevice.active,
            companyId: this.$store.state.activeCompany.id,
            deviceTypes: this.newDevice.deviceTypes,
            rooms: this.newDevice.rooms,
            branches: this.newDevice.branches,
            defaultDays: this.newDevice.defaultDays,
            weight: this.nextResourceWeight,
            resourceDeleted: false
          })

          await this.$createNewLog("info", logData);

          this.closeAddDeviceDialog();
          this.$emit("updateData");
          this.$refs.AddDeviceForm.reset();
          this.newDevice = {
            branches: new Array(),
            active: true,
            defaultDays: {
              Sun: false,
              Mon: false,
              Tue: false,
              Wed: false,
              Thu: false,
              Fri: false,
              Sat: false
            }
          };
        }
        catch (error) {
          this.feedback = this.$t('general.addError', this.$store.state.locale);
          console.error("Error adding Device: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }     
      }
      else {
        this.feedback = this.$t('device.required', this.$store.state.locale);
      }
    }
  }
};
</script>
