
<template>
  <div>
    <v-dialog
      v-model="editEmailDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t('emails.editEmail', $store.state.locale) }}</h2>
          <v-spacer></v-spacer>
          <div class="text-right px-4 pt-0" v-if="savingItem" :style="{'color' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>mdi-content-save</v-icon>{{ $t('emails.saving', $store.state.locale) }}
          </div>
          <div class="text-right px-4 pt-0" v-if="unsafedChanges" :style="{'color' : $themes[$store.state.companyTheme][$store.state.themeModus].text }">
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small>mdi-content-save</v-icon> {{ $t('emails.unsavedChanges', $store.state.locale) }}
          </div>
          <v-btn small dark depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mx-2" @click="saveEmailChanges()"><v-icon ledt>mdi-save</v-icon> {{ $t('general.save', $store.state.locale) }}</v-btn>
          <v-btn small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" @click="closeEmailEditor()">{{ $t('general.close', $store.state.locale) }}</v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-4 ma-0" style="position: relative" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }">

          <div v-if="loading">
            <div style="position: relative; padding: 200px 0;">
              <div class="thrive-loader"></div>
            </div>
          </div>
        
          <div class="pa-0" v-if="!loading">
            <v-container grid-list-md class="pa-0 ma-0" fluid grow>

              <v-layout row wrap>
                <v-flex md8 xs12>
                  <v-btn @click="previewEmail()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark ><v-icon left>mdi-eye</v-icon>{{ $t('emails.preview', $store.state.locale) }}</v-btn>
                  <h3 class="mb-2" style="width: 100%; margin-top: 37px!important">{{ $t('emails.design', $store.state.locale) }}</h3>
                  <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 ma-0 mr-5 email-card " :class="$store.state.companyTheme" style="overflow: visible">
                    <draggable id="email-wrapper" class="drag-wrapper remove-all-styling" v-model="emailDesign.children[0].children" ghost-class="ghost" handle=".move-element" :group="{ name: 'items' }" @start="drag=true" @end="drag=false" :class="{'draging' : drag}">
                      <div v-for="(element, index) in emailDesign.children[0].children" :key="'row-'+index" class="email-row" :class="{'selected' : selectedElement(element.uniqId)}" :style="[{'backgroundColor' : element.attributes['background-color']},{'padding' : element.attributes['padding']}, { 'width': element.fullWidth ? '100%': '600px'}]"> 
                        <div class="email-row-edit">
                          <div class="email-row-edit-item move-element">
                            <v-icon dark size="18">mdi-arrow-all</v-icon>
                          </div>
                          <v-tooltip dark top>
                            <template v-slot:activator="{ on }">
                              <div v-on="on" class="email-row-edit-item">
                                <v-icon v-if="element.show == 'all'"  dark size="18">mdi-eye</v-icon>
                                <v-icon v-else dark size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-eye</v-icon>
                              </div>
                            </template>
                            <span style="font-size: 12px">{{ getShowType(element.show) }} </span>
                          </v-tooltip>
                          <div class="email-row-edit-item" @click="selectEmailItem(element, element.uniqId)">
                            <v-icon dark size="18">mdi-pencil</v-icon>
                          </div>  
                          <v-tooltip dark top>
                            <template v-slot:activator="{ on }">
                              <div v-on="on" class="email-row-edit-item" @click="duplicateEmailItem(element, index)">
                                <v-icon dark size="18">mdi-content-copy</v-icon>
                              </div>
                            </template>
                            <span style="font-size: 12px">{{ $t('emails.duplicateRow', $store.state.locale) }}</span>
                          </v-tooltip>
                          <v-tooltip dark top>
                            <template v-slot:activator="{ on }">
                              <div v-on="on" class="email-row-edit-item" @click="saveEmailItemDialog(element)">
                                <v-icon dark size="18">mdi-content-save</v-icon>
                              </div>
                            </template>
                            <span style="font-size: 12px">{{ $t('emails.saveRow', $store.state.locale) }}</span>
                          </v-tooltip>
                          <v-tooltip dark top>
                            <template v-slot:activator="{ on }">
                              <div v-on="on" class="email-row-edit-item" @click="removeEmailItemDialog(element)">
                                <v-icon dark size="18">mdi-delete</v-icon>
                              </div>
                            </template>
                            <span style="font-size: 12px;">{{ $t('emails.removeRow', $store.state.locale) }}</span>
                          </v-tooltip>  
                        </div>

                        <div class="pa-0 editor-row-wrapper" @click.stop="selectEmailItem(element, element.uniqId)">
                          <v-container v-if="emailDesign.children[0].children[index].type == 'row'" class="pa-0 editor" style="max-width: 600px; margin: 0 auto; position: relative" >
                            <v-layout row wrap class="pa-0 ma-0">

                              <v-flex  v-for="(column, columnIndex) in emailDesign.children[0].children[index].children" :key="'col-'+columnIndex"  class="email-column" :class="[{'selected' : selectedElement(column.uniqId)}, 'xs'+ 12/(emailDesign.children[0].children[index].children.length)]" @click.stop="selectEmailItem(column, element.uniqId)">
                              
                                  <div :style="[{'backgroundColor' : column.attributes['background-color']},{'border' : column.attributes['border']},{'padding' : column.attributes['padding']},{'fontFamily' : column.attributes['font-family']}]">
                                    <div class="email-column-edit">
                                      <div class="email-column-edit-item" @click.stop="selectEmailItem(column, element.uniqId, column.uniqId)">
                                        <v-icon dark size="18">mdi-pencil</v-icon>
                                      </div>  
                                    </div>

                                    <draggable class="drag-row-wrapper" v-model="emailDesign.children[0].children[index].children[columnIndex].children" handle=".move-element" ghost-class="ghost" :move="moveModule" :group="{ name: 'items' }" :class="{'email-empty-column': emailDesign.children[0].children[index].children[columnIndex].children.length == 0}">
                                      
                                      <div v-for="(module, subIndex) in emailDesign.children[0].children[index].children[columnIndex].children" :key="subIndex" class="email-module" :class="{'selected' : selectedElement(module.uniqId)}">
                                        
                                          <div class="email-module-edit">
                                            <div class="email-module-edit-item move-element">
                                              <v-icon dark size="18">mdi-arrow-all</v-icon>
                                            </div>
                                            <div class="email-module-edit-item" @click.stop="selectEmailItem(module, element.uniqId, column.uniqId)">
                                              <v-icon dark size="18">mdi-pencil</v-icon>
                                            </div> 
                                            <v-tooltip dark top>
                                              <template v-slot:activator="{ on }">
                                                <div v-on="on" class="email-module-edit-item" @click="duplicateEmailItem(module, index, columnIndex, subIndex)">
                                                  <v-icon dark size="18">mdi-content-copy</v-icon>
                                                </div>
                                              </template>
                                              <span style="font-size: 12px">{{ $t('emails.duplicateRow', $store.state.locale) }}</span>
                                            </v-tooltip>
                                            <v-tooltip dark top>
                                              <template v-slot:activator="{ on }">
                                                <div v-on="on" class="email-module-edit-item" @click="saveEmailItemDialog(module)">
                                                  <v-icon dark size="18">mdi-content-save </v-icon>
                                                </div>
                                              </template>
                                              <span style="font-size: 12px">{{ $t('emails.duplicateRow', $store.state.locale) }}</span>
                                            </v-tooltip>
                                            <v-tooltip dark top>
                                              <template v-slot:activator="{ on }">
                                                <div v-on="on" class="email-module-edit-item" @click="removeEmailItemDialog(module, index, columnIndex)">
                                                  <v-icon dark size="18">mdi-delete</v-icon>
                                                </div>
                                              </template>
                                              <span style="font-size: 12px;">{{ $t('emails.duplicateRow', $store.state.locale) }}</span>
                                            </v-tooltip>
                                          </div>

                                          <div class="email-item-content" :class="module.attributes['css-class']" v-if="module.id == 'text'" v-html="module.content" :style="[{'padding' : module.attributes['padding']},{'backgroundColor' : module.attributes['background-color']}, {'color' : module.attributes.color}, {'fontFamily' : module.attributes['font-family']}, {'fontSize' : module.attributes['font-size']}, {'fontWeight' : module.attributes['font-weight']}, {'lineHeight' : module.attributes['line-height']}, {'textTransform' : module.attributes['text-transform']}, {'textAlign' : module.attributes['align']}]"></div>
                                          <div class="email-item-content" :class="module.attributes['css-class']" v-if="module.id == 'spacer'" style="width: 100%" :style="[{'padding' : module.attributes['padding']},{'backgroundColor' : module.attributes['container-background-color']},{'height' : module.attributes['height']}]"></div>
                                          <div class="email-item-content" :class="module.attributes['css-class']" v-if="module.id == 'divider'"  :style="[ {'textAlign' : module.attributes['align']}]">
                                            <div style="display: inline-block" :style="[{'border' : module.attributes['border']},{'padding' : module.attributes['padding']},{'backgroundColor' : module.attributes['container-background-color']},{'width' : module.attributes['width']}]"></div>
                                          </div>
                                          <div class="email-item-content" :class="module.attributes['css-class']" v-if="module.id == 'html'" v-html="module.content" :style="[{'padding' : module.attributes['padding']}]"></div>
                                          <div class="email-item-content" :class="module.attributes['css-class']" v-if="module.id == 'button'" :style="[ {'textAlign' : module.attributes['align']},{'padding' : module.attributes['padding']}]">
                                            <!-- style="text-decoration: none; text-align: center; margin: 0 auto;"  -->
                                            <a v-html="module.content" style="display: inline-block" :style="[{'borderRadius' : module.attributes['border-radius']},{'border' : module.attributes['border']},{'href' : module.attributes['href']},{'target' : module.attributes['target']},{'rel' : module.attributes['rel']}, {'width' : module.attributes['width']}, {'title' : module.attributes['width']},{'textAlign' : module.attributes['text-align']}, {'textDecoration' : module.attributes['text-decoration']},{'padding' : module.attributes['inner-padding']}, {'backgroundColor' : module.attributes['background-color']}, {'color' : module.attributes.color + '!important'}, {'align' : module.attributes.align}, {'fontFamily' : module.attributes['font-family']}, {'fontSize' : module.attributes['font-size']}, {'fontWeight' : module.attributes['font-weight']}, {'lineHeight' : module.attributes['line-height']}, {'textTransform' : module.attributes['text-transform']}]"></a>
                                          </div>
                                          <div class="email-item-content" :class="module.attributes['css-class']" v-if="module.id == 'image'" :style="[{'text-align' : module.attributes.align}, {'padding' : module.attributes['padding']}]">
                                            <img v-if="module.attributes.src && module.attributes.src != '[branchLogoUrl]' && module.attributes.src != '[vestigingsLogoUrl]'" :src="module.attributes.src" :style="[{'width' : module.attributes.width}, {'borderRadius' : module.attributes['border-radius']},{'border' : module.attributes['border']}]"/>
                                            <div v-else-if="module.attributes.src == '[branchLogoUrl]' || module.attributes.src == '[vestigingsLogoUrl]'" class="pa-3 text-center">
                                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="66">mdi-rhombus</v-icon>
                                              <div><strong>{{ module.imageName }}</strong></div>
                                            </div>
                                            
                                            <div v-else class="pa-3 text-center" style="background-color: #f9f9f9 ">
                                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="66">mdi-image</v-icon>
                                              <div><strong>Drop image</strong></div>
                                              <div class="pa-2">or</div>
                                              <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark depressed @click="openMediaLibrary(), selectEmailItem(module, element.uniqId, column.uniqId)">Select from library</v-btn>
                                            </div>
                                          </div>

                                          <div class="email-item-content" :class="module.attributes['css-class']" v-if="module.id == 'receipt'" v-html="module.value" :style="[{'padding' : module.attributes['padding']},{'backgroundColor' : module.attributes['background-color']}]"></div>

                                        </div> 
                                      

                                        <div v-if="emailDesign.children[0].children[index].children[columnIndex].children.length == 0" class="empty-column-content">
                                          Drop <strong>Blocks</strong> here.
                                        </div>
                                    </draggable>
                                  </div>
                       
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </div> 
                      </div>

                    </draggable>
                  </v-card>

                </v-flex>
                <v-flex md4 xs12 class="fill-height">
                  <v-tabs class="mb-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" show-arrows v-model="tab" @change="tabChange()" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
                    <v-tab href="#general">General</v-tab>
                    <v-tab href="#add">Add</v-tab>
                    <v-tab href="#edit" :disabled="!selectedItem">Edit</v-tab>
                    <v-tab href="#saved">Saved</v-tab> 
                  </v-tabs>

                  <v-tabs-items v-model="tab" class="transparant-tabs">   
                    <v-tab-item  value="general" :transition="tabTransition" :reverse-transition="tabTransition">
                      <h3 class="mb-2">General information and settings</h3>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-2 ma-0" :class="$store.state.companyTheme" style="width: 100%">
                        <div class="pa-4"> <!-- Email type  -->
                          <h5 class="pb-1">{{ $t('emails.emailType', $store.state.locale) }}</h5>
                          <v-select
                            disabled
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :items="emailTypes"
                            :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            v-model="selectedMessage.type"
                          ></v-select>
                        </div>

                        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader"  class="ma-4 pa-2">
                          <h4>{{ $t('emails.variables', $store.state.locale) }}</h4>
                          <div style="display: inline-block; margin: 2px 2px;" v-for="(variable, index) in emailVariablesFiltered" :key="index">
                            <v-chip label small :color="variable.type == 'text' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  :text-color="variable.type == 'text' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mr-1 mt-1 custom-chip-style">{{ variable.name[($store.state.locale).toLowerCase()] }}</v-chip>
                            <div v-if="variable.copy == false" class="copy-tag-url" @click="copyVariableToClipboard(variable.name, index)"><v-icon small>mdi-content-copy</v-icon> {{ $t('emails.copy', $store.state.locale) }}</div>
                            <div v-else class="copy-tag-url copied">{{ $t('emails.copied', $store.state.locale) }}</div>
                          </div>
                        </v-card>
                        
                        <div class="pa-4 pt-0"> <!-- Email Subject   -->
                          <h5 class="pb-1">{{ $t('emails.emailType', $store.state.locale) }}</h5>
                          <v-select
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :items="languages"
                            item-text="name"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            v-model="selectedPreviewLanguage"
                          ></v-select>
                          <v-text-field
                            class="mt-4"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                            dense
                            outlined
                            hide-details
                            v-model="selectedMessage.subject[selectedPreviewLanguage]"
                          ></v-text-field>
                        </div>

                        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader"  class="ma-4 pa-2" v-if="emailHeader && emailHeader.children && emailHeader.children.length > 0">
                          <h4>Custom CSS</h4>
                          <v-textarea 
                            outlined 
                            hide-details
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            class="mt-2" 
                            v-model="emailHeader.children[0].content">
                          </v-textarea>
                        </v-card>
                      </v-card>

                    </v-tab-item>  
                    <v-tab-item  value="add" :transition="tabTransition" :reverse-transition="tabTransition">
                      <h3 class="mb-2">Rows</h3>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-2 ma-0" :class="$store.state.companyTheme" style="width: 100%">
                        <v-container grid-list-md class="pa-0 ma-0" fluid >
                          <draggable
                            class="dragArea list-group"
                            :list="rows"
                            :clone="clone"
                            :move="moveRow"
                            :sort="false"
                            :group="{ name: 'items', pull: 'clone', put: false }"
                            style="width: 100%; inline-block"
                            >
                            <v-card flat outlined v-for="(row, index) in rows"  :style="{ 'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].text + ''}"  :key="index" class="pa-1 ma-2 text-center email-editor-button" style="border-style: dotted!important; border-width: 2px!important;">
                              <v-layout row wrap>
                                <v-flex  v-for="n in row.children.length" :key="n" :class="12/n">
                                  <v-card flat class="pa-4" style="width: 100%;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background"></v-card>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </draggable>
                        </v-container>
                      </v-card>
                      <h3 class="mt-5 mb-2">{{ $t('emails.blocks', $store.state.locale) }}</h3>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-2 ma-0" :class="$store.state.companyTheme" style="width: 100%">
                        <div class="pa-3 ma-0 modules-wrapper">
                          <draggable
                            class="v-layout row wrap"
                            :list="modules"
                            :clone="clone"
                            :move="moveModule"
                            :group="{ name: 'items', pull: 'clone', put: false }"
                              >
                              <div class="module-wrapper" v-for="(module, index) in modules" :key="index">
                            <v-card flat outlined class="pa-3 text-center email-editor-button">
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="36">{{ module.icon}}</v-icon>
                              <h5>{{ module.title[($store.state.locale).toLowerCase()] }}</h5>
                            </v-card>
                            </div>
                          </draggable>
                        </div>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item value="edit" :transition="tabTransition" :reverse-transition="tabTransition">
                      <h3 class="mb-2" v-if="selectedItem && selectedItem.type == 'row'">Edit row</h3>
                      <h3 class="mb-2" v-if="selectedItem && selectedItem.type == 'column'">Edit column</h3>
                      <h3 class="mb-2" v-if="selectedItem && selectedItem.type == 'module'">Edit {{ selectedItem.title[($store.state.locale).toLowerCase()] }}</h3>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 ma-0" :class="$store.state.companyTheme" style="width: 100%; overflow:visible" v-if="selectedItem">
                        <div class="pa-4">
                          <v-btn @click="saveEmailChanges()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark block depressed>{{ $t('emails.saveAndClose', $store.state.locale) }}</v-btn>
                        </div>
                        <v-divider></v-divider>
                        <div>        
                          <!-- ROW & COLUMN -->
                          <div v-if="selectedItem.type == 'row' || selectedItem.type == 'column'">   
                            <div v-if="selectedItem.type == 'row'">
                              <div class="pa-4">
                                <h5 class="pb-1">Weergave {{ $t('emails.saveAndClose', $store.state.locale) }}</h5>
                                <v-select
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                  :items="showTypes"
                                  :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                                  item-value="id"
                                  dense
                                  outlined
                                  hide-details
                                  v-model="selectedItem.show"
                                ></v-select>       
                              </div>
                              <v-divider></v-divider>
                            </div>      
                              <div>
                                <div class="pa-4"> <!-- Background  --> 
                                  <div>

                                    <div v-if="selectedItem.type == 'row'"> 
                                      <h5 class="pb-1">Full width</h5>
                                      <v-switch  
                                        class="mb-4"  
                                        :color="saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                        v-model="selectedItem.fullWidth" 
                                        @change="setRowFullWidth"
                                        inset
                                        hide-details
                                        label="Full width">
                                      </v-switch>
                                    </div>

                                    <h5 class="pb-1">Background color 2</h5>
                                    <v-text-field
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                      style="max-width: 200px; width: 50%"
                                      v-model="selectedItem.attributes['background-color']"
                                      dense 
                                      outlined
                                      hide-details
                                      >
                                      <template v-slot:prepend-inner>
                                        <verte v-model="selectedItem.attributes['background-color']" :enableAlpha="false" model="hex" :showHistory="false" menuPosition="center">
                                          <div style="width:24px; height: 24px; border: 1px solid #666; border-radius: 50px" :style="{'backgroundColor':selectedItem.attributes['background-color'] }"></div> 
                                        </verte>
                                      </template>
                                      <template v-slot:append>
                                        <v-icon @click="clearColorField('backgroundColor')">
                                          mdi-close
                                        </v-icon>
                                      </template>
                                    </v-text-field>
                                  </div>

                                  <div v-if="selectedItem.type == 'row'"> <!-- padding -->
                                    <h5 class="pb-1 mt-4">Padding</h5>
                                    <v-select
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                      style="max-width: 200px; width: 50%"
                                      :items="paddingSizes"
                                      dense
                                      outlined
                                      hide-details
                                      v-model="selectedItem.attributes['padding']"
                                    ></v-select>
                                  </div>
                                </div>

                                <v-divider></v-divider>
                              </div>
                              
                              <div class="pa-4">
                                <h4 class="pb-1">Border</h4>

                                <h5 class="pb-1 mt-2">Style</h5>
                                <v-container grid-list-md class="ma-0 pa-0">
                                  <v-layout row wrap class="ma-0 pa-0">
                                    <v-flex xs4 class="ma-0 pa-0">
                                      <v-select
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                        :items="borderStyles"
                                        @change="setBorder"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        outlined
                                        hide-details
                                        v-model="selectedItem.borderStyle"
                                      ></v-select>
                                    </v-flex>
                                    <v-flex xs4 class="ma-0 pa-0">
                                      <v-select
                                        v-if="selectedItem.borderStyle != 'none'"
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                        :items="borderSizes"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        @change="setBorder"
                                        class="ml-1"
                                        outlined
                                        hide-details
                                        v-model="selectedItem.borderSize"
                                      ></v-select>
                                    </v-flex>
                                    <v-flex xs4 class="ma-0 pa-0">
                                      <v-text-field
                                        v-if="selectedItem.borderStyle != 'none'"
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        label="Color"
                                        v-model="selectedItem.borderColor"
                                        fullWidth
                                        dense
                                        @input="setBorder"
                                        class="ml-1" 
                                        outlined
                                        hide-details
                                        >
                                        <template v-slot:prepend-inner>
                                          <verte v-model="selectedItem.borderColor" :enableAlpha="false" model="hex" :showHistory="false" menuPosition="center" @input="setBorder">
                                            <div style="width:24px; height: 24px; border: 1px solid #666; border-radius: 50px" :style="{'backgroundColor':selectedItem.borderColor }"></div> 
                                          </verte>
                                        </template>
                                      </v-text-field>
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </div>
                          </div>

                          <!-- MODULE -->
                          <div v-if="selectedItem.type == 'module'">
                            <div class="pa-3 pb-1">
                              <v-container container class="ma-0 pa-0">
                                <v-layout row wrap class="ma-0 pa-0">
                                  <v-flex :class="contentEditWidth" class="ma-0 pa-0" v-if="contentAvailable">
                                    <div class="module-colum-button left" :class="{ active: selectedItemColumn == 'content' }" @click="selectedItemColumn = 'content'">
                                      <v-icon size="16" class="mr-2" :color="selectedItemColumn == 'content' ? '#ffffff' : $themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-form-textbox</v-icon>
                                      Content
                                    </div>
                                  </v-flex>
                                  <v-flex :class="contentEditWidth" class="ma-0 pa-0">
                                    <div class="module-colum-button middle"  :class="[{ active: selectedItemColumn == 'style' }, { left: !contentAvailable }, { right: !settingsAvailable }]" @click="selectedItemColumn = 'style'">
                                      <v-icon size="16" class="mr-2" :color="selectedItemColumn == 'style' ? '#ffffff' : $themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-format-paint</v-icon>
                                      Style
                                    </div>
                                  </v-flex>
                                  <v-flex :class="contentEditWidth" class="ma-0 pa-0" v-if="settingsAvailable">
                                    <div class="module-colum-button right"  :class="[{ active: selectedItemColumn == 'settings'}]" @click="selectedItemColumn = 'settings'">
                                      <v-icon size="16" class="mr-2" :color="selectedItemColumn == 'settings' ? '#ffffff' : $themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cog</v-icon>
                                      Settings
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </div>     

                            <div>
                              <!-- CONTENT --> 
                              <div v-if="selectedItemColumn == 'content'">
                                <div v-if="selectedItem.id == 'receipt'" class="text-center pa-4 ma-4">
                                  <v-avatar
                                    size="58"
                                    light
                                    class="ma-0 mr-2 custom-avatar-style" 
                                    rounded="lg"
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                    >
                                    <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-receipt</v-icon>
                                  </v-avatar>
                                  <p class="pt-4">De inhoud van een bon wordt automatisch gegenereerd. Gebasseerd op het actieve template.</p>
                                </div>


                                <v-card outlined class="ma-4 pa-2" v-if="selectedItem.id == 'image' || selectedItem.id == 'text' || selectedItem.id == 'button' || selectedItem.id == 'html'">
                                  <h4>Variables</h4>
                                  <div style="display: inline-block; margin: 2px 2px;" v-for="(variable, index) in emailVariablesFiltered" :key="index">
                                    <v-chip label small :color="variable.type == 'text' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  :text-color="variable.type == 'text' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mr-1 mt-1 custom-chip-style">{{ variable.name[($store.state.locale).toLowerCase()] }}</v-chip>
                                    <div v-if="variable.copy == false" class="copy-tag-url" @click="copyVariableToClipboard(variable.name, index)"><v-icon small>mdi-content-copy</v-icon> {{ $t('emails.copy', $store.state.locale) }}</div>
                                    <div v-else class="copy-tag-url copied">{{ $t('emails.copied', $store.state.locale) }}</div>
                                  </div>
                                </v-card>

                                <div v-if="selectedItem.id == 'spacer'" class="pa-4"> <!-- Spacer height  -->
                                  <h5 class="pb-1">Height</h5>
                                  <v-select
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    :items="borderSizes"
                                    item-text="name"
                                    item-value="id"
                                    dense
                                    class="ml-1"
                                    outlined
                                    hide-details
                                    v-model="selectedItem.attributes.height"
                                  ></v-select>
                                </div>

                                <div v-if="selectedItem.id == 'text'" class="pa-4 remove-all-styling preview "> <!-- Text Settings  -->
                                  <ckeditor :editor="editor" v-model="selectedItem.content" :config="editorConfig"></ckeditor>
                                </div>

                                <div class="pa-4" v-if="selectedItem.id == 'image'"> <!-- Image Settings  -->
                                  <v-row align="center" justify="center">
                                    <v-col xs3>
                                      <v-icon v-if="selectedItem.attributes.src == '[branchLogoUrl]' || selectedItem.attributes.src == '[vestigingsLogoUrl]'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="86" class="pa-0 ma-0">mdi-rhombus</v-icon>
                                      <v-icon v-else-if="!selectedItem.attributes.src" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" size="86" class="pa-0 ma-0">mdi-image</v-icon>
                                      <img v-else :src="selectedItem.attributes.src" style="max-width: 100%"/>
                                    </v-col>
                                    <v-col xs9>
                                      <v-btn v-if="!selectedItem.attributes.src" @click="openMediaLibrary()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark>Select from library</v-btn>
                                      <div v-else>
                                        <h4>{{ selectedItem.imageName }}</h4>
                                        <p v-if=" selectedItem.size" class="my-2">{{ selectedItem.size }}px</p>
                                        <a @click="openMediaLibrary()" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}">Replace</a>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <div v-if="editorType == 'admin'" class="text-center">

                                    <p>Of</p>

                                    <v-text-field
                                      label="Image URL"
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                      style="width: 100%;"
                                      v-model="selectedItem.attributes.src"
                                      @change="changeImageSrc()"
                                      @input="changeImageSrc()"
                                      dense
                                      outlined
                                      hide-details
                                    ></v-text-field>
                                  </div>
                                </div>

                                

                                <div v-if="selectedItem.id == 'button' || (selectedItem.id == 'image' && selectedItem.attributes.src)" > <!-- Button & Image Settings  -->
                                  <div class="pa-4"> 
                                    <div v-if="selectedItem.id == 'button'" class="mb-4">
                                      <h5 class="pb-1">Button text</h5>
                                      <v-text-field
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        style="width: 100%;"
                                        v-model="selectedItem.content"
                                        dense
                                        outlined
                                        hide-details
                                      ></v-text-field>
                                    </div>

                                    <h5 class="pb-1 ">Link to</h5>
                                    <v-select
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                      style="width: 100%;"
                                      :items="linkTypes"
                                      item-text="name"
                                      item-value="id"
                                      dense
                                      @change="setLink"
                                      outlined
                                      hide-details
                                      v-model="selectedItem.linkType"
                                    ></v-select>

                                    <div v-if="selectedItem.linkType == 'web'">
                                      <h5 class="pb-1 mt-4">Web address (URL)</h5>
                                      <v-text-field
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        style="width: 100%;"
                                        v-model="selectedItem.webLink"
                                        dense
                                        @change="setLink" 
                                        outlined
                                        clearable
                                        hide-details
                                        >
                                      </v-text-field>
                                    </div>

                                    <div v-if="selectedItem.linkType == 'email'">
                                      <h5 class="pb-1 mt-4">E-mail address</h5>
                                      <v-text-field
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        style="width: 100%;"
                                        v-model="selectedItem.linkEmailAddress"
                                        dense
                                        clearable
                                        @change="setLink" 
                                        outlined
                                        hide-details
                                        >
                                      </v-text-field>

                                      <h5 class="pb-1 mt-4">E-mail Subject</h5>
                                      <v-text-field
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        style="width: 100%;"
                                        v-model="selectedItem.linkEmailSubject"
                                        dense
                                        @input="setLink" 
                                        outlined
                                        clearable
                                        hide-details
                                        >
                                      </v-text-field>

                                      <h5 class="pb-1 mt-4">E-mail Text</h5>
                                      <v-text-field
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        style="width: 100%;"
                                        v-model="selectedItem.linkEmailText"
                                        dense
                                        clearable
                                        @change="setLink" 
                                        outlined
                                        hide-details
                                        >
                                      </v-text-field>
                                    </div>

                                    <div v-if="selectedItem.linkType == 'tel'">
                                      <h5 class="pb-1 mt-4">Phonenumber</h5>
                                      <v-text-field
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        style="width: 100%;"
                                        v-model="selectedItem.linkPhone"
                                        dense
                                        clearable
                                        @change="setLink" 
                                        outlined
                                        hide-details
                                        >
                                      </v-text-field>
                                    </div>

                                    <div v-if="selectedItem.linkType == 'file'">
                                      <h5 class="pb-1 mt-3">File (URL)</h5>
                                      <v-btn  @click="openMediaLibrary()" block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark>Select from library</v-btn>
                                      <v-text-field
                                        class="mt-3"
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        style="width: 100%;"
                                        v-model="selectedItem.linkFileUrl"
                                        dense
                                        clearable
                                        @change="setLink" 
                                        outlined
                                        hide-details
                                        >
                                      </v-text-field>
                                    </div>
                                  </div>
                                  <v-divider></v-divider>
                                  <div class="pa-4">
                                    <h5 class="pb-1 mt-3">Title</h5>
                                    <v-text-field
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                      style="width: 100%;"
                                      v-model="selectedItem.attributes.title"
                                      dense
                                      outlined
                                      clearable
                                      hide-details
                                      >
                                    </v-text-field>
                                  </div>
                                </div>

                                <div class="pa-4" v-if="selectedItem.id == 'html'"> <!-- HTML Settings  -->
                                  <v-textarea
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                  v-model="selectedItem.content" 
                                  outlined
                                  rows=5
                                  hide-details=""
                                ></v-textarea>
                                </div>
                              </div>


                              <!-- STYLE --> 
                              <div v-if="selectedItemColumn == 'style'">
                                <div v-if="selectedItem.id == 'receipt'" class="text-center pa-4 ma-4">
                                  <v-avatar
                                    size="58"
                                    light
                                    class="ma-0 mr-2 custom-avatar-style" 
                                    rounded="lg"
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                    >
                                    <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-receipt</v-icon>
                                  </v-avatar>
                                  <p class="pt-4">De styling van een bon wordt automatisch gegenereerd. Gebasseerd op het actieve template.</p>
                                </div>

                                <div v-if="selectedItem.id == 'spacer'">
                                  <div>
                                    <div class="pa-4"> <!-- Background  --> 
                                      <v-text-field
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        label="Color"
                                        v-model="selectedItem.attributes['container-background-color']"
                                        fullWidth
                                        dense
                                        class="ml-1" 
                                        outlined
                                        hide-details
                                        >
                                        <template v-slot:prepend-inner>
                                          <verte v-model="selectedItem.attributes['container-background-color']" :enableAlpha="false" model="hex" :showHistory="false" menuPosition="center" @input="setBorder">
                                            <div style="width:24px; height: 24px; border: 1px solid #666; border-radius: 50px" :style="{'backgroundColor':selectedItem.attributes['container-background-color'] }"></div> 
                                          </verte>
                                        </template>
                                      </v-text-field> 
                                    </div>
                                  </div>
                                </div>
                                <div v-if="selectedItem.id == 'button'|| selectedItem.id == 'divider'">
                                  <div>
                                    <div class="pa-4"> <!-- Background  --> 
                                      <div v-if="selectedItem.id == 'button'">
                                        <h5 class="pb-1">Background color</h5>
                                        <v-text-field
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          style="max-width: 200px; width: 50%"
                                          v-model="selectedItem.attributes['background-color']"
                                          dense 
                                          outlined
                                          hide-details
                                          >
                                          <template v-slot:prepend-inner>
                                            <verte v-model="selectedItem.attributes['background-color']" :enableAlpha="false" model="hex" :showHistory="false" menuPosition="center">
                                              <div style="width:24px; height: 24px; border: 1px solid #666; border-radius: 50px" :style="{'backgroundColor':selectedItem.attributes['background-color'] }"></div> 
                                            </verte>
                                          </template>
                                          <template v-slot:append>
                                            <v-icon @click="clearColorField('buttonBackgroundColor')">
                                              mdi-close
                                            </v-icon>
                                          </template>
                                        </v-text-field>
                                      </div>

                                      <div v-if="selectedItem.id == 'divider'">
                                        <h5 class="pb-1">Background color</h5>
                                        <v-text-field
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          style="max-width: 200px; width: 50%"
                                          v-model="selectedItem.attributes['container-background-color']"
                                          dense 
                                          outlined
                                          hide-details
                                          >
                                          <template v-slot:prepend-inner>
                                            <verte v-model="selectedItem.attributes['container-background-color']" :enableAlpha="false" model="hex" :showHistory="false" menuPosition="center">
                                              <div style="width:24px; height: 24px; border: 1px solid #666; border-radius: 50px" :style="{'backgroundColor':selectedItem.attributes['container-background-color'] }"></div> 
                                            </verte>
                                          </template>
                                          <template v-slot:append>
                                            <v-icon @click="clearColorField('containerBackgroundColor')">
                                              mdi-close
                                            </v-icon>
                                          </template>
                                        </v-text-field>
                                      </div>

                                      <div v-if="selectedItem.id == 'button'"> <!-- padding -->
                                        <h5 class="pb-1 mt-4">Padding</h5>
                                        <v-select
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                          style="max-width: 200px; width: 50%"
                                          :items="paddingSizes"
                                          dense
                                          outlined
                                          hide-details
                                          v-model="selectedItem.attributes['inner-padding']"
                                        ></v-select>
                                      </div>

                                    </div>
                                  </div>

                                  <v-divider></v-divider>
                                </div>
                              
                                <div v-if="selectedItem.id == 'button' || selectedItem.id == 'image' || selectedItem.id == 'divider'"> <!-- Border settings --> 
                                  <div class="pa-4">
                                    <h4 class="pb-1">Border</h4>

                                    <h5 class="pb-1 mt-2">Style</h5>
                                    <v-container grid-list-md class="ma-0 pa-0">
                                      <v-layout row wrap class="ma-0 pa-0">
                                        <v-flex xs4 class="ma-0 pa-0">
                                          <v-select
                                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                            :items="borderStyles"
                                            @change="setBorder"
                                            item-text="name"
                                            item-value="id"
                                            dense
                                            outlined
                                            hide-details
                                            v-model="selectedItem.borderStyle"
                                          ></v-select>
                                        </v-flex>
                                        <v-flex xs4 class="ma-0 pa-0">
                                          <v-select
                                            v-if="selectedItem.borderStyle != 'none'"
                                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                            :items="borderSizes"
                                            item-text="name"
                                            item-value="id"
                                            dense
                                            @change="setBorder"
                                            class="ml-1"
                                            outlined
                                            hide-details
                                            v-model="selectedItem.borderSize"
                                          ></v-select>
                                        </v-flex>
                                        <v-flex xs4 class="ma-0 pa-0">
                                          <v-text-field
                                            v-if="selectedItem.borderStyle != 'none'"
                                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                            label="Color"
                                            v-model="selectedItem.borderColor"
                                            fullWidth
                                            dense
                                            @input="setBorder"
                                            class="ml-1" 
                                            outlined
                                            hide-details
                                            >
                                            <template v-slot:prepend-inner>
                                              <verte v-model="selectedItem.borderColor" :enableAlpha="false" model="hex" :showHistory="false" menuPosition="center" @input="setBorder">
                                                <div style="width:24px; height: 24px; border: 1px solid #666; border-radius: 50px" :style="{'backgroundColor':selectedItem.borderColor }"></div> 
                                              </verte>
                                            </template>
                                          </v-text-field>
                                        </v-flex>
                                      </v-layout>
                                    </v-container>

                                    <div v-if="selectedItem.id == 'button' || selectedItem.id == 'image'">
                                      <h5 class="pb-1 mt-4">Radius</h5>
                                      <v-select
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                        style="max-width: 200px; width: 50%"
                                        :items="borderRadiusSizes"
                                        dense
                                        outlined
                                        hide-details
                                        v-model="selectedItem.attributes['border-radius']"
                                      ></v-select>
                                    </div>
                                  </div>

                                  <v-divider></v-divider>
                                </div>
          
                                <div class="pa-4" v-if="selectedItem.id == 'button' || selectedItem.id == 'text' "> <!-- Text  settings -->
                                  <h4 class="pb-1">Text</h4>              
                                  <v-container grid-list-md class="ma-0 pa-0 py-1">
                                    <v-layout row wrap class="ma-0 pa-0">
                                      <v-flex xs6 class="ma-0 pa-0 pr-1">
                                        <h5 class="pb-1 mt-2">Family</h5>
                                        <v-select
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                          style="width: 100%"
                                          :items="fontFamilies"
                                          item-text="name"
                                          item-value="id"
                                          dense
                                          outlined
                                          hide-details
                                          v-model="selectedItem.attributes['font-family']"
                                        ></v-select>
                                      </v-flex>
                                      <v-flex xs6 class="ma-0 pa-0 pl-1">
                                        <h5 class="pb-1 mt-2">Weight</h5>
                                        <v-select
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                          style="width: 100%"
                                          :items="fontWeights"
                                          item-text="name"
                                          item-value="id"
                                          dense
                                          outlined
                                          hide-details
                                          v-model="selectedItem.attributes['font-weight']"
                                        ></v-select>
                                      </v-flex>
                                    </v-layout>
                                  </v-container>

                                  <v-container grid-list-md class="ma-0 pa-0 py-1">
                                    <v-layout row wrap class="ma-0 pa-0">
                                      <v-flex xs6 class="ma-0 pa-0 pr-1">
                                        <h5 class="pb-1 mt-4">Transform</h5>
                                        <v-select
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                        style="width: 100%"
                                        :items="transformTypes"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        outlined
                                        hide-details
                                        v-model="selectedItem.attributes['text-transform']"
                                      ></v-select>
                                      </v-flex>
                                      <v-flex xs6 class="ma-0 pa-0 pl-1">
                                        <h5 class="pb-1 mt-4">Color</h5>
                                        <v-text-field
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          style="width: 100%"
                                          v-model="selectedItem.attributes['color']"
                                          fullWidth
                                          dense 
                                          outlined
                                          hide-details    
                                          >
                                          <template v-slot:prepend-inner>
                                            <verte v-model="selectedItem.attributes['color']" :enableAlpha="false" model="hex" :showHistory="false" menuPosition="center">
                                              <div style="width:24px; height: 24px; border: 1px solid #666; border-radius: 50px" :style="{'backgroundColor':selectedItem.attributes['color'] }"></div> 
                                            </verte>
                                          </template>
                                          <template v-slot:append>
                                            <v-icon @click="clearColorField('buttonText')">
                                              mdi-close
                                            </v-icon>
                                          </template>
                                        </v-text-field>
                                      </v-flex>
                                    </v-layout>
                                  </v-container>

                                  <v-container grid-list-md class="ma-0 pa-0 py-1">
                                    <v-layout row wrap class="ma-0 pa-0">
                                      <v-flex xs6 class="ma-0 pa-0 pr-1">
                                        <h5 class="pb-1 mt-4">Size</h5>
                                        <v-select
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                          style="width: 100%"
                                          :items="fontSizes"
                                          dense
                                          outlined
                                          hide-details
                                          v-model="selectedItem.attributes['font-size']"
                                        ></v-select>
                                      </v-flex>
                                      <v-flex xs6 class="ma-0 pa-0 pl-1">
                                        <h5 class="pb-1 mt-4">Line height</h5>
                                        <v-select
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                          style="width: 100%"
                                          :items="lineHeights"
                                          dense
                                          outlined
                                          hide-details
                                          v-model="selectedItem.attributes['line-height']"
                                        ></v-select>
                                      </v-flex>
                                    </v-layout>
                                  </v-container>

                                  <v-container grid-list-md class="ma-0 pa-0 py-1">
                                    <v-layout row wrap class="ma-0 pa-0">
                                      <v-flex xs6 class="ma-0 pa-0 pr-1">
                                        <h5 class="pb-1 mt-4">Align</h5>
                                        <v-select
                                          v-if="selectedItem.id == 'button'"
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                          style="width: 100%"
                                          :items="alignTypes"
                                          item-text="name"
                                          item-value="id"
                                          dense
                                          outlined
                                          hide-details
                                          v-model="selectedItem.attributes['text-align']"
                                        ></v-select>
                                        <v-select
                                          v-if="selectedItem.id == 'text'"
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                          style="width: 100%"
                                          :items="alignTypes"
                                          item-text="name"
                                          item-value="id"
                                          dense
                                          outlined
                                          hide-details
                                          v-model="selectedItem.attributes['align']"
                                        ></v-select>
                                      </v-flex>
                                      <v-flex xs6 class="ma-0 pa-0 pl-1">
                                        <h5 class="pb-1 mt-4">Text decoration</h5>
                                        <v-select
                                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                          style="width: 100%"
                                          :items="decorationTypes"
                                          item-text="name"
                                          item-value="id"
                                          dense
                                          outlined
                                          hide-details
                                          v-model="selectedItem.attributes['text-decoration']"
                                        ></v-select>
                                      </v-flex>
                                    </v-layout>
                                  </v-container>
                                </div>

                                <div class="pa-4">
                                  <div v-if="selectedItem.id == 'image' || selectedItem.id == 'divider'">
                                    <h5 class="pb-1 mt-4">Width</h5>
                                    <v-slider
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                      dense
                                      class="ma-0 pa-0"
                                      style="max-width: 300px"
                                      v-model="selectedItem.width"
                                      track-color="#cccccc"
                                      max="100"
                                      @change="setItemWidth()"
                                      min="0"
                                      hide-details
                                    ></v-slider>
                                  </div>
                                </div>
                              </div>

                              <!-- SETTINGS --> 
                              <div v-if="selectedItemColumn == 'settings'">
                                <div class="pa-4"> <!-- padding -->
                                  <h5 class="pb-1 mt-1">Padding</h5>
                                  <v-select
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    style="max-width: 200px; width: 50%"
                                    :items="paddingSizes"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="selectedItem.attributes['padding']"
                                  ></v-select>
                                </div>
                                <div v-if="selectedItem.id == 'button' || selectedItem.id == 'image' || selectedItem.id == 'divider'">
                                  <v-divider></v-divider>
                                  <div class="pa-4">
                                    <div v-if="selectedItem.id == 'button' || selectedItem.id == 'image' || selectedItem.id == 'divider'"> <!-- Align  -->
                                      <h5 class="pb-1 mt-1">Align</h5>
                                      <v-select
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                        style="max-width: 200px; width: 50%"
                                        :items="alignTypes"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        outlined
                                        hide-details
                                        v-model="selectedItem.attributes.align"
                                      ></v-select>
                                    </div>
                                    <div v-if="selectedItem.id == 'button'"> <!-- Full width or Text with  -->
                                      <h5 class="pb-1 mt-">Weergave</h5>
                                      <v-select
                                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                        style="max-width: 200px; width: 50%"
                                        :items="displayTypes"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        outlined
                                        hide-details
                                        v-model="selectedItem.attributes.width"
                                      ></v-select>
                                    </div>
                                  </div>
                                </div>
                                <div class="pa-4"> <!-- CSS Class  -->
                                  <h5 class="pb-1">CSS Class</h5>
                                  <v-text-field
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                                      style="width: 100%;"
                                      v-model="selectedItem.attributes['css-class']"
                                      dense
                                      outlined
                                      hide-details
                                    ></v-text-field>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </v-card>
                    </v-tab-item>  
                    <v-tab-item value="saved" :transition="tabTransition" :reverse-transition="tabTransition">
                      <h3 class="mb-2">Rows</h3>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-2 ma-0 saved-items" :class="$store.state.companyTheme" style="width: 100%">
                        <div v-if="library.rows.length == 0" class="text-center pa-4" style="font-size: 13px">
                          <i>Nog geen Rows opgeslagen.</i>
                        </div>
                        <draggable
                          v-else
                          :list="library.rows"
                          :clone="clone"
                          :move="moveRow"
                          :group="{ name: 'items', pull: 'clone', put: false }"
                          >
                          <div class="module-wrapper" v-for="(module, index) in library.rows" :key="index">
                            
                          <v-card flat outlined class="pa-2 my-1 saved-item-wrapper">
                            <div class="saved-item-name" :class="{'general' : module.general }">{{ module.name }}</div>
                            <div class="saved-item-tag" v-if="module.general">
                              <v-chip label small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="custom-chip-style">General</v-chip>
                            </div>
                            <div class="saved-item-date">{{ module.dateAdded | moment("D-MM-YYYY") }}</div>
                            <div class="saved-item-remove" @click="removeItemFromLibrary(module)">
                              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="18">mdi-delete</v-icon>
                            </div>
                            <!-- <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="36">{{ module.icon}}</v-icon>
                            <h5>{{ module.title[($store.state.locale).toLowerCase()] }}</h5> -->
                          </v-card>
                          </div>
                        </draggable>
                      </v-card>

                      <h3 class="mb-2 mt-4">Modules</h3>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-2 py-1 ma-0 saved-items" :class="$store.state.companyTheme" style="width: 100%">
                        <div v-if="library.modules.length == 0" class="text-center pa-4" style="font-size: 13px">
                          <i>Nog geen modules opgeslagen.</i>
                        </div>
                        <draggable
                          v-else
                          :list="library.modules"
                          :clone="clone"
                          :move="moveModule"
                          :group="{ name: 'items', pull: 'clone', put: false }"
                          >
                          <div class="module-wrapper" v-for="(module, index) in library.modules" :key="index">
                            
                          <v-card flat outlined class="pa-2 my-1 saved-item-wrapper">
                            <div class="saved-item-name" :class="{'general' : module.general }">{{ module.name }}</div>
                            <div class="saved-item-tag" v-if="module.general">
                              <v-chip label small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="custom-chip-style">General</v-chip>
                            </div>
                            <div class="saved-item-remove" @click="removeItemFromLibrary(module)">
                              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="18">mdi-delete</v-icon>
                            </div>
                            <!-- <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="36">{{ module.icon}}</v-icon>
                            <h5>{{ module.title[($store.state.locale).toLowerCase()] }}</h5> -->
                          </v-card>
                          </div>
                        </draggable>
                      </v-card>
                        
                    </v-tab-item>        
                  </v-tabs-items>
                </v-flex>
              </v-layout>
            </v-container>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- / Delete Item Dialog \ -->
    <v-dialog v-model="removeEmailItemData.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <h2 class="mb-4">{{ removeEmailItemData.title }}</h2> 
          <p class="ma-0">{{ removeEmailItemData.text }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="deletingItem" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="removeEmailItemData.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="deletingItem" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeEmailItem()">
                   {{ $t('rooms.removeRoomButton', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Item Dialog / -->

    <!-- / Close Without Saving Dialog \ -->
    <v-dialog v-model="closeWithoutSaving.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].alert}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alert</v-icon>
          </v-avatar>
          <h2 class="mb-4">Wijzigingen niet opslaan?</h2> 
          <p class="ma-0">Er zijn niet opgeslagen wijzigingen.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                
                <v-btn dark :loading="savingItem" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="closeAndSave()">
                  Opslaan en sluiten
                   <!-- {{ $t('rooms.removeRoomButton', $store.state.locale) }} -->
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn outlined depressed :disabled="savingItem" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeWithoutSave()">
                  Sluiten
                  <!-- {{ $t('general.cancel', $store.state.locale) }} -->
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Item Dialog / -->

    <!-- / Save Item Dialog \ -->
    <v-dialog v-model="saveEmailItemData.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-content-save</v-icon>
          </v-avatar>
          <h2 class="mb-4">{{ saveEmailItemData.title }}</h2> 
          <p class="ma-0">{{ saveEmailItemData.text }}</p>
          <v-text-field
            class="mt-4"
            v-model="saveEmailItemData.name"
            fullWidth
            dense
            hide-details=""
            label="Name"
            outlined
            clearable
            ></v-text-field>
          <div style="margin: 0; display: none">
            <v-tooltip dark bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                <v-switch    
                  :color="saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  v-model="saveEmailItemData.general" 
                  inset
                  hide-details
                  label="General">
                </v-switch>
                </div>
              </template>
              <span style="font-size: 12px">Make general to stay updated in te future.</span>
            </v-tooltip>
          </div>        
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="deletingItem" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="saveEmailItemData.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn :disabled="!saveItemValid" :dark="saveItemValid" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="saveEmailItem()">
                   {{ $t('general.save', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Save Item Dialog / -->

    <!-- / Image Library \ -->
    <v-dialog
      v-model="libraryDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>Media Library</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeLibraryDialog()">
            <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0" style="position: relative">
          <media-library  @mediaFileSelect="setChosenImage" :assetGroup="'companyAsset'" :assetType="'general'" :fileRules="{accept: ['image/jpg', 'image/jpeg', 'image/png'], fileSize: 2000000}" ></media-library>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- \ Image Library / -->

  </div>
</template>

<script>
import _ from 'lodash'
import db from "../firebase/init"; 
import { bus } from "../main";
import draggable from "vuedraggable";
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
// import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
// import Image from '@ckeditor/ckeditor5-image/src/image';
// import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
// import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Font from '@ckeditor/ckeditor5-font/src/font';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import MediaLibrary from "../components/elements/MediaLibrary";
// import EditMenuItem from "../components/modals/EditMenuItem";
var uniqid = require("uniqid");
import axios from "axios";
import moment from "moment";

export default {
  name:"editEmailTemplate",
  components:{
    "media-library" : MediaLibrary,
    draggable,
  },

  data(){
    return{
      selectedMessage:{
        id: null
      },
      savingItem: false,
      unsafedChanges: false,
      editorType: 'client',
      companies: new Array(),
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Essentials,
          UploadAdapter,
          Autoformat,
          Bold,
          Font,
          Italic,
          BlockQuote,
          // EasyImage,
          Heading,
          // Image,
          // ImageCaption,
          // ImageStyle,
          // ImageToolbar,
          // ImageUpload,
          Link,
          List,
          Paragraph,
          Alignment    
        ],
        fontSize: {
            options: [
                8,
                10,
                12,
                'default',
                16,
                18,
                20
            ]
          },
        toolbar: {
          items: [
            'heading',
            'undo',
            'alignment',
            'redo',
            'bold',
            'italic',
            'link',
            'fontSize',
            'fontColor', 
            'fontBackgroundColor' , 
            'fontFamily'
          ],

          alignment: {
            options: ['left', 'center', 'right', 'justify']
          },
          heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
            ]
          },
          
          fontColor: {
            colors: [
                // 9 colors defined here...
            ],

            columns: 3, // so, you can display them in 3 columns.

              // ...
          },
          fontBackgroundColor: {
              columns: 6,

              // ...
          },
        }
      },
      selectedPreviewLanguage: 'nl',
      libraryDialog: false,
      loading: true,
      templates: new Array(),
      emailItems: new Array(),
      drag: false,
      tab: "general",
      tabTransition: false,
      emailHeader: {
        tagName: 'mj-head',
        attributes: new Object(),
        content: [
          {
            tagName: 'mj-style',
            content: "",
            attributes: new Object()
          }
        ]
      },
      emailDesign: {
        tagName: 'mjml',
        children: [{
            tagName: 'mj-body',
            children: new Array()
          }
        ]
      },
      emailVariables: [
        {
          id: 'branchUrl',
          name: {
            nl: '[vestigingsWebsiteUrl]',
            en: '[branchWebsiteUrl]',
          },
          type: 'text',
          editorType: 'admin',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "receipt",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'appointmentCancelUrl',
          name: {
            nl: '[afspraakAnnuleerLink]',
            en: '[appointmentCancelUrl]',
          },
          type: 'text',
          editorType: 'admin',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "downPaymentReminder",
            "appointmentReminder",
          ],
          copy: false
        },
        {
          id: 'branchLogoUrl',
          name: {
            nl: '[vestigingsLogoUrl]',
            en: '[branchLogoUrl]',
          },
          type: 'image',
          editorType: 'admin',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "receipt",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'branchName',
          name: {
            nl: '[vestigingNaam]',
            en: '[branchName]',
          },
          type: 'text',
          editorType: 'admin',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "receipt",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'branchAddress',
          name: {
            nl: '[vestigingAdres]',
            en: '[branchAddress]',
          },
          type: 'text',
          editorType: 'admin',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "receipt",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'firstName',
          name: {
            nl: '[voornaam]',
            en: '[firstname]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "receipt",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'lastname',
          name: {
            nl: '[achternaam]',
            en: '[lastname]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "receipt",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'date',
          name: {
            nl: '[datum]',
            en: '[date]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'daystogo',
          name: {
            nl: '[tijd te gaan]',
            en: '[time to go]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentReminder",
            "eventReminder",
            "downPaymentReminder"
          ],
          copy: false
        },
        {
          id: 'starttime',
          name: {
            nl: '[starttijd]',
            en: '[starttime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'endtime',
          name: {
            nl: '[eindtijd]',
            en: '[endtime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'starttimeold',
          name: {
            nl: '[oude starttijd]',
            en: '[old starttime ]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentChanges",
            "eventChanges",
          ],
          copy: false
        },
        {
          id: 'endtimeold',
          name: {
            nl: '[oude eindtijd]',
            en: '[old endtime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentChanges",
            "eventChanges",
          ],
          copy: false
        },
        {
          id: 'starttimenew',
          name: {
            nl: '[nieuwe starttijd]',
            en: '[new starttime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentChanges",
            "eventChanges",
          ],
          copy: false
        },
        {
          id: 'endtimenew',
          name: {
            nl: '[nieuwe eindtijd]',
            en: '[new endtime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentChanges",
            "eventChanges",
          ],
          copy: false
        },
        {
          id: 'treatment',
          name: {
            nl: '[behandeling]',
            en: '[treatment]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder",
            "downPaymentReminder",
            "appointmentCancelNoDownPayment"
          ],
          copy: false
        },
        {
          id: 'depositAmount',
          name: {
            nl: '[aanbetalingBedrag]',
            en: '[depositAmount]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirmDownPayment",
            "downPaymentReminder"
          ],
          copy: false
        },
        {
          id: 'depositUrl',
          name: {
            nl: '[aanbetalingURL]',
            en: '[depositURL]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirmDownPayment",
            "downPaymentReminder"
          ],
          copy: false
        },
        // {
        //   id: 'depositUrl',
        //   name: {
        //     nl: '[digitaleBon]',
        //     en: '[digtalReceipt]',
        //   },
        //   type: 'text',
        //   editorType: 'all',
        //   include: [
        //     "receipt", 
        //   ],
        //   copy: false
        // },
      ],
      selectedItem: null,
      selectedItemType: null,
      selectedRow: null,
      selectedColumn: null,
      deletingItem: false,
      selectedItemColumn: 'content',
      removeEmailItemData: {
        dialog: false,
        text: null,
        title: "Are you sure?",
        type: null,
        id: null
      },
      saveEmailItemData: {
        dialog: false,
        item: null,
        text: null,
        name: null,
        title: null,
        general: false
      },
      library: {
        rows: new Array(),
        modules: new Array()
      },
      editEmailDialog: false,
      languages: [
        {
          name: "Nederlands",
          id: 'nl'
        },
        {
          name: "Engels",
          id: 'en'
        },
        {
          name: "Duits",
          id: 'de'
        }
      ],
      showTypes: [
        {
          name: {
            nl: "Altijd zichtbaar",
            en: "Alway visible"
          },
          id: 'all',
        },
        {
          name: {
            nl: "Zichtbaar als taal ontvanger Nederlands is.",
            en: "Visible if recipient's language is Dutch."
          },
          id: 'nl',
        },
        {
          name: {
            nl: "Zichtbaar als taal ontvanger Engels is.",
            en: "Visible if recipient's language is English."
          },
          id: 'en',
        },
        {
          name: {
            nl: "Zichtbaar als taal ontvanger Duits is.",
            en: "Visible if recipient's language is German."
          },
          id: 'de',
        },
        {
          name: {
            nl: "Zichtbaar als taal ontvanger geen Nederlands is.",
            en: "Visible if recipient's language is not Dutch."
          },
          id: 'exceptNl',
        },
        {
          name: {
            nl: "Zichtbaar als taal ontvanger geen Engels is.",
            en: "Visible if recipient's language is not English."
          },
          id: 'exceptEn',
        },
      ],
      emailTypes: [
        {
          name: {
            nl: "Afspraakbevestiging",
            en: "Appointment Confirmation"
          },
          id: 'appointmentConfirm',
        },
        {
          name: {
            nl: "Afspraakbevestiging met aanbetaling",
            en: "Appointment Confirmation with Downpayment"
          },
          id: 'appointmentConfirmDownPayment',
        },
        {
          name: {
            nl: "Afspraakherinnering",
            en: "Appointment reminder"
          },
          id: 'appointmentReminder',
        },
        {
          name: {
            nl: 'Aanbetaling herinnering',
            en: 'Desposit reminder'
          },
          id: 'downPaymentReminder',
        },
        {
          name: {
            nl: "Afspraak annulering ",
            en: "Appointment cancellation "
          },
          id: 'appointmentCancel',
        },
        {
          name: {
            nl: 'Afspraak annulering (geen aanbetaling ontvangen)',
            en: 'Appointment cancelation (no deposit received)'
          },
          id: 'appointmentCancelNoDownPayment',
        },
        {
          name: {
            nl: "Afspraak  aanpassingsbevestiging",
            en: "Appointment adjustment confirmation"
          },
          id: 'appointmentChanges',
        },
        {
          name: {
            nl: "Inschrijfbevestiging ",
            en: "Confirmation subscription"
          },
          id: 'eventConfirm',
        },
        {
          name: {
            nl: "Herinnering Eventinschrijving",
            en: "Event Subscription reminder"
          },
          id: 'eventReminder',
        },
        {
          name: {
            nl: "Inschrijving annulering ",
             en: "Subscription cancellation"
          },
          id: 'eventCancel',
        },
        {
          name: {
            nl: "Inschrijving aanpassingsbevestiging",
              en: "Inschrijving adjustment confirmation"
          },
          id: 'eventChanges',
        },
        {
          name: {
            nl: 'Digitale bon na afrekenen',
            en: 'Digital receipt after payment'
          },
          id: 'receipt',
        }
      ],
      fontSizes: ['8px', '10px', '12px', '14px','16px', '18px', '20px', '22px', '24px', '26px'],
      borderSizes: ['1px', '2px', '3px', '4px','5px', '6px', '7px', '8px'],
      borderRadiusSizes: ['0px','2px', '3px', '4px','5px', '6px', '7px', '8px', '9px', '10px', '20px', '30px', '40px', '50px', '100px', '50px%', '100%'],
      paddingSizes: ['0px', '2px', '4px', '6px', '8px', '10px', '12px', '14px', '16px', '18px', '20px'],
      lineHeights: [1,1.7,2],
      fontFamilies: [
        {
          name: 'Standard Webfonts',
          id: 'standardWebfonts',
          disabled: true
        },
        {
          name: 'Arial',
          id: 'Arial, Helvetica, sans-serif'
        },
        {
          name: 'Courier New',
          id: 'Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace'
        },
        {
          name: 'Georgia',
          id: 'Georgia, Times, Times New Roman, serif'
        },
        {
          name: 'Helvetica',
          id: 'Helvetica, Arial, Verdana, sans-serif'
        },
        {
          name: 'Lucida',
          id: 'Lucida Sans Unicode, Lucida Grande, sans-serif'
        },
        {
          name: 'Tahoma',
          id: 'Tahoma, Verdana, Segoe, sans-serif'
        },
        {
          name: 'Times New Roman',
          id: 'Times New Roman, Times, Baskerville, Georgia, serif'
        },

        {
          name: 'Trebuchet MS',
          id: ' Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif'
        },
        
        {
          name: 'Verdana',
          id: 'Verdana, Geneva, sans-serif'
        },
        {
          name: 'Custom Webfonts',
          id: 'customWebfonts',
          disabled: true
        },
        {
          name: 'Avro',
          id: 'Arvo, Courier, Georgia, serif'
        },
        {
          name: 'Lato',
          id: 'Lato, Helvetica Neue, Helvetica, Arial, sans-serif'
        },

        {
          name: 'Lora',
          id: 'Lora, Georgia, Times New Roman, serif'
        },
        {
          name: 'Merriweather',
          id: 'Merriweather, Georgia, Times New Roman, serif'
        },
        {
          name: 'Merriweather Sans',
          id: 'Merriweather Sans, Helvetica Neue, Helvetica, Arial, sans-serif'
        },
        {
          name: 'Noticia Text',
          id: 'Noticia Text, Georgia, "Times New Roman", serif'
        },
        {
          name: 'Open Sans',
          id: 'Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif'
        },
        {
          name: 'Playfair Display',
          id: 'Playfair Display, Georgia, Times New Roman, serif'
        },
        {
          name: 'Roboto',
          id: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif'
        },
        {
          name: 'Source Sans Pro',
          id: 'Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif'
        }
      ],
      fontWeights: [
         {
          name: 'Normal',
          id: 400
        },
         {
          name: 'Bold',
          id: 600
        },

      ],
      transformTypes: [
        {
          name: 'None',
          id: 'none'
        },
        {
          name: 'Uppercase',
          id: 'uppercase'
        },
        {
          name: 'Lowercase',
          id: 'lowercase'
        },
        {
          name: 'Capitalize',
          id: 'capitalize'
        },
      ],
      displayTypes: [
        {
          name: 'Full width',
          id: '100%'
        },
        {
          name: 'Fit to text',
          id: 'auto'
        }
      ],
      linkTypes: [
         {
          name: 'None',
          id: 'none'
        },
        {
          name: 'Website',
          id: 'web'
        },
        {
          name: 'Email',
          id: 'email'
        },
        {
          name: 'Phone',
          id: 'tel'
        },
        {
          name: 'File',
          id: 'file'
        },
      ],
      decorationTypes: [
        {
          name: 'None',
          id: 'none'
        },
        {
          name: 'Underline',
          id: 'underline'
        },
        {
          name: 'Overline',
          id: 'overline'
        },
         {
          name: 'Line through',
          id: 'line-through'
        }
      ],
      alignTypes: [
        {
          name: 'Left',
          id: 'left'
        },
        {
          name: 'Center',
          id: 'center'
        },
        {
          name: 'Right',
          id: 'right'
        }
      ],
      borderStyles: [
        {
          name: 'None',
          id: 'none'
        },
        {
          name: 'Solid',
          id: 'solid'
        },
        {
          name: 'Dotted',
          id: 'dotted'
        },
        {
          name: 'Dashed',
          id: 'dashed'
        },
        {
          name: 'Double',
          id: 'double'
        }
      ],
      changes: 0,
      closeWithoutSaving: {
        dialog: false
      },

      // https://documentation.mjml.io/#mj-section
      modules: [
        {
          tagName: 'mj-text',
          content: "This is a text block. You can use it to add text to your template.",
          attributes: {
            'color': "#333",
            'font-family':'Helvetica, Arial, Verdana, sans-serif',
            'font-size':'14px',
            'font-style': 'normal',
            'font-weight': 400,
            'line-height': 1,
            'text-decoration'	: 'none',
            'text-transform': 'none',
            'align': 'left',
            'text-align': 'left',
            'container-background-color': null,
            'padding':'20px',
            'css-class' : null,
          },
          id: 'text',
          title: {
            nl: "Tekst",
            en: "Text"
          },
          type: 'module',
          icon: 'mdi-format-text',
        },
        {
          tagName: 'mj-image',
          attributes: {
            'align':'center',
            'alt': '',
            'border': 'none',
            'border-radius': "0px",
            'container-background-color': null,
            'css-class' : null,
            'fluid-on-mobile': true,
            'height': 'auto',
            'href': '',
            'name': "",
            'padding':'0px',       
            'rel': "",//	string	specify the rel attribute	n/a
            'sizes': null	,// media query & width	set width based on query	n/a
            'src': ""	,// url	image source	n/a
            'srcset': ""	,// url & width	enables to set a different image source based on the viewport	n/a
            'target': "_blank"	,// string	link target on click	_blank
            'title': ""	,// string	tooltip & accessibility	n/a
            // 'usemap': ""	,// string	reference to image map, be careful, it isn't supported everywhere	n/a
            'width': "300px"
          },

          id: 'image',
          title: {
            nl: "Afbeelding",
            en: "Image"
          },
          borderStyle: 'none',
          borderSize: '1px',
          borderColor: '#333333',

          type: 'module',
          value: null,
          name: null,
          size: null,
          width: 50,
          maxWidth: null,
          borderRadius: 0,
          
          borderStyle: 'none',
          borderWidth: 0,
          borderColor: "#333333",
          linkType: 'none',
          webLink: '',
          linkEmailAddress: '',
          linkEmailSubject: '',
          linkEmailText: '',
          linkPhone: '',
          linkFileUrl: '',
          align: "center",
          icon: 'mdi-image'
        },
        {
          tagName: 'mj-button',
          content: "Button",
          attributes: {
            'align': 'center',	// string	horizontal alignment	center
            'background-color': '#333333',	// color	button background-color	#414141
            'border': 'none',
            'border-radius': '0px',
            'color':'#ffffff',
            'font-family':'Helvetica, Arial, Verdana, sans-serif',
            'font-size':'18px',
            'font-style': 'normal',
            'font-weight': 400,
            'line-height': 1,
            'css-class' : null,         
            'height':'0px', 
            'href': '', // link	link to be triggered when the button is clicked	n/a
            'inner-padding': '20px', //	px	inner button padding	10px 25px
            'rel': "", //	string	specify the rel attribute for the button link	n/a
            'target': "_blank"	,// string	link target on click	_blank
            'text-decoration'	: 'none',
            'text-transform': 'none',
            'text-align': 'center',
            'vertical-align': 'middle',
            'title': '', //	string	tooltip & accessibility	n/a
            'width':	'auto',
            'padding': '10px'
          },
          borderStyle: 'none',
          borderSize: '1px',
          borderColor: '#333333',
          id: 'button',
          title: {
            nl: "Knop",
            en: "Button"
          },
          linkType: 'web',
          webLink: '',
          linkEmailAddress: '',
          linkEmailSubject: '',
          linkEmailText: '',
          linkPhone: '',
          linkFileUrl: '',
          type: 'module',
          icon: 'mdi-cursor-default-click'
        },
        {
          tagName: 'mj-raw',
          content: "",
          id: 'html',
          attributes: {
            'padding': '10px'
          },
          title: {
            nl: "Code",
            en: "Code"
          },

          type: 'module',
          value: "<i>This is a code block. You can use it to add custom html to your template.</i>",
          icon: 'mdi-code-tags'
        },
        {
          tagName: 'mj-text',
          value: `
          <table border="0" cellpadding="0" width="99%" style="width:100%; font-family: Menlo, Consolas, "Lucida Console", monospace!important;font-size:14px;font-style:normal;font-weight:400;line-height:1;text-align:left;text-decoration:none;text-transform:none;color:#333333;"><tr><td><table><tr><td style="padding: 4px 0px;">${moment().format("DD/MM/YYYY")}</td><td align="right" style="padding: 4px 0px;">${moment().format("H:mm")}u</td></tr></table></td></tr>
                  <tr><td>&nbsp;</td></tr>
                  <tr><td><table border="0" cellpadding="0" width="99%" style="width: 100%;"><tr style="border-bottom: 1px dashed rgba(94, 86, 105, 0.87) !important; border-top: 1px dashed rgba(94, 86, 105, 0.87) !important;"><td style="padding: 4px 0px;"><strong>Artikelen</strong></td><td align="right" style="padding: 4px 0px;"><strong>Prijs</strong></td></tr><tr><td style="padding: 4px 0px;">Behandeling</td><td align="right" style="padding: 4px 0px;">€ 150.00</td></tr><tr><td style="padding: 4px 0px;">Product</td><td align="right" style="padding: 4px 0px;">€ 21.95</td></tr><tr style="border-top: 1px dashed rgba(94, 86, 105, 0.87) !important;"><td style="padding: 8px 0px;"><strong>Totaal</strong></td><td align="right" style="padding: 8px 0px;"><strong>€ 171,95</strong></td></tr></table></td></tr> 
                  <tr><td><table border="0" cellpadding="0" width="99%" style="width: 100%;"><tr><td style="padding: 8px 0px 4px;"><strong>BTW</strong></td><td align="right" style="padding: 8px 0px 4px;"></td></tr><tr><td style="padding: 4px 0px 0px;">BTW 21%: </td><td align="right" style="padding: 4px 0px 0px;">€ 26,03</td></tr><tr><td style="padding: 4px 0px 0px;">BTW 9%: </td><td align="right" style="padding: 4px 0px 0px;">€ 1,81</td></tr></table></td></tr></table>`,
          id: 'receipt',
          attributes: {
            'padding': '0px',
            'container-background-color': null,
            'css-class': 'receipt-module'
          },
          title: {
            nl: "Bon",
            en: "Receipt"
          },

          type: 'module',
          content: "[receipt]",
          icon: 'mdi-receipt'
        },
        {
          tagName: 'mj-spacer',
          content: "",
          id: 'spacer',
          attributes: {
            'height': '4px',
            'padding': '0px',
            'container-background-color': "#333333"
          },
          title: {
            nl: "Spacer",
            en: "Spacer"
          },

          type: 'module',
          icon: 'mdi-unfold-less-horizontal'
        },
        {
          tagName: 'mj-divider',
          content: "",
          id: 'divider',
          attributes: {
            'border': '1px solid #333333',
            'width': '70%',
            'padding': '0px',
            'align': 'center',
            'container-background-color': "#ffffff"
          },
          width: 70,
          borderStyle: 'solid',
          borderSize: '1px',
          borderColor: '#333333',
          title: {
            nl: "Divider",
            en: "Divider"
          },

          type: 'module',
          icon: 'mdi-minus'
        },
      ],
      rows: [
        {
          tagName: 'mj-section',
          attributes: {
            'background-color': '#ffffff',
            'border': 'none',
            'full-width': "",
            'inner-padding': "0px",
            'padding': "0px"
          },
          children: [
            {
              tagName: 'mj-column',
              type: 'column',
              attributes: {
                'background-color': '#ffffff',
                'inner-background-color': '',
                'border': 'none',
                'border-radius': "0px",
                'width': '100%',
                'vertical-align': 'middle',
                'padding':'0px',
                'css-class' : null,
              },
              children: new Array(),
              borderStyle: 'none',
              borderSize: '1px',
              borderColor: '#333333',
            }
          ],
          id: 'single',
          rows: 1,
          type: 'row',
          show: 'all',
          fullWidth: false,
          borderStyle: 'none',
          borderSize: '1px',
          borderColor: '#333333',
        },
        {
          tagName: 'mj-section',
           attributes: {
            'background-color': '#ffffff',
            'border': 'none',
            'full-width': "",
            'inner-padding': "0px",
            'padding': "0px"
          },
          children: [
            {
              tagName: 'mj-column',
              type: 'column',
              attributes: {
                'background-color': '#ffffff',
                'inner-background-color': '',
                'border': 'none',
                'border-radius': "0px",
                'width': '300px',
                'vertical-align': 'middle',
                'padding':'10px',
                'css-class' : null,
              },
              children: new Array(),
              borderStyle: 'none',
              borderSize: '1px',
              borderColor: '#333333'
            },
            {
              tagName: 'mj-column',
              type: 'column',
              attributes: {
                'background-color': '#ffffff',
                'inner-background-color': '',
                'border': 'none',
                'border-radius': "0px",
                'width': '300px',
                'vertical-align': 'middle',
                'padding':'10px',
                'css-class' : null,
              },
              children: new Array(),
              borderStyle: 'none',
              borderSize: '1px',
              borderColor: '#333333'
            }
          ],
          id: 'double',
          rows: 2,
          show: 'all',
          type: 'row',
          fullWidth: false,
          borderStyle: 'none',
          borderSize: '1px',
          borderColor: '#333333'
        },
        {
          tagName: 'mj-section',
           attributes: {
            'background-color': '#ffffff',
            'border': 'none',
            'full-width': "",
            'inner-padding': "0px",
            'padding': "0px"
          },
          id: 'triple',
          rows: 3,
          show: 'all',
          type: 'row',
          fullWidth: false,
          attributes: {
            'background-color': '#ffffff',
            'border': 'none',
            'full-width': "",
            'inner-padding': "0px",
            'padding': "0px"
          },
          children: [
            {
              tagName: 'mj-column',
              type: 'column',
              attributes: {
                'background-color': '#ffffff',
                'inner-background-color': '',
                'border': 'none',
                'border-radius': "0px",
                'width': '200px',
                'vertical-align': 'middle',
                'padding':'10px',
                'css-class' : null,
              },
              children: new Array(),
              borderStyle: 'none',
              borderSize: '1px',
              borderColor: '#333333'
            },
            {
              tagName: 'mj-column',
              type: 'column',
              attributes: {
                'background-color': '#ffffff',
                'inner-background-color': '',
                'border': 'none',
                'border-radius': "0px",
                'width': '200px',
                'vertical-align': 'middle',
                'padding':'10px',
                'css-class' : null,
              },
              children: new Array(),
              borderStyle: 'none',
              borderSize: '1px',
              borderColor: '#333333'
            },
            {
              tagName: 'mj-column',
              type: 'column',
              attributes: {
                'background-color': '#ffffff',
                'inner-background-color': '',
                'border': 'none',
                'border-radius': "0px",
                'width': '200px',
                'vertical-align': 'middle',
                'padding':'10px',
                'css-class' : null,
              },
              children: new Array(),
              borderStyle: 'none',
              borderSize: '1px',
              borderColor: '#333333'
            }
          ],
        }
      ],    
    }
  },

  created(){
    bus.$on("editEmailTemplate", (id, editorType, type) => { 
      this.selectedMessage.id = id;
      this.getSavedEmailItems();
      this.editorType = editorType;
      this.loadEmailTemplate();
    })
  },

  watch: {
    emailDesign: {
      handler(newValue, oldValue) {
        this.unsafedChanges = true;
      },
      deep: true
    }
  },


  methods:{

    openEditEmailDialog(){
      this.editEmailDialog = true;
    },

    closeEditEmailDialog(){
      this.closeWithoutSaving.dialog = false;
      let savedChanges = true;
      this.editEmailDialog = false;
      this.$emit("updated", savedChanges);
    },

    closeEmailEditor(){
      if(this.unsafedChanges){
        this.closeWithoutSaving.dialog = true;
      }
      else{
        this.closeWithoutSave()
      }
    },

    async closeAndSave(){
      await this.saveMessage();
      this.closeEditEmailDialog();
    },

    getShowType(type){
      let typeItem = this.showTypes.find(showType => showType.id == type);
      if(typeItem){
        // return typeItem
        return typeItem.name[(this.$store.state.locale).toLowerCase()]
      }
      else{
        return null
      }
    },

    closeWithoutSave(){
      this.closeEditEmailDialog();
      this.selectedItem = null;
      this.selectedColumn = null;
      this.selectedRow = null;
      this.tab = 'general';
    },

    async saveMessage(){
      this.unsafedChanges = false;
      this.savingItem = true;
      await db.collection('messages').doc(this.selectedMessage.id).set({
        subject: this.selectedMessage.subject,
        content: this.emailDesign,
        lastChanges: new Date()
      }, {merge: true})
      this.savingItem = false;
      return
    },

    clearColorField(type){
      if(type == 'backgroundColor'){
        this.selectedItem.attributes['background-color'] = null;
      }
      if(type == 'backgroundColor'){
        this.selectedItem.attributes['background-color'] = null;
      }
      if(type == 'buttonBackgroundColor'){
        this.selectedItem.attributes['background-color'] = '#333333'
      }
      if(type == 'buttonText'){
        this.selectedItem.attributes['color'] = '#ffffff'
      }
      if(type == 'containerBackgroundColor'){
        this.selectedItem.attributes['container-background-color'] = '#ffffff'
      }
    },

    setBackgroundColor(value){
      this.selectedItem.backgroundColor = _.cloneDeep(value.hexa);
    },

    setTextColor(value){
      this.selectedItem.textColor = _.cloneDeep(value.hexa);
    },

    setBorder(){
      if(this.selectedItem.borderStyle != 'none'){
        this.selectedItem.attributes.border = `${this.selectedItem.borderStyle} ${this.selectedItem.borderSize} ${this.selectedItem.borderColor}`;
      }
      else{
        this.selectedItem.attributes.border = 'none';
      }
    },

    copyVariableToClipboard(text, index){
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text[(this.$store.state.locale).toLowerCase()]);
        return;
      }
      navigator.clipboard.writeText(text[(this.$store.state.locale).toLowerCase()]).then(()=> {
        this.emailVariablesFiltered.forEach(variable =>{
          variable.copy = false
        })
        this.emailVariablesFiltered[index].copy = true
        setTimeout(()=>{ 
          this.emailVariablesFiltered[index].copy = false
        }, 1000);

      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },

    setLink(){
      if(this.selectedItem.linkType == 'web'){
        this.selectedItem.attributes.href = this.selectedItem.webLink;
      }
      else if(this.selectedItem.linkType == 'email'){
        this.selectedItem.attributes.href = `mailto:${this.selectedItem.linkEmailAddress}?subject=${this.selectedItem.linkEmailSubject}&body=${this.selectedItem.linkEmailText}`;
      }
      else if(this.selectedItem.linkType == 'tel'){
        this.selectedItem.attributes.href = `tel:${this.selectedItem.linkPhone}`;
      }
      else if(this.selectedItem.linkType == 'file'){
        this.selectedItem.attributes.href = `${this.selectedItem.linkFileUrl} download`;
      }
      else{
        this.selectedItem.attributes.href = '';
      }
    },

    setRowFullWidth(){
      if(this.selectedItem.fullWidth){
        this.selectedItem.attributes['full-width'] = 'full-width';
      }
      else{
        this.selectedItem.attributes['full-width'] = '';
      }
    },

    setItemWidth(){
      this.selectedItem.attributes.width =`${Math.floor((6 * this.selectedItem.width))}px`; 
    },

    previewEmail(){
      console.log("previewMessageTemplate", this.selectedMessage.id, null, this.emailDesign, this.emailHeader, this.selectedMessage.subject, this.selectedMessage.type, 'email')
      bus.$emit("previewMessageTemplate", this.selectedMessage.id, null, this.emailDesign, this.emailHeader, this.selectedMessage.subject, this.selectedMessage.type, 'email');
    },

    openMediaLibrary(){
      this.libraryDialog = true;
      bus.$emit("openMediaLibrary");
    },

    setChosenImage(data){
      if(this.selectedItem.id == 'image'){
        this.selectedItem.imageName = data.fileName;
        this.selectedItem.size = `${data.width} x ${data.height}`;
        this.selectedItem.attributes.src = data.url;
        this.selectedItem.maxWidth = data.width;
      }
      if(this.selectedItem.id == 'button'){
        this.selectedItem.linkFileUrl = data.url;
        this.selectedItem.attributes.href = `${this.selectedItem.linkFileUrl} download`
      }
      
      this.closeLibraryDialog();
    },

    changeImageSrc(){
      this.selectedItem.imageName = null;
      this.selectedItem.size = null;
      if(this.selectedItem.attributes.src == '[branchLogoUrl]' || this.selectedItem.attributes.src == '[vestigingsLogoUrl]'){
        this.selectedItem.imageName = "Branch logo"
      }     
    },

    closeLibraryDialog(){
      this.libraryDialog = false;
      bus.$emit("clearMediaLibrarySelection");
    },

    saveEmailChanges(){
      this.selectedItem = null;
      this.selectedColumn = null;
      this.selectedRow = null;
      this.tab = 'add'
      this.selectedItemColumn = 'content';
      this.saveMessage();
    },

    clone: function(el){
      let item =  _.cloneDeep(el);
      item.uniqId = uniqid();
      if(item.type == 'row'){
        item.children.forEach(column=>{
          column.uniqId = uniqid();
        })
      }
    	return item
    },

    moveModule: function(el){
      if(el.to.className.includes("drag-row-wrapper")){
        return true;
      }
      else{
        return false;
      }    	
    },

    moveRow: function(el){
      if(el.to.id == 'email-wrapper'){
        return true;
      }
      else{
        return false;
      }    	
    },

    duplicateEmailItem(element, rowIndex, columnIndex, itemIndex){
      let item =  _.cloneDeep(element);
      item.uniqId = uniqid();
      if(item.type == 'row' || item.type == 'column'){
        item.children.forEach(child => {
          child.uniqId = uniqid();
          if(child.children && child.children.length > 0){
            child.children.forEach(subChild => {
              subChild.uniqId = uniqid();
            });
          }
        });
      }
      if(item.type == 'row'){
        this.emailDesign.children[0].children.splice(rowIndex, 0, item);
      }
      else{
        this.emailDesign.children[0].children[rowIndex].children[columnIndex].children.splice(itemIndex, 0, item);
      }
    },

    saveEmailItemDialog(item){
      this.saveEmailItemData.item = _.cloneDeep(item);
      this.saveEmailItemData.item.savedId = this.saveEmailItemData.item.uniqId;
      this.saveEmailItemData.item.uniqId = null;
      this.saveEmailItemData.dialog = true;
      this.saveEmailItemData.general = false;
      this.saveEmailItemData.name = "";
      this.saveEmailItemData.title = "Save row"
      this.saveEmailItemData.text = "Save this content row to use later in other emails.";
      
      if(item.type =='module'){
        this.saveEmailItemData.title = "Save block"
        this.saveEmailItemData.text = "Save this content block to use later in other emails.";
      }
    },

    getSavedEmailItems(){
      this.library.rows = new Array();
      this.library.modules = new Array(); 
      db.collection("savedEmailItems")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let savedItem = doc.data();
          savedItem.docId = doc.id;
          if(savedItem.savedItemType == 'row'){
            this.library.rows.push(savedItem);
          }
          else{
            this.library.modules.push(savedItem)
          }
        });
      })
    },

    saveEmailItem(){
      let item = _.cloneDeep(this.saveEmailItemData.item);
      item.companyId = this.$store.state.activeCompany.id;
      item.name = this.saveEmailItemData.name;
      item.general = this.saveEmailItemData.general;
      item.dateAdded = moment().format("YYYY-MM-DD");
      
      item.savedItemType = this.saveEmailItemData.item.type;
      db.collection("savedEmailItems")
      .add(item)
      .then((docRef) => {
        item.docId = docRef.id
      })
      .then(()=>{
        if(this.saveEmailItemData.item.type == 'row'){
          this.library.rows.push(item);
        }
        else{
          this.library.modules.push(item)
        }
      })
      this.saveEmailItemData.dialog = false;
    },
    
    removeEmailItemDialog(item, rowIndex, columnIndex){
      this.removeEmailItemData.id = item.uniqId;
      this.removeEmailItemData.type = item.type;
      this.removeEmailItemData.rowIndex = rowIndex >= 0 ? rowIndex : null;
      this.removeEmailItemData.columnIndex = columnIndex >= 0 ? columnIndex : null;
      this.removeEmailItemData.dialog = true;
      this.removeEmailItemData.text = "You’re about to delete a content row. Are you sure you want to do that?";
      if(item.type =='module'){
        this.removeEmailItemData.text = "You’re about to delete a content block. Are you sure you want to do that?";
      }
    },

    removeEmailItem(){
      this.deletingItem = true;
      if(this.removeEmailItemData.type == 'row'){
        this.emailDesign.children[0].children = this.emailDesign.children[0].children.filter(row=>{ return row.uniqId != this.removeEmailItemData.id})
      }
      else{
        this.emailDesign.children[0].children[this.removeEmailItemData.rowIndex].children[this.removeEmailItemData.columnIndex].children = this.emailDesign.children[0].children[this.removeEmailItemData.rowIndex].children[this.removeEmailItemData.columnIndex].children.filter(row=>{ return row.uniqId != this.removeEmailItemData.id})
      }
      this.deletingItem = false;
      this.removeEmailItemData.dialog = false;
    },

    removeItemFromLibrary(item){
      console.log(item.savedId)
      db.collection("savedEmailItems").doc(item.docId).delete()
      if(item.type == 'module'){
        this.library.modules = this.library.modules.filter(module=>{ return module.savedId != item.savedId})
      }
      else{
        this.library.rows = this.library.rows.filter(row=>{ return row.savedId != item.savedId})
      }
    },

    async loadEmailTemplate(){

      let emailDesign = {
        tagName: 'mjml',
        children: [{
            tagName: 'mj-body',
            children: new Array()
        },
        {
            tagName: 'mj-head',
            children: new Array()
        }]
      };
      let emailHeader = {
        tagName: 'mj-head',
        attributes: {},
        children: [
          {
            tagName: 'mj-style',
            attributes: {},
            content: ''
          }
        ]
      };
      this.savingItem = false;
      this.unsafedChanges = false;
      this.openEditEmailDialog();
      await db.collection('messages').doc(this.selectedMessage.id).get()
      .then(doc => {
        this.selectedMessage =  doc.data();
        this.selectedMessage.id = doc.id;
        this.emailDesign = this.selectedMessage.content ? this.selectedMessage.content : emailDesign;
        this.emailHeader = this.selectedMessage.header ? this.selectedMessage.header : emailHeader;
      });
      this.unsafedChanges = false;
      this.loading = false;
    },


    tabChange(){
      this.selectedItem = null;
      this.selectedColumn = null;
      this.selectedRow = null;
    },

    selectEmailItem(item, rowId, columnId){
      this.selectedItemType = item.type;
      if(item.id == 'divider'){
        this.selectedItemColumn = 'style';
      }
      else{
        this.selectedItemColumn = 'content';
      }  
      if(this.selectedItem == item){
        this.selectedItem = null;
        this.selectedRow = null;
        this.selectedColumn = null;
        this.tab = 'add'
      }
      else{
        this.selectedItem = item
        this.selectedRow = rowId
        this.selectedColumn = columnId
        this.tab = 'edit'
      }
    },

    selectedElement(id){
      if((this.selectedItem && id == this.selectedItem.uniqId && this.selectedItemType == 'module') || this.selectedRow && id == this.selectedRow && this.selectedItemType == 'row' || this.selectedColumn && id == this.selectedColumn && this.selectedItemType == 'column'){
        return true
      }
      else{
        return false
      }
    },
    
  },

  computed: {
    contentEditWidth(){
      if(this.selectedItem.id == 'divider'){
        return 'xs6';
      }
      else{
        return 'xs4';
      }
    },
    contentAvailable(){
      if(this.selectedItem.id == 'divider'){
        return false;
      }
      else{
        return true;
      }
    },
    settingsAvailable(){
      // if(this.selectedItem.id == 'text'){
      //   return false;
      // }
      // else{
      //   return true;
      // }
      return true;
    },
    emailVariablesFiltered(){
      // Filter email variables to show te right variables with the right email type
      let variables = new Array();
      let emailType = this.selectedMessage.type;

      this.emailVariables.forEach(variable=>{
        if(_.indexOf(variable.include, emailType) >= 0){
          if(this.editorType == 'admin' || (this.editorType == 'user' && variable.editorType == 'all')){
            variables.push(variable);
          }     
        }
      });
      return variables;
    },
    saveItemValid(){
      if(this.saveEmailItemData.name){
        return true
      }
      else{
        return false
      }
    }
    
  }
};
</script>

<style>

  .module-colum-button {
    width: 100%;
    text-align: center;
    font-size: 14px;
    padding: 8px 0;
    color: var(--primary-color);
    font-weight: bold;
    border-bottom: 1px solid var(--primary-color);
    border-top: 1px solid var(--primary-color);
    border-left: 1px solid var(--primary-color);
    border-right: 1px solid var(--primary-color);
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .module-colum-button:hover {
    cursor: pointer;
  }

  .module-colum-button.left {
    border-left: 1px solid!important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
  }

  .module-colum-button.middle {
    border-left: none;
    border-right: none;
  }

  .module-colum-button.right {
    border-right: 1px solid !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .module-colum-button.active {
    background-color: var(--primary-color);
    color: #ffffff;
    font-weight: bold;
  }
  .module-colum-button.active.right {
    border-right: none!important;
  }
  .module-colum-button.active.left {
    border-left: none!important;
  }

  .email-editor-button{
    cursor: pointer;
  }

  .draging{
    display: block;
  }
  
  .draging .email-row{
    /* border-top: 2px dotted rgba(var(--editor-row-color),1); */
    margin: 15px auto;
    padding-top: 15px;
  }

  .email-row:hover{
    background-color: #f9f9f9;
  }
  
  .sortable-chosen > .empty-column-content{
    display: none;
  }

  .empty-column-content{
    width: 100%;
    border: 1px dashed rgba(0,0,0,0.20);
    text-align: center;
    padding: 15px;
    background-color: rgba(0,0,0,0.05)
  }


  .email-card{
    display: inline-block;
    width: 100%;
  }

  #email-wrapper{
    position: relative;
    width: 100%;
    min-height: 150px;
  }

  .email-row,
  .email-column,
  .email-module{
    position: relative;
    margin: 0 auto;
  }

  /* .email-module{
    margin: 10px auto;
  } */

  .email-row:hover,
  .email-column:hover,
  .email-module:hover{
     border: 2px solid rgba(var(--editor-row-color),1);
     padding: 35px 5px 5px 5px!important; 
  }

  .email-row.selected,
  .email-column.selected,
  .email-module.selected{
     border: 2px solid rgba(var(--editor-row-color),1);
     padding: 35px 5px 5px 5px
  }

  .email-row:hover,
  .email-row.selected{
    margin: 5px auto!important;
    width: calc(100% - 10px);
  }

  .email-module:hover,
  .email-module.selected{
    padding: 35px 5px 5px 5px!important;
  }

  .email-column:hover,
  .email-column.selected{
     border: 2px solid rgba(var(--editor-column-color),1);
     padding: 35px 5px 5px 5px!important; 
  }

  .email-module:hover,
  .email-module.selected{
     border: 2px solid rgba(var(--editor-module-color),1);
  }

  .email-column:hover > .email-empty-column{
    border: none
  }

  .email-row:hover .email-row-edit,
  .email-column:hover .email-column-edit,
  .email-module:hover .email-module-edit,
  .email-row.selected .email-row-edit,
  .email-column.selected .email-column-edit,
  .email-module.selected .email-module-edit{
    display: block;
  }

  .email-module:hover .edit-text-item,
  .email-module.selected .edit-text-item{
    display: block;
  }

  .email-module:hover  .preview-text-item,
  .email-module.selected .preview-text-item{
    display: none;
  }

  .preview-text-item{
     display: block;
  }

  .edit-text-item{
    display: none;
  }

  .email-row-edit,
  .email-column-edit,
  .email-module-edit{
    display: none;
    position: absolute;
    top: -0;
    left: -0;
    width: 100%;
    background-color: rgba(var(--editor-row-color),0.85);
  }
  .email-column-edit{
    background-color: rgba(var(--editor-column-color),0.85);
  }
  .email-module-edit{
    background-color: rgba(var(--editor-module-color),0.85);
  }

  .email-row-edit:after,
  .email-column-edit:after,
  .email-module-edit:after{
    clear: both;
    display: block;
    content: "";
  }

  .email-row-edit-item.move-element{
    width: calc(100% - 150px);
    text-align: left;
  }


  .email-column-edit-item.move-element,
  .email-module-edit-item.move-element{
    width: calc(100% - 120px);
    text-align: left;
  }


  .email-row-edit-item,
  .email-column-edit-item,
  .email-module-edit-item{
    height: 30px;
    width: 30px;
    padding: 2px;
    text-align: center;
    float: left;
  }
  .email-column-edit-item{
    float: right;
  }

  .email-row-edit-item:hover,
  .email-column-edit-item:hover,
  .email-module-edit-item:hover{
    background-color: rgba(var(--editor-row-color),1);
    cursor: pointer;
  }

  .email-column-edit-item:hover{
    background-color: rgba(var(--editor-column-color),1);
  }

  .email-module-edit-item:hover{
    background-color: rgba(var(--editor-module-color),1);
  }

  .saved-item-wrapper:after,
  .modules-wrapper:after{
    display: block;
    content: "";
    clear: both;
  }


  .modules-wrapper .module-wrapper{
    width: calc(50% - 10px);
    margin: 5px;
    float: left;
  }

  .saved-item-name{
    width: calc(100% - 125px);
    padding: 0 10px 0 0;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .saved-item-date{
    width: 100px;
    padding: 0 10px 0 0;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .saved-item-name.general{
    width: calc(100% - 190px);
  }

  .saved-items .saved-item-remove,
  .saved-items .saved-item-tag{
    display: block;
  }

  .saved-item-tag{
    display: none;
    width: 75px;
    float: left;
  }
  .saved-item-remove{
    cursor: pointer;
    display: none;
    text-align: center;
    width: 25px;
    height: 20px;
    float: left;
  }

  .verte-picker__slider{
    width: auto;
    height: auto;
    overflow:visible;
  }

  .remove-all-styling.preview > a,
  .remove-all-styling.preview a,
  .remove-all-styling.preview p,
  .remove-all-styling.preview h1,
  .remove-all-styling.preview h2,
  .remove-all-styling.preview h3,
  .remove-all-styling.preview h4,
  .remove-all-styling.preview h5,
  .remove-all-styling .email-item-content a,
  .remove-all-styling .email-item-content p,
  .remove-all-styling .email-item-content h1,
  .remove-all-styling .email-item-content h2,
  .remove-all-styling .email-item-content h3,
  .remove-all-styling .email-item-content h4,
  .remove-all-styling .email-item-content h5{
    font-size: inherit;
    margin: 0;
    color: unset;
  }

  /* .remove-all-styling.preview > a,
  .remove-all-styling.preview a{
    color: unset;
  } */

  .email-preview .v-card__text{
    letter-spacing: 0px!important
  }

  .email-preview-wrapper{
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 50px;
  }
  .email-preview-wrapper.mobile{
    padding: 50px 10px 70px 10px;
    width: 320px;
    margin: 30px auto;
    border: 3px solid #333;
    border-radius: 30px;
    background-color: #000;

  }

  .email-preview-wrapper.mobile .email-preview-display{
    border: 1px solid #333;
    border-radius: 5px;
    height: 500px;
    overflow: scroll;
    margin: 0;
    padding: 0;
    background-color: #fff;
  }

  .full-height-container-preview{
    height: calc(100% - 65px)
  }

</style>
