<template>
  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
    <div class="schedule-wrapper" :style="{ minHeight: overlayHeight }">
      <div id="calendar-filter-wrapper" class="ma-2 mb-1">
        <div v-if="loading || calendarChecking" class="custom-loader-overlay" :style="{ minHeight: overlayHeight }">
          <div style="position: absolute; left: 50%; top: 50%; z-index: 999">
            <div class="thrive-loader" :class="$store.state.companyTheme"></div>
          </div>
        </div>

        <v-container v-show="!loading && resources.length > 0" grid-list-md class="pa-0" fluid>
          <v-layout row wrap>
            <v-flex md4>
              <v-btn v-if="!calendarDayView" class="mx-1" dark outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="calendarLoading" @click="openDuplicatingScheduleModal()">planning dupliceren</v-btn>
            </v-flex>
            <v-flex md4>
              <div class="text-center">
                <h3 style="font-size: 16px">
                  <strong>Week {{ selectedWeeknumber }}</strong>
                </h3>
                <p class="ma-0" style="font-size: 14px" v-if="calendarView == 'day'">{{ selectedDateFormatted }}</p>
                <p class="ma-0" style="font-size: 14px" v-if="calendarView == 'week'">{{ selectedWeekFirstDay }} - {{ selectedWeekLastDay }}</p>
              </div>
            </v-flex>
            <v-flex md4>
              <div class="text-right">
                <v-btn class="mx-1" dark :outlined="!calendarDayView" depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="calendarLoading" @click="dayView()">Dag</v-btn>
                <v-btn class="ml-1 mr-4" dark :outlined="calendarDayView" depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="weekView()">Week</v-btn>
                <v-btn class="mx-1" fab dark outlined small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="calendarLoading" @click="previous()">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn class="mx-1" fab dark outlined small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="calendarLoading" @click="next()">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container v-show="!loading && resources.length > 0" grid-list-md class="pa-0 ma-0 mt-0" fluid>
          <v-layout row wrap>
            <v-flex md6 xs12 d-flex>
              <v-card class="usertype-total" outlined style="width: 100%">
                <span style="font-size: 14px; font-weight: bold">Aangepast weekrooster terugzetten naar basisrooster</span>
                <v-container grid-list-md class="pa-0 pr-1 pb-1 ma-0 mt-1" fluid>
                  <v-layout row wrap>
                    <v-flex md6 xs12>
                      <v-select
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :items="employeesWithCustomScheduleForRemoving"
                        item-text="name"
                        item-value="id"
                        v-model="selectedEmployeesForRemoveSchedule"
                        label="Selecteer medewerker"
                        outlined
                        multiple
                        no-data-text="Geen medewerkers met een aangepast rooster"
                        hide-details
                        dense
                      ></v-select>
                    </v-flex>
                    <v-flex md6 md12>
                      <v-btn
                        class="ml-1 mr-4"
                        height="40px"
                        block
                        outlined
                        depressed
                        :dark="!removeScheduleDisabled"
                        :disabled="removeScheduleDisabled"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        @click="removeScheduleForSelectedEmployees()"
                      >Terug zetten</v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </v-flex>
            <v-flex md2 xs12 d-flex>
              <v-card class="usertype-total" outlined style="width: 100%">
                <div class="usertype-total-number" >{{ activeResources }}</div>
                <div class="usertype-total-desc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span>Medewerkers <v-icon small color="text" v-on="on">mdi-help-circle</v-icon></span>
                    </template>
                    <span class="white--text">Aantal actieve medewerkers <span class="white--text" v-if="calendarView == 'day'">vandaag</span><span class="white--text" v-if="calendarView == 'week'">deze week</span></span>
                  </v-tooltip>
                </div>
              </v-card>
            </v-flex>
            <v-flex md2 xs12 d-flex>
              <v-card class="usertype-total" outlined :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }" style="width: 100%">
                <div class="usertype-total-number">{{ totalHours }}</div>
                <div class="usertype-total-desc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span>Uren <v-icon small color="text" v-on="on">mdi-help-circle</v-icon></span>
                    </template>
                    <span class="white--text"
                      >Totaal aantal werkuren <span class="white--text" v-if="calendarView == 'day'">vandaag</span><span class="white--text" v-if="calendarView == 'week'">deze week</span></span
                    >
                  </v-tooltip>
                </div>
              </v-card>
            </v-flex>
            <v-flex md2 xs12 d-flex>
              <v-card outlined class="px-2 py-1">
                <v-container grid-list-md class="pa-0 ma-0 mt-0" fluid>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-card outlined color="#eeeeee" class="px-1">
                        <span style="font-size: 14px; font-weight: bold; margin: 1px 0 0 0; color: #333">Basisrooster</span>
                      </v-card>
                    </v-flex>
                    <v-flex xs12>
                      <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="px-1">
                        <span style="color: #fff; font-size: 14px; font-weight: bold; margin: 1px 0 0 0">Aangepast rooster</span>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>

      <div v-show="!loading && resources.length > 0" class="calendar-wrapper mt-2">
        <div style="min-width: 100%" :style="{ maxHeight: calendarHeight + 'px' }">
          <div style="position: absolute; left: 48.5%" :class="{ wolfload: wolfload }">
            <div class="blackbox" style="display: none">
              <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-dual-ring">
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke-linecap="square"
                  r="40"
                  stroke-width="16"
                  stroke="#FFFFFF"
                  stroke-dasharray="62.83185307179586 62.83185307179586"
                  transform="rotate(359.669 50.0012 50.0012)"
                  >
                  <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="0.4s" begin="0s" repeatCount="indefinite" />
                </circle>
              </svg>
            </div>
          </div>
          <FullCalendar
            ref="branchCalendar"
            :options="calendarOptions"
            selectConstraint="businessHours"
            eventConstraint="businessHours"
            :columnHeaderFormat="columnHeaderFormat"
            @eventDrop="eventResize"
            @eventClick="eventClick"
            @eventResize="eventResize"
            @select="select"
            :loading="calendarLoading"
            :resourceRender="resourceRender"
          />
        </div>
      </div>

      <div v-show="!loading && !calendarChecking && resources.length == 0">
        <p style="text-align: center; padding: 50px">Er zijn geen medewerkers actief voor de geselecteerde vestiging.</p>
      </div>

      <v-dialog hide-overlay v-model="removeDialog" persistent max-width="290" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
          <v-card-text class="text-center ma-0 pt-4">
            <v-avatar 
              size="56"
              light
              class="ma-4 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
            </v-avatar>
            <p class="ma-0">Verwijder de geselecteerde periode.</p>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="(removeDialog = false), refreshCalendar()"> Annuleren </v-btn>
            <v-spacer></v-spacer>
            <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeEvent()"> Verwijderen </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="duplicateScheduleModal" persistent max-width="600" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h2>Weekplanning dupliceren</h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="duplicateScheduleModal = false">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-alert class="ma-0 pa-2" v-if="duplicateFeedback" :value="true" type="warning">
            {{ duplicateFeedback }}
          </v-alert>

          <v-divider></v-divider>

          <v-card-text>
            <p class="my-4">Gebruik de exacte planning van deze week vaker door deze te dupliceren en toe te passen op een andere week.</p>
            <v-select
              class="mt-4"
              :items="resources"
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              background-color="white"
              backgroundColor="menu"
              item-value="id"
              item-text="title"
              hide-details
              multiple
              v-on:input="checkingDuplicatingSchedule()"
              v-model="selectedResourcesForCopy"
              label="Weekplanning dupliceren van:"
              outlined
              return-object
            >
            </v-select>
            <v-select
              class="mt-4"
              v-if="weeksInTheFuture.length > 0"
              :items="weeksInTheFuture"
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              background-color="white"
              backgroundColor="menu"
              item-value="weeknumber"
              item-text="name"
              hide-details
              v-on:input="checkingDuplicatingSchedule()"
              v-model="selectedWeekForCopy"
              label="Weekplanning dupliceren naar:"
              outlined
            >
            </v-select>

            <div class="pa-3 mt-4" v-if="adjustedScheduleEmployees.length > 0" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }">
              <p class="mb-1">Er is al een aangepaste planning gevonden voor:</p>
              <div v-for="(employee, index) in adjustedScheduleEmployees" :key="index">
                <strong>&#x2022; {{ employee.name }}</strong>
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="duplicateScheduleModal = false"> Annuleren </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="duplicatePlanning()">
                    Planning dupliceren
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar.active" :color="snackbar.color" top :timeout="snackbar.timeout">
        <v-row>
          <v-icon color="white" class="mx-3">{{ snackbar.icon }}</v-icon>
          <span class="py-2 white--text">{{ snackbar.text }}</span>
        </v-row>
      </v-snackbar>
    </div>
  </v-card>
</template>

<script>
  import { bus } from "../main";
  import "@firebase/firestore";
  import db from "../firebase/init";
  import platform from "platform";
  import moment from "moment";

  //Full Calendar

  import FullCalendar from "@fullcalendar/vue";
  import interactionPlugin from "@fullcalendar/interaction";
  import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import scrollgrid from "@fullcalendar/scrollgrid";
  // import "@fullcalendar/core/main.css";
  // import "@fullcalendar/daygrid/main.css";
  // import "@fullcalendar/timegrid/main.css";

  export default {
    components: {
      FullCalendar,
    },

    name: "branch-schedules",

    data() {
      return {
        activeBranchData: new Object(),
        activeBranchDataOriginal: new Object(),
        branchDataLoading: true,
        // Full Calendar
        calendarOptions: {
          plugins: [dayGridPlugin, interactionPlugin, resourceTimeGridPlugin, scrollgrid],
          initialView: "resourceTimeGridDay",
          dayMinWidth: 135,
          headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "resourceTimeGridWeek",
          },
          businessHours: {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime: "05:00",
            endTime: "23:59",
          },
          slotLabelFormat: {
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          },

          firstDay: 1,
          titleFormat: { year: "numeric", month: "2-digit", day: "2-digit" },
          schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
          slotMinTime: "05:00:00",
          slotMaxTime: "23:59:00",
          slotDuration: "00:30:00",
          contentHeight: "auto",
          slotEventOverlap: false,
          eventTextColor: "#000000",
          eventBorderColor: "#1f89e5",
          eventOverlap: false,
          selectOverlap: false,
          dayMaxEvents: true,
          weekends: true,
          selectable: true,
          selectMirror: true,
          eventStartEditable: true,
          eventDurationEditable: true,
          eventResizableFromStart: true,
          eventResourceEditable: false,
          refetchResourcesOnNavigate: true,
          droppable: false,

          dayHeaderFormat: { weekday: "long" },
          displayEventTime: true,

          eventResizableFromStart: false,
          timeZone: "local",
          locale: "NL",
          allDaySlot: false,
          resources: new Array(),
          initialEvents: this.fetchEvents,
          loading: this.isLoading,
          resourceLabelContent: (arg) => {
            return this.resourceRender(arg);
          },
          eventResize: (eventData) => {
            return this.eventResize(eventData);
          },
          eventDrop: (eventData) => {
            return this.eventResize(eventData);
          },
          eventClick: (eventData) => {
            return this.eventClick(eventData);
          },
          select: (eventData) => {
            return this.select(eventData);
          },
        },
        //End Calendar

        columnHeaderFormat: { weekday: "short" },
        wolfload: false,
        dayformat: "dddd",
        duplicateScheduleModal: false,
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: false,
          hour12: false,
        },
        eventResizableFromStart: true,
        events: [],

        calendarChecking: true,
        removeDialog: false,
        selectedEvent: null,
        loading: true,
        feedback: null,
        filterToday: true,
        filterWeek: false,
        filterMonth: false,
        filterPeriod: false,
        searchCompanies: "",
        selectedPeriodDay: "01",
        selectedPeriodYear: moment().year(),
        selectedPeriodMonth: Number(moment().format("M")),
        resources: [],
        compStartYear: null,
        compStartMonth: null,
        today: null,
        calendarView: "day",
        calendarHeight: "1000",
        selectedWeekFirstDay: null,
        selectedWeekLastDay: null,
        selectedDate: null,
        selectedDateFormatted: null,
        selectedYear: null,
        selectedWeeknumber: null,
        conversionPeriodType: "day",
        conversionPeriodStart: null,
        conversionPeriodEnd: null,
        currentMonth: Number(moment().format("M")),
        selectedSources: [],
        calendar: null,
        activeResources: 0,
        totalHours: 0,
        weeksInTheFuture: [],
        duplicateFeedback: null,
        selectedResourcesForCopy: null,
        selectedWeekForCopy: null,
        adjustedScheduleEmployees: [],
        employeesWithCustomSchedule: [],
        selectedEmployeesForRemoveSchedule: [],
        customSchedules: [],
        overlayHeight: 0,
        snackbar: {
          active: false,
          text: "",
          icon: "",
          timeout: -1,
          color: "",
        },
        positions: [],
        employees: [],
      };
    },

    created() {
      
      this.$root.$on("branchChanged", () => {
        this.loadBranchData();
      });
      bus.$on("loadBranchData", () => {
        this.loadBranchData();
      });
      this.loadBranchData();
    },

    beforeDestroy(){
      window.removeEventListener("resize", this.calculateCalendarHeight, this.calculateOverlayHeight);
    },

    methods: {

      loadBranchData(){
        this.branchDataLoading = true;
        this.activeBranchData = _.cloneDeep(this.$store.state.activeBranch);
        this.activeBranchDataOriginal = _.cloneDeep(this.activeBranchData);
        this.getEmployees();
        this.calculcateWeeksInTheFuture();
        this.today = moment().unix();
        this.selectedDate = moment().format("YYYY-MM-DD");
        this.selectedDateFormatted = moment(this.selectedDate).locale("nl").format("dddd D MMMM YYYY");
        this.selectedYear = moment().year();
        this.selectedWeeknumber = moment(moment(this.selectedDate)).isoWeek();
        this.selectedWeekFirstDay = moment(moment(this.selectedDate)).day(1).format("D MMMM YYYY");
        this.selectedWeekLastDay = moment(moment(this.selectedDate)).day(7).format("D MMMM YYYY");
        window.addEventListener("resize", this.calculateCalendarHeight, this.calculateOverlayHeight);
        this.calculateOverlayHeight();
        this.branchDataLoading = false;
      },

      // SELECT EVENT
      eventClick(info) {
        this.removeDialog = true;
        this.selectedEvent = info.event;

        info.el.style.backgroundColor = this.$themes[$store.state.companyTheme][$store.state.themeModus].primary;
      },

      dayView() {
        this.calendar.changeView("resourceTimeGridDay");
        this.calendarView = "day";
        this.calculateSummary();
        this.calendar.gotoDate(this.selectedDate);
        this.$refs.branchCalendar.getApi().refetchEvents();
      },

      weekView() {
        this.calendar.changeView("resourceTimeGridWeek");
        this.calendarView = "week";
        this.calculateSummary();
        this.calendar.gotoDate(this.selectedDate);
        this.$refs.branchCalendar.getApi().refetchEvents();
      },

      isLoading(isLoading) {
        if (isLoading) {
          // this.wolfload = true;
          // console.log("Loading Calendar started...");
        } else {
          this.calculateCalendarHeight();
          // console.log("Loading Calender finished...");
          // setTimeout(() => {
          //   this.wolfload = false;
          // }, 500);
        }
      },

      calculcateWeeksInTheFuture() {
        let weeks = [];
        let currentWeek = moment().isoWeek();
        let currentYear = moment().format("YYYY");
        let weeksleft = 53 - currentWeek;
        weeks = Array.apply(null, Array(weeksleft));
        let filledWeeks = [];

        let weekArrayNumber = 0;
        let counter = 0;

        if (weeks.length > 0) {
          new Promise((resolve) => {
            weeks.forEach((week) => {
              let start = moment(currentYear)
                .add(currentWeek + weekArrayNumber, "weeks")
                .startOf("isoweek")
                .format("D-MM-YYYY");
              let end = moment(currentYear)
                .add(currentWeek + weekArrayNumber, "weeks")
                .endOf("isoweek")
                .format("D-MM-YYYY");
              let weeknumber = currentWeek + weekArrayNumber + 1;
              let weekData = {
                start: start,
                end: end,
                weeknumber: weeknumber,
                name: "Week  (" + weeknumber + ": " + start + " t/m " + end + ")",
              };
              filledWeeks.push(weekData);
              weekArrayNumber++;
              counter++;
            });
            if (counter == weeks.length) {
              resolve();
            }
          }).then(() => {
            this.weeksInTheFuture = filledWeeks;
          });
        } else {
          this.weeksInTheFuture = filledWeeks;
        }
      },

      removeScheduleForSelectedEmployees() {
        this.loading = true;
        let toRemoveSchedules = _.filter(this.customSchedules, (o) => {
          return _.indexOf(this.selectedEmployeesForRemoveSchedule, o.employeeId) >= 0;
        });

        new Promise((resolve) => {
          let counter = 0;
          toRemoveSchedules.forEach((schedule) => {
            db.collection("schedules")
              .doc(schedule.id)
              .delete()
              .then(() => {
                counter++;
                if (counter == toRemoveSchedules.length) {
                  resolve();
                }
              })
              .catch((error) => {
                console.error("Error removing custom schedule: ", error);
                counter++;
                this.deleteCounter++;
                if (counter == toRemoveSchedules.length) {
                  resolve();
                }
              });
          });
        }).then(() => {
          this.getEmployees();
        });
      },

      removeEvent() {
        let counter = 0;
        let removeIndex;
        let resourceId;
        new Promise((resolve) => {
          this.events.forEach((event, index) => {
            if (event.id == this.selectedEvent.id) {
              removeIndex = index;
            }
            counter++;
            if (counter == this.events.length) {
              resolve();
            }
          });
        }).then(() => {
          resourceId = this.events[removeIndex].resourceId;
          this.events.splice(removeIndex, 1);
          this.removeDialog = false;
          this.refreshCalendar();
          this.updatedResourceSchedulde(resourceId);
        });
      },

      openDuplicatingScheduleModal() {
        this.adjustedScheduleEmployees = [];
        this.selectedWeekForCopy = null;
        this.selectedResourcesForCopy = _.cloneDeep(this.resources);
        this.duplicateScheduleModal = true;
      },

      // Pre-check before duplicating
      checkingDuplicatingSchedule() {
        this.adjustedScheduleEmployees = [];
        this.selectedResourcesForCopy.forEach((resource) => {
          resource.databaseDocId = null;

          db.collection("schedules")
            .where("companyId", "==", this.$store.state.activeCompany.id)
            .where("branchId", "==", this.activeBranchData.id)
            .where("year", "==", this.selectedYear)
            .where("week", "==", this.selectedWeekForCopy)
            .where("employeeId", "==", resource.id)
            .get()
            .then((snap) => {
              if (snap.size > 0) {
                snap.forEach((doc) => {
                  let adjustedScheduleEmployee = {
                    id: resource.id,
                    name: resource.title,
                  };
                  resource.databaseDocId = doc.id;
                  this.adjustedScheduleEmployees.push(adjustedScheduleEmployee);
                });
              }
            });
        });
      },

      duplicatePlanning() {
        let resourceCounter = 0;
        new Promise((resolve) => {
          this.selectedResourcesForCopy.forEach((resource) => {
            let counter = 0;
            let databaseDocId;
            let schedule = {
              Sun: [],
              Mon: [],
              Tue: [],
              Wed: [],
              Thu: [],
              Fri: [],
              Sat: [],
            };

            new Promise((resolve) => {
              this.events.forEach((event) => {
                let dayOfWeek = "Sun";
                if (event.resourceId == resource.id) {
                  if (event.day == "1") {
                    dayOfWeek = "Mon";
                  }
                  if (event.day == "2") {
                    dayOfWeek = "Tue";
                  }
                  if (event.day == "3") {
                    dayOfWeek = "Wed";
                  }
                  if (event.day == "4") {
                    dayOfWeek = "Thu";
                  }
                  if (event.day == "5") {
                    dayOfWeek = "Fri";
                  }
                  if (event.day == "6") {
                    dayOfWeek = "Sat";
                  }
                  schedule[dayOfWeek].push({
                    start: moment(event.start).format("HH:mm"),
                    end: moment(event.end).format("HH:mm"),
                  });
                  counter++;
                  if (counter == this.events.length) {
                    resolve();
                  }
                } else {
                  counter++;
                  if (counter == this.events.length) {
                    resolve();
                  }
                }
              });
            })
            .then(() => {
              if (resource.databaseDocId) {
                db.collection("schedules")
                  .doc(resource.databaseDocId)
                  .set(
                    {
                      schedule: schedule,
                    },
                    { merge: true }
                  )
                  .then(() => {
                    //console.log("Database document updated")
                    resourceCounter++;
                    if (resourceCounter == this.selectedResourcesForCopy.length) {
                      resolve();
                    }
                  })
                  .catch((error) => {
                    this.duplicateFeedback("Planning dupliceren niet gelukt.");
                    //console.error("Error updated schedule for duplication: ", error);
                  });
              }
              else {
                db.collection("schedules")
                  .doc()
                  .set(
                    {
                      companyId: this.$store.state.activeCompany.id,
                      branchId: this.activeBranchData.id,
                      employeeId: resource.id,
                      week: this.selectedWeekForCopy,
                      year: this.selectedYear,
                      schedule: schedule,
                    },
                    { merge: true }
                  )
                  .then(() => {
                    //console.log("Nieuw database document aangemaakt")
                    resourceCounter++;
                    if (resourceCounter == this.selectedResourcesForCopy.length) {
                      resolve();
                    }
                  })
                  .catch((error) => {
                    this.duplicateFeedback("Planning dupliceren niet gelukt.");
                    console.error("Error adding schedule for duplication: ", error);
                  });
              }
            });
          });
        })
        .then(() => {
          this.snackbar.active = true;
          this.snackbar.timeout = 3000;
          this.snackbar.color = "rgba(1, 230, 110, 0.7)";
          this.snackbar.icon = "mdi-thumb-up";
          this.snackbar.text = "Weekplanning succesvol gedupliceerd.";
          this.duplicateScheduleModal = false;
        });
      },

      // NIEUWE EVENT op basis van slepen in de calendar
      select(info) {
        let resourceId = info.resource.id;
        let mergeAvailable = false;
        let mergeEventIndexBefore;
        let mergeEventIndexAfter;
        let mergeTypeBefore = false;
        let mergeTypeAfter = false;
        let selectedDate = moment(info.start).format("YYYY-MM-DD");
        let start = moment(info.start).format("YYYY-MM-DD" + "T" + "HH:mm");
        let end = moment(info.end).format("YYYY-MM-DD" + "T" + "HH:mm");
        let counter = 0;
        new Promise((resolve) => {
          this.events.forEach((event, index) => {
            if (event.resourceId == resourceId) {
              this.events[index].backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
              this.events[index].textColor = "#ffffff";
            }
            if (event.resourceId == resourceId && event.start == end) {
              mergeAvailable = true;
              mergeTypeBefore = true;
              mergeEventIndexBefore = index;
            }
            if (event.resourceId == resourceId && event.end == start) {
              mergeAvailable = true;
              mergeTypeAfter = true;
              mergeEventIndexAfter = index;
            }
            counter++;
            if (counter == this.events.length) {
              resolve();
            }
          });
        })
          .then(() => {
            if (mergeAvailable) {
              if (mergeTypeBefore && mergeTypeAfter) {
                this.events[mergeEventIndexAfter].end = this.events[mergeEventIndexBefore].end;
                this.events[mergeEventIndexAfter].backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
                this.events[mergeEventIndexAfter].textColor = "#ffffff";
                this.events.splice(mergeEventIndexBefore, 1);
              } else if (mergeTypeBefore && !mergeTypeAfter) {
                this.events[mergeEventIndexBefore].start = start;
                this.events[mergeEventIndexBefore].backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
                this.events[mergeEventIndexBefore].textColor = "#ffffff";
              } else if (!mergeTypeBefore && mergeTypeAfter) {
                this.events[mergeEventIndexAfter].end = end;
                this.events[mergeEventIndexAfter].backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
                this.events[mergeEventIndexAfter].textColor = "#ffffff";
              }
            } else {
              let event = {
                id: this.events.length + "new",
                day: String(moment(selectedDate).day()).toLowerCase(),
                resourceId: info.resource.id,
                start: start,
                end: end,
                backgroundColor: this.$themes[this.companyTheme][this.theme].primary,
                textColor: "#ffffff",
              };
              this.events.push(event);
            }
          })
          .then(() => {
            this.calendar.unselect();
            this.refreshCalendar();
            this.updatedResourceSchedulde(resourceId);
          });
      },

      // RESIZE OF VERPLAATSEN EVENT
      eventResize(info) {
        let resources = info.event.getResources();
        let resourceId = resources[0].id;
        let mergeAvailable = false;
        let mergeTypeAfter = false;
        let mergeTypeBefore = false;
        let selectedEventIndex;
        let mergeEventIndexBefore;
        let mergeEventIndexAfter;
        let mergeType;
        let start = moment(info.event.start).format("YYYY-MM-DD" + "T" + "HH:mm");
        let end = moment(info.event.end).format("YYYY-MM-DD" + "T" + "HH:mm");
        let counter = 0;
        new Promise((resolve) => {
          this.events.forEach((event, index) => {
            if (event.resourceId == resourceId) {
              this.events[index].backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
              this.events[index].textColor = "#ffffff";
            }
            if (info.event.id == event.id) {
              selectedEventIndex = index;
            }
            if (event.resourceId == resourceId && event.start == end && index !== selectedEventIndex) {
              mergeAvailable = true;
              mergeTypeBefore = true;
              mergeEventIndexBefore = index;
            }
            if (event.resourceId == resourceId && event.end == start && index !== selectedEventIndex) {
              mergeAvailable = true;
              mergeTypeAfter = true;
              mergeEventIndexAfter = index;
            }
            counter++;
            if (counter == this.events.length) {
              resolve();
            }
          });
        })
          .then(() => {
            if (mergeAvailable) {
              if (mergeTypeBefore && mergeTypeAfter) {
                this.events[mergeEventIndexAfter].end = this.events[mergeEventIndexBefore].end;
                this.events[mergeEventIndexAfter].backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
                this.events[mergeEventIndexAfter].textColor = "#ffffff";
                this.events.splice(selectedEventIndex, 1);
                this.events.splice(mergeEventIndexBefore, 1);
              } else if (mergeTypeBefore && !mergeTypeAfter) {
                this.events[mergeEventIndexBefore].start = start;
                this.events[mergeEventIndexBefore].backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
                this.events[mergeEventIndexBefore].textColor = "#ffffff";
                this.events.splice(selectedEventIndex, 1);
              } else if (!mergeTypeBefore && mergeTypeAfter) {
                this.events[mergeEventIndexAfter].end = end;
                this.events[mergeEventIndexAfter].backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
                this.events[mergeEventIndexAfter].textColor = "#ffffff";
                this.events.splice(selectedEventIndex, 1);
              }
            } else {
              this.events[selectedEventIndex].start = start;
              this.events[selectedEventIndex].end = end;
              this.events[selectedEventIndex].backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
              this.events[selectedEventIndex].textColor = "#ffffff";
            }
          })
          .then(() => {
            this.refreshCalendar();
            this.updatedResourceSchedulde(resourceId);
          });
      },

      next() {
        let weekNumberOriginal = _.cloneDeep(this.selectedWeeknumber);
        this.selectedEmployeesForRemoveSchedule = [];
        this.calendar.next();

        if (this.calendarView == "week") {
          this.selectedDate = moment(this.selectedDate).add(7, "days").format("YYYY-MM-DD");
        } else {
          this.selectedDate = moment(this.selectedDate).add(1, "days").format("YYYY-MM-DD");
        }
        this.selectedDateFormatted = moment(this.selectedDate).locale("nl").format("dddd D MMMM YYYY");
        this.selectedYear = moment(this.selectedDate).year();
        this.selectedWeeknumber = moment(moment(this.selectedDate)).isoWeek();
        this.selectedWeekFirstDay = moment(this.selectedDate).isoWeek('week').startOf('week').format("D MMMM YYYY");
        this.selectedWeekLastDay = moment(this.selectedDate).isoWeek('week').endOf('week').format("D MMMM YYYY");

        if (weekNumberOriginal !== this.selectedWeeknumber) {
          console.log("getSchedules")
          this.getSchedules();
        } 
        else {
          this.calculateSummary();
        }
        this.$refs.branchCalendar.getApi().refetchEvents();
      },

      previous() {
        let weekNumberOriginal = _.cloneDeep(this.selectedWeeknumber);
        this.selectedEmployeesForRemoveSchedule = [];
        this.calendar.prev();

        if (this.calendarView == "week") {
          this.selectedDate = moment(this.selectedDate).subtract(7, "days").format("YYYY-MM-DD");
        } else {
          this.selectedDate = moment(this.selectedDate).subtract(1, "days").format("YYYY-MM-DD");
        }
        this.selectedDateFormatted = moment(this.selectedDate).locale("nl").format("dddd D MMMM YYYY");
        this.selectedYear = moment(this.selectedDate).year();
        this.selectedWeeknumber = moment(moment(this.selectedDate)).isoWeek();
        this.selectedWeekFirstDay = moment(this.selectedDate).isoWeek('week').startOf('week').format("D MMMM YYYY");
        this.selectedWeekLastDay = moment(this.selectedDate).isoWeek('week').endOf('week').format("D MMMM YYYY");

        if (weekNumberOriginal !== this.selectedWeeknumber) {
          this.getSchedules();
        }
        else {
          this.calculateSummary();
        }
        this.$refs.branchCalendar.getApi().refetchEvents();
      },

      resourceRender(resourceObj) {
        let resHeader = document.createElement("div");
        let title = document.createElement("div");
        let hours = document.createElement("p");
        hours.setAttribute("style", " font-size: 12px; margin: 0; padding: 0;");
        title.setAttribute("style", " font-size: 14px; font-weight: bold;");

        if (resourceObj.resource.extendedProps.hours) {
          hours.innerHTML = resourceObj.resource.extendedProps.hours + " uur";
        }
        else {
          hours.innerHTML = "Geen uren";
        }

        title.innerHTML = resourceObj.resource.title;

        resHeader.appendChild(hours);

        let arrayOfDomNodes = [title, resHeader];
        return { domNodes: arrayOfDomNodes };
      },

      calculateCalendarHeight() {
        let height = null;
        let filterHeight = document.getElementById("calendar-filter-wrapper").clientHeight;
        let windowHeight = window.innerHeight;
        let availableHeight = windowHeight - (filterHeight + 180);
        height = availableHeight + "px";
        // this.$refs.branchCalendar.getApi().setOption("contentHeight");
        this.calendarHeight = availableHeight;
        this.calendarOptions.contentHeight = availableHeight;
        this.calculateOverlayHeight();
      },

      calculateOverlayHeight() {
        let windowHeight = window.innerHeight;
        this.overlayHeight = windowHeight - 220 + "px";
      },

      getEmployees() {
        this.loading = true;
        this.employees = [];
        db.collection("employees")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("branches", "array-contains", this.activeBranchData.id)
          .where("active", "==", true)
          .get()
          .then((snap) => {
            snap.forEach((doc) => {
              let employee = doc.data();
              employee.id = doc.id;
              employee.positionName = "";
              employee.hours = 0;
              employee.hourRevenueGoal = 0;
              employee.title = doc.data().firstName + " " + doc.data().lastName;

              let days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
              employee.basicScheduleMinutes = new Object();
              let counter = 0;

              new Promise((resolve)=>{
                if(employee.basicSchedule){ // Check if basicSchedule is not empty
                  for (const [key] of Object.entries(employee.basicSchedule)) { // Loop through branches 
                    days.forEach((day)=>{
                      if(employee.basicSchedule[key][day] && employee.basicSchedule[key][day].length > 0){
                        employee.basicSchedule[key][day].forEach((time)=>{
                          employee.basicScheduleMinutes[key] = employee.basicScheduleMinutes[key] ? Number(employee.basicScheduleMinutes[key]) + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes")) : Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                        })
                      }
                      counter++
                      if(counter == days.length * Object.keys(employee.basicSchedule).length){
                        resolve()
                      }
                    })
                  }
                }
              })
              .then(()=>{
                this.employees.push(employee);
              })
            });
          })
          .then(() => {
            this.loading = false;
            // this.calculateCalendarHeight();
            this.getSchedules();
          })
          .catch((error) => {
            console.error("Laden van employees is niet gelukt: ", error);
          });
      },

      async fetchEvents() {
        return this.events;
      },

      getSchedules() {
        if (this.employees.length > 0) {
          this.calendarChecking = true;
          this.resources = [];
          this.events = [];
          this.employeesWithCustomSchedule = [];
          this.customSchedules = [];
          let counter = 0;
          new Promise((resolve) => {
            this.employees.forEach((employee) => {
              let schedule = null;
              let scheduleDocId = null;
              let scheduleType = "scheduleBasic";
              db.collection("schedules")
                .where("companyId", "==", this.$store.state.activeCompany.id)
                .where("branchId", "==", this.activeBranchData.id)
                .where("year", "==", this.selectedYear)
                .where("week", "==", this.selectedWeeknumber)
                .where("employeeId", "==", employee.id)
                .get()
                .then((snap) => {
                  if (snap.size > 0) {
                    // console.log("Uitzondering op basisschema");
                    snap.forEach((doc) => {
                      schedule = doc.data().schedule;
                      schedule.id = doc.id;
                      schedule.employeeId = doc.data().employeeId;
                      scheduleDocId = doc.id;
                      this.customSchedules.push(schedule);
                    });
                    scheduleType = "scheduleExample";
                  } else {
                    // console.log("Geen uitzondering op basisschema")
                    if (employee.basicSchedule[this.activeBranchData.id]) {
                      schedule = employee.basicSchedule[this.activeBranchData.id];
                    }
                  }
                })
                .then(() => {
                  let mondayOfWeek = moment(this.selectedDate).startOf('week').add(1, 'day').format("YYYY-MM-DD")
                  // console.log("Start of week: ", moment(this.selectedDate).startOf('week').format("DD-MM-YYYY"))
                  // console.log("Start of week maandag: ", mondayOfWeek)
                  if (schedule) {
                    if (schedule.employeeId) {
                      this.employeesWithCustomSchedule.push(schedule.employeeId);
                    }
                    const days = Object.keys(schedule);
                    for (const day of days) {
                      let dayOfWeek = null;
                      if (day == "Mon") {
                        dayOfWeek = 1;
                      }
                      if (day == "Tue") {
                        dayOfWeek = 2;
                      }
                      if (day == "Wed") {
                        dayOfWeek = 3;
                      }
                      if (day == "Thu") {
                        dayOfWeek = 4;
                      }
                      if (day == "Fri") {
                        dayOfWeek = 5;
                      }
                      if (day == "Sat") {
                        dayOfWeek = 6;
                      }
                      if (day == "Sun") {
                        dayOfWeek = 7;
                      }
                      if (dayOfWeek != null) {
                        schedule[day].forEach((item) => {
                          let event = {
                            day: dayOfWeek,
                            startEditable: true,
                            id: this.events.length,
                            docId: scheduleDocId,
                            resourceId: employee.id,
                            start: moment(mondayOfWeek).locale("nl").day(dayOfWeek).format("YYYY-MM-DD") + "T" + item.start,
                            end: moment(mondayOfWeek).locale("nl").day(dayOfWeek).format("YYYY-MM-DD") + "T" + item.end,
                            backgroundColor: "#eeeeee",
                            textColor: "#000000",
                          };
                          if (scheduleType == "scheduleExample") {
                            event.backgroundColor = this.$themes[this.companyTheme][this.theme].primary;
                            event.textColor = "#ffffff";
                            event.classNames = ["edited"];
                          }
                          this.events.push(event);
                        });
                      }
                    }
                  }
                  
                  this.resources.push(employee);
                  counter++;
                  if (counter == this.employees.length) {
                    this.calendarOptions.resources = this.resources;
                    resolve();
                  }
                });
            });
          })

            .then(() => {
              this.calendar = null;
              this.calendar = this.$refs.branchCalendar.getApi();
              this.$refs.branchCalendar.getApi().refetchEvents();

              this.calculateSummary();
            })
            .then(() => {
              this.calendarChecking = false;
            });
        }
        else {
          // console.log("Calendar Succesvol geladen leeg: ", this.calendar);
          this.calendar = null;
          this.calendar = this.$refs.branchCalendar.getApi();
          this.calendar.render();
          this.calendarChecking = false;
          this.calculateSummary();
        }
      },

      calculateSummary() {
        // Events van vandaag of week filteren per resource en totaal uren weergeven
        let activeResources = {};
        let startDate;
        let endDate;
        let counter = 0;
       

        if (this.calendarView == "day") {
          startDate = moment(this.selectedDate).format("YYYY-MM-DD") + "T00:00";
          endDate = moment(this.selectedDate).format("YYYY-MM-DD") + "T23:59";
        }
        else { // Weekview
          startDate = moment(this.selectedWeekFirstDay, "D MMMM YYYY").format("YYYY-MM-DD") + "T00:00";
          endDate = moment(this.selectedWeekLastDay, "D MMMM YYYY").format("YYYY-MM-DD") + "T23:59";
          
        }
        
        new Promise((resolve) => {
          if(this.events.length > 0){
            this.events.forEach((event) => {
              this.resources.forEach((resource) => {
                if (event.start >= startDate && event.end <= endDate && event.resourceId == resource.id) {
                  if (!activeResources[resource.id]) {
                    activeResources[resource.id] = [];
                    activeResources[resource.id].push(event);
                  } 
                  else {
                    activeResources[resource.id].push(event);
                  }
                }
              });
              counter++;
              if (counter == this.events.length) {
                resolve();
              }
            });
          }
          else{
            resolve();
          }
        })
        .then(() => {
          let timeCounter = 0;
          this.totalHours = 0;
          const activeResource = Object.keys(activeResources);
          new Promise((resolve) => {
            if(activeResource.length > 0){
              for (const resource of activeResource) {
                if (activeResources[resource].length > 0) {
                  activeResources[resource].hours = 0;
                  activeResources[resource].forEach((event) => {
                    activeResources[resource].hours = activeResources[resource].hours + Number(moment(event.end).diff(moment(event.start), "minutes") / 60);
                    this.totalHours = this.totalHours + Number(moment(event.end).diff(moment(event.start), "minutes") / 60);
                  });
                }
                timeCounter++;
                if (timeCounter == activeResource.length) {
                  resolve();
                }
              }
            }
            else{
              resolve()
            }
          })
          .then(() => {
            let employeeCounter = 0;
            new Promise((resolve) => {
              this.employees.forEach((employee) => {
                employee.positionName = "";
                employee.hours = 0;
                employee.hourRevenueGoal = 0;

                this.positions.forEach((position) => {
                  if (position.id == employee.position) {
                    employee.positionName = position.name;
                    employee.hourRevenueGoal = position.revenue;
                  }
                });
                employeeCounter++;
              });
              if (employeeCounter == this.employees.length) {
                resolve();
              }
            })
            .then(() => {
              let employeeCounterTwo = 0;
              this.goalRevenue = 0;
              this.activeResources = 0;

              new Promise((resolve) => {
                this.employees.forEach((employee) => {
                  if (activeResources[employee.id]) {
                    this.activeResources++;
                    employee.hours = activeResources[employee.id].hours;
                    employee.revenueGoal = activeResources[employee.id].hours * employee.hourRevenueGoal;
                    this.goalRevenue = this.goalRevenue + employee.revenueGoal;
                  }
                  else{
                    employee.hours = 0;
                    employee.revenueGoal = 0;
                    this.goalRevenue = 0;
                  }
                  employeeCounterTwo++;
                });
                if (employeeCounterTwo == this.employees.length) {
                  resolve();
                }
              })
              .then(() => {
                this.$refs.branchCalendar.getApi().refetchEvents();
                this.$refs.branchCalendar.getApi().refetchResources();
              });
            });
          });
        });
      },

      updatedResourceSchedulde(resource) {
        // console.log("updatedResourceSchedulde: ", resource);
        let counter = 0;
        let databaseDocId = _.find(this.customSchedules, { employeeId: resource });
        let employee = _.find(this.employees, { id: resource });
        let schedule = {
          Sun: [],
          Mon: [],
          Tue: [],
          Wed: [],
          Thu: [],
          Fri: [],
          Sat: [],
        };
        // console.log("employee: ", employee);
        // console.log("databaseDocId: ", databaseDocId);

        new Promise((resolve) => {
          if (this.events.length > 0) {
            this.events.forEach((event) => {
              let dayOfWeek = "Sun";
              if (event.resourceId == resource) {
                if (event.day == "1") {
                  dayOfWeek = "Mon";
                }
                if (event.day == "2") {
                  dayOfWeek = "Tue";
                }
                if (event.day == "3") {
                  dayOfWeek = "Wed";
                }
                if (event.day == "4") {
                  dayOfWeek = "Thu";
                }
                if (event.day == "5") {
                  dayOfWeek = "Fri";
                }
                if (event.day == "6") {
                  dayOfWeek = "Sat";
                }
                schedule[dayOfWeek].push({
                  start: moment(event.start).format("HH:mm"),
                  end: moment(event.end).format("HH:mm"),
                });
                schedule[dayOfWeek] = _.orderBy(schedule[dayOfWeek], ["start"], ["asc"]);
                counter++;
                if (counter == this.events.length) {
                  resolve();
                }
              } else {
                counter++;
                if (counter == this.events.length) {
                  resolve();
                }
              }
            });
          } else {
            resolve();
          }
        })
        .then(() => {
          // console.log("updatedResourceSchedulde promise resolved");
          if (databaseDocId) {
            console.log("Database document updated");
            db.collection("schedules")
              .doc(databaseDocId.id)
              .set(
                {
                  schedule: schedule,
                },
                { merge: true }
              )
              .then(() => {
                this.calculateSummary();
              })
              .catch((error) => {
                console.error("Error updated schedule: ", error);
                this.busy = true;
              });
          }
          else {
            // console.log("Nieuw database document aanmaken");
            db.collection("schedules")
              .add({
                  companyId: this.$store.state.activeCompany.id,
                  branchId: this.activeBranchData.id,
                  employeeId: resource,
                  week: this.selectedWeeknumber,
                  year: this.selectedYear,
                  schedule: schedule,
                  basicScheduleMinutes: employee.basicScheduleMinutes[this.activeBranchData.id] ? employee.basicScheduleMinutes[this.activeBranchData.id] : 0,
                  basicSchedule: employee.basicSchedule[this.activeBranchData.id] ? employee.basicSchedule[this.activeBranchData.id] : null,
                  type: "customSchedule"
                },{ merge: true }
              )
              .then((docRef) => {
                this.customSchedules.push({
                  id: docRef.id,
                  employeeId: resource,
                  Sun: schedule.Sun,
                  Mon: schedule.Mon,
                  Tue: schedule.Tue,
                  Wed: schedule.Wed,
                  Thu: schedule.Thu,
                  Fri: schedule.Fri,
                  Sat: schedule.Sat,
                });
                this.calculateSummary();
              })
              .catch((error) => {
                console.error("Error updated schedule: ", error);
                this.busy = true;
              });
          }
        });
      },

      refreshCalendar() {
        this.$refs.branchCalendar.getApi().refetchEvents();
        this.calculateCalendarHeight();
      },
    },

    computed: {
      removeScheduleDisabled() {
        let currentWeek = moment().isoWeek();
        let selectedWeek = moment(this.selectedDate).isoWeek();
        return selectedWeek >= currentWeek && this.selectedEmployeesForRemoveSchedule.length > 0 ? false : true;
      },

      employeesWithCustomScheduleForRemoving() {
        let employees = _.filter(this.employees, (o) => {
          return _.indexOf(this.employeesWithCustomSchedule, o.id) >= 0;
        });
        return employees;
      },

      calendarLoading() {
        return this.loading || this.calendarChecking ? true : false;
      },

      activeYears() {
        let currentYear = Number(moment().year());
        let startDateCompany = moment().format("YYYY-MM-DD");

        if (this.$store.state.activeCompany.conversion.start) {
          startDateCompany = moment.unix(this.$store.state.activeCompany.conversion.start.seconds).format("YYYY-MM-DD");
        }

        let numberOfYears = moment().diff(startDateCompany, "years", false) + 2;
        let activeYears = Array.apply(null, Array(numberOfYears));
        let counter = 0;

        activeYears.forEach((year, index) => {
          activeYears[index] = currentYear - counter;
          counter++;
        });
        return activeYears;
      },

      calenderWidth() {
        let width;
        if (this.calendarView == "week") {
          width = this.resources.length * 700 + 50 + "px";
        } else {
          width = this.resources.length * 150 + 50 + "px";
        }
        return width;
      },

      calendarDayView() {
        if (this.calendarView == "day") {
          return true;
        } else {
          return false;
        }
      },

      filteredCompanies() {
        if (this.searchCompanies && this.searchCompanies.length > 0) {
          return this.companies.filter((company) => {
            return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
          });
        } else {
          return this.companies;
        }
      },
    },
  };
</script>

<style>
  .calendar-wrapper td.fc-today,
  .calendar-wrapperr .fc-unthemed td.fc-day.fc-widget-content,
  .calendar-wrapper .fc-unthemed td.fc-today {
    background: var(--card-color);
  }

  .calendar-wrapper .fc-nonbusiness {
    background: #b9bbc3;
  }
  .calendar-wrapper {
    max-width: 100%;
    min-width: 100%;
    overflow: hidden;
    display: inline-block;
  }

  .calendar-wrapper .fc-view-container {
    background-color: var(--card-color);
  }

  /* width */
  .calendar-wrapper::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .calendar-wrapper::-webkit-scrollbar-track {
    background: var(--separator-color);
  }

  /* Handle */
  .calendar-wrapper::-webkit-scrollbar-thumb {
    background: var(--primary-color);
  }

  /* Handle on hover */
  .calendar-wrapper::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
  .usertype-total {
    padding: 5px;
  }

  .usertype-total-number {
    text-align: center;
    font-size: 28px;
  }
  .usertype-total-desc {
    text-align: center;
    font-size: 16px;
  }

  .calendar-wrapper .fc-scroller.fc-time-grid-container::-webkit-scrollbar {
    display: none;
  }


  /* .calendar-wrapper .fc-body tr td.fc-widget-content,
.calendar-wrapper .fc-head tr td.fc-head-container.fc-widget-header{
  border-left: 0;
} */
  .edited .fc-content .fc-time span {
    font-weight: bold;
    color: #fff;
  }
  /* .edited .fc-content .fc-time span:after{
  content: "(Aangepast)";
  display: block;
  font-weight: normal;
} */

  .calendar-wrapper .fc-timegrid-event-harness {
    box-shadow: none !important;
  }

  .schedule-wrapper {
    position: relative;
    overflow: hidden;
    min-height: 750px;
  }

  .calendar-wrapper .fc-theme-standard .fc-scrollgrid {
    border-left: 0;
    border-right: 0;
  }

  .custom-loader-overlay {
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(245, 245, 245, 0.5);
    width: 100%;
    height: 100%;
    z-index: 99;
  }
</style>
