/* ┌───────────────────────┐                                ┌───────────────────────┐ 
 * │      Input Docs       │                                │        Upload         │ 
 * │   Parameters: docs,   │                                │    ForEach back to    │ 
 * │     activeBranch      │                              ┌─▶       Firebase        │ 
 * └───────────────────────┘                              │ └───────────────────────┘ 
 *             │    ┌─────────────────────────────────┐   │             │             
 *             │    │Loyalty Module Re-Calculate Docs │   │             │             
 * ┌───────────▼────┴─────────────────────────────────┴┐  │ ┌───────────▼───────────┐ 
 * │                                                   │  │ │        Upload         │▒
 * │  ┌─────────────────────────────────────────────┐  │░░│ │    ForEach back to    │▒
 * │  │   Removing the element from the array and   │  │░░│ │       Firebase        │▒
 * │  └─────────────────────────────────────────────┘  │░░│ └───────────────────────┘▒
 * │                                                   │░░│  ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒
 * │  ┌─────────────────────────────────────────────┐  │░░│                           
 * │  │        Sort by Highest points first         │  │░░│                           
 * │  └─────────────────────────────────────────────┘  │░░│                           
 * │                                                   │░░│                           
 * │  ┌─────────────────────────────────────────────┐  │░░│                           
 * │  │place byBranchId as the first element in the │  │░░│                           
 * │  └─────────────────────────────────────────────┘  │░░│                           
 * │                                                   │░░│                           
 * │  ┌─────────────────────────────────────────────┐  │░░│                           
 * │  │     Subtracting the pointsLeft from the     │  │░░│                           
 * │  └─────────────────────────────────────────────┘  │░░│                           
 * │                                                   │░░│                           
 * └───────────────────────────────────────┬───────────┘░░│                           
 *   ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░│░░░░░░░░░░░░░░│                           
 *   ░░░░░░░░░░░░░░░░░░░░░░░░░░┌───────────▼───────────┐░░│                           
 *                             │        Output:        │  │                           
 *                             │      return docs      │──┘                           
 *                             │     recalculated      │                              
 *                             └───────────────────────┘                              
 *                                                                                    
 *                                                                                    
 *                                                                                    
 *                                                                                    
 * ┌───────────────────────┐                                                          
 * │      Input Docs       │                                                          
 * │   Parameters: docs,   │                                                          
 * │     activeBranch      │                                                          
 * └───────────┬───────────┘   ┌─────────────────────┐                                
 *             │               │quick Validate Points│                                
 * ┌───────────▼───────────────┴─────────────────────┴─┐                              
 * │                                                   │░                             
 * │  ┌─────────────────────────────────────────────┐  │░                             
 * │  │ Reduce: Looping through the docs array and  │  │░                             
 * │  │ adding up the points for the active branch. │  │░                             
 * │  └─────────────────────────────────────────────┘  │░                             
 * │                                                   │░                             
 * │                                       │           │░                             
 * └───────────────────────────────────────┼───────────┘░                             
 *  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░│░░░░░░░░░░░░░                             
 *                             ┌───────────▼───────────┐                              
 *                             │ Output boolean True / │                              
 *                             │         False         │                              
 *                             │                       │                              
 *                             └───────────────────────┘                              
 */    
                                                                               

// let docsLoyaltyPoints = [
	
// 	{ docId: "BBBUtrecht", companyId: "hogansbeauty", branchId: "hogansbeautyutrecht", points: 100 },
// 	{ docId: "CCCPapendor", companyId: "hogansbeauty", branchId: "hogansbeautypapendorp", points: 180 },
//    { docId: "AAABreukelen", companyId: "hogansbeauty", branchId: "hogansbeautybreukelen", points: 170 }
// ];

// quickValidateLoyalty(docsLoyaltyPoints, 400); //?

/* Checking if the total points of the docsLoyaltyPoints array is greater than 400. */
// reCalculateLoyalty(docsLoyaltyPoints, "hogansbeautybreukelen", 10); //?



//Re-calculate loyalty points and give back the new docs
//By subtraction the total points from all the docs included in the array
/**
 * 
 * @param {*} docs | Array of docs
 * @param {*} activeBranch  | The branch that is active
 * @param {*} pointsNeeded | the points needed to be subtracted from the total points
 * @returns 
 */
export function reCalculateLoyalty(docs, activeBranch, pointsNeeded) {
	let totalPoints =  docs.reduce((acc, doc) => {
		return acc + doc.points; 
	}, 0); 
 
   /* Removing the element from the array and returning it. */
  let byBranchId = docs.splice((docs.findIndex(doc => doc.branchId === activeBranch)), 1); //?

  //Sort by Highest points first
  docs = docs.sort((a, b) => { return b.points - a.points; }); //?
  
  //place byBranchId as the first element in the array
  docs.unshift(byBranchId[0]); //?
  
  //Subtract pointNeeded recursive from docs

   let pointsLeft = pointsNeeded;

   /* Subtracting the pointsLeft from the docs.points. */
   let docsRecalculated = docs.map(doc => {
     
      if(pointsLeft > 0) { 

         //Check how many points are left 
         if(pointsLeft >= doc.points) {
         pointsLeft -= doc.points 
         doc.points = 0; 
         } else if(pointsLeft <= doc.points && pointsLeft > 0) {
         doc.points -= pointsLeft; 
         pointsLeft = 0; 
         } 
         
      }
      return doc;
   });

   return docsRecalculated;
  

}
