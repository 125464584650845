<template>
  <v-app>
    <section class="loginpage-wrapper clear" v-if="!loading" :style="[{background: $themes[$store.state.companyTheme].light.background}]">

        <div class="login-form-box">
          <v-card flat color="#ffffff" class="theme-card theme-shadow thrive mt-4 pa-0">

            <div v-if="theme == 'thrive'" class="login-logo-wrapper">
              <div class="thrive-logo-login"></div>
              <div class="thrive-login-text">THRIVE</div>
            </div>
            <div v-else>
              <div class="jameda-login">MagnaMed</div>
            </div>
            <v-alert
              class="ma-0 pa-3"
              style="border-radius: 0"
              :value="loginError"
              text
              :icon="loginErrorData.icon"
              :prominent="loginErrorData.prominent"
              :type="loginErrorData.type"
              transition="scale-transition"
            >{{ loginErrorData.message }}</v-alert>
      
            <v-alert
              class="ma-0 pa-3"
              style="border-radius: 0"
              v-if="feedback"
              :value="true"
              type="warning"
              transition="scale-transition"
            >{{ feedback }}</v-alert>
            <v-alert
              class="ma-0 pa-3"
              style="border-radius: 0"
              v-if="success"
              :value="true"
              type="success"
              transition="scale-transition"
            >{{ success }}</v-alert>
      
            <div id="login-form">
              <v-form ref="loginForm" lazy-validation @keyup.native.enter="submit()">
                <v-text-field
                  label="Email"
                  v-model="login.email"
                  :rules="emailRules"
                  prepend-inner-icon="mdi-email"
                  required
                  dense
                  color="#1f1d31"
                  outlined
                  hide-details
                ></v-text-field>
                <v-text-field
                  label="Password"
                  outlined
                  hide-details
                  dense
                  class="mt-4"
                  color="#1f1d31"
                  autocomplete="false"
                  v-model="login.password"
                  :rules="passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  prepend-inner-icon="mdi-lock"
                  required
                ></v-text-field>

                <v-btn 
                  class="login-button mt-4" 
                  block
                  dark
                  :color="$themes[theme].light.primary"
                  @click="submit"
                  >
                  Login
                </v-btn>
                <div class="login-remember">
                  <v-checkbox
                    v-model="login.remember"
                    label="Remember me"
                    :color="$themes[theme].light.primary"
                    hide-details
                  ></v-checkbox>
                </div>

                <div class="login-forgot-password">
                  <a @click="resetPassword()" style="color:#1f1d31">Wachtwoord vergeten</a>
                </div>
              </v-form>
            </div>
          </v-card>
          <div class="login-new-client clear">
            <div class="new-client-text">
              <p class="ma-0">Nog geen account?</p>
            </div>
            <div class="new-client-button">
              <v-btn block outlined color="#1f1d31" @click="goToRegister()">Probeer gratis</v-btn>
            </div>
          </div>
        </div>
        <p class="login-disclaimer">
          &copy; {{ currentYear }} Thrive. All rights reserved.
          <a
            href="https://www.thrive.nl/privacy-policy"
            target="_blank"
            style="color:#1f1d31; text-decoration: underline"
          >Privacy Policy</a>
        </p>

    </section>
  </v-app>
</template>

<script>
import db from "../firebase/init";
import '@firebase/firestore';
import '@firebase/auth';
import moment from "moment"
export default {
  name: "Login",
  data() {
    return {
      loading: true,
      showPassword: false,
      login: {
        email: null,
        password: null,
        remember: true
      },
      theme: 'thrive',
      currentYear: null,
      errors: [],
      feedback: null,
      success: null,
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      passwordRules: [v => !!v || "Password is required"]
    };
  },

  created() {
    this.currentYear = moment().format("YYYY")
    this.$vuetify.theme.dark = false;
    this.checkUrl()
  },

  methods: {
    checkUrl(){
      console.log("window.location.href: ", window.location.href)
      if((location.href).includes('magnamed')){
        this.theme = "jameda";
      }
      this.loading = false;
    },
    
    goToRegister() {
      this.$router.push("/register");
    },
    resetPassword() {
      if (this.login.email) {
        firebase
          .auth()
          .sendPasswordResetEmail(this.login.email)
          .then(() => {
            this.feedback = null;
            this.success = "Email met instructies is verzonden";
            // Email sent.
          })
          .catch(error => {
            this.feedback = "Er is iets misgegaan. Probeer het opnieuw";
          });
      } else {
        this.feedback = "Vul een emailadres in.";
      }
    },
    submit() {
      if (this.login.email && this.login.password) {
        const loginData = {
          email: this.login.email,
          password: this.login.password,
          remember: this.login.remember,
          returnSecureToken: true
        };
        this.feedback = null;
        this.$store.dispatch("login", loginData)
      } else {
        this.feedback = "Vul alle velden in.";
      }
    }
  },
  computed: {
    loginError() {
      return this.$store.state.error;
    },
    loginErrorData() {      
      let error = {
        message: "Logingegevens onjuist",
        type: "warning",
        prominent: false,
        icon: "mdi-alert",
        error: null
      }
      if(this.$store.state.errorData){
        error.error = this.$store.state.errorData.code;
        if(this.$store.state.errorData.code == "auth/user-disabled"){
          error.type = "error";
          error.message = "Account geblokkeerd. Neem contact op met de beheerder.";
          error.prominent = true;
          error.icon =  "mdi-cancel";
        }
        else if(this.$store.state.errorData.code == "auth/user-not-found"){
          error.message = "Gebruikersaccount niet gevonden.";
        }
      }
      return error; 
    },
    userId() {
      return this.$store.state.userId;
    },
    authenticated() {
      return this.$store.state.authenticated;
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : 'thrive';
    }
  }
};
</script>

<style>

.login-logo-wrapper{
  width: 230px;
  margin: 20px auto 0 auto;
}
.login-logo-wrapper:after{
  display: block;
  clear: both;
  content: ""
}

.thrive-logo-login{
    height: 50px;
    width: 50px;
    display: block;
    float: left;
    background: url(/img/thrive-logo.ad55d132.png) no-repeat center left;
    background-size: 50px auto;
}

.thrive-login-text{
  display: inline-block;
  text-align: center;
  padding: 10px;
  color: rgb(31, 29, 49);
  font-weight: bold;
  font-size: 36px;
  line-height: 30px;
  letter-spacing: 4px;
}

.jameda-login{
  padding: 20px 0 0 0;
  display: block;
  text-align: center;
  color: #3d83df;
  font-size: 28px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 4px;
  }

.loginpage-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.login-form-wrapper {
  position: absolute;
  background-color: #f6f7fb;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
}

.login-image-wrapper {
  position: absolute;
  background-size: cover;
  height: 100%;
  width: 50%;
  top: 0;
  right: 0;
}

.login-form-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 380px;
  height: 560px;
  margin: -280px 0 0 -190px;
}

.login-wrapper {
  width: 380px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px #e8e9ee solid;
  border-radius: 5px;
  overflow: hidden;
}

#login-form {
  padding: 20px;
}

.login-logo {
  height: 60px;
  background-size: 200px auto;
  width: 200px;
  margin: 0 auto 20px auto;
}

.login-forgot-password {
  margin: 10px 0 0 0;
  border-top: 1px #e8e9ee solid;
  width: 100%;
}

.login-forgot-password a {
  text-decoration: none;
  padding: 10px 0 0 0;
  display: block;
  color: #8b93a6;
}

.login-new-client {
  width: 80%;
  margin: 20px auto 0 auto;
}

.new-client-text,
.new-client-button {
  width: 50%;
  float: left;
}

.new-client-text p {
  font-size: 14px;
  color: #373737;
  text-align: right;
  padding: 10px 15px 0 0;
}

.login-remember {
  padding: 5px 0 0 0;
}

.login-remember .v-input label {
  padding: 2px 0 0 0;
  font-size: 13px;
}

.login-disclaimer {
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 12px;
  color: #666666;
}

.login-disclaimer a {
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .login-form-wrapper {
    width: 100%;
  }

  .login-image-wrapper {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  #login-form {
    width: 300px;
    height: 320px;
    padding: 15px;
  }

  .login-logo {
    height: 50px;
    width: 200px;
    margin: 0 auto 20px auto;
  }

  .new-client-text p {
    text-align: center;
    padding: 0;
  }

  .login-new-client {
    width: 60%;
  }

  .new-client-text,
  .new-client-button {
    width: 100%;
  }
  
}
</style>