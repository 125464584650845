<template>
	<div v-if="loading">
		<div style="position: relative; padding: 200px 0;">
		<div class="thrive-loader"></div>
		</div>
	</div>
	<v-container v-else grid-list-md class="pa-0 pt-4 ma-0">
      	<v-layout row wrap>
        	<v-flex md6 xs12 class="pr-3">
				<!-- / Selecting Receipt Printer \ -->
				<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
					<div class="pa-3">
						<v-avatar
							size="36"
							light
							style="float: left"
							class="ma-0 mr-2 custom-avatar-style" 
							rounded="lg"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
							:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
							>
							<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-register</v-icon>
						</v-avatar>
						<h3 class="pt-1 pb-4">{{ $t('posSettings.posReceiptPrinter', $store.state.locale)}} </h3>
								
						<v-divider></v-divider>

						<div class="py-4">
							{{ $t('posSettings.posReceiptPrinterHint', $store.state.locale) }} 
						</div>

						<v-select
							outlined
							dense
							:items="printers"
							:label="$t('posSettings.posReceiptName', $store.state.locale)"
							return-object
							item-text="printerName"
							v-model="selectedPrinter"
							@change="saveSettings()"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
							:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
						></v-select>

						<div>
							<h3>{{ $t('posSettings.posChosenReceiptPrinter', $store.state.locale) }}</h3>
							<p class="mb-2">{{ $t('posSettings.posReceiptPrinter', $store.state.locale) }}: {{ selectedPrinter.printerName }}</p>
							<div :class="printerStateReason.printerStateReasons.some(reason => reason.code !== 1) ? 'dot' : 'dot success'"></div>
							<span class="caption">{{ $t('general.status', $store.state.locale) }}: {{ printerStateReason.printerStateReasons.map((reason) => reason.reason).toString() }}</span>
						</div>
					</div>
				</v-card>
				<!-- \ Selecting Receipt Printer / -->
			</v-flex>
			<v-flex md6 xs12 class="pl-3">
			</v-flex>
		</v-layout>

		<v-layout row wrap>

				<v-flex md6 xs12 class="pr-3">
					<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
						
						<div class="pa-3">
						<v-avatar
							size="36"
							light
							style="float: left"
							class="ma-0 mr-2 custom-avatar-style" 
							rounded="lg"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
							:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
							>
							<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-printer-receipt-cog</v-icon>
						</v-avatar>
						<h3 class="pt-1 pb-4">{{ $t('posSettings.posLocation', $store.state.locale)}} </h3>
								
						<v-divider></v-divider>

						<div class="py-4">
							{{ $t('posSettings.posLocationHint', $store.state.locale) }} 
						</div>

						<v-select
							outlined
							dense
							:items="posSettingsLocation"
							:item-text="item => item.name === null ? $t('posSettings.posDefault', $store.state.locale) : item.name"
							:label="$t('posSettings.posChooseLocation', $store.state.locale)"
							@change="savePosLocation()"
							return-object
							v-model="selectedPosLocation"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
							:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
						></v-select>

					</div>
					
					</v-card>
				
				</v-flex>

		</v-layout>
	</v-container>

	

</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import db from "../firebase/init";

export default {
	name: "posSettings",

	data: () => ({
		loading: true,
		printers: [],
		selectedPrinter: new Object(),
		checkPrinterInterval: null,
		printerStatus: new Array(),
		printerStateReason: new Object(),
		posSettingsLocation: [{
			active: true,
			name: null,
			id: "general",
			deleted: false
		}],
		selectedPosLocation: new Object(),
	}),

	

	mounted() {
		this.loading = true;
		const { ipcRenderer } = require("electron");
		//get printers from vuex storage
		this.printers = this.$store.state.posPresets.filter((printer) => printer.type === "thermalPrinter");

		//Get printerSetting from localStorage
		this.selectedPrinter = window.localStorage.getItem("printerSetting") ? JSON.parse(localStorage.getItem("printerSetting")) : "";

		console.log("printers: ", this.printers);
		ipcRenderer.send("give-printers", "get printer");

		// Sending a printer message to the main process every 4 seconds.
		this.checkPrinterInterval = setInterval(() => { //set Interval every 5 seconds
			ipcRenderer.send("give-printers", "get printer");
		}, 5000);

		ipcRenderer.on("give-printers-answer", (event, arg) => {
			console.log("arg: ", arg);
			this.printerStatus = arg;
			this.checkPrinterStatus();
		});

		this.downloadBranch();



	},
	destroyed() {
		clearInterval(this.checkPrinterInterval);
	},

	methods: {
		
		//download branch via firebase the name of the branch is in the local storage
		downloadBranch() {
			
			let companyId = localStorage.getItem("selectedThriveCompany");
			let getLocalStorageBranch = localStorage.getItem("selectedThriveBranch" + companyId);

			console.log("getLocalStorageBranch: ", companyId, getLocalStorageBranch);
			
			//Always add the general location to the list 
     	   this.posSettingsLocation.push({active: true, name: null, id: "general", deleted: false});
				

			db.collection("branches")
			.doc(getLocalStorageBranch)
			.get()
			.then(doc => {
				if (doc.exists) {
					console.log("Document data:", doc.data());
			     let posSettingsLocation = doc.data().posSettings.locations;
				  this.posSettingsLocation = posSettingsLocation.filter((location) => location.active === true || location.id === "general");

				} else {
					// doc.data() will be undefined in this case
					console.log("No such document!");
				}
			}).catch(function(error) {
				console.log("Error getting document:", error);
			});

			this.selectedPosLocation = localStorage.getItem("posLocation") ? JSON.parse(localStorage.getItem("posLocation")) : this.posSettingsLocation[0];

		},

		savePosLocation() {
			console.log("selectedPosLocation: ", this.selectedPosLocation);
			localStorage.setItem("posLocation", JSON.stringify(this.selectedPosLocation));
		
		},


		saveSettings() { // Save to Electron Local storage
			window.localStorage.setItem("printerSetting", JSON.stringify(this.selectedPrinter));
		},

		checkPrinterStatus() {
			//Check the selected Printer status via "this.printerStatus" array and check selectedPrinter by factory name and the printer-state-reasons

			let printerStateReasonObject = this.printerStatus.find((printer) => printer.name === this.selectedPrinter.factoryName);

			if (printerStateReasonObject) {

				let printerstate = printerStateReasonObject.options["printer-state-reasons"];
				let printerstateArray = printerstate.split(",");

				if (printerstate === "none") {
               		this.printerStateReason = {
						printerName: "",
						printerStateReasons: printerstateArray.map((printerState) => {
							const printerStateObject = {
								reason: this.$t('posSettings.receiptPrinterReadyToUse', this.$store.state.locale),
								reasonCode: printerState,
								code: 1,
							};
							return printerStateObject;
						}),
					};
				} else {
					this.printerStateReason = {
						printerName: "",
						printerStateReasons: printerstateArray.map((printerState) => {
							const printerStateObject = {
								reason: printerState.replace(/-/g, " "),
								reasonCode: printerState,
								code: 3,
							};
							return printerStateObject;
						}),
					};
				}
			}

			if (!printerStateReasonObject) {
				console.error("Printer:", this.selectedPrinter.printerName + " is not installed.");

				this.printerStateReason = {
					printerName: this.selectedPrinter.printerName,
					printerStateReasons: 
						[{
							reason: this.$t('posSettings.receiptPrinterNotInstalled', this.$store.state.locale),
							reasonCode: "printer-not-installed",
							code: 2,
						}]				
				};
			}
			this.loading = false;
		},
	},
	filters: {
		reasonFilter() {
			return this.printerStateReason.printerStateReasons.filter((reason) => reason.code === 0);
		},
	},

	computed: {
		userFirstName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
		},
		userLastName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
		},
		userRole() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
		},
		userId() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
		},
		theme() {
			return this.$vuetify.theme.dark ? "dark" : "light";
		},
		companyTheme() {
			return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
		}
	}
};
</script>

<style scoped>
.dot {
	margin: 2px 8px 0 0;
	height: 12px;
	width: 12px;
	background-color: red;
	border-radius: 50%;
	display: inline-block;
}

.dot.success {
	background-color: rgb(7, 191, 7) !important;  
}
</style>
