/**

 ██████╗ ██╗   ██╗██╗ ██████╗██╗  ██╗    
██╔═══██╗██║   ██║██║██╔════╝██║ ██╔╝    
██║   ██║██║   ██║██║██║     █████╔╝     
██║▄▄ ██║██║   ██║██║██║     ██╔═██╗     
╚██████╔╝╚██████╔╝██║╚██████╗██║  ██╗    
 ╚══▀▀═╝  ╚═════╝ ╚═╝ ╚═════╝╚═╝  ╚═╝    
                                         
██╗    ██╗██╗███╗   ██╗███████╗          
██║    ██║██║████╗  ██║██╔════╝          
██║ █╗ ██║██║██╔██╗ ██║███████╗          
██║███╗██║██║██║╚██╗██║╚════██║          
╚███╔███╔╝██║██║ ╚████║███████║          
 ╚══╝╚══╝ ╚═╝╚═╝  ╚═══╝╚══════╝          
                                         
Quick wins for the project by Sven Bakker & Joe van Ettekoven                                                                    

*/

//DEEP COPY RECURSIVE
export function deepCopy(inputObject) {
  let outputObject, value, key;

  if (typeof inputObject !== "object" || inputObject === null) {
    return inputObject; // Return the value if inputObject is not an object
  }

  // Create an array or object to hold the values with ternary operator
  outputObject = Array.isArray(inputObject) ? [] : {};

  for (key in inputObject) {
    value = inputObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outputObject[key] = deepCopy(value);
  }

  return outputObject;
}

//Find all Vue Observers in an object
export function findObservers(object, path = '') {
  let observerPaths = [];

  // Controleer of het huidige object een Vue Observer is
  if (object && object.__ob__) {
    observerPaths.push(path);
  }

  // Recursief doorlopen van objecten en arrays
  if (object !== null && typeof object === 'object') {
    Object.keys(object).forEach(key => {
      const fullPath = path ? `${path}.${key}` : key;
      const value = object[key];
      if (typeof value === 'object' && value !== null) {
      observerPaths = observerPaths.concat(findObservers(value, fullPath));
      }
    });
  }

  return observerPaths;
  }

/**
 * CALCULATE TIP WITH FLOATING POINTS:
 * (-0.2 * 10 - 0.1 * 10) / 10
 * (100 * 10 - 99.1 * 10) / 10
 */

/**
 * Convert output safely to a Number without Floating points
 * ALL ABOUNT FLOATING POINTS: https://floating-point-gui.de/
 * 
 * Convert output safely to a Number without Floating points

 * @param {integer} calc - the formula to calculate
 * @param {string} currency - EUR 
 * @param {integer} digits - for decimal calculation
 * Currency object -> currency: new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 8,
      }),
 * 
 */
export function cashConv(calc, currency, digits) {
  const currencyOBJ = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: digits,
  });

  const cash = new Array();

  //Generate Digits
  let byDigits = 1 + Array(digits).fill(0).reduce((previousValue,currentValue) => previousValue + "" + currentValue) //?
  currencyOBJ.formatToParts(calc).forEach(({ type, value }) => {
     
     if (type == "minusSign") cash.push(value)
     //When is negative
     if (type == "integer" || type == "fraction") cash.push(value);
   
  });
 
  //Reduce the parts as a string to a number safely.
  return cash.reduce((previousValue, currentValue) => previousValue + currentValue) / byDigits

}

/**
 * Convert output safely to a Number without Floating points
 * TODO Multi Currency Support
 * @param {string} currency - EUR, USD, etc
 * @param {integer} digits - for decimal calculation
 */
export function formatToCurrency(locale, currency, digits) {
 
  if(!locale || !currency || !digits ) {
    return "--"
  }

  const currencyFormat = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });


  return currencyFormat.format(digits);
}



/**
 * This function formats the input to a clean number
 * From 1.111,11 to 1111.11 56,24 -> 56.24 and can then be used in calculations with cashConv
 * @param {string} inputValue 
 */
export function formatToCleanNumber(inputValue) {

  console.log("inputValue", inputValue);



			const locale = 'nl-NL';

			const thousandSeparator = Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
			const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');

			let cleanNumber = parseFloat(inputValue
					.replace(new RegExp('\\' + thousandSeparator, 'g'), '') // Verwijder duizendtalscheiders
					.replace(new RegExp('\\' + decimalSeparator), '.') // Vervang decimaalteken door punt
			);

    
    console.log("Clean", cleanNumber)
		
    return cleanNumber;


}