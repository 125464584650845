
<template>
  <div>
      <div v-if="dataloaded" class="pb-4">
        <div class="clear">
          <companySelector @companyChanged="companyUpdate"></companySelector>
          <!-- <v-container grid-list-md class="pa-0" fluid>
            <v-layout row wrap>
              <v-flex md3 xs12>
                <v-select
                  v-if="dataloaded && companies.length > 1"
                  :items="filteredCompanies" 
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="menu"       
                  item-text="name"
                  item-value="id"
                  hide-details
                  rounded
                  v-on:input="loadActiveCompData()"
                  v-model="activeComp"
                  no-data-text="Geen bedrijven gevonden."
                  label="Selecteer bedrijf"
                  outlined
                  dense>
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content  class="pt-0" >
                        <v-text-field
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          placeholder="Zoeken"
                          prepend-inner-icon="mdi-magnify"
                          clearable
                          v-model="searchCompanies"
                          hide-details
                          dense
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <div v-if="searchCompanies && searchCompanies.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredCompanies.length }} van {{ companies.length }} bedrijven</div>
                    <v-divider v-if="searchCompanies && searchCompanies.length > 0"></v-divider>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-container> -->
        </div>
      </div>

      <div v-if="dataloaded == false" class="thrive-loader"></div>


      <v-card v-if="$store.state.activeCompany && dataloaded == true" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-tab-header" :class="$store.state.companyTheme">
          <v-tabs :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="tab">
            <v-tab>{{ $t('financial.discounts', $store.state.locale)}}</v-tab>
            <v-tab>{{ $t('financial.giftcards', $store.state.locale)}}</v-tab>
            <v-tab>{{ $t('financial.revenue', $store.state.locale)}}</v-tab>
          </v-tabs>
        </v-card>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
              <discounts></discounts>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
            </v-card>
          </v-tab-item>
          <v-tab-item :value="2" :transition="tabTransition" :reverse-transition="tabTransition">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">

            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

  </div>
</template>

<script>
import { bus } from "../main";
import '@firebase/firestore';
import db from "../firebase/init";
import Discounts from "./Discounts.vue";
import Social from "./Social.vue";
import Employees from "./Employees.vue";
import PackagesOverview from "./PackagesOverview.vue";
import companySelector from "@/components/elements/companySelector.vue";

export default {
  components: {
    discounts: Discounts,
    employees: Employees,
    social: Social,
    companySelector: companySelector,
    "packages-overview": PackagesOverview
  },
  name: "Financials",
  data: () => ({
    tab: 0,
    tabTransition: false,
    resources: [],
    searchCompanies: "",
    dataloaded: false,
    
    headers: [
      {
        text: "ID",
        align: "left",
        sortable: false,
        value: "resourceId"
      },
      {
        text: "Naam",
        align: "left",
        value: "resourceName",
        sortable: false
      }
    ]
  }),

  created() {
    this.dataloaded = true;
  },
  mounted() {
    // this.grid = window.GridStack.init({
    //   acceptWidgets: true
    // });
    // window.grid = this.grid;
  },

  methods: {
    
    

    companyUpdate(){
      console.log("company Update")
      bus.$emit("companyUpdate");
    },

    loadActiveCompData() {
      db.collection("companies")
        .doc(this.activeComp)
        .get()
        .then(doc => {
          this.activeCompData = doc.data();
          this.activeCompData.id = doc.id;
        })
        .then(() => {
          this.dataloaded = true;
        })
        .then(() => {
          bus.$emit("companyUpdate");
        });
    },


  },

  

  computed: {
    version() {
      return this.$store.state.version;
    },
    filteredCompanies(){
      if (this.searchCompanies && this.searchCompanies.length > 0){
        return this.companies.filter(company => {
          return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
        });
      } else {
        return this.companies;
      }
    },
  }
};
</script>
