<script>
  import { bus } from "../main"; //Going Depreacted
  import lodash from "lodash";
  import Moment from "moment";
  import momentTimezone from 'moment-timezone';
  import { extendMoment } from "moment-range";
  import algoliasearch from "algoliasearch/lite";
  import "instantsearch.css/themes/satellite-min.css";
  const moment = extendMoment(Moment);

  import db from "../firebase/init";
  import axios from "axios";
  import firebase from "firebase/app";
  import "firebase/auth";
  import "firebase/firestore";
  import FullCalendar from "@fullcalendar/vue";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import scrollgrid from "@fullcalendar/scrollgrid";
  import interactionPlugin from "@fullcalendar/interaction";
  import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
  import momentTimezonePlugin from '@fullcalendar/moment-timezone'
  import nlLocale from "@fullcalendar/core/locales/nl";
  import DeviceImage from "../assets/icons/device.svg";
  import RoomImage from "../assets/icons/roomcube.svg";
  import EmployeeIcon from "../assets/icons/account-icon-white.svg";
  import ResourceImage from "../assets/icons/resource-icon.svg";
  import NotesImage from "../assets/icons/notes-icon.svg";

  //Modals
  import EditLeadForm from "../components/modals/EditLeadForm.vue";
  import changeTreatmentTime from "../components/modals/changeTreatmentTime.vue";
  import addRoomBooking from "../components/modals/AddRoomBooking.vue";
  import CustomerInformationDialog from "../components/modals/CustomerInformationDialog.vue";
  import createBooking from "../components/modals/BookingWidgetCalendar.vue";
  import addEventBooking from "../components/modals/AddEventBooking.vue";
  import modaladdPause from "../components/modals/AddPause.vue";
  import editPauseDialog from "../components/modals/EditPause.vue";
  import EditRoomForm from "../components/modals/EditRoomForm.vue";
  import InformClientModal from "../components/modals/InformClientModal.vue";
  import bookingSubscriptionsModal from "../components/modals/bookingSubscriptionsModal.vue";
  import companySelector from "@/components/elements/companySelector.vue";
  import treatmentSelector from "@/components/elements/treatmentSelector.vue";
  import { generateMeshAvatar } from "@/modules/avatarGen.js";
  
  import { mdiValve } from "@mdi/js";

  import ThriveAssistant from '@/components/ThriveAssistant.vue'

  export default {
    props: {
      viewType: String,
      leadId: String,
      trialTreatmentId: String,
      clientId: String,
    },
    components: {
      FullCalendar, // make the <FullCalendar> tag available
      "edit-lead-form": EditLeadForm,
      "change-treatment-time": changeTreatmentTime,
      "add-room-booking": addRoomBooking,
      "customer-information-dialog": CustomerInformationDialog,
      "create-booking": createBooking,
      "add-event-booking": addEventBooking,
      "add-pause-form": modaladdPause,
      "edit-pause": editPauseDialog,
      "edit-room-form": EditRoomForm,    
      "inform-client-modal": InformClientModal,   
      "bookingsubscriptions-modal": bookingSubscriptionsModal,   
      companySelector: companySelector,
      treatmentSelector: treatmentSelector,
      thriveAssistant: ThriveAssistant,
      
    },

    name: "Agenda",

    data() {
      return {
        calendarOptions: {
          plugins: [resourceTimeGridPlugin, interactionPlugin, dayGridPlugin, scrollgrid],
          headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "resourceTimeGridDay",
          },

          schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
          initialView: "resourceTimeGridDay",
          titleFormat: { year: "numeric", month: "2-digit", day: "2-digit" },
          height: "auto",
          eventColor: "#ccc",
          locale: nlLocale,
          defaultAllDayEventDuration:  { days: 1 },
          // timeZone: 'Europe/Amsterdam',
          editable: true,
          selectable: true,
          selectMirror: false,
          dayMaxEvents: true,
          weekends: true,
          allDaySlot: true,
          aspectRatio: 1.35,
          dayMinWidth: 250,
          refetchResourcesOnNavigate: true, //Fetching Native for Resources

          displayEventTime: true,
          slotEventOverlap: true,
          // snapDuration: '05:00',
          eventOverlap: true,
          handleWindowResize: true,
          stickyHeaderDates: "auto",
          slotLabelFormat: {
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: false,
            hour12: false,
          },
          firstDay: 1,
          
          slotMinTime: "06:00:00",
          slotMaxTime: "21:00:00",
          slotDuration: "00:05:00",
          eventClick: this.eventClick,
          initialEvents: this.fetchEvents,
          loading: function(isLoading) {
            
              if (!isLoading) {
               
                return false
              } else {           
     
                return true
              }
            
          },

          // eventAllow: function(dropInfo, draggedEvent) {
          //   console.log("eventAllow : ", dropInfo)
          //   return true
          // },

          nowIndicator: true,
          eventClassNames: (arg) => {
            let timeClass = moment(this.calendarApi.getDate()).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") ? 'day-past' : moment(this.calendarApi.getDate()).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD") ? 'day-future' : 'day-today';
            let classes = [`bsid-${arg.event.extendedProps.bookingSummaryId}`, `${timeClass}`];
            if (arg.event.extendedProps.paid) {
              classes.push('paid')
            }
            else {
              if (arg.event.extendedProps.status) {
                classes.push(arg.event.extendedProps.status)
              }
            }
            if(this.$store.state.treatmentCutId && this.$store.state.treatmentCutId == arg.event.extendedProps.bookingSummaryId){
              classes.push('event-cut')
            }

            if(this.$store.state.treatmentCopyId && this.$store.state.treatmentCopyId == arg.event.extendedProps.bookingSummaryId){
              classes.push('event-copy')
            }
            if(arg.event.extendedProps.demoBooking){
              classes.push('event-demo')
            }
            return classes.join(' ')
          },
          nowIndicatorContent: (indicatorHooks) => {
            if (indicatorHooks.isAxis && this.lastClick === 0) {
              this.scrollToIndicator(indicatorHooks.date);
            }
          },
          resources: new Array(),
     
          resourceOrder: "computedWeight",
          windowResize: (arg) => {},
          select: (selectData) => {
            return this.select(selectData);
          },

          resourceLabelContent: (arg) => {
            return this.resourceRender(arg);
          },

          allDayContent: "",
          eventContent: (arg) => {
            return this.eventRender(arg);
          },

          eventDrop: (eventDropInfo) => {
            return this.eventDrop(eventDropInfo);
          },
          eventResize: (eventData) => {
            return this.eventResize(eventData);
          },
          // eventChange: (eventData) => {
          //   console.log("eventData Change: ", eventData);
          // },
          // eventAdd: (eventData) => {
          //   console.log("eventData Add: ", eventData);
          // },
          // eventRemove: (eventData) => {
          //   console.log("eventData Remove: ", eventData);
          // },
        }, // End calendar Options Object

        showAssistant: false,
        thriveAssistantType: 'planning',
       
        calendarApi: null,
        calendarStartTime: null,
        calendarEndTime: null,
        cachePreview: {
          active: false,
          type: 'small'
        },

        timeRegistrationInfo: {
          show: false,
          showBatch: false,
          number: 0
        },
        timeRegistrationDialog:{
          show: false,
          changes: 0,
          employees: new Object(),
          feedback: null,
          loading: false,
        },
        scheduleExceptions: new Array(),
        scheduleExceptionsOriginal: new Array(),
        selectedClientName: "",
        demoBookingsDialog:{
          dialog: false,
          number: 5,
          speed: 200,
          loading: false
        },
        demoBookingSpeeds:[
          {
            id: 50,
            name:"Lightspeed (1 booking per 50ms)"
          },
          {
            id: 200,
            name:"Fast: (1 booking per 200ms)"
          },
          {
            id: 500,
            name:"Normal: (1 booking per 500ms)"
          },
          {
            id: 1500,
            name:"Slow: (1 booking per 1500ms)"
          },
        ],
        branchBackgroundColor: null, 
        cachePreviewObject: new Object(),
        dialogOpen: false,
        checkUserActivity: false,
        searchCompanies: null,
        snapshotListenerBookings: null,
        snapshotListenerBookingsummary: null,
        snapshotListenerCache: null,
        snapBookingsChanged: false, //Snapshot Bookings works with refetch
        refetchTimer: null, //Refetch Timer
        chosenDateCalendar: null,
        defaultRange: {
          min: null,
          max: null,
        },
        localetime: null,
        wolfload: false,
        calendarLoading: true,
        otherDayLoading: false,
        slotDuration: null,
        individualMove: false,
        calendarHeaderWidth: {},
   
        // Cache
        resourceCache: new Array(),
        adjustedResources: new Array(),
        debugCache: false,

        // Branch and Company
        activeBranch: null,
        activeBranchData: new Object(),
        activeCompData: new Object(),
        activeComp: new String(),
        settingsBranch: new Object(),
        branchInformation: new Object(),
        humanResources: false,
        branchClosed: false,
        branchClosedReason: "",
        companies: new Array(),
        branches: new Array(),
        branchesAvailable: false,
        menu: false,

        // Buttons Settings
        showEmployees: true,
        showDevices: true,
        showRooms: true,
        calendarZoom: false,

        // Resources
        fetchResourceData: new Array(),
        resourceObjects: new Array(), //All Resources of the day
        employeesResources: new Array(), //All Employee Resources
        resourcesFromDB: new Array(), //All Resources from DB
        grouppedRoomsDevices: new Array(),
        devicesLoaded: new Array(), //For modal Like createBooking
        roomsLoaded: new Array(),
        resourcesRoomReservation: new Array(),
        customScheduleRooms: new Array(),

        scheduleExceptionsTypes: [
          {
            id: "present",
            name: "Aanwezig"
          },
          {
            id: "absence",
            name: "Afwezig"
          }
        ],

        entireDayTypes: [
          {
            id: true,
            name: this.$t('general.yes', this.$store.state.locale)
          },
          {
            id: false,
            name: this.$t('general.no', this.$store.state.locale)
          }
        ],
        presenceStart: false,

        // Events
        localBookingen: new Array(),
        eventsObjects: new Array(),
        eventBookingen: new Array(), //For Event BookingSummaries
        bookingsummaries: new Array(),

        earliestBooking: new Array(),
        latestBooking: new Array(),

        // Props Modals
        widgettype: "desktopApp",
        selectedBranch: null,
        selectedEventObject: null,
        selectedEvent: {
          eventID: "",
          eventStart: "",
          eventEnd: "",
          eventDateBooking: "",
          bookingId: "",
        },
        selectedEventOriginal: null,
        selectRoom: null,
        selected_Calendar_Start: null,
        selected_Calendar_End: null,
        selectedResource: null,
        selectedResourceEvents: new Array(),
        roomBookingen: new Array(),
        addRoomBookingModal: false, //Dialog
        CustomerCardModal: false, //Dialog
        customerDialog: false, //Dialog
        devicesLoadedFilteredRoom: new Array(),
        createBookingDialog: false, //Dialog
        CalbookEventModal: false, //Dialog
        dialogPause: false,
        clientNameClicked: false,
        selectedClient: null,
        selectedClientOriginal: null,
        customTimecolumn: new Array(), //CreateCalendar Range
        selectedPause: null,
        selectedPauseOriginal: null,

        
        // selectedEmployeeInfo: {
        //   branches: new Array(),
        //   email: null,
        //   password: null,
        //   employeeName: null,
        //   EmployeeSurame: null,
        // },
        selectedRoomInfo: new Object(),
        selectedRoomInfoOriginal: new Object(),

        selectedEmployeeInfoOriginal: new Object(),
        listenerIndicatorTimer: null, //Event listener
        filterHeight: null,
    
        //Handlers
        lastClick: 10,
        timer: null,
        mouseListener: null,
        employeeSchedules: new Array(),

        newBookings: new Array(),
        newBookingsTimer: null,

        isoDaysOfWeek: {
          Mon: 1,
          Tue: 2,
          Wed: 3,
          Thu: 4,
          Fri: 5,
          Sat: 6,
          Sun: 0,
        },

        //Bubble
        bubble: {
          css: "bubble",
          type: null,
          position: {
            left: "0px",
            top: "0px;",
          },
          options: {
            showBubble: false,
            view: null,
            resource: null,
            startDate: null,
            endDate: null,
          },
          buttons: {
            butTrialTreatment: false,
            butTreatment: false,
            butEvent: false,
            butRoom: false,
            butTimebooker: false,
            butPlaceEvent: false,
            allBookings: false,
            openBooking: false,
          },
        },
        //Custom swal
        swalBox: null,
        swalTitle: null,
        swalBody: null,
        swalButtonLable: null,
        swalHideOption: false,
        swalCustomEvent: null,
        swalExtraButton: false,
        //Snackerbar
        snackbar: null,
        timeout: 2500,
        snackBarMessage: null,
        snackBarStatus: null,
        snackbarIcon: null,

        bookingChanges: 0,

        customSources: [
          {
            id: "advertisement",
            name: {
              en: "Advertisement",
              nl: "Advertentie",
            },
          },
          {
            id: "bringFriend",
            name: {
              en: "Bring a friend",
              nl: "Bring a friend",
            },
          },
          {
            id: "email",
            name: {
              en: "E-mail",
              nl: "E-mail",
            },
          },
          {
            id: "website",
            name: {
              en: "Website",
              nl: "Website",
            },
          },
          {
            id: "flyer",
            name: {
              en: "Flyer",
              nl: "Flyer",
            },
          },
          {
            id: "wordOfMonth",
            name: {
              en: "Word of month",
              nl: "Mond tot mond",
            },
          },
          {
            id: "referral",
            name: {
              en: "Referral",
              nl: "Doorverwijzing",
            },
          },
          {
            id: "social",
            name: {
              en: "Social Media",
              nl: "Social Media",
            },
          },
          {
            id: "formerClient",
            name: {
              en: "Former client",
              nl: "Voormalige klant",
            },
          },
        ],
        schedulesPauseExceptions: new Array(),
        employeeFutureSchedulePopup: {
          show: false,
          id: null,
          class: "center",
          left: 0,
          top: 0
        },
        // Search in Calendar
        algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
        searchField: {
          value: null,
          show: false,
          maxWidth: '0px',
          loading: true,
          showResults: false,
          showDeleted: false
        },
        searchResults: new Array(),
        resizeChanges: 0,

        // changeBookingTreatment

        changeBookingTreatmentData: {
          dialog: false,
          originalTreatmentId: null,
          newTreatmentId: null,
          newTreatmentData: new Object(),
          loading: false,
        },

        noShowDialog: {
          show: false,
          type: 'noShow'
        }

      };
    },

    created() {
      // moment.locale("en");
      // this.loadData('first');
      // window.addEventListener("resize", this.checkResizing);
      // this.userActivity();
      // this.showEmployees = window.localStorage.getItem("thriveShowEmployees") == 'false' ? false : true;
      // this.showDevices = window.localStorage.getItem("thriveShowDevices") == 'false' ? false : true;
      // this.showRooms = window.localStorage.getItem("thriveShowRooms") == 'false' ? false : true;


      // if(this.$store.state.userRoleNumber == 0){
      //   this.watching = this.$watch('bookingChanges', (newVal)=> {
      //     if(this.resourceCache.length > 0 && this.calendarStartTime){
      //       this.getResourceCacheOverview()
      //     }
      //     else{
      //       this.cachePreviewObject = new Object();
      //     }
      //   },
      //   {deep: true, immediate: true})

      //   this.$watch('calendarStartTime', (newVal)=> {
      //     if(this.resourceCache.length > 0 && this.calendarStartTime){
      //       this.getResourceCacheOverview()
      //     }
      //     else{
      //       this.cachePreviewObject = new Object();
      //     }
      //   },
      //   {deep: true})
      // }
      
    },

  beforeDestroy() {
    // Turn of snapshots
    this.calendarApi.destroy();
    this.checkUserActivity = false;
    window.removeEventListener("resize", this.checkResizing);

    clearTimeout(this.timer)
    this.timer = null;
    
    if (typeof this.snapshotListenerBookings === 'function') {
      this.snapshotListenerBookings();
    }
    if (typeof this.snapshotListenerBookingsummary === 'function') {
      this.snapshotListenerBookingsummary();
    }
    if (typeof this.snapshotListenerCache === 'function') {
      this.snapshotListenerCache();
    }
    //vanish the listeners completely
    this.snapshotListenerBookings = null;
    this.snapshotListenerBookingsummary = null;
    this.snapshotListenerCache = null;
    this.listenerIndicatorTimer = null;
    this.filterHeight = null;
  },

    //Component Mounted
    async mounted() {
      //Check if bus returns changes with new bookings
      //update cache with new bookings
      
      this.branchBackgroundColor = {
        colorWithPreference: this.$store.state.activeBranch.calendarSettings.colorWithPreference ? this.$store.state.activeBranch.calendarSettings.colorWithPreference : "#7a41ba",
        colorNoPreference: this.$store.state.activeBranch.calendarSettings.colorNoPreference ? this.$store.state.activeBranch.calendarSettings.colorNoPreference : "#ae84c7",
        colorTime: this.$store.state.activeBranch.calendarSettings.colorTime ? this.$store.state.activeBranch.calendarSettings.colorTime : "#747474",
      }

      await this.$nextTick(() => {
        if (this.$refs.fullCalendar) {
            this.calendarApi = this.$refs.fullCalendar.getApi();
            
            // verdere acties met calendarApi
            console.log("FullCalendar is beschikbaar.");
        } else {
            console.error("FullCalendar is nog niet beschikbaar.");
            return;
        }
      });

      this.calendarApi.render();
  

      moment.locale("en");
      this.loadData('first');
      window.addEventListener("resize", this.checkResizing);
      this.userActivity();
      this.showEmployees = window.localStorage.getItem("thriveShowEmployees") == 'false' ? false : true;
      this.showDevices = window.localStorage.getItem("thriveShowDevices") == 'false' ? false : true;
      this.showRooms = window.localStorage.getItem("thriveShowRooms") == 'false' ? false : true;


      if(this.$store.state.userRoleNumber == 0){
        this.watching = this.$watch('bookingChanges', (newVal)=> {
          if(this.resourceCache.length > 0 && this.calendarStartTime){
            this.getResourceCacheOverview()
          }
          else{
            this.cachePreviewObject = new Object();
          }
        },
        {deep: true, immediate: true})

        this.$watch('chosenDateCalendar', (newVal)=> {
          if(this.resourceCache.length > 0 && this.chosenDateCalendar){
            this.getResourceCacheOverview()
          }
          else{
            this.cachePreviewObject = new Object();
          }
        },
        {deep: true})
      }




       //Create context menu bubble via event listener
      if (this.$store.state.userRoleNumber > 1) {

        let getCalendarClass = document.querySelector(".calendarcontainer");

        getCalendarClass.addEventListener("contextmenu", (event) => {

          event.preventDefault();

          // console.log("test Click", event)

          // let api = this.$refs.fullCalendar.getApi()
          // console.log("event", api)

          //event.target get atribute data-time

          let getDataTime = event.target.getAttribute("data-time");
          if (!getDataTime) {
            return;
          }

          //moment getDate + getDataTime
          let getMomentDate = moment(this.chosenDateCalendar + "T" + getDataTime)

          let getColums = document.querySelector("div.fc-timegrid-cols > table > colgroup");

          //calculate position of each column
          const columnChildren = getColums.children;

          //Get clicked X and Y
          let x = event.clientX;
          let y = event.clientY;

          let clickedColumnIndex = -1;
          for (let i = 0; i < columnChildren.length; i++) {
            const columnRect = columnChildren[i].getBoundingClientRect();
            if (x >= columnRect.left && x <= columnRect.right) {
              clickedColumnIndex = i;
              break;
            }
          }
          // console.log(`Clicked column index: ${clickedColumnIndex}`);

          if (clickedColumnIndex !== -1) {

            let resource = this.calendarApi.getResourceById(this.grouppedRoomsDevices[clickedColumnIndex].docId);
            console.log("resource", resource)
            if (resource !== null) {

              this.calendarOptions.select({
                start: getMomentDate,
                startStr: getMomentDate.format("YYYY-MM-DDTHH:mm:ss"),
                end: getMomentDate.add(5, 'minutes'),
                endStr: getMomentDate.add(5, 'minutes').format("YYYY-MM-DDTHH:mm:ss"),
                jsEvent: event,
                resource: resource,
                view: this.calendarApi.view,

                allDay: false

              });

            }

          }

        });

      }

      //#content-calendar > div.content-box > div.theme-card.theme-shadow.ma-4.mr-0.v-card.v-card--flat.v-sheet.theme--light.thrive > div.calendarcontainer > div > div > div.fc-view-harness.fc-view-harness-active > div > table > tbody > tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid > td:nth-child(2) > div > div > div > div.fc-timegrid-slots > table > tbody > tr:nth-child(45) > td
      let hoverTarget = null;
      let timeCode = null;
      let eventTarget = null;
      
      this.$nextTick(() => {
        const timeSlots = document.querySelectorAll("div.fc-timegrid-slots > table > tbody");
        let columns = document.querySelector("div.fc-timegrid-cols > table > colgroup");
        // Calculate the position of each column
        const columnElements = columns.children;
        timeSlots.forEach(slot => {
                    
          const mouseOverHandler = (event) => {
            if (hoverTarget !== event.target.getAttribute("data-time")) {
              hoverTarget = event.target.getAttribute("data-time");
             
              // console.log(event.target)
              // console.log(`Hovering over time: ${hoverTarget}`);
             
              //give event.target a color as hover
              // event.target.style.backgroundColor = "#0000000a";
              event.target.style.backgroundColor = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].cardHeader;
              
              //Make add hover css 
              event.target.classList.add("hover");

              //add a side the mouse pointer  the timecode
              timeCode = document.createElement("div");
              //add div name
              timeCode.id = "timeCode";
              timeCode.textContent = hoverTarget.slice(0, -3); //Delete milliseconds
              timeCode.style.position = "absolute";
              timeCode.style.zIndex = "10";
              // timeCode.style.color = getComputedStyle(document.documentElement).getPropertyValue('--v-text-base');
              timeCode.style.color = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].text
            }
          };

          const mouseOutHandler = (event) => {
            if (hoverTarget === event.target.getAttribute("data-time")) {
              
              // Reset hover target
              hoverTarget = null;
              // Remove hover styles
              event.target.style.backgroundColor = "";
              event.target.classList.remove("hover");
              let timeCode =  document.querySelector("#timeCode")

              if(timeCode){
                timeCode.remove()
              }

            }
          };

          slot.addEventListener("mouseover", (event) => {
            mouseOverHandler(event);
            determineColumnCalculation(event);
          });
          slot.addEventListener("mouseout", mouseOutHandler);
        });

        // determineColumnCalculation(event)
        function determineColumnCalculation(event) {
          // Determine which column the mouse is in
          let mouseX = event.clientX;

          let currentColumnIndex = -1;
          let previousColumnIndex = -1; // Keep track of the previous column index to know when we change columns

          for (let index = 0; index < columnElements.length; index++) {
            const column = columnElements[index];
            const columnRect = column.getBoundingClientRect();
            if (mouseX >= columnRect.left && mouseX < columnRect.right) {
              currentColumnIndex = index;

              timeCode.style.left = `${columnRect.left + 6}px`;
              timeCode.style.top = `${event.target.getBoundingClientRect().top + 4}px`;
              break;
            }
          }

          if (currentColumnIndex !== previousColumnIndex) {
            // console.log("currentColumnIndex", currentColumnIndex)
            document.body.appendChild(timeCode);
            previousColumnIndex = currentColumnIndex; // Update the previous column index
          }

        }
      });

      bus.$on("removeSchedulePause", ( id) => {
        this.removeSchedulePauseEvent('original',id);
      });
      
      bus.$on("calendarAction", (action, type) => {
        this.dialogOpen = false;
        switch (action) {
          case "addBooking":
            this.notify(this.$t('calendar.appointmentAddedSuccessfull', this.$store.state.locale), "success");
            break;
          case "bookingChanged":
            this.notify(this.$t('calendar.appointmentChangedSuccessfull', this.$store.state.locale), "success");
            break;
          case "bookingDelete":
            this.notify(this.$t('calendar.appointmentDeletedSuccessfull', this.$store.state.locale), "success");
            break;
          case "addCustom":
            this.notify(this.$t('calendar.customAddedSuccessfull', this.$store.state.locale), "success");
            break;
          case "editCustom":
            this.notify(this.$t('calendar.customChangedSuccessfull', this.$store.state.locale), "success");
            break;
          case "deleteCustom":
            this.notify(this.$t('calendar.customDeletedSuccessfull', this.$store.state.locale), "success");
            break;
          case "addPause":
            if (type == "task") {
              this.notify(this.$t('calendar.tastAddedSuccessfull', this.$store.state.locale), "success");
            } else if (type == "meeting") {
              this.notify(this.$t('calendar.meetingAddedSuccessfull', this.$store.state.locale), "success");
            } else if (type == "occupied") {
              this.notify(this.$t('calendar.occupiedAddedSuccessfull', this.$store.state.locale), "success");
            } else {
              this.notify(this.$t('calendar.pauseAddedSuccessfull', this.$store.state.locale), "success");
            }
            break;
          case "updatePause":
            if (type == "task") {
              this.notify(this.$t('calendar.taskChangedSuccessfull', this.$store.state.locale), "success");
            } else if (type == "meeting") {
              this.notify(this.$t('calendar.meetingChangedSuccessfull', this.$store.state.locale), "success");
            } else if (type == "occupied") {
              this.notify(this.$t('calendar.occcupiedChangedSuccessfull', this.$store.state.locale), "success");
            } else {
              this.notify(this.$t('calendar.pauseChangedSuccessfull', this.$store.state.locale), "success");
            }
            break;
          case "deletePause":
            if (type == "task") {
              this.notify(this.$t('calendar.taskDeletedSuccessfull', this.$store.state.locale), "success");
            } else if (type == "meeting") {
              this.notify(this.$t('calendar.meetingDeletedSuccessfull', this.$store.state.locale), "success");
            } else if (type == "occupied") {
              this.notify(this.$t('calendar.occupiedDeletedSuccessfull', this.$store.state.locale), "success");
            } else {
              this.notify(this.$t('calendar.pauseDeletedSuccessfull', this.$store.state.locale),  "success");
            }
            break;
          case "addEvent":
            this.notify(this.$t('calendar.eventAddedSuccessfull', this.$store.state.locale), "success");
            break;
          case "deleteEvent":
            this.notify(this.$t('calendar.eventDeletedSuccessfull', this.$store.state.locale), "success");
            break;
          case "deleteEvents":
            this.notify(this.$t('calendar.eventsDeletedSuccessfull', this.$store.state.locale), "success");
            break;
        }
      });

      bus.$on("openCustomerInformationDialog", (bookingId, event, type) => {
        this.openCustomerDialog(bookingId, event, type);
      });

      bus.$on("openChangeTreatmentDialog", (data, type) => {
        this.changeBookingTreatment(data, type);
      });

      //Update Cache via Modals
      bus.$on("cacheUpdate", (resIds) => {
        //Trigger cacheUpdate via addRoomBooking
        // console.log("cacheUpdate Trigger resIDs", resIds);
        resIds.forEach((resourceDocId) => this.updateCache(resourceDocId, true));
  
      });

      bus.$on("newBookingCacheUpdate", (bookingData) => {
        console.log("newBookingCacheUpdate")
        this.newBookings.push(bookingData)
      });

      bus.$on("changeTreatmentSelected", (treatment) => {
        this.updateChangeBookingTreatment(treatment)
      });
    },

    methods: {
      
      genAvatar(name){
        return name ? generateMeshAvatar(name): "";
      },

      useAssistant(type){
        this.showAssistant = true;
        this.thriveAssistantType = type;
      },
      
      async changeBookingTreatment(data, type){
        console.log("changeBookingTreatment: ", data, type)
        let treatmentId = null;
        let bookingSummaryId = null;
        let clientId = null;
        let clientFirstName = null;
        let clientLastName = null;

        if(type == "changeTreatmentTime") { //BookingSummary Data is provided
          console.log("changeTreatmentTime: ", data)
          treatmentId = data.treatmentId;
          bookingSummaryId = data.id;
          clientId = data.clientId;
        }
        else{
          console.log("calendar: ", data)
          treatmentId = data.extendedProps.treatmentId
          bookingSummaryId = data.extendedProps.bookingSummaryId;
          clientId = data.extendedProps.clientId;
          clientFirstName = data.extendedProps.name;
          clientLastName = data.extendedProps.surname;
        }

        let events = this.eventsObjects.filter(event=> {return event.bookingSummaryId == bookingSummaryId})
        let primaryHumanResource = {
          id: null,
          minutes: 0
        };

        events.forEach(event=>{ // Get Primary Human Resource
          let minutes = moment(event.end, "YYYY-MM-DDTHH:mm").diff(moment(event.start, "YYYY-MM-DDTHH:mm"), 'minutes'); 
          if(event.extendedProps.resourceType == 'employee'&& ((primaryHumanResource && primaryHumanResource.minutes < minutes) || !primaryHumanResource)){ // Update primaryData
            primaryHumanResource = {
              id: event.extendedProps.resourceDocId,
              minutes: minutes,
            }
          }
        })

        if(!primaryHumanResource.id){
          primaryHumanResource.id = this.calendarOptions.resources.filter((resource)=>{ return resource.resourceTypeSelect == 'employee' })[0].id
        }

        this.changeBookingTreatmentData = {
          dialog: true,
          bookingData: {
            bookingSummaryId: bookingSummaryId,
            clientFirstName: clientFirstName ? clientFirstName : events[0].extendedProps.firstName,
            clientLastName: clientLastName ? clientLastName : events[0].extendedProps.surname,
            clientId: clientId,
            source: events[0].extendedProps.source,
            uniqId: events[0].extendedProps.uniqIdStamp,
            preferredPractitioner: events[0].extendedProps.preferredPractitioner,
            primaryHumanResource: primaryHumanResource.id,
            employees: new Array(),
            treatmentDeviceTypes: new Array()
          },     
          orginalTreatmentId: treatmentId,
          newTreatmentId: null,
          newTreatmentData: new Object(),
          loading: false,
          events:  events.sort((a, b) => a.start.localeCompare(b.start))
        }

        bus.$emit("preSelectTreatment", treatmentId);
      },

      async updateChangeBookingTreatment(treatmentId){    
        if(this.changeBookingTreatmentData.newTreatmentId != treatmentId){
          this.changeBookingTreatmentData.newTreatmentId = treatmentId; 
          if(this.changeBookingTreatmentData.newTreatmentId != this.changeBookingTreatmentData.orginalTreatmentId){
            await db.collection("treatments")
            .doc(treatmentId)
            .get()
            .then((doc) => {
              let treatment = doc.data();
              treatment.id = doc.id;
              this.changeBookingTreatmentData.newTreatmentData = treatment;
            });
          }
        }   
      },

      async saveChangeBookingTreatment(){
        let bookingItems =  new Array();
        let startTime = this.changeBookingTreatmentData.events[0].start;
        let minutesStart = 0;
        let minutesEnd = 0;
        
        await new Promise((resolve) => {
          let counter = 0;
          this.changeBookingTreatmentData.newTreatmentData.subTreatments.forEach((subTreatment) => {
            minutesEnd = minutesEnd + subTreatment.time;
            let bookingResourceIdRoom = null;
            let bookingResourceIdDevice = null;

            if (subTreatment.device == 2 || subTreatment.device == 3) { // Device or Employee & Device
              let availableDevices = this.calendarOptions.resources.filter((availableDevice)=>{ 
                if(availableDevice.deviceTypes && availableDevice.deviceTypes.length > 0){
                  return availableDevice.deviceTypes.includes(subTreatment.deviceId)
                }
              })
              let device = availableDevices.length > 0 ? availableDevices[0] : null;
              if(device){
                bookingResourceIdDevice = device.id;
                if(device.rooms && device.rooms.length > 0){
                  bookingResourceIdRoom = device.rooms[0];
                }
              }
            }
            if (subTreatment.device == 5 || subTreatment.device == 6) { // Employee & Room or Room
              bookingResourceIdRoom = subTreatment.rooms.length > 0 ? subTreatment.rooms[0].id : null;
            }
           

            // Check item matching with original event and other resource
            
            let employeeData = {
              start: minutesStart,
              end: minutesEnd,
              resourceDocId: this.changeBookingTreatmentData.bookingData.primaryHumanResource,
              bookingOverride: false,
              deviceId: subTreatment.deviceId ? subTreatment.deviceId : null,
              type: "employee",
              description: subTreatment.description,
              eventColor: subTreatment.eventColor,
              withEventBlock: typeof subTreatment.withEventBlock !== "undefined" ? subTreatment.withEventBlock : true 
            };

            let deviceData = {
              start: minutesStart,
              end: minutesEnd,
              description: subTreatment.description,
              resourceDocId: bookingResourceIdDevice,
              deviceId: subTreatment.deviceId,
              type: "device",
              bookingOverride: false,
              eventColor: subTreatment.eventColor,
            };

            let roomData = {
              start: minutesStart,
              end: minutesEnd,
              deviceId: subTreatment.deviceId ? subTreatment.deviceId : null,
              description: subTreatment.description,
              bookingOverride: false,
              resourceDocId: bookingResourceIdRoom,
              type: "room",
              eventColor: subTreatment.eventColor,
            };

            if (subTreatment.device == 1) { // Employee
              bookingItems.push(employeeData);
              this.changeBookingTreatmentData.bookingData.employees.push(employeeData.resourceDocId)
            } 
            else if (subTreatment.device == 2) { // Device
              bookingItems.push(deviceData);
              if (bookingResourceIdRoom) {
                bookingItems.push(roomData);
              }
            } 
            else if (subTreatment.device == 3) {  // Employee & Device  
              bookingItems.push(employeeData);
              bookingItems.push(deviceData);
              this.changeBookingTreatmentData.bookingData.employees.push(employeeData.resourceDocId)
              if (bookingResourceIdRoom) {
                bookingItems.push(roomData);
              }
            } 
            else if (subTreatment.device == 4) { // Employee bookingOverride
              employeeData.bookingOverride = true;
              this.changeBookingTreatmentData.bookingData.employees.push(employeeData.resourceDocId)
              
              if(typeof subTreatment.withEventBlock !== "undefined") { // WithEventBlock Field is active and with withEventBlock === true 
                if(subTreatment.withEventBlock === true) {
                  bookingItems.push(employeeData);
                }
              }
              else { //WithEventBlock Field is undefined
                bookingItems.push(employeeData);
              }
            } 
            else if (subTreatment.device == 5) { // Employee & Room
              bookingItems.push(employeeData);
              bookingItems.push(roomData);
              this.changeBookingTreatmentData.bookingData.employees.push(employeeData.resourceDocId)
            } 
            else if (subTreatment.device == 6) { // Room
              bookingItems.push(roomData);
            }

            minutesStart = minutesStart + subTreatment.time;
            counter++;
            if (counter == this.changeBookingTreatmentData.newTreatmentData.subTreatments.length) {
              resolve();
            }
          });
        })

        // 1. Post New Bookings
        await new Promise((resolve) => {
          let bookingCounter = 0;
          bookingItems.forEach((bookingItem) => {
            //Add to Database
            db.collection("bookingen")
              .add({
                bookingId: this.changeBookingTreatmentData.bookingData.bookingSummaryId,
                clientname: `${this.changeBookingTreatmentData.bookingData.clientFirstName} ${this.changeBookingTreatmentData.bookingData.clientLastName}`,
                firstName: this.changeBookingTreatmentData.bookingData.clientFirstName,
                surname: this.changeBookingTreatmentData.bookingData.clientLastName,
                start: moment(startTime).add(bookingItem.start, "m").format("YYYY-MM-DD[T]HH:mm"),
                end: moment(startTime).add(bookingItem.end, "m").format("YYYY-MM-DD[T]HH:mm"),
                dateBooking: this.chosenDateCalendar,
                title: this.changeBookingTreatmentData.newTreatmentData.treatmentTitle,
                description: bookingItem.description,
                eventColor: bookingItem.eventColor, // toegevoegd door Sven
                branchId: this.$store.state.activeBranch.id,
                resourceDocId: bookingItem.resourceDocId,
                companyId: this.$store.state.activeCompany.id,
                treatmentId:  this.changeBookingTreatmentData.newTreatmentId,
                preferredPractitioner: this.changeBookingTreatmentData.bookingData.preferredPractitioner,
                resourceType: bookingItem.type,
                deviceId: bookingItem.deviceId,
                type: "booking",
                bookingOverride: bookingItem.bookingOverride,
                clientId: this.changeBookingTreatmentData.bookingData.clientId,
                deleted: false,
                source: this.changeBookingTreatmentData.bookingData.source,
                uniqIdStamp: this.changeBookingTreatmentData.bookingData.uniqId,
                leadExtra: this.lead && this.preSelectedClientId ? { leadId: this.lead, cliendId: this.preSelectedClientId}: null // Post lead info if lead
              })
              .then(() => {
                bookingCounter++;
                if (bookingCounter == bookingItems.length) {
                  resolve();
                }
              });
          });
        })

       
        let history = {
          bookings: new Array(),
          role: this.$store.state.activeUserRole,
          timestamp: new Date(),
          user: `${this.$store.state.activeUserFirstName} + ${this.$store.state.activeUserLastName}`
        };

        // 2. Delete Old Bookings
        await new Promise((resolve) => {
          let eventCounter = 0;
          this.changeBookingTreatmentData.events.forEach(event=>{
            db.collection("bookingen")
            .doc(event.id)
            .update({
              dateBooking: "deleted",
              deleted: true,
              deletedInfo: {
                normalDatebooking: event.extendedProps.dateBooking,
                normalStart: event.start,
                normalEnd: event.end,
                deletedby: `${this.$store.state.activeUserFirstName} + ${this.$store.state.activeUserLastName}  (${this.$store.state.activeUserCompany})`, //Dynamisch maken via Rollen systeem
                deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
              },
            })
            .then(()=>{
              history.bookings.push({ deleted: true, deletedTimestamp: new Date(), bookingId: event.id });
              eventCounter++
              if(eventCounter == this.changeBookingTreatmentData.events.length){
                resolve()
              }
            })
          })
        })

        // 3. Update Booking Summary
        await db.collection("bookingsummary").doc(this.changeBookingTreatmentData.bookingData.bookingSummaryId).set({
          treatmentTitle: this.changeBookingTreatmentData.newTreatmentData.treatmentTitle,
          treatmentId: this.changeBookingTreatmentData.newTreatmentId,
          treatmentDeviceTypes: this.changeBookingTreatmentData.bookingData.treatmentDeviceTypes,
          employees: this.changeBookingTreatmentData.bookingData.employees,
          history: firebase.firestore.FieldValue.arrayUnion(history),
        },{merge: true})
        
        this.closeChangeBookingTreatment();
        this.notify(this.$t(`calendar.changeBookingTreatmentSucces`, this.$store.state.locale), "success")
      },


      closeChangeBookingTreatment(){
        this.changeBookingTreatmentData = {
          dialog: false,
        };
        bus.$emit("resetTreatmentSelect");
      },

      cleanSearchCommand(){
        this.searchField.showResults = false;
        this.searchResults = new Array();
        this.searchField.value = null;
        this.searchField.showDeleted = false;   
      },

      bookingSearchColor(date, deleted){
        if(deleted){
          return "#cccccc"
        }
        if(date >= moment().format("YYYY-MM-DD")){
          return this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary;
        }
        else{
          return this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].tertiary;
        }
      },
      getBranchName(id){
        let branch = this.$store.state.userBranches.find(branch => { return branch.id == id})
        if(branch){
          return branch.name;
        }
        else{
          return "";
        }
      },

      downPaymentStatus(created){
        let now = moment().format("YYYY-MM-DD");
        let bookingCreated = moment(created.seconds, "X").format("YYYY-MM-DD");
        let timeAgo = moment(now).diff(bookingCreated, 'hours', false);

        if(timeAgo > 12){
          return {
            color: "#ff4c51",
            text: this.$t('booking.downPaymentRed', this.$store.state.locale)
          }
        }
        else if(timeAgo > 6){
          return {
            color: "#ffb400",
            text: this.$t('booking.downPaymentOrange', this.$store.state.locale)
          }
        }
        else{
          return {
            color: "#ffb400",
            text: this.$t('booking.downPaymentGreen', this.$store.state.locale)
          }
        }
      },

      openBooking(booking){
        if(booking.deleted || booking.branchId != this.$store.state.activeBranch.id){
          let selectedEvent = {
            bookingId: booking.id,
            clientname:booking.clientname,
            eventDateBooking: booking.visit,
            eventEnd: booking.end,
            eventStart: booking.start,
            eventType: "booking",
            title: booking.treatmentTitle
          }
          this.selectedEventOriginal = _.cloneDeep(selectedEvent);
          bus.$emit("editBooking", selectedEvent);
        }
        else{
          
          this.$route.params.data = booking;  
          this.$route.params.type = 'gotobooking';  
          this.loadCalendarData();
          // this.calendarApi.gotoDate(booking.start);
          // this.goToBookingInCalendar(booking);
          this.cleanSearchCommand()
        }
      },

      async searchInCalendar(){
        this.searchField.showResults = true;
        this.searchField.loading = true;

        // Determin the Search Period
        let firstDay = moment().subtract(7, 'days').format("YYYY-MM-DD")
        let lastDay = moment(this.chosenDateCalendar).add(30, 'days').format("YYYY-MM-DD")

        let foundClients = new Array()
        this.searchResults = new Array();
        let index =  this.algoSearch.initIndex("thrive_clients");
        var FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
        await index.search(this.searchField.value, {
          facetFilters: [FacetFilterString],
        },)
        .then(({ hits, nbHits }) => {
          hits.forEach((client) => {
            foundClients.push(client)
          });
        });

        if(foundClients.length > 0){ // Clients found in Algolia
          await new Promise((resolve)=>{
            let counter = 0;
            foundClients.forEach(client=>{ // Get bookingsummaries for all clients
              db.collection("bookingsummary")
              .where("visit", ">=", firstDay)
              .where("visit", "<=", lastDay)
              .where("clientId", "==", client.objectID)
              .where("companyId", "==", this.$store.state.activeCompany.id)
              .get()
              .then(snap => {
                if(snap.size > 0){
                  snap.forEach(doc => {
                    let booking = doc.data();
                    booking.id = doc.id;
                    this.searchResults.push(booking);
                  });
                }
                counter++ 
                if(counter == foundClients.length){
                  console.log("resolve")
                  resolve()
                }
              })
            })
          })
          this.searchResults = _.orderBy(this.searchResults, ["start"], ["desc"]); 
          this.searchField.loading = false;
        }
        else{ // No clients found
          this.searchField.loading = false;
        }
      },

      checkResizing(){
        console.log("checkResizing: ")
        this.calculateCalendarHeight();

        this.calculateCalendarHeaderWidth();

        if(this.employeeFutureSchedulePopup.show){
          this.calculateEmployeeScheduleFuturePosition();
        }
      },

      loadData(type){
        this.branchesAvailable = true;
        
        this.loadCalendarData(type); 
        this.checkTimeRegistration();

        console.log("loadData", type)

      },
      loadDataBranch(){
        console.log("loadDataBranch")
        this.loadCalendarData("change");
      },

      //  /   EMPLOYEE SCHEDULE IN THE COMING WEEKS  \

      /*             Example view in calendar

                                ( 😎 )
                            Employee Name                      
       ___________________________/\_________________________
      |                   Schedule employee                  |
      |------------------------------------------------------|
      | Week 7                                               |
      |------------------------------------------------------|
      | Mon 13 Feb           [.......[**********].....]      |
      | Tue 14 Feb  [......[****][====][******]....]         |
      | Fri 17 Feb      [................[*****]..........]  |
      |------------------------------------------------------|
      | Week 8                                               |
      |------------------------------------------------------|
      | Mon 20 Feb           [.........[==]...........]      |
      |______________________________________________________|

      */

      async viewEmployeeScheduleFuture(selectedEmp){ // Find and show employee schedule in future
        console.log(selectedEmp)
        this.employeeFutureSchedulePopup.show = false; 
        this.employeeFutureSchedulePopup.employeeId = selectedEmp.id; 
        this.employeeFutureSchedulePopup.data = selectedEmp;  
        await Promise.all([this.calculateEmployeeScheduleFuturePosition(), this.getEmployeeScheduleFutureData()]); // Wait for position calculation
        this.employeeFutureSchedulePopup.show = true;  
      },

      getBookingColor(booking){
        if(booking.type == 'booking'){
          if(booking.swapping){
            return this.$store.state.activeBranch.calendarSettings.colorNoPreference
          }
          else{
            return this.$store.state.activeBranch.calendarSettings.colorWithPreference
          }
        }
        else{
          return this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].text
        }
      },  


      checkForWeekData(weeksData){ // Hide week if is has no timeblocks
        let timeBlocks = 0;
        let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        for (let i = 0; i < days.length; i++) { 
          if(weeksData.schedule[days[i]].length > 0){
            timeBlocks++
          }
        }
        return timeBlocks > 0 ? true: false;
      },

      calculateEmployeeScheduleFuturePosition(){ // Calculate the position of the modal that shows the future schedule
        let contentCalender = document.getElementById("content-wrapper")
        let employee = document.getElementById(`employee-${this.employeeFutureSchedulePopup.employeeId}`)
        let employeeWidth = employee.offsetWidth;
        var employeeBounding = employee.getBoundingClientRect();
        let contentCalenderBounding = contentCalender.getBoundingClientRect();
        let arrowClass = 'center'; // Default position is in the center

        // Calculate leftover space on both sides.
        let spaceLeft = (employeeBounding.left - contentCalenderBounding.left) + (employeeWidth / 2);
        let spaceRight = (contentCalenderBounding.right - employeeBounding.right) + (employeeWidth / 2);
        
        if(spaceLeft < 300){ // Not enough space on the left to place in center
          arrowClass = 'left'
          this.employeeFutureSchedulePopup.left = (employeeBounding.left - contentCalenderBounding.left) + (employeeWidth / 2) - 34 + 'px';   
          this.employeeFutureSchedulePopup.top = (employeeBounding.top - contentCalenderBounding.top) + 90 + 'px';  
        }
        else if(spaceRight < 300){ // Not enough space on the right to place in center
          arrowClass = 'right';
          this.employeeFutureSchedulePopup.left = (employeeBounding.left - contentCalenderBounding.left) + (employeeWidth / 2) - 500 + 34 + 'px';   
          this.employeeFutureSchedulePopup.top = (employeeBounding.top - contentCalenderBounding.top) + 90 + 'px';  
        }
        else{ // Calculate position for center view
          this.employeeFutureSchedulePopup.left = (employeeBounding.left - contentCalenderBounding.left) + (employeeWidth / 2) - 250 + 'px';   
          this.employeeFutureSchedulePopup.top = (employeeBounding.top - contentCalenderBounding.top) + 90 + 'px';  
        }
        this.employeeFutureSchedulePopup.class = arrowClass; 
        return 
      },

      /**
       * Calculate de absolute position of a timeblok in the day 
       * use in displaying the employee schedule & bookings the coming weeks
       * 
       * @param startTime - starttime of the timeblock
       * @param endTime - endtime of the timeblock
       * @param type - caclulation type total width of the timeblock or postition left
       * 
       * day [------------------[*** timeblock ***]-----]
       *           (left in %)     (width in %)    
       */
      calculateTimeBlockPosition(startTime, endTime, type){
        let minutesBetweenStartAndEnd = moment.duration(moment(this.employeeFutureSchedulePopup.lastTime, "HH:mm").diff(moment(this.employeeFutureSchedulePopup.firstTime, "HH:mm"))).asMinutes();
        let minutesBetweenStartAndStartTime = moment.duration(moment(startTime, "HH:mm").diff(moment(this.employeeFutureSchedulePopup.firstTime, "HH:mm"))).asMinutes();
        let minutesBetweenStartTimeAndEndTime = moment.duration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).asMinutes();

        let percentageLeft = minutesBetweenStartAndStartTime/minutesBetweenStartAndEnd;
        let percentageWidth = minutesBetweenStartTimeAndEndTime/minutesBetweenStartAndEnd;

        let left = `${percentageLeft*100}%`;
        let width = `${percentageWidth*100}%`;
       
        return type == 'left' ? left : width;
      },

      /**
       * Calculate de absolute position of a booking in a timeblok in the day 
       * use in displaying the employee schedule & bookings the coming weeks
       * 
       * @param startTime - starttime of the booking
       * @param endTime - endtime of the booking
       * @param firstTime - starttime of the timeblock
       * @param lastTime - endtime of the timeblock
       * @param type - caclulation type total width of the booking or postition left
       * 
       * timeblock [------------------[*** booking ***]-----]
       *                (left in %)      (width in %)    
       */
      calculateBookingPosition(startTime, endTime, firstTime, lastTime, type){
        let minutesBetweenStartAndEnd = moment.duration(moment(lastTime, "HH:mm").diff(moment(firstTime, "HH:mm"))).asMinutes();
        let minutesBetweenStartAndStartTime = moment.duration(moment(startTime, "HH:mm").diff(moment(firstTime, "HH:mm"))).asMinutes();
        let minutesBetweenStartTimeAndEndTime = moment.duration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).asMinutes();

        let percentageLeft = minutesBetweenStartAndStartTime/minutesBetweenStartAndEnd;
        let percentageWidth = minutesBetweenStartTimeAndEndTime/minutesBetweenStartAndEnd;

        let left = `${percentageLeft*100}%`;
        let width = `${percentageWidth*100}%`;
       
        return type == 'left' ? left : width;
      },

      showTimeBlock(week, continiousType){ // Show only if continiousType is correct
        if(continiousType != 'all'){
          let type = week % 2 === 0 ? 'even' : 'odd';
          return type == continiousType ? true : false;
        }
        else{ 
          return true;
        }
      },

      checkTimeBlockPosition(week, timeBlocks,index, type){
        let filteredTimeBlocks = new Array()
        timeBlocks.forEach((block, i)=>{
          block.item = i == index ? true : false;
          if(block.continiousType != 'all'){
            let type = week % 2 === 0 ? 'even' : 'odd';
            if(type == block.continiousType){
              filteredTimeBlocks.push(block);
            }
          }
          else{ 
            filteredTimeBlocks.push(block);
          }
        })
        let itemIndex = filteredTimeBlocks.findIndex(block => block.item == true)
        if(type == 'first'){
          return itemIndex == 0 ? true : false;
        }
        else{
          return itemIndex == (filteredTimeBlocks.length - 1) ? true : false;
        }
      },

      dateOfToday(){ // Today's day formatted
       return moment().format('YYYY-MM-DD');
      },

      getDayDate(year, week, day, format){ // Get formatted dat based on year, week and day
        let date = moment(day, "ddd").year(year).isoWeek(week).format(format)
        let dateFormatted = moment(day, "ddd").year(year).isoWeek(week).format("YYYY-MM-DD")
        return {
          current: dateFormatted == this.chosenDateCalendar ? true : false,
          date: date
        }
      },

      goToDayDate(year, week, day){ // Navigate to specific date in de future
        let date = moment(day, "ddd").year(year).isoWeek(week).format("YYYY-MM-DD");
        this.employeeFutureSchedulePopup.show = false; 
        this.selectDate(date);
      },
      
      async getEmployeeScheduleFutureData(){ // Get future schedule and booking data of employee
        let date = moment().format("YYYY-MM-DD"); // Day to calculate from
        let weeksInTheFuture = 10; // Weeks to look in the future
        let dayOfTheWeek = moment(date).day(); 
        let daysIntheFuture = (7 * (weeksInTheFuture - 1)) + (7 - dayOfTheWeek); // For loading cache
        let currentWeek = moment(date).isoWeek(); // For loading schedules
        let year = moment(date).format("YYYY");
        let lastDayOfPeriod = moment(date).add(daysIntheFuture, 'days').format("YYYY-MM-DD");
        let weeksData = new Array(); // Array with weeknumbers and years to be filled
        let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        function compare( a, b ) { // Sort Array of Objects based on start value
          if ( a.start < b.start ){ return -1; }
          if ( a.start > b.start ){ return 1; }
          return 0;
        }

        // Approach in 10 steps

        // 1. Generate array with weeks in te future (based on the number of weeks we will look ahead)
        // 2. Get Custom Schedules of employee (of all weeks in the future)
        // 3. Set weeks with the correct schedule data (Custom Schedule or basic schedule)
        // 4. Add extra time and remove leave time
        // 5. Get future bookings from resourceCache
        // 6. Get schedulesPauseExceptions 
        // 7. Set resourceCache bookings in timeBlocks 
        // 8. Convert pause timeblocks in schedule to work timeblocks 
        // 9. Calculate First and Last time to style the length of the calendar bars 
        // 10. Show modal

        // 1. Generate array with weeks in te future
        for (let i = 0; i < weeksInTheFuture; i++) { // Generate the weekdata to look at in employee schedules
          let item = { week: null, year: null };
          let nextWeekNumber = currentWeek + i;

          if(nextWeekNumber > moment().year(year).isoWeeksInYear()){
            item.week = (nextWeekNumber - moment().year(year).isoWeeksInYear());
            item.year = Number(year) + 1;
          }
          else{
            item.week = nextWeekNumber;
            item.year = Number(year);
          }
          weeksData.push(item);
        }

        // 2. Get Custom Schedules of employee      
        await new Promise((resolve)=>{
          let counter = 0;
          weeksData.forEach((week)=>{
            db.collection("schedules")
            .where("employeeId", "==", this.employeeFutureSchedulePopup.employeeId)
            .where("week", "==", week.week)
            .where("year", "==", week.year)
            .where("branchId", "==", this.$store.state.activeBranch.id)
            .get()
            .then((snapshot) => {
              if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                  week.schedule = {
                    "Sun": new Array(),
                    "Mon": new Array(),
                    "Tue": new Array(),
                    "Wed": new Array(),
                    "Thu": new Array(),
                    "Fri": new Array(),
                    "Sat": new Array(),
                  };
                  let continiousType =  week.week % 2 == 0 ? 'even' : 'odd';
                  days.forEach(day=>{
                    week.schedule[day] = new Array();
                    if(doc.data().schedule[day] && doc.data().schedule[day].length > 0){
                      doc.data().schedule[day].forEach(timeblock=>{
                        if(timeblock.type == 'work'){
                          if(timeblock.continiousType == 'all' || (timeblock.continiousType == 'even' && continiousType == 'even') || (timeblock.continiousType == 'odd' && continiousType == 'odd')){
                            week.schedule[day].push(timeblock)
                          }
                        }
                      })
                    }
                  }) 
                  // week.schedule = doc.data().schedule;
                })
              }
              else{
                week.schedule = null;
              }
              counter++
              if(counter == weeksData.length){
                resolve()
              }
            })
          })
        })

        // 3. Set weeks with the correct schedule data
        console.log("this.employeeFutureSchedulePopup.data: ", this.employeeFutureSchedulePopup.data)
        let checkForEndDate = this.employeeFutureSchedulePopup.data.basicSchedule.endDate || this.employeeFutureSchedulePopup.data.employeeContractEnd != "noEndDate" ? true: false;
        let employeeEndDate = null; // Earliest enddate
        let employeeEndDateType = null; // Endday in Branch of Contract?

        if(checkForEndDate){  // Check for earliest enddate
          let employeeEndDateCompany = this.employeeFutureSchedulePopup.data.employeeContractEnd != "noEndDate" ? this.employeeFutureSchedulePopup.data.employeeContractEnd : null;
          let employeeEndDateBranch = this.employeeFutureSchedulePopup.data.basicSchedule.endDate ? this.employeeFutureSchedulePopup.data.basicSchedule.endDate : null;
          employeeEndDate = employeeEndDateCompany && employeeEndDateCompany < employeeEndDateBranch ? employeeEndDateCompany : employeeEndDateBranch;
          employeeEndDateType = employeeEndDateCompany && employeeEndDateCompany < employeeEndDateBranch ? 'contract' : 'branch';
        }


        await new Promise((resolve)=>{
          let counter = 0;          
          weeksData.forEach((week)=>{
            // Set schedule for all weeks: Basis schedule if no custom schedule if found.
            if(!week.schedule && this.employeeFutureSchedulePopup.data.basicSchedule && this.employeeFutureSchedulePopup.data.basicSchedule){
               week.schedule = _.cloneDeep(this.employeeFutureSchedulePopup.data.basicSchedule)
            }

            if(checkForEndDate && employeeEndDate){ // Remove schedules on days after last endday of employee
              let firstDayOfThisWeek = moment().isoWeek(week.week).year(week.year).startOf('week').format("YYYY-MM-DD");
              let lastDayOfThisWeek = moment().isoWeek(week.week).year(week.year).endOf('week').format("YYYY-MM-DD");

              if(this.employeeFutureSchedulePopup.data.basicSchedule.endDate <= lastDayOfThisWeek && this.employeeFutureSchedulePopup.data.basicSchedule.endDate >= firstDayOfThisWeek){
                for (let i = 0; i < days.length; i++) { 
                  let dayDate = moment(days[i], "ddd").isoWeek(week.week).year(week.year).format("YYYY-MM-DD");
                  if(dayDate > employeeEndDate){
                    week.schedule[days[i]] = new Array();
                  }
                }
              }
              else if(this.employeeFutureSchedulePopup.data.basicSchedule.endDate < firstDayOfThisWeek){
                week.schedule = {
                  "Sun": new Array(),
                  "Mon": new Array(),
                  "Tue": new Array(),
                  "Wed": new Array(),
                  "Thu": new Array(),
                  "Fri": new Array(),
                  "Sat": new Array(),
                };
              }
            }
            counter++
            if(counter == weeksData.length){
              resolve()
            }
          })
        })

        // 4. Add extra time and remove leave time
        let schedulesExceptionsExtra = new Array();
        let schedulesExceptionsAbcense = new Array();
        await db.collection("schedulesExceptions")
        .where("branchId", "==", this.$store.state.activeBranch.id)
        .where("resourceId", "==", this.employeeFutureSchedulePopup.employeeId)
        .where("startdate", ">=", date)
        .where("startdate", "<=", lastDayOfPeriod)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let schedulesException = doc.data();
            schedulesException.id = doc.id;
            if(schedulesException.type == 'absence'){
              schedulesExceptionsAbcense.push(schedulesException);
            }
            else{
              schedulesExceptionsExtra.push(schedulesException);
            }
          });
        })
        await db.collection("schedulesExceptions")
        .where("branchId", "==", this.$store.state.activeBranch.id)
        .where("resourceId", "==", this.employeeFutureSchedulePopup.employeeId)
        .where("enddate", ">=", date)
        .where("enddate", "<=", lastDayOfPeriod)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let schedulesException = doc.data();
            schedulesException.id = doc.id;
            if(schedulesException.startDate <= date || schedulesException.startDate >= lastDayOfPeriod){
              if(schedulesException.type == 'absence'){
                schedulesExceptionsAbcense.push(schedulesException);
              }
              else{
                schedulesExceptionsExtra.push(schedulesException);
              }
            }  
          });
        })
        
        if(schedulesExceptionsExtra.length > 0 || schedulesExceptionsAbcense.length > 0){
          await new Promise((resolve)=>{
            let counter = 0;

            weeksData.forEach(week=>{
              let firstDayOfThisWeek = moment().isoWeek(week.week).year(week.year).startOf('week').format("YYYY-MM-DD");
              let lastDayOfThisWeek = moment().isoWeek(week.week).year(week.year).endOf('week').format("YYYY-MM-DD");

              schedulesExceptionsAbcense.forEach(abcense=>{
                if(abcense.startdate >= firstDayOfThisWeek && abcense.startdate <= lastDayOfThisWeek || abcense.enddate >= firstDayOfThisWeek && abcense.enddate <= lastDayOfThisWeek){
                  for (let i = 0; i < days.length; i++) { 
                    let dayDate = moment(days[i], "ddd").isoWeek(week.week).year(week.year).format("YYYY-MM-DD");
                    if(week.schedule[days[i]].length > 0){
                      if(dayDate >= abcense.startdate && dayDate <= abcense.enddate){
                        if(abcense.time.length == 0){
                          week.schedule[days[i]] = new Array();
                        }
                        else{
                          week.schedule[days[i]].forEach(timeBlock=>{
                            abcense.time.forEach(time=>{                             
                              if(timeBlock.start >= time.start && timeBlock.end <= time.end){
                                timeBlock.start = null;
                                timeBlock.end = null;
                              }
                              else if(timeBlock.start <= time.start && timeBlock.end <= time.end && timeBlock.end > time.start){
                                timeBlock.end = time.start
                              }
                              else if(timeBlock.start >= time.start && timeBlock.end >= time.end && timeBlock.start < time.end){
                                timeBlock.start = time.end
                              }
                              else if(timeBlock.start < time.start && timeBlock.end > time.end){                               
                                let newTimeBlock = {
                                  start: time.end,
                                  end: timeBlock.end,
                                  type: timeBlock.type,
                                  continiousType: timeBlock.continiousType
                                }
                                timeBlock.end = time.start
                                week.schedule[days[i]].push(newTimeBlock)
                              }
                            })
                          })
                        }
                      }
                    }
                  }
                }
              })
              schedulesExceptionsExtra.forEach(extra=>{
                for (let i = 0; i < days.length; i++) { 
                  let dayDate = moment(days[i], "ddd").isoWeek(week.week).year(week.year).format("YYYY-MM-DD");
                  if(extra.startdate == dayDate){
                    extra.time.forEach(time=>{       
                      let newTimeBlock = {
                        start: time.start,
                        end: time.end,
                        type: 'work',
                        continiousType: 'all'
                      }
                      week.schedule[days[i]].push(newTimeBlock)
                    })
                  }
                }
              })
              counter++
              if(counter == weeksData.length){
                resolve()
              }
            }) 
          })
        }
       
        // 5. Get future bookings from resourceCache  
        let cacheDocuments = new Array();
        await db.collection("resourceCache")
          .where("branchId", "==", this.$store.state.activeBranch.id)
          .where("resourceId", "==", this.employeeFutureSchedulePopup.employeeId)
          .where("date", ">=", date)
          .where("date", "<=", lastDayOfPeriod)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let cache = doc.data();
              cache.id = doc.id;
              cache.year = moment(doc.data().date).year();
              cache.week = moment(doc.data().date).isoWeek();
              cache.day = moment(doc.data().date).format("ddd");
              cacheDocuments.push(cache);
            });
          })
         
        // 6. Get schedulesPauseExceptions  
        let schedulesPauseExceptionsPeriod = new Array();
        await db.collection("schedulesPauseExceptions")
          .where("branchId", "==", this.$store.state.activeBranch.id)
          .where("resourceId", "==", this.employeeFutureSchedulePopup.employeeId)
          .where("date", ">=", date)
          .where("date", "<=", lastDayOfPeriod)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let pauseException = doc.data();
              pauseException.id = doc.id;
              schedulesPauseExceptionsPeriod.push(pauseException);
            });
          })

        // 7. Set Cache bookings in timeBlocks 
        await new Promise((resolve)=>{
          if(cacheDocuments.length > 0){
            let counter = 0;
            cacheDocuments.forEach(cache=>{
              weeksData.forEach(week=>{
                if(week.week == cache.week && week.year == cache.year){
                  if(week.schedule[cache.day]){
                    week.schedule[cache.day].forEach(timeBlock=>{
                      if(cache.bookings && cache.bookings.length > 0){
                        cache.bookings.forEach(booking=>{
                          if(timeBlock.start <= booking.start && timeBlock.end >= booking.start){
                            if(!timeBlock.bookings){
                              timeBlock.bookings = new Array();
                            }
                            timeBlock.bookings.push(booking)
                          }
                        }) 
                      }           
                    })
                    counter++

                    if(counter == cacheDocuments.length * weeksData.length){
                      resolve()
                    }
                  }
                  else{
                    counter++
                    if(counter == cacheDocuments.length * weeksData.length){
                      resolve()
                    }
                  }  
                }
                else{
                  counter++
                  if(counter == cacheDocuments.length * weeksData.length){
                    resolve()
                  }
                }
              })
            })
          }
          else{
            resolve()
          } 
        })

        // console.log("cacheDocuments: ", cacheDocuments);
        // console.log("schedulesPauseExceptionsPeriod: ", schedulesPauseExceptionsPeriod)

        // 8. Convert pause timeblocks in schedule to work timeblocks
        await new Promise((resolve)=>{
          let counter = 0;
          weeksData.forEach((week)=>{
            if(week.schedule){
              for (let i = 0; i < days.length; i++) { 
                if(week.schedule[days[i]].length > 0){   

                  let dayDate = moment(days[i], "ddd").isoWeek(week.week).year(week.year).format("YYYY-MM-DD")
                  let timeBlocks = (week.schedule[days[i]]).sort(compare);
                  let removesPausesToday = schedulesPauseExceptionsPeriod.filter(removedPause =>{ return removedPause.resourceId == this.employeeFutureSchedulePopup.employeeId && removedPause.date == dayDate})
                  let currentPause = 0;

                  timeBlocks.forEach(timeBlock=>{
                    if(removesPausesToday && removesPausesToday.length > 0){
                      if(timeBlock.type =='pause'){
                        removesPausesToday.forEach(removedPauseDay=>{
                          removedPauseDay.removedPauses.forEach(removedPause=>{
                            if(currentPause == removedPause){
                              timeBlock.type = 'work';
                            }
                          })
                        })
                        currentPause++
                      }
                    }
                  })
                }
              }
              counter++
              if(counter == weeksData.length){
                resolve()
              }
            }
            else{
              counter++
              if(counter == weeksData.length){
                resolve()
              }
            }
          })
        });

        // 9. Calculate First and Last time to style the length of the calendar bars
        let firstTime =  "23:59";
        let lastTime = "00:00";

        await new Promise((resolve)=>{
          let counter = 0;
          weeksData.forEach((week)=>{
            if(week.schedule){
              for (let i = 0; i < days.length; i++) { 
                if(week.schedule[days[i]].length > 0){
                  week.schedule[days[i]].forEach(timeBlock=>{
                    if(timeBlock.start < firstTime){ // Save only the earliest time
                      firstTime = _.cloneDeep(timeBlock.start)
                    }
                    if(timeBlock.end > lastTime){ // Save only the latest time
                      lastTime = _.cloneDeep(timeBlock.end)
                    }
                  })
                }
              }
              counter++
              if(counter == weeksData.length){
                resolve()
              }
            }
            else{
              counter++
              if(counter == weeksData.length){
                resolve()
              }
            }
          })
        });

        let message = null;

        if(employeeEndDate && employeeEndDateType){
          if(employeeEndDate < lastDayOfPeriod){
            message = employeeEndDateType == 'branch' ? 'employeeEndDateBranch' : 'employeeEndDateContract';
          }
        }

        // 10. Show modal
        this.employeeFutureSchedulePopup.firstTime = moment(firstTime, "HH:mm").subtract(15, 'minutes').format("HH:mm");
        this.employeeFutureSchedulePopup.lastTime = moment(lastTime, "HH:mm").add(15, 'minutes').format("HH:mm");
        this.employeeFutureSchedulePopup.weeksData = weeksData;
        this.employeeFutureSchedulePopup.message = message;
        this.employeeFutureSchedulePopup.employeeEndDate = employeeEndDate ? moment(employeeEndDate).add(1, 'day').format("DD-MM-YYYY") : null;
        return
      },

      closeViewEmployeeScheduleFuture(){ // Empty data and hide overlay an modal
        this.employeeFutureSchedulePopup.show = false; 
        this.employeeFutureSchedulePopup.message = null;
        this.employeeFutureSchedulePopup.employeeEndDate = null
        this.employeeFutureSchedulePopup.employeeId = null; 
        this.employeeFutureSchedulePopup.data = new Object(); 
      },

      //  \   EMPLOYEE SCHEDULE IN THE COMING WEEKS  /

      async renderCalendar(){
      
        this.localetime = moment(this.calendarApi.getDate()).locale(this.$store.state.locale.toLowerCase()).format("dddd D MMMM YYYY");
        // this.calendar = this.$refs.fullCalendar.getApi();
        // Local Time of Calendar
        // this.calendar.setOption("slotDuration", this.$store.state.activeBranch.calendarSettings.timeIterationCalendar);

        // const paramsAvailable = await this.checkForParams();
        // //No params found, then go to default mode
        // if (!paramsAvailable) {
          // this.localetime = moment(this.$refs.fullCalendar.getApi().getDate()).locale(this.$store.state.locale.toLowerCase()).format("dddd D MMMM YYYY");
        //   this.chosenDateCalendar = moment(this.$refs.fullCalendar.getApi().getDate()).format("YYYY-MM-DD");
        //   // this.fetchResources();
        // }
        
        // await this.createCalendarRange('renderCalendar');
        

        //Time Calendar and Local Storage
        if (window.localStorage.getItem("timeIterationCalendar") === null) { // Get time Iteration Calendar and set New Localstorage
          this.calendarApi.setOption("timeIterationCalendar", this.$store.state.activeBranch.calendarSettings.timeIterationCalendar);
          window.localStorage.setItem("timeIterationCalendar", this.$store.state.activeBranch.calendarSettings.timeIterationCalendar);
          
          this.slotDuration = this.$store.state.activeBranch.calendarSettings.timeIterationCalendar;
        }
        else { // Local Storage time Iteration Calendar Exist
          const slotDuration = window.localStorage.getItem("timeIterationCalendar");
          this.calendarApi.setOption("slotDuration", slotDuration);
          
          this.slotDuration = slotDuration;
        }
        
        //Scroll to time with a delay
        if (this.chosenDateCalendar === moment().format("YYYY-MM-DD")) {
          this.scrollToIndicator(new Date());
        }

       

        return 
      },

      async loadCalendarData(type) {

        console.log("Load Calendar Data, type: ", type)
       
        this.calendarLoading = false;
      
        await this.$store.dispatch('downloadBranchTime');


        this.branchInformation = this.$store.state.activeBranch; //Global Branch Information
        this.settingsBranch = this.$store.state.settingsBranch; //Active Branch Time Settings
        this.calendarOptions.businessHours = this.$store.state.businessHours; //Active Branch Business Hours

        this.eventsObjects = new Array();
        this.schedulesPauseExceptions = new Array();

        this.branchClosed = false;
        this.branchClosedReason = "";
        this.resourcesFromDB = new Array();
        this.resourceCache = new Array(); //Cache of Resoucres
        this.resourceObjects = new Array(); //Resources
        this.eventBookingen = new Array(); //Bookings
        this.bookingsummaries = new Array(); //Bookingsummaries
        this.localBookingen = new Array();
        this.branchInformation = new Object();
       
        if (type === "first") {
          this.chosenDateCalendar = moment().format("YYYY-MM-DD");
          this.calendarLoading = false; // Stond eerst op true maar zorgt soms voor een lege / witte agenda. Onduidelijk waar dit voor nodig is #Sven

        } else {
          this.calendarLoading = false;
        }

        //Is it a routed booking via Clients Dashboard?
        if (this.$route.params.type) {
          this.chosenDateCalendar = this.$route.params.data.visit; //Booking Visit YYYY-MM-DD
          this.calendarApi.gotoDate(this.chosenDateCalendar);
          if(this.$route.params.open){
            let selectedEvent = {
              bookingId: this.$route.params.data.id,
              clientname:this.$route.params.data.clientname,
              eventDateBooking: this.$route.params.data.visit,
              eventEnd: this.$route.params.data.end,
              eventStart: this.$route.params.data.start,
              eventType: "booking",
              title: this.$route.params.data.treatmentTitle
            }
            this.selectedEventOriginal = _.cloneDeep(selectedEvent);
            bus.$emit("editBooking", selectedEvent);
            this.highlightAllRelatedEvents(selectedEvent, 'show')
            this.checkUserActivity = false;
            this.hideBubble();
          }
        }    

        //When changing the Branch          
        if (type && type == 'change') {
          console.log("Change")
          await this.$store.dispatch('downloadBranchTime');
          this.snapshotListenerBookings();
          this.snapshotListenerBookingsummary();
          this.snapshotListenerCache();
        }

        // Check if branch closed and check for closeReason to show
        let branchClosed = this.checkIfBranchIsClosed(this.chosenDateCalendar)
        if (branchClosed.closed === true) {
          this.branchClosed = true;
          this.branchClosedReason = branchClosed.closedReason;
        }
        this.checkResizing(); //Calculate the Header With

        const getCalendarEvents = await this.getCalendarEventsApi(this.chosenDateCalendar,this.$store.state.activeBranch.id);

        if(getCalendarEvents){
          this.eventsObjects = getCalendarEvents.bookings;
          this.bookingsummaries = getCalendarEvents.bookingsummaries;
          this.schedulesPauseExceptions = getCalendarEvents.pauses;
        
          console.log("Load Calendar Events BACKEND API REFETCH")
        }
        

        await this.$nextTick();

        await this.fetchResources();

        await this.$nextTick();
      

        
        
        
        await this.$nextTick();

        this.querySnapshotFirebaseBooking();
        this.querySnapshotFirebaseBookingsummary();
        this.querySnapshotFirebaseCache();

        this.createCalendarRange('renderCalendar');
               
        this.renderCalendar();
              
        await this.$nextTick();
  
        
     
        

        await this.getSchedulesPauseExceptions();
        this.generateSchedulePause();
        this.calendarApi.refetchEvents();
            
        
        this.calendarLoading = false;
        await this.$nextTick();
        

        if (this.$route.params.type) {

          //data output is a booking data object
          const currentTime = moment(this.$route.params.data.start).subtract(1, 'hours').format("HH:mm:ss");
          this.calendarApi.scrollToTime(currentTime);

          console.log("scroll To Time: ", currentTime);

          this.$route.params.type = '';  
        
        }
 
       
      },

 
      async getSchedulesPauseExceptions(){
        console.log("getSchedulesPauseExceptions", this.chosenDateCalendar)
        this.schedulesPauseExceptions = new Array();
        await db.collection("schedulesPauseExceptions")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("branchId", "==", this.$store.state.activeBranch.id)
        .where("date", "==", this.chosenDateCalendar)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if(doc.data().removedPauses && doc.data().removedPauses.length > 0){
              let pauseExceptionDoc = doc.data();
              pauseExceptionDoc.id = doc.id;
              this.schedulesPauseExceptions.push(pauseExceptionDoc);
            }
          });
        })
        .catch(error=>{
          console.error("We hebben een error: ", error)
        })
        console.log("getSchedulesPauseExceptions done")
        return
        
      },

      getCalendarApi() {
        return this.calendarApi.getDate();
      },

      async fetchEvents() { // First Startup
        return this.eventsObjects;
      },

         
      async createCalendarRange(type) { // Check if events in the calendar are outside the min and max visual time

          
        
        let starttime = moment(this.chosenDateCalendar, "YYYY-MM-DD").format("YYYY-MM-DD") + "T" + this.settingsBranch.branchTime[moment(this.chosenDateCalendar, "YYYY-MM-DD").locale('en').format("ddd")].start;
        let endtime = moment(this.chosenDateCalendar, "YYYY-MM-DD").format("YYYY-MM-DD") + "T" + this.settingsBranch.branchTime[moment(this.chosenDateCalendar, "YYYY-MM-DD").locale('en').format("ddd")].end;

        //Genereer tijdcode
        console.log("📆starttime", starttime);
        console.log("📆endtime", endtime);
        let rangeID = moment.range(starttime, endtime);
        // console.log("starttime: ", starttime)
        // console.log("endtime: ", endtime)
        // console.log("rangeID: ", rangeID)

        this.customTimecolumn = new Array();
        this.customTimecolumn = Array.from(
          rangeID.by("minutes", {
            step: this.$store.state.activeBranch.calendarSettings.timeIterationCalendar, //Dynamisch maken via Settings
            excludeEnd: false,
          })
        );
        // dynamicResourcehours.length == 5 // true
        this.customTimecolumn = this.customTimecolumn.map((m) => "<tr><td class='zui-sticky-col'>" + moment(m).format("HH:mm") + "</td></tr>"); // ['00:00', '01:00', '02:00', '03:00', '04:00']
 
        // this.minTime = rangeID.start.subtract(60,'minutes').format("HH:mm");
        // this.maxTime = rangeID.end.add(60,'minutes').format("HH:mm");

        this.defaultRange.min = rangeID.start.format("HH:mm:ss");
        this.defaultRange.max = rangeID.end.format("HH:mm:ss");

        // console.log("defaultRange.max", this.defaultRange.max);
        let events = this.eventsObjects.filter(event=> {return !event.allDay}) // Remove All Day events from filter

        const eventsExist = events.length > 0;
        //Use of reduce to compare with acummulator the lowest booking start and highest booking end
        const earliestEvent = eventsExist && events.reduce((a, b) => (a.start < b.start ? a : b)); //Earliest event
        const latestEvent = eventsExist && events.reduce((a, b) => (a.end > b.end ? a : b)); //Last Event

        let minTime = new String();
        let maxTime = new String();
        let weekDay = moment(this.chosenDateCalendar, "YYYY-MM-DD").weekday();

        //earliestEvent.start => Earliest event start
        //latestEvent.end   => Last Event end
        //defaultRange => Default min and max time of calendar

        if (earliestEvent.start != undefined && moment(earliestEvent.start, "YYYY-MM-DDTHH:mm").format("HH:mm") < this.defaultRange.min) {
          minTime = moment(earliestEvent.start, "YYYY-MM-DDTHH:mm").format("HH:mm");
        }
        else {
          minTime = this.defaultRange.min != null ? this.defaultRange.min : minTime;
        }
        // console.log("Mintime BEFORE", minTime);
        if (latestEvent.end != undefined && moment(latestEvent.end, "YYYY-MM-DDTHH:mm").format("HH:mm") > this.defaultRange.max) {
          // maxTime = moment(latestEvent.end).add(60,'minutes').format("HH:mm");
          maxTime = moment(latestEvent.end, "YYYY-MM-DDTHH:mm").format("HH:mm");
        }
        else {
          maxTime = this.defaultRange.min != null ? this.defaultRange.max : maxTime;
        }
        // console.log("WEEKDAY", weekDay);
        // console.log("resource Objects", this.resourceObjects)
        // console.log("minTime ⏰ before", minTime);

        // console.log("maxTime ⏰ before", maxTime);
        // console.log("chosenDateCalendar", this.chosenDateCalendar);
        // console.log("minTime ⏰ before moment event",earliestEvent,  moment(earliestEvent.start).format("HH:mm"));
        // console.log("maxTime ⏰ before moment event",latestEvent, moment(latestEvent.end).format("HH:mm"));

        // Calculate lowest startTime Available
        const minAvailableTime = await this.resourceObjects
          .filter((resource) => resource.resourceTypeSelect === "employee") //Loop tru resources and Filter by "employee"
          .map((resource) => resource.businessHours) //Get ALL the business hours
          .map((resBusinessHours) => {
            return resBusinessHours.filter((businessHours) => businessHours.daysOfWeek[0] == weekDay);
          }) //Filter the Business hours by Day
          .map((resBusinessHours) => {
            //Get the lowest available time ["08:30", "09:00", "09:00"]
            return moment(Math.min(...resBusinessHours.map((dayBusinessHours) => moment(dayBusinessHours.startTime, "HH:mm")))).format("HH:mm");
          })
          .filter((availableTime) => availableTime !== "Invalid date" && availableTime !== "00:00") //Filter "Invalid dates" appears when there is no there is no businesshour available
          .sort((a, b) => {
            //sort small to big -  [ '13:00', '17:30', '18:00' ]
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
          });       
          
        // Calculate highest endTime Available
        let maxAvailableTime = await this.resourceObjects
          .filter((resource) => resource.resourceTypeSelect === "employee") //Loop tru resources and Filter by "employee"
          .map((resource) => resource.businessHours) //Get ALL the business hours
          .map((resBusinessHours) => {
            return resBusinessHours.filter((businessHours) => businessHours.daysOfWeek[0] == weekDay);
          }) //Filter the Business hours by Day
          .map((resBusinessHours) => {
            //Get the highest available time ["18:00", "17:00", "14:30"]
            return moment(Math.max(...resBusinessHours.map((dayBusinessHours) => moment(dayBusinessHours.endTime, "HH:mm")))).format("HH:mm");
          })
          .filter((availableTime) => availableTime !== "Invalid date" && availableTime !== "00:00") //Filter "Invalid dates" appears when there is no there is no businesshour available
          .sort((a, b) => {
            //sort big to small -  [ '18:00', '17:30', '13:00' ]
            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
          });

        console.log("⬇️ maxAvailableTime", maxAvailableTime);
        console.log("⬇️ maxTime", maxTime);
        //MaxTime
        const clockAbove = "23";
        let highestTimeAvailableTimeObject = maxAvailableTime[0] ? moment(this.chosenDateCalendar + " " + maxAvailableTime[0]) : moment(this.chosenDateCalendar);
        let highestTimeObject = moment(this.chosenDateCalendar + " " + maxTime);
        if (maxAvailableTime[0]) {
          //10:00 vs 10:30

          if (maxTime.substring(0, 5) <= maxAvailableTime[0]) {
            this.calendarEndTime =
              clockAbove == maxAvailableTime[0].substring(0, 2)
                ? highestTimeAvailableTimeObject.set({ hour: 23, minute: 59 }).format("HH:mm:ss")
                : highestTimeAvailableTimeObject.add(30, "minutes").format("HH:mm:ss");
            this.calendarApi.setOption("slotMaxTime", this.calendarEndTime);
          }
          else { // maxAvailableTime[0] is smaller
            this.calendarEndTime = maxTime.substring(0, 2) == clockAbove ? highestTimeObject.set({ hour: 23, minute: 59 }).format("HH:mm") : highestTimeObject.add(30, "minutes").format("HH:mm");
            this.calendarApi.setOption("slotMaxTime", this.calendarEndTime);
          }
        }
        else { // No maxAvailableTime time found
          
          this.calendarEndTime = maxTime.substring(0, 2) == clockAbove ? highestTimeObject.set({ hour: 23, minute: 59 }).format("HH:mm") : highestTimeObject.add(30, "minutes").format("HH:mm");
          this.calendarApi.setOption("slotMaxTime", this.calendarEndTime);
        }
        console.log("⬆️ minAvailableTime", minAvailableTime);
        console.log("⬆️ minTime", minTime);

        // MinTime
        const clockBelow = "01";
        let lowestTimeAvailableTimeObject = minAvailableTime[0] ? moment(this.chosenDateCalendar + " " + minAvailableTime[0]) : moment(this.chosenDateCalendar);
        let lowestMinTimeObject = moment(this.chosenDateCalendar + " " + minTime);
        if (minAvailableTime[0]) {
          if (minTime.substring(0, 5) >= minAvailableTime[0]) {
            this.calendarStartTime =
              clockBelow >= minAvailableTime[0].substring(0, 2)
                ? lowestTimeAvailableTimeObject.set({ hour: 0, minute: 0 }).format("HH:mm")
                : lowestTimeAvailableTimeObject.subtract(30, "minutes").format("HH:mm");
            this.calendarApi.setOption("slotMinTime", this.calendarStartTime);
          }
          else { // Min AvailableTime[0] is bigger
            let lowestMinTimeObjectMinutes = moment(lowestMinTimeObject).format("HH:mm:ss")
            let minTimeBigger = minAvailableTime[0] && (lowestMinTimeObjectMinutes < minAvailableTime[0]) ? minAvailableTime[0] : lowestMinTimeObjectMinutes;

            this.calendarStartTime = minTime.substring(0, 2) == clockBelow ? moment(minTimeBigger, "HH:mm:ss").format("HH:mm") : moment(minTimeBigger, "HH:mm:ss").subtract(60, "minutes").format("HH:mm");
            this.calendarApi.setOption("slotMinTime", this.calendarStartTime);
          }
        }
        else { // No time found
          this.calendarStartTime = minTime.substring(0, 2) == clockBelow ? lowestMinTimeObject.set({ hour: 0, minute: 0 }).format("HH:mm") : lowestMinTimeObject.subtract(60, "minutes").format("HH:mm");
          this.calendarApi.setOption("slotMinTime", this.calendarStartTime);
        }
        
        console.log("⏰ minTime", this.calendarStartTime);
        return
      },

      // async downloadBranchTime() { //LEGACY CODE

      //   console.log("this.$store.state.activeBranch.id)", this.$store.state.activeBranch.id)

      //   const getBusinessHours = async () => {

      //     return await db.collection("branches")
      //       .doc(this.$store.state.activeBranch.id)
      //       .collection("businessHours")
      //       .doc("default")
      //       .get()
      //       .then((doc) => {
      //         this.settingsBranch = doc.data();
      //       })
      //       .then(() => {
      //         const businessHoursBranch = [
      //           {
      //             daysOfWeek: [0],
      //             startTime: this.settingsBranch.branchTime.Sun.start,
      //             endTime: this.settingsBranch.branchTime.Sun.end,
      //           },
      //           {
      //             daysOfWeek: [1],
      //             startTime: this.settingsBranch.branchTime.Mon.start,
      //             endTime: this.settingsBranch.branchTime.Mon.end,
      //           },
      //           {
      //             daysOfWeek: [2],
      //             startTime: this.settingsBranch.branchTime.Tue.start,
      //             endTime: this.settingsBranch.branchTime.Tue.end,
      //           },
      //           {
      //             daysOfWeek: [3],
      //             startTime: this.settingsBranch.branchTime.Wed.start,
      //             endTime: this.settingsBranch.branchTime.Wed.end,
      //           },
      //           {
      //             daysOfWeek: [4],
      //             startTime: this.settingsBranch.branchTime.Thu.start,
      //             endTime: this.settingsBranch.branchTime.Thu.end,
      //           },
      //           {
      //             daysOfWeek: [5],
      //             startTime: this.settingsBranch.branchTime.Fri.start,
      //             endTime: this.settingsBranch.branchTime.Fri.end,
      //           },
      //           {
      //             daysOfWeek: [6],
      //             startTime: this.settingsBranch.branchTime.Sat.start,
      //             endTime: this.settingsBranch.branchTime.Sat.end,
      //           },
      //         ];

      //         this.calendarOptions.businessHours = businessHoursBranch;
      //         console.log("businessHoursBranch", businessHoursBranch)
      //         return;

      //       })
      //       .catch((err) => {
      //         console.error("Error Loading Branch BusinessHours data: ", err);
      //       });

      //   }


      //   const getBranchSettings = async () => {

      //     return await db.collection("branches")
      //       .doc(this.$store.state.activeBranch.id)
      //       .get()
      //       .then((doc) => {
      //         this.branchInformation = doc.data();
      //         return
      //       }).catch((err) => {
      //         console.error("Error Loading Branch Settings data: ", err);
      //       });

      //   }

      //   return Promise.all([getBusinessHours(), getBranchSettings()]).then(() => {
      //     console.log("All Branch Settings Loaded")
      //   });


      // },

      /**
       * Calculate the time of the branch exceptions
       * @param {string} chosenDate - The date of the calendar as YYYY-MM-DD
       * return {boolean} - True if the branch is closed
       */
      checkIfBranchIsClosed(chosenDate) {

        //Check if its in the range of the exception
        if(this.$store.state.activeBranch.hasOwnProperty('exception') && this.$store.state.activeBranch.exception.some((exception) => exception.start <= chosenDate && exception.end >= chosenDate)){
          return {
            closed: true,
            closedReason:  this.$store.state.activeBranch.exception.find((exception)=>{ return exception.start <= this.chosenDateCalendar && exception.end >= this.chosenDateCalendar}).extra
          }
        }
        else{
          return {
            closed: false,
            closedReason:  null
          }
        }
      },

      async fetchResourcesAPI(date, branchId) {

      /**
       *  OUTPUT DATA:
         	branchId: as string,
          calendarDate: as string YYYY-MM-DD,
          employeeSchedules: as array of objects,
          loadedDevices: as array of objects,
          loadedRooms: as array of objects,
          businessHoursBranchCalendarOptions: as array of objects,
          scheduleExceptions: as array of objects
        */
        let forceCacheCreation = this.$store.state.activeUserRoleNumber == 0 ? !this.$store.state.calendarApiCache : this.$store.state.activeBranch.calendarSettings.useResourceCache ? !this.$store.state.activeBranch.calendarSettings.useResourceCache : true 
        //Axios post 
        try {
          const response = await axios.post(`${this.$store.state.calendarApi.url}/getDayResources`, {
            date: date,
            branchId: branchId,
            // forceNewCache: this.$store.state.userRoleNumber == 0 ? true : false
            forceNewCache: forceCacheCreation 
          }, 
          {
            timeout: 5000,
            headers: {
              'Content-Type': 'application/json'
            }
          });

          const fetchResourceData = response.data;

          // console.log("CALENDAR API DAYRESOURCES RESPONSE: ", response.data)

          if(forceCacheCreation) {
            console.log("%cDeveloper Mode Cache Off", "background: red; color: white; font-size: 16px;")
          }
          else{
            console.log("%cDeveloper Mode Cache ON", "background: green; color: white; font-size: 16px;")
          }
                     

          return fetchResourceData;

        } catch (error) {
          console.error("Error getDayResources API Fetching Resources", error)
        }


      },

      async fetchResources() {

        console.log("Start Fetch Resources")

        

        let fetchResourceData;
        try {
          fetchResourceData = await this.fetchResourcesAPI(this.chosenDateCalendar, this.$store.state.activeBranch.id);
        } catch (error) {
          console.error("Failed to fetch resource data:", error);
          return;
        }

        let fetchStart = this.chosenDateCalendar; //YYYY-MM-DD


    
        this.resourceObjects = new Array();

          
          console.log("fetchResourceData", fetchResourceData.employeeSchedules)

         
         
          fetchResourceData.employeeSchedules = fetchResourceData.employeeSchedules.map((employee,index) => {
            if (!employee || !employee.businessHours) {
              console.log("Invalid employee data or missing businessHours", employee);
              return null; // Of een andere geschikte default waarde
            }
            console.log("employee", employee.businessHours);
            let newEmployeeData = employee; // Gebruik spread operator om een kopie te maken
            newEmployeeData.docId = employee.id;
            newEmployeeData.extendedProps.businessHours = newEmployeeData.businessHours;
            return newEmployeeData;
          });
              
                     
         
          // this.resourceObjects = [...loadedEmployees, ...loadedDevices, ...loadedRooms]; //LEGACY
          this.resourceObjects = [...fetchResourceData.employeeSchedules, ...fetchResourceData.loadedDevices, ...fetchResourceData.loadedRooms]; 
         

          // this.devicesLoaded = this.resourceObjects.filter((resource) => resource.resourceTypeSelect == "device"); //LEGACY
          this.devicesLoaded = fetchResourceData.loadedDevices;
          // this.roomsLoaded = this.resourceObjects.filter((resource) => resource.resourceTypeSelect == "room"); //LEGACY
          this.roomsLoaded = fetchResourceData.loadedRooms;

 
          // this.employeesResources = loadedEmployees LEGACY
          this.employeesResources = fetchResourceData.employeeSchedules;

          this.scheduleExceptions = fetchResourceData.scheduleExceptions
  
          this.scheduleExceptionsOriginal = _.cloneDeep(this.scheduleExceptions); //Sven's Original Schedule Exceptions

          let availableEmployees = fetchResourceData.employeeSchedules;
          let tmpResourcesDevice = fetchResourceData.loadedDevices;
          this.resourcesRoomReservation = fetchResourceData.loadedRooms;



          //GROUP BY ROOMS AND DEVICES_IDS
          let residualRooms = fetchResourceData.loadedRooms;
          let residualDevices = fetchResourceData.loadedDevices;

           //Filter kamers max length 1 to
          let grouppedRoomsDevices = new Array();

          this.resourcesRoomReservation.forEach((room) => {
            room.groupColour = room.color; // Give room the color set in Settings -> Branches -> Rooms

            // One Room per time by boolean
            let foundRoom = false;
            let counter = 0;

            tmpResourcesDevice.forEach((device) => {
              //Filter the room with max 1 room option
              if (device.rooms.length == 1 && device.rooms[0] == room.id) {

                //Add room 1 time
                if (foundRoom == false) {
                  room.groupColour = room.color; // Give room the color set in Settings -> Branches -> Rooms
                  grouppedRoomsDevices.push(room);
                  foundRoom = true; //Room is found just add one time to array by setting to true
                  console.log("ROOM FOUND")
                  // residualRooms = residualRooms.filter((residual) => residual.docId !== room.docId); // Remove room
                }
                device.groupColour = room.color; // Give device the same color as the room
                grouppedRoomsDevices.push(device);
                residualDevices = residualDevices.filter((residual) => residual.id !== device.id); //Remove device
              }
        
              counter++
              if(counter == tmpResourcesDevice.length){
                console.log("Found Room: ", foundRoom)
                // if (!foundRoom) { // Push room to grouppedRoomsDevices to maintain room order
                //   grouppedRoomsDevices.push(room);
                // }
               } 
            });
          
            

            
          });

          residualRooms = _.orderBy(residualRooms, ['roomWeight'])
          residualDevices = _.orderBy(residualDevices, ['deviceWeight'])

          console.log("residualDevices", residualDevices)

          //Residual Devices are mobile
          residualDevices = residualDevices.map((residualDevice) => {
            return { ...residualDevice, mobile: true };
          });

          if (this.showEmployees === true && this.showDevices === true && this.showRooms === true) {
            grouppedRoomsDevices = [...availableEmployees, ...grouppedRoomsDevices, ...residualRooms, ...residualDevices];
          }
          else if (this.showEmployees === false && this.showDevices === true && this.showRooms === true) {
            grouppedRoomsDevices = [...grouppedRoomsDevices, ...residualRooms, ...residualDevices];
          }
          else {
            //Other options
            availableEmployees = this.showEmployees === true ? availableEmployees : [];
            let availableDevices = this.showDevices === true ? tmpResourcesDevice : [];
            let availableRooms = this.showRooms === true ? this.resourcesRoomReservation : [];

            grouppedRoomsDevices = [...availableEmployees, ...availableDevices, ...availableRooms];
          }

          console.log("grouppedRoomsDevices", grouppedRoomsDevices)

          
          //Add computedWeight
          grouppedRoomsDevices = grouppedRoomsDevices.map((resource, index) => {
            resource.computedWeight = index;
            return resource;
          });

          this.grouppedRoomsDevices = grouppedRoomsDevices;

          // return grouppedRoomsDevices;
          // this.$refs.fullCalendar.getApi().refetchEvents();

          //Are there already bookings with "computedSubscription" exist?
          // if(!this.eventsObjects.find(event => event.extendedProps.type === "computedSubscription")) {
          //   this.generateComputedSubscriptions();
          // }

          // successCallback(grouppedRoomsDevices); // oud

          await this.$nextTick();
          this.humanResources = false;
          let employees = _.filter(grouppedRoomsDevices, ['resourceTypeSelect', 'employee'])
          console.log(employees)
          if (!employees || employees.length == 0) { // Er zijn geen resources
            this.humanResources = false;
          }
          else {
            this.humanResources = true;
          }

          
          if(this.branchClosed){
            // Check if employees have any bookings on closed day and show the day with greyed out employees is this is the case
            this.employeesResources.forEach((employee)=>{
              let events = this.eventsObjects.filter((event)=>{ return event.resourceId == employee.id})
              if(events.length > 0){ // Event found
                this.branchClosed = false; // Show calendar
              }
              employee.businessHours = [ // Disable times
                {
                  daysOfWeek: [],
                  endTime: "00:00",
                  startTime: "00:00",
                }
              ];
            })
          }
          

          if (this.$store.state.activeBranch.calendarSettings.showNotes) {
            let notesObject = {
              docId: `${this.$store.state.activeBranch.id}NoteResource`,
              id: `${this.$store.state.activeBranch.id}NoteResource`,
              title: "Notities",
              color: "#cccccc",
              defaultDays: {
                Fri: true,
                Mon: true,
                Sat: true,
                Sun: true,
                Thu: true,
                Tue: true,
                Wed: true
              },
              active: true,
              slotReservation: true,
              name: "notes",
              businessHours: [
                {
                  daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
                  // endTime: this.$store.state.settingsBranch.branchTime[moment(this.chosenDateCalendar, "YYYY-MM-DD").locale('en').format("ddd")].end,
                  // startTime: this.$store.state.settingsBranch.branchTime[moment(this.chosenDateCalendar, "YYYY-MM-DD").locale('en').format("ddd")].start,
                  startTime: `${moment().format("YYYY-MM-DD")}T${this.calendarStartTime}`,
                  endTime: `${moment().format("YYYY-MM-DD")}T${this.calendarEndTime}`
                }
              ],
              resourceTypeSelect: "notes", //Wordt gebruikt bij het volboeken van een ruimte.
              basicScheduleLastChanged: null,
              days: {
                Fri: new Array(),
                Mon: new Array(),
                Sat: new Array(),
                Sun: new Array(),
                Thu: new Array(),
                Tue: new Array(),
                Wed: new Array()
              }
            }
            this.grouppedRoomsDevices.push(notesObject)
            this.resourceObjects.push(notesObject)
          }
          this.calendarOptions.resources = grouppedRoomsDevices
     

          console.log("Fetch Resource Done")
          this.checkTimeRegistration();
          return;
        // }
      },

      minutesOfDay(){
        let startTime = this.branchClosed || !this.humanResources ? moment().startOf('day') : moment(`${moment().format("YYYY-MM-DD")}T${this.calendarStartTime}`, "YYYY-MM-DDTHH:mm");
        let endTime = this.branchClosed || !this.humanResources ? moment().endOf('day') : moment(`${moment().format("YYYY-MM-DD")}T${this.calendarEndTime}`, "YYYY-MM-DDTHH:mm");

        var duration = moment.duration(endTime.diff(startTime));
        // console.log("minutesOfDay: ", this.calendarStartTime, this.calendarEndTime, Math.ceil(((duration.asMinutes())/5)))
        return Math.ceil(((duration.asMinutes())/5));
      },

      extraCacheData(number, type){
        let startTime = moment(`${moment().format("YYYY-MM-DD")}T${this.calendarStartTime}`, "YYYY-MM-DDTHH:mm").add((number - 1) * 5, 'minutes').format("HH:mm");
        let endTime = moment(`${moment().format("YYYY-MM-DD")}T${this.calendarStartTime}`, "YYYY-MM-DDTHH:mm").add(((number - 1) * 5) + 5, 'minutes').format("HH:mm");
        return type == 'start' ? startTime : type == 'end' ? endTime : ""
      },

      checkForRowHour(number){
        let timeHour = moment(`${moment().format("YYYY-MM-DD")}T${this.calendarStartTime}`, "YYYY-MM-DDTHH:mm").add((number - 1) * 5, 'minutes').format("YYYY-MM-DDTHH:00")
        let timeNumber = moment(`${moment().format("YYYY-MM-DD")}T${this.calendarStartTime}`, "YYYY-MM-DDTHH:mm").add((number - 1) * 5, 'minutes').format("YYYY-MM-DDTHH:mm")
        return timeHour == timeNumber ? true : false;
      },

      findResourceCacheDocuments(resource){
        let rescourceCache = this.resourceCache.filter((doc)=>{return doc.resourceId == resource.id })
        return rescourceCache.length;
      },

      async removeResourceCacheDocuments(resource){
        this.resourceCache.forEach((cacheDoc)=>{
          if(cacheDoc.resourceId == resource.id){
            db.collection("resourceCache").doc(cacheDoc.docId).delete()
          }
        })
        // this.resourceCache = this.resourceCache.filter((doc)=>{return doc.resourceId != resource.id })

      },

    
      async generateSchedulePause(){
        let scheduledPauses = new Array();
        this.employeesResources.forEach((employeeData)=>{
          // Check for scheduled pause in employee schedule
          let date = moment(this.chosenDateCalendar).format('ddd');
          let weekNumber = moment(this.chosenDateCalendar).isoWeek();
          let continiousType =  weekNumber % 2 == 0 ? 'even' : 'odd';  
          
          function compare( a, b ) {
            if ( a.start < b.start ){ return -1; }
            if ( a.start > b.start ){ return 1; }
            return 0;
          }
    
          if(employeeData.basicSchedule && employeeData.basicSchedule[date] && employeeData.basicSchedule[date].length > 0){
            let timeBlocks = (employeeData.basicSchedule[date]).sort(compare);

            let index = 0;
            timeBlocks.forEach(timeblock=>{
              if(timeblock.type == 'pause' && (timeblock.continiousType == 'all' || timeblock.continiousType == continiousType)){
                let pause = {
                  allDay:false,
                  start: timeblock.start,
                  end: timeblock.end,
                  bookingSummaryId: null,
                  resourceId: employeeData.id,
                  resourceDocId: employeeData.id,
                  resourceName: employeeData.title,
                  index: index,
                  editable: true,
                  treatmentId: null,
                  droppable: true,
                  resourceEditable:true,
                  minutes: Number(moment(timeblock.end, "HH:mm").diff(moment(timeblock.start, "HH:mm"), "minutes"))
                };
                index++
                scheduledPauses.push(pause)
              }
            })
          }
        })
        // console.log("this.schedulesPauseExceptions.length: ", this.schedulesPauseExceptions.length )
        // console.log("scheduledPauses.length: ", scheduledPauses.length )
        if(this.schedulesPauseExceptions.length > 0){
          // remove pause events
          this.schedulesPauseExceptions.map((schedulesPauseException) => {
            schedulesPauseException.removedPauses.map((pauseException) => {
              scheduledPauses.map((scheduledPause, i) => {
                if (scheduledPause.resourceId === schedulesPauseException.resourceId && scheduledPause.index === pauseException) {
                  scheduledPauses.splice(i, 1);
                }
              })
            })
          })
        }

        if(scheduledPauses.length > 0){
          // let generatePausesNumber = schedulePauses.length - bookingPauses.length;
          // if(generatePausesNumber != bookingPauses.length){
          //   bookingPauses.splice(0, (bookingPauses.length - generatePausesNumber)) // Generate online bookings for pauses that are not bookings
          // }
          let uniqid = require("uniqid");
          scheduledPauses.forEach(pause=>{
            // console.log("Start: ", `${this.chosenDateCalendar}T${pause.start}`)
            // console.log("End: ", `${this.chosenDateCalendar}T${pause.end}`)
            // console.log("pause.resourceId: ", pause.resourceId)
            
            this.eventsObjects.push({
              id: null,
              resourceId: pause.resourceId,
              bookingSummaryId: null,
              treatmentId: null,
              title:"Pause",
              start: `${this.chosenDateCalendar}T${pause.start}`,
              end: `${this.chosenDateCalendar}T${pause.end}`,
              backgroundColor:"#eee",
              borderColor: "rgba(0,0,0,0)",
              droppable: true,
              durationEditable: true, 
              resourceEditable:true,
              editable: true,
              bookingOverride: true,
              inProgress: false,
              allDay: false,
              extendedProps: {
                bookingOverride: false,
                editable: true,
                droppable: true,
                durationEditable: true,
                inProgress: false,
                branchId:this.$store.state.activeBranch.id,
                clientname: pause.resourceName,
                companyId: this.$store.state.activeCompany.id,
                created: moment().format("YYYY-MM-DDTHH:mm"),
                dateBooking: moment(this.chosenDateCalendar).format("YYYY-MM-DD"),
                description:"",
                eventColor:"#eee",
                extra: {
                  type: 'pause'
                },
                end: `${this.chosenDateCalendar}T${pause.end}`,
                
                leadExtra: null,
                schedulePauseId: uniqid(),
                modifiedStamp: {
                  nanoseconds: 400000000,
                  seconds: moment().unix()
                },
                resourceDocId: pause.resourceId,
                start: `${this.chosenDateCalendar}T${pause.start}`,
                title:"Pauze",
                type:"pause",
                uniqIdStamp:uniqid(),
                removePauseIndex: pause.index
              },
            })
          })
        }
        // // Get employee data
        // this.resourceObjects.forEach((employeeData)=>{
        //   // Check for pause in employee schedule
        //   let today = moment(this.chosenDateCalendar).format('ddd');
        //   let weekNumber = moment().isoWeek();
        //   let continiousType =  weekNumber % 2 == 0 ? 'even' : 'odd';    
        //   let schedulePauses = new Array();
        //   let schedulePausesMinutes = 0;
        //   if(employeeData.schedule && employeeData.schedule[today] && employeeData.schedule[today].length > 0){
        //     employeeData.schedule[today].forEach(timeblock=>{
        //       if(timeblock.type == 'pause' && (timeblock.continiousType == 'all' || timeblock.continiousType == continiousType)){
        //         let pause = {
        //           start: timeblock.start,
        //           end: timeblock.end,
        //           minutes: Number(moment(timeblock.end, "HH:mm").diff(moment(timeblock.start, "HH:mm"), "minutes"))
        //         };
        //         schedulePausesMinutes = schedulePausesMinutes + Number(moment(timeblock.end, "HH:mm").diff(moment(timeblock.start, "HH:mm"), "minutes"));
        //         schedulePauses.push(pause)
        //       }
        //     })
        //   }
        //   let bookingPauses = new Array();
        //   let bookingPausesMinutes = 0;
        //   if(schedulePauses.length > 0){ // Check for pauses in bookings of employee (only if pauses are in schedule)
        //     let employeeBookingPauses = this.eventsObjects.filter(event => { return event.resourceId == employeeData.id &&  event.extendedProps.type == 'pause'});
        //     if(employeeBookingPauses.length > 0){
        //       employeeBookingPauses.forEach(timeblock=>{
        //         let pause = {
        //           start: moment(timeblock.start, "YYYY-MM-DDTHH:mm").format("HH:mm"),
        //           end: moment(timeblock.end, "YYYY-MM-DDTHH:mm").format("HH:mm"),
        //           minutes: Number(moment(timeblock.end, "YYYY-MM-DDTHH:mm").diff(moment(timeblock.start, "YYYY-MM-DDTHH:mm"), "minutes"))
        //         };
        //         bookingPausesMinutes = bookingPausesMinutes + Number(moment(timeblock.end, "YYYY-MM-DDTHH:mm").diff(moment(timeblock.start, "YYYY-MM-DDTHH:mm"), "minutes"));
        //         bookingPauses.push(pause);0
        //       })
        //     }
        //     if(bookingPauses.length != schedulePauses.length){
        //       let generatePausesNumber = schedulePauses.length - bookingPauses.length;
        //       if(generatePausesNumber != bookingPauses.length){
        //         bookingPauses.splice(0, (bookingPauses.length - generatePausesNumber)) // Generate online bookings for pauses that are not bookings
        //       }
        //       schedulePauses.forEach(pause=>{
        //         let uniqid = require("uniqid");
        //         this.eventsObjects.push({
        //           allDay:false,
        //           backgroundColor:"#d5d5d5",
        //           bookingSummaryId: null,
        //           borderColor:"rgba(0,0,0,0)",
        //           droppable:true,
        //           editable:true,
        //           end: `${this.chosenDateCalendar}T${pause.end}`,
        //           extendedProps: {
        //             bookingOverride:false,
        //             branchId:this.$store.state.activeBranch.id,
        //             clientname: employeeData.title,
        //             companyId: this.$store.state.activeCompany.id,
        //             created: moment().format("YYYY-MM-DDTHH:mm"),
        //             dateBooking: moment().format("YYYY-MM-DDTHH:mm"),
        //             description:"",
        //             droppable:true,
        //             end: `${this.chosenDateCalendar}T${pause.end}`,
        //             inProgress:false,
        //             leadExtra:null,
        //             schedulePauseId: uniqid(),
        //             modifiedStamp:null,
        //             resourceDocId: employeeData.id,
        //             start: `${this.chosenDateCalendar}T${pause.start}`,
        //             title:"Pauze",
        //             type:"pause",
        //             uniqIdStamp:""
        //           },
        //           id: null,
        //           resourceEditable:true,
        //           resourceId: employeeData.id,
        //           start: `${this.chosenDateCalendar}T${pause.start}`,
        //           title:"Pause",
        //           treatmentId: null
        //         })
        //       })
        //     }
        //   }
        // })
       
        // console.log("scheduledPauses: ", scheduledPauses)
        return
      },

      updateResourceException(resultTime, relatedResource) {
        //change calendar resource buisness hours to the exception times
        let today = moment(this.calendarApi.getDate()).format("YYYY-MM-DD");
        let dayNumber = this.getDayNumber(today);

        let newBusinessHours = function () {
          let result = new Array();

          resultTime.forEach((block) => {
            // console.log("dayNumber updateResourceException", dayNubmer);
            result.push({
              startTime: block.start,
              endTime: block.end,
              daysOfWeek: [dayNumber],
            });
          });
          return result;
        };

        relatedResource.businessHours = newBusinessHours();
      },

      removeResource(resObj) {
        // let removeIndex = this.resourceObjects.map((item) => item.id).indexOf(resObj.id);
        // this.resourceObjects.splice(removeIndex, 1);
        this.resourceObjects = this.resourceObjects.filter((resource) => resource.docId !== resObj.docId);
      },

      filterWeekByResource(weekDay, resourceId, customBasicWorkSchedule) {
        return customBasicWorkSchedule.filter((customSchedule) => resourceId === customSchedule.employeeId && weekDay === customSchedule.week)[customBasicWorkSchedule.length - 1] || false;
      },

      /**
       * @param scheduledHours - schedules from DB
       * @param type - basic or customb
       * @param basicSchedule - basicSchedule to compare
       *
       */
      setBusinessHours(scheduledHours, type, basicSchedule) {
        let businessHours = new Array();
        for (let key in scheduledHours) {

          if (typeof scheduledHours[key] !== null) {
            if (key !== "basicScheduleLastChanged" && key !== "endDate" && key !== "startDate" && scheduledHours[key].length > 0) {    
              let weekNumber = moment(this.chosenDateCalendar).isoWeek(); 
              let continiousType =  weekNumber % 2 == 0 ? 'even' : 'odd';        
              //Logic between Basic and Custom
              if (type === "basic") {
                scheduledHours[key].forEach((hoursObj) => {
                  if(!hoursObj.continiousType || hoursObj.continiousType == 'all' || (hoursObj.continiousType == 'even' && continiousType == 'even') || (hoursObj.continiousType == 'odd' && continiousType == 'odd')){
                    businessHours.push({
                      startTime: hoursObj.start,
                      endTime: hoursObj.end,
                      daysOfWeek: [this.isoDaysOfWeek[key]],
                      scheduleType: "basic",
                      continiousType: hoursObj.continiousType
                    });
                  }
                });
              }
              else {
                //Custom Schedule
                // let foundBasicSchedules = basicSchedule.filter(basic => basic.daysOfWeek[0] === this.isoDaysOfWeek[key])
                scheduledHours[key].forEach((hoursObj) => {
                  if(!hoursObj.continiousType || hoursObj.continiousType == 'all' || (hoursObj.continiousType == 'even' && continiousType == 'even') || (hoursObj.continiousType == 'odd' && continiousType == 'odd')){
                    businessHours.push({
                      startTime: hoursObj.start,
                      endTime: hoursObj.end,
                      daysOfWeek: [this.isoDaysOfWeek[key]],
                      scheduleType: "custom",  
                      continiousType: hoursObj.continiousType       
                    });
                  }
                });
              }
            }
          }
        }

        businessHours = businessHours.sort((b, a) => b.startTime.localeCompare(a.startTime))
        let newBusinessHours = this.mergeTimeBlocks(businessHours)

        // businessHours.forEach(times=>{
        //   let contiguous = 'none'
        //   businessHours.forEach(checkTimes=>{
        //     if(times.daysOfWeek[0] == checkTimes.daysOfWeek[0]){
        //       if(times.startTime == checkTimes.endTime){
        //         contiguous = 'start';
        //         times.startTime = checkTimes.startTime
        //         console.log("cjange starttime")
        //         newBusinessHours.push(times)
        //       }
        //       // else if(times.endTime == checkTimes.startTime){ 
        //       //   contiguous = 'end'
        //       //   times.startTime = checkTimes.endTime
        //       // }
        //     }
            
        //   })
        //   // if(contiguous == 'start'){
            
        //   // }
        //   // else if(contiguous == 'end'){
        //   //   newBusinessHours.push(times)
        //   // }
        //   // else{
        //   //   newBusinessHours.push(times)
        //   // }
        // })



        return newBusinessHours;
      },

      mergeTimeBlocks(timeBlocks) {
        let days = {
          0: { currentBlock: null, mergedBlocks: new Array(), seperatedBlocks: new Array()},
          1: { currentBlock: null, mergedBlocks: new Array(), seperatedBlocks: new Array()},
          2: { currentBlock: null, mergedBlocks: new Array(), seperatedBlocks: new Array()},
          3: { currentBlock: null, mergedBlocks: new Array(), seperatedBlocks: new Array()},
          4: { currentBlock: null, mergedBlocks: new Array(), seperatedBlocks: new Array()},
          5: { currentBlock: null, mergedBlocks: new Array(), seperatedBlocks: new Array()},
          6: { currentBlock: null, mergedBlocks: new Array(), seperatedBlocks: new Array()},
          7: { currentBlock: null, mergedBlocks: new Array(), seperatedBlocks: new Array()},
        };


        const mergedBlocks = [];
        let currentBlock = null;

        // Doorloop elk tijdvak om te controleren of ze samengevoegd kunnen worden.
        timeBlocks.forEach((block, index) => {
          days[block.daysOfWeek[0]].seperatedBlocks.push(block)

          if(!days[block.daysOfWeek[0]].currentBlock){
            days[block.daysOfWeek[0]].currentBlock = { ...block };
          }
          else if (days[block.daysOfWeek[0]].currentBlock.endTime === block.startTime) { // Als het huidige blok aansluit op het volgende, voeg ze samen.
            days[block.daysOfWeek[0]].currentBlock.endTime = block.endTime; //?
          }
          else {// Als ze niet aansluiten, voeg het huidige blok toe aan de lijst en start een nieuw blok.
            days[block.daysOfWeek[0]].mergedBlocks.push(days[block.daysOfWeek[0]].currentBlock);
            days[block.daysOfWeek[0]].currentBlock = { ...block }; //?
          } 
        });

        // Voeg het laatste blok toe aan de lijst als het bestaat.
        for (const [key, value] of Object.entries(days)) {
          // console.log(`${key}: ${value}`);
          if (days[key].currentBlock) {
            days[key].mergedBlocks.push(days[key].currentBlock); //?
          }

          if(days[key].mergedBlocks.length > 0){
            days[key].mergedBlocks.forEach(mergedBlock=>{ // Push mergedBlocks to array for return
              mergedBlocks.push(mergedBlock) 
            })
          }

        }


        // Geef de lijst met samengevoegde tijdvakken terug.
        // console.log("days: ", days)
        return mergedBlocks;
      },

      getDayNumber(currentDay) {
        return moment(currentDay, "YYYY-MM-DD").weekday();
      },
   
      // Render  custom resource DOM style
      resourceRender(resourceObj) {
        let vm = this;
        //resource data
        let res = resourceObj.resource; //Res object

        let withImage = typeof res.extendedProps.resPhoto === "undefined"  || !res.extendedProps.resPhoto ? false : true; //Boolean returns true if Res have photo
        let isEmployee = res.extendedProps.resType === "employee"; // Boolean returns true if Res type is employee
        let isDevice = res.extendedProps.resourceTypeSelect === "device"; // Boolean returns true if Res type is device
        let isNotes = res.extendedProps.resourceTypeSelect === "notes"; // Boolean returns true if Res type is device
        // let withRoomName = res.extendedProps.roomName ? true : false; // Boolean returns true if Res have room
        const groupColour = res.extendedProps.groupColour;

        //custom html resource creation
        let elem = document.createElement("div");
        let resHeader = document.createElement("div");
        let resInfo = document.createElement("div");
        let title = document.createElement("div");
        let subtitle = document.createElement("p");
        let imgContainer = document.createElement("div");
        let traineeElement = null;
        let mobileDeviceElement = null;
        let img = document.createElement("img");
        let groupColourElement = document.createElement("div");

        elem.innerHTML = ""; //clear default calander res title

        //Give created elements css classes
        resHeader.classList.add("resourceinfo");
        resHeader.setAttribute('id', `employee-${res.extendedProps.id}`);
        resInfo.classList.add("resInfo");
        title.classList.add("resName");
        imgContainer.classList.add("resPhoto");
        groupColourElement.classList.add("groupColour");

        //Trainee
        if (res.extendedProps.trainee) {
          traineeElement = document.createElement("span");
          traineeElement.innerHTML = "T";
          traineeElement.classList.add("trainee");
        }

        //Group Colours
        if (groupColour) {
          groupColourElement.style.cssText = `background-color:${groupColour};`;
        }

        //Mobile Device
        if (res.extendedProps.mobile) {
          mobileDeviceElement = document.createElement("span");
          mobileDeviceElement.innerHTML = "M";
          mobileDeviceElement.classList.add("mobileDevice");
        }

        if (res.extendedProps.schedule == "adjusted") {
          imgContainer.classList.add("adjusted");
        }

        //a- add title to the new element
        // elem.innerHTML = ""; //clear default calander res title
        let employeeName = res.extendedProps.publicName ? `<span class="res-firstname">${res.extendedProps.publicName}</span>` : `<span class="res-firstname">${res.extendedProps.firstName}</span> <span class="res-lastname">${res.extendedProps.lastName}</span>`
        title.innerHTML = res.extendedProps.resourceTypeSelect == "employee" ? employeeName : `<span>${res.title}</span>`;
        // res.title; //set resource title`

        //b- check if res includes photo and add photo only to the included once
        if (!withImage) {
          if (isEmployee) {
            img.src = ResourceImage;
            img.classList.add("placeholder");
          }
          else if (isNotes) {
            img.src = NotesImage;
            img.classList.add("placeholder");
            img.classList.add("notes");
          }
          else if (isDevice) {
            img.src = DeviceImage;
            // img.style.width = "20px";
            img.style.padding = "7px";
            if (groupColour) {
              imgContainer.style.cssText = `background-color:${groupColour}!important;`;
            }
          }
          else {
            img.src = RoomImage;
            // img.style.width = "20px";
            img.style.padding = "7px";
            if (groupColour) {
              imgContainer.style.cssText = `background-color:${groupColour}!important;`;
            }
          }
        }
        else {
          img.src = res.extendedProps.resPhoto;
        }

        //c-zet puzzel aan elkaar
        imgContainer.appendChild(img);

        resInfo.appendChild(imgContainer);
        if (res.extendedProps.mobile) imgContainer.appendChild(mobileDeviceElement);
        if (res.extendedProps.trainee) imgContainer.appendChild(traineeElement);
        resInfo.appendChild(title);
        resHeader.appendChild(resInfo);
        resHeader.appendChild(groupColourElement);

        //d-append room name as sub title if res includes subtitle
        //open edit dialog
        // resHeader.appendChild(subtitle)
        //if loaded resource is employee add click event to open employee edit
        if (res.extendedProps.resourceTypeSelect == "employee") {
          resHeader.classList.add("resourceHover"); //Add hover effect
          resHeader.addEventListener("click", function () {
            vm.dialogEditEmployee = true;
            //select target employee
            let selectedEmp = vm.employeesResources.find((employee) => employee.id == resourceObj.resource.id);
            // vm.selectedEmployeeInfo = selectedEmp;

            // vm.selectedEmployeeInfo.email = null;
            // vm.selectedEmployeeInfo.password = null;
            // if (vm.selectedEmployeeInfo.employeeContractStart) {
            //   vm.selectedEmployeeInfo.employeeContractStart = moment(vm.selectedEmployeeInfo.employeeContractStart, "DD/MM/YYYY").format("YYYY-MM-DD");
            // }
            // if (vm.selectedEmployeeInfo.employeeContractEnd) {
            //   vm.selectedEmployeeInfo.employeeContractEnd = moment(vm.selectedEmployeeInfo.employeeContractEnd, "DD/MM/YYYY").format("YYYY-MM-DD");
            // }
            // vm.selectedEmployeeInfoOriginal = _.cloneDeep(vm.selectedEmployeeInfo);
            // vm.loadAbilities();
            // bus.$emit("editEmployee", selectedEmp);
            vm.viewEmployeeScheduleFuture(selectedEmp)
          });
        }

        else if (res.extendedProps.resourceTypeSelect == "room" && this.$store.state.userRoleNumber < 3) {
          resHeader.classList.add("resourceHover"); //Add hover effect
          resHeader.addEventListener("click", function () {
            //select target room
            let selectedRoom = vm.resourcesRoomReservation.find((room) => room.docId == resourceObj.resource.id);
            vm.selectedRoomInfo = selectedRoom;
            vm.selectedRoomInfoOriginal = _.cloneDeep(vm.selectedRoomInfo);
            bus.$emit("editRoom", vm.selectedRoomInfo, vm.selectedRoomInfoOriginal, vm.chosenDateCalendar);
          });
        }
        elem.appendChild(resHeader);

        let arrayOfDomNodes = [elem];
        return { domNodes: arrayOfDomNodes };
      }, //end resource render

      eventRender(loadedObject) { // select used atrs from current event
        // console.log("eventRender")
        // console.log("loadedObject: ", loadedObject)
        let event = loadedObject.event;
        let type = event.extendedProps.type;
        let title = event.extendedProps.title;
        let clientname = event.extendedProps.clientname;
        let desc = event.extendedProps.description; 
        let maxPersons = event.extendedProps.maxPersons;
        let bookingId = event.extendedProps.bookingId; // Booking Summary ID
        let uniqIdStamp = event.extendedProps.uniqIdStamp; // Booking Summary ID
        let start = event.start;
        let clientId = event.extendedProps.clientId; 
        

        //create html custom elemenst
        let elem = document.createElement("div");
        let customEvent = document.createElement("div");
        let customEventIcons = document.createElement("div");
        let desc_el = document.createElement("div");
        let time_el = document.createElement("div");
        let title_el = document.createElement("div");
        let clientName_el = document.createElement("div");
        let clientAvatar_el = document.createElement("div");
        let maxPersons_el = document.createElement("div");
        let eveHeader_el = document.createElement("div");
        let progressBar_el = document.createElement("div");
        let individualMove = document.createElement("div");
        let individualMoveTooltip = document.createElement("div");
        let taskProgress = document.createElement("div");
        let individualMoveTooltipIcon = document.createElement("i");
        let extraInfo = document.createElement("div");
        let extraInfoMenu = document.createElement("div");

        let menuOptionWrapperZero = document.createElement("div");
        let menuOptionWrapperUnlock = document.createElement("div");

        let menuOptionWrapperOne = document.createElement("div");
        let menuOptionWrapperTwo = document.createElement("div")
        let menuOptionWrapperThree = document.createElement("div")
        let menuOptionWrapperFour = document.createElement("div");
        let menuOptionWrapperFive = document.createElement("div");
        
        let menuOptionWrapperOneTitle = document.createElement("div"); 
        let menuOptionWrapperNoShow = document.createElement("div");
        let menuOptionWrapperPresent = document.createElement("div");
        let menuOptionWrapperCompleted = document.createElement("div");

        let menuOptionWrapperCut = document.createElement("div");
        let menuOptionWrapperCopy = document.createElement("div");
        let menuOptionWrapperChange = document.createElement("div");
        let menuOptionWrapperPay = document.createElement("div");
        let menuOptionWrapperOneTitleText = document.createElement("div"); 
        let menuOptionWrapperUnlockText = document.createElement("div");
        let menuOptionWrapperLockText = document.createElement("div");
        let menuOptionWrapperPresentText = document.createElement("div");
        let menuOptionWrapperCutText = document.createElement("div");
        let menuOptionWrapperCopyText = document.createElement("div");
        let menuOptionWrapperChangeText = document.createElement("div");
        let menuOptionWrapperPayText = document.createElement("div");
        let menuOptionWrapperNoShowText = document.createElement("div");
        let menuOptionWrapperCompletedText = document.createElement("div");
        let cutIcon = document.createElement("i");
        let copyIcon = document.createElement("i");
        let changeIcon = document.createElement("i");
        let payIcon = document.createElement("i");
        let checkIconEmpty = document.createElement("i");
        let checkIconCheck = document.createElement("i");
        let checkIconCheckMark = document.createElement("i");
        let checkIconNoShow = document.createElement("i");

        let lockedIcon = document.createElement("i");
        let unlockedIcon = document.createElement("i");

        let bookingNotes = document.createElement("div");

        //give html elemnts css classes
        desc_el.classList.add("calender-event-description");
        title_el.classList.add("calender-event-title");
        clientName_el.classList.add("calender-event-user");
        clientAvatar_el.classList.add("calender-event-avatar");
        maxPersons_el.classList.add("calender-event-description");
        eveHeader_el.classList.add("event-header");
        time_el.classList.add("eve-time");
        desc_el.classList.add("eve-desc");
        progressBar_el.classList.add("progressBar");
        individualMove.classList.add("individual-move");
        individualMoveTooltip.classList.add("individual-move-tooltip");
        taskProgress.classList.add("task-type");
        individualMoveTooltipIcon.classList.add("mdi");
        individualMoveTooltipIcon.classList.add("mdi-drag");
        checkIconCheckMark.classList.add("mdi");
        checkIconCheckMark.classList.add("mdi-check");
        checkIconNoShow.classList.add("mdi");
        checkIconNoShow.classList.add("mdi-close");
        customEventIcons.classList.add("event-icons");

        lockedIcon.classList.add("mdi");
        lockedIcon.classList.add("mdi-lock");
        unlockedIcon.classList.add("mdi");
        unlockedIcon.classList.add("mdi-lock-open");
        bookingNotes.classList.add("booking-notes");
        extraInfo.classList.add("event-extra-info");

        menuOptionWrapperZero.classList.add("extra-info-menu-wrapper");
        menuOptionWrapperZero.classList.add("choose");
        menuOptionWrapperUnlock.classList.add("extra-info-menu-item");
        menuOptionWrapperOne.classList.add("extra-info-menu-wrapper");
        menuOptionWrapperOne.classList.add("choose");
        menuOptionWrapperTwo.classList.add("extra-info-menu-wrapper");
        menuOptionWrapperThree.classList.add("extra-info-menu-wrapper");
        menuOptionWrapperFour.classList.add("extra-info-menu-wrapper");
        menuOptionWrapperFive.classList.add("extra-info-menu-wrapper");
        menuOptionWrapperOneTitle.classList.add("extra-info-menu-wrapper-title");
        menuOptionWrapperNoShow.classList.add("extra-info-menu-item");
        menuOptionWrapperNoShow.classList.add("no-show-item");
        menuOptionWrapperCompleted.classList.add("extra-info-menu-item");
        menuOptionWrapperCompleted.classList.add("completed-item");
        menuOptionWrapperPresent.classList.add("extra-info-menu-item");
        menuOptionWrapperPresent.classList.add("present-item");


        menuOptionWrapperCut.classList.add("extra-info-menu-item");
        menuOptionWrapperCut.classList.add("cut-item");
        menuOptionWrapperCopy.classList.add("extra-info-menu-item");
        menuOptionWrapperCopy.classList.add("copy-item");
        menuOptionWrapperChange.classList.add("extra-info-menu-item");

        if(this.$store.state.treatmentCutId == event.extendedProps.bookingSummaryId){ // Treatement is selected fot Cuting
          menuOptionWrapperCut.classList.add("item-selected");
        }

        if(this.$store.state.treatmentCopyId == event.extendedProps.bookingSummaryId){ // Treatement is selected fot Copying
          menuOptionWrapperCopy.classList.add("item-selected");
        }

        menuOptionWrapperPay.classList.add("extra-info-menu-item");


        extraInfoMenu.classList.add("event-extra-info-menu");
        cutIcon.classList.add("mdi");
        cutIcon.classList.add("mdi-content-cut");
        copyIcon.classList.add("mdi");
        copyIcon.classList.add("mdi-content-copy");
        changeIcon.classList.add("mdi");
        changeIcon.classList.add("mdi-repeat");
        payIcon.classList.add("mdi");
        payIcon.classList.add("mdi-cash-register");
        checkIconEmpty.classList.add("mdi");
        checkIconCheck.classList.add("mdi");
        checkIconEmpty.classList.add("mdi-checkbox-blank-outline");
        checkIconCheck.classList.add("mdi-checkbox-marked");

        menuOptionWrapperOneTitleText.classList.add("info-menu-text");
        menuOptionWrapperNoShowText.classList.add("info-menu-text");
        menuOptionWrapperCompletedText.classList.add("info-menu-text");
        menuOptionWrapperPresentText.classList.add("info-menu-text");
        menuOptionWrapperPayText.classList.add("info-menu-text");

        menuOptionWrapperCutText.classList.add("info-menu-text");
        menuOptionWrapperCopyText.classList.add("info-menu-text");
        menuOptionWrapperChangeText.classList.add("info-menu-text");

        menuOptionWrapperOneTitleText.innerHTML = this.$t('calendar.mark', this.$store.state.locale);
        menuOptionWrapperNoShowText.innerHTML = this.$t('calendar.noShow', this.$store.state.locale);
        menuOptionWrapperCompletedText.innerHTML = this.$t('calendar.completed', this.$store.state.locale);
        menuOptionWrapperPresentText.innerHTML = this.$t('calendar.present', this.$store.state.locale);
        menuOptionWrapperPayText.innerHTML = this.$t('calendar.checkout', this.$store.state.locale);
        menuOptionWrapperLockText.innerHTML = this.$t('calendar.move', this.$store.state.locale);
        menuOptionWrapperUnlockText.innerHTML = this.$t('calendar.close', this.$store.state.locale);
        menuOptionWrapperChangeText.innerHTML = this.$t('calendar.changeTreatment', this.$store.state.locale);

        if(this.$store.state.treatmentCopyId != event.extendedProps.bookingSummaryId){ // Text based on active status of action
          menuOptionWrapperCopyText.innerHTML = this.$t('calendar.copy', this.$store.state.locale);
        }
        else{
          menuOptionWrapperCopyText.innerHTML = this.$t('calendar.copyActive', this.$store.state.locale);
        }

        if(this.$store.state.treatmentCutId != event.extendedProps.bookingSummaryId){  // Text based on active status of action
          menuOptionWrapperCutText.innerHTML = this.$t('calendar.cut', this.$store.state.locale);
        }
        else{
          menuOptionWrapperCutText.innerHTML = this.$t('calendar.cutActive', this.$store.state.locale);
        }
        

        menuOptionWrapperCut.appendChild(cutIcon);
        menuOptionWrapperCut.appendChild(menuOptionWrapperCutText);

        menuOptionWrapperCopy.appendChild(copyIcon);
        menuOptionWrapperCopy.appendChild(menuOptionWrapperCopyText);

        menuOptionWrapperChange.appendChild(changeIcon);
        menuOptionWrapperChange.appendChild(menuOptionWrapperChangeText);

        menuOptionWrapperPay.appendChild(payIcon);
        menuOptionWrapperPay.appendChild(menuOptionWrapperPayText);

        
        if(event.extendedProps.status == 'present'){
          menuOptionWrapperPresent.appendChild(checkIconCheck.cloneNode(true));
          menuOptionWrapperNoShow.appendChild(checkIconEmpty.cloneNode(true));
          menuOptionWrapperCompleted.appendChild(checkIconEmpty.cloneNode(true));
        }
        else if(event.extendedProps.status == 'noShow'){
          menuOptionWrapperPresent.appendChild(checkIconEmpty.cloneNode(true));
          menuOptionWrapperNoShow.appendChild(checkIconCheck.cloneNode(true));
          menuOptionWrapperCompleted.appendChild(checkIconEmpty.cloneNode(true));
        }
        else if(event.extendedProps.status == 'completed'){
          menuOptionWrapperPresent.appendChild(checkIconEmpty.cloneNode(true));
          menuOptionWrapperNoShow.appendChild(checkIconEmpty.cloneNode(true));
          menuOptionWrapperCompleted.appendChild(checkIconCheck.cloneNode(true));
        }
        else{
          menuOptionWrapperPresent.appendChild(checkIconEmpty.cloneNode(true));
          menuOptionWrapperNoShow.appendChild(checkIconEmpty.cloneNode(true));
          menuOptionWrapperCompleted.appendChild(checkIconEmpty.cloneNode(true));
        }

        menuOptionWrapperPresent.appendChild(menuOptionWrapperPresentText);
        menuOptionWrapperNoShow.appendChild(menuOptionWrapperNoShowText);
        menuOptionWrapperCompleted.appendChild(menuOptionWrapperCompletedText);
        
        menuOptionWrapperOneTitle.appendChild(menuOptionWrapperOneTitleText);
        if(!event.extendedProps.droppable){
          customEvent.classList.add("locked");
          menuOptionWrapperUnlock.appendChild(unlockedIcon);
          menuOptionWrapperUnlock.appendChild(menuOptionWrapperLockText);
        }
        else{
          menuOptionWrapperUnlock.appendChild(lockedIcon);
          menuOptionWrapperUnlock.appendChild(menuOptionWrapperUnlockText);
        }

        // menuOptionWrapperOne.appendChild(menuOptionWrapperOneTitle);
        menuOptionWrapperZero.appendChild(menuOptionWrapperUnlock);
        menuOptionWrapperOne.appendChild(menuOptionWrapperNoShow);
        menuOptionWrapperOne.appendChild(menuOptionWrapperPresent);
        menuOptionWrapperOne.appendChild(menuOptionWrapperCompleted);   
        menuOptionWrapperTwo.appendChild(menuOptionWrapperCopy);
        menuOptionWrapperThree.appendChild(menuOptionWrapperCut);
        menuOptionWrapperFour.appendChild(menuOptionWrapperChange);
        menuOptionWrapperFive.appendChild(menuOptionWrapperPay);

        if((!event.extendedProps.droppable && this.userRoleNumber < 3) || (this.userRoleNumber < 3 && event.extendedProps.droppable && moment(event.end).format("YYYY-MM-DDTHH:mm") < moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm"))){
          extraInfoMenu.appendChild(menuOptionWrapperZero);
        }
        // if((event.extendedProps.droppalbe && moment(event.end).format("YYYY-MM-DDTHH:mm") < moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm"))){
        //   extraInfoMenu.appendChild(menuOptionWrapperZero);
        // }
        if(!event.extendedProps.paid){
          extraInfoMenu.appendChild(menuOptionWrapperOne);
          extraInfoMenu.appendChild(menuOptionWrapperTwo);
          extraInfoMenu.appendChild(menuOptionWrapperThree);
          extraInfoMenu.appendChild(menuOptionWrapperFour);
          extraInfoMenu.appendChild(menuOptionWrapperFive);
        }
        else{
          extraInfoMenu.appendChild(menuOptionWrapperTwo);
        }
        
        // if(!event.extendedProps.paid || (event.extendedProps.paid && this.userRoleNumber < 3 && !event.extendedProps.droppable) || event.extendedProps.paid && this.userRoleNumber < 3 && event.extendedProps.droppable && moment(event.end).format("YYYY-MM-DDTHH:mm") < moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm")){
        //   extraInfo.appendChild(extraInfoMenu);
        // }
        extraInfo.appendChild(extraInfoMenu);

        // if(!event.extendedProps.paid || (event.extendedProps.paid) || event.extendedProps.paid && event.extendedProps.droppalbe && moment(event.end).format("YYYY-MM-DDTHH:mm") < moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm")){
        //   extraInfo.appendChild(extraInfoMenu);
        // }
        
        // append values into html elements
        time_el.innerHTML = start.getHours() + ":" + ((start.getMinutes() < 10 ? "0" : "") + start.getMinutes()) + " ";
       
        
        eveHeader_el.appendChild(time_el);
        eveHeader_el.appendChild(desc_el);
        title_el.innerHTML = title;
        clientName_el.innerHTML = clientname;
        clientAvatar_el.innerHTML = this.genAvatar(clientname);
        maxPersons_el.innerHTML = maxPersons;
        individualMoveTooltip.innerHTML = this.$t('calendar.moveIndividually', this.$store.state.locale);

        individualMove.appendChild(individualMoveTooltip);
        individualMove.appendChild(individualMoveTooltipIcon);

        //custmize html elemnt depends on event type
        if (type == "booking") {
          title = this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, event.extendedProps.title);
          desc = this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, event.extendedProps.description)
          desc_el.innerHTML = desc;
          title_el.innerHTML = title;
          // console.log("Event: ", event)
          if (event.extendedProps.source) {
            let bookingType = document.createElement("div");
            bookingType.classList.add("booking-type");
            bookingType.setAttribute("style", "background-color:" + event.backgroundColor);
            if (event.extendedProps.source == "widget") {
              bookingType.innerHTML = "O";
            } else if (event.extendedProps.source == "app") {
              bookingType.innerHTML = "A";
            } else if (event.extendedProps.source == "personal") {
              bookingType.innerHTML = "P";
            } else if (event.extendedProps.source == "telephone") {
              bookingType.innerHTML = "T";
            } else {
              bookingType.classList.add("unknown");
            }
            
            if (event.extendedProps.paid) {
              extraInfo.classList.add("paid");
              elem.classList.add("paid");
              extraInfo.appendChild(checkIconCheckMark.cloneNode(true))
            }
            else if(event.extendedProps.status == 'present') {
              extraInfo.classList.add("present");
              elem.classList.add("present");
              extraInfo.appendChild(checkIconCheckMark.cloneNode(true))
            }
            else if(event.extendedProps.status == 'completed') {
              extraInfo.classList.add("completed");
              elem.classList.add("completed");
              extraInfo.appendChild(checkIconCheckMark.cloneNode(true))
            }
            else if(event.extendedProps.status == 'noShow') {
              extraInfo.classList.add("no-show");
              elem.classList.add("no-show");
              extraInfo.appendChild(checkIconNoShow.cloneNode(true))
            }
            customEventIcons.appendChild(bookingType);
          }
          if (event.extendedProps.preferredPractitioner && event.extendedProps.resourceType == "employee") {
            let preferation = document.createElement("div");
            preferation.classList.add("preferred-practitioner");
            customEventIcons.appendChild(preferation);
          }

          if (event.extendedProps.downPayment && event.extendedProps.downPayment.active && event.extendedProps.downPayment.paid) {
            let downPaymentIcon = document.createElement("i");
            downPaymentIcon.classList.add("mdi");
            downPaymentIcon.classList.add("mdi-cash-check");
            let downPaymentDone = document.createElement("div");
            downPaymentDone.classList.add("downpayment-done");
            downPaymentDone.appendChild(downPaymentIcon);
            customEventIcons.appendChild(downPaymentDone);
            
          }
          
          customEvent.appendChild(eveHeader_el);
          let clientWrapper = document.createElement("div");
          clientWrapper.classList.add("client-wrapper");
          clientWrapper.appendChild(clientAvatar_el);
          clientWrapper.appendChild(clientName_el);
          customEvent.appendChild(clientWrapper);
          
          customEvent.appendChild(title_el);
          customEvent.appendChild(individualMove);

          // Alleen tonen als booking definitief is
          if( !event.extendedProps.downPayment || event.extendedProps.downPayment && !event.extendedProps.downPayment.active || event.extendedProps.downPayment && event.extendedProps.downPayment.active && event.extendedProps.downPayment.paid){ 
            customEvent.appendChild(extraInfo);
          }
          if(event.extendedProps.downPayment && event.extendedProps.downPayment.active && !event.extendedProps.downPayment.paid){
            // unconfirmed booking
            let unconfirmedBooking = document.createElement("div");
            unconfirmedBooking.classList.add("unconfirmed-booking");
            let iconDownPayment = document.createElement("i");
            iconDownPayment.classList.add("mdi");

            if(event.extendedProps.created){
              let bookingTime = moment(event.extendedProps.created.seconds, "X");
              let minutes = moment().diff(bookingTime, 'minutes', false);

              if(minutes > 720){
                unconfirmedBooking.classList.add("overtime");
                iconDownPayment.classList.add("mdi-timer-sand-complete");
              }
              else if(minutes >360){
                unconfirmedBooking.classList.add("late");
                iconDownPayment.classList.add("mdi-timer-sand-empty");
              }
              else{
                iconDownPayment.classList.add("mdi-timer-sand-empty");
              }
            }    
            unconfirmedBooking.appendChild(iconDownPayment.cloneNode(true))
            customEventIcons.appendChild(unconfirmedBooking); 
          }

          if (event.extendedProps.notes) {
            bookingNotes.innerHTML = event.extendedProps.notes
            customEvent.appendChild(bookingNotes);
          }

          customEvent.appendChild(customEventIcons);
          
          // customEvent.appendChild(groupMove); //Group move Turned off

          //Add click event to client name in the booking block
          clientName_el.addEventListener("click", () => {
            this.clientNameClicked = true;
            this.openCustomerDialog(bookingId, event, "calendar");
          });

          menuOptionWrapperPay.addEventListener("click", () => {
            this.clientNameClicked = true;
            // this.$router.push({path: "/kassa" , params: { booking: bookingId}});
            this.$router.push({path: "/kassa" , query: { uid: uniqIdStamp, clientId: clientId}});
          });

          menuOptionWrapperCopy.addEventListener("click", () => {
            this.clientNameClicked = true;
            this.$store.state.treatmentCutId = null;
            if(this.$store.state.treatmentCopyId != event.extendedProps.bookingSummaryId){
              this.$store.state.treatmentCopyId = event.extendedProps.bookingSummaryId;
            }
            else{
              this.$store.state.treatmentCopyId = null;
            }
            this.$refs.fullCalendar.getApi().render();
          });

          menuOptionWrapperCut.addEventListener("click", () => {
            this.clientNameClicked = true;
            if(moment(this.calendarApi.getDate()).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")){
              this.$store.state.treatmentCopyId = null;
              if(this.$store.state.treatmentCutId != event.extendedProps.bookingSummaryId){
                this.$store.state.treatmentCutId = event.extendedProps.bookingSummaryId;
              }
              else{
                this.$store.state.treatmentCutId = null
              }
            }
            this.$refs.fullCalendar.getApi().render();
          });

          menuOptionWrapperChange.addEventListener("click", () => {
            this.clientNameClicked = true;
            this.changeBookingTreatment(event, 'calendar');
          })

          menuOptionWrapperUnlock.addEventListener("click", () => {
            this.clientNameClicked = true;
            this.unlockEvents(bookingId, event.extendedProps.droppable);
          });

          menuOptionWrapperPresent.addEventListener("click", () => {
            this.clientNameClicked = true;
            if(moment(this.calendarApi.getDate()).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")){
              if(event.extendedProps.status != 'present'){
                this.setEventStatus(bookingId, 'present');
              }
              else{
                this.setEventStatus(bookingId, null);
              }
            }
          });

          menuOptionWrapperCompleted.addEventListener("click", () => {
            this.clientNameClicked = true;
            if(moment(this.calendarApi.getDate()).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")){
              if(event.extendedProps.status != 'completed'){
                this.setEventStatus(bookingId, 'completed');
              }
              else{
                this.setEventStatus(bookingId, null);
              }
            }
          });

          menuOptionWrapperNoShow.addEventListener("click", () => {
            this.clientNameClicked = true;
            if(moment(this.calendarApi.getDate()).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")){
              if(event.extendedProps.status != 'noShow'){
                this.setEventStatus(bookingId, 'noShow');
              }
              else{
                this.setEventStatus(bookingId, null);
              }
            }
          });

          individualMove.addEventListener("mousedown", () => {
            this.individualMove = true;
          });
        }

        if (type == "roombooking") {
          customEvent.appendChild(eveHeader_el);
          customEvent.appendChild(title_el);
        }

        if (type == "occupied" || type == "pause" || type == "meeting" || (type == "task") || (type == "note") | (type == "lead")) {
          customEvent.appendChild(eveHeader_el);
          eveHeader_el.innerHTML = title;
          // title_el.innerHTML = event.extendedProps.description.length !== 0 ? event.extendedProps.description : event.title + " tijd";

          if (type == "task") {
            title_el.classList.add("task");
            if (event.extendedProps.extra && event.extendedProps.extra.completed) {
              taskProgress.classList.add("done");
            } else {
              taskProgress.classList.add("open");
            }

            title_el.innerHTML = event.extendedProps.description.length !== 0 ? event.extendedProps.description.replace(/(\r\n|\n|\r)/gm,"<br>") : "";
            customEvent.appendChild(title_el);
            customEvent.appendChild(taskProgress);

            // Taak afvinken of weer actief maken
            taskProgress.addEventListener("click", () => {
              this.taskProgressClicked = true;
              if (event.extendedProps.extra && event.extendedProps.extra.completed) {
                event.extendedProps.extra.completed = false;
                taskProgress.classList.remove("done");
                this.changeTaskStatus(event, "completed");
              } else {
                taskProgress.classList.add("done");
                event.extendedProps.extra.completed = true;
                this.changeTaskStatus(event, "incompleted");
              }
            });
          }
          else if (type == "note") {
            title_el.innerHTML = event.extendedProps.description.length !== 0 ? event.extendedProps.description.replace(/(\r\n|\n|\r)/gm,"<br>") : "";
            customEvent.appendChild(title_el);
          }
          else {
            title_el.innerHTML = event.extendedProps.description.length !== 0 ? this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, event.extendedProps.description) : "";
            customEvent.appendChild(title_el);
          }
        }

        if (type == "eventbooking") {
          customEvent.appendChild(eveHeader_el);
          let availability = "";
          if (event.extendedProps.maxPersons && event.extendedProps.registrations) {
            availability = "<span style='color: #fff; font-weight: bold; position: absolute; right: 10px;'> " + event.extendedProps.registrations.length + "/" + event.extendedProps.maxPersons + "</span>";
          }
          eveHeader_el.innerHTML = event.title + availability;
          title_el.innerHTML = event.extendedProps.description;
          customEvent.appendChild(title_el);
          customEvent.style.height = "200px !important";
        }

        if(type == "computedSubscription"){
          customEvent.appendChild(eveHeader_el);
          let availability = "";
          if (event.extendedProps.seats) {
            availability = "<span style='color: #fff; font-weight: bold; position: absolute; right: 10px;'> " + "0" + "/" + event.extendedProps.seats + "</span>";
          }
          eveHeader_el.innerHTML = event.title + availability;
          title_el.innerHTML = event.extendedProps.description;
          customEvent.appendChild(title_el);
          customEvent.style.height = "200px !important";
        }

        if(type == "bookingSubscription"){
          customEvent.appendChild(eveHeader_el);
          let availability = "";
          if (event.extendedProps.availableSeats >= 0 && event.extendedProps.seats) {  
            availability = "<span style='color: #fff; font-weight: bold; position: absolute; right: 10px;'> " + event.extendedProps.subscriptions.length + "/" + event.extendedProps.seats + "</span>";
          }
          eveHeader_el.innerHTML = event.title + availability;
          title_el.innerHTML = event.extendedProps.title;
          customEvent.appendChild(title_el);
          customEvent.style.height = "200px !important";
        }
        // elem.style.borderRadius = "5px";
        // elem.style.margin = "1%";
        customEvent.classList.add("cus_event");
        // elem.appendChild(customEvent);
        // event.extendedProps.inProgress && this.loadProgress(progressBar_el);
        // let arrayOfDomNodes = [elem, progressBar_el, groupMove]; <-- With GroupMove
        let arrayOfDomNodes = [elem, progressBar_el, customEvent];
        return { domNodes: arrayOfDomNodes };
      },

      changeTaskStatus(event, status) {
        if (status == "incompleted") { // Task is completed
          event.extendedProps.extra.completed = true;
          event.extendedProps.extra.completedBy = this.userFirstName + " " + this.userLastName;
          event.extendedProps.extra.completedOn = new Date();
        }
        else { // Task is incompleted
          event.extendedProps.extra.completed = false;
          event.extendedProps.extra.completedBy = null;
          event.extendedProps.extra.completedOn = null;
        }
        db.collection("bookingen").doc(event.id).set({ extra: event.extendedProps.extra },{ merge: true });
      },

      

      async setEventStatus(bookingId, status){

        // console.log("event status", bookingId, status)
        let currentStatus = this.bookingsummaries.find((summary)=> summary.id == bookingId) ? this.bookingsummaries.find((summary)=> summary.id == bookingId).status : null;
        let waitTime = status == 'noShow' || currentStatus == 'noShow' ? 500 : 0;
        
        if(status == 'noShow' || currentStatus == 'noShow'){

          this.noShowDialog.type = status;
          this.noShowDialog.show = 'true';

          let bookings = this.eventsObjects.filter((event) => {return event.bookingSummaryId == bookingId});
          bookings.forEach((bookingEvent)=>{ // Update booking width bookingOverride value and save bookingOverrideOrginal value
          db.collection("bookingen")
            .doc(bookingEvent.id)
            .update({
              bookingOverride: status == 'noShow' ? true: bookingEvent.bookingOverrideOrginal ? bookingEvent.bookingOverrideOrginal : false,
              bookingOverrideOrginal: status == 'noShow' ? false : bookingEvent.bookingOverride ? bookingEvent.bookingOverride : false,
            })
          })
        }

        await setTimeout(() => { return },waitTime);
        
        await db.collection("bookingsummary")
        .doc(bookingId)
        .update({
          status: status,
          bookingOverride: status == 'noShow' ? true : false
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch(error=> {
          console.error("Error updating document: ", error);
        });
    

        if(status == 'noShow'){
          let bookingData = this.eventsObjects.find((event) => {return event.bookingSummaryId == bookingId});
      

          if(bookingData && bookingData.extendedProps.downPayment && bookingData.extendedProps.downPayment.active && bookingData.extendedProps.downPayment.paid){ // Use downpayment as compensation for no Show
            await axios.post(
              `${this.$store.state.connectionsApi.url}/mollie/paymentCompensation`, 
              {
                id: bookingId,
                type: "bookingSummary",
                description: "bookingNoShow"
              },
              {
                timeout: 5000,
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            .then((response) => {
              console.log("Mollie Payment Compensation No Show Success:", response.data);
            })
            .catch(error=>{
              console.error("Error  Payment Compensation No Show Success : ", error.response.status, error.response.data)
            }) //Mollie
          }
        }
      

      this.calendarApi.refetchEvents();

        
      },

      unlockEvents(bookingId, edit){
        new Promise((resolve)=>{
          let counter = 0;
          this.eventsObjects.forEach(booking=>{
            if(booking.bookingSummaryId == bookingId){
              booking.droppable = edit ? false : true;
              booking.resourceEditable = edit ? false : true;
              booking.editable = edit ? false : true;
            }
            counter++
            if(counter == this.eventsObjects.length){
              resolve()
            }
          })
        })
        .then(()=>{
          this.calendarApi.refetchEvents();
        })
      },

      checkBookingEditStatus(){
        let changes = false;
        new Promise((resolve)=>{
          let counter = 0;
          this.eventsObjects.forEach(booking=>{
            if(booking.end < moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm") && booking.extendedProps.droppable){
              booking.droppable = false;
              booking.resourceEditable = false;
              booking.resourceEditable = false;
              booking.editable = false;
              changes = true;
            }  
            counter++
            if(counter == this.eventsObjects.length){
              resolve()
            }
          })
        })
        .then(()=>{
          if(changes){
            this.calendarApi.refetchEvents();
          }
        })
      },

      // SNAPSHOTS
      // Bookingsummary
      async querySnapshotFirebaseBookingsummary(navigate) {
        this.snapshotListenerBookingsummary = null;
        let chosenday = navigate ? moment(this.calendarApi.getDate()).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
        this.snapshotListenerBookingsummary = await db
        .collection("bookingsummary")
        // .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("visit", "==", chosenday)
        .where("branchId", "==", this.$store.state.activeBranch.id)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "modified") {

              console.log("modified")

              // Update information in bookingSummary 
              this.bookingsummaries.forEach(summary =>{
                if(summary.id == change.doc.id){
                  // summary = change.doc.data();
                  // summary.id = change.doc.id;
                  summary.status = change.doc.data().status;
                }
              })

              // Update information in booking Events 
              this.eventsObjects.forEach(booking =>{

 
                if(booking.bookingId == change.doc.id){
                  booking.extendedProps.paid = change.doc.data().paid;
                  booking.extendedProps.created = change.doc.data().created;
                  booking.extendedProps.notes = change.doc.data().notes ? change.doc.data().notes : null ;
                  booking.extendedProps.downPayment = change.doc.data().downPayment ? change.doc.data().downPayment : null ;
                  booking.extendedProps.status = change.doc.data().status ? change.doc.data().status : null;
                  booking.extendedProps.history = change.doc.data().history ?  change.doc.data().history : new Array();
                  booking.extendedProps.emailHistory = change.doc.data().emailHistory ? change.doc.data().emailHistory : new Array();
                  booking.backgroundColor = booking.extendedProps.eventColor ? booking.extendedProps.eventColor : this.getEventColor(booking.extendedProps.type, booking.extendedProps.preferredPractitioner, 'snapshot');
                  if(change.doc.data().downPayment && change.doc.data().downPayment.active && !change.doc.data().downPayment.paid){
                      booking.backgroundColor = "rgba(132,132,132,0.33)";
                  }
                  if(change.doc.data().status && change.doc.data().status == 'noShow'){
                    booking.backgroundColor = "#EF9A9A"
                  }
                 
                }
              })
              this.calendarApi.refetchEvents();
            }
          })
        })
        return 
      },

      getEventColor(type, preferredPractitioner, location){
        // console.log("getEventColor: ", type, preferredPractitioner, location)
        if (type == "booking") {
          return preferredPractitioner ? this.branchBackgroundColor.colorWithPreference : this.branchBackgroundColor.colorNoPreference;         }
        if (type == "pause" || event.type == "task" || event.type == "meeting" || event.type == "occupied" || event.type == "roombooking") {
          return this.branchBackgroundColor.colorTime; 
        }
      },

     

      async getCalendarEventsApi(date, branchId) {
        console.log("Events API Get Day Backend: ", date, branchId)

        let data = {
          bookings: new Array(),
          pauses: new Array(),
          bookingsummaries: new Array()

        }
        try {
          const response = await axios.post(`${this.$store.state.calendarApi.url}/getDay`, {
            date: date,
            branchId: branchId
          }, {
            timeout: 5000,
            headers: {
              'Content-Type': 'application/json'
            }
          });

          // console.log("CALENDAR API DAY RESPONSE: ", response)
         
          const backendData = response.data;

          //Create new event objects
          data.bookings = backendData.bookings.map((event) => {

            // console.log("Event: ", event.extendedProps)

            let newEvent = {
              id: event.id,
              resourceId: event.resourceDocId,
              bookingSummaryId: event.bookingId,
              bookingId: event.bookingId,
              treatmentId: event.treatmentId,
              title: event.title,
              start: event.start,
              end: event.end,
              backgroundColor: event.eventColor ? event.eventColor : this.getEventColor(event.type, event.preferredPractitioner, "getCalendarEventsApi"),
              borderColor: "rgba(0,0,0,0)",
              extendedProps: { ...event, ...event.extendedProps },
              droppable: event.end > moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm") ? true : false,
              resourceEditable: event.end > moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm") ? true : false,
              editable: event.end > moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm") ? true : false,
              allDay: event.allDay ? event.allDay : false,
              modifiedStamp: event.modifiedStamp
            }

            if(newEvent.extendedProps.downPayment && newEvent.extendedProps.downPayment.active && !newEvent.extendedProps.downPayment.paid){
              newEvent.backgroundColor = "rgba(132,132,132,0.33)";
              // newEvent.droppable = false;
              // newEvent.resourceEditable = false;
              // newEvent.editable = false;
            }
            if(newEvent.extendedProps.status && newEvent.extendedProps.status == 'noShow'){
              newEvent.backgroundColor = "#EF9A9A";
             }

            return newEvent;
          })

          data.pauses = backendData.pauses;

          data.bookingsummaries = backendData.bookingsummaries;

          
        } catch (error) {
          console.error('Error fetching events getCalendarEvents:', error);
        }

        return data;
       
      },

      /**
       * 
       * @description - Add or Update the newest Event Object from Firebase onSnapshot
       * @param {Object} newData - Newest Event Object from Firebase onSnapshot
       */
      giveAddNewestEventOnly(newData) {

        let somethingChanged = false; // If something changed, refetch the event source
        
        // console.log("this.eventsObjects", this.eventsObjects)

        // console.log(this.eventsObjects.length)

        const index = this.eventsObjects.findIndex(event => event.id === newData.id);
        // console.log("New Data: ",index,newData.id, newData)

        //TEST CODE
        // if (index !== -1) {

        //   try {
        //     this.$refs.fullCalendar.getApi().getEventSourceById(newData.id).refetch();
        //   } catch (error) {
        //     console.error('Error refetching the event source:', error);
        //   }

        // }        
      
        
        if (index === -1) {
          // Als het event niet bestaat, voeg het nieuwe event toe
          this.eventsObjects.push(newData);
          console.log("New Event Added: ", newData)

          return true;

         
        } else if (index !== -1 && !this.eventsObjects[index].modifiedStamp && newData.modifiedStamp) {

          //TODO DEZE CODE MAG WORDEN WEGGEHAALD WANNEER WIDGET EN BOOKING VIA DE CALENDAR ZIJN GEUPDATE MET "modifiedStamp"

          // Als het event bestaat maar geen modifiedStamp heeft, vervang het oude event met nieuwe data als deze wel een modifiedStamp heeft
          console.log("Existing event has no modifiedStamp, replacing with new data.");
          this.eventsObjects[index] = newData;

          return true;

          
        }
        else if (index !== -1 && this.eventsObjects[index].modifiedStamp && newData.modifiedStamp && this.eventsObjects[index].modifiedStamp.seconds < newData.modifiedStamp.seconds) {
          
          console.log("Event Exists: ", newData.modifiedStamp.seconds, this.eventsObjects[index].modifiedStamp.seconds)

          // Als het event bestaat en de modifiedStamp van het nieuwe event is nieuwer dan de modifiedStamp van het oude event, vervang het oude event met nieuwe data
          this.eventsObjects[index] = newData;

          return true;

        }
        else {
          // console.log("nothing changed")
          return false; // Nothing changed
        }

      },
      // Bookings
      async querySnapshotFirebaseBooking() {
        
        this.snapshotListenerBooking = null;
        // let chosenday = navigate ? moment(this.$refs.fullCalendar.getApi().getDate()).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");


        let chosenday = this.chosenDateCalendar;

        this.earliestBooking = new Array();
        this.latestBooking = new Array();


        //Earliest Booking
        const earlistBookingFromDB = async () => {
         return  await db.collection("bookingen")
          .where("dateBooking", "==", chosenday)
          .where("branchId", "==", this.$store.state.activeBranch.id)
          .orderBy("start", "desc")
          .limit(1)
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach(doc => {
              console.log(doc.data())
              this.earliestBooking.push(doc.data().start)
              return
            });
          }).catch((error) => {
            console.error("Error getting Earliest Booking documents: ", error);
          });
        }

        const latestBookingFromDB = async () => {

         return await db.collection("bookingen")
          .where("dateBooking", "==", chosenday)
          .where("branchId", "==", this.$store.state.activeBranch.id)
          .orderBy("end", "asc")
          .limit(1)
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach(doc => {
              console.log(doc.data())
              this.latestBooking.push(doc.data().end)
              return
            });
          }).catch((error) => {
            console.error("Error getting Latest Booking documents: ", error);
          });
        }

        const handleSnapshotListenerBookings = async () => {
          await new Promise((resolve)=>{
            let startSnapshotListener = moment();
            this.snapshotListenerBookings = db
            .collection("bookingen")
            .where("dateBooking", "==", chosenday)
            .where("branchId", "==", this.$store.state.activeBranch.id) 
            .onSnapshot((snapshot) => {    
              let counter = 0;

              //if snapshot is empty
              if(snapshot.size == 0){
                console.log("No Bookings", chosenday)
                resolve()
              }
              console.log("events start snapshot")
              
              snapshot.docChanges().forEach(async(change) => { 
                // let branchBackgroundColor = null;
                // if(this.$store.state.activeBranch.calendarSettings.colorTime && this.$store.state.activeBranch.calendarSettings.colorWithPreference && this.$store.state.activeBranch.calendarSettings.colorNoPreference){
                //   if(change.doc.data().type == "booking"){
                //     branchBackgroundColor = change.doc.data().preferredPractitioner ? this.$store.state.activeBranch.calendarSettings.colorWithPreference : this.$store.state.activeBranch.calendarSettings.colorNoPreference;
                //   }
                //   if(change.doc.data().type == "pause" || change.doc.data().type == "task" || change.doc.data().type == "meeting" || change.doc.data().type == "occupied" || change.doc.data().type == "roombooking"){
                //     branchBackgroundColor = this.$store.state.activeBranch.calendarSettings.colorTime; 
                //   }
                // }
                if(change.type === "added"){ // New booking Doc
                  let data = change.doc.data();
                  data.extendedProps = change.doc.data();
                  data.extendedProps.inProgress = false;
                  data.id = change.doc.id;
                  data.extendedProps.droppable = data.end > moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm") ? true : false;

                  let newData = {
                    id: data.id, // Doc ID of the Booking itself
                    resourceId: data.resourceDocId,
                    bookingSummaryId: data.bookingId, //Same as BookingSummaryId
                    bookingId: data.bookingId, // Same as BookingSummaryId
                    treatmentId: data.treatmentId,
                    title: data.title,
                    start: data.start,
                    end: data.end,
                    backgroundColor: data.eventColor ? data.eventColor : this.getEventColor(data.extendedProps.type, data.extendedProps.preferredPractitioner, "handleSnapshotListenerBookings newData"),
                    borderColor: "rgba(0,0,0,0)",
                    extendedProps: data.extendedProps,
                    droppable: data.end > moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm") ? true : false,
                    resourceEditable: data.end > moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm") ? true : false,
                    editable: data.end > moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm") ? true : false,        
                    allDay: data.allDay ? data.allDay: false,
                    modifiedStamp: data.modifiedStamp
                  };

                  if(data.type != "eventbooking" && data.type != "bookingSubscription") {
                                    
                   const foundedBookingsummary = await this.mergeEventStats(data.bookingId) // get Booking Summary by bookingId = bookingsummary id 
                   
                      if(foundedBookingsummary){
                        newData.extendedProps.created = foundedBookingsummary.created ? foundedBookingsummary.created : null;
                        newData.extendedProps.paid = foundedBookingsummary.paid ? foundedBookingsummary.paid : null;
                        newData.extendedProps.notes = foundedBookingsummary.notes ? foundedBookingsummary.notes : null;
                        newData.extendedProps.downPayment = foundedBookingsummary.downPayment ? foundedBookingsummary.downPayment : null ;
                        newData.extendedProps.status = foundedBookingsummary.status ? foundedBookingsummary.status : null;
                        newData.extendedProps.history = foundedBookingsummary.history ? foundedBookingsummary.history : new Array();
                        newData.extendedProps.emailHistory = foundedBookingsummary.emailHistory ? foundedBookingsummary.emailHistory : new Array();
                      }
  
                      if(newData.extendedProps.downPayment && newData.extendedProps.downPayment.active && !newData.extendedProps.downPayment.paid){
                        newData.backgroundColor = "rgba(132,132,132,0.33)";
                        // newData.droppable = false;
                        // newData.resourceEditable = false;
                        // newData.editable = false;
                      }
                      if(newData.extendedProps.status && newData.extendedProps.status == 'noShow'){
                        newData.backgroundColor = "#EF9A9A";
                      }
                      // this.eventsObjects.push(newData);
                      // this.$refs.fullCalendar.getApi().refetchEvents();
                      

                      //Debounce for Performance purpose
                      this.snapBookingsChanged = this.giveAddNewestEventOnly(newData);
                      if(this.snapBookingsChanged === true){
                        
                        if (this.refetchTimer) {
                          clearTimeout(this.refetchTimer);
                        }
                        this.refetchTimer = setTimeout(() => {
                         let vm = this;
                          vm.calendarApi.refetchEvents();
                          console.log("Events refetched after debounce period.");
                        }, 800); // Debounce period of 1000 milliseconds


                      }
                                          

                      if(change.doc.data().createdBy != 'postBookingWidget' && (snapshot.size != snapshot.docChanges().length)){
                       this.checkForCacheChanges() // Set timer to compare Cache with bookings  
                        // this.$refs.fullCalendar.getApi().refetchEvents();
                      }
                      else{ // First Calendar Load
                        counter++
                        if(counter == snapshot.docChanges().length){
                        // this.$refs.fullCalendar.getApi().refetchEvents();

                          console.log("First Calendar Load")
                          resolve()
                        }
                      }

              
                  } else if (data.type === "eventbooking") { // Event booking
                    const foundedBookingsummary = await this.mergeEventStats(data.bookingId) // get Booking Summary by bookingId = bookingsummary id
                      // Merge new data
                      newData.extendedProps.registrations = foundedBookingsummary.registrations ? foundedBookingsummary.registrations : new Array();
                      this.eventsObjects.push(newData);
                      this.calendarApi.refetchEvents();
               
                  } 
                  else if (data.type === "bookingSubscription") { // Booking || Pause etc
                    // Rewrite Time
                    newData.start = chosenday + "T" + data.startTime;
                    newData.end = chosenday + "T" + data.endTime;
                    newData.resourceId = data.resourceId;
                    newData.backgroundColor = data.backgroundColor; 
                    newData.editable = false;
                    newData.droppable = false;
                    newData.resourceEditable = false;

                    // Remove computedSubscription
                    let removeComputedId = this.eventsObjects.find(eventObject => eventObject.type === "computedSubscription" && eventObject.resourceId === newData.resourceId );

                    if(removeComputedId) {
                      // Quick delete Computed Resource Booking
                      for (let index = 0; index < this.eventsObjects.length; index++) {
                        if (this.eventsObjects[index].type === "computedSubscription" && this.eventsObjects[index].computedId === removeComputedId.computedId) {
                          this.eventsObjects.splice(index, 1);
                          break;
                        }
                      }
                    }
                    this.eventsObjects.push(newData);
                    this.calendarApi.refetchEvents();
                  }
                  
                }
                if(change.type === "modified"){ // Modified booking Doc
                  // console.log("Modified Booking: =>", change.doc.id, change.doc.data());
                  let data = change.doc.data();
                  data.id = change.doc.id;
                  data.extendedProps = change.doc.data();
                  data.extendedProps.resID = change.doc.data().resourceId; //GOING Depreacted
                  data.extendedProps.inProgress = false;
                  data.extendedProps.droppable = data.end > moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm") ? true : false;

                  let modifiedData = new Object();

                  const foundedBookingsummary = await this.mergeEventStats(data.bookingId) // get Booking Summary by bookingId = bookingsummary id 
                   
                  if(foundedBookingsummary){
                    data.extendedProps.created = foundedBookingsummary.created ? foundedBookingsummary.created : null;
                    data.extendedProps.paid = foundedBookingsummary.paid ? foundedBookingsummary.paid : null;
                    data.extendedProps.notes = foundedBookingsummary.notes ? foundedBookingsummary.notes : null;
                    data.extendedProps.downPayment = foundedBookingsummary.downPayment ? foundedBookingsummary.downPayment : null ;
                    data.extendedProps.status = foundedBookingsummary.status ? foundedBookingsummary.status : null;
                    data.extendedProps.history = foundedBookingsummary.history ? foundedBookingsummary.history : new Array();
                    data.extendedProps.emailHistory = foundedBookingsummary.emailHistory ? foundedBookingsummary.emailHistory : new Array();
                  }

                  if(data.extendedProps.downPayment && data.extendedProps.downPayment.active && !data.extendedProps.downPayment.paid){
                    data.backgroundColor = "rgba(132,132,132,0.33)";
                    data.droppable = false;
                    data.resourceEditable = false;
                    data.editable = false;
                  }
                  if(data.extendedProps.status && data.extendedProps.status == 'noShow'){
                    data.backgroundColor = "#EF9A9A";
                  }
                  
                  if(data.resourceDocId) { // When Resource Id is Found
                    console.log("data.resourceDocId", data.resourceDocId)
                    modifiedData = {
                      id: data.id,
                      resourceId: data.resourceDocId,
                      bookingSummaryId: data.bookingId,
                      bookingId: data.bookingId,
                      treatmentId: data.treatmentId,
                      title: data.title,
                      start: data.start,
                      end: data.end,
                      backgroundColor: data.eventColor ? data.eventColor : this.getEventColor(data.extendedProps.type, data.extendedProps.preferredPractitioner, "handleSnapshotListenerBookings modifiedData"),
                      extendedProps: data.extendedProps,
                      editable: true,
                      droppable: true,
                      resourceEditable:true,
                      allDay: data.allDay ? data.allDay: false,
                      modifiedStamp: data.modifiedStamp
                    };
                  }
                  else {
                    //LEGACY
                    // const foundResources = this.resourceObjects.find((resource) => resource.legacyId === data.resourceId);
                   
                    // console.log("foundResources", foundResources)
                   
                    // modifiedData = {
                    //   id: data.id,
                    //   resourceId: foundResources.id, // The new resourceDocId
                    //   bookingSummaryId: data.extendedProps.bookingId,
                    //   bookingId: data.bookingId,
                    //   treatmentId: data.treatmentId,
                    //   title: data.title,
                    //   start: data.start,
                    //   end: data.end,
                    //   backgroundColor: data.eventColor ? data.eventColor : branchBackgroundColor,
                    //   extendedProps: data.extendedProps,
                    //   editable: true,
                    //   droppable: true,
                    //   resourceEditable: true,
                    //   allDay: data.allDay ? data.allDay: false,
                    //   modifiedStamp: data.modifiedStamp
                    // };
                  }

                  

                  const pushEventModified = () => {
                    let foundIndex = this.eventsObjects.findIndex((booking) => booking.id == data.id);
                    if (foundIndex !== -1) { // Replace Modified booking
                      this.eventsObjects[foundIndex] = modifiedData;
                      
                      return 
                    }
                    else { // Triggert by LEGACY Code add the new converted Booking 
                      this.eventsObjects.push(modifiedData);
                    }
                    // this.$refs.fullCalendar.getApi().refetchEvents();
                    return 
                  };

                  this.calendarApi.refetchEvents();

                  if (data.type == "eventbooking") { // Event booking
                    const foundedBookingsummary = await this.mergeEventStats(data.bookingId) // get Booking Summary by bookingId = bookingsummary id
                    // Merge new data
                      modifiedData.extendedProps.registrations = foundedBookingsummary.registrations ? foundedBookingsummary.registrations : new Array();
                     
                      pushEventModified();
                      this.checkForCacheChanges() // Set timer to compare Cache with bookings  
                  
                  }
                  else { //Booking || Pause etc
                    pushEventModified();
                  }

                  if (data.type == "bookingSubscription") { // bookingSubscription
                    // Rewrite Time
                    modifiedData.start = chosenday + "T" + data.startTime;
                    modifiedData.end = chosenday + "T" + data.endTime;
                    modifiedData.backgroundColor = data.backgroundColor;

                    // Remove computedSubscription
                    let removeComputedId = this.eventsObjects.find(eventObject => eventObject.type === "computedSubscription" && eventObject.resourceId === data.resourceId && eventObject.start === modifiedData.start && eventObject.end === modifiedData.end);

                    if(removeComputedId) {
                      //Quick delete Computed Resource Booking
                      for (let index = 0; index < this.eventsObjects.length; index++) {
                        if (this.eventsObjects[index].type === "computedSubscription" && this.eventsObjects[index].computedId === removeComputedId.computedId) {
                          this.eventsObjects.splice(index, 1);
                          break;
                        } 
                      }
                    }
                    //Replace modified Data
                    let foundIndex = this.eventsObjects.findIndex((booking) => booking.id == data.id);

                    if (foundIndex !== -1) { // Replace Modified booking
                      this.eventsObjects[foundIndex] = modifiedData;
                      return this.calendarApi.refetchEvents();
                    }
                  }
                  this.checkForCacheChanges() // Set timer to compare Cache with bookings  
                }
                if (change.type === "removed"){ // Booking Removed
                  let data = change.doc.data();
                  data.id = change.doc.id;
                  data.extendedProps = {
                    resID: change.doc.data().resourceId,
                    inProgress: false,
                  };
                  data.color = change.doc.data().eventColor;
                  data.id = change.doc.id;

                  if(data.type === "bookingSubscription") { // Create a new idle Reservation Block
                    let computedId = Math.floor(Math.random() * (9999 - 3000 + 1)) + 3000; // Generate a random Id
                    let extendedPropsObject = {
                      start: chosenday + "T" + data.startTime,
                      end: chosenday + "T" + data.endTime,
                      id: computedId,
                      title: data.title,
                      backgroundColor: change.doc.data().backgroundColor + "60", //Get the right color
                      description: data.title,
                      resourceDocId: data.resourceDocId,
                      maxPersons: data.seats,
                      seats: data.seats,
                      subscriptions: [],
                    }

                    let newComputedSubscription = {
                      computedId: computedId,
                      start: chosenday + "T" + data.startTime,
                      end: chosenday + "T" + data.endTime,
                      resourceId: change.doc.data().resourceDocId,
                      title: change.doc.data().title,
                      id: computedId,
                      type: "computedSubscription",
                      backgroundColor: extendedPropsObject.backgroundColor,
                      allDay: data.allDay ? data.allDay: false,
                      extendedProps:extendedPropsObject,
                    }
                    this.eventsObjects.push(newComputedSubscription);
                  }
                  this.eventsObjects = this.eventsObjects.filter((booking) => booking.id !== data.id);
                  this.checkForCacheChanges() // Set timer to compare Cache with bookings  
                  this.calendarApi.refetchEvents();
                }
              })
              
              if(snapshot.size == 0){
                resolve()
                // this.renderCalendar();
              }      
            })
            
          })
        }
       

       return Promise.all([earlistBookingFromDB(), latestBookingFromDB()]).then(()=>{
          handleSnapshotListenerBookings();
          console.log("Earliest Booking: ", this.earliestBooking)
          console.log("Latest Booking: ", this.latestBooking)
         
          return;
        })
      },
     
      async createResourceCache(resId, resourceTypeSelect) { // Check if resource includes bookings then push it to the database
        let bookingsCollect = await this.getResourceBookingCache(resId);                
        if (bookingsCollect.length > 0) { // Does resources has bookings?
          let cacheRecord = {
            resourceId: resId,
            companyId: this.$store.state.activeCompany.id,
            branchId: this.$store.state.activeBranch.id,
            debugCache: this.debugCache == true ? true : false,
            type: resourceTypeSelect,
            date: this.chosenDateCalendar,
            bookings: bookingsCollect,
            timestamp: new Date(),
            createdBy: "ThriveClient",
          };
          console.log("createResourceCache: ", cacheRecord) 
          db.collection("resourceCache") // Push to firebase
          .add(cacheRecord)
          .catch((err) => {
            console.error("Error adding Cache to DB =>", err);
          });
        }
      },

      async getResourceBookingCache(resourceId){ // Get ResourcesBookings for cache
        let bookingsCollect = new Array();

        function typeOfBooking(booking) { // Check if booking is swappable
          if(booking.extendedProps.resourceType === "employee" && booking.extendedProps.type === "booking") {
            return booking.extendedProps.preferredPractitioner === true ? false: true // Preferred by client?
          }
          else { // This type of booking cannot be swapped (We don't want to swap pauzes, rooms, etc.)
            return false;
          }
        }

        await new Promise((resolve)=>{
          let counter = 0;
          if(this.eventsObjects.length > 0){
            this.eventsObjects.forEach((booking) => {
              if (resourceId === booking.extendedProps.resourceDocId && !booking.extendedProps.bookingOverride) {
                bookingsCollect.push({
                  start: moment(booking.start).format("HH:mm"),
                  end: moment(booking.end).format("HH:mm"),
                  bookingSummaryId: booking.bookingSummaryId ? booking.bookingSummaryId : null,
                  bookingId: booking.id,
                  treatmentId: booking.treatmentId ? booking.treatmentId : null,
                  swapping: typeOfBooking(booking),
                  type: booking.extendedProps.type
                });
              }
              counter++
              if(counter == this.eventsObjects.length){ resolve()}
            });
          }
          else{
            resolve()
          }
        })
        return bookingsCollect
      },

      compareCacheWithBookings(){  // Compare current cache with bookings
        console.log("compareCacheWithBookings")
        this.resourceObjects.forEach(resource=>{
          this.getResourceBookingCache(resource.id).then((bookingsCollect)=>{
            let resourceCache = this.resourceCache.find(cache=>{ return cache.resourceId == resource.id});
            let different = false
            if(bookingsCollect.length > 0 || resourceCache ){
              if(resourceCache && resourceCache.bookings){
                different = _.isEqual(bookingsCollect, resourceCache.bookings) ? false : true;
              }
              else{
                different = true;
              }
            }
            if(different){
              this.updateCache(resource.id)
            }
          })
        })
        clearInterval(this.newBookingsTimer);
      },

      openCashRegister(){
        bus.$emit("openCashUpModal");
      },

      checkTimeRegistration(){ // Check if there are changes in the schedules of active employees today
        console.log("checkTimeRegistration: ", this.scheduleExceptions.length)
        this.timeRegistrationInfo.show = false;
        this.timeRegistrationDialog.changes = 0;
        this.timeRegistrationInfo.showBatch = false;
        this.timeRegistrationInfo.number = 0;

        if(this.chosenDateCalendar == moment().format("YYYY-MM-DD")){
          console.log("scheduleExceptions: ", this.scheduleExceptions)
          this.timeRegistrationInfo.show = true;
          if(this.scheduleExceptions.length > 0){
            let scheduleExceptionsToday = this.scheduleExceptions.filter((exception)=>{ return (exception.startdate == this.chosenDateCalendar || exception.enddate == this.chosenDateCalendar || (exception.startdate < this.chosenDateCalendar && exception.enddate > this.chosenDateCalendar)) });

            if(scheduleExceptionsToday.length > 0 ){
              this.timeRegistrationInfo.number = scheduleExceptionsToday.length;
              this.timeRegistrationInfo.showBatch = this.timeRegistrationInfo.number > 0 ? 1 : 0;
            }

          }
        }
        return
      },

      timeRegistrationEmployeeInfo(employee){
        const date = moment().format("YYYY-MM-DD");
        const day = moment(date).format("ddd")
        const week = moment(date).isoWeek();
        const year = moment(date).year();
        let randomNumber= Math.floor(Math.random() * 10);
        
        
        let exceptions = false;
        let data = {
          description: this.$t('employees.timeRegistrationBySchedule', this.$store.state.locale),
          minutes: 0,
          minutesExtra: 0,
          minutesMinus: 0,
          timeChanges: false,
          changes: new Array()
        };

        let minutes = 0;
        let minutesMinus = 0;
        let minutesExtra = 0;
        let times = employee.basicSchedule[day] ? employee.basicSchedule[day] : new Array();
     

        // Calculate Hours
        if(times.length > 0) {
          times.forEach(time=>{
            if(time.type == 'work'){
              minutes = minutes + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
            }
          })
        }
        else{
          data.description = this.$t('employees.timeRegistrationNoWorkToday', this.$store.state.locale);
        }
        
        let scheduleExceptionsAbsence = this.scheduleExceptions.filter((exception)=>{ return exception.type == 'absence' && exception.resourceId == employee.id && (exception.startdate == date || exception.enddate == date || (exception.startdate < date && exception.enddate > date)) });
        let scheduleExceptionsPresent = this.scheduleExceptions.filter((exception)=>{ return exception.type == 'present' && exception.resourceId == employee.id && (exception.startdate == date || exception.enddate == date || (exception.startdate < date && exception.enddate > date)) });
        let mergedScheduleExceptionsAbsence = scheduleExceptionsAbsence.filter((exception)=>{ return !exception.time || exception.time.length == 0 });
        let mergedScheduleExceptionsPresent = new Array();

        let extraWorkedMinutes = 0;
        let totalLeaveMinutes = 0;
        let totalExceptionOverlapTime = 0;

        // Get unique absence times (for later calculation)
        if(scheduleExceptionsAbsence.length > 0){
          let exceptionTimes = new Array()
          
          scheduleExceptionsAbsence.forEach((exception)=>{
            if(exception.time && exception.time.length > 0){
              exceptionTimes = (exceptionTimes.concat(exception.time)).sort((a, b) => a.start.localeCompare(b.start));    
            }
          })
          let mergedExceptionTimes = mergeTimeBlocks(exceptionTimes);
          
          mergedScheduleExceptionsAbsence.push({
            allDay: false,
            type: 'absence',
            time: mergedExceptionTimes
          })

          if(mergedExceptionTimes.length > 0){
            mergedExceptionTimes.forEach((exceptionTimes)=>{
              totalLeaveMinutes = totalLeaveMinutes + Number(moment(exceptionTimes.end, "HH:mm").diff(moment(exceptionTimes.start, "HH:mm"), "minutes"))
            })
          }
        }

        // Get unique present times (for later calculation)
        if(scheduleExceptionsPresent.length > 0){
          let exceptionTimes = new Array()
          
          scheduleExceptionsPresent.forEach((exception)=>{
            if(exception.time && exception.time.length > 0){
              exceptionTimes = (exceptionTimes.concat(exception.time)).sort((a, b) => a.start.localeCompare(b.start));    
            }
          })
          
          let mergedExceptionTimes = mergeTimeBlocks(exceptionTimes);
          
          mergedScheduleExceptionsPresent.push({
            allDay: false,
            type: 'present',
            time: mergedExceptionTimes
          })

          if(mergedExceptionTimes.length > 0){
            mergedExceptionTimes.forEach((exceptionTimes)=>{
              extraWorkedMinutes = extraWorkedMinutes + Number(moment(exceptionTimes.end, "HH:mm").diff(moment(exceptionTimes.start, "HH:mm"), "minutes"))
            })
          }
        }

        // Check for overlap and overrule abcense type
        // Calculate the overlap between absence and extra present timeblock and (total is later subtacted from the total absence minutes, for the present beeing dominant)

        if(mergedScheduleExceptionsAbsence.length > 0 && scheduleExceptionsPresent.length > 0){
          mergedScheduleExceptionsAbsence.forEach((exceptionAbsence)=>{
            scheduleExceptionsPresent[0].time.forEach((exceptionPresentTime, index)=>{
              if(!exceptionAbsence.time || exceptionAbsence.time.length == 0){ // Absence is set for all day
                totalExceptionOverlapTime = extraWorkedMinutes
              }
              else{ // Absence is set in timeblock(s)
                exceptionAbsence.time.forEach((absenseTime)=>{
                  if(exceptionPresentTime.start <= absenseTime.start && exceptionPresentTime.end >= absenseTime.end){ // Complete overlap. Add all minutes
                    totalExceptionOverlapTime = totalExceptionOverlapTime + Number(moment(absenseTime.end, "HH:mm").diff(moment(absenseTime.start, "HH:mm"), "minutes"));
                  }
                  else if(exceptionPresentTime.start > absenseTime.start && exceptionPresentTime.end < absenseTime.end){ // Partial overlaop split absence in two parts and add minutes
                    totalExceptionOverlapTime = totalExceptionOverlapTime + Number(moment(exceptionPresentTime.start, "HH:mm").diff(moment(absenseTime.start, "HH:mm"), "minutes")) + Number(moment(absenseTime.end, "HH:mm").diff(moment(exceptionPresentTime.start, "HH:mm"), "minutes"));
                  }
                  else if(exceptionPresentTime.start >= absenseTime.start && exceptionPresentTime.start < absenseTime.end &&exceptionPresentTime.end > absenseTime.end){ // Add first part of absence due to overlap
                    totalExceptionOverlapTime = totalExceptionOverlapTime + Number(moment(exceptionPresentTime.start, "HH:mm").diff(moment(absenseTime.start, "HH:mm"), "minutes"));
                  }

                  else if(exceptionPresentTime.start <= absenseTime.start && exceptionPresentTime.end > absenseTime.start){ // Add last part of absence due to overlap
                    totalExceptionOverlapTime = totalExceptionOverlapTime + Number(moment(absenseTime.end, "HH:mm").diff(moment(exceptionPresentTime.end, "HH:mm"), "minutes"));
                  }
                })
              }
            })
          })
        }
 
        let scheduleExceptions = mergedScheduleExceptionsPresent.concat(mergedScheduleExceptionsAbsence);
        let scheduleExceptionsOriginals = scheduleExceptionsAbsence.concat(scheduleExceptionsPresent);
        scheduleExceptionsOriginals.forEach((exception)=>{
          exception.allDay = !exception.time || exception.time.length == 0 ? true: false;
          data.changes.push(exception)
        })

        // Check for exceptions for employee
        if(scheduleExceptions.length > 0){
          scheduleExceptions.forEach(exception=>{
            if(exception.type == 'absence'){
              if(!exception.allDay && exception.time && exception.time.length > 0){
                let overlapMinutes = 0
                times.forEach(time => {
                  if(time.type == 'work'){
                    exception.time.forEach(exceptionTime=>{
                      if(exceptionTime.start && exceptionTime.end){
                        let start = exceptionTime.start >= time.start ? exceptionTime.start : time.start ;
                        let end = exceptionTime.end >= time.end ? time.end : exceptionTime.end ;
                        let minutes =  Number(moment(end, "HH:mm").diff(moment(start, "HH:mm"), "minutes")) > 0 ? Number(moment(end, "HH:mm").diff(moment(start, "HH:mm"), "minutes")) : 0;
                        
                        overlapMinutes = overlapMinutes + minutes;
                      }  
                    })
                  }
                })
                minutesMinus = (totalLeaveMinutes - overlapMinutes) >= 0 ? totalLeaveMinutes : (totalLeaveMinutes - overlapMinutes)
              }
              else if(exception.allDay){
                times.forEach(time =>{
                  if(time.type == 'work'){
                    minutesMinus = minutesMinus + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                  }
                })
              }
              else{
                minutesMinus = minutes;
              }
              minutesMinus =(minutesMinus -  totalExceptionOverlapTime) > 0 ? (minutesMinus -  totalExceptionOverlapTime) : 0
            }
            if(exception.type == 'present'){
              if(exception.time && exception.time.length > 0){

                let overlapMinutes = 0
                times.forEach(time =>{
                  exception.time.forEach(exceptionTime=>{
                    if(exceptionTime.start && exceptionTime.start.length == 5 && exceptionTime.end && exceptionTime.end.length == 5){
                      let start = exceptionTime.start >= time.start ? exceptionTime.start : time.start ;
                      let end = exceptionTime.end >= time.end ? time.end : exceptionTime.end ;
                      let minutes =  Number(moment(end, "HH:mm").diff(moment(start, "HH:mm"), "minutes")) > 0 ? Number(moment(end, "HH:mm").diff(moment(start, "HH:mm"), "minutes")) : 0;
                      overlapMinutes = overlapMinutes + minutes; 
                    }
                  })
                })
                minutesExtra = extraWorkedMinutes - overlapMinutes
              }
            }
            exceptions = true;
            exception.startDateDialog = false;
            exception.endDateDialog = false;
            
   
          })
        }
        data.description = exceptions ? this.$t('employees.timeRegistrationExceptionsInSchedule', this.$store.state.locale) : data.description;
        data.timeChanges = data.changes.length > 0 ? true : false;
        data.minutes = minutes;
        data.minutesExtra = minutesExtra;
        data.minutesMinus = minutesMinus; 

        this.timeRegistrationDialog.employees[employee.id] = data;

        // Function to merge times blocks in array
        function mergeTimeBlocks(timeBlocks) {
          const mergedBlocks = [];
          let currentBlock = null;
 
          timeBlocks.forEach(block => { // Doorloop elk tijdvak om te controleren of ze samengevoegd kunnen worden.
            const { type, ...blockWithoutType } = block; // Verwijder 'type' uit het object voor eenvoud.
            if (!currentBlock) { // Als er nog geen huidig blok is, start dan met dit blok.
              currentBlock = { ...blockWithoutType };
            }
            else if (currentBlock.end === block.start) { // Als het huidige blok aansluit op het volgende, voeg ze samen.
              currentBlock.end = block.end; //?
            }
            else { // Als ze niet aansluiten, voeg het huidige blok toe aan de lijst en start een nieuw blok.
              mergedBlocks.push(currentBlock);
              currentBlock = { ...blockWithoutType }; //?
            }
          });
  
          if (currentBlock) { // Voeg het laatste blok toe aan de lijst als het bestaat.
            mergedBlocks.push(currentBlock); //?
          }

          return mergedBlocks; // Geef de lijst met samengevoegde tijdvakken terug.
        }
      },

      calculateTimeRegistration(employee, change, exceptionTime){
        if(exceptionTime.start && exceptionTime.end){
          let date = moment().format("YYYY-MM-DD");
          let day = moment(date).format("ddd");
          let times = employee.basicSchedule[day];

          let data = {
            minutes: Number(moment(exceptionTime.end, "HH:mm").diff(moment(exceptionTime.start, "HH:mm"), "minutes")),
            minutesOverlap: 0,
            type: change.type
          };

          times.forEach(time =>{ // Calculate overlap
            let start = null;
            let end = null;
            if(exceptionTime.start < time.start && exceptionTime.end > time.start){
              start = time.start
            }
            else{
                start = exceptionTime.start
            } 

            if(exceptionTime.end > time.start ){
              if(exceptionTime.start > time.end){
                end = exceptionTime.start;
              }
              else if(exceptionTime.end > time.end){
                end = time.end
              }
              else{
                end = exceptionTime.end;
              }
            }
            else{
              end = exceptionTime.start
            }
            data.minutesOverlap = data.minutesOverlap + Number(moment(end, "HH:mm").diff(moment(start, "HH:mm"), "minutes")); 
            // console.log("data.minutesOverlap present: ", data.minutesOverlap, start, end, employee.firstName)
          })
      

          return data;
        }
        else{
          return {minutes: 0, minutesOverlap: 0, type: change.type}
        }
        
      }, 

      addScheduleException(employee){
        let uniqid = require("uniqid");
        let exception = {
          branchId: this.$store.state.activeBranch.id,
          companyId: this.$store.state.activeCompany.id,
          dateAdded: new Date(),
          reason: "extraHours",
          extra: null,
          startdate: moment().format("YYYY-MM-DD"),
          enddate: null,
          resourceId: employee.id,
          time: [{end: "", start: ""}],
          type: "present",
          year: moment().format("YYYY"),
          period: false,
          allDay: true,
          startDateDialog: false,
          endDateDialog: false,
          change: 'new',
          docId: uniqid()
        }
        this.scheduleExceptions.push(exception);
        this.timeRegistrationEmployeeInfo(employee);

      },

      changeTimeRegistrationReason(change, index){
        if(change.type == 'absence'){
          change.reason = 'leave';
        }
        else{
          change.reason = 'extraHours';
        }
        change.time = [{start: null, end: null}]
      },

      async saveScheduleExceptionsChanges(){
        // 1. Check if all required fields are filled
        let valid = true;
        await new Promise((resolve)=>{
          if(this.scheduleExceptions && this.scheduleExceptions.length > 0){
            let counter = 0
            this.scheduleExceptions.forEach(exception=>{
              if(exception.type == 'present' || (exception.type == 'absent' && !exception.allDay)){ // Check for timefields
                if(exception.time && exception.time.length > 0){
                  exception.time.forEach(time=>{
                    if(!time.start || time.start.length < 5 || !time.end || time.end.length < 5 || time.start >= time.end){
                      valid = false;
                    }
                  })
                }
                else{
                  valid = false;
                }
              }
              counter++
              if(counter == this.scheduleExceptions.length){
                resolve()
              }
            })
          }
          else{
            resolve()
          }
        })

        if(valid){
          this.timeRegistrationDialog.loading = true;
          // 2. Check if scheduleExceptions need to be removed

          const removeExceptions = this.scheduleExceptionsOriginal.filter(({ id: id1 }) => !this.scheduleExceptions.some(({ id: id2 }) => id2 === id1));
          //console.log("Need to be removed: ", removeExceptions.length)
          if(removeExceptions && removeExceptions.length > 0){
            await new Promise((resolve)=>{
              let removeCounter = 0;
              removeExceptions.forEach(exception=>{
                db.collection("schedulesExceptions").doc(exception.id).delete()
                .then(()=>{
                  removeCounter++
                  if(removeCounter == removeExceptions.length){
                    resolve()
                  }
                })
              })
            })
          }
          
          // 3. Check if scheduleExceptions need to be added
          const newScheduleExceptions = this.scheduleExceptions.filter(exception => {return exception.change == 'new'})
          if(newScheduleExceptions && newScheduleExceptions.length > 0){
            await new Promise((resolve)=>{
              let addCounter = 0;
              newScheduleExceptions.forEach(exception=>{
                db.collection("schedulesExceptions")
                .add({
                  companyId: this.$store.state.activeCompany.id,
                  branchId: this.$store.state.activeBranch.id,
                  resourceId: exception.resourceId,
                  reason: exception.reason,
                  extra: exception.extra,
                  dateAdded: new Date(),
                  startdate: exception.startdate,
                  enddate: exception.startdate,
                  year: Number(moment(exception.startdate).format("YYYY")),
                  time: _.orderBy(exception.time, ["start"], ["asc"]),
                  type: exception.type,
                  userCreated: this.$store.state.activeUserId,
                })
                .then(()=>{
                  addCounter++
                  if(addCounter == newScheduleExceptions.length){
                    resolve()
                  }
                })
              })
            })
          }
          // 4. Check if scheduleExceptions need to be changed
          let exceptionsChanged = new Array();
          if(this.scheduleExceptionsOriginal && this.scheduleExceptionsOriginal.length > 0){
            await new Promise((resolve)=>{
              let changeCounter = 0;
              this.scheduleExceptionsOriginal.forEach(exceptionOrginal=>{
                let exceptionItem = this.scheduleExceptions.find(exception => {return exception.id == exceptionOrginal.id})
                if(exceptionItem  && _.isEqual(exceptionItem, exceptionOrginal) == false){
                  exceptionsChanged.push(exceptionItem)
                }
                changeCounter++
                if(changeCounter == this.scheduleExceptionsOriginal.length){
                  resolve()
                }
              })
            })
          }

          //console.log("Need to be changed: ", exceptionsChanged.length);

          if(exceptionsChanged && exceptionsChanged.length > 0){
            await new Promise((resolve)=>{
              let updateCounter = 0;
              exceptionsChanged.forEach(exception=>{
                db.collection("schedulesExceptions").doc(exception.id)
                .set({
                  reason: exception.reason,
                  extra: exception.extra,
                  dateChanged: new Date(),
                  time: _.orderBy(exception.time, ["start"], ["asc"]),
                  type: exception.type,
                  userCreated: this.$store.state.activeUserId,
                },{ merge: true })
                .then(()=>{
                  updateCounter++
                  if(updateCounter == exceptionsChanged.length){
                    resolve()
                  }
                })
              })
            })
          }

          this.$store.dispatch('removeCalendarResourceCache', {branchId: this.$store.state.activeBranch.id, start: moment().format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD")})
          .then((response)=>{
            console.log("removeCalendarResourceCache response: ", response)
          })

          this.scheduleExceptionsOriginal = _.cloneDeep(this.scheduleExceptions);
          this.timeRegistrationDialog.loading = false;
          this.timeRegistrationDialog.show = false;
          this.fetchResources(this.calendarApi.getDate())
         
        }
        else{
          this.timeRegistrationDialog.feedback = this.$t('general.requiredField', this.$store.state.locale);
        }

      },

      minutesToHourFormat(minutes, rounding){
        let roundingNumber = rounding ? rounding : 1;
        let hours = Math.floor(minutes/60);
        let minutesLeft = (((minutes - (hours * 60)).toFixed(0))/roundingNumber).toFixed(0)*roundingNumber
        return minutesLeft > 9 ? `${hours}:${minutesLeft}` : `${hours}:0${minutesLeft}`; // Add leading zero if  minutes is less than two characters
      },

      openTimeRegistration(){
        this.timeRegistrationDialog.feedback = null;
        this.$store.state.activeBranchEmployees.forEach((employee)=>{
          this.timeRegistrationEmployeeInfo(employee)
        })

        this.timeRegistrationDialog.show = true;
      },

      removeTimeRegistration(employee, item){
        this.scheduleExceptions = this.scheduleExceptions.filter(exception => { return exception.id != item.id})
        this.timeRegistrationDialog.changes++
        this.timeRegistrationEmployeeInfo(employee)
      },

      removeTimeRegistrationTime(employee, change, index){
        change.time.splice(index, 1);
        this.timeRegistrationEmployeeInfo(employee);
      },

      addExtraTime(employee,change){
        change.time.push({end: "", start: ""})
        this.timeRegistrationEmployeeInfo(employee);
      },

      formatChangeDate(date){
        if(date){
          return moment(date).format('D-MM-YYYY');
        }
        else{
         return "";
        }
      },
      
      closeTimeRegistration(){
        this.presenceStart = false;
        this.timeRegistrationDialog.show = false;
        this.timeRegistrationDialog.employees = new Object();
        this.scheduleExceptions = _.cloneDeep(this.scheduleExceptionsOriginal);
        this.timeRegistrationDialog.changes++
      },

      checkForCacheChanges(){ // Set timer to compare Cache with bookings  
        console.log("checkForCacheChanges")
        clearInterval(this.newBookingsTimer);
        this.newBookingsTimer = setInterval(()=>{    
          this.compareCacheWithBookings(); // Trigger function that compares all bookings with cache
        }, 500);
      },

       

      generateComputedSubscriptions() {
        let vm = this;

        // Manipulate customSchedules
        let week = moment(this.calendarApi.getDate()).isoWeek();
        this.resourcesRoomReservation = this.resourcesRoomReservation.map((room) => {
          let newObjRoom = room;
          let customScheduleRoom = this.customScheduleRooms.find((customRoom) => customRoom.week === week && customRoom.resourceId === room.id);
          if(customScheduleRoom) {
            newObjRoom.customSchedule = true;
            newObjRoom.schedule = customScheduleRoom.schedule;
            return newObjRoom;
          }
          else {
            room.schedule = room.days
            return room;
          } 
        });

        // console.log("this.resourcesRoomReservation.length", this.resourcesRoomReservation.length)
        let chosenday = moment(this.calendarApi.getDate()).format("YYYY-MM-DD");

        let nameOfDay = moment(this.calendarApi.getDate()).format("ddd"); //for Slot reservations
          let numberComputed = 0; //Number to point the computedId
          this.resourcesRoomReservation.filter(
            (resourceObject) =>
              resourceObject.resourceTypeSelect === "room" &&
              (resourceObject.slotReservation || resourceObject.customSchedule && moment().format("YYYY-MM-DD") > this.chosenDateCalendar) // Ook laten zien als de het Custom Schedule True is in het verleden
          ).forEach((resourceObject) => generateSlots(resourceObject));

        // SLOT RESERVATION Reservation
        // Generates hour slots by given data from resourceObject

        function generateSlots(resourceObject) {
      
          //Generate by Calendar date
          if(resourceObject.schedule[nameOfDay]) {
            // console.log("resourceObject.days[nameOfDay]", nameOfDay, resourceObject.schedule[nameOfDay]);
            resourceObject.schedule[nameOfDay].sort((a, b) => {
              //sort small to big -  [ '13:00', '17:30', '18:00' ]
              if (a.startTime > b.startTime ) return 1;
              if (a.startTime  < b.startTime ) return -1;
              return 0;
            })
            .forEach((dayTimeBlock) =>
              generatePerBlock(dayTimeBlock,resourceObject)
            );
          }

          function generatePerBlock(dayTimeBlock, resourceObject) {
            const start = moment(chosenday + "T" + dayTimeBlock.startTime, "YYYY-MM-DDTHH:mm"); //?
            const end = moment(chosenday + "T" + dayTimeBlock.endTime, "YYYY-MM-DDTHH:mm");

            // Make a range
            const range = moment.range(start, end); //?
            // Make shallow copy from the iterable object and extract the hours
            const hours = Array.from(range.by("minutes", { excludeEnd: true, step: dayTimeBlock.duration }));

             // For Calendar purpose use below
            hours.forEach((m) => {
         
              let generatedStart = moment(m).format("YYYY-MM-DDTHH:mm");
              let generatedEnd = moment(m).add(dayTimeBlock.duration, "minutes").format("YYYY-MM-DDTHH:mm");

              //Check if Booking Already exist
              let checkBookingExist = vm.eventsObjects.find(event => event.extendedProps.type === "bookingSubscription" && event.resourceId === resourceObject.docId && event.start === generatedStart && event.end === generatedEnd);
           
              //if "bookingSubscription" not exist generate a block
              if(!checkBookingExist) {

                const extendedPropsObject = {
                  start: generatedStart,
                  end: generatedEnd,
                  resourceId: resourceObject.id,
                  id: resourceObject.id, 
                  title: dayTimeBlock.title,
                  backgroundColor: dayTimeBlock.backgroundColor + "60",
                  description: dayTimeBlock.title,
                  resourceDocId: resourceObject.id, 
                  treatmentId: dayTimeBlock.treatmentId ? dayTimeBlock.treatmentId : null,
                  allDay: data.allDay ? data.allDay: false,
                  seats: dayTimeBlock.seats,
                  subscriptions: [],
                  editable: false,
                  droppable: false,
                  type:"computedSubscription", //For static purpose
                };

                const eventObject = {
                  start: generatedStart,
                  end: generatedEnd,
                  resourceId: resourceObject.id,
                  id: resourceObject.id, 
                  title: dayTimeBlock.title,
                  backgroundColor: dayTimeBlock.backgroundColor  + "60",
                  extendedProps:extendedPropsObject,
                  allDay: data.allDay ? data.allDay: false,
                  editable: false,
                  droppable: false,
                  resourceEditable: false,
                  type:"computedSubscription",
                  computedId: numberComputed++
                };
                vm.eventsObjects.push(eventObject)
              }
            }) 
            return;
          }
        }
      },

      // Cache
      async querySnapshotFirebaseCache(navigate) {
        this.snapshotListenerCache = null;
        let chosenday = moment().format("YYYY-MM-DD");
        if (navigate) {
          chosenday = this.chosenDateCalendar;
        }
        else {
          chosenday = moment().format("YYYY-MM-DD");
        }

        this.snapshotListenerCache = await db
          .collection("resourceCache")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("branchId", "==", this.$store.state.activeBranch.id)
          .where("date", "==", chosenday)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                console.log("Cache Loaded =>", change.doc.id, change.doc.data());
                let data = change.doc.data();
                data.docId = change.doc.id;
     
                this.resourceCache.push(data);
                //Smaller then delete cache
               if(change.doc.data().date < moment().format("YYYY-MM-DD") && moment().diff(change.doc.data().date, 'days', "YYYY-MM-DD") >= 20) {
                console.log("ifstatement baby: ", change.doc.data().date)
                  db.collection("resourceCache")
                  .doc(change.doc.id)
                  .delete()
                  .catch((err) => {
                    console.error("Can't remove Cache", err)
                  })
                } 
              }
              if (change.type === "modified") {
                console.log("Modified Cache: =>", change.doc.id);
                let data = change.doc.data();
                data.docId = change.doc.id;
                let foundIndex = this.resourceCache.findIndex((docs) => docs.docId == data.docId);
                this.resourceCache[foundIndex] = data;
                // this.createCalendarRange();
              }
              if (change.type === "removed") {
                console.log("Removed Cache: =>", change.doc.id);
                let data = change.doc.data();
                data.docId = change.doc.id;
                this.resourceCache = this.resourceCache.filter((cache) => cache.docId !== data.docId);
              }
              this.bookingChanges++
            });
          });
          return 
      },

      //Merge Bookingsummary with Bookings
      async mergeEventStats(bookingSummaryId) {
        if (!bookingSummaryId) return null; 
        const bookingSummary = this.bookingsummaries.find(summary => summary.bookingSummaryId === bookingSummaryId);
        
        if(bookingSummary) {
          return bookingSummary;
        }
      
        if(!bookingSummary) {
            try {
              const doc = await db.collection("bookingsummary").doc(bookingSummaryId).get();
              return doc.exists ? doc.data() : null;
          } catch (err) {
              console.error("Error Loading BookingSummary: ", err);
              return null; 
          }
        }
      
      },

      // Cache update
      /**
       * Updates the cache for a specific resource.
       * 
       * This function performs the following steps:
       * 1. Waits for stability in the events objects to ensure all changes are processed.
       * 2. Finds the related resource and cache.
       * 3. If no cache exists, creates a new one.
       * 4. If cache exists, updates it with the latest booking information.
       * 5. Removes the cache if there are no bookings, or updates it otherwise.
       * 
       * @param {string} resourceDocId - The ID of the resource to update cache for.
       * @param {boolean} [isViaArray=false] - Indicates if the update is triggered via an array operation.
       * @returns {Promise<void>}
       * 
       * @throws {Error} If there's an issue updating the cache.
       * 
       * @example
       * // Update cache for a resource
       * await updateCache('resource123');
       * 
       * // Update cache for a resource via array operation
       * await updateCache('resource123', true);
       */
      async updateCache(resourceDocId, isViaArray) {
        // console.log("Cache Activate ", resourceDocId);

        const checkInterval = 25; // Check elke 25ms
        const maxWaitTime = 500; // Maximale wachttijd van 500ms
        let lastChangeTime = Date.now();
        let currentEvents = JSON.stringify(this.eventsObjects); //Schakel Vue2 observers uit

        const waitForStability = () => {
          return new Promise((resolve) => {
            const intervalId = setInterval(() => {
              const newEvents = JSON.stringify(this.eventsObjects);
              if (newEvents !== currentEvents) {
                currentEvents = newEvents;
                lastChangeTime = Date.now();
              } else if (Date.now() - lastChangeTime >= maxWaitTime) {
                clearInterval(intervalId);
                resolve();
              }
            }, checkInterval);
          });
        };

        // Wacht tot er geen veranderingen meer zijn in this.eventsObjects
        if(!isViaArray) {
          await waitForStability();
        }
        

        //Start code voor Cache Updates
        const resourceObject = this.resourceObjects.find((resource) => resource.id === resourceDocId);
        let relatedCache = this.resourceCache.find((cache) => cache.resourceId === resourceDocId);

        // console.log("resourceObject", resourceObject)
        // console.log("relatedCache", relatedCache)

        //Is there related Cache?
        if (!relatedCache) {
          //Cache not found that matched with resourceObject
          console.log(resourceObject.id, resourceObject.resourceTypeSelect);
          await this.createResourceCache(resourceObject.id, resourceObject.resourceTypeSelect, "NEW");
          // console.log("createResourceCache 2");
          return;
        }
        else {
          // Logica Cache gevonden voor het updaten van de cache
          let relatedEvents = this.eventsObjects.filter((event) => event.extendedProps.resourceDocId === resourceObject.id && !event.extendedProps.bookingOverride);

          let bookings = relatedEvents.map((booking) => ({
            start: moment(booking.start).format("HH:mm"),
            end: moment(booking.end).format("HH:mm"),
            bookingSummaryId: booking.bookingSummaryId || null,
            treatmentId: booking.treatmentId || null,
            bookingId: booking.id,
            swapping: typeOfBooking(booking),
            type: booking.extendedProps.type
          }));

          //Which type of Booking ?
          function typeOfBooking(booking) {
            if (booking.extendedProps.resourceType === "employee" && booking.extendedProps.type === "booking") {
              return !booking.extendedProps.preferredPractitioner;
            }
            return false;
          }

          relatedCache.bookings = bookings;
          relatedCache.timestamp = new Date();
          relatedCache.debugCache = this.debugCache;

          if (bookings.length === 0) {
            // Verwijder cache als er geen boekingen zijn
            await this.removeResourceCache(resourceObject, relatedCache);
          } else {
            // Update cache met nieuwe boekingen
            await this.updateResourceCache(relatedCache);
          }
        }
      },

async removeResourceCache(res, relatedCache) {
        try {
          const querySnapshot = await db.collection("resourceCache")
            .where("companyId", "==", this.$store.state.activeCompany.id)
            .where("branchId", "==", this.$store.state.activeBranch.id)
            .where("resourceId", "==", res.id)
            .where("date", "==", this.chosenDateCalendar)
            .get();

          const deletePromises = querySnapshot.docs.map(doc => doc.ref.delete());
          await Promise.all(deletePromises);

          console.info(res.title, "No Cache needed, Cache document deleted =>", relatedCache.docId);
          this.bookingChanges++;
        } catch (err) {
          console.error("Error deleting resourceCache =>", err);
        }
      },

async updateResourceCache(relatedCache) {
        try {
          await db.collection("resourceCache").doc(relatedCache.docId).update(relatedCache);
          console.info("Cache successfully updated... =>", relatedCache);
          this.bookingChanges++;
        } catch (err) {
          console.error("Error updating resourceCache", err);
        }
      },

      formatDate(date, format, language){
        let locale = language ? language : 'en';
        return moment(date).locale(locale).format(format)
      },

      // View Renders
      resizeCalendarZoomIn() {
        const calculatedSubstract = moment(this.slotDuration, "HH:mm:ss").subtract(5, "minutes").format("HH:mm:ss");
        //Can't be smaller then 5 minutes
        if (moment("00:00:00", "HH:mm:ss") < moment(calculatedSubstract, "HH:mm:ss")) {
          this.slotDuration = calculatedSubstract;
          this.calendarApi.setOption("slotDuration", calculatedSubstract);
          window.localStorage.setItem("timeIterationCalendar", calculatedSubstract);
        }
      },

      resizeCalendarZoomOut() {
        const calculatedAdd = moment(this.slotDuration, "HH:mm:ss").add(5, "minutes").format("HH:mm:ss");
        //Can't be bigger then 5 minutes
        if (moment("00:35:00", "HH:mm:ss") > moment(calculatedAdd, "HH:mm:ss")) {
          this.slotDuration = calculatedAdd;
          this.calendarApi.setOption("slotDuration", calculatedAdd);
          window.localStorage.setItem("timeIterationCalendar", calculatedAdd);
        }
      },

      calculateCalendarHeight() {
        const getContentBox = document.querySelector("#content-wrapper").clientHeight;
        this.calendarApi.setOption("height", getContentBox - 130) 
      },

      // HANDLERS for DAY SWTICH
      async daySwitcher(typeHandler, dateSelect) {
        this.cleanSearchCommand();
        if (document.querySelector("#timeCode")) {
          document.querySelector("#timeCode").remove();
        }

        console.log("daySwitcher", typeHandler, dateSelect)
        
        this.branchClosed = false;
        await this.$nextTick();
        this.otherDayLoading = true;

        let calcStyling = document.querySelector(".content-box")
        calcStyling.style.visibility = "hidden";

        this.eventsObjects = new Array();
        this.bookingsummaries = new Array(); //Bookingsummaries
        this.schedulesPauseExceptions = new Array();
        this.resourceCache = new Array();
        this.eventBookingen = new Array(); //For bookingen via Event EventBooking
        this.localBookingen = new Array();

        if (typeHandler === "next") {
          if (dateSelect === 1) {
            this.calendarApi.next();
          } else {
            let nextDate = moment(this.calendarApi.getDate()).add(dateSelect, 'days').format("YYYY-MM-DD");
            this.calendarApi.gotoDate(nextDate);
          }
        }
        else if (typeHandler === "prev") {
          this.calendarApi.prev();
        }
        else if (typeHandler === "selectDate") {
          this.calendarApi.gotoDate(dateSelect);
        }

        
        this.localetime = moment(this.calendarApi.getDate()).locale(this.$store.state.locale.toLowerCase()).format("dddd D MMMM YYYY");
        this.chosenDateCalendar = moment(this.calendarApi.getDate()).format("YYYY-MM-DD");
        


        this.checkTimeRegistration();

        await this.$nextTick();

        this.snapshotListenerBookings = null;
        this.snapshotListenerBookingsummary = null;
        this.snapshotListenerCache = null;

        // Check if branch closed and check for closeReason to show

        let branchClosed = this.checkIfBranchIsClosed(this.chosenDateCalendar)
        if (branchClosed.closed === true) {
          this.branchClosed = true;
          this.branchClosedReason = branchClosed.closedReason;
          calcStyling.style.visibility = "visible";
        }
        const getCalendarEvents = await this.getCalendarEventsApi(this.chosenDateCalendar,this.$store.state.activeBranch.id);

        if(getCalendarEvents){
          this.eventsObjects = getCalendarEvents.bookings;
          this.bookingsummaries = getCalendarEvents.bookingsummaries;
          this.schedulesPauseExceptions = getCalendarEvents.pauses;
       
          // console.log("Day Switcher Events BACKEND API REFETCH")
        }

        await this.$nextTick();

        await this.fetchResources();

        await this.$nextTick();   
      
        await this.$nextTick();
   
        this.querySnapshotFirebaseBooking();    
        this.querySnapshotFirebaseBookingsummary({ navigate: true })
        this.querySnapshotFirebaseCache({ navigate: true })
   
        this.createCalendarRange('renderCalendar');

        this.renderCalendar();
        await this.$nextTick();
        this.checkTimeRegistration();
        await this.getSchedulesPauseExceptions(); //TODO 
        this.generateSchedulePause(); 
        this.calendarApi.refetchEvents();

        calcStyling.style.visibility = "visible";
        this.otherDayLoading = false;
        await this.$nextTick();
        this.checkResizing(); //Calculate the Header With
       

      },

      async next(days) { // Go to Next Date in Calendar
        this.daySwitcher("next", days)
      },

      async prev() { // Go to Previous Date in Calendar
        this.daySwitcher("prev", null)
      },

      async selectDate(dateSelect) { // Go to Custom Date in Calendar
        this.daySwitcher("selectDate", dateSelect)
      },
   
      async removeSchedulePauseEvent(type, id){
        // console.log("removeSchedulePauseEvent", type, id)
        let removePause = this.eventsObjects.find(booking =>{return booking.extendedProps.schedulePauseId == id});
        
        // console.log("removePause: ", removePause)

        let pauseData = {
          start: moment(removePause.start, "YYYY-MM-DDTHH:mm").format("HH:mm"),
          end: moment(removePause.end, "YYYY-MM-DDTHH:mm").format("HH:mm"),
          bookingId: removePause.extendedProps.schedulePauseId,
          resourceDocId: removePause.extendedProps.resourceDocId,
          index: removePause.extendedProps.removePauseIndex
        }
        this.eventsObjects = this.eventsObjects.filter(booking =>{return booking.extendedProps.schedulePauseId != id})

        // Save Pause exeption to database
        
        let resourcePauseException = this.schedulesPauseExceptions.find(exeption => {return exeption.resourceId == removePause.extendedProps.resourceDocId});
        
        // console.log("resourcePauseException: ", resourcePauseException)
        
        let removedPause = {
          companyId: this.$store.state.activeCompany.id,
          branchId: this.$store.state.activeBranch.id,
          date: this.chosenDateCalendar,
          resourceId: removePause.extendedProps.resourceDocId,
          removedPauses: [pauseData.index],
          week: moment(this.chosenDateCalendar).isoWeek(),
          year: moment(this.chosenDateCalendar).year(),
          updated: new Date()
        };
        
        if(!resourcePauseException || resourcePauseException == undefined){
          await db.collection("schedulesPauseExceptions").add(removedPause).then(docRef=>{
            removedPause.id = docRef.id
          })
          this.schedulesPauseExceptions.push(removedPause)
        }
        else{
          resourcePauseException.removedPauses = resourcePauseException.removedPauses.concat([pauseData.index])
          await db.collection("schedulesPauseExceptions").doc(resourcePauseException.id).set({
            removedPauses: resourcePauseException.removedPauses,
            updated: new Date()
          }, {merge: true})
        }

        this.calendarApi.refetchEvents();
        return
      },
      
      async eventDrop(eventDropInfo) { // Droppable event data

        // console.log("eventDropInfo: ", eventDropInfo)
      
        
        let event = eventDropInfo.event;
        let oldEvent = eventDropInfo.oldEvent;
        
        // new resource (useful to know if event dropped into a new resource or into the same old one)
        let newRes =
          eventDropInfo.newResource !== null
            ? this.resourceObjects.find((res) => res.id === eventDropInfo.newResource._resource.id) //id for eventdrop is the same as resourceDocID
            : this.resourceObjects.find((res) => res.id === eventDropInfo.event.extendedProps.resourceDocId); //push old resource if needed

        let oldRes =
          eventDropInfo.oldResource !== null
            ? this.resourceObjects.find((res) => res.id === eventDropInfo.oldResource._resource.id)
            : this.resourceObjects.find((res) => res.id === eventDropInfo.event.extendedProps.resourceDocId);

            console.log("res",  eventDropInfo)
           
        //Calculate Difference Time range
        let showClientConfirm = moment(eventDropInfo.event.end).diff(moment(eventDropInfo.oldEvent.end), 'minutes',true);

        //Update changed Resources object for Cache
        this.adjustedResources = new Array();
        this.adjustedResources.push(newRes);
        oldRes.id !== newRes.id && this.adjustedResources.push(oldRes);

        // check dropping rules between resources
        let restriction = this.dropRestriction(eventDropInfo, newRes); // true or false

        if (restriction) { // Event drop is Restricted
          eventDropInfo.revert();
          this.notify("Hierheen verplaatsen niet mogelijk", "error");
          return;
        }

        if (
          event.extendedProps.type == "pause" ||
          event.extendedProps.type == "meeting" ||
          event.extendedProps.type == "task" ||
          event.extendedProps.type == "note" ||
          event.extendedProps.type == "occupied" ||
          event.extendedProps.type == "lead"
        ) {
          if(event.id != null && event.id != 'null'){ // Existing booking
            await db.collection("bookingen")
            .doc(event.id)
            .update({
              start: moment(event.start).format("YYYY-MM-DD" + "T" + "HH:mm"),
              end: moment(event.end).format("YYYY-MM-DD" + "T" + "HH:mm"),
              resourceDocId: newRes.id, //New Resource Id
            })
            .catch((error) => { // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
            
          }
          else{ // Schedule Pause Event
            let uniqid = require("uniqid");
            let uniqidBooking = uniqid();
            await db.collection("bookingen")
            .add({
              resourceDocId: event.extendedProps.resourceDocId,
              start: moment(event.start).format("YYYY-MM-DD" + "T" + "HH:mm"),
              end: moment(event.end).format("YYYY-MM-DD" + "T" + "HH:mm"),
              dateBooking: this.chosenDateCalendar,
              created: moment().format("YYYY-MM-DDTHH:mm"),
              companyId: this.$store.state.activeCompany.id,
              branchId: this.$store.state.activeBranch.id,
              title: event.extendedProps.title,
              clientname:  event.extendedProps.clientname,
              description: "",
              type: event.extendedProps.type,
              extra:{
                type: 'pause'
              },
              eventColor: "rgb(204, 204, 204)",
              uniqIdStamp: uniqidBooking,
              modifiedStamp: new Date(),
              bookingOverride: false,
              leadExtra:null // Post lead info if lead
            })
            .catch((error) => { // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
            await this.removeSchedulePauseEvent('replace', event.extendedProps.schedulePauseId);// Remove existing Event
          }
          // if(event.extendedProps.type =='pause'){
          //   console.log("generateSchedulePause na drop")
          //   this.generateSchedulePause()
          // }
          

          this.updateCache(newRes.id); // Update Cache

          // console.log("DROP");

          if (event.extendedProps.type == "task") {
            this.notify(this.$t('calendar.taskChangedSuccessfull', this.$store.state.locale), "success");
          } else if (event.extendedProps.type == "meeting") {
            this.notify(this.$t('calendar.meetingChangedSuccessfull', this.$store.state.locale), "success");
          } else if (event.extendedProps.type == "occupied") {
            this.notify(this.$t('calendar.occcupiedChangedSuccessfull', this.$store.state.locale), "success");
          } else if (event.extendedProps.type == "lead") {
            this.notify(this.$t('calendar.leadChangedSuccessfull', this.$store.state.locale), "success");
          } else {this.$t('calendar.itemsChangedSuccessfull', this.$store.state.locale)
            this.notify(this.$t('calendar.pauseChangedSuccessfull', this.$store.state.locale), "success");
          }
        
        if (event.extendedProps.leadExtra && event.extendedProps.leadExtra.leadId) { // Check if booking had ID of Lead Doc
          let leadData = null;
          db.collection("leads")
          .doc(event.extendedProps.leadExtra.leadId)
          .get()
          .then((doc) => {
            leadData = doc.data();
            leadData.id = doc.id;
            if (leadData.actions && leadData.actions.length > 0) {
              leadData.actions.forEach((action) => {
                if (action.bookingId == event.id) {
                  action.date = Number(moment(event.start).format("x"));
                }
              });
            }
          })
          .then(() => {
            db.collection("leads")
            .doc(event.extendedProps.leadExtra.leadId)
            .set(
              {
                updated: new Date(),
                actions: leadData.actions,
              },
              { merge: true }
            )
            .catch((error) => {
              console.error("Error updating lead: ", error);
            });
          })
          .catch((error) => {
            console.error("Error bij laden leaddata: ", error);
          });
        }
        } 
        else if (event.extendedProps.type == "booking" || event.extendedProps.type == "roombooking") {
      
          this.groupMove("drop", newRes, oldRes, event, oldEvent).then(async(value)=>{
            //console.log("Groupmove then done: ", value)
            await db.collection("bookingen")
            .doc(event.id)
            .update({
              start: moment(event.start).format("YYYY-MM-DD" + "T" + "HH:mm"),
              end: moment(event.end).format("YYYY-MM-DD" + "T" + "HH:mm"),
              resourceDocId: newRes.id, //New Resource Id
            })
            .then(() => {
              //Update Cache
              this.adjustedResources.forEach((adjustedResource) => this.updateCache(adjustedResource.id));

              if (event.extendedProps.type == "booking") {
                if(value == 'individual'){
                  this.notify(this.$t('calendar.itemsChangedSuccessfull', this.$store.state.locale), "success");
                }
                else{
                  this.notify(this.$t('calendar.appointmentItemsChangedSuccessfull', this.$store.state.locale), "success");
                }
              } 
              else if (event.extendedProps.type == "roombooking") {
                this.notify(this.$t('calendar.roomChangedSuccessfull', this.$store.state.locale), "success");
              }
              
              // Show Modal to Inform Client or Send Email (based on branch settings)
              if(this.$store.state.activeBranch.calendarSettings.informClientCalendarModal){
                if(showClientConfirm > 10 || showClientConfirm < -10 ) {
                  bus.$emit('showInformClientModal', 'appointmentChanges', this.$store.state.activeBranch.id, event.extendedProps.bookingId, event.extendedProps.clientId, null)
                }
              }
              else{
                if(this.$store.state.activeBranch.calendarSettings.informClientCalendar){
                  
                  axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, {doc: informClientCalendarDocId, clientId: event.extendedProps.clientId, branchId: this.$store.state.activeBranch.id, type: "appointmentChanges"},
                  {
                    timeout: 5000,
                    headers: {
                      'Content-Type': 'application/json'
                    }
                   }
                  )
                    .then((response) => {
                      if (response) {
                        //Email succesfully sent!
                      }
                    })
                    .catch((error) => {
                      console.error("Error sending timeChanged email", error.message);
                    });
                }
              }
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });

            let history = {
              bookings: new Array(),
              role: this.userRole,
              timestamp: new Date(),
              user: this.userFirstName + " " + this.userLastName,
            };

            // Update Bookingsummary with start & end
            if (event.extendedProps.bookingId) {
              let sameSummaryEvents = new Array();
              let timeChanged = moment(event.start).diff(moment(oldEvent.start), 'minutes');
              // ("timeChanged 1: ", timeChanged)
              this.eventsObjects.forEach((item) => {

                console.log(item)

                if (item.id == event.id) {
                  // Set new start and end for the ChangeBooking E-mail & Bookings Summary
                  item.start = moment(event.start).format("YYYY-MM-DD" + "T" + "HH:mm");
                  item.end = moment(event.end).format("YYYY-MM-DD" + "T" + "HH:mm");

                  // Set 'old' start and end for the ChangeBooking E-mail
                  item.previousStart = moment(oldEvent.start).format("YYYY-MM-DD" + "T" + "HH:mm");
                  item.previousEnd = moment(oldEvent.end).format("YYYY-MM-DD" + "T" + "HH:mm");
                }
                else{
                  item.previousStart = moment(item.start).subtract(timeChanged, 'minutes').format("YYYY-MM-DD" + "T" + "HH:mm")
                  item.previousEnd = moment(item.end).subtract(timeChanged, 'minutes').format("YYYY-MM-DD" + "T" + "HH:mm")
                }
                if (item.extendedProps.bookingId == event.extendedProps.bookingId && !item.extendedProps.deleted) {

                  let oldStart = item.id != event.id ? moment(item.start).subtract(timeChanged, 'minutes').format("YYYY-MM-DD" + "T" + "HH:mm") : item.previousStart;
                  let oldEnd = item.id != event.id ? moment(item.end).subtract(timeChanged, 'minutes').format("YYYY-MM-DD" + "T" + "HH:mm") : item.previousEnd;
                  let newResourceDocId = item.extendedProps.resourceType == event.extendedProps.resourceType ? newRes.id  : item.extendedProps.resourceDocId;
                  let oldResourceDocId = item.extendedProps.resourceType == event.extendedProps.resourceType ? oldRes.id  : item.extendedProps.resourceDocId;

                  if(value == 'groupmove' || item.id == event.id){
                    if(timeChanged != 0 || newResourceDocId != oldResourceDocId){ // Add only to history if something changes
                      history.bookings.push({
                        deleted: false,
                        bookingId: item.id,
                        newStart: item.start,
                        oldStart: oldStart,
                        newEnd: item.end,
                        oldEnd: oldEnd,
                        newResourceDocId: newResourceDocId,
                        oldResourceDocId: oldResourceDocId, 
                      });
                    }
                  }
                  sameSummaryEvents.push(item);         
                }
              });
              
              history.bookings = _.sortBy(history.bookings, ["newStart"]["newResourceDocId"], ["asc"], ["asc"]);
              sameSummaryEvents = _.sortBy(sameSummaryEvents, ["start"], ["asc"]);

              let newStart = sameSummaryEvents[0].start;
              let newEnd = sameSummaryEvents[sameSummaryEvents.length - 1].end;
              let previousStart = sameSummaryEvents[0].previousStart ? sameSummaryEvents[0].previousStart : sameSummaryEvents[0].start;
              let previousEnd = sameSummaryEvents[sameSummaryEvents.length - 1].previousEnd ? sameSummaryEvents[sameSummaryEvents.length - 1].previousEnd : sameSummaryEvents[sameSummaryEvents.length - 1].end;
              
              console.log("res",  eventDropInfo.newResource)


              let employeeEvents = this.eventsObjects.filter((calendarEvent) => { return calendarEvent.extendedProps.bookingId == event.extendedProps.bookingId && calendarEvent.extendedProps.resourceType == 'employee' && calendarEvent.id != event.id })
          
              if(eventDropInfo.newResource!== null && event.extendedProps.resourceType == 'employee'){
                console.log("Event:", event)
                employeeEvents.push(
                  {
                    resourceId: eventDropInfo.newResource._resource.id
                  })
              }
              console.log("employeeEvents: ", employeeEvents)
              let bookingEmployees = employeeEvents != undefined && employeeEvents.length > 0 ? [...new Set(employeeEvents.map((element) => element.resourceId)),] : new Array();

              console.log("bookingEmployees: ", bookingEmployees)
              // Update Bookingsummary
              await db.collection("bookingsummary")
              .doc(event.extendedProps.bookingId)
              .update({
                start: newStart,
                end: newEnd,
                previousStart: previousStart,
                previousEnd: previousEnd,
                employees: bookingEmployees,
                history: firebase.firestore.FieldValue.arrayUnion(history),
              })
              .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });

              // Update Lead Doc with changed time
              if (event.extendedProps.leadExtra && event.extendedProps.leadExtra.leadId) {
                // Check if booking had ID of Lead Doc
                let leadData = null;
                db.collection("leads")
                .doc(event.extendedProps.leadExtra.leadId)
                .get()
                .then((doc) => {
                  leadData = doc.data();
                  leadData.id = doc.id;
                  if (leadData.actions && leadData.actions.length > 0) {
                    leadData.actions.forEach((action) => {
                      if (action.bookingSummaryId && action.bookingSummaryId == event.extendedProps.bookingId) {
                        action.date = Number(moment(newStart).format("x"));
                      }
                    });
                  }
                })
                .then(() => {
                  db.collection("leads")
                  .doc(event.extendedProps.leadExtra.leadId)
                  .set(
                    {
                      updated: new Date(),
                      actions: leadData.actions,
                    },
                    { merge: true }
                  )
                  .catch((error) => {
                    console.error("Error updating lead: ", error);
                  });
                })
                .catch((error) => {
                  console.error("Error bij laden leaddata: ", error);
                });
              }
            }
          })
        }

        else if (event.extendedProps.type == "eventbooking") {
          eventDropInfo.revert();
          const eventSummaryId = event.extendedProps.eventSummaryId;

          let bookingid = event.extendedProps.bookingId; // Bookingsummary DocId
          let passEvent = event.id; // Booking DocId
          let eventDate = moment(event.start).format("YYYY-MM-DD");

          this.eventBookingen = new Array(); //Used for Props
          this.eventsObjects.forEach((o) => {
            if (event.extendedProps.bookingId == o.extendedProps.bookingId) {
              this.eventBookingen.push({
                id: o.extendedProps.resourceDocId,
                subId: o.extendedProps.id,
              });
            }
          });

          this.editEvent(eventSummaryId, bookingid, passEvent, eventDate);
        }        
        this.individualMove = false;
        this.checkBookingEditStatus()
      },

      //Event drop uti validate type dropped resource
      dropRestriction(eventDropInfo, newRes) {
        const newResType = newRes.resourceTypeSelect;
        let restrict = false;
        //if event is dropped within a new resource

        if (eventDropInfo.oldResource !== null) {
          //Pause from employee to another
          if (eventDropInfo.event.extendedProps.type === "pause") restrict = true;
          if (eventDropInfo.event.extendedProps.type === "note") restrict = true;

          const oldResType = eventDropInfo.oldResource.extendedProps.resourceTypeSelect;
          const EMP = "employee";
          const DEV = "device";
          const ROOM = "room";
          const NOTES = "notes";
          //deny dropping between 2 types resources
          const deny = (type1, type2) => {
            if (newResType == type1 && oldResType == type2) restrict = true;
          };
          //restrict dropping combinations
          deny(EMP, DEV);
          deny(DEV, EMP);
          deny(EMP, ROOM);
          deny(ROOM, EMP);
          deny(DEV, ROOM);
          deny(ROOM, DEV);
          deny(NOTES, DEV);
          deny(DEV, NOTES);
          deny(NOTES, ROOM);
          deny(ROOM, NOTES);
          deny(NOTES, EMP);
          deny(EMP, NOTES);
        }

        return restrict;
      },

      async groupMove(type, newRes, oldRes, event, oldEvent){
        console.log("Groupmove: ", this.individualMove)
        if(this.individualMove){
          return "individual"
        }
        else{        
          let bookingSummaryId = event.extendedProps.bookingSummaryId;
          let timeChanged = moment(event.start).diff(moment(oldEvent.start), 'minutes');

          this.eventsObjects.forEach(eventObject=>{
            
            if(eventObject.bookingSummaryId == bookingSummaryId && eventObject.id != event.id){ // Use only events that have the same summary ID
           
              eventObject.start = moment(eventObject.start).add(timeChanged, 'minutes').format("YYYY-MM-DD" + "T" + "HH:mm")
              eventObject.end = moment(eventObject.end).add(timeChanged, 'minutes').format("YYYY-MM-DD" + "T" + "HH:mm")
            
              if (timeChanged != 0 || eventObject.resourceId != newRes.id) { 
                // Check if anything has changed for this event
                if(eventObject.extendedProps.resourceType ==  event.extendedProps.resourceType){ // Check if event type is the same
                  eventObject.extendedProps.resourceDocId = newRes.id;
                  eventObject.resourceId = newRes.id;  
                  
                }
                if(type == "drop"){ // Update on drag may be used later (no update on drag)
                  db.collection("bookingen")
                  .doc(eventObject.id)
                  .update({
                    start: eventObject.start,
                    end: eventObject.end,
                    resourceId: eventObject.resourceId,
                    resourceDocId: eventObject.resourceId
                  })
                  .catch((error) => {
                    console.error("Error updating booking (cluster drop): ", error);
                  });
                }
              }
                
            }
          });   
          return "groupmove";
        }
      },

      // Cluster bookings are automaticaly dropped with the same range of the dropped event
      clusterDrop(clusteredBooking, newRes, timeChanged, event, oldEvent, getBookingsummaryID, oldRes) {
        
        let oldBookings = _.cloneDeep(clusteredBooking);
        oldBookings[0].start = moment(oldEvent.start).format("YYYY-MM-DD" + "T" + "HH:mm");
        oldBookings[0].end = moment(oldEvent.end).format("YYYY-MM-DD" + "T" + "HH:mm");

        //filter cluster .. dropped event must be removed from cluster
        let events = clusteredBooking.filter((booking) => booking.id !== event.id);
        //get first moved event and push it into new cluster bookings array
        let firstEvent = clusteredBooking.filter((booking) => booking.id == event.id)[0];
        let newClusterBookings = [];

        newClusterBookings.push(firstEvent);

        events.forEach((selectedEvent) => {
          // Change ResourceId only if equal to the old ResourceId (Important when dragging between resources)
          let newResId = selectedEvent.resourceId == oldRes.id ? newRes.id : selectedEvent.resourceId;

          // Change start and end time depends on the time change
          selectedEvent.start = moment(new Date(selectedEvent.start).setMilliseconds(timeChanged)).format("YYYY-MM-DD" + "T" + "HH:mm");
          selectedEvent.end = moment(new Date(selectedEvent.end).setMilliseconds(timeChanged)).format("YYYY-MM-DD" + "T" + "HH:mm");

          // Push current cluster event after change start and end time
          newClusterBookings.push(selectedEvent);

          // Add loading effect in the calendar
          this.localBookingen.forEach((booking) => {
            if (booking.id === selectedEvent.id) {
              booking.setExtendedProp("inProgress", true);
            }
          });

          // Update only if Time or Resource is changed
          if (timeChanged > 0 || newResId != oldRes.id) {
            db.collection("bookingen")
            .doc(selectedEvent.id)
            .update({
              start: selectedEvent.start,
              end: selectedEvent.end,
              resourceId: parseInt(newResId),
            })
            .then(() => {
              this.notify("Afspraak succesvol aangepast!", "success");
            })
            .catch((error) => {
              console.error("Error updating booking (cluster drop): ", error);
            });
          }
        });

        // Re-sort clusters after start and en time changed
        newClusterBookings = _.sortBy(newClusterBookings, ["start"], ["asc"]);
        oldBookings = _.sortBy(oldBookings, ["start"], ["asc"]);

        this.updateSummaryHistory(newClusterBookings, oldBookings, oldRes);

        let newStart = newClusterBookings[0].start; //first event start
        let newEnd = newClusterBookings[newClusterBookings.length - 1].end; //last event end

        let startIndex = moment(newStart).format("HH:mm");
        let endIndex = moment(newEnd).format("HH:mm");

        // Dropped event out of calendar range from the top
        if (startIndex < this.minTime) {
          this.minTime = startIndex;
        }
        if (endIndex > this.maxTime) {
          this.maxTime = endIndex;
        }

        // Update bookingsummary new cluster start and end time
        db.collection("bookingsummary")
        .doc(getBookingsummaryID)
        .update({
          start: newStart,
          end: newEnd,
        })
        .catch((error) => {
          console.error("Error updating bookingsummary (cluster drop): ", error);
        });
      },

      highlightAllRelatedEvents(event, status){
        const relatedEvents = document.getElementsByClassName(`bsid-${event.bookingId}`);
        const relatedEventsBody = document.getElementsByClassName(`fc-timegrid-body`);
        const relatedEventsCols = document.getElementsByClassName(`fc-timegrid-col-events`);

        if(status && status == 'show'){
          if(relatedEventsBody && relatedEventsBody.length > 0){
            relatedEventsBody.forEach(relatedEventBody =>{
              relatedEventBody.classList.add("auto-z-index");
            })
          }

          if(relatedEventsCols && relatedEventsCols.length > 0){
            relatedEventsCols.forEach(relatedEventCol =>{
              relatedEventCol.classList.add("auto-z-index");
            })
          }

          if(relatedEvents && relatedEvents.length > 0){
            
            relatedEvents.forEach(relatedEvent =>{
              relatedEvent.classList.add("highlight-event");
            })
          }
        }
        else{
          if(relatedEventsBody && relatedEventsBody.length > 0){
            relatedEventsBody.forEach(relatedEventBody =>{
              relatedEventBody.classList.remove("auto-z-index");
            })
          }

          if(relatedEventsCols && relatedEventsCols.length > 0){
            relatedEventsCols.forEach(relatedEventCol =>{
              relatedEventCol.classList.remove("auto-z-index");
            })
          }

          if(relatedEvents && relatedEvents.length > 0){
            relatedEvents.forEach(relatedEvent =>{
              relatedEvent.classList.remove("highlight-event");
            })
          }
        }

        
      },




           
      eventClick(eventClickInfo) { // Click on a Booking / Event / Pause
        if (this.clientNameClicked) {
          this.clientNameClicked = false;
          return;
        }       

        let event = eventClickInfo.event;
        let type = eventClickInfo.event.extendedProps.type;
        this.selectedEventObject = event;
        this.selectedEvent.eventType = event.extendedProps.type;
        this.selectedEvent.clientname = event.extendedProps.clientname;
        this.selectedEvent.title = event.extendedProps.title;

        this.selectedEvent.eventID = event.id;
        this.selectedEvent.eventStart = moment(event.start).format("YYYY-MM-DD" + "T" + "HH:mm");
        this.selectedEvent.eventEnd = moment(event.end).format("YYYY-MM-DD" + "T" + "HH:mm");
        this.selectedEvent.eventDateBooking = moment(event.start).format("YYYY-MM-DD");
        this.selectedEvent.bookingId = event.extendedProps.bookingId;
        this.selectedEvent.leadExtra = event.extendedProps.leadExtra ? event.extendedProps.leadExtra : null;
        this.selectedEventOriginal = _.cloneDeep(this.selectedEvent);
        
        if (type == "booking") {
          bus.$emit("editBooking", this.selectedEvent);
          this.highlightAllRelatedEvents(this.selectedEvent, 'show')
          this.checkUserActivity = false;
          this.hideBubble();
        }

        if (type == "eventbooking") {
          const eventSummaryId = event.extendedProps.eventSummaryId;

          let bookingid = event.extendedProps.bookingId; // Bookingsummary DocId
          let passEvent = event.id; // Booking DocId
          let eventDate = moment(event.start).format("YYYY-MM-DD");

          this.eventBookingen = new Array(); // Used for Props
          this.localBookingen.forEach((o) => {
            if (event.extendedProps.bookingId == o.extendedProps.bookingId) {
              this.eventsObjects.push({
                id: o.extendedProps.resourceDocId,
                subId: o.extendedProps.id,
              });
            }
          });
          this.editEvent(eventSummaryId, bookingid, passEvent, eventDate);
        }

        if (type == "roombooking") {
          let resourcesData = this.grouppedRoomsDevices.find((resource)=> resource.id == event.extendedProps.resourceDocId)
          this.selectedResource = (resourcesData && resourcesData != undefined) ? resourcesData :  new Object();

          let passEvent = event.id;

          let descriptionEvent = event.extendedProps.description;
          let bookingoverrideEvent = event.extendedProps.bookingOverride;

          this.selectedStart = moment(event.start).format("HH:mm");
          this.selectedEnd = moment(event.end).format("HH:mm");
          this.selectRoom = event.extendedProps.roomName;
          let eventIntel = _.cloneDeep(event);

          let selectedRoombooking = {
            start: this.selectedStart,
            end: this.selectedEnd,
            room: this.selectRoom,
            eventId: passEvent,
            description: descriptionEvent,
            bookingOverride: bookingoverrideEvent,
            eventData: eventIntel,
            resourceDocIdRoom: eventIntel.extendedProps.resourceDocId,
            type: this.selectedEvent.eventType,
          };
          bus.$emit("editRoomBooking", selectedRoombooking, event.extendedProps.resourceId);
        }

        if (type == "lead") {
          if(this.viewType != 'scheduleLead'){
            bus.$emit("editLead", "calendar", event.extendedProps.leadExtra.leadId);
          }
          else{
            this.notify("Er wordt al een lead bewerkt", "error");
          }
        }

        if (type == "pause" || type == "occupied" || type == "meeting" || type == "task" || type == "note") {
          if (this.taskProgressClicked) {
            this.taskProgressClicked = false;
            return;
          }
          else {
            if(event.id != null && event.id != 'null'){
              this.editPause(event.id, 'database');
            }
            else{
              if(type == 'pause'){
                let pauseEvent = {
                  id: null,
                  start: event.start,
                  end: event.end,
                  bookingOverride: false,
                  branchId: this.$store.state.activeBranch.id,
                  companyId: this.$store.state.activeCompany.id,
                  type: event.extendedProps.type,
                  title: event.extendedProps.title,
                  description: "",
                  extra:{
                    type: 'pause'
                  },
                  eventColor: event.extendedProps.eventColor,
                  leadExtra: null,
                  resourceDocId: event.extendedProps.resourceDocId,
                  clientname: event.extendedProps.clientname,
                  created: event.extendedProps.created,
                  dateBooking: event.extendedProps.dateBooking,
                  modifiedStamp: event.extendedProps.modifiedStamp,
                  uniqIdStamp: event.extendedProps.uniqIdStamp,
                  schedulePauseId: event.extendedProps.schedulePauseId
                }
                this.editPause(pauseEvent, 'schedule');
              }
            }
          }
        }

        if(type == "computedSubscription"){ // Open popup met eventinformatie zonder inschrijving. Meegeven dat het een computed subscription is.
          this.openSubscription('computed', eventClickInfo.event.extendedProps, null);
        }
        if(type == "bookingSubscription"){ // Open popup met eventinformatie zonder inschrijving. Meegeven dat het een booking subscription is (Doc met informatie is aangemaakt in bookingen collection).
          this.openSubscription('booking', eventClickInfo.event.extendedProps, eventClickInfo.event.id);
        }
        this.dialogOpen = true;
      },

      async eventResize(eventResizeInfo) {
        let event = eventResizeInfo.event;
        let oldEvent = eventResizeInfo.oldEvent;
        let type = event.extendedProps.type;
        let EveNewStart = moment(event.start).format("YYYY-MM-DD" + "T" + "HH:mm");
        let EveNewEnd = moment(event.end).format("YYYY-MM-DD" + "T" + "HH:mm");

        // Calculate Difference Time range
        let showClientConfirm = moment(eventResizeInfo.event.end).diff(moment(eventResizeInfo.oldEvent.end), 'minutes',true);
        let vm = this;
        if (type == "pause" || type == "meeting" || type == "task" || type == "occupied" || type == "lead") {
          if(event.id != null && event.id != 'null'){ // Existing booking
            await db.collection("bookingen")
            .doc(event.id)
            .update({
              start: EveNewStart,
              end: EveNewEnd,
            })
            .catch((error) => { // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
            
          }
          else{ // Schedule pause
            let uniqid = require("uniqid");
            let uniqidBooking = uniqid();

            await db.collection("bookingen")
            .add({
              resourceDocId: event.extendedProps.resourceDocId,
              start: EveNewStart,
              end: EveNewEnd,
              dateBooking: this.chosenDateCalendar,
              created: moment().format("YYYY-MM-DDTHH:mm"),
              companyId: this.$store.state.activeCompany.id,
              branchId: this.$store.state.activeBranch.id,
              title: event.extendedProps.title,
              clientname:  event.extendedProps.clientname,
              description: "",
              type: event.extendedProps.type,
              extra: event.extendedProps.extra,
              eventColor: "rgb(204, 204, 204)",
              uniqIdStamp: uniqidBooking,
              modifiedStamp: new Date(),
              bookingOverride: false,
              leadExtra:null // Post lead info if lead
            })
            .catch((error) => { // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
            if(event.extendedProps.schedulePauseId){
              await this.removeSchedulePauseEvent('replace', event.extendedProps.schedulePauseId);// Remove existing Event
            }
          }
          
       
          //Update Cache
          this.updateCache(eventResizeInfo.event.extendedProps.resourceDocId);
          if (type == "task") {
            this.notify(this.$t('calendar.taskChangedSuccessfull', this.$store.state.locale), "success");
          } else if (type == "meeting") {
            this.notify(this.$t('calendar.meetingChangedSuccessfull', this.$store.state.locale), "success");
          } else if (type == "occupied") {
            this.notify(this.$t('calendar.occcupiedChangedSuccessfull', this.$store.state.locale), "success");
          } else if (type == "lead") {
            this.notify(this.$t('calendar.leadChangedSuccessfull', this.$store.state.locale), "success");
          } else {
            this.notify(this.$t('calendar.pauseChangedSuccessfull', this.$store.state.locale), "success");
          }
       
          
        } 
        else if (type == "booking" || type == "roombooking") {
          let getBookingsummaryID = this.eventsObjects.find((booking) => booking.id === event.id).bookingSummaryId;

          let newStart = moment(EveNewStart).format("YYYY-MM-DD" + "T" + "HH:mm");
          let newEnd = moment(EveNewEnd).format("YYYY-MM-DD" + "T" + "HH:mm");
          let oldStart = moment(eventResizeInfo.prevEvent).format("YYYY-MM-DD" + "T" + "HH:mm");
          let oldEnd = moment(eventResizeInfo.prevEvent).format("YYYY-MM-DD" + "T" + "HH:mm");

          let history = {
            bookings: new Array(),
            role: this.userRole,
            timestamp: new Date(),
            user: this.userFirstName + " " + this.userLastName,
          };

          history.bookings.push({
            deleted: false,
            bookingId: event.id,
            newStart: newStart,
            oldStart: oldStart,
            newEnd: newEnd,
            oldEnd: oldEnd,
            newResourceDocId: event.extendedProps.resourceDocId,
            oldResourceDocId: event.extendedProps.resourceDocId,
          });
          // console.log("event extended", event.extendedProps);
          // console.log("getBookingsummaryID", getBookingsummaryID);
          // console.log("History", history);

          let sameSummaryEvents = new Array();
          this.eventsObjects.forEach((item) => {
            if (item.id == event.id) {
              // Set new start and end for the ChangeBooking E-mail & Bookings Summary
              item.start = moment(event.start).format("YYYY-MM-DD" + "T" + "HH:mm");
              item.end = moment(event.end).format("YYYY-MM-DD" + "T" + "HH:mm");

              // Set 'old' start and end for the ChangeBooking E-mail
              item.previousStart = moment(oldEvent.start).format("YYYY-MM-DD" + "T" + "HH:mm");
              item.previousEnd = moment(oldEvent.end).format("YYYY-MM-DD" + "T" + "HH:mm");
            }
            if (item.extendedProps.bookingId == event.extendedProps.bookingId && !item.extendedProps.deleted) {
              sameSummaryEvents.push(item);
            }
          });

          sameSummaryEvents = _.sortBy(sameSummaryEvents, ["start"], ["asc"]);

          let newStartSummary = sameSummaryEvents[0].start;
          let newEndSummary = sameSummaryEvents[sameSummaryEvents.length - 1].end;
          let previousStart = sameSummaryEvents[0].previousStart ? sameSummaryEvents[0].previousStart : sameSummaryEvents[0].start;
          let previousEnd = sameSummaryEvents[sameSummaryEvents.length - 1].previousEnd ? sameSummaryEvents[sameSummaryEvents.length - 1].previousEnd : sameSummaryEvents[sameSummaryEvents.length - 1].end;
          console.log("history: ", history)
          

          //Bookingen
          await db.collection("bookingen")
            .doc(event.id)
            .update({
              start: EveNewStart,
              end: EveNewEnd,
            })
            .then(() => {

              console.log(eventResizeInfo.event.extendedProps.resourceDocId)
              //Update Cache
              this.updateCache(eventResizeInfo.event.extendedProps.resourceDocId);

              // Show Modal to Inform Client or Send Email (based on branch settings)             
              if(this.$store.state.activeBranch.calendarSettings.informClientCalendarModal){
                if(showClientConfirm > 10 || showClientConfirm < -10 ) {
                  bus.$emit('showInformClientModal', 'appointmentChanges', this.$store.state.activeBranch.id, event.extendedProps.bookingId, event.extendedProps.clientId, null)
                }
              }
              else{
                if(this.$store.state.activeBranch.calendarSettings.informClientCalendar){
                  axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, {doc: informClientCalendarDocId, branchId: this.$store.state.activeBranch.id, type: 'appointmentChanges' }, {
                    timeout: 5000,
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  })
                    .then((response) => {
                      if(response){ } // Email succesfully sent! 
                    })
                    .catch((error) => {
                      console.error("Error sending confirmchangeMail email", error.message);
                    });
                }
              }
              vm.notify(this.$t('calendar.appointmentChangedSuccessfull', this.$store.state.locale), "success");
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });

          // Bookingsummary
          await db.collection("bookingsummary")
            .doc(getBookingsummaryID)
            .update({
              start: newStartSummary,
              end: newEndSummary,
              previousStart: previousStart,
              previousEnd: previousEnd,
              history: firebase.firestore.FieldValue.arrayUnion(history),
            })
            .catch(function (error) { // The document probably doesn't exist. 
              console.error("Error updating document: ", error);
            }); 
        }

        if (event.extendedProps.type == "eventbooking") {
          eventResizeInfo.revert();
          let eventSummaryId = event.extendedProps.eventSummaryId;
          // console.log("event summary", event.extendedProps.eventSummaryId);JOE

          let bookingid = event.extendedProps.bookingId; //Is bookingsummaryId
          let passEvent = event.id; //Is losse bookingId
          let eventDate = moment(event.start).format("YYYY-MM-DD");

          this.eventBookingen = new Array(); //Used for Props
          this.localBookingen.forEach((o) => {
            if (event.extendedProps.bookingId == o.extendedProps.bookingId) {
              this.eventsObjects.push({
                id: o.extendedProps.resourceDocId,
                subId: o.extendedProps.id,
              });
            }
          });

          this.editEvent(eventSummaryId, bookingid, passEvent, eventDate);
        }

        // this.autorefresh();
      },
       
      //Select range in resource
      select(selectedBlock, e) {

        // console.log("selectedBlock", selectedBlock);

        let past = null;
        if(selectedBlock){
          past = moment(selectedBlock.start).format("YYYY-MM-DDTHH:mm") < moment().subtract(2, 'hours').format("YYYY-MM-DDTHH:mm") ? true : false;
        }
        
        this.selectedResourceEvents = new Array();

        let X;
        let Y;
        if(selectedBlock){
          X = selectedBlock.jsEvent.pageX;
          Y = selectedBlock.jsEvent.pageY;
        } else {
          //get mouse position
          X = e.pageX;
          Y = e.pageY;

        }
        let left = X;
        let top = Y;
        //Exceptions mouse position

        

        let extraSpace = this.$store.state.menuOpen ? 250 : 25; // calculate extra space if menu is open or not
        left = X - extraSpace;


        let rightOverflow = left > (document.body.offsetWidth - (250 + extraSpace)) ? true: false;
        let bottomOverflow = (Y * 100) / this.calendarApi.el.offsetHeight > 90 ? true: false; //clicked point bigger than 100% of calendar height

        //manage the position of mouse event click
        if (bottomOverflow) {
          top = Y - 180;
        }
        if (rightOverflow) {
          left = left - 220;
        }

        // Set chosen position finally
        this.bubble.position.left = left + "px;";
        this.bubble.position.top = top + "px;";

        // test selected resource type
        let type = selectedBlock.resource.extendedProps.resourceTypeSelect;
        let showBubble = true

        switch (type) {
          case "employee":
            this.bubble.buttons.butTrialTreatment = this.viewType && this.viewType == "scheduleLead" ? true : false;
            this.bubble.buttons.butTreatment = true;
            this.bubble.buttons.butEvent = (this.viewType && this.viewType == "scheduleLead") || !this.$store.state.activeBranch.calendarSettings.useEvents ? false : true;
            this.bubble.buttons.butTreatment = true;
            this.bubble.buttons.butTimebooker = true;
            this.bubble.buttons.butPlaceEvent = true;
            this.bubble.buttons.butRoom = false;     
            this.bubble.buttons.allBookings = false;
            this.bubble.buttons.openBooking = false;
            this.bubble.buttons.notes = false;
            this.selectedResourceEvents = this.eventsObjects.filter((event) => event.extendedProps.resourceDocId == selectedBlock.resource.id);
            break;

          case "device":
            this.bubble.buttons.butTrialTreatment = false;
            this.bubble.buttons.butTreatment = false;
            this.bubble.buttons.butEvent = (this.viewType && this.viewType == "scheduleLead") || !this.$store.state.activeBranch.calendarSettings.useEvents ? false : true;
            this.bubble.buttons.butTimebooker = false;
            this.bubble.buttons.butRoom = false;
            this.bubble.buttons.allBookings = false;
            this.bubble.buttons.openBooking = false;
            this.bubble.buttons.notes = false;
            this.bubble.buttons.butPlaceEvent = true;
            showBubble = (this.$store.state.activeBranch.calendarSettings.useEvents || this.$store.state.treatmentCutId || this.$store.state.treatmentCopyId) ? true : false;
            break;

          case "room":
            this.bubble.buttons.butTrialTreatment = false;
            this.bubble.buttons.butTreatment = false;
            this.bubble.buttons.butEvent = (this.viewType && this.viewType == "scheduleLead") || !this.$store.state.activeBranch.calendarSettings.useEvents  ? false : true;
            this.bubble.buttons.butTimebooker = false;
            this.bubble.buttons.butRoom = true;
            this.bubble.buttons.allBookings = false;
            this.bubble.buttons.openBooking = false;
            this.bubble.buttons.butPlaceEvent = true;
            this.bubble.buttons.notes = false;
            break;

            case "notes":
            this.bubble.buttons.butTrialTreatment = false;
            this.bubble.buttons.butTreatment = false;
            this.bubble.buttons.butEvent = (this.viewType && this.viewType == "scheduleLead") || !this.$store.state.activeBranch.calendarSettings.useEvents  ? false : true;
            this.bubble.buttons.butTimebooker = false;
            this.bubble.buttons.butRoom = false;
            this.bubble.buttons.allBookings = false;
            this.bubble.buttons.openBooking = false;
            this.bubble.buttons.notes = true;
            this.bubble.buttons.butPlaceEvent = false;
            break

          default:
            break;
        }
        let userRestiction = (this.userRoleNumber > 2 || this.$store.state.demoMode) ? true : false;
        if((!past || !userRestiction) && showBubble ){ // Controleren of selectie in het verleden is
          this.bubble.css = "bubble-is-visible";
        }       

        //set basic select range info in Vue data variables witch will be used in another dialogs
        this.selectedResource = _.cloneDeep(selectedBlock.resource);
        this.selectRoom = this.selectedResource.title;

        this.selected_Calendar_Start = selectedBlock.startStr;
        this.selected_Calendar_End = selectedBlock.endStr;
        this.selectedBranch = this.branches.find((b) => b.id === this.$store.state.activeBranch.id);
      },

      handleDateSelect(selectInfo) {
        let title = prompt("Please enter a new title for your event");
        let calendarApi = selectInfo.view.calendar;

        let tmpEvents = new Array();
        // this.currentEvents.push({
        //   resourceId: "sven",
        //   id: "b",
        //   title,
        //   start: selectInfo.startStr,
        //   end: selectInfo.endStr,
        //   allDay: false, // will make the time show
        // });

        // this.currentEvents.forEach((event) => calendarApi.addEvent(event))
        calendarApi.unselect(); // clear date selection
        calendarApi.getEvents();
        calendarApi.refetch();

        // if (title) {
        //   calendarApi.addEvent({
        //     resourceId: 'sven',
        //     id: 'joe',
        //     title,
        //     start: selectInfo.startStr,
        //     end: selectInfo.endStr,
        //     allDay: selectInfo.allDay
        //   })
        // }
      },

      /**
       *
       * Modal Openers
       */
      openCustomerDialog(bookingID, event, type) {
        this.checkUserActivity = false;

        let clientId = type == "timeChange" ? event.clientId : event.extendedProps.clientId;
        this.selectedClientName = type == "timeChange" ? event.clientname : event.extendedProps.clientname;
        

        this.customerDialog = true;
        
        bus.$emit("getClientInformation", clientId)
      },

      async placeBooking(){ 

        // Get BookingsData
        let bookingsummaryId = this.$store.state.treatmentCutId ? this.$store.state.treatmentCutId : this.$store.state.treatmentCopyId
        let newBookingSummaryId = null // New id for copied bookingsummary
        let bookings = new Array();
        let bookingsummary = null;
        let clientData = null;
        let treatmentData = null;
        let primaryData = null; // Primary Employee with LONGEST treatment, number of MINUTES and booking INDEX data
        let oneEmployee = true;
        let abortPlacing = null; // Abort placing when error accurs
        let copyBookingEmployees = new Array();
        let history = {  // For Cut and Paste of Event
          bookings: new Array(),
          role: this.$store.state.activeUserRole,
          timestamp: new Date(),
          user: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`
        };
        await getBookingsSummaryData();
        await Promise.all([getBookingsData(), getTreatmentData(), getClientData()])
        
        async function getBookingsSummaryData(){ // Get Bookings Summary Data    
          await db.collection("bookingsummary").doc(bookingsummaryId).get()
          .then((doc) => {
            let data =  doc.data();
            data.id = doc.id;
            bookingsummary = data;
            oneEmployee = doc.data().employees.length > 1 ? false : true;
          })
          .catch(error=>{
            console.error("Error getting bookingSummary for placeBooking: ", error)
          })
          return
        }

        async function getTreatmentData(){ // Get Booking Treatment Data
          
          await db.collection("treatments").doc(bookingsummary.treatmentId).get()
          .then((doc) => {
            let data = doc.data();
            data.id = doc.id;
            treatmentData = data;
          })
          .catch(error=>{
            console.error("Error getting treatmentData for placeBooking: ", error)
          })
          return
        }

        async function getClientData(){ // Get Booking Client Data
          if(bookingsummary.clientId){
            await db.collection("clients").doc(bookingsummary.clientId).get()
            .then((doc) => {
              let data = doc.data();
              data.id = doc.id;
              clientData = data;
            })
            .catch(error=>{
              console.error("Error getting clientData for placeBooking: ", error)
            })
            return
          }
          else{
            return
          }
        }

        async function getBookingsData(){ // Get all Booking Elements Data
          await db.collection("bookingen")
          .where("bookingId", "==", bookingsummaryId)
          .orderBy('start')
          .get()
          .then((snap) => {
            let counter = 0;
            snap.forEach(doc => { 
              let booking = doc.data();
              booking.id = doc.id;
              let minutes = moment(doc.data().end, "YYYY-MM-DDTHH:mm").diff(moment(doc.data().start, "YYYY-MM-DDTHH:mm"), 'minutes'); 
              if(doc.data().resourceType == 'employee'&& ((primaryData && primaryData.minutes < minutes) || !primaryData)){ // Update primaryData
                primaryData = {
                  id: doc.data().resourceDocId,
                  minutes: minutes,
                  index: counter
                }
              }
              bookings.push(booking);
              counter++
            });
          })
          .catch(error=>{
            console.error("Error getting bookingen for placeBooking: ", error)
          })
        }
      
        // Update Booking Items
        let sumMinutes = 0;   
        let oldStartTime = null

        if(bookings && bookings.length > 0){
          await new Promise((resolve)=>{
            let counter = 0;
            bookings.forEach((booking, index)=>{

              // Save old data for change history§
              let oldStart = booking.start;

              if(!oldStartTime){
                oldStartTime = booking.start;
              }

              sumMinutes = moment.duration(moment(booking.start).diff(moment(oldStartTime))).asMinutes()

              let oldEnd = booking.end;
              let oldResourceDocId = booking.resourceDocId;

              // Change START and END times for bookings
              let minutes = moment(booking.end, "YYYY-MM-DDTHH:mm").diff(moment(booking.start, "YYYY-MM-DDTHH:mm"), 'minutes');     
              booking.start = moment(this.selected_Calendar_Start).add(sumMinutes, 'minutes').format("YYYY-MM-DDTHH:mm");

              sumMinutes = sumMinutes + minutes;

              booking.end = moment(this.selected_Calendar_Start).add(sumMinutes, 'minutes').format("YYYY-MM-DDTHH:mm");
              let sameResource = booking.resourceDocId != this.selectedResource.id ? false : true;
              let newResource = null;
              booking.sameResource = true;


              if(!sameResource){ // Placed on differend resource
                // CHANGE RESOURCE
                // Reasons:
                // 1. Booking is placed on different resource type
                // 2. A not primary Booking item who was originated from different resource (check if available)

                if(this.selectedResource.extendedProps.resourceTypeSelect == booking.resourceType){ // Same type different item (no problems to place booking here)
                  newResource = this.selectedResource.id; 
                }
                else{ // Different resource type (example: placed on employee but orgin type is device)
                  let resourceOptions = this.calendarOptions.resources.filter(resource => { return resource.resourceTypeSelect == booking.resourceType }) // Available orgin resource types this day
                  if(resourceOptions != undefined && resourceOptions.length > 0){
                    if(booking.resourceType == 'employee'){
                      newResource = resourceOptions[0].id // First employee in calendar
                    }
                    if(booking.resourceType == 'device'){
                      let deviceOptions = resourceOptions.filter(resource => { // Find devices who can execute the treatement for this booking
                        return resource.deviceTypes.filter((type) => { return type == booking.deviceId })
                      })
                      if(deviceOptions.length > 0){
                        if(deviceOptions.length == 1){  // Just one option
                          newResource = deviceOptions[0].id // First matching deviceType in calendar
                        }
                        else{ // Multiple options, check for availability
                          let availableIndexes = new Array()
                          deviceOptions.forEach((deviceResource, index) => {
                            deviceResource.available = this.checkForAvailability(deviceResource.id, booking.start, booking.end)
                            if(deviceResource.available){
                                availableIndexes.push(index)
                            }
                          })
                          if(availableIndexes.length > 0){ // Use first index in available devices array
                            newResource = deviceOptions[availableIndexes[0]].id
                          }
                          else{
                            newResource = deviceOptions[0].id // No available devices. But place it anyways
                          }
                        }
                      }
                      else{
                        // Abort placing and Alert that placing is not possible today
                        abortPlacing = 'resourceTypeNotPresentToday';
                      }
                    }
                    if(booking.resourceType == 'room'){
                      // Later aanvullen?
                    }
                  }
                  else{ // Abort placing and Alert that placing is not possible today
                    abortPlacing = 'resourceTypeNotPresentToday';
                  }
                }
              }

              if(booking.resourceType == 'employee'){
                booking.sameResource =  booking.resourceDocId == newResource ? true : false;
                if(index != primaryData.index && !sameResource && !oneEmployee){ // this is not the primary bookings item or item is placed on different resource 
                  // Check if this resource is present and available
                  let present = this.calendarOptions.resources.find(resource => { return resource.id == booking.resourceDocId })
                  
                  if(present == undefined || !this.checkForAvailability(booking.resourceDocId, booking.start, booking.end)){ // Resource is not present on this day || resource is not available
                    booking.resourceDocId = newResource;
                  }  
                }
                else if(this.selectedResource.extendedProps.resourceTypeSelect != booking.resourceType){
                  booking.resourceDocId = newResource;
                }
                else{
                  booking.sameResource =  booking.resourceDocId == this.selectedResource.id ? true : false;
                  booking.resourceDocId = this.selectedResource.id;
                } 
              }

              if(booking.resourceType == 'device'){
                if(!sameResource){
                  booking.resourceDocId = newResource;
                }
              }

              copyBookingEmployees.push(booking.resourceDocId)
            
              // Update booking in database if treatment to placed/paste is cut

              // Cut Event
              if(this.$store.state.treatmentCutId){ 
                // Push Changes to History
                history.bookings.push({ 
                  deleted: false,
                  bookingId: booking.id,
                  newStart: booking.start,
                  oldStart: oldStart,
                  newEnd: booking.end,
                  oldEnd: oldEnd,
                  newResourceDocId: booking.resourceDocId,
                  oldResourceDocId: oldResourceDocId, 
                });

                // Update booking in database
                if(!booking.deleted){ // Don't update deleted bookings
                  db.collection("bookingen").doc(booking.id).set({
                    start: booking.start,
                    end: booking.end,
                    dateBooking: moment(booking.start).format("YYYY-MM-DD"),
                    resourceDocId: booking.resourceDocId
                  },{merge: true})
                }
              }
              counter++
              if(counter == bookings.length){
                resolve()
              }
            })
          })
        }
        else{
          abortPlacing = 'noBookingsFound';
        }
        
        if(!abortPlacing){
          let downPaymentData = null;
          if(this.$store.state.treatmentCutId){ // Cut
            // Update bookingsummary in database
            db.collection("bookingsummary").doc(bookingsummaryId).set({
              start: moment(this.selected_Calendar_Start).format("YYYY-MM-DDTHH:mm"),
              end: moment(this.selected_Calendar_Start).add(sumMinutes, 'minutes').format("YYYY-MM-DDTHH:mm"),
              previousStart: bookingsummary.start,
              previousEnd: bookingsummary.end,
              visit: moment(this.selected_Calendar_Start).format("YYYY-MM-DD"),
              history: firebase.firestore.FieldValue.arrayUnion(history),
            },{merge: true})        
          }
          else{ // Copy Event
            // Create bookingsummary in database
            let uniqid = require("uniqid");
            let uniqId = uniqid();

            let treatmentPrice = treatmentData.treatmentPrice; // Calc with Vat
            downPaymentData = await this.$downPaymentInformation(this.selected_Calendar_Start, clientData, treatmentPrice)
            
            await db.collection("bookingsummary").add({
              bookingType: 'personal', 
              branchId: this.$store.state.activeBranch.id,
              clientId: bookingsummary.clientId,
              clientname: bookingsummary.clientname,
              company: this.$store.state.activeCompany.name,
              companyId: this.$store.state.activeCompany.id,
              created: new Date(),
              deleted: false,
              employees: Array.from(new Set(copyBookingEmployees)),
              sendMail: false,
              createdBy: this.$store.state.activeUserId,
              leadExtra: null,
              notes: "",
              paid: false,
              downPayment: {
                active: downPaymentData.downPaymentRequired && downPaymentData.downPaymentAmount > 0,
                paid: false,
              },
              uniqIdStamp: uniqId,
              treatmentId: bookingsummary.treatmentId,
              treatmentTitle: bookingsummary.treatmentTitle,
              visit: moment(this.selected_Calendar_Start).format("YYYY-MM-DD"),
              start: moment(this.selected_Calendar_Start).format("YYYY-MM-DDTHH:mm"),
              end: moment(this.selected_Calendar_Start).add(sumMinutes, 'minutes').format("YYYY-MM-DDTHH:mm"),
              previousStart: null,
              previousEnd: null,
              visit: moment(this.selected_Calendar_Start).format("YYYY-MM-DD"),
              history: new Array(),
            },{merge: true})
            .then((docRef)=>{
              newBookingSummaryId = docRef.id;
            })  

            bookings.forEach((booking)=>{
              if(!booking.deleted){ // Don't copy deleted bookings
                db.collection("bookingen").add({
                  bookingId: newBookingSummaryId,
                  bookingOverride: booking.bookingOverride,
                  branchId: this.$store.state.activeBranch.id,
                  clientId: booking.clientId,
                  clientname: booking.clientname,
                  companyId: this.$store.state.activeCompany.id,
                  dateBooking: moment(this.selected_Calendar_Start).format("YYYY-MM-DD"),
                  description: booking.description,
                  deleted: false,
                  end: booking.end,
                  eventColor: booking.eventColor,
                  firstName: booking.firstName,
                  leadExtra: booking.leadExtra,
                  preferredPractitioner:  booking.preferredPractitioner && booking.sameResource ? true : false,
                  resourceDocId: booking.resourceDocId,
                  resourceType: booking.resourceType,
                  source: 'personal',
                  deviceId: booking.deviceId,
                  start: booking.start,
                  surname: booking.surname,
                  title: booking.title,
                  treatmentId: booking.treatmentId,
                  type: booking.type,
                  uniqIdStamp: uniqId
                })
              }
            })
          }
          
          // Show Modal to Inform Client or Send Email (based on branch settings)
          let messageType = this.$store.state.treatmentCopyId ? downPaymentData.downPaymentRequired && downPaymentData.downPaymentAmount > 0 ? "appointmentConfirmDownPayment" : "appointmentConfirm" : "appointmentChanges";
          if(this.$store.state.activeBranch.calendarSettings.informClientCalendarModal){
            bus.$emit('showInformClientModal', messageType, this.$store.state.activeBranch.id, newBookingSummaryId ? newBookingSummaryId : bookingsummaryId, bookingsummary.clientId, downPaymentData)  
          }
          else{
            if(this.$store.state.activeBranch.calendarSettings.informClientCalendar){      
              axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, {doc: newBookingSummaryId ? newBookingSummaryId : bookingsummaryId, clientId: bookingsummary.clientId, branchId: this.$store.state.activeBranch.id, type: messageType, amount: downPaymentData.downPaymentAmount}, {
                timeout: 5000,
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then((response) => {
                if (response) {
                  //Email succesfully sent!
                }
              })
              .catch((error) => {
                console.error("Error sending timeChanged email", error.message);
              });
            }
          }

          this.$store.state.treatmentCutId = null;
          this.$store.state.treatmentCopyId = null;
          this.calendarApi.render();
          this.hideBubble();
        }
        else{ // Show snackbar notification in calendar    
          this.notify(this.$t(`calendar.${abortPlacing}`, this.$store.state.locale), "error")
        }
      },

      checkForAvailability(resourceId, start, end){ // Check if a resource is available between a certain start and end time
        let available = false;
        let resourceIdBookings = this.eventsObjects.filter((event) => { return event.resourceId == resourceId }) // Get all resource events

        if(resourceIdBookings.length > 0){ // Check all resource events
          available = true; // Assuming no events between start and end

          for (let i = 0; i < resourceIdBookings.length; i++) {
            if (
              resourceIdBookings[i].start == start ||
              moment(resourceIdBookings[i].start).isBetween(moment(start), moment(end)) == true ||
              moment(start).isBetween(moment(resourceIdBookings[i].start), moment(resourceIdBookings[i].end)) == true ||
              moment(end).isBetween(moment(resourceIdBookings[i].start), moment(resourceIdBookings[i].end)) == true
            ) {
              available = false; // Resource not available
              break
            } 
          }
        }
        else{ // No events this day for resource
          available = true;
        }

        return available;
      },

      bookTreatment(type) {
        let clientId = this.clientId ? this.clientId : null;
        let bookingType = clientId && this.leadId ? "trial" : null;
        if (type && type == "trial" && bookingType && bookingType == "trial" && this.trialTreatmentId) {
          if (this.trialTreatmentId) {
            bus.$emit("bookTreatment", this.selectedResource, "trial", this.leadId, this.trialTreatmentId, clientId);
          }
        } 
        else if (bookingType && bookingType == "trial") {
          bus.$emit("bookTreatment", this.selectedResource, "trial", this.leadId, null, clientId);
        } 
        else {
          bus.$emit("bookTreatment", this.selectedResource, null, null, null, clientId);
        }

        this.createBookingDialog = true;
        this.devicesLoaded = this.resourceObjects.filter((resource) => resource.resourceTypeSelect == "device");
        this.roomsLoaded = this.resourceObjects.filter((resource) => resource.resourceTypeSelect == "room");
        this.hideBubble();
      },

      bookEvent(topMenu) {
        let senderResource = topMenu ? "topMenu" : this.selectedResource.extendedProps.resourceTypeSelect;
        bus.$emit("bookEvent", senderResource,  this.calendarApi.getDate());
        this.CalbookEventModal = true;
        this.hideBubble();
      },

      editEvent(eventSummaryId, bookingid, passEvent, eventDate) {
        bus.$emit("editEvent", eventSummaryId, bookingid, passEvent, eventDate);
        this.CalbookEventModal = true;
        this.hideBubble();
      },

      openSubscription(type, info, id){
        bus.$emit("openSubscription", type, info, id);
      },

      updatePauzeInfo(type) {
        let clientId = this.clientId ? this.clientId : null;
        let bookingType = clientId && this.leadId ? "trial" : null;
        
        if (type && type == "trial" && bookingType && bookingType == "trial" && this.trialTreatmentId) {
          if (this.trialTreatmentId) {
            bus.$emit("chosenDate", this.chosenDateCalendar, this.selectedResource, "trial", this.leadId, this.trialTreatmentId, clientId, null);
          }
        }
        else if (type && type == "note") {
          bus.$emit("chosenDate", this.chosenDateCalendar, this.selectedResource, 'note', null, null, null, this.employeesResources);
        } 
        else if (type && type == "task") {
          bus.$emit("chosenDate", this.chosenDateCalendar, this.selectedResource, 'task', null, null, null, this.employeesResources);
        } 
        else if (bookingType && bookingType == "trial") {
          bus.$emit("chosenDate", this.chosenDateCalendar, this.selectedResource, "trial", this.leadId, null, clientId, null);
        } 
        else {
          bus.$emit("chosenDate", this.chosenDateCalendar, this.selectedResource, null, null, null, clientId, this.employeesResources);
        }
        this.dialogPause = true;
        this.hideBubble();
      },

      editPause(id, type) {
        console.log("editPause", id, type, this.employeesResources)
        bus.$emit("editPause", id, type, this.employeesResources);
      },

      bookRoom() {
        bus.$emit("bookRoom", this.selectedResource.id);
        this.addRoomBookingModal = true;
        this.hideBubble();
      },

      showResourcesEmployees() {
        this.showEmployees = !this.showEmployees;
        window.localStorage.setItem("thriveShowEmployees", this.showEmployees);
        this.fetchResources(this.chosenDateCalendar);
      },

      showResourcesDevices() {
        this.showDevices = !this.showDevices;
        window.localStorage.setItem("thriveShowDevices", this.showDevices);
        this.fetchResources();
        // this.renderCalendar()
      },

      showResourcesRooms() {
        this.showRooms = !this.showRooms;
        window.localStorage.setItem("thriveShowRooms", this.showRooms);
        this.fetchResources();
      },

      //SnackBar notification
      notify(message, status) {
        this.snackbar = true;
        this.snackBarStatus = status == "success" ? "rgba(1, 230, 110, 0.7)" : "rgba(213, 0, 0, 0.7)";
        this.snackbarIcon = status == "success" ? "mdi-thumb-up" : "mdi-thumb-down";
        this.snackBarMessage = message;
      },

      generateDemoBookingsDialog(){
        this.demoBookingsDialog.dialog = true;
      },

      resetDemoBookingsDialog(){
        this.demoBookingsDialog.dialog = false;
        this.demoBookingsDialog.number = 5;
        this.demoBookingsDialog.speed = 200;
        this.demoBookingsDialog.loading = false
      },   

      async generateDemoBookings(){
        let vm = this
        let bookingTypes = [
          {id: 'pause', resourceTypes: ["employee"]},
          {id: 'booking', resourceTypes: ['employee', 'room', 'device' ]},
          {id: 'booking', resourceTypes: ['employee', 'room', 'device' ]},
          {id: 'booking', resourceTypes: ['employee', 'room', 'device' ]},
          {id: 'booking', resourceTypes: ['employee', 'room', 'device' ]},          
          {id: 'roombooking', resourceTypes: ['room' ] }
        ];
        // let resourceTypes = ['employee', 'room', 'device' ]
        let subTreatmentLengths = [15, 20 ,30, 45]

        this.demoBookingsDialog.loading = true;
        

        function createDemoBooking(){
          let uniqid = require("uniqid");
          let uniqidBooking = uniqid();
          let bookingType = bookingTypes[Math.floor(Math.random() * (5 - 0 + 1) + 0)];
          let startTime = moment(`${moment().format("YYYY-MM-DD")}T${vm.calendarStartTime}`).add(1, 'hours');
          let endTime = moment(`${moment().format("YYYY-MM-DD")}T${vm.calendarEndTime}`).subtract(1, 'hours');
          let duration = moment.duration(endTime.diff(startTime));
          let minutes = Math.ceil(duration.asMinutes()/5);

          if(bookingType.id == "pause"){ // Geen Booking summary nodig
            
            let extraMinutes = (Math.round((Math.floor(Math.random() * minutes) + 1)/5)*5)+60;
            let start = moment(`${moment().format("YYYY-MM-DD")}T${vm.calendarStartTime}`).add(extraMinutes, "minutes");
            let end = moment(start).add(subTreatmentLengths[Math.floor(Math.random() * (3 - 0 + 1) + 0)], "minutes");
            let resource = vm.employeesResources[Math.floor(Math.random() * ((vm.employeesResources.length -1) - 0 + 1) + 0)];

            db.collection("bookingen")
            .add({
              resourceDocId: resource.docId,
              start: vm.chosenDateCalendar + "T" + moment(start).format("HH:mm"),
              end: vm.chosenDateCalendar + "T" + moment(end).format("HH:mm"),
              dateBooking: vm.chosenDateCalendar,
              created: new Date(),
              companyId: vm.$store.state.activeCompany.id,
              branchId: vm.$store.state.activeBranch.id,
              title: 'Pause (generated)',
              clientname: resource.title,
              description: "",
              type: bookingType.id,
              extra: {
                type: bookingType.id
              },
              eventColor: null,
              uniqIdStamp: uniqidBooking,
              modifiedStamp: new Date(),
              bookingOverride: false,
              leadExtra:  null,
              demoBooking: true
            })
            .then((docRef)=>{
              let cacheObject = {
                uniqIdStamp: uniqidBooking,
                found: 0,
                bookings: [docRef.id],
                resources: [resource.docId,]
              };
              // bus.$emit("newBookingCacheUpdate", cacheObject);
            })
          }
          else{
            let cacheObject = {
              uniqIdStamp: uniqidBooking,
              found: 0,
              bookings: new Array(),
              resources: new Array()
            };
            let numberOfSubtreatments = bookingType.id == 'booking' ?  Math.floor(Math.random() * (2 - 0 + 1) + 1) : 1;
            let subTreatmentLength = subTreatmentLengths[Math.floor(Math.random() * (3 - 0 + 1) + 0)];
            let extraMinutes = (Math.round((Math.floor(Math.random() * minutes) + 1)/5)*5) + 60;
            let start = moment(`${moment().format("YYYY-MM-DD")}T${vm.calendarStartTime}`).add(extraMinutes, "minutes");
            let end = moment(start).add(numberOfSubtreatments * subTreatmentLength, "minutes").format("HH:mm");
            // console.log("numberOfSubtreatments: ", numberOfSubtreatments)
            // console.log("subTreatmentLength: ", subTreatmentLength)
            // console.log("numberOfSubtreatments*subTreatmentLength: ", numberOfSubtreatments * subTreatmentLength)
            // console.log("starting: ", start)
            // console.log("ending: ", end)

            new Promise((resolve)=>{

              db.collection("bookingsummary")
              .add({
                company: vm.$store.state.activeCompany.name,
                companyId: vm.$store.state.activeCompany.id,
                created: new Date(),
                createdby: vm.userFirstName + " " + vm.userLastName + " (" + vm.userCompany + ")",
                visit: moment(vm.localetime).format("YYYY-MM-DD"),
                start: moment(start).format("HH:mm"),
                end: end, 
                clientId: null,
                clientname: "Thrive Development Test",
                treatmentTitle: `${bookingType.id} (Generated)`,
                branchId: vm.$store.state.activeBranch.id,
                type: bookingType.id,
                demoBooking: true
              })
              .then((docRef)=>{
                let counter = 0;
                for (let i = 0; i < numberOfSubtreatments; i++) {
                  let resourceType = bookingType.resourceTypes[[Math.floor(Math.random() * ((bookingType.resourceTypes.length -1) - 0 + 1) + 0)]]
                  let resource = null;

                  if(resourceType == 'employee'){
                    resource = vm.employeesResources[Math.floor(Math.random() * ((vm.employeesResources.length -1) - 0 + 1) + 0)];
                  }
                  else if(resourceType == 'room'){
                    resource = vm.roomsLoaded[Math.floor(Math.random() * ((vm.roomsLoaded.length -1) - 0 + 1) + 0)];
                  }
                  else{
                    resource =  vm.devicesLoaded[Math.floor(Math.random() * ((vm.devicesLoaded.length -1) - 0 + 1) + 0)];
                  }
       
                  db.collection("bookingen").add({
                    bookingId: docRef.id,
                    resourceDocId: resource.id,
                    start: vm.chosenDateCalendar + "T" + moment(start).add(subTreatmentLength * i, "minutes").format("HH:mm"),
                    end: vm.chosenDateCalendar + "T" + moment(start).add((subTreatmentLength * i) + subTreatmentLength, "minutes").format("HH:mm"),
                    dateBooking: moment(vm.localetime).format("YYYY-MM-DD"), 
                    branchId: vm.$store.state.activeBranch.id,
                    title: bookingType.id == "booking" ? { nl: uniqidBooking, en: uniqidBooking} : uniqidBooking,
                    clientId: null,
                    clientname: "Thrive Development Test",
                    description: bookingType.id == "booking" ? {nl: "(Generated booking)",en: "(Generated booking)"} : "(Generated booking)",
                    type: bookingType.id,
                    deleted: false,
                    eventColor: null,
                    bookingOverride: false,
                    preferredPractitioner: bookingType.id == "booking" ? true: null,
                    uniqIdStamp: uniqidBooking,
                    modifiedStamp: new Date(),
                    source: "generated",
                    demoBooking: true
                  })
                  .then((booking)=>{
                    cacheObject.bookings.push(booking.id)
                    cacheObject.resources.push(resource.id)
                    counter++
                    if(counter == numberOfSubtreatments){
                      resolve()
                    }
                  })
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                  });
                  
                }
              })
              .catch((error) => {
                console.error("Error created document bookingsummary ", error);
              });

              

            })
            .then(()=>{
              console.log("POSTED")
              // bus.$emit("newBookingCacheUpdate", cacheObject);
            })
          }
        }

        for (let i = 0; i < this.demoBookingsDialog.number; i++) {
          await new Promise(resolve => setTimeout(resolve, this.demoBookingsDialog.speed))
          createDemoBooking()
        }
        this.resetDemoBookingsDialog()
      },

      hideBubble() {
        this.bubble.css = "bubble";
      },
        
      userActivity(){ 
        let vm = this;
        this.checkUserActivity = true;
        let countdown = 20000;
        let timeout;
        let counter;
        let seconds;
        mouseListener()
        
        function mouseListener(){ 
          resetTimer();
          timer();
        };

        function timer() {
          window.addEventListener("mousemove", mouseListener, false);
          timeout = setTimeout(()=>{ }, countdown);
          seconds = countdown / 1000;  
          counter = setInterval(()=>{     
            if(!vm.checkUserActivity){
              resetTimer();
            }
            else{
              if(seconds <= 0){  
                mouseListener();
                vm.scrollToIndicator();             
                vm.checkBookingEditStatus();
              }
            }
            seconds -= 1;
          }, 1000);
        };

        function resetTimer(){
          window.removeEventListener("mousemove", mouseListener, false);
          clearTimeout(timeout)
          clearInterval(counter);
        }
      },

      scrollToIndicator(date) {  
        if(!this.dialogOpen){
         
          let today = moment().format("YYYY-MM-DD")
          let dateFormatted = this.calendarApi.getDate() ? moment(this.calendarApi.getDate()).format("YYYY-MM-DD") : null;
          if(dateFormatted && dateFormatted == today){
            let currentTime = moment(date).subtract(2, "hours").format("HH:mm:ss");
            this.calendarApi.scrollToTime(currentTime);
            // ("scrollToIndicator currentTime: ", currentTime)
          }
        }
      },

      getResourceCacheOverview(){
        let cacheObjects = new Object();
        let counter = 0;
        new Promise((resolve)=>{
          this.resourceCache.forEach(cache=>{
            if(!cacheObjects[cache.resourceId]){
              cacheObjects[cache.resourceId] = new Object();
            }

            if(cache.bookings.length > 0){
              let bookingCounter = 0
              cache.bookings.forEach(booking=>{
                let startTime = moment(`${moment().format("YYYY-MM-DD")}T${this.calendarStartTime}`);         
                let startBookingTime = moment(`${moment().format("YYYY-MM-DD")}T${booking.start}`);
                let endBookingTime = moment(`${moment().format("YYYY-MM-DD")}T${booking.end}`);
                let durationStart = moment.duration(startBookingTime.diff(startTime));
                let durationEnd = moment.duration(endBookingTime.diff(startTime));
                let fiveMinutesBlockStart = ((durationStart.asMinutes())/5) + 1;
                let fiveMinutesBlockEnd = ((durationEnd.asMinutes())/5);

                cacheObjects[cache.resourceId][fiveMinutesBlockStart] = { type: 'start', cache: true, swapping: booking.swapping ? true: false};
                cacheObjects[cache.resourceId][fiveMinutesBlockEnd] = { type: 'end', cache: true , swapping: booking.swapping ? true: false};
                if(fiveMinutesBlockEnd - fiveMinutesBlockStart > 1){ // Multiple chache blocks between
                  let extraBlocks =  fiveMinutesBlockEnd - fiveMinutesBlockStart - 1;

                  let extraBlocksArray = Array.apply(null, Array(extraBlocks))

                  extraBlocksArray.forEach((number, index)=>{
                    cacheObjects[cache.resourceId][fiveMinutesBlockStart + index + 1] = { type: 'between', cache: true , swapping: booking.swapping ? true: false};
                  })
                }
                bookingCounter++ 
                if(bookingCounter == cache.bookings.length){
                  counter++
                  if(counter == this.resourceCache.length){
                    resolve()
                  }
                }
              })
            }
            else{
              counter++
              if(counter == this.resourceCache.length){
                resolve()
              }
            }
          })
        })
        .then(()=>{
          this.cachePreviewObject = cacheObjects;
        })
      },

      calculateCalendarHeaderWidth(){
        this.resizeChanges++
        let headerColmnWidth = document.querySelector("#calendar-header-column") ? document.querySelector("#calendar-header-column").clientWidth : 0; //Query Selector
        if(headerColmnWidth > 0){
          let maxFlexColumnWith = 0;
          maxFlexColumnWith = headerColmnWidth  - 165;
          this.calendarHeaderWidth = { maxWidth: `${maxFlexColumnWith}px` }

          let numberOfButtons = this.chosenDateCalendar == moment().format("YYYY-MM-DD") ? 5 : 4;
          let leftOverSpace = Math.floor(maxFlexColumnWith - (48 * numberOfButtons) - 16);

          if(leftOverSpace > 100){
            this.searchField.show = true;
            this.searchField.maxWidth = `${leftOverSpace}px`;
          }
          else{
            this.searchField.show = false;
          }
        }
        else{
          this.calendarHeaderWidth =  {}
          this.searchField.show = false;
        }
        
      },
    },

    watch: {
      localetime: function (val) {
        if (this.viewType && this.viewType == "scheduleLead") {
          bus.$emit("localTimeLeadScheduleChanged", val);
        }
        else {
          bus.$emit("localTimeChanged", val);
        }
      },
      '$store.state.menuOpen': function() {
        setTimeout(() => {
          this.calculateCalendarHeaderWidth()
        }, 50) 
      },
      '$store.state.demoMode': function() {
        setTimeout(() => {
          this.calculateCalendarHeaderWidth()
        }, 50)
      }

      

      // newBookings: function (val) { // Check if new bookings are converted in to cache
      //   if (this.newBookings.length > 0) {
      //     this.newBookingsTimer = setInterval(()=>{    
      //       this.compareCacheWithBookings(); // Trigger function that compares all bookings with cache
      //     }, 500);
      //   }
      //   else {
      //     clearInterval(this.newBookingsTimer);
      //   }
      // },

      
    },

    computed: {
      multipleCompaniesGrid() {
        return this.companies.length > 1 ? "xs3" : "xs6";
      },
      addAppointments() {
        if (this.$store.state.userRules) {
          if (this.$store.state.userRules.free && _.indexOf(this.$store.state.userRules.free, "addAppointments") >= 0) {
            return true; // Rule found in free
          }
          else { // Check if some user is logged in  
            if (this.$store.state.connectedEmployee) {
              if (this.$store.state.activeUserRoleNumber < 4) { // Employee is logged in
                return true; // userRole OVERRULES the rules
              }
              else {
                if (this.$store.state.userRules.restricted && _.indexOf(this.$store.state.userRules.free, "addAppointments") >= 0) {
                  return true; // Rule found in restricted
                }
                else {// Rule NOT found in restricted
                  return false;
                } 
              }
            }
            else {// Employee is NOT logged in
              return false;
            } 
          }
        }
        else { // No branch is logged in
          return true;
        } 
      },

      companyClass(){
        if(this.$store.state.userCompanies && this.$store.state.userCompanies.length > 1 && !this.$store.state.demoMode ){
          return "xs12 md6"
        }
        else{
          return "xs12 md9"
        }
      },
      
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRoleNumber(){
        return this.employeeConnected ? this.connectedEmployee.userRoleNumber : this.$store.state.userRoleNumber;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      scheduleExceptionsChanges(){
        let changes = this.timeRegistrationDialog.changes;
        return _.isEqual(this.scheduleExceptions, this.scheduleExceptionsOriginal) ? false : true
      }
    },
  };
</script>

<template>
  <div>

    <thrive-assistant 
      v-if="showAssistant" 
      @close="showAssistant = false" 
      :type="thriveAssistantType"
      :branchId="$store.state.activeBranch.id"
      :calendarDate="chosenDateCalendar"
    ></thrive-assistant>

    <!-- / Employee Future Availability Overlay for Calendar \ -->
    <v-card class="employee-future-schedule-popup elevation-18" :class="[this.employeeFutureSchedulePopup.class]" v-if="employeeFutureSchedulePopup.show" :style="[{'top' :employeeFutureSchedulePopup.top},{'left' :employeeFutureSchedulePopup.left}]">
      <v-card-title class="pa-1 pl-3" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardOption }">
        <h3>{{ $t('branch.schedule', $store.state.locale) }} {{ employeeFutureSchedulePopup.data.firstName }} {{ employeeFutureSchedulePopup.data.lastName }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeViewEmployeeScheduleFuture()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-divider></v-divider>

      <v-card-text class="ma-0 pa-0" style="overflow-y: scroll; height: 350px;">
        <div class="week-data-wrapper">
          <div v-for="(week, index) in employeeFutureSchedulePopup.weeksData" :key="index">
            <div v-if="checkForWeekData(week)">
              <v-divider v-if="index != 0"></v-divider>
              <span class="weeknumber">{{ $t('general.week', $store.state.locale) }} {{ week.week }}</span>
              <v-divider></v-divider>
              <div v-for="(day, i) in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" :key="i">
                <div v-if="week.schedule[day].length > 0 && getDayDate(week.year, week.week, day, 'YYYY-MM-DD').date >= dateOfToday()" class="day-view-wrapper" @click="goToDayDate(week.year, week.week, day)">
                  <div class="day-view-date" :class="{'current' : getDayDate(week.year, week.week, day, 'ddd D MMM').current }">{{ getDayDate(week.year, week.week, day, "ddd D MMM").date }}</div>
                  <div class="day-view">
                    <div v-for="(timeBlock, d) in week.schedule[day]" :key="d"> 
                      <div class="time-block-view" v-if="showTimeBlock(week.week, timeBlock.continiousType)" :class="[timeBlock.type, {'firstItem': checkTimeBlockPosition(week.week, week.schedule[day], d, 'first')}, {'lastItem': checkTimeBlockPosition(week.week, week.schedule[day], d, 'last')}]" :style="[{'left': calculateTimeBlockPosition(timeBlock.start, timeBlock.end, 'left')},{'width': calculateTimeBlockPosition(timeBlock.start, timeBlock.end, 'width')}]">
                        <div class="time-block-view-times">{{ timeBlock.start }} - {{ timeBlock.end }}</div>
                        <div v-for="(booking, b) in timeBlock.bookings" :key="b" class="block-view" :class="[booking.type, {'swapping': booking.swapping}]" :style="[{'backgroundColor': getBookingColor(booking)},{'left': calculateBookingPosition(booking.start, booking.end, timeBlock.start, timeBlock.end, 'left')},{'width': calculateBookingPosition(booking.start, booking.end, timeBlock.start, timeBlock.end, 'width')}]"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="employeeFutureSchedulePopup.message">
          <v-divider></v-divider>
          <div class="pa-3 text-center">
            <v-chip outlined small dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"> {{ $t(`employees.${employeeFutureSchedulePopup.message}`, $store.state.locale) }} {{  employeeFutureSchedulePopup.employeeEndDate }}</v-chip>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-overlay :value="employeeFutureSchedulePopup.show" @click="employeeFutureSchedulePopup.show = false"></v-overlay>
    <!-- \ Employee Future Availability Overlay for Calendar / -->

    <!-- / Employees Time Registration Modal \ -->
    <v-dialog v-model="timeRegistrationDialog.show" scrollable max-width="700px">
      <v-card v-if="timeRegistrationDialog.show">
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('employees.employeesTimeregistration', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeTimeRegistration()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="timeRegistrationDialog.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ timeRegistrationDialog.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text class="ma-0 pa-0">
          <div class="timeregistration-employees pa-1">
            <!-- <v-card outlined v-for="(employee, index) in employeesResources" :key="index + timeRegistrationDialog.changes" class="ma-2 my-3" > -->
              <v-card outlined v-for="(employee, index) in $store.state.activeBranchEmployees" :key="index + timeRegistrationDialog.changes" class="ma-2 my-3" >

              <v-row no-gutters class="pa-2">
                <v-col sm="4" align-self="center">
                  <v-avatar size="36" class="mr-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background">
                    <img v-if="employee.employeePhoto" :src="employee.employeePhoto">
                    <v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>
                  </v-avatar>
                  {{ employee.firstName }} {{ employee.lastName }}
                </v-col>
                <v-col sm="4" align-self="center"><i>{{ timeRegistrationDialog.employees[employee.id].description }}</i></v-col>
                <v-col sm="3" align-self="center">
                  <!-- Minutes: {{ timeRegistrationDialog.employees[employee.id].minutes}} - {{ minutesToHourFormat(timeRegistrationDialog.employees[employee.id].minutes) }}<br>
                  minutesExtra: {{ timeRegistrationDialog.employees[employee.id].minutesExtra}} - {{ minutesToHourFormat(timeRegistrationDialog.employees[employee.id].minutesExtra) }}<br>
                  minutesMinus: {{ timeRegistrationDialog.employees[employee.id].minutesMinus}} - {{ minutesToHourFormat(timeRegistrationDialog.employees[employee.id].minutesMinus) }}<br> -->

                  <span v-if="timeRegistrationDialog.employees[employee.id].minutes + timeRegistrationDialog.employees[employee.id].minutesExtra + timeRegistrationDialog.employees[employee.id].minutesMinus > 0">({{ minutesToHourFormat(timeRegistrationDialog.employees[employee.id].minutes + timeRegistrationDialog.employees[employee.id].minutesExtra - timeRegistrationDialog.employees[employee.id].minutesMinus, 0) }} {{$t('general.hour', $store.state.locale)}})</span>
                </v-col>
                <v-col sm="1" align-self="center" class="text-right">
                  <v-btn icon outlined small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mx-1">
                    <v-icon dark small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addScheduleException(employee)">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <div v-if="timeRegistrationDialog.employees[employee.id].timeChanges">
                <div v-for="(change, index) in timeRegistrationDialog.employees[employee.id].changes" :key="index" class="timechange-item" >
                  <v-divider class="ma-0"></v-divider>
                  <div class="time-change-wrapper " :class="change.change">
                    <div class="time-change-item pa-2 py-3" >
                      <v-row no-gutters>
                        <v-col sm="4" align-self="center" class="pr-2">
                          <v-select
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :items="scheduleExceptionsTypes"
                            :disabled="change.period"
                            item-text="name"
                            :label="$t('general.type', $store.state.locale)"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            v-model="change.type"
                            @change="changeTimeRegistrationReason(change, index)"
                          ></v-select>
                        </v-col>
                        <v-col :sm="change.type == 'absence'? 4 : 8" align-self="center" class="pr-2">
                          <v-select
                            v-if="change.type == 'absence'"
                            :label="$t('employees.absenceReason', $store.state.locale)"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :items="$leaveItems.absentItems"
                            :disabled="change.period"
                            item-text="name"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            v-model="change.reason"
                          ></v-select>
                          <v-select
                            v-else
                            :label="$t('employees.presentReason', $store.state.locale)"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :items="$leaveItems.presenceItems"
                            :disabled="change.period"
                            item-text="name"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            v-model="change.reason"
                          ></v-select> 
                        </v-col>
                        <v-col v-if="change.type == 'absence' && !change.period" sm="4" align-self="center" class="pr-2">
                          <v-select
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :items="entireDayTypes"
                            :disabled="change.period"
                            item-text="name"
                            :label="$t('employees.entireDay', $store.state.locale)"
                            item-value="id"
                            dense
                            outlined
                            @update="timeRegistrationEmployeeInfo(employee)"
                            hide-details
                            v-model="change.allDay"
                          ></v-select>
                          </v-col>
                          <v-col v-if="change.period" sm="4" align-self="center" class="pr-2">
                            <v-text-field dense :value="`${formatDate(change.startdate,'D MMM', 'nl')} - ${formatDate(change.enddate,'D MMM', 'nl')} `" :label="$t('employees.period', $store.state.locale)" outlined hide-details :disabled="change.period" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                            </v-text-field>
                          </v-col>
                         <!-- <v-menu :close-on-content-click="true" v-model="change.startDateDialog" :nudge-right="40" transition="scale-transition" offset-y :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-on="on"
                                :value="formatChangeDate(change.startdate)"
                                :label="$t('general.date', $store.state.locale)"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                required
                                dense
                                outlined
                                hide-details
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                background-color="background"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="change.startdate" no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="change.startDateDialog = false"></v-date-picker>
                          </v-menu>
                        </v-col> -->
                        
                        <v-col sm="12" align-self="center" class="pr-2 pt-2">
                          <v-text-field dense v-model="change.extra" :label="$t('employees.notes', $store.state.locale)" outlined hide-details :disabled="change.period" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-text-field>
                        </v-col>

           
                      </v-row>
                      <div v-if="(change.type == 'present'|| (change.type == 'absence' && !change.allDay))">
                        <v-row no-gutters v-for="(time, i) in change.time" :key="i">
                          <v-col sm="4" align-self="center" class="pr-2 pl-1 pt-3">
                            <div v-if="i == 0">
                              <div v-if="change.time.length > 1"><strong>{{ $t('employees.times', $store.state.locale) }}</strong></div>
                              <div v-else><strong>{{ $t('general.time', $store.state.locale) }}</strong></div>
                            </div>
                          </v-col>
                          <v-col sm="2" align-self="center" class="pr-1 pt-3">
                            <v-text-field dense :label="$t('general.start', $store.state.locale)" v-model="time.start" type="time" outlined hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" background-color="background"></v-text-field>
                          </v-col>

                          <v-col sm="2" align-self="center" class="px-1 pt-3">
                            <v-text-field  dense :label="$t('general.end', $store.state.locale)" v-model="time.end" type="time" outlined hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" background-color="background"></v-text-field>
                          </v-col>
                          <v-col sm="1" align-self="center" class="pr-2 pt-3 text-left">
                            <v-btn :disabled="change.time.length == 1" icon @click="removeTimeRegistrationTime(employee, change, i)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col sm="3" align-self="center" class="px-1 pt-3">
                            <div v-if="change.type == 'present'">
                              <span v-if="calculateTimeRegistration(employee, change, time).minutes - calculateTimeRegistration(employee, change, time).minutesOverlap > 0"><strong>{{ minutesToHourFormat(calculateTimeRegistration(employee, change, time).minutes - calculateTimeRegistration(employee, change, time).minutesOverlap) }} {{$t('general.hour', $store.state.locale)}}</strong></span>
                              <v-tooltip dark top v-if="calculateTimeRegistration(employee, change, time).minutesOverlap > 0">
                                <template v-slot:activator="{ on }">
                                  <v-icon small color="warning" v-on="on">mdi-alert</v-icon>
                                </template>
                                <span class="tooltip-text-white">{{  minutesToHourFormat(calculateTimeRegistration(employee, change, time).minutesOverlap) }} {{$t('general.hour', $store.state.locale)}} {{  $t('employees.timeRegistrationScheduleOverlap', $store.state.locale)  }}</span>
                              </v-tooltip>
                            </div>
                            <div v-else>
                              <span v-if="calculateTimeRegistration(employee, change, time).minutesOverlap > 0"><strong>{{ minutesToHourFormat(calculateTimeRegistration(employee, change, time).minutesOverlap) }} {{$t('general.hour', $store.state.locale)}}</strong></span>
                              <v-tooltip dark top v-if="calculateTimeRegistration(employee, change, time).minutes - calculateTimeRegistration(employee, change, time).minutesOverlap > 0">
                                <template v-slot:activator="{ on }">
                                  <v-icon small color="warning" v-on="on">mdi-alert</v-icon>
                                </template>
                                <span class="tooltip-text-white">{{  minutesToHourFormat(calculateTimeRegistration(employee, change, time).minutes - calculateTimeRegistration(employee, change, time).minutesOverlap ) }} {{$t('general.hour', $store.state.locale)}} {{  $t('employees.timeRegistrationNoScheduleOverlap', $store.state.locale)  }}</span>
                              </v-tooltip>
                            </div>
                          </v-col> 
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col sm="4" align-self="center"></v-col>
                          <v-col sm="4" align-self="center" class="text-center">
                            <v-btn  x-small outlined fab dark @click="addExtraTime(employee, change)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </div>

                    <div class="time-change-remove" align-self="center"> 
                      <v-btn icon :disabled="change.period" @click="removeTimeRegistration(employee, change, index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  
                </div>
              </div>
            </v-card>

          </div>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeTimeRegistration()">
                {{ $t('general.cancel', $store.state.locale) }} 
                </v-btn>
              </v-flex>

              <v-flex xs12 md8 class="text-right">
               <v-btn 
                  @click="saveScheduleExceptionsChanges()"
                  :disabled="(!scheduleExceptionsChanges || timeRegistrationDialog.loading)"
                  :dark="scheduleExceptionsChanges && !timeRegistrationDialog.loading"
                  :loading="timeRegistrationDialog.loading"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  >
                  {{ $t('employees.changeTimeregistration', $store.state.locale) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn> 
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Employees Time Registration Modal / -->

    <!-- / Change Booking Treatment \ -->
    <v-dialog v-model="changeBookingTreatmentData.dialog" eager scrollable max-width="700px">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('calendar.changeBookingTreatment', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeChangeBookingTreatment()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="changeBookingTreatmentData.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ changeBookingTreatmentData.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text class="ma-0 pa-0">
          <treatmentSelector :source="'calendar'"></treatmentSelector>  
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeChangeBookingTreatment()">
                {{ $t('general.cancel', $store.state.locale) }} 
                </v-btn>
              </v-flex>

              <v-flex xs12 md8 class="text-right">
               <v-btn 
                  @click="saveChangeBookingTreatment()"
                  :disabled="(changeBookingTreatmentData.orginalTreatmentId == changeBookingTreatmentData.newTreatmentId) || changeBookingTreatmentData.newTreatmentId == null || changeBookingTreatmentData.loading"
                  :dark="(changeBookingTreatmentData.orginalTreatmentId != changeBookingTreatmentData.newTreatmentId) && !changeBookingTreatmentData.loading"
                  :loading="changeBookingTreatmentData.loading"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  >
                  {{ $t('calendar.changeTreatment', $store.state.locale) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn> 
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card> 
    </v-dialog>
    <!-- \ Change Booking Treatment / --> 

    <!-- / Bubble overlay for Calendar \ -->
    <div :id="bubble.css" :style="'width: 204px; left: ' + bubble.position.left + 'top: ' + bubble.position.top">
      <v-btn small v-show="bubble.buttons.butTrialTreatment" dark style="width: 186px; float: left;" :disabled="!addAppointments" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.native="bookTreatment('trial')"><span style="font-size: 12px!important">{{ $t('calendar.trialTreatment', $store.state.locale) }}</span></v-btn>
      <v-btn small v-show="bubble.buttons.butTreatment" outlined style="width: 186px; float: left" :disabled="!addAppointments" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.native="bookTreatment()"><span style="font-size: 12px!important">{{ $t('calendar.bookTreatment', $store.state.locale) }}</span></v-btn>

      <v-btn small v-show="bubble.buttons.butEvent" style="width: 186px; float: left" :disabled="!addAppointments" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.native="bookEvent()"><span style="font-size: 12px!important">{{ $t('calendar.bookEvent', $store.state.locale) }}</span></v-btn>
      <v-btn small v-show="bubble.buttons.butRoom" style="width: 186px; float: left" :disabled="!addAppointments" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.native="bookRoom()"><span style="font-size: 12px!important">{{ $t('calendar.bookRoom', $store.state.locale) }}</span></v-btn>
      <v-btn small v-show="bubble.buttons.butTimebooker" style="width: 186px; float: left" :disabled="!addAppointments" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.native="updatePauzeInfo()"><span style="font-size: 12px!important">{{ $t('calendar.bookTime', $store.state.locale) }}</span></v-btn>
      <v-btn small v-show="bubble.buttons.notes" style="width: 186px; float: left" :disabled="!addAppointments" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.native="updatePauzeInfo('note')"><span style="font-size: 12px!important">{{ $t('calendar.notes', $store.state.locale) }}</span></v-btn>
      <v-btn small v-show="bubble.buttons.notes" style="width: 186px; float: left" :disabled="!addAppointments" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.native="updatePauzeInfo('task')"><span style="font-size: 12px!important">{{ $t('calendar.task', $store.state.locale) }}</span></v-btn>
      
      <v-btn small v-show="bubble.buttons.butPlaceEvent && ($store.state.treatmentCutId || $store.state.treatmentCopyId)" outlined style="width: 186px; float: left; border-style: dashed" :disabled="!addAppointments" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.native="placeBooking()"><span style="font-size: 12px!important">{{ $t('calendar.placeBooking', $store.state.locale) }}</span></v-btn>

      <v-btn small outlined @click.native="hideBubble()" style="width: 186px; float: left" dark color="secondary"><span style="font-size: 12px!important">{{ $t('general.cancel', $store.state.locale) }}</span></v-btn>
    </div>
    <!-- \ Bubble overlay for Calendar / -->
    
    <div v-show="calendarLoading" style="position: absolute; top: 0; left: 0; z-index: 99; min-height: 500px; height: 100%; width: 100%">
        <div class="thrive-loader"></div>
      </div>
      
    <section id="content-calendar">
      
      <div id="calendar-header-wrapper" >
        <div>
        <!-- <div class="calendar-header-navigation">   -->
          <v-container grid-list-md class="pa-0 pt-0 ma-0 pl-4 pr-0">
            <v-layout row wrap id="calendar-header-row" >

              <companySelector :showBranches="true" @companyChanged="loadData('change')" @branchChanged="loadDataBranch"></companySelector>          

              <v-flex :class="companyClass" id="calendar-header-column"> 
                <div :style="calendarHeaderWidth" style="display: block; float: left;">
                  <div v-show="branchesAvailable" class="text-left">
                    
                    <!-- / Search field and results \ -->
                    <v-menu :key="resizeChanges" :style="{'maxWidth': searchField.maxWidth}" :value="searchField.show ? searchField.showResults : false" offset-y :open-on-click="!searchField.show ? true : false" :close-on-content-click="false" :close-on-click="false" >
                      <template v-slot:activator="{ on, attrs }" v-click-outside="cleanSearchCommand">
                        <v-text-field 
                          v-show="searchField.show" 
                          :style="{'width': searchField.maxWidth}"
                          style="display:inline-block;"
                          outlined
                          dense
                          clearable
                          class="mx-1"
                          rounded
                          v-model="searchField.value"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="menu"
                          hide-details
                          :label="$t('general.search', $store.state.locale)"
                          single-line
                          @click:clear="cleanSearchCommand()"
                          @click:append="searchInCalendar()"
                          @keyup.native.enter="searchInCalendar()"
                          append-icon="mdi-magnify"
                        ></v-text-field>
                        <v-btn v-show="searchField.show == false" class="mx-1" v-on="on" v-bind="attrs" fab dark small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                      <div v-if="!searchField.show" style="background-color: #ffffff">
                        <v-list dense class="ma-0 pa-0">
                          <v-list-item-group class="ma-0 pa-0">
                            <v-list-item class="ma-0 pa-0"> 
                              <v-text-field 
                                style="display:inline-block; max-width: 500px;"
                                outlined
                                dense
                                class="ma-2"
                                rounded
                                clearable
                                v-model="searchField.value"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                background-color="menu"
                                hide-details
                                :label="$t('general.search', $store.state.locale)"
                                single-line
                                append-icon="mdi-magnify"
                                @click:clear="cleanSearchCommand()"
                                @click:append="searchInCalendar()"
                                @keyup.native.enter="searchInCalendar()"
                              ></v-text-field>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <v-divider></v-divider>
                      </div>
                      <div v-if="searchField.showResults">
                        <div class="text-center" v-if="searchField.loading" style="background-color: #ffffff">
                          <div style="position: relative; padding: 40px 0;">
                            <div class="thrive-loader"></div>
                          </div>
                        </div>
                        <div v-else style="background-color: #ffffff">
                          <v-list dense class="ma-0 pa-0"  v-if="searchResults.length > 0">
                            <div class="pa-3">
                              <v-row>
                                <v-col md="10">
                                  <v-switch
                                    v-model="searchField.showDeleted"
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    hide-details
                                    class="mb-0 mt-0 pt-1"
                                    label="Laat verwijderde behandelingen zien"
                                    >
                                  </v-switch>
                                </v-col >
                                <v-col md="2" class="text-right">
                                  <v-icon class="pa-1" @click="cleanSearchCommand">mdi-close</v-icon>
                                </v-col>
                              </v-row>
                            </div>
                          </v-list>
                          <v-divider></v-divider>
                          <v-list dense class="ma-0 pa-0" style="max-height: 500px; overflow-y: scroll">
                            <v-list-item-group v-if="searchResults.length > 0" class="ma-0 pa-0">
                              <div v-for="(booking, index) in searchResults" :key="index" v-if="!booking.deleted || searchField.showDeleted">
                                <v-list-item class="px-0 mx-0 pb-2 pt-1" @click="openBooking(booking)">
                                  <v-list-item-avatar
                                    size="50"
                                    light
                                    class="ma-0 mx-2 mt-1 pa-0 custom-avatar-style" 
                                    rounded="lg"
                                    :color=" bookingSearchColor(booking.visit, booking.deleted)" 
                                    :style="{'color': bookingSearchColor(booking.visit, booking.deleted)}" 
                                    >
                                    <div><span style="font-size: 12px">{{ booking.visit | moment("MMM")}}</span>
                                    <p class="mb-0" style="font-size: 20px" :style="{color:  bookingSearchColor(booking.visit, booking.deleted)} "><strong>{{ booking.visit | moment("D ")}}</strong></p></div>
                                  </v-list-item-avatar>

                                  <v-list-item-content class="py-0">
                                    <v-list-item-title class="ma-0 pa-0 pt-1" style="font-size: 14px"> {{ booking.clientname}} </v-list-item-title>
                                    <v-list-item-subtitle class="pa-0 pt-1" style="font-size: 12px;">
                                    {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, booking.treatmentTitle) }} <br>
                                    {{ booking.start | moment("H:mm")}}<!-- - {{ booking.end | moment("H:mm")}}-->u <v-chip x-small label light :color="bookingSearchColor(booking.visit, booking.deleted)" :style="{color:  bookingSearchColor(booking.visit, booking.deleted)}" class="ma-0 pa-0 px-1 custom-chip-style"><span style="font-size: 11px">{{ getBranchName(booking.branchId) }}</span></v-chip><br>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action class="text-center ma-0 mr-3" v-if="!booking.deleted && booking.downPayment && booking.downPayment.active && booking.downPayment.paid">
                                    <v-icon size="16" style="margin: 10px auto 0 auto" color="#56ca00">mdi-cash-check</v-icon>
                                    <v-list-item-action-text style="font-size: 10px; width: 80px"  v-html="$t('booking.downPaymentReceived', $store.state.locale)"></v-list-item-action-text>
                                  </v-list-item-action>
                                  <v-list-item-action class="text-center ma-0 mr-3" v-if="!booking.deleted && booking.downPayment && booking.downPayment.active && !booking.downPayment.paid">
                                    <v-icon size="16" style="margin: 10px auto 0 auto" :color="downPaymentStatus(booking.created).color">mdi-checkbox-blank-circle</v-icon>
                                    <v-list-item-action-text style="font-size: 10px; width: 80px" v-html="$t('booking.downPaymentNotReceived', $store.state.locale)"></v-list-item-action-text>
                                  </v-list-item-action>
                                  <v-list-item-action class="text-center ma-0 mr-3" v-if="booking.deleted">
                                    <v-icon size="16" style="margin: 10px auto 0 auto" color="#ff4c51">mdi-delete</v-icon>
                                    <v-list-item-action-text style="font-size: 10px; width: 80px" v-html="$t('booking.bookingRemoved', $store.state.locale)"></v-list-item-action-text>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-divider></v-divider>
                              </div>
                            </v-list-item-group>
                            <v-list-item-group v-else class="ma-0 pa-0">
                              <v-list-item>
                                {{  $t('clients.noResultsFound', $store.state.locale) }}
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </div>
                    </v-menu>
                    <!-- \ Search field and results / -->

                     <!-- / Hide and show resources \ -->
                    <v-menu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" v-on="on" v-bind="attrs" fab dark small depressed :color="showDevices && showRooms && showEmployees ? 'grey' : $themes[$store.state.companyTheme][$store.state.themeModus].primary">
                          <v-icon v-if="showDevices && showRooms && showEmployees">mdi-eye</v-icon>
                          <v-icon v-else>mdi-eye-off</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense class="ma-0 pa-0">
                        <v-list-item-group class="ma-0 pa-0">
                          <v-list-item  @click.native="showResourcesEmployees()">
                            <v-list-item-icon>
                              <v-icon v-if="showEmployees">mdi-account</v-icon>
                              <v-icon v-else>mdi-account-off</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title v-if="showEmployees">{{ $t('calendar.showingEmployees', $store.state.locale) }}</v-list-item-title>
                              <v-list-item-title v-else>{{ $t('calendar.hidingEmployees', $store.state.locale) }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item  @click.native="showResourcesDevices()">
                            <v-list-item-icon>
                              <v-icon v-if="showDevices">mdi-power-plug</v-icon>
                              <v-icon v-else>mdi-power-plug-off</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title v-if="showDevices">{{ $t('calendar.showingDevices', $store.state.locale) }}</v-list-item-title>
                              <v-list-item-title v-else>{{ $t('calendar.hidingDevices', $store.state.locale) }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item  @click.native="showResourcesRooms()">
                            <v-list-item-icon>
                              <v-icon v-if="showRooms">mdi-cube-outline</v-icon>
                              <v-icon v-else>mdi-cube-off-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title v-if="showRooms">{{ $t('calendar.showingRooms', $store.state.locale) }}</v-list-item-title>
                              <v-list-item-title v-else>{{ $t('calendar.hidingRooms', $store.state.locale) }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                    <!-- \ Hide and show resources / -->

                    <v-tooltip dark bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-1" v-on="on" depressed :dark="slotDuration != '00:05:00'" fab :disabled="slotDuration == '00:05:00'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small @click.native="resizeCalendarZoomIn()">
                          <v-icon>mdi-magnify-plus-outline</v-icon>
                        </v-btn>
                      </template>
                      <span class="tooltip-text-white">{{ $t('calendar.zoomIn', $store.state.locale) }}</span>
                    </v-tooltip>

                    <v-tooltip dark bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-1" v-on="on" fab depressed :dark="slotDuration != '00:30:00'"  :disabled="slotDuration == '00:30:00'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small @click.native="resizeCalendarZoomOut()">
                          <v-icon>mdi-magnify-minus-outline</v-icon>
                        </v-btn>
                      </template>
                      <span class="tooltip-text-white">{{ $t('calendar.zoomOut', $store.state.locale) }}</span>
                    </v-tooltip>

                    <!-- / Open Cash Up Modal \ -->
                    <v-tooltip dark bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-1" v-on="on" fab outlined depressed dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  small @click.native="openCashRegister()">
                          <v-icon>mdi-cash-register</v-icon>
                        </v-btn>
                      </template>
                      <span class="tooltip-text-white">{{ $t('payment.registerCheck', $store.state.locale) }}</span>
                    </v-tooltip>
                    <!-- \ Open Cash Up Modal / -->

                  

                    <!-- / Edit schedule exceptions of today \ -->
                    <v-badge
                      v-if="timeRegistrationInfo.show && !calendarLoading && !otherDayLoading"
                      :content="timeRegistrationInfo.number"
                      :value="timeRegistrationInfo.showBatch"
                      color="error"
                      overlap
                      bordered
                      offset-x="15"
                      offset-y="15"
                      >
                      <v-tooltip dark bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-1" v-on="on" fab outlined depressed dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small @click.native="openTimeRegistration()">
                              <v-icon>mdi-clock</v-icon>
                            </v-btn>
                          </template>
                        <span class="tooltip-text-white">{{ $t('employees.timeRegistration', $store.state.locale) }}</span>
                      </v-tooltip>
                    </v-badge>
                    <!-- \ Edit schedule exceptions of today / -->

                    
                    
                  </div>
                </div>

                <div style="display: block; float: right; width: 160px" class="pt-1">
                  <v-btn icon @click.native="prev()">
                    <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-chevron-left</v-icon>
                  </v-btn>
                  <!-- :return-value.sync="chosenDateCalendar" -->
                  <v-menu ref="menu" :close-on-content-click="true" v-model="menu" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker v-model="chosenDateCalendar" locale-first-day-of-year="4" show-week no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  scrollable :first-day-of-week="1" v-on:input="selectDate(chosenDateCalendar)"></v-date-picker>
                  </v-menu>
                  <v-btn icon @click.native="next(1)">
                    <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-chevron-right</v-icon>
                  </v-btn>

                  <v-menu offset-y :close-on-content-click="true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon class="mx-1" v-on="on" v-bind="attrs" fab dark small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-chevron-double-right</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense class="ma-0 pa-0">
                      <v-list-item-group class="ma-0 pa-0">
                        <v-list-item  @click.native="next(7)">
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('calendar.oneWeekNext', $store.state.locale) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item  @click.native="next(14)">
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('calendar.twoWeeksNext', $store.state.locale) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item  @click.native="next(21)">
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('calendar.threeWeeksNext', $store.state.locale) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item  @click.native="next(28)">
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('calendar.fourWeeksNext', $store.state.locale) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item  @click.native="next(35)">
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('calendar.fiveWeeksNext', $store.state.locale) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item  @click.native="next(42)">
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('calendar.sixWeeksNext', $store.state.locale) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item  @click.native="next(49)">
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('calendar.sevenWeeksNext', $store.state.locale) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item  @click.native="next(56)">
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('calendar.eightWeeksNext', $store.state.locale) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        <!-- <div class="calendar-date-navigation">
          <v-btn icon @click.native="prev()">
            <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-chevron-left</v-icon>
          </v-btn>
          <v-menu ref="menu" :close-on-content-click="true" v-model="menu" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-calendar</v-icon>
              </v-btn>
            </template>
            <v-date-picker v-model="chosenDateCalendar" no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  scrollable :first-day-of-week="1" v-on:input="selectDate(chosenDateCalendar)"></v-date-picker>
          </v-menu>
          <v-btn icon @click.native="next()">
            <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-chevron-right</v-icon>
          </v-btn>
        </div> -->
      </div>

      

      <div class="content-box" style="position: relative;">

        <!-- {{ humanResources }} -->
<!--
        <div v-if="wolfload" style="position: absolute; top: 0; left: 0; z-index: 99; min-height: 500px; height: 100%; width: 100%">
          <div class="thrive-loader"></div>
        </div> -->
        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow ma-4 mr-0" :class="$store.state.companyTheme" v-show="!calendarLoading && humanResources">

          <div class="no-data-message" v-show="calendarLoading == false && branchesAvailable == false">
            <p>{{ $t('calendar.noBranches', $store.state.locale) }}</p>
            <router-link to="/branches" custom v-slot="{ navigate }">
              <div @click="navigate" class="menu-item clear">
                <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="newBranchDialog = true">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </div>
            </router-link>
          </div>

          <div class="calendarcontainer" v-show="branchClosed === false">
            <div style="min-width: 100%">
              <FullCalendar  ref="fullCalendar" :options="calendarOptions"> </FullCalendar>
            </div>
          </div>
        </v-card>

        <div v-show="(!calendarLoading && !humanResources) === true || branchClosed === true" class="text-center" style="padding: 150px 0;">
          <v-avatar 
            size="76"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon v-if="branchClosed === false" large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-search</v-icon>
            <v-icon v-else large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-remove</v-icon>
          </v-avatar> 
          <div v-if="branchClosed === false" style="font-size: 18px;">{{ $t('calendar.noEmployees', $store.state.locale) }}</div>
          <div v-else style="font-size: 18px;">
            {{branchClosedReason}}<br>
            <span style="font-size:14px"><i>{{ $t('calendar.branchClosed', $store.state.locale) }}</i></span>
          </div>
        </div>
        
      </div>
      <div v-show="otherDayLoading && !branchClosed" style="position: absolute; top: 0; left: 0; z-index: 99; min-height: 500px; height: 100%; width: 100%">
        <div class="thrive-loader"></div>
      </div>
    </section>

    <!-- / Inform Client Modal (Send e-mail to client) \ -->
    <inform-client-modal></inform-client-modal>
    <!-- \ Inform Client Modal (Send e-mail to client) / -->

    <!-- / Booking Subscriptions Modal \ -->
    <bookingsubscriptions-modal :activeCompData="activeCompData" :activeBranch="activeBranch"></bookingsubscriptions-modal>
    <!-- \ Booking Subscriptions Modal / -->

    <!-- / Show Change time \ -->
    <change-treatment-time
      ref="changeTreatmentTimeFunc"
      :selectedEvent="selectedEvent"
      :selectedEventOriginal="selectedEventOriginal"
      :activeCompData="activeCompData"
      :activeBranch="activeBranch"
      :activeBranchData="activeBranchData"
      :resources="resourceObjects"
      @changeTreatmentTimeChange="highlightAllRelatedEvents; userActivity(); dialogOpen = false"
      @disableHighlightTimeChange="highlightAllRelatedEvents"
      @openCustomerCard="openCustomerCard()"
    ></change-treatment-time>
    <!-- / Show Change time \ -->
  

    <!-- / Show Book room Card Dialog \ -->
    <add-room-booking
      ref="addRoomBookingFunc"
      :selectRoom="selectRoom"
      :localetime="chosenDateCalendar"
      :selectedStart="selected_Calendar_Start"
      :selectedEnd="selected_Calendar_End"
      :selectedResourceInfo="selectedResource"
      :activeBranch="activeBranch"
      :activeCompData="activeCompData"
      :bookingen="roomBookingen"
      :addRoomBookingModal="addRoomBookingModal"
      :devicesLoadedFilteredRoom="devicesLoadedFilteredRoom"
      @addRoomBookingModalChange="addRoomBookingModal = $event; dialogOpen = false"
    ></add-room-booking>
    <!-- \ Show Book room Card Dialog / -->

    <!-- \ Custome Swal box / 
    <app-swal-box
      :swalBoxDialog="swalBox"
      :customEvent="swalCustomEvent"
      :buttonLabel="swalButtonLable"
      :extraButton="swalExtraButton"
      :showHideOption="swalHideOption"
      :title="swalTitle"
      :body="swalBody"
      width="400"
      @swalBoxChange="swalBox = $event;"
    ></app-swal-box> -->

    <!-- / edit employee dialog \ -->
    <!-- <edit-employee-form
      ref="editEmployee"
      :source="'calendar'"
      :activeCompData="activeCompData"
      :branches="branches"
      :selectedEmployee="selectedEmployeeInfo"
      :selectedEmployeeOriginal="selectedEmployeeInfoOriginal"
    ></edit-employee-form> -->
    <!-- \ edit employee dialog / -->

    <!-- / edit room dialog \ -->
    <edit-room-form
      ref="editRoom"
      :source="'calendar'"
      :activeCompData="activeCompData"
      :activeBranch="activeBranch"
    ></edit-room-form>
    <!-- \ edit room dialog / -->

    <!-- / Create Booking Dialog \ -->
    <create-booking
      ref="createBooking"
      :viawidget="widgettype"
      :devices="devicesLoaded"
      :rooms="roomsLoaded"
      :createBookingDialog="createBookingDialog"
      :activeCompData="activeCompData"
      :activeBranchData="activeBranchData"
      :selectedBranch="selectedBranch"
      :givenStartDate="selected_Calendar_Start"
      :selectedResourceEvents="selectedResourceEvents"
      @BookingEditChange="createBookingDialog = $event, dialogOpen = false"
      @BookingAlternative="bookingEditDialog = $event"
      @triggerRefetch="calendar.refetchEvents()"
      @refetch="calendar.refetchEvents()"
    ></create-booking>
    <!-- \ Create Booking Dialog / -->

    <!-- \ Show Book Event Card Dialog / -->
    <add-event-booking
      ref="CalbookEventFunc"
      :selectRoom="selectRoom"
      :localetime="chosenDateCalendar"
      :activeBranch="activeBranch"
      :activeCompData="activeCompData"
      :bookingen="eventsObjects"
      :startTime="selected_Calendar_Start"
      :endTime="selected_Calendar_End"
      :selectedResource="selectedResource"
      @CalbookEventModalChange="CalbookEventModal = $event, dialogOpen = false"
      @triggerRefetch="calendar.refetchEvents()"
      :CalbookEventModal="CalbookEventModal"
    ></add-event-booking>
    <!-- \ Show Book Event Card Dialog / -->

    <!-- / Add Pause Dialog \ -->
    <add-pause-form
      ref="AddpauseFunc"
      :dialogPause="dialogPause"
      :activeBranch="activeBranch"
      :activeCompData="activeCompData"
      @DialogPauseChange="dialogPause = $event, dialogOpen = false"
      :chosenDateCalendar="chosenDateCalendar"
      :startTime="selected_Calendar_Start"
      :endTime="selected_Calendar_End"
    ></add-pause-form>
    <!-- \ Add Pause Dialog / -->

    <!-- / Edit Pause Dialog \ -->
    <edit-pause
      ref="editPause"
      :selectedPauseOriginal="selectedPauseOriginal"
      :selectedPause="selectedPause"
      :activeCompData="activeCompData"
      :activeBranch="activeBranch"
      @triggerRefetch="calendar.refetchEvents()"
      @refetch="calendar.refetchEvents()"
    ></edit-pause>
    <!-- \ Edit Pause dialog / -->

    <!-- / Customer info dialog \ -->
    <customer-information-dialog
      :selectedClientName="selectedClientName"
      :client="selectedClient"
      :customerDialog="customerDialog"
      :activeCompData="activeCompData"
      :activeBranch="activeBranch"
      @customerDialogChange="customerDialog = $event; userActivity();"
    ></customer-information-dialog>
    <!-- \ Customer info dialog / -->

       <!-- / Open Thrive Assistant \ -->
    <v-tooltip dark bottom v-if="!$store.state.demoMode && $store.state.userRoleNumber == 0">
      <template v-slot:activator="{ on }">
        <v-btn class="thriveAssistent-button" v-on="on" fab  depressed dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  small @click.native="useAssistant('planning')">
          <v-icon>mdi-assistant</v-icon>
        </v-btn>
      </template>
      <span class="tooltip-text-white">Thrive Assistent</span>
    </v-tooltip>
    <!-- \ Open Thrive Assistant / -->

    <!-- / Developer Cache Preview \ -->
    <v-btn
      class="cache-button"
      v-show="!cachePreview.active && !$store.state.demoMode && $store.state.userRoleNumber == 0"
      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
      dark
      small
      @click="cachePreview.active = true"
      >
      Show cache
    </v-btn>

    <v-card class="cache-preview" flat :class="cachePreview.type" v-if="cachePreview.active" :elevation="cachePreview.type == 'large' ? 5 : 2">
      <v-card-title class="px-1 py-0" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h4>Cache preview</h4>

        <v-spacer></v-spacer>

        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-square</v-icon> <h5 style="font-size: 12px" class="mr-2">Not swappable </h5>
        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-square</v-icon> <h5 style="font-size: 12px">Swappable</h5>

        <v-spacer></v-spacer>

        <v-btn x-small dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="generateDemoBookingsDialog()" >
          <span style="font-size: 12px!important">Demo Bookings</span>
        </v-btn>

        <v-tooltip dark top> 
          <template v-slot:activator="{ on }">
            <v-btn icon dark  v-on="on" @click="compareCacheWithBookings()">
              <v-icon size="18" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-select-compare</v-icon>
            </v-btn>
          </template>
          <span class="tooltip-text-white" style="font-size: 11px"><strong>Cache handmatig genereren</strong> <br>Verwijderd niet de huidige cacheDocs maar genereerd nieuwe cacheDocs als deze nog niet aanwezig is of update/ verwijderd bestaande cacheDocs.</span>
        </v-tooltip>

        <v-tooltip dark top  v-if="cachePreview.type == 'small'"> 
          <template v-slot:activator="{ on }">
            <v-btn icon dark  v-on="on" @click="cachePreview.type = 'large'">
              <v-icon size="18" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-arrow-expand</v-icon>
            </v-btn>
          </template>
          <span class="tooltip-text-white" style="font-size: 11px"><strong>Weergave vergroten</strong> <br>Vergroot weergave om begin en eindtijden van de cache-bookings te zien.</span>
        </v-tooltip>


        
       
        <v-btn v-if="cachePreview.type == 'large'" icon dark @click="cachePreview.type = 'small'">
          <v-icon size="18" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-arrow-collapse</v-icon>
        </v-btn>

        <v-btn icon dark @click="cachePreview.active = false">
          <v-icon size="18" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div v-if="grouppedRoomsDevices && grouppedRoomsDevices.length > 0">
        <div :style="{width: (grouppedRoomsDevices.length * 100) + 'px'}" class="cache-preview-resoucres-wrapper">
          <div class="cache-preview-resource" v-for="(resource, index) in grouppedRoomsDevices" :key="index" :style="{width: (100 / grouppedRoomsDevices.length) + '%' }">
            <div class="cache-preview-resource-title" :class="{'cache-present' : findResourceCacheDocuments(resource) > 0}">
              <div class="cache-preview-resource-title-text">
                {{ resource.title }}
                <div class="docs" v-if="findResourceCacheDocuments(resource) > 0">
                  CacheDocs: <strong>{{ findResourceCacheDocuments(resource) }}</strong>
                </div>
              </div>
              <div class="cache-preview-resource-title-action">
                <v-tooltip dark top> 
                  <template v-slot:activator="{ on }">
                    <v-btn class="mt-1" text icon color="#fff" v-on="on" @click="removeResourceCacheDocuments(resource)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip-text-white" style="font-size: 11px">Verwijderd alle cacheDocs van deze resource op deze dag.</span>
                </v-tooltip>

              </div>              
            </div>
          </div>
        </div>
      </div>
      <v-card-text class="ma-0 pa-0" style="overflow-y: scroll; max-height: 1000px">
        <div v-if="grouppedRoomsDevices && grouppedRoomsDevices.length > 0">
          <div :style="[{width: (grouppedRoomsDevices.length * 100) + 'px'}, {height: (cachePreview.type == 'small' ? minutesOfDay * 3 : minutesOfDay * 15) + 'px'}]" class="cache-preview-wrapper" :class="cachePreview.type">
            <div class="cache-preview-cache" v-for="(resource, index) in grouppedRoomsDevices" :key="index" :style="{width: (100 / grouppedRoomsDevices.length) + '%' }">
              <div class="cache-preview-timegrid">
                <div v-for="n in minutesOfDay()" :key="n" class="cache-preview-timegrid-row" :class="[{swapping: cachePreviewObject[resource.id] && cachePreviewObject[resource.id][n]&& cachePreviewObject[resource.id][n].swapping},{filled: cachePreviewObject[resource.id] && cachePreviewObject[resource.id][n]&& cachePreviewObject[resource.id][n].cache}, { 'bold': checkForRowHour(n) }]">
                  <div v-if="cachePreviewObject[resource.id] && cachePreviewObject[resource.id][n] && cachePreview.type == 'large'" >
                    <span v-if="cachePreviewObject[resource.id][n].type == 'start'"> Start: </span> 
                    <span v-if="cachePreviewObject[resource.id][n].type == 'end'"> End: </span>
                    {{ extraCacheData(n, cachePreviewObject[resource.id][n].type) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pa-4 text-center">
          <p>Geen resources aanwezig</p>
          <div v-if="resourceCache && resourceCache.length > 0">
            Cache-documenten aanwezig voor deze dag. Aantal: {{ resourceCache.length}}.
          </div>
          <div v-else>
            Geen Cache-documenten aanwezig voor deze dag.
          </div>
        </div>

        
      </v-card-text>
    </v-card>
    <!-- \ Developer Cache Preview / -->

    <!-- / Edit Lead Dialog \ -->
    <div v-if="viewType != 'scheduleLead'">
      <edit-lead-form :activeCompData="activeCompData" :branches="branches" :customSources="customSources" :selectedBranch="activeBranch"></edit-lead-form>
    </div>
    <!-- \ Edit Lead Dialog / -->

    <!-- / Demo Bookings Dialog \ -->
    <v-dialog v-if="$store.state.userRoleNumber == 0" v-model="demoBookingsDialog.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`" @click:outside="resetDemoBookingsDialog()">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-month-outline</v-icon>
          </v-avatar>
          <h3 class="mb-2">Genereer bookingen</h3> 
          <p>Voor testen en demo's</p>

          <v-text-field
            outlined
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            label="Aantal bookingen"
            dense
            v-model.number="demoBookingsDialog.number"
            hide-details
            class="mt-4"
          ></v-text-field>

          <v-select
            outlined
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            item-text="name"
            item-value="id"
            hide-details
            dense
            class="mt-4"
            :items="demoBookingSpeeds"
            v-model="demoBookingsDialog.speed"
            label="Bookingssnelheid"
          ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="demoBookingsDialog.loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="resetDemoBookingsDialog()">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="demoBookingsDialog.loading" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="generateDemoBookings()">
                   Genereren
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Demo Bookings Dialog / -->

    <!-- / Restore Employee Dialog\ -->
    <v-dialog v-model="noShowDialog.show" persistent max-width="280" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-divider></v-divider>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon v-if="noShowDialog.type == 'noShow'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cancel</v-icon>
            <v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-undo</v-icon>
          </v-avatar>
          <h3 v-if="noShowDialog.type == 'noShow'" class="mb-3">{{ $t('calendar.noShowExplain', $store.state.locale) }}</h3>
          <h3 v-else class="mb-3">{{ $t('calendar.noShowRestoreExplain', $store.state.locale) }}</h3>
          
          <p v-if="noShowDialog.type == 'noShow'" class="ma-0" v-html="$t('calendar.noShowExplainHint', $store.state.locale)"></p>
          <p v-else class="ma-0" v-html="$t('calendar.noShowRestoreExplainHint', $store.state.locale)"></p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn dark block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="noShowDialog.show = false"> {{ $t('general.continue', $store.state.locale) }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Restore Employee Dialog / -->
    

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackBarStatus" top>
      <v-row>
        <v-icon color="white" class="mx-3">{{ snackbarIcon }}</v-icon>
        <span class="py-2 white--text">{{ snackBarMessage }}</span>
      </v-row>
    </v-snackbar>
  </div>
</template>

<style lang="css">

  .week-data-wrapper .weeknumber{
    font-size: 14px;
    padding: 4px;
    font-weight: 500;
    color: var(--primary-color);
  }

  .day-view-date{
    position: relative;
    width: 80px;
    height: 20px;
    float: left;
    /* margin: 5px 0; */
    padding: 4px 0 4px 4px;
    font-size: 11px;
    line-height: 20px;
  }
  .day-view-date.current{
    color: var(--primary-color);
  }

  .day-view{
    position: relative;
    width: calc(100% - 80px);
    height: 20px;
    float: left;
    margin: 4px 0;
  }

  .day-view-wrapper:hover{
    background-color: #f1f1f950;
    cursor: pointer;
  }

  .day-view-wrapper:after,
  .day-view:after{
    clear: both;
    content: "";
    display: block;
  }

  .time-block-view{
    position: absolute;
    top: 0;
    height: 20px;
    background-color: var(--background-color);
    /* border-radius: 5px; */
  }

  .time-block-view:hover .time-block-view-times{
    display: block;
  }

  .time-block-view-times{
    position: absolute;
    top: 2px;
    left: 3px;
    background-color: rgba(0,0,0,0.78);
    color: #fff;
    font-size: 11px;
    text-align: center;
    width: 85px;
    line-height: 1;
    border-radius: 5px;
    padding: 3px 5px;
    z-index: 999;
    display: none; 
  }

  .block-view{
    background-color: var(--heading-color);
    position: absolute;
    top: 0;
    height: 20px;
  }

  .time-block-view.pause{
    /* display: none; */
    background-color: var(--textSmooth-color);
  }

  .time-block-view.firstItem{
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  .time-block-view.lastItem{
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .block-view.treatment{
    background-color: var(--primary-color);
  }

  .block-view.swapping{
    background-color: var(--primary-color);
  }

  .employee-future-schedule-popup{
    position: absolute;
    z-index: 999;
    width: 500px;
    
    /* top: 100px;
    left: 100px;
    background-color: #fff;
    padding: 20px;
    
 
    border-radius: 20px; */

  }

  .employee-future-schedule-popup.left:before{
    width: 20px;
    height: 20px;
    position: absolute;
    content: "";
    border: 14px solid var(--cardOption-color);
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    top: -28px;
    left: 20px
  }

  .employee-future-schedule-popup.center:before{
    width: 20px;
    height: 20px;
    position: absolute;
    content: "";
    border: 14px solid var(--cardOption-color);
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    top: -28px;
    left: 236px;
  }

  .employee-future-schedule-popup.right:before{
    width: 20px;
    height: 20px;
    position: absolute;
    content: "";
    border: 14px solid var(--cardOption-color);
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    top: -28px;
    right: 20px
  }



  .cache-preview{
    min-width: 150px;
    min-height: 150px;
    opacity: 0.75;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9;
    max-height: 70%;
    overflow: hidden;
  }

  .cache-preview.large{
    opacity: 0.9
  }

  .cache-button{
    position: fixed;
    bottom: 30px;
    right: 90px;
    z-index: 99;
  }

  .thriveAssistent-button{
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 99;
  }


  .cache-preview-resoucres-wrapper{
    display: table;
    
  }

  .cache-preview-wrapper{
    overflow-y:scroll;
  }
  .cache-preview-resoucres-wrapper:after,
  .cache-preview-wrapper:after{
    content: "";
    clear: both;
    display: block
  }
  .cache-preview-resource{
    display: table-cell;
    height: 100%;
    border-right: 1px solid #cccccc;
    position: relative;
  }
  .cache-preview-cache{
    float: left;
    height: 100%;
    border-right: 1px solid #cccccc;
  }

  .cache-preview-resource-title{
    text-align: center; 
    font-size: 10px; 
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    line-height: 1.5;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    color: #fff;
    vertical-align: middle;
    background-color: #333
  }
  .cache-preview-resource-title .cache-preview-resource-title-text{
    font-size: 10px; 
    line-height: 1.5;
    font-weight: bold;
    vertical-align: middle;
    text-overflow: ellipsis;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 2px;
  }

  .cache-preview-resource-title-action{
    display: block;
    height: 46px;
    padding: 2px;
    position: relative;
    background-color:red
  }

  .cache-preview-resource-title.cache-present:hover .cache-preview-resource-title-text,
  .cache-preview-resource-title-action{
    display: none;
  }

  .cache-preview-resource-title-text,
  .cache-preview-resource-title.cache-present:hover .cache-preview-resource-title-action{
    display: block;
  }

  .cache-preview-resource-title .docs{
    font-size: 10px; 
  }

  .cache-preview-resource-title.cache-present{
    background-color: var(--primary-color);
  }

  .cache-preview-timegrid-row{
    height: 3px;
    width: 100%;
    border-top: 1px solid #cccccc;
  }

  .cache-preview-timegrid-row.bold{
    height: 3px;
    width: 100%;
    border-top: 1px solid #666666;
  }

  .cache-preview-wrapper.large .cache-preview-timegrid-row{
    height: 15px;
  }
  .cache-preview-timegrid-row div,
  .cache-preview-timegrid-row div span{
    font-size: 8px;
    line-height: 1;
    padding: 3px;
    font-weight: bold;
    color: #ffffff
  }
  .cache-preview-timegrid-row.filled{
    background-color: var(--secondary-color);
  }
  .cache-preview-timegrid-row.filled.swapping{
    background-color: var(--primary-color);
  }

  #bubble {
    position: absolute;
    padding: 2%;
    z-index: 600;
    visibility: hidden;
    background-color: #fff;
    border: 1px solid #e8e9ee;
    border-radius: 4px;
    -webkit-box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.25);
    transition: opacity 100ms ease-in-out;
    opacity: 0;
  }

  #bubble-is-visible {
    position: absolute;
    z-index: 600;
    visibility: visible;
    background-color: #fff;
    border: 1px solid #e8e9ee;
    border-radius: 5px;

    -webkit-box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.25);
    opacity: 1;
  }
  #bubble-is-visible button {
    margin: 3%;
  }

  .timecolumn {
    position: absolute;
    left: 51px;
    width: 300px;
    height: 300px;
    z-index: 999;
  }

  .calendarcontainer .fc-theme-standard .fc-scrollgrid {
    border: 1px;
  }

  .local-time {
    position: absolute;
    text-align: center;
    width: 250px;
    left: 50%;
    margin: 0 0 0 -125px;
  }

  .showSubscriptions {
    color: white;
  }

  .devicesicon {
    background: url("../assets/icons/device.svg") no-repeat center center;
    margin-left: 30px;
  }
  .roomsicon {
    background: url("../assets/icons/roomcube.svg") no-repeat center center;
  }

  .calendar-title .date-float,
  .calendar-title .title-float {
    width: 50%;
    float: left;
  }

  .calendar-title .date-float p {
    text-align: right;
  }

  .calendar-title .title-float h1 {
    margin: 0;
    padding: 0;
    font-size: 26px;
    color: #251433;
  }

  .calendar-select-company,
  .calendar-select-settings {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  .calendar-select {
    margin-bottom: 10px;
  }

  .admin-menu-add .v-btn {
    margin: 0 10px 10px 0;
  }

  .calendar-select-company .v-input {
    margin: 5px 0 0 0;
    padding: 0;
  }

  div.fc-toolbar.fc-header-toolbar {
    display: none;
  }
  .calendarcontainer .fc-head-container.fc-widget-header,
  .calendarcontainer .fc-head-container.fc-widget-header th {
    border: none;
    background: none;
  }
  .calendarcontainer th.fc-resource-cell {
    text-indent: -99999px;
  }

  .calendarcontainer .fc-time-grid-event {
    padding: 4px;
    border: 1px;
    border-radius: 2px;
  }

  .calendarcontainer .fc-col-header-cell-cushion {
    display: block;
  }
  .resourceinfo {
    text-indent: 0;
    /* padding: 2.5%; */
  }

  .resourceinfo {
    padding: 3px 5px 1px 5px;
    margin: 0;
  }

  .resourceHover:hover {
    cursor: pointer;
    opacity: 1;
  }

  .resName {
    float: left;
    display: table;
    padding: 0;
    text-align: left;
    max-width: calc(100% - 50px);
    font-size: 12px;
    font-weight: 600;
    margin: 0 5px;
    height: 34px;
    
  }
  .resName span{
    padding: 2px;
    font-size: 13px;
    line-height: 16px;
    display: table-cell;
    vertical-align: middle;
  }
  
  .resInfo:after{
    content:"";
    display: block;
    clear: both;
  }


  .resPhoto {
    float: left;
    padding: 0;
    margin: 0 0 0 5px;
    text-align: center;
    width: 34px;
    overflow: hidden;
    height: 34px;
    border-radius: 50px;
    background-color: #ccc !important;
    color: var(--v-text-base);
    opacity: 0.75;
    border: 3px solid var(--v-background-base);
  }
  .resPhoto.adjusted {
    border: 3px solid var(--secondary-color);
  }

  .resPhoto img {
    /* width: 58px; */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .resPhoto img.placeholder {
    width: 34px !important;
    padding: 5px;
    height: auto;
  }

  .resPhoto img.placeholder.notes {
    background-color: var(--primary-color);
  }

  .groupColour {
    display: none;
    width: 24px;
    height: 8px;
    margin: 10px auto 0px;
    border-radius: 30px;
  }

  span.mobileDevice {
    position: absolute;
    background-color: var(--primary-color);
    height: 18px;
    width: 18px;
    margin: 18px 0 0 -12px;
    color: white;
    font-size: 13px;
    border-radius: 30px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  }

  span.trainee {
    position: absolute;
    background-color: var(--primary-color);
    height: 20px;
    width: 20px;
    margin-top: -42px;
    margin-left: 10px;
    color: white;
    font-size: 14px;
    border-radius: 30px;
  }
  
  .calendarcontainer .fc .fc-timegrid-now-indicator-container{
    overflow:visible;
  }
  .calendarcontainer .fc-now-indicator {
    
    position: absolute;
    /* color: #5f1d9a !important; */
    border: 1px solid #f48c00;
  }

  .fc .fc-timegrid-now-indicator-line {
    position: absolute;
    z-index:8;
    left: 0;
    pointer-events: none;
    opacity: 0.3;
    right: 0;
    border-style: solid;
    border-color: var(--primary-color);
    border-width: 2px 0 0 0!important;
  }
  .fc .fc-timegrid-now-indicator-arrow {
    display: none;
    border-left-color: #f48c00;
  }

  .calendarcontainer .fc-timegrid-cols table{
    height: 100%;
  }

  /* .calendarcontainer .fc-timegrid-cols table .fc-timegrid-col{
    background-color: var(--v-primary-menu) !important;
  } */

  /* .calendarcontainer .fc .fc-timegrid-col-events{
    z-index: auto
  } */

  .calendarcontainer .fc-timegrid-cols table .fc-timegrid-col .fc-timegrid-col-frame{
    background-color: var(--v-primary-menu);
  }

  #calendar td.fc-day-today,
  .calendarcontainer .fc-unthemed td.fc-day.fc-widget-content,
  .calendarcontainer .fc-unthemed td.fc-day-today {
    background: var(--v-menu-base) !important;
  }
  .fc-day-today {
    background: var(--v-menu-base) !important;
  }

  .fc-v-event {
    border: 3px solid rgba(0, 0, 0, 0) !important;
  }

  table {
    /* width: 100% !important; */
  }

  .calendarcontainer .fc-row {
    border-right-width: 0px !important;
  }

  .calendarcontainer .fc-time-grid-event .fc-time {
    width: 35px;
    height: 100%;
    float: left;
  }
  .calendarcontainer .fc-time-grid-event .fc-time span {
    color: #fff !important;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 2px 5px 0 5px;
    display: inline-block;
    border-radius: 2px;
  }
  .fc-title {
    display: none;
  }

  /* timegrid */
  th.fc-timegrid-axis {
    border: 1px;
  }

  .progressBar {
    width: 90%;
    height: 10px;
    margin-left: 1.5px;
    position: absolute;
    bottom: 5px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    display: none;
  }

  .move-group {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    top: 2px;
    right: 2px;
    z-index: 15;
    background: rgba(0, 0, 0, 0.3) url("../assets/icons/checkbox-multiple-blank-outline.svg") no-repeat center center;
    background-size: 20px auto;
    display: none;
  }

  .calendarcontainer .fc-timegrid-event:hover .move-group {
    display: block;
  }

  .calendarcontainer .fc-timegrid-event.group-selected .move-group {
    position: absolute;
    background: rgba(0, 0, 0, 0.3) url("../assets/icons/checkbox-multiple-marked.svg") no-repeat center center;
    background-size: 20px auto;
    display: block;
  }

  .calender-event-title {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    padding: 0 0 0 0px;
    display: block;
  }
  .calender-event-description {
    color: #fff;
    font-size: 11px;
    line-height: 12px;
    font-weight: 400;
  }
  .calender-event-user a {
    text-decoration: none;
    color: #fff; 
  }
  .calender-event-user {
    display: inline-block;
    /* background: url("../assets/icons/calendar-user-icon-white.svg") no-repeat left center;
    padding: 0 0 0 15px; */
    background-size: 10px auto;
    text-decoration: underline;
    font-size: 13px;
  }
  .calender-event-user:hover {
    cursor: pointer;
  }

  .calender-event-avatar svg{
    width: 14px;
    height: 14px;

    margin: 5px 5px 0 0;
    float: left;
  }

  .calendar-event-maxPersons {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    padding: 0 0 0 0px;
  }

  .calendar-user-icon {
    height: 10px;
    width: 10px;
    content: "";
    display: inline-block;
    background: url("../assets/icons/calendar-user-icon-white.svg") no-repeat bottom center;
    background-size: auto 10px;
    margin: 0 4px 0 0;
  }

  .calendarcontainer .fc .fc-timegrid-slot {
    height: 2.0em;
  }

  .fc .fc-timegrid-slot-label {
    vertical-align: bottom;
  }

  .calender-event-user a:hover {
    text-decoration: underline;
  }

  .selected-date {
    font-size: 16px;
    padding: 0 0 0 20px;
  }

  .select-calendar-devices-check {
    padding: 0 0 0 20px;
    min-width: 200px;
  }

  #calendar {
    width: 100%;
    height: 84vh;
    margin-bottom: 100px;
  }

  .calendarcontainer .fc-view-container .fc-view {
    overflow: hidden !important;
  }

  .calendarcontainer .fc-view-container .fc-view > table {
    width: var(--calendar-width);
  }

  button.swal2-confirm.swal2-styled,
  button.swal2-cancel.swal2-styled {
    border-radius: 20px !important;
  }

  .calendarcontainer .fc-event.fc-helper {
    background-color: #00adef !important;
    border: none;
  }

  .event-header {
    display: flex;
    background: rgba(0, 0, 0, 0.1);
    align-items: center;
    border-radius: 5px;
    padding: 1px 2px 1px 3px;
  }

  .event-header .eve-time {
    font-weight: bold;
    font-size: 12px;
  }

  .calendarcontainer .fc-timegrid-event-harness {
    border-radius: 5px;
    box-shadow: none
  }
  
  .fc-scroller-harness .fc-daygrid-body.fc-daygrid-body-unbalanced.fc-daygrid-body-natural .fc-scrollgrid-sync-table .fc-daygrid-day{
    border-color: var(--card-color);
  }
  .calendarcontainer th.fc-col-header-cell.fc-resource {
    border-bottom: none!important;
    /* border: 1px solid var(--primary-color)!important; */
    border-right: none!important;
    border-left: none!important;
  }

  .booking-type {
    display: block;
    float: right;
    margin: 2px 2px 0 2px; 
    height: 14px;
    line-height: 1.1;
    width: 13px;
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
  }

  .booking-notes {
    margin-top: 5px;
    left: 2px;
    padding: 4px;
    background: rgba(0, 0, 0, 0.07);
    height: auto;
    line-height: 1.1;
    width: calc(100% - 4px);
    color: #ffffff;
    border-radius: 3px;
    font-size: 12px;
    border-radius: 4px;
    text-align: left;
  }

  .calender-event-title.task {
    margin: 5px 0 0 26px;
    width: calc(100% - 20px);
  }

  .task-type {
    position: absolute;
    z-index: 15;
    top: 28px;
    left: 6px;
    height: 19px;
    width: 19px;
    color: #ffffff;
    background: url("../assets/icons/checkbox-blank.svg") no-repeat bottom center;
    background-size: auto 19px;
  }

  .task-type.done {
    background: url("../assets/icons/checkbox-marked.svg") no-repeat bottom center;
    background-size: auto 19px;
  }

  .booking-type.unknown {
    display: none;
  }

  .preferred-practitioner {
    display: block;
    float: right;
    height: 18px;
    width: 18px;
    margin: 0 2px;
    /* background: blue url("../assets/icons/calendar-preferred-practitioner-icon-white.svg") no-repeat bottom center; */
    background: url("../assets/icons/calendar-preferred-practitioner-box-icon-white.svg") no-repeat bottom center; 
    background-size: auto 18px;
  }
   /* background:red url("../assets/icons/calendar-preferred-practitioner-box-icon-white.svg") no-repeat bottom center;  */
   /*   background: url("../assets/icons/calendar-preferred-practitioner-icon-white.svg") no-repeat bottom center;*/

  .event-icons{
    position: absolute;
    top: 2px;
    right: 2px;
    width:100%;
    height: 18px;
  }
  .downpayment-done {
    display: block;
    float: right;
    height: 18px;
    width: 18px;
    line-height: 1;
    margin: 0 2px;
  }
  .downpayment-done i{
    font-size: 18px;
  }

  .event-header .eve-desc {
    padding-left: 10px;
    width: calc(100% - 65px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 12px;
  }

  .cus_event {
    color: white;
  }
  .fc-title {
    display: none !important;
  }
  .fc-widget-content {
    height: 30px !important;
  }
  .selected {
    border: 1px solid black;
  }

  .calendarcontainer .fc-axis.fc-time {
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
  }

  .calendarcontainer .cus_event{
    height: 100%;
    overflow: hidden;
  }

  .calendarcontainer .fc-view-container {
    background-color: var(--v-background-base);
  }

  .calendarcontainer .fc-scroller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.calendarcontainer .fc-scroller {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

  label.v-label {
    font-size: 14px !important;
  }
  .blackSelected {
    border: 1px solid black;
  }
  .rotate {
    transform: rotate(-180deg);
  }

  .calendarcontainer .fc-timegrid-event-harness:hover .individual-move{
    display: block;
    z-index: 99;
  }

  .calendarcontainer .fc-timegrid-event-harness:hover .locked .individual-move{
    display: none;
  }

  .calendarcontainer .fc-timegrid-event-harness,
  .calendarcontainer .fc-timegrid-event-harness .fc-event .fc-event-main{
    
    z-index: auto!important;
  }

  .calendarcontainer .fc .fc-daygrid-body-natural .fc-daygrid-day-events{
    margin: 0!important;
  }

  .calendarcontainer .fc-timegrid-axis-frame.fc-scrollgrid-shrink-frame{
    height: 0px!important
  }
  .calendarcontainer .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
    min-height: 0px!important
  }

  .individual-move .mdi.mdi-drag{
    pointer-events: none!important;
  }


  
  .event-extra-info{
    position: absolute;
    bottom: 3px;
    right: 3px;
    width: 18px;
    height: 18px;
    background: rgba(0, 0, 0, 0.25);
    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    border-radius: 2px;
    z-index: auto!important;
  }

  .event-extra-info .mdi{
    font-size: 18px;
    line-height: 18px;
  }

  .event-extra-info-menu:before{
    position: absolute;
    top: -24px;
    right: 5px;
    width: 12px;
    height: 12px;
    border: 12px solid #fff;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    content: "";
  }

  .event-extra-info-menu{
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    right: -7px;
    margin: 0;
    width: 200px;
    height: auto;
    background: #ffffff;
    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }

  .event-extra-info:hover .event-extra-info-menu{
    display: block;
  }

  .extra-info-menu-wrapper.choose{
    width: 100%;
    border: none;
  }

  .extra-info-menu-wrapper{
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  /* .extra-info-menu-wrapper.choose .extra-info-menu-item{
    padding-left: 20px
  } */

  .extra-info-menu-wrapper-title,
  .extra-info-menu-item{
    width: 100%;
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color)
  }

  .day-future .extra-info-menu-item.no-show-item,
  .day-past .extra-info-menu-item.no-show-item,
  .day-future .extra-info-menu-item.present-item,
  .day-past .extra-info-menu-item.present-item,
  .day-past .extra-info-menu-item.completed-item{
    opacity: 0.5
  }

  .day-future .extra-info-menu-item.no-show-item i.mdi-checkbox-blank-outline,
  .day-past .extra-info-menu-item.present-item i.mdi-checkbox-blank-outline,
  .day-future .extra-info-menu-item.present-item i.mdi-checkbox-blank-outline,
  .day-past .extra-info-menu-item.no-show-item i.mdi-checkbox-blank-outline,
  .day-future .extra-info-menu-item.completed-item i.mdi-checkbox-blank-outline,
  .day-past .extra-info-menu-item.completed-item i.mdi-checkbox-blank-outline{
    opacity: 0
  }

  .extra-info-menu-item:after{
    clear: both;
    content: "";
    display: block;
  }

  .extra-info-menu-item .mdi{
    width: 24px;
    line-height: 22px;
    float: left;
  }
  .extra-info-menu-item .info-menu-text{
    width: calc(100% - 24px);
    float: left;
  }

  .extra-info-menu-wrapper-title{
    font-weight: 300;
  }


  .extra-info-menu-item:hover{
    background-color: rgba(0, 0, 0, 0.07);
  }

  .event-extra-info.paid{
    background: rgba(0, 0, 0, 0.27);
  }

  .event-extra-info.present .mdi-check{
    opacity: 0.5;
  }
  
  .unconfirmed-booking{
    position: relative;
    float: right;
    height: 12px;
    margin: 3px 2px 0 2px;
    z-index: 10;
    line-height: 10px;
    width: 18px;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
  }

  .unconfirmed-booking i{
    font-size: 10px;
    color: #999999;
  }

  .unconfirmed-booking.late{
    background-color: #ffb400
  }
  .unconfirmed-booking.late i{
    color: #fff;
  }
  .unconfirmed-booking.overtime{
    background-color: #ff4c51;
  }
  .unconfirmed-booking.overtime i{
    color: #fff;
  }

  .individual-move{
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0,0,0, 0.5);
    height: 28px;
    width: 28px;
    text-align: center;
    border-radius: 3px;
  } 

  .individual-move i{
    font-size: 20px;
  }

  .individual-move .individual-move-tooltip {
    visibility: hidden;
    width: 150px;
    background-color: rgba(0,0,0, 0.6);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 4px 0;
    font-size: 12px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 0;
    margin-left: 0px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .individual-move .individual-move-tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 15px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .individual-move:hover .individual-move-tooltip {
    visibility: visible;
    opacity: 1;
  }

  #calendar-header-wrapper:after{
    clear: both;
    display: block;
    content: ""
  }

  .calendar-header-navigation{
    width: calc(100% - 110px);
    float: left;
  }

  .calendar-date-navigation{
    width: 110px;
    float: left;
    padding: 4px 0 0 0;
  }

  .time-change-item{
    display: table-cell;
    width: calc(100% - 30px);
  }

  .time-change-remove{
    width: 30px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .time-change-wrapper:after{
    clear: both;
    content: "";
    display: block
  }

  .time-change-wrapper{
    display: table-row;
  }


  .time-change-wrapper.remove{
    background-color: rgba(255,0,0,0.1)
  }
  .time-change-wrapper.changed{
    background-color: rgba(255,165,0,0.1)
  }

  .fc .fc-non-business{
    background: var(--background-color);
  }

  .fc-timegrid-event.noShow{
    width: 75%
  }

  /* .fc-timegrid-event.completed,
  .fc-timegrid-event.paid {
    opacity: 0.6
  } */
  .fc-timegrid-event.noShow:before,
  .fc-timegrid-event.completed:before,
  .fc-timegrid-event.paid:before,
  .calendarcontainer .fc-v-event.fc-timegrid-event.event-cut:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    content:"";
    display: block
  }
  .calendarcontainer .fc-v-event.fc-timegrid-event.event-cut {
    /* border: 2px dashed var(--text-color)!important; */
    border: 2px dotted white!important;
    background: repeating-linear-gradient(
      45deg,
      rgba(0,0,0,0),
      rgba(0,0,0,0) 8px,
      rgba(0,0,0,0.1) 8px,
      rgba(0,0,0,0.1) 16px
    );
  }

  .calendarcontainer .fc-v-event.fc-timegrid-event.event-copy,
  .calendarcontainer .fc-v-event.fc-timegrid-event.event-copy.event-demo {
    background: repeating-linear-gradient(
      45deg,
      rgba(0,0,0,0),
      rgba(0,0,0,0) 8px,
      rgba(0,0,0,0.1) 8px,
      rgba(0,0,0,0.1) 16px
    );
  }

  .calendarcontainer .fc-v-event.fc-timegrid-event.event-demo {
    background: repeating-linear-gradient(
      135deg,
      rgba(0,0,0,0),
      rgba(0,0,0,0) 16px,
      rgba(255,255,255,0.1) 16px,
      rgba(255,255,255,0.1) 32px
    );
  }

  
  .calendarcontainer .fc-v-event{
    border: none!important;
    padding: 3px
  }

  .extra-info-menu-item.item-selected{
    background-color: var(--menuActiveSecondary-color);
    color: #ffffff
  }
  
  


</style>
