<template>
  <v-app>
    <section class="register-wrapper clear">
      <v-container grid-list-md >
        <v-layout row wrap>
          <v-flex md6 xs12>
            <div v-if="loading == true">
              <div style="position: relative; padding: 200px 0;">
                <div class="thrive-loader"></div>
              </div>
            </div>
            <div class="register-info-wrapper" v-if="loading == false">
              <div class="slider-wrapper" v-if="functionalities.length > 0">
                <h2
                  style="text-align: center;"
                >Thrive proberen? Bekijk de beschikbare functionaliteiten</h2>

                <Slider
                  ref="Slider"
                  direction="horizontal"
                  :mousewheel-control="true"
                  :performance-mode="true"
                  :pagination-visible="true"
                  :pagination-clickable="true"
                  :loop="true"
                  :speed="500"
                >
                  <div
                    class="functionalities-slide"
                    v-for="(thriveFunctionality, index) in functionalities"
                    :key="index"
                  >
                    <v-img :src="thriveFunctionality.iconUrl" height="125" contain>
                      <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-layout>
                    </v-img>
                    <h4>{{ thriveFunctionality.title}}</h4>
                    <p>{{ thriveFunctionality.description}}</p>
                  </div>
                </Slider>
                <div class="free-version-explanation">
                  <h4>Gratis versie Thrive</h4>
                  <p>De gratis versie van Thrive bied de mogelijk om onze applicatie kostenloos te ontdekken.
                    <br>
                    <br>
                    <strong>Mogelijkheden</strong>
                    <br>De eerste 5 cli&euml;ntendossiers kunnen kostenloos worden aangemaakt. Van alle pakket is het mogelijk om per klant 5 acties uit te voeren zoals; foto's maken, lichaamssamenstelling toevoegen en afspraak in plannen.
                    <br>
                    <br>
                    <strong>Upgraden</strong>
                    <br>Het is altijd mogelijk om pakkten toe te voegen en/of op te zeggen. Aan de eerste 3 gebruikens (medewerkers die kunnen inloggen) zijn geen kosten verbonden.
                  </p>
                </div>
              </div>
            </div>
          </v-flex>

          <v-flex class="register-from-flex" md6 xs12>
            <div class="register-form-wrapper">
              <v-card color="#f9f9f9">
                <v-card-title>
                  <h2>Registreren</h2>
                </v-card-title>

                <div class="hogans-alerts">
                  <v-alert
                    v-if="loginError"
                    :value="loginError"
                    type="warning"
                    transition="scale-transition"
                  >Inloggegevens onjuist</v-alert>
                  <v-alert
                    v-if="feedback"
                    :value="feedback"
                    type="warning"
                    transition="scale-transition"
                  >{{ feedback }}</v-alert>
                  <v-alert
                    v-if="success"
                    :value="success"
                    type="success"
                    transition="scale-transition"
                  >{{ success }}</v-alert>
                </div>

                <v-card-text>
                  <div id="register-form">
                    <v-form
                      ref="registerForm"
                      v-model="validForm"
                      lazy-validation
                      @keyup.native.enter="register()"
                    >
                      <div class="register-fields-box">
                        <h4>Accountgegevens</h4>
                        <v-text-field
                          label="Bedrijfsnaam"
                          v-model="registerData.company.name"
                          :rules="requiredRules"
                          prepend-inner-icon="mdi-domain"
                          required
                          type="text"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                        <v-text-field
                          label="Website"
                          v-model="registerData.company.url"
                          :rules="requiredRules"
                          prepend-inner-icon="mdi-web"
                          required
                          type="text"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                        <v-text-field
                          label="Telefoonnummer"
                          v-model="registerData.company.phonenumber"
                          :rules="requiredRules"
                          :mask="phoneMask"
                          prepend-inner-icon="mdi-phone"
                          required
                          type="text"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                        <v-text-field
                          label="E-mailadres"
                          v-model="registerData.company.email"
                          :rules="emailRules"
                          prepend-inner-icon="mdi-email"
                          required
                          type="text"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                      </div>

                      <div class="register-fields-box">
                        <h4>Accountgegevens</h4>
                        <v-text-field
                          label="Voornaam"
                          v-model="registerData.user.firstName"
                          :rules="requiredRules"
                          prepend-inner-icon="mdi-label"
                          required
                          type="text"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>

                        <v-text-field
                          label="Achternaam"
                          v-model="registerData.user.lastName"
                          :rules="requiredRules"
                          prepend-inner-icon="mdi-label"
                          required
                          type="text"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>

                        <v-text-field
                          label="Email"
                          v-model="registerData.user.email"
                          :rules="emailRules"
                          prepend-inner-icon="mdi-email"
                          required
                          type="text"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>

                        <v-text-field
                          label="Password"
                          autocomplete="false"
                          v-model="registerData.user.password"
                          :rules="passwordRules"
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                          prepend-inner-icon="mdi-lock"
                          required
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                      </div>

                      <v-checkbox required color="primary" v-model="registerData.terms">
                        <div slot="label">Ik ga akkoord met de
                          <v-tooltip bottom>
                            <a
                              slot="activator"
                              color="primary"
                              target="_blank"
                              href="https://www.thrive.nl/algemene-voorwaarden/"
                              @click.stop
                            >Algemene voorwaarden</a>
                            Opent in nieuw venster
                          </v-tooltip>
                        </div>
                      </v-checkbox>

                      <v-btn
                        class="login-button"
                        block
                        color="primary"
                        @click="register()"
                      >Registreren</v-btn>
                    </v-form>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-flex>
          <v-flex xs12>
            <p class="login-disclaimer">
              &copy; {{ currentYear }} Thrive. All rights reserved.
              <a
                href="https://www.thrive.nl/privacy-policy"
                target="_blank"
              >Privacy Policy</a>
            </p>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
  </v-app>
</template>

<script>
import slugify from "slugify";
import db from "../firebase/init";
import '@firebase/firestore';
import Slider from "vue-plain-slider";
import moment from "moment"

export default {
  name: "Register",
  components: {
    Slider
  },
  data() {
    return {
      currentYear: null,
      loading: true,
      validForm: false,
      functionalities: [],
      showPassword: false,
      registerData: {
        company: {
          name: "",
          url: "",
          email: "",
          phonenumber: "",
        },
        user: {
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          accessCompanies: []
        },
        terms: false
      },
      errors: [],
      phoneMask: "#### ## ## ##",
      feedback: null,
      success: null,
      requiredRules: [v => !!v || "Dit veld is verplicht"],
      emailRules: [
        v => !!v || "E-mail is verplicht",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      passwordRules: [v => !!v || "Wachtwoord is verplicht"]
    };
  },

  created() {
    this.currentYear = moment().format("YYYY")
    this.loadFunctionalities();
  },

  methods: {
    loadFunctionalities() {
      this.loading = true;
      db.collection("functionalities")
        .where("active", "==", true)
        .get()
        .then(snap => {
          this.functionalities = [];
          snap.forEach(doc => {
            let functionality = doc.data();
            functionality.id = doc.id;
            this.functionalities.push(functionality);
          });
        })
        .then(() => {
          this.loading = false;
        });
    },

    register() {
      this.feedback = null
      let userId = slugify(this.registerData.user.email, {
        symbols: true,
        remove: /[!@#$%^&*()"';:.,]/g,
        lower: true
      });

      let compNameLower = this.registerData.company.name.toLowerCase();
      let compNameLowerNoSpecial = compNameLower.replace(
        /[!@#$%^&*+()"';:.,]+/g,
        ""
      );
      this.registerData.user.accessCompanies = []
      let companyId = compNameLowerNoSpecial.replace(/\s+/g, "");
      this.registerData.user.accessCompanies.push(companyId);

      if (this.$refs.registerForm.validate()) {
        if (this.registerData.terms == true) {
          // Controleer of bedrijf al bestaat in de database
          let refCompany = db.collection("companies").doc(companyId);
          let refUser = db.collection("users").doc(userId);
          refCompany.get().then(doc => {
            if (doc.exists) {
              this.feedback = "Er bestaat al een bedrijf met deze naam";
            } else {
              refUser.get().then(doc => {
                if (doc.exists) {
                  this.feedback =
                    "Er bestaat al een  gebruiker met dit emailadres";
                } else {
                  // Bedrijf aanmaken
                  db.collection("companies")
                    .doc(companyId)
                    .set({
                      // Basic Company Settings
                      active: true,
                      name: this.registerData.company.name,
                      email: this.registerData.company.email,
                      url: this.registerData.company.url,
                      phonenumber: this.registerData.company.phonenumber,
                      logoUrl: null,
                      registerDate: new Date(),
                      subscription: {
                        accountholder: null,
                        groupDiscount: null,
                        iban: null,
                        packages: {},
                        users: 1,
                        activationFeeAmount: null
                      }
                    })
                    .then(() => {
                      firebase
                        .auth()
                        .createUserWithEmailAndPassword(
                          this.registerData.user.email,
                          this.registerData.user.password
                        )
                        .then(cred => {
                          refUser.set({
                            firstName: this.registerData.user.firstName,
                            lastName: this.registerData.user.lastName,
                            userRole: "admin",
                            registerDate: new Date(),
                            user_id: cred.user.uid,
                            accessCompanies: this.registerData.user
                              .accessCompanies,
                            company: companyId,
                            email: this.registerData.user.email
                          });
                        })
                        .then(() => {
                          this.$router.push("/home");
                          console.log("Registratie Succesvol");
                        })
                        .catch(error => {
                          this.feedback = "Opslaan is niet gelukt. Probeer opnieuw";
                          console.error("Client Unsuccessfull updated: " + error);
                        });
                    });
                }
              });
            }
          });

          // Controleer of email van nieuwe gebruiker al bestaat in de database
        } else {
          this.feedback = "Ga akkoord met de voorwaarden om door te gaan.";
        }
      } else {
        this.feedback = "Vul alle velden in om door te gaan.";
      }
    }
  },
  computed: {
    loginError() {
      return this.$store.state.error;
    },
    userId() {
      return this.$store.state.userId;
    },
    authenticated() {
      return this.$store.state.authenticated;
    }
  }
};
</script>

<style>
.register-fields-box {
  margin: 0 0 15px 0;
  /* padding: 5px 10px; */
}

.register-fields-box h4 {
  font-size: 18px;
}

.register-info-wrapper {
  margin: 50px 0 0 0;
  padding: 20px 0 0 0;
}

.register-form-wrapper {
  margin: 50px;
  padding: 20px;
}

@media only screen and (max-width: 959px) {
  .register-form-wrapper {
    margin: 20px 0;
    padding: 0;
  }
}
</style>



