<template>
  <div>
    <v-container grid-list-md class="ma-0 pa-0" fluid>
      <v-layout row wrap class="py-0">
        <v-flex xs12 class="text-right py-0">
          <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  small :dark="changed" :loading="updateUserRulesSettingsBusy" :disabled="!changed" :depressed="!changed" @click="updateSettings()">
              <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale)}} <v-icon righy>mdi-chevron-right</v-icon></div>
              <div v-else>{{ $t('general.saved', $store.state.locale)}}<v-icon right>mdi-check</v-icon></div>  
            </v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container grid-list-md class="pa-0 py-4 ma-0">
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat class="pa-3" :style="{backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].card}">
            <div class="text-center">
              <h4 class="mt-0">{{ $t('userRules.branchUserRules', $store.state.locale)}}</h4>
              <p class="ma-0 mb-4">{{ $t('userRules.sortUserRules', $store.state.locale)}}</p>
            </div>
            <v-container grid-list-md class="pa-0 ma-0">
              <v-layout row wrap>
                <v-flex xs4 d-flex>
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" style="width: 100%">
                    <v-card-text class="pa-2 text-center" > 
                      <h4 class="mb-4" style="text-transform: uppercase; font-weight: bold">{{ $t('userRules.never', $store.state.locale)}}</h4>
                      <draggable v-model="userRules.never" v-bind="{group:'rules'}" style="min-height: 10px">
                        <v-btn block v-for="(rule, index) in userRules.never" :key="index" color="error" dark draggable class="mb-1 mr-1" small><v-icon color="white" left>mdi-drag</v-icon><strong>{{ userRulesNames[rule] }}</strong></v-btn>
                      </draggable>
                    </v-card-text>
                  </v-card>
                  
                </v-flex>
                <v-flex xs4 d-flex>
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" style="width: 100%">
                    <v-card-text class="pa-2 text-center"> 
                      <h4 class="mb-4" style="text-transform: uppercase; font-weight: bold">{{ $t('userRules.restricted', $store.state.locale)}}</h4>
                      <draggable v-model="userRules.restricted" v-bind="{group:'rules'}" style="min-height: 10px">
                        <v-btn block v-for="(rule, index) in userRules.restricted" :key="index" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" draggable dark class="mb-1 mr-1" small><v-icon color="white" left>mdi-drag</v-icon><strong>{{ userRulesNames[rule] }}</strong></v-btn>
                      </draggable>
                    </v-card-text>
                  </v-card>
                  
                </v-flex>
                <v-flex xs4 d-flex>
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" style="width: 100%">
                    <v-card-text class="pa-2 text-center">
                      <h4 class="mb-4" style="text-transform: uppercase; font-weight: bold">{{ $t('userRules.free', $store.state.locale)}}</h4>
                      <draggable v-model="userRules.free" v-bind="{group:'rules'}" style="min-height: 10px">
                        <v-btn block v-for="(rule, index) in userRules.free" :key="index" color="success" draggable class="mb-1 mr-1" dark small><v-icon color="white" left>mdi-drag</v-icon><strong>{{ userRulesNames[rule] }}</strong></v-btn>
                      </draggable>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-select
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :items="logoutTimes"
                    v-model="userRules.logoutTime"
                    :label="$t('userRules.autoLogoutTime', $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    prepend-inner-icon="mdi-clock-outline"
                    required
                    dense
                    outlined
                    class="mt-4"
                    persistent-hint
                    :hint="$t('userRules.autoLogoutTimeHint', $store.state.locale)"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

  </div>
</template>


<script>
import '@firebase/firestore';
import db from "../firebase/init";
import { bus } from "../main";
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },

  name: "user-rules-company",
  data(){
    return{
      defaultUserRules:{
        never: [
          "viewReports",
          "updateInventory"
        ],
        restricted: [
          "viewClient",
          "editClient",
          "addTasks",
          "completeTasks"
        ],
        free: [   
          "payments",
          "changeAppointments",
          "addAppointments"
        ],
        logoutTime: 120
      },

      userRules: new Object(),
      userRulesOriginal: new Object(),

      userRulesNames:{
        viewReports: this.$t('userRules.viewReports', this.$store.state.locale),
        updateInventory: this.$t('userRules.updateInventory', this.$store.state.locale),
        viewClient: this.$t('userRules.viewClient', this.$store.state.locale),
        editClient: this.$t('userRules.editClient', this.$store.state.locale),
        addTasks: this.$t('userRules.addTasks', this.$store.state.locale),
        completeTasks: this.$t('userRules.completeTasks', this.$store.state.locale),
        payments: this.$t('userRules.payments', this.$store.state.locale),
        changeAppointments: this.$t('userRules.changeAppointments', this.$store.state.locale),
        addAppointments: this.$t('userRules.addAppointments', this.$store.state.locale)
      },

      logoutTimes: [
        {
          name: "30 seconden",
          name: this.$t('userRules.thirtySeconds', this.$store.state.locale),
          id: 30
        },
        {
          name: "1 minuut",
          name: this.$t('userRules.oneMinute', this.$store.state.locale),
          id: 60
        },
        {
          name: "2 minuten",
          name: this.$t('userRules.twoMinutes', this.$store.state.locale),
          id: 120
        },
        {
          name: "5 minuten",
          name: this.$t('userRules.fiveMinutes', this.$store.state.locale),
          id: 300
        },
      ],
      updateUserRulesSettingsBusy: false,
    }
  },

  created() {
    this.checkDataUserRules();
  },

  methods: {
    checkDataUserRules(){
      if(!this.$store.state.activeCompany.userBranchRules){
        this.userRules = _.cloneDeep(this.defaultUserRules)
        this.userRulesOriginal = _.cloneDeep(this.defaultUserRules)
      }
      else{
        this.userRules = _.cloneDeep(this.activeCompData.userBranchRules)
        this.userRulesOriginal = _.cloneDeep(this.activeCompData.userBranchRules)
      }
    },

    async updateSettings() {
      this.updateUserRulesSettingsBusy = true;
      await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .set({
          userBranchRules: this.userRules,
        }, { merge: true })
        .then(() => {
          console.log("Algemene Accountrechten succesfully updated");
          this.updateUserRulesSettingsBusy = false;
          this.userRulesOriginal = _.cloneDeep(this.userRules)
        })
        .catch(error => {
          this.updateUserRulesSettingsBusy = false;
          console.error("Error Algemene Accountrechten Settings: ", error);
        });

        await this.$store.dispatch("updateActiveCompanyData", this.activeCompanyData.id);
    },
 
  },

  computed: { 
    changed(){
      if( _.isEqual(this.userRules, this.userRulesOriginal) == false){
        return true
      } else{
        return false
      }
    },
  }
};
</script>

