<template>
  <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>Nieuwe lichaamsamenstelling</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeAddBodyCompositionDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert class="ma-0 pa-2" v-if="feedback" type="warning">{{ feedback }} </v-alert>

    <v-divider></v-divider>
 
    <v-card-text>
      <v-form>
        <div class="input-content-wrapper padding-top space">
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6>
                <v-menu
                  ref="dateDialog"
                  :close-on-content-click="false"
                  v-model="dateDialog"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  >
                  <template v-slot:activator="{on}">
                    <v-text-field
                      class="mt-2"
                      outlined
                      dense
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      v-on="on"
                      slot="activator"
                      v-model="addedDateFormatted"
                      label="Datum meting"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="date" no-title @input="dateDialog = false" :max="max" :show-current="true"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md6 >
                <v-text-field
                  class="mt-2"
                  label="Tijdstip"
                  v-model="newComposition.time"
                  prepend-inner-icon="mdi-clock-outline"
                  type="time"
                  dense
                  suffix="uur"
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        <div class="input-content-wrapper padding-top">
          <div class="choose-measurement-type-wrapper">
            <div class="choose-measurement-type-buttons clear">
              <div class="measurement-type-button one" v-bind:class="{active: biaView}" @click="setBiaView()">BIA</div>
              <div class="measurement-type-button two" v-bind:class="{active: skinfoldView}" @click="setSkinfoldView()">Huidplooi</div>
            </div>
          </div>
        </div>
        <div v-if="biaView == true" class="bia-view-wrapper">
          <div class="input-content-wrapper space">
            <div class="input-wrapper">
            
              <div class="body-measurement-item-wrapper clear">
                <div class="body-measurement-icon scale" ></div>
                <div class="body-measurement-item">
                  <v-text-field
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    label="Gewicht"
                    v-model.number="newComposition.bia.weight"
                    v-on:input="setBiaWeight()"
                    type="number"
                    suffix="kg"
                  ></v-text-field>
                </div>
              </div>

              <div class="body-measurement-item-wrapper clear">

                <div class="body-measurement-icon fat" ></div>
                <div class="body-measurement-item no-label clear">
                  <p class="custom-measurement-label ma-0">Vet</p>
                  <div class="measurement-input-field-one">
                    <v-text-field
                      :disabled="biaReadOnly"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      outlined
                      dense
                      background-color="background"
                      v-model.number="newComposition.bia.fat.weight"
                      v-on:input="calculateFatPercentage()"
                      type="number"
                      suffix="kg"
                    ></v-text-field>
                  </div>
                  <div class="measurement-input-field-two">
                    <v-text-field
                      :disabled="biaReadOnly"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      outlined
                      dense
                      background-color="background"
                      v-model.number="newComposition.bia.fat.percentage"
                      v-on:input="calculateFatWeight()"
                      type="number"
                      suffix="%"
                    ></v-text-field>
                  </div>
                </div>
              </div>

              <div class="body-measurement-item-wrapper clear">
                <div class="body-measurement-icon muscle" ></div>
                <div class="body-measurement-item no-label clear">
                    <p class="custom-measurement-label ma-0">Spieren</p>
                    <div class="measurement-input-field-one">
                      <v-text-field
                        :disabled="biaReadOnly"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        outlined
                        dense
                        background-color="background"
                        v-model.number="newComposition.bia.muscle.weight"
                        v-on:input="calculateMusclePercentage()"
                        type="number"
                        suffix="kg"
                      ></v-text-field>
                    </div>
                    <div class="measurement-input-field-two">
                      <v-text-field
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="background"
                        :disabled="biaReadOnly"
                        v-model.number="newComposition.bia.muscle.percentage"
                        v-on:input="calculateMuscleWeight()"
                        type="number"
                        suffix="%"
                      ></v-text-field>
                    </div>
                  
                </div>
              </div>

              <div class="body-measurement-item-wrapper clear">
                <div class="body-measurement-icon vis-fat" ></div>
                <div class="body-measurement-item no-label clear">
                    <p class="custom-measurement-label ma-0">Visceraal vet</p>
                    <div class="measurement-input-field-one">
                      <v-text-field
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        outlined
                        dense
                        background-color="background"
                        :disabled="biaReadOnly"
                        v-model.number="newComposition.bia.visFat.weight"
                        v-on:input="calculateVisFatPercentage()"
                        type="number"
                        suffix="kg"
                      ></v-text-field>
                    </div>
                    <div class="measurement-input-field-two">
                      <v-text-field
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        outlined
                        dense
                        background-color="background"
                        :disabled="biaReadOnly"
                        v-model.number="newComposition.bia.visFat.percentage"
                        v-on:input="calculateVisFatWeight()"
                        type="number"
                        suffix="%"
                      ></v-text-field>
                    </div>
                </div>
              </div>

              <div class="body-measurement-item-wrapper clear">
                <div class="body-measurement-icon bone" ></div>
                <div class="body-measurement-item no-label clear">
                  <p class="custom-measurement-label ma-0">Botmassa</p>
                  <div class="measurement-input-field-one">
                    <v-text-field
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      outlined
                      dense
                      background-color="background"
                      :disabled="biaReadOnly"
                      v-model.number="newComposition.bia.bone.weight"
                      v-on:input="calculateBonePercentage()"
                      type="number"
                      suffix="kg"
                    ></v-text-field>
                  </div>
                  <div class="measurement-input-field-two">
                    <v-text-field
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      outlined
                      dense
                      background-color="background"
                      :disabled="biaReadOnly"
                      v-model.number="newComposition.bia.bone.percentage"
                      v-on:input="calculateBoneWeight()"
                      type="number"
                      suffix="%"
                    ></v-text-field>
                  </div>
                </div>
              </div>

              <div class="body-measurement-item-wrapper clear">
                <div class="body-measurement-icon water" ></div>
                <div class="body-measurement-item no-label clear">
                    <p class="custom-measurement-label ma-0">Vocht</p>
                    <div class="measurement-input-field-one">
                      <v-text-field
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        outlined
                        dense
                        background-color="background"
                        :disabled="biaReadOnly"
                        v-model.number="newComposition.bia.water.weight"
                        v-on:input="calculateWaterPercentage()"
                        type="number"
                        suffix="kg"
                      ></v-text-field>
                    </div>
                    <div class="measurement-input-field-two">
                      <v-text-field
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        outlined
                        dense
                        background-color="background"
                        :disabled="biaReadOnly"
                        v-model.number="newComposition.bia.water.percentage"
                        v-on:input="calculateWaterWeight()"
                        type="number"
                        suffix="%"
                      ></v-text-field>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div v-if="skinfoldView == true" class="skinfold-view-wrapper">

          <div class="input-content-wrapper space">
            <div class="input-wrapper">
            <div class="skinfold-progress-wrapper">
              <p>Voortgang huidplooimeting</p>
              <div class="skinfold-progress-bar mb-4">
                <div class="skinfold-progress-bar-inner" v-bind:style="{ width: skinfoldProgress }"></div>
              </div>
            </div>

            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon scale" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  label="Gewicht"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  outlined
                  background-color="background"
                  v-model.number="newComposition.skinfold.weight"
                  v-on:input="setSkinfoldWeight()"
                  type="number"
                  suffix="kg"
                ></v-text-field>
              </div>
            </div>
        
            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon biciptal" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  :disabled="skinfoldReadOnly"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  outlined
                  background-color="background"
                  label="Biciptale plooi"
                  v-model.number="newComposition.skinfold.biciptal"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>

            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon tricipital" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  :disabled="skinfoldReadOnly"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  outlined
                  background-color="background"
                  label="Tricipitale plooi"
                  v-model.number="newComposition.skinfold.tricipital"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>

            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon subscapulair" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  outlined
                  background-color="background"
                  :disabled="skinfoldReadOnly"
                  label="Subscapulaire plooi"
                  v-model.number="newComposition.skinfold.subscapulair"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>

            <div class="body-measurement-item-wrapper clear">
              <div class="body-measurement-icon suprailiacal" ></div>
              <div class="body-measurement-item">
                <v-text-field
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  outlined
                  background-color="background"
                  :disabled="skinfoldReadOnly"
                  label="Supra-iliacale plooi"
                  v-model.number="newComposition.skinfold.suprailiacal"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </div>
            </div>

            </div>
          </div>
        </div>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
              @click="closeAddBodyCompositionDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
            dark
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              @click="saveComposition()"
              >
              Toevoegen
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>


<script>
import db from "../../firebase/init";
import moment from "moment";
import _ from "lodash";
import { bus } from "../../main";

export default {
  name: "add-body-composition-form",
  props: { 
    clientInfo: Object,
    activeCompData: Object,
  },
  data() {
    return {
      date: null,
      time: null,
      max: null,
      loading: true,
      newComposition: {
        bia: {
          weight: null,
          fat: {
            weight: null,
            percentage: null
          },
          muscle: {
            weight: null,
            percentage: null
          },
          visFat: {
            weight: null,
            percentage: null
          },
          bone: {
            weight: null,
            percentage: null
          },
          water: {
            weight: null,
            percentage: null
          }
        },
        skinfold: {
          weight: null,
          biciptal: null,
          tricipital: null,
          subscapulair: null,
          suprailiacal: null
        },
        time: null
      },
      feedback: null,
      dateDialog: false,
      biaView: true,
      skinfoldView: false
    };
  },

  created() {
    this.date = moment().format("YYYY-MM-DD");
    this.max = moment().format("YYYY-MM-DD");
    this.getCurrentTime();
    bus.$on("setCurrentTime",()=>{
      this.getCurrentTime();
    })
  },

  methods: {

    getCurrentTime(){
      console.log("Time set")
      this.newComposition.time = moment().format("HH:mm");
    },

    saveComposition(){
      if(this.biaView){
        if(this.addedDateFormatted && this.newComposition.bia.weight){
          this.addMeasurementBia()
        } else {
          this.feedback = "Vul datum en gewicht in om op te slaan."
        }
      } else {
        if(this.newComposition.skinfold.weight && this.newComposition.skinfold.biciptal && this.newComposition.skinfold.tricipital && this.newComposition.skinfold.subscapulair && this.newComposition.skinfold.suprailiacal){
          this.addMeasurementSkinfold()
        } else {
          this.feedback = "Vul alle velden in om op te slaan."
        }
      }
    },

    closeAddBodyCompositionDialog() {
      this.$emit("closeAddBodyCompositionDialogChange", false);
      this.newComposition = {
        bia: {
          weight: null,
          fat: {
            weight: null,
            percentage: null
          },
          muscle: {
            weight: null,
            percentage: null
          },
          visFat: {
            weight: null,
            percentage: null
          },
          bone: {
            weight: null,
            percentage: null
          },
          water: {
            weight: null,
            percentage: null
          }
        },
        skinfold: {
          weight: null,
          biciptal: null,
          tricipital: null,
          subscapulair: null,
          suprailiacal: null
        },
        time: null
      };
    },

    // BIA meting met invoervelden activeren
    setBiaView() {
      this.biaView = true;
      this.skinfoldView = false;
    },
    // BIA meting met invoervelden activeren
    setSkinfoldView() {
      this.skinfoldView = true;
      this.biaView = false;
    },

    // Alle waarden op NULL zetten als er een nieuw gewicht wordt ingevoerd.
    setBiaWeight() {
      this.newComposition.bia.fat.weight = null;
      this.newComposition.bia.fat.percentage = null;
      this.newComposition.bia.muscle.weight = null;
      this.newComposition.bia.muscle.percentage = null;
      this.newComposition.bia.visFat.weight = null;
      this.newComposition.bia.visFat.percentage = null;
      this.newComposition.bia.bone.weight = null;
      this.newComposition.bia.bone.percentage = null;
      this.newComposition.bia.water.weight = null;
      this.newComposition.bia.water.percentage = null;
    },

    // Alle waarden op NULL zetten als er een nieuw gewicht wordt ingevoerd.
    setSkinfoldWeight() {
      this.newComposition.skinfold.biciptal = null;
      this.newComposition.skinfold.tricipital = null;
      this.newComposition.skinfold.subscapulair = null;
      this.newComposition.skinfold.suprailiacal = null;
    },

    // Bereken Vetprecentage als vetgewicht wordt ingevoerd (op basis van ingevoerde vetgewicht en totale lichaamsgewicht)
    calculateFatPercentage() {
      if (this.newComposition.bia.fat.weight) {
        if (this.newComposition.bia.weight) {
          let newCompositionFatPercentage = (
            (this.newComposition.bia.fat.weight / this.newComposition.bia.weight) *
            100
          ).toFixed(2);
          this.newComposition.bia.fat.percentage = Number(newCompositionFatPercentage);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.newComposition.bia.fat.weight = null;
        this.newComposition.bia.fat.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Vetgewicht als vetpercentage wordt ingevoerd (op basis van ingevoerde vetpercentage en totale lichaamsgewicht)
    calculateFatWeight() {
      if (this.newComposition.bia.fat.percentage) {
        if (this.newComposition.bia.fat) {
          let newCompositionFatWeight = (
            (this.newComposition.bia.fat.percentage / 100) *
            this.newComposition.bia.weight
          ).toFixed(2);
          this.newComposition.bia.fat.weight = Number(newCompositionFatWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
          this.newComposition.bia.fat.percentage = null;
        }
      } else {
        this.newComposition.bia.fat.weight = null;
        this.newComposition.bia.fat.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Spierprecentage als spiergewicht wordt ingevoerd (op basis van ingevoerde spiergewicht en totale lichaamsgewicht)
    calculateMusclePercentage() {
      if (this.newComposition.bia.muscle.weight) {
        if (this.newComposition.bia.weight) {
          let newCompositionMusclePercentage = (
            (this.newComposition.bia.muscle.weight / this.newComposition.bia.weight) *
            100
          ).toFixed(2);
          this.newComposition.bia.muscle.percentage = Number(newCompositionMusclePercentage);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.newComposition.bia.muscle.weight = null;
        this.newComposition.bia.muscle.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Spiergewicht als spierpercentage wordt ingevoerd (op basis van ingevoerde spierpercentage en totale lichaamsgewicht)
    calculateMuscleWeight() {
      if (this.newComposition.bia.muscle.percentage) {
        if (this.newComposition.bia.muscle) {
          let newCompositionMuscleWeight = (
            (this.newComposition.bia.muscle.percentage / 100) *
            this.newComposition.bia.weight
          ).toFixed(2);
          this.newComposition.bia.muscle.weight = Number(newCompositionMuscleWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.newComposition.bia.muscle.weight = null;
        this.newComposition.bia.muscle.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Visceraalvetprecentage als Visceraalvetgewicht wordt ingevoerd (op basis van ingevoerde Visceraalvetgewicht en totale lichaamsgewicht)
    calculateVisFatWeight() {
      if (this.newComposition.bia.visFat.percentage) {
        if (this.newComposition.bia.visFat) {
          let newCompositionVisFatPercentage = (
            (this.newComposition.bia.visFat.percentage / 100) *
            this.newComposition.bia.weight
          ).toFixed(2);

          this.newComposition.bia.visFat.weight = Number(newCompositionVisFatPercentage);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.newComposition.bia.visFat.percentage = null;
        this.newComposition.bia.visFat.weight = null;
        this.feedback = null;
      }
    },

    // Bereken Visceraalvetgewicht als Visceraalvetpercentage wordt ingevoerd (op basis van ingevoerde Visceraalvetpercentage en totale lichaamsgewicht)
    calculateVisFatPercentage() {
      if (this.newComposition.bia.visFat.weight) {
        if (this.newComposition.bia.weight) {
          let newCompositionVisFatWeight = (
            (this.newComposition.bia.visFat.weight / this.newComposition.bia.weight) *
            100
          ).toFixed(2);
          this.newComposition.bia.visFat.percentage = Number(newCompositionVisFatWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.newComposition.bia.visFat.percentage = null;
        this.newComposition.bia.visFat.weight = null;
        this.feedback = null;
      }
    },


    // Bereken Botpercentage als Botgewicht wordt ingevoerd (op basis van ingevoerde Botgewicht en totale lichaamsgewicht)
    calculateBoneWeight() {
      if (this.newComposition.bia.bone.percentage) {
        if (this.newComposition.bia.weight) {
          let newCompositionBonePercentage = (
            (this.newComposition.bia.bone.percentage / 100) *
            this.newComposition.bia.weight
          ).toFixed(2);
          this.newComposition.bia.bone.weight = Number(newCompositionBonePercentage);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.newComposition.bia.bone.weight = null;
        this.newComposition.bia.bone.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Botgewicht als Botpercentage wordt ingevoerd (op basis van ingevoerde Botpercentage en totale lichaamsgewicht)
    calculateBonePercentage() {
      if (this.newComposition.bia.bone.weight) {
        if (this.newComposition.bia.weight) {
          let newCompositionBoneWeight = (
            (this.newComposition.bia.bone.weight / this.newComposition.bia.weight) *
            100
          ).toFixed(2);
          this.newComposition.bia.bone.percentage = Number(newCompositionBoneWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.newComposition.bia.bone.weight = null;
        this.newComposition.bia.bone.percentage = null;
        this.feedback = null;
      }
    },

    // Bereken Vochtpercentage als Vochtgewicht wordt ingevoerd (op basis van ingevoerde Vochtgewicht en totale lichaamsgewicht)
    calculateWaterPercentage() {
      if (this.newComposition.bia.water.weight) {
        if (this.newComposition.bia.weight) {
          let newCompositionWaterPercentage = (
            (this.newComposition.bia.water.weight / this.newComposition.bia.weight) *
            100
          ).toFixed(2);

          this.newComposition.bia.water.percentage = Number(newCompositionWaterPercentage);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.newComposition.bia.water.percentage = null;
        this.newComposition.bia.water.weight = null;
        this.feedback = null;
      }
    },

    // Bereken Vochtgewicht als Vochtpercentage wordt ingevoerd (op basis van ingevoerde Vochtpercentage en totale lichaamsgewicht)
    calculateWaterWeight() {
      if (this.newComposition.bia.water.percentage) {
        if (this.newComposition.bia.weight) {
          let newCompositionWaterWeight = (
            (this.newComposition.bia.water.percentage / 100) *
            this.newComposition.bia.weight
          ).toFixed(2);

          this.newComposition.bia.water.weight = Number(newCompositionWaterWeight);
        } else {
          this.feedback = "Voer eerst het totaalgewicht in.";
        }
      } else {
        this.newComposition.bia.water.weight = null;
        this.newComposition.bia.water.percentage = null;
        this.feedback = null;
      }
    },

    // Datum omvormen naar Europese leesbare datum
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    // Voeg BIA-meting toe aan database en log deze actie. Ook eventuele errors worden gelogd
    addMeasurementBia() {
      this.feedback = null
      db.collection("compositions")
        .add({
          clientId: this.clientInfo.id,
          dateAdded: new Date(this.firebaseDateFormatted),
          type: "bia",
          time: this.newComposition.time,
          companyId: this.activeCompData.id,
          weight: this.newComposition.bia.weight,
          fat: {
            weight: this.newComposition.bia.fat.weight,
            percentage: this.newComposition.bia.fat.percentage
          },
          muscle: {
            weight: this.newComposition.bia.muscle.weight,
            percentage: this.newComposition.bia.muscle.percentage
          },
          visFat: {
            weight: this.newComposition.bia.visFat.weight,
            percentage: this.newComposition.bia.visFat.percentage
          },
          bone: {
            weight: this.newComposition.bia.bone.weight,
            percentage: this.newComposition.bia.bone.percentage
          },
          water: {
            weight: this.newComposition.bia.water.weight,
            percentage: this.newComposition.bia.water.percentage
          }
        })
        .then(() => {
          this.$emit("updateData");
          this.closeAddBodyCompositionDialog()
          console.log("Body Composition Added Successfull");
        })
        .catch(error => {
          this.feedback = "Opslaan is niet gelukt. Probeer opnieuw";
          console.error("Error adding BIA measurement: ", error);
        });
    },

    // Voeg Huidplooimeting toe aan database en log deze actie. Ook eventuele errors worden gelogd
    addMeasurementSkinfold() {
      this.feedback = null
      db.collection("compositions")
        .add({
          companyId: this.activeCompData.id,
          clientId: this.clientInfo.id,
          dateAdded: new Date(this.firebaseDateFormatted),
          time: this.newComposition.time,
          type: "skinfold",
          weight: this.newComposition.skinfold.weight,
          biciptal: this.newComposition.skinfold.biciptal,
          tricipital: this.newComposition.skinfold.tricipital,
          subscapulair: this.newComposition.skinfold.subscapulair,
          suprailiacal: this.newComposition.skinfold.suprailiacal,
          fat: {
            weight: this.skinfoldFatWeight,
            percentage: this.skinfoldFatPercentage
          }
        })
        .then(() => {
          this.$emit("updateData");
          this.closeAddBodyCompositionDialog()
          console.log("Body Composition Added Successfull");
        })
        .catch(error => {
          this.feedback = "Opslaan is niet gelukt. Probeer opnieuw";
          console.error("Error adding skinfold measurement: ", error);
        });
    }
  },
  computed: {
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    // Berekenen van totale vetprecentage op basis van de huidplooimeting
    skinfoldFatWeight() {
      if (
        this.newComposition.skinfold.weight &&
        this.newComposition.skinfold.biciptal &&
        this.newComposition.skinfold.tricipital &&
        this.newComposition.skinfold.subscapulair &&
        this.newComposition.skinfold.suprailiacal
      ) {
        let averageFatPercentage =
          (this.newComposition.skinfold.biciptal +
            this.newComposition.skinfold.tricipital +
            this.newComposition.skinfold.subscapulair +
            this.newComposition.skinfold.suprailiacal) /
          4;
        let averageFatWeigt = (averageFatPercentage / 100) * this.newComposition.skinfold.weight;
        return Number(averageFatWeigt.toFixed(2));
      } else {
        return null;
      }
    },

    // Berekenen van totale vetgewicht op basis van de huidplooimeting
    skinfoldFatPercentage() {
      if (
        this.newComposition.skinfold.weight &&
        this.newComposition.skinfold.biciptal &&
        this.newComposition.skinfold.tricipital &&
        this.newComposition.skinfold.subscapulair &&
        this.newComposition.skinfold.suprailiacal
      ) {
        let averageFatPercentage =
          (this.newComposition.skinfold.biciptal +
            this.newComposition.skinfold.tricipital +
            this.newComposition.skinfold.subscapulair +
            this.newComposition.skinfold.suprailiacal) /
          4;
        return Number(averageFatPercentage.toFixed(2));
      } else {
        return null;
      }
    },

    // Voorgang van huidplooimeting berekenen
    skinfoldProgress() {
      let progress = 0;
      if (this.newComposition.skinfold.weight) {
        progress += 20;
      }
      if (this.newComposition.skinfold.biciptal) {
        progress += 20;
      }
      if (this.newComposition.skinfold.tricipital) {
        progress += 20;
      }
      if (this.newComposition.skinfold.subscapulair) {
        progress += 20;
      }
      if (this.newComposition.skinfold.suprailiacal) {
        progress += 20;
      }
      return String(progress) + "%";
    },
    
    // Kijken naar het geslacht van de geselecteerde klant. Op basis hiervan worden de iconen voor man of vrouw weergegeven
    activeGender() {
      return this.clientInfo.gender == 1 ? "man" : "woman";
    },
    addedDateFormatted() {
      return this.formatDate(this.date); // Datum omvormen naar Europese leesbare datum
    },   
    firebaseDateFormatted() {
      return new Date(this.date); // Datum BIA-meting omvormen naar juist datum voor Firebase Database
    },
    biaReadOnly() {
      return this.newComposition.bia.weight ? false : true; // Kijken of de minimale velden voor de BIA-meting is ingevuld
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      if(this.$store.state.companyTheme){
        return this.$store.state.companyTheme;
      }
      else{
        return "thrive"
      }
    }, 
    skinfoldReadOnly() {
      return this.newComposition.skinfold.weight ? false : true; // Kijken of de minimale velden voor de Huidplooimeting is ingevuld
    }
  }
};
</script>

<style>
  .choose-measurement-type-wrapper {
  padding: 5px 15px 20px 15px;
}

.choose-measurement-type-buttons {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.measurement-type-button {
  width: calc(50% - 1px);
  float: left;
  height: 32px;
  text-align: center;
  color:  var(--primary-color);
  font-size: 13px;
  line-height: 32px;
  background-color: var(--card-color);
  border-bottom: 1px solid  var(--primary-color);
  border-top: 1px solid  var(--primary-color);
  cursor: pointer;
}

.measurement-type-button:hover{
  cursor: pointer;
}

.measurement-type-button.active {
  background-color: var(--primary-color);
  color: #fff;
}

.measurement-type-button.one {
  border-left: 1px solidvar(--primary-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.measurement-type-button.two {
  border-right: 1px solid var(--primary-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.measurement-input-field-one {
  width: calc(50% - 10px);
  margin: 0 20px 0 0;
  float: left;
}

.measurement-input-field-two {
  width: calc(50% - 10px);
  float: left;
}

.body-measurement-item.no-label .v-text-field {
  margin: 0;
  padding: 0;
}

.body-measurements-wrapper {
  position: relative;
  padding: 10px;
  background-color: #fff;
}

.body-measurement-icon {
  width: 56px;
  height: 56px;
  background-color: var(--card-color);
  margin: 10px 10px 0 0;
  float: left;
}

.body-measurement-item {
  width: calc(100% - 66px);
  float: left;
}

.skinfold-progress-wrapper {
  width: 100%;
}

.skinfold-progress-bar {
  width: 100%;
  height: 12px;
  background-color: var(--cardOption-color);
  margin: 10px 0;
  border-radius: 20px;
  overflow: hidden;
}

.skinfold-progress-bar-inner {
  background-color: var(--primary-color);
  height: 12px;
}

/* BODY MEASUREMENT ICONS */


.body-measurement-icon.scale {
  background: url("../../assets/body-icons/scale-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 0px 10px 0 0;
}

.body-measurement-icon.fat {
  background: url("../../assets/body-icons/fat-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 10px 10px 0 0;
}

.body-measurement-icon.muscle {
  background: url("../../assets/body-icons/muscle-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 10px 10px 0 0;
}

.body-measurement-icon.vis-fat {
  background: url("../../assets/body-icons/vis-fat-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 10px 10px 0 0;
}

.body-measurement-icon.bone {
  background: url("../../assets/body-icons/bone-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 10px 10px 0 0;
}

.body-measurement-icon.water {
  background: url("../../assets/body-icons/water-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 10px 10px 0 0;
}

.body-measurement-icon.biciptal {
  background: url("../../assets/body-icons/biciptal-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 0 10px 0 0;
}

.body-measurement-icon.tricipital {
  background: url("../../assets/body-icons/tricipital-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 0 10px 0 0;
}

.body-measurement-icon.subscapulair {
  background: url("../../assets/body-icons/subscapulair-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 0 10px 0 0;
}

.body-measurement-icon.suprailiacal {
  background: url("../../assets/body-icons/suprailiacal-icon.png") no-repeat center center;
  background-size: 56px;
  margin: 0 10px 0 0;
}
</style>
