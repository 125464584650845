export default 
    [
        {
            name: "Adwords",
            query: "adw",
            copy: false,
            icon: "mdi-google-ads",
            bgColor: {
              hex: "#1a73e8",
            },
            textColor: {
              hex: "#fff",
            },
          },
          {
            name: "Mailchimp",
            query: "mc",
            copy: false,
            icon: "mdi-email-newsletter",
            bgColor: {
              hex: "#ffe01b",
            },
            textColor: {
              hex: "#333",
            },
          },
          {
            query: "walkIn",
            name: "Walk-in",
            copy: false,
            icon: "mdi-account",
            bgColor: {
              hex: "#000000",
            },
            textColor: {
              hex: "#fff",
            },
          },
          {
            query: "callIn",
            name: "Call-in",
            icon: "mdi-phone",
            copy: false,
            bgColor: {
              hex: "#000000",
            },
            textColor: {
              hex: "#fff",
            },
          },
          {
            query: "mailIn",
            name: "Mail-in",
            copy: false,
            icon: "mdi-email",
            bgColor: {
              hex: "#000000",
            },
            textColor: {
              hex: "#fff",
            },
          }     
      ]
;
