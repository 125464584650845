<template>
  <div class="tab-component-wrapper">
    <div v-if="!loading">
      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
        <v-tabs :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="tab" @change="changeTab()">
          <v-tab v-for="(api, index) in apis" :key="index"><v-icon left >{{ `mdi-numeric-${index+1}-circle`}}</v-icon> {{ api.name }}</v-tab>
          <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark depressed style="margin: 6px 0 0 0" @click="addNewApiDialog()">
            {{ $t("admin.newApi", $store.state.locale) }} <v-icon right small>mdi-plus</v-icon>
          </v-btn>

        </v-tabs>
        <v-divider></v-divider>

        <v-tabs-items v-model="tab" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card}" class="transparant-tabs" :key="changes" >
          <v-tab-item v-for="(api, index) in apis" :transition="tabTransition" :reverse-transition="tabTransition" :key="index + changes">
            <v-container grid-list-md class=" ma-0 pa-3" fluid>
              <v-layout row wrap>
                <v-flex md4 xs12>
                  <v-text-field
                    class="mx-1"
                    dense
                    v-model="apis[index].name"
                    hide-details
                    label="Naam"
                    outlined
                    @input="apis[index].changes++"
                  ></v-text-field>
                </v-flex>
                <v-flex md4 xs12>
                  <v-text-field
                    class="mx-1"
                    dense
                    :value="apis[index].id"
                    hide-details
                    label="ID"
                    outlined
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex md4 xs12 class="text-right">
                  <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :dark="changed" :disabled="!changed" :depressed="!changed" @click="updateApiSettings()">
                    <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }} <v-icon right small>mdi-chevron-right</v-icon></div>
                    <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right small>mdi-check</v-icon></div>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
            <v-divider></v-divider>
            
            
            <v-card class="pt-4" flat v-if="api.corsWhiteListData && api.corsWhiteListData.length > 0">

              <h3 class="pa-4 pt-0">{{ $t("admin.apiWhitelistUrls", $store.state.locale) }}</h3>
      
              <div v-for="(url, whiteListIndex) in api.corsWhiteListData" :key="whiteListIndex" class="api-whitelist-wrapper" :class="[{'url-changed': checkChanges(index, whiteListIndex)}, apis[index].corsWhiteListData[whiteListIndex].status]"> 
                <div class="api-whitelist-field">
                 <v-text-field
                    :label="$t('admin.apiSettingsUrl', $store.state.locale)"
                    v-model="apis[index].corsWhiteListData[whiteListIndex].url"
                    outlined
                    dense
                    required
                    hide-details
                    @change="changes++"
                    @input="apis[index].changes++"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  ></v-text-field>
                </div>
                <div class="api-whitelist-remove">
                  <v-btn v-if="apis[index].corsWhiteListData[whiteListIndex].status != 'deleted'" icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeItem(index, whiteListIndex)"><v-icon>mdi-delete</v-icon></v-btn>
                  <v-btn v-if="apis[index].corsWhiteListData[whiteListIndex].status == 'deleted'" icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="restoreItem(index, whiteListIndex)"><v-icon>mdi-delete-off</v-icon></v-btn>
                </div>
              </div>
              
            </v-card>
            <div v-else class="text-center pt-4 mt-4">
              <v-avatar
                size="58"
                light
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-playlist-check</v-icon>
              </v-avatar>
              <h3 class="pt-4">{{ $t("admin.apiWhitelistUrls", $store.state.locale) }}</h3>
              <p class="pt-4">{{ $t('admin.noApiUrls', $store.state.locale) }}</p>
            </div>


            <div class="text-center pa-4 mb-4">
                <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addItem(index)">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>

    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>

    <!-- / New Vat Dialog \ -->
    <v-dialog v-model="newApi.dialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('admin.newApi', $store.state.locale) }} </h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeNewApiDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-alert v-if="newApi.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ newApi.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text>

          <v-layout row wrap>
            <v-flex md12>
              <v-text-field
                label="ID"
                v-model="newApi.id"
                autocomplete
                required
                dense
                outlined
                @input="setApiId()"
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                class="mt-4"
              ></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-text-field
                label="Name"
                v-model="newApi.name"
                autocomplete
                required
                dense
                outlined
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                class="mt-4"
              ></v-text-field>
            </v-flex>
          </v-layout>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                  @click="closeNewApiDialog()"
                  >
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md8 class="text-right">
                <v-btn
                  :disabled="(newApi.id.length == 0 || newApi.name.length == 0)"
                  :dark="(newApi.id.length > 0 && newApi.name.length > 0)"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  @click="addNewApi()"
                  >
                  {{ $t('admin.addNewApi', $store.state.locale) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import db from "../firebase/init";

  export default {
    name: "api-settings",
    data() {
      return {
        apis: new Array(),
        apisOriginal: new Array(),
        tab: "",
        tabTransition: false,
        selectedVatInfo: new Object(),
        selectedVatInfoOriginal: new Object(),
        pages: -1,
        loading: true,
        vatItems: new Array(),
        changes: 0,
        changed: false,
        newApi: {
          dialog: false,
          feedkback: null,
          id: "",
          name: ""
        }
      }
    },

    created() {
      this.loadApiSettings();
    },

    watch: {

      apis: {
        handler(val) {
          this.checkApiChanges()
        },
        deep: true,
      },
    },

    methods: {

      setApiId(){
        if(this.newApi.id.slice(0, -1) == this.newApi.name || this.newApi.id == this.newApi.name.slice(0, -1) ){
          this.newApi.name = this.newApi.id
        }
      },

      checkChanges(index, whiteListIndex){
        return _.isEqual(this.apis[index].corsWhiteListData[whiteListIndex], this.apisOriginal[index].corsWhiteListData[whiteListIndex]) ? false : true;
      },

      changeTab(){
        this.changed = false;
        this.checkApiChanges('change')
        
      },

      checkApiChanges(type){
        for (let i = 0; i < this.apis.length; i++) {
          if(i == this.tab && (this.apis[i].changes > 0 || type == 'change')){
            this.changed = false;
            this.apis[i].changes = 0;
            if(this.apis[i].name != this.apisOriginal[i].name){
              this.changed = true;
            }
            for (let x = 0; x < this.apis[i].corsWhiteListData.length; x++) {
              if(this.apisOriginal[i].corsWhiteListData[x] === undefined || this.apis[i].corsWhiteListData[x].url != this.apisOriginal[i].corsWhiteListData[x].url || this.apis[i].corsWhiteListData[x].status != this.apisOriginal[i].corsWhiteListData[x].status){
                this.changed = true;
              }
            }
          } 
        }
      },

      removeItem(index, whiteListIndex){
        if(this.apis[index].corsWhiteListData[whiteListIndex].status == 'new'){
          this.apis[index].corsWhiteListData.splice(whiteListIndex,1);
        }
        else{
          this.apis[index].corsWhiteListData[whiteListIndex].status = 'deleted';
        }
        this.changes++
        this.apis[index].changes++
      },

      restoreItem(index, whiteListIndex){
        this.apis[index].corsWhiteListData[whiteListIndex].status = 'original';     
        this.changes++
        this.apis[index].changes++
      },

      addItem(index){
        this.apis[index].corsWhiteListData.push({
          url: "",
          status: "new"
        })
        this.changes++
        this.apis[index].changes++
      },

      closeNewApiDialog(){
        console.log("closeNewApiDialog")
        this.newApi.dialog = false;
        this.newApi.id = "";
        this.newApi.name = "";
        this.newApi.feedback = "";
      },

      addNewApiDialog(){
        this.newApi.dialog = true;
      },
      
      addNewApi(){
        const slugify = require("slugify");
        this.newApi.id = slugify(this.newApi.id, {
          remove: /[*+~.()'"!:@, '']/g
        });
        this.newApi.feedback = "";
        db.collection("apiSettings").doc(this.newApi.id)
        .set({
          name: this.newApi.name,
          corsWhiteList: new Array()
        },{merge: true})
        .then(()=> {
          this.closeNewApiDialog();
          this.loadApiSettings();
        })
        .catch(error=>{
          console.error("Fout bij toevoegen API settings: ", error);
          this.newApi.feedback = "Toevoegen niet gelukt";
        });
      },

      async loadApiSettings() {
        this.loading = true;
        this.apis = new Array();
        this.apisOriginal = new Array();
        await db.collection("apiSettings")
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let api = doc.data();
            api.changed = false;
            api.changes = 0;
            api.id = doc.id;
            this.apis.push(api);
          });
        })
        .catch(error=>{
          console.error("Fout bij binnenladen van api Settings: ", error); 
        });

        this.apis.forEach(api=>{
          api.corsWhiteListData = new Array();
          api.corsWhiteList.forEach(url=>{
            let urlData = {
              url: url,
              status: 'original'
            }
            api.corsWhiteListData.push(urlData)
          })
        })


        this.apisOriginal = _.cloneDeep(this.apis);
        this.tab = this.apis[0].id;
        this.checkApiChanges();
        this.loading = false;
      },

      async updateApiSettings(){
        let corsWhiteList = new Array();
        await new Promise((resolve)=>{
          if(this.apis[this.tab].corsWhiteListData && this.apis[this.tab].corsWhiteListData.length > 0){
            let counter = 0;
            this.apis[this.tab].corsWhiteListData.forEach(data=>{
              if(data.status != 'deleted' && data.url.length > 0){
                corsWhiteList.push(data.url)
              }
              counter++
              if(counter == this.apis[this.tab].corsWhiteListData.length){
                resolve()
              }
            })
          }
          else{
            resolve()
          }
        })
        await db.collection("apiSettings").doc(this.apis[this.tab].id)
        .set({
          name: this.apis[this.tab].name,
          corsWhiteList: corsWhiteList
        },{merge: true})
        .catch(error=>{
          console.error("Fout bij opslaan van api Settings: ", error); 
        });
        this.loadApiSettings()
      }
    },
  };
</script>
<style>
  .api-whitelist-wrapper{
    padding: 5px;
    margin: 4px;
  }

  .url-changed{
    background-color: #FFEE5850;
  }

  .url-changed.deleted{
    background-color: #FF8A8050;
  }

  .url-changed.new{
    background-color: #AED58150;
  }

  .api-whitelist-wrapper:after{
    display: block;
    clear: both;
    content: ""
  }

  .api-whitelist-field{
    width: calc(100% - 50px);
    float: left;
  }

  .api-whitelist-remove{
    width: 50px;
    padding: 2px 0 0 0;
    text-align: center;
    float: left;
  }
</style>
