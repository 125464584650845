<template>
  <v-dialog v-model="showDialog" scrollable max-width="800px" height="700px">
      <v-card v-if="!loading">
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2 v-if="type == 'new'">{{ $t(`indicator.newIndicator`, $store.state.locale) }}</h2>
          <h2 v-else >{{ $t(`indicator.editIndicator`, $store.state.locale) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeIndicatorDialog()">
            <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

        <v-divider></v-divider>


        <v-card-text class="pa-0 ma-0">

          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" class="pa-2" style="width: 100%" >

            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex :class="exampleWidth.example" d-flex>
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="pa-3 theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%">
                        <v-list class="ma-0 pa-0 no-hover-effect" flat inactive>
                          <v-list-item class="pa-0 ma-0 pb-3">
                            <v-list-item-content  class="pa-0 ma-0">
                              <v-list-item-subtitle>
                                <h4 class="indicator-subheading smooth">{{ $t(`indicator.${indicator.dataType}`, $store.state.locale) }}</h4>
                              </v-list-item-subtitle>
                              <div v-if="indicator.w == 8 || (indicator.w == 4  && indicator.graphType == 'number')">
                                <v-list-item-title class="indicator-heading" v-if="indicator.dataFilter == 'all'">{{ $t(`indicator.${indicator.data}`, $store.state.locale) }}</v-list-item-title>
                                <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length == 1"> {{ getTreatmentName(indicator.treatments[0]) }} </v-list-item-title>
                                <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length == 1">  {{ getProductName(indicator.products[0]) }}</v-list-item-title>
                                <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length > 1">{{ $t(`indicator.datafilter.treatments`, $store.state.locale) }}</v-list-item-title>
                                <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length > 1">{{ $t(`indicator.datafilter.products`, $store.state.locale) }}</v-list-item-title>
                                <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length == 1">{{ $t(`indicator.datafilter.treatmentCategory`, $store.state.locale) }}</v-list-item-title>
                                <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length == 1">{{ $t(`indicator.datafilter.productCategory`, $store.state.locale) }}</v-list-item-title>
                                <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length > 1">{{ $t(`indicator.datafilter.treatmentCategories`, $store.state.locale) }}</v-list-item-title>
                                <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length > 1">{{ $t(`indicator.datafilter.productCategories`, $store.state.locale) }}</v-list-item-title>
                              </div>
                              <div v-else>  
                                <v-list-item-title class="indicator-heading" v-if="indicator.dataType == 'revenue'">  
                                  €{{ indicator.revenue }} 
                                </v-list-item-title>
                                <v-list-item-title class="indicator-heading" v-else>  
                                  {{ indicator.number }} 
                                </v-list-item-title>
                              </div>
                            </v-list-item-content>
                          
                            <v-list-item-action class="mt-0 ml-0"  style="z-index: 999">
                              <v-menu
                                transition="slide-y-transition"
                                v-model="indicator.menu"
                                :close-on-content-click="true"
                                offset-y
                                open-on-hover
                                :content-class="`theme-shadow ${$store.state.companyTheme}`"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                                >
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" icon>
                                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>

                                <v-card max-width="200px" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                  <v-list dense class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
        
                                    <v-list-item class="ma-0 px-2" >
                                      <v-list-item-icon class="mr-2">
                                        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Bewerken</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>

                                    <v-divider></v-divider>

                                    <v-list-item class="ma-0 px-2" >
                                      <v-list-item-icon class="mr-2">
                                        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Verwijderen</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>

                                  </v-list>
                                </v-card>
                              </v-menu>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>

                      <div v-if="indicator.w == 4">

                        <v-card-text  class="pa-0 ma-0">
                          <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" v-if="indicator.graphType == 'number'" >
                            <v-list-item class="pa-0 pl-0 ma-0">
                              <v-list-item-avatar
                                size="56"
                                light
                                rounded="lg"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" 
                                >
                                <v-icon v-if="indicator.dataType == 'revenue'" color="#fff">mdi-trending-up</v-icon>
                                <v-icon v-else color="#fff">mdi-label</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-if="indicator.dataType == 'revenue'">
                                  <h2 class="indicator-number">€{{ sampleData.revenue }} </h2>
                                </v-list-item-title>
                                <v-list-item-title v-else>
                                  <h2 class="indicator-number">{{ sampleData.number }} </h2>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="indicator.graphType == 'number' && (indicator.treatmentTypes.length == 1 || indicator.productTypes.length == 1)">
                                  <v-chip v-if="indicator.treatmentTypes.length == 1" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getTreatmentTypeName(indicator.treatmentTypes[0]) }} </v-chip>
                                  <v-chip v-if="indicator.productTypes.length == 1" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getProductTypeName(indicator.productTypes[0]) }} </v-chip>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <div v-if="indicator.graphType == 'bar' || indicator.graphType == 'line'" >
                            <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && !indicator.useGoal" class="pt-3">
                              <apexchart width="100%" height="80" :type="indicator.graphType" :options="actualChartOptions"  :series="indicator.chartData"></apexchart>
                            </div>

                            <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && indicator.useGoal" class="pb-0" style="margin: -45px 0 0 0">
                              <apexchart width="100%" height="270" type="radialBar" :options="actualChartOptions" :series="indicator.goalRadial"></apexchart>
                            </div>
                          </div>

                          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.dataFilter == 'all'">{{ $t(`indicator.${indicator.data}`, $store.state.locale) }}</h5>
                          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length  > 1">{{ $t(`indicator.datafilter.treatments`, $store.state.locale) }}</h5>
                          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length  > 1">{{ $t(`indicator.datafilter.products`, $store.state.locale) }}</h5>
                          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length < 2">{{ $t(`indicator.datafilter.treatmentCategory`, $store.state.locale) }}</h5>
                          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length < 2">{{ $t(`indicator.datafilter.productCategory`, $store.state.locale)  }}</h5>
                          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length > 1">{{ $t(`indicator.datafilter.treatmentCategories`, $store.state.locale) }}</h5>
                          <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length > 1">{{ $t(`indicator.datafilter.productCategories`, $store.state.locale) }}</h5>

                          <div class="indictor-items pb-2" v-if="(indicator.graphType == 'number' && indicator.treatments.length > 1) || (indicator.graphType == 'number' && indicator.products.length > 1) || (indicator.graphType == 'number' && indicator.treatmentTypes.length > 1) || (indicator.graphType == 'number' && indicator.productTypes.length > 1) || indicator.graphType != 'number'">
                            <v-chip v-for="(treatment, index) in indicator.treatments" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getTreatmentName(treatment) }}</v-chip>
                            <v-chip v-for="(product, index) in indicator.products" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style">{{ getProductName(product) }}</v-chip>
                            <v-chip v-for="(treatmentType, index) in indicator.treatmentTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style">{{ getTreatmentTypeName(treatmentType) }}</v-chip>
                            <v-chip v-for="(productType, index) in indicator.productTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style">{{ getProductTypeName(productType) }}</v-chip>
                          </div>

                          <div v-if="indicator.graphType == 'number'">
                            <div v-if="indicator.useGoal" class="px-1">
                              <strong>{{ sampleData.goal }}%</strong>
                              <v-progress-linear :value="sampleData.goal" :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" ></v-progress-linear>
                            </div>
                          </div>

                        </v-card-text>
                      </div>

                      <div v-if="indicator.w == 8">
                        <v-layout row wrap>
                        <!-- <v-flex xs12>
                          <v-list class="ma-0 pa-0 no-hover-effect" flat inactive>
                            <v-list-item class="pa-0 ma-0" style="min-height: 0px;">
                              <v-list-item-content  class="pa-0 ma-0">
                                <div>
                                  <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.dataFilter == 'all'">{{ $t(`indicator.${indicator.data}`, $store.state.locale) }}</v-list-item-title>
                                  <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length == 1"> {{ getTreatmentName(indicator.treatments[0]) }} </v-list-item-title>
                                  <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length == 1">  {{ getProductName(indicator.products[0]) }}</v-list-item-title>
                                  <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length > 1">{{ $t(`indicator.datafilter.treatments`, $store.state.locale) }}</v-list-item-title>
                                  <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length > 1">{{ $t(`indicator.datafilter.products`, $store.state.locale) }}</v-list-item-title>
                                  <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length < 2">{{ $t(`indicator.datafilter.treatmentCategory`, $store.state.locale) }}</v-list-item-title>
                                  <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length < 2">{{ $t(`indicator.datafilter.productCategory`, $store.state.locale) }}</v-list-item-title>
                                  <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length > 1">{{ $t(`indicator.datafilter.treatmentCategories`, $store.state.locale) }}</v-list-item-title>
                                  <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length > 1">{{ $t(`indicator.datafilter.productCategories`, $store.state.locale) }}</v-list-item-title>
                                </div>
                              </v-list-item-content>
                              <v-list-item-action class="mt-0 mb-0 ml-0"  style="z-index: 999">
                                <v-btn icon>
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-dots-vertical</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-flex>
                        -->
                        <v-flex :class="[{'xs12' :indicator.graphType == 'number' },{'xs6' :indicator.graphType != 'number' }]">
                          <v-list class="ma-0 pa-0 clear" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :class="{'pt-2': indicator.graphType != 'number'}">
                            <v-list-item class="pa-0 pl-0 ma-0">
                              <v-list-item-avatar
                                v-if="indicator.graphType == 'number'"
                                size="56"
                                light
                                rounded="lg"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" 
                                >
                                <v-icon v-if="indicator.dataType == 'revenue'" color="#fff">mdi-trending-up</v-icon>
                                <v-icon v-else color="#fff">mdi-label</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <!-- <v-list-item-subtitle>
                                  <h4 class="indicator-subheading smooth">{{ $t(`indicator.${indicator.dataType}`, $store.state.locale) }}</h4>
                                </v-list-item-subtitle> -->
                                <v-list-item-title v-if="indicator.dataType == 'revenue'">
                                  <h2 class="indicator-number" :class="'size-'+ indicator.w">€{{ sampleData.revenue }} </h2>
                                </v-list-item-title>
                                <v-list-item-title v-else>
                                  <h2 class="indicator-number" :class="'size-'+ indicator.w">{{ sampleData.number }} </h2>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          
                        </v-flex> 
                        <v-flex xs6 v-if="indicator.graphType != 'number'">
                          <div v-if="indicator.graphType == 'bar' || indicator.graphType == 'line'" >
                            <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && !indicator.useGoal" class="pt-3">
                              <apexchart width="100%" height="80" :type="indicator.graphType" :options="actualChartOptions"  :series="indicator.chartData"></apexchart>
                            </div>

                            <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && indicator.useGoal" class="pb-0" style="margin: -45px 0 0 0">
                              <apexchart width="100%" height="270" type="radialBar" :options="actualChartOptions" :series="indicator.goalRadial"></apexchart>
                            </div>
                          </div>
                        </v-flex>

                        <v-flex xs12 class="indictor-items pt-0 pb-2" v-if="(indicator.graphType == 'number' && indicator.treatments.length > 1) || (indicator.graphType == 'number' && indicator.products.length > 1) || (indicator.graphType == 'number' && indicator.treatmentTypes.length > 0) || (indicator.graphType == 'number' && indicator.productTypes.length > 0) || indicator.graphType != 'number'">
                          <v-chip v-for="(treatment, index) in indicator.treatments" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style"> {{ getTreatmentName(treatment) }}</v-chip>
                          <v-chip v-for="(product, index) in indicator.products" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style">{{ getProductName(product) }}</v-chip>
                          <v-chip v-for="(treatmentType, index) in indicator.treatmentTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style">{{ getTreatmentTypeName(treatmentType) }}</v-chip>
                          <v-chip v-for="(productType, index) in indicator.productTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style">{{ getProductTypeName(productType) }}</v-chip>
                        </v-flex>
                        <v-flex xs12 v-if="indicator.graphType == 'number' && indicator.useGoal" class="pt-0">
                          <div v-if="indicator.useGoal" class="px-1">
                            <strong>{{ sampleData.goal }}%</strong>
                            <v-progress-linear :value="indicator.goal" :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" ></v-progress-linear>
                          </div>
                        </v-flex>
                      </v-layout>
                      </div>
                    </v-card> 
                </v-flex>
                <v-flex :class="exampleWidth.extra" d-flex>
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="pa-4 theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%">
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>

          <v-form ref="addDepositForm" lazy-validation>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md12>
                  <v-select
                    class="mt-4"
                    outlined
                    dense
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.color"
                    :items="colors"
                    :label="$t(`indicator.color`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    @change="changePreviewColor()"
                  ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.dataType"
                    :items="dataTypes"
                    :label="$t(`indicator.dataType`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    @change="resetDataSubType"
                  ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.data"
                    :items="dataSubTypes"
                    :label="$t(`indicator.data`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    @change="resetDataSubType"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'treatments' || indicator.data == 'products'"> 
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.dataFilter"
                    :items="dataFilters"
                    :label="$t(`indicator.filter`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    @change="loadDataFilter()"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'products' && indicator.dataFilter == 'selected'">
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    multiple
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.products"
                    :items="products"
                    :label="$t(`indicator.productsFilter`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    :loading="productsLoading"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'products' && indicator.dataFilter == 'categories'">
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    multiple
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.productTypes"
                    :items="productTypes"
                    :label="$t(`indicator.categories`, $store.state.locale)"
                    :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                    item-value="id"
                    :loading="productTypesLoading"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected'">
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    multiple
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.treatments"
                    :items="treatments"
                    :label="$t(`indicator.treatmentsFilter`, $store.state.locale)"
                    item-text="treatmentTitle"
                    item-value="id"
                    :loading="treatmentsLoading"
                  ></v-select>
                </v-flex>
                <v-flex md6 v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories'">
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    multiple
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.treatmentTypes"
                    :items="treatmentTypes"
                    :label="$t(`indicator.categories`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                    :loading="treatmentTypesLoading"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout row wrap>  
                <v-flex md6>
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.graphType"
                    :items="graphTypes"
                    :label="$t(`indicator.viewType`, $store.state.locale)"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-flex> 
                <v-flex md6>
                  <v-select
                    class="mt-4"
                    hide-details
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="indicator.w"
                    :label="$t(`indicator.size`, $store.state.locale)"
                    :items="sizes"
                    item-text="name"
                    item-value="id"
                    @change="calculateXY()"
                  ></v-select>
                </v-flex> 
                
                <v-card outlined class="pa-4 my-2 mx-1" style="width: 100%">
                  <h3>Doel</h3>
                  <v-layout row wrap>
                    <v-flex md6>
                      <v-switch
                        class="ma-0 mt-2 pa-0"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :label="$t(`indicator.useGoal`, $store.state.locale)"
                        hide-details
                        v-model="indicator.useGoal"
                        @change="resetGoal()"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex md6>
                      <v-select
                        v-if="indicator.useGoal"
                        class="mt-4"
                        outlined
                        dense
                        hide-details
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        v-model="indicator.goalType"
                        :items="goalTypes"
                        :label="$t(`indicator.goalType`, $store.state.locale)"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field 
                        v-if="indicator.useGoal && indicator.goalType == 'number'"
                        :label="indicator.dataType == 'revenue' ? $t(`indicator.revenueGoal`, $store.state.locale) : $t(`indicator.numberGoal`, $store.state.locale)"
                        class="mt-4"
                        hide-details
                        dense
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        v-model.number="indicator.goalNumber" 
                        type="number"
                        autocomplete
                        required
                        outlined
                        :prefix="indicator.dataType == 'revenue' ? '€' : null"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-select
                        v-if="indicator.useGoal && indicator.goalType == 'number'"
                        class="mt-4"
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        v-model="indicator.goalPeriod"
                        :items="periods"
                        persistent-hint
                        :label="$t(`indicator.goalPeriod`, $store.state.locale)"
                        :hint="$t(`indicator.goalPeriodHint`, $store.state.locale)"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-card>

                <v-flex md6>
                  <v-switch
                    class="ma-0 mt-2 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :label="$t(`indicator.activeHint`, $store.state.locale)"
                    hide-details
                    v-model="indicator.active"
                  ></v-switch>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeIndicatorDialog()">
                   {{ $t('general.cancel', $store.state.locale) }} 
                </v-btn>
              </v-flex>
              <v-flex xs12 md8 class="text-right">
                <v-btn v-if="type == 'new'" :dark="!loading" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :loading="loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="validateIndicator()">
                  {{ $t(`indicator.addIndicator`, $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>

                <v-btn
                  v-else
                  :disabled="!changed" 
                  :depressed="!changed"  
                  :dark=" changed"  
                  :loading="busy"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  @click="validateIndicator()"
                >
                <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else> {{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>   
              </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import db from "../../firebase/init";
  import { bus } from "../../main";

  export default {
    name: "add-barometers-form",
    data() {
      return {
        showDialog: false,
        type: 'new',
        coordinates: new Object(),
        indicator: {
          color: "primary",
          products: new Array(),
          productTypes: new Array(),
          treatments: new Array(),
          treatmentTypes: new Array(),
          dataFilter: 'all',
          graphType: "number",
          data: "general",
          dataType: "revenue",
          useGoal: false,
          goalNumber: null,
          goalType: 'number',
          goalPeriod: null,
          active: true,
          chartData: new Array(), // Wordt later berekend in het dashboard
          revenue: 0, // Wordt later berekend in het dashboard
          number: 0, // Wordt later berekend in het dashboard
          goal: 0, // Wordt later berekend in het dashboard
          w: 8,
          x: 0,
          y: 0,
          h: 4,
        },
        indicatorOriginal: new Object(),
        chartColors: ['#E91E63'],
        sampleData: {
          revenue: "52.569",
          number: 27,
          goal: 75
        },
        radialChartseries: [44],
        actualChartseries: [{data: [44, 55, 41, 67]}],
        actualChartOptions: {   
          chart: {
            offsetX: 0,
            offsetY: 0,
            sparkline: {
                enabled: true,
            },
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
          },
          plotOptions: {
            radialBar: {
              inverseOrder: false,
              startAngle: -90,
              endAngle: 90,
              offsetX: 0,
              offsetY: 0,            
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '18px'
                }
              }
            },
          //   bar: {
          //     borderRadius: 5,
          //     columnWidth: '10px',
          //     barHeight: '70%',
          // distributed: false,
          // rangeBarOverlap: true,
          // rangeBarGroupRows: false,
          //   }
          },
          stroke: {
              curve: 'smooth',
              lineCap: 'round',
            },
          tooltip: {
            enabled: false,
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            padding: {
              bottom: 0
            },
            show: true,
            strokeDashArray: 6,
            position: 'back',
            xaxis: {
              lines: {
                  show: false
              }
            },   
            xaxis: {
              lines: {
                  show: true
              }
            }, 
            yaxis: {
              lines: {
                  show: false
              }
            },
            
            markers: {
              size: 1
            },
 
            padding: {
              top: 0,
              right: 0,
              bottom: 5,
              left: 0
            },  
          },
          
          // colors: new Array(),   
          xaxis: {
            labels: {
              show: false,
              offsetX: 0,
              offsetY: 0,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
          },
            
          yaxis: {
            labels: {
              show: false,
              offsetX: 0,
              offsetY: 0
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
          },
        },

        feedback: null,
        loading: true,
        busy: false,
        colors: [
           {
            id: "primary",
            name: "Primair"
          },
          {
            id: "secondary",
            name: "Secondair"
          },
          {
            id: "tertiary",
            name: "Tertiar"
          },
          {
            id: "quaternary",
            name: "Quaternair"
          }
        ],
        graphTypes: [
          {
            id: "line",
            name: this.$t(`indicator.lineChart`, this.$store.state.locale)
          },
          {
            id: "bar",
            name: this.$t(`indicator.barChart`, this.$store.state.locale)
          },
          {
            id: "number",
            name: this.$t(`indicator.numberChart`, this.$store.state.locale)
          }
        ],
        goalTypes: [
          {
            id: "number",
            name: this.$t(`indicator.numberInput`, this.$store.state.locale)
          },
          {
            id: "schedule",
            name: this.$t(`indicator.scheduleInput`, this.$store.state.locale),
            disabled: true
          }
        ],
        dataTypes: [
          {
            id: "numbers",
            name: this.$t(`indicator.numberType`, this.$store.state.locale)
          },
          {
            id: "revenue",
            name: this.$t(`indicator.revenueType`, this.$store.state.locale)
          }
        ],
        sizes: [
          {
            id: 4,
            name: this.$t(`indicator.smallSize`, this.$store.state.locale)
          },
          {
            id: 8,
            name: this.$t(`indicator.mediumSize`, this.$store.state.locale)
          }
        ],
        periods: [
          {
            id: "week",
            name: this.$t(`general.week`, this.$store.state.locale)
          },
          {
            id: "fourWeeks",
            name: this.$t(`general.fourWeeks`, this.$store.state.locale)
          },
          {
            id: "month",
            name: this.$t(`general.month`, this.$store.state.locale)
          }
        ],
        treatmentsLoading: true,
        treatmentTypesLoading: true,
        productsLoading: true,
        productTypesLoading: true,
        treatments: new Array(),
        treatmentTypes: new Array(),
        products: new Array(),
        productTypes: new Array(),
      };
    },

    created(){
      bus.$on("addIndicator", (coordinates) => { 
        this.type = "new";
        this.coordinates = coordinates;
        this.calculateXY()
        this.loading = false;  
        this.showDialog = true;
      });

      bus.$on("editIndicator", (indicator) => {
        this.loading = true; 
        this.indicator = _.cloneDeep(indicator);
        this.indicatorOriginal = _.cloneDeep(this.indicator);
        this.changePreviewColor();
         new Promise((resolve)=>{
          if(indicator.products.length > 0){
            this.loadProducts().then(()=>{
              resolve()
            })
          }
          else if(indicator.treatments.length > 0){
            this.loadTreatments().then((response)=>{
              resolve()
            })
          }
          else if(indicator.treatmentTypes.length > 0){
            this.loadTreatmentTypes().then(()=>{
              resolve()
            })
          }
          else if(indicator.productTypes.length > 0){
            this.loadProductTypes().then(()=>{
              resolve()
            })
          }
          else{
            resolve()
          }
        })
        .then(()=>{
          this.loading = false;
          this.showDialog = true;
          this.type = "edit"; 
        })  
      });
      
      this.actualChartOptions.colors = new Array();
      this.actualChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].primary);
    },

    methods: {
      closeIndicatorDialog() { // Close Dialog Modal
        this.showDialog = false;
        this.resetIndicator();
      },

      resetIndicator(){
        this.indicator.color = "primary";
        this.indicator.products = new Array();
        this.indicator.productTypes = new Array();
        this.indicator.treatments = new Array();
        this.indicator.treatmentTypes = new Array();
        this.indicator.dataType = "revenue";
        this.indicator.graphType = "number";
        this.indicator.data = "general";
        this.indicator.useGoal = false;
        this.indicator.goalNumber = null;
        this.indicator.goalType = 'number';
        this.indicator.goalPeriod = null;
        this.indicator.active = true;
        this.indicator.chartData = new Array();
        this.indicator.revenue = 0;
        this.indicator.number = 0;
        this.indicator.goal = 0;
        this.indicator.w = 8;
        this.indicator.x = 0;
        this.indicator.y = 0;
        this.indicator.h = 4;
        this.coordinates = new Object();
      },

      resetDataSubType(){
        if(this.indicator.dataType == 'numbers'){
          if(this.indicator.data == 'general'){
            this.indicator.data == 'treatments'
            this.indicator.dataFilter = 'all';
          } 
        }
        else{
          this.indicator.dataFilter = 'all';
          this.indicator.treatments = new Array();
          this.indicator.products = new Array();
          this.indicator.treatmentTypes = new Array();
          this.indicator.productTypes = new Array();
        }
      },

      validateIndicator(){
        this.feedback = null;
        let goalValid = !this.indicator.useGoal || (this.indicator.useGoal && ((this.indicator.goalType == 'number' && this.indicator.goalNumber && this.indicator.goalPeriod) || this.indicator.goalType == 'schedule')) ? true : false;
        let treatmentsValid = this.indicator.data != 'treatments' || this.indicator.data == 'treatments' && this.indicator.dataFilter != 'selected' || (this.indicator.data == 'treatments' && this.indicator.dataFilter == 'selected' && this.indicator.treatments.length > 0) ? true : false;
        let productsValid = this.indicator.data != 'products' ||  this.indicator.data == 'products' && this.indicator.dataFilter != 'selected' || (this.indicator.data == 'products' && this.indicator.dataFilter == 'selected' && this.indicator.products.length > 0) ? true : false;
        let treatmentsTypesValid = this.indicator.data != 'treatments' || this.indicator.data == 'treatments' && this.indicator.dataFilter != 'categories' || (this.indicator.data == 'treatments' && this.indicator.dataFilter == 'categories' && this.indicator.treatmentTypes.length > 0) ? true : false;
        let productsTypesValid = this.indicator.data != 'products' ||  this.indicator.data == 'products' && this.indicator.dataFilter != 'categories' || (this.indicator.data == 'products' && this.indicator.dataFilter == 'categories' && this.indicator.productTypes.length > 0) ? true : false;

        if(goalValid && treatmentsValid && productsValid && treatmentsTypesValid && productsTypesValid){
          if(this.type == 'new'){
            this.addIndicator()
          }
          else{
            this.updateIndicator()
          }
        }
        else{
          if(!treatmentsValid){
            this.feedback = this.$t(`indicator.feedbackTreatments`, this.$store.state.locale); 
          }
          else if(!productsValid){
            this.feedback = this.$t(`indicator.feedbackProducts`, this.$store.state.locale); 
          } 
          else if(!treatmentsTypesValid || !productsTypesValid){
            this.feedback = this.$t(`indicator.feedbackCategories`, this.$store.state.locale); 
          }
          else if(!goalValid){
            this.feedback = this.$t(`indicator.feedbackGoal`, this.$store.state.locale); 
          }
        }
      },

      addIndicator(){
        db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("indicators")
        .add(this.indicator)
        .then((docRef)=>{
          this.indicator.i = docRef.id;
          this.$emit("updateData", _.cloneDeep(this.indicator));
          
          this.closeIndicatorDialog();
        })
        .catch((err) => {
          console.error("Error adding indicator", err);
        });
      },

      updateIndicator(){
        this.busy = true;
        db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("indicators")
        .doc(this.indicator.i)
        .set({
            color: this.indicator.color,
            products: this.indicator.products,
            productTypes: this.indicator.productTypes,
            treatments: this.indicator.treatments,
            treatmentTypes: this.indicator.treatmentTypes,
            dataType: this.indicator.dataType,
            graphType: this.indicator.graphType,
            data: this.indicator.data,
            useGoal: this.indicator.useGoal,
            goalNumber: this.indicator.goalNumber,
            goalType: this.indicator.goalType,
            goalPeriod: this.indicator.goalPeriod,
            active: this.indicator.active,
            chartData: this.indicator.chartData,
            revenue: this.indicator.revenue,
            number: this.indicator.number,
            goal: this.indicator.goal,
            w: this.indicator.w
          },{merge: true})
        .then(()=>{
          this.$emit("updateDataEdit", _.cloneDeep(this.indicator));
          this.closeIndicatorDialog();
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          console.error("Error adding indicator", err);
        });
      },

      loadDataFilter(){
        this.indicator.products = new Array();
        this.indicator.productTypes = new Array();
        this.indicator.treatments = new Array();
        this.indicator.treatmentTypes = new Array();

        if(this.indicator.data == 'products' && this.indicator.dataFilter == 'selected'){
          this.loadProducts()
        }
        if(this.indicator.data == 'products' && this.indicator.dataFilter == 'categories'){
          this.loadProductTypes()
        }
        if(this.indicator.data == 'treatments' && this.indicator.dataFilter == 'selected'){
          this.loadTreatments()
        }
        if(this.indicator.data == 'treatments' && this.indicator.dataFilter == 'categories'){
          this.loadTreatmentTypes()
        }
      },

      async loadProducts(){
        if(this.productsLoading){
          this.products = new Array();
          await db.collection("products")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let product = doc.data();
              product.id = doc.id;
              this.products.push(product);
            });
          })
          .then(()=>{
            this.productsLoading = false;
            return
          })
        }
        else{
          return
        }
      },

      async loadProductTypes(){
        if(this.productTypesLoading){
          this.productTypes = new Array();
          await db.collection("productCategories")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let productType = doc.data();
              productType.id = doc.id;
              this.productTypes.push(productType);
            });
          })
          .then(()=>{
            this.productTypesLoading = false;
            return
          })
        }
        else{
          return
        }
      },

      async loadTreatmentTypes(){
        if(this.treatmentTypesLoading){
          this.treatmentTypes = new Array();
          await db.collection("treatmentTypes")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let treatmentType = doc.data();
              treatmentType.id = doc.id;
              this.treatmentTypes.push(treatmentType);
            });
          })
          .then(()=>{
            this.treatmentTypesLoading = false;
            return
          })
        }
        else{
          return
        }
      },

      async loadTreatments(){
        if(this.treatmentsLoading){
          console.log("get treatments")
          this.treatments = new Array();
          await db.collection("treatments")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let treatment = doc.data();
              treatment.id = doc.id;
              this.treatments.push(treatment);
            });
          })
          .then(()=>{
            this.treatmentsLoading = false;
            return;
          })
        }
        else{
          return;
        }
      },

      changePreviewColor(){
        let actualChartOptions = _.cloneDeep(this.actualChartOptions);
        actualChartOptions.colors = new Array();
        actualChartOptions.colors.push(this.$themes[this.companyTheme][this.theme][this.indicator.color]);
        this.actualChartOptions = actualChartOptions
      },

      getTreatmentName(id){
        if(id){   
          let name = null;
          let index = _.findIndex(this.treatments, {'id': id})
          if(index => 0){
            name =  this.treatments[index].treatmentTitle;
          }
          return name;
        }
        else{
          return null;
        }
      },

      getProductName(id){
        if(id){   
          let name = null;
          let index = _.findIndex(this.products, {'id': id})
          if(index => 0){
            name =  this.products[index].name;
          }
          return name;
        }
        else{
          return null;
        }
      },

      getTreatmentTypeName(id){
        if(id){   
          let name = null;
          let index = _.findIndex(this.treatmentTypes, {'id': id})
          if(index => 0){
            name =  this.treatmentTypes[index].name;
          }
          return name;
        }
        else{
          return null;
        }
      },

      getProductTypeName(id){
        if(id){   
          let name = null;
          let index = _.findIndex(this.productTypes, {'id': id})
          if(index => 0){
            name =  this.productTypes[index].name[(this.$store.state.locale).toLowerCase()];
          }
          return name;
        }
        else{
          return null;
        }
      },

      calculateXY(){
        console.log("calculateXY")
        if(this.type == 'new'){
          if(this.indicator.w == 8 && (this.coordinates.x <= 4 && this.coordinates.w == 4)){
            this.indicator.x = this.coordinates.x + this.coordinates.w;
            this.indicator.y = this.coordinates.y;
          }
          else if(this.indicator.w == 4 && ((this.coordinates.x <= 4 && this.coordinates.w == 4) || (this.coordinates.x == 0 && this.coordinates.w == 8))){
            this.indicator.x = this.coordinates.x + this.coordinates.w;
            this.indicator.y = this.coordinates.y;
          }
          else if(this.coordinates.x == 0 && this.coordinates.y == 0 && this.coordinates.w == 0){
            this.indicator.x = 0;
            this.indicator.y = 0;
          }
          else{
            this.indicator.x = 0;
            this.indicator.y = this.coordinates.y + 4;
          }
        }
        
      },
      resetGoal(){
        this.indicator.goalNumber = null;
        this.indicator.goalPeriod = null;
      },

    },
    computed: {
      exampleWidth(){
        let sizes = {
          example: "xs8",
          extra: "xs4"
        }

        if(this.indicator.w == 4){
          sizes.example = "xs4";
          sizes.extra = "xs8";
        }
        return sizes;
      },

      dataSubTypes(){
        let types=  [
          {
            id: "general",
            name: this.$t(`indicator.generalFilter`, this.$store.state.locale),
            disabled: this.indicator.dataType == 'numbers' ? true : false          
          },
          {
            id: "treatments",
            name: this.$t(`indicator.treatmentsFilter`, this.$store.state.locale)
          },
          {
            id: "products",
            name: this.$t(`indicator.productsFilter`, this.$store.state.locale)
          }
        ]
        return types
      },

      dataFilters(){
        let filter = [
          {
            id: "all",
            name: this.indicator.data == 'treatments' ? this.$t(`indicator.allTreatmentsFilter`, this.$store.state.locale) : this.$t(`indicator.allProductsFilter`, this.$store.state.locale)   
          },
          {
            id: "categories",
            name: "Categorieën"
          },
          {
            id: "selected",
            name: this.indicator.data == 'treatments' ? this.$t(`indicator.selectTreatmentsFilter`, this.$store.state.locale) : this.$t(`indicator.selectProductsFilter`, this.$store.state.locale)       
          }
        ];
        return filter;
      },
       
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId(){
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme(){
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      changed(){
        return _.isEqual(this.indicator, this.indicatorOriginal) ? false : true;
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
      
    }
  };
</script>
<style>
  .clear:after{
    display: block;
    content: "";
    clear: both;
  }
  .indicator-heading{
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  .indicator-heading.size-8{
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  h4.indicator-subheading{
    font-size: 14px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  h5.indicator-subheading{
    font-size: 12px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  h2.indicator-number{
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }

  h2.indicator-number.size-8{
    font-weight: 700;
    font-size: 38px;
    letter-spacing: 0.0125em;
    line-height: 1.5;
    color: var(--text-color)
  }
  .indicator-heading.smooth,
  h3.indicator-heading.smooth,
  h4.indicator-subheading.smooth,
  h5.indicator-subheading.smooth{
    color: var(--textSmooth-color)
  }

  .no-hover-effect .v-list-item:hover{
    background: none;
  }
</style>


