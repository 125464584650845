
<script>
import moment from "moment";
import db from "../../firebase/init";
import { bus } from "../../main";
import { buildUpForm } from "../../modules/formBuildUp";
import algoliasearch from "algoliasearch/lite";
import vueSignature from "vue-signature";
import AddPhonenumber from "@/components/modals/AddPhoneNumber.vue";
import AddEmailaddress from "@/components/modals/AddEmailaddress.vue";
import axios from "axios";

export default {
	name: "addClientForm",
	components: {
		vueSignature,
		"add-phonenumber-dialog": AddPhonenumber,
		"add-emailaddress-dialog": AddEmailaddress,
	},
	props: {
		clientInfo: Object,
		typeForm: String,
		chosenTemplate: Object,
		existingDoc: Object,
	},

	data() {
		return {
			algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
			formFields: new Array(),
			loading: true,
			buildedDoc: new Object(),
			formIsValid: false,
			chosenTemplateNew: new Object(),
			existingDocNew: new Object(),
			relatedTo: new Array(),
			rebuildFormVue: 0,
			employeeSearch: "Joe van Ettekoven",
			test: "",
			idCopy: false,
			score: 0,
			dialogScore: false,
			scoreInfo: new Object(),
			scoreRegister: [],
			activeFieldIndex: null, //for addNewPhoneNumber function
			imageIsLoading: false,
			
			phoneHeaders: [
				{
					text: this.$t("clients.phoneType", this.$store.state.locale),
					align: "center",
					value: "type",
					width: "50px",
					sortable: false,
				},
				{
					text: this.$t("clients.phonenumber", this.$store.state.locale),
					align: "left",
					value: "phone",
					sortable: false,
				},
				{
					text: "",
					align: "center",
					value: "primary",
					width: "40px",
					sortable: false,
				},
				{
					text: "",
					align: "center",
					value: "primary",
					width: "40px",
					sortable: false,
				},
			],
			emailHeaders: [
				{
					text: this.$t("clients.email", this.$store.state.locale),
					align: "left",
					value: "email",
					sortable: false,
				},
				{
					text: "score",
					align: "center",
					value: "primary",
					width: "40px",
					sortable: false,
				},
				{
					text: "",
					align: "center",
					value: "primary",
					width: "40px",
					sortable: false,
				},
				{
					text: "",
					align: "center",
					value: "primary",
					width: "40px",
					sortable: false,
				},
			],
		};
	},

	created() {
		this.loading = true;
		console.log("newOrExisting created")

		

		this.chosenTemplateNew = this.chosenTemplate;
		this.existingDocNew = this.existingDoc;

		if (this.existingDocNew === null) {
			//Create a new Form or Rapport

			this.buildedDoc = buildUpForm(this.chosenTemplateNew, null);

			console.log(null, this.buildedDoc);
		} else {
			//Its a existing document

			this.buildedDoc = buildUpForm(this.chosenTemplateNew, this.existingDocNew);

			console.log(this.buildedDoc, "Existing Doc available");
		}

		this.loading = false;

		bus.$on("newOrExisting", (chosenTemplate, existingDoc) => {
			this.loading = true;
			console.log("newOrExisting bus", this.chosenTemplateNew)

			this.chosenTemplateNew = chosenTemplate;
			this.existingDocNew = existingDoc;

			

			if (this.existingDocNew === null) {
				console.log("new");
				this.buildedDoc = new Object();
				//Generated a new One by the template
				this.buildedDoc = buildUpForm(this.chosenTemplateNew, null);

				console.log(null, this.buildedDoc);
			} else {
				//Its a existing document

				this.buildedDoc = new Object();

				this.buildedDoc = buildUpForm(this.chosenTemplateNew, this.existingDocNew);

				console.log(this.buildedDoc, "Existing Doc available");
			}

			this.loading = false;
		});
	},

	methods: {
		closeDialog() {
			this.$emit("closeDialog", false);
		},

		saveDocument() {
			if (this.existingDocNew === null) {
				this.saveNewDocument();
			} else {
				this.saveExistingDocument();
			}
		},

		saveNewDocument() {
			console.log("SAVE NEW DOCUMENT", this.$store.state.activeCompany.id);

			this.loading = true;

			this.buildedDoc.clientId = this.clientInfo.id;
			this.buildedDoc.companyId = this.$store.state.activeCompany.id;
			this.buildedDoc.created = new Date();
			this.buildedDoc.updated = new Date();
			this.buildedDoc.updatedBy = new Array();
			this.buildedDoc.updatedBy.push({
				name: this.userFirstName + " " + this.userLastName,
				userRole: this.userRole,
				timestamp: new Date(),
				userId: this.userId,
			});
			this.buildedDoc.chosenTemplateId = this.chosenTemplate.id;
			

			

			//TODO Branches wegschrijven en bij save existing document ook.
			// this.buildedDoc.branches

			db.collection("forms")
				.add(this.buildedDoc)
				.then((docRef) => {
					console.log("Document saved", docRef.id);
					this.loading = false;
					this.$emit("reloadForms");
					this.$emit("closeDialog", false);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		saveSignature() {
			//Save signature

			let vm = this;
			let png = vm.$refs.signatureref.save();
			console.log(png);
		},

		clearSignature() {
			//Clear signature

			let vm = this;
			vm.$refs.signatureref.clear();
		},

		saveExistingDocument() {
			console.log("SAVE EXISTING DOCUMENT");

			this.loading = true;

			this.buildedDoc.updated = new Date();
			
			this.buildedDoc.updatedBy.push({
				name: this.userFirstName + " " + this.userLastName,
				userRole: this.userRole,
				timestamp: new Date(),
				userId: this.userId,
			});

			db.collection("forms")
				.doc(this.existingDoc.id)
				.set(this.buildedDoc)
				.then(
					() => {
						console.log("Document successfully written!", this.existingDoc.id);
						this.loading = false;
						this.$emit("reloadForms");
						this.$emit("closeDialog", false);
					},
					{ merge: true }
				)
				.catch((error) => {
					console.log(error);
				});
		},

		addPhonenumber(fieldIndex) {
			this.activeFieldIndex = fieldIndex;
			bus.$emit("addPhonenumberDialog", this.buildedDoc.fields[fieldIndex].input, "addClientForm");
		},

		setPhonePrimary(item, fieldIndex) {
			if (this.buildedDoc.fields[fieldIndex].input.length !== 0) {
				this.buildedDoc.fields[fieldIndex].input = this.buildedDoc.fields[fieldIndex].input.map((phone) => {
					if (phone.phone.e164 == item.phone.e164) {
						return { ...phone, primary: true };
					} else {
						return { ...phone, primary: false };
					}
				});
			}
		},
		removePhone(item, fieldIndex) {
			this.buildedDoc.fields[fieldIndex].input = this.buildedDoc.fields[fieldIndex].input.filter((phone) => {
				return phone.phone.e164 != item.phone.e164;
			});
			this.checkForPrimaryNumber(fieldIndex);
			this.checkRelated(
				this.buildedDoc.fields[fieldIndex].typeField,
				this.buildedDoc.fields[fieldIndex].uniqId,
				fieldIndex,
				this.buildedDoc.fields[fieldIndex].input
			);
			this.addScore(this.buildedDoc.fields[fieldIndex].typeField, this.buildedDoc.fields[fieldIndex]);
			this.checkScore(
				this.buildedDoc.fields[fieldIndex].typeField,
				this.buildedDoc.fields[fieldIndex].uniqId,
				fieldIndex,
				this.buildedDoc.fields[fieldIndex].input
			);
		},

		addEmailaddress(fieldIndex) {
			this.activeFieldIndex = fieldIndex;
			bus.$emit("addEmailDialog", this.buildedDoc.fields[fieldIndex].input, "addClientForm");
		},

		addNewEmailaddress(data) {
			console.log("ADD NEW EMAIL", data);
			this.buildedDoc.fields[this.activeFieldIndex].input.push(data);
			this.checkRelated(
				this.buildedDoc.fields[this.activeFieldIndex].typeField,
				this.buildedDoc.fields[this.activeFieldIndex].uniqId,
				this.activeFieldIndex,
				this.buildedDoc.fields[this.activeFieldIndex].relatedTo
			);
			this.addScore("phone", this.buildedDoc.fields[this.activeFieldIndex]);
			this.checkScore(this.buildedDoc.fields[this.activeFieldIndex].scoreValue);
		},

		removeEmail(item, fieldIndex) {
			this.buildedDoc.fields[fieldIndex].input = this.buildedDoc.fields[fieldIndex].input.filter((email) => {
				return email.email != item.email;
			});
			this.checkForPrimaryEmail(fieldIndex);
			this.checkRelated(
				this.buildedDoc.fields[fieldIndex].typeField,
				this.buildedDoc.fields[fieldIndex].uniqId,
				fieldIndex,
				this.buildedDoc.fields[fieldIndex].input
			);
			this.addScore(this.buildedDoc.fields[fieldIndex].typeField, this.buildedDoc.fields[fieldIndex]);
			this.checkScore(
				this.buildedDoc.fields[fieldIndex].typeField,
				this.buildedDoc.fields[fieldIndex].uniqId,
				fieldIndex,
				this.buildedDoc.fields[fieldIndex].input
			);
		},

		async checkForPrimaryEmail(fieldIndex) {
			let primary = this.buildedDoc.fields[fieldIndex].input.filter((email) => {
				return email.primary;
			});
			if (!primary || primary.length == 0) {
				if (this.buildedDoc.fields[fieldIndex].input.length > 0) {
					this.setEmailPrimary(this.buildedDoc.fields[fieldIndex].input[0], fieldIndex);
				}
			} else {
				if (this.buildedDoc.fields[fieldIndex].input.length > 0) {
					this.setEmailPrimary(primary[0], fieldIndex);
				}
			}
		},

		setEmailPrimary(item, fieldIndex) {
			this.buildedDoc.fields[fieldIndex].input = this.buildedDoc.fields[fieldIndex].input.map((email) => {
				email.email == item.email ? (email.primary = true) : (email.primary = false);
			});
		},

		async checkForPrimaryNumber(fieldIndex) {
			let primary = this.buildedDoc.fields[fieldIndex].input.filter((phone) => {
				return phone.primary;
			});
			if (!primary || primary.length == 0) {
				let mobilePhones = this.buildedDoc.fields[fieldIndex].input.filter((phone) => {
					return phone.type == "mobile";
				});
				if (!mobilePhones || primary.mobilePhones == 0) {
					if (this.buildedDoc.fields[fieldIndex].input.length > 0) {
						this.setPhonePrimary(this.buildedDoc.fields[fieldIndex].input[0], fieldIndex);
					}
				} else {
					if (this.buildedDoc.fields[fieldIndex].input.length > 0) {
						this.setPhonePrimary(mobilePhones[0], fieldIndex);
					}
				}
			}
		},

		emailScoreColor(score) {
			//Score from 10 to 8 "green"; 8 to 5 "yellow"; 5 to 0 "red"
			if (score >= 8) {
				return "green";
			} else if (score >= 5) {
				return "yellow";
			} else {
				return "red";
			}
		},

		emailScoreDialog(score, error) {
			this.dialogScore = !this.dialogScore;
			this.scoreInfo = {
				error: Object.keys(error).length > 0 ? error : null,
				score: score,
			};
		},

		addNewPhonenumber(data) {
			this.buildedDoc.fields[this.activeFieldIndex].input.push(data);
			this.checkRelated(
				this.buildedDoc.fields[this.activeFieldIndex].typeField,
				this.buildedDoc.fields[this.activeFieldIndex].uniqId,
				this.activeFieldIndex,
				this.buildedDoc.fields[this.activeFieldIndex].relatedTo
			);
			this.addScore("phone", this.buildedDoc.fields[this.activeFieldIndex]);
			this.checkScore(this.buildedDoc.fields[this.activeFieldIndex].scoreValue);
		},

		onImageChange(fieldIndex) {
			console.log("Upload Image", this.$refs["imageUpload" + fieldIndex]);

			//When clicked on clear button via input file
			if (this.buildedDoc.fields[fieldIndex].input.image == null) {
				this.buildedDoc.fields[fieldIndex].input.previewTemp = null;
				this.buildedDoc.fields[fieldIndex].image.docId = null;
				this.buildedDoc.fields[fieldIndex].image.thumbnailImage = null;
				this.buildedDoc.fields[fieldIndex].image.originalImage = null;
				this.buildedDoc.fields[fieldIndex].image.modifiedImage = null;
				this.buildedDoc.fields[fieldIndex].image.name = null;

				this.checkRelated(
					this.buildedDoc.fields[fieldIndex].typeField,
					this.buildedDoc.fields[fieldIndex].uniqId,
					this.activeFieldIndex,
					this.buildedDoc.fields[fieldIndex].relatedTo
				);
				this.addScore("photo", this.buildedDoc.fields[fieldIndex]);
				this.checkScore(this.buildedDoc.fields[fieldIndex].scoreValue);

				return;
			}

			this.imageIsLoading = true;
			const reader = new FileReader();
			reader.onload = (event) => {
				this.buildedDoc.fields[fieldIndex].input.previewTemp = event.target.result; //Tempory the result we dont want to save it in the database
				const img = new Image();
				img.onload = () => {
					this.buildedDoc.fields[fieldIndex].input.width = img.width;
					this.buildedDoc.fields[fieldIndex].input.height = img.height;
					this.imageIsLoading = false;
				};
				img.src = event.target.result;
			};
			reader.readAsDataURL(this.buildedDoc.fields[fieldIndex].input.image);

			return;
		},

		async uploadImage(fieldIndex) {
			let formData = new FormData();
			this.imageIsLoading = true;
			formData.append("file", this.buildedDoc.fields[fieldIndex].input.image);
			formData.append("name", this.buildedDoc.fields[fieldIndex].input.image.name);
			formData.append("width", this.buildedDoc.fields[fieldIndex].input.width);
			formData.append("height", this.buildedDoc.fields[fieldIndex].input.height);
			formData.append("size", this.buildedDoc.fields[fieldIndex].input.image.size);
			formData.append("assetGroup", "formAssets");
			formData.append("assetType", "formImage"); //Dynamisch maken van de assetType
			formData.append("companyId", this.$store.state.activeCompany.id);
			formData.append("uploadedBy", JSON.stringify({ userId: this.userId, username: this.userFirstName + " " + this.userLastName }));
			formData.append("clientId", this.clientInfo.id);

			try {
				let response = await axios.post(`${this.$store.state.uploadApi.url}/upload`, formData);
				//Push to media library
				console.log(response.data);
				let imageUrlData = response.data;

				/* response is like
						
					docId: docId,
					thumbnailImage: fileInfoObject.urlThumbnail,
					originalImage: fileInfoObject.urlOriginal,
					modifiedImage: fileInfoObject.url,
					name: fileInfoObject.name,

				*/

				this.buildedDoc.fields[fieldIndex].image.docId = imageUrlData.docId;
				this.buildedDoc.fields[fieldIndex].image.thumbnailImage = imageUrlData.thumbnailImage;
				this.buildedDoc.fields[fieldIndex].image.originalImage = imageUrlData.originalImage;
				this.buildedDoc.fields[fieldIndex].image.modifiedImage = imageUrlData.modifiedImage;
				this.buildedDoc.fields[fieldIndex].image.name = imageUrlData.name;
				this.buildedDoc.fields[fieldIndex].input.previewTemp = null; //This switches the v-if to the image from the buildedDoc

				console.log("responseData", response.data);
				this.imageIsLoading = false;
				this.checkRelated(
					this.buildedDoc.fields[fieldIndex].typeField,
					this.buildedDoc.fields[fieldIndex].uniqId,
					this.activeFieldIndex,
					this.buildedDoc.fields[fieldIndex].relatedTo
				);
				this.addScore("photo", this.buildedDoc.fields[fieldIndex]);
				this.checkScore(this.buildedDoc.fields[fieldIndex].scoreValue);
			} catch (error) {
				console.error("Error Upload", error);
			}
		},

		scoreIncreaseOrDecrease(type) {
			if (type === "increase") {
				this.score++;
			} else {
				this.score--;
			}
		},

		addScore(typeField, field, option) {
			if (typeField === "open" || typeField === "openTextArea" || typeField === "employeeSelect") {
				// console.log("OPEN", field);
				//	{id:"ABC", score: 5, type:"open", options: null,}
				//Check if field.scoreVaule is not undefined
				if (field.scoreValue !== undefined) {
					//Check if field.input is empty
					if (field.input == "") {
						//Remove the score from the scoreRegister array with field.id
						this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);
					} else {
						//When field.input is NOT empty
						//Check if field.id is not already in the scoreRegister array
						this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);

						//Push again the score to the scoreRegister array
						this.scoreRegister.push({ id: field.uniqId, score: field.scoreValue, type: typeField, options: [] });
					}
				}
			} else if (typeField === "phone" || typeField === "email") {
				//Check if field.scoreVaule is not undefined

				// console.log("PHONE", field, field.input);
				if (field.scoreValue !== undefined) {
					//Check if field.input is empty
					if (field.input.length === 0) {
						//Remove the score from the scoreRegister array with field.id
						this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);
					} else {
						//When field.input is NOT empty
						//Check if field.id is not already in the scoreRegister array
						this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);

						//Push again the score to the scoreRegister array
						this.scoreRegister.push({ id: field.uniqId, score: field.scoreValue, type: typeField, options: [] });
					}
				}
			}  else if (typeField === "photo") {

		
				if (field.scoreValue !== undefined) {
					//Check if field.input is empty
					if (field.image.thumbnailImage === null) {
						//Remove the score from the scoreRegister array with field.id
						this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);
					} else {
						//When field.image.thumbnailImage is NOT empty
						//Check if field.id is not already in the scoreRegister array
						this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);

						//Push again the score to the scoreRegister array
						this.scoreRegister.push({ id: field.uniqId, score: field.scoreValue, type: typeField, options: [] });
					}
				}

			} else if (typeField === "closed") {
				//	{id:"ABC", score: 5, type:"closed", options: null,}

				//Check if field.id is not already in the scoreRegister array
				this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);

				//Push again the score to the scoreRegister array
				this.scoreRegister.push({
					id: field.uniqId,
					score: field.scoreValue ? field.scoreValue : null,
					type: "closed",
					options: [{ id: option.id, score: option.scoreValue }],
				});
			} else if (typeField === "choice") {
				//Check if field.id is not already in the scoreRegister array
				this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);

				//Push again the score to the scoreRegister array
				this.scoreRegister.push({
					id: field.uniqId,
					score: field.scoreValue ? field.scoreValue : null,
					type: "choice",
					options: [{ id: option.id, score: option.scoreValue }],
				});
			} else if (typeField === 'timeSelect') {
			
			//Check if field.id is not already in the scoreRegister array
				this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);

				//Push again the score to the scoreRegister array
				this.scoreRegister.push({
					id: field.uniqId,
					score: field.scoreValue ? field.scoreValue : null,
					type: "timeSelect",
					options: [{ id: option.id, score: option.scoreValue }],
				});

			}	else if (typeField === "multiChoice") {
				console.log("multiChoice", option, field);

				//Check if field.id is not already in the scoreRegister array
				this.scoreRegister = this.scoreRegister.filter((score) => score.id !== field.uniqId);

				//Push again the score to the scoreRegister array
				this.scoreRegister.push({
					id: field.uniqId,
					score: field.scoreValue ? field.scoreValue : null,
					type: "multiChoice",
					options:
						field.input.length !== 0
							? field.input.map((input) => {
									return { id: input.id, score: input.scoreValue };
							  })
							: [], // Its always an array [{ id: option.id, score: option.scoreValue }],
				});
			}

			/**
			 * Sum up the score of the scoreRegister array with reduce
			 */
			this.score = this.scoreRegister.reduce((acc, item) => {
				//Add the score of the field to the acc when its null i will add 0
				acc += item.score;

				item.options.forEach((option) => {
					acc += option.score;
				});

				return acc;
			}, 0);
		},

		checkScore(scoreValue) {
			//TODO Zet er een beschrijving van deze functie bij.

			if (scoreValue == undefined) {
				return;
			}

			//Check if the score is higher or equal to the score of the related question.
			let foundFieldWithRelatedScore = this.buildedDoc.fields.filter((field) => {
				console.log(field.relatedTo, "field.relatedTo", this.score, "vm.score");

				//Check if the field.relatedTo array has a field with "score"
				let foundScore = field.relatedTo.find((related) => related.type === "score");

				if (foundScore) {
					//Check if the score is higher or equal to the score of the related question.
					if (foundScore.scoreValue <= this.score) {
						return true;
					}
				}
			});

			console.log("foundFieldWithRelatedScore", foundFieldWithRelatedScore);

			foundFieldWithRelatedScore.forEach((scoredField) => {
				//Find the right index of the field in the buildedDoc.fields array.
				let index = this.buildedDoc.fields.findIndex((field) => field.uniqId === scoredField.uniqId);

				//First check for duplication in the relatedTo array before pushing the new relatedTo object.
				let alreadyAdded = this.relatedTo.find((related) => related.relatedId === scoredField.uniqId);

				if (!alreadyAdded) {
					this.relatedTo.push({ relatedId: scoredField.uniqId, fieldIndex: index, linkedId: null, type: "score", scoreValue: scoreValue });
				}
			});

			//Remove field from relateTo when the score is lower than the score of the related question.
			this.relatedTo = this.relatedTo.filter((related, index) => {
				if (related.type === "score") {
					// console.log("related.scoreValue > this.score", related.scoreValue, this.score)
					if (related.scoreValue > this.score) {
						// console.log("Score is smaller then needed");
					} else {
						// console.log("Score is bigger then needed");
						return true;
					}
				} else {
					//Its not a score field, so do nothing.
					console.log("Do nothing");
					return true;
				}
			});
		},

		/**
		 * checkRelated will check if the question is related to another question. 
		 * And when related match then push the value to the related question via the relatedTo array.
		 * In vue the v-if is used to show the related questions. Like -> 	<v-col v-if="relatedTo.some((related) => field.relatedTo.id === related.linkedId) && field.typeField === 'open'" cols="12" class="my-2">
		  * @param {String} fieldType
   	  * @param {array} id
    	 * @param {String} fieldIndex | fieldIndex to check open questions
    	 * @param {String} fieldInput | fieldInput to check open questions
    	 * @param {String} linked | Generated by a question, OR find the root uniqId of the Root question, called a basedId related from the first questions. This is the id of the root question.
    
     */

		checkRelated(fieldType, id, fieldIndex, relatedTo) {
			let vm = this;

			console.log("Change Trigger", fieldType, id, fieldIndex, "relatedTo", relatedTo);

			/**
			 * FieldType: closed works with "option.id", because of the multiple selection
			 *
			 */

			// if (
			// 	fieldType === "closed" ||
			// 	fieldType === "open" ||
			// 	fieldType === "choice" ||
			// 	fieldType === "multiChoice" ||
			// 	fieldType === "openTextArea" ||
			// 	fieldType === "email" ||
			// 	fieldType === "employeeSelect"
			// ) {
				let foundRelated = this.relatedTo.find((related) => related.fieldIndex === fieldIndex);
				//If foundRelated is True toggle off
				if (foundRelated) {
					//Filter out the related fields
					console.log("Drop Field", foundRelated);

					removeRelated(vm.relatedTo, foundRelated.linkedId);

					//And filter out by linked
				} else {
					//Its a related field so add it
					console.log("Add Field", fieldType, id, fieldIndex);

					// Filtering the fields of the buildedDoc and returning the fields that have a relatedTo that has
					// an id that matches the id passed in and the type is relation.
					let foundedRelatedFromField = this.buildedDoc.fields.filter((field) => {
						 return field.relatedTo.some((related) => related.id === id && related.type === "relation");
					});

					console.log("foundedRelatedFromField", foundedRelatedFromField);

					if (foundedRelatedFromField.length !== 0) {
						//Find wich field is related to the current field

						foundedRelatedFromField.forEach((foundedField) => {
							//Find the matching id
							let related = foundedField.relatedTo.find((related) => related.id === id);

							console.log("test Relate", related)

							if (related) {
								console.log("IsRelated", related.id);

								//Before push check for duplicates
								let foundDuplicate = this.relatedTo.find((relatedTo) => relatedTo.linkedId === relatedTo.id);
								if (!foundDuplicate) {
									//No Duplicate found so push it
									this.relatedTo.push({ relatedId: foundedField.uniqId, fieldIndex: fieldIndex, linkedId: related.id, type: "relation" });
								} else {
									console.log("duplicate found", foundDuplicate)
								}
							}
						});
					} else {
						console.log("Related field is not found");
					}
				// }
			}

			/**
			 *
			 * @param relatedToArray relatedTo
			 * @param relatedId  relatedId
			 *
			 * Recursive function to remove all related objects by linkedId property
			 *
			 */
			function removeRelated(relatedToArray, linkedId) {
				let foundRelated = relatedToArray.find((relatedTo) => relatedTo.linkedId === linkedId);

				if (foundRelated) {
					vm.relatedTo = vm.relatedTo.filter((relatedTo) => relatedTo.linkedId !== foundRelated.linkedId);

					//Clean the inputfield inside buildedDoc.fields that matches linkedId with uniqId

					vm.buildedDoc.fields = vm.buildedDoc.fields.map((field) => {
						if (field.uniqId === foundRelated.linkedId) {
							field.fieldInput = "";
						}
						return field;
					});

					//.. and remove the next relation by using the relatedId
					removeRelated(vm.relatedTo, foundRelated.relatedId);
				} else {
					return;
				}
			}

			this.rebuildFormVue++;
		},

	

		copyTextToClipboard(text) {
			if (!navigator.clipboard) {
				fallbackCopyTextToClipboard(text);
				return;
			}

			navigator.clipboard.writeText(text).then(
				() => {
					this.idCopy = true;
					setTimeout(() => {
						this.idCopy = false;
					}, 1000);
				},
				function (err) {
					console.error("Async: Could not copy text: ", err);
				}
			);
		},

		addEmployeeSelect(firstName, id) {
			console.log("items:", firstName, id);
			return {
				firstName: firstName,
				id: id,
			};
			this.employeeSearch = "";
		},

		clearEmployee() {
			return null;
		},
	},
	watch: {},

	computed: {
		userLanguage() {
			return this.$store.state.locale ? this.$store.state.locale : "NL";
		},
		userCompanies() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
		},
		userCompany() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
		},
		userFirstName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
		},
		userLastName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
		},
		userRole() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
		},
		userId() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
		},
		theme() {
			return this.$vuetify.theme.dark ? "dark" : "light";
		},
		companyTheme() {
			if (this.$store.state.companyTheme) {
				return this.$store.state.companyTheme;
			} else {
				return "thrive";
			}
		},
	},
};
</script>

<style scoped>
custom-algolia .ais-Highlight-highlighted {
	color: var(--primary-color);
}

.custom-algolia .ais-Hits-item,
.custom-algolia .ais-Hits-list {
	padding: 0 !important;
}
</style>

<template>
<div >

	<v-card tile :style="'background-color:' + $themes[companyTheme][theme].background +';'" style="height: 100%">
		<v-toolbar dense flat style="max-height: 80px" :color="$themes[companyTheme][theme].primary" dark>
			<v-toolbar-title v-if="loading === false">
				{{ buildedDoc.name[$store.state.locale.toLowerCase()] }} {{ $t("general.of", $store.state.locale) }} {{ clientInfo.name }} {{ clientInfo.surname }}
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn :disabled="!formIsValid" dark text :loading="loading" @click="saveDocument()">
					{{ $t("general.save", $store.state.locale) }}
				</v-btn>
				<v-btn dark icon @click="closeDialog()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>

		<!-- <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2 v-if="loading === false"> {{ buildedDoc.name[$store.state.locale.toLowerCase()] }} </h2>
      <v-spacer></v-spacer>
      <v-btn  icon dark>
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeDialog()">mdi-close</v-icon>
      </v-btn>
    </v-card-title> -->
		<!-- <v-alert class="ma-0 pa-2" v-if="feedback" type="warning">{{ feedback }} </v-alert> -->

		<!-- <v-divider></v-divider> -->

		<v-card-text>
			<v-container style="max-width: 540px">
				<v-form v-model="formIsValid">
					<div v-if="loading === false && existingDoc !== null">
						<span class="body-2">{{ $t("general.lastEdited", $store.state.locale) }}: {{ buildedDoc.updated.seconds | moment("D MMMM YYYY - H:mm") }}</span>
					</div>

					<div v-if="$store.state.activeUserRoleNumber > 2">
						<span class="subtitle-1">ID: {{ buildedDoc.id }}</span>
						<div style="display: inline-block" v-if="idCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(buildedDoc.id)">
							<v-icon small>mdi-content-copy</v-icon>
							Kopieër DocId
						</div>
					</div>
					<v-card class="py-6 px-2 vcardForm" flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" >
						<div class="subtitle-2 my-2">{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, buildedDoc.description) }}</div>
					
				
					</v-card>
					<!-- Score: {{ score }}

					<v-btn @click="scoreIncreaseOrDecrease('increase')">++</v-btn>
					<v-btn @click="scoreIncreaseOrDecrease('decrease')">--</v-btn> -->

					<v-row no-gutters v-for="(field, fieldIndex) in buildedDoc.fields" :key="fieldIndex + rebuildFormVue">
						<!-- / Text Field \ -->
						
						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'open') ||
								(field.relatedTo.length === 0 && field.typeField === 'open')
							"
							cols="12"
							class="my-2"
						>
						<v-card class="py-6 px-2 vcardForm" flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" >
							
							
								<div class="pt-2 mb-0"> 
									{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description) }}
								</div>
							<v-text-field
								class="pt-2 mb-0"
								:label="$t('forms.typeHere', $store.state.locale)"
								v-model="field.input"
								:prefix="field.label.prefix ? field.label.prefix : ''"
								:suffix="field.label.suffix ? field.label.suffix : ''"
								:prepend-inner-icon="field.label.prependIcon ? field.label.prependIcon : ''"
								:append-icon="field.label.appendIcon ? field.label.appendIcon : ''"
								:type="field.label.fieldCharacters"
								dense
								:color="$themes[companyTheme][theme].primary"
								background-color="background"
								:rules="[(v) => !!v || $t('clientForm.textInputRequired', $store.state.locale)]"
								:required="field.required"
								@change="
									checkRelated(field.typeField, field.uniqId, fieldIndex, field.relatedTo);
									addScore('open', field);
									checkScore(field.scoreValue);
								"
							></v-text-field>
							</v-card>
						</v-col>
						<!-- \ Text Field / -->

						<!-- / Alert \ -->
						<v-col
							cols="12"
							class="my-2"
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'alert') ||
								(field.relatedTo.length === 0 && field.typeField === 'alert')
							"
						>
							<v-alert text dense :color="field.label.alertSettings.alertColor" :icon="field.label.alertSettings.alertIcon" border="left">
								<!-- Description -->
								{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description) }}
							</v-alert>
						</v-col>
						<!-- \ Alert / -->

						<!-- / Photo Upload \ -->
						<v-col
							cols="12"
							class="my-2"
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'photoUpload') ||
								(field.relatedTo.length === 0 && field.typeField === 'photoUpload')
							"
						>
						<v-card class="py-6 px-2 vcardForm" flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" >
							
							{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description) }}

							<v-file-input
								
								label="Selecteer Afbeelding"
								:value="buildedDoc.fields[fieldIndex].input.image"
						
								@change="onImageChange(fieldIndex)"
								clearable
								accept="image/*"
							></v-file-input>
							<!-- preview before upload -->
							<div v-if="field.image.originalImage === null && field.input.previewTemp !== null">
								<v-img style="width: 478px" :src="field.input.previewTemp" alt="Preview"></v-img>

								<v-btn dense class="mi-2" :loading="imageIsLoading" @click="uploadImage(fieldIndex)">Afbeelding uploaden</v-btn>
							</div>
							<div v-if="field.image.originalImage !== null && field.input.previewTemp == null">
								<v-img style="width: 478px" :src="field.image.thumbnailImage" alt="Preview"></v-img>
								<v-btn dense class="mi-2" disabled>Afbeelding gereed</v-btn>
							</div>
						</v-card>
						</v-col>

						<!-- \ Photo Upload / -->


						<!-- / Time Select \ -->

						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'timeSelect') ||
								(field.relatedTo.length === 0 && field.typeField === 'timeSelect')
							"
							cols="12"
							class="my-2"
						>
						<v-card class="py-6 px-2 vcardForm" flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" >
							
							
								<div class="pt-2 mb-0"> 
									{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description) }}
								</div>
							<v-row>
						<v-col cols='6'>
							<v-select
								:items="Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'))"
								outlined
								return-object
								dense
								:label="$t('basicdictionary.hour', $store.state.locale)"
								v-model="field.input.hours"
								
								class="my-2"
								:color="$themes[companyTheme][theme].primary"
								:background-color="$themes[companyTheme][theme].card"
								:item-color="$themes[companyTheme][theme].primary"
								:required="field.required"
								@change="
								   
									checkRelated(field.typeField, field.input.id, fieldIndex, field.relatedTo);
									addScore('timeSelect', field, field.input);
									checkScore(field.input.scoreValue);
								"
							></v-select>
						</v-col>
							<v-col cols='6'>
							<v-select
								:items="Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'))"
								outlined
								return-object
								dense
								:label="$t('basicdictionary.minutes', $store.state.locale)"
								v-model="field.input.minutes"
								
								class="my-2"
								:color="$themes[companyTheme][theme].primary"
								:background-color="$themes[companyTheme][theme].card"
								:item-color="$themes[companyTheme][theme].primary"
								:required="field.required"
								@change="
								
									checkRelated(field.typeField, field.input.id, fieldIndex, field.relatedTo);
									addScore('timeSelect', field, field.input);
									checkScore(field.input.scoreValue);
								"
							></v-select>
							</v-col>
							</v-row>
							</v-card>
						</v-col>

						<!-- \ Time Select / -->


						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'openTextArea') ||
								(field.relatedTo.length === 0 && field.typeField === 'openTextArea')
							"
							cols="12"
							class="my-2"
						>
							<!-- type textfield could be: "text", "number", "text" via field.label.fieldCharacters -->
							<v-card class="py-6 px-2 " flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" >
							{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description) }}

							<v-textarea
								class="my-2"
								:label="$t('forms.typeHere', $store.state.locale)"
								v-model="field.input"
								
								dense
								:prepend-inner-icon="field.label.prependIcon ? field.label.prependIcon : ''"
								:append-icon="field.label.appendIcon ? field.label.appendIcon : ''"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								background-color="background"
								:rules="[(v) => !!v || $t('clientForm.textInputRequired', $store.state.locale)]"
								:required="field.required"
								@change="
									checkRelated(field.typeField, field.uniqId, fieldIndex, field.relatedTo);
									addScore('openTextArea', field);
									checkScore(field.scoreValue);
								"
							></v-textarea>
							</v-card>
						</v-col>

						<!-- \ Text Area Field / -->

						<!-- / Radio Field \ -->

						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'closed') ||
								(field.relatedTo.length === 0 && field.typeField === 'closed')
							"
							cols="12"
							class="my-2"
						>
						<v-card class="py-6 px-2 vcardForm" flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" >
							<div class="font-weight-regular my-2">
								{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description) }}
							</div>

							<v-radio-group v-model="field.input" :rules="[field.input.length !== 0 || $t('clientForm.chooseYesOrNo', $store.state.locale)]">
								<v-radio
									v-for="(option, optionIndex) in field.optionsClosed"
									:key="optionIndex"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									:label="$autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, option)"
									:value="option.value"
									@change="
										checkRelated(field.typeField, option.id, fieldIndex, field.relatedTo);
										addScore('closed', field, option);
										checkScore(option.scoreValue);
									"
								></v-radio>
							</v-radio-group>
						</v-card>
						</v-col>

						<!-- \ Radio Field / -->

						<!-- / Drop down list \ -->

						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'choice') ||
								(field.relatedTo.length === 0 && field.typeField === 'choice')
							"
							cols="12"
							class="my-2"
						>
						<v-card class="py-6 px-2 vcardForm" flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" >
							<span class="font-weight-regular">
								{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description) }}
							</span>

							<v-select
								:items="field.optionsSingleChoice"
								:item-text="(item) => item[$store.state.locale.toLowerCase()]"
								return-object
								dense
								:label="$t('clientForm.selectSingleChoice', $store.state.locale)"
								v-model="field.input"
								
								class="my-2"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
								:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								:required="field.required"
								@change="
									checkRelated(field.typeField, field.input.id, fieldIndex, field.relatedTo);
									addScore('choice', field, field.input);
									checkScore(field.input.scoreValue);
								"
							></v-select>
						</v-card>
						</v-col>

						<!-- \ Drop down list / -->

						<!-- / Multi Choice Field \ -->
						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'multiChoice') ||
								(field.relatedTo.length === 0 && field.typeField === 'multiChoice')
							"
							cols="12"
							class="my-2"
						>
						<v-card class="py-6 px-2 vcardForm" flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" >
							<span class="font-weight-regular">
								{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description) }}
							</span>

							<v-container fluid>
								<v-row>
									<v-col v-for="(multiChoiceField, indexMulti) in field.optionsMultiChoice" :key="multiChoiceField + indexMulti" cols="4">
										<v-checkbox
											v-model="field.input"
											:label="multiChoiceField[$store.state.locale.toLowerCase()]"
											:value="multiChoiceField"
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											:required="field.required"
											:rules="[field.input.length !== 0 || $t('clientForm.selectMultipleChoice', $store.state.locale)]"
											@change="
												checkRelated(field.typeField, field.input.id, fieldIndex, field.relatedTo);
												addScore('multiChoice', field, field.input);
												checkScore(field.input.scoreValue);
											"
										></v-checkbox>
									</v-col>
								</v-row>
							</v-container>
						</v-card>
						</v-col>

						<!-- \ Multi Choice Field / -->

						<!-- <v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'email') ||
								(field.relatedTo.length === 0 && field.typeField === 'email')
							"
							cols="12"
							class="my-2"
						>
							<v-text-field
								class="my-2"
								:label="$autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description)"
								v-model="field.input"
								outlined
								dense
								placeholder="email@adres.com"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								background-color="background"
								:rules="[(v) => !!v || $t('emailRequired', $store.state.locale), (v) => /.+@.+\..+/.test(v) || $t('emailValid', $store.state.locale)]"
								:required="field.required"
								@change="
									checkRelated(field.typeField, field.uniqId, fieldIndex, field.relatedTo);
									addScore('email', field);
									checkScore(field.scoreValue);
								"
							></v-text-field>
						</v-col> -->

						<!-- / CLIENT EMAILADDRESSES  \ -->

						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'email') ||
								(field.relatedTo.length === 0 && field.typeField === 'email')
							"
							cols="12"
							class="my-2"
						>
						
							<v-card flat :color="$themes[companyTheme][theme].card" :class="'theme-card theme-shadow mt-4 ' + companyTheme">
								<div class="pa-3 pb-0">
									<v-row class="pa-0 ma-0">
										<v-avatar
											size="36"
											light
											style="float: left"
											class="ma-0 mr-2 custom-avatar-style"
											rounded="lg"
											:color="$themes[companyTheme][theme].primary"
											:style="{ color: $themes[companyTheme][theme].primary }"
										>
											<v-icon :color="$themes[companyTheme][theme].primary">mdi-email</v-icon>
										</v-avatar>
										<h3 class="pt-1 pb-4">{{ $t("clients.emails", $store.state.locale) }}</h3>
										<v-spacer></v-spacer>
										<v-btn fab x-small depressed :color="$themes[companyTheme][theme].primary" dark @click="addEmailaddress(fieldIndex)">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</v-row>

									<v-divider></v-divider>
								</div>

								<div v-if="field.input && field.input.length > 0" class="text-center pa-1 pt-0">
									<v-avatar
										size="46"
										light
										class="ma-4 custom-avatar-style"
										rounded="lg"
										:color="$themes[companyTheme][theme].primary"
										:style="{ color: $themes[companyTheme][theme].primary }"
									>
										<v-icon :color="$themes[companyTheme][theme].primary">mdi-email-multiple-outline</v-icon>
									</v-avatar>
									<p>{{ $t("clients.noEmailsAdded", $store.state.locale) }}</p>
								</div>

								<div v-if="field.input && field.input.length > 0">
									<v-data-table
										:color="$themes[companyTheme][theme].primary"
										:headers="emailHeaders"
										:items-per-page="-1"
										:items="field.input"
										hide-default-footer
										style="background: none"
									>
										<template v-slot:item="{ item }">
											<tr>
												<td>{{ item.email }}</td>
												<td :style="'color:' + emailScoreColor(item.score) + ';'" @click="emailScoreDialog(item.score, item.error)">{{ item.score }}</td>
												<td class="text-center">
													<v-icon v-if="item.primary" :size="18" :color="$themes[companyTheme][theme].primary">mdi-star</v-icon>
													<v-btn @click="setEmailPrimary(item, fieldIndex)" icon small v-else>
														<v-icon :size="18" :color="$themes[companyTheme][theme].textSmooth">mdi-star-outline</v-icon>
													</v-btn>
												</td>
												<td>
													<v-btn icon small @click="removeEmail(item, fieldIndex)">
														<v-icon :size="18" :color="$themes[companyTheme][theme].text">mdi-delete</v-icon>
													</v-btn>
												</td>
											</tr>
										</template>
									</v-data-table>
									<v-divider></v-divider>
								</div>
							</v-card>
						</v-col>
						<!-- \ CLIENT EMAILADDRESSES  / -->

						<!--- Employee Select Without Auto Select -->
						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'employeeSelect') ||
								(field.relatedTo.length === 0 && field.typeField === 'employeeSelect')
							"
							cols="12"
							class="my-2"
						>
						<v-card class="py-6 px-2 vcardForm" flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" style="overflow:visible;"  >
							
							{{ $t("clientForm.byEmployee", $store.state.locale) }}
							<div style="position: relative">
								<v-text-field
									v-if="field.input.firstName"
									dense
									outlined
									:placeholder="field.input.firstName"
									append-icon="mdi-close"
									clear-icon="mdi-close"
									readonly
									clearable
									@click:append="field.input = ''"
									@click:clear="field.input = ''"
									background-color="white"
									:required="field.required"
									@change="
										checkRelated(field.typeField, field.uniqId, fieldIndex, field.relatedTo);
										addScore('email', field);
										checkScore(field.scoreValue);
									"
								></v-text-field>

								<v-text-field
									v-if="!field.input.firstName"
									dense
									outlined
									:placeholder="$t('clientForm.byEmployee', $store.state.locale)"
									prepend-inner-icon="mdi-magnify"
									background-color="white"
									clearable
									:required="field.required"
									:rules="[(v) => typeof v == 'object' || $t('clientForm.employeeRequired', $store.state.locale)]"
									v-model="field.input"
								></v-text-field>

							

								<div style="position: absolute; left: 0; top: 20px; width: 100%" v-if="field.input && field.input.length > 2">
									<v-card
										style="position: absolute; z-index: 9999; max-height: 260px; overflow-y: scroll; left: 0; width: 100%; top: 20px"
										tile
										class="pa-0 ma-0 custom-algolia"
									>
										<ais-instant-search :search-client="algoSearch" index-name="thrive_employees" class="pa-0 ma-0">
											<v-list style="max-height: 250px; overflow-y: scroll" class="pa-0 ma-0">
												<ais-hits class="pa-0 ma-0">
													<template slot="item" slot-scope="{ item }">
														<v-list-item class="pa-2 py-1 ma-0" @click="field.input = addEmployeeSelect(item.firstName, item.objectID)">
															<v-list-item-avatar color="#f1f1f1">
																<v-icon>mdi-account</v-icon>
															</v-list-item-avatar>

															<v-list-item-content>
																<v-list-item-title>
																	<ais-highlight attribute="firstName" :hit="item" highlightedTagName="mark" />
																	&nbsp;
																</v-list-item-title>
																<!-- <v-list-item-subtitle>
																		<ais-highlight
																		attribute="company"
																		:hit="item"
																		highlightedTagName="mark"
																		/>
																	</v-list-item-subtitle>
																	<v-list-item-subtitle>
																		<span v-if="item.phone">
																		<strong>
																			{{ item.phone }}
																		</strong>
																		</span>
																	</v-list-item-subtitle> -->
															</v-list-item-content>

															<v-list-item-action>
																<v-icon>mdi-chevron-right</v-icon>
															</v-list-item-action>
														</v-list-item>
													</template>
												</ais-hits>
											</v-list>
											<ais-state-results>
												<p class="text-center pa-2 pt-4 pb-1" slot-scope="{ state: { query }, results: { hits } }" v-show="!hits.length">
													<i>
														{{ $t("clients.noResultsFoundFor", $store.state.locale) }}
														{{ query }}
														
													</i>
												</p>
											</ais-state-results>
											<ais-configure :query="field.input" :facetFilters="[`companyId:${$store.state.activeCompany.id}`]" />
										</ais-instant-search>
									</v-card>
								</div>

								<!--  ***** \ End Client Filter  / ***** -->
							</div>
						</v-card>
						</v-col>

						<!--  ***** \ Signatuer  / ***** -->
						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'signature') ||
								(field.relatedTo.length === 0 && field.typeField === 'signature')
							"
							cols="12"
							class="my-2"
						>
						<v-card class="py-6 px-2 vcardForm" flat :class="'theme-card theme-shadow mt-4 ' + companyTheme" >
							<!-- type textfield could be: "text", "number", "text" via field.label.fieldCharacters -->
							<span class="font-weight-regular">
								{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, field.label.description) }}
							</span>
							<div class="signature">
								<vueSignature
									ref="signatureref"
									h="140px"
									w="470px"
									:options="{
										penColor: 'rgb(0, 0, 0)',
										backgroundColor: 'rgb(255, 255, 255)',

										onBegin: () => {
											console.log('begin drawing');
										},
										onEnd: () => {
											console.log('end drawing');
										},
									}"
								></vueSignature>
							</div>
							<v-btn @click="saveSignature">Opslaan</v-btn>
							<v-btn @click="clearSignature">Wissen</v-btn>
						</v-card>
						</v-col>

						<v-col
							v-if="
								(relatedTo.some((related) => field.uniqId === related.relatedId) && field.typeField === 'phone') ||
								(field.relatedTo.length === 0 && field.typeField === 'phone')
							"
						>
							<!-- / CLIENT PHONENUMBERS  \ -->


							<v-card flat :color="$themes[companyTheme][theme].card" class="theme-card theme-shadow mt-4" :class="companyTheme">
								<div class="pa-3">
									<v-row class="pa-0 ma-0">
										<v-avatar
											size="36"
											light
											style="float: left"
											class="ma-0 mr-2 custom-avatar-style"
											rounded="lg"
											:color="$themes[companyTheme][theme].primary"
											:style="{ color: $themes[companyTheme][theme].primary }"
										>
											<v-icon :color="$themes[companyTheme][theme].primary">mdi-phone</v-icon>
										</v-avatar>
										<h3 class="pt-1 pb-4">{{ $t("clients.phonenumbers", $store.state.locale) }}</h3>
										<v-spacer></v-spacer>
										<v-btn fab x-small depressed :color="$themes[companyTheme][theme].primary" dark @click="addPhonenumber(fieldIndex)">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</v-row>

									<v-divider></v-divider>
								</div>

								<div v-if="!field.input || field.input.length == 0" class="text-center pa-1 pt-0">
									<v-avatar
										size="46"
										light
										class="ma-4 custom-avatar-style"
										rounded="lg"
										:color="$themes[companyTheme][theme].primary"
										:style="{ color: $themes[companyTheme][theme].primary }"
									>
										<v-icon :color="$themes[companyTheme][theme].primary">mdi-phone-ring</v-icon>
									</v-avatar>
									<p>{{ $t("clients.noPhonenumbersAdded", $store.state.locale) }}</p>
								</div>

								<div v-if="field.input && field.input.length > 0">
									<v-data-table
										:color="$themes[companyTheme][theme].primary"
										:headers="phoneHeaders"
										:items-per-page="-1"
										:items="field.input"
										hide-default-footer
										style="background: none"
									>
										<template v-slot:item="{ item }">
											<tr>
												<td class="text-center">
													<v-icon :size="18" v-if="item.type == 'mobile'" :color="$themes[companyTheme][theme].text">mdi-cellphone</v-icon>
													<v-icon :size="18" v-else :color="$themes[companyTheme][theme].text">mdi-deskphone</v-icon>
												</td>
												<td v-if="item.region == 'NL'">{{ item.phone.national }}</td>
												<td v-else>{{ item.phone.international }}</td>
												<td class="text-center">
													<v-icon v-if="item.primary" :size="18" :color="$themes[companyTheme][theme].primary">mdi-star</v-icon>
													<v-btn @click="setPhonePrimary(item, fieldIndex)" icon small v-else>
														<v-icon :size="18" :color="$themes[companyTheme][theme].textSmooth">mdi-star-outline</v-icon>
													</v-btn>
												</td>
												<td>
													<v-btn icon small @click="removePhone(item, fieldIndex)">
														<v-icon :size="18" :color="$themes[companyTheme][theme].text">mdi-delete</v-icon>
													</v-btn>
												</td>
											</tr>
										</template>
									</v-data-table>
									<v-divider></v-divider>
								</div>
							</v-card>
							<!-- \ CLIENT PHONENUMBERS  / -->
						</v-col>

						<!--  ***** \ End Signatuer  / ***** -->
					</v-row>
				</v-form>
			</v-container>
		</v-card-text>
		<!-- / New Phonenumber Dialog \ -->
		<add-phonenumber-dialog :type="'clientForm'" @newPhoneNumber="addNewPhonenumber($event)"></add-phonenumber-dialog>
		<!-- \ New Phonenumber Dialog / -->

		<!-- / New Phonenumber Dialog \ -->
		<add-emailaddress-dialog :type="'clientForm'" @newEmailaddress="addNewEmailaddress($event)"></add-emailaddress-dialog>
		<!-- \ New Phonenumber Dialog / -->

		<!-- / score Dialog \ -->
		<v-dialog v-model="dialogScore" max-width="350">
			<v-card>
				<v-card-title class="text-h5">Email score information</v-card-title>

				<v-card-text v-if="scoreInfo.error == null">
					Email score is: {{ scoreInfo.score }}
					Everything looks fine!
				</v-card-text>
				<v-card-text v-else>
					Email score is: {{ scoreInfo.score }}
					That is not good!
					<div class="codeBlockError">
						{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, scoreInfo.error) }}
					</div>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="primary" text @click="dialogScore = false" :color="$themes[companyTheme][theme].primary">Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ score Dialog / -->
	</v-card>
</div>
</template>

<style scoped>
.signature {
	display: flex;
	border: 1px solid rgb(232 231 234);
	border-radius: 5px;
	padding: 16px 0 16px 0px;
	margin: 16px 0 16px 0px;
}
.codeBlockError {
	font-family: "Lucida Console", "Consolas", "Monaco", "Menlo", sans-serif;
	background-color: #f2f2f2;
	padding: 4px 4px 4px 16px;
	margin: 4px 0 4px 0;
}


</style>
