<template v-slot:activator="{ on }">
  <div>
    <div v-if="dataloaded && $store.state.activeUserCompanies.length > 1" class="pb-2">
      <v-container grid-list-md class="pa-0 py-2" fluid>
        <v-layout row wrap>
          <companySelector :showBranches="false" @companyChanged="loadData"></companySelector>
          <v-flex md6 xs12 >
            <period-select :dataType="'leadDashboard'" :typeRounded="true" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select> 
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div v-if="!dataloaded" class="thrive-loader"></div>

    <div v-if="dataloaded">
      <v-container class="my-4 pa-0" grid-list-xl fluid>
          <v-layout row wrap class=" pa-0">

            <v-flex md4 xs6 d-flex>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card theme-card" outlined :class="$store.state.companyTheme">
                <div class="lead-data-card-label">{{ $t(`leadCampaign.newLeads`, this.$store.state.locale) }}</div>
                <div class="lead-data-card-wrapper">
                  <div class="lead-data-card-col-one">
                    <div class="lead-data-card-number">{{ leadData.newLeads.number }}</div>
                    <v-chip small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 my-1 custom-chip-style">In deze periode</v-chip>
                  </div>
                  <div class="lead-data-card-col-two">
                    <apexchart width="100%" height="100" type="line" :options="barometerChartOptions['primary']"  :series="leadData.newLeads.chartData"></apexchart>
                  </div>
                </div>
                
              </v-card>
            </v-flex>

            <v-flex md4 xs6 d-flex>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card theme-card" outlined :class="$store.state.companyTheme">
                <div class="lead-data-card-label">{{ $t(`leadCampaign.scheduled`, this.$store.state.locale) }}</div>
                <div class="lead-data-card-number">{{ leadData.scheduled }}</div>
                <div class="lead-data-card-extra">{{ $t(`leadCampaign.duringThisPeriod`, this.$store.state.locale) }}</div>
              </v-card>
            </v-flex>

            <v-flex md4 xs6 d-flex>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card theme-card" outlined :class="$store.state.companyTheme">
                <div class="lead-data-card-label">{{ $t(`leadCampaign.success`, this.$store.state.locale) }}</div>
                <div class="lead-data-card-number">
                  {{ leadData.success }}
                  <span v-if="leadData.notAssigned > 0" class="lead-data-card-percentage">{{ calculatePercentage(leadData.success, leadData.newLeads.number) }}%</span>
                </div>
                <div class="lead-data-card-extra">{{ $t(`leadCampaign.duringThisPeriod`, this.$store.state.locale) }}</div>
              </v-card>
            </v-flex>

            <v-flex md6 xs12 d-flex>
              <v-container class="ma-0 pa-0" grid-list-xl fluid>
                <v-layout row wrap class=" pa-0">
                  <v-flex md4 xs12 d-flex>
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card theme-card" outlined :class="$store.state.companyTheme">
                      <div class="lead-data-card-label">{{ $t(`leadCampaign.notAssigned`, this.$store.state.locale) }}</div>
                      <div class="lead-data-card-number">
                        {{ leadData.notAssigned }} 
                        <span v-if="leadData.notAssigned > 0" class="lead-data-card-percentage">{{ calculatePercentage(leadData.notAssigned, leadData.newLeads.number) }}%</span>
                      </div>
                      <v-chip small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" light class="mr-2 my-1 custom-chip-style">In deze periode</v-chip>
                    </v-card>
                  </v-flex>

                  <v-flex md4 xs12 d-flex>
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card theme-card" outlined :class="$store.state.companyTheme">
                      <div class="lead-data-card-label">{{ $t(`leadCampaign.notScheduled`, this.$store.state.locale) }}</div>
                      <div class="lead-data-card-number">
                        {{ leadData.notScheduled }}
                        <span v-if="leadData.notAssigned > 0" class="lead-data-card-percentage">{{ calculatePercentage(leadData.notScheduled, leadData.newLeads.number) }}%</span>
                      </div>
                      <v-chip small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 my-1 custom-chip-style">In deze periode</v-chip>
                    </v-card>
                  </v-flex>

                  <v-flex md4 xs12 d-flex>
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card theme-card" outlined :class="$store.state.companyTheme">
                      <div class="lead-data-card-label">{{ $t(`leadCampaign.notPursued`, this.$store.state.locale) }}</div>
                      <div class="lead-data-card-number">{{ leadData.notPursued }}</div>
                      <div class="lead-data-card-extra">{{ $t(`leadCampaign.duringThisPeriod`, this.$store.state.locale) }}</div>
                    </v-card>
                  </v-flex>

                  <v-flex md4 xs12 d-flex v-if="leadData.averageLeadAssignTime">
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card theme-card" outlined :class="$store.state.companyTheme">
                      <div class="lead-data-card-label">{{ $t(`leadCampaign.averageLeadAssignTime`, this.$store.state.locale) }}</div>
                      <div >
                        <v-icon 
                          class="my-3 m-2"
                          size="50"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        >mdi-clock</v-icon>
                      </div>
                      <div class="lead-data-card-number small"> 
                        {{ minutesToHourFormat(leadData.averageLeadAssignTime, 1) }}
                      </div>
                    </v-card>
                  </v-flex>

                  <v-flex md4 xs12 d-flex v-if="leadData.averageLeadScheduleTime">
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card theme-card" outlined :class="$store.state.companyTheme">
                      <div class="lead-data-card-label">{{ $t(`leadCampaign.averageLeadAssignTime`, this.$store.state.locale) }}</div>
                      <div >
                        <v-icon 
                          class="my-3 m-2"
                          size="50"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary"
                        >mdi-clock</v-icon>
                      </div>  
                      <div class="lead-data-card-number small">
                        {{ minutesToHourFormat(leadData.averageLeadScheduleTime, 1) }}
                      </div>
                    </v-card>
                  </v-flex>

                  <v-flex md4 xs12 d-flex>
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card theme-card" outlined :class="$store.state.companyTheme">
                      <div class="lead-data-card-label">{{ $t(`leadCampaign.noSuccess`, this.$store.state.locale) }}</div>
                      <div class="lead-data-card-number">{{ leadData.noSuccess }}</div>
                      <div class="lead-data-card-extra">{{ $t(`leadCampaign.duringThisPeriod`, this.$store.state.locale) }}</div>
                    </v-card>
                  </v-flex>

                  <v-flex xs12 d-flex v-if="leadData.sources && leadData.sources.length > 0">
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card pa-0 theme-card" outlined :class="$store.state.companyTheme">
                      <h3 class="pa-4">{{ $t(`leadCampaign.leadSources`, this.$store.state.locale) }}</h3>
                      <v-data-table
                        :headers="headerSources"
                        :items="leadData.sources"
                        dense
                        :items-per-page="-1"
                        class="dense-table"
                        sort-by="number"
                        :sort-desc="true"
                        hide-default-footer
                        hide-default-header
                        > 
                        <template v-slot:item="{item}">
                          <tr>
                            <td>
                              <v-icon size="28" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >{{ getTagInfo(item.id).icon }}</v-icon>
                            </td>
                            <td>
                              <div class="py-2">
                                <span style="font-size: 15px!important"><strong>{{ getTagInfo(item.id).name }}</strong></span><br>
                                <span style="font-size: 13px!important">{{ getTagInfo(item.id).description }}</span>
                              </div>
                            </td>
                            <td>{{ item.number }}</td>
                          </tr>  
                        </template>
                      </v-data-table>
                      <v-divider class="mb-2"></v-divider>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>

            <v-flex md6 xs12 d-flex v-if="leadData.customSources && leadData.customSources.length > 0">
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="lead-data-card pa-0 theme-card" outlined :class="$store.state.companyTheme">
                <h3 class="pa-4">{{ $t(`leadCampaign.customSources`, this.$store.state.locale) }}</h3>
                <v-data-table
                  :headers="headerSources"
                  :items="leadData.customSources"
                  dense
                  :items-per-page="-1"
                  class="dense-table"
                  sort-by="number"
                  :sort-desc="true"
                  hide-default-footer
                  hide-default-header
                  > 
                  <template v-slot:item="{item}">
                    <tr>
                      <td>
                        <div class="pa-2">
                          <span style="font-size: 15px!important"><strong>{{ getTagSourceInfo(item.id).name }}</strong></span><br>
                          <span style="font-size: 13px!important">{{ getTagSourceInfo(item.id).description }}</span>
                        </div>
                      </td>
                      <td>{{ item.number }}</td>
                    </tr>  
                  </template>
                </v-data-table>
                <v-divider class="mb-2"></v-divider>
              </v-card>
            </v-flex>

            

          </v-layout>
        </v-container>



      <!-- <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" v-if="campaigns.length >0">
        <v-container grid-list-md class=" ma-0 pa-3 py-5" fluid>
            <v-layout row wrap>
              <v-flex md4 xs12>
                <v-text-field
                  style="max-width: 300px;"
                  v-model="search"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  placeholder="Zoeken"
                  outlined
                  dense
                  hide-details     
                  background-color="background"                                         
                  prepend-inner-icon="mdi-magnify"
                  autocomplete="disabled"
                  clearable>
                </v-text-field>
              </v-flex>
              <v-flex md8 xs12 class="text-right">
                <v-btn fab dark small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" v-if="selected.length > 0" @click="deleteCampaigns()">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn class="mx-2" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addCampaign()">
                  <v-icon left>mdi-plus</v-icon> Nieuwe campagne
                </v-btn>
                
              </v-flex>
            </v-layout>
          </v-container>

          <v-divider> </v-divider>

        <v-data-table
          :headers="headers"
          :items="campaigns"
          :items-per-page="-1"
          show-select
          v-model="selected"
          :search="search"
          hide-default-footer
          item-key="name"
          no-data-text="Geen campaigns."
          >
          <template v-slot:item="{ item, isSelected, select}">
            <tr>
              <td>
                <v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
                <v-icon @click="select(false)" v-if="isSelected" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
              </td>
              <td>{{ item.name }}</td>
              <td>{{ getTreatmentType(item.type) }}</td>
              <td class="text-left">
                <v-btn icon @click="campaignLink(item)">
                  <v-icon small color="text">mdi-link</v-icon>
                </v-btn>
              </td>
              <td class="text-right">
                <v-btn icon @click="editCampaign(item)">
                  <v-icon small color="text">mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <div class="no-data-message" v-else>
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-filter</v-icon>
        </v-avatar>
        <p>{{ $t('leadCampaign.noCampaigns', $store.state.locale) }}</p>
        <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addCampaign()">
          <v-icon left>mdi-plus</v-icon> {{ $t('leadCampaign.newLeadCampaign', $store.state.locale) }} 
        </v-btn>
      </div>-->
    </div> 

  </div>
</template>

<script>
import _ from "lodash";
import db from "../firebase/init";
import moment from "moment";
import { bus } from "../main";
import TreatmentTypesVue from './TreatmentTypes.vue';
import companySelector from "@/components/elements/companySelector.vue";
import periodSelector from "../components/elements/periodSelector.vue";
 
export default {
  name: "LeadDashboard",
  components: {
    companySelector:companySelector,
    "period-select": periodSelector,
  },

  data: function() {
    return {
      dataloaded: false,
      leads: new Array(),
      dashboardDataFilter:{
        type: "week",
        year: moment().year(),
        number: moment().isoWeek(),
        loaded: false,
        start: null,
        end: null
      },
      leadData:new Object(), 
      barometerChartOptions: new Object(),
      headerSources: [
        {
          text: "icon",
          align: "left",
          value: "icon",
          width: "40px"
        },
        {
          text: "name",
          align: "left",
          value: "icon"
        },
        {
          text: "",
          align: "left",
          value: "number",
          width: "120px"
        }
      ]
    };
  },

  created() {
    this.loadData();
  },

  methods: {
    async loadData(){
      await Promise.all([this.setupBarometerChartOptions(), this.resetDashboard(), this.calculatePeriod(), this.getLeads()])
      this.dataloaded = true;
    },

    getTagInfo(id){
      let data = {
        icon: "",
        name: "",
        description: "",
        color: this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].text
      };
      let tagData = this.$leadTags.find((tag)=> tag.query == id)
      console.log("Leadtags: ", this.$leadTags)
      

      if(tagData){
        data.icon = tagData.icon;
        data.color = tagData.bgColor.hex;
        data.name = this.$t(`leadCampaign.tags.${tagData.query}`, this.$store.state.locale);
        data.description = this.$t(`leadCampaign.tags.${tagData.query}Description`, this.$store.state.locale);
      }
      else{
        data.icon = "mdi-help-box-outline";
        data.name = id;
      }
      console.log("id: ", id)
      console.log("Tag data: ", tagData)
      return data;
    },

    getTagSourceInfo(id){
      let data = {
        name: "",
        description: "",
        color: this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].text
      };
      let tagData = this.$leadSources.find((tag)=> tag.id == id);

      if(tagData){
        data.color = tagData.color;
        data.name = this.$t(`leadCampaign.sources.${tagData.id}`, this.$store.state.locale);
        data.description = this.$t(`leadCampaign.sources.${tagData.id}Description`, this.$store.state.locale);
      }
      else{
        data.name = id;
      }
      return data;
    },

    resetDashboard(){
      this.leadData = {
        newLeads: { 
          number:0,
          chartData:[
            {
              data: new Array(),
              name: "lead"
            }
          ]
        },
        notAssigned: 0,
        notPursued: 0,
        notScheduled: 0,
        scheduled: 0,
        success:0,
        noSuccess: 0,
        sources: new Array(),
        customSources: new Array(),
        averageLeadAssignTime: null,
        averageLeadScheduleTime: null,
      };
      return
    },

    async setupBarometerChartOptions(){
      let chartOptions = {   
        chart: {
          width: '100%',
          offsetX: 0,
          offsetY: 0,
          sparkline: {
              enabled: true,
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
        },
        plotOptions: {
          radialBar: {
            inverseOrder: false,
            startAngle: -90,
            endAngle: 90,
            offsetX: 0,
            offsetY: 0,            
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '18px'
              }
            }
          },
        },
        stroke: { curve: 'smooth', lineCap: 'round'},
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: true,
          strokeDashArray: 6,
          position: 'back',
          xaxis: {
            lines: {
                show: false
            }
          },   
          xaxis: {
            lines: {
                show: true
            }
          }, 
          yaxis: {
            lines: {
                show: false
            }
          },
          markers: { size: 1},
          padding: {
            top: 7,
            right: 0,
            bottom: 5,
            left: 0
          },  
        },
        colors: new Array(),   
        xaxis: {
          labels: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
            offsetX: 0,
            offsetY: 0
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
        },
      };
      this.barometerChartOptions = new Object();
      this.barometerChartOptions.primary = _.cloneDeep(chartOptions);
      this.barometerChartOptions.secondary = _.cloneDeep(chartOptions);
      this.barometerChartOptions.tertiary = _.cloneDeep(chartOptions);
      this.barometerChartOptions.quaternary = _.cloneDeep(chartOptions);
      this.barometerChartOptions.primary.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary);
      this.barometerChartOptions.secondary.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary);
      this.barometerChartOptions.tertiary.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].tertiary);
      this.barometerChartOptions.quaternary.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].quaternary);
      return
    },

    async updateDataPeriodRefresh(filter){
      this.dataloaded = false;
      this.dashboardDataFilter.type = filter.type;
      this.dashboardDataFilter.year = filter.year;
      this.dashboardDataFilter.number = filter.number;
      await this.calculatePeriod();
      await this.getLeads();
      this.dataloaded = true;
    },

    setFilterData(){
      bus.$emit("setFilterData", this.dashboardDataFilter);
    },  

    calculatePercentage(a, b){
      return ((a/b)*100).toFixed(0)
    },

    async calculatePeriod(){
      if(this.dashboardDataFilter.type == "week"){
        this.dashboardDataFilter.start = moment().isoWeek(this.dashboardDataFilter.number).year(this.dashboardDataFilter.year).day(1).format("YYYY-MM-DD");
        this.dashboardDataFilter.end = moment().isoWeek(this.dashboardDataFilter.number).year(this.dashboardDataFilter.year).day(7).format("YYYY-MM-DD");
      }
      if(this.dashboardDataFilter.type == "fourWeeks"){
        let endWeek = this.dashboardDataFilter.number * 4;
        let startWeek =  endWeek - 3
        this.dashboardDataFilter.start = moment().isoWeek(startWeek).year(this.dashboardDataFilter.year).day(1).format("YYYY-MM-DD");
        this.dashboardDataFilter.end = moment().isoWeek(endWeek).year(this.dashboardDataFilter.year).day(7).format("YYYY-MM-DD");
      }
      if(this.dashboardDataFilter.type == "month"){
        this.dashboardDataFilter.start = moment(this.dashboardDataFilter.number, "M").year(this.dashboardDataFilter.year).startOf('month').format("YYYY-MM-DD");
        this.dashboardDataFilter.end =  moment(this.dashboardDataFilter.number, "M").year(this.dashboardDataFilter.year).endOf('month').format("YYYY-MM-DD");
      }
      if(this.dashboardDataFilter.type == "quarter"){
        let endMonth = this.dashboardDataFilter.number * 3;
        let startMonth =  endMonth - 2
        this.dashboardDataFilter.start = moment(startMonth, "M").year(this.dashboardDataFilter.year).startOf('month').format("YYYY-MM-DD");
        this.dashboardDataFilter.end = moment(endMonth, "M").year(this.dashboardDataFilter.year).endOf('month').format("YYYY-MM-DD");
      }
      if(this.dashboardDataFilter.type == "year"){
        this.dashboardDataFilter.start = moment().year(this.dashboardDataFilter.year).startOf('year').format("YYYY-MM-DD");
        this.dashboardDataFilter.end = moment().year(this.dashboardDataFilter.year).endOf('year').format("YYYY-MM-DD");
      }
      return
    },
    

    async getLeads(){  
      this.leads = new Array();
      await db.collection("leads")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("updated", ">=", moment(this.dashboardDataFilter.start).toDate())
      .where("updated", "<=", moment(this.dashboardDataFilter.end).toDate())
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let lead = doc.data();
          lead.id = doc.id;
          this.leads.push(lead);
        });
      })
      .catch(error=>{
        console.error("Error bij laden leads: ", error);
      })

      await this.resetDashboard();
      await this.sortLeadData();
      return
    },

    async sortLeadData(){
      // Create Array for data period to use in graphs and charts
      let start = moment(this.dashboardDataFilter.start);
      let end = moment(this.dashboardDataFilter.end);
      let daysSelected = end.diff(start, 'day') + 1;
      let weeksSelected = end.diff(start, 'week') + 1;
      let monthsSelected = end.diff(start, 'month') + 1;
      let type = daysSelected < 60 ? "day" : daysSelected > 182 ? "month" : 'week';
      let number = daysSelected < 60 ? daysSelected : daysSelected > 182 ? monthsSelected : weeksSelected;

      if(type == 'week'){
        if( moment(this.dashboardDataFilter.start).add(number - 1, 'weeks').day(7).format("YYYY-MM-DD")< this.dashboardDataFilter.end){
          number++
        }
      }
      let data = [...Array(number).fill(0).map((x, i) => (
        { 
          type: type,
          number: i + 1, 
          startDate: (type != 'days' && i == 0) ? moment(start).format("YYYY-MM-DD") : moment(start).add(i, type).startOf(type).format("YYYY-MM-DD"),
          endDate: (type != 'days' && i == number - 1 && moment(start).add(i, type).endOf(type).format("YYYY-MM-DD") > this.dashboardDataFilter.end) ? this.dashboardDataFilter.end : moment(start).add(i, type).endOf(type).format("YYYY-MM-DD"),
          newLeads: 0,
          plannedLeads: 0,
          succesLead: 0
        }
      ))];

      data.forEach(period=>{
        this.leads.forEach(lead=>{
          if(moment(lead.created.seconds, "X").format("YYYY-MM-DD") >= period.startDate && moment(lead.created.seconds, "X").format("YYYY-MM-DD") <= period.endDate){ // New lead in this period found
            this.leadData.newLeads.number++
            period.newLeads++
            if(lead.actions.length == 0){ // No actions means not assigned
              this.leadData.notAssigned++
            } 
          }
        })
        this.leadData.newLeads.chartData[0].data.push(period.newLeads);
      })

      let leadAssignTimes = new Array();
      let leadScheduleTimes = new Array();

      this.leads.forEach(lead=>{
        let schedules = new Array();
        let newLead = false;
        let leadPursued = false;
        let leadSuccess= false;
        let leadScheduled = false;
        let leadNoSuccess = false;
        if(moment(lead.created.seconds, "X").format("YYYY-MM-DD") >= this.dashboardDataFilter.start && moment(lead.created.seconds, "X").format("YYYY-MM-DD") <= this.dashboardDataFilter.end){ // New lead in this period found
          newLead = true;
        }
        if(lead.actions.length > 0){
          lead.actions.forEach(action=>{
            if(moment(action.timestamp, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DD") >= this.dashboardDataFilter.start && moment(action.timestamp, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DD") <= this.dashboardDataFilter.end){ // Action in selected period 

              if(action.id == 'leadAssigned'){
                let start = moment(lead.created.seconds, "X");
                let end = moment(action.timestamp, "YYYY-MM-DDTHH:mm");
                console.log("gemaakt", moment(lead.created.seconds, "X").format("YYYY-MM-DD"))
                console.log("action", moment(action.timestamp, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DD"))
                let minutesBetween = end.diff(start, 'minutes');
                leadAssignTimes.push(minutesBetween)
              }
              if(action.id == 'schedulePhoneCall' || action.id == 'scheduleFollowUpCall' || action.id == 'scheduleFollowUpAppointment'){
                leadScheduled = true;
                schedules.push(action)
              }
              if(action.id == 'directPhoneCall' || action.id == 'rescheduleAppointment' || action.id == 'phoneCallNoSuccess' ){
                console.log("leadPursued", action.id, lead.id)
                leadPursued = true
              }
              if(action.id == 'leadBecomesCustomer' ){
                leadSuccess = true
              }

              if(action.id == 'notReachableStop' || action.id == 'leadBecomesNoCustomer' || action.id == 'notInterested' || action.id == 'addReferral'){
                leadNoSuccess = true
              }
            }
          })
        }
        if(schedules && schedules.length > 0){
          schedules = schedules.sort((a, b) => a.timestamp - b.timestamp);
          let startTime = moment(lead.created.seconds, "X");
          let endTime = moment(schedules[0].timestamp, "YYYY-MM-DDTHH:mm");
          let minutesBetween = endTime.diff(startTime, 'minutes');
          leadScheduleTimes.push(minutesBetween)
        }
        if(leadNoSuccess){
          this.leadData.noSuccess++
        }
        if(newLead && !leadScheduled){
          this.leadData.notScheduled++
          this.leadData.notPursued++
        }
        else{
          if(!leadPursued){
            this.leadData.notPursued++
          }
          if(leadScheduled){
            this.leadData.scheduled++
          }
          if(leadSuccess){
            this.leadData.success++
          }
        }
      })
      this.leadData.averageLeadAssignTime = null;
      this.leadData.averageLeadScheduleTime = null;
      if(leadAssignTimes && leadAssignTimes.length > 0){
        this.leadData.averageLeadAssignTime = leadAssignTimes.reduce((accumulator, currentValue) => {return accumulator + currentValue},0) / leadAssignTimes.length;
      }
      if(leadScheduleTimes && leadScheduleTimes.length > 0){
        this.leadData.averageLeadScheduleTime = leadScheduleTimes.reduce((accumulator, currentValue) => {return accumulator + currentValue},0) / leadScheduleTimes.length;
      }
      
      this.leadData.sources = this.mergeDuplicates(this.leads, 'source');
      this.leadData.customSources = this.mergeDuplicates(this.leads, 'customSource');
      return
    },

    mergeDuplicates(data, key) {
      let unique = new Object()
      data.forEach(item=>{
        if(unique[item[key]]){
          unique[item[key]].number++
        }
        else{
          unique[item[key]] = { number: 1}
        }
      })
      return Object.entries(unique).map((e) => ( { 'id': e[0], 'number': e[1].number } ));;
    },

    minutesToHourFormat(minutes, rounding){
      let roundingNumber = rounding ? rounding : 1;
      let hours = Math.ceil(minutes/60);
      let days = Math.floor(hours/24);
      let hoursLeft = (((hours - (days * 24)).toFixed(0))/roundingNumber).toFixed(0)*roundingNumber

      let hoursResult = hoursLeft > 9 ? `${this.$t('general.hours', this.$store.state.locale)}` : hoursLeft == 1  ?  `${this.$t('general.hour', this.$store.state.locale)} ` : `${this.$t('general.hours', this.$store.state.locale)} `;
      let daysResult = days == 0 ? `` : days == 1  ?  `${days} ${this.$t('general.day', this.$store.state.locale)} ${this.$t('general.and', this.$store.state.locale)} ` : `${days} ${this.$t('general.days', this.$store.state.locale)} ${this.$t('general.and', this.$store.state.locale)} `;


      return `${daysResult} ${hoursLeft} ${hoursResult}`
    },
    

    async getTreatments(){
      await db.collection("treatments")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        this.treatments = new Array();
        snap.forEach(doc => {
          let treatment = doc.data();
          treatment.id = doc.id;
          this.treatments.push(treatment);
        });
      })
      .catch(error => {
        console.error("Error getting treatments for campaigns: ", error);
      });
      return
    },


  },

  computed: {
    
  }
};
</script>

<style scoped>
  .lead-data-card{
    width: 100%; 
    padding: 20px 10px 10px 10px;           
    border-color: var(--separator-color)!important; 
  }

  .lead-data-card-label{
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
  }
  .lead-data-card-number{
    font-size: 50px;
  }
  .lead-data-card-number.small{
    font-size: 16px;
  }
  .lead-data-card-extra{
  font-size: 12px;
  color: var(--text-color);
  opacity: 0.5;
  }

  .lead-data-card-wrapper:after{
    clear: both;
    display: block;
    content: "";
  }
  .lead-data-card-col-one,
  .lead-data-card-col-two{
    width: 50%;
    float: left;
  }

  .lead-data-card-percentage{
    color: var(--text-color)!important; 
    display: inline-block;
    vertical-align:middle;
    height: 100%;
    opacity: 0.5;
    line-height: 1;
  }
</style>


