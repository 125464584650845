
<template>
  <div>
    <div v-if="!dataloaded " class="thrive-loader" :class="$store.state.companyTheme"></div>

    <div v-if="dataloaded">
      <v-container grid-list-md class="pa-0 pb-4" fluid>
        <v-layout row wrap>
          <companySelector :showBranches="false" @companyChanged="loadData"></companySelector>
        </v-layout>
      </v-container>

      <v-tabs v-if="branchesLoaded"
        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        show-arrows
        v-model="tab"
        class="mb-4"
        style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }"
        >
        <v-tab><v-icon left>mdi-chart-bar</v-icon>{{ $t('messages.overview', $store.state.locale) }}</v-tab>
        <v-tab><v-icon left>mdi-email-fast</v-icon>{{ $t('messages.autoDelivery', $store.state.locale) }}</v-tab>
        <v-tab><v-icon left>mdi-star-box-multiple-outline</v-icon>{{ $t('messages.templates', $store.state.locale) }}</v-tab>
        <v-tab><v-icon left>mdi-calendar</v-icon>{{ $t('messages.campagnes', $store.state.locale) }}</v-tab>
      </v-tabs>
  
      <v-tabs-items v-model="tab"  v-if="branchesLoaded" :key="messagesChanges" style="background: none!important;">
        <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
          <!-- / Messages History \ -->
          <messages-history></messages-history>
          <!-- \ Messages History  / -->
        </v-tab-item>
        <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition" >
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" >
            <div v-if="$store.state.activeUserBranches.length > 0">
              <v-container grid-list-md class="ma-0 pa-0" >
                <v-layout class="ma-0 pa-3">
                  <v-flex md3 xs12>
                    <v-select
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :items="$store.state.activeUserBranches"
                      item-text="name"
                      item-value="id"
                      :value="$store.state.activeBranch.id"
                      v-on:input="changeActiveBranchData"
                      :label="$t('general.branch', $store.state.locale)"
                      outlined
                      hide-details
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex md9 xs12>
                    <div class="text-right px-4 pt-2" v-if="savingBranchData" :style="{'color' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>mdi-content-save</v-icon> Saving...
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-divider></v-divider>
            </div>

            <v-container grid-list-md class="ma-0 pa-0">
              <v-layout class="mx-4 my-2 pa-0" d-flex> <!-- Afspraken -->
                <v-flex xs4 d-flex>
                  <h3>{{ $t('emails.appointmentMessages', $store.state.locale) }}</h3>
                </v-flex>
              </v-layout>
              <v-divider class="ma-0"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Afspraak bevestiging -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" style="min-height: 0px" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-check</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.appointmentMessages', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.appointmentConfirmationHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :input-value="true"
                    :label="$t('general.email', $store.state.locale)"
                    readonly
                    disabled
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    @change="changeMessageSettings()"
                    :items="confirmEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentConfirmEmail"
                  ></v-select>
                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="activeBranchMessages.appointmentConfirmTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    :disabled="savingBranchData"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>

                  <v-select
                    @change="changeMessageSettings()"
                    class="mt-2"
                    :disabled="!activeBranchMessages.appointmentConfirmTextUse || savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="confirmTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentConfirmText"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-divider class="ma-4"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Afspraak bevestiging met aanbetaling -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" style="min-height: 0px" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-cash</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.appointmentDownpayment', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.appointmentDownpaymentHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :input-value="true"
                    :label="$t('general.email', $store.state.locale)"
                    readonly
                    disabled
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="confirmDownPaymentEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentConfirmDownPaymentEmail"
                  ></v-select>

                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="activeBranchMessages.appointmentConfirmDownPaymentTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    hide-details
                    :disabled="savingBranchData"
                    @change="changeMessageSettings()"
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="!activeBranchMessages.appointmentConfirmDownPaymentTextUse"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="confirmDownPaymentTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentConfirmDownPaymentText"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-divider class="ma-4"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Afspraak herinnering -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0" style="min-height: 0px">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-clock</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.appointmentReminder', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.appointmentReminderHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-0 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :disabled="savingBranchData" 
                    v-model="activeBranchMessages.appointmentReminderEmailUse"
                    :label="$t('general.email', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="!activeBranchMessages.appointmentReminderEmailUse || savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="reminderEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentReminderEmail"
                  ></v-select>
                  <v-select
                    dense
                    class="mt-2"
                    :disabled="!activeBranchMessages.appointmentReminderEmailUse || savingBranchData"
                    item-value="number"
                    :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                    :items="reminderDays"
                    @change="changeMessageSettings()"
                    v-model="activeBranchMessages.appointmentReminderEmailTime"
                    :label="$t('emails.sendTimeBefore', $store.state.locale)"
                    outlined
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  ></v-select>

                </v-flex>
                <v-flex xs4 class="px-4 pt-0 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="activeBranchMessages.appointmentReminderTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    :disabled="savingBranchData"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="!activeBranchMessages.appointmentReminderTextUse"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="reminderTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentReminderText"
                  ></v-select>
                  <v-select
                    dense
                    :disabled="!activeBranchMessages.appointmentReminderTextUse"
                    class="mt-2"
                    item-value="number"
                    :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                    :items="reminderHours"
                    v-model="activeBranchMessages.appointmentReminderTextTime"
                    :label="$t('emails.sendTimeBefore', $store.state.locale)"
                    outlined
                    @change="changeMessageSettings()"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  ></v-select>
                </v-flex>

              </v-layout>

              <v-divider class="ma-4"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Aanbetaling herinnering -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0" style="min-height: 0px">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-clock</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.downPaymentReminder', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.downPaymentReminderHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-0 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :disabled="savingBranchData" 
                    v-model="activeBranchMessages.downPaymentReminderEmailUse"
                    :label="$t('general.email', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="!activeBranchMessages.downPaymentReminderEmailUse || savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="downPaymentReminderEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.downPaymentReminderEmail"
                  ></v-select>
                </v-flex>
                <v-flex xs4 class="px-4 pt-0 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="activeBranchMessages.downPaymentReminderTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    :disabled="savingBranchData"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="!activeBranchMessages.downPaymentReminderTextUse"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="downPaymentReminderTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.downPaymentReminderText"
                  ></v-select>
                </v-flex>

              </v-layout>

              <v-divider class="ma-4"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Afspraak wijziging -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0" style="min-height: 0px">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-expand-horizontal</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.appointmentChanges', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.appointmentChangesHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :input-value="true"
                    :label="$t('general.email', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                    disabled
                    read-only
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="savingBranchData"
                    @change="changeMessageSettings()"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="changesEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentChangesEmail"
                  ></v-select>

                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="activeBranchMessages.appointmentChangesTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="!activeBranchMessages.appointmentChangesTextUse || savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="changesTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentChangesText"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-divider class="ma-4"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Afspraak annuleren -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0" style="min-height: 0px">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-remove</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.appointmentCancel', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.appointmentCancelHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :input-value="true"
                    :label="$t('general.email', $store.state.locale)"
                    hide-details
                    disabled
                    read-only
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="savingBranchData"
                    @change="changeMessageSettings()"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="cancelEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentCancelEmail"
                  ></v-select>

                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="activeBranchMessages.appointmentCancelTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="!activeBranchMessages.appointmentCancelTextUse || savingBranchData"
                    @change="changeMessageSettings()"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="cancelTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentCancelText"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-divider class="ma-4"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Afspraak annuleren (geen aanbetaling ontvangen) -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0" style="min-height: 0px">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-remove</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.appointmentCancelNoDownPayment', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.appointmentCancelNoDownPaymentHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :input-value="true"
                    :label="$t('general.email', $store.state.locale)"
                    hide-details
                    disabled
                    read-only
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="savingBranchData"
                    @change="changeMessageSettings()"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="appointmentCancelNoDownPaymentEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentCancelNoDownPaymentEmail"
                  ></v-select>

                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="activeBranchMessages.appointmentCancelTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="!activeBranchMessages.appointmentCancelNoDownPaymentTextUse || savingBranchData"
                    @change="changeMessageSettings()"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    style="width: 100%"
                    :items="appointmentCancelNoDownPaymentTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.appointmentCancelNoDownPaymentText"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-divider class="ma-4 mx-0"></v-divider>
            </v-container>  

            <v-container grid-list-md class="ma-0 pa-0">
              <v-layout class="mx-4 my-2 pa-0" d-flex> <!-- Events & Groepslessen -->
                <v-flex xs4 d-flex>
                  <h3>{{ $t('emails.eventsAndGrouplessons', $store.state.locale) }}</h3>
                </v-flex>
              </v-layout>
              <v-divider class="ma-0 mt-4"></v-divider>
              
              <v-layout class="my-4 pa-0" d-flex> <!-- Event bevestiging -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" style="min-height: 0px" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-calendar-check</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4> {{ $t('emails.subscriptionConfirmation', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.subscriptionConfirmationHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    :input-value="true"
                    :label="$t('general.email', $store.state.locale)"
                    readonly
                    disabled
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="savingBranchData"
                    @change="changeMessageSettings()"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    style="width: 100%"
                    :items="confirmEventEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.eventConfirmEmail"
                  ></v-select>

                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    v-model="activeBranchMessages.eventConfirmTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="!activeBranchMessages.eventConfirmTextUse || savingBranchData"
                    @change="changeMessageSettings()"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    style="width: 100%"
                    :items="confirmEventTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.eventConfirmText"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-divider class="ma-4"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Event herinnering -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0" style="min-height: 0px">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-calendar-clock</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.subscriptionReminder', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.subscriptionReminderHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-0 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    v-model="activeBranchMessages.eventReminderEmailUse"
                    :label="$t('general.email', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="!activeBranchMessages.eventReminderEmailUse || savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    style="width: 100%"
                    :items="reminderEventEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.eventReminderEmail"
                  ></v-select>
                  <v-select
                    dense
                    class="mt-2"
                    :disabled="!activeBranchMessages.eventReminderEmailUse || savingBranchData"
                    item-value="number"
                    :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                    :items="reminderDays"
                    v-model="activeBranchMessages.eventReminderEmailTime"
                    :label="$t('emails.sendTimeBefore', $store.state.locale)"
                    outlined
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  ></v-select>

                </v-flex>
                <v-flex xs4 class="px-4 pt-0 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    v-model="activeBranchMessages.eventReminderTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="!activeBranchMessages.eventReminderTextUse || savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    style="width: 100%"
                    @change="changeMessageSettings()"
                    :items="reminderEventTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.eventReminderText"
                  ></v-select>
                  <v-select
                    dense
                    class="mt-2"
                    :disabled="!activeBranchMessages.eventReminderTextUse || savingBranchData"
                    item-value="number"
                    :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                    :items="reminderHours"
                    v-model="activeBranchMessages.eventReminderTextTime"
                    :label="$t('emails.sendTimeBefore', $store.state.locale)"
                    outlined
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  ></v-select>
                </v-flex>

              </v-layout>

              <v-divider class="ma-4"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Event wijziging -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0" style="min-height: 0px">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-calendar-expand-horizontal</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.subscriptionChanges', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.subscriptionChangesHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    :input-value="true"
                    :label="$t('general.email', $store.state.locale)"
                    disabled
                    read-only
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    style="width: 100%"
                    @change="changeMessageSettings()"
                    :items="changesEventEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.eventChangesEmail"
                  ></v-select>

                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    v-model="activeBranchMessages.eventChangesTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    hide-details
                    :disabled="savingBranchData"
                    @change="changeMessageSettings()"
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="!activeBranchMessages.eventChangesTextUse || savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    style="width: 100%"
                    :items="changesEventTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.eventChangesText"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-divider class="ma-4"></v-divider>

              <v-layout class="my-4 pa-0" d-flex> <!-- Event annuleren -->
                <v-flex xs4 d-flex>
                  <v-list class="ma-0 pa-0 pl-2" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="pa-0 ma-0" style="min-height: 0px">
                      <v-list-item-avatar
                        size="58"
                        light
                        class="ma-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                        >
                        <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-calendar-remove</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 ma">
                        <v-list-item-title>
                          <h4>{{ $t('emails.subscriptionCancel', $store.state.locale) }}</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle style="white-space: initial">{{ $t('emails.subscriptionCancelHint', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    :input-value="true"
                    :label="$t('general.email', $store.state.locale)"
                    disabled
                    read-only
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    @change="changeMessageSettings()"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    style="width: 100%"
                    :items="cancelEventEmails"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.eventCancelEmail"
                  ></v-select>

                </v-flex>
                <v-flex xs4 class="px-4 pt-2 pb-0" style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" >
                  <v-checkbox
                    class="ma-0 pa-0"
                    :disabled="savingBranchData"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    v-model="activeBranchMessages.eventCancelTextUse"
                    :label="$t('emails.textMessage', $store.state.locale)"
                    @change="changeMessageSettings()"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-2"
                    :disabled="!activeBranchMessages.eventCancelTextUse || savingBranchData"
                    @change="changeMessageSettings()"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                    style="width: 100%"
                    :items="cancelEventTexts"
                    item-text="title"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="activeBranchMessages.eventCancelText"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-divider class="ma-4 mx-0"></v-divider>
            </v-container>  

          </v-card>
        </v-tab-item>
        <v-tab-item :value="2" :transition="tabTransition" :reverse-transition="tabTransition">
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" >
            <v-container grid-list-md class="ma-0 pa-0">
              <v-layout class="mx-4 py-2 pa-0" d-flex> 
                <v-flex xs4 d-flex>
                  <h3>{{ $t('emails.defaultMessages', $store.state.locale) }}</h3>
                </v-flex>
              </v-layout>
            </v-container>

            <v-divider class="ma-0"></v-divider>

            <v-data-table
              :headers="defaultMessagesHeader"
              :items="defaultMessages"
              item-key="name"
              class="dense-table"
              hide-default-footer
              :itemsPerPage.sync="pages"
              > 
              <template v-slot:item="{item}">
                <tr >
                  <td><strong>{{ item.name[($store.state.locale).toLowerCase()] }}</strong></td>
                  <td>
                    <div v-if="item.messageTypes.email">
                      <div v-if="item.messages.email" style="min-height: 35px">
                        <v-btn block small outlined class="mt-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="previewMessage(item.messages.email, 'email')">
                          <v-icon small left>mdi-eye</v-icon> {{ $t('general.view', $store.state.locale) }}
                        </v-btn>
                      </div>
                      <div v-else style="min-height: 35px">
                        <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="red" class="mt-3 mb-2">mdi-alert-outline</v-icon>
                          </template>
                          <span style="color: #fff; font-size: 11px">{{ $t('emails.noDefaultEmail', $store.state.locale) }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div v-else>-</div>
                  </td>
                  <td>
                    <div v-if="item.messageTypes.text">
                      <div v-if="item.messages.text" style="min-height: 35px">
                        <v-btn block small outlined class="mt-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="previewMessage(item.messages.text, 'text')">
                          <v-icon small left>mdi-eye</v-icon> {{ $t('general.view', $store.state.locale) }}
                        </v-btn>
                      </div>
                      <div v-else style="min-height: 35px">
                        <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="red" class="mt-3 mb-2">mdi-alert-outline</v-icon>
                          </template>
                          <span style="color: #fff; font-size: 11px">{{ $t('emails.noDefaultSMS', $store.state.locale) }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div v-else>-</div>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <v-divider class="ma-0"></v-divider>

          </v-card>
          <v-card class="mt-5" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
            <v-container grid-list-md class="ma-0 pa-0">
              <v-layout class="mx-4 my-0 pa-0 py-1" d-flex> <!-- Standaard berichten -->
                <v-flex xs4 d-flex class="pt-2">
                  <h3>{{ $t('emails.customMessages', $store.state.locale) }}</h3>
                </v-flex>
                <v-flex xs8 class="text-right">
                  <v-btn outlined v-if="customMessages.length > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addNewMessage()">{{ $t('emails.addCustomMessages', $store.state.locale) }}</v-btn>
                </v-flex>
              </v-layout>
            </v-container>

            <v-divider class="ma-0"></v-divider>

            <v-data-table
              v-if="customMessages.length > 0"
              :headers="customMessagesHeader"
              :items="customMessages"
              item-key="name"
              :key="customMessages.length"
              class="dense-table"
              hide-default-footer
              :itemsPerPage.sync="pages"
              > 
              <template v-slot:item="{item}">
                <tr >
                  <td><strong>{{ item.title }}</strong> </td>
                  
                  <td>{{ getMessageType(item.type) }} </td>
                  <td style="font-style: italic">{{ item.lastChanges.seconds | moment("YYYY-MM-DD") }} om {{ item.lastChanges.seconds | moment("H:mm") }}u</td>
                  <td>
                    <div v-if="item.messageType == 'email'">
                      {{ $t('general.email', $store.state.locale) }}
                    </div>
                    <div v-if="item.messageType == 'text'">
                      {{ $t('emails.textMessage', $store.state.locale) }}
                    </div>
                  </td>
                  <td>
                    <div style="min-height: 35px">
                      <v-btn v-if="item.messageType == 'email'" class="my-2" small block  outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="previewMessage(item.id, 'email')">
                        <v-icon small left>mdi-eye</v-icon> {{ $t('general.view', $store.state.locale) }}
                      </v-btn>
                      <v-btn v-else class="my-2" small outlined block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="previewMessage(item.id, 'text')">
                        <v-icon small left>mdi-eye</v-icon> {{ $t('general.view', $store.state.locale) }}
                      </v-btn>
                    </div>
                  </td>
                  <td>
                    <div style="min-height: 35px">
                      <v-btn v-if="item.messageType == 'email'" class="my-2" dark block small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editEmail(item.id)" >
                        <v-icon small left>mdi-pencil</v-icon> {{ $t('emails.edit', $store.state.locale) }}
                      </v-btn>
                      <v-btn v-else block class="my-2" dark small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editText(item.id)" >
                        <v-icon small left>mdi-pencil</v-icon> {{ $t('emails.edit', $store.state.locale) }}
                      </v-btn>
                    </div>
                  </td>
                  <td>
                    <div style="min-height: 35px">
                      <v-btn dark class="my-2" small icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="duplicateMessage(item)">
                        <v-icon small>mdi-content-copy</v-icon> 
                      </v-btn>
                      <v-btn dark class="my-2" small icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeCustomEmailInfo.id = item.id, removeCustomEmailInfo.dialog = true" >
                        <v-icon small>mdi-delete</v-icon> 
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <div v-else class="text-center" style="padding: 50px; 0 ;">
              <v-avatar 
                size="56"
                light
                class="ma-4 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email-send-outline</v-icon>
              </v-avatar>
              
              <div style="font-size: 16px;">{{ $t('emails.noCustomMessages', $store.state.locale) }}</div>
              <v-btn class="mt-3" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addNewMessage()">
                <v-icon>mdi-plus</v-icon> {{ $t('emails.addCustomMessages', $store.state.locale) }}
              </v-btn>
            </div>

          </v-card>
        </v-tab-item>
        <v-tab-item :value="3" :transition="tabTransition" :reverse-transition="tabTransition">

            <div class="text-center" style="padding: 150px; 0 ;">
              <v-avatar 
                size="76"
                light
                class="ma-4 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email-send-outline</v-icon>
              </v-avatar>
              
              <div style="font-size: 18px;">{{ $t('emails.sendCampaigns', $store.state.locale) }}</div>
            </div>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <!-- / Add Message Dialog \ -->
    <v-dialog v-model="newMessage.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme} `">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon v-if="newMessage.messageType == 'email'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-email</v-icon>
            <v-icon v-if="newMessage.messageType == 'text'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-cellphone-text</v-icon>
          </v-avatar>
          <h3 class="mb-4">{{ $t('emails.newMesssage', $store.state.locale) }}</h3> 

          <v-alert v-if="newMessage.feedback" class="ma-0 pa-2 mb-4" text type="error">{{ newMessage.feedback }}</v-alert>

          <v-text-field
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
            :label="$t('general.name', $store.state.locale)"
            v-model="newMessage.title"
            fullWidth
            dense
            outlined
            hide-details
          ></v-text-field>  
          <v-select
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :items="defaultMessages"
            :item-text="item => item.name[($store.state.locale).toLowerCase()]"
            :label="$t('general.type', $store.state.locale)"
            item-value="type"
            dense
            class="mt-4"
            outlined
            hide-details
            v-model="newMessage.type"
          ></v-select>
          <v-select
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :items="messageTypes"
            :item-text="item => item.name[($store.state.locale).toLowerCase()]"
            :label="$t('emails.messageType', $store.state.locale)"
            item-value="id"
            dense
            class="mt-4"
            outlined
            hide-details
            v-model="newMessage.messageType"
          ></v-select>     
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="newMessage.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="createNewMessage()">
                   {{ $t('general.add', $store.state.locale) }} 
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Add Message Dialog / -->

    <!-- / Edit Email \ -->
    <email-editor></email-editor>
    <!-- \ Edit Email / -->

    <!-- / Edit SMS \ -->
    <sms-editor></sms-editor>
    <!-- \ Edit SMS / -->

    <!-- / Delete Item Dialog \ -->
    <v-dialog v-model="removeCustomEmailInfo.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <h2 class="mb-4">{{ $t('emails.removeMessage', $store.state.locale) }}</h2> 
          <p class="ma-0">{{ $t('emails.removeMessageHint', $store.state.locale) }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="removeCustomEmailInfo.deleting" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="removeCustomEmailInfo.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="removeCustomEmailInfo.deleting" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeCustomEmail()">
                   {{ $t('rooms.removeRoomButton', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Item Dialog / -->

    <!-- / Email Preview \ -->
    <email-preview></email-preview>
    <!-- \ Email Preview / -->

  </div>
</template>

<script>
  import '@firebase/firestore';
  import db from "../firebase/init";
  import { bus } from "../main";
  import EmailEditor from "./EmailEditor.vue";
  import SmsEditor from "./SmsEditor.vue";
  import EmailPreview from "./EmailPreview.vue";
  import MessagesHistory from "./MessagesHistory.vue";
  import companySelector from "@/components/elements/companySelector.vue";

  export default {
    name: "Messages",
    components: {
      "messages-history" : MessagesHistory,
      "email-editor": EmailEditor,
      "email-preview": EmailPreview,
      "sms-editor": SmsEditor,
      companySelector: companySelector
    },
    data(){
      return{
        newMessage: {
          dialog: false,
          type: "appointmentConfirm",
          messageType: 'email',
          title: null,
          subject: null,
          content: null,
          feedback: null
        },
        removeCustomEmailInfo: {
          dialog: false,
          id: null,
          deleting: false
        },
        messagesTemplates: new Array(),

        // Emails and Text for appointments
        confirmEmails: new Array(),
        confirmTexts: new Array(),
        confirmDownPaymentEmails: new Array(),
        confirmDownPaymentTexts: new Array(),
        reminderEmails: new Array(),
        reminderTexts: new Array(),
        downPaymentReminderEmails: new Array(),
        downPaymentReminderTexts: new Array(),
        changesEmails: new Array(),
        changesTexts: new Array(),
        cancelEmails: new Array(),
        cancelTexts: new Array(),
        appointmentCancelNoDownPaymentEmail:new Array(),
        appointmentCancelNoDownPaymentTexts:new Array(),

        // Email and Texts for events
        confirmEventEmails: new Array(),
        confirmEventTexts: new Array(),
        reminderEventEmails: new Array(),
        reminderEventTexts: new Array(),
        changesEventEmails: new Array(),
        changesEventTexts: new Array(),
        cancelEventEmails: new Array(),
        cancelEventTexts: new Array(),

        pages: -1,
        messageTypes: [
          {
            name: {
              nl: 'E-mail',
              en: 'Email',
              de: 'Email'
            },
            id: 'email'
          },
          {
            name: {
              nl: 'SMS',
              en: 'Textmessage',
              de: 'Textnachricht'
            },
            id: 'text'
          }
        ],
        defaultMessages: [
          {
            name: {
              nl: 'Afspraakbevestiging',
              en: 'Appointment Confirmation',
              de: 'Terminbestätigung'
            },
            type: "appointmentConfirm",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Verzoek om aanbetaling als bevestiging',
              en: 'Request for deposit as confirmation',
              de: 'Bitte um Anzahlung als Bestätigung'
            },
            type: "appointmentConfirmDownPayment",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Afspraakherinnering',
              en: 'Appointment reminder',
              de: 'Terminerinnerung'
            },
            type: "appointmentReminder",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Aanbetaling herinnering',
              en: 'Desposit reminder'
            },
            type: "downPaymentReminder",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Afspraak wijziging',
              en: 'Appointment changes',
              de: 'Terminänderungen'
            },
            type: "appointmentChanges",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Afspraak annulering',
              en: 'Appointment cancelation',
              de: 'Terminabsage'
            },
            type: "appointmentCancel",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Afspraak annulering (geen aanbetaling ontvangen)',
              en: 'Appointment cancelation (no deposit received)'
            },
            type: "appointmentCancelNoDownPayment",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Inschrijfbevestiging',
              en: 'Subscription Confirmation',
              de: 'Abonnementbestätigung'
            },
            type: "eventConfirm",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Herinnering inschrijving',
              en: 'Subscription reminder',
              de: 'Abonnement-Erinnerung'
            },
            type: "eventReminder",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Inschrijving wijziging',
              en: 'Event changes',
              de: 'Veranstaltungsänderungen'
            },
            type: "eventChanges",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
          {
            name: {
              nl: 'Inschrijving annulering of uitschrijving',
              en: 'Subscription cancelation or unsubscription',
              de: 'Kündigung oder Abmeldung des Abonnements'
            },
            type: "eventCancel",
            messageTypes: {
              email: true,
              text:  true
            },
            messages: {
              email: null,
              text:  null
            }
          },
        ], 
        customMessages: new Array(),
        defaultMessagesHeader: [
          {
            // text: this.$t('general.name', this.$store.state.locale) ,
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "name",
            
          },
          {
            // text: this.$t('general.name', this.$store.state.locale) ,
            text: this.$t('general.email', this.$store.state.locale),
            align: "left",
            value: "name",
            sortable: false,
            width: "180px"
          },
          {
            // text: this.$t('general.name', this.$store.state.locale) ,
            text: this.$t('emails.textMessage', this.$store.state.locale),
            align: "left",
            value: "name",
            sortable: false,
            width: "180px"
          }
        ],
        customMessagesHeader: [
          {
            // text: this.$t('general.name', this.$store.state.locale) ,
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "name",
            width: "200px",
            sortable: true,
          },
          {
            // text: this.$t('general.name', this.$store.state.locale) ,
            text: "Type",
            align: "left",
            value: "name",
            sortable: false,
            width: "200px"
          },
          {
            // text: this.$t('general.name', this.$store.state.locale) ,
            text: this.$t('general.lastUpdated', this.$store.state.locale),
            align: "left",
            value: "lastChanges",
            width: "160px",
            sortable: true,
          },
          {
            text: this.$t('general.type', this.$store.state.locale) ,
            align: "left",
            value: "name",
            sortable: false,
            width: "100px"
          },
          {
            // text: this.$t('general.name', this.$store.state.locale) ,
            text: "",
            width: "140px",
            align: "left",
            value: "name",
            sortable: false
          },
          {
            // text: this.$t('general.name', this.$store.state.locale) ,
            text: "",
            width: "140px",
            align: "left",
            value: "name",
            sortable: false
          },
          {
            // text: this.$t('general.name', this.$store.state.locale) ,
            text: "",
            width: "80px",
            align: "left",
            value: "name",
            sortable: false
          }
        ],
        tab: 0,
        searchCompanies: "",
        tabTransition: false,
        activeBranchOriginal: new Object(),
        dataloaded: false,
        branchesLoaded: false,
        messagesChanges: 0,
        savingBranchData: false,
        activeBranchMessages: new Object(),

        reminderDays: [
          {
            number: 24,
            name: {
              nl: '1 dag van tevoren',
              en: '1 day before',
              de: '1 Tag vorher'
            },
          },
          {
            number: 48,
            name: {
              nl: '2 dagen van tevoren',
              en: '2 days before',
              de: '2 Tage vorher'
            },
          },
          {
            number: 72,
            name: {
              nl: '3 dagen van tevoren',
              en: '3 days before',
              de: '3 Tage vorher'
            },
          },
          {
            number: 96,
            name: {
              nl: '4 dagen van tevoren',
              en: '4 days before',
              de: '4 days before'
            },
          },
          {
            number: 120,
            name: {
              nl: '5 dagen van tevoren',
              en: '5 days before',
              de: '5 Tage vorher'
            },
          }
        ],
        reminderHours: [
          {
            number: 1,
            name: {
              nl: '1 uur voor aanvang',
              en: '1 hour before start',
              de: '1 Stunde vor Beginn'
            },
          },
          {
            number: 2,
            name: {
              nl: '2 uur voor aanvang',
              en: '2 hours before start',
              de: '2 Stunden vor Beginn'
            },
          },
          {
            number: 3,
            name: {
              nl: '3 uur voor aanvang',
              en: '3 hours before start',
              de: '3 Stunden vor Beginn'
            },
          },
          {
            number: 6,
            name: {
              nl: '6 uur voor aanvang',
              en: '6 hours before start',
              de: '6 Stunden vor Beginn'
            },
          },
          {
            number: 12,
            name: {
              nl: '12 uur',
              en: '12 hours before start',
              de: '12 Stunden vor Beginn'
            },
          }
        ],
      }
    },

    created() {
      this.loadData();
    },

    methods: {
      updateData() {
        this.loadData();
      },

      loadData(){
        this.dataloaded = false;
        this.activeBranchOriginal = _.cloneDeep(this.$store.state.activeBranch);
        this.loadSelectedBranchData();
        this.loadMessageTemplates();
        bus.$emit("loadMessageHistory");
      },

      changeMessageSettings(){
        this.messagesChanges++
        this.saveBranchMessageSettings();
      },

      async changeActiveBranchData(branchId){
        if(branchId != this.$store.state.activeBranch.id){
          await this.$store.dispatch("changeActiveBranchData", branchId);
          this.loadData();
        }
      },

      loadSelectedBranchData() {   
        let messsageSettings = new Object();
        db.collection("branches") // Get and Set Branch Messages Data
        .doc(this.$store.state.activeBranch.id)
        .collection("messages")
        .doc("settings").get()
        .then(doc => {
          if (!doc.exists) {
            messsageSettings = new Object();
          }
          else{
            messsageSettings = doc.data()
          }
        })
        .then(()=>{
          this.$store.state.activeBranch.messages = messsageSettings ? messsageSettings : new Object();

          this.activeBranchMessages.appointmentConfirmEmail =  messsageSettings.appointmentConfirmEmail ? messsageSettings.appointmentConfirmEmail : 'general';
          this.activeBranchMessages.appointmentConfirmText = messsageSettings.appointmentConfirmText ? messsageSettings.appointmentConfirmText : 'general';
          this.activeBranchMessages.appointmentConfirmTextUse = messsageSettings.appointmentConfirmTextUse ? messsageSettings.appointmentConfirmTextUse : false;
          this.activeBranchMessages.appointmentConfirmDownPaymentEmail = messsageSettings.appointmentConfirmDownPaymentEmail ? messsageSettings.appointmentConfirmDownPaymentEmail : 'general';
          this.activeBranchMessages.appointmentConfirmDownPaymentText = messsageSettings.appointmentConfirmDownPaymentText ? messsageSettings.appointmentConfirmDownPaymentText : 'general';
          this.activeBranchMessages.appointmentConfirmDownPaymentTextUse = messsageSettings.appointmentConfirmDownPaymentTextUse ? messsageSettings.appointmentConfirmDownPaymentTextUse : false;
          this.activeBranchMessages.appointmentReminderEmail = messsageSettings.appointmentReminderEmail ? messsageSettings.appointmentReminderEmail : 'general';
          this.activeBranchMessages.appointmentReminderEmailUse = messsageSettings.appointmentReminderEmailUse ? messsageSettings.appointmentReminderEmailUse : false;
          this.activeBranchMessages.appointmentReminderEmailTime = messsageSettings.appointmentReminderEmailTime ? messsageSettings.appointmentReminderEmailTime : 24;
          this.activeBranchMessages.appointmentReminderText = messsageSettings.appointmentReminderText ? messsageSettings.appointmentReminderText : 'general';
          this.activeBranchMessages.appointmentReminderTextUse = messsageSettings.appointmentReminderTextUse ? messsageSettings.appointmentReminderTextUse : false;
          this.activeBranchMessages.appointmentReminderTextTime = messsageSettings.appointmentReminderTextTime ? messsageSettings.appointmentReminderTextTime : 1;
          this.activeBranchMessages.downPaymentReminderEmail = messsageSettings.downPaymentReminderEmail ? messsageSettings.downPaymentReminderEmail : 'general';
          this.activeBranchMessages.downPaymentReminderEmailUse = messsageSettings.downPaymentReminderEmailUse ? messsageSettings.downPaymentReminderEmailUse : false;
          this.activeBranchMessages.downPaymentReminderText = messsageSettings.downPaymentReminderText ? messsageSettings.downPaymentReminderText : 'general';
          this.activeBranchMessages.downPaymentReminderTextUse = messsageSettings.downPaymentReminderTextUse ? messsageSettings.downPaymentReminderTextUse : false;
          this.activeBranchMessages.appointmentChangesEmail = messsageSettings.appointmentChangesEmail ? messsageSettings.appointmentChangesEmail : 'general';
          this.activeBranchMessages.appointmentChangesText = messsageSettings.appointmentChangesText ? messsageSettings.appointmentChangesText : 'general';
          this.activeBranchMessages.appointmentChangesTextUse = messsageSettings.appointmentChangesTextUse ? messsageSettings.appointmentChangesTextUse : false;
          this.activeBranchMessages.appointmentCancelEmail = messsageSettings.appointmentCancelEmail ? messsageSettings.appointmentCancelEmail : 'general';
          this.activeBranchMessages.appointmentCancelText = messsageSettings.appointmentCancelText ? messsageSettings.appointmentCancelText : 'general';
          this.activeBranchMessages.appointmentCancelTextUse = messsageSettings.appointmentCancelTextUse ? messsageSettings.appointmentCancelTextUse : false;            
          this.activeBranchMessages.appointmentCancelNoDownPaymentEmail = messsageSettings.appointmentCancelNoDownPaymentEmail ? messsageSettings.appointmentCancelNoDownPaymentEmail : 'general';
          this.activeBranchMessages.appointmentCancelNoDownPaymentText = messsageSettings.appointmentCancelNoDownPaymentText ? messsageSettings.appointmentCancelNoDownPaymentText : 'general';
          this.activeBranchMessages.appointmentCancelNoDownPaymentTextUse = messsageSettings.appointmentCancelNoDownPaymentTextUse ? messsageSettings.appointmentCancelNoDownPaymentTextUse : false;     


          this.activeBranchMessages.eventConfirmEmail = messsageSettings.eventConfirmEmail ? messsageSettings.eventConfirmEmail : 'general';
          this.activeBranchMessages.eventConfirmText = messsageSettings.eventConfirmText ? messsageSettings.eventConfirmText : 'general';
          this.activeBranchMessages.eventConfirmTextUse = messsageSettings.eventConfirmTextUse ? messsageSettings.eventConfirmTextUse : false;
          this.activeBranchMessages.eventReminderEmail = messsageSettings.eventReminderEmail ? messsageSettings.eventReminderEmail : 'general';
          this.activeBranchMessages.eventReminderEmailUse = messsageSettings.eventReminderEmailUse ? messsageSettings.eventReminderEmailUse : false;
          this.activeBranchMessages.eventReminderEmailTime = messsageSettings.eventReminderEmailTime ? messsageSettings.eventReminderEmailTime : 24;
          this.activeBranchMessages.eventReminderText = messsageSettings.eventReminderText ? messsageSettings.eventReminderText : 'general';
          this.activeBranchMessages.eventReminderTextUse = messsageSettings.eventReminderTextUse ? messsageSettings.eventReminderTextUse : false;
          this.activeBranchMessages.eventReminderTextTime = messsageSettings.eventReminderTextTime ? messsageSettings.eventReminderTextTime : 1;
          this.activeBranchMessages.eventChangesEmail = messsageSettings.eventChangesEmail ? messsageSettings.eventChangesEmail : 'general';
          this.activeBranchMessages.eventChangesText = messsageSettings.eventChangesText ? messsageSettings.eventChangesText : 'general';
          this.activeBranchMessages.eventChangesTextUse = messsageSettings.eventChangesTextUse ? messsageSettings.eventChangesTextUse : false;
          this.activeBranchMessages.eventCancelEmail = messsageSettings.eventCancelEmail ? messsageSettings.eventCancelEmail : 'general';
          this.activeBranchMessages.eventCancelText = messsageSettings.eventCancelText ? messsageSettings.eventCancelText : 'general';
          this.activeBranchMessages.eventCancelTextUse = messsageSettings.eventCancelTextUse ? messsageSettings.eventCancelTextUse : false;
        })

        this.branchesLoaded = true;
        this.dataloaded = true;
      },

      addNewMessage(){
        this.newMessage.dialog = true;
      },

      editEmail(id){ // Open EditEmail.vue in Dialog with bus
        bus.$emit("editEmailTemplate", id, 'user'); // Send Message Id and Type to EditEmail.vue (id: Admin type had global variables to use)
      },

      editText(id){ // Open EditSMS.vue in Dialog with bus
        bus.$emit("editTextTemplate", id, 'user'); // Send Message Id and Type to SendEmail.vue (id: Admin has global variables to use)
      },

      async removeCustomEmail(){
        this.removeCustomEmailInfo.deleting = true;
        await db.collection("messages").doc(this.removeCustomEmailInfo.id).delete()
        this.removeCustomEmailInfo.dialog = false;
        this.removeCustomEmailInfo.deleting = false;
        this.loadMessageTemplates(); 
      },

      async createNewMessage(){ // Create new custom message based om 
        this.newMessage.feedback = null;

        let emailContent = { // Set Default Empty Email Content
          children: [
            { tagName: "mj-body", children: new Array()},
            { tagName: "mj-head", children: new Array()}
          ]
        };

        // Find General Message Template (to copy)
        let defaultMessageId = null;
        let defaultMessage = this.defaultMessages.find((defaultMessage)=> { return defaultMessage.type == this.newMessage.type});

        if(defaultMessage != undefined){
          defaultMessageId = defaultMessage.messages[this.newMessage.messageType] ? defaultMessage.messages[this.newMessage.messageType] : null;
        }

        // Get Default Email content from General Template
        if(defaultMessageId){ 
          await db.collection("messages").doc(defaultMessageId).get()
          .then(doc => {
            if(doc.exists){
              emailContent = doc.data().content
            }
          })
        }
             
        // Create new template
        if(this.newMessage.title){
          await db.collection("messages").add({
            templateType: 'custom',
            type: this.newMessage.type,
            title: this.newMessage.title,
            messageType: this.newMessage.messageType,
            lastChanges: new Date(),
            campaignType: 'transactional', // Transactional email to inform the client, no promotion
            content: this.newMessage.type == 'text' ? new Object() : emailContent,
            subject: this.newMessage.type == 'text' ? null : {en:"", nl: "", de: "x "},
            companyId: this.$store.state.activeCompany.id
          })
          this.newMessage.dialog = false;
          this.newMessage.title = '';
          this.newMessage.messageType = 'email';
          this.newMessage.type = 'appointmentConfirm';
          this.loadMessageTemplates(); 
        }
        else{
          this.newMessage.feedback = this.$t('emails.addNameToSaveMessage', this.$store.state.locale); 
        }
      },

      async duplicateMessage(item){
        await db.collection("messages").add({
          templateType: 'custom',
          type: item.type,
          title: `${item.title} (${this.$t('general.duplicate', this.$store.state.locale)})`,
          messageType: item.messageType,
          lastChanges: new Date(),
          campaignType: 'transactional', // Transactional email to inform the client, no promotion
          content: item.content,
          subject: item.subject,
          companyId: this.$store.state.activeCompany.id
        })
        this.loadMessageTemplates(); 
      },

      getMessageType(id){
        let messageInfo = this.defaultMessages.find(message => message.type == id);
        if(messageInfo){
          return messageInfo.name[(this.$store.state.locale).toLowerCase()]
        }
        else{
          return null
        }
      },

      saveBranchMessageSettings(){
        this.savingBranchData = true;
        db.collection("branches")
        .doc(this.$store.state.activeBranch.id)
        .collection("messages")
        .doc("settings")
        .set(this.activeBranchMessages,{merge: true})
        .then(() => {
          this.savingBranchData = false;
        })
        .catch(error=>{
          console.error("Error saving Message Settings to Branch: ", error) 
          this.savingBranchData = false;
        })
      },

      previewMessage(id, messageType){
        bus.$emit("previewMessageTemplate", id, null, null, null, null, messageType); // Send Message Id and Type to EditEmail.vue (Admin type had global variables to use)
      },

      loadMessageTemplates(){
        this.messagesTemplates = new Array();
        this.customMessages = new Array();

        // Emails and Text for appointments
        this.confirmEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.confirmTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.confirmDownPaymentEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.confirmDownPaymentTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.reminderEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.reminderTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.downPaymentReminderEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.downPaymentReminderTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.changesEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.changesTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.cancelEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.cancelTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.appointmentCancelNoDownPaymentEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.appointmentCancelNoDownPaymentTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];

        // Email and Texts for events
        this.confirmEventEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.confirmEventTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.reminderEventEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.reminderEventTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.changesEventEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.changesEventTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.cancelEventEmails = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];
        this.cancelEventTexts = [{ title: this.$t('emails.general', this.$store.state.locale), id: 'general' }];

        let defaultMessagesThrive = new Array();
        db.collection("messages")
        .where("templateType", "==", 'general')
        .get()
        .then(snap => {
          snap.forEach(doc => {         
            let message = doc.data();
            message.id = doc.id;
            defaultMessagesThrive.push(message);
          });
        })
        .then(()=>{
          if(defaultMessagesThrive.length > 0){
            defaultMessagesThrive.forEach(message => {
              this.defaultMessages.forEach(defaultMessage => {
                if(defaultMessage.type == message.type){
                  defaultMessage.messages[message.messageType] = message.id
                }
              })
            });
          }
        })
        .catch((error)=>{
          console.error("Error getting Default Templates: ", error)
        })
        
        db.collection("messages")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("templateType", "==", 'custom')
        .get()
        .then(snap => {
          snap.forEach(doc => {         
            let template = doc.data();
            template.id = doc.id;
            this.messagesTemplates.push(template);
            this.customMessages.push(template);
          });
        })
        .then(()=>{
          if(this.messagesTemplates.length > 0){
            this.messagesTemplates.forEach(template => {
              if(template.messageType === 'email'){
                if(template.type == 'appointmentConfirm'){
                  this.confirmEmails.push(template)
                }
                if(template.type == 'appointmentConfirmDownPayment'){
                  this.confirmDownPaymentEmails.push(template)
                }
                if(template.type == 'appointmentReminder'){
                  this.reminderEmails.push(template)
                }
                if(template.type == 'downPaymentReminder'){
                  this.downPaymentReminderEmails.push(template)
                }
                if(template.type == 'appointmentChanges'){
                  this.changesEmails.push(template)
                }
                if(template.type == 'appointmentCancel'){
                  this.cancelEmails.push(template)
                }
                if(template.type == 'appointmentCancelNoDownPayment'){
                  this.appointmentCancelNoDownPaymentEmails.push(template)
                }
                if(template.type == 'eventConfirm'){
                  this.confirmEventEmails.push(template)
                }
                if(template.type == 'eventReminder'){
                  this.reminderEventEmails.push(template)
                }
                if(template.type == 'eventChanges'){
                  this.changesEventEmails.push(template)
                }
                if(template.type == 'eventCancel'){
                  this.cancelEventEmails.push(template)
                }
              }
              else if(template.messageType === 'text'){
                if(template.type == 'appointmentConfirm'){
                  this.confirmTexts.push(template)
                }
                if(template.type == 'appointmentDownPaymenyConfirm'){
                  this.confirmDownPayconfirmDownPaymentTextsmentEmails.push(template)
                }
                if(template.type == 'appointmentReminder'){
                  this.reminderTexts.push(template)
                }
                if(template.type == 'downPaymentReminder'){
                  this.downPaymentReminderText.push(template)
                }
                if(template.type == 'appointmentChanges'){
                  this.changesTexts.push(template)
                }
                if(template.type == 'appointmentCancel'){
                  this.cancelTexts.push(template)
                }
                if(template.type == 'appointmentCancelNoDownPayment'){
                  this.appointmentCancelNoDownPaymentTexts.push(template)
                }
                if(template.type == 'eventConfirm'){
                  this.confirmEventTexts.push(template)
                }
                if(template.type == 'eventReminder'){
                  this.reminderEventTexts.push(template)
                }
                if(template.type == 'eventChanges'){
                  this.changesEventTexts.push(template)
                }
                if(template.type == 'eventCancel'){
                  this.cancelEventTexts.push(template)
                }
              }
            });
          }
        })
      }
    },
  };
</script>