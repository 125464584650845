
<template>
    <v-dialog
      v-model="emailPreviewDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card class="email-preview">
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>Preview</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeEmailPreviewDialog()">
            <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0" style="position: relative">

          <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackBarStatus" top>
            <v-row>
              <v-icon color="white" class="mx-3">{{ snackbarIcon }}</v-icon>
              <span class="py-2 white--text">{{ snackBarMessage }}</span>
            </v-row>
          </v-snackbar>

          <div v-if="loading">
            <div style="position: relative; padding: 200px 0;">
              <div class="thrive-loader"></div>
            </div>
          </div>

          <v-container class="ma-0 pa-0 full-height-container-preview" v-else>
            <v-layout class="ma-0 pa-0">
               <v-flex xs8>
                 <div style="max-width: 400px; margin: 0 auto" v-if="messageType == 'email'">
                  <v-container container class="ma-0 pa-3">
                    <v-layout row wrap class="ma-0 pa-0">
                      <v-flex xs6 class="ma-0 pa-0">
                        <div class="module-colum-button left" :class="{ active: selectedPreviewType == 'desktop' }" @click="selectedPreviewType = 'desktop'">
                          <v-icon size="16" class="mr-2" :color="selectedPreviewType == 'desktop' ? '#ffffff' : $themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-desktop-mac</v-icon>
                          Desktop
                        </div>
                      </v-flex>
                      <v-flex xs6 class="ma-0 pa-0">
                        <div class="module-colum-button right"  :class="[{ active: selectedPreviewType == 'mobile'}]" @click="selectedPreviewType = 'mobile'">
                          <v-icon size="16" class="mr-2" :color="selectedPreviewType == 'mobile' ? '#ffffff' : $themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cellphone</v-icon>
                          Mobile
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </div>

              </v-flex>
              <v-flex xs4 style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}">
                <div class="pa-4 pt-5">
                  <h3 v-if="messageType == 'email'">Email information</h3>
                  <h3 v-if="messageType == 'text'">SMS information</h3>
                </div>
              </v-flex>
            </v-layout>

            <v-divider></v-divider>

            <v-layout fill-height>
              <v-flex xs18 class="remove-all-styling preview" fill-height>

                <div class="text-preview-wrapper mobile" v-if="messageType == 'text' && selectedMessage.content">
                  <div class="text-preview-display mobile">
                    <div class="textmessage">
                      {{ smsContent }}
                    </div>
                  </div>
                </div>

                <div class="email-preview-wrapper" :class="selectedPreviewType" v-if="messageType == 'email'">
                  <div class="email-preview-display" :style="selectedPreviewStyle" v-html="emailInformation.content"></div>
                </div>
              </v-flex>
              <v-flex xs4 style="border-left: 1px solid" :style="{'border-color' :  $themes[$store.state.companyTheme][$store.state.themeModus].separator}" fill-height>

                <div class="pa-4">
                  <h4>Type</h4>
                  <p class="mb-0">{{ getMessageName(emailInformation.type) }}</p>

                  <v-text-field
                    v-if="messageType == 'text'"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    class="mt-4"
                    label="Afzender"
                    v-model="smsSender"
                    outlined
                    persistent-hint
                    hint="Kan gewijzigd worden bij vestiging."
                    dense
                    read-only
                    disabled
                  ></v-text-field>

                  <v-select
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :items="branches"
                    label="Vestiging"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="selectedBranch"
                    @change="updatePreview()"
                    class="my-4"
                  ></v-select>

                  <v-select
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :items="languages"
                    label="Taal"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    v-model="selectedPreviewLanguage"
                    @change="updatePreview()"
                    class="my-4"
                  ></v-select>
                  
                  <div v-if="messageType == 'email'">
                    <h4>Subject</h4>
                    <p class="mt-0">{{ emailInformation.subject }}</p>
                  </div>
                </div>

                <v-card outlined class="ma-4 pa-2" v-if="messageType == 'email'">
                  <h4>Test versturen</h4>

                  <v-text-field
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    hide-details
                    class="mt-2"
                    label="Emailadres"
                    v-model="testEmailaddress"
                    outlined
                    dense
                  ></v-text-field>
                  <v-btn block class="mt-2" :disabled="!testEmailaddress" :dark="testEmailaddress != null" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"   @click="sendTestEmail()">Verstuur test</v-btn>
                </v-card>

                <v-card outlined class="ma-4 mt-0 pa-2" v-if="messageType == 'text'">
                  <h4>Test versturen</h4>

                  <v-text-field
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                    hide-details
                    class="mt-2"
                    label="Telefoonummer"
                    v-model="testPhonenumber"
                    outlined
                    dense
                  ></v-text-field>
                  <v-btn block class="mt-2" :disabled="!testPhonenumber" :dark="testPhonenumber != null" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"   @click="sendTestText()">Verstuur test</v-btn>
                </v-card>
              </v-flex>

            </v-layout>
          </v-container>

        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import _ from 'lodash'
import db from "../firebase/init";
import { bus } from "../main";
import moment from "moment";
import axios from "axios";

export default {
  name:"previewMessageTemplate",
  data(){
    return{
      snackbar: null,
      timeout: 2500,
      snackBarMessage: null,
      snackBarStatus: null,
      snackbarIcon: null,
      receipt: null,

      loading: true,
      branches: new Array(),
      selectedBranch: null,
      emailPreviewDialog: false,
      testEmailaddress: null,
      testPhonenumber: null,

      smsContent: null,
      smsSender: null,
      
      emailInformation: {
        content: null,
        subject: new Object(),
        type: 'appointmentConfirm',
      },
      messageType: "email",
      selectedPreviewLanguage: 'nl',
      selectedPreviewType: 'desktop',
      selectedMessage: new Object(),
      receiptPreview: `
          <table border="0" cellpadding="0" width="99%" style="width:100%; font-family: Menlo, Consolas, "Lucida Console", monospace!important;font-size:14px;font-style:normal;font-weight:400;line-height:1;text-align:left;text-decoration:none;text-transform:none;color:#333333;"><tr><td>
            <table style="width:100%;""><tr><td style="padding: 4px 0px;">${moment().format("DD/MM/YYYY")}</td><td align="right" style="padding: 4px 0px;">${moment().format("H:mm")}u</td></tr></table></td></tr>
            <tr><td>&nbsp;</td></tr>
            <tr><td><table border="0" cellpadding="0" width="99%" style="width: 100%;"><tr style="border-bottom: 1px dashed rgba(94, 86, 105, 0.87) !important; border-top: 1px dashed rgba(94, 86, 105, 0.87) !important;"><td style="padding: 4px 0px;"><strong>Artikelen</strong></td><td align="right" style="padding: 4px 0px;"><strong>Prijs</strong></td></tr><tr><td style="padding: 4px 0px;">Behandeling</td><td align="right" style="padding: 4px 0px;">€ 150.00</td></tr><tr><td style="padding: 4px 0px;">Product</td><td align="right" style="padding: 4px 0px;">€ 21.95</td></tr><tr style="border-top: 1px dashed rgba(94, 86, 105, 0.87) !important;"><td style="padding: 8px 0px;"><strong>Totaal</strong></td><td align="right" style="padding: 8px 0px;"><strong>€ 171,95</strong></td></tr></table></td></tr> 
            <tr><td><table border="0" cellpadding="0" width="99%" style="width: 100%;"><tr><td style="padding: 8px 0px 4px;"><strong>BTW</strong></td><td align="right" style="padding: 8px 0px 4px;"></td></tr><tr><td style="padding: 4px 0px 0px;">BTW 21%: </td><td align="right" style="padding: 4px 0px 0px;">€ 26,03</td></tr><tr><td style="padding: 4px 0px 0px;">BTW 9%: </td><td align="right" style="padding: 4px 0px 0px;">€ 1,81</td></tr></table></td></tr>
          </table>`,
      languages: [
        {
          name: "Nederlands",
          id: 'nl'
        },
        {
          name: "Engels",
          id: 'en'
        },
        {
          name: "Duits",
          id: 'de'
        }
      ],
      emailTypes: [
        {
          name: {
            nl: "Afspraakbevestiging",
            en: "Appointment Confirmation"
          },
          id: 'appointmentConfirm',
        },
        {
          name: {
            nl: "Afspraakbevestiging met aanbetaling",
            en: "Appointment Confirmation with Downpayment"
          },
          id: 'appointmentConfirmDownPayment',
        },
        {
          name: {
            nl: "Afspraakherinnering",
            en: "Appointment reminder"
          },
          id: 'appointmentReminder',
        },
        {
          name: {
            nl: "Afspraak annulering ",
            en: "Appointment cancellation "
          },
          id: 'appointmentCancel',
        },
        {
          name: {
            nl: "Afspraak  aanpassingsbevestiging",
            en: "Appointment adjustment confirmation"
          },
          id: 'appointmentChanges',
        },
        {
          name: {
            nl: "Inschrijfbevestiging ",
            en: "Confirmation subscription"
          },
          id: 'eventConfirm',
        },
        {
          name: {
            nl: "Herinnering Eventinschrijving",
            en: "Event Subscription reminder"
          },
          id: 'eventReminder',
        },
        {
          name: {
            nl: "Ischrijving annulering ",
             en: "Subscription cancellation"
          },
          id: 'eventCancel',
        },
        {
          name: {
            nl: "Inschrijving aanpassingsbevestiging",
              en: "Inschrijving adjustment confirmation"
          },
          id: 'eventChanges',
        }
      ],
    }
  },

  created(){
    bus.$on("previewMessageTemplate", async (id, branchId, content, header, subject, type, messageType) => { 
      this.selectedBranch = _.cloneDeep(this.$store.state.activeBranch.id)
      this.branches = _.cloneDeep(this.$store.state.activeUserBranches) 
      // await this.loadBranchData(branchId);

      if(content && subject){
        this.messageType = messageType;
        this.selectedMessage.content = _.cloneDeep(content);
        if(header){
          this.selectedMessage.content.children.push(header)
        }
        this.selectedMessage.subject = subject;
        this.emailInformation.type = type;
      }
      else{
        await this.loadEmailTemplate(id);
      }
      this.loading = true;
      this.updatePreview();
    })
  },

  methods:{
    async loadEmailTemplate(id){
      await db.collection('messages').doc(id).get()
      .then(doc => {
        this.selectedMessage =  doc.data();
        this.selectedMessage.id = doc.id;
        this.emailInformation.type = doc.data().type;
        this.messageType = doc.data().messageType;
        if(this.selectedMessage.header){
          this.selectedMessage.content.children.push(this.selectedMessage.header)
        }
      });
      return
    },

    openEmailPreviewDialog(){
      this.emailPreviewDialog = true;
    },

    closeEmailPreviewDialog(){
      this.emailPreviewDialog = false;
      this.selectedMessage = new Object();
      this.emailInformation.content = null; 
      this.emailInformation.subject = null;
      this.testEmailaddress = null;
      this.testPhonenumber = null;
    },

    updatePreview(){
      if(this.messageType == 'email'){
        this.emailPreview();
      }
      else{
        this.textPreview();
      }
    },

    async emailPreview(){
      let receipt = this.selectedMessage.type == 'receipt' || this.emailInformation.type == 'receipt' ? Buffer.from(this.receiptPreview).toString('base64') : null;
      await axios.post(`${this.$store.state.messagesApi.url}/emailPreview`, {language: this.selectedPreviewLanguage, object: this.selectedMessage.content, subject: this.selectedMessage.subject, companyId: this.$store.state.activeCompany.id, branchId: this.selectedBranch, receipt: receipt})
      .then((response) => {
        this.emailInformation.content = response.data.html;
        this.emailInformation.subject = response.data.subject;
      })
      .catch(response=>{
        console.error("Error converting to html", response);
        this.emailInformation.content = null; 
        this.emailInformation.subject = null;
      })
      this.loading = false;
      this.openEmailPreviewDialog();
    },

    async textPreview(){
      await axios.post(`${this.$store.state.messagesApi.url}/textPreview`, {language: this.selectedPreviewLanguage, object: this.selectedMessage.content, companyId: this.$store.state.activeCompany.id, branchId: this.selectedBranch, receipt: null })
      .then((response) => {
        this.smsContent = response.data.text;
        this.smsSender = response.data.sender;
      })
      .catch(response=>{
        console.error("Error converting to html", response);
        this.emailInformation.content = null; 
        this.emailInformation.subject = null;
      })
      this.loading = false;
      this.openEmailPreviewDialog();

    },

    sendTestEmail(){
      let receipt = this.selectedMessage.type == 'receipt' || this.emailInformation.type == 'receipt' ? Buffer.from(this.receiptPreview).toString('base64') : null;
      axios.post(`${this.$store.state.messagesApi.url}/emailTest`, {language: this.selectedPreviewLanguage, object: this.selectedMessage.content, type: this.selectedMessage.type, subject: this.selectedMessage.subject, companyId: this.$store.state.activeCompany.id, branchId: this.selectedBranch, email: this.testEmailaddress, receipt: receipt})
      .then((response) => {
        this.snackbar = true;
        this.snackBarStatus = "rgba(1, 230, 110, 0.7)";
        this.snackbarIcon = "mdi-thumb-up";
        this.snackBarMessage = "Email verzonden";
      })
      .catch(response=>{
        this.snackbar = true;
        this.snackBarStatus = "rgba(213, 0, 0, 0.7)";
        this.snackbarIcon = "mdi-thumb-down";
        this.snackBarMessage = "Verzenden niet gelukt";
        console.error("Error sending testmail:", response);
      })
    },

    sendTestText(){
      axios.post(`${this.$store.state.messagesApi.url}/textTest`, {language: this.selectedPreviewLanguage, object: this.selectedMessage.content, type: this.selectedMessage.type, sender: this.smsSender, companyId: this.$store.state.activeCompany.id, branchId: this.selectedBranch, phone: this.testPhonenumber})
      .then((response) => {
        // console.log("response.data: ",response.data);
        this.snackbar = true;
        this.snackBarStatus = "rgba(1, 230, 110, 0.7)";
        this.snackbarIcon = "mdi-thumb-up";
        this.snackBarMessage = "Sms verzonden";
      })
      .catch(response=>{
        this.snackbar = true;
        this.snackBarStatus = "rgba(213, 0, 0, 0.7)";
        this.snackbarIcon = "mdi-thumb-down";
        this.snackBarMessage = "Verzenden niet gelukt";
        console.error("Error sending testmail:", response);
      })
    },

    getMessageName(type){
      let name = null;
      let counter = 0;
      new Promise((resolve)=>{
        this.emailTypes.forEach(message => {
          if(type == message.id){
            name = message.name[(this.$store.state.locale).toLowerCase()];
          }
          counter++
          if(counter == this.emailTypes.length){
            resolve()
          }
        });
      })
      return name
    }
  },

  computed: {
    selectedPreviewStyle(){
      if(this.selectedPreviewType == 'desktop'){
        return 'max-width: 100%'
      }
      else if(this.selectedPreviewType == 'tablet'){
        return 'max-width: 500px'
      }
      if(this.selectedPreviewType == 'mobile'){
        return 'max-width: 320px'
      }
    }
  }
};
</script>

