import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import i18n, { selectedLocale } from "i18n";

import Home from "../views/Home";
import logPlayer from "../views/logPlayer";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import DeviceTypes from "../views/DeviceTypes.vue";
import Users from "../views/Users.vue";
import Companies from "../views/Companies.vue";
import Admin from "../views/Admin.vue";
import Settings from "../views/Settings.vue";
import posSettings from "../views/posThermalSettings.vue";
import BranchesSettings from "../views/BranchesSettings.vue";
import Abilities from "../views/Abilities.vue";
import Clients from "../views/Clients.vue";
import Financials from "../views/Financials.vue";
import Inventory from "../views/Inventory.vue";
import Pay from "../views/Pay.vue";
import Log from "../views/Log.vue";
import Menu from "../views/Menu.vue";
import defaultMessages from "../views/defaultMessages.vue";
import agenda from "../views/agenda.vue";
import Messages from "../views/Messages.vue";
import Subscriptions from "../views/SubscriptionsOverview.vue";
import Account from "../views/Account.vue";
import Subscription from "../views/Subscription.vue";
import Conversion from "../views/Conversion.vue";
import DigitalAwareness from "../views/DigitalAwareness.vue";
import modelLibrary from "../views/modelLibrary.vue";
import Reports from "../views/Reports.vue";
import LeadCampaigns from "../views/LeadCampaigns.vue";
import LeadDashboard from "../views/LeadDashboard.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "Home - Thrive",
    },
  },
  {
    path: "/logPlayer",
    name: "logPlayer",
    component: logPlayer,
    meta: {
      title: "Log Player - Thrive",
    },
  },
  // {
  //   path: "/agenda",
  //   name: "Agenda",
  //   component: Calendar,
  //   meta: {
  //     title: "Agenda - Thrive"
  //   }
  // },

  {
    path: "/conversion",
    name: "conversion",
    component: Conversion,
    meta: {
      title: "Widget - Thrive",
    },
  },
  {
    path: "/posSettings",
    name: "posSettings",
    component: posSettings,
    meta: {
      title: "POS Settings - Thrive",
    }
  },
  {
    path: "/messages",
    alias: "/berichten",
    name: "messages",
    component: Messages,
    meta: {
      title: "Messages - Thrive",
    },
  },
  {
    path: "/digitalAwareness",
    name: "DigitalAwareness",
    component: DigitalAwareness,
    meta: {
      title: "Digital Awareness - Thrive",
    },
  },

  {
    path: "/models",
    name: "models",
    component: modelLibrary,
    meta: {
      title: "Models - Thrive",
    },
  },
  {
    path: "/hoofdmenu",
    alias: "/mainmenu",
    name: "menu",
    component: Menu,
    meta: {
      title: "Menu - Thrive",
    },
  },
  {
    path: "/emails",
    name: "emails",
    component: defaultMessages,
    meta: {
      title: "Emails - Thrive",
    },
  },

  {
    alias: "/abonnementen",
    path: "/subscriptions",
    name: "subscriptions",
    component: Subscriptions,
    meta: {
      title: "Abonnementen - Thrive",
    },
  },
  {
    path: "/subscription/:packageId?/:language?",
    name: "subscription",
    component: Subscription,
    meta: {
      title: "Abonnement Inschrijven - Thrive",
    },
  },
  // {
  //   path: "/agenda",
  //   name: "fullcalendar",
  //   component: FullCalender,
  //   meta: {
  //     title: "Agenda - Thrive",
  //   },
  // },
  {
    path: "/calendar",
    path: "/agenda",
    name: "agenda",
    component: agenda,
    meta: {
      title: "Agenda - Thrive",
    },
  },
  {
    path: "/clients",
    alias: "/clienten",
    children: [{ path: ":client", name: "client", component: Clients }],
    name: "clients",
    component: Clients,
    meta: {
      title: "Cliënten - Thrive",
    },
  },

  {
    path: "/reports",
    name: "reports",
    alias: "/rapportage",
    component: Reports,
    meta: {
      title: "Rapportage - Thrive",
    },
    beforeEnter(to, from, next) {
      let viewReports = false;
      if (store.state.userRules) {
        if (store.state.userRules.free && _.indexOf(store.state.userRules.free, "viewReports") >= 0) {
          viewReports = true; // Rule found in free
        } else {
          // Check if some user is logged in
          if (store.state.connectedEmployee) {
            // Employee is logged in
            if (store.state.activeUserRoleNumber < 4) {
              viewReports = true; // userRole OVERRULES the rules
            } else {
              if (store.state.userRules.restricted && _.indexOf(store.state.userRules.free, "viewReports") >= 0) {
                viewReports = true; // Rule found in restricted
              } else {
                viewReports = false;
              } // Rule NOT found in restricted
            }
          } else {
            viewReports = false;
          } // Employee is NOT logged in
        }
      } else {
        viewReports = true;
      } // No branch is logged in

      if (store.state.activeUserRoleNumber < 2 || viewReports) {
        console.log("Autorized to access this page");
        next();
      } else {
        console.log("Not autorized to access this page");
        next("/");
      }
    },
  },

  {
    path: "/account",
    name: "account",
    component: Account,
    meta: {
      title: "Account - Thrive",
    },
  },
  {
    path: "/leads-dashboard",
    name: "leadDashboard",
    component: LeadDashboard,
    meta: {
      title: "Leads Dashboard - Thrive"
    }
  },
  {
    path: "/campaigns",
    name: "leadCampaigns",
    component: LeadCampaigns,
    meta: {
      title: "Lead campaigns - Thrive"
    }
  },
  {
    path: "/paymentsettings",
    alias: "/betalingsinstellingen",
    name: "paymentSettings",
    component: Financials,
    meta: {
      title: "Financieel - Thrive",
    },
  },
  {
    path: "/inventory",
    alias: "/voorraadbeheer",
    name: "inventory",
    component: Inventory,
    meta: {
      title: "Voorraadbeheer - Thrive",
    },
    beforeEnter(to, from, next) {
      let updateInventory = false;
      // let currentUserRole = store.state.connectedEmployee ? store.state.connectedEmployee.userRole : store.state.userRole;
      // if (store.state.userRules) {
      //   if (store.state.userRules.free && _.indexOf(store.state.userRules.free, "updateInventory") >= 0) {
      //     updateInventory = true; // Rule found in free
      //     console.log("Rule found in free");
      //   } else {
      //     // Check if some user is logged in
      //     console.log("Check if some user is logged in");
      //     if (store.state.connectedEmployee) {
      //       // Employee is logged in
      //       console.log("Employee is logged in");
      //       if (store.state.activeUserRoleNumber < 4) {
      //         updateInventory = true; // userRole OVERRULES the rules
      //         console.log("userRole OVERRULES the rules");
      //       }
      //       else {
      //         if (store.state.userRules.restricted && _.indexOf(store.state.userRules.free, "updateInventory") >= 0) {
      //           updateInventory = true; // Rule found in restricted
      //           console.log("Rule found in restricted");
      //         } else {
      //           updateInventory = false;
      //           console.log("Rule NOT found in restricted");
      //         } // Rule NOT found in restricted
      //       }
      //     } else {
      //       updateInventory = false;
      //       console.log("Employee is NOT logged in");
      //     } // Employee is NOT logged in
      //   }
      // } else {
      //   updateInventory = false;
      //   console.log("No branch is logged in");
      // } // No branch is logged in

      if (store.state.activeUserRoleNumber < 3) {
        console.log("Autorized to access this page");
        next();
      }
      else {
        console.log("Not autorized to access this page");
        next("/");
      }
    },
  },
  {
    path: "/checkout",
    alias: "/kassa",
    name: "checkout",
    props: true,
    component: Pay,
    meta: {
      title: "Kassa - Thrive",
    },
  },
  {
    path: "/company-settings/branches",
    alias: "/bedrijfsinstellingen/vestigingen",
    name: "branches",
    component: BranchesSettings,
    meta: {
      title: "Vestigingen - Thrive",
    },
  },
  {
    path: "/devices",
    name: "deviceTypes",
    component: DeviceTypes,
    meta: {
      title: "Log - Thrive",
    },
  },
  {
    path: "/admin/users",
    alias: "/admin/gebruikers",
    name: "users",
    component: Users,
    beforeEnter(to, from, next) {
      if (store.state.activeUserRoleNumber < 2) {
        next();
      }
      else {
        console.log("Not autorized to access this page");
        next("/");
      }
    },
    meta: {
      title: "Gebruikers - Thrive",
    },
  },
  {
    path: "/log",
    name: "log",
    component: Log,
    beforeEnter(to, from, next) {
      if (store.state.activeUserRoleNumber < 4) {
        console.log("Autorized to access this page");
        next();
      }
      else {
        console.log("Not autorized to access this page");
        next("/");
      }
    },
    beforeRouteUpdate(to, from, next) {
      if (store.state.activeUserRoleNumber < 4) {
        next();
      }
      else {
        console.log("Not autorized to access this page");
        next("/");
      }
    },
    meta: {
      title: "Log - Thrive",
    },
  },
  {
    path: "/abilities",
    name: "abilities",
    component: Abilities,
    beforeEnter(to, from, next) {
      if (store.state.activeUserRoleNumber < 2){
        next();
      }
      else {
        console.log("Not autorized to access this page");
        next("/");
      }
    },
    meta: {
      title: "Vaardigheden - Thrive",
    },
  },

  {
    path: "/admin",
    redirect: "/admin/companies",
  },
  {
    path: "/admin/companies",
    alias: "/admin/bedrijven",
    name: "companies",
    component: Companies,
    beforeEnter(to, from, next) {
      if (store.state.activeUserRoleNumber < 2){
        next();
      }
      else {
        console.log("Not autorized to access this page");
        next("/");
      }
    },
    meta: {
      title: "Bedrijven - Thrive",
    },
  },
  {
    path: "/admin/settings",
    alias: "/admin/instellingen",
    name: "adminSettings",
    component: Admin,
    beforeEnter(to, from, next) {
      if (store.state.activeUserRoleNumber < 2){
        next();
      }
      else {
        console.log("Not autorized to access this page");
        next("/");
      }
    },
    meta: {
      title: "Admin - Thrive",
    },
  },

  {
    path: "/company-settings",
    alias: "/bedrijfsinstellingen",
    redirect: "/company-settings/company",
  },
  {
    path: "/company-settings/company/:tab?/:extra?",
    alias: "/bedrijfsinstellingen/bedrijf",
    name: "companysettings",
    component: Settings,
    meta: {
      title: "Instellingen - Thrive",
    },
    beforeEnter(to, from, next) {
      if (store.state.activeUserRoleNumber < 4){
        next();
      }
      else {
        console.log("Not autorized to access this page");
        // next("/");
      }
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Inloggen - Thrive",
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "Registreren - Thrive",
    },
  },
];

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
