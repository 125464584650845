<template>
  <v-dialog v-model="showDialog" scrollable persistent max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card style="height: 600px; overflow: hidden">
      <!-- / MAIN DIALOG CONTENT \ -->
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h2>Groepsles<span v-if="eventInfo">{{ eventInfo.title }} ({{eventTimes.start}} - {{eventTimes.end}})</span></h2>
        <v-spacer></v-spacer>
        <v-btn v-if="!loading" icon dark @click="closedialog(true)">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" :size="25" :width="3"></v-progress-circular>
      </v-card-title>

      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>
      
      <v-divider></v-divider>

      <v-card-text class="pa-0 ma-0" :style="{ background: $vuetify.theme.themes[theme].background }" style="overflow: hidden">
        <div v-if="!loading">
          <div class="pa-4 text-center">
            <h4 class="ma-0">
              <span v-if="bookingType == 'booking'"><strong>{{ eventInfo.subscriptions.length }}/{{ eventInfo.seats }} {{ $t("events.subscriptionsNumber", $store.state.locale) }}</strong></span>
              <span v-else><strong>0/{{ eventInfo.seats }} {{ $t("events.subscriptionsNumber", $store.state.locale) }}</strong></span>
            </h4>
            <v-btn fab small class="mt-2" v-if="registrationsList.length < eventInfo.seats" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="openNewSubscriptionModal()">
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-card flat>
            <v-data-table :headers="headers" :items="registrationsList" :items-per-page="-1" hide-default-footer item-key="index" :no-data-text="$t('events.noSubscriptionsFound', $store.state.locale)">
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>{{ item.name }} {{ item.surname }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>
                    <v-btn icon @click="removeClientSubscriptionDialog(item, index)">
                      <v-icon small color="text">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>

          <!--  ***** / WORKING ALGOLIA EXAMPLE WITH COMPANY ID FILTER  \ *****
            <v-text-field
              dense
              outlined
              background-color="background"
              hide-details
              v-model="testSven"
            ></v-text-field>

             <ais-instant-search :search-client="searchClient" index-name="thrive">
              <v-list  style="max-height: 200px; overflow-y: scroll">
                <ais-hits class="pa-0 ma-0">
                  <template slot="item" slot-scope="{ item }">
                    <v-list-item class="pa-0 ma-0">
                      <v-list-item-content class="pa-0 ma-0">
                        <v-list-item-title>{{ item.name }} {{ item.surname }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.companies }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </ais-hits>
              </v-list>
            <ais-configure
              :query="testSven"
              :facetFilters=" [`companies:${this.activeCompData.id}`]"
            />
            </ais-instant-search>

          ***** \ WORKING ALGOLIA EXAMPLE WITH COMPANY ID FILTER  / ***** -->
        </div>

        <div v-else style="position: relative; padding: 200px 0">
          <div class="thrive-loader" :class="$store.state.companyTheme"></div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left">
                <v-btn v-if="!loading" outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closedialog()">
                   {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn :depressed="(!eventChanged || loading)" :disabled="(!eventChanged || loading)" :dark="eventChanged" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="saveExistingEvent()">
                <div v-if="eventChanged">{{ $t('general.saveChanged', userLanguage) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div  v-else> {{ $t('general.saved', userLanguage) }} <v-icon right >mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
      <!-- \ MAIN DIALOG CONTENT / -->  

      <!-- / SUBSCRIBE CLIENT DIALOG \ -->  
      <v-dialog v-model="addClientSubscriptionModal" max-width="500px" hide-overlay scrollable persistent :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card style="height: 700px">
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h2>{{ $t('clients.subscribeClient', $store.state.locale) }}</h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="(addClientSubscriptionModal = false), (selectedClient.selected = false), (newclient = false)">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-2 py-4 ma-0">
            <!-- / Create New Client \ -->
            <v-card flat color="background" class="text-center pa-2">
              <div v-if="!newclient">
                <h2 class="pa-2"><strong> {{ $t('clients.createNewClient', $store.state.locale) }} </strong></h2>
                <p class="ma-0">{{ $t('clients.chooseGender', $store.state.locale) }}? </p>
                <div class="pa-4 text-center">
                  <v-btn
                    depressed
                    class="ma-1"
                    style="width: 150px"
                    v-if="!newclient"
                    @click="
                      newclient = true;
                      newClientData.gender = 2;
                    "
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    rounded
                    dark
                    >{{ $t('general.woman', $store.state.locale) }} </v-btn
                  >
                  <v-btn
                    depressed
                    class="ma-1"
                    style="width: 150px"
                    v-if="!newclient"
                    @click="
                      newclient = true;
                      newClientData.gender = 1;
                    "
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    outlined
                    rounded
                    >{{ $t('general.man', $store.state.locale) }} </v-btn
                  >
                </div>
              </div>
              
              <div v-show="newclient" class="text-center">
                <h2 class="pa-2"><strong>{{ $t('clients.createNewClient', $store.state.locale) }} </strong></h2>
                <p class="ma-0">{{ $t('clients.clientBasicInfo', $store.state.locale) }} </p>

                <v-form ref="form" v-model="newClientData.valid">
                  <v-container grid-list-md fluid class="ma-0 pa-0">
                    <v-layout row wrap class="ma-0 pa-0">
                      <v-flex md12>
                        <v-text-field 
                          :label="$t('general.firstName', $store.state.locale)" 
                          class="mt-4" 
                          hide-details v-model="newClientData.name" 
                          :rules="newClientData.nameRules" 
                          outlined 
                          dense
                          background-color="menu" 
                          required>
                        </v-text-field>
                      </v-flex>
                      <v-flex md12>
                        <v-text-field
                          outlined
                          background-color="menu"
                          :label="$t('general.lastName', $store.state.locale)"
                          class="mt-4"
                          dense
                          hide-details
                          v-model="newClientData.surname"
                          :rules="newClientData.surnameRules"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex md12>
                        <v-text-field 
                          dense 
                          outlined 
                          class="mt-4" 
                          background-color="menu" 
                          :label="$t('general.email', $store.state.locale)" 
                          v-model="newClientData.email" 
                          :rules="newClientData.emailRules" 
                          hide-details 
                          required>
                        </v-text-field>
                      </v-flex>
                      <v-flex md12>
                        <v-select
                          dense
                          :items="genders"
                          item-text="name"
                          item-value="id"
                          v-model="newClientData.gender"
                          :label="$t('clients.selectGender', $store.state.locale)"
                          outlined
                          class="mt-4"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="menu"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        ></v-select>
                      </v-flex>
                      <v-flex md12>
                        <v-checkbox class="pa-0 ma-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  style="font-size: 14px" :label="$t('clients.sendClientConfirmation', $store.state.locale)" v-model="confirmEmail" hide-details></v-checkbox>
                      </v-flex>
                      <v-flex md12>
                        <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  :disabled="!showSubscribeNewClientButton" :depressed="!showSubscribeNewClientButton" :dark="showSubscribeNewClientButton" block @click="createClient()"
                          >{{ $t('clients.createAndSubscribeClient', $store.state.locale) }}</v-btn
                        >
                      </v-flex>

                      <v-flex md12 v-if="existingClientData && !showSubscribeNewClientButton">
                        <v-card outlined color="secondary">
                          <v-card-text class="ma-0 text-center">
                            <div v-if="!existingClientSubscribed">
                              <h3 class="white--text"><v-icon color="white">mdi-information</v-icon> <strong>{{ $t('clients.clientExist', $store.state.locale) }}</strong></h3>
                              <v-btn class="mt-3" depressed block color="white" @click="subscribeClient()"
                                ><span class="pr-2"
                                  ><strong>{{ existingClientData.name }} {{ existingClientData.surname }}</strong>
                                </span>
                                {{ $t('clients.subscribe', $store.state.locale) }}</v-btn
                              >
                            </div>
                            <div v-else>
                              <h3 class="white--text"><v-icon color="white">mdi-information</v-icon> <strong>{{ $t('clients.clientExistAndSubscribed', $store.state.locale) }}</strong></h3>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </div>
            </v-card>
            <!-- \ Create New Client / -->

            <h3 class="pa-3 text-center"><strong>{{ $t('clients.or', $store.state.locale) }}</strong></h3>

            <!-- / Select Existing Client \ -->  
            <div class="pa-2 text-center" :style="{ background: $vuetify.theme.themes[theme].background }">
              <div class="client-info-input-wrapper">
                <h3><strong>{{ $t('clients.searchExistingClient', $store.state.locale) }}</strong></h3>
                <p style="margin: 0 0 6px 0">{{ $t('clients.addExtraInfoToClient', $store.state.locale) }}</p>

                <v-text-field outlined v-model="search" dense name="searchfield" :label="$t('general.search', $store.state.locale)" append-icon="mdi-magnify" single-line hide-details background-color="menu"></v-text-field>

                <v-card outlined color="background" style="max-height: 180px; overflow-y: scroll" class="custom-algolia">
                  <ais-instant-search :search-client="searchClient" index-name="thrive_clients" class="pa-0 ma-0">
                    <v-list class="pa-0 ma-0 ">
                      <v-list-item-group
                        v-model="selectedClient.selectIndex"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      >
                        <ais-hits class="pa-0 ma-0">
                          <template slot="item" slot-scope="{ item }" >
                            <v-list-item 
                              class="pa-2 py-1 ma-0 align-left" 
                              @click="selectedClientForSubscription(item)"
                              :key="item.objectID"
                              >
                              <v-list-item-avatar color="#f1f1f1">
                                <v-icon>mdi-account</v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content class="text-left">
                                <v-list-item-title>
                                  <ais-highlight
                                    attribute="name"
                                    :hit="item"
                                    highlightedTagName="mark"
                                  />&nbsp;
                                  <ais-highlight
                                    attribute="surname"
                                    :hit="item"
                                    highlightedTagName="mark"
                                  />

                                  <v-chip class="ml-1" x-small  v-if="item.gender"> {{ getGender(item.gender) }} </v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <ais-highlight
                                    attribute="email"
                                    :hit="item"
                                    highlightedTagName="mark"
                                  />
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  <span v-if="item.phone"><strong>{{ item.phone }}</strong></span>
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-icon>mdi-chevron-right</v-icon>
                              </v-list-item-action>
                            </v-list-item>
                            
                          </template>
                        </ais-hits>
                      </v-list-item-group>
                    </v-list>
                    <ais-state-results>
                      <p 
                        class="text-center pa-2 pt-4 pb-1"
                        slot-scope="{ state: { query }, results: { hits } }"
                        v-show="!hits.length"
                      >
                        <i> {{ $t('clients.noResultsFoundFor', $store.state.locale) }} {{ query }}</i>
                      </p>
                    </ais-state-results>
                  <ais-configure
                    :query="search"
                    :facetFilters=" [`companies:${this.activeCompData.id}`]"
                  />
                  </ais-instant-search>

                  <!-- <v-list v-if="!clientsLoading" class="pa-0 ma-0">
                    <v-list-item-group
                      v-model="selectedClientSearchIndex"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    >
                      <v-list-item
                        v-for="(client, index) in filteredClients"
                        :key="index"
                        class="pa-0 ma-0 search-client-list"
                        @click="selectedClientForSubscription(client, index)"
                        :disabled="client.disabled"
                      >
                        <v-list-item-content class="custom-list-item-content py-0 ma-0">
                          <v-list-item-title><v-icon v-if="client.disabled" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-checkbox-marked-circle</v-icon> {{ client.name }} {{ client.surname }} </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <div v-else style="position: relative; padding: 50px 0">
                    <div class="thrive-loader"></div>
                  </div> -->
                </v-card>

                <v-checkbox :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  :label="$t('clients.sendClientConfirmation', $store.state.locale)"  v-model="confirmEmail" hide-details class="pb-2"></v-checkbox>
              </div>
              <v-btn :disabled="!selectedClient.selected" :dark="selectedClient.selected" depressed block class="ma-1" style="width: 150px" @click="subscribeClient()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                <div v-if="selectedClient.selected">
                  <strong>{{ selectedClient.name }} {{ selectedClient.surname }}</strong> {{ $t('events.subscribe', $store.state.locale) }}
                </div>
                <div v-else>{{ $t('clients.subscribeClient', $store.state.locale) }}</div>
              </v-btn>
            </div>
            <!-- \ Select Existing Client / -->  
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- \ SUBSCRIBE CLIENT DIALOG / -->  

      <!-- / REMOVE SUBSCRIPTION DIALOG \ -->
      <v-dialog v-model="removeSubscription.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
          <v-card-title class="pr-2" :style="{ backgroundColor: $vuetify.theme.themes[theme].primary }">
            <h2 class="white--text">{{ $t('events.removeSubscription', $store.state.locale) }}  </h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="removeSubscription.dialog = false">
              <v-icon dark color="white">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <span v-if="removeSubscription.item" style="display: block">{{ $t('events.removeSubscriptionOf', $store.state.locale) }}  {{ removeSubscription.item.name }} {{ this.removeSubscription.item.surname }}?</span>
            <v-checkbox :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  :label="$t('events.sendConfirmationToClient', $store.state.locale)" v-model="removeSubscription.mail" hide-details class="pb-2"></v-checkbox>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="removeSubscription.dialog = false">
                    {{ $t("general.cancel", $store.state.locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeClientSubscription()">
                    {{ $t("general.unsubscribe", $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- \ REMOVE SUBSCRIPTION DIALOG / -->
    </v-card>
  </v-dialog>
</template>

<script>
  import { bus } from "../../main";
  import moment from "moment";
  import { extendMoment } from "moment-range";
  import axios from "axios";

  import lodash from "lodash";
  import db from "../../firebase/init";
  import { log } from "util";
  import algoliasearch from 'algoliasearch/lite';
  import 'instantsearch.css/themes/satellite-min.css';

  export default {
    props: ["activeBranch", "activeCompData"],

    data() {
      return {
        // Algolia Test Data
        searchClient: algoliasearch(
          'B4XU2Z5BAW',
          'a29d0e9160e36dc8ee83d5888c28cdcc'
        ),
    
        loading: false,
        showDialog: false,
        eventInfo: new Object(),
        bookingType: null,
        eventTimes: {
          start: null,
          end: null
        },
        eventBookingId: null,
        selectedClientSearchIndex: 0,
        
        feedback: null,

        genders: [
          { name: this.$t('general.man', this.$store.state.locale), id: 1 },
          { name: this.$t('general.woman', this.$store.state.locale), id: 2 },
          { name: this. $t('general.other', this.$store.state.locale), id: 3 },
        ],

        //Inschrijvingen
        addClientSubscriptionModal: false,
        selected: [],
        registrationsList: [],
        registrationPost: [],

        // Client Modal
        newclient: false,
        clients: [],
        clientsLoading: false,
        clientsLoaded: false,
        selectedClient: {
          selected: false,
        },
        confirmEmail: true, //Client wilt bevestinging ontvangen

        newClientData: {
          valid: false, //Zijn persoongevens goed ingevuld?
          gender: 0,
          name: null,
          nameRules: [(v) => !!v || this.$t('general.required', this.$store.state.locale), (v) => (v && v.length > 1) || this.$t('general.required', this.$store.state.locale)],
          surname: null,
          surnameRules: [(v) => !!v || this.$t('general.required', this.$store.state.locale), (v) => (v && v.length > 1) || this.$t('general.required', this.$store.state.locale)],
          email: "",
          emailRules: [(v) => !!v || this.$t('general.required', this.$store.state.locale), (v) => (v && v.length > 3) || this.$t('general.required', this.$store.state.locale), (v) => /.+@.+\..+/.test(v) || this.$t('general.required', this.$store.state.locale)],
        },
        validatedEmail: null,
        clientExistsInDatabase: false,
        clientExistsInCompany: false,
        existingClientData: null,
        existingClientSubscribed: false,

        removeSubscription: {
          index: null,
          item: null,
          loading: false,
          dialog: false,
          mail: true,
        },
        search: "",
        pagination: {
          // sortBy: "name",
          rowsPerPage: 10,
          descending: false,
        },
        headers: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            sortable: false,
            value: "name",
          },

          {
            text: this.$t('general.email', this.$store.state.locale),
            align: "left",
            value: "email",
          },

          {
            text: this.$t('general.phone', this.$store.state.locale),
            align: "left",
            value: "phone",
          },
          {
            text: "",
            align: "right",
            value: "unsubscribe",
            width: "30px",
          },
        ],
      };
    },

    mounted() {
      bus.$on("openSubscription", (type, info, id) => { // Executed when click on Booking Subscription in the Calendar
        this.registrationsList = new Array();
        this.loading = true;
        this.showDialog = true;
        this.eventInfo = info;
        if (type == "computed") { // Booking Subscription has NO doc in database
          this.bookingType = 'computed';
          this.eventBookingId = null;
          this.eventTimes.start = moment(this.eventInfo.start).format("HH:mm");
          this.eventTimes.end = moment(this.eventInfo.end).format("HH:mm");
          this.loading = false;
        }
        else { // Booking Subscription has doc in database
          this.eventBookingId = id;
          this.bookingType = 'booking';
          this.eventTimes.start = this.eventInfo.startTime;
          this.eventTimes.end = this.eventInfo.endTime;
          let counter = 0;
          new Promise((resolve)=>{ // Get data of subscribed clients
            if(this.eventInfo.subscriptions.length > 0){
            this.eventInfo.subscriptions.forEach(client=>{
              db.collection("clients").doc(client.clientId)
              .get()
              .then((doc) => {
                let clientData;
                if (doc.exists) {
                  clientData = doc.data();
                  clientData.id = doc.id;
                }
                else{ // Create client data (no client doc found)
                  clientData = {
                    name: client.clientId,
                    surname: null,
                    email: null,
                    phone: null,
                    id: client.clientId
                  };
                }
                this.registrationsList.push(clientData);
                counter++
                if(counter == this.eventInfo.subscriptions.length){
                  resolve();
                }
              })
              .catch(error=>{
                counter++
                if(counter == this.eventInfo.subscriptions.length){
                  resolve();
                }
                console.error("Error getting data og subscribed client: ", error)
              })
            })
          }
          else{
            resolve()
          }
          })
          .then(()=>{
            this.loading = false;
          })
        }
      });
    },

    methods: {
      resetModal(){ // Empty dialog data
        this.loading = true;
        this.eventInfo = new Object();
        this.bookingType = null;
        this.eventTimes = {
          start: null,
          end: null
        };
      },

      removeClientSubscriptionDialog(item, index) { // Show Dialog for unsubscribe confirmation
        this.removeSubscription.item = item;
        this.removeSubscription.registrationsListIndex = index;
        this.removeSubscription.eventInfoSubscriptionsIndex = _.indexOf(this.eventInfo.subscriptions, item.id);;
        this.removeSubscription.dialog = true;
        this.removeSubscription.mail = true;
      },

      removeClientSubscription() { // Remove client from subscriptions and save doc in database
        this.removeSubscription.loading = true;
        this.registrationsList.splice(this.removeSubscription.registrationsListIndex, 1);
        this.eventInfo.subscriptions.splice(this.removeSubscription.eventInfoSubscriptionsIndex, 1);
         
        new Promise((resolve)=>{
          if(this.eventInfo.subscriptions.length > 0){
            db.collection("bookingen")
            .doc(this.eventBookingId)
            .set({
                subscriptions: this.eventInfo.subscriptions,
                availableSeats: this.eventInfo.seats - this.eventInfo.subscriptions.length
                },
              { merge: true }
            )
            .then(() => {
              if(this.removeSubscription.mail) {
                axios
                //.get(`${this.$store.state.messagesApi.url}/cancelSubscription?doc=${this.eventBookingId}&activebranch=${this.activeBranch}&clientId=${this.removeSubscription.item.id}&lang=nl`)
                .post(`${this.$store.state.messagesApi.url}/sendMessage`, { doc: this.eventBookingId, branchId: this.activeBranch, type: "eventCancel", clientId: this.removeSubscription.item.clientId })
                .then((response) => {
                  if(response) { 
                    console.log("Email cancelSubscription send")
                    // Email succesfully sent! 
                  }
                  resolve()
                })
                .catch((error) => {
                  resolve()
                  console.error(error.message);
                });
              }
            })
            .catch((error) => {
              console.error("Error updating Booking Subscription Document: ", error);
            });
          }
          else{ // No subscriptions, remove Document from Database
            db.collection("bookingen").doc(this.eventBookingId).delete()
            .then(()=>{
              this.bookingType = 'computed';
              this.eventBookingId = null;
              this.eventInfo.backgroundColor = this.eventInfo.backgroundColor + "60";
              this.eventInfo.start = this.eventInfo.dateBooking + "T" + this.evenTimes.start;
              this.eventInfo.end = this.eventInfo.dateBooking + "T" + this.evenTimes.end;
              resolve()
            })
            .catch((error) => {
              resolve()
              console.error(error.message);
            });
          }
        })
        .then(()=>{
          this.removeSubscription = {
            index: null,
            item: null,
            loading: false,
            dialog: false,
            mail: true,
          };
        }) 
      },

      openNewSubscriptionModal() { // Show dialog to create new client or select existing client
        this.selectedClientSearchIndex = null;
        this.selectedClient = {};
        this.addClientSubscriptionModal = true;
        // Clients maar 1x binnenhalen per Event modal
      },

      selectedClientForSubscription(client, index) {
        this.selectedClient = {};
        this.selectedClient.selected = true;
        this.selectedClient.id = client.objectID;
        this.selectedClient.name = client.name;
        this.selectedClient.surname = client.surname ? client.surname : null;
        this.selectedClient.phone = client.phone ? client.phone : null;
        this.selectedClient.email = client.email ? client.email : null;
      },

      subscribeClient() { // Add client to subscriptions and save doc in database, or create new doc
        this.eventInfo.subscriptions.push({clientId:this.selectedClient.id, group: null});
        this.registrationsList.push(this.selectedClient);
        this.addClientSubscriptionModal = false;
        this.selectedClient.selected = false;

        this.clientExistsInDatabase = false;
        this.clientExistsInCompany = false;
        this.existingClientData = null;
        this.existingClientSubscribed = false;
        this.newClientData.gender = 2;
        (this.newClientData.name = null), (this.newClientData.surname = null), (this.newClientData.email = null), (this.newclient = false);

        new Promise((resolve)=>{
          if(this.bookingType == 'booking'){
            db.collection("bookingen")
            .doc(this.eventBookingId)
            .update({
              subscriptions: this.eventInfo.subscriptions,
              availableSeats: this.eventInfo.seats - this.eventInfo.subscriptions.length,
            })
            .then(() => {
              console.log("eventInfo", this.eventInfo);
              resolve()
            })
            .catch((error) => {
              console.error("Error updating Booking Subscription Document: ", error);
            });
          }
          else{ // Create new document for Booking Subscriptions
            db.collection("bookingen")
            .add({
              availableSeats: this.eventInfo.seats - this.eventInfo.subscriptions.length,
              backgroundColor: this.eventInfo.backgroundColor.slice(0, -2),
              branch: this.activeBranch,
              companyId: this.activeCompData.id,
              dateBooking: moment(this.eventInfo.start).format("YYYY-MM-DD"),
              duration: moment.duration(moment(this.eventInfo.end).diff(moment(this.eventInfo.start))).asMinutes(),
              endTime: this.eventTimes.end,
              optimize: true,
              described: false,
              resourceDocId: this.eventInfo.resourceDocId,
              resourceId: this.eventInfo.resourceId,
              seats: this.eventInfo.seats,
              startTime: this.eventTimes.start,
              subscriptions: this.eventInfo.subscriptions,
              title: this.eventInfo.title,
              treatmentId: this.eventInfo.treatmentId,
              type: "bookingSubscription"
            })
            .then((docRef) => {
              this.bookingType = 'booking'; // Change booking type for new subscriptions
              this.eventBookingId = docRef.id; // Save Doc Id for new subscriptions
              resolve()
            })
            .catch((error) => {
              console.error("Error creating Booking Subscription Document: ", error);
            });
          }
        })
        .then(()=>{
          if (this.confirmEmail) {
            axios
              // .get(`${this.$store.state.messagesApi.url}/confirmSubscription?doc=${this.eventBookingId}&activebranch=${this.activeBranch}&clientId=${this.selectedClient.id}&lang=nl`)
              .get(`${this.$store.state.messagesApi.url}/sendMessage?doc=${this.eventBookingId}&branchId=${this.activeBranch}&type=eventConfirm&clientId=${this.selectedClient.id}`)
              .then((response) => {
                if (response) {
                  console.log("Email confirmSubscription send")
                  //Email succesfully sent!
                }
              })
              .catch((error) => {
                console.error(error.message);
              });
          }
          this.$emit("triggerRefetch"); // Refesh Calendar
        })
      },

      async createClient() { // Create or update client with the Client Check API
        const configAxiosPOST = {
          headers: {"Content-Type": "application/json"},
        };

        let jsonOBJ = {
          companyId: this.activeCompData.id,
          clientEmail: this.newClientData.email,
          clientFirstName: this.newClientData.name,
          clientLastName: this.newClientData.surname,
          clientPhone: " ",
        };

        await new Promise((resolve) => {
          axios
            .post(`${this.$store.state.restApi.url}/postClientExist`, jsonOBJ, configAxiosPOST)
            .then((response) => {
              console.log(response.data);
              // Client does not exist
              if (response.data.typeExist == 2) {
                // Create Client
                let companies = [this.activeCompData.id];
                db.collection("clients")
                  .add({
                    name: this.newClientData.name,
                    surname: this.newClientData.surname,
                    email: this.newClientData.email,
                    companies: companies,
                    gender: this.newClientData.gender,
                    emailSubscriptions: {
                      newsletter: false,
                    },
                    health: {
                      macronutrients: {},
                    },
                    address: {},
                    permissions: {},
                  })
                  .then((docRef) => {
                    this.newClientData.id = docRef.id;
                    console.log("Client check finished");
                    resolve();
                  });
              }

              // Client bestaat al, maar nog niet bij dit bedrijf
              else if (response.data.typeExist == 1 && response.data.companySet == false) {
                // Set company in client doc
                let companies = null;
                db.collection("clients")
                  .doc(response.data.clientId)
                  .get()
                  .then((doc) => {
                    companies = doc.data().companies;
                    companies.push(this.activeCompData.id);
                  })
                  .then(() => {
                    db.collection("clients").doc(response.data.clientId).set(
                      {
                        companies: companies,
                      },
                      { merge: true }
                    );
                  })
                  .then(() => {
                    this.newClientData.id = response.data.clientId;
                    console.log("Client check finished");
                    resolve();
                  });
              }

              // Client bestaat al, ook bij dit bedrijf
              else if (response.data.typeExist == 0 && response.data.companySet == true) {
                // Set client Id
                this.newClientData.id = response.data.clientId;
                console.log("Client check finished");
                resolve();
              }
            })
            .catch((err) => {
              console.error("Error axios postClientExist =>", err);
              resolve();
            });
        })
        .then(() => { // Cliënt inschrijven
          this.clients.push(this.newClientData);
          this.selectedClient = _.cloneDeep(this.newClientData);
          console.log("this.selectedClient: ", this.selectedClient);
          console.log("this.newClientData: ", this.newClientData);
          this.subscribeClient();
        });
      },

      getGender(gender){
        if(gender == 2){
          return this.$t('general.woman', this.$store.state.locale)
        }
        else if(gender == 1){
          return this.$t('general.man', this.$store.state.locale)
        }
        else{
          return this.$t('general.other', this.$store.state.locale)
        }
      },

      closedialog() {
        this.showDialog = false;
        this.resetModal();
      },
    },

    computed: {
      showSubscribeNewClientButton() {
        if (this.newClientData.valid && this.newClientData.email != this.validatedEmail) {
          return true;
        } else {
          return false;
        }
      },

      eventChanged() {
        return false;
      },

      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },

      filteredClients() {
        let availableClients = [];
        this.clients.forEach((clientInList) => {
          let index = _.findIndex(this.registrationsList, {'id': clientInList.id });
          clientInList.disabled = index >= 0 ? true : false; // Disable already registered clients
          availableClients.push(clientInList);
        });

        return availableClients.filter((client) => {
          let clientName = client.name + " " + client.surname;
          let clientEmail = "";
          if (client.email) {
            clientEmail = client.email;
          }
          return clientName.toLowerCase().includes(this.search.toLowerCase()) || clientEmail.toLowerCase().includes(this.search.toLowerCase());
        });
      },
    },
  };
</script>
<style>
  .search-client-list {
    min-height: 44px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: left;
  }
</style>
