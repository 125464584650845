<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>Bedrijf bewerken</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeEditCompClientDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text>
      <v-form  ref="editCompForm" lazy-validation>
        <v-text-field
          label="Bedrijfsnaam"
          v-model="selectedCompany.name"
          autocomplete
          prepend-inner-icon="mdi-domain"
          required
          dense
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>
        <v-text-field
          label="Email"
          v-model="selectedCompany.email"
          :rules="emailRules"
          autocomplete
          required
          dense
          prepend-inner-icon="mdi-email"
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>
        <v-text-field
          label="Telefoonnummer"
          v-model="selectedCompany.phone"
          prepend-inner-icon="mdi-phone"
          required
          dense
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>
        <v-text-field
          label="KvK-nummer"
          v-model="selectedCompany.coc"
          autocomplete
          required
          dense
          prepend-inner-icon="mdi-web"
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>
         <v-text-field
          label="BTW-nummer"
          v-model="selectedCompany.vat"
          autocomplete
          required
          dense
          prepend-inner-icon="mdi-web"
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="background"
        ></v-text-field>
      </v-form>

      <!-- CONTACT -->
      <h3 class="client-overview-subtitle pt-4 pb-2">Adresgegevens</h3>  
      <v-container grid-list-md class="pa-0" fluid>
        <v-layout row wrap>

          <v-flex xs8>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="Straat"
              v-model="selectedCompany.address.street"
              outlined
              dense
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>

          <v-flex xs4>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="huisnummer"
              v-model="selectedCompany.address.housenr"
              outlined
              dense
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>

          <v-flex xs4>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="Postcode"
              v-model="selectedCompany.address.postalCode"
              outlined
              dense
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>

          <v-flex xs8>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="Plaats"
              v-model="selectedCompany.address.city"
              outlined
              dense
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              class="ma-0 pa-0 mt-1"
              label="Land"
              v-model="selectedCompany.address.country"
              outlined
              dense
              background-color="background"
              hide-details
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
              @click="closeEditCompClientDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              :disabled=" !changed" 
              :depressed="!changed"  
              :dark=" changed"  
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              @click="updateCompany()"
              >
              <div v-if="changed">{{ $t('general.saveChanged', userLanguage) }} <v-icon right >mdi-chevron-right</v-icon></div>
              <div v-else> {{ $t('general.saved', userLanguage) }} <v-icon right >mdi-check</v-icon></div>   
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>


<script>
import db from "../../firebase/init";
import '@firebase/firestore';

export default {
  name: "edit-comp-form",
  props: {
    selectedCompany: Object,
    selectedCompanyOriginal: Object
  },

  data() {
    return {
      feedback: null,
      emailRules: [
        v => !!v || "E-mail is verplicht",
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid"
      ],
    };
  },

  methods: {
    closeEditCompClientDialog() {
      this.$emit("editCompClientDialogChange", false);
    },

    updateCompany() {
      db.collection("companyClients")
        .doc(this.selectedCompany.id)
        .set({
          name: this.selectedCompany.name,
          email: this.selectedCompany.email,
          phone: this.selectedCompany.phone,
          coc: this.selectedCompany.coc,
          vat: this.selectedCompany.vat,
          address: this.selectedCompany.address
        }, { merge: true })
        .then(() => {
          this.$emit("updateData");
          this.closeEditCompClientDialog();
        })
        .catch(error => {
          console.error("Error updating Company: ", error);
        });
    }
  },
  computed: {
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },
    changed() {
      if (
        _.isEqual(this.selectedCompany, this.selectedCompanyOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>
