<template>
  <div>
    <div v-show="loading" style="position: relative; padding: 200px 0;">
      <div class="thrive-loader"></div>
    </div>

    <div v-show="!loading">
      <v-card v-show="tags.length > 0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" >
        <v-container grid-list-md class=" ma-0 pa-3" fluid>
          <v-layout row wrap>
            <v-flex md4 xs12>
              <v-text-field
                class="mx-1"
                v-model="search"
                name="searchfield"
                :label="$t('general.search', $store.state.locale)"
                background-color="background"
                prepend-inner-icon="mdi-magnify"
                single-line
                dense
                hide-details
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex md8 xs12 class="text-right">
              <v-btn 
              small
                dark
                height="36" 
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addTag()" 
                class="mx-1"
                >
                <v-icon left>mdi-plus</v-icon> {{ $t('tags.newTag', $store.state.locale) }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider> </v-divider>
        
        <v-data-table
          :headers="headersTags"
          :items="tags"
          :search="search"
          item-key="name"
          class="dense-table rooms-table"
          hide-default-footer
          dense
          :itemsPerPage.sync="pages"
          :no-results-text="`${$t('general.noResultsFoundFor', $store.state.locale)} ${search}`"
          > 
          <template v-slot:item="{item, index}">
            <tr>

              <td>
                <v-chip small :color="$themes[$store.state.companyTheme][$store.state.themeModus][item.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][item.color]" light class="mr-2 my-1 custom-chip-style" >
                  {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) }} 
                </v-chip>
                <div>
                  <v-chip v-if="!$store.state.demoMode && $store.state.userRoleNumber == 0" dark label small  light class="mr-2 mb-1 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="copyVariableToClipboard( item.id, index )">
                    <span v-if="!item.copy">{{ item.id }}</span><span v-else>{{ $t('emails.copied', $store.state.locale)}}</span><v-icon v-if="!item.copy" right size="12">mdi-content-copy</v-icon>
                  </v-chip>
                </div>
              </td>
              <td> 
                {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.description) }} 
              </td>
              <td class="text-center">
                <v-btn class="mr-1" icon @click="editTag(item)">
                  <v-icon small color="text">mdi-pencil</v-icon>
                </v-btn>
                <v-btn  @click="showDeleteDialog(item.id, 'deleteTag')" icon>
                  <v-icon small color="text">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <div class="no-data-message text-center" v-if="tags.length == 0">
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-tag</v-icon>
        </v-avatar>
        <p>{{ $t('tags.noTagsAddedForBranch', $store.state.locale) }} {{ $store.state.activeCompany.name }}</p>
        <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addTag()">
          <v-icon left >mdi-plus</v-icon> {{ $t('tags.newTag', $store.state.locale) }}
        </v-btn>
      </div>
    </div>

    <!-- / Tag Form Dialog \ -->
    <tag-form @updateData="loadTags()"></tag-form>
    <!-- \ Tag Form Dialog / -->

  </div>
</template>

<script>
  import '@firebase/firestore';
  import db from "../firebase/init";
  import { bus } from "../main";
  import TagForm from "../components/modals/TagForm.vue";
  import moment from "moment";

  export default {
    components: {
      "tag-form": TagForm,
    },
    name: "tags",
    data(){
      return {

        tags: new Array(),

        watcher: 0,
        selected: new Array(),
        search: "",
        pages: -1,
        loading: true,

        headersTags: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "name",
            width: "250px"
          },
          {
            text: this.$t('general.description', this.$store.state.locale),
            align: "left",
            value: "description",
            sortable: false
          },
          {
            text: "",
            align: "right",
            value: "actions",
            sortable: false,
            width: "110px"
          }
        ],
        paginationRooms: {
          sortBy: "tagName",
          descending: false
        }
      }
    },

    created() {
      bus.$on("deleteTag", (id) => {this.deleteTag(id) });
      this.loadData();
    },

    methods: {
      loadData() {
        this.loadTags();
      },

      activeColor(active){ // Set color green for active or red for inactive
        return active ? this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].quaternary : this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary; 
      },

      copyVariableToClipboard(text, index){
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(()=> {
          this.tags.forEach(room =>{
            room.copy = false;
          })
          this.tags[index].copy = true;
          setTimeout(()=>{ 
            this.tags[index].copy = false;
          }, 1000);

        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      },

      async loadTags() {
        this.loading = true;
        this.tags = new Array();
        await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("tags")
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let item = doc.data();
            item.id = doc.id;
            this.tags.push(item);
          });
        })

        this.tags.sort((a, b) => this.$autoLanguageSelector(this.$store.state.nativeLanguage, this.$store.state.companyLanguages, a.name).localeCompare(this.$autoLanguageSelector(this.$store.state.nativeLanguage, this.$store.state.companyLanguages, b.name))),


        this.loading = false;
      },

      addTag(){
        bus.$emit("tagModal", null, 'new');
      },

      editTag(item){
        bus.$emit("tagModal", item, 'edit');
      },
      
      showDeleteDialog(id, deleteFunction){
        let deleteInformation = new Object();
        deleteInformation.id = id;
        deleteInformation.emit = deleteFunction;

        deleteInformation.title = this.$t('tags.removeTag', this.$store.state.locale);
        deleteInformation.info = this.$t('tags.removeTagInfo', this.$store.state.locale);
  
        bus.$emit('deletedModal', deleteInformation, 'open');
      },

      async deleteTag(id) {
        await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("tags")
        .doc(element.id)
        .set({
          deleted: {
            deleted: true,
            deletedTime: new Date(),
            deletedBy: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`
          }
        }, {merge: true})
        bus.$emit('deletedModal', null, 'close');
        this.loadTags();
      },
    },
  };
</script>