<template>
  <div>
    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>
    <div class="no-data-message" v-if="loading == false && companies.length == 0">
      <p>Er zijn nog geen bedrijven toegevoegd.</p>
      <v-btn 
        dark
        height="40px"
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
        @click="newCompDialog = true"
        class="mx-1"
        >
        {{ $t('admin.newCompany', $store.state.locale) }}
      </v-btn>
    </div>

    <v-card v-if="!loading && companies.length > 0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
      <v-card-title>
        <v-text-field
          style="max-width: 300px;"
          v-model="search"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :placeholder="$t('general.search', $store.state.locale)"
          outlined
          dense
          hide-details                                         
          prepend-inner-icon="mdi-magnify"
          autocomplete="disabled"
          clearable>
        </v-text-field>
        <v-spacer></v-spacer>
        <v-btn 
          dark
          height="40px"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          @click="newCompDialog = true"
          class="mx-1"
          >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('admin.newCompany', $store.state.locale) }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="companies"
        :items-per-page="companyPages"
        :search="search"
        single-expand
        show-expand
        dense
        item-key="name"
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
        :no-results-text="`${$t('treatments.noResultsFoundFor', $store.state.locale)} ${search}`"
        :style="{'background-color' : $themes[$store.state.companyTheme][$store.state.themeModus].card}"
        class="dense-table"
        >
        <template v-slot:item="{item, isExpanded, expand}">
          <tr>
            <td>{{ item.name }}</td>
            <td class="circle-wrapper">
              <div class="circle" v-bind:class="{ active: item.active }"></div>
            </td>
            <td>{{ getBranchesNumber(item.id) }} </td>
            <td>{{ item.phonenumber }}</td>
            <td>{{ item.email }}</td>
            <td>
              <v-btn icon @click="editCompany(item)">
                <v-icon small color="text">mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn icon @click="expand(true)" v-if="!isExpanded && getBranchesNumber(item.id) > 0"><v-icon  dark>mdi-menu-down</v-icon></v-btn>
              <v-btn icon @click="expand(false)" v-if="isExpanded"><v-icon  dark>mdi-menu-up</v-icon></v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" style="padding: 0;">
            <v-card class="ma-0 pa-0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background">
              <v-card-text class="ma-0">
                <b>{{ $t('general.branches', $store.state.locale) }}</b>
                <ul>
                  <span v-for="(branch, index) in branches.filter(branch => branch.companyId == item.id)" :key="branch.id">
                    <li v-if="index != branches.filter(branch => branch.companyId == item.id).length - 1">{{ branch.name }}</li>
                    <li v-if="index == branches.filter(branch => branch.companyId == item.id).length - 1">{{ branch.name }}</li>
                  </span>
                </ul>
              </v-card-text>
            </v-card>
            <v-divider></v-divider>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <!-- / New Company Dialog \ -->
    <v-dialog v-model="newCompDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <add-comp-form
        :themes="themes"
        :showDialog="newCompDialog"
        @newCompDialogChange="newCompDialog = $event"
        @updateData="loadCompanies()"
      ></add-comp-form>
    </v-dialog>
    <!-- \ New Company Dialog / -->
    <!-- / Edit Company Dialog \ -->
    <v-dialog v-model="editCompDialog" scrollable max-width="600px"  :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <edit-comp-form
        :showEditDialog="editCompDialog"
        :selectedCompany="selectedCompanyInfo"
        :selectedCompanyOriginal="selectedCompanyInfoOriginal"
        :themes="themes"
        @editCompDialogChange="editCompDialog = $event"
        @updateData="loadCompanies()"
      ></edit-comp-form>
    </v-dialog>
    <!-- \ Edit Company Dialog / -->
  </div>
</template>


<script>
  import '@firebase/firestore';
  import db from "@/firebase/init";
  import AddCompForm from "@/components/modals/AddCompForm.vue";
  import EditCompForm from "@/components/modals/EditCompForm.vue";

  export default {
    components: {
      "add-comp-form": AddCompForm,
      "edit-comp-form": EditCompForm
    },
    name: "companies",
    data() {
      return {
        companyPages: 30,
        search: "",
        editCompDialog: false,
        newCompDialog: false,
        selectedCompanyInfo: new Object(),
        selectedCompanyInfoOriginal: new Object(),
        loading: true,
        selected: new Array(),
        branches: new Array(),
        companies: new Array(),
        pagination: {
          sortBy: "name",
          rowsPerPage: -1,
          descending: false
        },
        headers: [
          {
            text: this.$t("admin.companyName", this.$store.state.locale),
            align: "left",
            value: "name"
          },
          {
            text: this.$t("admin.active", this.$store.state.locale),
            align: "center",
            value: "",
            sortable: false,
            width: "100px"
          },
          {
            text: this.$t("general.branches", this.$store.state.locale),
            align: "left",
            value: "branches",
            width: "140px"
          },
          {
            text: this.$t("admin.phonenumber", this.$store.state.locale),
            align: "left",
            value: "phonenumber"
          },
          {
            text: this.$t("general.email", this.$store.state.locale),
            align: "left",
            value: "email"
          },
          {
            text: "",
            align: "right",
            value: "",
            width: "60px"
          },
          {
            text: "",
            value: "data-table-expand",
            align: "right",
            width: "60px",
            sortable: false
          }
        ],
        themes: [
          {name: "Thrive", id: 'thrive'},
          {name: "Bonair", id: 'bonair'},
          {name: "Jameda", id: 'jameda'}
        ]
      }
    },

    created() {
      this.loadCompanies();
    },

    methods: {
      editCompany(item) {
        this.editCompDialog = true;
        this.editedIndex = this.companies.indexOf(item);
        this.selectedCompanyInfo = Object.assign({}, item);
        this.selectedCompanyInfoOriginal = Object.assign({}, item);
      },

      getBranchesNumber(id){
        return this.branches.filter(branch => branch.companyId == id).length > 0 ? this.branches.filter(branch => branch.companyId == id).length : '-';
      },

      async loadCompanies() {
        this.loading = true;
        await db.collection("companies")
        .get()
        .then(snap => {
          this.companies = new Array();
          snap.forEach(doc => {
            let compCollection = doc.data();
            compCollection.id = doc.id;
            this.companies.push(compCollection);
          });
        })
        await db.collection("branches")
        .get()
        .then(snap => {
          this.branches = new Array();
          snap.forEach(doc => {
            let branchCollection = doc.data();
            branchCollection.id = doc.id;
            this.branches.push(branchCollection);
          });
        })
        this.loading = false;
      },
    },
  };
</script>
