<template>
  <div>
    <div v-if="loading" style="position: relative; padding: 200px 0">
      <div class="thrive-loader"></div>
    </div>

    <div v-else>
      <template>
        <div v-for="news,index in awarenessNews" :key="index">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="200px"
            :src="news.imageUrl"
          >
            
          </v-img>
          <v-card-title>{{ news.siteName }} {{ news.subject }} update</v-card-title>

          <v-card-subtitle class="pb-0">{{news.site}} </v-card-subtitle>


          <v-card-text class="text--primary">
            <div>Nieuwe visuele veranderingen waargenomen op de pagina {{news.site}}.</div>

            <div>Device: {{ news.device }}</div>
            <div>Mismatch Percentage: {{ news.misMatchPercentage }}</div>
          </v-card-text>

          <v-card-actions>
            <v-btn color="orange" text>Visuele Aanpassing</v-btn>

            
          </v-card-actions>
        </v-card>

       
        </div>
      </template>
    
      <!-- / Customer info dialog \
      <customer-information-dialog
        :customerDialog="clientDialog"
        :selectedClientOriginal="selectedClientOriginal"
        :selectedClient="selectedClient"
        :activeCompData="activeCompData"
        @customerDialogChange="clientDialog = $event"
      ></customer-information-dialog> -->
    </div>
  </div>
</template>

<script>
import { bus } from "../main";
import moment from "moment";
import db from "../firebase/init";

export default {
  name: "awarenessTimeline",
  props: {
    urlStart: String,
    urlEnd: String,
    activeCompData: Object,
    activeCompDataOriginal: Object,
  },
  components: {},

  data: function () {
    return {
      expanded: [],
      loading: true,
      newAction: false,
      radioGroup: [{}],

      companies: [],
      branches: [],
      activeComp: "",

      selected: [],

      currentMonth: Number(moment().format("M")),
      selectedSources: [],
      filter: {
        selectedSources: [],
        active: false,
      },
      clientDialog: false,
      loadingTest: false,
      tableOptions: {
        sortBy: "timestampUnix",
        itemsPerPage: 20,
        sortDesc: false,
      },
      awarenessNews: new Array(),

      dialog: false,
      dialogDelete: false,
    };
  },

  created() {
    this.loadData();

    this.now = moment().format("YYYY-MM-DD");
  },

  destroyed() {
    this.updatesRunning = false;
    this.checkForUpdates;
  },

  methods: {
    updateDate() {
      this.timeChecker = setInterval(countdown, 1);
      let vm = this;

      function countdown() {
        vm.today = moment().unix();
      }
    },

    loadData() {
      // if(this.activeCompData && this.activeCompData.id){

      db.collection("awarenessNews")
        .where("companyId", "==", this.userCompany)
        .where("branches", "array-contains", "hogansbeautybreukelen") //Later toevoegen

        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            let awarenessNews = doc.data();
            awarenessNews.docId = doc.id;

            this.awarenessNews.push(awarenessNews);
          });
        })

        .then(() => {
          this.dataLoading = false;
          this.loading = false;
        })
        .catch((error) => {
          this.dataLoading = false;
          console.error("Laden van conversies is niet gelukt: ", error);
        });
    },
  },

  computed: {
    updateCompany() {
      return (id) => _.find(this.companies, ["id", id]);
    },

    randomHipster() {
      if (this.conversionsFiltered.length == 0) {
        let randomNumber = Math.floor(Math.random() * 12) + 1;
        let hipsterClass = "hipster-" + randomNumber;
        return hipsterClass;
      } else {
        return null;
      }
    },

    userLanguage() {
      return this.$store.state.locale ? this.$store.state.locale : "NL";
    },
    userCompanies() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.accessCompanies
        : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.company
        : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.name
        : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.surname
        : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.userRole
        : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.userId
        : this.$store.state.userId;
    },
    userOperator() {
      return this.$store.state.userOperator;
    },
    companyPrimaryColor() {
      return this.$store.state.companyPrimaryColor;
    },
    companySecondaryColor() {
      return this.$store.state.companySecondaryColor;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    filteredCompanies() {
      if (this.searchCompanies && this.searchCompanies.length > 0) {
        return this.companies.filter((company) => {
          return company.name
            .toLowerCase()
            .includes(this.searchCompanies.toLowerCase());
        });
      } else {
        return this.companies;
      }
    },
    changed() {
      if (
        _.isEqual(this.activeCompData, this.activeCompDataOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<!-- 
<style>

.update-snackbar .v-snack__wrapper {
  min-width: 450px !important;
}

.attempt-box {
  display: block;
  width: 32px;
  height: 32px;
  background-color: var(--v-text-base);
  border-radius: 50px;
  font-size: 11px;
  text-align: center;
  line-height: 34px;
  color: var(--v-menu-base);
}

.attempt-box span {
  font-size: 15px;
  color: var(--v-menu-base);
  padding: 0 1px 0 0;
}

.extra-conversion-info h3 {
  padding: 0 0 10px 0;
}

.conversion-client-info .info-wrapper:after {
  display: block;
  clear: both;
  content: "";
}

.conversion-client-info .info-label {
  font-weight: 600;
  width: 120px;
  float: left;
  display: block;
}

.conversion-client-info .info-data {
  width: calc(100% - 120px);
  display: block;
  float: left;
}

.no-conversions {
  width: 280px;
  margin: 0px auto;
  padding: 80px 0;
}

.hipster-image {
  width: 130px;
  height: 130px;
  margin: 0 auto;
}

.no-conversions p {
  font-weight: 600;
  text-align: center;
  font-size: 16px;
}

.conversion-selected-period p {
  text-align: center;
  padding: 15px 0 5px 0;
  text-transform: lowercase;
}

.v-toolbar.custom-toolbar .v-toolbar__content,
.v-toolbar.custom-toolbar .v-toolbar__extension {
  padding: 0 !important;
}

.conversion-table table head td:not(:nth-child(1)),
.conversion-table table tbody td:not(:nth-child(1)),
.conversion-table table thead th:not(:nth-child(1)),
.conversion-table table tbody th:not(:nth-child(1)),
.conversion-table table thead td:first-child,
.conversion-table table tbody td:first-child,
.conversion-table table thead th:first-child,
.conversion-table table tbody th:first-child {
  padding: 0 8px !important;
}

.close-conversion-buttons {
  border-radius: 5px;
  overflow: hidden;
}

.close-conversion-buttons:after {
  display: block;
  content: "";
  clear: both;
}

.close-conversion-button {
  width: 33.3%;
  border: 1px solid #251433;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  color: #251433;
  float: left;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.close-conversion-button.left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.close-conversion-button.middle {
  border-right: none;
  border-left: none;
}

.close-conversion-button.right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.close-conversion-button.active {
  background-color: #251433;
  color: #fff;
}

tr.buzy {
  background-color: rgba(0, 0, 0, 0.09);
}

.custom-label {
  color: var(--v-text-base);
}
</style>

<style scoped>
.usertype-total {
  padding: 10px;
}

.usertype-total-number {
  text-align: center;
  font-size: 38px;
}
.usertype-total-desc {
  text-align: center;
  font-size: 16px;
}
</style> -->
