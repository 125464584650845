<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type =='new'">{{ $t("admin.addNewTreatmentType", $store.state.locale) }}</h3>
        <h3 v-if="type =='edit'">{{ $t("admin.editTreatmentType", $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeTreatmentDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>
        <v-text-field
          class="mt-4"
          :label="$t('general.name', $store.state.locale) + ` (${getLanguageName('nl').languageName[$store.state.locale.toLowerCase()]})`"
          v-model="treatmentType.name.nl"
          autocomplete
          outlined
          required
          dense
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          >
          <template v-slot:prepend-inner >
            <country-flag style="margin: -9px -5px 0 -10px" country="nl" size="small"/>
          </template>
        </v-text-field>

        <v-text-field
          class="mt-4"
          :label="$t('general.name', $store.state.locale) + ` (${getLanguageName('en').languageName[$store.state.locale.toLowerCase()]})`"
          v-model="treatmentType.name.en"
          autocomplete
          outlined
          required
          dense
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          > 
          <template v-slot:prepend-inner >
            <country-flag style="margin: -9px -5px 0 -10px" country="gb" size="small"/>
          </template>
        </v-text-field>

        <v-text-field
          class="mt-4"
          :label="$t('general.name', $store.state.locale) + ` (${getLanguageName('de').languageName[$store.state.locale.toLowerCase()]})`"
          v-model="treatmentType.name.de"
          autocomplete
          outlined
          required
          dense
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          > 
          <template v-slot:prepend-inner >
            <country-flag style="margin: -9px -5px 0 -10px" country="de" size="small"/>
          </template>
        </v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeTreatmentDialog()"
                >
                {{ $t('general.cancel', $store.state.locale)}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type =='new'"
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addTreatmentType()"
                >
                {{ $t("general.add", $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn
                v-if="type =='edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="updateTreatmentType()"
                >
                <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right>mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
  import { bus } from "../../main";
  import db from "../../firebase/init";
  import '@firebase/firestore';

  export default {
    name: "treatmenttype-form",
    data() {
      return {
        feedback: null,
        treatmentType: { name: new Object() },
        treatmentTypeOriginal: { name: new Object() },
        type: 'new',
        showDialog: false
      };
    },

    created() {
      bus.$on("TreatmentTypeModal", (treatmentType, type) => {
        this.type = type;
        if(type == "edit"){
          this.treatmentType = _.cloneDeep(treatmentType);
          this.treatmentTypeOriginal = _.cloneDeep(this.treatmentType);
        }
        else{
          this.type = 'new';
          this.resetTreatmentType();
        }
        this.showDialog = true;
      });
    },

    methods: {
      getLanguageName(item){
        return this.$countries.find(language => { return language.id == item });
      },
      closeTreatmentDialog() {
        this.resetTreatmentType();
        this.showDialog = false;
      },

      resetTreatmentType(){
        this.feedback = null;
        this.treatmentType = {
          name: {
            nl: "",
            en: "",
            de: ""
          }
        };
      },

      async updateTreatmentType() {
        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          actionType: "userAction",
          action: "treatmentTypeEditted",
          actionItem: this.treatmentTypeOriginal.name,
          extra:[
            {
              name: "originalData",
                value: ""
            },
            {
              name: "name",
              value: this.treatmentTypeOriginal.name
            },
            {
              name: "changedData",
              value: ""
            },
            {
              name: "name",
              value: this.treatmentType.name
            }
          ]
        };

        try{
          await db.collection("treatmentTypes")
          .doc(this.treatmentType.id)
          .update({
            name: this.treatmentType.name,
          })

          await this.$createNewLog("info", logData)
          this.$emit("updateData");
          this.closeTreatmentDialog();

        }
        catch(error){
          console.error("Error edditing TreatmentType: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      },

      async addTreatmentType() {
        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "treatmentTypeAdded",
          actionItem: this.$autoLanguageSelector(this.$store.state.nativeLanguage, $store.state.companyLanguages, this.treatmentType.name),
          extra:[
            {
              name: "name",
              value: this.treatmentType.name
            }
          ]
        };
        if (this.treatmentType.name.nl && this.treatmentType.name.nl) {
          
          try{
            await db.collection("treatmentTypes")
            .add({
              name: this.treatmentType.name,
            })
            await this.$createNewLog("info", logData);
            this.$emit("updateData");
            this.closeTreatmentDialog();
          }
          catch(error){
            console.error("Error adding TreatmentType: ", error);
            this.feedback = this.$t("general.addError", this.$store.state.locale);
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
          }
        }
        else {
          this.feedback = "Vul alle velden in om een nieuw behandelingstype toe te voegen.";
        }
      }
    },
    computed: {
      changed() {
        return _.isEqual(this.treatmentType ,this.treatmentTypeOriginal) ? false : true;
      }
    }
  };
</script>