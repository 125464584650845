<template>
  <div>
    <div v-if="loading">
      <div style="position: relative; padding: 200px 0">
        <div class="thrive-loader"></div>
      </div>
    </div>


    <v-container grid-list-md class="ma-0 pa-0" v-show="!loading">
      <v-layout row wrap v-if="employees.length > 0">
        <v-flex xs2>
          <v-card v-if="!employeeDetailsView" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" v-show="employees.length > 0">
            <v-text-field
                v-if="!loading"
                v-model="search"
                outlined
                dense
                clearable
                hide-details
                background-color="background"  
                name="searchfield"
                :label=" $t('general.search', $store.state.locale)"
                prepend-inner-icon="mdi-magnify"
                single-line
                class="ma-2"
                @click="filterEmployeeView('all')"
                @click:clear="filterEmployeeView('all'), search = '', clientCounter++"
              ></v-text-field>

              <v-list class="pa-0 ma-0" dense>
                <v-divider></v-divider>
                
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('active')" @click="filterEmployeeView('active')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('general.active', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> 
                <v-divider></v-divider>

                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('removed')" @click="filterEmployeeView('removed')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('general.removed', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('all')" @click="filterEmployeeView('all')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('general.all', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                

                <p class="ma-0 mt-5 pa-0 pl-4">{{ $t('general.type', $store.state.locale) }}</p>
    
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('employee')" @click="filterEmployeeView('employee')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('general.employee', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('intern')" @click="filterEmployeeView('intern')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('employees.intern', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('trainee')" @click="filterEmployeeView('trainee')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('employees.trainee', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('seatTenant')" @click="filterEmployeeView('seatTenant')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('employees.seatTenant', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('entrepreneur')" @click="filterEmployeeView('entrepreneur')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('employees.entrepreneur', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('owner')" @click="filterEmployeeView('owner')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('employees.owner', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <div class="pa-3">
                  <v-btn dark height="40" depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="newEmployeeDialog = true" block class="ma-0">
                    <v-icon left>mdi-plus</v-icon> {{ $t('general.new', $store.state.locale) }} 
                  </v-btn>
                </div>
            </v-list>
          </v-card>

          <v-card v-if="employeeDetailsView" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" v-show="employees.length > 0">
            <v-list class="pa-0 ma-0" dense>
              <div v-for="(employee, index) in employeesFiltered" :key="index">
                <v-divider v-if="index != 0"></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedEmployee(employee.objectID)" @click="employeeDetails(employee)">
                  <v-list-item-avatar :style="{'backgroundColor' : !employee.employeePhotoThumb ? $themes[$store.state.companyTheme][$store.state.themeModus].background : 'none' }">
                    <v-img v-if="employee.employeePhoto" :src="employee.employeePhoto"></v-img>
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-else>mdi-account</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{employee.firstName }} {{employee.lastName }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-flex>
        <v-flex xs10 class="pl-3">
          <div v-if="!employeeDetailsView">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" v-show="employees.length > 0 && employeesFiltered.length > 0">
              <v-data-table
                :headers="headers"
                :items="employeesFiltered"
                :search="search"
                v-model="selected"
                single-expand
                hide-default-footer
                :itemsPerPage.sync="pages"
                :no-results-text="`${$t('employees.noResults', this.$store.state.locale)}:  ${search}`"
                :style="{'background-color' : $themes[$store.state.companyTheme][$store.state.themeModus].card}"
                dense
                class="dense-table employees-table"
              >
                <template v-slot:item="{ item, index, }">
                  <tr :key="watcher + index" :class="{ 'removed-employee' : (employeeFilterActive == 'all' && item.deleted.deleted) }">
                    <td class="grap" v-if="employeeFilterActive == 'active'">
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>mdi-arrow-all</v-icon>
                    </td>
                    <td v-else-if="employeeFilterActive == 'all' && item.deleted.deleted != true">
                      <v-icon color="#EF5350" size="20">mdi-delete-outline</v-icon>
                    </td>
                    <td v-else></td>
                    <!-- <td class="text-center">
                      <v-icon class="px-2" :color="activeColor(item.active)" small>mdi-checkbox-blank-circle</v-icon>
                    </td> -->
                    <td class="text-center">
                        <v-icon class="px-2" :color="activeColor(item.loginAccountActive)" small>mdi-checkbox-blank-circle</v-icon>
                    </td>
                    <td>{{ item.firstName }}</td>
                    <td>
                      {{ item.lastName }}
                      <v-chip v-if="item.trainee" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style">Leerling</v-chip>
                    </td>
                    <td v-if="!loading">
                      <v-chip v-for="(branch, index) in item.branches" :key="index" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-1 my-1 custom-chip-style" >
                        {{ employeeBranch(branch).name }}
                      </v-chip>
                    </td>
                    
                    <td>
                      <v-btn v-if="item.loginAccount" style="float: right" icon class="mx-0" @click="seeUserLog(item)">
                        <v-icon small  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-text</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn icon style="float: right" class="mx-0" @click="employeeDetails(item)">
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-right</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>

            <div v-if="employees.length > 0 && employeesFiltered.length == 0" class="no-data-message">
              <v-avatar
                size="58"
                light
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-multiple</v-icon>
              </v-avatar>
              <p>{{ $t('employees.noEmployeesFound', $store.state.locale) }}</p>
            </div>

            
          </div>
    
          <employee-information v-show="employeeDetailsView" :branches="branches" @updateData="loadEmployees('refresh')"></employee-information>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="employees.length == 0">
        <v-flex xs12>
          <div class="no-data-message">
            <v-avatar
              size="58"
              light
              class="ma-0 mr-2 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-multiple</v-icon>
            </v-avatar>
            <p>{{ $t('employees.noEmployeesFor', $store.state.locale) }} {{ $store.state.activeCompany.name }}.</p>
            <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="newEmployeeDialog = true">
              <v-icon left>mdi-plus</v-icon> {{ $t('employees.newEmployee', $store.state.locale) }} 
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- / New Employee Dialog \ -->
    <v-dialog v-model="newEmployeeDialog" persistent scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <add-employee-form
        :nextResourceWeight="nextResourceWeight"
        :activeBranches="activeBranches"
        :branches="branches"
        :activeCompData="activeCompData"
        @newEmployeeDialogChange="newEmployeeDialog = $event"
        @updateData="loadEmployees('refresh')"
      ></add-employee-form>
    </v-dialog>
    <!-- \ New UseEmployeer Dialog / -->

    <!-- / View User Logs \ -->
    <view-user-logs :selectedUserCompany="selectedUserCompany"></view-user-logs>
    <!-- \ View User Logs / -->

    <v-snackbar v-model="snackbar.active" :color="snackbar.color" top :timeout="snackbar.timeout">
      <v-row>
        <v-icon color="white" class="mx-3">{{ snackbar.icon }}</v-icon>
        <span class="py-2 white--text">{{ snackbar.text }}</span>
      </v-row>
    </v-snackbar>
  </div>
</template>

<style scoped>
  .swal2-popup {
    border-radius: 20px !important;
  }
</style>

<script>
  import db from "../firebase/init";
  import { bus } from "../main";
  import Sortable from "sortablejs";
  import draggable from "vuedraggable";
  import AddEmployeeForm from "../components/modals/AddEmployeeForm.vue";
  import EmployeeInformation from "../views/EmployeeInformation.vue";
  import ViewUserLogs from "../components/modals/ViewUserLogs.vue";
  import moment from "moment";

  //Full Calendar
  import FullCalendar from "@fullcalendar/vue";
  import interactionPlugin from "@fullcalendar/interaction";
  import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import scrollgrid from "@fullcalendar/scrollgrid";
  import algoliasearch from "algoliasearch/lite";

  // import NL from "@fullcalendar/core/locales/nl";

  // import "@fullcalendar/core/main.css";
  // import "@fullcalendar/daygrid/main.css";
  // import "@fullcalendar/timegrid/main.css";
  export default {
    props: {
      activeCompData: Object,
    },
    components: {
      FullCalendar,
      'employee-information': EmployeeInformation,
      "add-employee-form": AddEmployeeForm,
      "view-user-logs": ViewUserLogs,
    },
    name: "Employees",
    data() {
      return {
        employeeFilterActive: "all",
        employeesFiltered: new Array(),
        deletedEmployees: new Array(),
        algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
        // Calendar Settings

        //End Calendar
        employeeDetailsView: false,
        wolfload: true,
        calendar: null,
        snackbar: {
          active: false,
          text: "",
          icon: "",
          timeout: -1,
          color: "",
        },
        selectedResource: null,
        selectedEmployeeIndex: null,
        scheduleStartDateRaw: null,
        scheduleStartDateDialog: false,

        watcher: 0,
        exceptionEnd: false,
        feedback: null,
        pages: -1,

        employees: new Array(),

        selectedEmployeeInfo: {
          abilities: new Object(),
          branches: new Array(),
          email: null,
          password: null,
          firstName: null,
          EmployeeSurame: null,
        },
        loading: true,
        selectedEmployeeInfoOriginal: new Object(),
        search: "",
        newEmployeeDialog: false,

        selected: new Array(),
        branches: new Array(),
        nextResourceWeight: null,
        activeBranches: new Object(),
        companies: new Array(),
        selectedUserCompany: null,
        resources: new Array(),

        headers: [
          {
            text: "",
            align: "left",
            sortable: false,
            value: "weight",
            width: "40px",
            sortable: false,
          },
          {
            text: this.$t('general.login', this.$store.state.locale),
            align: "center",
            sortable: false,
            value: "loginAccount",
            width: "80px",
            sortable: false,
          },
          {
            text: this.$t('general.firstName', this.$store.state.locale),
            align: "left",
            value: "firstName",
            width: "120px",
            sortable: false,
          },
          {
            text: this.$t('general.lastName', this.$store.state.locale),
            align: "left",
            value: "lastName",
            sortable: false,
          },
          {
            text: this.$t('general.branch', this.$store.state.locale),
            align: "left",
            value: "branches",
            sortable: false,
          },
          {
            text: "",
            align: "left",
            value: "edit",
            width: "60px",
            sortable: false,
          },
          {
            text: "",
            align: "left",
            value: "expand",
            width: "60px",
            sortable: false,
          },
        ],

        valid: false,
      };
    },
    created() {
      bus.$on("companyUpdate", this.loadData);
      bus.$on("goToEmployeeOverview", this.goToEmployeeOverview);
      
      if(this.$route.params.extra){
        this.loadData("param");
      }
      else{
        this.loadData();
      }
    },

    methods: {
      async loadData(type) {
        await this.loadEmployees();
        if(type == "param"){
          let employeeInfo = this.employees.find(item=> item.objectID == this.$route.params.extra)
          this.employeeDetails(employeeInfo)
        }
      },

      refresh() {
        this.state.refresh();
      },

      seeUserLog(item) {
        this.selectedUserCompany = this.$store.state.activeCompany.name;
        let username = item.firstName + " " + item.lastName;
        bus.$emit("getUserLogs", username);
      },

      activeColor(active){
        return active ? "#81C784" : "#EF5350"; 
      },

      filterEmployeeView(id){
        this.employeesLoading = true;
        this.employeesFiltered = new Array();
        this.employeeFilterActive = id;

        if(id == 'active'){
          this.employeesFiltered = this.employees.filter(item=> {return !item.deleted.deleted });
        }
        else if(id == 'all'){
          this.employeesFiltered = this.employees.concat(this.deletedEmployees);
        }
        else if(id == 'removed'){
          this.employeesFiltered = this.deletedEmployees;
        }
        else if(id == 'employee'){
          this.employeesFiltered = this.employees.filter(item=> {return item.contractType == 'employee'});
        }
        else if(id == 'trainee'){
          this.employeesFiltered = this.employees.filter(item=> {return item.contractType == 'trainee'});
        }
        else if(id == 'intern'){
          this.employeesFiltered = this.employees.filter(item=> {return item.contractType == 'intern'});
        }
        else if(id == 'seatTenant'){
          this.employeesFiltered = this.employees.filter(item=> {return item.contractType == 'seatTenant'});
        }
        else if(id == 'entrepreneur'){
          this.employeesFiltered = this.employees.filter(item=> {return item.contractType == 'entrepreneur'});
        }
        else if(id == 'owner'){
          this.employeesFiltered = this.employees.filter(item=> {return item.contractType == 'owner'});
        }
        this.employeesFiltered= this.employeesFiltered.sort((a, b) => a.weight < b.weight ? -1 : 1)
      },

      async loadEmployees(type) {  
        console.log("loadEmployees type: ", type)    
        this.loading = true;
        this.employeeDetailsView = false;
        this.employees = new Array();
        this.deletedEmployees = new Array();
        let index = this.algoSearch.initIndex("thrive_employees");
        if(type && type == 'refresh'){ // Refresh Algolia Cache before loading to see changes
          await this.algoSearch.clearCache(); // Refresh Algolia Cache
          await new Promise(resolve => setTimeout(resolve, 2500));
        }
       
        var FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
        await index.search("", {
          facetFilters: [FacetFilterString],
        },)
        .then(({ hits }) => {
          hits.forEach((employee) => {
            if(!employee.deleted || !employee.deleted.deleted ){
              this.employees.push(employee)
            }
            else{
              this.deletedEmployees.push(employee)
            }
          });
        });

        this.nextResourceWeight = this.employees.length

        await this.loadBranches();
        await this.employees.sort(this.sortEmployees);
        this.orderEmployees();
        this.filterEmployeeView("active")
        return
      },

      async loadBranches() {
        await db.collection("branches")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then((snap) => {
          this.branches = new Array();
          snap.forEach((doc) => {
            let branch = doc.data();
            branch.id = doc.id;
            this.branches.push(branch);
          });

          this.activeBranches = new Object();
          snap.forEach((doc) => {
            let branch = doc.id;
            this.activeBranches[branch] = false;
          });
        })
        this.loading = false;
      },

      async sortEmployees(a, b) {
        // Use toUpperCase() to ignore character casing
        const genreA = a.weight;
        const genreB = b.weight;

        let comparison = 0;
        if (genreA > genreB) {
          comparison = 1;
        } else if (genreA < genreB) {
          comparison = -1;
        }
        return comparison;
      },

      orderEmployees() {
        let element = document.querySelector(".employees-table tbody");
        const vm = this;
        Sortable.create(element, {
          handle: ".grap",
          onEnd({ newIndex, oldIndex }) {
            const rowSelected = vm.employees.splice(oldIndex, 1)[0]; // Get the selected row and remove it
            vm.employees.splice(newIndex, 0, rowSelected); // Move it to the new index
            vm.updateOrderEmployees();
          },
        });
      },

      async updateOrderEmployees() {
        this.watcher++;
        let employees = _.cloneDeep(this.employees);
        await new Promise((resolve)=>{
          let counter = 0
          employees.forEach((item) => {
            let index = employees.indexOf(item);
            this.employees[index].weight = index;
            db.collection("employees").doc(item.objectID).set(
              {
                weight: index,
              },
              { merge: true }
            );
            counter++
            if(counter == this.employees.length){
              resolve()
            }
          });
        })
        this.employeesFiltered= this.employeesFiltered.sort((a, b) => a.weight < b.weight ? -1 : 1)
      },

      employeeDetails(item){
        bus.$emit("getEmployeeInformation", item.objectID)
        this.selectedEmployeeInfo = _.cloneDeep(item);
        this.employeeDetailsView = true;
        if(!this.$route.params.extra){
          let slash =this.$route.path.slice(-1) == "/" ? "" : "/"; // Check for slash to prevent dubble slash
          let routePath = (this.$route.path).includes("employees") ? this.$route.path : this.$route.path + slash + "employees" 
          history.pushState({},null,routePath + slash + item.objectID)
        }
        else{
          this.$router.push({ params: { tab: "employees", extra: item.objectID} });
        }
      },

      goToEmployeeOverview(){
        this.$router.push({ params: { tab: "employees", extra: null} });
        this.employeeDetailsView = false;
      },

      // deleteEmployee() {

    
      //   this.selected.forEach((employee) => {

      //     // Check if employee has noEndContract
      //     if(employee.contractType != "noEndContract"){
      //       //check if employee has noEndContract
      //     }

      //     db.collection("employees")
      //       .doc(employee.id)
      //       .update({
      //         status: "deleted",
      //       })
      //       .then(() => {
      //         //CLEAR CACHE
      //         this.$store.dispatch("clearFetchedResourceCache")
      //         this.loadEmployees('refresh'); // Refresh Algolia Cache to prevent showing employee as active
      //       })
      //       .then(() => {
      //         console.log("Employee successfully deleted!");
      //       })
      //       .catch((error) => {
      //         console.error("Error removing Employee: ", error);
      //       });
      //   });
      // },

      employeeBranch(id) {
        let branch = _.find(this.$store.state.userBranches, ["id", id])
        
        return branch ? branch : new Object()
      },
    },

    computed: {
      selectedView(){
        return id => {
          if(this.employeeFilterActive == id){
            return true;
          }
          else {
            return false;
          }
        }
      },

      selectedEmployee(){
        return id => {
          if(this.selectedEmployeeInfo.objectID == id){
            return true;
          }
          else {
            return false;
          }
        }
      },
    },
  };
</script>

<style>
  .removed-employee{
    opacity: 0.5;
    text-decoration: line-through;
  }
  .employee-calendar-wrapper .fc-axis.fc-widget-header,
  .employee-calendar-wrapper .fc-resource-cell {
    height: auto;
  }
  .employee-calendar-wrapper .fc-day-header.fc-widget-header {
    height: 25px;
    text-align: center;
    font-weight: bold;
  }
  tr.employee-expanded {
    background-color: var(--v-menuhover-base);
  }
  .employee-calendar-wrapper .fc-view-container {
    background-color: var(--v-menu-base);
  }

  .employee-calendar-wrapper .fc-resource-cell {
    text-align: center !important;
  }
  .employee-calendar-wrapper .fc-head:hover .fc-head-container.fc-widget-header,
  .employee-calendar-wrapper .fc-body:hover .fc-bg,
  .employee-calendar-wrapper table:hover,
  .employee-calendar-wrapper table tr:hover,
  .employee-calendar-wrapper .fc-body:hover td.fc-time {
    background: none !important;
  }
  .employee-calendar-wrapper .fc-unthemed td.fc-today {
    background: var(--v-menu-base);
  }

  .employee-calendar-wrapper .fc-timegrid-event-harness{
    box-shadow: none!important;
  }

  .fc-v-event{
    background-color: var(--primary-color);
  }

  .fc-v-event.drag{
    background-color: "red";
  }

  .fc-v-event.selected .fc-event-time{
    color: #ffffff
  }
</style>
