<template>
  <v-dialog v-model="userLogsDialog" scrollable max-width="800px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card style="overflow: hidden;" color="background">
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h2>Activiteiten van {{ selectedUsername }} - {{ selectedUserCompany }}</h2>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeViewUserLogsDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="ma-0 pa-0">
        <div v-if="loading" style="margin: 0 auto; padding: 90px 0;" class="extra-loading-dots"></div>

        <div v-if="!loading && userLogs.length > 0">
          <v-data-table
            :headers="headers"
            :items="userLogs"
            :itemsPerPage.sync="userLogPages"
            hide-default-footer
            class="user-log-table"
            style="border-bottom: 1px solid rgba(0,0,0,0.12);"
            >
            <template v-slot:item="{item, isExpanded, expand }">
              <tr>
                <td>{{ item.time.seconds | moment("D MMM YYYY - H:mm") }}u</td>
                <td>
                  <v-icon v-if="item.logType == 'loginerror'" color="red">mdi-warning</v-icon>
                  <v-icon v-if="item.logType == 'error'" color="red">mdi-warning</v-icon>
                  <v-icon v-if="item.logType == 'info'" color="grey lighten-1">mdi-information</v-icon>
                </td>
                <td>{{ item.action }}</td>
                <td>
                  <span v-if="item.userRole != 'admin'">
                    <v-btn icon @click="expand(true)" v-if="!isExpanded"><v-icon color="text" dark>mdi-chevron-down</v-icon></v-btn>
                    <v-btn icon @click="expand(false)" v-if="isExpanded"><v-icon color="text" dark>mdi-chevron-up</v-icon></v-btn>
                  </span>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding: 0;">
                <v-card  flat :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }">
                  <v-card-text class="ma-0">
                    <p class="ma-0"><strong>Software: </strong>{{item.software}}</p>
                    <p class="ma-0"><strong>Browser: </strong>{{item.browser}}</p>
                    <span v-if="item.extra">
                      <br>
                      <div v-html="item.extra"></div>
                    </span>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>

          <div v-if="userLogs.length >= 50" class="text-xs-center" style="padding: 20px;">
            <v-row align="center" v-if="extraLoading == false && userLogs.length < 200" >
              <v-col class="text-center" cols="12">
                <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click.native="loadMoreUserLogs()">Meer logs laden</v-btn>
              </v-col>
            </v-row>
            <div v-if="extraLoading == true" class="extra-loading-dots"></div>
          </div>

        </div>
        <div v-if="!loading && userLogs.length == 0">
          <v-layout justify-space-around>
          <div style="padding: 70px 20px;" ><v-icon large style="display: block; float: left; padding: 0 5px 0 0;" :color="companySecondaryColor">touch_app</v-icon> <span style="display: block; float: left; padding: 12px 0 0 0;">Geen activiteiten gevonden.</span></div>
          </v-layout>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import db from "../../firebase/init";
import firebase from "firebase/app";
import platform from 'platform'; 
import { bus } from "../../main";

export default {
  name: "view-user-logs",
  props: {
    selectedUserCompany: String
  },
  data() {
    return {
      selectedUsername: null,
      userLogsDialog: false,
      loading: true,
      userLogs: [],
      userLogPages: -1,
      userLogContinueAt: {},
      extraLoading: false,
      headers: [
        {
          text: "Tijd",
          align: "left",
          value: "time.seconds",
          width: "200px"
        },
        {
          text: "",
          align: "left",
          value: "type",
          width: "30px"
        },
        {
          text: "Activiteit",
          align: "left",
          value: "action"
        },
        {
          text: "",
          align: "left",
          value: "extra",
          width: "30px"
        }
      ],
      pagination: {
        rowsPerPage: "20000",
        sortBy: "time.seconds",
        descending: true
      }
    };
  },

  beforeCreate(){
    bus.$on("getUserLogs", (userName) => {
      this.loadUserLogs(userName)
      this.loading = true;
      this.selectedUsername = userName;
      this.userLogsDialog = true;
    });
  },

  methods: {
    closeViewUserLogsDialog() {
      this.userLogsDialog = false;
    },

    loadUserLogs(userName){
      this.loading = true;
      db.collection("log")
        .where("user", "==", userName)
        .where("actionType", "==", "User Action")
        .orderBy("time", "desc")
        .limit(50)
        .get()
        .then(snap => {
          this.userLogs = [];
          snap.forEach(doc => {
            let logCollection = doc.data();
            logCollection.id = doc.id;
            this.userLogs.push(logCollection);
          });
          this.userLogContinueAt = snap.docs[snap.docs.length - 1];
        })
        .then(()=>{
          this.loading = false
        })
        .catch(error=>{
          console.error("Fout bij binnenladen userlogs: ", error)
        })
    },

    loadMoreUserLogs() {
      this.extraLoading = true;
      db.collection("log")
        .where("user", "==", this.selectedUsername)
        .where("actionType", "==", "User Action")
        .orderBy("time", "desc")
        .startAfter(this.userLogContinueAt)
        .limit(50)
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let logCollection = doc.data();
            logCollection.id = doc.id;
            this.userLogs.push(logCollection);
          });
          this.userLogContinueAt = snap.docs[snap.docs.length - 1];
        })
        .then(() => {
          this.extraLoading = false;
        })
        .catch(error => {
          console.error("Fout bij binnenladen extra userlogs: ", error)
        });
    },
  },
  computed: {
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
  }
};
</script>
<style scoped>
  .user-log-table table.v-table thead td:not(:nth-child(1)), 
  .user-log-table table.v-table tbody td:not(:nth-child(1)), 
  .user-log-table table.v-table thead th:not(:nth-child(1)), 
  .user-log-table table.v-table tbody th:not(:nth-child(1)), 
  .user-log-table table.v-table thead td:first-child, 
  .user-log-table table.v-table tbody td:first-child, 
  .user-log-table table.v-table thead th:first-child, 
  .user-log-table table.v-table tbody th:first-child {
      padding: 0 12px;
  }
</style>
