
<template>
  <div>
      <div v-if="$store.state.userCompanies.length > 1" class="pt-0 ma-0 mb-4 pa-0">
        <div class="clear">
          <v-container grid-list-md class="pa-0" fluid>
            <v-layout row wrap>
              <companySelector :showBranches="true" @companyChanged="loadData" @branchChanged="loadData"></companySelector>
            </v-layout>
          </v-container>
        </div>
      </div>

      <div v-if="dataloaded == false" class="thrive-loader"></div>

      <div v-if="dataloaded && $store.state.activeCompany">
        <v-tabs class="mb-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" show-arrows v-model="tab" @change="tabChanged()" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
          <v-tab><v-icon left>mdi-chart-bar</v-icon>{{ $t('conversion.conversionStats', $store.state.locale)}}</v-tab>
          <v-tab><v-icon left>mdi-cog</v-icon>{{ $t('conversion.conversionSettings', $store.state.locale)}}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="transparant-tabs">
          <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
            <conversion-statistics></conversion-statistics>
          </v-tab-item>
          <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
            <conversion-settings></conversion-settings>
          </v-tab-item>
        </v-tabs-items>
      </div>
  </div>
</template>

<script>
import { bus } from "../main";
import '@firebase/firestore';
import db from "../firebase/init";

import ConversionStatistics from "./ConversionStatistics.vue";
import ConversionSettings from "./ConversionSettings.vue";
import companySelector from "@/components/elements/companySelector.vue";


export default {
  components: {
    conversionStatistics: ConversionStatistics,
    conversionSettings: ConversionSettings,
    companySelector: companySelector
  },
  name: "Conversion",
  data: () => ({
    tab: 0,
    tabTransition: false,
    dataloaded: true,
  }),


  methods: {
    loadData(){ // We need to hide and show the tabitems so they will reload the new data
      this.dataloaded = false;
      setTimeout(()=>{
        this.dataloaded = true;
      },200)
      // bus.$emit("loadBranchData");
    },
    tabChanged(){ // Query in URl aanpassen bij selecteren van de router.
      // this.$router.push({ params: { tab: null, extra: null} });
      // history.pushState({},null,this.$route.path + "/" + this.tab)
    }
  },

  computed: {
    version() {
      return this.$store.state.version;
    },
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    filteredCompanies(){
      if (this.searchCompanies && this.searchCompanies.length > 0){
        return this.companies.filter(company => {
          return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
        });
      } else {
        return this.companies;
      }
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      if(this.$store.state.companyTheme){
        return this.$store.state.companyTheme;
      }
      else{
        return "thrive"
      }
    }
  }
};
</script>

