// import '@babel/polyfill'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import themes from './plugins/themes'
import countries from './plugins/countries'
import leadTags from './plugins/leadTags'
import leadSources from './plugins/leadSources'
import officialFreeDays from './plugins/officialFreeDays'
import leaveItems from './plugins/leaveItems'
import App from './App.vue'
import router from './router'
import store from './store'
import wysiwyg from 'vue-wysiwyg'
// import Header from './components/elements/Header.vue'
import Sidemenu from './components/elements/Sidemenu.vue'
import FullCalendar from 'vue-full-calendar'
import { autoLanguageSelector } from './modules/autoLanguageSelector'
import { createNewClient } from './modules/createNewClient'
import { createNewLog } from './modules/customLogger'

import { downPaymentInformation } from './modules/downPayment'
import { moneyInput } from './modules/moneyDirective'

import * as VueGoogleMaps from 'vue2-google-maps'
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)



import 'fullcalendar-scheduler'

import Verte from 'verte'
import 'verte/dist/verte.css'
// register component globally
Vue.component('Verte', Verte)

// import "fullcalendar/dist/fullcalendar.min.css";
// import "fullcalendar-scheduler/dist/scheduler.min.css";
import VueGoogleCharts from 'vue-google-charts'
import CKEditor from '@ckeditor/ckeditor5-vue2'
Vue.use(CKEditor)
import CountryFlag from 'vue-country-flag'
import i18n from './i18n'
import moment from 'moment'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

import platform from 'platform'
import InstantSearch from 'vue-instantsearch'


Vue.directive('money-input', moneyInput)

Vue.use(InstantSearch)
Vue.use(platform)


import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('Apexchart', VueApexCharts)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBxh2jNJZiHvpnPw8pLSpcTPd27plstWxY',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

// import slugify from "slugify";
// import db from "./firebase/init";
import firebase from 'firebase/app'
// import "firebase/firestore";
// require("firebase/firestore");

import VueZoomer from 'vue-zoomer'
//import 'vue-zoomer/dist/vue-zoomer.css'

// Helpers
// import Moment from "moment";
// import { extendMoment } from "moment-range";
import { Tabs, Tab } from 'vue-tabs-component'
import VueSweetalert2 from 'vue-sweetalert2'
//import VueLodash from "vue-lodash";
import VueMoment from 'vue-moment'
require('moment/locale/nl')

import lodash from 'lodash'

Vue.prototype._ = lodash

export const bus = new Vue()

const options = { name: '_' } // customize the way you want to call it
//Vue.use(VueLodash, options, lodash); // options is optional

Vue.use(require('vue-moment'), {
  moment,
})
Vue.use(VueZoomer)
// Vue.use(CKEditor);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
})

Vue.use(VueGoogleCharts)

// Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.use(FullCalendar)
Vue.use(VueSweetalert2)

// Vue.component('PageHeader', Header)
Vue.component('SideMenu', Sidemenu)

// Vue Tabs
Vue.component('Tabs', Tabs)
Vue.component('Tab', Tab)
Vue.component('CountryFlag', CountryFlag)

// WYSIWSG Editor
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    table: true,
    headings: true,
    code: true,
    orderedList: true,
    unorderedList: true,
    removeFormat: true,
    separator: true,
  },
  minHeight: '150px',
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  if (to.path == '/bookwidget') {
    //console.log("Bookwidget");
    next()
  } else if (to.path == '/register') {
    //console.log("Register");
    next()
  } else if (to.path.includes('/subscription/')) {
    //TODO: Change value for security resaons like url can always contains "subscription".
    //console.log("Subscription");
    next()
  } else if (to.path.includes('/afspraak-wijzigen/')) {
    //TODO: Change value for security resaons like url can always contains "subscription".
    //console.log("afspraak-wijzigen");
    next()
  }
  else {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          //let token = idTokenResult.token
          let userData = {
            user: user,
            token: idTokenResult.token,
          };

          store.dispatch("tryAutoLogin", userData)
            .then((test) => {
              if (to.path == "/login") {
                next("/home");
              } else {
                // console.log("NEXT")
                next();
              }
            })
            .catch((error) => bus.$emit("eventError", error)); // Added error handling statement
        });
      }
      else { // Firestore token niet aanwezig
        if (to.path == "/login") {
          next();
        } else {
          next("/login");
        }
      }
    });
	}
})

Vue.config.productionTip = false
//Capture Errors
Vue.config.errorHandler = (err, vm, info) => {

  bus.$emit('eventError', err, info, vm)

}


//Capture Warnings
// Vue.config.warnHandler = (msg, vm, info) => {
//   console.log('Dit is een warn', msg, info, vm)
//   recordLog('errortje');
// }

Vue.prototype.$themes = themes;
Vue.prototype.$leadTags = leadTags;
Vue.prototype.$countries = countries;
Vue.prototype.$leadSources = leadSources;
Vue.prototype.$officialFreeDays = officialFreeDays;
Vue.prototype.$leaveItems = leaveItems;
Vue.prototype.$autoLanguageSelector = autoLanguageSelector;
Vue.prototype.$downPaymentInformation = downPaymentInformation;
Vue.prototype.$createNewClient = createNewClient;
Vue.prototype.$createNewLog = createNewLog;


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
