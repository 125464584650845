<template>
  <div>
    <div v-if="loading" style="position: relative; padding: 200px 0">
      <div class="thrive-loader"></div>
    </div>

    <div v-else>
      <v-data-table
        :headers="headers"
        :items="awarenessJobs"
       
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Awareness Jobs</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" min-width="700px" max-width="700px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  New Job
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container fluid  >
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          v-model="editedItem.siteName"
                          label="Name Site"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="editedItem.subject"
                          label="Onderwerp"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-switch
                          v-model="editedItem.active"
                          label="Active"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.site"
                          label="URL"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedItem.pushover.user"
                          label="Pushover User"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedItem.pushover.token"
                          label="Pushover Token"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="12">
                       <v-radio-group v-model="editedItem.type">
                        <v-radio value="visualization">
                          <template v-slot:label>
                            <div>Visualization<strong class="primary--text"> Track Visual Changes</strong></div>
                          </template>
                        </v-radio>
                        <v-radio value="keywords">
                          <template v-slot:label>
                            <div>Keywords<strong class="primary--text"> Track when Keywords occur</strong></div>
                          </template>
                        </v-radio>
                        <v-radio value="sitemap">
                          <template v-slot:label>
                            <div>Sitemap<strong class="primary--text"> Track a complete sitemap</strong></div>
                          </template>
                        </v-radio>
                      
                      </v-radio-group>
                      </v-col>

                        <div v-if="editedItem.type === 'visualization'">
                          <v-flex xs12 v-if="editedItem.simulationImage">
                            <v-img 
                              :src="editedItem.simulationImage"
                              
                              max-width="600"
                             
                              
                            >
                             <!-- <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template> -->
                            </v-img>
                           </v-flex>   

                          <v-flex xs12 class="mb-2 mt-2" v-if="editedItem.simulationInfo">
                             <strong style="font-size:16px" :class="editedItem.simulationInfo === 'successful' ? 'green--text' : 'red--text'  " :key="itemKey" > Status simulation: {{ editedItem.simulationInfo }} </strong>

                          </v-flex> 

                          <v-flex xs12 class="mb-2 mt-2" v-if="editedItem.errorMessage && editedItem.simulationInfo === 'failed'">
                             <v-textarea
                                disabled
                                filled
                                
                                label="Error Message"
                                :value="editedItem.errorMessage.message"
                              ></v-textarea>

                          </v-flex> 

                          <v-container fluid>
                            <v-row class="light--text">
                              <v-col cols="4">
                                  <v-select
                                  
                                    v-model="editedItem.device"
                                    :hint="`Emulate Device`"
                                    :items="devices"
                                    item-text="name"
                                    item-value="name"
                                    label="Select"
                                    persistent-hint
                                    
                                    filled
                                    dense
                                  ></v-select>
                              </v-col>
                              <v-col cols="4">
                                  <v-checkbox
                                  v-model="editedItem.additionalOptions"
                                  label="Ignore HTTPS"
                                  value="ignoreHTTPSErrors"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="4">
                                  <v-checkbox
                                    v-model="editedItem.additionalOptions"
                                    label="Disable Javascript"
                                    value="disableJavascript"
                                  ></v-checkbox>
                              
                              </v-col>
                            </v-row>
                          </v-container>

                         

                          <v-flex xs6 class="pb-2">
                            <v-text-field
                              filled
                              dense
                              v-model="editedItem.screenshotSelector"
                             
                              hint="Screenshot CSS Selector"
                              persistent-hint
                            ></v-text-field>
                          </v-flex> 

                           <v-flex xs6 class="pb-2">
                            <v-text-field
                              filled
                              dense
                              v-model="editedItem.cron"
                             
                              hint="Enter Cron"
                              persistent-hint
                            ></v-text-field>
                          </v-flex> 

                         <v-layout wrap v-for="(steps, index) in editedItem.steps" :key="itemKey + index" >
                         
                        
                          <v-flex xs5>
                           
                            <v-select
                             @change="selectType(index, steps.type.type)"
                              v-model="steps.type"
                              :hint="`Type of action`"
                              :items="selectTypes"
                              item-text="typeName"
                              item-value="type"
                              label="Select"
                              persistent-hint
                              return-object
                              filled
                              dense
                            ></v-select>
                          </v-flex>
                               
                          <v-flex xs5 class="pl-2" v-if="steps.type === 'wait'">
                            <v-text-field
                              filled
                              dense
                              v-model="steps.wait"
                              label="ms"
                              type="number"
                              hint="In miliseconds"
                              persistent-hint
                            ></v-text-field>
                          </v-flex> 
                         
                         
                          <v-flex xs5 class="pl-2" v-if="steps.type === 'block'">
                            <v-text-field
                              filled
                              dense
                              v-model="steps.selectorCSS"
                              hint="Paste your CSS selector"
                              label="CSS Selector"
                              persistent-hint
                            ></v-text-field>
                          </v-flex> 
                         
                         
                          <v-flex xs5 class="pl-2" v-if="steps.type === 'click'">
                            <v-text-field
                              filled
                              dense
                              v-model="steps.xPath"
                              hint="Paste your xPath"
                              persistent-hint
                              label="xPath Selector"
                            ></v-text-field>
                          </v-flex> 
                        
                      
                          <v-flex xs5 class="pl-2" v-if="steps.type === 'type'">
                            <v-text-field
                              filled
                              dense
                              v-model="steps.selectorCSS"
                              hint="Paste your CSS selector"
                              label="CSS Selector"
                              persistent-hint
                            ></v-text-field>
                          </v-flex> 
                          <v-flex xs5  v-if="steps.type === 'type'">
                            <v-text-field
                              filled
                              dense
                              v-model="steps.text"
                              label="Text"
                              hint="Type something.."
                              persistent-hint
                            ></v-text-field>
                          </v-flex> 
                          <v-flex xs5 class="pl-2" v-if="steps.type === 'type'">
                            <v-text-field
                              filled
                              dense
                              v-model="steps.delay"
                              label="ms"
                              type="number"
                              hint="Emulate typing speed in ms"
                              persistent-hint
                            ></v-text-field>
                          </v-flex> 

                          <v-flex xs1 class="pt-2" >
                            <v-btn icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="deleteStep(index);">
                            <v-icon dark>mdi-delete</v-icon>
                            </v-btn>
                          </v-flex> 
                         
                         <v-flex xs12 class="mt-2 mb-4">
                          <v-divider>
                          
                          </v-divider>
                         </v-flex>

        
                       
                        </v-layout>

                         <v-col cols="12" sm="6" md="4">
                          <v-btn fab :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addStep(editedItem);">
                           <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </v-col>

                        </div>

                       

                     <div class="text-center mt-4">
                    
                  </div>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :loading="loadingTest" color="blue" dark dense @click="dryRun()">
                    Test
                  </v-btn>
                  <v-btn v-show="!loadingTest" color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn v-show="!loadingTest && readyToSave && editedItem.simulationInfo == 'successful'" color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
              <v-card>
                <v-card-title class="text-h5">
                  Bevestig om {{editedItem.siteName}} met onderwerp {{ editedItem.subject }} te verwijderen.
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Annuleren
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    Bevestig
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>

      <!-- / Customer info dialog \
      <customer-information-dialog
        :customerDialog="clientDialog"
        :selectedClientOriginal="selectedClientOriginal"
        :selectedClient="selectedClient"
        :activeCompData="activeCompData"
        @customerDialogChange="clientDialog = $event"
      ></customer-information-dialog> -->
    </div>
  </div>
</template>

<script>
import { bus } from "../main";
import moment from "moment";
import db from "../firebase/init";
import axios from "axios";
import deviceList from "../modules/devices";



export default {
  name: "awarenessJobs",
  props: {
    urlStart: String,
    urlEnd: String,
    activeCompData: Object,
    activeCompDataOriginal: Object,
  },
  components: {
   
  },

  data: function () {
    return {
      expanded: [],
      loading: true,
      newAction: false,
      radioGroup: [{}],

      companies: [],
      branches: [],
      activeComp: "",
      devices: deviceList, 
      selectTypes: [
        { typeName: 'Wait', type: 'wait' },
        { typeName: 'Wait', type: 'wait' },
        { typeName: 'Block', type: 'block' },
        { typeName: 'Click', type: 'click' },
        { typeName: 'Typing', type: 'type' }
      ],
      selectedType: { typeName: 'Wait', type: 'wait' },
      readyToSave: false,

      
      selected: [],
      itemKey: 0,
      removeConversionsDialog: false,

      currentMonth: Number(moment().format("M")),
      selectedSources: [],
      filter: {
        selectedSources: [],
        active: false,
      },
      clientDialog: false,
      loadingTest: false,
      tableOptions: {
        sortBy: "timestampUnix",
        itemsPerPage: 20,
        sortDesc: false,
      },
      awarenessJobs: new Array(),

      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Site",
          align: "start",
          sortable: false,
          value: "siteName",
        },
        { text: "Type Action", value: "type",sortable: false  },
       
        { text: "URL", value: "site", sortable: false  },
        { text: 'Simulation', value: 'simulationInfo', sortable: false },
        { text: "Keywords", value: "keywords", sortable: false  },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: "Job Timing", value: "cron", sortable: false  },
        


        
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        active: true,
        device: "Desktop",
        siteName: "Website name",
        subject: "blog",
        site: "yourdomain.com (without http)",
        simulationInfo: "First time",
        steps: [],
        type: "visualization",
        simulationImage: "",
        pushover: {user: "", token: ""},
        cron: "*/5 * * * *",
        branches: ["hogansbeautybreukelen"],
        companyId: "hogansbeauty",
        additionalOptions: ["ignoreHTTPSErrors"],
        firstTime: true

      },
      defaultItem: {
        active: true,
        device: "Desktop",
        siteName: "Website name",
        subject: "blog",
        site: "yourdomain.com (without http)",
        simulationInfo: "First time",
        steps: [],
        type: "visualization",
        simulationImage: "",
        pushover: {user: "", token: ""},
        cron: "*/5 * * * *",
        branches: ["hogansbeautybreukelen"],
        companyId: "hogansbeauty",
        additionalOptions: ["ignoreHTTPSErrors"],
        firstTime: true

      },
      

    };
  },

  created() {
    bus.$on("cconversionUpdate", this.loadData);
    this.loadData();
    this.updateDate();
    this.now = moment().format("YYYY-MM-DD");
   
  },

  destroyed() {
    this.updatesRunning = false;
    this.checkForUpdates;
  },

  methods: {
  

    updateDate() {
      this.timeChecker = setInterval(countdown, 1);
      let vm = this;

      function countdown() {
        vm.today = moment().unix();
      }
    },

    loadData() {
      // if(this.activeCompData && this.activeCompData.id){

      db.collection("awarenessJobs")
        .where("companyId", "==", this.userCompany)

        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            let awarenessJob = doc.data();
            awarenessJob.docId = doc.id;

            this.awarenessJobs.push(awarenessJob);
          });
        })

        .then(() => {
          this.dataLoading = false;
          this.loading = false;
        })
        .catch((error) => {
          this.dataLoading = false;
          console.error("Laden van conversies is niet gelukt: ", error);
        });
    },

    
    editItem(item) {
      console.log(item)
      this.editedIndex = this.awarenessJobs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    addStep(item) {
      console.log("Item add", item)
      
      // this.editedItem = Object.assign({}, item);
      console.log("this.awarenessJobs[this.editedIndex].steps", this.editedItem.steps)
      
      this.editedItem.steps.push({type: "wait", wait:2000})
      // Object.assign(this.awarenessJobs[this.editedIndex], this.editedItem)
     
    },

    deleteStep(index) {
      this.editedItem.steps.splice(index,1)
    },

     selectType(index, type) {
      
      if (type === "wait") {
        this.editedItem.steps[index] = {type: "wait", wait: 0}
      } else if (type === "block") {
        this.editedItem.steps[index] = {type: "block", selectorCSS: "#your-element"}
      } else if (type === "click") {
        this.editedItem.steps[index] = {type: "click", xPath: "/html/your/xPath/"}
      } else if (type === "type") {
        this.editedItem.steps[index] = {type: "type", selectorCSS: "#your-form-element", text: "Your text", delay:100}
      }
      this.itemKey++;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
   
      db.collection("awarenessJobs").doc(this.editedItem.docId)
      .delete()
      .then(() => {
          console.log("Awareness successfully deleted!");
      })
      .catch((error) => {
          console.error("Error removing document: ", error);
      });

      this.awarenessJobs.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    dryRun() {

      this.loadingTest = true;
       const configAxiosPOST = {
          headers: {
            "Content-Type": "application/json",
          }
       }

        console.log("Start Pose", this.editedItem);

       axios.post(`https://europe-west1-thrive-365.cloudfunctions.net/simulateVisualization`, this.editedItem, configAxiosPOST)
      // axios.post(`http://localhost:5001/thrive-hogans/europe-west1/simulateVisualization`, this.editedItem, configAxiosPOST)
            .then((response) => {
              console.log(response.data);
              // Client does not exist
              this.editedItem.simulationImage = response.data.url;
              this.editedItem.simulationInfo = response.data.status;
              this.editedItem.errorMessage = {};
              this.editedItem.errorMessage.message = response.data.message;
              this.editedItem.errorMessage.timestamp = new Date();
              if(response.data.status === "successful") {
                this.editedItem.errorMessage = {};
                 this.readyToSave = true;
              } else {
                this.readyToSave = false;
              }
              this.loadingTest = false;
              this.itemKey++
              
            })
              
            .catch((err) => {
              this.loadingTest = false;
              console.error("Error axios postClientExist =>", err);
              this.editedItem.simulationInfo = "failed";
              this.itemKey++
             
            })

      // db.collection("awarenessJobs")
      //   .doc(this.editedItem.docId)
      //   .set(
      //     {
      //       companies: admin.firestore.FieldValue.arrayUnion(bookingCache.companyData.companyId),
      //     },
      //     { merge: true }
      //   )
      //   .then(() => {
      //     //The new Client ID by DocId
      //     clientId = bookingCache.clientExist.clientId;
      //     return resolve();
      //   })
      //   .catch((err) => {
      //     console.error("Firebase Update Client error =>", err);
      //   });

    },


    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {

        Object.assign(this.awarenessJobs[this.editedIndex], this.editedItem);
        this.editedItem.errorMessage = new Object();

         db.collection("awarenessJobs")
        .doc(this.editedItem.docId)
        .set(this.editedItem,
          { merge: true }
        )
        .then(() => {
          console.log("Awareness Job Updated to DB")
        })
        .catch((err) => {
          console.error("Firebase Update AwarenessJobs error =>", err);
     
        });
      } else {
        this.editedItem.firstTime = false;
        this.awarenessJobs.push(this.editedItem);
        db.collection("awarenessJobs")
       
        .add(this.editedItem)
        .then(() => {
          console.log("Awareness Job Added to DB")
        })
        .catch((err) => {
          console.error("Firebase Add AwarenessJobs error =>", err);
     
        });
      }
      this.close();
    },
  },


  computed: {
    updateCompany() {
      return (id) => _.find(this.companies, ["id", id]);
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Job";
    },

    randomHipster() {
      if (this.conversionsFiltered.length == 0) {
        let randomNumber = Math.floor(Math.random() * 12) + 1;
        let hipsterClass = "hipster-" + randomNumber;
        return hipsterClass;
      } else {
        return null;
      }
    },

    userLanguage() {
      return this.$store.state.locale ? this.$store.state.locale : "NL";
    },
    userCompanies() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.accessCompanies
        : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.company
        : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.name
        : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.surname
        : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.userRole
        : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee
        ? this.$store.state.connectedEmployee.userId
        : this.$store.state.userId;
    },
    userOperator() {
      return this.$store.state.userOperator;
    },
    companyPrimaryColor() {
      return this.$store.state.companyPrimaryColor;
    },
    companySecondaryColor() {
      return this.$store.state.companySecondaryColor;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    filteredCompanies() {
      if (this.searchCompanies && this.searchCompanies.length > 0) {
        return this.companies.filter((company) => {
          return company.name
            .toLowerCase()
            .includes(this.searchCompanies.toLowerCase());
        });
      } else {
        return this.companies;
      }
    },
    changed() {
      if (
        _.isEqual(this.activeCompData, this.activeCompDataOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // editedItem: {
    //   handler (newValue) {
    //     if(this.dialog){
    //       console.log("something changed", newValue);
    //       this.readyToSave = false;
    //     }
        
    //     },
    //    deep: true,
    // }
  },
};
</script>
<!-- 
<style>
.update-snackbar .v-snack__wrapper {
  min-width: 450px !important;
}

.attempt-box {
  display: block;
  width: 32px;
  height: 32px;
  background-color: var(--v-text-base);
  border-radius: 50px;
  font-size: 11px;
  text-align: center;
  line-height: 34px;
  color: var(--v-menu-base);
}

.attempt-box span {
  font-size: 15px;
  color: var(--v-menu-base);
  padding: 0 1px 0 0;
}

.extra-conversion-info h3 {
  padding: 0 0 10px 0;
}

.conversion-client-info .info-wrapper:after {
  display: block;
  clear: both;
  content: "";
}

.conversion-client-info .info-label {
  font-weight: 600;
  width: 120px;
  float: left;
  display: block;
}

.conversion-client-info .info-data {
  width: calc(100% - 120px);
  display: block;
  float: left;
}

.no-conversions {
  width: 280px;
  margin: 0px auto;
  padding: 80px 0;
}

.hipster-image {
  width: 130px;
  height: 130px;
  margin: 0 auto;
}

.no-conversions p {
  font-weight: 600;
  text-align: center;
  font-size: 16px;
}

.conversion-selected-period p {
  text-align: center;
  padding: 15px 0 5px 0;
  text-transform: lowercase;
}

.v-toolbar.custom-toolbar .v-toolbar__content,
.v-toolbar.custom-toolbar .v-toolbar__extension {
  padding: 0 !important;
}

.conversion-table table head td:not(:nth-child(1)),
.conversion-table table tbody td:not(:nth-child(1)),
.conversion-table table thead th:not(:nth-child(1)),
.conversion-table table tbody th:not(:nth-child(1)),
.conversion-table table thead td:first-child,
.conversion-table table tbody td:first-child,
.conversion-table table thead th:first-child,
.conversion-table table tbody th:first-child {
  padding: 0 8px !important;
}

.close-conversion-buttons {
  border-radius: 5px;
  overflow: hidden;
}

.close-conversion-buttons:after {
  display: block;
  content: "";
  clear: both;
}

.close-conversion-button {
  width: 33.3%;
  border: 1px solid #251433;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  color: #251433;
  float: left;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.close-conversion-button.left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.close-conversion-button.middle {
  border-right: none;
  border-left: none;
}

.close-conversion-button.right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.close-conversion-button.active {
  background-color: #251433;
  color: #fff;
}

tr.buzy {
  background-color: rgba(0, 0, 0, 0.09);
}

.custom-label {
  color: var(--v-text-base);
}
</style>

<style scoped>
.usertype-total {
  padding: 10px;
}

.usertype-total-number {
  text-align: center;
  font-size: 38px;
}
.usertype-total-desc {
  text-align: center;
  font-size: 16px;
}
</style> -->
