<template>
  <v-dialog v-model="deleteInformation.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-text class="text-center ma-0 pt-4">
        <v-avatar 
          size="56"
          light
          class="ma-4 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
        </v-avatar>
        <h3 class="mb-4" v-html="deleteInformation.title"></h3> 
        <p class="ma-0" v-html="deleteInformation.info"></p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn outlined depressed :disabled="deleteInformation.deleting" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeDeleteDialog()">
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn dark :loading="deleteInformation.deleting" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleteDialogFunction()">
                {{ $t('general.remove', $store.state.locale) }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { bus } from "../../main";

  export default {
    name: "general-delete-dialog",
    data() {
      return {
        deleteInformation: new Object(),
      };
    },

    created(){
      this.closeDeleteDialog();
      bus.$on("deletedModal", (info, type) => {
        if(type == 'open'){
          this.deleteInformation = info;
          this.deleting == false;
          this.deleteInformation.dialog = true;
        }
        else{
          this.closeDeleteDialog();
        }
      });
    },

    methods: {
      closeDeleteDialog(){
        this.deleteInformation = {
          dialog: false,
          id: null,
          title: null,
          info: null,
          emit: null,
          deleting: false,
        };
        this.deleteInformation.dialog = false;
      },

      deleteDialogFunction(){
        this.deleteInformation.deleting = true;
        bus.$emit(this.deleteInformation.emit, this.deleteInformation.id);
      }
    },
  };

  //   HOW TO USE THE GENERAL DELETE MODAL
  // 
  //   1. (Source.vue File) Collect data in function and send with bus.$emit
  // 
  //   showDeleteDialog(id, type, deleteFunction){                                                    --- EXAMPLE FUNCTION (find original in treatments.vue or products.vue)
  //     let deleteInformation = new Object();
  //     deleteInformation.id = id;                                                                   --- Specific Document ID to delete (optional)
  //     deleteInformation.emit = deleteFunction;                                                     --- What function to execute when delete is confirmed
  //
  //    if(this.selected.length > 1){                                                                 --- Set Modal Title and Info
  //       deleteInformation.title = this.$t('products.removeProducts', this.$store.state.locale);  
  //       deleteInformation.info = this.$t('products.removeProductsInfo', this.$store.state.locale);
  //    }
  //    else{
  //       deleteInformation.title = this.$t('products.removeProduct', this.$store.state.locale);
  //       deleteInformation.info = this.$t('products.removeProductInfo', this.$store.state.locale);
  //    }
  //
  //    bus.$emit('deletedModal', deleteInformation, 'open');                                         --- Send Data and Type 'open' to General Delete Modal
  //  }
  // 
  //   
  //   2. General Delete Modal is showing wit send data
  // 
  //
  //   3. Confirm Delete Action in General Delete Modal
  // 
  //   bus.$emit(this.deleteInformation.emit, this.deleteInformation.id);                            --- Send bus.$emit to Source.vue (with optional id)
  //
  //
  //   4. Listen to Bus Emit in Source.vue  File in created
  //
  //   created() {
  //     bus.$on("deleteProducts", (id) => {this.deleteProducts(id) });                              --- What function to execute when delete bus is emitted is confirmed?
  //   }
  //   
  //   5. Execute function in Source.vue file
  //
  //   async deleteProducts() {
  //       await new Promise((resolve)=>{
  //         let counter = 0; 
  //         this.selected.forEach(element => {
  //           db.collection("products")
  //           .doc(element.id)
  //           .delete()
  //           counter++
  //           if(counter == this.selected.length){
  //             resolve()
  //           }
  //         });
  //       })
  //       this.selected = new Array()
  //       this.loadProducts();
  //       bus.$emit('deletedModal', null, 'close');                                                 --- Send Type 'close' to close the General Delete Modal
  //   }
  //

</script>
