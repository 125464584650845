<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type == 'new'">{{$t('products.addNewSupplier', $store.state.locale)}}</h3>
        <h3 v-if="type == 'edit'">{{$t('products.editSupplier', $store.state.locale)}}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeProductSupplierDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>
        <v-form lazy-validation>
          <v-text-field
            :label="$t('general.name', $store.state.locale)"
            v-model="productSupplier.name"
            autocomplete
            required
            dense
            outlined
            background-color="background"
            class="mt-4"
            hide-details
          ></v-text-field>
          <v-select
            :items="filteredCompanies"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            background-color="background"       
            item-text="name"
            item-value="id"
            hide-details
            multiple
            chips
            small-chips
            dense
            class="mt-4"
            v-model="productSupplier.companies"
            :no-data-text="$t('general.noCompanies', $store.state.locale)"
            :label="$t('products.activeCompanies', $store.state.locale)"
            outlined
            >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content  class="pt-0" >
                  <v-text-field
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    placeholder="Zoeken"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    outlined
                    background-color="background"
                    class="ma-0"
                    v-model="searchCompanies"
                    hide-details
                    dense
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <div v-if="searchCompanies && searchCompanies.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredCompanies.length }} van {{ companies.length }} bedrijven</div>
              <v-divider v-if="searchCompanies && searchCompanies.length > 0"></v-divider>
            </template>
          </v-select>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                @click="closeProductSupplierDialog()"
                >
                {{$t('general.cancel', $store.state.locale)}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type == 'new'"
                dark  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addProductSupplier()"
                >
                {{$t('products.addSupplier', $store.state.locale)}}
                <v-icon right dark>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn
                v-if="type == 'edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="updateProductSupplier()"
                >
                <div v-if="changed">{{$t('general.saveChanged', $store.state.locale)}} <v-icon>mdi-chevron-right</v-icon></div>
                <div v-else>{{$t('general.saved', $store.state.locale)}} <v-icon>mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
import db from "../../firebase/init";
import '@firebase/firestore';
import { bus } from "../../main";

export default {
  name: "product-supplier-form",
  props: {
    companies: Array
  },

  data() {
    return {
      showDialog: false,
      feedback: null,
      searchCompanies: "",
      productSupplier: new Object(),
      productSupplierOriginal: new Object(),
      type: 'new',
    };
  },

  created() {
    bus.$on("ProductSupplierModal", (supplier, type) => {
      this.type = type
      if(type == "edit"){
        this.productSupplier = _.cloneDeep(supplier);
        this.productSupplierOriginal = _.cloneDeep(this.productSupplier);
      }
      else{
        this.resetSupplier()
      }
      this.showDialog = true;
    });
  },


  methods: {
    closeProductSupplierDialog() { // Close Dialog Modal
      this.showDialog = false;
      this.resetSupplier();
    },

    resetSupplier(){ // Reset Supplier values and feedback
      this.feedback = null;
      this.productSupplier.name = ""; 
      this.productSupplier.companies = new Array(); 
    },

    async addProductSupplier() { // Add new Supplier to database
      this.feedback = null;
      if (this.productSupplier.name) {
        try {
          await db.collection("productSuppliers")
          .add({
            name: this.productSupplier.name,
            companies: this.productSupplier.companies
          })
          this.$emit("updateData");
          this.closeProductSupplierDialog();
        }
        catch (error) {
          this.feedback = this.$t('general.addError', this.$store.state.locale);
          console.error("Error adding ProductSupplier: ", error);
        }
      } 
      else {
        this.feedback = this.$t('products.addSupplierFeedback', this.$store.state.locale);
      }
    },

    async updateProductSupplier() { // Save changed Supplier to database
      this.feedback = null;
      if(this.productSupplier.name){


        try { 
          await db.collection("productSuppliers")
          .doc(this.productSupplier.id)
          .update({
            name: this.productSupplier.name,
            companies: this.productSupplier.companies
          })

          this.$emit("updateData");
          this.closeProductSupplierDialog();
        }
        catch (error) {
          this.feedback = this.$t('general.addError', this.$store.state.locale);
        }
      }
      else{
        this.feedback = this.$t('products.editSupplierFeedback', this.$store.state.locale);
      }
    },
  },
  computed: {
    filteredCompanies(){
      if (this.searchCompanies && this.searchCompanies.length > 0){
        return this.companies.filter(company => {
          return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
        });
      }
      else {
        return this.companies;
      }
    },
    changed() {
      if (
        _.isEqual(this.productSupplier, this.productSupplierOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>