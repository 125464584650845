import { render, staticRenderFns } from "./LeadCampaigns.vue?vue&type=template&id=92cca1bc&scoped=true&v-slot%3Aactivator=%7B%20on%20%7D&"
import script from "./LeadCampaigns.vue?vue&type=script&lang=js&"
export * from "./LeadCampaigns.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92cca1bc",
  null
  
)

export default component.exports