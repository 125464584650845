<template>
  <div>
    <v-text-field outlined dense :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" v-model="search" :placeholder="$t('general.search', $store.state.locale)" prepend-inner-icon="mdi-magnify" class="ma-0 pa-0" hide-details></v-text-field>

    <v-card outlined :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="client-list-card mt-3 custom-algolia">
      <ais-instant-search :search-client="algoSearch" index-name="thrive_clients" class="pa-0 ma-0">
        <v-list class="pa-0 ma-0">
          <v-list-item-group :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedClient">
            <ais-hits class="pa-0 ma-0">
              <template slot="item" slot-scope="{ item }" >
                
                <v-list-item :disabled="clientIsExcluded(item.objectID)" class="pa-2 py-1 ma-0" @click="selectClient(item)" :key="item.objectID">
                  <v-list-item-avatar color="#f1f1f1">
                    <div class="avatarGen header-avatar" v-html="genAvatar(item.firstName + ' ' + item.surname)"></div>
                  </v-list-item-avatar>

                  <v-list-item-content class="py-1">
                    <v-list-item-title>
                      <ais-highlight attribute="name" :hit="item" highlightedTagName="mark" />&nbsp;<ais-highlight attribute="surname" :hit="item" highlightedTagName="mark" />
                      <!-- <v-chip x-small v-if="item.gender">{{ getGender(item.gender) }}</v-chip> -->
                      <v-chip v-if="item.gender" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 custom-chip-style" >
                        {{ getGender(item.gender) }}
                      </v-chip>

                      <v-chip v-if="item.minor"x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 custom-chip-style" >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" x-small left>mdi-human-female-boy</v-icon>
                        {{ $t('general.'+ item.minorType, $store.state.locale) }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.emails && item.emails.length > 0">
                      <span v-for="(email, index) in item.emails" :key="index">
                        <span v-if="email.primary"><v-icon size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email</v-icon> {{ email.email }} </span>
                      </span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.phones">
                      <span v-for="(phone, index) in item.phones" :key="index">
                        <span v-if="phone.primary"><v-icon size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-phone</v-icon> {{ phone.phone.international }}</span>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>          

                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
                
              </template>
            </ais-hits>
          </v-list-item-group>
        </v-list>
        <ais-state-results>
          <p class="text-center pa-2 pt-4 pb-1" slot-scope="{ state: { query }, results: { hits } }" v-show="!hits.length">
            <i>{{ $t('clients.noResultsFoundFor', $store.state.locale) }}  {{ query }}</i>
          </p>
        </ais-state-results>
        <ais-configure :query="search" :facetFilters=" [`companyId:${this.$store.state.activeCompany.id}`]"/>
      </ais-instant-search>

      
    </v-card>
  </div>
</template>

<script>
  import moment from "moment";
  import { bus } from "../../main";
  import axios from "axios";
  import algoliasearch from 'algoliasearch/lite';
  import 'instantsearch.css/themes/satellite-min.css';
  import { generateMeshAvatar } from "@/modules/avatarGen.js";

  export default {
    name: "clientSearch",
    props: {
      source: String,
      exclude: Array
    },
    data() {
      return {
        algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
        search: "",
        clients: [],
        genders: [
          {
            id: 1,
            name: this.$t('general.man', this.$store.state.locale)
          },
          {
            id: 2,
            name: this.$t('general.woman', this.$store.state.locale)
          }
        ],
        selectedClient: null,
        clientData: new Object(),
      }   
    },

    created(){   
      bus.$on("resetClientSearch", () => {
        this.search = "";
      });
    },

    methods:{   

      genAvatar(fullName){
        return generateMeshAvatar(fullName);
      },

      resetClient(){
        this.clientData = {
          firstName: "",
          firstNameRules: [(v) => !!v || this.$t('booking.firstnameRequired', this.$store.state.locale)],
          surname: "",
          surnameRules: [(v) => !!v || this.$t('booking.lastnameRequired', this.$store.state.locale)],
          emails: new Array(),
          selectedEmail: "",
          id: null,
          gender: 2,
          language: null,
          phones: new Array(),
          selectedPhone: "",
          emailRules: [this.$t('booking.emailInvalid', this.$store.state.locale)],
        };
      },

      selectClient(selectedClient) {
        if(this.clientData.objectID == selectedClient.objectID){
          this.resetClient();
        }
        else{
          this.clientData = _.cloneDeep(selectedClient);
        }

        // if(this.source == "clientInformation"){
        //   this.$emit("clientSelect", this.clientData);
        // }
        this.$emit("clientSelect", this.clientData);

      },

      clientIsExcluded(id){ // Disable client from selection 
        if(this.exclude && this.exclude.length > 0){
          return this.exclude.find((client)=>{return client.id == id }) ? true : false;
        }
        else{
          return false;
        }
      },

      getGender(gender){
        if(gender == 1){
          return "Man"
        }
        else if(gender == 2){
          return "Vrouw"
        }
        else{
          return "Onbekend"
        }
      },
    },
  };
</script>
