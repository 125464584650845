<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type =='new'">{{ $t('admin.addNewAbility', $store.state.locale)}}</h3>
        <h3 v-if="type =='edit'">{{ $t('admin.editAbility', $store.state.locale)}}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeAbilityDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text class="ma-0">
        <v-text-field
          class="mt-4"
          :label="$t('general.name', $store.state.locale) + ` (${getLanguageName('nl').languageName[$store.state.locale.toLowerCase()]})`"
          v-model="ability.name.nl"
          autocomplete
          outlined
          required
          dense
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          >
          <template v-slot:prepend-inner>
            <country-flag style="margin: -9px -5px 0 -10px" country="nl" size="small"/>
          </template>
        </v-text-field>

        <v-text-field
          class="mt-4"
          :label="$t('general.name', $store.state.locale) + ` (${getLanguageName('en').languageName[$store.state.locale.toLowerCase()]})`"
          v-model="ability.name.en"
          autocomplete
          outlined
          required
          dense
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          >
          <template v-slot:prepend-inner>
            <country-flag style="margin: -9px -5px 0 -10px" country="gb" size="small"/>
          </template>
        </v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeAbilityDialog()"
                >
                {{ $t("general.cancel", $store.state.locale) }} 
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type =='edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateAbility()"
                >
                <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right>mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>  
              </v-btn>
              <v-btn
              v-if="type =='new'"
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addAbility()"
                >
                {{ $t('admin.addAbility', $store.state.locale)}}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
  import { bus } from "../../main";
  import db from "../../firebase/init";
  import '@firebase/firestore';

  export default {
    name: "ability-dialog",

    data() {
      return {
        feedback: null,
        ability: {
          name: new Object()
        },
        abilityOriginal: new Object(),
        showDialog: false,
        type: 'new'
      };
    },
    created() {
      bus.$on("AbilityModal", (ability, type) => {
        this.type = type
        if(type == "edit"){
          this.ability  = _.cloneDeep(ability);
          this.abilityOriginal = _.cloneDeep(this.ability);
        }
        else{
          this.type = 'new'
          this.resetAbility()
        }
        this.showDialog = true;
        
      });
    },

    methods: {

      resetAbility(){
        this.ability = {
          name: {
            nl: "",
            en: ""
          }
        };
        this.abilityOriginal = new Object();
        this.feedback = null;
        this.type = 'new';
      },

      closeAbilityDialog() {
        this.showDialog = false;
        this.resetAbility()
      },

      getLanguageName(item){
        return this.$countries.find(language => { return language.id == item });
      },


      async updateAbility() {
        this.feedback = null;
        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "abilityEditted",
          actionItem: this.abilityOriginal.name,
          extra:[
            {
              name: "originalData",
              value: ""
            },
            {
              name: "name",
              value: this.abilityOriginal.name
            },
            {
              name: "changedData",
              value: ""
            },
            {
              name: "name",
              value: this.ability.name
            }
          ]
        }

        try {
          await db.collection("abilities")
          .doc(this.ability.id)
          .update({
            name: this.ability.name
          })
          await this.$createNewLog("info", logData);
          this.$emit("updateData");
          this.closeAbilityDialog();
        }
        catch (error) {
          console.error("Error ipdating ability: ", error);
          this.feedback = this.$t("general.addError", this.$store.state.locale) ;
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      },

      async addAbility() {
        this.feedback = null;
        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "abilityAdded",
          actionItem: this.abilityOriginal.name,
          extra:[
            {
              name: "name",
              value: this.ability.name
            }
          ]
        }
        if(this.ability.name.en && this.ability.name.nl) {
          try {
            await db.collection("abilities")
            .add({
              name: this.ability.name,
            })

            await this.$createNewLog("info", logData);
            this.$emit("updateData");
            this.closeAbilityDialog();
          }
          catch (error) {
            console.error("Error adding ability: ", error);
            this.feedback = this.$t("general.addError", this.$store.state.locale) ;
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
          }
        }
        else {
          this.feedback = this.$t('admin.addNewAbilityFeedback', this.$store.state.locale);
        }
      }
    },
    computed: {
      changed() {
        return  _.isEqual(this.ability, this.abilityOriginal) ? false : true;
      }
    }
  };
</script>