<template>
  <div class="tab-component-wrapper">
    <div v-if="!loading">
      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" v-if="abilities && abilities.length > 0">
         <v-container grid-list-md class=" ma-0 pa-3" fluid>
          <v-layout row wrap>
            <v-flex md4 xs12>
              <v-text-field
                class="px-2"
                v-model="search"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                name="searchfield"
                :placeholder="$t('general.search', $store.state.locale)"
                outlined
                dense
                hide-details                                                
                prepend-inner-icon="mdi-magnify"
                autocomplete="disabled"
                clearable
                single-line>
              </v-text-field>
            </v-flex>

            <v-flex md8 xs12 class="text-right">
              <v-btn class="px-2" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addAbility()">
                <v-icon left>mdi-plus</v-icon> {{ $t('admin.addAbility', $store.state.locale) }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="abilities"
          :search="search"
          :itemsPerPage.sync="pages"
          hide-default-footer
          item-key="name"
          :no-results-text="`${$t('treatments.noResultsFoundFor', $store.state.locale) } ${search}`"
          dense
          class="dense-table"
          >
          <template v-slot:item="{item }">
            <tr>
              <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) }}</td>
              <td>
                <v-btn icon class="mx-0" @click="editAbility(item)">
                  <v-icon small color="text">mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <div class="no-data-message" v-else>
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
        </v-avatar>
        <p>{{ $t('admin.noAbilitiesFound', $store.state.locale) }}</p>
        <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addAbility()">
          <v-icon left>mdi-plus</v-icon>{{ $t('admin.addAbility', $store.state.locale) }} 
        </v-btn>
      </div>
    </div>

    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader" :class="$store.state.companyTheme"></div>
      </div>
    </div>

    <!-- / Ability Dialog \ -->
      <ability-form @updateData="loadAbilities()"></ability-form>
    <!-- \ Ability Dialog / -->
  </div>
</template>
     


<script>
  import '@firebase/firestore';
  import db from "@/firebase/init";
  import { bus } from "../main";
  import AbilityForm from "@/components/modals/AbilityForm.vue";

  export default {
    components: {
      "ability-form": AbilityForm
    },
    name: "abilities",
    data(){
      return{
      search: "",
      loading: true,
      pages: -1,
      abilities: new Array(),
      headers: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "ability"
        },
        {
          text: "",
          align: "right",
          value: "edit",
          width: "60px"
        }
      ]
    }
  },

  created() {
    this.loadAbilities();
  },

  methods: {
    addAbility() {
      console.log("addAbility")
      bus.$emit("AbilityModal", null, "new");
    },
    editAbility(item) {
      bus.$emit("AbilityModal", item, "edit");
    },

    async loadAbilities() {
      this.loading = true;
      await db.collection("abilities") 
      .get()
      .then(snap => {
        this.abilities = [];
        snap.forEach(doc => {
          let ability = doc.data();
          ability.id = doc.id;
          this.abilities.push(ability);
        });
      })
      this.loading = false;  
    }
  },
};
</script>
