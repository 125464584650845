<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type =='edit'"> {{$t('products.changeProductCategory', $store.state.locale)}}</h3>
        <h3 v-if="type =='new'"> {{$t('products.addProductCategory', $store.state.locale)}}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeProductCategoryDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>
        <v-text-field
          label="Naam"
          class="mt-4"
          v-model="productCategory.name.nl"
          autocomplete
          dense
          required
          outlined
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          >
          <template v-slot:prepend-inner >
            <country-flag style="margin: -9px -5px 0 -10px" country="nl" />
          </template>
        </v-text-field>
        <v-text-field
          label="Name"
          class="mt-4"
          v-model="productCategory.name.en"
          autocomplete
          required
          dense
          outlined
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          >
          <template v-slot:prepend-inner >
            <country-flag style="margin: -9px -5px 0 -10px" country="gb" />
          </template>
        </v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                @click="closeProductCategoryDialog()"
                >
                {{$t('general.cancel', $store.state.locale)}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type =='edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="updateProductCategory()"
                >
                <div v-if="changed">{{$t('general.saveChanged', $store.state.locale)}} <v-icon>mdi-chevron-right</v-icon></div>
                <div v-else>{{$t('general.saved', $store.state.locale)}} <v-icon>mdi-check</v-icon></div>  
              </v-btn>
              <v-btn
                v-if="type =='new'"
                dark  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" 
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addProductCategory()"
                >
                {{$t('products.addProductCategory', $store.state.locale)}}
                <v-icon right dark>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
  import { bus } from "../../main";
  import db from "../../firebase/init";
  import '@firebase/firestore';

  export default {
    name: "product-categorie-form",

    data() {
      return {
        feedback: null,
        productCategory: {
          name: new Object()
        },
        productCategoryOriginal: new Object(),
        type: 'new',
        showDialog: false
      };
    },
    created(){
      bus.$on("ProductCategoryModal", (productCategory, type) => {
          this.tab = 0;
          this.type = type
          if(type == "edit"){
            this.productCategory = productCategory;
            this.productCategoryOriginal = _.cloneDeep(this.productCategory);
          }
          else{
            this.type = 'new'
            this.resetProductCategory()
          }
          this.showDialog = true;
          
        });
    },
    methods: {
      closeProductCategoryDialog() {
        this.showDialog = false;
        this.resetProductCategory()
      },

      resetProductCategory(){
        this.feedback = null;
        this.productCategory = {
          name: new Object()
        };
        this.productCategoryOriginal = new Object();
        this.type = 'new';
      },

      updateProductCategory() {
        this.feedback = null;
        if(this.productCategory.name){
          db.collection("productCategories")
          .doc(this.productCategory.id)
          .update({
            name: this.productCategory.name
          })
          .then(() => {
            this.$emit("updateData");
            this.closeProductCategoryDialog();
          })
          .catch(error => {
            console.error("Error updating ProductCategory: ", error);
          });
        }
        else{
          this.feedback = this.$t('product.changeProductCategoryFeedback', this.$store.state.locale);
        }
      },

      addProductCategory() {
      if (this.productCategory.name) {
        db.collection("productCategories")
          .add({
            name: this.productCategory.name,
          })
          .then(() => {
            this.$emit("updateData");
            this.closeProductCategoryDialog();
          })
          .catch(error => {
            console.error("Error adding ProductCategory: ", error);
          });
      } 
      else {
        this.feedback = this.$t('products.addProductCategoryFeedback', this.$store.state.locale);
      }
    }
    },
    computed: {
      changed() {
        return _.isEqual(this.productCategory, this.productCategoryOriginal) == false ? true : false;
      }
    }
  };
</script>