<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>{{ $t('admin.editCompany',$store.state.locale) }}</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeEditCompDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text class="pt-4 mb-4">
      <v-card outlined class="ma-0 mb-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="$store.state.userRoleNumber < 2 && !$store.state.demoMode">
            <v-btn dark @click="copyTextToClipboard(selectedCompany.id)" class="ma-1 my-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="text-transform: none">
              <strong>{{ selectedCompany.id }}</strong>
            </v-btn>
            <div style="display: inline-block" v-if="selectedCompanyIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(selectedCompany.id)">
              <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon> Kopieër DocId
            </div>
            <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied">DocId gekopieërd</div>
          </v-card>

      <v-text-field
        :label="$t('admin.companyName',$store.state.locale)"
        v-model="selectedCompany.name"
        autocomplete
        prepend-inner-icon="mdi-domain"
        outlined
        dense
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
      ></v-text-field>
      <v-text-field
        :label="$t('general.email',$store.state.locale)"
        v-model="selectedCompany.email"
        :rules="emailRules"
        autocomplete
        required
        dense
        prepend-inner-icon="mdi-email"
        outlined
        class="mt-4"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
      ></v-text-field>
      <v-text-field
        label="Url"
        v-model="selectedCompany.url"
        required
        dense
        prepend-inner-icon="mdi-web"
        outlined
        class="mt-4"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
      ></v-text-field>
      <v-text-field
        :label="$t('admin.phonenumber',$store.state.locale)"
        v-model="selectedCompany.phonenumber"
        prepend-inner-icon="mdi-phone"
        required
        dense
        outlined
        class="mt-4"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
      ></v-text-field>
      <v-select
        :label="$t('general.languages',$store.state.locale)"
        v-model="selectedCompany.languages"
        :items="availableLanguages"
        :item-text="item => item.languageName[($store.state.locale).toLowerCase()]"
        item-value="id"
        required
        outlined
        chips
        small-chips
        dense
        deletable-chips
        prepend-inner-icon="mdi-flag"
        class="mt-4"
        hide-details
        multiple
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
      ></v-select>
      <v-select
        :label="$t('admin.theme',$store.state.locale)"
        v-model="selectedCompany.theme"
        :items="themes"
        item-text="name"
        item-value="id"
        required
        outlined
        dense
        class="mt-4"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
      ></v-select>
      <v-switch
        class="ma-0 mt-4 pa-0"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
        :label="$t('admin.active',$store.state.locale)"
        v-model="selectedCompany.active"
      ></v-switch>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  
              @click="closeEditCompDialog()"
              >
              {{ $t('general.cancel', $store.state.locale) }}
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              :disabled="!changed" 
              :depressed="!changed"  
              :dark=" changed"  
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
              @click="updateCompany()"
              >
              <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
              <div v-else> {{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>   
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>


<script>
import db from "../../firebase/init";
import firebase from "firebase/app";
import "firebase/storage";

export default {
  name: "edit-comp-form",
  props: {
    selectedCompany: Object,
    selectedCompanyOriginal: Object,
    themes: Array
  },

  data() {
    return {
      emailRules: [
        v => !!v || this.$t('general.mandatoryEmailField',this.$store.state.locale), 
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('general.mandatoryEmailValid',this.$store.state.locale)
      ],
      feedback: null,
      recordId: null,
      selectedCompanyIdCopy: false,
    };
  },

  methods: {


    closeEditCompDialog() {
      this.$emit("editCompDialogChange", false);
    },

    updateCompany() {
      let connections = {};
      if(this.selectedCompany.connections){
        connections = this.selectedCompany.connections;
      }
      db.collection("companies")
        .doc(this.selectedCompany.id)
        .set({
          active: this.selectedCompany.active,
          name: this.selectedCompany.name,
          email: this.selectedCompany.email,
          url: this.selectedCompany.url,
          theme: this.selectedCompany.theme,
          connections: connections,
          languages: this.selectedCompany.languages
        }, { merge: true })
        .then(() => {
          this.$emit("updateData");
          this.closeEditCompDialog();
        })
        .catch(error => {
          console.error("Error updating Company: ", error);
        });
    },
    copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }

        navigator.clipboard.writeText(text).then(
          () => {
            this.selectedCompanyIdCopy = true;
            setTimeout(() => {
              this.selectedCompanyIdCopy = false;
            }, 1000);
          },
          function (err) {
            console.error("Async: Could not copy text: ", err);
          }
        );
      },
  },
  computed: {
    availableLanguages(){
      return this.$countries.filter(language => { // Filter rich languages data from languages
        if(language.userLanguage){ return language }
      });
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },
    changed() {
      return  _.isEqual(this.selectedCompany, this.selectedCompanyOriginal) ? false : true;
    },
  }
};
</script>
