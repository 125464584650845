<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type =='new'">{{ $t("tags.addNewTag", $store.state.locale) }}</h3>
        <h3 v-if="type =='edit'">{{ $t("tags.editTag", $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeTagDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>

        <!-- / Select imput language \ -->
        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="pa-2 mb-4">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12> 
              <v-avatar
                size="54"
                light
                style="float: left"
                class="ma-0" 
                rounded="lg"
                color="#ffffff" 
                >
                <country-flag class="ma-0 pa-0" :country="getFlagCode(selectedLanguage)" />
              </v-avatar>
              <v-select
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :disabled="availableLanguages.length == 1"
                :items="availableLanguages"
                :item-text="(item) => item.languageName[$store.state.locale.toLowerCase()]"
                :label="$t('general.language', $store.state.locale)"
                item-value="id"
                dense
                outlined
                hide-details
                class="mt-2 pl-2"
                v-model="selectedLanguage"
              ></v-select> 
            </v-flex>
          </v-layout>
        </v-card>
        <!-- \ Select imput language / -->

        <div class="pt-2">
          <v-tooltip dark right> 
							<template v-slot:activator="{ on }">
                <v-chip v-on="on" class="custom-chip-style" close small label :color="$themes[$store.state.companyTheme][$store.state.themeModus][tag.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][tag.color]"> 
                  <span v-if="Object.keys(tag.name).length > 0">
                    {{ $autoLanguageSelector(selectedLanguage, $store.state.companyLanguages, tag.name) }}
                  </span>
                  <span v-else>{{ $t('tags.tagNameExample', $store.state.locale) }}</span>
                </v-chip>
							</template>
							<span class="tooltip-text-white" v-if="Object.keys(tag.description).length > 0">{{ $autoLanguageSelector(selectedLanguage, $store.state.companyLanguages, tag.description) }} </span>
              <span class="tooltip-text-white" v-else>{{ $t('tags.tagDescriptionExample', $store.state.locale) }}</span>
						</v-tooltip>
        </div>
  
        <v-text-field
          class="mt-4"
          :label="$t('general.name', $store.state.locale) + ` (${getLanguageName(selectedLanguage).languageName[$store.state.locale.toLowerCase()]})`"
          v-model="tag.name[selectedLanguage]"
          autocomplete
          outlined
          required
          dense
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          >
        </v-text-field>

        <v-textarea
          class="mt-4"
          :label="$t('general.description', $store.state.locale) + ` (${getLanguageName(selectedLanguage).languageName[$store.state.locale.toLowerCase()]})`"
          v-model="tag.description[selectedLanguage]"
          autocomplete
          outlined
          required
          dense
          rows="2"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          >
        </v-textarea>

        <v-select
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :disabled="availableLanguages.length == 1"
          :items="colors"
          item-text="name"
          :label="$t('general.color', $store.state.locale)"
          item-value="id"
          dense
          outlined
          hide-details
          class="mt-4"
          v-model="tag.color"
          prepend-inner-icon="mdi-tag"
        ></v-select> 

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeTagDialog()"
                >
                {{ $t('general.cancel', $store.state.locale)}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type =='new'"
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addTag()"
                >
                {{ $t("general.add", $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn
                v-if="type =='edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="updateTag()"
                >
                <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right>mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
  import { bus } from "../../main";
  import db from "../../firebase/init";
  import '@firebase/firestore';

  export default {
    name: "tag-form",
    data() {
      return {
        feedback: null,
        tag: { 
          name: new Object(),
          description: new Object(),
          color: 'primary'
        },
        tagOriginal: { name: new Object() },
        colors: [
          {
            name: "Primary",
            id: "primary"
          },
          {
            name: "Secondary",
            id: "secondary"
          },
          {
            name: "Tertiary",
            id: "tertiary"
          },
          {
            name: "Quaternary",
            id: "quaternary"
          }
        ],
        type: 'new',
        showDialog: false,
        selectedLanguage: 'nl'
      };
    },


    created() {
      this.selectedLanguage = this.$store.state.activeCompany.languages[0]; // Set language to company default language
      bus.$on("tagModal", (tag, type) => {
        this.type = type;
        if(type == "edit"){
          this.tag = _.cloneDeep(tag);
          this.tagOriginal = _.cloneDeep(this.tag);
        }
        else{
          this.type = 'new';
          this.resetTag();
        }
        this.showDialog = true;
      });
    },

    methods: {
      getLanguageName(item){
        return this.$countries.find(language => { return language.id == item });
      },
      closeTagDialog() {
        this.resetTag();
        this.showDialog = false;
      },

      resetTag(){
        this.feedback = null;
        this.tag = {
          name: new Object(),
          description: new Object(),
          color: 'primary'
        };
      },

      async updateTag() {
        this.feedback = null;

        try {
          await db.collection("companies")
          .doc(this.$store.state.activeCompany.id)
          .collection("tags")
          .doc(this.tag.id)
          .set({
            name: this.tag.name,
            description: this.tag.description,
            color: this.tag.color,
            created: new Date(),
            deleted: {
              deleted: false
            }
          }, {merge: true})

          await db.collection("log") // Log Action
          .add({
            time: new Date(),
            logType: "info",
            software: String(platform.os),
            browser: `${platform.name} ${platform.version}`,
            user: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
            userType: this.$store.state.activeUserRole,
            company: this.$store.state.userCompany,
            actionType: "userAction",
            action: "tagEditted",
            actionItem: this.tagOriginal.name,
            extra:[
              {
                name: "originalData",
                value: ""
              },
              {
                name: "name",
                value: this.tagOriginal.name
              },
              {
                name: "description",
                value: this.tagOriginal.description
              },
              {
                name: "color",
                value: this.tagOriginal.color
              },
              {
                name: "changedData",
                value: ""
              },
              {
                name: "name",
                value: this.tag.name
              },
              {
                name: "description",
                value: this.tag.description
              },
              {
                name: "color",
                value: this.tag.color
              }
            ]
          });
          this.$emit("updateData");
          this.closeTagDialog();
        }
        catch (error) {
          console.error("Error updating Treatmenttype: ", error);
          await db.collection("log")
          .add({
            time: new Date(),
            logType: "error",
            software: String(platform.os),
            browser: `${platform.name} ${platform.version}`,
            user: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
            userType: this.$store.state.activeUserRole,
            company: this.$store.state.userCompany,
            actionType: "userAction",
            action: "errorTagEddited",
            actionItem: this.tagOriginal.name,
            extra:[
              {
                name: "error",
                value: JSON.stringify(error)
              }
            ]
          });
        }
      },

      async addTag() {

        this.feedback = null;

        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "tagAdded",
          actionItem: this.$autoLanguageSelector(this.$store.state.nativeLanguage, this.$store.state.companyLanguages, this.tag.name),
          extra:[
            {
              name: "name",
              value: this.tag.name
            },
            {
              name: "description",
              value: this.tag.description
            },
            {
              name: "color",
              value: this.tag.color
            }
          ]
        };

        if (this.tag.name) {
          try {
            await db.collection("companies")
            .doc(this.$store.state.activeCompany.id)
            .collection("tags")
            .add({
              name: this.tag.name,
              description: this.tag.description,
              color: this.tag.color,
            })

            await this.$createNewLog("info", logData);

            this.$emit("updateData");
            this.closeTagDialog();
          }
          catch (error) {
            console.error("Error adding Tag: ", error);
            this.feedback = this.$t("general.addError", this.$store.state.locale);
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
          }
        } else {
          this.feedback = this.$t('general.requiredField', this.$store.state.locale)
        }
      },
      getFlagCode(selectedLanguage){
        return selectedLanguage ? this.$countries.find(language => { return language.id == selectedLanguage }).flagCode : "";
      }
    },
    computed: {
      availableLanguages(){
        let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
          if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
        });
        // Show languages in the same order as company languages
        return availableLanguages.sort((a, b) => this.$store.state.activeCompany.languages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
      },
      changed() {
        return _.isEqual(this.tag ,this.tagOriginal) ? false : true;
      }
    }
  };
</script>