
<template>
  <div>
    <v-tabs class="mb-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" show-arrows v-model="tab" @change="tabChanged()" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
      <v-tab href="#treatmentTypes">{{ $t("admin.treatmentTypes", $store.state.locale) }}</v-tab>
      <v-tab href="#subtreatmentTypes">{{ $t("admin.subTreatmentTypes", $store.state.locale) }}</v-tab>
      <v-tab href="#devicetypes">{{ $t("admin.deviceTypes", $store.state.locale) }}</v-tab>
      <v-tab href="#abilities">{{ $t("admin.skills", $store.state.locale) }}</v-tab> 
      <v-tab href="#vat">{{ $t("admin.vatRates", $store.state.locale) }}</v-tab>
      <v-tab href="#cao">{{ $t("admin.cao", $store.state.locale) }}</v-tab>
      <v-tab href="#categories">{{ $t('products.categories', $store.state.locale) }}</v-tab>
      <v-tab href="#suppliers">{{ $t('products.suppliers', $store.state.locale) }}</v-tab>
      <v-tab href="#api">{{ $t('admin.apiSettings', $store.state.locale) }}</v-tab>
      <!-- <v-tab href="#weight">Gewicht</v-tab>  -->
      <!-- <v-tab href="#packages">Paketten</v-tab> -->
      <v-tab href="#packagestypes">Lidmaatschapscategorieën</v-tab>  
      <!-- <v-tab href="#users">Betalende gebruikers</v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tab" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card}" class="transparant-tabs">
      <v-tab-item value="treatmentTypes" :transition="tabTransition" :reverse-transition="tabTransition">
        <treatment-types></treatment-types>
      </v-tab-item>
      <v-tab-item value="subtreatmentTypes" :transition="tabTransition" :reverse-transition="tabTransition">
        <subtreatment-types></subtreatment-types>
      </v-tab-item>
      <v-tab-item value="devicetypes" :transition="tabTransition" :reverse-transition="tabTransition">
        <device-types></device-types>
      </v-tab-item>
      <v-tab-item value="abilities" :transition="tabTransition" :reverse-transition="tabTransition">
        <abilities></abilities>
      </v-tab-item>    
      <v-tab-item value="vat" :transition="tabTransition" :reverse-transition="tabTransition">
        <vat></vat>
      </v-tab-item> 
      <v-tab-item value="cao" :transition="tabTransition" :reverse-transition="tabTransition">
        <cao></cao>
      </v-tab-item>
      <v-tab-item value="suppliers" :transition="tabTransition" :reverse-transition="tabTransition">
        <productSuppliers></productSuppliers>
      </v-tab-item>
      <v-tab-item value="categories" :transition="tabTransition" :reverse-transition="tabTransition">
        <productCategories></productCategories>
      </v-tab-item>
      <v-tab-item value="api" :transition="tabTransition" :reverse-transition="tabTransition">
        <apiSettings></apiSettings>
      </v-tab-item>

      <!-- <v-tab-item value="weight" :transition="tabTransition" :reverse-transition="tabTransition">
        <weight></weight>
      </v-tab-item>
      <v-tab-item value="packages" :transition="tabTransition" :reverse-transition="tabTransition">
        <packages></packages>
      </v-tab-item> -->
      <v-tab-item value="packagestypes" :transition="tabTransition" :reverse-transition="tabTransition">
        <membershipCategories></membershipCategories>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import '@firebase/firestore';
  import TreatmentTypes from "@/views/TreatmentTypes.vue";
  import SubtreatmentTypes from "@/views/SubtreatmentTypes.vue";
  import DeviceTypes from "@/views/DeviceTypes.vue";
  import Abilities from "@/views/Abilities.vue";
  import Vat from "@/views/Vat.vue";
  import CAO from "@/views/Cao.vue";
  import ProductSuppliers from "@/views/ProductSuppliers.vue";
  import ProductCategories from "@/views/ProductCategories.vue";
  import ApiSettings from "@/views/ApiSettings.vue";
  // import Packages from "@/views/packages.vue";
  // import Weight from "@/views/weightGoals.vue";
  // import PayingUsers from "@/views/PayingUsers.vue";
  import MembershipCategories from "@/views/membershipCategories.vue";

  export default {
    components: {
      treatmentTypes: TreatmentTypes,
      subtreatmentTypes: SubtreatmentTypes,
      deviceTypes: DeviceTypes,
      abilities: Abilities,
      vat: Vat,
      cao: CAO,
      apiSettings:ApiSettings,
      productCategories: ProductCategories,
      productSuppliers: ProductSuppliers,
      // weight: Weight,
      // packages: Packages,
      // payingUsers: PayingUsers,
      membershipCategories: MembershipCategories,
    },
    name: "admin-settings",
    data() {
      return {
        tab: 'treatmentTypes',
        tabTransition: false,
      }
    },

    created() {
      this.tabChanged();
      if(this.$route.query.tab){
        this.tab = this.$route.query.tab;
      }
    },

    methods: {
      tabChanged(){ // Change query in url when selecting tab.
        history.pushState(new Object(),null,this.$route.path + '?tab=' + this.tab)
      }
    },
  };
</script>