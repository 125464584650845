<template>


    
      <v-card>
        <v-card-title class="text-center"><h3><strong>Verbinding verbroken...</strong></h3> </v-card-title>
         <v-divider></v-divider>
        
        <v-card-text class="text-center">

        <div class="connection">
          <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-radio"><circle cx="28" cy="75" r="11" fill="#bfc5d2"><animate attributeName="fill-opacity" calcMode="linear" values="0;1;1" keyTimes="0;0.2;1" dur="1" begin="0s" repeatCount="indefinite"></animate></circle><path d="M28 47A28 28 0 0 1 56 75" fill="none"  stroke-width="10" stroke="#bfc5d2"><animate attributeName="stroke-opacity" calcMode="linear" values="0;1;1" keyTimes="0;0.2;1" dur="1" begin="0.1s" repeatCount="indefinite"></animate></path><path d="M28 25A50 50 0 0 1 78 75" fill="none"  stroke-width="10" stroke="#bfc5d2"><animate attributeName="stroke-opacity" calcMode="linear" values="0;1;1" keyTimes="0;0.2;1" dur="1" begin="0.2s" repeatCount="indefinite"></animate></path></svg>
        </div>
            
          Probeert opnieuw te verbinden binnen {{lostconnectioncounter}} sec. 
          Helaas nog geen verbinding. Er is geen verbinding met het internet. Check uw verbinding.
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn class="my-2" block color="secondary" dark @click ="closeconnectiondialog()">Sluiten</v-btn>
        </v-card-actions>
      </v-card>
    

</template>

<style>
.connection {
  margin: 0 auto;
  width: 100px;
  height: 100px;
}
</style>

<script>
export default {
  data() {
    return {
      dialogconnection: false,
      lostconnectioncounter: 3,
      connectionfailure: ""
    };
  },
  mounted() {
    setInterval(() => {
      this.lostconnectioncounter--;
      if (this.lostconnectioncounter == 0) {
        if (navigator.onLine == false) {
          // alert("Browser is offline");
          this.dialogconnection = true;
        }
        this.lostconnectioncounter = 3;
      }
    }, 1000);
  },

  methods: {
    closeconnectiondialog: function() {
      this.$emit("newQuestionDialogChange", false);
    }
  }
};
</script>