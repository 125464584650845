
<template>
  <div class="tab-component-wrapper">
    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>
    
    <div class="content" v-if="!loading && categories.length > 0">
      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
        <v-container grid-list-md class=" ma-0 pa-3" fluid>
          <v-layout row wrap>
            <v-flex md4 xs12>
              <v-text-field
                class="mx-1"
                dense
                v-model="search"
                name="searchfield"
                :label="$t('general.search', this.$store.state.locale)"
                prepend-inner-icon="mdi-magnify"
                single-line
                hide-details
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex md8 xs12 class="text-right">
              <v-btn class="px-2" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addItem()">
                <v-icon left>mdi-plus</v-icon> Nieuwe categorie
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider></v-divider>
    <!-- 
        <div class="mx-4 mt-4">
          <v-container grid-list-md class="pa-0" fluid>
            <v-layout row wrap>
              <v-flex md6 xs12>
                <h1 class="tab-title">Lidmaatschap categorieën</h1>
              </v-flex>
              <v-flex md6 xs12>
                <div style="display: inline-block; float: right;">
                  <v-row>
                    <v-text-field
                      class="mx-1"
                      v-model="search"
                      name="searchfield"
                      label="Zoeken"
                      prepend-inner-icon="mdi-magnify"
                      single-line
                      background-color="background"
                      outlined
                      clearable
                    ></v-text-field>
                    <v-btn 
                      fab
                      darken
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                      @click="addCategoryDialog = true"
                      class="mx-1"
                      >
                      <v-icon color="light-blue lighten-5">mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        -->
        <v-data-table
          :headers="headers"
          :items="categories"
          :items-per-page="-1"
          hide-default-footer
          :width="1"
          :search="search"
          class="dense-table"
          dense
          >
          <template v-slot:item="{item}">
            <tr>
              <td> {{ item.name }}</td>
              <td> {{ item.description }}</td>
              <td>
                <v-btn icon class="mx-0" @click="editItem(item)">
                  <v-icon small color="text">mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn icon class="mx-0" @click="showDeleteDialog(item.id)">
                  <v-icon small color="text">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>


    <div class="no-data-message" v-if="!loading && categories.length == 0">
      <p>Er zijn nog geen categorieën.</p>
      <v-btn fab darken color="secondary" @click="addCategoryDialog = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <!-- / Category Dialog \ -->
    <membership-category @updateData="fetchCategories" ></membership-category>
    <!-- \ Category Dialog / -->
  </div>
</template>

<script>
import _ from 'lodash'
import db from "../firebase/init";
import { bus } from "../main";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import membershipCategoryDialog from "../components/modals/membershipCategory";

export default {
  name:"membership-categories",

  components:{
    "membership-category" : membershipCategoryDialog,
  },

  data(){
    return{
        loading: true,
        addCategoryDialog : false,
        editCategoryDialog: false,
        activeName: null,
        activeDescription: null,
        search: null,
        categories: new Array(),
        headers:[
          {
            text: 'Naam',
            align: 'start',
            value: 'name',
          },
          { 
            text: 'Beschrijving', 
            value: 'description',
            sortable: false,
          },
          { 
            text: '', 
            value: 'actions',
            sortable: false,
            width: "40px"
          },
          { 
            text: '', 
            value: 'actions',
            sortable: false,
            width: "40px"
          }
        ]
     }
  },

  created(){
    this.fetchCategories();
    bus.$on("deleteMembershipCategory", (id) => {this.deleteItem(id) });       
  },

  methods:{

    async fetchCategories(){
      this.loading = true;
      this.categories = new Array();
      await db.collection('membershipCategories')
      .get()
      .then(snap=>{
        snap.forEach(doc=>{
          let category = doc.data();
          category.id = doc.id;
          this.categories.push(category);
        })
      })
      .catch(err=>{
        console.error("Error adding membership categories: ",err);
      });
      this.loading = false;
    },

    addItem() {
      bus.$emit("membershipCategory",null, 'new');
    },

    editItem (item) {
      bus.$emit("membershipCategory",item, 'edit');
    },

    showDeleteDialog(id){                                                    
      let deleteInformation = new Object();
      deleteInformation.id = id;                                                                 
      deleteInformation.emit = "deleteMembershipCategory";                                                    
   
      deleteInformation.title = "Categorie verwijderen";
      deleteInformation.info = "De categorie zal verwijderd worden. Dit kan niet ongedaan worden gemaakt.";
  
      bus.$emit('deletedModal', deleteInformation, 'open');                                       
    },

    async deleteItem (id) {
      await db.collection('membershipCategories').doc(id).delete()
      .catch(err=>{
        console.error("Error bij verwijderen van membershipCategorie", err)
      })
      bus.$emit('deletedModal', null, 'close');  
      this.fetchCategories();
    }
  }
}
</script>
