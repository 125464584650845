export default 
    [
        {   
            countryName: {
                nl: "Nederland",
                en: "The Netherlands",
                de: "Die Niederlande"
            },
            languageName: {
                nl: "Nederlands",
                en: "Dutch",
                de: "Niederländisch"
            },
            id: "nl",
            userLanguage: true,
            country: 'netherlands',
            languageCode: "NL",
            countryCode: "NL",
            flagCode: "nl", 
            language: true,
            phonePrefix: "+31"
        },
        {   
            countryName: {
                nl: "Verenigd Koninkrijk",
                en: "United Kingdom",
                de: "Vereinigtes Königreich"
            },
            languageName: {
                nl: "Engels",
                en: "English",
                de: "Englisch"
            },
            id: "en",
            userLanguage: true,
            country: 'unitedkindom',
            languageCode: "EN",
            countryCode: "EN",
            flagCode: "gb",
            language: true,
            phonePrefix: "+44"
        },
        {   
            countryName: {
                nl: "Duitsland",
                en: "Germany",
                de: "Deutschland"
            },
            languageName: {
                nl: "Duits",
                en: "German",
                de: "Deutsch"
            },
            id: "de",
            userLanguage: true,
            country: 'germany',
            languageCode: "DE",
            countryCode: "DE",
            flagCode: "de",
            language: true,
            phonePrefix: "+49"
        },
        {   
            countryName: {
                nl: "België",
                en: "Belgium",
                de: "Belgien"
            },
            id: "be",
            userLanguage: false,
            country: 'belgium',
            languageCode: "BE",
            countryCode: "BE",
            flagCode: "be",
            language: false,
            phonePrefix: "+32"
        },
        {   
            countryName: {
                nl: "Frankrijk",
                en: "France",
                de:"Frankreich"
            },
            languageName: {
                nl: "Frans",
                en: "France",
                de: "Französisch"
            },
            id: "fr",
            userLanguage: false,
            country: 'france',
            languageCode: "FR",
            countryCode: "FR",
            flagCode: "fr",
            language: true,
            phonePrefix: "+33"
        },
        {   
            countryName: {
                nl: "Verenigde Staten",
                en: "United States",
                de: "USA"
            },
            id: "us",
            userLanguage: false,
            country: 'unitedstates',
            languageCode: "US",
            countryCode: "US",
            flagCode: "us",
            language: false,
            phonePrefix: "+1"
        },
        {   
            countryName: {
                nl: "Canada",
                en: "Canada",
                en: "Kanada"
            },
            id: "can",
            userLanguage: false,
            country: 'canada',
            languageCode: "CAN",
            countryCode: "CAN",
            flagCode: "can",
            language: false,
            phonePrefix: "+1"
        }, 
        {   
            countryName: {
                nl: "Spanje",
                en: "Spain",
                de: "Spanien"
            },
            id: "es",
            userLanguage: false,
            country: 'spain',
            languageCode: "ES",
            countryCode: "ES",
            flagCode: "es",
            language: false,
            phonePrefix: "+34"
        },       
      ]
;
