<template>
  <v-dialog v-model="newPhoneNumberData.dialog" scrollable @click:outside="closeNewPhoneNumber" max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`"> 
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('clients.newPhoneNumber', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeNewPhoneNumber()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="newPhoneNumberData.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ newPhoneNumberData.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text class="modal-card ma-0 pa-3">
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs3>
                <v-select
                  outlined
                  hide-details
                  class="pa-0 ma-0 select-custom-items"
                  content-class="pa-0 ma-0 select-custom-items-content"
                  :items="$countries"
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  v-model="newPhoneNumberRegion"
                  item-text="item"
                  item-value="countryCode"
                  >
                  <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                    <country-flag :country="data.item.flagCode" size='small'/>
                    <span class="pl-1"> {{ data.item.phonePrefix }}</span>
                  </template>
                  <template v-slot:item="{ active, item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }" class="my-0 py-0 pl-3">
                      <v-list-item-avatar size="20">
                        <country-flag :country="item.flagCode" size='small'/>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.phonePrefix }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs9>
                <v-text-field
                  :label="$t('general.phone', $store.state.locale)"
                  number
                  v-model="newPhoneNumberInput"
                  outlined
                  dense
                  counter
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  >
                  <template v-slot:append>        
                    <v-icon v-if="newPhoneNumberData.valid == true" color="green"> mdi-check-circle </v-icon> 
                    <v-icon v-if="newPhoneNumberData.valid == false" color="warning"> mdi-alert</v-icon> 
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout v-if="newPhoneNumberData.valid == false" row wrap class="ma-0 pa-0 text-center">
              <v-flex xs12 ><p><i>Het ingevoerde nummer is niet juist.</i></p></v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="closeNewPhoneNumber()"
                  >
                  {{ $t('general.cancel', $store.state.locale)}}
                </v-btn>
              </v-flex>
              <v-flex xs12 md8 class="text-right">
                <v-btn
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :disabled="!newPhoneNumberData.valid"
                  :dark="newPhoneNumberData.valid"
                  @click="addPhonenumber()"
                  >
                  {{ $t('general.add', $store.state.locale)}}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>


<script>
import { bus } from "../../main";
import { parsePhoneNumber } from 'awesome-phonenumber'

export default {
  name: "add-phonenumber-form",
  props:{
    type: String
  },
  data() {
    return {
      existingPhoneNumbers: new Array(),
      newPhoneNumberData: {
        dialog: false,
        feedback: null,
        data: null,
        valid: null,
      },
      newPhoneNumberRegion: "NL",
      newPhoneNumberInput: "",
    };
  },

  created(){
    bus.$on('addPhonenumberDialog', (phones, componentType) =>{
      if(componentType == this.type){
        this.newPhoneNumberData.region = this.availableLanguages[0].languageCode;
        this.newPhoneNumberRegion = this.availableLanguages[0].languageCode;
        this.existingPhoneNumbers = phones;
        this.newPhoneNumberData.dialog = true;
      }
    });
  },

  methods: {
    addPhonenumber(){
      let phonenumberData = {
        primary: !this.existingPhoneNumbers || this.existingPhoneNumbers.length == 0 ? true : false,
        region: this.newPhoneNumberRegion,
        phone: this.newPhoneNumberData.data.number,
        type: this.newPhoneNumberData.data.type
      }
      this.$emit("newPhoneNumber", phonenumberData);
      this.closeNewPhoneNumber();
    },

    closeNewPhoneNumber(){
      this.newPhoneNumberData = {
        dialog: false,
        valid: null,
        feedback: null,
        data: null,
      };
      this.newPhoneNumberInput = "";
      this.newPhoneNumberRegion = this.availableLanguages[0].languageCode;
    },

    checkPhonenumber(input, region){
      const pn = parsePhoneNumber( input, region );
      if(pn.g && pn.g.valid){
        this.newPhoneNumberData.valid = true;
        this.newPhoneNumberData.data = pn.g
      }
      else{
        this.newPhoneNumberData.valid = false;
      }
    },
  },
  watch: {
    newPhoneNumberInput: {
      handler() {
        if(this.newPhoneNumberInput && this.newPhoneNumberInput.length > 7){
          this.$countries.forEach(language => {
            if(this.newPhoneNumberInput.includes(language.phonePrefix)){
              this.newPhoneNumberRegion = language.countryCode;
            }
          });
          this.checkPhonenumber(this.newPhoneNumberInput, this.newPhoneNumberRegion)
        }
        else{
          this.newPhoneNumberData.valid = null;
        }
      },
      deep: true
    },
    newPhoneNumberRegion: {
      handler() {
        if(this.newPhoneNumberInput && this.newPhoneNumberInput.length > 7){
          this.checkPhonenumber(this.newPhoneNumberInput, this.newPhoneNumberRegion)
        }
        else{
          this.newPhoneNumberData.valid = null;
        }
      },
      deep: true
    },
  },
  computed: {
    availableLanguages(){
      let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
        if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
      });
      // Show languages in the same order as company languages
      return availableLanguages.sort((a, b) => this.$store.state.activeCompany.languages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive"; 
    }
  }

};
</script>
