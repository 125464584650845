<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="900px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3>{{ $t('rooms.addRoom', $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeAddRoomDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

      <v-tabs :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="tab" show-arrows>
        <v-tab>{{ $t('general.general', $store.state.locale) }}</v-tab>
        <v-tab>{{ $t('rooms.grouplessons', $store.state.locale) }}</v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-card-text style="height: 500px; padding: 0; margin: 0;">
        <v-tabs-items v-model="tab">
          <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-form ref="AddRoomForm" lazy-validation>
                <v-text-field 
                  :label="$t('general.name', $store.state.locale)" 
                  class="mt-2 mb-4"
                  hide-details
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                  v-model="newRoom.name" 
                  autocomplete required
                  outlined
                  >
                </v-text-field>

                <v-card class="pa-2 ma-0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
                  <v-container class="pa-0 ma-0">
                    <h3>{{ $t('rooms.defaultDays', $store.state.locale) }}</h3>
                    <v-layout row wrap class="pa-0 ma-0" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">
                      <v-flex xs3 class="py-2"><strong>{{ $t('general.day', $store.state.locale) }}</strong></v-flex>
                      <v-flex xs9 class="py-2"><strong>{{ $t('general.available', $store.state.locale) }}</strong></v-flex>
                      <v-flex xs3>{{ $t('general.sunday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newRoom.defaultDays.Sun"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.monday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newRoom.defaultDays.Mon"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.tuesday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newRoom.defaultDays.Tue"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.wednesday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newRoom.defaultDays.Wed"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.thursday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newRoom.defaultDays.Thu"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.friday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newRoom.defaultDays.Fri"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.saturday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="newRoom.defaultDays.Sat"></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>

                <div class="mt-2 clear">
                  <label><strong>{{ $t('general.color', $store.state.locale) }}</strong></label>
                  <div class="color-field">
                    <swatches
                      v-model="newRoom.color"
                      :swatches="colors"
                      :wrapper-style="wrapperStyle"
                      :swatch-style="swatchStyle"
                      swatch-size="36"
                      show-border
                      inline
                    ></swatches>
                  </div>
                  <div class="custom-hint">{{ $t('treatments.colorHint', $store.state.locale) }}</div>
                </div>

                <v-switch 
                  :label="$t('general.active', $store.state.locale)" 
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  hide-details
                  v-model="newRoom.active"
                ></v-switch>
          
              </v-form>
            </div>
          </v-tab-item>
          <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-switch
                :label="$t('general.active', $store.state.locale)" 
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                v-model="newRoom.slotReservation"
              ></v-switch>

              <v-card flat class="pa-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="newRoom.slotReservation">
                <div class="py-1 px-2"><strong>{{ $t('rooms.planGrouplessons', $store.state.locale) }}</strong></div>
                <v-layout dflex class="grouplessons-schedule-wrapper">
                  <div class="day-of-week text-center" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }">
                    <div class="schedule-day-title">{{ $t('general.sunday', $store.state.locale) }}</div>
                    <div v-if="newRoom.days.Sun.length > 0">
                      <div class="grouplesson" v-for="(grouplesson, index) in filteredGrouplesson(newRoom.days.Sun)" :key="index" :style="{backgroundColor: grouplesson.backgroundColor}">
                        <div class="grouplesson-time">{{ grouplesson.startTime }}-{{ grouplesson.endTime }}</div>
                        <div class="grouplesson-title">{{ grouplesson.title }}</div>
                        <div class="grouplesson-seats">{{ grouplesson.seats }} {{ $t('rooms.persons', $store.state.locale) }}</div>
                        <div class="grouplesson-edit">
                          <v-btn class="my-2" fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editGroupLesson('Sun', filteredGrouplesson(newRoom.days.Sun), index)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </div>
                      </div>
                    </div>
                    <div v-else class="schedule-day-empty">{{ $t('rooms.noGrouplessonsPlanned', $store.state.locale) }}</div>
                    <v-btn class="my-2" fab small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addGroupLesson('Sun', newRoom.days.Sun)"><v-icon>mdi-plus</v-icon></v-btn>

                  </div>
                  <div class="day-of-week text-center" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }">
                    <div class="schedule-day-title">{{ $t('general.monday', $store.state.locale) }}</div>
                    <div v-if="newRoom.days.Mon.length > 0">
                      <div class="grouplesson" v-for="(grouplesson, index) in filteredGrouplesson(newRoom.days.Mon)" :key="index" :style="{backgroundColor: grouplesson.backgroundColor}">
                        <div class="grouplesson-time">{{ grouplesson.startTime }}-{{ grouplesson.endTime }}</div>
                        <div class="grouplesson-title">{{ grouplesson.title }}</div>
                        <div class="grouplesson-seats">{{ grouplesson.seats }} {{ $t('rooms.persons', $store.state.locale) }}</div>
                        <div class="grouplesson-edit">
                          <v-btn class="my-2" fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editGroupLesson('Mon', filteredGrouplesson(newRoom.days.Mon), index)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </div>
                      </div>
                    </div>
                    <div v-else class="schedule-day-empty">{{ $t('rooms.noGrouplessonsPlanned', $store.state.locale) }}</div>
                    <v-btn class="my-2" fab small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addGroupLesson('Mon', newRoom.days.Mon)"><v-icon>mdi-plus</v-icon></v-btn>
                  </div>
                  <div class="day-of-week text-center" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }">
                    <div class="schedule-day-title">{{ $t('general.tuesday', $store.state.locale) }}</div>
                    <div v-if="newRoom.days.Tue.length > 0">
                      <div class="grouplesson" v-for="(grouplesson, index) in filteredGrouplesson(newRoom.days.Tue)" :key="index" :style="{backgroundColor: grouplesson.backgroundColor}">
                        <div class="grouplesson-time">{{ grouplesson.startTime }}-{{ grouplesson.endTime }}</div>
                        <div class="grouplesson-title">{{ grouplesson.title }}</div>
                        <div class="grouplesson-seats">{{ grouplesson.seats }} {{ $t('rooms.persons', $store.state.locale) }}</div>
                        <div class="grouplesson-edit">
                          <v-btn class="my-2" fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editGroupLesson('Tue', filteredGrouplesson(newRoom.days.Tue), index)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </div>
                      </div>
                    </div>
                    <div v-else class="schedule-day-empty">{{ $t('rooms.noGrouplessonsPlanned', $store.state.locale) }}</div>
                    <v-btn class="my-2" fab small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addGroupLesson('Tue', newRoom.days.Tue)"><v-icon>mdi-plus</v-icon></v-btn>
                  </div>
                  <div class="day-of-week text-center" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }">
                    <div class="schedule-day-title">{{ $t('general.wednesday', $store.state.locale) }}</div>
                    <div v-if="newRoom.days.Wed.length > 0">
                      <div class="grouplesson" v-for="(grouplesson, index) in filteredGrouplesson(newRoom.days.Wed)" :key="index" :style="{backgroundColor: grouplesson.backgroundColor}">
                        <div class="grouplesson-time">{{ grouplesson.startTime }}-{{ grouplesson.endTime }}</div>
                        <div class="grouplesson-title">{{ grouplesson.title }}</div>
                        <div class="grouplesson-seats">{{ grouplesson.seats }} {{ $t('rooms.persons', $store.state.locale) }}</div>
                        <div class="grouplesson-edit">
                          <v-btn class="my-2" fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editGroupLesson('Wed',filteredGrouplesson(newRoom.days.Wed), index)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </div>
                      </div>
                    </div>
                    <div v-else class="schedule-day-empty">{{ $t('rooms.noGrouplessonsPlanned', $store.state.locale) }}</div>
                    <v-btn class="my-2" fab small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addGroupLesson('Wed', newRoom.days.Wed)"><v-icon>mdi-plus</v-icon></v-btn>
                  </div>
                  <div class="day-of-week text-center" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }">
                    <div class="schedule-day-title">{{ $t('general.thursday', $store.state.locale) }}</div>
                    <div v-if="newRoom.days.Thu.length > 0">
                      <div class="grouplesson" v-for="(grouplesson, index) in filteredGrouplesson(newRoom.days.Thu)" :key="index" :style="{backgroundColor: grouplesson.backgroundColor}">
                        <div class="grouplesson-time">{{ grouplesson.startTime }}-{{ grouplesson.endTime }}</div>
                        <div class="grouplesson-title">{{ grouplesson.title }}</div>
                        <div class="grouplesson-seats">{{ grouplesson.seats }} {{ $t('rooms.persons', $store.state.locale) }}</div>
                        <div class="grouplesson-edit">
                          <v-btn class="my-2" fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editGroupLesson('Thu', filteredGrouplesson(newRoom.days.Thu), index)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </div>
                      </div>
                    </div>
                    <div v-else class="schedule-day-empty">{{ $t('rooms.noGrouplessonsPlanned', $store.state.locale) }}</div>
                    <v-btn class="my-2" fab small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addGroupLesson('Thu', newRoom.days.Thu)"><v-icon>mdi-plus</v-icon></v-btn>
                  </div>
                  <div class="day-of-week text-center" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }">
                    <div class="schedule-day-title">{{ $t('general.friday', $store.state.locale) }}</div>
                    <div v-if="newRoom.days.Fri.length > 0">
                      <div class="grouplesson" v-for="(grouplesson, index) in filteredGrouplesson(newRoom.days.Fri)" :key="index" :style="{backgroundColor: grouplesson.backgroundColor}">
                        <div class="grouplesson-time">{{ grouplesson.startTime }}-{{ grouplesson.endTime }}</div>
                        <div class="grouplesson-title">{{ grouplesson.title }}</div>
                        <div class="grouplesson-seats">{{ grouplesson.seats }} {{ $t('rooms.persons', $store.state.locale) }}</div>
                        <div class="grouplesson-edit">
                          <v-btn class="my-2" fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editGroupLesson('Fri', filteredGrouplesson(newRoom.days.Fri), index)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </div>
                      </div>
                    </div>
                    <div v-else class="schedule-day-empty">{{ $t('rooms.noGrouplessonsPlanned', $store.state.locale) }}</div>
                    <v-btn class="my-2" fab small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addGroupLesson('Fri', newRoom.days.Fri)"><v-icon>mdi-plus</v-icon></v-btn>
                  </div>
                  <div class="day-of-week text-center last" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }">
                    <div class="schedule-day-title">{{ $t('general.saturday', $store.state.locale) }}</div>
                    <div v-if="newRoom.days.Sat.length > 0">
                      <div class="grouplesson" v-for="(grouplesson, index) in filteredGrouplesson(newRoom.days.Sat)" :key="index" :style="{backgroundColor: grouplesson.backgroundColor}">
                        <div class="grouplesson-time">{{ grouplesson.startTime }}-{{ grouplesson.endTime }}</div>
                        <div class="grouplesson-title">{{ grouplesson.title }}</div>
                        <div class="grouplesson-seats">{{ grouplesson.seats }} {{ $t('rooms.persons', $store.state.locale) }}</div>
                        <div class="grouplesson-edit">
                          <v-btn class="my-2" fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editGroupLesson('Sat', filteredGrouplesson(newRoom.days.Sat), index)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </div>
                      </div>
                    </div>
                    <div v-else class="schedule-day-empty">{{ $t('rooms.noGrouplessonsPlanned', $store.state.locale) }}</div>
                    <v-btn class="my-2" fab small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addGroupLesson('Sat', newRoom.days.Sat)"><v-icon>mdi-plus</v-icon></v-btn>
                  </div>
                </v-layout>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeAddRoomDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addRoom()"
                >
                {{ $t('rooms.addRoomButton', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>

    <!-- / Group Lesson Dialog \ -->
    <v-dialog v-model="newGroupLesson.dialog" scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2 v-if="newGroupLesson.type == 'new'">{{ $t('rooms.addGrouplesson', $store.state.locale) }}</h2>
          <h2 v-else>{{ $t('rooms.editGrouplesson', $store.state.locale) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="resetNewGroupLesson()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="newGroupLesson.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ newGroupLesson.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text class="pa-0">
          <v-form ref="newGroupLessonForm">
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md12>
                  <v-text-field 
                    :label="$t('general.name', $store.state.locale)" 
                    v-model="newGroupLesson.title" 
                    required
                    disabled
                    readonly
                    dense
                    outlined
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                  </v-text-field>
                  <v-select
                    :label="$t('general.treatment', $store.state.locale)"
                    v-model="newGroupLesson.treatment"
                    :items="groupTreatments"
                    item-text="treatmentTitle"
                    item-value="id"
                    required
                    dense
                    outlined
                    return-object
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-on:input="calculateNewGroupLessonEndtime()"
                  ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-text-field 
                    :label="$t('general.start', $store.state.locale)" 
                    v-model="newGroupLesson.startTime" 
                    type="time"
                    required
                    outlined
                    dense
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-on:input="calculateNewGroupLessonEndtime()"
                    >
                  </v-text-field>
                </v-flex>
                <v-flex md6>
                <v-select
                    :label="$t('rooms.numberLessons', $store.state.locale)"
                    v-model="newGroupLesson.number"
                    :items="numberOfLessonsNew.numbers"
                    required
                    outlined
                    dense
                    :disabled="numberOfLessonsNew.disabled"
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-on:input="calculateNewGroupLessonEndtime()"
                  ></v-select>
                </v-flex>
                <v-flex md12>
                  <v-text-field 
                    :label="$t('rooms.availableSeats', $store.state.locale)" 
                    v-model.number="newGroupLesson.seats" 
                    type="number"
                    required
                    outlined
                    dense
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                  </v-text-field>
  
                  <div class="clear mt-4">
                    <label>{{ $t('general.color', $store.state.locale) }}</label>
                    <div class="color-field">
                      <swatches
                        v-model="newGroupLesson.backgroundColor"
                        :swatches="groupLessonColors"
                        :wrapper-style="wrapperStyle"
                        :swatch-style="swatchStyle"
                        swatch-size="36"
                        show-border
                        inline
                      ></swatches>
                    </div>
                    <div class="custom-hint"> {{ $t('treatments.colorHint', $store.state.locale) }}</div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="resetNewGroupLesson()"
                  >
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  v-if="newGroupLesson.type == 'new'" 
                  dark  
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  @click="saveGroupLesson('new')"
                  >
                  {{ $t('rooms.addGrouplessonButton', $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  dark  
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  
                  @click="saveGroupLesson('edit')"
                  >
                  {{ $t('rooms.saveGrouplessonButton', $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ Group Lesson Dialog / -->
  </v-dialog>
</template>

<script>
import db from "@/firebase/init";
import '@firebase/firestore';
import { bus } from "@/main";
import Swatches from "vue-swatches";
import moment from "moment";

export default {
  name: "add-room-modal",
  components: {
    Swatches
  },
  data() {
    return {
      showDialog: false,
      colors: new Array(),
      tab: 0,
      tabTransition: false,
      groupLessonColors: [
        "#72D9C4",
        "#ABDEE6",
        "#83C0EC",
        "#BC8DD0",
        "#F39488",
        "#F8DD65",
        "#E89861",
        "#61C1AD",
        "#D7E2EA",
        "#ECEAE4",  
      ],
      newGroupLesson: {
        type: null,
        index: null,
        dialog: false,
        title: null,
        treatment: null,
        seats: 1,
        startTime: null,
        endTime: null,
        backgroundColor: null,
        duration: 0,
        number: 1,
        scheduledLessons: new Array(),
        day: null,
        feedback: null,
      },
      colors: [
        "#72D9C4",
        "#ABDEE6",
        "#83C0EC",
        "#BC8DD0",
        "#F39488",
        "#F8DD65",
        "#E89861",
        "#61C1AD",
        "#D7E2EA",
        "#ECEAE4",  
      ],
      newRoom: {
        color: null,
        defaultDays: {
          Sun: true,
          Mon: true,
          Tue: true,
          Wed: true,
          Thu: true,
          Fri: true,
          Sat: true
        },
        days:{
          Sun: new Array(),
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array()  
        },
        active: true,
        slotReservation: false,
      },
      groupTreatments: new Array(),
      treatments: new Array(),
      wrapperStyle: { paddingTop: "0", paddingLeft: "0" },
      swatchStyle: { marginBottom: "0" },
      feedback: null
    };
  },

  created(){
    bus.$on("addRoom", () => { // Executed on opening the dialog in Rooms.vue
      this.newRoom.color = this.colors[0];
      this.loadGroupLessonTreatments();
      this.tab = 0;
      this.showDialog = true;  
    });
  },

  methods: {
    closeAddRoomDialog() { // Close Dialog Modal
      this.showDialog = false;
    },
    async addRoom() { // Add Room to database
      if (this.newRoom.name) {
        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "newRoomAdded",
          actionItem: this.newRoom.name,
          extra:[
            {
              name: "name",
              value: this.newRoom.name
            },
            {
              name: "companyId",
              value: this.$store.state.activeCompany.id
            },
            {
              name: "branches",
              value: [this.$store.state.activeBranch.id]
            }
          ]
        };

        try{
          await db.collection("rooms")
          .add({
            name: this.newRoom.name,
            color: this.newRoom.color,
            allDay: true,
            slotReservation: this.newRoom.slotReservation,
            days: this.newRoom.days,
            branches: [this.$store.state.activeBranch.id],
            companyId: this.$store.state.activeCompany.id,
            active: this.newRoom.active,
            defaultDays: this.newRoom.defaultDays,
            resourceDeleted: false
          })

          await this.$createNewLog("info", logData);
          this.$emit("updateData");
          this.closeAddRoomDialog();
        }
        catch(error){
          console.error("Error adding Room: ", error);
          this.feedback = this.$t("general.addError", this.$store.state.locale) ;
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      }
      else {
        this.feedback = "Vul alle velden in om een nieuwe ruimte toe te voegen.";
      }
    },

     filteredGrouplesson(lessons){
      return lessons ? _.orderBy(lessons, ['startTime'],['asc']) : new Array();
    },

    addGroupLesson(day, lessons){
      this.newGroupLesson.feedback = null;
      this.newGroupLesson.type = 'new';
      this.newGroupLesson.backgroundColor = this.groupLessonColors[Math.floor(Math.random() * (9 - 0) + 0)];
      this.newGroupLesson.dialog = true;
      this.newGroupLesson.day = day;
      this.newGroupLesson.seats = null;
      this.newGroupLesson.scheduledLessons = _.cloneDeep(lessons);
    },

    resetNewGroupLesson(){
      this.newGroupLesson.index = null;
      this.newGroupLesson.title = null;
      this.newGroupLesson.startTime = null;
      this.newGroupLesson.endTime = null;
      this.newGroupLesson.number = 1;
      this.newGroupLesson.duration = null;
      this.newGroupLesson.treatment = null;
      this.newGroupLesson.day = null;
      this.newGroupLesson.dialog = false;
      this.newGroupLesson.scheduledLessons = new Array();
    },

    calculateNewGroupLessonEndtime(){
      this.newGroupLesson.title = this.newGroupLesson.treatment.treatmentTitle;
      if(this.newGroupLesson.startTime && this.newGroupLesson.treatment && this.newGroupLesson.treatment.treatmentTime && this.newGroupLesson.number){
        this.newGroupLesson.duration = this.newGroupLesson.treatment.treatmentTime;
        this.newGroupLesson.endTime = moment(this.newGroupLesson.startTime, "HH:mm").add((this.newGroupLesson.treatment.treatmentTime * this.newGroupLesson.number), 'minutes').format("HH:mm");       
      }
      else{
        this.newGroupLesson.endTime = null;
      }
    },

    editGroupLesson(day, lessons, index){
      this.newGroupLesson.feedback = null;
      this.newGroupLesson.type = 'edit';
      this.newGroupLesson.day = day;
      this.newGroupLesson.index = index;
      this.newGroupLesson.scheduledLessons = _.cloneDeep(lessons);
      this.newGroupLesson.scheduledLessons[index]
      this.newGroupLesson.title = this.newGroupLesson.scheduledLessons[index].title;
      this.newGroupLesson.number = this.newGroupLesson.scheduledLessons[index].number ? this.newGroupLesson.scheduledLessons[index].number : null;
      this.newGroupLesson.startTime = this.newGroupLesson.scheduledLessons[index].startTime;
      this.newGroupLesson.endTime = this.newGroupLesson.scheduledLessons[index].endTime;
      this.newGroupLesson.duration = this.newGroupLesson.scheduledLessons[index].duration;
      this.newGroupLesson.backgroundColor = this.newGroupLesson.scheduledLessons[index].backgroundColor;

      this.newGroupLesson.number = Math.floor((moment.duration(moment(this.newGroupLesson.scheduledLessons[index].endTime, 'HH:mm').diff(moment(this.newGroupLesson.scheduledLessons[index].startTime, 'HH:mm'))).asMinutes()) / this.newGroupLesson.scheduledLessons[index].duration);

      this.newGroupLesson.seats = this.newGroupLesson.scheduledLessons[index].seats;
      this.newGroupLesson.treatment = this.treatments.find(treatment => treatment.id === this.newGroupLesson.scheduledLessons[index].treatmentId);
      this.newGroupLesson.scheduledLessons.splice(index, 1)
      this.newGroupLesson.dialog = true;
    },

    saveGroupLesson(type){ // Nieuwe groepsles toevoegen
      this.newGroupLesson.feedback = null;
      if(this.newGroupLesson.title && // Alles in ingevuld en berekend?
         this.newGroupLesson.startTime &&
         this.newGroupLesson.endTime &&
         this.newGroupLesson.number &&
         this.newGroupLesson.duration &&
         this.newGroupLesson.treatment.id &&
         this.newGroupLesson.seats
        )
      {
        let valid = true;
        new Promise((resolve)=>{
          let counter = 0;
          // Check of tijdstippen elkaar overlappen
          if(this.newGroupLesson.scheduledLessons.length > 0){ // Check uitvoeren
            this.newGroupLesson.scheduledLessons.forEach(lesson=>{
              if (
                lesson.startTime == this.newGroupLesson.startTime ||
                moment(lesson.startTime, 'HH:mm').isBetween(moment(this.newGroupLesson.startTime, 'HH:mm'), moment(this.newGroupLesson.endTime, 'HH:mm')) == true ||
                moment(this.newGroupLesson.startTime, 'HH:mm').isBetween(moment(lesson.startTime, 'HH:mm'), moment(lesson.endTime, 'HH:mm')) == true ||
                moment(this.newGroupLesson.endTime, 'HH:mm').isBetween(moment(lesson.startTime, 'HH:mm'), moment(lesson.endTime, 'HH:mm')) == true
              ) {
                valid = false;
              }
              counter++
              if(counter == this.newGroupLesson.scheduledLessons.length){
                resolve()
              }
            })
          }
          else{ // Geen bestaande events op deze dag
            resolve()
          }
        })
        .then(()=>{
          if(valid){ // Nieuwe groepsles overlapt geen bestaande groepslessen
            if(type == 'new'){
              this.newRoom.days[this.newGroupLesson.day].push({
                title: this.newGroupLesson.title,
                startTime: this.newGroupLesson.startTime,
                endTime: this.newGroupLesson.endTime,
                duration: this.newGroupLesson.duration,
                seats: this.newGroupLesson.seats,
                number: this.newGroupLesson.number,
                treatmentId:this.newGroupLesson.treatment.id,
                backgroundColor: this.newGroupLesson.backgroundColor
              })
            }
            else{ 
              this.newGroupLesson.scheduledLessons.push({
                title: this.newGroupLesson.title,
                startTime: this.newGroupLesson.startTime,
                endTime: this.newGroupLesson.endTime,
                duration: this.newGroupLesson.duration,
                seats: this.newGroupLesson.seats,
                number: this.newGroupLesson.number,
                treatmentId:this.newGroupLesson.treatment.id,
                backgroundColor: this.newGroupLesson.backgroundColor
              })
              this.newRoom.days[this.newGroupLesson.day] = _.cloneDeep(this.newGroupLesson.scheduledLessons)
            }
            this.resetNewGroupLesson(); // Reset and close dialog
          }
          else{
            this.newGroupLesson.feedback = this.$t('rooms.feedbackOverlap', this.$store.state.locale);
          }
        })     
      }
      else{ // Niet alles in ingevuld
        this.newGroupLesson.feedback = this.$t('rooms.feedbackRequired', this.$store.state.locale);
      }
    },

    loadGroupLessonTreatments(){
      this.groupTreatments = new Array();
      this.treatments = new Array();
      db.collection("treatments")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then(snap => {
          this.treatments = new Array();
          snap.forEach(doc => {
            let treatment = doc.data();
            treatment.id = doc.id;
            this.treatments.push(treatment);
            if(treatment.eventBooking){
              this.groupTreatments.push(treatment);
            }
          });
        })
        .catch(error=>{
          console.error("Error getting GroupLessonTreatments: ", error)
        })
    }
  },
  computed: {
     numberOfLessonsNew(){ // Calculate the possible times in the time left of the day
      if(this.newGroupLesson.type == 'new'){
        this.newGroupLesson.number = null;
      }
      
      let availableNumbers = {
        disabled: true,
        numbers: new Array()
      }
      if(this.newGroupLesson.startTime && this.newGroupLesson.treatment){
        let end = moment().endOf('day') // End of the day
        let start = moment(this.newGroupLesson.startTime, 'HH:mm'); // Start of the grouplesson
         
        availableNumbers.numbers = Array.apply(null, Array(Math.floor(duration / this.newGroupLesson.treatment.treatmentTime)))

        if(availableNumbers.numbers.length > 0){
          for (let i = 0; i < availableNumbers.numbers.length; i++) {
            availableNumbers.numbers[i] = i + 1
          }
          availableNumbers.disabled = false;
        }
        return availableNumbers;
      }
      else{
        return availableNumbers;
      }
    },

    numberOfLessonsEdit(){ // Calculate the possible times in the time left of the day
      let availableNumbers = {
        disabled: true,
        numbers: new Array()
      }
      if(this.newGroupLesson.startTime && this.newGroupLesson.treatment){
        let end = moment().endOf('day') // End of the day
        let start = moment(this.newGroupLesson.startTime, 'HH:mm'); // Start of the grouplesson
        let duration = moment.duration(end.diff(start)).asMinutes(); // time left in minutes    
        availableNumbers.numbers = Array.apply(null, Array(Math.floor(duration / this.newGroupLesson.treatment.treatmentTime)))

        if(availableNumbers.numbers.length > 0){
          for (let i = 0; i < availableNumbers.numbers.length; i++) {
            availableNumbers.numbers[i] = i + 1
          }
          availableNumbers.disabled = false;
        }
        return availableNumbers;
      }
      else{
        return availableNumbers;
      }
    }
  }
};
</script>
