const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

let selectedModifierData = {
  typeDiscount: {
    uniqueItems: true,
    discountType: "amount",
    buyQuantity: 3,
    quantityRules: [],
    getQuantity: 2,
    type: "buyXX",
    discountValue: 10,
  },
  deleted: false,
  dateLimit: { active: false, startDate: null, endDate: null },
  description: "Koop X krijg X",
  name: "X to X Joe",
  combinationLimit: false,
  // filterTreatments: { itemsAction: "exclude", items: ["3s6qQlPWdem2XeDrdYyP","1fpQ7Paga0hrZeL1zPqG"], type: "specific", active: true }, //Specific
  filterTreatments: {
    itemsAction: "exclude",
    items: ["1fpQ7Paga0hrZeL1zPqG"],
    type: "categories",
    active: false,
  }, //category

  numberLimit: { number: null, numberUsed: null, type: "max", active: false },
  active: false,
  useLimit: { active: false, number: null },
  filterProducts: {
    itemsAction: "include",
    active: false,
    items: ["23ZEDz8ozeDWUhlt6K3g", "CCCProduct", "CDAsupplier", "testCat"],
    type: "specific",
  }, //Specific
  //  filterProducts: { itemsAction: "include", active: true, items: ["testCat"], type: "specific" }, //Catogory
  //   filterProducts: { itemsAction: "include", active: true, items: ["w0BF9tDRBtX9asl7Zcrm"], type: "specific" }, //Supplier

  // type: "all",
  type: "products",
  companyId: "hogansbeauty",
  automatic: false,
  branches: ["hogansbeautybreukelen"],
  id: "NTO28Fx0BaQZ4XHXVP4i",
};

let modifiers = [
  {
    description: "Koop X krijg X ANDERE KORTING",
    dateLimit: { active: false, startDate: null, endDate: null },
    name: "X to X Joe Duplicate",
    filterTreatments: {
      itemsAction: "include",
      items: ["3s6qQlPWdem2XeDrdYyP"],
      type: "specific",
      active: true,
    },

    filterProducts: {
      type: "specific",
      items: ["23ZEDz8ozeDWUhlt6K3g"],
      itemsAction: "include",
      active: true,
    },
    typeDiscount: {
      discountType: "amount",
      discountValue: 10,
      getQuantity: 2,
      buyQuantity: 3,
      quantityRules: [],
      type: "buyXX",
      uniqueItems: true,
    },
    automatic: false,
    combinationLimit: false,
    active: true,
    useLimit: { number: null, active: false },
    deleted: false,
    companyId: "hogansbeauty",
    type: "all",
    branches: ["hogansbeautybreukelen"],
    numberLimit: { type: "max", active: false, number: null, numberUsed: null },
    id: "AAAA",
  },
  {
    dateLimit: { active: false, startDate: null, endDate: null },
    active: true,
    branches: ["papendorp", "hogansbeautybreukelen"],
    companyId: "hogansbeauty",
    typeDiscount: {
      type: "buyXX",
      getQuantity: 3,
      quantityRules: [],
      discountType: "amount",
      discountValue: 4,
      buyQuantity: 3,
      uniqueItems: true,
    },
    active: true,
    description: "Een tweede X to X",
    filterTreatments: {
      itemsAction: "include",
      items: ["1fpQ7Paga0hrZeL1zPqG"],
      type: "categories",
      active: true,
    },

    useLimit: { active: false, number: null },
    type: "products",
    automatic: false,
    deleted: false,
    numberLimit: { number: null, numberUsed: null, type: "max", active: false },
    filterProducts: {
      active: true,
      type: "specific",
      items: ["23ZEDz8ozeDWUhlt6K3g"],
      itemsAction: "include",
    },
    name: "X to X 2 test Treatment",
    combinationLimit: false,
    id: "msky3FDt0eY0qTAJrO10",
  },
  {
    dateLimit: { active: false, startDate: null, endDate: null },
    active: true,
    branches: ["papendorp", "hogansbeautybreukelen"],
    companyId: "hogansbeauty",
    typeDiscount: {
      type: "buyXX",
      getQuantity: 3,
      quantityRules: [],
      discountType: "amount",
      discountValue: 4,
      buyQuantity: 3,
      uniqueItems: true,
    },
    active: true,
    description: "Een tweede X to X",
    filterTreatments: {
      itemsAction: "include",
      items: ["234Gga0hrZeL1zPqG"],
      type: "categories",
      active: true,
    },

    useLimit: { active: false, number: null },
    type: "products",
    automatic: false,
    deleted: false,
    numberLimit: { number: null, numberUsed: null, type: "max", active: false },
    filterProducts: {
      active: true,
      type: "specific",
      items: ["23ZEDz8ozeDWUhlt6K3g"],
      itemsAction: "include",
    },
    name: "Andere Categorie",
    combinationLimit: false,
    id: "msky3FDt0eY0qTAJrO10",
  },
];

let products = [
  {
    companyId: "hogansbeauty",
    priceNetto: 18,
    userManual: null,
    medical: false,
    sku: null,
    discount: {
      number: 10,
      type: "percentage",
      start: null,
      active: true,
      end: null,
    },
    costPrice: 10,
    category: ["G79WhJ7ZtNj7kaSpvr0J"],
    imageUrl: "",
    barcode: { type: "undefined", code: null },
    active: true,
    name: "StyleToGo 2.0",
    supplier: "w0BF9tDRBtX9asl7Zcrm",
    useStock: true,
    branches: [
      "hogansbeautybreukelen",
      "hogansbeautypapendorp",
      "hogansbeautywtc",
    ],
    stock: {
      hogansbeautybreukelen: { minStockValue: 5, stockValue: 10 },
      hogansbeautywtc: { stockValue: 4, minStockValue: 10 },
      hogansbeautypapendorp: { stockValue: 15, minStockValue: 5 },
    },
    productDescription: null,
    content: "50ml",
    vat: "UOjSPqfO7kyR8MS09Wa9",
    id: "23ZEDz8ozeDWUhlt6K3g",
  },
  {
    discount: {
      end: null,
      active: false,
      start: null,
      number: null,
      type: "percentage",
    },
    branches: ["hogansbeautybreukelen"],
    content: null,
    active: true,
    productDescription: "   ",
    category: ["G79WhJ7ZtNj7kaSpvr0J"],
    supplier: "w0BF9tDRBtX9asl7Zcrm",
    name: "Blush",
    useStock: true,
    sku: null,
    barcode: { code: null, type: "undefined" },
    companyId: "hogansbeauty",
    userManual: null,
    imageUrl: "",
    vat: "W0YE8q5sCwEMTueZ5CAE",
    medical: false,
    costPrice: 10,
    priceNetto: 25,
    stock: {
      hogansbeautywtc: { stockValue: 0, minStockValue: 0 },
      hogansbeautybreukelen: { minStockValue: 0, stockValue: 50 },
      hogansbeautypapendorp: { minStockValue: 0, stockValue: 0 },
    },
    id: "WQCFNqLkZ7x1nkcF9TTu",
  },
  {
    costPrice: 15,
    companyId: "hogansbeauty",
    content: "300 gram",
    useStock: true,
    priceNetto: 28.88,
    active: true,
    discount: {
      end: null,
      number: null,
      active: false,
      start: null,
      type: "percentage",
    },
    imageUrl: "",
    vat: "UOjSPqfO7kyR8MS09Wa9",
    name: "Burn Pro",
    productDescription: null,
    medical: true,
    branches: ["hogansbeautybreukelen"],
    category: ["MPIIPMFBpTUTfIzS8qmd", "G79WhJ7ZtNj7kaSpvr0J"],
    barcode: { code: 9780201379624, type: "EAN13" },
    stock: {
      hogansbeautywtc: { minStockValue: 0, stockValue: 0 },
      hogansbeautybreukelen: { stockValue: 20, minStockValue: 5 },
      hogansbeautypapendorp: { minStockValue: 0, stockValue: 0 },
    },
    userManual: null,
    sku: null,
    supplier: null,
    id: "qkFh12NRVZaJIR7erNUK",
  },
  {
    costPrice: 15,
    companyId: "hogansbeauty",
    content: "300 gram",
    useStock: true,
    priceNetto: 28.88,
    active: true,
    discount: {
      end: null,
      number: null,
      active: false,
      start: null,
      type: "percentage",
    },
    imageUrl: "",
    vat: "UOjSPqfO7kyR8MS09Wa9",
    name: "Burn Extra",
    productDescription: null,
    medical: true,
    branches: ["hogansbeautybreukelen"],
    category: ["MPIIPMFBpTUTfIzS8qmd", "G79WhJ7ZtNj7kaSpvr0J"],
    barcode: { code: 9780201379624, type: "EAN13" },
    stock: {
      hogansbeautywtc: { minStockValue: 0, stockValue: 0 },
      hogansbeautybreukelen: { stockValue: 20, minStockValue: 5 },
      hogansbeautypapendorp: { minStockValue: 0, stockValue: 0 },
    },
    userManual: null,
    sku: null,
    supplier: "CDAsupplierToken",
    id: "CDAsupplier",
  },
  //   {
  //     supplier: "w0BF9tDRBtX9asl7Zcrm",
  //     branches: ["hogansbeautybreukelen"],
  //     active: true,
  //     companyId: "hogansbeauty",
  //     barcode: { type: "undefined", code: null },
  //     imageUrl: "",
  //     costPrice: 20,
  //     vat: "W0YE8q5sCwEMTueZ5CAE",
  //     name: "Hair Spray ",
  //     content: "30 ml",
  //     productDescription: " ",
  //     useStock: true,
  //     sku: null,
  //     discount: { end: null, number: null, start: null, active: false, type: "percentage" },
  //     stock: { hogansbeautypapendorp: { stockValue: 0, minStockValue: 0 }, hogansbeautywtc: { stockValue: 0, minStockValue: 0 }, hogansbeautybreukelen: { stockValue: 30, minStockValue: 0 } },
  //     medical: false,
  //     priceNetto: 30,
  //     userManual: null,
  //     category: ["testCat", "andereCat"],
  //     id: "CCCProduct",
  //   },
];

let treatments = [
  {
    preferredPractitioner: true,
    treatmentTitleIntern: "Behandeling van Svenn",
    timeIteration: 15,
    treatmentType: "1fpQ7Paga0hrZeL1zPqG",
    timeRestriction: {
      hogansbeautybreukelen: {
        Tue: [
          { end: "16:00", start: "14:00" },
          { start: "17:00", end: "18:00" },
        ],
      },
    },
    treatmentTime: 30,
    subTreatments: [],
    employees: [
      "2OiiKJ4NRfy5VGKimR6x",
      "c4oTxs4biqLKkjfGY43x",
      "LXO4YXHt8MQJMnUM5dQz",
    ],
    free: false,
    device: true,
    bookingTimeLimit: null,
    treatmentPrice: 91.74,
    customTimeIteration: true,
    vat: "W0YE8q5sCwEMTueZ5CAE",
    companyId: "hogansbeauty",
    treatmentActive: true,
    treatmentShortDesc: "Effectieve behandeling tegen stres",
    deviceId: 15,
    branches: ["hogansbeautybreukelen"],
    discount: {
      active: false,
      type: "amount",
      brutoPrice: null,
      start: "2019-10-10",
      nettoPrice: null,
      number: 5,
      end: "2019-10-15",
      period: false,
    },
    treatmentDesc: "Effectieve behandeling tegen stresssss",
    treatmentOnline: true,
    treatmentGender: "unisex",
    treatmentTitle: "Sven's Treatmentt",
    id: "3s6qQlPWdem2XeDrdYyP",
  },

  {
    branches: ["hogansbeautybreukelen"],
    treatmentTitleIntern: "Alleen Device ",
    vat: "W0YE8q5sCwEMTueZ5CAE",
    discount: {
      number: 5,
      end: "2019-10-10",
      active: false,
      type: "amount",
      start: "2019-10-05",
      brutoPrice: null,
      nettoPrice: null,
      period: true,
    },
    free: false,
    bookingTimeLimit: null,
    treatmentGender: "female",
    customTimeIteration: true,
    subTreatments: [],
    companyId: "hogansbeauty",
    treatmentTitle: "Alleen Device ",
    treatmentPrice: 9.17,
    device: false,
    deviceId: 1,
    treatmentDesc: "Derde oog masseren",
    treatmentTime: 20,
    treatmentActive: true,
    timeIteration: 15,
    treatmentShortDesc: "Derde oog masseren",
    employees: ["c4oTxs4biqLKkjfGY43x", "2OiiKJ4NRfy5VGKimR6x"],
    timeRestriction: null,
    treatmentOnline: true,
    preferredPractitioner: true,
    treatmentType: "234Gga0hrZeL1zPqG",
    id: "AAonhuskMgoONVB7fIpx",
  },
];

//Test Purpose:
// getCorrelatedModifiers(products, treatments, modifiers, selectedModifierData);

/**
 * Validate and find correlated modifiers that are active with the same product or treatment.
 * Always check the dateLimit first to narrow its scope.
 *
 * @param {Array} products - List of product objects. Each product object contains details about a product, such as its ID, name, category, supplier, price, stock information, and other relevant attributes.
 * @param {Array} treatments - List of treatment objects. Each treatment object includes details about a treatment, such as its ID, title, type, duration, price, applicable branches, and other relevant attributes.
 * @param {Array} modifiers - List of all available modifier objects. Each modifier object contains information about a discount or promotion, including its ID, name, description, type, date limits, filter criteria for products and treatments, and other relevant attributes.
 * @param {Object} selectedModifierData - The data of the selected modifier to be validated.
 * @returns {Object} An object containing correlated product and treatment modifiers.
 */
export function getCorrelatedModifiers(
  products,
  treatments,
  modifiers,
  selectedModifierData,
) {


  selectedModifierData = JSON.parse(JSON.stringify(selectedModifierData));
  modifiers = JSON.parse(JSON.stringify(modifiers));

  console.log(
    " modifiers, selectedModifierData",
    selectedModifierData
  );

  /**
   * Clean up modifiers by filter Date range and Activity
   * Always check the dateLimit first to narrow its scope
   */
  let checkModifiersDuplicate = new Array(); //All the modifiers that are in date range or doesnt have range.


  //Is a new modifier ? Or an existed Modifier -> filter out the modifier for comparison
  let modifiersFiltered = modifiers.filter((modifier) => modifier.id !== selectedModifierData.id); //?

      console.log("modifiersFiltered", modifiersFiltered);

  //Get the right branches
  if (selectedModifierData.branches.length !== 0) {
  
    let modifierBranchMatch = new Array();

    modifiersFiltered.forEach((modifier) => {
      modifier.branches.find((branch) => {
        let matchBrand = selectedModifierData.branches.includes(branch); //?
        if (matchBrand) {
          modifierBranchMatch.push(modifier);
        }
      });
    });

    modifiersFiltered = modifierBranchMatch;
  } else {
    //Nothing to match
    modifiersFiltered = new Array();
  }

  // modifiersFiltered = modifiersFiltered.filter((modifier) => modifier.branches.map((branch) => branch.includes(selectedModifierData.branches)))


  if (selectedModifierData.dateLimit.active) {
    let dateThisModifer = moment.range(
      selectedModifierData.dateLimit.startDate,
      selectedModifierData.dateLimit.endDate
    );
    checkModifiersDuplicate = modifiersFiltered.filter((modifier) => {
      if (modifier.active === true) {
        let dateOtherModifier = null; //time range
        if (modifier.dateLimit.active) {
          //Get range of other Modifier
          dateOtherModifier = moment.range(
            modifier.dateLimit.startDate,
            modifier.dateLimit.endDate
          );
          //Does is overlaps and adjecent?
          if (dateThisModifer.overlaps(dateOtherModifier, { adjacent: true })) {
            return modifier;
          }
        } else {
          //No date limit but the modifier is active so return modifier
          return modifier;
        }
      }
    });
  } else {
    //No date limit found
    checkModifiersDuplicate = modifiersFiltered;
  }

  checkModifiersDuplicate; //?
  checkModifiersDuplicate.length; //?
  console.log(checkModifiersDuplicate);

  /**
   * Check products and treatments different from each other for better output control
   *
   */

  let alreadyUsedModifiersProducts = new Set(); // founded Duplicated Prodcuts
  let alreadyUsedModifiersTreatments = new Set(); // founded Duplicated Prodcuts

  let productsActive = false;
  let treatmentsActive = false;

  if (selectedModifierData.type === "product") {
    productsActive = true;
  } else if (selectedModifierData.type === "treatments") {
    treatmentsActive = true;
  } else {
    productsActive = true;
    treatmentsActive = true;
  }

  //TODO onderstaande FLAGS / BOOLEANS BINDEN MET @selectedModifierData param

  //Products
  const specificProductsToggle =
    selectedModifierData.filterProducts.type === "specific" ? true : false;
  const categoryProductsToggle =
    selectedModifierData.filterProducts.type === "categories" ? true : false;
  const supplierProductsToggle =
    selectedModifierData.filterProducts.type === "brands" ? true : false;

  //Items Action "include" && "exclude"
  const specificProductsItemsAction =
    selectedModifierData.filterProducts.itemsAction;
  const specificTreatmentsItemsAction =
    selectedModifierData.filterTreatments.itemsAction;

  //Treatments
  const specificTreatmentsToggle =
    selectedModifierData.filterTreatments.type === "specific" ? true : false;
  const categoryTreatmentsToggle =
    selectedModifierData.filterTreatments.type === "categories" ? true : false;

  if (productsActive) productValidate();
  if (treatmentsActive) treatmentValidate();

  function productValidate() {
    console.log("products Validate van Start");
    //SPECIFIC SELECTED ITEMS
    //selectedItem is the item
    console.log("specificProductsToggle", specificProductsToggle);

    if (productsActive === true && specificProductsToggle === true) {
      console.log("product toggles specific");
      if (specificProductsItemsAction === "include") {
        selectedModifierData.filterProducts.items.forEach((selectedItem) => {
          //Specific

          let foundSpecific = checkModifiersDuplicate.filter(
            (products) =>
              products.filterProducts.items.length !== 0 &&
              products.filterProducts.type === "specific" &&
              products.filterProducts.items.includes(selectedItem)
          );

          foundSpecific.forEach((specific) =>
            alreadyUsedModifiersProducts.add(specific.id)
          );

          //Category
          //Check if the product is also use by other modifiers by categories
          //Get categorie of product
          let getCategories = products.find(
            (product) => product.id === selectedItem
          );
          //Filter if categorie is used by other modifiers
          getCategories; //?
          if (getCategories) {
            getCategories.category.forEach((category) => {
              let findItemsCategorie = checkModifiersDuplicate.filter(
                (modifier) =>
                  modifier.filterProducts.items.length !== 0 &&
                  modifier.filterProducts.type === "categories" &&
                  modifier.filterProducts.items.includes(category)
              );

              findItemsCategorie.forEach((specific) =>
                alreadyUsedModifiersProducts.add(specific.id)
              );
            });
          }

          //Supplier
          let getSupplier = products.find(
            (product) => product.id === selectedItem
          );
          if (getSupplier) {
            let findItemsSupplier = checkModifiersDuplicate.filter(
              (modifier) =>
                modifier.filterProducts.items.length !== 0 &&
                modifier.filterProducts.type === "brands" &&
                modifier.filterProducts.items.includes(getSupplier.supplier)
            ); //?
            findItemsSupplier.forEach((specific) =>
              alreadyUsedModifiersProducts.add(specific.id)
            );
          }
        });
      } else {

        

        //Exclude
        selectedModifierData.filterProducts.items.forEach((selectedItem) => {
          //Specific

          let foundSpecific = checkModifiersDuplicate.filter(
            (products) =>
              products.filterProducts.items.length !== 0 &&
              products.filterProducts.type === "specific" &&
              !products.filterProducts.items.includes(selectedItem)
          );

          foundSpecific.forEach((specific) =>
            alreadyUsedModifiersProducts.add(specific.id)
          );

          //Category
          //Check if the product is also use by other modifiers by categories
          //Get categorie of product
          let getCategories = products.find(
            (product) => product.id === selectedItem
          );
          //Filter if categorie is used by other modifiers
          getCategories; //?
          if (getCategories) {
            getCategories.category.forEach((category) => {
              let findItemsCategorie = checkModifiersDuplicate.filter(
                (modifier) =>
                  modifier.filterProducts.items.length !== 0 &&
                  modifier.filterProducts.type === "categories" &&
                  !modifier.filterProducts.items.includes(category)
              );

              findItemsCategorie.forEach((specific) =>
                alreadyUsedModifiersProducts.add(specific.id)
              );
            });
          }

          //Supplier
          let getSupplier = products.find(
            (product) => product.id === selectedItem
          );
          if (getSupplier) {
            let findItemsSupplier = checkModifiersDuplicate.filter(
              (modifier) =>
                modifier.filterProducts.items.length !== 0 &&
                modifier.filterProducts.type === "brands" &&
                !modifier.filterProducts.items.includes(getSupplier.supplier)
            ); //?
            findItemsSupplier.forEach((specific) =>
              alreadyUsedModifiersProducts.add(specific.id)
            );
          }
        });
      }
    }

    //CATEGORIE SELECTED ITEMS
    if (productsActive === true && categoryProductsToggle === true) {
      if (specificProductsItemsAction === "include") {
        selectedModifierData.filterProducts.items.forEach((selectedItem) => {
          //selectedItem = categorie ID
          //Specific
          //get allproducts of categorie
          let getProducts = products.filter((product) =>
            product.category.includes(selectedItem)
          ); //?

          getProducts.forEach((product) => {
            let foundSpecific = checkModifiersDuplicate.filter(
              (products) =>
                products.filterProducts.items.length !== 0 &&
                products.filterProducts.type === "specific" &&
                products.filterProducts.items.includes(product.id)
            );
            foundSpecific.forEach((specific) =>
              alreadyUsedModifiersProducts.add(specific.id)
            );
          });

          //Category
          let foundCategory = checkModifiersDuplicate.filter(
            (products) =>
              products.filterProducts.items.length !== 0 &&
              products.filterProducts.type === "categories" &&
              products.filterProducts.items.includes(selectedItem)
          ); //?
          foundCategory.forEach((specific) =>
            alreadyUsedModifiersProducts.add(specific.id)
          );

          //Supplier
          //get suppliers by categorie
          let getSupplier = products
            .filter((product) => product.category.includes(selectedItem))
            .map((product) => product.supplier); //?
          getSupplier.forEach((supplier) => {
            let foundSupplier = checkModifiersDuplicate.filter(
              (products) =>
                products.filterProducts.items.length !== 0 &&
                products.filterProducts.type === "brands" &&
                products.filterProducts.items.includes(supplier)
            ); //?
            foundSupplier.forEach((specific) =>
              alreadyUsedModifiersProducts.add(specific.id)
            );
          });
        });
      } else {
        //Exclude
        selectedModifierData.filterProducts.items.forEach((selectedItem) => {
          //selectedItem = categorie ID
          //Specific
          //get allproducts of categorie
          let getProducts = products.filter(
            (product) => !product.category.includes(selectedItem)
          ); //?

          getProducts.forEach((product) => {
            let foundSpecific = checkModifiersDuplicate.filter(
              (products) =>
                products.filterProducts.items.length !== 0 &&
                products.filterProducts.type === "specific" &&
                !products.filterProducts.items.includes(product.id)
            );
            foundSpecific.forEach((specific) =>
              alreadyUsedModifiersProducts.add(specific.id)
            );
          });

          //Category
          let foundCategory = checkModifiersDuplicate.filter(
            (products) =>
              products.filterProducts.items.length !== 0 &&
              products.filterProducts.type === "categories" &&
              !products.filterProducts.items.includes(selectedItem)
          ); //?
          foundCategory.forEach((specific) =>
            alreadyUsedModifiersProducts.add(specific.id)
          );

          //Supplier
          //get suppliers by categorie
          let getSupplier = products
            .filter((product) => !product.category.includes(selectedItem))
            .map((product) => product.supplier); //?
          getSupplier.forEach((supplier) => {
            let foundSupplier = checkModifiersDuplicate.filter(
              (products) =>
                products.filterProducts.items.length !== 0 &&
                products.filterProducts.type === "brands" &&
                !products.filterProducts.items.includes(supplier)
            ); //?
            foundSupplier.forEach((specific) =>
              alreadyUsedModifiersProducts.add(specific.id)
            );
          });
        });
      }
    }
    //BRAND SELECTED
    if (productsActive === true && supplierProductsToggle === true) {
      if (specificProductsItemsAction === "include") {
        selectedModifierData.filterProducts.items.forEach((selectedItem) => {
          //selectedItem = supplier ID
          //Specific
          //get allproducts of supplier
          let getProducts = products.filter(
            (product) => product.supplier === selectedItem
          ); //?

          getProducts.forEach((product) => {
            let foundSpecific = checkModifiersDuplicate.filter(
              (products) =>
                products.filterProducts.items.length !== 0 &&
                products.filterProducts.type === "specific" &&
                products.filterProducts.items.includes(product.id)
            );
            foundSpecific.forEach((specific) =>
              alreadyUsedModifiersProducts.add(specific.id)
            ); //?
          });

          //Categories
          getProducts.forEach((product) => {
            product.category.forEach((categoryId) => {
              let foundCategory = checkModifiersDuplicate.filter(
                (products) =>
                  products.filterProducts.items.length !== 0 &&
                  products.filterProducts.type === "categories" &&
                  products.filterProducts.items.includes(categoryId)
              ); //?
              foundCategory.forEach((category) =>
                alreadyUsedModifiersProducts.add(category.id)
              );
            });
          });

          //Suppliers
          let foundSuppliers = checkModifiersDuplicate.filter(
            (products) =>
              products.filterProducts.items.length !== 0 &&
              products.filterProducts.type === "brands" &&
              products.filterProducts.items.includes(selectedItem)
          ); //?
          foundSuppliers.forEach((specific) =>
            alreadyUsedModifiersProducts.add(specific.id)
          );
        });
      } else {
        //Exclude
        selectedModifierData.filterProducts.items.forEach((selectedItem) => {
          //selectedItem = supplier ID
          //Specific
          //get allproducts of supplier
          let getProducts = products.filter(
            (product) => product.supplier !== selectedItem
          ); //?

          getProducts.forEach((product) => {
            let foundSpecific = checkModifiersDuplicate.filter(
              (products) =>
                products.filterProducts.items.length !== 0 &&
                products.filterProducts.type === "specific" &&
                !products.filterProducts.items.includes(product.id)
            );
            foundSpecific.forEach((specific) =>
              alreadyUsedModifiersProducts.add(specific.id)
            ); //?
          });

          //Categories
          getProducts.forEach((product) => {
            product.category.forEach((categoryId) => {
              let foundCategory = checkModifiersDuplicate.filter(
                (products) =>
                  products.filterProducts.items.length !== 0 &&
                  products.filterProducts.type === "categories" &&
                  !products.filterProducts.items.includes(categoryId)
              ); //?
              foundCategory.forEach((category) =>
                alreadyUsedModifiersProducts.add(category.id)
              );
            });
          });

          //Suppliers
          let foundSuppliers = checkModifiersDuplicate.filter(
            (products) =>
              products.filterProducts.items.length !== 0 &&
              products.filterProducts.type === "brands" &&
              !products.filterProducts.items.includes(selectedItem)
          ); //?
          foundSuppliers.forEach((specific) =>
            alreadyUsedModifiersProducts.add(specific.id)
          );
        });
      }
    }
  }

  function treatmentValidate() {
    //SPECIFIC SELECTED ITEMS
    //selectedItem is the item
    if (treatmentsActive === true && specificTreatmentsToggle === true) {
      if (specificTreatmentsItemsAction === "include") {
        selectedModifierData.filterTreatments.items.forEach((selectedItem) => {
          //Specific
          selectedItem; //?

          let foundSpecific = checkModifiersDuplicate.filter(
            (treatments) =>
              treatments.filterTreatments.items.length !== 0 &&
              treatments.filterTreatments.items.includes(selectedItem)
          );
          foundSpecific; //?
          foundSpecific.forEach((specific) =>
            alreadyUsedModifiersTreatments.add(specific.id)
          );

          //Category
          //Check if the treatment is also use by other modifiers by categories
          let getCategories = treatments.find(
            (treatment) => treatment.id === selectedItem
          ); //?

          if (getCategories) {
            getCategories.treatmentType; //?
            let foundModifiers = checkModifiersDuplicate.filter(
              (modifier) =>
                modifier.filterTreatments.items.length !== 0 &&
                !modifier.filterTreatments.items.includes(
                  getCategories.treatmentType
                )
            ); //?
            foundModifiers; //?
            foundModifiers.forEach((specific) =>
              alreadyUsedModifiersTreatments.add(specific.id)
            );
          }
        });
      } else {
        //Exclude
        selectedModifierData.filterTreatments.items.forEach((selectedItem) => {
          //Specific
          selectedItem; //?
          let foundSpecific = checkModifiersDuplicate.filter(
            (treatments) =>
              treatments.filterTreatments.items.length !== 0 &&
              treatments.filterTreatments.type === "specific" &&
              !treatments.filterTreatments.items.includes(selectedItem)
          );
          foundSpecific; //?
          foundSpecific.forEach((specific) =>
            alreadyUsedModifiersTreatments.add(specific.id)
          );

          //Category
          //Check if the treatment is also use by other modifiers by categories
          let getCategories = treatments.find(
            (treatment) => treatment.id === selectedItem
          ); //?

          if (getCategories) {
            getCategories.treatmentType; //?
            let foundModifiers = checkModifiersDuplicate.filter(
              (modifier) =>
                modifier.filterTreatments.items.length !== 0 &&
                modifier.filterTreatments.type === "categories" &&
                !modifier.filterTreatments.items.includes(
                  getCategories.treatmentType
                )
            ); //?
            foundModifiers; //?
            foundModifiers.forEach((specific) =>
              alreadyUsedModifiersTreatments.add(specific.id)
            );
          }
        });
      }
    }
    //Treatment Categories
    if (treatmentsActive === true && categoryTreatmentsToggle === true) {
      if (specificTreatmentsItemsAction === "include") {
        selectedModifierData.filterTreatments.items.forEach((selectedItem) => {
          //Specific
          let getAllTreatmentsByCategoryId = treatments.filter(
            (treatment) => treatment.treatmentType === selectedItem
          );
          //Check if selectedItem is in use by Other Modifiers
          getAllTreatmentsByCategoryId.forEach((treatment) => {
            let foundModifiers = checkModifiersDuplicate.filter(
              (modifier) =>
                modifier.filterTreatments.items.length !== 0 &&
                modifier.filterTreatments.type === "specific" &&
                modifier.filterTreatments.items.includes(treatment.id)
            ); //?
            foundModifiers.forEach((specific) =>
              alreadyUsedModifiersTreatments.add(specific.id)
            );
          });

          //Categories
          let findCategories = treatments.filter(
            (treatment) => treatment.treatmentType === selectedItem
          );
          findCategories; //?
          findCategories.forEach((treatment) => {
            // treatment.treatmentType; //?
            // treatment.id; //?
            let foundModifiers = checkModifiersDuplicate.filter(
              (modifier) =>
                modifier.filterTreatments.items.length !== 0 &&
                modifier.filterTreatments.type === "categories" &&
                modifier.filterTreatments.items.includes(treatment.id)
            ); //?
            foundModifiers.forEach((specific) =>
              alreadyUsedModifiersTreatments.add(specific.id)
            );
          });
        });
      } else {
        selectedModifierData.filterTreatments.items.forEach((selectedItem) => {
          //Specific
          let getAllTreatmentsByCategoryId = treatments.filter(
            (treatment) => treatment.treatmentType !== selectedItem
          );
          //Check if selectedItem is in use by Other Modifiers
          getAllTreatmentsByCategoryId.forEach((treatment) => {
            let foundModifiers = checkModifiersDuplicate.filter(
              (modifier) =>
                modifier.filterTreatments.items.length !== 0 &&
                modifier.filterTreatments.type === "specific" &&
                !modifier.filterTreatments.items.includes(treatment.id)
            ); //?
            foundModifiers.forEach((specific) =>
              alreadyUsedModifiersTreatments.add(specific.id)
            );
          });

          //Categories
          let findCategories = treatments.filter(
            (treatment) => treatment.treatmentType !== selectedItem
          );
          findCategories; //?
          findCategories.forEach((treatment) => {
            // treatment.treatmentType; //?
            // treatment.id; //?
            let foundModifiers = checkModifiersDuplicate.filter(
              (modifier) =>
                modifier.filterTreatments.items.length !== 0 &&
                modifier.filterTreatments.type === "categories" &&
                !modifier.filterTreatments.items.includes(treatment.id)
            ); //?
            foundModifiers.forEach((specific) =>
              alreadyUsedModifiersTreatments.add(specific.id)
            );
          });
        });
      }
    }
  }

  alreadyUsedModifiersProducts; //?
  alreadyUsedModifiersTreatments; //?
  //Get ids from set
  let idsCorrelatedModifierProducts = [...alreadyUsedModifiersProducts];
  let idsCorrelatedModifierTreatments = [...alreadyUsedModifiersTreatments];

  idsCorrelatedModifierProducts; //?
  idsCorrelatedModifierTreatments; //?

  return {
    correlatedProductModifiersName: idsCorrelatedModifierProducts.map(
      (item) => {
        return modifiers.find((modifier) => modifier.id === item).name; //?
      }
    ),
    correlatedProductModifiersId: idsCorrelatedModifierProducts,
    productsFilterType: selectedModifierData.filterProducts.type,
    specificProductsItemsAction: specificProductsItemsAction,
    correlatedTreatmentModifiersName: idsCorrelatedModifierTreatments.map(
      (item) => {
        return modifiers.find((modifier) => modifier.id === item).name; //?
      }
    ),
    correlatedTreatmentModifiersId: idsCorrelatedModifierTreatments,
    treatmentsFilterType: selectedModifierData.filterTreatments.type,
    specificTreatmentsItemsAction: specificTreatmentsItemsAction,
  };
}

// console.log(
//   `Een andere korting zoals ${modifierProducts
//     .map((modifier) => modifier.name)
//     .join(", ")} bevatten in dezelfde periode producten die overeenkomen, dit kan leiden tot het weggeven van teveel korting. Het gaat om de volgende producten:`
// );
