<template>
  <v-dialog v-model="CalbookEventModal" scrollable persistent max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card style="height: 600px; overflow: hidden">
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h2 v-if="!loading && existingEvent">{{ $t('events.editEvent', $store.state.locale) }} {{ event.title }}</h2>
        <h2 v-if="!loading && !existingEvent">{{ $t('events.createEvent', $store.state.locale) }} {{ event.title }}</h2>
        <v-spacer></v-spacer>
        <v-btn v-if="!loading" icon dark @click="closedialog(true)">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate color="white" :size="25" :width="3"></v-progress-circular>
      </v-card-title>

      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text class="pa-0 ma-0" :style="{ background: $vuetify.theme.themes[theme].background }" style="overflow: hidden">
        <div v-if="!loading">
          <v-tabs v-if="existingEvent" background-color="menu" v-model="tab" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
            <v-tab>{{ $t('events.event', $store.state.locale) }}</v-tab>
            <v-tab>{{ $t('events.subscriptions', $store.state.locale) }}</v-tab>
          </v-tabs>

          <v-divider v-if="existingEvent"></v-divider>

          <v-tabs-items v-model="tab" style="height: 493px; overflow: scroll">
            <!-- EVENTS -->
            <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition" :style="{ background: $vuetify.theme.themes[theme].menu }"  >
              <v-container grid-list-md style="padding-bottom: 70px">
                <v-layout row wrap>
                  <v-flex xs12>
                    <h2 style="display: block" v-if="existingEvent">{{ $t('events.globalSettings', $store.state.locale) }}</h2>
                    <p style="display: block; color: #8b93a6" v-if="existingEvent">{{ $t('events.globalSettingsHint', $store.state.locale) }}</p>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field :label="$t('events.eventTitle', $store.state.locale)" value outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" background-color="background" maxlength="30" counter="30" v-model="event.title"></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-textarea
                      name="input-5-1"
                      :label="$t('events.shortDescription', $store.state.locale)"
                      value
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      rows="2"
                      :hint="$t('events.shortDescriptionHint', $store.state.locale)"
                      maxlength="150"
                      counter="150"
                      v-model="event.notes"
                    ></v-textarea>
                    <!-- :disabled="existingEvent" -->
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs6>
                    <v-select
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      :items="eventTypes"
                      item-text="name"
                      item-value="id"
                      v-model="event.eventType"
                      :label="$t('events.eventType', $store.state.locale)"
                    ></v-select>
                  </v-flex>

                  <v-flex xs6>
                    <v-select
                      v-if="event.eventType == 'treatment'"
                      :items="treatmentTypes"
                      item-text="name"
                      item-value="id"
                      v-model="event.treatmentType"
                      :label="$t('events.treatmentType', $store.state.locale)"
                      outlined
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                    ></v-select>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12>
                    <h2 v-if="existingEvent">{{ $t('events.individualSettings', $store.state.locale) }}</h2>
                    <p style="display: block" class="ma-0 pa-0" v-if="existingEvent">{{ $t('events.individualSettingsHint', $store.state.locale) }}</p>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field :label="$t('events.maxNumber', $store.state.locale)" v-model.number="event.persons" :suffix="$t('general.persons', $store.state.locale)" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" background-color="background"></v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :items="subscriptionTimeLimits"
                      item-text="name"
                      item-value="id"
                      v-model="event.maxTimeLimitSubscription"
                      :label="$t('events.maxSubscriptionPeriod', $store.state.locale)"
                      :hint="$t('events.maxSubscriptionPeriodHint', $store.state.locale)"
                      persistent-hint
                      outlined
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                    ></v-select>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :items="subscriptionTimeLimits"
                      item-text="name"
                      item-value="id"
                      v-model="event.minTimeLimitSubscription"
                      :label="$t('events.minSubscriptionPeriod', $store.state.locale)"
                      :hint="$t('events.minSubscriptionPeriodHint', $store.state.locale)"
                      persistent-hint
                      outlined
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                    ></v-select>
                  </v-flex>
                </v-layout>

                <v-layout row wrap v-if="event.showSelectedResources">
                  <v-flex xs6>
                    <v-card class="pa-2" outlined :style="{ background: $vuetify.theme.themes[theme].background }">
                      <h3><strong>{{ $t('events.selectAttendees', $store.state.locale) }}</strong></h3>
                      <v-select
                        class="mt-4"
                        :items="employeesBranch"
                        item-text="resourceName"
                        item-value="resourceId"
                        v-model="selected_employees"
                        :menu-props="{ maxHeight: '300' }"
                        :label="$t('events.employees', $store.state.locale)"
                        multiple
                        persistent-hint
                        outlined
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="menu"
                        hide-details
                      ></v-select>

                      <v-select
                        class="mt-4"
                        :items="employeesBranch"
                        item-text="resourceName"
                        item-value="resourceId"
                        v-model="selected_employees_override"
                        :menu-props="{ maxHeight: '300' }"
                        :label="$t('events.employeesOverwritable', $store.state.locale)"
                        multiple
                        arr="selected_employees_override"
                        persistent-hint
                        outlined
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="menu"
                        hide-details
                      ></v-select>
                    </v-card>
                  </v-flex>

                  <v-flex xs6 v-if="event.showSelectedResources">
                    <v-card class="pa-2" outlined :style="{ background: $vuetify.theme.themes[theme].background }">
                      <h3><strong>{{ $t('events.bookRooms', $store.state.locale) }}</strong></h3>
                      <v-select
                        class="mt-4"
                        :items="roomsBranch"
                        item-text="resourceName"
                        item-value="resourceId"
                        v-model="selected_rooms"
                        :menu-props="{ maxHeight: '300' }"
                        :label="$t('general.rooms', $store.state.locale)"
                        multiple
                        persistent-hint
                        outlined
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="menu"
                        hide-details
                      ></v-select>

                      <v-select
                        class="mt-4"
                        :items="roomsBranch"
                        item-text="resourceName"
                        item-value="resourceId"
                        v-model="selected_rooms_override"
                        :menu-props="{ maxHeight: '300' }"
                        :label="$t('events.roomsOverwritable', $store.state.locale)"
                        multiple
                        persistent-hint
                        outlined
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="menu"
                        hide-details
                      ></v-select>
                    </v-card>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs6 v-if="!existingEvent" style="margin-top: 25px">
                    <v-menu ref="menustartDate" :close-on-content-click="false" v-model="menuStartDate" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="chosenDate_begin"
                          :label="$t('events.startDate', $store.state.locale)"
                          readonly
                          prepend-inner-icon="mdi-calendar"
                          outlined
                          v-on="on"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="background"
                        ></v-text-field>
                      </template>

                      <v-date-picker v-model="chosenDate_begin" no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" scrollable :first-day-of-week="1" @input="menuStartDate = false"></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 v-if="!existingEvent">
                    <v-switch class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('events.repeatEvent', $store.state.locale)" v-model="repeatEvent"> </v-switch>
                  </v-flex>

                  <v-flex xs6 v-show="repeatEvent" v-if="!existingEvent">
                    <v-menu ref="menuEndDate" :close-on-content-click="false" v-model="menuEndDate" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="chosenDate_end"
                          :label="$t('events.repeatTill', $store.state.locale)"
                          readonly
                          prepend-inner-icon="mdi-calendar"
                          v-on="on"
                          outlined
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="background"
                        ></v-text-field>
                      </template>

                      <v-date-picker v-model="chosenDate_end" no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" scrollable @input="menuEndDate = false"></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs2 v-show="repeatEvent">
                    <v-select
                      :items="everychoose"
                      item-value="id"
                      v-model="everychoose_selected"
                      :menu-props="{ maxHeight: '300' }"
                      persistent-hint
                      outlined
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                    ></v-select>
                  </v-flex>

                  <v-flex xs4 v-show="repeatEvent">
                    <v-select
                      :items="planRepeatOptions"
                      item-text="name"
                      item-value="id"
                      v-model="selected_planRepeatOptions"
                      :menu-props="{ maxHeight: '300' }"
                      persistent-hint
                      outlined
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                    ></v-select>
                  </v-flex>

                  <!-- <div class="linebreark" style="width:300px;"></div> -->
                  <v-flex xs6>
                    <v-text-field
                      :label="$t('general.from', $store.state.locale)"
                      v-model="event.selectedStart"
                      type="time"
                      :suffix="$t('general.hour', $store.state.locale)"
                      @change="timeChanged = true; event.showSelectedResources = true;"
                      prepend-inner-icon="mdi-clock"
                      outlined
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6>
                    <v-text-field
                      :label="$t('general.till', $store.state.locale)"
                      v-model="event.selectedEnd"
                      type="time"
                      :suffix="$t('general.hour', $store.state.locale)"
                      outlined
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      @change="
                        timeChanged = true;
                        event.showSelectedResources = true;
                      "
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('events.showEventInWidget', $store.state.locale)" v-model="event.showInWidget"></v-switch>
                  </v-flex>

                  <v-flex xs12>
                    <v-switch class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('events.showSeatsInWidget', $store.state.locale)" v-model="event.showSeats"></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>

            <!-- INSCHRIJVINGEN -->
            <v-tab-item
              :value="1"
              :transition="tabTransition"
              :reverse-transition="tabTransition"
              style="position: relative; height: 100%"
              :style="{ background: $vuetify.theme.themes[theme].background }"
            >
              <div class="pa-4 text-center">
                <h4 class="ma-0">
                  <strong>{{ registrationsList.length }}/{{ event.persons }} {{ $t('events.subscriptionsNumber', $store.state.locale) }} </strong>
                </h4>
                <v-btn fab small class="mt-2" v-if="registrationsList.length < event.persons" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="openNewSubscriptionModal()">
                  <v-icon color="white">mdi-plus</v-icon>
                </v-btn>
              </div>
              <v-data-table :headers="headers" :items="registrationsList" :items-per-page="-1" hide-default-footer item-key="index" :no-data-text="$t('events.noSubscriptionsFound', $store.state.locale)">
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td>{{ item.name }} {{ item.surname }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone }}</td>
                    <td>
                      <v-btn icon @click="removeClientSubscriptionDialog(item, index)">
                        <v-icon small color="text">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <div v-else style="position: relative; padding: 200px 0">
          <div class="thrive-loader" :class="$store.state.companyTheme"></div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left">
              <div class="text-left">
                <div v-show="existingEvent" style="display: inline-block; margin: 0 5px">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" text icon>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link v-for="(item, index) in eventDeleteOptions" :key="index" @click.native="deleter(item.id)">
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <v-btn v-if="!loading" outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  @click="closedialog()"> {{ $t('general.cancel', $store.state.locale) }}  </v-btn>
              </div>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn v-if="!existingEvent" dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="bookEvent()">
                {{ $t('events.addEvent', $store.state.locale) }} 
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn :depressed="!eventChanged" :disabled="!eventChanged" :dark="eventChanged" v-if="existingEvent && !loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="saveExistingEvent()">
                <div v-if="eventChanged">{{ $t('general.saveChanged', userLanguage) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t('general.saved', userLanguage) }} <v-icon right >mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

      <v-dialog v-model="addClientSubscriptionModal" max-width="500px" hide-overlay scrollable persistent :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card style="max-height: 700px">
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h2>Cliënt inschrijven</h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="(addClientSubscriptionModal = false), (selectedClient.selected = false), (newclient = false)">
              <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-2 py-4 ma-0">
            <v-card flat color="background" class="text-center pa-2">
              <div v-if="!newclient">
                <h3 class="pa-2"><strong>{{ $t('clients.createNewClient', $store.state.locale) }} </strong></h3>
                <p class="ma-0">{{ $t('clients.chooseGender', $store.state.locale) }} </p>
                <div class="pa-4 text-center">
                  <v-btn
                    depressed
                    class="ma-1"
                    style="width: 150px"
                    v-if="!newclient"
                    @click="
                      newclient = true;
                      newClientData.gender = 2;
                    "
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    rounded
                    dark
                    >{{ $t('general.woman', $store.state.locale) }}</v-btn
                  >
                  <v-btn
                    depressed
                    class="ma-1"
                    style="width: 150px"
                    v-if="!newclient"
                    @click="
                      newclient = true;
                      newClientData.gender = 1;
                    "
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    outlined
                    rounded
                    >{{ $t('general.man', $store.state.locale) }}</v-btn
                  >
                </div>
              </div>
              <!-- NEW CLIENT INFO -->
              <div v-show="newclient" class="text-center">
                <h2 class="pa-2"><strong>{{ $t('clients.createNewClient', $store.state.locale) }}</strong></h2>
                <p class="ma-0">{{ $t('clients.clientBasicInfo', $store.state.locale) }}</p>

                <v-form ref="form" v-model="newClientData.valid">
                  <v-container grid-list-md fluid class="ma-0 pa-0">
                    <v-layout row wrap class="ma-0 pa-0">
                      <v-flex md12>
                        <v-text-field :label="$t('general.firstName', $store.state.locale) " class="mt-4" hide-details v-model="newClientData.name" :rules="newClientData.nameRules" outlined background-color="menu" required></v-text-field>
                      </v-flex>
                      <v-flex md12>
                        <v-text-field
                          outlined
                          background-color="menu"
                          :label="$t('general.lastName', $store.state.locale) "
                          class="mt-4"
                          hide-details
                          v-model="newClientData.surname"
                          :rules="newClientData.surnameRules"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex md12>
                        <v-text-field outlined class="mt-4" background-color="menu" :label="$t('general.email', $store.state.locale) " v-model="newClientData.email" :rules="newClientData.emailRules" hide-details required></v-text-field>
                      </v-flex>
                      <v-flex md12>
                        <v-select
                          :items="genders"
                          item-text="name"
                          item-value="id"
                          v-model="newClientData.gender"
                          :label="$t('clients.selectGender', $store.state.locale) "
                          outlined
                          class="mt-4"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="menu"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        ></v-select>
                      </v-flex>
                      <v-flex md12>
                        <v-checkbox class="pa-0 ma-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="font-size: 14px" :label="$t('clients.sendClientConfirmation', $store.state.locale)" v-model="confirmEmail" hide-details></v-checkbox>
                      </v-flex>
                      <v-flex md12>
                        <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!showSubscribeNewClientButton" :depressed="!showSubscribeNewClientButton" :dark="showSubscribeNewClientButton" block @click="createClient()"
                          >{{ $t('clients.createAndSubscribeClient', $store.state.locale)  }}</v-btn
                        >
                      </v-flex>

                      <v-flex md12 v-if="existingClientData && !showSubscribeNewClientButton">
                        <v-card outlined color="secondary">
                          <v-card-text class="ma-0 text-center">
                            <div v-if="!existingClientSubscribed">
                              <h3 class="white--text"><v-icon color="white">mdi-information</v-icon> <strong>{{ $t('clients.clientExist', $store.state.locale)  }}</strong></h3>
                              <v-btn class="mt-3" depressed block color="white" @click="subscribeClient()">
                              <span class="pr-2">
                                <strong>{{ existingClientData.name }} {{ existingClientData.surname }}</strong>
                              </span>
                              {{ $t('clients.subscribe', $store.state.locale)  }}</v-btn>
                            </div>
                            <div v-else>
                              <h3 class="white--text"><v-icon color="white">mdi-information</v-icon> <strong>{{ $t('clients.clientExistAndSubscribed', $store.state.locale)  }}</strong></h3>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </div>
            </v-card>

            <h3 class="pa-3 text-center"><strong>{{ $t('clients.or', $store.state.locale)  }}</strong></h3>

            <div class="pa-2 text-center" :style="{ background: $vuetify.theme.themes[theme].background }">
              <div class="client-info-input-wrapper">
                <h3><strong>{{ $t('clients.searchExistingClient', $store.state.locale)  }}</strong></h3>
                <p>{{ $t('clients.addExtraInfoToClient', $store.state.locale)  }}</p>

                <v-text-field class="searchbox" outlined dense v-model="search" name="searchfield" label="Zoeken" prepend-inner-icon="mdi-magnify" single-line hide-details background-color="menu"></v-text-field>

                <v-card outlined color="background" style="max-height: 170px; overflow-y: scroll" class="custom-algolia">
                  <ais-instant-search :search-client="searchClient" index-name="thrive_clients" class="pa-0 ma-0">
                    <v-list class="pa-0 ma-0 ">
                      <v-list-item-group
                        v-model="selectedClient.selectIndex"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      >
                        <ais-hits class="pa-0 ma-0">
                          <template slot="item" slot-scope="{ item }" >
                            <v-list-item 
                              class="pa-2 py-1 ma-0 align-left" 
                              @click="selectedClientForSubscription(item)"
                              :key="item.objectID"
                              >
                              <v-list-item-avatar color="#f1f1f1">
                                <v-icon>mdi-account</v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content class="text-left">
                                <v-list-item-title>
                                  <ais-highlight
                                    attribute="name"
                                    :hit="item"
                                    highlightedTagName="mark"
                                  />&nbsp;
                                  <ais-highlight
                                    attribute="surname"
                                    :hit="item"
                                    highlightedTagName="mark"
                                  />

                                  <v-chip class="ml-1" x-small  v-if="item.gender"> {{ getGender(item.gender) }} </v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <ais-highlight
                                    attribute="email"
                                    :hit="item"
                                    highlightedTagName="mark"
                                  />
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  <span v-if="item.phone"><strong>{{ item.phone }}</strong></span>
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-icon>mdi-chevron-right</v-icon>
                              </v-list-item-action>
                            </v-list-item>
                            
                          </template>
                        </ais-hits>
                      </v-list-item-group>
                    </v-list>
                    <ais-state-results>
                      <p 
                        class="text-center pa-2 pt-4 pb-1"
                        slot-scope="{ state: { query }, results: { hits } }"
                        v-show="!hits.length"
                      >
                        <i>{{ $t('clients.noResultsFoundFor', $store.state.locale) }} {{ query }}</i>
                      </p>
                    </ais-state-results>
                  <ais-configure
                    :query="search"
                    :facetFilters=" [`companies:${this.activeCompData.id}`]"
                  />
                  </ais-instant-search>
                </v-card>

                <v-checkbox :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('events.sendConfirmationToClient', $store.state.locale)" v-model="confirmEmail" hide-details class="pb-2"></v-checkbox>
              </div>
              <v-btn :disabled="!selectedClient.selected" :dark="selectedClient.selected" depressed block class="ma-1" style="width: 150px" @click="subscribeClient()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                <div v-if="selectedClient.selected">
                  <strong>{{ selectedClient.name }} {{ selectedClient.surname }}</strong> {{ $t('clients.subscribe', $store.state.locale)  }}
                </div>
                <div v-else>{{ $t('events.selectClient', $store.state.locale) }}</div>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- / Delete subscription dialog \ -->
      <v-dialog v-model="removeSubscription.dialog" persistent width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card class="dialog-round-wrapper">
          <v-card-text class="ma-0 pt-4">
            <div class="text-center">
              <v-avatar 
                size="76"
                light
                class="ma-4 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alert</v-icon>
              </v-avatar>
              <h2 class="pa-2"><strong>{{ $t('events.removeSubscription', $store.state.locale) }}</strong></h2>
              <p v-if="removeSubscription.item" style="display: block">{{ $t('events.removeSubscriptionOf', $store.state.locale) }}  {{ removeSubscription.item.name }} {{ this.removeSubscription.item.surname }}?</p>
              <v-checkbox :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('events.sendConfirmationToClient', $store.state.locale)" v-model="removeSubscription.mail" hide-details class="pb-2"></v-checkbox>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left">
                  <v-btn outlined depressed color="tertiary" @click="removeSubscription.dialog = false"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" @click="removeClientSubscription()">
                    {{ $t('general.unsubscribe', $store.state.locale) }}<v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- \ Feedback Dialog / -->
    

      <!-- / Feecback Dialog \ -->
      <v-dialog v-model="feedbackModel.dialog" persistent width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card class="dialog-round-wrapper">
          <v-card-text class="ma-0 pt-4">
            <div class="text-center">
              <v-avatar 
                size="76"
                light
                class="ma-4 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alert</v-icon>
              </v-avatar>
              <h2 class="pa-2"><strong> {{ feedbackModel.title }}</strong></h2>
              <p>{{ feedbackModel.description }}</p>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 class="text-center">
                  <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" @click="feedbackModel.dialog = false">
                    {{ $t('general.close', $store.state.locale) }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- \ Feedback Dialog / -->
    </v-card>
  </v-dialog>
</template>

<script>
  import { bus } from "../../main";
  import moment from "moment";
  import { extendMoment } from "moment-range";
  import slugify from "slugify";
  import axios from "axios";

  import lodash from "lodash";
  import db from "../../firebase/init";
  import { log } from "util";
  import algoliasearch from 'algoliasearch/lite';
  import 'instantsearch.css/themes/satellite-min.css';
  export default {
    props: ["selectRoom", "activeBranch", "activeCompData", "bookingen", "CalbookEventModal", "startTime", "endTime", "selectedResource", "localetime"],

    data() {
      return {
        loading: false,
        eventBookingen: new Array(),
        searchClient: algoliasearch(
          'B4XU2Z5BAW',
          'a29d0e9160e36dc8ee83d5888c28cdcc'
        ),
        feedbackModel:{
          dialog: false,
          title: null,
          description: null
        },
        
        eventSummaryId: null, //Samenvatting van Event
        registrations: [],
        indexingBookingsummary: null, //Hoeveel Bookingsummary's
        indexingBooking: [],
        selected_eventDeleteOption: "",
        eventDeleteOptions: [
          { name: this.$t('events.removeEventSelectedDay', this.$store.state.locale), id: 1 },
          { name: this.$t('events.removeSelectedOnly', this.$store.state.locale), id: 2 },
          { name: this.$t('events.removeAllEvents', this.$store.state.locale), id: 3 },
        ],
        everychoose: [1, 2, 3, 4],
        everychoose_selected: 1,
        feedback: null,
        registeredClient: null,
        tabTransition: false,
        bookingSummary: null,
        bookingSummaryOriginal: null,
        bookingSummaryId: null,
        selected_planRepeatOptions: "1",
        planRepeatOptions: [
          { name: this.$t('general.week', this.$store.state.locale), id: "1" },
          { name: this.$t('general.month', this.$store.state.locale), id: "2" },
        ],
        tab: 0,
        RangeRender: null, // Dynamische tijd Render
        employeesBranch: [],
        employeesBranch_override: [],
        menuStartDate: false,
        menuEndDate: false,
        repeatEvent: false,
        repeatEventTimes: null,

        roomsBranch: [],
        selected_rooms: [],
        selected_rooms_override: [],
        selected_employees: [],
        selected_employees_override: [], //Overboekbare employees
        selected_roomsDevices: [],
        selected_roomsDevices_override: [],
        chosenDate_begin: null,
        chosenDate_end: null,

        selected_rooms: [],
        devicesLoadedFiltered: [],
        devicesBranch: [],
        roomName: null,

        clientID: "",
        genders: [
          { name: this.$t('general.man', this.$store.state.locale), id: 1 },
          { name: this.$t('general.woman', this.$store.state.locale), id: 2 },
          { name: this.$t('general.other', this.$store.state.locale), id: 3 },
        ],
        datepickerdialog: false,
        bookingOverride: false,
        event: {
          title: "",
          notes: "",
          showInWidget: true, // Show event in widget?
          showSeats: false,
          showSelectedResources: false, //Alleen laten zien bij timeChanged
          selectedStart: "",
          selectedEnd: "",
          persons: 1,
          slots: null, //Aamtal ingeschreven personen,
          status: "",
          eventDate: null,
          treatmentType: null, //Gekozen Treatmenttype
          eventType: "treatment",
          minTimeLimitSubscription: 0,
          maxTimeLimitSubscription: 0,
        },
        eventOriginal: null,

        disableTimechange: false,
        eventID: null,
        buttonNewEvent: false,
        existingEvent: false,
        timeChanged: false, //Als de tijd is aangepast.

        timeCheckup: false,
        subscriptionTimeLimits: [
          {
            name: this.$t('general.none', this.$store.state.locale),
            id: 0,
          },
          {
            name: this.$t('events.oneHours', this.$store.state.locale),
            id: 1,
          },
          {
            name: this.$t('events.twoHours', this.$store.state.locale),
            id: 2,
          },
          {
            name: this.$t('events.threeHours', this.$store.state.locale),
            id: 3,
          },
        ],

        //Inschrijvingen
        addClientSubscriptionModal: false,

        selected: [],

        registrationsList: [],
        registrationsListOriginal: [],
        registrationPost: [],
        removeClientCounter: 0,

        // Client Modal
        newclient: false,
        clients: [],
        clientsLoading: false,
        clientsLoaded: false,
        selectedClient: {
          selected: false,
        },
        confirmEmail: true, //Client wilt bevestinging ontvangen

        newClientData: {
          valid: false, //Zijn persoongevens goed ingevuld?
          gender: 0,
          name: null,
          nameRules: [(v) => !!v || this.$t('general.required', this.$store.state.locale), (v) => (v && v.length > 1) || this.$t('general.required', this.$store.state.locale)],
          surname: null,
          surnameRules: [(v) => !!v || this.$t('general.required', this.$store.state.locale), (v) => (v && v.length > 1) || this.$t('general.required', this.$store.state.locale)],
          email: "",
          emailRules: [(v) => !!v || this.$t('general.required', this.$store.state.locale), (v) => (v && v.length > 3) || "E-mail is verplicht", (v) => /.+@.+\..+/.test(v) || this.$t('general.required', this.$store.state.locale)],
        },
        validatedEmail: null,
        clientExistsInDatabase: false,
        clientExistsInCompany: false,
        existingClientData: null,
        existingClientSubscribed: false,

        removeSubscription: {
          index: null,
          item: null,
          loading: false,
          dialog: false,
          mail: true,
        },

        search: "",
        pagination: {
          // sortBy: "name",
          rowsPerPage: 10,
          descending: false,
        },
        headers: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            sortable: false,
            value: "name",
          },

          {
            text: this.$t('general.email', this.$store.state.locale),
            align: "left",
            value: "email",
          },

          {
            text: this.$t('general.phone', this.$store.state.locale),
            align: "left",
            value: "phone",
          },
          {
            text: "",
            align: "right",
            value: "unsubscribe",
            width: "30px",
          },
        ],
        eventTypes: [
          {
            name: this.$t('general.treatment', this.$store.state.locale),
            id: "treatment",
          },
          {
            name: this.$t('events.specialEvent', this.$store.state.locale),
            id: "specialevent",
          },
        ],
        treatmentTypes: [],
      };
    },

    mounted() {
      bus.$on("bookEvent", (sender, date) => {
        if (sender === "employee") {
          this.settingsEvent(this.startTime, this.endTime);
          this.selectedResource != null && this.selected_employees.push(parseInt(this.selectedResource.id));
        } else if (sender === "device") {
          this.settingsEvent(this.startTime, this.endTime);
        } else if (sender === "room") {
          this.settingsEvent(this.startTime, this.endTime);
          this.selectedResource != null && this.selected_rooms.push(parseInt(this.selectedResource.id));
        } else if (sender === "topMenu") {
          this.settingsEvent(this.event.selectedStart, this.event.selectedEnd);
        }
        this.chosenDate_begin = this.localetime;
      });

      bus.$on("editEvent", (eventSummaryId, bookingid, passEvent, eventDate) => {
        this.editExistingEvent(eventSummaryId, bookingid, passEvent, eventDate);
      });
    },

    methods: {
      //Alleen voor nieuwe events
      loadTreatmentTypes() {
        db.collection("treatmentTypes")
          .get()
          .then((snap) => {
            this.event.treatmentTypes = [];
            snap.forEach((doc) => {
              let treatmentType = doc.data();
              treatmentType.id = doc.id;
              this.treatmentTypes.push(treatmentType);
            });
          });
      },

      settingsEvent(start, end) {
        // console.log("DATUMS: ", start, end);
        this.loading = true;
        this.loadTreatmentTypes();
        this.event.showSelectedResources = true;
        this.event.notes = "";
        this.event.title = "";
        this.event.persons = 1;
        this.event.slots = null;
        this.event.bookingSummary = [];
        this.event.selectedStart = moment(start).format("HH:mm");
        this.event.selectedEnd = moment(end).format("HH:mm");
        this.event.bookingSummaryId = null;
        this.existingEvent = false;
        this.eventBookingen = this.bookingen.filter((booking) => booking.extendedProps.type == "eventbooking");
        this.$emit("CalbookEventModalChange", true);

        this.devicesBranch = [];
        this.employeesBranch = [];
        this.roomsBranch = [];

        db.collection("resources")
          .where("branches", "array-contains", this.activeBranch)
          .where("active", "==", true)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if (doc.data().resourceType == "device") {
                this.devicesBranch.push(doc.data());
              } else if (doc.data().resourceType == "employee") {
                this.employeesBranch.push(doc.data());
              } else if (doc.data().resourceType == "room") {
                this.roomsBranch.push(doc.data());
              }
            });
          })
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error download resources document: ", error);
          });
      },

      editExistingEvent(eventSummaryId, bookingid, passEvent, eventDate) {
        this.loading = true;
        this.eventSummaryId = eventSummaryId;
        this.loading = true;
        this.devicesBranch = [];
        this.employeesBranch = [];
        this.roomsBranch = [];
        this.bookingSummaryId = bookingid;
        this.eventID = passEvent; //Gebruikt om selectie te verwijderen
        this.event.eventDate = eventDate;
        this.eventBookingen = this.bookingen.filter((booking) => booking.extendedProps.type == "eventbooking");

        this.existingEvent = true;
        this.$emit("CalbookEventModalChange", true);
        this.bookingSummary = [];
        this.selected_rooms = [];
        this.selected_rooms_override = [];
        this.selected_employees = [];
        this.selected_employees_override = [];
        this.loadTreatmentTypes();


        db.collection("resources")
          .where("branches", "array-contains", this.activeBranch)
          .where("active", "==", true)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              if (doc.data().resourceType == "device") {
                this.devicesBranch.push(doc.data());
              } else if (doc.data().resourceType == "employee") {
                this.employeesBranch.push(doc.data());
              } else if (doc.data().resourceType == "room") {
                this.roomsBranch.push(doc.data());
              }
            });
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error download resources document: ", error);
          });

        db.collection("bookingsummary")
          .doc(bookingid)
          .get()
          .then((doc) => {
            this.bookingSummary = {
              bookingSummaryId: doc.id,
              eventSummaryId: doc.data().eventSummaryId,
              company: doc.data().company,
              companyId: doc.data().companyId,
              created: doc.data().created,
              createdby: doc.data().createdby,
              start: doc.data().start,
              end: doc.data().end,
              title: doc.data().title,
              description: doc.data().description,
              branch: doc.data().branch,
              type: doc.data().type,
              maxPersons: doc.data().maxPersons, //Maximaal aantal personen
              minTimeLimitSubscription: this.event.minTimeLimitSubscription,
              maxTimeLimitSubscription: this.event.maxTimeLimitSubscription,
              registrations: doc.data().registrations,
              showSeats: doc.data().showSeats,
              active: doc.data().active
            };
            this.bookingSummaryOriginal = _.cloneDeep(this.bookingSummary);
            this.event.showSeats = doc.data().showSeats;
            this.event.showInWidget = doc.data().active;
          })
          .then(() => {
            this.registeredClient = _.cloneDeep(this.bookingSummary.registrations); //Booking Registrations
            this.indexingBookingsummary = []; // Hoeveel summary's?

            this.event.persons = this.bookingSummary.maxPersons;
            this.slots = this.bookingSummary.notes;
            this.event.selectedStart = moment(this.bookingSummary.start).format("HH:mm");
            this.event.selectedEnd = moment(this.bookingSummary.end).format("HH:mm");

            //Binnen halen van alle bookingsummary voor verwijdering.
            console.log("this.eventSummaryId: ", this.eventSummaryId)
            db.collection("bookingsummary")
              .where("eventSummaryId", "==", this.eventSummaryId)
              .get()
              .then((snapshot) => {
                snapshot.forEach((doc) => {
                  this.indexingBookingsummary.push({
                    docIDbookingsummary: doc.id,
                    start: doc.data().start,
                  });
                });
              })
              .then(() => {
                console.log("this.bookingSummary.registrations: ", this.bookingSummary.registrations)
                this.registrationsList = [];
                for (let index = 0; index < this.bookingSummary.registrations.length; index++) {
                  console.log("this.bookingSummary.registrations[index]", this.bookingSummary.registrations[index])
                
                    db.collection("clients")
                    .doc(this.bookingSummary.registrations[index])
                    .get()
                    .then((doc) => {
                      if (doc.exists) {
                        let userData = doc.data();
                        userData.index = index;
                        this.registrationsList.push(userData);
                      } else {
                        let userData = {
                          name: "Onbekende inschrijving",
                          surname: null,
                          phone: "-",
                          email: "-",
                          index: index,
                          clientId: this.bookingSummary.registrations[index],
                          type: "unknown",
                        };
                        this.registrationsList.push(userData);
                      }
                    })
                    .then(() => {
                      this.registrationsListOriginal = _.cloneDeep(this.registrationsList);
                    })
                    .catch((error) => {
                      // The document probably doesn't exist.
                      console.error("Error indexingBookingsummary: ", error);
                      let userData = {
                          name: "Onbekende inschrijving",
                          surname: null,
                          phone: "-",
                          email: "-",
                          index: index,
                          clientId: this.bookingSummary.registrations[index],
                          type: "unknown",
                        };
                        this.registrationsList.push(userData);
                    });
           
                  
                }
              })
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error indexingBookingsummary: ", error);
              });

            db.collection("events")
              .doc(this.eventSummaryId)
              .get()
              .then((doc) => {
                this.event.title = doc.data().title;
                this.event.notes = doc.data().description;
                this.event.eventType = doc.data().eventType;
                this.event.treatmentType = doc.data().treatmentType;
              })
              .then(() => {
                this.eventOriginal = _.cloneDeep(this.event);
                this.loading = false;
              })
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error events: ", error);
              });
          })

          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      },
      removeClientSubscriptionDialog(item, index) {
        this.removeSubscription.item = item;
        this.removeSubscription.index = index;
        this.removeSubscription.dialog = true;
        this.removeSubscription.mail = true;
      },

      async removeClientSubscription() {
        this.removeSubscription.loading = true;
        let clientIndex = _.indexOf(this.registeredClient, this.removeSubscription.item.clientId);
        this.registeredClient.splice(clientIndex, 1);
        this.registrationsList.splice(this.removeSubscription.index, 1);

        await db
          .collection("bookingsummary")
          .doc(this.bookingSummaryId)
          .set(
            {
              registrations: this.registeredClient,
            },
            { merge: true }
          )
          .then(() => {
            this.$emit("triggerRefetch");
            if (this.removeSubscription.mail) {
              axios
                //.get(`${this.$store.state.messagesApi.url}/cancelEvent?doc=${this.bookingSummaryId}&activebranch=${this.activeBranch}&clientId=${this.removeSubscription.item.clientId}&lang=nl`)
                .post(`${this.$store.state.messagesApi.url}/sendMessage`, {doc: this.bookingSummaryId, branchId: this.activeBranch, type: "eventCancel", clientId: this.removeSubscription.item.clientId })
                .then((response) => {
                  if (response) {
                    //Email succesfully sent!
                  }
                })
                .catch((error) => {
                  console.error(error.message);
                });
            }
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
        this.removeSubscription = {
          index: null,
          item: null,
          loading: false,
          dialog: false,
          mail: true,
        };
        return;
      },

      openNewSubscriptionModal() {
        this.addClientSubscriptionModal = true;
      },


      selectedClientForSubscription(client) {
        console.log("selectedClientForSubscription: ", client)
        this.selectedClient = {};
        this.selectedClient.selected = true;
        this.selectedClient.clientId = client.objectID;
        this.selectedClient.name = client.name;
        this.selectedClient.surname = client.surname ? client.surname : null;
        this.selectedClient.phone = client.phone ? client.phone : null;
        this.selectedClient.email = client.email ? client.email : null;
        this.selectedClient.index = this.registeredClient.length;
      },

      subscribeClient() {
        console.log("subscribeClient");
        this.registeredClient.push(this.selectedClient.clientId);
        this.registrationsList.push(this.selectedClient);
        this.addClientSubscriptionModal = false;
        this.selectedClient.selected = false;

        this.clientExistsInDatabase = false;
        this.clientExistsInCompany = false;
        this.existingClientData = null;
        this.existingClientSubscribed = false;
        this.newClientData.gender = 2;
        (this.newClientData.name = null), (this.newClientData.surname = null), (this.newClientData.email = null), (this.newclient = false);

        db.collection("bookingsummary")
          .doc(this.bookingSummaryId)
          .update({
            registrations: this.registeredClient,
          })
          .then(() => {
            if (this.confirmEmail) {
              axios
                // .get(`${this.$store.state.messagesApi.url}/confirmEvent?doc=${this.bookingSummaryId}&activebranch=${this.activeBranch}&clientId=${this.selectedClient.clientId}&lang=nl`)
                .post(`${this.$store.state.messagesApi.url}/sendMessage?doc=${this.bookingSummaryId}&branchId=${this.activeBranch}&type=eventConfirm&clientId=${this.selectedClient.clientId}`)
                .then((response) => {
                  if (response) {
                    //Email succesfully sent!
                  }
                })
                .catch((error) => {
                  console.error(error.message);
                });
            }
            // Refesh Calendar
            this.$emit("triggerRefetch");
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      },

      async createClient() {
        console.log("Client Check");
        const configAxiosPOST = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        let jsonOBJ = {
          companyId: this.activeCompData.id,
          clientEmail: this.newClientData.email,
          clientFirstName: this.newClientData.name,
          clientLastName: this.newClientData.surname,
          clientPhone: " ",
        };

        console.log("jsonOBJ: ", jsonOBJ);

        await new Promise((resolve) => {
          axios
            .post(`${this.$store.state.restApi.url}/postClientExist`, jsonOBJ, configAxiosPOST)
            .then((response) => {
              console.log(response.data);
              // Client does not exist
              if (response.data.typeExist == 2) {
                // Create Client
                let companies = [this.activeCompData.id];
                db.collection("clients")
                  .add({
                    name: this.newClientData.name,
                    surname: this.newClientData.surname,
                    email: this.newClientData.email,
                    companies: companies,
                    gender: this.newClientData.gender,
                    emailSubscriptions: {
                      newsletter: false,
                    },
                    health: {
                      macronutrients: {},
                    },
                    address: {},
                    permissions: {},
                  })
                  .then((docRef) => {
                    this.newClientData.clientId = docRef.id;
                    console.log("Client check finished");
                    resolve();
                  });
              }

              // Client bestaat al, maar nog niet bij dit bedrijf
              else if (response.data.typeExist == 1 && response.data.companySet == false) {
                // Set company in client doc
                let companies = null;
                db.collection("clients")
                  .doc(response.data.clientId)
                  .get()
                  .then((doc) => {
                    companies = doc.data().companies;
                    companies.push(this.activeCompData.id);
                  })
                  .then(() => {
                    db.collection("clients").doc(response.data.clientId).set(
                      {
                        companies: companies,
                      },
                      { merge: true }
                    );
                  })
                  .then(() => {
                    this.newClientData.clientId = response.data.clientId;
                    console.log("Client check finished");
                    resolve();
                  });
              }

              // Client bestaat al, ook bij dit bedrijf
              else if (response.data.typeExist == 0 && response.data.companySet == true) {
                // Set client Id
                this.newClientData.clientId = response.data.clientId;
                console.log("Client check finished");
                resolve();
              }
            })
            .catch((err) => {
              console.error("Error axios postClientExist =>", err);
              resolve();
            });
        }).then(() => {
          // Cliënt inschrijven
          this.clients.push(this.newClientData);
          this.selectedClient = _.cloneDeep(this.newClientData);
          console.log("this.selectedClient: ", this.selectedClient);
          console.log("this.newClientData: ", this.newClientData);
          this.subscribeClient();
        });
      },

      bookEvent() {
        let titleORdescription = {
          check: false,
          text: "",
        };

        let maxPersonValidate = { check: false, text: "" };

        let checkResouces = {
          check: false,
          text: "",
        };
        let checkStartDate = {
          check: false,
          text: "",
        };
        let checkTimeEvent = {
          check: false,
          text: "",
        };
        let timeCheckup = {
          check: false,
          text: "",
        };

        let checkEventType = {
          check: false,
          text: "",
        };

        if (this.event.title == "" || this.note == "") {
          titleORdescription.check = false;
          titleORdescription.text = this.$t('events.requiredTitle', this.$store.state.locale);
        } else {
          titleORdescription.check = true;
          titleORdescription.text = "";
        }

        if (this.event.persons == 0) {
          maxPersonValidate.check = false;
          maxPersonValidate.text = this.$t('events.requiredPerson', this.$store.state.locale);
        } else {
          maxPersonValidate.check = true;
          maxPersonValidate.text = "";
        }

        if (this.event.eventType == "treatment" && this.event.treatmentType == null) {
          checkEventType.check = false;
          checkEventType.text = this.$t('events.requiredTreatmentType', this.$store.state.locale);
        } else {
          checkEventType.check = true;
          checkEventType.text = "";
        }

        if ((this.selected_employees.length !== 0 || this.selected_employees_override.length !== 0) && (this.selected_rooms.length !== 0 || this.selected_rooms_override.length !== 0)) {
          checkResouces.check = true;
          checkResouces.text = "";
        } else {
          checkResouces.check = false;
          checkResouces.text = this.$t('events.requiredEmployee', this.$store.state.locale);
        }

        if (this.chosenDate_begin == null) {
          checkStartDate.check = false;
          checkStartDate.text = this.$t('events.requiredDate', this.$store.state.locale);
        } else {
          checkStartDate.check = true;
          checkStartDate.text = "";
        }

        if (this.event.selectedStart == null || this.event.selectedEnd == null) {
          checkTimeEvent.check = false;
          checkTimeEvent.text = this.$t('events.requiredTime', this.$store.state.locale);
        } else {
          checkTimeEvent.check = true;
          checkTimeEvent.text = "";
        }

        if (moment("2020-10-10T" + this.event.selectedStart).unix() > moment("2020-10-10T" + this.event.selectedEnd).unix()) {
          timeCheckup.check = false;
          timeCheckup.text = this.$t('events.invalidTime', this.$store.state.locale);
        } else {
          timeCheckup.check = true;
          timeCheckup.text = "";
        }

        if (
          [titleORdescription.check, checkResouces.check, checkEventType.check, checkStartDate.check, checkTimeEvent.check, timeCheckup.check].every((element) => {
            return element;
          }) == false
        ) {
          this.feedbackModel.title = this.$t('events.required', this.$store.state.locale);
          this.feedbackModel.description = `${titleORdescription.text} ${checkResouces.text} ${checkEventType.text} ${checkStartDate.text} ${checkTimeEvent.text} ${timeCheckup.text}`;
          this.feedbackModel.dialog = true;
        }
        else {
          let timestamp = moment().format("YYYY-MM-DDTHH:mm");
          let bookingcolor = null;

          if (this.repeatEvent == true && this.chosenDate_end == null) {
            this.feedbackModel.title = this.$t('events.required', this.$store.state.locale);
            this.feedbackModel.description = this.$t('events.repeatEventHint', this.$store.state.locale);
            this.feedbackModel.dialog = true;
          }
          else {
            if (this.repeatEvent == true) {
              let checkEndDate = {
                check: false,
                text: "",
              };

              let startdate = moment(this.chosenDate_begin);
              let enddate = moment(this.chosenDate_end);
              let period = null;

              if (this.selected_planRepeatOptions == 1) {
                // 1 = weeks true and else is months false
                period = "weeks";
              } else {
                period = "months";
              }

              const rangeID = moment.range(startdate, enddate);
              this.RangeRender = Array.from(
                rangeID.by(period, {
                  step: this.everychoose_selected,
                  excludeEnd: false,
                })
              );
              this.RangeRender.map((m) => m.format("YYYY-MM-DD")); // ['00:00', '01:00', '02:00', '03:00', '04:00']

              this.loading = true;

              db.collection("events")
                .add({
                  company: this.activeCompData.name,
                  companyId: this.activeCompData.id,
                  title: this.event.title,
                  description: this.event.notes,
                  branch: this.activeBranch,
                  eventType: this.event.eventType,
                  treatmentType: this.event.treatmentType,
                })
                .then((docRef) => {
                  let AddeventSummaryId = docRef.id;
                  for (let index = 0; index < this.RangeRender.length; index++) {
                    db.collection("bookingsummary")
                      .add({
                        eventSummaryId: docRef.id,
                        active: this.event.showInWidget,
                        company: this.activeCompData.name,
                        companyId: this.activeCompData.id,
                        created: timestamp,
                        minTimeLimitSubscription: this.event.minTimeLimitSubscription,
                        maxTimeLimitSubscription: this.event.maxTimeLimitSubscription,
                        showSeats: this.event.showSeats,
                        createdby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")",
                        start: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedStart,
                        end: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedEnd,
                        title: this.event.title,
                        description: this.event.notes,
                        branch: this.activeBranch,
                        type: "eventbooking",
                        maxPersons: this.event.persons, //Maximaal aantal personen
                        registrations: this.registrations,
                      })

                      .then((docRef) => {
                        //selected_employees
                        for (let indexEmployee = 0; indexEmployee < this.selected_employees.length; indexEmployee++) {
                          db.collection("bookingen")
                            .add({
                              companyId: this.activeCompData.id,
                              eventSummaryId: AddeventSummaryId,
                              bookingId: docRef.id,
                              resourceId: Number(this.selected_employees[indexEmployee]),
                              start: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedStart,
                              end: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedEnd,
                              dateBooking: moment(this.RangeRender[index]).format("YYYY-MM-DD"),
                              branch: this.activeBranch,
                              maxPersons: this.event.persons,
                              title: this.event.title,
                              description: this.event.notes,
                              type: "eventbooking",
                              eventColor: "rgb(114, 217, 196)",
                              bookingOverride: false,
                            })
                            
                            .catch((error) => {
                              console.error("Error created document selected_employees ", error);
                            });
                        }
                        for (let indexEmployee = 0; indexEmployee < this.selected_employees_override.length; indexEmployee++) {
                          db.collection("bookingen")
                            .add({
                              companyId: this.activeCompData.id,
                              eventSummaryId: AddeventSummaryId,
                              bookingId: docRef.id,
                              resourceId: Number(this.selected_employees_override[indexEmployee]),
                              start: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedStart,
                              end: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedEnd,
                              dateBooking: moment(this.RangeRender[index]).format("YYYY-MM-DD"),
                              branch: this.activeBranch,
                              maxPersons: this.event.persons,
                              title: this.event.title,
                              description: this.event.notes,
                              type: "eventbooking",
                              eventColor: "rgba(114, 217, 196, 0.50)",
                              bookingOverride: true,
                            })
                            .catch((error) => {
                              console.error("Error created document selected_employees_override ", error);
                            });
                        }

                        // Check welke kamer bij welke machine hoort
                        //Post ruimtes (niet overboekbaar)
                        this.selected_roomsDevices = [];
                        for (let indexRooms = 0; indexRooms < this.selected_rooms.length; indexRooms++) {
                          let foundedroomId = null;
                          let DeviceRoomMatch = null;
                          let deviceId = null;

                          //Welk Id hoort bij welke ruimte?
                          this.roomsBranch.forEach((o) => {
                            if (Number(this.selected_rooms[indexRooms]) == o.resourceId) {
                              foundedroomId = o.roomId;
                            }
                          });
                          //Welk apparaat hoort bij welke kamer?
                          this.devicesBranch.forEach((o) => {
                            if (foundedroomId == o.roomId) {
                              this.selected_roomsDevices.push({
                                resourceId: o.resourceId,
                                deviceId: o.deviceId,
                                roomId: o.roomId,
                              });
                            }
                          });
                          //Post Ruimte (niet overboekbaar)

                          db.collection("bookingen")
                            .add({
                              companyId: this.activeCompData.id,
                              eventSummaryId: AddeventSummaryId,
                              bookingId: docRef.id,
                              resourceId: Number(this.selected_rooms[indexRooms]),
                              start: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedStart,
                              end: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedEnd,
                              dateBooking: moment(this.RangeRender[index]).format("YYYY-MM-DD"),
                              branch: this.activeBranch,
                              maxPersons: this.event.persons,
                              title: this.event.title,
                              description: this.event.notes,
                              type: "eventbooking",
                              eventColor: "rgb(114, 217, 196)",
                              bookingOverride: false,
                              roomId: foundedroomId,
                            })
                            .then(()=>{
                              console.log("Room Booking Added")
                            })
                            .catch((error) => {
                              console.error("Error created document selected_rooms ", error);
                            });
                        }

                        //Post ruimtes (overboekbaar)
                        this.selected_roomsDevices_override = [];
                        for (let indexRooms = 0; indexRooms < this.selected_rooms_override.length; indexRooms++) {
                          let foundedroomId = null;
                          let DeviceRoomMatch = null;
                          let deviceId = null;

                          //Welk Id hoort bij welke ruimte?
                          this.roomsBranch.forEach((o) => {
                            if (Number(this.selected_rooms_override[indexRooms]) == o.resourceId) {
                              foundedroomId = o.roomId;
                            }
                          });
                          //Welk apparaat hoort bij welke kamer?
                          this.devicesBranch.forEach((o) => {
                            if (foundedroomId == o.roomId) {
                              this.selected_roomsDevices_override.push({
                                resourceId: o.resourceId,
                                deviceId: o.deviceId,
                                roomId: o.roomId,
                              });
                            }
                          });
                          //Post Ruimte (overboekbaar)

                          db.collection("bookingen")
                            .add({
                              companyId: this.activeCompData.id,
                              eventSummaryId: AddeventSummaryId,
                              bookingId: docRef.id,
                              resourceId: Number(this.selected_rooms_override[indexRooms]),
                              start: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedStart,
                              end: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedEnd,
                              dateBooking: moment(this.RangeRender[index]).format("YYYY-MM-DD"),
                              branch: this.activeBranch,
                              title: this.event.title,
                              maxPersons: this.event.persons,
                              description: this.event.notes,
                              type: "eventbooking",
                              eventColor: "rgba(114, 217, 196, 0.50)",
                              bookingOverride: true,
                              roomId: foundedroomId,
                            })
                            .catch((error) => {
                              console.error("Error created document selected_rooms_override ", error);
                            });
                        }

                        //post Apparaat (niet overboekbaar)
                        for (let indexDevices = 0; indexDevices < this.selected_roomsDevices.length; indexDevices++) {
                          db.collection("bookingen")
                            .add({
                              companyId: this.activeCompData.id,
                              eventSummaryId: AddeventSummaryId,
                              bookingId: docRef.id,
                              resourceId: this.selected_roomsDevices[indexDevices].resourceId,
                              start: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedStart,
                              end: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedEnd,
                              dateBooking: moment(this.RangeRender[index]).format("YYYY-MM-DD"),
                              branch: this.activeBranch,
                              title: this.event.title,
                              description: this.event.notes,
                              type: "eventbooking",
                              eventColor: "rgb(114, 217, 196)",
                              maxPersons: this.event.persons,
                              bookingOverride: false,
                              roomId: this.selected_roomsDevices[indexDevices].roomId,
                              resourceType: "device", //Nodig voor Booker, ruimte valt onder machine
                              active: true, //Nodig voor Booker, ruimte valt onder machine
                              deviceId: this.selected_roomsDevices[indexDevices].deviceId, //Nodig voor Booker,
                            })
                            .catch((error) => {
                              console.error("Error created document selected_roomsDevices ", error);
                            });
                        }
                        //post Apparaat (overboekbaar)
                        for (let indexDevices = 0; indexDevices < this.selected_roomsDevices_override.length; indexDevices++) {
                          db.collection("bookingen")
                            .add({
                              companyId: this.activeCompData.id,
                              eventSummaryId: AddeventSummaryId,
                              bookingId: docRef.id,
                              resourceId: this.selected_roomsDevices_override[indexDevices].resourceId,
                              start: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedStart,
                              end: moment(this.RangeRender[index]).format("YYYY-MM-DD") + "T" + this.event.selectedEnd,
                              dateBooking: moment(this.RangeRender[index]).format("YYYY-MM-DD"),
                              branch: this.activeBranch,
                              title: this.event.title,
                              description: this.event.notes,
                              type: "eventbooking",
                              eventColor: "rgba(114, 217, 196, 0.50)",
                              maxPersons: this.event.persons,
                              bookingOverride: true,
                              roomId: this.selected_roomsDevices_override[indexDevices].roomId,
                              resourceType: "device", //Nodig voor Booker, ruimte valt onder machine
                              active: true, //Nodig voor Booker, ruimte valt onder machine
                              deviceId: this.selected_roomsDevices_override[indexDevices].deviceId, //Nodig voor Booker,
                            })
                            .catch((error) => {
                              console.error("Error created document selected_roomsDevices_override ", error);
                            });
                        }
                      })

                      .catch((error) => {
                        console.error("Error created document bookingsummary ", error);
                      });

                    this.loading = false;
                    this.$emit("CalbookEventModalChange", false);
                  }
                })
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error add Event summary: ", error);
                });
            } else {
              //Enkele event

              let AddeventSummaryId = null;
              db.collection("events")
                .add({
                  company: this.activeCompData.name,
                  companyId: this.activeCompData.id,
                  title: this.event.title,
                  description: this.event.notes,
                  branch: this.activeBranch,
                  // showSeats: this.event.showSeats,
                  // lastEvent: this.chosenDate_begin + "T" + this.event.selectedStart,
                  eventType: this.event.eventType,
                  treatmentType: this.event.treatmentType,
                })
                .then((docRef) => {
                  AddeventSummaryId = docRef.id;
                  db.collection("bookingsummary")
                    .add({
                      eventSummaryId: docRef.id,
                      active: this.event.showInWidge,
                      company: this.activeCompData.name,
                      companyId: this.activeCompData.id,
                      created: timestamp,
                      minTimeLimitSubscription: this.event.minTimeLimitSubscription,
                      maxTimeLimitSubscription: this.event.maxTimeLimitSubscription,
                      showSeats: this.event.showSeats,
                      createdby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")",
                      start: this.chosenDate_begin + "T" + this.event.selectedStart,
                      end: this.chosenDate_begin + "T" + this.event.selectedEnd,
                      title: this.event.title,
                      description: this.event.notes,
                      branch: this.activeBranch,
                      type: "eventbooking",
                      maxPersons: this.event.persons, //Maximaal aantal personen
                      registrations: this.registrations,
                    })

                    .then((docRef) => {
                      //selected_employees
                      for (let indexEmployee = 0; indexEmployee < this.selected_employees.length; indexEmployee++) {
                        db.collection("bookingen")
                          .add({
                            eventSummaryId: AddeventSummaryId,
                            bookingId: docRef.id,
                            resourceId: Number(this.selected_employees[indexEmployee]),
                            start: this.chosenDate_begin + "T" + this.event.selectedStart,
                            end: this.chosenDate_begin + "T" + this.event.selectedEnd,
                            dateBooking: this.chosenDate_begin,
                            branch: this.activeBranch,
                            maxPersons: this.event.persons,
                            title: this.event.title,
                            description: this.event.notes,
                            type: "eventbooking",
                            eventColor: "rgb(114, 217, 196)",
                            bookingOverride: false,
                          })
                          .catch((error) => {
                            console.error("Error created document selected_employees ", error);
                          });
                      }
                      for (let indexEmployee = 0; indexEmployee < this.selected_employees_override.length; indexEmployee++) {
                        db.collection("bookingen")
                          .add({
                            eventSummaryId: AddeventSummaryId,
                            bookingId: docRef.id,
                            resourceId: Number(this.selected_employees_override[indexEmployee]),
                            start: this.chosenDate_begin + "T" + this.event.selectedStart,
                            end: this.chosenDate_begin + "T" + this.event.selectedEnd,
                            dateBooking: this.chosenDate_begin,
                            branch: this.activeBranch,
                            maxPersons: this.event.persons,
                            title: this.event.title,
                            description: this.event.notes,
                            type: "eventbooking",
                            eventColor: "rgba(114, 217, 196, 0.50)",
                            bookingOverride: true,
                          })
                          .catch((error) => {
                            console.error("Error created document selected_employees_override ", error);
                          });
                      }

                      // Check welke kamer bij welke machine hoort
                      //Post ruimtes (niet overboekbaar)
                      this.selected_roomsDevices = [];
                      for (let indexRooms = 0; indexRooms < this.selected_rooms.length; indexRooms++) {
                        let foundedroomId = null;
                        let DeviceRoomMatch = null;
                        let deviceId = null;

                        //Welk Id hoort bij welke ruimte?
                        this.roomsBranch.forEach((o) => {
                          if (Number(this.selected_rooms[indexRooms]) == o.resourceId) {
                            foundedroomId = o.roomId;
                          }
                        });
                        //Welk apparaat hoort bij welke kamer?
                        this.devicesBranch.forEach((o) => {
                          if (foundedroomId == o.roomId) {
                            this.selected_roomsDevices.push({
                              resourceId: o.resourceId,
                              deviceId: o.deviceId,
                              roomId: o.roomId,
                            });
                          }
                        });
                        //Post Ruimte (niet overboekbaar)

                        db.collection("bookingen")
                          .add({
                            eventSummaryId: AddeventSummaryId,
                            bookingId: docRef.id,
                            resourceId: Number(this.selected_rooms[indexRooms]),
                            start: this.chosenDate_begin + "T" + this.event.selectedStart,
                            end: this.chosenDate_begin + "T" + this.event.selectedEnd,
                            dateBooking: this.chosenDate_begin,
                            branch: this.activeBranch,
                            title: this.event.title,
                            maxPersons: this.event.persons,
                            description: this.event.notes,
                            type: "eventbooking",
                            eventColor: "rgb(114, 217, 196)",
                            bookingOverride: false,
                            roomId: foundedroomId,
                          })
                          .catch((error) => {
                            console.error("Error created document selected_rooms ", error);
                          });
                      }

                      //Post ruimtes (overboekbaar)
                      this.selected_roomsDevices_override = [];
                      for (let indexRooms = 0; indexRooms < this.selected_rooms_override.length; indexRooms++) {
                        let foundedroomId = null;
                        let DeviceRoomMatch = null;
                        let deviceId = null;

                        //Welk Id hoort bij welke ruimte?
                        this.roomsBranch.forEach((o) => {
                          if (Number(this.selected_rooms_override[indexRooms]) == o.resourceId) {
                            foundedroomId = o.roomId;
                          }
                        });
                        //Welk apparaat hoort bij welke kamer?
                        this.devicesBranch.forEach((o) => {
                          if (foundedroomId == o.roomId) {
                            this.selected_roomsDevices_override.push({
                              resourceId: o.resourceId,
                              deviceId: o.deviceId,
                              roomId: o.roomId,
                            });
                          }
                        });
                        //Post Ruimte (overboekbaar)

                        db.collection("bookingen")
                          .add({
                            eventSummaryId: AddeventSummaryId,
                            bookingId: docRef.id,
                            resourceId: Number(this.selected_rooms_override[indexRooms]),
                            start: this.chosenDate_begin + "T" + this.event.selectedStart,
                            end: this.chosenDate_begin + "T" + this.event.selectedEnd,
                            dateBooking: this.chosenDate_begin,
                            branch: this.activeBranch,
                            title: this.event.title,
                            maxPersons: this.event.persons,
                            description: this.event.notes,
                            type: "eventbooking",
                            eventColor: "rgba(114, 217, 196, 0.50)",
                            bookingOverride: true,
                            roomId: foundedroomId,
                          })
                          .catch((error) => {
                            console.error("Error created document selected_rooms_override ", error);
                          });
                      }

                      //post Apparaat (niet overboekbaar)
                      for (let indexDevices = 0; indexDevices < this.selected_roomsDevices.length; indexDevices++) {
                        db.collection("bookingen")
                          .add({
                            eventSummaryId: AddeventSummaryId,
                            bookingId: docRef.id,
                            resourceId: this.selected_roomsDevices[indexDevices].resourceId,
                            start: this.chosenDate_begin + "T" + this.event.selectedStart,
                            end: this.chosenDate_begin + "T" + this.event.selectedEnd,
                            dateBooking: this.chosenDate_begin,
                            branch: this.activeBranch,
                            title: this.event.title,
                            description: this.event.notes,
                            type: "eventbooking",
                            eventColor: "rgb(114, 217, 196)",
                            maxPersons: this.event.persons,
                            bookingOverride: false,
                            roomId: this.selected_roomsDevices[indexDevices].roomId,
                            resourceType: "device", //Nodig voor Booker, ruimte valt onder machine
                            active: true, //Nodig voor Booker, ruimte valt onder machine
                            deviceId: this.selected_roomsDevices[indexDevices].deviceId, //Nodig voor Booker,
                          })
                          .catch((error) => {
                            console.error("Error created document selected_roomsDevices ", error);
                          });
                      }
                      //post Apparaat (overboekbaar)
                      for (let indexDevices = 0; indexDevices < this.selected_roomsDevices_override.length; indexDevices++) {
                        db.collection("bookingen")
                          .add({
                            eventSummaryId: AddeventSummaryId,
                            bookingId: docRef.id,
                            resourceId: this.selected_roomsDevices_override[indexDevices].resourceId,
                            start: this.chosenDate_begin + "T" + this.event.selectedStart,
                            end: this.chosenDate_begin + "T" + this.event.selectedEnd,
                            dateBooking: this.chosenDate_begin,
                            branch: this.activeBranch,
                            title: this.event.title,
                            description: this.event.notes,
                            type: "eventbooking",
                            eventColor: "rgba(114, 217, 196, 0.50)",
                            maxPersons: this.event.persons,
                            bookingOverride: true,
                            roomId: this.selected_roomsDevices_override[indexDevices].roomId,
                            resourceType: "device", //Nodig voor Booker, ruimte valt onder machine
                            active: true, //Nodig voor Booker, ruimte valt onder machine
                            deviceId: this.selected_roomsDevices_override[indexDevices].deviceId, //Nodig voor Booker,
                          })
                          .catch((error) => {
                            console.error("Error created document selected_roomsDevices_override ", error);
                          });
                      }
                    })

                    .catch((error) => {
                      console.error("Error created document bookingsummary ", error);
                    });
                })

                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error add Event summary: ", error);
                });
            }

            this.loading = false;
            this.$emit("CalbookEventModalChange", false);
            this.$emit("triggerRefetch");
          }
        }
      },

      saveExistingEvent() {
        this.loading = true;

        //Check of na het aanpassen van de tijd resources zijn geselecteerd.
        if (this.timeChanged == true) {
          if ((this.selected_employees.length !== 0 || this.selected_employees_override.length !== 0) && (this.selected_rooms.length !== 0 || this.selected_rooms_override.length !== 0)) {
            db.collection("events")
              .doc(this.eventSummaryId)
              .update({
                title: this.event.title,
                description: this.event.notes,
                treatmentType: this.event.treatmentType,
              })
              .then(() => {
                console.log("Existing Event update done. ", this.eventSummaryId);
              })

              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });

            db.collection("bookingsummary")
              .doc(this.bookingSummaryId)
              .update({
                maxPersons: this.event.persons,
                start: this.event.eventDate + "T" + this.event.selectedStart,
                end: this.event.eventDate + "T" + this.event.selectedEnd,
                description: this.event.notes,
                minTimeLimitSubscription: this.event.minTimeLimitSubscription,
                maxTimeLimitSubscription: this.event.maxTimeLimitSubscription,
                title: this.event.title,
                showSeats: this.event.showSeats,
              })
              .then(() => {
                let uploadcounter = 0;
                console.log("Existing Booking Summary updated. ", this.bookingSummaryId);
                for (let index = 0; index < this.eventBookingen.length; index++) {
                  db.collection("bookingen")
                    .doc(this.eventBookingen[index].id)
                    .delete()

                    .then(() => {
                      uploadcounter++;
                      console.log("Counter check Bookingen, ", uploadcounter);
                      if (uploadcounter == this.eventBookingen.length) {
                        this.saveExistingEventUpdate();
                        uploadcounter = 0;
                      }
                    })
                    .catch((error) => {
                      // The document probably doesn't exist.
                      console.error("Error updating document: ", error);
                    });
                }
              })

              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });
          } else {
            this.$swal({
              title: this.$t('events.invalidInput', this.$store.state.locale),
              text: this.$t('events.requiredEdit', this.$store.state.locale),
              confirmButtonText: this.$t('general.close', this.$store.state.locale),
              confirmButtonColor: "#00adef",
            });

            this.loading = false;
          }
        } 
        else {
          db.collection("events")
            .doc(this.eventSummaryId)
            .update({
              title: this.event.title,
              description: this.event.notes,
              treatmentType: this.event.treatmentType,
              // showSeats: this.event.showSeats
            })
            .then(() => {
              db.collection("bookingsummary")
                .doc(this.bookingSummaryId)
                .update({
                  active: this.event.showInWidget,
                  maxPersons: this.event.persons,
                  description: this.event.notes,
                  title: this.event.title,
                  showSeats: this.event.showSeats,
                  maxPersons: this.event.persons,
                  showInWidget: this.event.showInWidget,
                  minTimeLimitSubscription: this.event.minTimeLimitSubscription,
                  maxTimeLimitSubscription: this.event.maxTimeLimitSubscription,
                })
                .then(() => {
                    this.$emit("CalbookEventModalChange", false);
                    this.$emit("triggerRefetch");
                    this.devicesLoadedFiltered = [];
                    this.disableTimechange = false;

                    this.wolfloadModal = false;
                    this.updateCacheTrigger();
                    bus.$emit("calendarAction", "addEvent");
                    this.loading = false;
                    this.closedialog()
             
                })
                .catch((error) => {
                  console.error("Error updating document: ", error);
                });
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        }
      },

      //Vervolg op saveExistingEvent()
      saveExistingEventUpdate() {
        for (let indexEmployee = 0; indexEmployee < this.selected_employees.length; indexEmployee++) {
          db.collection("bookingen")
            .add({
              eventSummaryId: this.eventSummaryId,
              bookingId: this.bookingSummaryId,
              resourceId: Number(this.selected_employees[indexEmployee]),
              start: this.event.eventDate + "T" + this.event.selectedStart,
              end: this.event.eventDate + "T" + this.event.selectedEnd,
              dateBooking: this.event.eventDate,
              branch: this.activeBranch,
              title: this.event.title,
              description: this.event.notes,
              type: "eventbooking",
              maxPersons: this.event.persons,
              eventColor: "rgb(114, 217, 196)",
              bookingOverride: false,
            })
            .then(() => {
              console.log("Boeking gemaakt voor employee ", Number(this.selected_employees[indexEmployee]));
            })
            .catch((error) => {
              console.error("Error created document selected_employees ", error);
            });
        }
        for (let indexEmployee = 0; indexEmployee < this.selected_employees_override.length; indexEmployee++) {
          db.collection("bookingen")
            .add({
              eventSummaryId: this.eventSummaryId,
              bookingId: this.bookingSummaryId,
              resourceId: Number(this.selected_employees_override[indexEmployee]),
              start: this.event.eventDate + "T" + this.event.selectedStart,
              end: this.event.eventDate + "T" + this.event.selectedEnd,
              dateBooking: this.event.eventDate,
              branch: this.activeBranch,
              title: this.event.title,
              description: this.event.notes,
              type: "eventbooking",
              maxPersons: this.event.persons,
              eventColor: "rgba(114, 217, 196, 0.50)",
              bookingOverride: true,
            })
            .then(() => {
              console.log("Boeking gemaakt voor employee override ", Number(this.selected_employees_override[indexEmployee]));
            })
            .catch((error) => {
              console.error("Error created document selected_employees_override ", error);
            });
        }

        // Check welke kamer bij welke machine hoort
        //Post ruimtes (niet overboekbaar)
        this.selected_roomsDevices = [];
        for (let indexRooms = 0; indexRooms < this.selected_rooms.length; indexRooms++) {
          let foundedroomId = null;
          let DeviceRoomMatch = null;
          let deviceId = null;

          //Welk Id hoort bij welke ruimte?
          this.roomsBranch.forEach((o) => {
            if (Number(this.selected_rooms[indexRooms]) == o.resourceId) {
              foundedroomId = o.roomId;
            }
          });
          //Welk apparaat hoort bij welke kamer?
          this.devicesBranch.forEach((o) => {
            if (foundedroomId == o.roomId) {
              this.selected_roomsDevices.push({
                resourceId: o.resourceId,
                deviceId: o.deviceId,
                roomId: o.roomId,
              });
            }
          });
          //Post Ruimte (niet overboekbaar)

          db.collection("bookingen")
            .add({
              eventSummaryId: this.eventSummaryId,
              bookingId: this.bookingSummaryId,
              resourceId: Number(this.selected_rooms[indexRooms]),
              start: this.event.eventDate + "T" + this.event.selectedStart,
              end: this.event.eventDate + "T" + this.event.selectedEnd,
              dateBooking: this.event.eventDate,
              branch: this.activeBranch,
              title: this.event.title,
              description: this.event.notes,
              type: "eventbooking",
              maxPersons: this.event.persons,
              eventColor: "rgb(114, 217, 196)",
              bookingOverride: false,
              roomId: foundedroomId,
            })
            .then(() => {
              console.log("Boeking gemaakt voor room ", Number(this.selected_rooms[indexRooms]));
            })
            .catch((error) => {
              console.error("Error created document selected_rooms ", error);
            });
        }

        //Post ruimtes (overboekbaar)
        this.selected_roomsDevices_override = [];
        for (let indexRooms = 0; indexRooms < this.selected_rooms_override.length; indexRooms++) {
          let foundedroomId = null;
          let DeviceRoomMatch = null;
          let deviceId = null;

          //Welk Id hoort bij welke ruimte?
          this.roomsBranch.forEach((o) => {
            if (Number(this.selected_rooms_override[indexRooms]) == o.resourceId) {
              foundedroomId = o.roomId;
            }
          });
          //Welk apparaat hoort bij welke kamer?
          this.devicesBranch.forEach((o) => {
            if (foundedroomId == o.roomId) {
              this.selected_roomsDevices_override.push({
                resourceId: o.resourceId,
                deviceId: o.deviceId,
                roomId: o.roomId,
              });
            }
          });
          //Post Ruimte (overboekbaar)

          db.collection("bookingen")
            .add({
              eventSummaryId: this.eventSummaryId,
              bookingId: this.bookingSummaryId,
              resourceId: Number(this.selected_rooms_override[indexRooms]),
              start: this.event.eventDate + "T" + this.event.selectedStart,
              end: this.event.eventDate + "T" + this.event.selectedEnd,
              dateBooking: this.event.eventDate,
              branch: this.activeBranch,
              title: this.event.title,
              description: this.event.notes,
              type: "eventbooking",
              maxPersons: this.event.persons,
              eventColor: "rgba(114, 217, 196, 0.50)",
              bookingOverride: true,
              roomId: foundedroomId,
            })
            .then(() => {
              console.log("Boeking gemaakt voor room override", Number(this.selected_rooms_override[indexRooms]));
            })
            .catch((error) => {
              console.error("Error created document selected_rooms_override ", error);
            });
        }

        //post Apparaat (niet overboekbaar)
        for (let indexDevices = 0; indexDevices < this.selected_roomsDevices.length; indexDevices++) {
          db.collection("bookingen")
            .add({
              eventSummaryId: this.eventSummaryId,
              bookingId: this.bookingSummaryId,
              resourceId: this.selected_roomsDevices[indexDevices].resourceId,
              start: this.event.eventDate + "T" + this.event.selectedStart,
              end: this.event.eventDate + "T" + this.event.selectedEnd,
              dateBooking: this.event.eventDate,
              branch: this.activeBranch,
              title: this.event.title,
              description: this.event.notes,
              type: "eventbooking",
              maxPersons: this.event.persons,
              eventColor: "rgb(114, 217, 196)",
              bookingOverride: false,
              roomId: this.selected_roomsDevices[indexDevices].roomId,
              resourceType: "device", //Nodig voor Booker, ruimte valt onder machine
              active: true, //Nodig voor Booker, ruimte valt onder machine
              deviceId: this.selected_roomsDevices[indexDevices].deviceId, //Nodig voor Booker,
            })
            .catch((error) => {
              console.error("Error created document selected_roomsDevices ", error);
            });
        }
        //post Apparaat (overboekbaar)
        for (let indexDevices = 0; indexDevices < this.selected_roomsDevices_override.length; indexDevices++) {
          db.collection("bookingen")
            .add({
              eventSummaryId: this.eventSummaryId,
              bookingId: this.bookingSummaryId,
              resourceId: this.selected_roomsDevices_override[indexDevices].resourceId,
              start: this.event.eventDate + "T" + this.event.selectedStart,
              end: this.event.eventDate + "T" + this.event.selectedEnd,
              dateBooking: this.event.eventDate,
              branch: this.activeBranch,
              title: this.event.title,
              description: this.event.notes,
              type: "eventbooking",
              eventColor: "rgba(114, 217, 196, 0.50)",
              maxPersons: this.event.persons,
              bookingOverride: true,
              roomId: this.selected_roomsDevices_override[indexDevices].roomId,
              resourceType: "device", //Nodig voor Booker, ruimte valt onder machine
              active: true, //Nodig voor Booker, ruimte valt onder machine
              deviceId: this.selected_roomsDevices_override[indexDevices].deviceId, //Nodig voor Booker,
            })
            .catch((error) => {
              console.error("Error created document selected_roomsDevices_override ", error);
            });
        }

        this.loading = false;
        this.$emit("CalbookEventModalChange", false);
        this.$emit("triggerRefetch");
      },

      deleter(selectie) {
        this.loading = true;
        let docref = this.eventID;

        
        if (selectie == 1) { // Verwijderd volledige behandeling
          db.collection("bookingsummary")
            .doc(this.bookingSummaryId)
            .update({
              active: false,
            })
            .then(() => {
              let counter = 0;
              new Promise((resolve)=>{ // Promise to continue after all events are removed
                this.eventBookingen.forEach(event=>{
                  db.collection("bookingen")
                  .doc(event.id)
                  .update({
                    active: false,
                    dateBooking: "deleted",
                    deleted: {
                      normalDatebooking: moment(this.localetime).format("YYYY-MM-DD"),
                      deletedby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")", //Dynamisch maken via Rollen systeem
                      deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
                    },
                  })
                  .then(() => {
                    counter++
                    if(counter == this.eventBookingen.length){
                      resolve()
                    }
                  })
                  .catch((error) => { // The document probably doesn't exist.          
                    console.error("Error updating document: ", error);
                    counter++
                    if(counter == this.eventBookingen.length){
                      resolve()
                    }
                  });
                })
              })
              .then(() => {
                this.$emit("CalbookEventModalChange", false);
                this.disableTimechange = false;
                this.wolfloadModal = false;
                this.updateCacheTrigger();
                bus.$emit("calendarAction", "deleteEvents");
                this.loading = false;
                this.closedialog()
              })
            })
            .catch((error) => { // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        }

        //Verwijderd gedeelte behandeling
        else if (selectie == 2) {
          this.status = this.$t('events.removing', this.$store.state.locale),

          db.collection("bookingen")
            .doc(docref)
            .update({
              active: false,
              dateBooking: "deleted",
              deleted: {
                normalDatebooking: moment(this.localetime).format("YYYY-MM-DD"),
                deletedby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")", //Dynamisch maken via Rollen systeem
                deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
              },
            })
            .then(() => {
              this.$emit("CalbookEventModalChange", false);
              this.devicesLoadedFiltered = [];
              this.disableTimechange = false;

              this.status = "";
              this.wolfloadModal = false;
              this.updateCacheTrigger();
              bus.$emit("calendarAction", "deleteEvent");
              this.loading = false;
              this.closedialog()
            })

            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        }
        

        else if (selectie == 3) {
          // 1. Check via Events (summary) hoeveel bookingsummarys
          // 2. Per summary de booking weggooien zoals selectie 1
          this.indexingBooking = [];
          let timeToday = moment().format("YYYY-MM-DD");
          let summaryCounter = 0;

          // Zet event op non-actief
          for (let indexSummary = 0; indexSummary < this.indexingBookingsummary.length; indexSummary++) {
            db.collection("bookingsummary")
              .doc(this.indexingBookingsummary[indexSummary].docIDbookingsummary)
              .update({
                active: false,
              })
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });

            //Haal alle losse Bookingen binnen
            db.collection("bookingen")
              .where("bookingId", "==", this.indexingBookingsummary[indexSummary].docIDbookingsummary)
              .where("dateBooking", ">=", timeToday)
              .get()
              .then((snapshot) => {
                snapshot.forEach((doc) => {
                  this.indexingBooking.push({
                    docIDbookings: doc.id,
                  });
                });
              })

              .then(() => {
                summaryCounter++;
                if (this.indexingBookingsummary.length == summaryCounter) {
                  this.deleteAllBookings();
                }
              })
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });
          }
        }
      },

      // Alle toekomstige bookings verwijderen
      deleteAllBookings() {
        let bookingCounter = 0;
        for (let index = 0; index < this.indexingBooking.length; index++) {
          db.collection("bookingen")
            .doc(this.indexingBooking[index].docIDbookings)
            .update({
              active: false,
              dateBooking: "deleted",
              deleted: {
                normalDatebooking: moment(this.localetime).format("YYYY-MM-DD"),
                deletedby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")", //Dynamisch maken via Rollen systeem
                deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
              },
            })
            .then(() => {
              // bookingCounter++;
              // if (this.indexingBooking == bookingCounter) {
              // }

              bus.$emit("calendarAction", "addEvent");
              this.closedialog()
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        }

        this.$emit("CalbookEventModalChange", false);
        this.disableTimechange = false;

        this.loading = false;
      },

      updateCacheTrigger() {
        let resIds = this.eventBookingen.map((booking) => booking.extendedProps.resourceDocId);
        resIds = [...new Set(resIds)];
        bus.$emit("cacheUpdate", resIds);
      },

      getGender(gender){
        if(gender == 2){
          return "Vrouw"
        }
        else if(gender == 1){
          return "Man"
        }
        else{
          return "Onbekend"
        }
      },

      closedialog() {
        // Object.assign(this.$data, this.$options.data());
        this.$emit("CalbookEventModalChange", false);
        this.event.notes = "";
        this.event.title = "";
        this.event.persons = 1;
        this.slots = null;
        this.bookingSummary = [];
        this.event.selectedStart = null;
        this.event.selectedEnd = null;
        this.repeatEvent = false;
      },
    },
    watch: {
      selected_employees() {
        if (this.selected_employees.some((r) => this.selected_employees_override.includes(r))) {
          this.selected_employees.pop();
        }
      },
      selected_employees_override() {
        if (this.selected_employees_override.some((r) => this.selected_employees.includes(r))) {
          this.selected_employees_override.pop();
        }
      },
      selected_rooms() {
        if (this.selected_rooms.some((r) => this.selected_rooms_override.includes(r))) {
          this.selected_rooms.pop();
        }
      },
      selected_rooms_override() {
        if (this.selected_rooms.some((r) => this.selected_rooms_override.includes(r))) {
          this.selected_rooms_override.pop();
        }
      },
    },

    computed: {
      showSubscribeNewClientButton() {
        if (this.newClientData.valid && this.newClientData.email != this.validatedEmail) {
          return true;
        } else {
          return false;
        }
      },

      eventChanged() {
        if (_.isEqual(this.bookingSummary, this.bookingSummaryOriginal) == false || _.isEqual(this.event, this.eventOriginal) == false) {
          return true;
        } else {
          return false;
        }
      },

      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
      filteredClients() {
        let availableClients = [];
        this.clients.forEach((clientInList) => {
          let index = _.indexOf(this.registeredClient, clientInList.id);
          if (index >= 0) {
            clientInList.disabled = true;
          } else {
            clientInList.disabled = false;
          }
          availableClients.push(clientInList);
        });

        return availableClients.filter((client) => {
          let clientName = client.name + " " + client.surname;
          let clientEmail = "";
          if (client.email) {
            clientEmail = client.email;
          }

          return clientName.toLowerCase().includes(this.search.toLowerCase()) || clientEmail.toLowerCase().includes(this.search.toLowerCase());
        });
      },
    },
  };
</script>
<style>
  .search-client-list {
    min-height: 44px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: left;
  }
</style>
