<template>
  <div>
    <div class="tab-component-wrapper">
      <div class="mx-4 mt-4">
        <v-container grid-list-md class="pa-0" fluid>
          <v-layout row wrap>
            <v-flex md6 xs12>
              <h1 class="tab-title">Pakketten</h1>
            </v-flex>
            <v-flex md6 xs12>
              <div style="display: inline-block; float: right;">
                <v-row v-if="!loading">
                  <div
                    class="add-packages-button"
                    v-if="_.isEmpty(subscriptionOriginal.packages) == false"
                    >
                    <v-btn
                      class="ma-4"
                      @click="openUpgradeDialog()"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      dark
                      depressed
                      >
                      <v-icon left>mdi-plus</v-icon> Pakket toevoegen
                    </v-btn>
                  </div>
                </v-row>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </div>

      <div class="content-box">

        <v-container fluid>
          <v-layout row wrap v-if="loading">
            <v-flex xs12 style="position: relative; padding: 200px 0;">
              <div class="thrive-loader"></div>
            </v-flex>
          </v-layout>

          <v-layout row wrap v-if="!loading">
            <v-flex xs6>
              <div class="subscription-box-wrapper">
                <h2>Huidige abonnement</h2>

                <div class="free-version-wrapper" v-if="_.isEmpty(subscriptionOriginal.packages)">
                  <p>Er zijn geen pakketten actief. U maakt nu gebruik van de gratis versie van Thrive.</p>

                  <div class="free-version-explanation">
                    <h4>Gratis versie Thrive</h4>
                    <p>De gratis versie van Thrive bied de mogelijk om onze applicatie kostenloos te ontdekken.
                      <br>
                      <br>
                      <strong>Mogelijkheden</strong>
                      <br>De eerste 5 cli&euml;ntendossiers kunnen kostenloos worden aangemaakt. Van alle pakket is het mogelijk om per klant 5 acties uit te voeren zoals; foto's maken, lichaamssamenstelling toevoegen en afspraak in plannen.
                      <br>
                      <br>
                      <strong>Upgraden</strong>
                      <br>Het is altijd mogelijk om pakkten toe te voegen en/of op te zeggen. Aan de eerste 3 gebruikens (medewerkers die kunnen inloggen) zijn geen kosten verbonden.
                    </p>
                  </div>

                  <v-layout class="upgrade-free-version" align-center>
                    <v-flex xs12 text-xs-center>
                      <p>Zonder limiet gebruik maken van Thrive? Voeg dan de gewenste pakketen toe.</p>
                      <v-btn
                        @click="openUpgradeDialog()"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        dark
                        rounded
                      >Pakket toevoegen
                        <v-icon right dark>mdi-plus-circle</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </div>

                <div v-else>
                  <div
                    class="subscription-item-wrapper clear"
                    v-for="(subscriptionPackage, key) in subscriptionOriginal.packages"
                    :key="key"
                  >
                    <h3>{{subscriptionPackage.name}}</h3>
                    <div v-if="$store.state.activeUserRoleNumber < 2" class="remove-package-wrapper">
                      <v-tooltip left>
                        <v-btn
                          @click="removePackage(key)"
                          fab
                          dark
                          small
                          depressed
                          color="#687387"
                        >
                          <v-icon small dark>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <span
                          class="tooltip-text-white"
                        >Verwijderen alleen mogelijk door Thrive</span>
                      </v-tooltip>
                    </div>
                    <div class="subscription-item-info">
                      <p>
                        <span class="subscription-item-label">Afgesloten op:</span>
                        <span
                          class="subscription-item-text"
                        >{{subscriptionPackage.startDate.seconds | moment("D MMM YYYY")}}</span>
                      </p>

                      <p>
                        <span class="subscription-item-label">Looptijd contract:</span>
                        <span
                          class="subscription-item-text"
                        >{{subscriptionPackage.contractMonths}} maanden</span>
                      </p>

                      <p>
                        <span class="subscription-item-label">Betaalperiode:</span>
                        <span v-if="subscriptionPackage.paymentPeriod == 'yearly'"
                          class="subscription-item-text"
                        >Jaarlijks</span>
                        <span v-else
                          class="subscription-item-text"
                        >Maandelijks</span>
                      </p>

                      <p>
                        <span class="subscription-item-label">Opzegbaar vanaf:</span>
                        <span
                          cass="subscription-item-text"
                        >{{subscriptionPackage.cancelDate | moment("D MMM YYYY")}}</span>
                      </p>

                      <p
                        class="subscription-cancel-package"
                        v-if="!subscriptionPackage.endDate"
                        @click="cancelPackage(key)"
                      >Pakket opzeggen</p>
                      <div
                        class="subscription-canceled"
                        v-if="subscriptionPackage.endDate"
                      >Pakket is geannuleerd vanaf {{subscriptionPackage.endDate.seconds | moment("D MMM YYYY")}}</div>
                      <p
                        class="subscription-cancel-package"
                        v-if="subscriptionPackage.endDate"
                        @click="undoCancelPackage(key)"
                      >Opzegging annuleren</p>
                    </div>

                    <div class="clear">
                      <div class="subscription-item-prices">
                        <p>
                          <span class="subscription-item-label">Pakketprijs:</span>
                          <span
                            class="subscription-item-text"
                          >&euro; {{ (subscriptionPackage.priceOriginal).toFixed(2) }}</span>
                        </p>
                        <p v-if="subscriptionPackage.packageDiscount">
                          <span class="subscription-item-label">Pakketkorting:</span>
                          <span
                            class="subscription-item-text"
                          >&euro; {{ (subscriptionPackage.packageDiscount).toFixed(2) }}</span>
                        </p>
                        <p>
                          <span class="subscription-item-label">Periodekorting:</span>
                          <span
                            v-if="subscriptionPackage.periodDiscount > 0"
                            class="subscription-item-text"
                          >&euro; {{ (subscriptionPackage.periodDiscount).toFixed(2) }}</span>
                          <span v-else class="subscription-item-text">-</span>
                        </p>
                        <p>
                          <span class="subscription-item-label">Groepskorting:</span>
                          <span
                            v-if="subscriptionPackage.groupDiscount > 0"
                            class="subscription-item-text"
                          >&euro; {{ (subscriptionPackage.groupDiscount).toFixed(2) }}</span>
                          <span v-else class="subscription-item-text">-</span>
                        </p>

                        <p>
                          <span class="subscription-item-label">Betaalkorting:</span>
                          <span
                            v-if="subscriptionPackage.paymentPeriod == 'yearly'"
                            class="subscription-item-text"
                          >&euro; {{ (subscriptionPackage.paymentPeriodDiscount).toFixed(2) }}</span>
                          <span v-else class="subscription-item-text">-</span>
                        </p>
                        <p>
                          <span class="subscription-item-label">Totaalprijs / mnd:</span>
                          <span class="subscription-item-text"><strong>&euro; {{ (subscriptionPackage.calculatedPrice).toFixed(2) }}</strong> excl. btw</span>
                        </p>
                        <p v-if="subscriptionPackage.paymentPeriod == 'yearly'">
                          <span class="subscription-item-label">Totaalprijs / jaar:</span>
                          <span class="subscription-item-text"><strong>&euro; {{ (subscriptionPackage.calculatedPrice * 12).toFixed(2) }}</strong> excl. btw</span>
                        </p>
                      </div>
                    </div>
                    <p class="summary-payment-info" v-if="subscriptionPackage.paymentPeriod == 'yearly'">Kosten worden jaarlijks automatisch afgeschreven.</p>
                    <p class="summary-payment-info" v-else>Kosten worden maandelijks automatisch afgeschreven.</p>
                  </div>

                  <div class="summary-payment-table">
                    <div class="summary-payment-row-wrapper clear">
                      <div class="summary-payment-row-label row-title">Gebruikers</div>
                      <div class="summary-payment-row-data"></div>
                      <div class="summary-payment-row-extra"></div>
                    </div>
                    <div class="summary-payment-row-wrapper clear">
                      <div class="summary-payment-row-label">Active gebruikers:</div>
                      <div class="summary-payment-row-data">{{subscription.users}}</div>
                      <div class="summary-payment-row-extra">Eerste 3 gebruikers gratis</div>
                    </div>
                    <div class="summary-payment-row-wrapper clear">
                      <div class="summary-payment-row-label">Kosten per active gebruiker:</div>
                      <div class="summary-payment-row-data">&euro; {{(userPrice).toFixed(2)}}</div>
                      <div class="summary-payment-row-extra">/ mnd </div>
                    </div>
                    <div class="summary-payment-row-wrapper clear">
                      <div class="summary-payment-row-label">Totaalprijs gebruikers:</div>
                      <div class="summary-payment-row-data">
                        <span v-if="subscription.users < 4">-</span>
                        <span v-else><strong>&euro; {{(priceSummary.totalPriceUsers).toFixed(2)}}</strong></span>
                      </div>
                      <div
                        class="summary-payment-row-extra"
                      >/ mnd  excl. btw </div>
                    </div>

                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex xs6>
              <div class="subscription-box-wrapper">
                <div class="slider-wrapper" v-if="functionalities.length > 0">
                  <h2>Beschikbare functionaliteiten</h2>

                  <Slider
                    ref="Slider"
                    direction="horizontal"
                    :mousewheel-control="true"
                    :performance-mode="true"
                    :pagination-visible="true"
                    :pagination-clickable="true"
                    :loop="true"
                    :speed="500"
                  >
                    <div
                      class="functionalities-slide"
                      v-for="(thriveFunctionality, index) in functionalities"
                      :key="index"
                    >
                      <v-img v-if="thriveFunctionality.iconUrl" :src="thriveFunctionality.iconUrl" height="125" contain>
                        <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-layout>
                      </v-img>
                      <h4>{{ thriveFunctionality.title}}</h4>
                      <p>{{ thriveFunctionality.description}}</p>
                    </div>
                  </Slider>
                </div>

                <div class="packages-wrapper" v-if="packages.length > 0">
                  <h2>Beschikbare pakketten</h2>
                  <v-container fluid grid-list-md class="pa-0">
                    <v-layout row wrap>
                      <v-flex
                        xs6
                        d-flex
                        v-for="(thrivePackage, index) in packages"
                        :key="index"
                      >
                        <div class="thrive-package-wrapper" >
                          <h4>{{ thrivePackage.name}}</h4>
                          <!-- <p class="package-price">
                            <span v-if="thrivePackage.action.active == true && thrivePackage.action.startDate.seconds <= today && thrivePackage.action.endDate.seconds > today"><span class="old-price">&euro;{{thrivePackage.priceNetto.toFixed(2) }}</span> <span class="new-price">&nbsp; &euro;{{thrivePackage.action.priceNetto.toFixed(2) }}</span>/ mnd</span>
                            <span v-else>&euro;{{thrivePackage.priceNetto.toFixed(2) }} / mnd</span>
                          </p>-->
                          <div class="package-extra">
                            <p>Dit pakket bevat:</p>
                            <ul class="functionalities-list">
                              <li v-if="functionality.package == thrivePackage.id"
                                v-for="(functionality, index) in functionalities"
                                :key="index"
                              >
                                <span >{{ functionality.title }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>
  

    <!-- / Upgrade Dialog \ -->
    <v-dialog v-if="!loading" v-model="upgradeDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <upgrade-dialog
        :chosenPackagesOriginal="chosenPackagesOriginal"
        :chosenPackages="chosenPackages"
        :chosenPeriod="chosenPeriod"
        :chosenPeriodDiscountPercentage="chosenPeriodDiscountPercentage"
        :subscription="subscription"
        :today="today"
        :termsAndConditions="termsAndConditions"
        :packages="packages"
        :paymentOptions="paymentOptions"
        :combinationdiscount="combinationdiscount"
        :functionalities="functionalities"
        :activeCompData="activeCompData"
        @upgradeDialogChange="upgradeDialog = $event"
        @updateData="loadData()"
      ></upgrade-dialog>
    </v-dialog>
    <!-- \ Upgrade Dialog / -->
  </div>
</template>

<script>
import db from "../firebase/init";
import moment from "moment";
import _ from "lodash";
import { bus } from "../main";
import Slider from "vue-plain-slider";
import { log } from "fullcalendar";
import UpgradeDialog from "../components/modals/upgradeDialog.vue";

export default {
  components: {
    Slider,
    "upgrade-dialog": UpgradeDialog
  },
  props: {
    activeComp: String,
    activeCompData: Object,
    activeCompDataOriginal: Object
  },

  name: "PackagesOverview",

  data: () => ({
    today: null,
    chosenPeriod: 0,
    chosenPeriodDiscountPercentage: null,
    loading: true,
    subscriptionOriginal: null,
    subscription: null,
    subscriptionCancel: null,
    packages: null,
    paymentOptions: null,
    functionalities: null,
    combinationdiscount: null,
    upgradeDialog: false,
    termsAndConditions: null,
    chosenPackages: [],
    chosenPackagesOriginal: [],
    userPrice: 0,
    priceSummary: {
      totalPriceDiscount: 0,
      totalPriceOriginal: 0,
      totalPricePackages: 0,
      totalPriceUsers: 0
    }
  }),

  created() {
    bus.$on("companyUpdate", this.loadData);
    this.loadData();
  },

  methods: {
    loadData() {
      this.loading = true
      this.today = moment().unix();
      this.loadUserPrice();
      this.checkSubscription();
      this.loadTerms();
    },

    openUpgradeDialog() {
      bus.$emit("updateCalculation");
      this.upgradeDialog = true;
    },

    removePackage(id) {
      let companySubscription = {};
      companySubscription.subscription = this.subscriptionCancel;
      delete companySubscription.subscription.packages[id];
      console.log(
        "Package removed: " + this.subscriptionOriginal.packages[id].name
      );
      if (this.objectSize(companySubscription.subscription.packages) < 2) {
        companySubscription.subscription.groupDiscount = 0;
      }
      db.collection("companies")
      .doc(this.activeCompData.id)
      .update(companySubscription)
      .then(() => {
        this.loadActiveCompData();
      })
    },

    cancelPackage(id) {
      this.loading = true;
      this.subscriptionOriginal.packages[id].endDate = {};
      this.subscriptionOriginal.packages[id].endDate.seconds = moment(
        this.subscriptionOriginal.packages[id].cancelDate
      ).format("DD MMM YYYY");

      let companySubscription = {};
      companySubscription.subscription = this.subscriptionCancel;
      companySubscription.subscription.packages[id].endDate = moment(
        this.subscriptionOriginal.packages[id].cancelDate
      ).toDate();
      console.log(
        "Package canceled: " + this.subscriptionOriginal.packages[id].name
      );

      db.collection("companies")
      .doc(this.activeCompData.id)
      .update(companySubscription)
      .then(() => {
        this.loadActiveCompData();
      })
    },

    undoCancelPackage(id) {
      this.loading = true;
      this.subscriptionOriginal.packages[id].endDate = null;
      let companySubscription = {};
      companySubscription.subscription = this.subscriptionCancel;
      companySubscription.subscription.packages[id].endDate = null;

      console.log(
        "Package cancelation undone: " +
          this.subscriptionOriginal.packages[id].name
      );

      db.collection("companies")
      .doc(this.activeCompData.id)
      .update(companySubscription)
      .then(() => {
        this.loadActiveCompData();
      })
    },

    isNotEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return true;
      }
      return false;
    },

    objectSize(obj) {
      let size = 0,
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    },

    checkSubscription() {
      let counterCheck = 0;

      // EERSTE PROMISE
      // Kijken of de pakketen in de database niet zijn verlopen
      // Maandelijks bij de incasso loop word deze check ook gedaan.

      if(this.objectSize(this.activeCompData.subscription.packages) > 0){

        console.log("Packages aanwezig. Promise wordt gestart.");

        new Promise((resolve, reject) => {
          for (let key in this.activeCompData.subscription.packages) {
            if (
              this.activeCompData.subscription.packages[key].endDate &&
              this.activeCompData.subscription.packages[key].endDate.seconds <
                this.today
            ) {
              console.log("Package canceled & expired");
              delete this.activeCompData.subscription.packages[key];
            }

            counterCheck++;
            if (counterCheck == this.objectSize(this.activeCompData.subscription.packages)) {
              resolve();
            }
          }
        })

        .then(() => {
          console.log("checkSubscription Promise Resolved");
          if (this.objectSize(this.activeCompData.subscription.packages) < 2) {
            this.activeCompData.subscription.groupDiscount = 0;
          }
        })
        .then(() => {
          this.loadSubscription();
        });

      } else {
        console.log("Geen packages aanwezig.");
        this.activeCompData.subscription.groupDiscount = 0;
        this.loadSubscription();
      }
    },

    loadUserPrice() {
      db.collection("prices")
        .doc("userprice")
        .get()
        .then(doc => {
          this.userPrice = Number(doc.data().price);
        });
    },

    loadSubscription() {
      console.log("Subscription loading");
      
      this.subscriptionOriginal = _.cloneDeep(this.activeCompData.subscription);
      this.subscriptionCancel = _.cloneDeep(this.activeCompData.subscription);
      this.subscription = _.cloneDeep(this.activeCompData.subscription);

      if (this.isNotEmpty(this.subscriptionOriginal.packages)) {
        this.priceSummary.totalPriceOriginal = null;
        this.priceSummary.totalPriceDiscount = null;
        let totalPriceOriginal = Number(0);
        let totalPriceDiscount = Number(0);
        let totalPricePackages = Number(0);
        let totalGroupDiscount = Number(0);
        let counter = 0;

        // TWEEDE  PROMISE
        // Berekenen van actuele prijzen

        new Promise((resolve, reject) => {
          for (let key in this.subscriptionOriginal.packages) {
            let startDate = moment.unix(this.subscriptionOriginal.packages[key].startDate.seconds);
            this.subscriptionOriginal.packages[key].cancelDate = moment(startDate).add(this.subscriptionOriginal.packages[key].contractMonths, "months");

            let calculatedPackagePrice = 0;
            let periodDiscount = 0;
            let packageDiscount = 0;
            let groupDiscount = 0;
            if (this.subscriptionOriginal.packages[key].priceOriginal != this.subscriptionOriginal.packages[key].price) {
              packageDiscount = Number(this.subscriptionOriginal.packages[key].priceOriginal - this.subscriptionOriginal.packages[key].price);
            }

            if (this.subscriptionOriginal.packages[key].chosenPeriodDiscountPercentage > 0) {
              periodDiscount = Number((this.subscriptionOriginal.packages[key].price / 100) * this.subscriptionOriginal.packages[key].chosenPeriodDiscountPercentage);
            }

            if (this.subscriptionOriginal.groupDiscount > 0) {
              groupDiscount = Number((this.subscriptionOriginal.packages[key].price / 100) * this.subscriptionOriginal.groupDiscount);
            }

            

            calculatedPackagePrice = Number(this.subscriptionOriginal.packages[key].priceOriginal - (periodDiscount + groupDiscount+ packageDiscount + this.subscriptionOriginal.packages[key].paymentPeriodDiscount));

            

            this.subscriptionOriginal.packages[key].periodDiscount = Number(
              periodDiscount
            );
            this.subscriptionOriginal.packages[key].packageDiscount = Number(
              packageDiscount
            );

            this.subscriptionOriginal.packages[key].calculatedPrice = calculatedPackagePrice;
            this.subscriptionOriginal.packages[key].groupDiscount = groupDiscount
            totalGroupDiscount = Number(totalGroupDiscount + groupDiscount);
            totalPriceDiscount = Number(totalPriceDiscount + calculatedPackagePrice);
            totalPriceOriginal = Number(totalPriceOriginal + this.subscriptionOriginal.packages[key].price);

            counter++;
            if (
              counter == this.objectSize(this.subscriptionOriginal.packages)
            ) {
              resolve();
            }
          }
        })
        .then(() => {

          console.log("Promise actuele prijzen resolved");
          this.loadPackages();

          if (this.subscription.users > 3) {
            console.log("Meer dan 3 users");
            let activeUsers = Number(this.subscription.users - 3);

            console.log();

            this.priceSummary.totalPriceUsers = activeUsers * this.userPrice;
          } else {
            this.priceSummary.totalPriceUsers = 0;
          }
          this.priceSummary.totalPriceOriginal = totalPriceOriginal;
          this.priceSummary.totalPriceDiscount = totalPriceDiscount;
          this.priceSummary.totalPricePackages = Number(
            totalPriceDiscount - totalGroupDiscount
          );
        });
      } else {
        console.log("No subscriptions");
        this.loadPackages();
      }
    },

    loadPackages() {
      db.collection("packages")
        .where("active", "==", true)
        .get()
        .then(snap => {
          this.packages = [];
          this.chosenPackages = [];
          this.chosenPackagesOriginal = [];
          snap.forEach(doc => {
            let thrivePackage = doc.data();

            thrivePackage.collapsed = false;
            thrivePackage.id = doc.id;

            if (this.subscriptionOriginal.packages[doc.id]) {
              thrivePackage.priceOriginal = this.subscriptionOriginal.packages[
                doc.id
              ].priceOriginal;
              thrivePackage.price = this.subscriptionOriginal.packages[
                doc.id
              ].price;
              thrivePackage.priceNetto = this.subscriptionOriginal.packages[
                doc.id
              ].price;
              thrivePackage.action.active = false;
              thrivePackage.chosenPeriodDiscountPercentage = this.subscriptionOriginal.packages[
                doc.id
              ].chosenPeriodDiscountPercentage;
              thrivePackage.alreadyPurchased = true;
              thrivePackage.added = true;
              this.chosenPackages.push(doc.id);
              this.chosenPackagesOriginal.push(doc.id);
            } else {
              thrivePackage.alreadyPurchased = false;
              thrivePackage.added = false;
            }

            this.packages.push(thrivePackage);
          });
        })
        .then(() => {
          db.collection("functionalities")
            .where("active", "==", true)
            .get()
            .then(snap => {
              this.functionalities = [];
              snap.forEach(doc => {
                let functionality = doc.data();
                functionality.id = doc.id;
                this.functionalities.push(functionality);
              });
            })
            .then(() => {
              db.collection("prices")
                .doc("groupdiscount")
                .get()
                .then(doc => {
                  this.combinationdiscount = Number(
                    doc.data().discountPercentage
                  );
                })
                .then(() => {
                  db.collection("paymentoptions")
                    .orderBy("period", "desc")
                    .get()
                    .then(snap => {
                      this.paymentOptions = [];
                      snap.forEach(doc => {
                        let payment = doc.data();
                        payment.id = doc.id;
                        payment.active = false;
                        this.paymentOptions.push(payment);
                      });
                    })
                    .then(() => {
                      this.paymentOptions[0].active = true;
                      this.chosenPeriod = this.paymentOptions[0].period;

                      if (this.paymentOptions[0].discount == true) {
                        this.chosenPeriodDiscountPercentage = this.paymentOptions[0].discountPercentage;
                      }
                    })
                    .then(() => {
                      this.loading = false;
                    });
                });
            });
        })

        .catch(error => {
          console.log("Errot getting upgrade data");
          this.loading = false;
        });
    },

    loadTerms() {
      db.collection("conditions")
        .doc("general-terms")
        .get()
        .then(doc => {
          this.termsAndConditions = doc.data();
        });
    }
  },

  computed: {

    userCompanies() {
      return this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
  }
};
</script>

<style >
.add-packages-button {
  position: absolute;
  top: 0;
  right: 0;
}

.packages-wrapper h2 {
  margin: 10px 0;
}

.subscription-box-wrapper {
  position: relative;
  margin: 15px;
}

.subscription-item-wrapper {
  position: relative;
  padding: 5px 10px;
  margin: 20px 0;
  background-color: var(--card-color);
  border-radius: 5px;
  border: 1px solid var(--primary-color);
}

.subscription-item-wrapper h3 {
  font-weight: 600;
  padding: 0 0 10px 0;
}

.subscription-item-info {
  width: 50%;
  float: left;
}

.subscription-item-prices {
  width: 50%;
  float: left;
}

.subscription-item-info p,
.subscription-item-prices p {
  font-size: 12px;
  color: var(--text-color);
  font-weight: 400;
  padding: 2px 0;
}

.subscription-item-info p span.subscription-item-label,
.subscription-item-prices p span.subscription-item-label {
  font-size: 12px;
  color: var(--text-color);
  font-weight: 600;
  width: 120px;
  display: inline-block;
}

p.subscription-cancel-package {
  font-size: 12px;
  color: var(--secondary-color);
  font-weight: 600;
  cursor: pointer;
}

.subscription-canceled {
  background-color: var(--secondary-color);
  margin: 5px 0;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
}

.slider {
  width: 100%;
  height: 250px;
}

.functionalities-slide h4 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.functionalities-slide p {
  text-align: center;
  padding: 10px 15px 0 15px;
}

.slider .slider-pagination .slider-pagination-bullet.active {
  background: var(--primary-color);
}

.packages-wrapper {
  padding: 10px 0;
}

.thrive-package-wrapper {
  width: 100%;
  padding: 5px 10px;
  background-color: var(--primary-color);
  border-radius: 5px;
  border: 1px solid var(--primary-color);
}

.thrive-package-wrapper h4 {
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0;
  color: #fff;
}
.thrive-package-wrapper p.package-price {
  padding: 0 0 5px 0;
  color: #fff;
  font-size: 15px;
}
.thrive-package-wrapper p.package-price span {
  font-weight: 600 !important;
  color: #fff;
}

span.old-price {
  text-decoration: line-through;
  font-size: 15px;
}

span.new-price {
  font-weight: 600;
  padding: 0 5px 0 0;
  font-size: 15px;
}

.package-extra p,
ul.functionalities-list,
ul.functionalities-list li span {
  color: #fff;
}

.free-version-wrapper {
  margin: 20px 0;
}

.free-version-explanation {
  margin: 10px 0;
  padding: 15px;
  background: var(--v-background-base);
  border-radius: 5px;
}

.free-version-explanation h4 {
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 15px 0;
  margin: 0;
}
.upgrade-free-version,
.upgrade-free-version p {
  padding: 15px 0;
}

.summary-payment-row-label {
  width: 35%;
  height: 30px;
  line-height: 30px;
  float: left;
  font-weight: 400;
}

.summary-payment-row-label.row-title {
  font-weight: 600;
}

.summary-payment-row-label.span {
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  float: none;
}

.summary-payment-row-data {
  width: 15%;
  float: left;
  height: 30px;
  line-height: 30px;
  text-align: right;
}

.summary-payment-row-extra {
  width: 50%;
  padding: 0 0 0 10px;
  height: 30px;
  line-height: 30px;
  float: left;
  font-style: italic;
}
.summary-payment-row-wrapper {
  height: 30px;
  line-height: 30px;
}

.summary-payment-row-wrapper.space {
  height: 30px;
}

.summary-payment-row-wrapper.total .summary-payment-row-data,
.summary-payment-row-wrapper.total .summary-payment-row-extra,
.summary-payment-row-wrapper.total .summary-payment-row-label {
  font-weight: 800;
  font-style: normal;
}

.summary-payment-info {
  padding: 10px 0 5px 0;
  font-style: italic;
  font-size: 12px;
}

.remove-package-wrapper {
  position: absolute;
  right: 0;
  top: 5px;
}
</style>
