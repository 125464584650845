
<template>
  <div>
    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
      <v-container grid-list-md class="ma-0 pa-0">
        <v-layout class="mx-4 py-2 pa-0" d-flex> <!-- Standaard berichten -->
          <v-flex xs4 d-flex>
            <h3>{{ $t('messages.overview', $store.state.locale) }}</h3>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <v-container grid-list-md class="ma-0 pa-0" >
        <v-layout class="ma-0 pa-3">
          <v-flex md3 xs12 v-if="branches.length > 0" :key="changes">
            <v-select
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :items="branches"
              item-text="name"
              item-value="id"
              v-model="selectedBranch"
              :label="$t('general.branch', $store.state.locale)"
              v-on:input="getBranchMessages()"
              outlined
              hide-details
              dense
              
            ></v-select>
          </v-flex>
          <v-flex md3 xs12>
            <period-select :dataType="'dashboard'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-tab-header" :class="$store.state.companyTheme">
          <v-tabs :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="historyTab">
            <v-tab href="#dashboard"><v-icon size="18" left>mdi-information</v-icon>{{ $t('messages.dashboard', $store.state.locale) }}</v-tab>
            <v-tab href="#messages"><v-icon size="18" left>mdi-send</v-icon>{{ $t('messages.messages', $store.state.locale) }}</v-tab>
          </v-tabs>
        </v-card>
      
        <v-tabs-items v-model="historyTab" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card}">
          <v-tab-item value="dashboard" :transition="tabTransition" :reverse-transition="tabTransition">
            <div v-if="messagesLoading">
              <div style="position: relative; padding: 200px 0">
                <div class="thrive-loader"></div>
              </div>
            </div>
            <div v-else>
              <div v-if="messages.length == 0" class="text-center" style="padding: 150px;">
                <v-avatar 
                  size="76"
                  light
                  class="ma-4 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon>
                </v-avatar>
                
                <div style="font-size: 18px;">{{ $t('emails.noSendMessages', $store.state.locale) }}</div>
              </div>
              <div v-else class="pa-4">
                <v-container grid-list-md class="ma-0 pa-0">
                  <v-layout class="ma-0 pa-0" d-flex> <!-- Standaard berichten -->
                    <v-flex xs6 d-flex>
                      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="pa-4" style="width: 100%">
                        <div class="text-center">
                         <v-avatar 
                            size="56"
                            light
                            class="ma-4 custom-avatar-style" 
                            rounded="lg"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                            >
                            <v-icon  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email</v-icon>
                          </v-avatar>
                          <h3 class="mb-4">{{ $t('general.emails', $store.state.locale) }} </h3>
                        </div>
                        <v-row class="ma-0 pa-0">
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          <v-col md="7" class="ma-0 py-2 pa-0 text-left"><strong>{{ $t('emails.numberOfSendMessages', $store.state.locale) }}</strong></v-col>
                          <v-col md="3" class="ma-0 py-2 pa-0 text-right"> {{ emailOverview.emailsSend }}</v-col>
                          <v-col md="1" class="ma-0 pt-1 pa-0 text-right">
                            <div v-if="Object.keys(emailOverview.branches).length > 1">
                              <v-btn small icon v-if="extraEmailInformation == false" @click="extraEmailInformation = true"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-down</v-icon></v-btn>
                              <v-btn small icon v-if="extraEmailInformation == true" @click="extraEmailInformation = false"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-up</v-icon></v-btn>
                            </div>
                          </v-col>
                        </v-row>

                        <v-card v-if="extraEmailInformation && Object.keys(emailOverview.branches).length > 1" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                          <v-row class="ma-0 pa-0" v-for="(value,name) in emailOverview.branches" :key="name">
                            <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                            <v-col md="7" class="ma-0 py-2 pa-0 text-left" style="font-size: 12px!important"><strong>{{ getBranchName(name) }}</strong></v-col>
                            <v-col md="3" class="ma-0 py-2 pa-0 text-right" style="font-size: 12px!important">{{ value.emailsSend }} </v-col>
                            <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          </v-row>
                        </v-card>

                        <v-row class="ma-0 pa-0">
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          <v-col md="7" class="ma-0 py-2 pa-0 text-left"><strong>{{ $t('emails.openRate', $store.state.locale) }}</strong></v-col>
                          <v-col md="3" class="ma-0 py-2 pa-0 text-right"> {{ emailOverview.openingPercentage }}</v-col>
                          <v-col md="1" class="ma-0 pt-1 pa-0 text-right">
                            <div v-if="Object.keys(emailOverview.branches).length > 1">
                              <v-btn small icon v-if="extraEmailOpeningInformation == false" @click="extraEmailOpeningInformation = true"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-down</v-icon></v-btn>
                              <v-btn small icon v-if="extraEmailOpeningInformation == true" @click="extraEmailOpeningInformation = false"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-up</v-icon></v-btn>
                            </div>
                          </v-col>
                        </v-row>

                        <v-card v-if="extraEmailOpeningInformation && Object.keys(emailOverview.branches).length > 1" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                          <v-row class="ma-0 pa-0" v-for="(value,name) in emailOverview.branches" :key="name">
                            <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                            <v-col md="7" class="ma-0 py-2 pa-0 text-left" style="font-size: 12px!important"><strong>{{ getBranchName(name) }}</strong></v-col>
                            <v-col md="3" class="ma-0 py-2 pa-0 text-right">
                              <span v-if="value.emailsSend" style="font-size: 12px!important">
                                {{ ((value.emailsOpened / value.emailsSend)* 100).toFixed(0) + "%" }}
                              </span>
                              <span v-else style="font-size: 12px!important">0%</span>
                            </v-col>
                            <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          </v-row>
                        </v-card>
      
                        <v-row class="ma-0 pa-0">
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          <v-col md="7" class="ma-0 py-2 pa-0 text-left"><strong>{{ $t('emails.campagnes', $store.state.locale) }}</strong></v-col>
                          <v-col md="3" class="ma-0 py-2 pa-0 text-right"> {{ emailOverview.campagnes }} </v-col>
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0">
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          <v-col md="7" class="ma-0 py-2 pa-0 text-left"><strong>{{ $t('emails.numberOfPromotions', $store.state.locale) }}</strong></v-col>
                          <v-col md="3" class="ma-0 py-2 pa-0 text-right "> {{ emailOverview.promotionEmailsSend }}</v-col>
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0">
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          <v-col md="7" class="ma-0 py-2 pa-0 text-left"><strong>{{ $t('emails.costs', $store.state.locale) }}</strong></v-col>
                          <v-col md="3" class="ma-0 py-2 pa-0 text-right"> {{ emailOverview.cost }}</v-col>
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                        </v-row>

                      </v-card>
                    </v-flex>
                    <v-flex xs6 d-flex>
                      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" class="pa-4" style="width: 100%">
                        <div class="text-center">
                         <v-avatar 
                            size="56"
                            light
                            class="ma-4 custom-avatar-style" 
                            rounded="lg"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                            >
                            <v-icon  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cellphone-text</v-icon>
                          </v-avatar>
                          <h3 class="mb-4">{{ $t('emails.textMessage', $store.state.locale) }}</h3>
                        </div>
                        <v-row class="ma-0 pa-0">
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          <v-col md="7" class="ma-0 py-2 pa-0 text-left"><strong>{{ $t('emails.numberOfSendMessages', $store.state.locale) }}</strong></v-col>
                          <v-col md="3" class="ma-0 py-2 pa-0 text-right"> {{ textOverview.textsSend }}</v-col>
                          <v-col md="1" class="ma-0 pt-1 pa-0 text-right">
                            <div v-if="Object.keys(textOverview.branches).length > 1">
                              <v-btn small icon v-if="extraTextInformation == false" @click="extraTextInformation = true"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-down</v-icon></v-btn>
                              <v-btn small icon v-if="extraTextInformation == true" @click="extraTextInformation = false"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-up</v-icon></v-btn>
                            </div>
                          </v-col>
                        </v-row>

                        <v-card v-if="extraTextInformation && Object.keys(textOverview.branches).length > 1" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                          <v-row class="ma-0 pa-0" v-for="(value,name) in textOverview.branches" :key="name">
                            <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                            <v-col md="7" class="ma-0 py-2 pa-0 text-left" style="font-size: 12px!important"><strong>{{ getBranchName(name) }}</strong></v-col>
                            <v-col md="3" class="ma-0 py-2 pa-0 text-right" style="font-size: 12px!important">{{ value.textsSend }} </v-col>
                            <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          </v-row>
                        </v-card>

                        <v-row class="ma-0 pa-0">
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          <v-col md="7" class="ma-0 py-2 pa-0 text-left"><strong>{{ $t('emails.campagnes', $store.state.locale) }}</strong></v-col>
                          <v-col md="3" class="ma-0 py-2 pa-0 text-right"> {{ textOverview.campagnes }} </v-col>
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0">
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          <v-col md="7" class="ma-0 py-2 pa-0 text-left"><strong>{{ $t('emails.numberOfPromotions', $store.state.locale) }}</strong></v-col>
                          <v-col md="3" class="ma-0 py-2 pa-0 text-right "> {{ textOverview.promotionTextsSend }}</v-col>
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0">
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                          <v-col md="7" class="ma-0 py-2 pa-0 text-left"><strong>{{ $t('emails.costs', $store.state.locale) }}</strong></v-col>
                          <v-col md="3" class="ma-0 py-2 pa-0 text-right"> {{ textOverview.cost }}</v-col>
                          <v-col md="1" class="ma-0 py-2 pa-0 text-right"></v-col>
                        </v-row>

                      </v-card>
                      
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>
            </div>

          </v-tab-item>
          <v-tab-item value="messages" :transition="tabTransition" :reverse-transition="tabTransition">
            <div v-if="messagesLoading">
              <div style="position: relative; padding: 200px 0">
                <div class="thrive-loader"></div>
              </div>
            </div>
            <div v-else>
              <div v-if="messages.length == 0" class="text-center" style="padding: 150px;">
                <v-avatar 
                  size="76"
                  light
                  class="ma-4 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email-fast</v-icon>
                </v-avatar>
                
                <div style="font-size: 18px;">{{ $t('emails.noSendMessages', $store.state.locale) }}</div>
              </div>
              <v-data-table
                v-else
                :headers="headers"
                :items="messages"
                :items-per-page="pages"
                item-key="id"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                hide-default-footer
                :no-results-text="`Geen resultaten gevonden voor: ${search}`"
                :style="{'background-color' : $themes[$store.state.companyTheme][$store.state.themeModus].card}"
                class="dense-table"
                >
                <template v-slot:item="{ item }">
                  <tr>
                    <td><v-icon class="py-1" :color="status(item).color" small>mdi-checkbox-blank-circle</v-icon></td>
                    <td>{{ item.clientName }}</td>
                    <td>{{ getMessageType(item.messageType) }}</td>
                    <td>{{ getMessageContentType(item.messageContentType) }} </td>
                    <td>{{ getBranchName(item.branchId) }} </td>
                    <td>{{ item.sendDate | moment("D MMMM - H:mm")}}u</td>
                    <td>{{ status(item).text[$store.state.locale.toLowerCase()] }} </td>
                    <td><span v-if="item.opens_count > 0">{{ $t('general.yes', $store.state.locale) }}</span></td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

  </div>
</template>
 
<script>
import '@firebase/firestore';
import db from "../firebase/init";
import { bus } from "../main";
import EmailEditor from "./EmailEditor.vue";
import SmsEditor from "./SmsEditor.vue";
import EmailPreview from "./EmailPreview.vue";
import periodSelector from "../components/elements/periodSelector.vue";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite-min.css";

import moment from "moment";

export default {
  name: "MessagesHistory",
  components: {
    "email-editor": EmailEditor,
    "email-preview": EmailPreview,
    "sms-editor": SmsEditor,
    "period-select": periodSelector,
  },
  data(){
    return{  
      changes: 0,    
      algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
      messages: new Array(),
      selectedBranch: null,
      branches: new Array(),
      activeTab: 'dashboard',
      sortBy: "sendDate",
      historyTab: 'dashboard',
      sortDesc: true,
      tabTransition: false,
      extraEmailInformation: false,
      extraTextInformation: false,
      extraEmailOpeningInformation: false,
      emailOverview: {
        emailsSend: 0,
        emailsOpened: 0,
        openingPercentage: "0%",
        campagnes: 0,
        promotionEmailsSend: 0,
        branches: null,
        cost: "-",
      },
      textOverview: {
        textsSend: 0,
        campagnes: 0,
        promotionTextsSend: 0,
        branches: null,
        cost: "-",
      },
      headers: [
        {
          text: "",
          value: "status",
          width: '30px',
          sortable: false
        },
        {
          text: this.$t('emails.client', this.$store.state.locale),
          value: "clientId",
          sortable: false,
          width: "140px"
        },
        {
          text: this.$t('general.type', this.$store.state.locale),
          value: "messageType",
          sortable: false,
           width: "80px"
        },
        {
          text: this.$t('general.type', this.$store.state.locale),
          value: "messageContentType",
          sortable: false
        },
        {
          text: this.$t('general.branch', this.$store.state.locale),
          value: "branch",
          sortable: false,
          width: "200px"
        },
        {
          text: this.$t('emails.send', this.$store.state.locale),
          value: "sendDate",
          sortable: false,
          width: "200px"
        },
        {
          text: this.$t('general.status', this.$store.state.locale),
          value: "arrived",
          sortable: false,
          width: "140px"
        },
        {
          text: this.$t('emails.opened', this.$store.state.locale),
          value: "opened",
          sortable: false,
          width: "100px"
        }
      ],
      search: "",
      messagesLoading: true,

      pages: -1,
      messageTypes: [
        {
          name: {
            nl: 'E-mail',
            en: 'Email'
          },
          id: 'email'
        },
        {
          name: {
            nl: 'SMS',
            en: 'Textmessage'
          },
          id: 'text'
        }
      ],
      defaultMessages: [
        {
          name: {
            nl: 'Afspraakbevestiging',
            en: 'Appointment Confirmation'
          },
          type: "appointmentConfirm",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Afspraak annulering (geen aanbetaling ontvangen)',
            en: 'Appointment cancelation (no deposit received)'
          },
          type: "appointmentCancelNoDownPayment",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Bon',
            en: 'Receipt'
          },
          type: "receipt",
          messageTypes: {
            email: true,
            text:  false
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Aanbetaling herinnering',
            en: 'Desposit reminder'
          },
          type: "downPaymentReminder",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Verzoek om aanbetaling als bevestiging',
            en: 'Request for deposit as confirmation'
          },
          type: "appointmentConfirmDownPayment",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Afspraakherinnering',
            en: 'Appointment reminder'
          },
          type: "appointmentReminder",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Afspraak wijziging',
            en: 'Appointment changes'
          },
          type: "appointmentChanges",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Afspraak annulering',
            en: 'Appointment cancelation'
          },
          type: "appointmentCancel",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Inschrijfbevestiging',
            en: 'Subscription Confirmation'
          },
          type: "eventConfirm",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Herinnering inschrijving',
            en: 'Subscription reminder'
          },
          type: "eventReminder",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Inschrijving wijziging',
            en: 'Event changes'
          },
          type: "eventChanges",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
        {
          name: {
            nl: 'Inschrijving annulering of uitschrijving',
            en: 'Subscription cancelation or unsubscription'
          },
          type: "eventCancel",
          messageTypes: {
            email: true,
            text:  true
          },
          messages: {
            email: null,
            text:  null
          }
        },
      ], 
      messageDataFilter: {
        type: "week",
        year: moment().year(),
        number: moment().isoWeek()
      },

    }
  },

  created() {
    this.loadData();
    bus.$on("loadMessageHistory", () => {
      this.loadData();
      
		});
   
  },

  methods: {
    loadData(){
      this.selectedBranch = _.cloneDeep(this.$store.state.activeBranch.id);
      this.branches = _.cloneDeep(this.$store.state.activeUserBranches)
      this.changes++
      this.getBranchMessages();
    },
    updateDataPeriodRefresh(filter){
      this.messageDataFilter.type = filter.type;
      this.messageDataFilter.year = filter.year;
      this.messageDataFilter.number = filter.number;
      this.getBranchMessages();
    },

    getMessageType(id){
      let messageInfo = this.messageTypes.find(message => message.id == id);
      if(messageInfo){
        return messageInfo.name[(this.$store.state.locale).toLowerCase()]
      }
      else{
        return null
      }
    },

    getMessageContentType(id){
      let messageInfo = this.defaultMessages.find(message => message.type == id);
      if(messageInfo){
        return messageInfo.name[(this.$store.state.locale).toLowerCase()]
      }
      else{
        return null
      }
    },

    getBranchName(id){
      let branchData = this.$store.state.activeUserBranches.find(branch => branch.id == id);
      if(branchData){
        return branchData.name
      }
      else{
        return null
      }
    },

    status(item){
      if(item.status){
        if(item.status == 'send'){
          return {
            color: this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].quaternary, 
            text: {
              nl: 'Verzonden',
              en: 'Send'
            }
          }
        }
        else if(item.status == 'delivered'){
          return {
            color: this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].quaternary, 
            text: {
              nl: 'Aangekomen',
              en: 'Delivered'
            }
          }
        }
        else if(item.status == 'not_delivered'){
          return {
            color: this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary, 
            text: {
              nl: 'Niet aangekomen',
              en: 'Not delivered'
            }
          }
        }
        else{
          return {
            color: this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary, 
            text: item.status
          }
        }
      }
      else{
        return {
          color: this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].quaternary, 
          text: 'Verzonden'
        }
      }
    },

    async getBranchMessages(){
      let startDate = moment().startOf('day').format();
      let endDate = moment().endOf('day').format();

      if(this.messageDataFilter.type == 'week'){
        startDate = moment().year(this.messageDataFilter.year).isoWeek(this.messageDataFilter.number).startOf('week').format("YYYY-MM-DD");
        endDate = moment().year(this.messageDataFilter.year).isoWeek(this.messageDataFilter.number).endOf('week').format("YYYY-MM-DD");
      }
      if(this.messageDataFilter.type == 'fourWeeks'){
        startDate = moment().year(this.messageDataFilter.year).isoWeek((this.messageDataFilter.number * 4)-3).startOf('week').format("YYYY-MM-DD");
        endDate = moment().year(this.messageDataFilter.year).isoWeek(this.messageDataFilter.number * 4).endOf('week').format("YYYY-MM-DD");
      }
      if(this.messageDataFilter.type == 'month'){
        startDate = moment(`${this.messageDataFilter.year}-${this.messageDataFilter.number}-1`, "YYYY-M-D").startOf('month').format("YYYY-MM-DD");
        endDate = moment(`${this.messageDataFilter.year}-${this.messageDataFilter.number}-1`, "YYYY-M-D").endOf('month').format("YYYY-MM-DD");
      }
      if(this.messageDataFilter.type == 'quarter'){
        startDate = moment().year(this.messageDataFilter.year).quarter(this.messageDataFilter.number).startOf('quarter').format("YYYY-MM-DD");
        endDate = moment().year(this.messageDataFilter.year).quarter(this.messageDataFilter.number).endOf('quarter').format("YYYY-MM-DD");
      }
      if(this.messageDataFilter.type == 'year'){
        startDate = moment().year(this.messageDataFilter.year).startOf('year').format("YYYY-MM-DD");
        endDate = moment().year(this.messageDataFilter.year).endOf('year').format("YYYY-MM-DD");
      } 

      // console.log("startDate: ", startDate);
      // console.log("endDate: ", endDate);
      
      this.activeTab = 'messages';
      this.messagesLoading = true;
      this.messages = new Array();
      
      await db.collection('sendMessages')
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("branchId", "==", this.selectedBranch)
      .where("sendDate", ">=", startDate)
      .where("sendDate", "<=", endDate)
      .orderBy("sendDate", "desc")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let message = doc.data();
          message.id = doc.id;
          message.clientName = doc.id
          this.messages.push(message);

          // let index =  this.algoSearch.initIndex("thrive_clients");
          // var FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
          // index.search(doc.data().clientId,{ // Get client Data from Algolia
          //   facetFilters: [FacetFilterString],
          //   attributesToRetrieve: ['name', 'surname'], // Get only this fields
          //   restrictSearchableAttributes: ['objectID'] // Search only on this fields
          // })
          // .then(({ hits }) => {
          //   message.clientName = hits && hits.length > 0 ? `${hits[0].name} ${hits[0].surname}` : null;
          //   this.messages.push(message);
          // })
          // .catch((error)=>{
          //   console.error("algolia error: ", error)
          //   message.clientName = "Unknown";
          //   this.messages.push(message);
          // })
        });
      })
      await this.createMessageSendOverview()
      this.messagesLoading = false;
    },

    async createMessageSendOverview(){
      this.emailOverview = {
        emailsSend: 0,
        emailsOpened: 0,
        openingPercentage: "0%",
        campagnes: 0,
        promotionEmailsSend: 0,
        branches: new Object(),
        cost: "-",
      };
      this.textOverview = {
        textsSend: 0,
        campagnes: 0,
        promotionTextsSend: 0,
        branches: new Object(),
        cost: "-",
      };

      if(this.messages.length > 0){
        let clientIndex =  this.algoSearch.initIndex("thrive_clients");
        new Promise((resolve)=>{
          let counter = 0
          this.messages.forEach(message => {
            
             clientIndex.search(message.clientId,{ // Get client Data from Algolia
              attributesToRetrieve: ['name', 'surname'], // Get only this fields
              restrictSearchableAttributes: ['objectID'] // Search only on this fields
            })
            .then(({ hits }) => {
              message.clientName = hits && hits.length > 0 ? `${hits[0].name} ${hits[0].surname}` : null;
            })

            if(message.messageType == 'email'){
              this.emailOverview.emailsSend++
              if(!this.emailOverview.branches[message.branchId]){
                this.emailOverview.branches[message.branchId] = {
                  emailsSend: 1,
                  emailsOpened: 0
                };
              }
              else{
                this.emailOverview.branches[message.branchId].emailsSend++
              }
              if(message.opens_count > 0){
                this.emailOverview.emailsOpened++
                this.emailOverview.branches[message.branchId].emailsOpened++
              }
              if(message.campaignType != 'transactional'){
                this.emailOverview.promotionEmailsSend++
              }
            }
            if(message.messageType == 'text'){
              this.textOverview.textsSend++
              this.textOverview.cost = "€" + (this.textOverview.textsSend * 0.1495).toFixed(2)
              if(!this.textOverview.branches[message.branchId]){
                this.textOverview.branches[message.branchId] = {
                  textsSend: 1,
                  cost: "€" + (1 * 0.1495).toFixed(2)
                };
              }
              else{
                this.textOverview.branches[message.branchId].textsSend++
                this.textOverview.branches[message.branchId].cost = "€" + (this.textOverview.branches[message.branchId].textsSend * 0.1495).toFixed(2)
              }
              if(message.campaignType != 'transactional'){
                this.textOverview.promotionTextsSend++
              }
            }
            counter++
            if(counter == this.messages.length){
              resolve()
            }
          });
        })
      }
      if(this.emailOverview.emailsOpened ){
        this.emailOverview.openingPercentage = ((this.emailOverview.emailsOpened / this.emailOverview.emailsSend)* 100).toFixed(0) + "%"
      }    
      return
     },
  },
};
</script>
