import firebase from "firebase/app";
import "firebase/firestore";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBtuz1rpKgKC9Zc2OCiuFXeiKvkzf9LUCI",
  authDomain: "thrive-365.firebaseapp.com",
  projectId: "thrive-365",
  storageBucket: "thrive-365.appspot.com",
  messagingSenderId: "927259966587",
  appId: "1:927259966587:web:2ee30df25b14c6e651f6de",
  measurementId: "G-JCBW6LJX4K"
};

const firebaseApp = firebase.initializeApp(config);
firebaseApp.firestore().settings({});
export default firebaseApp.firestore();
