
<template>
  <div>
    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>
   
    <v-container grid-list-md class=" ma-0 pa-0 pr-3" fluid v-if="!loading && menuItems.length > 0">
      <v-layout row wrap class="pa-0 ma-0">
        <v-flex xs6>
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-4" :class="$store.state.companyTheme">
            <v-select
              class="mb-4"
              hide-details 
              outlined
              dense
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :item-text="(item) => item.languageName[$store.state.locale.toLowerCase()]"
              :label="$t('general.language', $store.state.locale)"
              v-model="selectedMenuLanguage"
              :items="availableLanguages"
              item-value="id"
              required
            ></v-select>

            <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" class="pa-4">
              <v-divider></v-divider>
              <draggable v-model="menuItems" group="people" @start="drag=true" @end="drag=false" @change="menuChanges()">
                <div v-for="(item, index) in menuItems" :key="index">
                  <div class="create-menuitem-wrapper" style="border-left: 1px solid rgba(0, 0, 0, 0.12); border-right: 1px solid rgba(0, 0, 0, 0.12)">
                    <div class="create-menuitem-drag">
                      <v-btn class="mt-3 ml-2" icon>
                        <v-icon class="py-4 px-1 handle">mdi-drag</v-icon>
                      </v-btn> 
                    </div>
                    <div class="create-menuitem-item">
                      <div class="create-menuitem-item-icon">
                        <v-icon class="py-4 px-1" v-if="item.icon">{{ item.icon}}</v-icon>
                      </div>
                      <div class="create-menuitem-item-text">
                        <h5 class="ma-0 create-menuitem-name" :class="item.type" >{{ item.name[selectedMenuLanguage]}}</h5>
                        <p style="font-size: 12px;" class="ma-0">{{ item.description[selectedMenuLanguage]}}</p>
                      </div>
                      <div class="create-menuitem-item-icon">
                        <v-icon v-if="item.packageLimit" class="mt-5 px-2" small>mdi-eye</v-icon>
                      </div>
                      <div class="create-menuitem-item-icon">
                        <v-icon v-if="item.permissionLimit" class="mt-5 px-2" small>mdi-account-lock</v-icon>
                      </div>
                      <div class="create-menuitem-item-icon">
                        <v-icon class="mt-5 px-2" :color="activeColor(item.active)" small>mdi-checkbox-blank-circle</v-icon>
                      </div>
                      <div class="create-menuitem-item-icon">
                        <v-btn class="mt-3 px-2" icon>
                          <v-icon small @click="editMenuItem(item)">mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                      <div class="create-menuitem-item-icon">
                        <v-btn class="mt-3 px-2" icon>
                          <v-icon small @click="deleteMenuModal = true; selectedMenuItem = item;">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <v-divider v-if="item.type == 'group'"></v-divider>
                  <v-card flat v-if="item.type == 'group'" class="pa-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" >
                    <v-card v-if="item.submenus && item.submenus.length > 0" outlined >
                      <draggable v-model="item.submenus" group="people" @start="drag=true" @end="drag=false" @change="menuChanges()">
                        <div v-for="(subitem, i) in item.submenus" :key="i">
                          <div class="create-menuitem-wrapper">
                            <div class="create-menuitem-drag">
                              <v-btn class="mt-3 ml-2" icon>
                                <v-icon class="py-4 px-1 handle">mdi-drag</v-icon>
                              </v-btn> 
                            </div>
                            <div class="create-menuitem-item">
                              <div class="create-menuitem-item-icon">
                                <v-icon class="py-4 px-1" v-if="subitem.icon">{{ subitem.icon}}</v-icon>
                              </div>
                              <div class="create-menuitem-item-text">
                                <h5 class="ma-0 create-menuitem-name">{{ subitem.name[selectedMenuLanguage]}}</h5>
                              </div>
                              <div class="create-menuitem-item-icon">
                                <v-icon v-if="subitem.packageLimit" class="mt-5 px-2" small>mdi-eye</v-icon>
                              </div>
                              <div class="create-menuitem-item-icon">
                                <v-icon v-if="subitem.permissionLimit" class="mt-5 px-2" small>mdi-account-lock</v-icon>
                              </div>
                              <div class="create-menuitem-item-icon">
                                <v-icon class="mt-5 px-2" :color="activeColor(subitem.active)" small>mdi-checkbox-blank-circle</v-icon>
                              </div>
                              <div class="create-menuitem-item-icon">
                                <v-btn class="mt-3 px-2" icon>
                                  <v-icon small @click="editMenuItem(subitem)">mdi-pencil</v-icon>
                                </v-btn>
                              </div>
                              <div class="create-menuitem-item-icon">
                                <v-btn class="mt-3 px-2" icon>
                                  <v-icon small @click="deleteMenuModal = true; selectedMenuItem = subitem;">mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </div>
                          <v-divider v-if="i < item.submenus.length - 1"></v-divider>
                        </div>
                      </draggable>
                    </v-card>
                    <div class="text-center pa-3">
                      <v-btn small dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addNewMenu(item.id)">
                        {{ $t('admin.newSubmenuItem', $store.state.locale) }}
                      </v-btn>
                    </div>
                  </v-card>
                  
                  <v-divider></v-divider>
                </div>
              </draggable>

                <div class="text-center pa-3"> 
                  <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addNewMenu()">
                    {{ $t('admin.newMenuItem', $store.state.locale) }}
                  </v-btn>
                </div>
              </v-card>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <div class="no-data-message" v-if="!loading && menuItems.length == 0">
      <v-avatar
        size="58"
        light
        class="ma-0 mr-2 custom-avatar-style" 
        rounded="lg"
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
        >
        <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-menu</v-icon>
      </v-avatar>
      <p>{{ $t('admin.noMenuItemsFound', $store.state.locale) }}</p>
      <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addNewMenu()">
        <v-icon left>mdi-plus</v-icon>{{ $t('admin.newMenuItem', $store.state.locale) }} 
      </v-btn>
    </div>


    <!-- / Menu Item Dialog \ -->
    <menu-item @updateData="getMenuItems('change')" :packageItems="packageItems" :userRoles="userRoles"></menu-item>
    <!-- \ Menu Item Dialog / -->

    <!-- / Delete Action Dialog \ -->
    <v-dialog v-model="deleteMenuModal" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <p class="ma-0">{{ $t('general.remove', $store.state.locale) }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="deletingMenuitem" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="deleteMenuModal = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="deletingMenuitem" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleteMenuItem()">
                  {{ $t('general.remove', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Action Dialog / -->

  </div>
</template>

<script>
  import _ from 'lodash'
  import db from "../firebase/init";
  import { bus } from "../main";
  import draggable from "vuedraggable";
  import MenuItem from "../components/modals/MenuItemForm";

  export default {
    name:"menu-items",

    components:{
      "menu-item" : MenuItem,
      draggable,
    },

    data(){
      return{
        loading: true,
        deletingMenuitem: false,
        menuItems: new Array(),
        submenuItems: new Array(),
        parent: null,
        deleteMenuModal: false,
        selectedMenuItem: null,
        menuItemType: "single",
        selectedMenuLanguage: null,
        packageItems: new Array(),
        userRoles: [
          {
            name: "1. Developer",
            id: "developer",
            number: 0
          },
          {
            name: "2. Super Admin en hoger",
            id: "superadmin",
            number: 1
          },
          {
            name: "3. Admin en hoger",
            id: "admin",
            number: 2
          },
          {
            name: "4. Manager en hoger",
            id: "manager",
            number: 3
          },
          {
            name: "5. Vestiging en hoger",
            id: "branch",
            number: 4
          },
          {
            name: "6. Medewerkers en hoger",
            id: "employee",
            number: 5
          },
        ]
      }
    },

    mounted(){
      this.getMenuItems();
      this.getPackages(); 
      this.selectedMenuLanguage = this.$store.state.locale.toLowerCase(); // Set language to user default language
    },

    methods:{
      addNewMenu(id){
        let type = 'single';
        let parent = id ? id : null; 
        bus.$emit("menuItem", null, type, parent, 'new' );
      },

      editMenuItem(item){
        bus.$emit("menuItem", item, null, null, 'edit' );
      },

      deleteMenuItem(){
        this.deletingMenuitem = true;
        db.collection("menuItems").doc(this.selectedMenuItem.id).delete()
        .then(()=>{
          this.deletingMenuitem = false;
          this.getMenuItems();
          this.deleteMenuModal = false;
        })
        .catch(error=>{
          console.error("Error deleting menu item:", error)
          this.deletingMenuitem = false;
          this.deleteMenuModal = false;
        })
      },

      activeColor(active){ // Set color green for active or red for inactive
        return active ? "#81C784" : "#EF5350"; 
      },

      getMenuItems(type){
        this.loading = true;
        this.menuItems = new Array();
        this.submenuItems = new Array();

        db.collection('menuItems')
        .get()
        .then(snap=>{
          snap.forEach(doc=>{
            let menuItem = doc.data()
            menuItem.id = doc.id; 
            if(menuItem.parent){
              this.submenuItems.push(menuItem);
            }
            else{
              if(menuItem.type == 'group'){
                menuItem.submenus = new Array();
              }
              this.menuItems.push(menuItem);
            }
          })
        })
        .then(()=>{
          if(this.menuItems.length > 0){
            this.menuItems = _.orderBy(this.menuItems, ['weight','desc']);
            this.sortMenu();
          }
          else{
            this.loading = false
          }  
        })
        .catch(err=>{
          this.loading = false
          console.error("Error getting menu items:", err);
        });
        if(type == "change"){
          bus.$emit("reloadMenu");
        } 
      },

      async sortMenu(){
        await new Promise((resolve)=>{
          let counter = 0;
          this.menuItems.forEach(item=>{
            if(item.type == 'group'){
              this.submenuItems.forEach(subItem=>{
                if(subItem.parent == item.id){
                  item.submenus.push(subItem)
                  item.submenus = _.orderBy(item.submenus, ['weight','desc']);
                }
              })
            }
            counter++
            if(counter == this.menuItems.length){
              resolve()
            }
          })
        })
        this.loading = false;
      },

      menuChanges(){
        this.menuItems.forEach((item, index)=>{
          db.collection("menuItems").doc(item.id).set(
            { 
              weight: index,
              parent: null
            },{ merge: true }
          );
          if(item.submenus && item.submenus.length > 1){
            item.submenus.forEach((subItem, i)=>{
              console.log(subItem.name.nl, subItem.weight, i)
              db.collection("menuItems").doc(subItem.id).set(
                { 
                  parent: item.id,
                  weight: i
                },{ merge: true }
              );
            })
          }
        })
        bus.$emit("reloadMenu");
      },

      getPackages(){
        this.packageItems = new Array();
        db.collection('packages')
        .get()
        .then(snap=>{
          snap.forEach(doc=>{
            let packageItem = doc.data()
            packageItem.id = doc.id;
            this.packageItems.push(packageItem);
          })
        })
        .catch(err=>{
          console.error("Error getting packages:", err);
        });
      }
    },

    computed: {
      selectedLanguageObject(){
        return this.selectedMenuLanguage ? this.$countries.find(language => { return language.id == this.selectedMenuLanguage }) : {languageName:new Object()};
      },
      availableLanguages(){
        return this.$countries.filter(language => { // Filter rich languages data from user languages
          if(language.userLanguage){return language.id }
        });
      }
    }
  };
</script>

<style>
  .create-menuitem-wrapper{
    width: 100%;
    background-color: white;
  }

  .create-menuitem-wrapper:after,
  .create-menuitem-item:after{
    display: block;
    clear: both;
    content: ""
  }

  .create-menuitem-drag{
    width: 44px;
    float: left;
  }
  .create-menuitem-item{
    width: calc(100% - 44px);
    float: left;
  }

  .create-menuitem-item-icon{ 
    width: 40px;
    float: left;
    min-height: 10px;
  }

  .create-menuitem-item-text{
     width: calc(100% - 240px);
     float: left;
    min-height: 10px;
  }
  .create-menuitem-name{
    padding: 16px 0 0 0;
  }

  .create-menuitem-name.group{
    padding: 4px 0 0 0;
  }          
</style>
