import { createWidgetMixin } from 'vue-instantsearch';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares/';
import aa from 'search-insights';

aa("init", {
    appId: "B4XU2Z5BAW",
    apiKey: "a29d0e9160e36dc8ee83d5888c28cdcc",
});

export default {
  mixins: [createWidgetMixin({ connector: true })],
  created() {
    const insightsMiddleware = createInsightsMiddleware({
      // customise here if needed
      insightsClient: aa,
    });

    console.log("aa: ", aa)
    console.log("window.aa: ", window.aa)
    this.instantSearchInstance.use(insightsMiddleware);
  },
  render() {
    return null;
  },
};
