<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type == 'new'">{{ $t('admin.addNewVatRate', $store.state.locale) }}</h3>
        <h3 v-if="type == 'edit'">{{ $t('admin.editVatRate', $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeVatDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="EditVatForm" lazy-validation>
          <v-layout row wrap>
            <v-flex md12>
              <!-- / Select imput language \ -->
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="pa-2 mb-4">
                <v-layout row wrap class="ma-0 pa-0">
                  <v-flex xs12> 
                    <v-avatar
                      size="54"
                      light
                      style="float: left"
                      class="ma-0" 
                      rounded="lg"
                      color="#ffffff" 
                      >
                      <country-flag class="ma-0 pa-0" :country="getFlagCode(selectedLanguage)" />
                    </v-avatar>
                    <v-select
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :disabled="availableLanguages.length == 1"
                      :items="availableLanguages"
                      :item-text="(item) => item.languageName[$store.state.locale.toLowerCase()]"
                      :label="$t('general.language', $store.state.locale)"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      class="mt-2 pl-2"
                      v-model="selectedLanguage"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-card>
              <!-- \ Select imput language / -->
            </v-flex>
            <v-flex md12>
              <v-text-field
                class="mt-4"
                :label="$t('general.name', $store.state.locale) + ` (${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
                v-model="vat.name[selectedLanguage]"
                autocomplete
                outlined
                required
                dense
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                >
              </v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field
                :label="$t('admin.percentage', $store.state.locale)"
                v-model.number="vat.percentage"
                autocomplete
                dense
                suffix="%"
                required
                style="padding-right: 20px;"
                outlined
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"            
                class="mt-4"
              ></v-text-field>
            </v-flex>
            <v-flex md8>
              <v-select
                :label="$t('admin.selectCountry', $store.state.locale)"
                :items="$countries"
                :item-text="item => item.countryName[($store.state.locale).toLowerCase()]"
                item-value="countryCode"
                v-model="vat.country"
                outlined
                dense
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                class="mt-4"
              ></v-select>
            </v-flex>
            <v-flex md12>
              <v-text-field
                :label="$t('admin.vatDescription', $store.state.locale) + ` (${selectedLanguageObject.languageName[$store.state.locale.toLowerCase()]})`"
                v-model="vat.description[selectedLanguage]"
                autocomplete
                required
                dense
                :hint="$t('admin.vatDescriptionHint', $store.state.locale)"
                persistent-hint
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                class="mt-4"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeVatDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type == 'new'"
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addVat()"
                >
                {{ $t('admin.addVatRate', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn
                v-if="type == 'edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateVat()"
                >
                <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right>mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
  import db from "../../firebase/init";
  import '@firebase/firestore';
  import { bus } from "../../main";

  export default {
    name: "vat-form",
    data() {
      return {
        selectedLanguage: null,
        feedback: null,
        showDialog: false,
        type: 'new',
        vat: {
          name: new Object(),
          percentage: null,
          country: null,
          description: new Object()
        },
        vatOriginal: new Object()
      };
    },
    created(){
      this.selectedLanguage = this.$store.state.locale.toLowerCase(); // Set language to user default language
      bus.$on("vatModal", (vat, type) => {
        this.type = type;
        if(type == "edit"){
          this.vat = _.cloneDeep(vat);
          this.vatOriginal = _.cloneDeep(this.vat);
        }
        else{
          this.resetVat();
        }
        this.showDialog = true;
      });
    },

    methods: {
      closeVatDialog() {
        this.showDialog = false;
        this.resetVat();
      },

      resetVat(){
        this.vat = {
          name: new Object(),
          percentage: null,
          country: null,
          description: new Object()
        };
        this.feedback = null;
      },

      async updateVat(){
        this.feedback = null;
        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "vatEditted",
          actionItem: this.vatOriginal.name,
          extra:[
            {
              name: "originalData",
              value: ""
            },
            {
              name: "name",
              value: this.vatOriginal.name
            },
            {
              name: "percentage",
              value: this.vatOriginal.percentage
            },
            {
              name: "country",
              value: this.vatOriginal.country
            },
            {
              name: "description",
              value: this.vatOriginal.description
            },
            {
              name: "changedData",
              value: ""
            },
            {
              name: "name",
              value: this.vat.name
            },
            {
              name: "percentage",
              value: this.vat.percentage
            },
            {
              name: "country",
              value: this.vat.country
            },
            {
              name: "description",
              value: this.vat.description
            },
          ]
        }
        
        if (this.vat.name && this.vat.percentage >= 0 && this.vat.country) {
          try {
            await db.collection("vat")
            .doc(this.vat.id)
            .update({
              name: this.vat.name,
              percentage: Number(this.vat.percentage),
              country: this.vat.country,
              description: this.vat.description
            })

            await this.$createNewLog("info", logData);
            this.$emit("updateData");
            this.closeVatDialog();
          }
          catch (error) {
            this.feedback = this.$t("general.addError", this.$store.state.locale) ;
            console.error("Error updating VAT-item: ", error);
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
          }
        }
        else {
          this.feedback = this.$t("admin.saveVatRateIncomplete", this.$store.state.locale) ;
        }
      },

      async addVat() {
        this.feedback = null;
        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "vatAdded",
          actionItem: this.vat.name,
          extra:[
            {
              name: "name",
              value: this.vat.name
            },
            {
              name: "percentage",
              value: this.vat.percentage
            },
            {
              name: "country",
              value: this.vat.country
            },
            {
              name: "description",
              value: this.vat.description
            },
          ]
        }
        if (this.vat.name && this.vat.percentage >= 0 && this.vat.country) {
          try {
            await db.collection("vat")
            .add({
              name: this.vat.name,
              percentage: Number(this.vat.percentage),
              country: this.vat.country,
              description: this.vat.description
            })
            await this.$createNewLog("info", logData); 
            this.$emit("updateData");
            this.closeVatDialog();
          }
          catch (error) {
            this.feedback = this.$t("general.addError", this.$store.state.locale) ;
            console.error("Error updating VAT-item: ", error);
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
          }
        }
        else {
          this.feedback = this.$t("admin.saveVatRateIncomplete", this.$store.state.locale) ;
        }
      },

      getFlagCode(selectedLanguage){
        return selectedLanguage ? this.$countries.find(language => { return language.id == selectedLanguage }).flagCode : "";
      }
    },
    computed: {
      selectedLanguageObject(){
        return this.selectedLanguage ? this.$countries.find(language => { return language.id == this.selectedLanguage }) : {languageName:new Object()};
      },

      availableLanguages(){
        return this.$countries.filter(language => { // Filter rich languages data from user languages
          if(language.userLanguage){return language.id }
        });
      },

      changed() {
        return _.isEqual(this.vat, this.vatOriginal) == false ? true : false;
      },
    }
  };
</script>