
<template>
  <div>
    <div class="tab-component-wrapper">

      <v-card v-if="userDataLoaded && dataloaded" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
        <div class="mx-4 mt-4">
          <v-container grid-list-md class="pa-0 pb-2" fluid>
            <v-layout row wrap>
              <v-flex md9 xs12>
                <v-select
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :items="branches"
                  item-text="name"
                  item-value="id"
                  multiple
                  attach
                  chips
                  small-chips
                  dense
                  outlined
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  hide-details
                  v-model="selectedBranches"
                  :label="$t('general.branches', $store.state.locale)"
                  >
                  <template slot="prepend-item">
                    <v-list-item
                      ripple
                      @click="toggleBranches()"
                    >
                      <v-list-item-action>
                        <v-icon :color="selectedBranches.length > 0 ? 'primary' : ''">{{ icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{$t('products.allBranches', $store.state.locale)}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-flex>
              <v-flex md3 xs12 >
                <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!changed" block height="40px" @click="loadSelectedBranchesProducts()">{{ $t('products.seeStock', $store.state.locale) }}</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <div v-if="!noData">
          <v-data-table
            :headers="headers"
            :items="products"
            hide-default-footer
            dense
            class="dense-table"
            :itemsPerPage.sync="pages"
            > 
            <template v-slot:item="{item, isExpanded, expand}">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.content }}</td>
                <td>
                  <v-chip v-for="(category, index) in findProductCategories(item.category)" :key="index" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 my-1 custom-chip-style">{{ category }}</v-chip>
                </td>
                <td>
                  <span v-for="(supplier, index) in productSuppliers" :key="index">
                    <span v-if="supplier.id == item.supplier"> {{supplier.name}}</span>
                  </span>
                </td>
                <td>
                  <span v-if="item.useStock">
                    {{ item.totalStock }}
                    <span v-if="item.minStockAlert">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-icon color="warning" dark small v-on="on" >mdi-alert</v-icon>
                        </template>
                        <span style="color: #fff;">{{$t('products.minimumStockReached', $store.state.locale)}}</span>
                      </v-tooltip>
                    </span>
                  </span>
                  <span v-else>-</span>
                </td>
                <td>
                  <v-btn icon @click="expand(true)" v-if="!isExpanded"><v-icon  dark>mdi-menu-down</v-icon></v-btn>
                  <v-btn icon @click="expand(false)" v-if="isExpanded"><v-icon  dark>mdi-menu-up</v-icon></v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding: 0;" :style="{backgroundColor: $vuetify.theme.themes[$store.state.themeModus].menuborder}">
                <v-container grid-list-xs  class="ma-0 pa-0">
                  <v-layout row wrap style="padding: 10px 15px;">
                    <v-flex md3 >
                      
                    </v-flex>
                    <v-flex md3 >
                      <b>{{$t('products.minStockLabel', $store.state.locale)}}</b>
                    </v-flex>
                    <v-flex md3 >
                      <b>{{$t('products.stock', $store.state.locale)}}</b>
                    </v-flex>
                  </v-layout>
                  <v-layout v-for="(value, key, index) in item.stock" :key="index" row wrap style="border-top: 1px solid rgba(0,0,0, .05); padding: 0 15px;">
                    <v-flex md3 >
                      <span v-for="(branch, index) in branches" :key="index">                   
                        <span style="display: block; padding: 10px 0 0 0 ;" v-if="branch.id == key"> <b>{{branch.name}}</b></span>
                      </span>
                    </v-flex>
                    <v-flex md3 >
                      <span style="display: block; padding: 10px 0 0 0 ;">{{ value.minStockValue }}</span>
                    </v-flex>
                    <v-flex md3 >
                      <span style="display: block; padding: 10px 0 0 0 ;">{{ value.stockValue }} 
                        <span v-if="value.stockValue <= value.minStockValue && value.minStockValue != 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{on}">
                              <v-icon color="warning" dark small v-on="on">mdi-alert</v-icon>
                            </template>
                            <span style="color: #fff;">{{$t('products.minimumStockReached', $store.state.locale)}}</span>
                          </v-tooltip>
                        </span>
                      </span>
                    </v-flex>
                    <v-flex md3 >
                      <v-btn
                        style="float: right;"
                        class="ma-0 pa-0"
                        icon
                        @click="addStockSettings(item, key)"
    
                      >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-plus-circle</v-icon>
                      </v-btn>
                      <v-btn
                        style="float: right;"
                        class="ma-0 pa-0"
                        icon
                        @click="removeStockSettings(item, key)"
                      >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-minus-circle</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </div>

        <div class="no-data-message" v-else>
          <v-avatar
            size="58"
            light
            class="ma-0 mr-2 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-package-variant</v-icon>
          </v-avatar>
          <p> {{ $t('products.noProductsWithStockSettings', $store.state.locale) }}</p>

        </div>

      </v-card>

      <div v-else style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>

      <!-- / Add Stock Dialog \ -->
      <v-dialog v-model="addStockDialog" scrollable max-width="600px" height="700px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
          <v-card-title class="pr-2" :style=" { backgroundColor: $vuetify.theme.themes[$store.state.themeModus].tertiary }">
            <h2 class="white--text">{{ $t('products.addInventoryAt', $store.state.locale) }}
              <span v-for="(branch, index) in branches" :key="index">                   
                <span style="color: #fff;" v-if="branch.id == selectedStockItem.branch">{{branch.name}}</span>
              </span></h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="addStockDialog = false">
              <v-icon dark color="white">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          
          <v-alert v-if="addStockFeedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ addStockFeedback }}</v-alert>

          <v-divider></v-divider>

          <v-card-text v-if="addStockDialog" style="padding: 0;">
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md6>
                  <v-text-field
                    :label="$t('products.addInventory', $store.state.locale)"
                    v-model.number="selectedStockItem.changedStock"
                    mask="######"
                    :suffix="$t('products.pcs', $store.state.locale)"
                    outlined
                    background-color="background"
                  ></v-text-field>
                </v-flex>

                <v-flex md6>
                  <v-text-field
                    readonly
                    :label="$t('products.numberAfterAdd', $store.state.locale)"
                    :value="selectedStockItem.currentStock + selectedStockItem.changedStock"
                    type="number"
                    :suffix="$t('products.pcs', $store.state.locale)"
                    outlined
                    background-color="background"
                  ></v-text-field>
                </v-flex>

                <v-flex md12>
                  <v-textarea
                    :label="$t('products.description', $store.state.locale)"
                    rows="2"
                    v-model="selectedStockItem.description"
                    outlined
                    background-color="background"
                    persistent-hint
                    :hint="$t('products.addReason', $store.state.locale)"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn
                    outlined
                    depressed
                    color="tertiary"
                    @click="addStockDialog = false"
                    >
                    {{$t('general.cancel', $store.state.locale)}}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn
                    dark  
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    rounded 
                    color="secondary" 
                    @click="addStock()"
                    >
                    {{$t('products.addInventory', $store.state.locale)}}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>

      </v-dialog>
      <!-- \ Add Stock Dialog / -->

      <!-- / Remove Stock Dialog \ -->
      <v-dialog v-model="removeStockDialog" scrollable max-width="600px" height="700px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
          <v-card-title class="pr-2" :style=" { backgroundColor: $vuetify.theme.themes[$store.state.themeModus].tertiary }">
            <h2 class="white--text">{{$t('products.reduceInventoryAt', $store.state.locale)}}
              <span v-for="(branch, index) in branches" :key="index">                   
                <span style="color: #fff;" v-if="branch.id == selectedStockItem.branch">{{branch.name}}</span>
              </span></h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="removeStockDialog = false">
              <v-icon dark color="white">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          
          <v-alert v-if="removeStockFeedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ removeStockFeedback }}</v-alert>

          <v-divider></v-divider>

          <v-card-text v-if="removeStockDialog" style="padding: 0;">
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md6>
                  <v-text-field
                    :label="$t('products.reduceNumber', $store.state.locale)"
                    v-model.number="selectedStockItem.changedStock"
                    mask="######"
                    :suffix="$t('products.pcs', $store.state.locale)"
                    v-on:input="checkStockValues()"
                    outlined
                    background-color="background"
                  ></v-text-field>
                </v-flex>

                <v-flex md6>
                  <v-text-field
                    readonly
                    :label="$t('products.numberAfterReduce', $store.state.locale)"
                    :value="selectedStockItem.currentStock - selectedStockItem.changedStock"
                    type="number"
                    :suffix="$t('products.pcs', $store.state.locale)"
                    outlined
                    background-color="background"
                  ></v-text-field>
                </v-flex>

                <v-flex md12>
                  <v-textarea
                    :label="$t('products.description', $store.state.locale)"
                    rows="2"
                    v-model="selectedStockItem.description"
                    outlined
                    background-color="background"
                    persistent-hint
                    :hint="$t('products.reduceReason', $store.state.locale)"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn
                    outlined
                    depressed
                    color="tertiary"
                    @click="removeStockDialog = false"
                    >
                    {{$t('general.cancel', $store.state.locale)}}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn
                    dark  
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    rounded 
                    color="secondary" 
                    @click="removeStock()"
                    >
                     {{$t('products.reduceInventory', $store.state.locale)}}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>

        </v-card>

      </v-dialog>
      <!-- \ Remove Stock Dialog / -->

    </div>
  </div>
</template>

<script>
import '@firebase/firestore';
import db from "../firebase/init";
import { bus } from "../main";


export default {
  name: "Voorraad",
  props: {
    productCategories: Array,
    productSuppliers: Array,
  },
  data(){
    return{
      allProducts: [],
      products: [],
      selectedBranches: [],
      selectedBranchesOriginal: [],
      addStockDialog: false,
      removeStockDialog: false,
      selectedStockItem: {
        item: null,
        branch: null,
        changedStock: 0,
        description: "",
        currentStock: null
      },
      pages: -1,
      addStockFeedback: null,
      removeStockFeedback: null,
      branches: [],
      userDataLoaded: false,
      dataloaded: false,
      noData: true,
      pagination: {
        sortBy: "name",
        rowsPerPage: -1,
        descending: false
      },
      headers: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t('general.content', this.$store.state.locale),
          align: "left",
          value: "productType",
          width: "120px"
        },
        {
          text: this.$t('products.header.categories', this.$store.state.locale),
          align: "left",
          value: "productType"
        },
        {
          text: this.$t('products.header.supplier', this.$store.state.locale),
          align: "left",
          value: "time"
        },
        {
          text: this.$t('products.header.totalStock', this.$store.state.locale),
          align: "left",
          value: "totalStock",
          width: "140px"
        },
        {
          text: "",
          align: "left",
          value: "expand",
          width: "40px"
        }
      ]
    }
  },

  created() {
    bus.$on("companyInventoryUpdate", this.loadBranches);
    this.loadBranches()
  },

  methods: {

    toggleBranches () {
      this.$nextTick(() => {
        if (this.selectAllBranches) {
          this.selectedBranches = []
        } else {
          let branches = []
          new Promise((resolve,reject)=>{
            let counter = 0
            
            this.branches.forEach(branch =>{
              branches.push(branch.id)
              counter++
              if(counter == this.branches.length){
                resolve()
              }
            })

          })
          .then(()=>{
            this.selectedBranches = branches
          })
        }
      })
    },

    findProductCategories(category){
      let categories = [];
      if(Array.isArray(category) && category.length > 0){
        category.forEach(item=>{
          let foundItem = _.find(this.productCategories, ['id', item]);
          if(foundItem){
            categories.push(foundItem.name[(this.$store.state.locale).toLowerCase()]);
          }
        })
      }
      return categories;
    },
  
    updateData() {
      this.loadBranches();
    },

    loadBranches() {
      this.dataloaded = false;

      // Load branches
      this.branches = [];
      this.selectedBranches = [];
      
      if (this.$store.state.activeUserRoleNumber == 5) {
        if (this.userBranches.length > 0) {
          this.userBranches;
          let branchCounter = 0;
          new Promise((resolve, reject) => {
            this.userBranches.forEach(branch => {
              db.collection("branches")
                .doc(branch)
                .get()
                .then(doc => {
                  let branchCollection = doc.data();
                  branchCollection.id = doc.id;
                  this.branches.push(branchCollection);
                  this.selectedBranches.push(branchCollection.id)
                })
                .then(() => {
                  branchCounter++;
                  if (branchCounter == this.userBranches.length) {
                    resolve();
                  }
                });
            });
          })
          .then(() => {
            if (this.selectedBranches.length > 0) {
              this.loadSelectedBranchesProducts();
            } 
            else {
              // console.log("Er zijn geen vestigingen gevonden.");
              this.selectedBranchData = {};
              this.noData = true;
              this.dataloaded = true;
              this.userDataLoaded = true;
            }
          })
        } 
        else {
          //console.log("Er zijn geen vestigingen gevonden.");
          this.selectedBranchData = {};
          this.noData = true;
          this.dataloaded = true;
          this.userDataLoaded = true;
        }
      } 
      else {
        db.collection("branches")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let branchCollection = doc.data();
            branchCollection.id = doc.id;
            this.branches.push(branchCollection);
            this.selectedBranches.push(branchCollection.id)
          });
        })
        .then(() => {
          if (this.selectedBranches.length > 0) {
            this.loadSelectedBranchesProducts();
          }
          else {
            // console.log("Er zijn geen vestigingen gevonden.");
            this.selectedBranchData = {};
            this.noData = true;
            this.dataloaded = true;
            this.userDataLoaded = true;
          }
        });
      }
    },

    loadSelectedBranchesProducts() {
      console.log("loadSelectedBranchesProducts")
      this.selectedBranchesOriginal = _.cloneDeep(this.selectedBranches)
      this.dataloaded = false;
      this.allProducts = []
      this.products = []
      db.collection("products")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let product = doc.data();
            product.id = doc.id;
            this.allProducts.push(product);
          });
        })
        .then(()=>{
          // Start Product Promise
          if(this.allProducts && this.allProducts.length > 0){
            new Promise((resolve,reject)=>{
              let productCounter = 0
              this.allProducts.forEach(product=>{
                product.totalStock = 0
                product.minStockAlert = false
                
                // Kijken of voorraadbeheer is ingeschakeld
                if(product.useStock){
                  // Voorraadbeheer is uitgeschakeld
                  // Start Branches Promise
                  new Promise((resolve,reject)=>{
                    let branchCounter = 0
                    this.selectedBranches.forEach(branch=>{
                      if(product.stock[branch]){
                        if(product.stock[branch].stockValue){
                          product.totalStock = product.totalStock + product.stock[branch].stockValue
                        }
                        if(product.stock[branch].minStockValue){
                          if(product.stock[branch].minStockValue >= product.stock[branch].stockValue){
                            product.minStockAlert = true
                          }
                        }
                      }

                      branchCounter++
                      if(branchCounter == this.selectedBranches.length){
                        resolve()
                      }
                    })
                  })
                  .then(()=>{
                    // Resolved Branches Promise
                    this.products.push(product)
                    productCounter++
                    if(productCounter == this.allProducts.length){
                      resolve()
                    }
                  })
                } else{
                  // Voorraadbeheer is uitgeschakeld
                  productCounter++
                  if(productCounter == this.allProducts.length){
                    resolve()
                  }
                }

              })
            })
            .then(()=>{
              // Resolved Product Promise
              if(this.products.length > 0){
                this.userDataLoaded = true;
                this.dataloaded = true;
                this.noData = false;
              } else {
                this.userDataLoaded = true;
                this.dataloaded = true;
                this.noData = true;
              }
            })
          }
          else{
            this.userDataLoaded = true;
            this.dataloaded = true;
            this.noData = true;
          }
          
        })
    },

    checkStockValues(){
      if(this.selectedStockItem.changedStock > this.selectedStockItem.currentStock){
        this.removeStockFeedback = this.$t('products.stockCannotBeLessThanCurrent', this.$store.state.locale);
      } else{
        this.removeStockFeedback = null
      }
    },

    addStockSettings(item, key){    
      this.selectedStockItem.item = item
      this.selectedStockItem.branch = key
      this.selectedStockItem.changedStock = 0
      this.selectedStockItem.description = null
      this.selectedStockItem.currentStock = _.cloneDeep(item.stock[key].stockValue)
      this.addStockDialog = true 
    },

    removeStockSettings(item, key){    
      this.selectedStockItem.item = item
      this.selectedStockItem.branch = key
      this.selectedStockItem.changedStock = 0
      this.selectedStockItem.description = null
      this.selectedStockItem.currentStock = _.cloneDeep(item.stock[key].stockValue)
      this.removeStockDialog = true 
    },

    async addStock(){
      this.addStockFeedback = null;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "stockAdded",
        actionItem: this.selectedStockItem.item.name,
        extra:[
          {
            name: "description",
            value: this.selectedStockItem.description 
          },
          {
            name: "numberAdded",
            value: this.selectedStockItem.currentStock
          },
          {
            name: "totalAfterAdding",
            value:  Number(this.selectedStockItem.currentStock + this.selectedStockItem.changedStock) 
          }
        ]
      };

      if (this.selectedStockItem.changedStock && this.selectedStockItem.description){
        let productStock = this.selectedStockItem.item.stock;
        productStock[this.selectedStockItem.branch].stockValue = this.selectedStockItem.currentStock + this.selectedStockItem.changedStock;

        // Totaalvoorraad in de tabel updaten (en waarschuwing geven bij weinig voorraad)
        this.selectedStockItem.item.totalStock = this.selectedStockItem.item.totalStock + this.selectedStockItem.changedStock;
        if(productStock[this.selectedStockItem.branch].stockValue <= productStock[this.selectedStockItem.branch].minStockValue){
          this.selectedStockItem.item.minStockAlert = true
        }
        else {
          this.selectedStockItem.item.minStockAlert = false
        }

        try {
          await db.collection("products").doc(this.selectedStockItem.item.id)
          .update({
            stock: productStock
          });
          await this.$createNewLog("info", logData);
          this.addStockDialog = false;
          this.addStockFeedback = null;
          this.selectedStockItem.descriptionAdded = null;

        }
        catch (error) {
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
          this.addStockFeedback = this.$t("general.addError", this.$store.state.locale);
        }
      }
      else{
        this.addStockFeedback = this.$t('products.stockFillNumberAndDescription', this.$store.state.locale); 
      }
    },

    async removeStock(){
      this.removeStockFeedback = null;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "stockRemoved",
        actionItem: this.selectedStockItem.item.name,
        extra:[
          {
            name: "description",
            value: this.selectedStockItem.description 
          },
          {
            name: "numberRemoved",
            value: this.selectedStockItem.currentStock
          },
          {
            name: "totalAfterRemoval",
            value:  Number(this.selectedStockItem.currentStock + this.selectedStockItem.changedStock) 
          }
        ]
      };
      if (this.selectedStockItem.changedStock && this.selectedStockItem.description ){
        if(this.selectedStockItem.currentStock >= this.selectedStockItem.changedStock){

          let productStock = this.selectedStockItem.item.stock;
          productStock[this.selectedStockItem.branch].stockValue = this.selectedStockItem.currentStock - this.selectedStockItem.changedStock;
          
          // Totaalvoorraad in de tabel updaten (en waarschuwing geven bij weinig voorraad)
          this.selectedStockItem.item.totalStock = this.selectedStockItem.item.totalStock - this.selectedStockItem.changedStock;
          if(productStock[this.selectedStockItem.branch].stockValue <= productStock[this.selectedStockItem.branch].minStockValue){
            this.selectedStockItem.item.minStockAlert = true;
          }
          else {
            this.selectedStockItem.item.minStockAlert = false;
          }
          
          try {
            await db.collection("products").doc(this.selectedStockItem.item.id)
            .update({
              stock: productStock
            })
            await this.$createNewLog("info", logData);
            this.removeStockDialog = false;
            this.removeStockFeedback = null;

          }
          catch (error) {
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
            this.removeStockFeedback = this.$t("general.addError", this.$store.state.locale);
          }
        }
        else{
          this.removeStockFeedback =  this.$t('products.stockCannotBeLessThanCurrent', this.$store.state.locale); 
        }
      }
      else{
        this.removeStockFeedback = this.$t('products.stockFillNumberAndDescription', this.$store.state.locale); 
      }
    }
  },

  computed: {
    selectAllBranches () {
      return this.selectedBranches.length === this.branches.length;
    },
    selectSomeBranches () {
      return this.selectedBranches.length > 0 && !this.selectAllBranches;
    },

    icon() {
      if (this.selectAllBranches) {
        return 'mdi-check-box-outline'
      }
      if (this.selectSomeBranches) {
        return 'mdi-checkbox-intermediate'
      }
      else {
        return 'mdi-checkbox-blank-outline'
      }
    },

    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },
    changed() {
      if (
        _.isEqual(this.selectedBranches, this.selectedBranchesOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

