<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="900px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3>{{ $t('rooms.editRoom', $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeEditRoomDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-tabs :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="tab" show-arrows>
        <v-tab>{{ $t('general.general', $store.state.locale) }}</v-tab>
        <v-tab>{{ $t('rooms.grouplessons', $store.state.locale) }}</v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-card-text style="height: 600px; padding: 0; margin: 0;">
        <v-tabs-items v-model="tab">
          <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"  v-if="$store.state.activeUserRoleNumber < 2 && !$store.state.demoMode">
                <v-btn @click="copyTextToClipboard(selectedRoom.id)" class="ma-2 mb-2" small dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="text-transform: none;"><strong>{{selectedRoom.id}}</strong></v-btn>
                <div style="display: inline-block" v-if="selectedRoomIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(selectedRoom.id)"><v-icon small>mdi-content-copy</v-icon> Kopieër DocId</div>
                <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
              </v-card>

              <v-form  ref="AddRoomForm" lazy-validation>
                <v-text-field
                  :label="$t('general.name', $store.state.locale)"
                  class="mt-4"
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                  v-model="selectedRoom.name"
                  autocomplete 
                  required
                  outlined
                  >
                </v-text-field>

                <v-card class="pa-4 ma-0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
                  <v-container>
                    <h3>{{ $t('rooms.defaultDays', $store.state.locale) }}</h3>
                    <v-layout row wrap class="pa-0 ma-0" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">
                      <v-flex xs3 class="py-2"><strong>{{ $t('general.day', $store.state.locale) }}</strong></v-flex>
                      <v-flex xs9 class="py-2"><strong>{{ $t('general.available', $store.state.locale) }}</strong></v-flex>
                      <v-flex xs3>{{ $t('general.sunday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedRoom.defaultDays.Sun"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.monday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedRoom.defaultDays.Mon"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.tuesday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedRoom.defaultDays.Tue"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.wednesday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedRoom.defaultDays.Wed"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.thursday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedRoom.defaultDays.Thu"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.friday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedRoom.defaultDays.Fri"></v-switch>
                      </v-flex>
                      <v-flex xs3>{{ $t('general.saturday', $store.state.locale) }}</v-flex>
                      <v-flex xs9>
                        <v-switch class="ma-0 pa-1" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedRoom.defaultDays.Sat"></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>

                <div class="mt-2 clear">
                  <label><strong>{{ $t('general.color', $store.state.activeUserLanguage) }}</strong></label>
                  <div class="color-field">
                    <swatches
                      v-model="selectedRoom.color"
                      :swatches="colors"
                      :wrapper-style="wrapperStyle"
                      :swatch-style="swatchStyle"
                      swatch-size="36"
                      show-border
                      inline
                    ></swatches>
                  </div>
                  <div class="custom-hint">{{ $t('treatments.colorHint', $store.state.activeUserLanguage) }}</div>
                </div>

                <v-switch
                  :label="$t('general.active', $store.state.locale)" 
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  v-model="selectedRoom.active"
                  hide-details
                ></v-switch>
              </v-form>
            </div>
         </v-tab-item>
         <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
           <div class="tab-component-content">
              <v-switch
                class="mt-0 pt-0"
                :label="$t('general.active', $store.state.locale)" 
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                v-model="selectedRoom.slotReservation"
              ></v-switch>

              <v-card flat class="pa-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="selectedRoom.slotReservation">
                <div class="py-1 px-2"><strong>{{ $t('rooms.basicSchedule', $store.state.locale) }}</strong></div>
                <v-layout dflex class="grouplessons-schedule-wrapper">

                  <div class="day-of-week text-center" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }" v-for="(day, index) in daysOfWeek" :key="index">
                   <div class="schedule-day-title">{{ $t('general.' + day.name, $store.state.locale) }}</div>
                    <div v-if="selectedRoom.days[day.id].length > 0">
                      <div class="grouplesson" v-for="(grouplesson, index) in filteredGrouplesson(selectedRoom.days[day.id])" :key="index" :style="{backgroundColor: grouplesson.backgroundColor}">
                        <div class="grouplesson-time">{{ grouplesson.startTime }}-{{ grouplesson.endTime }}</div>
                        <div class="grouplesson-title">{{ grouplesson.title }}</div>
                        <div class="grouplesson-seats">{{ grouplesson.seats }} {{ $t('rooms.persons', $store.state.locale) }}</div>
                        <div class="grouplesson-edit">
                          <v-btn class="my-2" fab x-small dark depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editGroupLesson([day.id], filteredGrouplesson(selectedRoom.days[day.id]), index)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </div>
                      </div>
                    </div>
                    <div v-else class="schedule-day-empty">{{ $t('rooms.noGrouplessonsPlanned', $store.state.locale) }}</div>
                    <v-btn class="my-2" fab small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addGroupLesson([day.id], selectedRoom.days[day.id], 'basic')"><v-icon>mdi-plus</v-icon></v-btn>
                  </div>
                </v-layout>
              </v-card>

              <v-card flat class="pa-1 mt-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="selectedRoom.slotReservation">
                <v-layout row wrap class="pa-0 ma-0 px-2" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">
                  <v-flex xs6 >
                    <p class="ma-0 py-3 pr-2" style="display: inline-block;"><strong>{{ $t('rooms.customSchedule', $store.state.locale) }}</strong></p>
                    <v-btn v-if="customWeekSchedule && today <  customDayDate('Mon')" class="my-2" fab x-small dark outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleteCustomScheduleCheck()"><v-icon>mdi-delete</v-icon></v-btn>
                  </v-flex>
                  <v-flex xs6 class="py-0 text-right">
                    <v-btn class="my-2" fab x-small dark outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="previousWeek()"><v-icon>mdi-chevron-left</v-icon></v-btn>
                    <div class="px-2" style="display: inline-block"><strong>Week {{ selectedWeek }} {{ selectedYear }}</strong></div>
                    <v-btn class="my-2" fab x-small dark outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="nextWeek()"><v-icon>mdi-chevron-right</v-icon></v-btn>
                  </v-flex>
                </v-layout>

                <v-layout dflex class="grouplessons-schedule-wrapper" v-if="!customWeekLoading">
                  <div v-if="customWeekSchedule" style="display: flex">
                    <div class="day-of-week text-center" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }" v-for="(day, index) in daysOfWeek" :key="index">
                    <div class="schedule-day-title">{{ $t('general.' + day.name, $store.state.locale) }}</div>
                      <div v-if="customWeekSchedule.schedule[day.id].length > 0">
                        <div class="grouplesson" v-for="(grouplesson, index) in filteredGrouplesson(customWeekSchedule.schedule[day.id])" :key="index" :style="{backgroundColor: grouplesson.backgroundColor}">
                          <div class="grouplesson-time">{{ grouplesson.startTime }}-{{ grouplesson.endTime }}</div>
                          <div class="grouplesson-title">{{ grouplesson.title }}</div>
                          <div class="grouplesson-seats">{{ grouplesson.seats }} {{ $t('rooms.persons', $store.state.locale) }}</div>
                          <div class="grouplesson-edit" v-if="today <= customDayDate(day.id)">
                            <v-btn class="my-2" fab x-small dark depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="editGroupLesson([day.id], filteredGrouplesson(customWeekSchedule.schedule[day.id]), index)"><v-icon>mdi-pencil</v-icon></v-btn>
                          </div>
                        </div>
                      </div>
                      <div v-else class="schedule-day-empty">{{ $t('rooms.noGrouplessonsPlanned', $store.state.locale) }}</div>
                      <v-btn v-if="today <= customDayDate(day.id)" class="my-2" fab small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addGroupLesson([day.id], customWeekSchedule.schedule[day.id], 'custom')"><v-icon>mdi-plus</v-icon></v-btn>
                    </div>
                  </div>
                  <div v-else class="text-center" style="width: 100%">
                    <p class="pa-2 ma-0">{{ $t('rooms.noCustomSchedule', $store.state.activeUserLanguage) }}</p>
                    <v-btn
                      v-if="today <= customDayDate('Sun')"
                      class="ma-2"
                      depressed 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      small
                      outlined
                      @click="createCustomSchedule()"
                      >
                     {{ $t('rooms.createCustomSchedule', $store.state.activeUserLanguage) }} 
                    </v-btn>
                  </div>
                </v-layout>

                <div v-else style="position: relative; padding: 50px 0;">
                  <div class="thrive-loader"></div>
                </div>

              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeEditRoomDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateRoom()"
                >
                <div v-if="changed">{{ $t('general.saveChanged', $store.state.activeUserLanguage) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div  v-else> {{ $t('general.saved', $store.state.activeUserLanguage) }} <v-icon right >mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>

    <!-- / Group Lesson Dialog \ -->
    <v-dialog v-model="newGroupLesson.dialog" scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2 v-if="newGroupLesson.type == 'new'">{{ $t('rooms.addGrouplesson', $store.state.locale) }}</h2>
          <h2 v-else>{{ $t('rooms.editGrouplesson', $store.state.locale) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="resetNewGroupLesson()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="newGroupLesson.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ newGroupLesson.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text class="pa-0">
          <v-form ref="newGroupLessonForm">
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md12>
                  <v-text-field 
                    :label="$t('general.name', $store.state.locale)" 
                    v-model="newGroupLesson.title" 
                    required
                    disabled
                    readonly
                    dense
                    outlined
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                  </v-text-field>
                  <v-select
                    :label="$t('general.treatment', $store.state.locale)"
                    v-model="newGroupLesson.treatment"
                    :items="groupTreatments"
                    item-text="treatmentTitle"
                    item-value="id"
                    required
                    outlined
                    dense
                    return-object
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-on:input="calculateNewGroupLessonEndtime()"
                  ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-text-field 
                    :label="$t('general.start', $store.state.locale)" 
                    v-model="newGroupLesson.startTime" 
                    type="time"
                    required
                    dense
                    outlined
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-on:input="calculateNewGroupLessonEndtime()"
                    >
                  </v-text-field>
                </v-flex>
                <v-flex md6>
                <v-select
                    :label="$t('rooms.numberLessons', $store.state.locale)"
                    v-model="newGroupLesson.number"
                    :items="numberOfLessonsNew.numbers"
                    required
                    dense
                    outlined
                    :disabled="numberOfLessonsNew.disabled"
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-on:input="calculateNewGroupLessonEndtime()"
                  ></v-select>
                </v-flex>
                <v-flex md12>
                  <v-text-field 
                    :label="$t('rooms.availableSeats', $store.state.locale)" 
                    v-model.number="newGroupLesson.seats" 
                    type="number"
                    required
                    dense
                    outlined
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                  </v-text-field>
  
                  <div class="clear mt-4">
                    <label>{{ $t('general.color', $store.state.activeUserLanguage) }}</label>
                    <div class="color-field">
                      <swatches
                        v-model="newGroupLesson.backgroundColor"
                        :swatches="groupLessonColors"
                        :wrapper-style="wrapperStyle"
                        :swatch-style="swatchStyle"
                        swatch-size="36"
                        show-border
                        inline
                      ></swatches>
                    </div>
                    <div class="custom-hint"> {{ $t('treatments.colorHint', $store.state.activeUserLanguage) }}</div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="resetNewGroupLesson()"
                  >
                  {{ $t('general.cancel', $store.state.activeUserLanguage) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  v-if="newGroupLesson.type == 'new'" 
                  dark  
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  @click="saveGroupLesson('new')"
                  >
                  {{ $t('rooms.addGrouplessonButton', $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  dark  
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  @click="saveGroupLesson('edit')"
                  >
                  {{ $t('rooms.saveGrouplessonButton', $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ Group Lesson Dialog / -->

    <!-- / Delete Action Dialog \ -->
    <v-dialog v-model="deleteCustomWeekSchedule.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <p>{{ $t('rooms.customScheduleSubscriptionsFound', $store.state.locale) }}</p>

          <v-card class="mb-3 pa-1" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" v-for="(event, index) in deleteCustomWeekSchedule.subscriptionData" :key="index">
            <p class="ma-0"><strong>{{ event.title }}</strong></p>
            <p class="ma-0"> {{ event.startTime }} - {{ event.endTime }}u</p>
            <p class="ma-0">{{ $t('rooms.customScheduleSubscriptions', $store.state.locale) }}:  {{ event.subscriptions }}</p> 
          </v-card>
          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="deleteCustomWeekSchedule.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleteCustomSchedule()">
                  {{ $t('general.remove', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Action Dialog / -->

  </v-dialog>
</template>

<script>
import db from "@/firebase/init";
import moment from "moment";
import '@firebase/firestore';
import { bus } from "@/main";
import Swatches from "vue-swatches";

export default {
  name: "edit-room-modal",
  components: {
    Swatches
  },

  data() {
    return {
      showDialog: false,
      colors: new Array(),
      today: null,
      groupLessonColors: [
        "#72D9C4",
        "#ABDEE6",
        "#83C0EC",
        "#BC8DD0",
        "#F39488",
        "#F8DD65",
        "#E89861",
        "#61C1AD",
        "#D7E2EA",
        "#ECEAE4",  
      ],
      daysOfWeek: [
        {
          id: "Mon",
          name: "monday"
        },
        {
          id: "Tue",
          name: "tuesday"
        },
        {
          id: "Wed",
          name: "wednesday"
        },
        {
          id: "Thu",
          name: "thursday"
        },
        {
          id: "Fri",
          name: "friday"
        },
        {
          id: "Sat",
          name: "saturday"
        },
        {
          id: "Sun",
          name: "sunday"
        }
      ],
      deleteCustomWeekSchedule: {
        dialog: false,
        subscriptions: 0,
        subscriptionData: new Object()
      },
      selectedWeek: null,
      selectedYear: null,
      tab: 0,
      customWeekSchedule: null,
      tabTransition: false,
      treatments: new Array(),
      groupTreatments: new Array(),
      selectedRoom: {
        color: null,
        defaultDays: {},
        active: true
      },
      selectedRoomOriginal: {
        color: null,
        defaultDays: {},
        active: true
      },
      customWeekLoading: true,
      feedback: null,
      selectedRoomIdCopy: false,
      wrapperStyle: { paddingTop: "0", paddingLeft: "0" },
      swatchStyle: { marginBottom: "0" },
      colors: [
        "#72D9C4",
        "#ABDEE6",
        "#83C0EC",
        "#BC8DD0",
        "#F39488",
        "#F8DD65",
        "#E89861",
        "#61C1AD",
        "#D7E2EA",
        "#ECEAE4",  
      ],
      newGroupLesson: {
        type: null,
        type: 'basic',
        index: null,
        dialog: false,
        title: null,
        treatment: null,
        seats: 1,
        startTime: null,
        endTime: null,
        backgroundColor: null,
        duration: 0,
        number: 1,
        scheduledLessons: new Array(),
        day: null,
        feedback: null,
      }
    };
  },

  created(){
    bus.$on("editRoom", (selectedRoom, selectedRoomOriginal, date) => { // Executed on opening the dialog in Rooms.vue
      this.loadGroupLessonTreatments();
      this.tab = 0;
      this.today = moment().format("YYYY-MM-DD")
      this.selectedRoom = selectedRoom;
      this.selectedRoomOriginal = selectedRoomOriginal;
      if(this.selectedRoom.color && this.colors.findIndex(color=> { return color == this.selectedRoom.color})  >= 0){ // Set available colors
        this.selectedRoom.color = this.colors[this.colors.findIndex(color=> { return color == this.selectedRoom.color})]; 
      }
      else{
        this.selectedRoom.color = this.colors[0]; 
      }

      // Check and Set resources for groupBookings
      this.selectedRoom.days = this.selectedRoom.days ? this.selectedRoom.days : new Object();
      this.selectedRoom.days.Sun = this.selectedRoom.days.Sun ? this.selectedRoom.days.Sun : new Array();
      this.selectedRoom.days.Mon = this.selectedRoom.days.Mon ? this.selectedRoom.days.Mon : new Array();
      this.selectedRoom.days.Tue = this.selectedRoom.days.Tue ? this.selectedRoom.days.Tue : new Array();
      this.selectedRoom.days.Wed = this.selectedRoom.days.Wed ? this.selectedRoom.days.Wed : new Array();
      this.selectedRoom.days.Thu = this.selectedRoom.days.Thu ? this.selectedRoom.days.Thu : new Array();
      this.selectedRoom.days.Fri = this.selectedRoom.days.Fri ? this.selectedRoom.days.Fri : new Array();
      this.selectedRoom.days.Sat = this.selectedRoom.days.Sat ? this.selectedRoom.days.Sat : new Array();
      this.customWeekLoading = true;
      if(date){
        this.selectedYear = moment(date).year();
        this.selectedWeek = moment(date).isoWeek();
      }
      else{
        this.selectedYear = moment().year();
        this.selectedWeek = moment().isoWeek();
      }
      
      this.loadSchedulesBookingSubscriptions();
      this.showDialog = true;
    });
  },

  methods: {
    closeEditRoomDialog() { // Close Dialog Modal
      this.showDialog = false;
    },

    copyTextToClipboard(text) { // Function to copy Room DocId to clipboard
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text).then(()=> {
        this.selectedRoomIdCopy = true
        setTimeout(()=>{ 
          this.selectedRoomIdCopy = false
        }, 1000);
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },

    customDayDate(id){
      return moment().year(this.selectedYear).isoWeek(this.selectedWeek).isoWeekday(id).format('YYYY-MM-DD')
    },

    deleteCustomSchedule(){
      this.customWeekLoading = true;
      db.collection("schedulesBookingSubscriptions").doc(this.customWeekSchedule.id).delete()
      .then(()=>{
        this.customWeekSchedule = null;
        this.customWeekLoading = false;
      })
      .catch((error)=>{
        console.error("Error removing customWeekSchedule: ", error)
        this.customWeekLoading = false;
      })
    },


    async deleteCustomScheduleCheck(){
      let subscriptions = 0;
      let subscriptionData = new Object()
      let counter = 0;
      await new Promise((resolve)=>{
        for (const [key] of Object.entries(this.customWeekSchedule.schedule)) {
          db.collection("bookingen")
          .where("companyId", "==", this.customWeekSchedule.companyId)
          .where("dateBooking", "==", moment().year(this.customWeekSchedule.year).isoWeek(this.customWeekSchedule.week).isoWeekday(key).format("YYYY-MM-DD")) 
          .where("resourceId", "==", this.customWeekSchedule.resourceId)
          .where("type", "==", "bookingSubscription")
          .get()
          .then((snap) => {
            if(snap.size > 0){
              snap.forEach((doc) => {
                if(doc.data().subscriptions && doc.data().subscriptions.length > 0){
                  subscriptions = subscriptions + doc.data().subscriptions.length;
                  if(!subscriptionData[doc.id]){
                    subscriptionData[doc.id] = {
                      title: doc.data().title,
                      startTime: doc.data().startTime,
                      endTime: doc.data().endTime,
                      subscriptions: 0
                    }
                  }
                  subscriptionData[doc.id].subscriptions = subscriptionData[doc.id].subscriptions + doc.data().subscriptions.length
                }
              });
            }
          })
          .then(()=>{
            counter++
            if(counter == Object.keys(this.customWeekSchedule.schedule).length){
              resolve()
            }
          })
        }
      })

      if(subscriptions > 0){ // Show dialog 
        this.deleteCustomWeekSchedule.subscriptions = subscriptions;
        this.deleteCustomWeekSchedule.subscriptionData = subscriptionData;
        this.deleteCustomWeekSchedule.dialog = true;
      }
      else{ // Booking verwijderen
        this.deleteCustomSchedule()
      }
    },

    createCustomSchedule(){
      this.customWeekLoading = true;
      db.collection("schedulesBookingSubscriptions").add({
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        resourceId: this.selectedRoom.id,
        schedule: {
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array(),
          Sun: new Array(),
        },
        week: this.selectedWeek,
        year: this.selectedYear
      })
      .then(docRef=>{
        this.customWeekSchedule = {
          id: docRef.id,
          companyId: this.$store.state.activeCompany.id,
          branchId: null,
          resourceId: this.selectedRoom.id,
          schedule: {
            Mon: new Array(),
            Tue: new Array(),
            Wed: new Array(),
            Thu: new Array(),
            Fri: new Array(),
            Sat: new Array(),
            Sun: new Array(),
          },
          week: this.selectedWeek,
          year: this.selectedYear
        };
        this.customWeekLoading = false;
      })
      
    },

    nextWeek(){   
      this.customWeekLoading = true;
      let selectedWeek = _.cloneDeep(this.selectedWeek)
      this.selectedWeek = moment(moment().year(this.selectedYear).isoWeek(selectedWeek).add(1, 'week').format("YYYY-MM-DD")).isoWeek();
      this.selectedYear = moment().year(this.selectedYear).isoWeek(selectedWeek).add(1, 'week').year();
      this.loadSchedulesBookingSubscriptions();
    },

    previousWeek(){
      this.customWeekLoading = true;
      let selectedWeek = _.cloneDeep(this.selectedWeek)
      this.selectedWeek = moment(moment().year(this.selectedYear).isoWeek(selectedWeek).subtract(1, 'week').format("YYYY-MM-DD")).isoWeek();
      this.selectedYear = moment().year(this.selectedYear).isoWeek(selectedWeek).subtract(1, 'week').year();
      this.loadSchedulesBookingSubscriptions();
    },

    loadSchedulesBookingSubscriptions(){
      db.collection("schedulesBookingSubscriptions")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("week", "==", this.selectedWeek)
      .where("year", "==", this.selectedYear)
      .where("resourceId", "==", this.selectedRoom.id)
      .get()
      .then((snap) => {
        if(snap.size > 0){
          snap.forEach((doc) => {
            this.customWeekSchedule = doc.data();
            this.customWeekSchedule.id = doc.id;
          });
        }
        else{
          this.customWeekSchedule = null;
        }  
      })
      .then(()=>{
          this.customWeekLoading = false;
      })
    },

    async updateRoom() { // Update room in database

      let logData = {
        superAdminAction: true, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "roomEditted",
        actionItem: this.selectedRoomOriginal.name,
        extra:[
          {
            name: "originalData",
            value: ""
          },
          {
            name: "name",
            value: this.selectedRoomOriginal.name
          },
          {
            name: "companyId",
            value: this.selectedRoomOriginal.companyId
          },
          {
            name: "branchId",
            value: this.selectedRoomOriginal.branches
          },
          {
            name: "changedData",
            value: ""
          },
          {
            name: "name",
            value: this.selectedRoom.name
          },
          {
            name: "companyId",
            value: this.selectedRoom.companyId
          },
          {
            name: "branchId",
            value: this.selectedRoom.branches
          }
        ]
      }
      try{
        await db.collection("rooms")
        .doc(this.selectedRoom.id)
        .update({
          name: this.selectedRoom.name,
          defaultDays: this.selectedRoom.defaultDays,
          color: this.selectedRoom.color,
          active: this.selectedRoom.active,
          slotReservation: this.selectedRoom.slotReservation ? this.selectedRoom.slotReservation : false,
          basicScheduleLastChanged: this.selectedRoom.basicScheduleLastChanged ? this.selectedRoom.basicScheduleLastChanged : null
        })

        await this.$createNewLog("info", logData);
        if(!this.selectedRoom.slotReservation && this.selectedRoom.slotReservation != this.selectedRoomOriginal.slotReservation ){
          this.setPreviousBasisSchedule()
          .then(()=>{
            this.$emit("updateData");
            this.closeEditRoomDialog();
          })
        }
        else{
          this.$emit("updateData");
          this.closeEditRoomDialog();
        }
      }
      catch(error){
        console.error("Error updating Room: ", error);
        this.feedback = this.$t("general.addError", this.$store.state.locale) ;
        logData.extra.push({ name: "error", value: JSON.stringify(error) })
        await this.$createNewLog("error", logData);
      }
    },

    filteredGrouplesson(lessons){
      return lessons ? _.orderBy(lessons, ['startTime'],['asc']) : new Array();
    },

    addGroupLesson(day, lessons, type){
      this.newGroupLesson.feedback = null;
      this.newGroupLesson.type = 'new';
      this.newGroupLesson.backgroundColor = this.groupLessonColors[Math.floor(Math.random() * (9 - 0) + 0)];
      this.newGroupLesson.dialog = true;
      this.newGroupLesson.day = day;
      this.newGroupLesson.type = type;
      this.newGroupLesson.seats = null;
      this.newGroupLesson.scheduledLessons = _.cloneDeep(lessons);
    },

    resetNewGroupLesson(){
      this.newGroupLesson.index = null;
      this.newGroupLesson.title = null;
      this.newGroupLesson.startTime = null;
      this.newGroupLesson.endTime = null;
      this.newGroupLesson.number = 1;
      this.newGroupLesson.duration = null;
      this.newGroupLesson.treatment = null;
      this.newGroupLesson.day = null;
      this.newGroupLesson.dialog = false;
      this.newGroupLesson.scheduledLessons = new Array();
    },

    calculateNewGroupLessonEndtime(){
      this.newGroupLesson.title = this.newGroupLesson.treatment.treatmentTitle;
      if(this.newGroupLesson.startTime && this.newGroupLesson.treatment && this.newGroupLesson.treatment.treatmentTime && this.newGroupLesson.number){
        this.newGroupLesson.duration = this.newGroupLesson.treatment.treatmentTime;
        this.newGroupLesson.endTime = moment(this.newGroupLesson.startTime, "HH:mm").add((this.newGroupLesson.treatment.treatmentTime * this.newGroupLesson.number), 'minutes').format("HH:mm");       
      }
      else{
        this.newGroupLesson.endTime = null;
      }
    },

    calculatenewGroupLessonEndtime(){
      if(this.newGroupLesson.startTime && this.newGroupLesson.treatment && this.newGroupLesson.treatment.treatmentTime && this.newGroupLesson.number){
        this.newGroupLesson.duration = this.newGroupLesson.treatment.treatmentTime;
        this.newGroupLesson.endTime = moment(this.newGroupLesson.startTime, "HH:mm").add((this.newGroupLesson.treatment.treatmentTime * this.newGroupLesson.number), 'minutes').format("HH:mm");       
      }
      else{
        this.newGroupLesson.endTime = null;
      }
    },

    editGroupLesson(day, lessons, index){
      this.newGroupLesson.feedback = null;
      this.newGroupLesson.type = 'edit';
      this.newGroupLesson.day = day;
      this.newGroupLesson.index = index;
      this.newGroupLesson.scheduledLessons = _.cloneDeep(lessons);
      this.newGroupLesson.scheduledLessons[index]
      this.newGroupLesson.title = this.newGroupLesson.scheduledLessons[index].title;
      this.newGroupLesson.number = this.newGroupLesson.scheduledLessons[index].number ? this.newGroupLesson.scheduledLessons[index].number : null;
      this.newGroupLesson.startTime = this.newGroupLesson.scheduledLessons[index].startTime;
      this.newGroupLesson.endTime = this.newGroupLesson.scheduledLessons[index].endTime;
      this.newGroupLesson.duration = this.newGroupLesson.scheduledLessons[index].duration;
      this.newGroupLesson.backgroundColor = this.newGroupLesson.scheduledLessons[index].backgroundColor;

      this.newGroupLesson.number = Math.floor((moment.duration(moment(this.newGroupLesson.scheduledLessons[index].endTime, 'HH:mm').diff(moment(this.newGroupLesson.scheduledLessons[index].startTime, 'HH:mm'))).asMinutes()) / this.newGroupLesson.scheduledLessons[index].duration);

      this.newGroupLesson.seats = this.newGroupLesson.scheduledLessons[index].seats;
      this.newGroupLesson.treatment = this.treatments.find(treatment => treatment.id === this.newGroupLesson.scheduledLessons[index].treatmentId);
      this.newGroupLesson.scheduledLessons.splice(index, 1)
      this.newGroupLesson.dialog = true;
    },

    saveGroupLesson(type){ // Nieuwe groepsles toevoegen
      this.newGroupLesson.feedback = null;
      if(this.newGroupLesson.title && // Alles in ingevuld en berekend?
         this.newGroupLesson.startTime &&
         this.newGroupLesson.endTime &&
         this.newGroupLesson.number &&
         this.newGroupLesson.duration &&
         this.newGroupLesson.treatment.id &&
         this.newGroupLesson.seats
        )
      {
        let valid = true;
        new Promise((resolve)=>{
          let counter = 0;
          // Check of tijdstippen elkaar overlappen
          if(this.newGroupLesson.scheduledLessons.length > 0){ // Check uitvoeren
            this.newGroupLesson.scheduledLessons.forEach(lesson=>{
              if(
                lesson.startTime == this.newGroupLesson.startTime ||
                moment(lesson.startTime, 'HH:mm').isBetween(moment(this.newGroupLesson.startTime, 'HH:mm'), moment(this.newGroupLesson.endTime, 'HH:mm')) == true ||
                moment(this.newGroupLesson.startTime, 'HH:mm').isBetween(moment(lesson.startTime, 'HH:mm'), moment(lesson.endTime, 'HH:mm')) == true ||
                moment(this.newGroupLesson.endTime, 'HH:mm').isBetween(moment(lesson.startTime, 'HH:mm'), moment(lesson.endTime, 'HH:mm')) == true
              ) {
                valid = false;
              }
              counter++
              if(counter == this.newGroupLesson.scheduledLessons.length){
                resolve()
              }
            })
          }
          else{ // Geen bestaande events op deze dag
            resolve()
          }
        })
        .then(()=>{
          if(valid){ // Nieuwe groepsles overlapt geen bestaande groepslessen
            if(this.newGroupLesson.type == 'basic'){ // Change and save Basic Schedule
              this.setPreviousBasisSchedule().then(()=>{
                if(type == 'new'){
                  this.selectedRoom.basicScheduleLastChanged = moment().format("YYYY-MM-DD");
                  this.selectedRoom.days[this.newGroupLesson.day].push({
                    title: this.newGroupLesson.title,
                    startTime: this.newGroupLesson.startTime,
                    endTime: this.newGroupLesson.endTime,
                    duration: this.newGroupLesson.duration,
                    seats: this.newGroupLesson.seats,
                    number: this.newGroupLesson.number,
                    treatmentId:this.newGroupLesson.treatment.id,
                    backgroundColor: this.newGroupLesson.backgroundColor
                  })
                }
                else{ 
                  this.newGroupLesson.scheduledLessons.push({
                    title: this.newGroupLesson.title,
                    startTime: this.newGroupLesson.startTime,
                    endTime: this.newGroupLesson.endTime,
                    duration: this.newGroupLesson.duration,
                    seats: this.newGroupLesson.seats,
                    number: this.newGroupLesson.number,
                    treatmentId:this.newGroupLesson.treatment.id,
                    backgroundColor: this.newGroupLesson.backgroundColor
                  })
                  this.selectedRoom.days[this.newGroupLesson.day] = _.cloneDeep(this.newGroupLesson.scheduledLessons)
                }
              })  
              db.collection("resources")
              .doc(this.selectedRoom.id)
              .update({
                days: this.selectedRoom.days,  
              })
              .then(()=>{
                this.selectedRoomOriginal = _.cloneDeep(this.selectedRoom)
                this.resetNewGroupLesson(); // Reset and close dialog
              })
              .catch(error=>{
                this.newGroupLesson.feedback = "Opslaan niet gelukt.";
                console.error("Error saving days of grouplessons: ", error)
              })
            }
            else{ // Change and save Custom Schedule
              if(type == 'new'){
                this.customWeekSchedule.schedule[this.newGroupLesson.day].push({
                  title: this.newGroupLesson.title,
                  startTime: this.newGroupLesson.startTime,
                  endTime: this.newGroupLesson.endTime,
                  duration: this.newGroupLesson.duration,
                  seats: this.newGroupLesson.seats,
                  number: this.newGroupLesson.number,
                  treatmentId:this.newGroupLesson.treatment.id,
                  backgroundColor: this.newGroupLesson.backgroundColor
                })
              }
              else{ 
                this.newGroupLesson.scheduledLessons.push({
                  title: this.newGroupLesson.title,
                  startTime: this.newGroupLesson.startTime,
                  endTime: this.newGroupLesson.endTime,
                  duration: this.newGroupLesson.duration,
                  seats: this.newGroupLesson.seats,
                  number: this.newGroupLesson.number,
                  treatmentId:this.newGroupLesson.treatment.id,
                  backgroundColor: this.newGroupLesson.backgroundColor
                })
                this.customWeekSchedule.schedule[this.newGroupLesson.day] = _.cloneDeep(this.newGroupLesson.scheduledLessons)
              }
              db.collection("schedulesBookingSubscriptions")
              .doc(this.customWeekSchedule.id)
              .update({
                schedule: this.customWeekSchedule.schedule,  
              })
              .then(()=>{
                this.resetNewGroupLesson(); // Reset and close dialog
              })
              .catch(error=>{
                this.newGroupLesson.feedback = "Opslaan niet gelukt.";
                console.error("Error saving days of grouplessons: ", error)
              })
            }
            
          }
          else{
            this.newGroupLesson.feedback = this.$t('rooms.feedbackOverlap', this.$store.state.locale);
          }
        })     
      }
      else{ // Niet alles in ingevuld
        this.newGroupLesson.feedback = this.$t('rooms.feedbackRequired', this.$store.state.locale);
      }
    },

    loadGroupLessonTreatments(){
      this.groupTreatments = new Array();
      this.treatments = new Array();
      db.collection("treatments")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then(snap => {
          this.treatments = new Array();
          snap.forEach(doc => {
            let treatment = doc.data();
            treatment.id = doc.id;
            this.treatments.push(treatment);
            if(treatment.eventBooking){
              this.groupTreatments.push(treatment);
            }
          });
        })
        .catch(error=>{
          console.error("Error getting GroupLessonTreatments: ", error)
        })
    },

    async setPreviousBasisSchedule(){
      console.log("setPreviousBasisSchedule: ", this.selectedRoom.basicScheduleLastChanged)
      await new Promise((resolve)=>{
        if(this.selectedRoom.basicScheduleLastChanged){
          let today = moment();
          let lastChanged = moment(this.selectedRoom.basicScheduleLastChanged);
          let weekNumberPreviousDate = moment(lastChanged, "YYYY-MM-DD").isoWeek();
          let yearPreviousDate = moment(lastChanged, "YYYY-MM-DD").year();   
          let weeksBetween = today.diff(lastChanged, "weeks");

          if (weeksBetween > 0) { // Er zitten aan aantal weken tussen
            let activeWeeks = Array.apply(null, Array(weeksBetween));
            let weekCounter = weekNumberPreviousDate;
            let yearCounter = yearPreviousDate;
            let counter = 0;
            new Promise((resolve) => {
              activeWeeks.forEach((week, index) => {
                if (weekCounter == 52) { // Kan ook 53 zijn
                  weekCounter = 0;
                  yearCounter++;
                }
                weekCounter++;
                activeWeeks[index] = {
                  year: yearCounter,
                  week: weekCounter,
                };
                counter++;
                if (counter == activeWeeks.length) {
                  resolve();
                }
              });
            })
            .then(() => {
              let oldBasicSchedules = new Array();
              console.log("activeWeeks: ", activeWeeks);
              let docCounter = 0;
              new Promise((resolve) => {
                activeWeeks.forEach((week) => { // Find changed Schedules in this period
                  db.collection("schedulesBookingSubscriptions")
                  .where("companyId", "==", this.$store.state.activeCompany.id)
                  .where("branchId", "==", this.$store.state.activeBranch.id)
                  .where("year", "==", week.year)
                  .where("week", "==", week.week)
                  .where("resourceId", "==", this.selectedRoom.id)
                  .get()
                  .then((snap) => {
                    if (snap.size == 0) {
                      oldBasicSchedules.push(week);
                    }
                    docCounter++;
                    if (docCounter == activeWeeks.length) {
                      resolve();
                    }
                  });
                });
              })
              .then(() => {
                if (oldBasicSchedules.length > 0) {
                  console.log("oldBasicSchedules", oldBasicSchedules);
                  let newDocCounter = 0;
                  new Promise((resolve) => {
                    oldBasicSchedules.forEach((week) => {
                      db.collection("schedulesBookingSubscriptions").doc().set({ // Create new database document 
                        companyId: this.$store.state.activeCompany.id,
                        branchId: this.$store.state.activeBranch.id,
                        resourceId: this.selectedRoom.id,
                        week: week.week,
                        year: week.year,
                        schedule: this.selectedRoomOriginal.days,
                      },{ merge: true })
                      .then(() => {
                        newDocCounter++;
                        if(newDocCounter == oldBasicSchedules.length) { resolve(); }
                      })
                      .catch((error) => {
                        newDocCounter++;
                        if(newDocCounter == oldBasicSchedules.length) { resolve(); }
                        console.error("Error updating schedules: ", error);
                      });
                    });
                  })
                  .then(() => {
                    console.log("Alles is geupdated");
                    resolve();
                    // this.snackbar.active = true;
                    // this.snackbar.timeout = 3000;
                    // this.snackbar.color = "rgba(1, 230, 110, 0.7)";
                    // this.snackbar.icon = "mdi-thumb-up";
                    // this.snackbar.text = this.$t('employees.basicScheduleChanged', this.$store.state.locale);
                  });
                } 
                else {
                  console.log("Niets te updaten");
                  resolve();
                  // this.snackbar.active = true;
                  // this.snackbar.timeout = 3000;
                  // this.snackbar.color = "rgba(1, 230, 110, 0.7)";
                  // this.snackbar.icon = "mdi-thumb-up";
                  // this.snackbar.text = this.$t('employees.basicScheduleChanged', this.$store.state.locale);
                }
              });
            });
          }
          else {
            console.log("Zelfde week nog gewijzigd");
            resolve();
            // this.snackbar.active = true;
            // this.snackbar.timeout = 3000;
            // this.snackbar.color = "rgba(1, 230, 110, 0.7)";
            // this.snackbar.icon = "mdi-thumb-up";
            // this.snackbar.text = this.$t('employees.basicScheduleChanged', this.$store.state.locale);
          }
        }
        else{ // No basicScheduleLastChanged
          console.log("No basicScheduleLastChanged")
          this.selectedRoom.basicScheduleLastChanged = moment().format("YYYY-MM-DD");
          resolve();
        }
      })
    },

  },
  
  computed: {
    numberOfLessonsNew(){ // Calculate the possible times in the time left of the day
      if(this.newGroupLesson.type == 'new'){
        this.newGroupLesson.number = null;
      }
      
      let availableNumbers = {
        disabled: true,
        numbers: new Array()
      }
      if(this.newGroupLesson.startTime && this.newGroupLesson.treatment){
        let end = moment().endOf('day') // End of the day
        let start = moment(this.newGroupLesson.startTime, 'HH:mm'); // Start of the grouplesson
        let duration = moment.duration(end.diff(start)).asMinutes(); // time left in minutes    
        availableNumbers.numbers = Array.apply(null, Array(Math.floor(duration / this.newGroupLesson.treatment.treatmentTime)))

        if(availableNumbers.numbers.length > 0){
          for (let i = 0; i < availableNumbers.numbers.length; i++) {
            availableNumbers.numbers[i] = i + 1
          }
          availableNumbers.disabled = false;
        }
        return availableNumbers;
      }
      else{
        return availableNumbers;
      }
    },

    numberOfLessonsEdit(){ // Calculate the possible times in the time left of the day
      let availableNumbers = {
        disabled: true,
        numbers: new Array()
      }
      if(this.newGroupLesson.startTime && this.newGroupLesson.treatment){
        let end = moment().endOf('day') // End of the day
        let start = moment(this.newGroupLesson.startTime, 'HH:mm'); // Start of the grouplesson
        let duration = moment.duration(end.diff(start)).asMinutes(); // time left in minutes    
        availableNumbers.numbers = Array.apply(null, Array(Math.floor(duration / this.newGroupLesson.treatment.treatmentTime)))

        if(availableNumbers.numbers.length > 0){
          for (let i = 0; i < availableNumbers.numbers.length; i++) {
            availableNumbers.numbers[i] = i + 1
          }
          availableNumbers.disabled = false;
        }
        return availableNumbers;
      }
      else{
        return availableNumbers;
      }
    },

    changed() {
      if (
        _.isEqual(this.selectedRoom, this.selectedRoomOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style>
  .schedule-day-title{
    font-weight: bold;
    padding: 5px 0;
  }
  .schedule-day-empty{
    font-style: italic;
    font-size: 12px;
  }

  .grouplessons-schedule-wrapper{
    padding: 5px;
  }
  .grouplessons-schedule-wrapper:after{
    display: block;
    clear: both;
    content: "";
  }
  .grouplessons-schedule-wrapper .day-of-week{
    float: left;
    background-color: #fff;
    width: calc((100% - 30px)/7);
    margin-right: 5px;
    position: relative;
  }

  .grouplessons-schedule-wrapper .day-of-week.last{
    margin-right: 0;
  }
  .grouplesson{
    border-radius: 3px;
    margin: 2px;
    padding: 3px;
    text-align: left;
    position: relative;
    background-color: var(--background-color);
    
  }
  .grouplesson.grouplesson-border{
    background-color: transparent!important;
  }
  .grouplesson-border:before {
    background-color: currentColor;
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    display: block;
    opacity: 0.32;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
  }
  .grouplesson-border:after {
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    display: block;
    opacity: 0.52;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    border: 1px solid currentColor
  }


  .grouplesson-time,
  .grouplesson-title,
  .grouplesson-seats{
    font-weight: bold;
    color: #ffffff;
    font-size: 11px;
    line-height: 1.2;
  }
  .grouplesson-title{
    font-size: 13px;
    padding: 5px 0;
  }

  .grouplesson-edit{
    display: none;
    position: absolute;
    top: 0px;
    right: 5px;
  }

  .grouplesson:hover .grouplesson-edit{
    display: block;
  }
  .room-defaultdays-wrapper {
    background-color: #f6f7fb;
  }

  .room-defaultdays-label {
    padding: 10px 0 0 10px;
    color: #251433;
  }
</style>
