
<template>
  <div>
    <v-container v-if="dataloaded" grid-list-md class="pa-0" fluid>
      <v-layout row wrap>
        <companySelector :showBranches="false" @companyChanged="loadData"></companySelector>
      </v-layout>
    </v-container>

    <div v-if="!dataloaded" class="thrive-loader"></div>

    <div v-if="dataloaded" class="special-content-box">
      <v-tabs class="mb-4" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="tab" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
        <v-tab>{{ $t('general.products', $store.state.locale) }}</v-tab>
        <v-tab @click="loadInventory()" >{{ $t('products.stockManagement', $store.state.locale) }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="transparant-tabs">
        <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
          <products :branches="branches" :vatItems="vatItems" :productCategories="productCategories" :productSuppliers="productSuppliers" :supplierProductGroups="supplierProductGroups"></products>
        </v-tab-item>

        <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
          <productInventory :productCategories="productCategories" :productSuppliers="productSuppliers" :supplierProductGroups="supplierProductGroups"></productInventory>
        </v-tab-item>
      </v-tabs-items>
    </div>

  </div>
</template>

<script>
import { bus } from "@/main";
import '@firebase/firestore';
import db from "@/firebase/init";
import companySelector from "@/components/elements/companySelector.vue";
import Products from "@/views/Products.vue";
import ProductInventory from "@/views/ProductInventory.vue";

export default {
  components: {
    products: Products,
    productInventory: ProductInventory,
    companySelector: companySelector
  },
  name: "inventory",
  data: () => ({
    tabTransition: false,
    tab: 0,
    dataloaded: false,
    branches: new Array(),
    vatItems: new Array(),
    productCategories: new Array(),
    productSuppliers: new Array(),
    supplierProductGroups: new Array(),
  }),

  created() {
    this.loadData();
  },
  
  methods: {
    loadInventory(){
      bus.$emit("loadBranchesInventory");
    },

    async changeActiveCompData(companyId){
      this.dataloaded = false;
      await this.$store.dispatch("changeActiveCompData", companyId);
      this.loadData();
    },

    async loadData(){ // Load all needed data simultaneously
      bus.$emit("loadBranchesInventory");
      bus.$emit("companyInventoryUpdate");
      await Promise.all([this.loadBranches(), this.loadProductCategories(), this.loadProductSuppliers(), this.loadVat()])
      this.dataloaded = true;
    },

    async loadBranches() {
      this.branches = new Array();
      await db.collection("branches")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        this.branches = new Array();
        snap.forEach(doc => {
          let branch = doc.data();
          branch.id = doc.id;
          this.branches.push(branch);
        });
      })
      .catch((error)=>{
        console.error("Load branches: ", error)
      })
      return;
    },

    async loadProductCategories() {
      this.productCategories = new Array();
      await db.collection("productCategories")
      .get()
      .then(snap => {
        this.productCategories = new Array();
        snap.forEach(doc => {
          let productCategory = doc.data();
          productCategory.id = doc.id;
          this.productCategories.push(productCategory);
        });
      })
      .catch((error)=>{
        console.error("Load product categories: ", error)
      })
      this.productCategories.sort((a, b) => this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.name).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.name)))

      return;
    },

    async loadProductSuppliers() {
      this.productSuppliers = new Array();
      await db.collection("productSuppliers")
      .where("companies", "array-contains", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        this.productSuppliers = new Array();
        snap.forEach(doc => {
          let productSupplier = doc.data();
          productSupplier.id = doc.id;
          this.productSuppliers.push(productSupplier);
        });
      })
      .catch((error)=>{
        console.error("Load product supplier: ", error)
      })
      this.productSuppliers.sort((a, b) => a.name.localeCompare(b.name));
      await this.loadSupplierProductGroups()
      return;
    },

    async loadSupplierProductGroups() {
      this.supplierProductGroups = new Array();
      if(this.productSuppliers.length > 0){
        await new Promise((resolve)=>{
          for (let index = 0; index < this.productSuppliers.length; index++) {
            this.productSuppliers[index].groups = new Array();
            db.collection("productSuppliers")
            .doc(this.productSuppliers[index].id)
            .collection("groups")
            .get()
            .then(snap => {
              snap.forEach(doc => {
                let group = doc.data();
                group.id = doc.id;
                group.disabled = doc.data().deleted && doc.data().deleted.deleted ? true : false
                this.productSuppliers[index].groups.push(group);
              });
            })
            .then(()=>{
              this.productSuppliers[index].groups.sort((a, b) => a.name.localeCompare(b.name));
              if(index == this.productSuppliers.length -1 ){
                resolve()
              }
            })
          }
        })
        return 
      }
      else{
        return
      }
      
    },

    


    async loadVat() {
      this.vatItems = new Array();
      await db.collection("vat")
      .get()
      .then(snap => {
        this.vatItems = new Array();
        snap.forEach(doc => {
          let vatItem = doc.data();
          vatItem.id = doc.id;
          vatItem.summary = doc.data().percentage + "%"
          this.vatItems.push(vatItem);
        });
      })
      .catch((error)=>{
        console.error("Load vat error: ", error)
      })
      return;
    },
  },
};
</script>

