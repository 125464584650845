<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h3>{{ $t('admin.addCompany',$store.state.locale) }}</h3>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeAddCompDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text class="pt-4 mb-4">
      <v-text-field
        :label="$t('admin.companyName',$store.state.locale)"
        v-model="newComp.name"
        autocomplete
        prepend-inner-icon="mdi-domain"
        required
        dense
        outlined
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
      ></v-text-field>
      <v-text-field
        :label="$t('general.email',$store.state.locale)"
        v-model="newComp.email"
        :rules="emailRules"
        autocomplete
        required
        dense
        prepend-inner-icon="mdi-email"
        outlined
        class="mt-4"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
      ></v-text-field>
        <v-text-field
        label="Url"
        v-model="newComp.url"
        autocomplete
        required
        dense
        prepend-inner-icon="mdi-web"
        outlined
        class="mt-4"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
      ></v-text-field>
      <v-text-field
        :label="$t('admin.phonenumber',$store.state.locale)"
        v-model="newComp.phonenumber"
        prepend-inner-icon="mdi-phone"
        required
        dense
        outlined
        class="mt-4"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
      ></v-text-field>
      <v-select
        :label="$t('general.languages',$store.state.locale)"
        v-model="newComp.languages"
        :items="availableLanguages"
        :item-text="item => item.languageName[($store.state.locale).toLowerCase()]"
        item-value="id"
        required
        outlined
        chips
        small-chips
        dense
        deletable-chips
        prepend-inner-icon="mdi-flag"
        class="mt-4"
        hide-details
        multiple
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
      ></v-select>
      <v-select
        :label="$t('admin.theme',$store.state.locale)"
        v-model="newComp.theme"
        :items="themes"
        item-text="name"
        item-value="id"
        required
        outlined
        dense
        class="mt-4"
        hide-details
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
      ></v-select>

      <v-switch
        class="ma-0 mt-4 pa-0"
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        :label="$t('admin.active',$store.state.locale)"
        hide-details
        v-model="newComp.active"
      ></v-switch>

    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
              @click="closeAddCompDialog()"
              >
              {{ $t('general.cancel',$store.state.locale)}}
            </v-btn>
          </v-flex>
          <v-flex xs12 md8 class="text-right">
            <v-btn
              dark
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              @click="addCompany()"
              >
              {{ $t('admin.addCompany',$store.state.locale) }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>


<script>
import db from "../../firebase/init";

export default {
  name: "add-company-dialog",
  props: {
    themes: Array
  },

  data() {
    return {
      newComp: {
        logoUrl: null,
        active: true,
        theme: 'thrive'
      },
      feedback: null,
      emailRules: [
        v => !!v || this.$t('general.mandatoryEmailField',this.$store.state.locale), 
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('general.mandatoryEmailValid',this.$store.state.locale)
      ]
    };
  },

  methods: {
    closeAddCompDialog() {
      this.$emit("newCompDialogChange", false);
    },

    async addCompany() {
      this.feedback = null;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "companyAdded",
        actionItem: this.newComp.name,
        extra:[
          {
            name: "name",
            value: this.newComp.name 
          },
          {
            name: "email",
            value: this.newComp.email
          },
          {
            name: "url",
            value: this.newComp.url
          },
          {
            name: "active",
            value: this.newComp.active
          },
        ]
      };



      if (this.newComp.name && this.newComp.email && this.newComp.url) {
        try {
          await db.collection("companies")
          .add({
            // Basic Company Settings
            active: this.newComp.active,
            name: this.newComp.name,
            email: this.newComp.email,
            phonenumber: this.newComp.phonenumber,
            url: this.newComp.url,
            logoUrl: null,
            theme: this.newComp.theme,
            languages : this.newComp.languages,
            connections: {},
            conversion: {
              adwordsBudget: "",
              sources: [],
              start: "",
              successUrl: null,
              widget: {
                gtmTracking:{
                  gtmCode: null,
                },
                active: true,
                feedBackOnClose: false,
                showCallback: false,
                notification: {
                  show: false,
                  message: {
                    en: "",
                    nl: ""
                  },
                  messageTitle: {
                    en: "",
                    nl: ""
                  }
                },
                startDate: null,
                startDateActive: false,
              }
            },
            paymentOptions: [],
            subscription: {
              accountholder: null,
              groupDiscount: null,
              iban: null,
              packages: {},
              users: 1,
              activationFeeAmount: null
            }
          })
        
          this.$emit("updateData");
          this.closeAddCompDialog();
          await this.$createNewLog("info", logData);
        
        }
        catch (error) {
          this.feedback = this.$t("general.addError", this.$store.state.locale) ;
          console.error("Error adding company: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      }
      else {
        this.feedback = this.$t('admin.allFieldRequiredCompany', this.$store.state.locale); 
      }
    }
  },
  computed: {
    availableLanguages(){
      return this.$countries.filter(language => { // Filter rich languages data from languages
        if(language.userLanguage){ return language }
      });
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
  }
};
</script>
