<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>{{ $t("user.addUser", $store.state.locale) }}</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeAddUserDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text>
      <v-form ref="AddUserForm" v-model="valid" lazy-validation>
        <v-text-field
          :label="$t('general.email', $store.state.locale)"
          v-model.trim="signup.email"
          :rules="emailRules"
          autocomplete
          required
          dense
          prepend-inner-icon="mdi-email"
          outlined
          class="mt-2"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        ></v-text-field>
        <v-text-field
          :label="$t('general.name', $store.state.locale)"
          v-model.trim="signup.firstName"
          autocomplete
          dense
          prepend-inner-icon="mdi-account"
          :rules="[rules.required]"
          required
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        ></v-text-field>
        <v-text-field
          :label="$t('general.lastName', $store.state.locale)"
          v-model.trim="signup.lastName"
          autocomplete
          dense
          prepend-inner-icon="mdi-account"
          :rules="[rules.required]"
          required
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        ></v-text-field>

        <v-select
          :label="$t('general.language', $store.state.locale)"
          :hint="$t('user.languageHint', $store.state.locale)"
          outlined
          dense
          class="mt-4"
          content-class="pa-0 ma-0 mr-4"
          :items="availableUserlanguages"
          v-model="signup.language"
          item-value="languageCode"
          item-text="code"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          hide-details
          >
          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
            <country-flag :country="data.item.flagCode" size="small"/>
            <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
          </template>
          <template slot="item" slot-scope="data">
            <country-flag :country="data.item.flagCode" size="small"/>
            <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
          </template>
        </v-select>

        <v-select
          :items="userRoles"
          v-model="signup.userRole"
          :label="$t('general.role', $store.state.locale)"
          item-text="name"
          item-value="id"
          single-line
          dense
          prepend-inner-icon="mdi-shield-check"
          :rules="[rules.required]"
          required
          outlined
          class="mt-4"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        ></v-select>

        <v-select
          class="mt-4"
          :items="filteredCompanies"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          item-text="name"
          item-value="id"
          return-object
          dense
          v-model="signup.company"
          no-data-text="Geen bedrijven gevonden."
          :label="$t('general.company', $store.state.locale)"
          outlined
          :hint="$t('user.companyHint', $store.state.locale)"
          persistent-hint
          prepend-inner-icon="mdi-domain"
          :rules="[rules.required]"
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content class="pt-0">
                <v-text-field
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :placeholder="$t('general.search', $store.state.locale)"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  v-model="searchCompanies"
                  hide-details
                  dense
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div v-if="searchCompanies && searchCompanies.length > 0" style="padding: 4px 17px 3px 17px; background-color: rgba(0, 0, 0, 0.02); text-align: center; font-size: 12px">
              {{ filteredCompanies.length }} {{ $t("general.of", $store.state.locale) }} {{ companies.length }} {{ $t("general.companies", $store.state.locale) }}
            </div>
            <v-divider v-if="searchCompanies && searchCompanies.length > 0"></v-divider>
          </template>
        </v-select>

        <v-select
          class="mt-2"
          :items="filteredCompaniesAccess"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          item-text="name"
          item-value="id"
          dense
          v-model="signup.accessCompanies"
          :no-data-text="$t('general.noCompanies', $store.state.locale)"
          :label="$t('user.access', $store.state.locale)"
          outlined
          :hint="$t('user.accessHint', $store.state.locale)"
          persistent-hint
          prepend-inner-icon="mdi-check"
          :rules="[rules.required]"
          multiple
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content class="pt-0">
                <v-text-field
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :placeholder="$t('general.search', $store.state.locale)"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  v-model="searchCompaniesAccess"
                  hide-details
                  dense
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div v-if="searchCompaniesAccess && searchCompaniesAccess.length > 0" style="padding: 4px 17px 3px 17px; background-color: rgba(0, 0, 0, 0.02); text-align: center; font-size: 12px">
              {{ filteredCompanies.length }} {{ $t("general.of", $store.state.locale) }} {{ companies.length }} {{ $t("general.companies", $store.state.locale) }}
            </div>
            <v-divider v-if="searchCompaniesAccess && searchCompaniesAccess.length > 0"></v-divider>
          </template>
        </v-select>

        <v-text-field
          :label="$t('general.password', $store.state.locale)"
          v-model.trim="signup.password"
          :rules="[rules.required, rules.min]"
          prepend-inner-icon="mdi-lock"
          hint="Minimaal 6 characters"
          min="6"
          required
          dense
          outlined
          class="mt-2"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        ></v-text-field>

        <v-switch class="ma-0 mt-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('user.emailNewAccount', $store.state.locale)" v-model="signup.sendEmail" hide-details></v-switch>

        <v-switch class="ma-0 mt-4 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('general.active', $store.state.locale)" v-model="signup.active" hide-details></v-switch>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeAddUserDialog()">
              {{ $t("general.cancel", $store.state.locale) }}
            </v-btn>
          </v-flex>
          <v-flex xs12 md8 class="text-right">
            <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="signUp()">
              {{ $t("user.addUser", $store.state.locale) }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
  import db from "../../firebase/init";
  import "@firebase/firestore";
  import firebase from "firebase/app";
  import "firebase/auth";
  import axios from "axios";

  export default {
    name: "add-user",
    props: {
      companies: Array,
    },
    data() {
      return {
        signup: {
          userRole: "admin",
          language: "NL",
          active: true,
          sendEmail: true,
          password: "",
        },
        searchCompanies: "",
        searchCompaniesAccess: "",
        valid: false,
        userRoles: [
          {
            name: "Developer",
            id: "developer",
            disabled: this.$store.state.userRoleNumber > 0
          },
          {
            name: "Superadmin",
            id: "superadmin",
          },
          {
            name: "Admin",
            id: "admin",
          },
        ],
        rules: {
          required: (value) => !!value || this.$t("general.required", this.$store.state.locale),
          min: (v) => v.length >= 6 || this.$t("user.sixCharactersPassword", this.$store.state.locale),
        },

        feedback: null,
        emailRules: [(v) => !!v || "E-mail is verplicht", (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid"],
        requiredRules: [(v) => !!v || "Dit veld is verplicht"],
        passwordRules: [(v) => !!v || "Wachtwoord is verplicht"],
      };
    },

    methods: {
      closeAddUserDialog() {
        this.$emit("newUserDialogChange", false);
      },

      async signUp() {
        let userExists = true;

        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "userAdded",
          actionItem: `${this.signup.firstName} ${this.signup.lastName}`,
          extra:[
            {
              name: "name",
              value: `${this.signup.firstName} ${this.signup.lastName}`
            },
            {
              name: "role",
              value: this.signup.role
            },
            {
              name: "email",
              value: (this.signup.email).toLowerCase()
            },
            {
              name: "company",
              value: this.signup.company.id
            },
            {
              name: "accessCompanies",
              value: this.signup.accessCompanies
            },
          ]
        };

        if (this.$refs.AddUserForm.validate()) {
          await db.collection("users")
          .where("email", "==", (this.signup.email).toLowerCase())
          .get()
          .then((snap) => {
            if(snap.size == 0){ // No user found
              userExists = false;
            }
          })

          if(!userExists){
            try {
              let idToken =  await firebase.auth().currentUser.getIdToken(true);
              let response = await  axios.post(`${this.$store.state.restApi.url}/auth`, { // API Call to create user account
                token: idToken,
                type: "createUser",
                email: (this.signup.email).toLowerCase(),
                password: this.signup.password,
                sendEmail: this.signup.sendEmail,
                company: this.signup.company.name,
                language: this.signup.language,
                status: this.signup.active,
                name: this.signup.firstName + " " + this.signup.lastName,
              })

              await db.collection("users").add({
                firstName: this.signup.firstName,
                lastName: this.signup.lastName,
                userRole: this.signup.userRole,
                user_id: response.data,
                language: this.signup.language,
                employeeId: null,
                accessCompanies: this.signup.accessCompanies,
                companyId: this.signup.company.id,
                email: (this.signup.email).toLowerCase(),
                active: this.signup.active,
              })

              await this.$createNewLog("info", logData);
              this.$emit("updateData");
              console.log("User Registration Succesfull");
              this.closeAddUserDialog();
              this.signup = {
                userRole: "admin",
                language: "NL",
                active: true,
                sendEmail: false,
                password: "",
              };
            }
            catch(error){
              console.log("User Registration Unsuccesfull: " + error);
              this.feedback = this.$t("general.addError", this.$store.state.locale) ;
              logData.extra.push({ name: "error", value: JSON.stringify(error) })
              await this.$createNewLog("error", logData);
            }
          }
          else{
            this.feedback = this.$t("user.userExists", this.$store.state.locale);
          }
        }
        else {
          this.feedback = this.$t("general.requiredField", this.$store.state.locale);
        }
      },
    },
    computed: {
      availableUserlanguages(){
        return this.$countries.filter(language => { // Filter rich languages data from languages
          if(language.userLanguage){ return language }
        });
      },
      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
      filteredCompaniesAccess() {
        if (this.searchCompaniesAccess && this.searchCompaniesAccess.length > 0) {
          return this.companies.filter((company) => {
            return company.name.toLowerCase().includes(this.searchCompaniesAccess.toLowerCase());
          });
        } else {
          return this.companies;
        }
      },
      filteredCompanies() {
        if (this.searchCompanies && this.searchCompanies.length > 0) {
          return this.companies.filter((company) => {
            return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
          });
        } else {
          return this.companies;
        }
      },
    },
  };
</script>
