
<template>
  <div>
    <div  v-if="!dataloaded" class="thrive-loader" :class="$store.state.companyTheme"></div>

    <div>

        <v-container grid-list-md class="pa-0 pb-4" fluid>
          <v-layout row wrap>
            <companySelector :showBranches="true" @companyChanged="loadData" @branchChanged="loadData"></companySelector>
            <v-flex md6 xs12 v-if="!noData">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn dark height="40" style="float: right;" v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="newBranchDialog = true">
                    <v-icon left >mdi-plus</v-icon> {{ $t('branch.newBranch', $store.state.locale) }}
                  </v-btn>
                </template>
                <span class="white--text">{{ $t('branch.addNewBranch', $store.state.locale) }}</span>
              </v-tooltip>
            </v-flex>

          </v-layout>
        </v-container>
   

      <div class="no-data-message text-center" v-if="noData">
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-domain</v-icon>
        </v-avatar>
        <p>{{ $t('branch.noBranchesFound', $store.state.locale) }}</p>
        <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="newBranchDialog = true">
          <v-icon left >mdi-plus</v-icon> {{ $t('branch.newBranch', $store.state.locale) }}
        </v-btn>
      </div>


       <v-tabs class="mb-4" v-if="!noData && dataloaded" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" show-arrows v-model="tab" @change="tabChanged()" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
          <v-tab  href="#information"><v-icon left>mdi-information</v-icon>{{ $t('branch.branchInformation', $store.state.locale) }}</v-tab>
          <!-- <v-tab  href="#schedule"><v-icon left>mdi-calendar</v-icon>{{ $t('branch.schedule', $store.state.locale) }}</v-tab>          -->
          <v-tab  href="#calendar"><v-icon left>mdi-calendar-edit</v-icon>{{ $t('branch.calendarSettings', $store.state.locale) }}</v-tab>     
          <v-tab  href="#widget"><v-icon left>mdi-calendar-cursor</v-icon>{{ $t('branch.widgetSettings', $store.state.locale) }}</v-tab>
          <v-tab  href="#register"><v-icon left>mdi-cash-register</v-icon>{{ $t('settings.posSettingsShort', $store.state.locale)}}</v-tab>
          <v-tab  href="#connections"><v-icon left>mdi-connection</v-icon>{{ $t('settings.connections', $store.state.locale) }}</v-tab>
          <v-tab  href="#rooms"><v-icon left>mdi-door</v-icon>{{ $t('branch.rooms', $store.state.locale) }}</v-tab>
          <v-tab  href="#devices"><v-icon left>mdi-power-plug</v-icon>{{ $t('branch.devices', $store.state.locale) }}</v-tab>
          <v-tab  href="#accountancy"><v-icon left>mdi-cash-check</v-icon>{{ $t('settings.accountancySettings', $store.state.locale)}}</v-tab>    
        </v-tabs>


        <!-- <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-tab-header" :class="$store.state.companyTheme">         
          <v-tabs
            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            v-if="!noData && branchesLoaded"
            show-arrows
            v-model="tab"
            >
            <v-tab><v-icon left>mdi-information</v-icon>{{ $t('branch.branchInformation', $store.state.locale) }}</v-tab>
            <v-tab><v-icon left>mdi-calendar</v-icon>{{ $t('branch.schedule', $store.state.locale) }}</v-tab>
            <v-tab><v-icon left>mdi-door</v-icon>{{ $t('branch.rooms', $store.state.locale) }}</v-tab>
            <v-tab><v-icon left>mdi-power-plug</v-icon>{{ $t('branch.devices', $store.state.locale) }}</v-tab>
            <v-tab><v-icon left>mdi-facebook</v-icon>{{ $t('branch.social', $store.state.locale) }}</v-tab>
            <v-tab><v-icon left>mdi-calendar-edit</v-icon>{{ $t('branch.calendarSettings', $store.state.locale) }}</v-tab>
            <v-tab><v-icon left>mdi-connection</v-icon>{{ $t('settings.connections', $store.state.locale) }}</v-tab>
            <v-tab><v-icon left>mdi-calendar-cursor</v-icon>{{ $t('branch.widgetSettings', $store.state.locale) }}</v-tab>
            <v-tab><v-icon left>mdi-cash-check</v-icon>{{ $t('settings.accountancySettings', $store.state.locale)}}</v-tab>
            <v-tab><v-icon left>mdi-print</v-icon>{{ $t('settings.posSettingsShort', $store.state.locale)}}</v-tab>
          </v-tabs>
        </v-card> -->

        <v-tabs-items v-model="tab"  v-if="!noData && dataloaded" class="transparant-tabs">
          <v-tab-item value="information" :transition="tabTransition" :reverse-transition="tabTransition">
            <branch-info v-if="dataloaded == true" :now="now" ></branch-info>
          </v-tab-item>
          <!-- <v-tab-item value="schedule" :transition="tabTransition" :reverse-transition="tabTransition">
            <branch-schedules v-if="dataloaded == true"></branch-schedules>
          </v-tab-item> -->
          <v-tab-item value="rooms" :transition="tabTransition" :reverse-transition="tabTransition">
            <rooms v-if="dataloaded == true"></rooms>
          </v-tab-item>
          <v-tab-item value="devices" :transition="tabTransition" :reverse-transition="tabTransition">
            <devices v-if="dataloaded == true"></devices>
          </v-tab-item>
          <v-tab-item value="calendar" :transition="tabTransition" :reverse-transition="tabTransition">
            <calendar v-if="dataloaded == true"></calendar>
          </v-tab-item>
          <v-tab-item value="connections" :transition="tabTransition" :reverse-transition="tabTransition">
              <connections v-if="dataloaded == true" :type="'branch'"></connections>
          </v-tab-item>
          <v-tab-item value="widget" :transition="tabTransition" :reverse-transition="tabTransition">
            <widget v-if="dataloaded == true"></widget>
          </v-tab-item>
          <v-tab-item value="register" :transition="tabTransition" :reverse-transition="tabTransition">
              <branch-pos-settings></branch-pos-settings>
          </v-tab-item>
          <v-tab-item value="accountancy" :transition="tabTransition" :reverse-transition="tabTransition">
            <accountancy-settings :typeCompanyOrBranch="'branch'"></accountancy-settings>
          </v-tab-item>
        </v-tabs-items>

    </div>

    <!-- / New Branch Dialog \ -->
    <v-dialog v-model="newBranchDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <add-branch-form
        :activeCompData="activeCompData"
        @newBranchDialogChange="newBranchDialog = $event"
        @updateData="reloadBranches"
      ></add-branch-form>
    </v-dialog>
    <!-- \ New Branch Dialog / -->

    
  </div>
</template>

<script>
import '@firebase/firestore';
import db from "../firebase/init";
import Calendar from "./CalendarSettingsBranch.vue";
import Social from "./Social.vue";
import Rooms from "./Rooms.vue";
import Devices from "./Devices.vue";
import BranchInfo from "./BranchInfo.vue";
import Widget from "./BranchWidget.vue";
import BranchSchedules from "./BranchSchedules.vue";
import { bus } from "../main";
import moment from "moment";
import AddBranchForm from "../components/modals/AddBranchForm.vue";
import Connections from "./Connections.vue";
import accountancySettings from "./accountancySettings.vue";
import branchPosSettings from "./branchPosSettings.vue";
import companySelector from "@/components/elements/companySelector.vue";

export default {
  name: "BranchesSettings",
  components: {
    calendar: Calendar,
    social: Social,
    rooms: Rooms,
    devices: Devices,
    widget: Widget,
    connections: Connections,
    "add-branch-form": AddBranchForm,
    "branch-info": BranchInfo,
    "branch-schedules": BranchSchedules,
    "accountancySettings": accountancySettings,
    "branchPosSettings": branchPosSettings,
    companySelector: companySelector
  },
  data: () => ({
    now: null,
    tab: "branchSettings",
    searchCompanies: "",
    tabTransition: false,
    activeComp: null,
    activeCompName: null,
    activeCompData: new Object(),
    newBranchDialog: false,
    companies: new Array(),
    selectedBranch: new Array(),
    selectedBranchData: new Object(),
    selectedBranchDataOriginal: new Object(),
    branches: new Array(),
    userDataLoaded: false,
    dataloaded: false,
    noData: true,
    branchesLoaded: false,
  }),

  created() {
    this.loadData();
    this.now = moment().format("YYYY-MM-DD");
    if(this.$route.query.tab){
      this.tab = this.$route.query.tab;
    }
  },

  methods: {
    loadData(){
      if(this.$store.state.activeBranch){
        bus.$emit("loadBranchData");
        this.noData = false;
      }
      else{
        this.noData = true;
      }
      this.dataloaded = true;
    },

    async reloadBranches(id){
      this.dataloaded = false;
      await this.$store.dispatch("changeActiveBranchData", id);
      this.tab = 'branchSettings';
      this.loadData();
      this.dataloaded = true;
    },

    updateData() {
      this.loadBranches();
    },

    tabChanged(){ // Query in URl aanpassen bij selecteren van de router.
      history.pushState({},null,this.$route.path + '?tab=' + this.tab)
    }
  },

  computed: {    
    branchInfoChanged() {
      let branchAddress = this.selectedBranchData.branchAddress;
      let logoUrl = this.selectedBranchData.logoUrl;
      let logoUrlOriginal = this.selectedBranchDataOriginal.logoUrl;
      let branchAddressOriginal = this.selectedBranchDataOriginal.branchAddress;
      let branchName = this.selectedBranchData.name;
      let branchNameOriginal = this.selectedBranchDataOriginal.name;
      let branchActive = this.selectedBranchData.active;
      let branchActiveOriginal = this.selectedBranchDataOriginal.active;
      let branchPaymentOptions = this.selectedBranchData.paymentOptions;
      let branchPaymentOptionsOriginal = this.selectedBranchDataOriginal.paymentOptions;
      let branchLoyalty = this.selectedBranchData.loyalty;
      let branchLoyaltyOriginal = this.selectedBranchDataOriginal.loyalty;
      let branchSenderName = this.selectedBranchData.senderName;
      let branchSenderNameOriginal = this.selectedBranchDataOriginal.senderName;
      let branchUrl = this.selectedBranchData.url;
      let branchUrlOriginal = this.selectedBranchDataOriginal.url;
      let branchEmail = this.selectedBranchData.email;
      let branchEmailOriginal = this.selectedBranchDataOriginal.email;
      if (
        _.isEqual(branchAddress, branchAddressOriginal) == false ||
        _.isEqual(branchName, branchNameOriginal) == false ||
        _.isEqual(branchActive, branchActiveOriginal) == false ||
        _.isEqual(branchPaymentOptions, branchPaymentOptionsOriginal) == false ||
        _.isEqual(branchLoyalty, branchLoyaltyOriginal) == false ||
        _.isEqual(branchPaymentOptions, branchPaymentOptionsOriginal) == false ||
        _.isEqual(branchSenderName, branchSenderNameOriginal) == false ||
        _.isEqual(branchUrl, branchUrlOriginal) == false ||
        _.isEqual(branchEmail, branchEmailOriginal) == false ||
        _.isEqual(logoUrl, logoUrlOriginal) == false
      )
      {
        return true;
      }
      else {
        return false;
      }
    },

    branchTimesChanged() {
      let branchTimes = this.selectedBranchData.branchTime;
      let branchTimesOriginal = this.selectedBranchDataOriginal.branchTime;
      let branchDefaultDays = this.selectedBranchData.defaultDays;
      let branchDefaultDaysOriginal = this.selectedBranchDataOriginal
        .defaultDays;
      if (
        _.isEqual(branchTimes, branchTimesOriginal) == false ||
        _.isEqual(branchDefaultDays, branchDefaultDaysOriginal) == false
      ) {
        return true;
      }
      else {
        return false;
      }
    },

    branchSettingsChanged() {
      let branchSettings = this.selectedBranchData.BranchSettings;
      let branchSettingsOriginal = this.selectedBranchDataOriginal
        .BranchSettings;
      if (_.isEqual(branchSettings, branchSettingsOriginal) == false) {
        return true;
      } else {
        return false;
      }
    },
    branchSocialChanged() {
      let branchSocial = this.selectedBranchData.social;
      let branchSocialOriginal = this.selectedBranchDataOriginal.social;
      if (_.isEqual(branchSocial, branchSocialOriginal) == false) {
        return true;
      } else {
        return false;
      }
    },
    branchesColumn(){
      if(this.companies.length > 1) {
        return "md6"
      } else{
        return "md9"
      }
    },
    filteredCompanies(){
      if (this.searchCompanies && this.searchCompanies.length > 0){
        return this.companies.filter(company => {
          return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
        });
      } else {
        return this.companies;
      }
    },
  }
};
</script>

