<template>
  <div>
    <div v-show="loading" style="position: relative; padding: 200px 0;">
      <div class="thrive-loader"></div>
    </div>

    <div v-show="!loading">
      <v-card v-show="rooms.length > 0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" >
        <v-container grid-list-md class=" ma-0 pa-3" fluid>
          <v-layout row wrap>
            <v-flex md4 xs12>
              <v-text-field
                class="mx-1"
                v-model="search"
                name="searchfield"
                :label="$t('general.search', $store.state.locale)"
                background-color="background"
                prepend-inner-icon="mdi-magnify"
                single-line
                dense
                hide-details
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex md8 xs12 class="text-right">
              <v-btn 
              small
                dark
                height="36" 
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addNewroom()" 
                class="mx-1"
                >
                <v-icon left>mdi-plus</v-icon> {{ $t('rooms.newRoom', $store.state.locale) }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider> </v-divider>
        
        <v-data-table
          :headers="headersRooms"
          :items="rooms"
          :search="search"
          item-key="name"
          class="dense-table rooms-table"
          hide-default-footer
          dense
          :itemsPerPage.sync="pages"
          :no-results-text="`${$t('rooms.noResultsFound', $store.state.locale)} ${search}`"
          > 
          <template v-slot:item="{item, index}">
            <tr :key="watcher + index">
            <td class="grap">
              <v-icon size="20" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-arrow-all</v-icon>
            </td> 
              <td>{{ item.name }}
                <div>
                  <v-chip v-if="!$store.state.demoMode && $store.state.userRoleNumber == 0" dark label small  light class="mr-2 mb-1 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="copyVariableToClipboard( item.id, index )">
                    <span v-if="!item.copy">{{ item.id }}</span><span v-else>{{ $t('emails.copied', $store.state.locale)}}</span><v-icon v-if="!item.copy" right size="12">mdi-content-copy</v-icon>
                  </v-chip>
                </div>
              </td>
              <td>
                <v-chip v-for="(device, index) in checkDevices(item.id)" :key="index" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 my-1 custom-chip-style" >{{ device }}</v-chip>
              </td>
              <td class="text-center">
                <v-icon :size="14" class="px-2" :color="activeColor(item.active)">mdi-checkbox-blank-circle</v-icon>
              </td>
              <td class="text-center">
                <v-btn class="mr-1" icon @click="editRoom(item)">
                  <v-icon small color="text">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="deleteSelectedRoomDialog = true, selectedRoomId = item.id, selectedRoomName = item.resourceName">
                  <v-icon small color="text">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <div class="no-data-message text-center" v-if="rooms.length == 0">
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-door</v-icon>
        </v-avatar>
        <p>{{ $t('rooms.noRoomsAddedForBranch', $store.state.locale) }} {{ $store.state.activeBranch.name }} {{ $t('general.added', $store.state.locale) }}</p>
        <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addNewroom()">
          <v-icon left >mdi-plus</v-icon> {{ $t('rooms.newRoom', $store.state.locale) }}
        </v-btn>
      </div>
    </div>
  
    <!-- / New Room Dialog \ -->
    <add-room-form @updateData="loadRooms()"></add-room-form>
    <!-- \ New Room Dialog / -->

    <!-- / Edit Room Dialog \ -->
    <edit-room-form @updateData="loadRooms()"></edit-room-form>
    <!-- \ Edit Room Dialog / -->

    <!-- / Delete Room Dialog \ -->
    <v-dialog v-model="deleteSelectedRoomDialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <h3 class="mb-4">{{ $t('rooms.removeRoom', $store.state.locale) }}</h3> 
          <p class="ma-0">{{ $t('rooms.removeRoomInfo', $store.state.locale) }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="deletingRoom" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="deleteSelectedRoomDialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="deletingRoom" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleteRoom()">
                   {{ $t('rooms.removeRoomButton', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Room Dialog / -->

  </div>
</template>

<script>
import '@firebase/firestore';
import db from "../firebase/init";
import { bus } from "../main";
import Sortable from "sortablejs";
import draggable from "vuedraggable";
import AddRoomForm from "../components/modals/AddRoomForm.vue";
import EditRoomForm from "../components/modals/EditRoomForm.vue";
import moment from "moment";

export default {
  components: {
    "add-room-form": AddRoomForm,
    "edit-room-form": EditRoomForm
  },
  name: "rooms",
  data(){
    return {
      watcher: 0,
      selected: new Array(),
      search: "",
      pages: -1,
      loading: true,
      selectedRoomName: null,
      selectedRoomId: null,
      editRoomDialog: false,
      newRoomDialog: false,
      deleteSelectedRoomDialog: false,
      deletingRoom: false,
      newExceptionDialog: false,
      selectedRoomInfo: {
        defaultDays: {
          Sun: null,
          Mon: null,
          Tue: null,
          Wed: null,
          Thu: null,
          Fri: null,
          Sat: null
        }
      },
      selectedRoomInfoOriginal: new Object(),
      rooms: new Array(),
      devices: new Array(),
      nextRoomId: null,
      now: null,
      headersRooms: [
        {
          text: "",
          align: "left",
          value: "weight",
          sortable: false,
          width: "60px"
        },
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "name",
          width: "250px"
        },
        {
          text: this.$t('general.devices', this.$store.state.locale),
          align: "left",
          value: "name",
          sortable: false
        },
        {
          text: this.$t('general.active', this.$store.state.locale),
          align: "center",
          value: "active",
          sortable: false,
          width: "100px"
        },
        {
          text: "",
          align: "right",
          value: "actions",
          sortable: false,
          width: "110px"
        }
      ],
      colors: [
        "#72D9C4",
        "#ABDEE6",
        "#83C0EC",
        "#BC8DD0",
        "#F39488",
        "#F8DD65",
        "#E89861",
        "#61C1AD",
        "#D7E2EA",
        "#ECEAE4",  
      ],
      resources: new Array(),
      nextResourceId: null,
      paginationRooms: {
        sortBy: "resourceName",
        descending: false
      }
    }
  },

  created() {
    this.loadData();
  },

  mounted() {
    this.$root.$on("branchChanged", () => {
      this.loadData();
    });
  },

  methods: {
    loadData() {
      this.loadDevices();
      this.now = moment().format("YYYY-MM-DD");
    },

    checkDevices(roomId){
      let devices = new Array();
      this.devices.forEach(device=>{
        let index = _.indexOf(device.rooms, roomId);
        if(index >= 0){
          devices.push(device.name);
        }
      })
      return devices;
    },

    activeColor(active){ // Set color green for active or red for inactive
      return active ? this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].quaternary : this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary; 
    },

    copyVariableToClipboard(text, index){
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(()=> {
        this.rooms.forEach(room =>{
          room.copy = false;
        })
        this.rooms[index].copy = true;
        setTimeout(()=>{ 
          this.rooms[index].copy = false;
        }, 1000);

      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },

    loadRooms() {
      this.loading = true;
      db.collection("rooms")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("branches", "array-contains", this.$store.state.activeBranch.id)
      .where("resourceDeleted", "==", false)
      .get()
      .then(snap => {
        this.rooms = new Array();
        snap.forEach(doc => {
          let room = doc.data();
          room.copy = false;
          room.id = doc.id;
          this.rooms.push(room);
        });
      })
      .then(()=>{
        this.loading = false;
        if(this.rooms.length > 1) {
          this.rooms.sort(this.sortRooms);
          this.orderRooms();
        }
      })
    },

    async filterColors(){ // Filter colors to show only colors that are not used
      let usedColors = new Array();
      this.rooms.forEach(room=>{
        if(room.color){
          usedColors.push(room.color);
        }
      });
      let colors = this.colors.filter(val => !usedColors.includes(val));
      return colors;
    },

    addNewroom(){
      this.filterColors().then(colors =>{
        bus.$emit("addRoom");
      })
    },

    editRoom(item) {
      this.filterColors().then((colors) =>{
        let selectedRoomInfo = Object.assign({}, item);
        selectedRoomInfo.color = selectedRoomInfo.color ? selectedRoomInfo.color : null;
        let selectedRoomInfoOriginal = _.cloneDeep(selectedRoomInfo)
        bus.$emit("editRoom", selectedRoomInfo, selectedRoomInfoOriginal);
      })
    },

    deleteRoom() {
      this.deletingRoom = true;
      db.collection("rooms")
        .doc(this.selectedRoomId)
        .update({
          resourceDeleted: true,
          deleted: {
            deletedTime: new Date(),
            deletedby:
              this.userFirstName +
              " " +
              this.userLastName +
              " (" +
              this.userCompany +
              ")"
          }
        })
        .then(() => {
          this.loadRooms();
        })
        .then(() => {
          // Log Action
          db.collection("log")
            .add({
              time: new Date(),
              logType: "info",
              browser: `${platform.name} ${platform.version}`,
              user: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
              userType: this.$store.state.activeUserRole,
              company: this.$store.state.userCompany,
              actionType: "userAction",
              action: "roomDeleted",
              actionItem: this.selectedRoomName,
              extra:[
                {
                  name: "name",
                  value: this.selectedRoomName
                },
                {
                  name: "id",
                  value: this.selectedRoomId
                },
                {
                  name: "companyId",
                  value: this.selectedRoom.companyId
                },
                {
                  name: "branchId",
                  value: this.selectedRoom.branches
                },
              ]
            })
            .then(() => {
              this.deletingRoom = false;
              this.deleteSelectedRoomDialog = false;
              this.selectedRoomId = null;
              this.selectedRoomName = null;
            })
            .catch((error) => {
              console.error("Error posting room delete log:", error);
              this.deletingRoom = false;
              this.deleteSelectedRoomDialog = false;
              this.selectedRoomId = null;
              this.selectedRoomName = null;
            });
        })
        .catch((err) => {
          console.error("Error posting room delete:", err);
          this.deletingRoom = false;
          this.deleteSelectedRoomDialog = false;
          this.selectedRoomId = null;
          this.selectedRoomName = null;
        });
    },

    loadDevices() {
      db.collection("devices")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        this.devices = new Array();
        snap.forEach(doc => {
          let deviceCollection = doc.data();
          deviceCollection.id = doc.id;
          this.devices.push(deviceCollection);
        });
      })
      .then(() => {
        this.loadRooms();
      });
    },

    sortRooms(a, b) {
      // Use toUpperCase() to ignore character casing
      const genreA = a.weight;
      const genreB = b.weight;

      let comparison = 0;
      if (genreA > genreB) {
        comparison = 1;
      } else if (genreA < genreB) {
        comparison = -1;
      }
      return comparison;
    },

    orderRooms() {
      let element = document.querySelector(".rooms-table tbody");
      const vm = this;
      Sortable.create(element, {
        handle: ".grap",
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = vm.rooms.splice(oldIndex, 1)[0]; // Get the selected row and remove it
          vm.rooms.splice(newIndex, 0, rowSelected); // Move it to the new index
          vm.updateOrderRooms();
        }
      });
    },

    updateOrderRooms() {
      this.watcher++
      let rooms = this.rooms;
      rooms.forEach(item => {
        let index = rooms.indexOf(item);
        this.rooms[index].weight = index;
        // Post new weight
        db.collection("rooms")
          .doc(item.id)
          .update({
            weight: index
          });
      });
      this.rooms.sort(this.sortRooms);
    },
  },
};
</script>