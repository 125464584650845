<template>
  <div>
    <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color" top>
      <v-row>
        <v-icon color="white" class="mx-3">{{ snackbar.icon }}</v-icon>
        <span class="py-2 white--text">{{ snackbar.message }}</span>
      </v-row>
    </v-snackbar>

    <div v-if="loading">
      <div style="position: relative; padding: 200px 0">
        <div class="thrive-loader" :class="$store.state.companyTheme"></div>
      </div>
    </div>

    <div v-if="!loading">
      <v-tabs class="mb-4" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="tab" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
        <v-tab><v-icon left>mdi-account</v-icon> {{ $t('general.account', $store.state.locale) }}</v-tab>
        <v-tab><v-icon left>mdi-lock-open-outline</v-icon> {{ $t('user.newPassword', $store.state.locale) }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="transparant-tabs">
        <v-tab-item :value="0">
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
            <v-container grid-list-md class="pa-4" fluid>
              <v-layout row wrap>
                <v-flex md6 xs12>
                  <v-text-field
                    class="pt-4"
                    dense
                    :label="$t('general.name', $store.state.locale)"
                    v-model="userData.firstName"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    hide-details
                    required
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field
                    class="pt-4"
                    :label="$t('general.lastName', $store.state.locale)"
                    v-model="userData.lastName"
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    hide-details
                    required
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field
                    class="pt-4"
                    :label="$t('general.email', $store.state.locale)"
                    disabled
                    readonly
                    v-model="userData.email"
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    hide-details
                    required
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-select
                    :label="$t('general.language', $store.state.locale)"
                    outlined
                    dense
                    class="mt-4"
                    content-class="pa-0 ma-0 mr-4"
                    :items="availableUserlanguages"
                    v-model="userData.language"
                    item-value="languageCode"
                    item-text="code"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    hide-details
                    >
                    <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                      <country-flag :country="data.item.flagCode" size="small"/>
                      <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                      <country-flag :country="data.item.flagCode" size="small"/>
                      <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex md6 xs12>
                  <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :dark="changed" :loading="updateUserInfoBusy" :disabled="!changed" :depressed="!changed" @click="updateUserInfo()">
                    <div v-if="changed">{{ $t('general.saveChanged', $store.state.activeUserLanguage) }} <v-icon right >mdi-chevron-right</v-icon></div>
                    <div v-else> {{ $t('general.saved', $store.state.activeUserLanguage) }} <v-icon right >mdi-check</v-icon></div>   
                  </v-btn>
                </v-flex>
                <v-flex md6 xs12></v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="1">
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
            <v-container grid-list-md class="pa-4" fluid>
              <v-layout row wrap>
                <v-flex md6 xs12>
                  <v-text-field
                    class="pt-4"
                    :label="$t('user.newPassword', $store.state.locale)"
                    v-model="password"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    required
                    dense
                    outlined
                    :rules="[rules.required, rules.min]"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    counter
                  ></v-text-field>

                  <v-text-field
                    class="pt-2"
                    :label="$t('user.confirmPassword', $store.state.locale)"
                    v-model="passwordConfirm"
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    required
                    outlined
                    :rules="[rules.required, rules.min]"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    counter
                  ></v-text-field>
                  <v-alert class="ma-0 mt-2 pa-2" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning" v-if="passwordNotEqual">
                    <span :style="{ color: $vuetify.theme.themes[theme].text }">{{ $t("user.passwordsNotEqual", $store.state.locale) }}</span>
                  </v-alert>

                  <v-btn
                    class="mt-4"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :dark="newPassword"
                    :loading="updatePasswordBusy"
                    :disabled="!newPassword"
                    :depressed="!newPassword"
                    @click="changePassword()"
                    >{{ $t("user.changePassword", $store.state.locale) }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>

  </div>
</template>

<script>
  import "@firebase/firestore";
  import _ from "lodash";
  import db from "../firebase/init";
  import firebase from "firebase/app";
  import "firebase/auth";
  import axios from "axios";

  export default {
    name: "account",
    data() {
      return {
        tab: 0,
        updateUserInfoBusy: false,
        companyPages: -1,
        loading: true,
        userData: null,
        snackbar: {
          color: "",
          timeout: 2000,
          icon: null,
          message: null,
        },
        userDataOriginal: null,
        password: "",
        passwordConfirm: "",
        showPassword: false,
        updatePasswordBusy: false,
        callcenterOnline: null,
        rules: {
          required: (value) => !!value || this.$t("general.required", this.$store.state.locale),
          min: (v) => v.length >= 6 || this.$t("user.sixCharactersPassword", this.$store.state.locale),
        },
      };
    },

    created() {
      this.loadUserData();
    },

    methods: {
      async loadUserData() {
        this.loading = true;
        await db.collection("users")
        .doc(this.$store.state.userName)
        .get()
        .then((doc) => {
          let userdata = doc.data();
          userdata.id = doc.id;
          if (!doc.data().language) {
            userdata.language = "NL";
          }
          this.userData = userdata;
          this.userDataOriginal = _.cloneDeep(this.userData);
        })
        this.loading = false;
      },

      changePassword() {
        this.updatePasswordBusy = true;

        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((idToken) => {
            // API Call to change user password
            axios
              .post("https://europe-west1-thrive-365.cloudfunctions.net/auth", {
                token: idToken,
                type: "changePassword",
                uid: this.userData.user_id,
                password: this.password,
              })
              .then((response) => {
                console.log("response", response);
                console.log("response status", response.status);
                if (response.status == 200) {
                  // Password succesfully changed
                  this.snackbar.active = true;
                  this.snackbar.color = "rgba(1, 230, 110, 0.7)";
                  this.snackbar.icon = "mdi-thumb-up";
                  this.snackbar.message = this.$t("user.passwordSuccessfulChanged", this.$store.state.locale);
                  this.password = "";
                  this.passwordConfirm = "";
                  setTimeout(() => {
                    this.$store.dispatch("userLogout");
                  }, 2000);
                } else {
                  this.snackbar.active = true;
                  this.snackbar.color = "rgba(213, 0, 0, 0.7)";
                  this.snackbar.icon = "mdi-thumb-down";
                  this.snackbar.message = this.$t("user.passwordNotSuccessfulChanged", this.$store.state.locale);
                }
                this.updatePasswordBusy = false;
              })
              .catch((error) => {
                console.error("Password updated niet gelukt: ", error);
                this.updatePasswordBusy = false;
              });
          });
      },

      async updateUserInfo() {
        this.updateUserInfoBusy = true;

        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "updatingCalendarSettingsBranch",
          actionItem: this.$store.state.activeBranch.name,
          extra:[
            {
              name: "originalData",
              value: ""
            },
            {
              name: "userData",
              value: JSON.stringify(this.userDataOriginal)
            },
            {
              name: "changedData",
              value: ""
            },
            {
              name: "userData",
              value: JSON.stringify(this.userData)
            },
          ]
        };

        try{
          await db.collection("users")
          .doc(this.userData.id)
          .set(
            {
              firstName: this.userData.firstName,
              lastName: this.userData.lastName,
              language: this.userData.language,
            },
            { merge: true }
          )
          await this.$createNewLog("info", logData);
        }
        catch(error) {
          console.error("Account bijwerken niet gelukt: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        };
  
        if (this.userData.language != this.userDataOriginal.language) {
          this.$store.dispatch("changeLanguage", this.userData.language);
          location.reload();
        }
        
        this.userDataOriginal = _.cloneDeep(this.userData);
        this.updateUserInfoBusy = false;
      },
    },

    computed: {
      availableUserlanguages(){
        return this.$countries.filter(language => { // Filter rich languages data from languages
          if(language.userLanguage){ return language.id }
        });
      },
      passwordNotEqual() {
        if (this.passwordConfirm) {
          if (this.passwordConfirm == this.password) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      },
      newPassword() {
        if (this.password && this.passwordConfirm == this.password) {
          return true;
        } else {
          return false;
        }
      },
      changed() {
        if (_.isEqual(this.userDataOriginal, this.userData)) {
          return false;
        } else {
          return true;
        }
      }
    },
  };
</script>
