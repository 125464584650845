<template>
  <v-dialog v-model="showDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type == 'new'">{{$t('treatments.newCustomCategory', $store.state.locale)}}</h3>
        <h3 v-if="type == 'edit'">{{$t('treatments.editCustomCategory', $store.state.locale)}}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeCustomTreatmentCategoryDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>
        <v-form lazy-validation>
          <v-text-field
            :label="$t('general.name', $store.state.locale)"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            v-model="customTreatmentCategory.name"
            autocomplete
            required
            dense
            outlined
            class="mt-4"
            hide-details
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                @click="closeCustomTreatmentCategoryDialog()"
                >
                {{$t('general.cancel', $store.state.locale)}}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type == 'new'"
                dark  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addCustomTreatmentCategory()"
                >
                {{$t('general.add', $store.state.locale)}}
                <v-icon right dark>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn
                v-if="type == 'edit'"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="updateCustomTreatmentCategory()"
                >
                <div v-if="changed">{{$t('general.saveChanged', $store.state.locale)}} <v-icon>mdi-chevron-right</v-icon></div>
                <div v-else>{{$t('general.saved', $store.state.locale)}} <v-icon>mdi-check</v-icon></div>  
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
import db from "../../firebase/init";
import '@firebase/firestore';
import { bus } from "../../main";

export default {
  name: "supplier-product-group-form",

  data() {
    return {
      showDialog: false,
      feedback: null,
      searchCompanies: "",
      customTreatmentCategory: new Object(),
      customTreatmentCategoryOriginal: new Object(),
      type: 'new',
    };
  },

  created() {
    bus.$on("customTreatmentTypeModal", (item, type) => {
      this.type = type
      if(type == "edit"){
        this.customTreatmentCategory = _.cloneDeep(item);
        this.customTreatmentCategoryOriginal = _.cloneDeep(this.customTreatmentCategory);
      }
      this.showDialog = true;
    });
  },


  methods: {
    closeCustomTreatmentCategoryDialog() { // Close Dialog Modal
      this.showDialog = false;
      this.resetData();
    },

    resetData(){ // Reset Supplier values and feedback
      this.feedback = null;
      this.customTreatmentCategory.name = "";
    },

    async addCustomTreatmentCategory() { // Add new Supplier to database
      this.feedback = null;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "customTreatmentCategoryAdded",
        actionItem: this.customTreatmentCategory.name,
        extra: [
          {
            name: "name",
            value: this.customTreatmentCategory.name
          }
        ]
      };
      if (this.customTreatmentCategory.name) {
        try {
          await db.collection("companies")
          .doc(this.$store.state.activeCompany.id)
          .collection("customTreatmentTypes")
          .add({
            name: this.customTreatmentCategory.name,
            order: 999,
            deleted: {
              deleted: false
            }
          })

          await this.$createNewLog("info", logData);

          this.$emit("updateData", this.productSupplierId);
          this.closeCustomTreatmentCategoryDialog();
        }
        catch (error) {
          this.feedback = this.$t('general.addError', this.$store.state.locale);
          console.error("Error adding Custom Treatment Category: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      } 
      else {
        this.feedback = this.$t('products.addSupplierFeedback', this.$store.state.locale);
      }
    },

    async updateCustomTreatmentCategory() { // Save changed Supplier to database
      this.feedback = null;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "customTreatmentCategoryAdded",
        actionItem: this.customTreatmentCategory.name,
        extra:[
          {
            name: "originalData",
            value: ""
          },
          {
            name: "name",
            value: this.customTreatmentCategoryOriginal.name
          },
          {
            name: "changedData",
            value: ""
          },
          {
            name: "name",
            value: this.customTreatmentCategory.name
          }
        ]
      };
      if(this.customTreatmentCategory.name){
        try { 
          await db.collection("companies")
          .doc(this.$store.state.activeCompany.id)
          .collection("customTreatmentTypes")
          .doc(this.customTreatmentCategory.id)
          .set({
            name: this.customTreatmentCategory.name,
          },{merge: true})

          await this.$createNewLog("info", logData);

          this.$emit("updateData", this.productSupplierId);
          this.closeCustomTreatmentCategoryDialog();
        }
        catch (error) {
          this.feedback = this.$t('general.addError', this.$store.state.locale);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      }
      else{
        this.feedback = this.$t('products.editSupplierFeedback', this.$store.state.locale);
      }
    },
  },
  computed: {
    filteredCompanies(){
      if (this.searchCompanies && this.searchCompanies.length > 0){
        return this.companies.filter(company => {
          return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
        });
      }
      else {
        return this.companies;
      }
    },
    changed() {
      if (
        _.isEqual(this.customTreatmentCategory, this.customTreatmentCategoryOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>