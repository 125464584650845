<template>
  <!-- / Delete source dialog \ -->
    <v-dialog v-model="informClientCalendarModal" width="550" :content-class="`custom-dialog ${$store.state.companyTheme}`" persistent>
      <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3 style="display: block; width: 100%" class="text-center">{{ $t('clients.informClientTitle', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="text-center">
          <v-avatar
            size="58"
            light
            class="ma-2 mb-4 mt-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email</v-icon>
          </v-avatar>

          <v-avatar
            size="58"
            light
            class="ma-2 mb-4 mt-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
            >
            <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-cellphone-text</v-icon>
          </v-avatar>
          <p v-if="informClientCalendarType == 'appointmentCancel'" style="display: block; text-align: center !important;">{{ $t('clients.informClientRemoved', $store.state.locale) }}</p>
          <p v-if="informClientCalendarType == 'appointmentConfirmDownPayment'" style="display: block; text-align: center !important;">{{ $t('clients.informClientNewAppointmentDownPayment', $store.state.locale) }}</p>
          <p v-if="informClientCalendarType == 'appointmentConfirm'" style="display: block; text-align: center !important;">{{ $t('clients.informClientNewAppointment', $store.state.locale) }}</p>
          <p v-if="informClientCalendarType == 'appointmentChanges'" style="display: block; text-align: center !important;">{{ $t('clients.informClientTimeChanged', $store.state.locale) }}</p>
         

          <div v-if="informClientCalendarType == 'appointmentConfirmDownPayment'">
            <h3 class="pa-2">{{ $t('booking.extraDownPaymentInformation', $store.state.locale) }}</h3>
            <p>{{ $t('booking.extraDownPaymentInformationInfo', $store.state.locale) }}</p>

            <v-card outlined>
              <div class="pa-3">
                <h5 class="pb-2">{{ $t('booking.downPaymentReasons', $store.state.locale) }} </h5>
                <div class="pb-1">
                  <v-chip v-for="(downPaymentReason, index ) in downPaymentData.downPaymentReasons" :key="index" class="my-1 mr-2 custom-chip-style" small :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][downPaymentReason.conditionType == 'client' ? 'primary' : 'tertiary']" > 
                    {{ $t(`booking.downPaymentAmountReasons.${downPaymentReason.type}`, $store.state.locale) }}
                    <span v-if="downPaymentReason.type == 'tag'" style="font-size: 12px">: <i>{{ downPaymentReason.name }}</i></span>
                  </v-chip> 
                </div>

                <h5 class="py-2">{{ $t('booking.downPaymentAmountDetails', $store.state.locale) }} </h5>
                <p class="ma-0">
                  <strong>{{ $t('general.percentage', $store.state.locale) }}:</strong>  {{ $store.state.activeBranch.widgetSettings.downPayments.percentage }}% <br> 
                  <strong>{{ $t('branch.downPaymentMinimum', $store.state.locale) }}:</strong>  &euro;{{ $store.state.activeBranch.widgetSettings.downPayments.minimum.toFixed(2) }}<br>
                  <strong> {{ $t('branch.downPaymentMaximum', $store.state.locale) }}:</strong> &euro;{{ $store.state.activeBranch.widgetSettings.downPayments.maximum.toFixed(2) }}
                </p>
              </div>
            </v-card>  
          </div>

          <p class="my-2 mb-0" v-if="$store.state.userRoleNumber < 2" style="display: block; text-align: center !important" v-html="$t('clients.informClientSettingsHint', $store.state.locale)"></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6>
                <v-btn :dark="!loading && informClientCalendarType != 'appointmentConfirmDownPayment'"  outlined block :disabled="loading || informClientCalendarType == 'appointmentConfirmDownPayment'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  @click="informClientCalendarModal = false">{{ $t('clients.sendNoMessage', $store.state.locale) }}</v-btn>
              </v-flex>
              <v-flex xs12 md6 class="px-1">
                <v-btn class="mx-1" :loading="loading" dark block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="informClientCalendar()">{{ $t('clients.sendMessage', $store.state.locale) }}</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete source dialog / -->
</template>


<script>
import axios from "axios";
import { bus } from "../../main";

export default {
  name: "inform-client-modal",
  data() {
    return {
      informClientCalendarModal: false,
      informClientCalendarDocId: null,
      informClientCalendarType: 'appointmentChanges',
      activeBranch: null,
      clientId: null,
      downPaymentData: null,
      loading: false
    };
  },
  created(){
    bus.$on("showInformClientModal",(messageType, activeBranch, docId, clientId, downPaymentData)=>{
        this.informClientCalendarDocId = docId;
        this.informClientCalendarModal = true;
        this.clientId = clientId ? clientId : null;  
        this.activeBranch = activeBranch;
        this.informClientCalendarType = messageType;
        this.downPaymentData = downPaymentData ? downPaymentData : { downPaymentAmount: null};
      })
  },

  methods: {
    async informClientCalendar() {
      this.loading = true;
      await axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, {doc: this.informClientCalendarDocId, clientId: this.clientId, branchId: this.activeBranch, type: this.informClientCalendarType, amount: this.downPaymentData.downPaymentAmount})
      .then((response) => {
        if (response) {
          // Email succesfully sent!
          this.loading = false;
        }
      })
      .catch((error) => {
        this.loading = false;
        console.error(error.message);
      });
      
      this.informClientCalendarModal = false;
      this.informClientCalendarDocId = null;
    },
  }
};
</script>
