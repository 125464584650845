<template>
  <v-app>
    <section :style="{backgroundColor: $vuetify.theme.themes[theme].background}" style="height: 100%;">
      <div v-if="loading" style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>

      <section v-if="!loading">
        <v-form ref="form" v-model="valid" v-if="packageFounded">
          <v-card class="subscription-card">
              <div class="pa-4">
                <v-img
                    class="mx-auto white white"
                    v-if="companyData.logoUrl"
                    :src="companyData.logoUrl"
                    max-width="80"
                    >
                    <template v-slot:placeholder>
                        <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        >
                        <v-progress-circular indeterminate color="white grey"></v-progress-circular>
                        </v-row>
                    </template>
                    
                </v-img>
                <p class=" pt-2 ma-0 title black--text text-center">{{companyData.name}}</p>
            </div>
            <div class="pt-2 text-center" id="description">
                <h2 style="font-size: 32px!important; font-weight: 500;" class=" black--text ma-0">{{ currentPackage.name }}</h2>
                <p class="font-weight-light">{{currentPackage.description}}</p>
            </div>

            <div class="pa-5 mx-5" :style="{backgroundColor: $vuetify.theme.themes[theme].background}">
                <p class="title black--text text-center">{{labels.general[language]}}</p>
                <v-container grid-list-md class="pa-0" fluid>
                    <v-layout row wrap>
                        <v-flex xs4><strong>{{labels.paymentPeriod[language]}}</strong></v-flex>
                        <v-flex xs8>{{currentPackage.paymentPeriode}}</v-flex>
                        <v-flex xs4><strong>{{labels.type[language]}}</strong></v-flex>
                        <v-flex xs8>{{currentPackage.packageType}}</v-flex>
                        <v-flex xs4><span style="font-size: 22px!important; font-weight: 700;">{{labels.price[language]}}</span></v-flex>
                        <v-flex xs4><span style="font-size: 22px!important; font-weight: 700;">€{{totalPrice}}</span></v-flex>
                        <v-flex xs4>
                            <div class="mt-2">
                                {{labels.vatIncluded[language]}}
                                {{vat == null ? 0 : vat.percentage}}%
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>

            <div class="pa-5 ma-2" >
              <p class="title black--text text-center mt-5 mb-5">{{labels.personalInformation[language]}}</p>
              <v-container grid-list-md class="pa-0" fluid>
                <v-layout row wrap>
                   <v-flex xs12 >
                    <v-text-field
                        :label="labels.name[language]"
                        v-model="name"
                        prepend-icon="mdi-account"
                        :rules="nameRules"
                        background-color="background"
                        :success="valid"
                        outlined
                        :change="storeLocally()"
                        required
                    ></v-text-field>
                   </v-flex>
                   <v-flex md6 xs12>
                       <v-text-field
                        :label="labels.email[language]"
                        v-model="email"
                        prepend-icon="mdi-mail"
                        :rules="emailRules"
                        background-color="background"
                        :success="valid"
                        :change="storeLocally()"
                        outlined
                        required
                        ></v-text-field>
                   </v-flex>
                   <v-flex md6 xs12>
                       <v-text-field
                        :label="labels.phone[language]"
                        v-model="phone"
                        :rules="telefoonRules"
                        prepend-icon="mdi-phone"
                        :success="valid"
                        background-color="background"
                        :change="storeLocally()"
                        outlined
                        required
                        ></v-text-field>
                   </v-flex>
                   <v-flex md6 xs12>
                       <v-text-field
                        :label="labels.iban[language]"
                        v-model="IBAN"
                        background-color="background"
                        prepend-icon="mdi-card"
                        :rules="ibanRules"
                        :success="valid"
                        :change="storeLocally()"
                        outlined
                        required
                       ></v-text-field>
                   </v-flex>
                </v-layout>
                <v-layout row wrap>
                   <v-flex md6 xs12>
                      <v-dialog
                        :content-class="`custom-dialog ${$store.state.companyTheme}`"
                        ref="dialog"
                        v-model="dateDialog"
                        :return-value.sync="startDate"
                        :success="valid"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="startDate"
                            :label="labels.start[language]"
                            prepend-icon="mdi-calendar"
                            background-color="background"
                            :change="storeLocally()"
                            hide-details
                            readonly
                            outlined
                            :success="valid"
                            :rules="[v => !!v || 'Startdatum is verplicht!']"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" no-title >
                        <v-spacer></v-spacer>
                        <v-btn text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="dateDialog = false">{{labels.cancel[language]}}</v-btn>
                        <v-btn flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="$refs.dialog.save(startDate)">{{labels.select[language]}}</v-btn>
                        </v-date-picker>
                    </v-dialog>
                   </v-flex>
                </v-layout>
                <v-layout row wrap>
                   <v-flex md6 xs12>
                       <v-checkbox
                        v-model="sendInvoice"
                        hide-details
                        :label="labels.emialInvoice[language]"
                        :change="storeLocally()"
                        ></v-checkbox>
                   </v-flex>
                </v-layout>
              </v-container>

            </div>

            <div class="pa-5 ma-2">

              <p class="title black--text text-center mt-5 mb-5">{{labels.terms[language]}}</p>
              <div class="terms">
                {{currentPackage.terms[0].terms[language]}}
              </div>
   
                <v-checkbox
                  v-model="termsAgree"
                  :label="labels.termsAgree[language]"
                  :change="storeLocally()"
                  :rules="[v => !!v || labels.termsAgreeRule[language]]"
                ></v-checkbox>
            </div>

            <v-divider></v-divider>
            <v-card-actions class="hogans-modal-footer clear">
              <!-- <v-btn outlined light color="green" @click="validate" :disabled="valid">Validieren</v-btn> -->
              <v-btn
                :disabled="!valid"
                color="secondary"
                rounded
                block
                @click="addSubscription() && storeLocally()"
              >
                {{ labels.startSubscription[language] }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </section>
    </section>
  </v-app>
</template>


<script>
import '@firebase/firestore';
import slugify from "slugify";
import db from "../firebase/init";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

export default {
  name: "Subscription",
  // props:{
  //   activeCompData: Object,
  //   type: String
  // },

  data() {
    return {
      currentPackage: null,
      packageID: null,
      packageFounded: false,
      locallyStored: false,
      dateDialog: false,
      valid: false,
      loading: true,
      totalPrice: null,
      vat: null,
      emailRules: [
        v => !!v || this.labels.emailRules[this.language],
        v => /.+@.+\..+/.test(v) || this.labels.emailCheck[this.language],
      ],
      nameRules: [v => !!v || this.labels.nameRules[this.language]],
      telefoonRules: [
        v => !!v || this.labels.phoneRules[this.language],
        v => /^0[0-9]{9}$/i.test(v) || this.labels.phoneCheck[this.language]
      ],
      ibanRules: [
        v => !!v || this.labels.ibanRules[this.language],
        v => /^[A-Z]{2}[0-9A-Z]*$/.test(v) || this.labels.ibanCheck[this.language]
      ],
      termsAgree: false,
      labels: {
          phoneRules: {
              en: "Phonenumber is required.",
              nl: "telefoonnummer is verplicht"
          },
          phoneCheck: {
              en: "Phonenumber not correct",
              nl: "Telefoonnummer is niet juist"
          },
          nameRules: {
              en: "Name & Surname are required.",
              nl: "Voor en achternaam zijn verplicht"
          },
          emailRules: {
              en: "Emailaddress is required.",
              nl: "E-mail is verplicht"
          },
          emailCheck: {
              en: "Emailaddress not correct",
              nl: "E-mail is niet juist"
          },
          ibanRules: {
              en: "IBAN is required.",
              nl: "IBAN is verplicht"
          },
          ibanCheck: {
              en: "IBAN not correct",
              nl: "IBAN is niet juist"
          },
          
          name: {
              en: "Name & Surname",
              nl: "Voor en achternaam"
          },
          email: {
              en: "Emailaddresss",
              nl: "E-mail"
          },
          phone: {
              en: "Phonenumber",
              nl: "Telefoonnummer"
          },
          iban: {
              en: "IBAN-number",
              nl: "IBAN-nummer"
          },
          start: {
              en: "Start date",
              nl: "Startdatum"
          },
          emialInvoice: {
              en: "Send invoice by email",
              nl: "Stuur factuur via e-mail"
          },
          general: {
              en: "Subscription information",
              nl: "Abonnement informatie"
          },
          type: {
              en: "Type",
              nl: "Type"
          },
          start: {
              en: "Start date",
              nl: "Startdatum"
          },
          price: {
              en: "Price",
              nl: "Prijs"
          },
          paymentPeriod: {
              en: "Payment period",
              nl: "Betaalperiode"
          },
          vatIncluded: {
              en: "VAT included: ",
              nl: "Inclusief BTW: "
          },
          personalInformation: {
              en: "Personal information",
              nl: "Persoonlijke informatie"
          },
          terms: {
              en: "Terms and conditions",
              nl: "Algemene voorwaarden"
          },
          termsAgree: {
              en: "I agree on the terms and conditions of this subscription.",
              nl: "Ik ga akkoord met de Algemene voorwaarden."
          }, 
          termsAgreeRule: {
              en: "You are required to accept terms and conditions.",
              nl: "U bent verplicht om Algemene woorwaarden te accepteren."
          },
          startSubscription: {
              en: "Subscribe",
              nl: "Abonnement afsluiten"
          },
          cancel: {
              en: "Cancel",
              nl: "Annuleren"
          }, 
          select: {
              en: "Select",
              nl: "Selecteren"
          },       
      },
      sendInvoice: false,
      startDate: null,
      language: "nl",
      name: null,
      email: null,
      phone: null,
      IBAN: null,
      storageData: null,
      client: null,
      clientExist: false,
      sent: false,
      companyData: null
    };
  },

  created() {
    this.packageID = null;
    this.scanUrl(); //after this step the (packageID) value will be equal to the url package id
  },

  methods: {
    scanUrl() {
      if (this.$route.params.language) {
        this.language = String(this.$route.params.language).toLowerCase();
      } 
      else{
        this.language = 'nl';
      }
      if (this.$route.params.packageId) {
        this.packageID = this.$route.params.packageId;
        this.getPackage();
        this.fetchStorageData();
      }
      else {
        this.loading = false;
      }
    },

    //Load a package from DB depends on package id
    getPackage() {
      db.collection("membershipPackages")
        .doc(this.packageID)
        .get()
        .then(doc => {
          if (doc.exists && !doc.data().deleted) {
              this.getCompanyData(doc.data().companyId)
            this.currentPackage = doc.data();
            
            this.packageFounded = true;
            
          } 
          else {
            console.log("No such document!");
          }
        })
        .catch(err => {
          console.error(err);
        });
    },

    getCompanyData(company) {
      db.collection("companies")
        .doc(company)
        .get()
        .then(doc => {
          if (doc.exists && !doc.data().deleted) {
            this.companyData = doc.data();
            this.fetchVat();         
          } 
          else {
            console.log("No such company");
          }
        })
        .catch(err => {
          console.error(err);
        });
    },

    fetchVat() {
      db.collection("vat")
        .doc(this.currentPackage.vat)
        .get()
        .then(doc => {
          this.vat = doc.data();
        })
        .then(() => {
          this.totalPrice =
            (this.vat.percentage * this.currentPackage.amount) / 100 +
            this.currentPackage.amount;
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
        });
    },

    addSubscription() {
      //1-cjeck if client already exist in the system
      db.collection("clients")
        .where("email", "==", this.email)
        .get()
        .then(snap => {
          //no client founded in snap
          if (snap.size == 0) {
            this.clientExist = false;
          } else {
            //client founded in snap
            snap.forEach(doc => {
              this.client = doc.data();
              this.client.clientId = doc.id;
              this.clientExist = true;
              return;
            });
          }
        })
        .then(() => {
          if (this.clientExist) {
            //print log message TEST
            console.log(
              "client is already exist in the system : ",
              this.client.name + " " + this.client.surname
            );
            console.log("client : ", this.client);

            //check if this client have already
          } else {
            //print log message TEST
            console.log("client is not exist in the system...");

            let slugClientEmail = slugify(this.email, {
              remove: /[*+~.()'"!:@]/g,
              lower: true
            });

            //Add as new client
            this.client = {
              adress: {},
              clientId: slugClientEmail,
              companies: [this.currentPackage.companyId],
              email: this.email,
              emailSubscriptions: {},
              health: {},
              name: this.name.split(" ")[0],
              surname: this.name.split(" ")[1]
                ? this.name.substr(this.name.indexOf(" ") + 1)
                : ""
            };
            //Adding new client
            db.collection("clients")
              .doc(slugClientEmail)
              .set(this.client)
              .then(() => {
                console.log(
                  "client added succefully as new client",
                  this.client
                );
              })
              .catch(err => {
                console.error("Error while adding new client...");
              });
          }
        })
        .then(() => {
          //Add membership to the database
          db.collection("memberships").add({
            companyId: this.currentPackage.companyId,
            clientId: this.client.clientId,
            created: moment().format(),
            status: {
              status: "active",
              type: this.currentPackage.membershipType,
              endDate: null
            },
            paymentPeriode: this.currentPackage.paymentPeriode,
            paymentDay: this.currentPackage.processingAt,
            paymentFrequency:
              this.currentPackage.frequency !== null
                ? this.currentPackage.frequency
                : "no frequency",
            paymentTypes: this.currentPackage.paymentTypes,
            freePeriode: {
              number: null,
              typ: null,
              active: null
            },
            trailPeriode: {
              number: null,
              typ: null,
              active: null
            },
            startDate: moment(this.startDate).format(),
            expireDate: null,
            purchaseDate: null,
            name: this.currentPackage.name,
            description: this.currentPackage.description,
            sendInvoice: this.sendInvoice,
            paymentReminder: this.currentPackage.paymentReminder,
            creditSettings: {
              number: this.totalPrice,
              periode: this.currentPackage.paymentPeriode,
              treatments: this.currentPackage.linkedTreatments,
              active: true
            },
            mandate: this.currentPackage.mandateTypes[0].name
          });
        })
        .then(() => {
          //Confirm succeful membership insert
          console.log("Membership added succefully to the system.");
          Swal.fire("Verzonden", "Bedankt voor uw aanmelding", "success");
          this.sent = true;
          this.reset();
        })
        .catch(err => {
          console.error("Error detected while checking user exist.");
        });
    },

    //Store user session locally in the local storage
    storeLocally() {
      if (!this.sent) {
        let data = {
          docID: this.packageID,
          sendInvoice: this.sendInvoice,
          startDate: this.startDate,
          name: this.name,
          email: this.email,
          phone: this.phone,
          IBAN: this.IBAN
        };
        localStorage.setItem("userSession", JSON.stringify(data));
      }
    },

    fetchStorageData() {
      this.storageData = JSON.parse(localStorage.getItem("userSession"));
      this.packageID = this.storageData.docID;
      this.name = this.storageData.name;
      this.email = this.storageData.email;
      this.phone = this.storageData.phone;
      this.IBAN = this.storageData.IBAN;
      this.startDate = this.storageData.startDate;
      this.sendInvoice = this.storageData.sendInvoice;
    },

    

    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    }
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      if(this.$store.state.companyTheme){
        return this.$store.state.companyTheme;
      }
      else{
        return "thrive"
      }
    }
  }
  
};
</script>

<style scoped>
.title {
  color: white;
}
.fixed {
  position: absolute;
}
.subtitleList {
  font-size: 1.25rem;
}
.v-expansion-panel-content {
  width: 80%;
  margin: 0 auto;
}
.dataTable {
  display: table;
  width: 100%;
  border: 1px solid black;
}
.tableRow {
  display: table-row;
}
.tableRow:hover {
  background: rgba(0, 0, 0, 0.1) !important;
  cursor: pointer;
}
.tableCol {
  display: table-column;
}
.tableCell {
  display: table-cell;
  padding: 1%;
}
.terms {
  margin: 0 auto;
  height: 200px;
  padding: 2.5%;
  background: var(--v-background-base);
  overflow: auto;
  overflow-x: hidden;
}


.subscription-card{
    max-width: 750px;
    width: 100%;
    margin: 30px auto;
}

@media only screen and (max-width: 750px) {
  .subscription-card{
        width: 100%;
        margin: 0 auto;
        border-radius: 0!important;
    }

}
</style>
