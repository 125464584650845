<script>
import { bus } from "../main";
import lodash from "lodash";
import moment from "moment";
import axios from "axios";
import db from "../firebase/init";
import firebase from "firebase/app";
import "firebase/firestore";
import { membershipValidation } from "../modules/memberships";
import { reCalculateLoyalty } from "../modules/loyalty";
import { scanDiscounts, quickValidate } from "../modules/POS";
import { receiptBuilder } from "../modules/receipt";

import { deepCopy, cashConv, formatToCurrency, formatToCleanNumber } from "../modules/quickWins";
import uniqid from "uniqid";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite-min.css";
import CustomerInformationDialog from "../components/modals/CustomerInformationDialog.vue";
import membershipInformation from "./membershipInformation.vue";
import AddClientCard from "../components/modals/AddClientCard.vue";
import companySelector from "@/components/elements/companySelector.vue";
import { generateMeshAvatar } from "@/modules/avatarGen.js";
import Insights from '@/modules/insights.js'
import aa from 'search-insights';
// import print from "print-js";

export default {
	
	
	name: "Pay",
	modules: { membershipValidation },
	components: {
		"membership-information": membershipInformation,
		"customer-information-dialog": CustomerInformationDialog,
		"add-client-form" : AddClientCard,
		companySelector: companySelector,
		Insights: Insights
	},

	data() {
		return {
			inputTimer: null,
			today: moment().format("dddd D MMMM YYYY"),
			now: moment().format("YYYY-MM-DD"),

			paymentTable: new Object(),
			searchCompanies: "",
			algoSearch: algoliasearch(
				"B4XU2Z5BAW", 
				"a29d0e9160e36dc8ee83d5888c28cdcc", 
				this.$store.state.activeCompany.id
			),

			// algoSearch: {
			// 	apiKey: "a29d0e9160e36dc8ee83d5888c28cdcc",
			// 	applicationID: "B4XU2Z5BAW",
			// 	search(requests) {  }
			// },

			//Discount Module
			usedModifiers: new Array(),
			usedModifiersReceipt: new Array(),
			deletedModifiers: new Array(),
			advicedModifiers: new Array(),
			feedbackArray: new Array(),
			feedbackHistory: false,
			customModifiers: new Array(),
  			deletedSelfGeneratedItems: new Array(),
			posLocation: localStorage.getItem("posLocation") ? JSON.parse(localStorage.getItem("posLocation")) : {active: true, name: null, id: "general", deleted: false},
			combinedModifiers: new Array(),
			activeTab: "pay",
			tab: 0,
			sidebarTab: 0,
			tabTransition: false,
			clientReceiptDataLoading: false,
			loadingDataTable: false,
			loadMemberships: false,
			selfGeneratedReceipt: false,
			snapshotListenerloadBookingSummarys: null,
			snapshotListenerloadReceipts: null,
			componentKey: 0,
			tempReceiptKey: 0, //auto refresh new temps
			resources: new Array(),
			step: 1,
			productSearchh: null, //LEGACY
			treatmentSearchh: null, //LEGACY
			companies: new Array(),
			preferredPaymentDevice: null, //First preferedd Payment Option as ID
			dataloaded: false,
			branches: new Array(),

			indexedClients: null,
			indexedClientsFound: new Array(),
			searchClientLegacy: "",
			loaderSearch: false,
			vatItems: new Array(),
			clientnameReceipt: "",
			selectedCashRegister: "general", // Selected register of branch 
			productCategories: new Array(),
			selectedCategoryProducts: new Array(), //Sorted Catogory by Supplier
			productSuppliers: new Array(),
			
			selectedSupplier: "", //Selected Supplier
			selectedSupplierGroup: "",
			selectedSupplierCategory: new Array(), //Categories selected and renderd by Supplier
			selectedSupplierGroups: new Array(), //Categories selected and renderd by Supplier
			productsFiltered: new Array(),
			productsByBranch: new Array(),
			productsByBranchClone: new Array(),
			treatmentsByBranch: new Array(),
			treatmentsByBranchClone: new Array(),
			customRuleByBranch: new Array(),
			customRuleByBranchClone: new Array(),

			filterType: 'customTreatmentType', // Kan later ook category zijn
			treatmentTypes: new Array(),
			treatmentsFiltered: new Array(),
			selectedTreatmentType: null,

			// selectedReceiptId: null,
			selectedItems: new Array(),
			clientId: "", //For Selected ClientId
			clientLoyaltyDocs: new Array(),
			searchboxLabel: "Zoek een klant",
			selectedReceipt: null,
			selectedReceiptId: "", //For updating Drafts
			localBookingsummary: new Array(),
			clients: new Array(), //Unpaid clients
			localBookings: new Array(),
			resources: new Array(), //Branch resources
			byResource: null,
			resourcesChecker: new Array(), //Update with the newest Resource movements
			localReceiptDrafts: new Array(),
			headerReceiptDrafts: new Array(),
			headerReceiptDraftsSearch: new Array(),
			isSearchingHeader: false,
			
			tmpReceiptData: null,
			customRuleOption: 0,

			anonymousClient: {
				dialog: false,
				paymentType: null,
			},

			selectedPrinter: null,
			printerStatus: null,
			printerStateReason: {
				printerStateReasons: [
					{
						reason: "Printer is not installed",
						reasonCode: "printer-not-installed",
						code: 0,
					},
				],
			},
			printAndMailDialog: {
				loaderSettings: true,
				busy: false,
				dialog: false,
				printerReady: false,
				options: new Array(), //printer options in payReceipt() and supplemented in checkPrinterStatus();
				// options: [
				// 	{ name: {nl: "<strong>Geen bon</strong><br> nodig", en: "<strong>No receipt</strong><br> needed"}, value: "noReceipt", icon:  "mdi-leaf", color: "#4CAF50" },
				// 	{ name: {nl: "Bon<br> <strong>mailen</strong>", en: "<strong>Email</strong> <br> receipt",}, value: "emailReceipt", icon:  "mdi-email-fast", color: "#4CAF50"},
				// 	{ name: {nl: "Bon<br> <strong>printen</strong>", en: "<strong>Print</strong><br> receipt"}, value: "printReceipt", icon:  "mdi-receipt", color: null },
				// 	{ name: {nl: "Bon <strong>printen</strong><br> en <strong>mailen</strong>", en: "<strong>Print</strong> and <strong>mail</strong> receipt"}, value: "printEmailReceipt", icon:  "mdi-receipt-text-plus", color: null },
								
				// ],
				chosenReceiptOption: "emailReceipt",
			},
			noPaymentOptionsDialog: false,
			toggleProductTreat: false,
			indexer: null, //Indexer selectedItem for modified posInputDiscount
			expander: false,
			modifiers: new Array(),
			modifiersClone: new Array(),
			selectedType: "",
			bookingSumArr: new Array(), //Summary of client
			chosenTreatInvents: new Array(), //All chosen Treatments and inventory
			totalNetto: 0,
			clientSelectedPage: 1,
			totalBruto: 0,
			clientLoading: true,
			totalDiscountVAT: 0,
			totalBrutoWithoutDiscount: 0, //Total price orignial without discounts
			totalVAT: 0,
			totalBrutoTwoDigits: 0, //Total price with 2 digits after comma for Pay Dialog
			totalVats: new Array(),
			totalBrutoAbsolute: 0,
			changeBrutoToClient: 0, //For Refund Cash
			changeForRefund: 0, //Change for Refund
			typeDiscount: ["Percentage", "Bedrag"],

			sortDiscountOrder: [
				{ priorityNumber: 3, typeDiscount: "membershipDiscount" },
				{ priorityNumber: 0, typeDiscount: "prePayment" },
				{ priorityNumber: 1, typeDiscount: "prePaymentDiscount" },
				{ priorityNumber: 2, typeDiscount: "prePaymentManualAdded" },
				{ priorityNumber: 4, typeDiscount: "discount" },
			],
			//Custom POS Rule

			customDialog: false,
			customRule: {
				name: "",
				description: "",
				priceBruto: 0, //bruto,

				amount: 1,
				resourceId: null,
				vat: 0,
			},
			differenceCash: 0,
			differencePin: 0,
			differenceCreditcard: 0,
		

			scanDiscountDialog: false,
			scanDiscountDialogObject: {
				barcode: null,
			},
			discountBarcode: null,
			payDialog: false,
			expensesDialog: false,
			depositDialog: false,
			cashUpDialog: false,
			cashUpFeedback: { box: null, message: null, loading: false },
			cashUpSumCash: 0,
			cashUpSumTotal: 0,
			cashUpSumByCard: {
				byPIN: 0,
				byCreditcard: 0,
			},

			lastCashUpTimestamp: null,
			latestCashUpCash: 0,
			latestCashRounding: 0,
			cashStatementStayManualChanged: false,

			paidReceiptsCashUp: new Array(),

			cashStatement: 0,
			cashStatementStay: {
				value: 0,
				feedback: "",
				error: false,
				depositMoneyToBank: 0,
			},

			pinIncomeStatement: 0,
			cashIncomeStatement: 0,
			cardStatement: 0,
			creditcardIncomeStatement: 0,

			cashUpDifference: 0,
			cashUpDepositToBank: 0,
			viewReceiptData: {
				index: null,
				data: null,
				dialog: false,
				loading:false
			},
			itemsPerPage: -1,
			historyPayments: new Array(),
			historyPaymentsDate: null,
			historyPaymentsDateModal: false,
			receiptsLoading: true,
			viewReceiptDataHeader: [
				{
					text: this.$t('general.name', this.$store.state.locale),
					align: "left",
					value: "id",
					sortable: false, 
				},
				{
					text: this.$t('general.type', this.$store.state.locale),
					align: "left",
					value: "typeItem",
					width: "100px",
					sortable: false, 
				},
				{
					text: this.$t('general.vat', this.$store.state.locale),
					align: "left",
					value: "percentageVat",
					width: "80px",
					sortable: false, 
				},
				{
					text: this.$t('payment.transaction', this.$store.state.locale),
					align: "left",
					value: "transaction",
					width: "70px",
					sortable: false,
				},
				// {
				// 	text: "Originele prijs ex. BTW",
				// 	align: "left",
				// 	value: "priceNetto",
				// 	width: "100px",
				// 	sortable: false, 
				// },
				// {
				// 	text: "Originele prijs incl. BTW",
				// 	align: "left",
				// 	value: "priceBruto",
				// 	width: "100px",
				// 	sortable: false, 
				// },
				// {
				// 	text: this.$t('payment.newPriceTotalNetto', this.$store.state.locale),
				// 	align: "right",
				// 	value: "newPriceTotalNetto",
				// 	width: "200px",
				// 	sortable: false, 
				// },
				{
					text: this.$t('payment.newPriceTotalBruto', this.$store.state.locale),
					align: "right",
					value: "newPriceTotalBruto",
					width: "200px",
					sortable: false, 
				},
			],
			viewExpenseDataHeader: [
				{
					text: this.$t('general.name', this.$store.state.locale),
					align: "left",
					value: "id",
					sortable: false, 
				},
				{
					text: this.$t('general.vat', this.$store.state.locale),
					align: "left",
					value: "percentageVat",
					width: "80px",
					sortable: false, 
				},
				{
					text: this.$t('payment.transaction', this.$store.state.locale),
					align: "left",
					value: "transaction",
					width: "70px",
					sortable: false,
				},
				{
					text: this.$t('payment.newPriceTotalBruto', this.$store.state.locale),
					align: "right",
					value: "newPriceTotalBruto",
					width: "200px",
					sortable: false, 
				},
			],
			historyPaymentsHeader: [
			{
				text: this.$t('general.date', this.$store.state.locale),
				align: "left",
				value: "created",
				width: "120px",
				sortable: false, 
			},
			{
				text: this.$t('general.time', this.$store.state.locale),
				align: "left",
				value: "created",
				width: "80px",
				sortable: false, 
			},
			{
				text: this.$t('payment.customer', this.$store.state.locale),
				align: "left",
				value: "created",
				sortable: false, 
			},
			{
				text: this.$t('general.employee', this.$store.state.locale),
				align: "left",
				value: "created",
				width: "200px",
				sortable: false, 
			},
			{
				text: this.$t('payment.method', this.$store.state.locale),
				align: "left",
				value: "created",
				width: "100px",
				sortable: false, 
			},
			{
				text: this.$t('general.amount', this.$store.state.locale),
				align: "right",
				value: "month",
				width: "150px",
				sortable: false, 
			},
			{
				text: "",
				align: "center",
				width: "80px",
				sortable: false, 
			}
			],

			mergeReceiptDialog: {
				dialog: false,
				loading: false,
				selectedReceipts: new Array(),
			},

			cashUp: {
				oneEuroCent: 0,
				twoEuroCent: 0,
				fiveEuroCent: 0,
				tenEuroCent: 0,
				twentyEuroCent: 0,
				fiftyEuroCent: 0,
				oneEuro: 0,
				twoEuro: 0,
				fiveEuro: 0,
				tenEuro: 0,
				twentyEuro: 0,
				fiftyEuro: 0,
				hundredEuro: 0,
				twoHundredEuro: 0,
				fiveHundredEuro: 0,
				pin: 0,
				creditcard: 0,
			},

			expenses: {
				chosenTypeExpenses: "",
				typeExpenses: [
					{ id: "other", ledgerNumber: null },
					{ id: "canteenExpense", ledgerNumber: null },
					{ id: "cleaningSupplies", ledgerNumber: null },
					{ id: "lecture", ledgerNumber: null },
					{ id: "officeExpense", ledgerNumber: null },
					{ id: "stamps", ledgerNumber: null },
					{ id: "interiorCost", ledgerNumber: null },
					{ id: "advertisementCost", ledgerNumber: null },
					{ id: "mealAllowance", ledgerNumber: null },
					{ id: "travelReimbursement", ledgerNumber: null },
					{ id: "parkingFee", ledgerNumber: null },
					{ id: "otherPersonnelExpense", ledgerNumber: null },
					{ id: "privateWithdrawel", ledgerNumber: null },
					{ id: "depositToBank", ledgerNumber: null },
					{ id: "cashDifference", ledgerNumber: null },
				],
				amountValue: 0,
				chosenVat: "",
				loading: false,
				feedback: null,
				name: null,
			},
			expensesOrginal: {
				chosenTypeExpenses: "",
				amountValue: 0,
				chosenVat: "",
			},
			deposit: {
				//Add cash
				namePayInto: "",
				payInto: 0,
				loading: false,
				feedback: null,
			},
			depositOrg: {
				namePayInto: "",
				payInto: 0,
				loading: false,
				feedback: null,
			},
			payResourceReady: false,
			transactionsNumber: 0,
			change: 0,
			displayChange: "--", //Display International
			changeCalculated: null,	
			displayChangeCalculated: "--", //Display International
			typePayment: "PIN",
			splitPayment: {
				doneSteps: false,
				transactionId: null,
				splitByCash: 0,
				splitByCard: 0,
				splitActive: false,
			},
			availablePayDevices: new Array(), //
			paymentInfo: {
				nextAppointment: false, //for making a follow-up appointment
				mailReceipt: true,
				chosenPaymentDevice: null,
			},
			delayNumpadCount: 0,
			delayNumpad: 0,
			numpadDot: false,
			typePayment: "",
			clientSearch: "",
			clientSearchFieldFocus: false,
			productSearch: "",
			productSearchFieldFocus: false,
			treatmentSearch: "",
			treatmentSearchFieldFocus: false,
			selectedClientData: {},

			//Memberships
			userMemberships: new Array(),
			validForUseMemberships: new Array(),
			notValidForUseMemberships: new Array(),
			validatedByMembershipModule: new Array(),
			correspondingMemberships: new Array(),

			//prePayments

			prePayments: new Array(),
			clientPrePayments: new Array(),
			refund: false,

			historyReceipts: new Array(),

			historyHeader: [
				{
					text: this.$t('general.type', this.$store.state.locale),
					align: "left",
					sortable: false,
					width: "16.67%",
					value: "visit",
				},
				{
					text: this.$t('general.date', this.$store.state.locale),
					align: "left",
					width: "33,33%",
					sortable: false,
				},
				{
					text: this.$t('general.amount', this.$store.state.locale),
					align: "left",
					sortable: false,
					width: "16.67%",
				},
				{
					text: "",
					align: "right",
					sortable: false,
					width: "25%",
					value: "",
				},
				{
					text: "",
					align: "right",
					sortable: false,
					width: "8.33%",
					value: "",
				},
			],

			prePaymentHeader: [
				{
					text: this.$t('general.type', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "visit",
				},
				{
					text: this.$t('general.date', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "created",
				},
				{
					text: this.$t('general.amount', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "created",
				},
				{
					text: this.$t('payment.status', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "created",
				},
				{
					text: "",
					align: "left",
					sortable: false,
					value: "created",
				},
			],

			clientFutureHeader: [
				{
					text: this.$t('general.date', this.$store.state.locale),
					align: "left",
					sortable: true,
					width: "250px",
					value: "visit",
				},
				{
					text: this.$t('general.treatment', this.$store.state.locale),
					align: "left",
					sortable: true,
					value: "name",
				},
				{
					text: this.$t('general.branch', this.$store.state.locale),
					align: "left",
					sortable: true,
					width: "250px",
					value: "resourceId",
				},
			],

			membershipHeader: [
				{
					text: this.$t('general.name', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "id",
				},
				{
					text: "",
					align: "left",
					sortable: false,
					value: "id",
				},
				{
					text: this.$t('general.start', this.$store.state.locale),
					align: "left",
					width: "100px",
					sortable: false,
					value: "id",
				},
				{
					text: this.$t('payment.duration', this.$store.state.locale),
					align: "left",
					width: "120px",
					sortable: false,
					value: "id",
				},
				{
					text: "",
					align: "left",
					width: "150px",
					sortable: false,
					value: "id",
				},
				{
					text: "",
					align: "right",
					sortable: false,
					width: "150px",
				},
			],

			paymentHeader: [
				{
					text: this.$t('general.remove', this.$store.state.locale),
					align: "left",
					sortable: false,
					width: "40px",
					value: "remove",
				},
				{
					text: this.$t('payment.content', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "content",
				},
				{
					text: this.$t('payment.collapse', this.$store.state.locale),
					align: "left",
					sortable: false,
					width: "40px",
					value: "collapse",
				},
			],

			headers: [
				{
					text: "ID",
					align: "left",
					sortable: false,
					value: "resourceId",
				},
				{
					text: this.$t('general.name', this.$store.state.locale),
					align: "left",
					value: "resourceName",
					sortable: false,
				},
			],
			pagination: {
				rowsPerPage: "-1",
				sortBy: "visit",
				descending: true,
			},
			paymentFloat: false,
			customDiscount: {
				modal: false,
				filterProducts: {
					active: false,
					items: null,
				},

				combinationLimit: true,
				deleted: false,
				name: "Korting",
				description: this.$t('payment.manualAddedDiscount', this.$store.state.locale),
				manualInputHigherThanBruto: false,
				dateLimit: {
					active: false,
					endDate: null,
					startDate: null,
				},
				numberLimit: {
					active: false,
					number: null,
					numberUsed: null,
					type: "max",
				},
				typeDiscount: {
					custom: true,
					type: "fixedAmount",
					discountValue: null,
					discountType: "amount",
				},
				feedback: null,
				type: "all",
				filterTreatments: {
					active: false,
					items: null,
				},
			},
			feedbackPayDialog: {
				feedback: null,
				code: null,
			},

			refundBoolean: false,
			receiptMenu: false,
			receiptExtra: false,
			refundMenu: false,

			paymentAddItemsHeight: 550,

			typeDiscountTypes: [
				{
					name: this.$t('general.amount', this.$store.state.locale),
					id: "amount",
				},
				{
					name: this.$t('payment.percentageData', this.$store.state.locale),
					id: "percentage",
				},
			],
			prepaymentTypes: [
				{
					name: this.$t('payment.downPayment', this.$store.state.locale),
					id: "downPayment",
				},
				{
					name: this.$t('payment.prePayment', this.$store.state.locale),
					id: "prePayment",
				},
			],

			prepaymentReturnTypes: [
				{
					name: this.$t('payment.refundData', this.$store.state.locale),
					id: "refund",
				},
				{
					name: this.$t('payment.settled', this.$store.state.locale),
					id: "settled",
				},
				{
					name: this.$t('payment.compensation', this.$store.state.locale),
					id: "compensation",
				},
				{
					name: this.$t('payment.partialSettled', this.$store.state.locale),
					id: "partialSettled",
				},
			],
			selectedMembership: {
				dialog: false,
				info: {
					name: new Object(),
					posDescription: new Object(),
				},
			},
			paymentWrapperWidth: 0,
			selectedClientName: null,
			customerDialog: false,
			settings: {
				searchParameters: {
					customRanking: ['desc(weight)'],
					ranking: "custom" // Jouw gewenste ranking instellingen
				// Andere instellingen hier
				},
				customRanking: ['desc(weight)'],
				// ranking: "custom" 
				ranking: ['desc(weight)']
				
				// customRanking: ['desc(branchWeight.branchIdOfVFioWmJ4Vt7ytW3EEx)'],
			// ranking: 'custom'
			}
	
			
		}
	},

	created() {
		this.loadData();
		window.addEventListener("resize", this.getScrollInformation);
		this.getScrollInformation;

		this.historyPaymentsDate = moment().format("YYYY-MM-DD");

		bus.$on('selectClientForPayment', (client)=>{
			this.getSelectedClientData(client.id, `${client.name} ${client.surname}`)
			this.clientId = client.id
		});
	},

	mounted() {

		

	},

	beforeDestroy() {
		window.removeEventListener("resize", this.getScrollInformation);
		window.removeEventListener("scroll", this.getScrollInformation);
	},

	destroyed() {
		this.snapshotListenerloadReceipts = null; // Turn of snapshots
	},

	methods: {
		/**
		 * Send Click Event to Algolia
		 * @param item | Object with all data of the clicked item
		 * @param type | String with value of the item type (product, treatment etc)
		 */
		algoliaItemClick(item, type){
			aa("init", {
				appId: "B4XU2Z5BAW",
				apiKey: "a29d0e9160e36dc8ee83d5888c28cdcc",
			});
			aa('sendEvents', [
				{
					userToken: this.$store.state.activeCompany.id,
					authenticatedUserToken: this.$store.state.activeUserId,
					eventType: 'click',
					eventName: `${type} clicked`,
					index: `thrive_${type}s`,
					queryID: item.__queryID,
					objectIDs: [item.objectID],
					positions: [item.__position],
				},
			]);

			console.log("algoliaItemClick: ", {
					userToken: this.$store.state.activeCompany.id,
					authenticatedUserToken: this.$store.state.activeUserId,
					eventType: 'click',
					eventName: `${type} clicked`,
					index: `thrive_${type}s`,
					queryID: item.__queryID,
					objectIDs: [item.objectID],
					positions: [item.__position],
				})
		},

		genAvatar(name){
			return generateMeshAvatar(name);
		},


		formatToCurrency(locale, currency, digits){
			return formatToCurrency(locale, currency, digits)
		},

		calculateBrutoPrice(priceNetto, vatDocId){
			let vatItem = this.vatItems.find((item) => item.id  == vatDocId)
			if(vatItem){
				let brutoPrice = (priceNetto * (100 + vatItem.percentage)) / 100
				return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: 2}).format(Number(brutoPrice));
			}
			else{
				return ""
			}
		},

		
		openCustomerDialog() {
			this.selectedClientName = `${this.selectedClientData.name} ${this.selectedClientData.surname}`
			this.customerDialog = true;
			bus.$emit("getClientInformation", this.clientId)
		},

		async getHistoryPayments(){
			this.receiptsLoading = true;
			this.historyPayments = new Array();

			await db.collection("receiptsPaid") // Get receipts
			.where("companyId", "==", this.$store.state.activeCompany.id)
			.where("visit", "==", this.historyPaymentsDate)
			.get()
			.then((snap) => {
				snap.forEach((doc) => {
				let historyPayment = doc.data();
				historyPayment.id = doc.id;  
				historyPayment.historyType = 'receipt'
				historyPayment.time = moment(historyPayment.created.seconds, "X").format("YYYY-MM-DDTHH:mm")  
				this.historyPayments.push(historyPayment);
				});
			})

			let start = moment(this.historyPaymentsDate, "YYYY-MM-DD").startOf('day').toDate()
			let end =  moment(this.historyPaymentsDate, "YYYY-MM-DD").endOf('day').toDate()

			await db.collection("cashRegister") // Get receipts
			.where("companyId", "==", this.$store.state.activeCompany.id)
			.where("type", "==", 'expense')
			.where("created", ">=", start)
			.where("created", "<=", end)
			.get()
			.then((snap) => {
				console.log("snaps size:", snap.size, this.$store.state.activeCompany.id)
				snap.forEach((doc) => {
					let historyPayment = doc.data();
					historyPayment.id = doc.id;  
					historyPayment.historyType = 'expense'  
					historyPayment.time = moment(historyPayment.created.seconds, "X").format("YYYY-MM-DDTHH:mm") 
					this.historyPayments.push(historyPayment);
				});
			})

			await new Promise((resolve)=>{ // Get Client names
				if(this.historyPayments.length > 0){
					let counter = 0
					this.historyPayments.forEach(receipt => {
						if(receipt.historyType == 'receipt'){
							if(receipt.clientId){
								db.collection("clients").doc(receipt.clientId).get()
								.then((doc) => {
									if(doc.exists){
										receipt.clientName = `${doc.data().name} ${doc.data().surname}`;
									}
									counter++ 
									if(counter == this.historyPayments.length){
										resolve()
									}
								})
							}
							else{
								receipt.clientName = `Onbekend`;
								counter++ 
								if(counter == this.historyPayments.length){
									resolve()
								}
							}
						}
						else{
							receipt.clientName = `Uitgave (${receipt.expenseName})`;
							counter++ 
							if(counter == this.historyPayments.length){
								resolve()
							}
						}
					});
				}
				else{
					resolve()
				}
			})

			await new Promise((resolve)=>{ // Get Employee names
				if(this.historyPayments.length > 0){
				let counter = 0;
				this.historyPayments.forEach(receipt => {
					if(receipt.historyType == 'receipt'){
						if(receipt.paymentTableObj && receipt.paymentTableObj.transactionClosedBy && receipt.paymentTableObj.transactionClosedBy.userId){
							db.collection("users").doc(receipt.paymentTableObj.transactionClosedBy.userId).get()
							.then((doc) => {
								if(doc.exists){
									receipt.employeeName = `${doc.data().firstName} ${doc.data().lastName}`;
								}
								counter++ 
								if(counter == this.historyPayments.length){
									resolve()
								}
							})
						}
						else{
							receipt.employeeName = `Onbekend`;
							counter++ 
							if(counter == this.historyPayments.length){
								resolve()
							}
						}
						
					// db.collection("users")
					// .where("user_id", "==", receipt.paymentTableObj.transactionClosedBy.userId)
					// .get()
					// .then((snap) => {
					// 	if(snap.size > 0){
					// 	snap.forEach((doc) => {
					// 		receipt.employeeName = `${doc.data().firstName} ${doc.data().lastName}`;
					// 		counter++ 
					// 		if(counter == this.historyPayments.length){
					// 		resolve()
					// 		}
					// 	});
					// 	}
					// 	else{
					// 	counter++ 
					// 	if(counter == this.historyPayments.length){
					// 		resolve()
					// 	}
					// 	}
					// })
					}
					else{
						db.collection("users").doc(receipt.byResource).get()
						.then((doc) => {
							if(doc.exists){
								receipt.employeeName = `${doc.data().firstName} ${doc.data().lastName}`;
							}
							counter++ 
							if(counter == this.historyPayments.length){
								resolve()
							}
						})	
					}
				});
				}
				else{
					resolve()
				}
			})
			this.historyPayments.sort((a, b) => a.time.localeCompare(b.time));
			this.receiptsLoading = false;
			},

			refreshAlgolia(){
			this.algoSearch = algoliasearch(
				"B4XU2Z5BAW",
				"a29d0e9160e36dc8ee83d5888c28cdcc"
			);
		},

		loadTab(type){
			this.activeTab = type;
		},

		getScrollInformation() {
			const contentWrapper = document.querySelector("#content");
			const routerWrapper = document.querySelector(".router-view");

			this.paymentAddItemsHeight = this.$store.state.activeUserRoleNumber == 0 ? `${contentWrapper.clientHeight - 600}` : `${contentWrapper.clientHeight - 430}`;

			this.paymentWrapperWidth = contentWrapper.clientWidth;

			if (routerWrapper.clientHeight > contentWrapper.clientHeight) {
				this.paymentFloat = true;
			}
			else {
				this.paymentFloat = false;
			}
		},

		subtreatmenStafmembers(treatment) {
			let missingResources = 0;
			let resources = new Array();
			let uniqueResources = null;
			let counter = 0;
			let resourcesReadable = {
				names: null,
				missingNames: null,
			};
			treatment.subTreatments.forEach((subtreatment) => {
				if (subtreatment.resourceId) {
					this.resources.forEach((resource) => {
						if (resource.id == subtreatment.resourceId) {
							resources.push(resource.name);
						}
					});
				} else {
					missingResources++;
				}
				counter++;
				if (counter == treatment.subTreatments.length) {
					resourcesReadable.missingNames = missingResources;
					let uniqResourcesSet = new Set();
					resources.forEach((res) => uniqResourcesSet.add(res));
					// _.uniq(resources);
					uniqueResources = [...uniqResourcesSet];

					let nameStrings = uniqueResources.join(", ");
					resourcesReadable.names = nameStrings.replace(/,(?=[^,]*$)/, " en");
				}
			});
			return resourcesReadable;
		},

		chooseDiscountPrefix(type) {
			// console.log("type: ", type);
			if (type == "Bedrag") {
				return "€";
			} else {
				return null;
			}
		},

		getGender(gender) {
			if (gender == 2) {
				return "Vrouw";
			} else if (gender == 1) {
				return "Man";
			} else {
				return "Onbekend";
			}
		},

		chooseDiscountSuffix(type) {
			if (type == "Percentage") {
				return "%";
			} else {
				return null;
			}
		},

		getPrepaymentType(type) {
			return this.prepaymentTypes.find((prepaymentType) => prepaymentType.id == type)
				? this.prepaymentTypes.find((prepaymentType) => prepaymentType.id == type).name
				: "";
		},

		getPrepaymentStatus(paymentStatus, status) {

			//partialSettled
			if(paymentStatus == "paid" && status.type == "partialSettled"){
				return {
					type: {
						name: "Gedeeltelijk verrekend",
						id: "partialSettled",
					},
					timestamp: status.timestamp ? status.timestamp : null,
					receiptId: status.receiptId ? status.receiptId : null,
				};
			} else if (paymentStatus == "paid" && status.type == "remainingAmount") {
				return {
					type: {
						name: "Nog niet verekkende restanten",
						id: "remainingAmount",
					},
					timestamp: status.timestamp ? status.timestamp : null,
					receiptId: status.receiptId ? status.receiptId : null,
				};
			} else if (paymentStatus == "paid" && status.type == "settled") {
				return {
					type: {
						name: "Volledig Verrekend",
						id: "settled",
					},
					timestamp: status.timestamp ? status.timestamp : null,
					receiptId: status.receiptId ? status.receiptId : null,
				};
			} 
			else if (paymentStatus == "paid" && status.type == "compensation") {
				return {
					type: {
						name: "Cancel / No-show compensatie ",
						id: "compensation",
					},
					timestamp: status.timestamp ? status.timestamp : null,
					receiptId: status.receiptId ? status.receiptId : null,
				};
			} else if (paymentStatus == "paid" && status.type == null) {
				
				if (status && status.returned) {
					// Is verekend
					return {
						type: {
							name: this.prepaymentReturnTypes.find((prepaymentReturnType) => prepaymentReturnType.id == status.type)
								? this.prepaymentReturnTypes.find((prepaymentReturnType) => prepaymentReturnType.id == status.type).name
								: "",
							id: status.type,
						},
						timestamp: status.timestamp ? status.timestamp : null,
						receiptId: status.receiptId ? status.receiptId : null,
					};
				}	else {
					return {
						type: {
							name: "Nog niet verrekend",
							id: "paidNotReturned",
						},
						timestamp: null,
						receiptId: null,
					};
				}
			} else {
				//paymentStatus == "open"
				return {
					type: {
						name: paymentStatus == "expired" ? "Verlopen" : "Betaling nog niet gedaan",
						id: paymentStatus,
					},
					timestamp: null,
					receiptId: null,
				};
			}
		},

		addCustomDiscount() {
			this.customDiscount.feedback = null;
			if (this.customDiscount.typeDiscount.discountValue) {
				//Manipulate

				let newModifier = deepCopy(this.customDiscount);
				newModifier.typeDiscount.itemFilterIndex = this.indexer;
				newModifier.id = uniqid.time();
				newModifier.custom = true; //TODO TEST VANAF HIER
				newModifier.addedVia = "customDiscount";

				console.log("Add Custom Discount: ", newModifier);
				this.modifiers.push(newModifier);
				this.usedModifiers.push(newModifier);
				this.customModifiers.push(newModifier);

				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

				this.closeCustomDiscountDialog();
			} else {
				this.customDiscount.feedback = "Voer een bedrag of percentage in om de korting toe te passen.";
			}
		},


		
		manualInputDiscount(discount, index, priceBruto) {
				
			const cleanNumber = formatToCleanNumber(discount); //Format to clean number to calculate with

			if (cleanNumber > priceBruto) {
        		// Stel de korting in op de maximale waarde (priceBruto)
				// console.log("Higher than bruto")
				this.manualInputHigherThanBruto = true;
      	
    	    	return;
    		}

	
			//The given discount by calculate the difference
			const givenDiscount = cashConv(this.selectedItems[index].priceBruto - cleanNumber,"EUR",2 );
			

			clearTimeout(this.inputTimer);
			this.inputTimer = setTimeout(() => {

				const newModifier = {

				typeDiscount: {
					itemFilterIndex: index,
					discountValue:  givenDiscount,
					discountType: "amount",
					discountName: "manualInputDiscount",
					type: "fixedAmount",
					custom: true
				},
				filterProducts: { active: false, items: null },
				filterTreatments: { actice: false, items: null },
				type: 'all',
				name: "Handmatige korting",
				description: "Handmatig toegevoegde korting via input",
				dateLimit: { active: false, endDate: null, startDate: null },
				numberLimit: { active: false, number: null, numberused: null, type: 'max' },
				deleted: false,
				combinationLimit: true,
				id: uniqid.time(),
				custom: true,
				feedback: null,
				manualInputDiscount: true, //First wipe-out the other manual input discount
				addedVia: "manualInput",


				};


				 // Controleer opnieuw of de korting niet groter is dan priceBruto
			 if (this.manualInputHigherThanBruto === true) {

				// console.log("Higher than bruto")

            // Stel de korting in op de maximale waarde (priceBruto)
           
            // Push feedback to feedbackArray
            // this.feedbackArray.push({
            //     modifierName: "Handmatige korting",
            //     explaination: `De korting invoer is hoger dan  €${givenDiscount.toFixed(2)}`,
            //     dismiss: true,
            //     type: "info",
            //     icon: "mdi-cash",
            //     color: "warning",
				// 	 immutable: true,
            // });

            this.manualInputHigherThanBruto = false;

        	}

				// Wis de andere kortingen wipe-out other manual input discounts
				if(this.selectedItems[index].discountValueByModifiers){
									
				
					const manualInputModifiersByIndex = this.selectedItems[index].discountValueByModifiers
					.filter(modifier => modifier.manualInputDiscount === true)
				   .map(modifier => modifier.modifierId)
				
					console.log("manualInputModifiersByIndex", manualInputModifiersByIndex)

					//remove modifiers by id from usedModifiers
					this.usedModifiers = this.usedModifiers.filter(modifier => !manualInputModifiersByIndex.includes(modifier.id))
					this.customModifiers = this.customModifiers.filter(modifier => !manualInputModifiersByIndex.includes(modifier.id))

				}
		
			
				this.modifiers.push(newModifier);
				this.usedModifiers.push(newModifier);
				this.customModifiers.push(newModifier);

				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
			}, 1000);

			





			// console.log(newModifier) 

		},

		addCustomDiscountDialog() {
			console.log("uniqID", uniqid.time());
			this.customDiscount.modal = true;
		},

		closeCustomDiscountDialog() {
			this.customDiscount.feedback = null;
			this.customDiscount.modal = false;
			this.customDiscount.typeDiscount.discountType = 'amount';
			this.customDiscount.typeDiscount.discountValue = 0;
			this.customDiscount.combinationLimit = true;
		},

		closePayDialog() {
			this.payDialog = false;
			this.chosenPaymentDevice = null;
			this.transactionsNumber = 0;
			this.splitPay = {
				doneSteps: false,
				transactionId: null,
				splitByCash: 0,
				splitByCard: 0,
				splitActive: false,
			};
		},

		openCustomDialogFunc() {
			this.customDialog = true;
			console.log("Dialog open");
		},

		closeCustomDialog() {
			this.customDialog = false;

			this.customRule = {
				name: "",
				description: "",
				priceBruto: 0, //bruto

				amount: 1,
				resourceId: null,
				vat: 0,
			};
		},

		addCustomRule() {
			let newCustomRuleObject = {
				name: this.customRule.name,
				type: "customRule",
				description: this.customRule.description,
				id: uniqid.time(),
				uniqueIdentifier: uniqid.time(),
				percentageVAT: this.customRule.vat,
				typeOfPayment: this.customRuleOption == 0 ? "deposit" : "withdraw",
				typeItem: "customRule",
				priceBruto: Number(this.customRule.priceBruto),
				priceNetto: Number((this.customRule.priceBruto / `1.${this.customRule.vat}`).toFixed(2)),
				newPriceTotal: Number(this.customRule.priceBruto), //Also Bruto
				newPriceTotalBruto: Number(this.customRule.priceBruto),
				newPriceTotalNetto: Number((this.customRule.priceBruto / `1.${this.customRule.vat}`).toFixed(2)),
				discountNetto: 0,
				discountBruto: 0,
				calculatedDiscountVAT: 0,
				calculatedDiscountNetto: 0,
				userAdded: true,
				modifiedId: uniqid.time(),
				usedModifierId: [],
				usedMembershipId: [],
				category: ["customRule"],
				resourceId: this.customRule.resourceId,
				sub: [],
				selected: false,
			};

			//Push it by selected Amount to SelectedItems
			Array(Number(this.customRule.amount))
				.fill(newCustomRuleObject)
				.forEach((customRule) => this.selectedItems.push(customRule));

			this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

			this.closeCustomDialog();
		},

		loadData(){
			this.closeReceipt();
			this.activeTab = 'pay';
			this.availablePayDevices = this.$store.state.activeBranch.paymentOptions;
			this.preferredPaymentDevice = this.$store.state.activeBranch.paymentOptions;
			this.clearLoadedReceipts(); // Clear ReceiptHistory
			this.loadVat();
		},


		// loadUserComp() {
		// 	let userCompanies = this.userCompanies;
		// 	this.companies = new Array();
		// 	let vm = this;
		// 	let getUserCompData = getUserComp();

		// 	async function getUserComp() {
		// 		for (const company of userCompanies) {
		// 			await db
		// 				.collection("companies")
		// 				.doc(company)
		// 				.get()
		// 				.then((doc) => {
		// 					let company = {};
		// 					company.name = doc.data().name;
		// 					company.id = doc.id;
		// 					vm.companies.push(company);
		// 				});
		// 		}
		// 		if (window.localStorage.getItem("selectedThriveCompany")) {
		// 			vm.activeComp = window.localStorage.getItem("selectedThriveCompany");
		// 		} else {
		// 			vm.activeComp = vm.companies[0].id;
		// 		}
		// 		vm.loadActiveCompData();
		// 	}
		// },

		// loadActiveCompData() {
		// 	window.localStorage.setItem("selectedThriveCompany", this.activeComp);
		// 	db.collection("companies")
		// 		.doc(this.activeComp)
		// 		.get()
		// 		.then((doc) => {
		// 			this.activeCompData = doc.data();
		// 			this.activeCompData.id = doc.id;
		// 		})
		// 		.then(() => {
		// 			// this.loadIndexedClients();
		// 			this.clearLoadedReceipts(); // Clear ReceiptHistory
		// 			this.loadBranches();
		// 		});
		// },

		// loadBranches() {
		// 	window.localStorage.setItem("selectedThriveCompany", this.activeComp);
		// 	this.branches = [];
		// 	this.branchesAvailable = false;
		// 	db.collection("branches")
		// 		.where("companyId", "==", this.$store.state.activeCompany.id)
		// 		.where("active", "==", true)
		// 		.get()
		// 		.then((snap) => {
		// 			snap.forEach((doc) => {
		// 				let branch = doc.data();
		// 				branch.id = doc.id;
		// 				this.branches.push(branch);
		// 			});
		// 		})
		// 		.then(() => {
		// 			// Voorkeur Branch laden

		// 			if (this.branches.length > 0) {
		// 				if (!this.activeBranch || _.findIndex(this.branches, { id: this.activeBranch }) == -1) {
		// 					if (window.localStorage.getItem("selectedThriveBranch" + this.activeComp)) {
		// 						this.activeBranch = window.localStorage.getItem("selectedThriveBranch" + this.activeComp);
		// 						this.branches.forEach((branch) => {
		// 							if (branch.id == this.activeBranch) {
		// 								this.activeBranchData = branch;
		// 							}
		// 						});
		// 					} else {
		// 						this.activeBranch = this.branches[0].id;
		// 						this.activeBranchData = this.branches[0];
		// 					}
		// 				}
		// 			}
		// 			// this.downloadLedgerRules();
		// 			this.loadVat();
		// 		})

		// 		.catch((error) => {
		// 			// The document probably doesn't exist.
		// 			console.error("Error loading branches: ", error);
		// 		});
		// },



		clearLoadedReceipts() {
			this.snapshotListenerloadReceipts = null; // Stop snapshot Listener
			this.headerReceiptDrafts = new Array();
			this.headerReceiptDraftsSearch = new Array();
			this.localReceiptDrafts = new Array();

		},


		async loadProductsByBranch() {

			let vatItems = this.vatItems;

			function calculateVAT(data) {
				for (let index = 0; index < vatItems.length; index++) {
					if (vatItems[index].id == data.vat) {
						// Example 32,00 * (100 + 9 BTW) / 100
						return cashConv((data.priceNetto * (100 + vatItems[index].percentage)) / 100, "EUR", 4);
					}
				}
			}

			//Write VAT to Object
			function getVAT(vatId) {
				for (let index = 0; index < vatItems.length; index++) {
					if (vatItems[index].id == vatId) {
						// Example 32,00 * (100 + 9 BTW) / 100
						return vatItems[index].percentage;
					}
				}
			}

			const products = new Array();

			let index = this.algoSearch.initIndex("thrive_products");
      		let FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;

			await index.search("", {
				
				userToken: this.$store.state.activeCompany.id,
        facetFilters: [FacetFilterString],
      })
      .then(({ hits }) => {
        hits.forEach((product) => {
          	let obj = product;
						obj.priceBruto = calculateVAT(product); //New Price Bruto based on amount
						obj.addedDiscount = 0; //Extra Discount
						obj.newPriceTotalNetto = product.priceNetto; //New Price with Discount and amount
						obj.newPriceTotal = calculateVAT(product); //New Bruto TotalPrice
						obj.percentageVAT = getVAT(product.vat); //Vat Percentage
						obj.calculatedDiscountVAT = 0;
						obj.calculatedDiscountNetto = 0;
						obj.usedModifierId = new Array();
						obj.usedMembershipId = new Array();
						obj.resourceId = null; //Sale by Resource
						obj.type = "product";
						obj.id = product.objectID;
						products.push(obj);
        });
      })
			this.productsByBranch = products;
			this.productsByBranchClone = deepCopy(this.productsByBranch);
			return;
		},

		loadProductCategories() {
			db.collection("productCategories")
				.orderBy("name", "asc")
				.get()
				.then((snap) => {
					this.productCategories = [];
					snap.forEach((doc) => {
						let productCategory = doc.data();
						productCategory.id = doc.id;
						this.productCategories.push(productCategory);
					});
				})
				.then(() => {
					return;
				});
		},

		async loadProductSuppliers() {
			let productSuppliers = new Array();
			await db.collection("productSuppliers")
			.where("companies", "array-contains", this.$store.state.activeCompany.id)
			.orderBy("name", "asc")
			.get()
			.then((snap) => {
				snap.forEach((doc) => {
					let productSupplier = doc.data();
					productSupplier.id = doc.id;
					productSupplier.groups = new Array();
					productSuppliers.push(productSupplier);
				});
			})
			
			if(productSuppliers.length > 0){
				productSuppliers.sort((a, b) => a.name.localeCompare(b.name));
				await new Promise((resolve)=>{
					for (let index = 0; index < productSuppliers.length; index++) {
						db.collection("productSuppliers")
						.doc(productSuppliers[index].id)
						.collection("groups")
						.get()
						.then(snap => {
							snap.forEach(doc => {
								console.log("group found")
								let group = doc.data();
								group.id = doc.id;
								group.disabled = doc.data().deleted && doc.data().deleted.deleted ? true : false
								if(!group.disabled){
									productSuppliers[index].groups.push(group);
								}
								
							});
						})
						.then(()=>{
							productSuppliers[index].groups.sort((a, b) => a.name.localeCompare(b.name));
							if(index == productSuppliers.length -1 ){
								resolve()
							}
						})
					}
				})
			}
			console.log("groupen klaar")
			this.productSuppliers = productSuppliers;
			return;
	
		},

		createNewClient(){
			bus.$emit("newClient", 'pay');
		},

		LoadModifiers() {
			this.modifiers = [];
			let getToday = moment().format("YYYY-MM-DD");

			db.collection("modifiers")
				.where("branches", "array-contains", this.$store.state.activeBranch.id)
				.where("active", "==", true)
				.where("deleted", "==", false)

				.get()
				.then((snap) => {
					snap.forEach((doc) => {
						let obj = doc.data();

						//Set Priority of Modifiers Discounts
						obj.priority = this.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "discount").priorityNumber;

						obj.id = doc.id;
						this.modifiers.push(obj);
						this.modifiersClone.push(obj); //For removing all Garbage and safety
					});
				})
				.then(() => {
					console.log("Modifiers documents loaded");
					return;
				})
				.catch((error) => {
					console.error("Modifiers documents error:", error);
				});
		},

		loadVat() {
			// console.log("LOAD VAT! ", moment().format("H:mm"));

			db.collection("vat")
				.get()
				.then((snap) => {
					this.vatItems = [];
					snap.forEach((doc) => {
						let vatItem = doc.data();
						vatItem.id = doc.id;
						vatItem.summary = doc.data().percentage + "%";
						this.vatItems.push(vatItem);
					});
				})
				.then(() => {
					this.loadAllResources();
				});
		},

		loadAllResources() {
			//Loading all neccesary resources for Receipt.
			Promise.all([
				this.loadProductCategories(),
				this.loadProductSuppliers(),
				this.loadProductsByBranch(),
				this.loadTreatmentTypes(),
				this.loadTreatmentsByBranch(),
				this.loadResources(),
				this.LoadModifiers(),
			]).then(() => {
				console.log("Everything loaded...");
				this.autoLoadReceipts(); //Auto reload Receipts
				
			});
		},

		async loadTreatmentsByBranch() {
			let currentDay = moment().format("YYYY-MM-DD");
			let vatItems = this.vatItems;

			//Write VAT to Object
			function getVAT(vatId) {
				for (let index = 0; index < vatItems.length; index++) {
					if (vatItems[index].id == vatId) {
						// Example 32,00 * (100 + 9 BTW) / 100
						return vatItems[index].percentage;
					}
				}
			}

			function calculateVAT(data) {
				for (let index = 0; index < vatItems.length; index++) {
					if (vatItems[index].id == data.vat) {
						// Example 32,00 * (100 + 9 BTW) / 100
						// console.log("Bruto Price", cashConv(data.treatmentPrice * (100 + vatItems[index].percentage) / 100,'EUR', 4));
						return cashConv((data.treatmentPrice * (100 + vatItems[index].percentage)) / 100, "EUR", 4);
					}
				}
			}


			let index = this.algoSearch.initIndex("thrive_treatments");
      let FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;

			await index.search("", {
				clickAnalytics: true,
				userToken: this.$store.state.activeCompany.id,
        facetFilters: [FacetFilterString],
      })
      .then(({ hits }) => {
        hits.forEach((doc) => {
					let obj = doc;

					obj.priceBruto = doc.treatmentPrice ? calculateVAT(doc) : 0;
					obj.addedDiscount = 0; //Extra Discount
					obj.calculatedDiscountVAT = 0;
					obj.calculatedDiscountNetto = 0;
					obj.usedModifierId = new Array();
					obj.usedMembershipId = new Array();

					obj.newPriceTotalNetto = doc.treatmentPrice ? doc.treatmentPrice : 0; //New Price with Discount and amount and VAT
					obj.newPriceTotal = doc.treatmentPrice ? calculateVAT(doc) : 0; //Bruto TotalPrice
					obj.percentageVAT = doc.treatmentPrice ? getVAT(doc.vat) : 0; //Vat Percentage

					obj.name = doc.treatmentTitle;

					obj.type = "treatment";
					obj.id = doc.objectID;
					obj.selected = false; //For toggling

					//When Discount is already Aplied
					if (doc.discount && doc.discount.active) {
						if (currentDay >= doc.discount.start && currentDay <= doc.discount.end) {
							// console.log("Discount is active in date range");

							//Discount Percentage calculation
							if (doc.discount.type == "percentage") {
								obj.discountName = "Percentage";

								obj.addedDiscount = doc.discount.number;

								obj.newPriceTotal = this.currency.format(obj.priceBruto - (obj.priceBruto * obj.addedDiscount) / 100);

								this.treatmentsByBranch.push(obj);
							}

							//Discount Amount calculation
							if (doc.discount.type == "amount") {
								obj.discountName = "Bedrag";
								obj.addedDiscount = doc.discount.number;
								obj.newPriceTotal = this.currency.format(obj.newPriceTotal - obj.addedDiscount);
								this.treatmentsByBranch.push(obj);
							}
						}
						else {
							//Discount is not inside the discount start and end range.

							//Default = percentage OR amount
							if (doc.discount.type == "percentage") {
								obj.discountName = "Percentage";

								this.treatmentsByBranch.push(obj);
							}

							if (doc.discount.type == "amount") {
								obj.discountName = "Bedrag";

								this.treatmentsByBranch.push(obj);
							}
						}
					}
					else {
						this.treatmentsByBranch.push(obj);
					}
				});
			})
				
			console.log("Treatments Succesfully Loaded");
			this.treatmentsByBranchClone = deepCopy(this.treatmentsByBranch);
			return;
				

		},

		/*

    ██╗      ██████╗  █████╗ ██████╗     ██████╗ ███████╗ ██████╗███████╗██╗██████╗ ████████╗███████╗
    ██║     ██╔═══██╗██╔══██╗██╔══██╗    ██╔══██╗██╔════╝██╔════╝██╔════╝██║██╔══██╗╚══██╔══╝██╔════╝
    ██║     ██║   ██║███████║██║  ██║    ██████╔╝█████╗  ██║     █████╗  ██║██████╔╝   ██║   ███████╗
    ██║     ██║   ██║██╔══██║██║  ██║    ██╔══██╗██╔══╝  ██║     ██╔══╝  ██║██╔═══╝    ██║   ╚════██║
    ███████╗╚██████╔╝██║  ██║██████╔╝    ██║  ██║███████╗╚██████╗███████╗██║██║        ██║   ███████║
    ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝     ╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝╚═╝        ╚═╝   ╚══════╝

     */

		//Getting new Receipts
		async autoLoadReceipts() {
			let getToday = moment().format("YYYY-MM-DD");
			await new Promise((resolve) => {
				this.snapshotListenerloadReceipts = db
					.collection("receiptsDraft")
					.where("companyId", "==", this.$store.state.activeCompany.id)
					.where("visit", "==", getToday)
					.where("branchId", "==", this.$store.state.activeBranch.id) //Dynamisch maken
					.where("draft", "==", true) //Dynamisch maken binnen halen per branch
					.onSnapshot((snapshot) => {
						snapshot.docChanges().forEach((change) => {
							if (change.type === "added") {
								console.log("New Receipt draft: ", change.doc.id, change.doc.data());
								let data = change.doc.data();
								data.id = change.doc.id;
								this.localReceiptDrafts.push(data);
							}

							if (change.type === "modified") {
								let data = change.doc.data();
								data.id = change.doc.id;
								console.log("Changed Receipt", change.doc.id, change.doc.data())

								let foundIndex = this.headerReceiptDrafts.findIndex((receipt) => receipt.id == data.id);
								this.localReceiptDrafts[foundIndex] = data;

								//If the same Receipt is open
								if (this.selectedReceiptId == data.id) {
									this.headerReceiptDrafts;
									// this.selectedItems = change.doc.data().selectedItems;
									this.getTotal();
								}
							}
							if (change.type === "removed") {
								console.log("Removed Receipt draft: ", change.doc.data());
								let data = change.doc.data();
								data.id = change.doc.id;
								this.localReceiptDrafts = this.localReceiptDrafts.filter((receipt) => receipt.id !== data.id);
							}
						});
						this.headerReceiptDrafts = this.localReceiptDrafts;
						this.headerReceiptDraftsSearch = this.localReceiptDrafts;	

				
						resolve();
					});
			});

			if (this.$route.query.uid) {
				await this.findOrSelectReceipt(this.$route.query.uid, this.$route.query.clientId); // find Receipt with Id
			}

			this.dataloaded = true;
			this.getScrollInformation();
		},

		openCashRegister(){
			bus.$emit("openCashUpModal");
		},

		//Merge Receipts
		async mergeReceiptFunc() {

			this.mergeReceiptDialog.loading = true;

			

			//get the receipt with selectedOpenReceipt is the receipt that is open for merging
			let selectedOpenReceipt = this.localReceiptDrafts.find((receipt) => receipt.id == this.selectedReceiptId);


			//If the receipt is not found, return
			if (!selectedOpenReceipt) {
				console.error("Bon niet gevonden.")
				return;
			}

			let arrayOfBookingSummariesIds = selectedOpenReceipt.bookingSummaryIds;
			

			this.mergeReceiptDialog.selectedReceipts.forEach(receipt =>{
				//Merge selectedItems from mergeReceiptDialog to the selectedItems of selectedOpenReceipt
				selectedOpenReceipt.selectedItems = selectedOpenReceipt.selectedItems.concat(receipt.selectedItems);
				//Merge usedModifiers from mergeReceiptDialog to the usedModifiers of selectedOpenReceipt
				selectedOpenReceipt.usedModifiers = selectedOpenReceipt.usedModifiers.concat(receipt.usedModifiers);
				//Custom Modifiers
				selectedOpenReceipt.customModifiers = selectedOpenReceipt.customModifiers.concat(receipt.customModifiers);
				//Deleted Modifiers
				selectedOpenReceipt.deletedModifiers = selectedOpenReceipt.deletedModifiers.concat(receipt.deletedModifiers);

				//There are no booking summaries by a front-end receipt draft
				if(!receipt.draftMadeAtFrontEnd) {
					//Add bookingSummaryIds to arrayOfBookingSummaries
					arrayOfBookingSummariesIds = arrayOfBookingSummariesIds.concat(receipt.bookingSummaryIds);

				}
				
			})

			selectedOpenReceipt.bookingSummaryIds = arrayOfBookingSummariesIds;

			console.log("Selected Open Receipt", selectedOpenReceipt);
			console.log("Array of Booking Summaries", arrayOfBookingSummariesIds);

			//update the object inside this.localReceiptDrafts with the new selectedOpenReceipt
			 
			this.localReceiptDrafts = this.localReceiptDrafts.map((receipt) => {
				if (receipt.id == selectedOpenReceipt.id) {
					console.log("Receipt found, updating receipt.");
					let newReceiptObject = receipt;
					newReceiptObject.selectedItems = selectedOpenReceipt.selectedItems;
					newReceiptObject.usedModifiers = selectedOpenReceipt.usedModifiers;
					newReceiptObject.customModifiers = selectedOpenReceipt.customModifiers;
					newReceiptObject.deletedModifiers = selectedOpenReceipt.deletedModifiers;
					newReceiptObject.bookingSummaryIds = selectedOpenReceipt.bookingSummaryIds;
					console.log("New Receipt Object", newReceiptObject);
					return newReceiptObject;
				} else {
					return receipt;
				}
			});

						
			if(arrayOfBookingSummariesIds.length > 0) {
				await updateBookingSummariesAndBookings();
			}

			//Update the booking summaries and bookings
			async function updateBookingSummariesAndBookings() {
				try {
					const bookingSummaryCollection = db.collection("bookingsummary");
					const bookingCollection = db.collection("bookingen");

					for (const bookingSummaryId of arrayOfBookingSummariesIds) {
						await bookingSummaryCollection.doc(bookingSummaryId).set({
							uniqIdStamp: selectedOpenReceipt.uniqIdStamp
						}, { merge: true });

						const bookingSnapshot = await bookingCollection.where("bookingId", "==", bookingSummaryId).get();
						bookingSnapshot.forEach(async (doc) => {
							await bookingCollection.doc(doc.id).set({
								uniqIdStamp: selectedOpenReceipt.uniqIdStamp
							}, { merge: true });
						});
					}
					console.log("Booking summaries and bookings updated successfully.");
				} catch (error) {
					console.error("Error updating booking summaries and bookings: ", error);
				}
			}
			

			//Update the selectedOpenReceipt
			await db.collection("receiptsDraft").doc(selectedOpenReceipt.id).set({
				selectedItems: selectedOpenReceipt.selectedItems,
				usedModifiers: selectedOpenReceipt.usedModifiers,
				customModifiers: selectedOpenReceipt.customModifiers,
				deletedModifiers: selectedOpenReceipt.deletedModifiers,
				bookingSummaryIds: selectedOpenReceipt.bookingSummaryIds
			}, { merge: true })
			.then(() => {
				console.log("Receipt updated successfully.");
			})
			.catch((error) => {
				console.error("Error updating receipt: ", error);
			});

			//Delete the mergeReceiptDialog.selectedReceipt from the database
			for (const receipt of this.mergeReceiptDialog.selectedReceipts) {
				await db.collection("receiptsDraft").doc(receipt.id).delete()
				.then(() => {
					console.log("Receipt deleted successfully.");
				})
				.catch((error) => {
					console.error("Error deleting receipt: ", error);
				});
			}

			console.log("Receipts before merge", this.localReceiptDrafts);

			//remove from localReceiptDrafts and HeaderReceiptDrafts filtered by .id
			this.localReceiptDrafts = this.localReceiptDrafts.filter(receipt => {
			
				console.log(receipt.id)
				if(this.mergeReceiptDialog.selectedReceipts.find(selectedReceipt => selectedReceipt.id == receipt.id)){
				
					//remove
					return false;
				}
				else{
		
					return true;
				}
			});

			this.headerReceiptDrafts = this.localReceiptDrafts.filter(receipt => {
				if(this.mergeReceiptDialog.selectedReceipts.find(selectedReceipt => selectedReceipt.id == receipt.id)){
					//remove
					return false;
				}
				else{
					return true;
				}
			});
			

			console.log("Receipts after merge", this.localReceiptDrafts);

			this.openReceipt(selectedOpenReceipt.id, this.localReceiptDrafts.findIndex((receipt) => receipt.id == selectedOpenReceipt.id));

			this.mergeReceiptDialog.dialog = false;
			this.mergeReceiptDialog.selectedReceipts = new Array();
			this.mergeReceiptDialog.loading = false;


		},



		async findOrSelectReceipt(uid, clientId) {
			const receipts = this.localReceiptDrafts.filter(receipt => receipt.uniqIdStamp === uid);

			if (receipts.length > 0) {
				this.openReceipt(receipts[0].id, this.localReceiptDrafts.findIndex(receipt => receipt.id === receipts[0].id));
				return;
			}

			try {
				const snapshot = await db.collection("receiptsDraft")
					.where("companyId", "==", this.$store.state.activeCompany.id)
					.where("branchId", "==", this.$store.state.activeBranch.id)
					.where("draft", "==", true)
					.where("uniqIdStamp", "==", uid)
					.get();

				snapshot.forEach(doc => {
					const receipt = { ...doc.data(), id: doc.id };
					this.headerReceiptDrafts.push(receipt);
					this.headerReceiptDraftsSearch.push(receipt);
				});

				this.headerReceiptDrafts = this.localReceiptDrafts;
				this.headerReceiptDraftsSearch = this.localReceiptDrafts;

				const updatedReceipts = this.localReceiptDrafts.filter(receipt => receipt.uniqIdStamp === uid);

				if (updatedReceipts.length > 0) {
					//Receipt found
					this.openReceipt(
						updatedReceipts[0].id,
						this.localReceiptDrafts.findIndex(receipt => receipt.id === updatedReceipts[0].id)
					);
				} else {
					console.log("Receipt not found in database");
					//Generate a new Receipt
					this.newReceiptDraft(clientId, uid);
				}
			} catch (error) {
				console.error("Error getting Receipt document =>", error);
			}
		},

		getVAT(vatId) {
			for (let index = 0; index < this.vatItems.length; index++) {
				if (this.vatItems[index].id == vatId) {
					// Example 32,00 * (100 + 9 BTW) / 100
					return this.vatItems[index].percentage;
				}
			}
		},

		downloadLedgerRules() {
			db.collection("companies")
				.doc(this.$store.state.activeCompany)
				.collection("accountancySettings")
				.doc(this.$store.state.activeBranch.id)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.expenses.typeExpenses = doc.data().expensesNumbers;
						console.log("Ledger Numbers for Branch Found.");
					}
					else {
						// doc.data() will be undefined in this case
						console.log("No ledger numbers for Branch Found");

						db.collection("companies")
							.doc(this.$store.state.activeCompany)
							.collection("accountancySettings")
							.doc(this.$store.state.activeCompany)
							.get()
							.then((doc) => {
								this.expenses.typeExpenses = doc.data().expensesNumbers;

								console.log(doc.data().expensesNumbers);
								console.log("Ledger Numbers for Company Found");
							})
							.catch((err) => {
								console.error("Company Ledger:", err);
							});
					}
				})

				.catch((err) => {
					console.error("Branch Ledger:", err);
				});
		},

		async loadResources() {
			console.log("loadResources in pay.vue")
			//Resources loaded
			// this.resources.push({ name: "Alle Medewerkers", id: null, resourceDocId: null });
			// return await db.collection("employees")
			// .where("companyId", "==", this.$store.state.activeCompany.id)
			// .where("branches", "array-contains", this.$store.state.activeBranch.id)
			// .where("active", "==", true)
			// .get()
			// .then((snap) => {
			// 	snap.forEach((doc) => {
			// 		let obj = new Object();
			// 		obj.name = doc.data().name;
			// 		obj.id = doc.id;
			// 		obj.resourceDocId = doc.id;
			// 		this.resources.push(obj);
			// 	});
			// })
			// .catch((error) => {
			// 	console.error("Error getting Employees =>", error);
			// });
			
			let activeResources = [...this.$store.state.activeBranchEmployees];
			let presentResources = this.$store.state.presentBranchEmployees.map((employee => {return { id: employee.id, name: employee.title, present: true }}));

			activeResources = activeResources.filter(resource => !presentResources.some(presentResource => presentResource.id === resource.id)).map((employee) => { return { id: employee.id, name: employee.name, present: false } });
			this.resources = presentResources.concat(activeResources);
			// console.log("activeResources: ", activeResources);
			// console.log("presentResources: ", presentResources);
			
		},

		//Change Resources SubTreatments
		changeResource(indexSub, changedResourceId) {
			this.selectedItems[this.indexer].subTreatments[indexSub].resourceId = changedResourceId;
			console.log("Resource Changed", this.selectedItems[this.indexer].subTreatments[indexSub]);
			console.log("Selected Items", this.selectedItems);




			db.collection("receiptsDraft")
				.doc(this.selectedReceiptId)
				.set({
					selectedItems: this.selectedItems,
					usedModifiers: this.usedModifiers,
					deletedModifiers: this.deletedModifiers,
					modified: new Date(),
					customModifiers: this.customModifiers,
				}, {merge: true})
				.then(() => {
					console.log("%c Receipt Computed and added to DB with ID => ", "background: blue; color: white", this.selectedReceiptId);
					// this.localReceiptDrafts = this.localReceiptDrafts.sort(function(a, b) {
					// // Sortby  modified.seconds
					// // If the first item has a higher number, move it down
					// // If the first item has a lower number, move it up
					// if (a.modified.seconds > b.modified.seconds) return -1;
					// if (a.modified.seconds < b.modified.seconds) return 1;
					// });

					this.tempReceiptKey += 1;

					this.loadingDataTable = false;
					this.getScrollInformation();
				})
				.catch((error) => {
					console.error("Error updating document Receipts Draft : ", error);
				});
		},

		/*

    ███╗   ██╗███████╗██╗    ██╗    ██████╗ ███████╗ ██████╗███████╗██╗██████╗ ████████╗
    ████╗  ██║██╔════╝██║    ██║    ██╔══██╗██╔════╝██╔════╝██╔════╝██║██╔══██╗╚══██╔══╝
    ██╔██╗ ██║█████╗  ██║ █╗ ██║    ██████╔╝█████╗  ██║     █████╗  ██║██████╔╝   ██║   
    ██║╚██╗██║██╔══╝  ██║███╗██║    ██╔══██╗██╔══╝  ██║     ██╔══╝  ██║██╔═══╝    ██║   
    ██║ ╚████║███████╗╚███╔███╔╝    ██║  ██║███████╗╚██████╗███████╗██║██║        ██║   
    ╚═╝  ╚═══╝╚══════╝ ╚══╝╚══╝     ╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝╚═╝        ╚═╝   
    */

		async newReceiptDraft(clientId, uid) {

			let clientName = this.$t('payment.anonymous', this.$store.state.locale);
			if (clientId) {
				try {
					const doc = await db.collection("clients").doc(clientId).get();
					if (doc.exists) {
						const clientData = doc.data();
						clientName = `${clientData.name} ${clientData.surname}`.trim();
					}
				} catch (error) {
					console.error("Error getting client document:", error);
				}
			}

			this.refundBoolean = false;
			this.clientId = "";
			this.feedbackArray = new Array();
			this.prePayments = new Array();
			this.clientPrePayments = new Array();
			this.clientLoyaltyDocs = new Array();
			this.selectedClientData = new Object();
			this.selectedItems = new Array();
			this.usedModifiers = new Array();
			this.modifiers = deepCopy(this.modifiersClone);
			this.combinedModifiers = new Array();
			this.customModifiers = new Array();
			this.deletedModifiers = new Array();
			this.validForUseMemberships = new Array();
			this.notValidForUseMemberships = new Array();
			this.correspondingMemberships = new Array();
			this.treatmentsByBranchClone = new Array();
			this.productsByBranchClone = new Array();

			const receiptObj = {
				draftMadeAtFrontEnd: true,
				clientId: clientId ? clientId : "", //Client Id
				clientname: clientName, //Client Name
				draft: true,
				created: new Date(),
				modified: new Date(),
				visit: moment().format("YYYY-MM-DD"),
				selectedItems: new Array(),
				usedModifiers: new Array(),
				deletedModifiers: new Array(),
				uniqIdStamp: uid ? uid : uniqid(),
				companyId: this.$store.state.activeCompany.id, //Company ID
				branchId: this.$store.state.activeBranch.id, //Branch
				userId: this.byResource, //Resource for Payment
			};
			//Create receipt by ClientId and Clientname
			db.collection("receiptsDraft")
				.add(receiptObj)
				.then((docRef) => {
					// console.log("Document Receipts Draft written with ID: ", docRef.id);
					//For editing the Receipt
					this.selectedReceiptId = docRef.id;
					this.selectedClientData = null;
					this.clientSearchFieldFocus = false;

					//Refresh Header
					this.updateResource();
					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
					let index = this.headerReceiptDrafts.length - 1;
					this.openReceipt(docRef.id, index);
				})
				.catch((error) => {
					console.error("Error adding document Receipts Draft : ", error);
				});
		},

		/*
       ██████╗ ██████╗ ███████╗███╗   ██╗    ██████╗ ███████╗ ██████╗███████╗██╗██████╗ ████████╗
      ██╔═══██╗██╔══██╗██╔════╝████╗  ██║    ██╔══██╗██╔════╝██╔════╝██╔════╝██║██╔══██╗╚══██╔══╝
      ██║   ██║██████╔╝█████╗  ██╔██╗ ██║    ██████╔╝█████╗  ██║     █████╗  ██║██████╔╝   ██║   
      ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║    ██╔══██╗██╔══╝  ██║     ██╔══╝  ██║██╔═══╝    ██║   
      ╚██████╔╝██║     ███████╗██║ ╚████║    ██║  ██║███████╗╚██████╗███████╗██║██║        ██║   
       ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝    ╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝╚═╝        ╚═╝   
      
    */

		async openReceipt(receiptId, index) {
			console.log("OPENING RECEIPT");

			let vm = this;

			this.isSearchingHeader = false;

			this.selectedReceipt = index;

			this.clientReceiptDataLoading = true;
			this.loadingDataTable = true;
			this.selfGeneratedReceipt = false;
			this.refundBoolean = false;
			this.deletedSelfGeneratedItems =  new Array();

			this.clientId = "";
			this.feedbackArray = new Array();
			this.selectedClientData = new Object();
			this.clientLoyaltyDocs = new Array();

			this.prePayments = new Array();
			this.clientPrePayments = new Array();
			this.modifiers = deepCopy(this.modifiersClone); //Cleaning up Modifiers
			this.userMemberships = new Array();
			this.combinedModifiers = new Array();
			this.validForUseMemberships = new Array();
			this.notValidForUseMemberships = new Array();
			this.correspondingMemberships = new Array();
			this.bookingSumArr = new Array();
			this.selectedItems = new Array();
			this.usedModifiers = new Array();
			this.deletedModifiers = new Array();
			this.customModifiers = new Array();
			this.treatmentsByBranchClone = new Array();
			this.productsByBranchClone = new Array();
			this.historyReceipts = new Array();

			this.selectedReceiptId = receiptId;
			let receiptObj = this.localReceiptDrafts.find((localReceipt) => localReceipt.id == receiptId);

			//if uniqIdCluster represent
			if (receiptObj) {
				// console.log("Found Receipt OBJ: ", receiptObj, index);

				await db
					.collection("receiptsDraft")
					.doc(receiptObj.id)
					.get()
					.then((doc) => {
						receiptObj = doc.data();
						receiptObj.id = doc.id;
						console.log("Receipt OBJ Modifiers", receiptObj);
					})
					.then(() => {
						this.clientId = receiptObj.clientId; //Client Id
						this.clientnameReceipt = receiptObj.clientname; //Full Client name
						this.selectedItems = receiptObj.selectedItems;
						this.deletedSelfGeneratedItems = receiptObj.deletedSelfGeneratedItems ? receiptObj.deletedSelfGeneratedItems : new Array();
						this.usedModifiers = receiptObj.usedModifiers ? receiptObj.usedModifiers : new Array();
						this.customModifiers = receiptObj.customModifiers ? receiptObj.customModifiers : new Array();
						this.deletedModifiers = receiptObj.deletedModifiers ? receiptObj.deletedModifiers : new Array();
						this.validForUseMemberships = receiptObj.validForUseMemberships ? receiptObj.validForUseMemberships : new Array();

						// console.log("VALID MEMBERSHIP A ",receiptObj.validForUseMemberships)

						this.byResource = receiptObj.userId ? receiptObj.userId : null;
						// Recalculate

						// Add the custom Modifier
						this.modifiers = this.modifiers.concat(this.customModifiers);

						// When SelectedItems is empty we don't need to download other items

						return;
					})

					.catch((err) => {
						console.error("Error getting Receipt Draft:", err);
					});

				if (this.selectedItems.length !== 0) {
					console.log("SelectedItems Open Receipt", this.selectedItems.length);
					await this.downloadProductsAndTreatments();
				}

		
		



				if (receiptObj && receiptObj.clientId) {
					this.getSelectedClientData(receiptObj.clientId, "", receiptObj);
					this.clientId = receiptObj.clientId;
				} else {
					this.getTotal();
					this.clientReceiptDataLoading = false;
					this.loadingDataTable = false;
				}

				// //Place the selected Receipt by selectedReceiptid at the top of the list
				this.receiptIndex = this.headerReceiptDrafts.findIndex((receipt) => receipt.id === this.selectedReceiptId);
				
				//Slice the index and place as first in the array 
				this.headerReceiptDrafts.splice(0, 0, this.headerReceiptDrafts.splice(this.receiptIndex, 1)[0]);
				this.headerReceiptDraftsSearch.splice(0, 0, this.headerReceiptDraftsSearch.splice(this.receiptIndex, 1)[0]);

				
			}
		},

		/**
		 * Used for Receipts with a Uniq Stamp. Generated by the postBooking API
		 * Generate treatments for the receipt by its own uniq id uniqIdStamp
		 */
		async generateByUniqIdStamp(uniqIdStamp, selectedItems, receiptId) {
			let vm = this;

			let itemsUserAdded = selectedItems.filter((selectedProductsTreats) => selectedProductsTreats.userAdded == true);

			this.bookingSumArr = new Array();
			let bookings = new Array();

			let bookingSummary = db
				.collection("bookingsummary")
				.where("branchId", "==", this.$store.state.activeBranch.id)
				.where("uniqIdStamp", "==", uniqIdStamp)
				.where("deleted", "==", false)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						//  console.log("Booking Sum Loaded =>", doc.data());
						let docObj = new Object();
						docObj = doc.data();
						docObj.id = doc.id;
						this.bookingSumArr.push(docObj);
					});

					return;
				})
				.catch((error) => {
					console.error("Error getting booking summary by UniqId: " + error.message);
				});

			let bookingen = db
				.collection("bookingen")
				.where("branchId", "==", this.$store.state.activeBranch.id)
				.where("uniqIdStamp", "==", uniqIdStamp)
				.where("deleted", "==", false)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						// console.log(doc.id, "=> Boooking Loaded");
						let docObj = new Object();
						docObj = doc.data();
						docObj.id = doc.id;
						bookings.push(docObj);
					});
					return;
				})
				.catch((error) => {
					console.error("Error getting booking by UniqId: " + error.message);
				});

			// Get the resources

			await Promise.all([bookingSummary, bookingen]).then((result) => {

				// console.log("Booking Summary", bookingSums);
				// console.log("Booking", bookings);

				if (bookings.length === 0 && vm.bookingSumArr.length === 0) {
					this.selfGeneratedReceipt = false; //Receipt is NOT Self Generated
					console.log("No Booking and booking summary found");
				} else {
					//Booking and booking summary found
					this.selfGeneratedReceipt = true; //Receipt is  Self Generated
					console.log("Booking and booking summary found");
				}
			});

			/**
        * Checking if the receipt is self-generated. If it is not, then it will not be sent to the
      user.
        */
			//No other treatments to connect
			if (this.selfGeneratedReceipt == false) {
				return;
			}

			let treatmentsFromBooking = await getTreatments();

			function getTreatments() {
				
				return new Promise((resolve, reject) => {
					let vatItems = vm.vatItems;

					//Write VAT to Object
					function getVAT(vatId) {
						for (let index = 0; index < vatItems.length; index++) {
							if (vatItems[index].id == vatId) {
								// Example 32,00 * (100 + 9 BTW) / 100
								return vatItems[index].percentage;
							}
						}
					}

					function calculateVAT(data) {
						for (let index = 0; index < vatItems.length; index++) {
							if (vatItems[index].id == data.vat) {
								// Example 32,00 * (100 + 9 BTW) / 100
								// console.log("Bruto Price", cashConv(data.treatmentPrice * (100 + vatItems[index].percentage) / 100,'EUR', 4));
								return cashConv((data.treatmentPrice * (100 + vatItems[index].percentage)) / 100, "EUR", 4);
							}
						}
					}

					let countTreatments = 0;
					let treatments = new Array();
					//Without Duplicates 
					//TODO Object van maken en booking Summary meenemen?
					let getTreatmentIds = [...new Set(vm.bookingSumArr.map((bookingSum) => bookingSum.treatmentId))];

					getTreatmentIds.forEach((treatmentId) => {
						//Get firebase document
						db.collection("treatments")
							.doc(treatmentId)
							.get()
							.then((doc) => {
								let obj = doc.data();

								// obj.amount = 1; //Standard Amount
								obj.priceBruto = doc.data().treatmentPrice ? calculateVAT(doc.data()) : 0;
								obj.addedDiscount = 0; //Extra Discount
								obj.calculatedDiscountVAT = 0;
								obj.calculatedDiscountNetto = 0;
								obj.usedModifierId = new Array();
								obj.usedMembershipId = new Array();
								

								

								obj.newPriceTotalNetto = doc.data().treatmentPrice ? doc.data().treatmentPrice : 0; //New Price with Discount and amount and VAT
								obj.newPriceTotal = doc.data().treatmentPrice ? calculateVAT(doc.data()) : 0; //Bruto TotalPrice
								obj.percentageVAT = doc.data().treatmentPrice ? getVAT(doc.data().vat) : 0; //Vat Percentage

								obj.name = doc.data().treatmentTitle;

								obj.internalUse = false; //False by default and set to true if internal use via GUI

								obj.type = "treatment";
								obj.id = doc.id;
								obj.selected = false; //For toggling
								obj.typeOfPayment = "deposit";
								treatments.push(obj);
								vm.treatmentsByBranchClone.push(obj);
							})
							.then(() => {
								countTreatments++;

								if (countTreatments === getTreatmentIds.length) {
									resolve(treatments);
								}
							})
							.catch((error) => {
								console.error("Error getting treatment", error);
							});
					});
				});
			}

			// console.log("itemsUserAdded", itemsUserAdded);
			// console.log("Treatments:", treatmentsFromBooking);
			// console.log("bookingSums: ", bookingSums);
			// console.log("bookings: ", bookings);

			//Sort by Time
			vm.bookingSumArr.sort(function (a, b) {
				// Sortby  ["start"] ["desc"]
				// If the first item has a higher number, move it down
				// If the first item has a lower number, move it up
				if (a.start < b.start) return -1;
				if (a.start > b.start) return 1;
			});

			let autoGeneratedTreatments = new Array();

			vm.bookingSumArr.forEach((bookingSum) => {

				//Get the Right Treatment
				let foundedTreatment = deepCopy(treatmentsFromBooking.find((treatment) => treatment.id === bookingSum.treatmentId));

				//Add the BookingSum AND uniqIdStamp to the treatment selectedItem 
				foundedTreatment.bookingSummaryId = bookingSum.id;
				foundedTreatment.uniqIdStamp = uniqIdStamp;

				let foundedTreatmentClone = deepCopy(foundedTreatment); //Clone for manipulation


				foundedTreatmentClone.subTreatments = new Array();

				//Get bookings by BookingSummary
				let bookingsByBookingSum = bookings.filter((booking) => booking.bookingId === bookingSum.id);

				foundedTreatment.subTreatments.map((subTreatment) => {
					// console.log("bookingsByBookingSum", bookingsByBookingSum);
					// console.log("subTreatment", subTreatment);

					//Get bookings with Employee
					let getSubTreatmentBooking = bookingsByBookingSum.find(
						(booking) => booking.description[this.$store.state.locale.toLowerCase()] === subTreatment.description[this.$store.state.locale.toLowerCase()] && booking.resourceType === "employee"
					);

					console.log("getSubTreatmentBooking", getSubTreatmentBooking)

					//If booking of subtreatment is found
					if (getSubTreatmentBooking) {
						//Only Employee
						if (getSubTreatmentBooking.resourceType == "employee") {
							subTreatment.resourceId = getSubTreatmentBooking.resourceDocId;
							foundedTreatmentClone.subTreatments.push(subTreatment);
						} else {
							foundedTreatmentClone.subTreatments.push(subTreatment);
						}
					}
				});
				console.log("foundedTreatmentClone", foundedTreatmentClone);
				autoGeneratedTreatments.push(foundedTreatmentClone);
			});

			
			//Remove the that are deleted via the this.deletedSelfGeneratedItems 

			console.log("deletedSelf Generated", this.deletedSelfGeneratedItems, autoGeneratedTreatments);

			//remove item from autoGeneratedTreatments with the same uniqIdStamp and bookingSummaryId from deletedSelfGeneratedItems
			autoGeneratedTreatments = autoGeneratedTreatments.filter((item) => {
				let found = this.deletedSelfGeneratedItems.find((deletedItem) => 
				deletedItem.uniqIdStamp === item.uniqIdStamp && deletedItem.bookingSummaryId === item.bookingSummaryId)
				//if the deleted item is found, return false remove it from the autoGeneratedTreatments, else return true
				if (!found) {
					return true;
				} else {
					return false;
				}
				});
			
			console.log("autoGeneratedTreatments", autoGeneratedTreatments);

			this.selectedItems = [...autoGeneratedTreatments, ...itemsUserAdded];

			//Download the necessary treatments and products
			await this.downloadProductsAndTreatments();

			return;
		},

		checkPaymentSettings(typePayment){
			if((typePayment == "PIN" || typePayment == "SPLIT") && this.$store.state.activeBranch.paymentOptions.length == 0){
				this.noPaymentOptionsDialog = true;
			}
			else{
				if(this.clientId){
					this.injectBeforePay(typePayment)
				}
				else{
					this.anonymousClient.paymentType = typePayment;
					this.anonymousClient.dialog = true;
				}
			}
		},

		injectBeforePay(typePayment) {
			console.log("")
			this.anonymousClient.dialog = false;
			this.anonymousClient.paymentType = null;
			this.tab = 0;
			this.changeCalculated = 0;
			this.change = 0;

			db.collection("cashRegister")
			.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
			.get()
			.then((doc) => {
				if (doc.exists) {
					this.cashStatement = doc.data().cash;
				}
				else {
					console.error("No CashState document for this branch!");
				}
			})
			.catch((error) => {
				console.error("Error getting Cash Statement", error);
			});

			console.log("Inject Before Pay", typePayment);
			//Set settings for PIN
			if (typePayment == "PIN") {
				// this.displayChange = "--";
				// this.changeFunc(this.totalBrutoTwoDigits)
				// this.displayChange = cashConv(this.totalBrutoTwoDigits, "EUR", 2);
				this.change = cashConv(this.totalBrutoTwoDigits, "EUR", 2);
				// this.displayChange = formatToCurrency('nl-NL', null, this.totalBrutoTwoDigits);

				const currencyFormat = new Intl.NumberFormat("nl-NL", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});


				this.displayChange = currencyFormat.format(this.totalBrutoTwoDigits);
			
				this.paymentInfo.chosenPaymentDevice = this.availablePayDevices[0];
				this.payDialog = true;
				this.typePayment = "PIN";
				this.splitPayment.transactionId = null;
				this.splitPayment.splitActive = false
				this.splitPayment.splitByCash = null;
			}
			//Set settings for Cash
			if (typePayment == "CASH") {
				this.displayChange = "--";
				this.payDialog = true;
				this.typePayment = "CASH";
				this.splitPayment.transactionId = null;
				this.splitPayment.splitActive = false
				this.splitPayment.splitByCash = null;
			}
			//Set settings for Split
			if (typePayment == "SPLIT") {
				this.displayChange = "--";
				this.paymentInfo.chosenPaymentDevice = this.availablePayDevices[0];
				this.splitPayment.transactionId = uniqid();
				this.splitPayment.splitActive = true;
				this.splitPayment.splitByCash = cashConv(this.totalBruto, "EUR", 2);
			
				this.payDialog = true;
				this.typePayment = "SPLIT";
			}

			if (typePayment == "FREE") {
				this.typePayment == "FREE";
				this.payReceipt();
			}
		},

		injectBeforeRefund(typePayment) {
			
			this.typePayment = "REFUNDCASH";

			//Make it absolute
			this.totalBrutoAbsolute = Math.abs(this.totalBruto);
			this.changeBrutoToClient = deepCopy(Math.abs(this.totalBruto));

			db.collection("cashRegister")
				.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.cashStatement = doc.data().cash;
						this.payDialog = true;
						console.log("cash statement loaded", this.cashStatement);

						if (this.totalBrutoAbsolute > this.cashStatement) {
							this.feedbackPayDialog.feedback = "Let op er is niet genoeg contant aanwezig om te retouneren.";
							this.feedbackPayDialog.code = 100;
						}
					}
					else{
						this.createCashRegister('injectBeforeRefund') // Create cashRegister Doc
					}
				})
				.catch((error) => {
					console.error("Error getting Cash Statement", error);
					this.cashUpDialog = false;
				});
		},

		//Delete receipt
		deleteReceiptDraft(selectedReceiptId) {
			this.searchboxLabel = "Zoek een klant";
			this.selectedItems = new Array();
			this.usedModifiers = new Array();
			this.deletedModifiers = new Array();
			//Filter out Receipt
			this.localReceiptDrafts = this.localReceiptDrafts.filter((localReceipt) => localReceipt.id !== selectedReceiptId);

			this.selectedReceiptId = "";
			this.clientId = "";
			this.updateResource();
			this.getTotal();

			db.collection("receiptsDraft")
				.doc(selectedReceiptId)
				.delete()
				.then(() => {
					console.log("Document:", selectedReceiptId, "successfully deleted!");
				})
				.catch((error) => {
					console.error("Error removing document: ", error);
				});
		},

		closeReceipt() {
			this.selectedReceiptId = "";
			this.byResource = null;
			this.clientId = "";
			this.selectedItems = new Array();
		},

		openExpense() {
			this.expensesOrginal = _.cloneDeep(this.expenses);
			this.expensesDialog = true;

			db.collection("cashRegister")
				.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.cashStatement = doc.data().cash;
						this.expensesDialog = true;
					}
					else{
						this.createCashRegister('openExpense') // Create cashRegister Doc
					}
				})
				.catch((error) => {
					console.error("Error getting Cash Statement", error);
					this.cashUpDialog = false;
				});
		},

		closeDiscountDialog() {
			this.scanDiscountDialog = false;
			this.scanDiscountDialogObject.barcode = null;
		},

		validateBarcodeAndAdd() {
			let getModifier = this.modifiers.find((modifier) => modifier.barcode.code === this.scanDiscountDialogObject.barcode);

			console.log("getModifier", getModifier);
			if (getModifier) {
				this.usedModifiers.push(getModifier);

				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
			}
		},

		closeExpense() {
			this.expensesDialog = false;

			this.expenses.chosenTypeExpenses = "";
			this.expenses.amountValue = 0;
			this.expenses.chosenVat = "";
			this.expenses.feedback = null;
			this.expenses.buttonDisabled = true;
			this.expenses.name = "";
			this.expenses.loading = false;
		},

		validateExpense(input) {

			this.expenses.amountValue = input; //Number will be cleaned add saveExpense() 


			if (formatToCleanNumber(input) > this.cashStatement) {
				this.expenses.feedback = "Niet genoeg in kas of de kasstaat komt niet overeen";
			} else {
				this.expenses.feedback = null;
			}
		},

		saveExpense() {
			// if (this.expenses.feedback == null) {
			this.expenses.loading = true;

			//Clean the numbers from validateExpense()
			this.expenses.amountValue = formatToCleanNumber(this.expenses.amountValue);


			db.collection("cashRegister")
				.add({
					byResource: this.userId,
					expenseName: this.expenses.name,
					typeExpenses: this.expenses.chosenTypeExpenses,
					expense: this.expenses.amountValue,
					vatId: this.expenses.chosenVat ? this.expenses.chosenVat.id : null,
					register: this.selectedCashRegister,
					branchId: this.$store.state.activeBranch.id,
					companyId: this.$store.state.activeCompany.id,
					created: new Date(),
					type: "expense",
				})
				.then((docRef) => {
					console.log("Pay Into done... =>", docRef.id);

					//Cash update
					db.collection("cashRegister")
						.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
						.update({
							cash: this.cashStatement - this.expenses.amountValue,
							timestamp: new Date(),
						})
						.then(() => {
							console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);

							this.closeExpense();
						})
						.catch((err) => {
							console.error("Cash Error", err);
						});
				})
				.catch((error) => {
					console.error("Error adding document Pay Into : ", error);
				});
			// }
		},

		

		openDeposit() {		
			this.depositOrg = _.cloneDeep(this.deposit);
			db.collection("cashRegister")
			.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
			.get()
			.then((doc) => {
				if (doc.exists) {
					this.cashStatement = doc.data().cash;
					this.depositDialog = true;
				}
				else{
					this.createCashRegister('openDeposit') // Create cashRegister Doc
				}
				console.log("cash statement loaded", this.cashStatement);
			})
			.catch((error) => {
				console.error("Error getting Cash Statement", error);
			});
		},

		saveDeposit() {
			console.log("this.deposit.namePayInto.length", this.deposit.namePayInto.length, this.deposit.namePayInto);
			if (this.deposit.namePayInto.length < 2) {
				return (this.deposit.feedback = "Vul een juiste naam in.");
			} else {
				this.deposit.feedback = null;
			}

			if (this.deposit.payInto == 0) {
				return (this.deposit.feedback = "Vul een juiste storting waarde in.");
			} else {
				this.deposit.feedback = null;
			}

			if (this.deposit.feedback == null) {
				this.deposit.loading = true;

				db.collection("cashRegister")
					.add({
						byResource: this.userId,
						depositName: this.deposit.namePayInto,
						deposit: this.deposit.payInto,
						depositType: "depositToCash",
						register: this.selectedCashRegister,
						branchId: this.$store.state.activeBranch.id,
						companyId: this.$store.state.activeCompany.id,
						created: new Date(),
						type: "deposit",
					})
					.then((docRef) => {
						console.log("Pay Into done... =>", docRef.id);

						
						// Cash update
						db.collection("cashRegister")
							.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
							.set({						
								cash: firebase.firestore.FieldValue.increment(this.deposit.payInto),
								timestamp: new Date(),
							}, {merge: true})
							.then(() => {
								console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
								this.deposit.loading = false;
								this.depositDialog = false;
								this.deposit = _.cloneDeep(this.depositOrg);
							})
							.catch((err) => {
								console.error("Cash Error", err);
							});
					})
					.catch((error) => {
						console.error("Error adding document Pay Into : ", error);
					});
			}
		},

		closeDeposit() {
			this.depositDialog = false;
			this.cashUp = {
				oneEuroCent: 0,
				twoEuroCent: 0,
				fiveEuroCent: 0,
				tenEuroCent: 0,
				twentyEuroCent: 0,
				fiftyEuroCent: 0,
				oneEuro: 0,
				twoEuro: 0,
				fiveEuro: 0,
				tenEuro: 0,
				twentyEuro: 0,
				fiftyEuro: 0,
				hundredEuro: 0,
				twoHundredEuro: 0,
				fiveHundredEuro: 0,
				pin: 0,
				creditcard: 0,
			};

			this.cashStatement = 0;
			this.cashStatementStay.value = 0;
		},

		
		async createCashRegister(functionName){
			await db.collection("cashRegister").doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`).set({
				cash: 0,
				cashRounding: 0,
				cashUpCash: 0,
				cashUpTimestamp: null,
				timestamp: new Date()
			})
			.catch((error) => {
				console.error("Error creating cashRegister", error);
			});
			this[functionName]()
		},

		async openCashUp() {
			// cashStatementStay.value always to zero for
			this.cashStatementStay.value = 0;
			this.cardStatement = 0;
			this.cashStatement = 0;
			this.cashUpDepositToBank = 0;

			this.paidReceiptsCashUp = new Array();
			let lastCashUp = null

			await db.collection("cashRegister")
			.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
			.get()
			.then((doc) => {
				if (doc.exists) {
					this.cashStatement = doc.data().cash;
					console.log("cash statement loaded", this.cashStatement);
					this.correctCashUpNumbers('first');
					this.cashUpDialog = true;
				}
				else{
					this.createCashRegister('openCashUp') // Create cashRegister Doc
				}
			})
			.catch((error) => {
				console.error("Error getting cashRegister Statement", error);
				this.cashUpDialog = false;
			});
			console.log("lastCashUp: ", lastCashUp)
			if(this.cashUpDialog){
				await db.collection("cashRegister")
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("branchId", "==", this.$store.state.activeBranch.id)
				.where("type", "==", "cashUp")
				.where("register", "==", this.selectedCashRegister)
				.orderBy("created", "desc")
				.limit(1)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						console.log(doc.id, " => ", doc.data());
						lastCashUp = doc.data();
						lastCashUp.docId = doc.id;
					});
				})
				.catch((error) => { //CashUp error
					console.error("Error cashUp documents: ", error);
				});
				if(lastCashUp){ // Get all PIN transactions after last cashup
					await db.collection("receiptsPaid")
					.where("companyId", "==", this.$store.state.activeCompany.id)
					.where("branchId", "==", this.$store.state.activeBranch.id)
					.where("register", "==", this.selectedCashRegister)
					.where("created", ">", lastCashUp.created)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((docReceipt) => {
							// console.log(docReceipt.id, " => ", docReceipt.data());
							let paidReceiptByCard = new Object();
							if (docReceipt.data().paymentTableObj.pin != 0) {
								paidReceiptByCard.pin = docReceipt.data().paymentTableObj.pin;
								paidReceiptByCard.docId = docReceipt.id;
								this.paidReceiptsCashUp.push(paidReceiptByCard);
							}
						});
					})
					.catch((error) => { // paidReceipts error
						console.error("Error getting paidReceipts documents: ", error);
					});
				}
				else{
					await db.collection("receiptsPaid") // Get all PIN transactions
					.where("companyId", "==", this.$store.state.activeCompany.id)
					.where("branchId", "==", this.$store.state.activeBranch.id)
					.where("register", "==", this.selectedCashRegister)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((docReceipt) => {
							let paidReceiptByCard = new Object();
							if (docReceipt.data().paymentTableObj.pin != 0) {
								paidReceiptByCard.pin = docReceipt.data().paymentTableObj.pin;
								paidReceiptByCard.docId = docReceipt.id;
								this.paidReceiptsCashUp.push(paidReceiptByCard);
							}
						});
					})
					.catch((error) => { // paidReceipts error
						console.error("Error getting paidReceipts documents: ", error);
					});	
				}
				// Sum Up the pins to cardStatement	
				this.cardStatement = this.paidReceiptsCashUp.reduce((total, currentValue) => total + Number(currentValue.pin), 0);
				this.pinIncomeStatement = this.paidReceiptsCashUp.reduce((total, currentValue) => total + Number(currentValue.pin), 0);
				this.creditcardIncomeStatement = this.paidReceiptsCashUp.reduce((total, currentValue) => total + currentValue.creditcard ? Number(currentValue.creditcard) : 0 , 0);
			
			}
		},

		closeCashUp(type) {
			this.cashUp = {
				oneEuroCent: 0,
				twoEuroCent: 0,
				fiveEuroCent: 0,
				tenEuroCent: 0,
				twentyEuroCent: 0,
				fiftyEuroCent: 0,
				oneEuro: 0,
				twoEuro: 0,
				fiveEuro: 0,
				tenEuro: 0,
				twentyEuro: 0,
				fiftyEuro: 0,
				hundredEuro: 0,
				twoHundredEuro: 0,
				fiveHundredEuro: 0,
				pin: 0,
				creditcard: 0,
			};
			//Refresh and Wipe-out History
			this.calculateCashStatement(0, "oneEuroCent");

			this.cashUpDifference = 0;
			this.cashUpDepositToBank = 0;
			this.cashStatement = 0;
			this.cashStatementStay.value = 0;
			this.cashUpDifference = 0;

			if (type === "close") {
				this.cashUpDialog = false;
				this.cashUpFeedback = { box: null, message: null, loading: false };
			} else {
				//Reset
				// this.calculateCashStatement();
				this.cashUpFeedback = { box: null, message: null, loading: false };
			}
		},

		messageTranslate(message) {
			console.log("message", message);

			let getLanguage = message.find((mess) => mess.language === this.userLanguage.toLowerCase());

			if (getLanguage) {
				return getLanguage.message;
			} else {
				if (message.length === 0) {
					return "";
				} else {
					message[0].message;
				}
			}
		},

		clearClient() {
			//clearClient is not active or is in conceptstate.

			this.selectedClientData = new Object();
			this.clientId = "";

			//Remove Modifiers and Memberships
			this.usedModifiers = this.usedModifiers.filter((usedModifier) => !usedModifier.modifierGroup === "membershipModifier");
			// this.usedModifiers = this.usedModifiers.filter(usedModifier => !usedModifier.byMembership);
			// this.modifiers = this.modifiers.filter(usedModifier => !usedModifier.type === "memberhsip");
			this.validatedByMembershipModule = new Array();
			this.validForUseMemberships = new Array();
			this.correspondingMemberships = new Array();
			this.notValidForUseMemberships = new Array();

			//localReceipt remove Client name and Id
			let localReceiptIds = this.localReceiptDrafts.map((localReceipt) => localReceipt.id);
			console.log("localReceiptIds", localReceiptIds, localReceiptIds.indexOf(this.selectedReceiptId));
			let foundedIndex = localReceiptIds.indexOf(this.selectedReceiptId);

			this.localReceiptDrafts[foundedIndex].clientname = "";
			this.localReceiptDrafts[foundedIndex].clientId = "";

			db.collection("receiptsDraft")
				.doc(this.selectedReceiptId)
				.update({
					clientname: this.$t('payment.anonymous', this.$store.state.locale),
					clientId: "",
				})
				.then(() => {
					//Recalculate
					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
				})
				.catch((error) => {
					console.error("Error updating document Receipts Draft : ", error);
				});
		},

		//Get the selected Client Data
		async getSelectedClientData(clientId, fullName, receiptObj) {
			let vm = this;
			console.log("getSelectedClientData: ", clientId);

			this.clientLoading = true;

			//Memberships
			this.userMemberships = new Array();
			this.validForUseMemberships = new Array();
			this.notValidForUseMemberships = new Array();
			this.correspondingMemberships = new Array();

			//Generated by Agenda or Widget
			if (receiptObj) {
				if (receiptObj.uniqIdStamp) {
					console.log("Activeer generateByUniqIdStamp");
					await this.generateByUniqIdStamp(receiptObj.uniqIdStamp, receiptObj.selectedItems, receiptObj.id);
				}
			}

			//Download History Receipts
			historyReceipts();

			async function historyReceipts() {
				return await db
					.collection("receiptsPaid")
					.where("companyId", "==", vm.$store.state.activeCompany.id)
					.where("clientId", "==", clientId)
					.orderBy("created", "desc")
					.limit(5)

					.get()
					.then((snap) => {
						snap.forEach((doc) => {
							console.log("Found History Receipt", doc.id);
							let historyReceipt = doc.data();
							historyReceipt.id = doc.id;
							vm.historyReceipts.push(historyReceipt);
						});
					})
					.then(() => {
						return;
					})
					.catch((err) => {
						console.error("Error History document", err);
					});
			}

			db.collection("clients")
				.doc(clientId)
				.get()
				.then((doc) => {
					console.log("We have clientData");
					this.selectedClientData = doc.data();
					this.selectedClientData.id = doc.id;
					this.selectedClientData.history = null;
				})
				.then(() => {
					// Get Future Appointments Client
					this.getFutureAppointmentsClient();

					//memberships and prePayments

					this.loadMemberships = true;
					Promise.all([membershipAllBranches(), membershipByBranch(), getPrePayments(), getLoyaltyDocs()]).then(() => {
						/**
						 * MEMBERSHIPS
						 */

						//Data for modifier is manipulated Inside the module
						const clonedUserMemberships = JSON.parse(JSON.stringify(this.userMemberships));
						const clonedSelectedItems = JSON.parse(JSON.stringify(this.selectedItems));
						const clonedClientId = this.selectedClientData.id;
						console.log(receiptObj)
						const clonedReceiptObj = receiptObj ? JSON.parse(JSON.stringify(receiptObj)) : new Object;

						let data = membershipValidation(clonedUserMemberships, clonedSelectedItems, clonedClientId, clonedReceiptObj, this.bookingSumArr);

						console.log("membership data", data);

						this.validForUseMemberships = data.validForUseMemberships;
						console.log("VALID MEMBERSHIP", data.validForUseMemberships);

						this.notValidForUseMemberships = data.notValidForUseMemberships;
						this.correspondingMemberships = data.correspondingMemberships;

						//Period or Total go to Front for Override

						//TODO if statement maken om duplicaties te voorkomen
						this.notValidForUseMemberships.forEach((membership) => {
							//Every status that is not valid is like below. If a  membership is valid the status = true;

							if (membership.status.code === "2A" || membership.status.code === "2B") {
								this.validForUseMemberships.push(membership);
							}
						});

						this.validatedByMembershipModule = [...data.validForUseMemberships, ...data.notValidForUseMemberships];
						this.validatedByMembershipModule = _.uniqBy(this.validatedByMembershipModule, ["id"]);

						// this.validForUseMemberships.forEach((validMembership) => this.modifiers.push(validMembership));

						/**
						 * prePayments
						 */

						// let newPrePaymentModifier = new Object();
						// let newPrePaymentModifierDiscount = new Object();

						if (this.selfGeneratedReceipt === true) {
							//Start matching with UniqIdStamp

							let foundedPrepayment = this.prePayments.find((prePayment) => prePayment.uniqIdStamp === receiptObj.uniqIdStamp);

							console.log("receiptObj.uniqIdStamp", receiptObj.uniqIdStamp, foundedPrepayment);

							/**
							 *
							 * Generated A fixed Amount Discount for Treatment
							 * 1. Get the highest Price Treatment first and place them in a filter
							 * 2. Generated the fixed amount for Pre Payment.
							 */

							//check if there is a prepayment
							if (!foundedPrepayment || foundedPrepayment.paymentStatus !== "paid") {
								//Nothing to match return; 

								this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

								this.loadingDataTable = false;
								this.clientReceiptDataLoading = false;
								this.loadMemberships = false;

								return;

							}

							function typePrePayment(type) {
								if (type === "prePayment") {
									return "Vooruitbetaling"; //prePayment
								} else {
									return "Aanbetaling"; //downPayment
								}
							}

							if (foundedPrepayment.type === "downPayment") {

								let newDownPaymentModifier = new Object();

								newDownPaymentModifier.name = typePrePayment(foundedPrepayment.type);
								newDownPaymentModifier.id = foundedPrepayment.id;
								newDownPaymentModifier.timestamp = foundedPrepayment.timestamp;
								newDownPaymentModifier.priority = this.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "prePaymentManualAdded").priorityNumber;

								// newDownPaymentModifier.createdStamp = new Date();

								newDownPaymentModifier.byPrePayment = true;
								newDownPaymentModifier.prePaymentDiscount = false; //Extra PrePaid Discount
								newDownPaymentModifier.manualAddedPrePayment = true;

								newDownPaymentModifier.type = foundedPrepayment.type; //PrePayment or DownPayment
								newDownPaymentModifier.modifierGroup = foundedPrepayment.type //PrePayment or DownPayment
								newDownPaymentModifier.paymentType = foundedPrepayment.type;
								newDownPaymentModifier.automatic = false;
								newDownPaymentModifier.active = true;
								newDownPaymentModifier.addedVia = "downPayment";

								newDownPaymentModifier.typeDiscount = {
									type: "fixedAmount",
									discountType: "amount",
									uniqueItems: false,
									discountValue: foundedPrepayment.amount.number,
								};
								newDownPaymentModifier.numberLimit = {
									active: false,
								};
								newDownPaymentModifier.combinationLimit = false;

								newDownPaymentModifier.useLimit = {
									active: true,
									number: 1,
								};

								newDownPaymentModifier.filterProducts = {
									active: true,
									items: new Array(), //Added by POS MODULE function filterPrePaymentsTreatments(modifier)
									itemsAction: "include",
									type: "all",
								};

								newDownPaymentModifier.amount = foundedPrepayment.amount;

								newDownPaymentModifier.filterTreatments = {
									active: true,
									items: new Array(), //Added by POS MODULE function filterPrePaymentsTreatments(modifier)
									itemsAction: "include",
									type: "all",
								};

								this.usedModifiers = [newDownPaymentModifier, ...this.usedModifiers]; //We want to load this first.
								this.modifiers.push(newDownPaymentModifier);
								this.customModifiers.push(newDownPaymentModifier);
							}



						}
						//Recalculate after correspondingMembership is found
						this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

						this.loadingDataTable = false;
						this.clientReceiptDataLoading = false;
						this.loadMemberships = false;
					})


					async function membershipAllBranches() {
						return await db
							.collection("memberships")
							.where("companyId", "==", vm.$store.state.activeCompany.id)
							.where("clientId", "==", vm.selectedClientData.id)
							.where("branches.all", "==", true)
							.get()
							.then((snap) => {
								snap.forEach((doc) => {
									console.log("Found Membership for All Branches", doc.id);
									let userMembership = doc.data();
									userMembership.id = doc.id;
									userMembership.priority = vm.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "membershipDiscount").priorityNumber;

									vm.userMemberships.push(userMembership);
								});
							})
							.then(() => {
								return;
							})
							.catch((err) => {
								console.error("Error Membership All Branches", err);
							});
					}

					async function membershipByBranch() {
						return await db
							.collection("memberships")
							.where("companyId", "==", vm.$store.state.activeCompany.id)
							.where("clientId", "==", vm.selectedClientData.id)
							.where("branches.branches", "array-contains", vm.$store.state.activeBranch.id)
							.get()
							.then((snap) => {
								snap.forEach((doc) => {
									console.log("Found Membership for specific Branches", doc.id);
									let userMembership = doc.data();
									userMembership.id = doc.id;
									userMembership.priority = vm.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "membershipDiscount").priorityNumber;
									vm.userMemberships.push(userMembership);
								});
							})
							.then(() => {
								return;
							})
							.catch((err) => {
								console.error("Error Membership All Branches", err);
							});
					}

					async function getPrePayments() {
						return await db
							.collection("prepayments")
							.where("companyId", "==", vm.$store.state.activeCompany.id)
							// .where("branchId", "==", vm.$store.state.activeBranch.id)
							.where("clientId", "==", vm.selectedClientData.id)
							.where("status.returned", "==", false)

							.get()
							.then((snap) => {
								snap.forEach((doc) => {
									let prePayment = doc.data();
									prePayment.id = doc.id;
									if(!doc.data().branchId || doc.data().branchId == vm.$store.state.activeBranch.id){
										vm.prePayments.push(prePayment);
										vm.clientPrePayments.push(prePayment);
										// vm.prePaymentsListTab.push(prePayment);
									}
								});
							})
							.then(() => {
								//vm.clientPrePayments.sort((a, b) => a.timestamp.seconds.localeCompare(b.timestamp.seconds));
								vm.clientPrePayments.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
								return;
							})
							.catch((err) => {
								console.error("Error prePayments", err);
							});
					}

					//Get all the Loyaty docs for Client X
					async function getLoyaltyDocs() {
						if (vm.$store.state.activeBranch.loyalty && vm.$store.state.activeBranch.loyalty.active === true) {
							//TODO - Get all the loyalty docs for this client

							//async map loop
							return await Promise.all(
								vm.$store.state.activeBranch.loyalty.redeemBranches.map(async (branchId) => {
									//Get all the loyalty docs for Client X
									return await db
										.collection("loyaltyPoints")
										.where("companyId", "==", vm.$store.state.activeCompany.id)
										.where("branchId", "==", branchId)
										.where("clientId", "==", vm.selectedClientData.id)

										.get()
										.then((snap) => {
											snap.forEach((doc) => {
												let loyaltyDoc = doc.data();
												loyaltyDoc.id = doc.id;
												vm.clientLoyaltyDocs.push(loyaltyDoc);
											});
										})
										.then(() => {
											return;
										})
										.catch((err) => {
											console.error("Error loyaltyDocs", err);
										});
								})
							);
						} else {
							return;
						}
					}

					// //Receipt History
					// db.collection("receiptsDraft")
					//   .where("companyId", "==", this.activeComp)
					//   .where("clientId", "==", this.selectedClientData.id)
					//   .where("draft", "==", false)
					//   .get()
					//   .then((snap) => {
					//     snap.forEach((doc) => {
					//       console.log("Item gevonden: bon");
					//       let historyItem = doc.data();
					//       historyItem.id = doc.id;
					//       historyItems.push(historyItem);
					//     });
					//   })
					//   .then(() => {
					//     if (historyItems.length > 0) {
					//       // There are receipts from history
					//       let receiptCounter = 0;
					//       new Promise((resolve, reject) => {
					//         // Loop trough receipts
					//         historyItems.forEach((historyItem) => {
					//           // Loop trough items in receipt
					//           new Promise((res, rej) => {
					//             let itemCounter = 0;
					//             historyItem.selectedItems.forEach((item) => {
					//               let purchasedItem = item;
					//               purchasedItem.date = historyItem.created;
					//               if (item.type == "product") {
					//                 historyItemsProducts.push(purchasedItem);
					//               } else {
					//                 historyItemsTreatments.push(purchasedItem);
					//               }
					//               itemCounter++;
					//               if (itemCounter == historyItem.selectedItems.length) {
					//                 res();
					//               }
					//             });
					//           }).then(() => {
					//             receiptCounter++;
					//             if (receiptCounter == historyItems.length) {
					//               resolve();
					//             }
					//           });
					//         });
					//       })
					//         .then(() => {
					//           this.selectedClientData.history = {
					//             treatments: historyItemsTreatments,
					//             products: historyItemsProducts,
					//           };
					//         })
					//         .then(() => {
					//           this.clientLoading = false;
					//           this.clientReceiptDataLoading = false;
					//         });
					//     } else {
					//       // There are NO receipts from history
					//       this.selectedClientData.history = null;
					//       this.clientLoading = false;
					//       this.clientReceiptDataLoading = false;
					//     }
					//   })
					//   .then(() => {
					//     this.clientLoading = false;
					//     if (fullName) {
					//       this.updateResource(); //Update Resource
					//       this.updateReceipt(this.selectedReceiptId, clientId, fullName); //Update with new/changed Client Data
					//     }
					//   })
					//   .catch((error) => {
					//     this.clientReceiptDataLoading = false;
					//     console.error("Error getting client receipt history: ", error);
					//   });
				})
				.catch((error) => {
					this.clientReceiptDataLoading = false;
					console.error("Error getting clientdata: ", error);
				});
		},

		async downloadProductsAndTreatments() {
			let vm = this;

			//Get All neccesary Items without discount for Recalculations

			let downloadProductsArr = _.uniqBy(
				this.selectedItems
					.filter((selectedItem) => selectedItem.type === "product")
					.map((selectedItem) => {
						return { type: selectedItem.type, id: selectedItem.id };
					}),
				"id"
			);
			let downloadTreatmentsArr = _.uniqBy(
				this.selectedItems
					.filter((selectedItem) => selectedItem.type === "treatment")
					.map((selectedItem) => {
						return { type: selectedItem.type, id: selectedItem.id };
					}),
				"id"
			);
			//Re-Download the original Treatments and Products

			// console.log("downloadProductsArr", downloadProductsArr);

			// console.log("downloadTreatmentsArr", downloadTreatmentsArr);

			Promise.all([downloadTreatments(downloadTreatmentsArr), downloadProducts(downloadProductsArr)]).then(() => {
				console.log("Treatments and Products downloaded...");
				return;
			});

			function downloadTreatments(downloadTreatmentsArr) {
				return new Promise((resolve) => {
					//Resolve if there is nothing
					if (downloadTreatmentsArr.length === 0) resolve();

					let vatItems = vm.vatItems;

					//Write VAT to Object
					function getVAT(vatId) {
						for (let index = 0; index < vatItems.length; index++) {
							if (vatItems[index].id == vatId) {
								// Example 32,00 * (100 + 9 BTW) / 100
								return vatItems[index].percentage;
							}
						}
					}

					function calculateVAT(data) {
						for (let index = 0; index < vatItems.length; index++) {
							if (vatItems[index].id == data.vat) {
								// Example 32,00 * (100 + 9 BTW) / 100
								// console.log("Bruto Price", cashConv(data.treatmentPrice * (100 + vatItems[index].percentage) / 100,'EUR', 4));
								return cashConv((data.treatmentPrice * (100 + vatItems[index].percentage)) / 100, "EUR", 4);
							}
						}
					}

					let countTreatment = 0;
					downloadTreatmentsArr.forEach((downloadTreatment) => {
						db.collection("treatments")
							.doc(downloadTreatment.id)
							.get()
							.then((doc) => {
								if (doc.exists) {
									let obj = doc.data();

									obj.priceBruto = doc.data().treatmentPrice ? calculateVAT(doc.data()) : 0;
									obj.addedDiscount = 0; //Extra Discount
									obj.calculatedDiscountVAT = 0;
									obj.calculatedDiscountNetto = 0;
									obj.usedModifierId = new Array();
									obj.usedMembershipId = new Array();

									obj.newPriceTotalNetto = doc.data().treatmentPrice ? doc.data().treatmentPrice : 0; //New Price with Discount and amount and VAT
									obj.newPriceTotal = doc.data().treatmentPrice ? calculateVAT(doc.data()) : 0; //Bruto TotalPrice
									obj.percentageVAT = doc.data().treatmentPrice ? getVAT(doc.data().vat) : 0; //Vat Percentage

									obj.name = doc.data().treatmentTitle;

									obj.internalUse = false; //False by default and set to true if internal use via GUI

									obj.type = "treatment";
									obj.id = doc.id;
									obj.selected = false; //For toggling

									//remove modified field as timestamp
									delete obj.modified;

									vm.treatmentsByBranchClone.push(obj);
								} else {
									console.log("Treatment not found");
								}
							})
							.then(() => {
								countTreatment++;
								if (countTreatment === downloadTreatmentsArr.length) {
									
									resolve();
								}
							})
							.catch((err) => {
								console.error("Error loading Treatment from Database", err);
							});
					});
				});
			}

			function downloadProducts(downloadProductsArr) {
				return new Promise((resolve) => {
					//Resolve if there is nothing
					if (downloadProductsArr.length === 0) resolve();

					let vatItems = vm.vatItems;

					function calculateVAT(data) {
						for (let index = 0; index < vatItems.length; index++) {
							if (vatItems[index].id == data.vat) {
								// Example 32,00 * (100 + 9 BTW) / 100
								return cashConv((data.priceNetto * (100 + vatItems[index].percentage)) / 100, "EUR", 4);
							}
						}
					}

					//Write VAT to Object
					function getVAT(vatId) {
						for (let index = 0; index < vatItems.length; index++) {
							if (vatItems[index].id == vatId) {
								// Example 32,00 * (100 + 9 BTW) / 100
								return vatItems[index].percentage;
							}
						}
					}

					let countProduct = 0;
					downloadProductsArr.forEach((downloadProduct) => {
						db.collection("products")
							.doc(downloadProduct.id)
							.get()
							.then((doc) => {
								if (doc.exists) {
									let obj = doc.data();

									// console.log("Product Data", doc.data())

									obj.priceBruto = calculateVAT(doc.data()); //New Price Bruto based on amount

									obj.addedDiscount = 0; //Extra Discount

									obj.newPriceTotalNetto = doc.data().priceNetto; //New Price with Discount and amount
									obj.newPriceTotal = calculateVAT(doc.data()); //New Bruto TotalPrice
									obj.percentageVAT = getVAT(doc.data().vat); //Vat Percentage
									obj.calculatedDiscountVAT = 0;
									obj.calculatedDiscountNetto = 0;
									obj.usedModifierId = new Array();
									obj.usedMembershipId = new Array();

									obj.userAdded = true; //Add manually

									obj.resourceId = null; //Sale by Resource
									obj.internalUse = false; //False by default and set to true if internal use via GUI

									obj.type = "product";
									obj.id = doc.id;
									obj.selected = false; //For toggling

									vm.productsByBranchClone.push(obj); //Clean without Discount

								}
							})
							.then(() => {
								countProduct++;
								if (countProduct === downloadProductsArr.length) {
									resolve();
									
								}
							})
							.catch((err) => {
								console.error("Error loading Product from Database", err);
							});
					});
				});
			}
		},

		getFutureAppointmentsClient() {
			let today = new Date();
			this.selectedClientData.future = [];
			db.collection("bookingsummary")
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("clientId", "==", this.selectedClientData.id)
				.where("visit", ">", moment(today).format("YYYY-MM-DD"))
				.get()
				.then((snap) => {
					snap.forEach((doc) => {
						let historyItem = doc.data();
						historyItem.id = doc.id;
						this.selectedClientData.future.push(historyItem);
					});
				})
				.then(() => {
					this.clientLoading = false;
				});
		},

		/* 

    ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗    ██████╗ ███████╗ ██████╗███████╗██╗██████╗ ████████╗
    ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝    ██╔══██╗██╔════╝██╔════╝██╔════╝██║██╔══██╗╚══██╔══╝
    ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗      ██████╔╝█████╗  ██║     █████╗  ██║██████╔╝   ██║   
    ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝      ██╔══██╗██╔══╝  ██║     ██╔══╝  ██║██╔═══╝    ██║   
    ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗    ██║  ██║███████╗╚██████╗███████╗██║██║        ██║   
    ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚═╝  ╚═╝╚══════╝ ╚═════╝╚══════╝╚═╝╚═╝        ╚═╝   
                                                                                                          

    */

		updateReceipt(selectedReceiptId, clientId, fullName) {
			db.collection("receiptsDraft")
				.doc(selectedReceiptId)
				.set(
					{
						clientId: clientId,
						clientname: fullName,
						selectedItems: this.selectedItems.length !== 0 ? this.selectedItems : new Array(),
					},
					{ merge: true }
				)

				.then(() => {
					this.clientSearchFieldFocus = false;
					console.log("Receipt Updated with ClientId");
				})
				.catch((error) => {
					console.error("Error updating document Receipts Draft : ", error);
				});
		},

		resetProductFilter(type){
			if(type != 'all' && this.selectedSupplier && this.selectedSupplierGroup && this.selectedSupplierGroups.length > 0){
				this.selectSupplier(this.selectedSupplier)
			}
			else{
				this.selectedSupplier = null;
				this.selectedSupplierGroups = new Array();
				this.productsFiltered = new Array();
			}
			this.selectedSupplierGroup = null;
		},

		selectSupplier(idSupplier) {
			// console.log(idSupplier);
			this.selectedSupplierGroups = new Array();

			let supplier = this.productSuppliers.find((item)=> item.id == idSupplier)
			if(supplier != undefined){
				if(supplier.groups && supplier.groups.length > 0){
					this.selectedSupplierGroups = supplier.groups
				}
				else{
					this.loadProductsBySelect(idSupplier);
				}
				this.selectedSupplier = idSupplier;
			}
		},

		async loadProductsBySelect(value){
			console.log("load loadProductsBySelect: ", value)
			this.productsFiltered = new Array();
			const products = new Array();

			let index = this.algoSearch.initIndex("thrive_products");
			let FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
			let ActiveFilterString = 'active:true';

			await index.search(value, {
				userToken: this.$store.state.activeCompany.id,
				facetFilters: [FacetFilterString, ActiveFilterString],
			})
			.then(({ hits }) => {
				hits.forEach((product) => {
					products.push(product)
				});
			})
			this.productsFiltered = products;
		},

		resetTreatmentFilter(){
			this.selectedTreatmentType = null;;
			this.treatmentsFiltered = new Array();
		},


		async loadTreatmentsByType(value){
			this.selectedTreatmentType = value;
			this.treatmentsFiltered = new Array();
			const treatments = new Array();

			let index = this.algoSearch.initIndex("thrive_treatments");
			let FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
			let ActiveFilterString = 'treatmentActive:true';
			let ActiveFilterBranch = `"branches:${this.$store.state.activeBranch.id}`;

			await index.search(value, {
				userToken: this.$store.state.activeCompany.id,
				facetFilters: [FacetFilterString, ActiveFilterString],
				// filter: ActiveFilterBranch
			})
			.then(({ hits }) => {
				hits.forEach((treatment) => {
					treatments.push(treatment)
				});
			})
			this.treatmentsFiltered = treatments;
		},

		async loadTreatmentTypes() {
			this.treatmentTypes = new Array();

			if(this.filterType == 'customTreatmentType'){
				await db.collection("companies")
				.doc(this.$store.state.activeCompany.id)
				.collection("customTreatmentTypes")
				.where("deleted.deleted", "==", false)
				.get()
				.then(snap => {
					snap.forEach(doc => {
						let treatmentType = doc.data();
						treatmentType.id = doc.id;
						treatmentType.order = doc.data().order ? doc.data().order : 0;
						this.treatmentTypes.push(treatmentType)
					});
				})
				this.treatmentTypes.sort((a,b) => a.order - b.order)
			}
			else{
				await db.collection("treatmentTypes")
				.get()
				.then(snap => {
					snap.forEach(doc => {
						let treatmentType = doc.data();
						treatmentType.id = doc.id;
						this.treatmentTypes.push(treatmentType)
					});
				})
			}
			return
		},
			

		transformHitsItems(items) {
			//Barcode Scanner for adding products
			return items.map((item) => {
				if (item.barcode) {
					if (item.barcode.code == this.productSearch) {
						this.productSearch = "";
						this.posInputAdd(item.objectID, "product", true);
					}
				}
				return item;
			});
		},

		selectCategory(idCategory) {
			console.log("idCatogory:", idCategory);
			this.selectedCategoryProducts = [];
			this.productsByBranch.forEach((o) => {
				console.log("o.category: ", o.category);
				if (_.indexOf(o.category, idCategory) >= 0 && o.supplier == this.selectedSupplier) {
					this.selectedCategoryProducts.push(o);
				}
			});
		},

		//This Function is used for updateing "verkocht door"
		updateResourceProducts() {
			db.collection("receiptsDraft")
				.doc(this.selectedReceiptId)
				.update({
					selectedItems: this.selectedItems,
					usedModifiers: this.usedModifiers,
					deletedModifiers: this.deletedModifiers,
					modified: new Date(),
					customModifiers: this.customModifiers,
				})
				.then(() => {
					console.log("Document Receipts Draft Resource updated: ", this.selectedReceiptId);
					this.tempReceiptKey += 1;
				})
				.catch((error) => {
					console.error("Error updating updateResourceProducts document Receipts Draft : ", error);
				});
		},

		posInputDelete(indexSelectedItem, isUserAdded) {
			console.log("userAdded", isUserAdded);

			this.loadingDataTable = true;
			this.tab = 0;

		   //Item is not added by user 
			if(!isUserAdded) {
				
				
				// Pushing the selected item to the deletedSelfGeneratedItems array as an Object
				this.deletedSelfGeneratedItems.push({uniqIdStamp: this.selectedItems[indexSelectedItem].uniqIdStamp, bookingSummaryId: this.selectedItems[indexSelectedItem].bookingSummaryId });
			
			   this.selectedItems.splice(indexSelectedItem, 1);
				db.collection("receiptsDraft")
					.doc(this.selectedReceiptId)
					.set({deletedSelfGeneratedItems: this.deletedSelfGeneratedItems}, {merge: true})
					.then(() => {
						console.log("%c Receipt deletedSelfGeneratedItems updated");
					   this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);

					})
					.catch((error) => {
						console.error("Error updating document Receipts Draft : ", error);
					});

			} else {
				//Item is added by user
				this.selectedItems.splice(indexSelectedItem, 1);
				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
			}

		},

		posHistoryAdd(id, indexer, fullReceipt) {
			//id of receiptsPaid doc id

			console.log("id history Receipt", id, indexer);

			if (fullReceipt === true) {
				let somethingReturned = false;

				//Find by id
				let getHistoryReceipt = this.historyReceipts.find((historyReceipt) => historyReceipt.id === id);

				getHistoryReceipt.selectedItems.forEach((selectedItem) => {
					//  The above code is checking if the selected item is not in the returned items list and if
					//  the selected item is a deposit. If both of these conditions are true, then the selected
					//  item is returned and the type of payment is changed to withdraw.
					if (
						getHistoryReceipt.returnedItems.map((returnedItem) => returnedItem.uniqueIdentifier).includes(selectedItem.uniqueIdentifier) === false &&
						selectedItem.typeOfPayment === "deposit"
					) {
						somethingReturned = true;
						selectedItem.returned = true;
						selectedItem.typeOfPayment = "withdraw";
						selectedItem.historyDocId = id; //Returned DocId for update the older receiipt
						this.selectedItems.push(selectedItem);
					}
				});

				if (somethingReturned) {
					this.tab = 0;
				} else {
					//Show alert nothing to return
					this.feedbackHistory = true;
				}
			}

			//Only return 1 item
			if (fullReceipt === false) {
				this.tab = 0;

				let getHistoryReceipt = this.historyReceipts.find((historyReceipt) => historyReceipt.id === id);

				if (getHistoryReceipt.selectedItems[indexer].typeOfPayment === "deposit") {
					let newReturnedItem = getHistoryReceipt.selectedItems[indexer];

					newReturnedItem.returned = true;
					newReturnedItem.typeOfPayment = "withdraw";
					newReturnedItem.historyDocId = id;

					console.log(newReturnedItem);

					this.selectedItems.push(newReturnedItem);
				}
			}

			//Recalculate
			this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
		},

		/**
		 *
		 * @param selectedItem | String Unique ID from selectedItems to match with "returnedItems"
		 */
		validateHistoryItem(uniqIdItem, indexHistoryReceipt) {
			// console.log("selectedItem",uniqIdItem, indexHistoryReceipt);
			if (indexHistoryReceipt.returnedItems) {
				/* This is checking if the unique identifier of the item is in the array of returned items 
            OR if the unique identifier of the item is in the array of selected items.
         */
				if (
					indexHistoryReceipt.returnedItems.map((returnedItem) => returnedItem.uniqueIdentifier).includes(uniqIdItem) ||
					this.selectedItems.map((selectedItem) => selectedItem.uniqueIdentifier).includes(uniqIdItem)
				) {
					return true;
				} else {
					return false;
				}
			} else {
				//returnedItems is undefined
				return false;
			}
		},

		addPrePaymentManual(prePaymentId, index) {
			let vm = this;

			this.tab = 0;

			let foundedPrepayment = this.prePayments.find((prePayment) => prePayment.id === prePaymentId);
			console.log("FOUNDED foundedPrepayment", foundedPrepayment);

			function typePrePayment(type) {
				if (type === "prePayment") {
					return "Oudere" + " " + "Vooruitbetaling";
				} else {
					return "Oudere" + " " + "Aanbetaling";
				}
			}

			/**
			 *
			 * Generated A fixed Amount Discount for Treatment
			 * 1. Get the highest Price Treatment first and place them in a filter
			 * 2. Generated the fixed amount for Pre Payment.
			 */


			 console.log("foundedPrepayment", foundedPrepayment.type);



			if (foundedPrepayment) {
				//If the foundedPrepayment is not found in usedModifiers then add it manual
				if (!this.usedModifiers.map((usedModifier) => usedModifier.id).includes(foundedPrepayment.id)) {
					let newDownPaymentModifier = new Object();

					newDownPaymentModifier.name = typePrePayment(foundedPrepayment.type);
					newDownPaymentModifier.id = foundedPrepayment.id;
					newDownPaymentModifier.timestamp = foundedPrepayment.timestamp;
					newDownPaymentModifier.priority = this.sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "prePaymentManualAdded").priorityNumber;

					// newDownPaymentModifier.createdStamp = new Date();

					newDownPaymentModifier.byPrePayment = true;
					newDownPaymentModifier.prePaymentDiscount = false; //Extra PrePaid Discount
					newDownPaymentModifier.manualAddedPrePayment = true;

					newDownPaymentModifier.type = foundedPrepayment.type; //PrePayment or DownPayment
					newDownPaymentModifier.modifierGroup = foundedPrepayment.type //PrePayment or DownPayment
					newDownPaymentModifier.paymentType = foundedPrepayment.type;
					newDownPaymentModifier.automatic = false;
					newDownPaymentModifier.active = true;
					newDownPaymentModifier.addedVia = "manualAddedPrePayment";

					newDownPaymentModifier.typeDiscount = {
						type: "fixedAmount",
						discountType: "amount",
						uniqueItems: false,
						discountValue: foundedPrepayment.amount.number,
					};
					newDownPaymentModifier.numberLimit = {
						active: false,
					};
					newDownPaymentModifier.combinationLimit = false;

					newDownPaymentModifier.useLimit = {
						active: true,
						number: 1,
					};

					newDownPaymentModifier.filterProducts = {
						active: true,
						items: new Array(), //Added by POS MODULE function filterPrePaymentsTreatments(modifier)
						itemsAction: "include",
						type: "all",
					};

					newDownPaymentModifier.amount = foundedPrepayment.amount;

					newDownPaymentModifier.filterTreatments = {
						active: true,
						items: new Array(), //Added by POS MODULE function filterPrePaymentsTreatments(modifier)
						itemsAction: "include",
						type: "all",
					};

					console.log("ADDED newDownPaymentModifier", newDownPaymentModifier);

					/**
					 * 1. We create a new modifier object and push it to the array of modifiers.
					 * 2. We push the modifier to the array of used modifiers.
					 */
					this.usedModifiers.push(newDownPaymentModifier);
					this.modifiers.push(newDownPaymentModifier);
					this.customModifiers.push(newDownPaymentModifier);

					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
				}
			}
		},

		async posInputAdd(objectId, productTreat, internalUse) {
			//Distinction bewteen product and treatment

			this.tab = 0;

			let typeOfPayment = "deposit"; //Or withdraw

			if (this.refundBoolean === true) {
				typeOfPayment = "withdraw";
			}

			let vm = this;

			let vatItems = this.vatItems;

			this.loadingDataTable = true;
			if (productTreat == "product") {
				function calculateVAT(data) {
					for (let index = 0; index < vatItems.length; index++) {
						if (vatItems[index].id == data.vat) {
							// Example 32,00 * (100 + 9 BTW) / 100
							return cashConv((data.priceNetto * (100 + vatItems[index].percentage)) / 100, "EUR", 4);
						}
					}
				}

				//Write VAT to Object
				function getVAT(vatId) {
					for (let index = 0; index < vatItems.length; index++) {
						if (vatItems[index].id == vatId) {
							// Example 32,00 * (100 + 9 BTW) / 100
							return vatItems[index].percentage;
						}
					}
				}

				await db
					.collection("products")
					.doc(objectId)
					.get()
					.then((doc) => {
						if (doc.exists) {
							//TODO disable until loaded
							let obj = doc.data();

							// console.log("Product Data", doc.data())

							obj.priceBruto = calculateVAT(doc.data()); //New Price Bruto based on amount

							obj.addedDiscount = 0; //Extra Discount

							obj.newPriceTotalNetto = doc.data().priceNetto; //New Price with Discount and amount
							obj.newPriceTotal = calculateVAT(doc.data()); //New Bruto TotalPrice
							obj.percentageVAT = getVAT(doc.data().vat); //Vat Percentage
							obj.calculatedDiscountVAT = 0;
							obj.calculatedDiscountNetto = 0;
							obj.usedModifierId = new Array();
							obj.usedMembershipId = new Array();

							obj.userAdded = true; //Add manually

							obj.resourceId = this.refundBoolean ? this.userId : null; //Sale by Resource (User Id bij teruggave)

							obj.type = "product";
							obj.id = doc.id;
							obj.selected = false; //For toggling

							console.log("typeOfPayment", typeOfPayment);
							console.log("obj.resourceId", obj.resourceId);

							obj.internalUse = internalUse ? true : false; //For internal use

							obj.typeOfPayment = typeOfPayment;

							this.selectedItems.push(obj);
							this.productsByBranchClone.push(obj); // Clean without Discount

							console.log("obj", obj);

							this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
						} else {
							//TODO Refresh POS
							//Deleted so refresh POS
							console.log("Product not found!");
							this.loadingDataTable = false;
						}
					})
					.catch((err) => {
						console.error("Error loading Product from Database", err);
					});
			}

			if (productTreat == "treatment") {
				//Write VAT to Object
				function getVAT(vatId) {
					for (let index = 0; index < vatItems.length; index++) {
						if (vatItems[index].id == vatId) {
							// Example 32,00 * (100 + 9 BTW) / 100
							return vatItems[index].percentage;
						}
					}
				}

				function calculateVAT(data) {
					for (let index = 0; index < vatItems.length; index++) {
						if (vatItems[index].id == data.vat) {
							// Example 32,00 * (100 + 9 BTW) / 100
							// console.log("Bruto Price", cashConv(data.treatmentPrice * (100 + vatItems[index].percentage) / 100,'EUR', 4));
							return cashConv((data.treatmentPrice * (100 + vatItems[index].percentage)) / 100, "EUR", 4);
						}
					}
				}

				db.collection("treatments")
					.doc(objectId)
					.get()
					.then((doc) => {
						if (doc.exists) {
							console.log("Treatment gevonden", objectId);

							let obj = doc.data();

							obj.priceBruto = doc.data().treatmentPrice ? calculateVAT(doc.data()) : 0;
							obj.addedDiscount = 0; //Extra Discount
							obj.calculatedDiscountVAT = 0;
							obj.calculatedDiscountNetto = 0;
							obj.usedModifierId = new Array();
							obj.usedMembershipId = new Array();

							obj.userAdded = true;

							obj.newPriceTotalNetto = doc.data().treatmentPrice ? doc.data().treatmentPrice : 0; //New Price with Discount and amount and VAT
							obj.newPriceTotal = doc.data().treatmentPrice ? calculateVAT(doc.data()) : 0; //Bruto TotalPrice
							obj.percentageVAT = doc.data().treatmentPrice ? getVAT(doc.data().vat) : 0; //Vat Percentage

							obj.name = doc.data().treatmentTitle;

							obj.type = "treatment";
							obj.id = doc.id;
							obj.selected = false; //For toggling
							obj.typeOfPayment = typeOfPayment;

							obj.internalUse = internalUse ? true : false; //For internal use


							//remove modified field as timestamp
							delete obj.modified;

							this.selectedItems.push(obj);
							this.treatmentsByBranchClone.push(obj); //Clean without Discount

							console.log("obj", obj);

							this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
						} else {
							//TODO Refresh POS
							//Deleted so refresh POS
							console.log("Treatment not found!");
							this.loadingDataTable = false;
						}
					})
					.catch((err) => {
						console.error("Error loading Treatment from Database", err);
					});
			}

			this.modifiers.forEach((modifier) => {
				
				quickValidate(this.selectedItems, modifier, this.usedModifiers, this.selectedClientData);

			})

			this.refundBoolean = false;

			// //Membership with Treatment variant
			// if (productTreat.type == "treatment") {
			//   let objNewTreatment = deepCopy(
			//     this.treatmentsByBranchClone.find((product) => product.id === id)
			//   );
			//   objNewTreatment.userAdded = true; //For manual input used by function generateByUniqIdStamp
			//   this.selectedItems.push(objNewTreatment);

			//         //Auto Toggle if Treatment is found
			//        let correspondingMembershipsIds = this.correspondingMemberships.map(corresponding => corresponding.id);
			//        this.validForUseMemberships.map(validMembership => {

			//          if(correspondingMembershipsIds.includes(validMembership.id)) {

			//            if(validMembership.status.code === '2A' || validMembership.status.code === '2B') {
			//              validMembership.toggle = false;

			//              //But its offPeak so activate the membership as offPeak
			//               if(validMembership.peakStatus.peak === "offPeak") {
			//                   validMembership.toggle = true;
			//                   this.posUseMembership(validMembership.id)
			//               }

			//            } else {
			//              validMembership.toggle = true;
			//             this.posUseMembership(validMembership.id)
			//            }
			//            return validMembership;
			//          } else {
			//            this.posUseMembership(validMembership.id)
			//            return validMembership;
			//          }

			//        })

			//   this.calculateModule(
			//     this.selectedItems,
			//     false,
			//     this.modifiers,
			//     this.modifiers,
			//     this.usedModifiers,
			//     this.deletedModifiers,
			//     true
			//   );
			// }



		},

		//Change the Resource
		updateResource() {
			//Selected Receipts for header receipts
			if (this.byResource !== null) {
				this.headerReceiptDrafts = this.localReceiptDrafts.filter((receipt) => receipt.userId == this.byResource);
				this.headerReceiptDraftsSearch = this.localReceiptDrafts.filter((receipt) => receipt.userId == this.byResource);
			} else {
				//If null
				this.headerReceiptDrafts = this.localReceiptDrafts;
				this.headerReceiptDraftsSearch = this.localReceiptDrafts;
			}
		},

		async resendReceipt(data, type){
			let receiptTemplate = null;
			let clientData = null;

			await db.collection('clients').doc(data.clientId)
			.get()
			.then(doc => {
				clientData = doc.data();
			})
			let language = clientData && clientData.language ? clientData.language : 'nl';

			if(!this.$store.state.activeBranch.posSettings.receiptTemplate){
				await db.collection('receiptTemplates')
				.where('companyId', '==', 'thrive')
				.where('templateType', '==', 'general')
				.get()
				.then(snap => {
					snap.forEach(doc => {
						receiptTemplate = doc.data();
					});
				})
			}
			else{
				await db.collection('receiptTemplates').doc(this.$store.state.activeBranch.posSettings.receiptTemplate)
				.get()
				.then(doc => {
					receiptTemplate = doc.data();
				})
			}

			let receiptData = receiptBuilder(
				this.$store.state.activeBranch,
				language,
				receiptTemplate,
				data.paymentTableObj.payedBy,
				data.paymentTableObj.receiptType,
				data.selectedItems,
				data.usedModifiers,
				data.paymentTableObj.totalBruto,
				data.paymentTableObj.totalDiscountBrutoWithoutVat,
				data.paymentTableObj.totalDiscountVat,
				data.paymentTableObj.total,
				data.paymentTableObj.totalVat,
				data.paymentTableObj.totalVats,
				data.id,
				data.time,
				type
			);


			let printData = {
				receiptData,
				printerSettings: window.localStorage.getItem("printerSetting") ? JSON.parse(window.localStorage.getItem("printerSetting")) : null,
			}

			let digitalReceiptData = {
				doc: "payment",
				branchId: data.paymentTableObj.branchId,
				clientId: data.paymentTableObj.clientId,
				type: "receipt",
				receipt: receiptData
			};

			if(type == 'digital'){
				this.viewReceiptData.resend = true;
				await this.emailReceipt(digitalReceiptData)
				this.viewReceiptData.resend = false;
				this.viewReceiptData.resendMessage = this.$t('clients.emailSuccessfullySent', this.$store.state.locale);
				setTimeout(() => {
					this.viewReceiptData.resendMessage = null;
				}, 1000);
			}
			else if(type == "history"){
				this.viewReceiptData.receipt = Buffer.from(receiptData, 'base64').toString('utf-8')
			}
			else{
				if (window && window.platform && window.platform.name == "Electron") {
					let { ipcRenderer } = require("electron");

					if (printData.printerSettings !== null) {
						ipcRenderer.send("printReceipt", printData);
					}
				}
			}
		},



		/**
		 * tmpReceiptData | string | Receipt Data for function receiptBuilder()
		 * receiptType | string : withdraw or deposit | Receipt Type
		 * receiptOption | string : How to get the receipt by print or by mail or nothing.		
		 */
		async rollOutReceipt(option) {
			this.printAndMailDialog.busy = true;
			let digitalReceipt = (option === "emailReceipt" || option === "printEmailReceipt") ? receiptBuilder(
				this.$store.state.activeBranch,
				this.tmpReceiptData.language,
				this.tmpReceiptData.receiptTemplate,
				this.tmpReceiptData.typePayment,
				this.tmpReceiptData.receiptType,
				this.tmpReceiptData.selectedItems,
				this.tmpReceiptData.usedModifiers,
				this.tmpReceiptData.totalBruto,
				this.tmpReceiptData.totalBrutoWithoutDiscount,
				this.tmpReceiptData.totalDiscountVAT,
				this.tmpReceiptData.totalNetto,
				this.tmpReceiptData.totalVAT,
				this.tmpReceiptData.totalVats,
				this.tmpReceiptData.receiptDocId,
				'digital'
			) : ""; 

			let printData = {
				generatedReceipt: receiptBuilder(
					this.$store.state.activeBranch,
					this.tmpReceiptData.language,
					this.tmpReceiptData.receiptTemplate,
					this.tmpReceiptData.typePayment,
					this.tmpReceiptData.receiptType,
					this.tmpReceiptData.selectedItems,
					this.tmpReceiptData.usedModifiers,
					this.tmpReceiptData.totalBruto,
					this.tmpReceiptData.totalBrutoWithoutDiscount,
					this.tmpReceiptData.totalDiscountVAT,
					this.tmpReceiptData.totalNetto,
					this.tmpReceiptData.totalVAT,
					this.tmpReceiptData.totalVats,
					this.tmpReceiptData.receiptDocId,
					'print'
				),
				printerSettings: window.localStorage.getItem("printerSetting") ? JSON.parse(window.localStorage.getItem("printerSetting")) : null,
			};

			let digitalReceiptData = {
				doc: "payment",
				branchId: this.$store.state.activeBranch.id,
				clientId: this.clientId,
				type: "receipt",
				receipt: digitalReceipt
			};

			if (option === "noReceipt") { // No receipt, close dialog
				console.log("noReceipt ACTION")
				this.closePrintMailDialog();
				return;
			}
			else if (option === "printReceipt") { // Print the receipt, but check for selected printer.			
				if (window && window.platform && window.platform.name == "Electron") {
					let { ipcRenderer } = require("electron");
					if (printData.printerSettings !== null) {
						ipcRenderer.send("printReceipt", printData);
					}
				}
				this.closePrintMailDialog();
				return;
			}
			else if (option === "emailReceipt") {

				// Send Receipt Email
				await this.emailReceipt(digitalReceiptData)
				this.closePrintMailDialog();
				return;
			}
			else if (option === "printEmailReceipt") { // Print and email
				if (window && window.platform && window.platform.name == "Electron") {
					let { ipcRenderer } = require("electron");

					if (printData.printerSettings !== null) {
						ipcRenderer.send("printReceipt", printData);
					}
				}

				// Send Receipt Email
				await this.emailReceipt(digitalReceiptData)
				this.closePrintMailDialog();
				return;
			}
			else {
				return;
			}
			this.clientId = "";
		},

		async emailReceipt(digitalReceiptData){
			console.log("emailReceiptFired")
			const axiosHeaders = {
				headers: { "Content-Type": "application/json" },
			};

			await axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, digitalReceiptData, axiosHeaders)
			.then((response) => {
				if(response) { 
				console.log("Receipt send")
				// Email succesfully sent! 
				}
			})
			.catch((error) => {
				console.error("Error sending receipt: ", error.message);
			});
			return 
		},

		closePrintMailDialog() {
			this.printAndMailDialog.dialog = false;
			this.tmpReceiptData = {};
			this.printAndMailDialog.busy = false;
		},

		checkPrinter() {
			let vm = this;
			//Get printerSetting from localStorage

			this.selectedPrinter = window.localStorage.getItem("printerSetting") ? JSON.parse(window.localStorage.getItem("printerSetting")) : null;

			const { ipcRenderer } = require("electron");

			//Printer is setup check for printer settings
			if (this.selectedPrinter !== null) {
				ipcRenderer.send("give-printers", "get printer");

				ipcRenderer.on("give-printers-answer", (event, arg) => {
					// console.log("ARG", arg);

					vm.printerStatus = arg;
					//Set timout 2 second
					// setTimeout(() => {
					checkPrinterStatus();
					// }, 2000);
				});
			}

			function checkPrinterStatus() {
				//Check the selected Printer status via "this.printerStatus" array and check selectedPrinter by factory name and the printer-state-reasons

				let printerStateReasonObject = vm.printerStatus.find((printer) => printer.name === vm.selectedPrinter.factoryName);

				// Checking if the printer is installed and if it is not installed it is giving a message that the
				// printer is not installed.
				if (printerStateReasonObject) {
					let printerstate = printerStateReasonObject.options["printer-state-reasons"];
					let printerstateArray = printerstate.split(",");

					if (printerstate === "none") {
						vm.printerStateReason = {
							printerName: "",
							printerStateReasons: printerstateArray.map((printerState) => {
								const printerStateObject = {
									reason: "Klaar voor gebruik",
									reasonCode: printerState,
									code: 1,
								};
								return printerStateObject;
							}),
						};

						//Extra options supplemented because the printer is ready
						vm.printAndMailDialog.options = [
							{ name: {nl: "<strong>Geen bon</strong><br> nodig", en: "<strong>No receipt</strong><br> needed"}, value: "noReceipt", icon:  "mdi-leaf", color: "#4CAF50" },
							{ name: {nl: "Bon<br> <strong>mailen</strong>", en: "<strong>Email</strong> <br> receipt",}, value: "emailReceipt", icon:  "mdi-email-fast", color: "#4CAF50"},
							{ name: {nl: "Bon<br> <strong>printen</strong>", en: "<strong>Print</strong><br> receipt"}, value: "printReceipt", icon:  "mdi-receipt", color: null },
							{ name: {nl: "Bon <strong>printen</strong><br> en <strong>mailen</strong>", en: "<strong>Print</strong> and <strong>mail</strong> receipt"}, value: "printEmailReceipt", icon:  "mdi-receipt-text-plus", color: null }
						];

						//set printer ready
						vm.printAndMailDialog.printerReady = true;
						vm.printAndMailDialog.loaderSettings = false;
					} else {
						// Creating an object with the following structure:
						vm.printerStateReason = {
							printerName: "",
							printerStateReasons: printerstateArray.map((printerState) => {
								const printerStateObject = {
									reason: printerState.replace(/-/g, " "),
									reasonCode: printerState,
									code: 3,
								};
								return printerStateObject;
							}),
						};

						vm.printAndMailDialog.printerReady = false;
						vm.printAndMailDialog.loaderSettings = false;
					}
				}

				if (!printerStateReasonObject) {
					console.error("Printer:", vm.selectedPrinter.printerName + " is not installed.");

					vm.printerStateReason = {
						printerName: vm.selectedPrinter.printerName,
						printerStateReasons: [
							{
								reason: "Printer is not installed",
								reasonCode: "printer-not-installed",
								code: 2,
							},
						],
					};
					vm.printAndMailDialog.loaderSettings = false;
				}
			}
		},

		receiptToClientDialog() {
			//Show get your receipt Dialog
			this.printAndMailDialog.dialog = true;
			this.printAndMailDialog.loaderSettings = true;

			//Default printer select options and will be supplemented later on via checkPrinter if in Electron

			this.printAndMailDialog.options = [
				{ name: {nl: "<strong>Geen bon</strong><br> nodig", en: "<strong>No receipt</strong><br> needed"}, value: "noReceipt", icon:  "mdi-leaf", color: "#4CAF50" },
				{ name: {nl: "Bon<br> <strong>mailen</strong>", en: "<strong>Email</strong> <br> receipt",}, value: "emailReceipt", icon:  "mdi-email-fast", color: "#4CAF50"},
			];

			if (window && window.platform && window.platform.name === "Electron") {
				this.checkPrinter();
			} else {
				this.printAndMailDialog.loaderSettings = false;
			}
		},


		handlePrePayments(remainingArray, withoutRemainingArray) {

			

			console.log("Before remainingPrePayments")
			// Filter out prepayments with remaining amount less than 0

			console.log("remainingPrePayments", remainingArray);



			// If there are any remaining prepayments
			if (remainingArray.length !== 0) {
				//1. Create Remaining prepayments Docs and Update the old ones by partialSettled.
				remainingArray.forEach((remainingPrePaymentModifier) => {
					// Add new prepayment document
					const prepaymentData = {
						timestamp: new Date(),
						clientId: this.clientId,
						companyId: this.$store.state.activeCompany.id,
						branchId: this.$store.state.activeBranch.id,
						type: remainingPrePaymentModifier.paymentType,
						amount: {
							number: Math.abs(remainingPrePaymentModifier.remainingAmount),
							currency: remainingPrePaymentModifier.amount.currency,
						},
						uniqIdStamp: null,
						paymentStatus: "paid",

						status: {
							returned: false,
							type: "remainingAmount",
							timestamp: null,
							receiptId: null,
							newPrePaymentDocId: null,
						},
					};

					const prepaymentsCollection = db.collection("prepayments");
					// Add new prepayment document
					prepaymentsCollection.add(prepaymentData)
						.then((docRef) => {
							const updateData = {

								paymentStatus: "paid",
								status: {
									returned: false,
									type: "partialSettled",
									timestamp: new Date(),
									receiptId: null,
									newPrePaymentDocId: docRef.id,
								},

							};

							console.log("New PrePayment Created", docRef.id);

							// Update old prepayment document
							prepaymentsCollection.doc(remainingPrePaymentModifier.id).update(updateData)
								.then(() => {
									console.log("Old PrePayment Updated");
								})
								.catch((err) => {
									console.error("Error updating old PrePayment", err);
								});
						})
						.catch((err) => {
							console.error("Error creating new Remaining prepayment document", err);
						});
				});
			}

			// 2. Update Old PrePayment docs without remainingAmounts
			console.log("withoutRemainingPrePayments", withoutRemainingArray);

			// If there are any prepayments without remaining amounts it will be settled
			if (withoutRemainingArray.length !== 0) {
				withoutRemainingArray.forEach((withoutRemainingPrePayment) => {

					//test db
					console.log("withoutRemainingPrePayment.id", withoutRemainingPrePayment.id)


					// Update old prepayment document
					db.collection("prepayments")
						.doc(withoutRemainingPrePayment.id)
						.set({
							status: {
								returned: true,
								type: "settled",
								timestamp: new Date(),
								receiptId: this.selectedReceiptId,
								newPrePaymentDocId: null,
							},
						}, { merge: true })
						.then(() => {
							console.log("Old PrePayment Updated");
						})
						.catch((err) => {
							console.error("Error old PrePayment Update", err);
						});
				});

			}
		},


		//Create payed Receipt
		async payReceipt() {
			console.log("payReceipt")
			let cashRegisterExists = false;

			await db.collection("cashRegister")
			.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
			.get()
			.then((doc) => {
				if (doc.exists) {
					cashRegisterExists = true;
				}
				else{
					this.createCashRegister('payReceipt') // Create cashRegister Doc
				}
			})

			if(cashRegisterExists){
				let vm = this;
				console.log("Paying Receipt");
				let receiptTemplate = null;

				if(!this.$store.state.activeBranch.posSettings.receiptTemplate){
					await db.collection('receiptTemplates')
					.where('companyId', '==', 'thrive')
					.where('templateType', '==', 'general')
					.get()
					.then(snap => {
						snap.forEach(doc => {
							receiptTemplate = doc.data();
						});
					})
				}
				else{
					await db.collection('receiptTemplates').doc(this.$store.state.activeBranch.posSettings.receiptTemplate)
					.get()
					.then(doc => {
						receiptTemplate = doc.data();
					})
				}

				//Save data for Receipt
				vm.tmpReceiptData = {
					language: this.selectedClientData && this.selectedClientData.language ? this.selectedClientData.language : 'nl',
					receiptTemplate: receiptTemplate,
					typePayment: vm.typePayment,
					receiptType: vm.totalBruto >= 0 ? "deposit" : "withdraw",
					totalBruto: vm.totalBruto,
					totalNetto: vm.totalNetto,
					totalBrutoWithoutDiscount: vm.totalBrutoWithoutDiscount,
					totalDiscountVAT: vm.totalDiscountVAT,
					totalVAT: vm.totalVAT,
					totalVats: vm.totalVats,
					selectedItems: vm.selectedItems,
					usedModifiers: vm.usedModifiers,
				};


				// Get paymentDevice by ID
				const paymentDevice = this.$store.state.activeCompany.paymentOptions.find((findDevice) => findDevice.id == this.paymentInfo.chosenPaymentDevice);		

				//** ADD PREPAYMENTS and DOWNPAYMENTS TO BQ **//
				let addedPrePayments = this.usedModifiers.filter(
					(usedModifier) => usedModifier.modifierGroup === "prePaymentModifier" || usedModifier.modifierGroup === "downPayment" && usedModifier.remainingAmount !== usedModifier.amount.number
				);

				
				

				
	
				//ADD USED MEMBERSHIPS
				let addedMemberships = this.usedModifiers.filter((usedModifier) => usedModifier.modifierGroup === "membershipModifier");

				let paymentTableObj = {
					clientId: this.clientId ? this.clientId : "anonymous",
					companyId: this.$store.state.activeCompany.id,
					branchId: this.$store.state.activeBranch.id,
					receiptType: this.totalBruto >= 0 ? "deposit" : "withdraw",
					posLocation: {
						locationId: this.posLocation.id === 'general' ? 'general' : this.posLocation.id, 
						locationName: this.posLocation.id === 'general' ? 'general' : this.posLocation.name
					},
					currency: "EUR", //Dynamisch
					loyaltyPoints: null,
					total: Math.abs(this.totalNetto),
					totalBruto: Math.abs(Number(this.totalBruto)), //To Number for Field
					totalDiscountBrutoWithoutVat: Math.abs(this.totalBrutoWithoutDiscount),
					totalDiscountVat: Math.abs(this.totalDiscountVAT),
					totalVat: Math.abs(this.totalVAT),
					totalVats: this.totalVats,
					payedBy: this.typePayment,
					payAndMail: "none", //Send receipt per mail?
					transactionClosedBy: {
						userId: this.userId,
						userRole: this.userRole,
						version: this.version,
					},
					pin: this.typePayment === "SPLIT" ? this.splitPayment.splitByCard : 0,
					cash: this.typePayment === "SPLIT" ? this.splitPayment.splitByCash : 0,
					transactionsNumber: 0, // Later uitbreiden als er meerdere transacties mogelijk zijn per bon
					chosenPaymentDevice: paymentDevice
						? {
								id: paymentDevice.id,
								name: paymentDevice.name,
								transactionCost: 0,
								type: null,
								intermediate: paymentDevice.intermediate,
								amount: 0,
							}
						: {
								id: "CASH",
								name: "CASH",
								transactionCost: 0,
								type: null,
								intermediate: false,
								amount: 0,
							},
					internalUse: this.internalUseFunc(this.selectedItems),
					items: new Array(),
				};

				/**
				 * Unique ID Stamp for History and BQ if there is already a stamp dont add a new one.
				 */

				this.selectedItems = this.selectedItems.map((selectedItem) => {
					//If there is no uniqueIdentifier add one. For Returning Items.
					if (!selectedItem.uniqueIdentifier) {
						selectedItem.uniqueIdentifier = uniqid();
					}
					return selectedItem;
				});

				console.log("paymentTableObj", paymentTableObj);
				//Items for Receipt
				paymentTableObj.items = this.selectedItems.map((item) => {
					return {
						id: item.id,
						uniqueIdentifier: uniqid(),
						typeOfPayment: item.typeOfPayment,
						percentageVAT: item.percentageVAT,
						typeItem: item.type, //Product or Treatment or Custom rule
						priceNetto: item.type === "product" || item.type === "customRule" ? item.priceNetto : item.treatmentPrice,
						priceBruto: item.priceBruto,
						newPriceTotalNetto: item.newPriceTotalNetto,
						newPriceTotalBruto: item.newPriceTotal,
						discountNetto: item.calculatedDiscountNetto,
						discountBruto: item.calculatedDiscountVAT,
						usedModifierId: item.usedModifierId,
						usedMembershipId: item.usedMembershipId,
						category: item.type === "product" || item.type === "customRule" ? item.category[0] : item.treatmentType,
						resourceId: item.type === "product" || item.type === "customRule" ? item.resourceId : "check subs",
						sub:
							item.type === "product" || item.type === "customRule"
								? []
								: item.subTreatments.map((subTreatment) => {
										return {
											subUniqueIdentifier: uniqid.time(),
											subSection: subTreatment.type, //What type of sub treatment
											subTypeSection: subTreatment.device, //What kind of planning
											subPriceNetto: subTreatment.priceNetto,
											subPriceBruto: subTreatment.priceBruto,
											subResourceId: subTreatment.resourceId,
										};
									}),
					};
				});

				//** ADD PREPAYMENTS TO BQ **//
				// Added PrePayments if exist
				if (addedPrePayments.length !== 0) {
					addedPrePayments = addedPrePayments.map((addedPrePayment) => {
						let itemObject = new Object();
						itemObject.typeOfPayment = addedPrePayment.paymentType;

						if (addedPrePayment.remainingAmount < 0) {
							// If there is some remaningAmount

							itemObject.newPriceTotalBruto = cashConv((Number(addedPrePayment.amount.number) + addedPrePayment.remainingAmount), "EUR", 4); //?
					
							itemObject.discountBruto = Math.abs(addedPrePayment.remainingAmount);
						} else {
							// There is no remainingAmount left
							itemObject.newPriceTotalBruto = addedPrePayment.amount.number; //?
							itemObject.discountBruto = addedPrePayment.amount.number; //?
						}

						itemObject.priceBruto = addedPrePayment.amount.number;
						itemObject.typeItem = addedPrePayment.paymentType; //"prePayment" Or "DownPayment"
						itemObject.category = "prePayment";
						return itemObject;
					});

					// Add Prepayment items to BQ
					paymentTableObj.items = [...paymentTableObj.items, ...addedPrePayments];
				}

				// /**
				//  * ADD MEMBERSHIP TO BQ LEGACY
				//  */

				// if (addedMemberships.length !== 0) {
				// 	addedMemberships = addedMemberships.map((addedMembership) => {
				// 		addedMembership.type; //?
				// 		itemObject = new Object();
				// 		itemObject.id = addedMembership.id;
				// 		itemObject.percentageVAT = vatItems.find((vat) => vat.id === addedMembership.vat).percentage;
				// 		/**
				// 		 * 
				// 			1. Find the VAT percentage of the added membership
				// 			2. Calculate the netto price of the added membership
				// 			3. Convert the netto price to the currency of the invoice
				// 		* 
				// 		*/

				// 		//Calculate BQ by Period
				// 		if (addedMembership.treatments.limitType === "period") {
				// 			let perPeriod = addedMembership.treatments.limitNumber * addedMembership.duration;
				// 			itemObject.priceNetto = cashConv(addedMembership.amount / perPeriod, "EUR", 4);
				// 			itemObject.priceBruto = cashConv(
				// 				itemObject.priceNetto * Number("1." + vatItems.find((vat) => vat.id === addedMembership.vat).percentage),
				// 				"EUR",
				// 				4
				// 			); //?
				// 		}

				// 		//Calculate BQ by Total
				// 		if (addedMembership.treatments.limitType === "total") {
				// 			itemObject.priceNetto = cashConv(addedMembership.amount / addedMembership.duration);
				// 			itemObject.priceBruto = cashConv(
				// 				itemObject.priceNetto * Number("1." + vatItems.find((vat) => vat.id === addedMembership.vat).percentage),
				// 				"EUR",
				// 				4
				// 			); //?
				// 		}
				// 	});

				// 	//Add membership items to BQ
				// 	paymentTableObj.items = [...paymentTableObj.items, ...addedMemberships];
				// }
				if (this.typePayment == "PIN") {
					console.log("paymentDevice.intermediate: ", paymentDevice.intermediate)
					paymentTableObj.pin = this.totalBruto;
					
					if (paymentDevice.intermediate == true) {
						paymentTableObj.chosenPaymentDevice.transactionCost = paymentDevice.transactionCost;
						paymentTableObj.chosenPaymentDevice.type = paymentDevice.type;
						if (paymentTableObj.chosenPaymentDevice.type == "amount") {
							paymentTableObj.chosenPaymentDevice.amount = paymentDevice.transactionCost;
						} else {
							let transactionPercentage = +paymentDevice.transactionCost / 100;
							paymentTableObj.chosenPaymentDevice.amount = + this.change * transactionPercentage;
						}
					}

					

					//Update CashRegister when changeCalculated is higher than 0
					if(this.changeCalculated > 0) {
						db.collection("cashRegister")
							.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
							.update({
								cash: firebase.firestore.FieldValue.increment(-this.changeCalculated),
								timestamp: new Date(),
							})
							.then(() => {
								console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
							})
							.catch((err) => {
								console.error("Cash Error", err);
							})					
					}
				}

				if (this.typePayment == "CASH") {
					paymentTableObj.cash = this.totalBruto;

					db.collection("cashRegister")
					.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
					.update({
						cash: firebase.firestore.FieldValue.increment(this.totalBruto),
						timestamp: new Date(),
					})
					.then(() => {
						console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
					})
					.catch((err) => {
						console.error("Cash Error", err);
					});
				}

				if (this.typePayment == "SPLIT") {
					paymentTableObj.cash = this.splitPayment.splitByCash;

					db.collection("cashRegister")
						.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
						.update({
							cash: firebase.firestore.FieldValue.increment(this.splitPayment.splitByCash),
							timestamp: new Date(),
						})
						.then(() => {
							console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
						})
						.catch((err) => {
							console.error("Cash Error", err);
						});
				}

				if (this.typePayment == "REFUNDCASH") {
					paymentTableObj.cash = this.totalBruto;

					db.collection("cashRegister")
						.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
						.update({
							cash: firebase.firestore.FieldValue.increment(this.totalBruto), //example -24.4324
							timestamp: new Date(),
						})
						.then(() => {
							console.log("cash statement Updated =>", `cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}`);
						})
						.catch((err) => {
							console.error("Cash Error", err);
						});
				}

				/*
					PREPAYMENTS and DOWNPAYMENTS
					Remaining Amounts prePayments
					1. if there is a reaming amount Create new one and update old preppayment to partialSettled
					2. Update Old PrePayment docs without remainingAmounts

				*/
				
				//1. Handle the PrePayments

				let remainingPrePayments = this.usedModifiers.filter((usedModifier) => usedModifier.type == "prePayment" && usedModifier.remainingAmount < 0);
				// Filter out prepayments with remaining amount greater than or equal to 0
				let withoutRemainingPrePayments = this.usedModifiers.filter((usedModifier) => usedModifier.type == "prePayment" && usedModifier.remainingAmount >= 0);

				this.handlePrePayments(remainingPrePayments, withoutRemainingPrePayments);

				//2. Handle the DownPayments
				let remainingDownPayments = this.usedModifiers.filter((usedModifier) => usedModifier.type == "downPayment" && usedModifier.remainingAmount < 0);
				// Filter out downPayments with remaining amount greater than or equal to 0
				let withoutRemainingDownPayments = this.usedModifiers.filter((usedModifier) => usedModifier.type == "downPayment" && usedModifier.remainingAmount >= 0);

				this.handlePrePayments(remainingDownPayments, withoutRemainingDownPayments);

				/* UPDATE RETURNED ITEMS
				Adding the historyDocId to a set, then it is filtering the selectedItems array to only include the
				selectedItems that have a historyDocId. It then loops through the returnedItems array and adds the
				historyDocId to the returnedItems array.
				*/

				

				let historyReceiptIds = [
					...new Set(
						this.selectedItems
							.filter((selectedItem) => typeof selectedItem.historyDocId != "undefined" && selectedItem.typeOfPayment === "withdraw")
							.map((selectedItem) => selectedItem.historyDocId)
					),
				];

				historyReceiptIds.forEach((historyReceiptId) => {
					let returnedItems = this.selectedItems.filter((selectedItem) => selectedItem.historyDocId === historyReceiptId);

					//TODO HIER PRODUCTEN TERUG OP STOCK ZETTEN

					console.log("returnedItems", returnedItems);

					db.collection("receiptsPaid")
						.doc(historyReceiptId)
						.set(
							{
								returnedItems: firebase.firestore.FieldValue.arrayUnion(...returnedItems),
							},
							{ merge: true }
						)
						.then("Old Receipt Updated with returned Item =>", historyReceiptId)
						.catch((err) => {
							console.error("Error updating History Paid Receipt =>", err);
						});
				});

				console.log("PaymentTable:", paymentTableObj);

			



				this.paymentTable = paymentTableObj; //For Receipt

				let branchIdViaStore = this.$store.state.activeBranch.id;

				

				/**
				 * Function to update the stock of products.
				 * This function performs the following operations:
				 * 1. Filters the items to get all the products with useStock set to true.
				 * 2. Reduces or adds the stock by the same id and counts the quantity based on the operation type.
				 * 3. Updates the stock in the database.
				 *
				 * @param {Array} items - Array of selectedItems
				 */
				function updateStock(items) {

					// Filter the items to get all the products with useStock set to true.
					const productsStock = items.filter((item) => item.type === "product" && item.useStock === true);

					// Reduce or add stock by the same id and count the quantity.
					// Start with a countQuantity of 1.
					let countQuantity = 1;
					const productsStockUpdated = productsStock.reduce((acc, item) => {
						const found = acc.find((accItem) => accItem.id === item.id);

						// If the item is found, increment the countQuantity.
						if (found) {
							++countQuantity
						} else {
							// If the item is not found, push it to the accumulator with its countQuantity.
							acc.push({ id: item.id, quantity: countQuantity, typeOfPayment: item.typeOfPayment });
						}
						return acc;
					}, []);

					// Update the updated Stock in Firebase by active Branch.
					//productStockUpdated - Array of objects with id and quantity and typeOfPayment
					productsStockUpdated.forEach((updatedStock) => {

						console.log("Updated Stock:", updatedStock);

						db.collection("products")
							.doc(updatedStock.id)
							.get()
							.then((doc) => {
								if (doc.exists) {
									let stockObject = doc.data().stock;
									console.log("Initial stock object:", stockObject);

									// Calculate the new stock value. It can't be lower than 0, stock can't be negative.
									let newStock;
									if (updatedStock.typeOfPayment === 'deposit') {
										newStock = stockObject[branchIdViaStore].stockValue - updatedStock.quantity;
										console.log("New stock after deposit:", newStock);
										if (newStock < 0) {
											newStock = 0;
											console.log("New stock can't be negative, set to 0");
										}
									} else if (updatedStock.typeOfPayment === 'withdraw') {
										newStock = stockObject[branchIdViaStore].stockValue + updatedStock.quantity;
										console.log("New stock after withdraw:", newStock);
									}
									stockObject[branchIdViaStore].stockValue = newStock;
									console.log("Updated stock object:", stockObject);

									// Update the stock in the database with the whole object.
									db.collection("products")
										.doc(updatedStock.id)
										.set({
											stock: stockObject,
										}, { merge: true })
										.then(() => {
											console.log("Product Stock Updated");
										})
										.catch((err) => {
											console.error("Error updating product stock", err);
										});
								}

							})
							.catch((err) => {
								console.error("Error getting document", err);
							});

					});
				}
				
				// Update Input Draft
				if (this.selectedReceiptId !== "") {		

					console.log("Update Draft", this.selectedItems);
					console.log("paymentTableObj", paymentTableObj)

					
					db.collection("receiptsPaid")
						.add({
							postToTable: "thrive-pos-v1", //COULD CHANGE FOR DEV
							companyId: this.$store.state.activeCompany.id,
							clientId: this.clientId ? this.clientId : "anonymous",
							branchId: this.$store.state.activeBranch.id,
							selectedItems: this.selectedItems,
							usedModifiers: this.usedModifiers,
							deletedModifiers: this.deletedModifiers,
							returnedItems: new Array(),
							paymentTableObj: paymentTableObj,
							internalUse: this.internalUseFunc(this.selectedItems),
							register: this.selectedCashRegister,
							created: new Date(),
							visit: moment().format("YYYY-MM-DD"),
						})
						.then((docRef) => {
							console.log("Payment to Database with ID => ", docRef.id);
							this.tmpReceiptData.receiptDocId = docRef.id

							if(this.clientId){ // Add lastPayment Date to client
								db.collection("clients")
								.doc(this.clientId)
								.set({
									lastPayment: new Date(),
								},{ merge: true })
								.then(() => {
									console.log("Client Updated with Last Payment");
								})
								.catch((error) => {
									console.error("Error updating document Client : ", error);
								});
							}

							//Reduce Product Stock 
							updateStock(this.selectedItems);
							
							//Update Memberships usage
							this.validForUseMemberships.forEach((membership) => { // Write Usage if Membership
								if (membership.override === false && membership.toggle === true) {
									let usageObj = { date: moment().format("YYYY-MM-DD"), override: false, receiptId: docRef.id };
									addUsageToMembership(usageObj);
								} else if (membership.override === true && membership.toggle === true) {
									let usageObj = { date: moment().format("YYYY-MM-DD"), override: true, receiptId: docRef.id };
									addUsageToMembership(usageObj);
								}

								function addUsageToMembership(usageObj) {
									console.log("ADD MEMBERSHIP USAGE");
									db.collection("memberships")
										.doc(membership.id)
										.set({ usage: firebase.firestore.FieldValue.arrayUnion(usageObj) }, { merge: true })
										.catch((err) => {
											console.error("Error Updating Membership Usage =>", err);
										});
								}
							});

							//Write Number Limit / Usage Modifier

							//Add number of use by usedModifiers to Database

							let arrayOfUsedModifiers = new Array();

							this.usedModifiers.forEach((usedModifier) => {
								//max used in total Or maxClient use per client
								arrayOfUsedModifiers.push({
									id: usedModifier.id,
									type: usedModifier.type,
									date: moment().format("YYYY-MM-DD"),
									byUser: this.userId,
								});
							});

							// Update selectedClientData with usedModifier before set.
							// console.log("ARRAy of USED", arrayOfUsedModifiers)
							if (this.selectedClientData.usedModifiers) {
								arrayOfUsedModifiers = [...this.selectedClientData.usedModifiers, ...arrayOfUsedModifiers];
							}

							// console.log("ARRAy of USED 2", arrayOfUsedModifiers)

							if (arrayOfUsedModifiers.length !== 0) {
								// Update / set Clients by adding usedModfiers
								db.collection("clients")
									.doc(this.clientId)
									.set(
										{
											usedModifiers: arrayOfUsedModifiers,
										},
										{ merge: true }
									)
									.catch((err) => {
										console.error("Error updating client card", err);
									});
							}

							// Checking if the usedModifiers array contains any modifiers with the modifierGroup
							// "loyaltyModifier". If it does, it will filter the usedModifiers array and sum up the
							// loyaltyPointsNeeded field. It will then call the reCalculateLoyalty function and pass in the
							// clientLoyaltyDocs array, the activeBranch and the loyaltyPointsNeeded. The reCalculateLoyalty
							// function will then return an array of recalculated loyaltyDocs. The recalculated loyaltyDocs
							// will then be uploaded to firebase.
							const loyaltyFound = this.usedModifiers.some((usedModifier) => {
								if (usedModifier.modifierGroup === "loyaltyModifier") {
									return true;
								} else {
									return false;
								}
							});

							if (loyaltyFound === true) {
								//filter only the usedModifiers with modifiergGroup "loyaltyModifer" and sum up the field lotaltyPointsNeeded
								let loyaltyPointsNeeded = 0;
								this.usedModifiers.forEach((usedModifier) => {
									if (usedModifier.modifierGroup === "loyaltyModifier") {
										loyaltyPointsNeeded += usedModifier.loyaltyPointsNeeded;
									}
								});

								let recalucatedLoyaltyDocs = reCalculateLoyalty(this.clientLoyaltyDocs, this.$store.state.activeBranch.id, loyaltyPointsNeeded);

								//Re-upload and the recalucatedLoyaltyDocs to firebase
								recalucatedLoyaltyDocs.forEach((loyaltyDoc) => {
									db.collection("loyaltyPoints")
										.doc(loyaltyDoc.id)
										.set(loyaltyDoc)
										.then(() => {
											console.log("Loyalty Document Updated =>", loyaltyDoc.id);
										})
										.catch((error) => {
											console.error("Error updating loyalty document =>", error);
										});
								});
							}

							new Promise((resolve) => {
								let getReceiptDraft = this.localReceiptDrafts.find((draft) => draft.id == this.selectedReceiptId);
								if (getReceiptDraft.bookingSummaryIds) {
									let amountBookingSummaries = getReceiptDraft.bookingSummaryIds.length;
									let countSummary = 0;
									getReceiptDraft.bookingSummaryIds.forEach((summaryId) => {
										db.collection("bookingsummary")
											.doc(summaryId)
											.set({ paid: true }, { merge: true })
											.then(() => {
												countSummary++;
												console.log("Booking Summary updated", summaryId);
												if (countSummary === amountBookingSummaries) {
													resolve();
												}
											})
											.catch((error) => {
												console.error("Booking Summary update Error =>", error);
											});
									});
								} else {
									// No Booking Summaries
									resolve();
								}
							}).then(() => {
								// Delete Old receipt from local Storage
								this.localReceiptDrafts = this.localReceiptDrafts.filter((localReceipt) => localReceipt.id !== this.selectedReceiptId);

								/**
								 * Print/Mail Receipt Dialog
								 * receiptToClientDialog, chose an option and then rollOutReceipt()
								 */
								vm.receiptToClientDialog();

								db.collection("receiptsDraft")
									.doc(this.selectedReceiptId)
									.delete()
									.then(() => {
										console.log("Receipt Deleted from receipts Draft collection =>", this.selectedReceiptId);

										//Clean up
										this.selectedReceiptId = "";
										this.selectedItems = new Array();
										this.byResource = null;
										this.componentKey++;
										this.tempReceiptKey += 1;
										this.closePayDialog();
										this.feedbackPayDialog = {
											feedback: null,
											code: null,
										};
									})
									.catch((err) => {
										console.error("Error deleting receipts Draft  => ", this.selectedReceiptId, err);
									});
							});
						})
						.catch((error) => {
							console.error("Error Add Payment document Receipts Draft : ", error);
						});
				} //End
			}
		},

		//Select Product or Treatments
		selector(indexed) {
			/* checkBoolean check if is already true, then false.
			 * When its false create everything false by loop then activate a new true
			 */

			//Unselect not selectedItems for V-data-list
			this.selectedItems.map((selectedItem, mapIndex) => {
				if (mapIndex !== indexed) {
					selectedItem.selected = false;
				} else {
					selectedItem.selected = true;
				}
				return selectedItem;
			});

			if (this.selectedItems[indexed]) {
				// this.selectedItems[indexed].selected = true;
				if (this.selectedItems[indexed].type == "treatment") {
					this.selectedType = "treatment";
					this.indexer = indexed;
				}
				if (this.selectedItems[indexed].type == "product") {
					this.selectedType = "product";
					this.indexer = indexed;
				}
				if (this.selectedItems[indexed].type == "customRule") {
					this.selectedType = "customRule";
					this.indexer = indexed;
				}
			}
			this.componentKey += 1;
		},

		getResourceName(resourceDocId) {
			// console.log("ResourceDocId", resourceDocId);
			if (resourceDocId !== null) {
				return this.resources.find((resource) => resource.id == resourceDocId).name;
			} else {
				return "Alle Medewerkers";
			}
		},

		/**
		 * How it works: The function will loop through the selectedItems array and check for internalUse boolean true.
		 * If there is a internalUse boolean true it will filter the selectedItems array and return a new array with only
		 * the items that have internalUse boolean true. It will then loop through the new array and create a new array of
		 * objects. The new array of objects will contain the id, number and totalCostPrice of the items with internalUse
		 * boolean true. It will then return the new array of objects.
		 * 
		 * @param {*} items | selectedItems as array
 		 */
		internalUseFunc(items) {
			//Filter out internalUse true
			const internalUseItems = items.filter((item) => item.internalUse === true);

			//create array of objects but add them togheter.
			// internalUseProducts: [
			// 	{id: products x by same id added},
			// 	{number: amount by same id added},
			// 	{totalCostPrice: totalCostPrice of all products x by same id added}
			// ]
			let countInternalItem = 1;
			let internalUseProducts = internalUseItems.reduce((acc, item) => {

				let found = acc.find((accItem) => accItem.id === item.id);
				if (found) {

					++countInternalItem;
					found.number = countInternalItem;
					found.totalCostPrice += item.costPrice;
				} else {
					acc.push({
						id: item.id,
						number: countInternalItem,
						totalCostPrice: cashConv(item.costPrice, "EUR", 4),
					});
					countInternalItem = 1;

				}
				return acc;
			}, []);

			return internalUseProducts;

		},

		/**
		 * This function will calculate the total of the receiptDraftItems. It will loop through the receiptDraftItems
		 * and calculate the sumUpNetto, sumUpVAT, sumUptotalBrutoWithoutDiscount and sumUpTotalDiscountVAT. It will then
		 * return the sumUpVAT if it is not null, otherwise it will return the sumUptotalBrutoWithoutDiscount.
		 *
		 * @param {*} receiptDraftItems | Array of receiptDraftItems
		 * @param {*} historyBoolean | Boolean
		 * @returns 
		 */
		getTotal(receiptDraftItems, historyBoolean) {
			//@Param : receiptDraftProducTreats from headerReceiptDraft

			//Calculation for Header Button

			//History Boolean is true when the receiptDraftItems are from the history Receipts

			if (receiptDraftItems && !historyBoolean) {
				let sumUpNetto = null; //Netto Total
				let sumUpVAT = null; //Bruto Total
				let sumUptotalBrutoWithoutDiscount = null; //Total price orignial without discounts
				let sumUpTotalDiscountVAT = null; //Total discount / promotion from modifier
				receiptDraftItems.forEach((selectedItem, i) => {
					
					if (selectedItem.internalUse) {
            		    return;  // Skip the current iteration of the loop
            		}

					cashConv(
						selectedItem.typeOfPayment === "deposit" ? (sumUpNetto += selectedItem.newPriceTotalNetto) : (sumUpNetto -= selectedItem.newPriceTotalNetto),
						"EUR",
						4
					);
					cashConv(selectedItem.typeOfPayment === "deposit" ? (sumUpVAT += selectedItem.newPriceTotal) : (sumUpVAT -= selectedItem.newPriceTotal), "EUR", 4);
					cashConv(
						selectedItem.typeOfPayment === "deposit"
							? (sumUptotalBrutoWithoutDiscount += selectedItem.priceBruto)
							: (sumUptotalBrutoWithoutDiscount -= selectedItem.priceBruto),
						"EUR",
						4
					);
					cashConv(
						selectedItem.typeOfPayment === "deposit"
							? (sumUpTotalDiscountVAT += selectedItem.calculatedDiscountVAT)
							: (sumUpTotalDiscountVAT -= selectedItem.calculatedDiscountVAT),
						"EUR",
						4
					);
				});
				//Return function for Header

				if (receiptDraftItems.length === 0) {
					return this.$t('payment.newReceipt', this.$store.state.locale);
				} else {
					return sumUpVAT !== null ? "€" + cashConv(sumUpVAT, "EUR", 2).toFixed(2) : "€" + cashConv(sumUptotalBrutoWithoutDiscount, "EUR", 2);
				}
			} else if (receiptDraftItems && historyBoolean) {
				//Collect different VATS
				let percentagesVat = [...new Set(receiptDraftItems.map((draftItem) => draftItem.percentageVAT))];

				// //Calculation for Totals to DB
				let sumUpNetto = null; //Netto Totaal Bedrag
				let sumUpVAT = null; //Bruto sumUp
				let sumUptotalBrutoWithoutDiscount = null; //Total price orignial without discounts
				let sumUpTotalDiscountVAT = null; //Total discount / promotion from modifier
				this.totalVats = new Array();

				receiptDraftItems.forEach((selectedItem) => {
					if (selectedItem.internalUse) {
            		    return;  // Skip the current iteration of the loop
            		}

					cashConv(
						selectedItem.typeOfPayment === "deposit" ? (sumUpNetto += selectedItem.newPriceTotalNetto) : (sumUpNetto -= selectedItem.newPriceTotalNetto),
						"EUR",
						4
					);
					cashConv(selectedItem.typeOfPayment === "deposit" ? (sumUpVAT += selectedItem.newPriceTotal) : (sumUpVAT -= selectedItem.newPriceTotal), "EUR", 4);
					cashConv(
						selectedItem.typeOfPayment === "deposit"
							? (sumUptotalBrutoWithoutDiscount += selectedItem.priceBruto)
							: (sumUptotalBrutoWithoutDiscount -= selectedItem.priceBruto),
						"EUR",
						4
					);
					cashConv(
						selectedItem.typeOfPayment === "deposit"
							? (sumUpTotalDiscountVAT += selectedItem.calculatedDiscountVAT)
							: (sumUpTotalDiscountVAT -= selectedItem.calculatedDiscountVAT),
						"EUR",
						4
					);
				});

				this.totalNetto = cashConv(sumUpNetto, "EUR", 2); //NETTO
				this.totalDiscountVAT = cashConv(sumUpTotalDiscountVAT, "EUR", 2); //Total discount / promotion from modifier
				this.totalBrutoWithoutDiscount = cashConv(sumUptotalBrutoWithoutDiscount, "EUR", 2); //BRUTO Total price orignial without discounts
				this.totalBruto = cashConv(sumUpVAT, "EUR", 2); //BRUTO Total
				this.totalBrutoTwoDigits = cashConv(sumUpVAT, "EUR", 2); //BRUTO Total
				this.totalVAT = cashConv(this.totalBruto - this.totalNetto, "EUR", 2); //VAT only

				

				//VAT Calculations
				percentagesVat.forEach((percentageVat) => {
					let sumUpVatPerItem = 0;

					this.selectedItems.filter((draftItem) => {

						if (draftItem.internalUse) {
         				   return false;  // Skip the current item
        				}

						if (draftItem.percentageVAT == percentageVat) {
							if (draftItem.typeOfPayment === "deposit") {
								sumUpVatPerItem += cashConv(draftItem.newPriceTotal - draftItem.newPriceTotalNetto, "EUR", 4);
							} else {
								sumUpVatPerItem -= cashConv(draftItem.newPriceTotal - draftItem.newPriceTotalNetto, "EUR", 4);
							}
						}
					});

					this.totalVats.push({
						percentageVat: percentageVat,
						totalVat: cashConv(sumUpVatPerItem, "EUR", 4),
					});
				});
			
			} else {

				
				//Collect different VATS
				let percentagesVat = [...new Set(this.selectedItems.map((draftItem) => draftItem.percentageVAT))];

				// //Calculation for Totals to DB
				let sumUpNetto = null; //Netto Totaal Bedrag
				let sumUpVAT = null; //Bruto sumUp
				let sumUptotalBrutoWithoutDiscount = null; //Total price orignial without discounts
				let sumUpTotalDiscountVAT = null; //Total discount / promotion from modifier
				this.totalVats = new Array();

				this.selectedItems.forEach((selectedItem) => {
					if (selectedItem.internalUse) {
            		    return;  // Skip the current iteration of the loop
            		}

					cashConv(
						selectedItem.typeOfPayment === "deposit" ? (sumUpNetto += selectedItem.newPriceTotalNetto) : (sumUpNetto -= selectedItem.newPriceTotalNetto),
						"EUR",
						4
					);
					cashConv(selectedItem.typeOfPayment === "deposit" ? (sumUpVAT += selectedItem.newPriceTotal) : (sumUpVAT -= selectedItem.newPriceTotal), "EUR", 4);
					cashConv(
						selectedItem.typeOfPayment === "deposit"
							? (sumUptotalBrutoWithoutDiscount += selectedItem.priceBruto)
							: (sumUptotalBrutoWithoutDiscount -= selectedItem.priceBruto),
						"EUR",
						4
					);
					cashConv(
						selectedItem.typeOfPayment === "deposit"
							? (sumUpTotalDiscountVAT += selectedItem.calculatedDiscountVAT)
							: (sumUpTotalDiscountVAT -= selectedItem.calculatedDiscountVAT),
						"EUR",
						4
					);
				});

				this.totalNetto = cashConv(sumUpNetto, "EUR", 4); //NETTO
				this.totalDiscountVAT = cashConv(sumUpTotalDiscountVAT, "EUR", 4); //Total discount / promotion from modifier
				this.totalBrutoWithoutDiscount = cashConv(sumUptotalBrutoWithoutDiscount, "EUR", 2); //BRUTO Total price orignial without discounts
				this.totalBruto = cashConv(sumUpVAT, "EUR", 4); //BRUTO Total
				this.totalBrutoTwoDigits = cashConv(sumUpVAT, "EUR", 2); //BRUTO Total
				this.totalVAT = cashConv(this.totalBruto - this.totalNetto, "EUR", 4); //VAT only

				

				//VAT Calculations
				percentagesVat.forEach((percentageVat) => {
					let sumUpVatPerItem = 0;

					this.selectedItems.filter((draftItem) => {

						if (draftItem.internalUse) {
         				   return false;  // Skip the current item
        				}

						if (draftItem.percentageVAT == percentageVat) {
							if (draftItem.typeOfPayment === "deposit") {
								sumUpVatPerItem += cashConv(draftItem.newPriceTotal - draftItem.newPriceTotalNetto, "EUR", 4);
							} else {
								sumUpVatPerItem -= cashConv(draftItem.newPriceTotal - draftItem.newPriceTotalNetto, "EUR", 4);
							}
						}
					});

					this.totalVats.push({
						percentageVat: percentageVat,
						totalVat: cashConv(sumUpVatPerItem, "EUR", 4),
					});
				});
			}
		},

		/**
		 *
		 * @param {array} items - selectedItems
		 * @param {boolean} manual - true or false for adding manual discount.
		 * @param {object} modifier - object of the modifier
		 * @param {map} alreadyUsed - map of already used items
		 * @param {map} deleted - map of already deleted items
		 * @param {boolean} debug - true or false
		 */

		calculateModule(items, manual, modifier, allModifiers, alreadyUsed, deleted, debug) {

		
			let vm = this;
			this.loadingDataTable = true;
			//PART A Generate new data manipulated with old data

			const cleanSelectedItems = items.map((item) => {
				// console.log("Items", item.type, item)
				// Return Product / treatment
				if (item.returned && item.typeOfPayment === "withdraw") {
					return item;
				} else {

					//Calculate Clone
					if (item.type === "product") {
						let obj = deepCopy(this.productsByBranchClone.find((product) => product.id === item.id));
						//Respect / keep the following fields intact
						if (obj) {
							if (item.modifiedId) obj.modifiedId = item.modifiedId;
							if (item.userAdded) obj.userAdded = item.userAdded;
							if (item.resourceId) obj.resourceId = item.resourceId;
							if (item.typeOfPayment) obj.typeOfPayment = item.typeOfPayment;
							if (item.returned) obj.returned = item.returned;
							if (item.internalUse) obj.internalUse = item.internalUse;
							
							return obj;
						}

						

					} else if (item.type === "treatment") {
						let obj = deepCopy(this.treatmentsByBranchClone.find((treatment) => treatment.id === item.id));

						if (obj) {
							if (item.modifiedId) obj.modifiedId = item.modifiedId;
							if (item.userAdded) obj.userAdded = item.userAdded;
							if (item.typeOfPayment) obj.typeOfPayment = item.typeOfPayment;
							if (item.returned) obj.returned = item.returned;
							if (item.bookingSummaryId) obj.bookingSummaryId = item.bookingSummaryId;
							if (item.uniqIdStamp) obj.uniqIdStamp = item.uniqIdStamp;
							if (item.internalUse) obj.internalUse = item.internalUse;
						

							obj.subTreatments = item.subTreatments;
							return obj;
						}
					} else if (item.type === "customRule") {
						// No Deepcopies needed because its custom POS rule
						let obj = item;
						return obj;
					}
				}
			});

			// add addedVia field to modifier by matching alreadyUsed modifiers
			alreadyUsed.forEach((usedModifier) => {
				modifier.forEach((modifier) => {
					if (usedModifier.id === modifier.id) {
						modifier.addedVia = usedModifier.addedVia; 
					}
				});
			});

			//PART B Consistent calculation returned with new Data AND CheckUsage
			//Combine 2 arrays //New Custom Modifier with Created Modifiers

			this.combinedModifiers = this.modifiers.concat(this.customModifiers);
			// console.log("combinedModifiers", this.combinedModifiers);
			// console.log("cleanSelectedItems", cleanSelectedItems);

			if (this.clientId.length !== 0) {
				let data;
				try {
					data = scanDiscounts(
						cleanSelectedItems,
						manual,
						modifier,
						this.combinedModifiers,
						alreadyUsed,
						deleted,
						true,
						this.selectedClientData,
						this.sortDiscountOrder
					);
				} catch (error) {
					console.error("Error scanning discounts:", error);
					this.feedbackArray.push({ type: "error", message: "Failed to scan discounts." });
					data = { selectedItems: [], usedModifiers: [], deletedModifiers: [], error: [], advise: [] };
				}

				// Add new Data
				this.selectedItems = data.selectedItems;
				this.usedModifiers = data.usedModifiers;
				this.deletedModifiers = data.deletedModifiers;

				this.feedbackArray = this.feedbackArray.filter(obj => obj.hasOwnProperty('immutable'));
				data.error.forEach((error) => this.feedbackInterpreter(error, "error"));
				console.log("Errors", data.error);
				data.advise.forEach((feedback) => this.feedbackInterpreter(feedback, "feedback"));
				console.log("Feedback", data.advise);
			}

			this.componentKey += 1;
			this.getTotal();

			console.log("%c Calculation done! ", "background: #222; color: #bada55");
			//Store to DB
			if (this.selectedReceiptId !== "") {
		

				function updateClientId() {
					//When there is no Client Data linked
					if (_.isEmpty(vm.selectedClientData) === false) {
						
						// console.log("Client Data linked");
					 
						

						return {

							clientId: vm.clientId,
							clientname: vm.selectedClientData.name + " " + vm.selectedClientData.surname,
							selectedItems: vm.selectedItems,
							usedModifiers: vm.usedModifiers,
							validForUseMemberships: vm.validForUseMemberships,

							deletedModifiers: vm.deletedModifiers,
							modified: new Date(),
							customModifiers: vm.customModifiers,
						};
					} else {
						// console.log("No Client Data linked");
						return {
							selectedItems: vm.selectedItems,
							usedModifiers: vm.usedModifiers,
							validForUseMemberships: vm.validForUseMemberships,

							deletedModifiers: vm.deletedModifiers,
							modified: new Date(),
							customModifiers: vm.customModifiers,
						};
					}
				}

				// const clientUpdateData = JSON.parse(JSON.stringify(updateClientId())); //Clean up the observers
				const clientUpdateData = updateClientId();
				 console.log("ClientUpdateData", clientUpdateData);


				db.collection("receiptsDraft")
					.doc(this.selectedReceiptId)
					.set(clientUpdateData, {merge: true})
					.then(() => {
						console.log("%c Receipt Computed and added to DB with ID => ", "background: blue; color: white", this.selectedReceiptId);
						// this.localReceiptDrafts = this.localReceiptDrafts.sort(function(a, b) {
						// // Sortby  modified.seconds
						// // If the first item has a higher number, move it down
						// // If the first item has a lower number, move it up
						// if (a.modified.seconds > b.modified.seconds) return -1;
						// if (a.modified.seconds < b.modified.seconds) return 1;
						// });

						this.tempReceiptKey += 1;
						this.loadingDataTable = false;
						this.getScrollInformation();
					})
					.catch((error) => {
						console.error("Error updating document Receipts Draft : ", error);
					});
			}
		},

	

		//Check if modifiers with modifierGroup loyaltyModifier could be activated
		checkUsageLoyalty(pointsNeeded) {
			//Sum up available points by docs
			let totalUserPoints = this.clientLoyaltyDocs.reduce((acc, doc) => {
				return acc + doc.points; //?
			}, 0); //?

			// console.log("totalUserPoints", totalUserPoints);

			//Sum up used points via usedModifiers
			let usedPoints = 0;
			this.usedModifiers.forEach((modifier) => {
				if (modifier.modifierGroup === "loyaltyModifier") {
					usedPoints += modifier.loyaltyPointsNeeded;
				}
			});

			// console.log("usedPoints", usedPoints);

			// console.log("calculate Points", totalUserPoints - usedPoints);

			/**
			 * If the user has enough points to activate the loyaltyModifier, return true
			 */
			if (totalUserPoints - usedPoints >= pointsNeeded) {
				return true;
			} else {
				return false;
			}
		},

		/**
		 * interpreter for feedback or advisement
		 * @param {object} error - Could be an error or advise
		 * @param {string} type - error or Feedback
		 */
		feedbackInterpreter(data, type) {
			console.log("FeedbackArray", data);

			this.feedbackArray = new Array();
			let getReason = null;

			if (type === "feedback") {
				switch (data.code) {
					case 405: //Discount buyXX and buyXY Almost active
						return this.feedbackArray.push({
							modifierName: data.modifierName,
							explaination: data.reason,
							dismiss: true,
							type: "info",
							icon: "mdi-emoticon-wink",
							color: "primay",
						});

					case 406: //Discount buyXX and buyXY suggestion
						return this.feedbackArray.push({
							modifierName: data.modifierName,
							explaination: data.reason,
							dismiss: true,
							type: "info",
							icon: "mdi-school",
							color: "primay",
						});

					case 407: //Discount already active
						return this.feedbackArray.push({
							modifierName: data.modifierName,
							explaination: data.reason,
							dismiss: true,
							type: "info",
							icon: "mdi-school",
							color: "green",
						});

					case 501: //peak message with discounts
						getReason = data.reason.find((reason) => reason.language == this.userLanguage.toLowerCase());
						return this.feedbackArray.push({
							modifierName: data.modifierName,
							explaination: getReason ? getReason.message : data.reason[0].message,
							dismiss: true,
							type: "info",
							icon: "mdi-school",
							color: "green",
						});

					case 502: //peak message with discounts
						getReason = data.reason.find((reason) => reason.language == this.userLanguage.toLowerCase());
						return this.feedbackArray.push({
							modifierName: data.modifierName,
							explaination: getReason ? getReason.message : data.reason[0].message,
							dismiss: true,
							type: "info",
							icon: "mdi-school",
							color: "green",
						});

					case 600: //peak message with discounts
						return this.feedbackArray.push({
							modifierName: data.modifierName,
							explaination: data.reason,
							dismiss: true,
							type: "info",
							icon: "mdi-school",
							color: "primay",
						});
					default:
						break;
				}
			}

			if (type === "error") {
				switch (data.code) {
					// case 304: //Discount already active

					//   return this.feedbackArray.push({modifierName: error.modifierName, explaination: "is al reeds toegevoegd!", dismiss:true, type: "info"})

					//   break;

					case 406: //Discount already active
						return this.feedbackArray.push({
							modifierName: data.modifierName,
							explaination: "is al reeds toegevoegd!",
							dismiss: true,
							type: "info",
						});

						break;

					default:
						break;
				}
			}
		},

		checkDeleted(deleted) {
			if (deleted) {
				return "onderdeel verwijderd";
			}
		},

		cashConvFilter(amount) {
			//For showing the ZERO's
			return cashConv(amount, "EUR", 2);
		},

		posDeleteModifier(id) {
			//Delete from Used Modifiers
			//TODO MANIPULATE DELETED
			console.log("posDeleteModifier", id);

			//Custom Mod not deleted to this.deletedModifiers
			if (this.customModifiers.some((customMod) => customMod.id === id)) {
				this.usedModifiers = this.usedModifiers.filter((modifier) => modifier.id !== id);
				this.customModifiers = this.customModifiers.filter((customModifier) => customModifier.id !== id);
				this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
			} else {
				//Add to deletedModifiers
				let getModifierToDelete = this.usedModifiers.find((modifier) => modifier.id === id);

				// //Delete related by Memberships
				// if(getModifierToDelete.type === "membership") {

				//   let foundRelatedModifiers = [...getModifierToDelete.separatedDiscount.discountsInsideSubscriptionDays, getModifierToDelete.separatedDiscount.discountsOutsideSubscriptionDays]
				//   console.log("found", foundRelatedModifiers)

				//   this.usedModifiers = this.usedModifiers.filter(
				//   (modifier) => modifier.id == foundRelatedModifiers.includes(modifier.id));

				// }

				if (getModifierToDelete.automatic === true) {
					console.log("getModifierToDelete", id, getModifierToDelete);

					if (getModifierToDelete.type == "product" || getModifierToDelete.type == "treatment" || getModifierToDelete == "all") {
						this.deletedModifiers.push(getModifierToDelete);
					}

					this.usedModifiers = this.usedModifiers.filter((modifier) => modifier.id !== id);

					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
				} else {
					//not automatic so no push to deletedModifiers

					this.usedModifiers = this.usedModifiers.filter((modifier) => modifier.id !== id);

					this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
				}
			}
		},

		/**
		 * Toggles the membership status and manages related discounts.
		 * 
		 * This function handles the following operations:
		 * 1. Toggles the membership on/off.
		 * 2. Applies or removes the membership discount.
		 * 3. Manages related extra discounts.
		 * 4. Applies discounts based on peak/off-peak status.
		 * 5. Recalculates the total after all modifications.
		 * 
		 * @param {string} docId - The unique identifier of the membership to toggle.
		 */
		posToggleMembership(docId) {
			let getRelatedMembershipModifier = this.validForUseMemberships.find((validMembership) => validMembership.id === docId);
			console.log("Related Membership", getRelatedMembershipModifier.toggle);

			if (getRelatedMembershipModifier.toggle === true) {
		
				let relatedDiscounts = new Array();

				// Add separatedDiscounts
				if (getRelatedMembershipModifier.separatedDiscount.active) {
					console.log("getRelatedMembershipModifier.separatedDiscount", getRelatedMembershipModifier.separatedDiscount);

					

					if (getRelatedMembershipModifier.peakStatus.peak === "peak") {
						relatedDiscounts = this.modifiers.filter(
							(usedModifier) => getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays.includes(usedModifier.id)
						);
					}

					if (getRelatedMembershipModifier.peakStatus.peak === "offPeak") {
						if (getRelatedMembershipModifier.separatedDiscount.splitDiscounts === true) {
							
							relatedDiscounts = this.modifiers.filter(
								(usedModifier) => getRelatedMembershipModifier.separatedDiscount.discountsOutsideSubscriptionDays.includes(usedModifier.id)
							);

						} else {
							relatedDiscounts = this.modifiers.filter(
								(usedModifier) => getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays.includes(usedModifier.id)
							);
						}
					}
				}

				//Add addedVia to related discounts
				relatedDiscounts.forEach((discount) => {
					discount.addedVia = "membership";
				});

				//Add related discounts to usedModifiers
				this.usedModifiers = [...relatedDiscounts, ...this.usedModifiers];

				
			} else {
				//Toggle off
				//Remove all related modifiers from usedModifiers
				console.log("getRelatedMembershipModifier", getRelatedMembershipModifier);

				//Remove separatedDiscounts
				if (getRelatedMembershipModifier.separatedDiscount.active) {
					console.log("getRelatedMembershipModifier.separatedDiscount", getRelatedMembershipModifier.separatedDiscount);

					if (getRelatedMembershipModifier.peakStatus.peak === "peak") {
						this.usedModifiers = this.usedModifiers.filter(
							(usedModifier) => !getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays.includes(usedModifier.id)
						);
					}

					if (getRelatedMembershipModifier.peakStatus.peak === "offPeak") {
						if (getRelatedMembershipModifier.separatedDiscount.splitDiscounts === true) {
							
							this.usedModifiers = this.usedModifiers.filter(
								(usedModifier) => !getRelatedMembershipModifier.separatedDiscount.discountsOutsideSubscriptionDays.includes(usedModifier.id)
							);

							console.log("usedModifiers", this.usedModifiers);

						} else {
							this.usedModifiers = this.usedModifiers.filter(
								(usedModifier) => !getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays.includes(usedModifier.id)
							);
						}
					}
				}
			}

			// Recalculate
			this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);


		},

		posManualAddDiscount(docId) {
			let getDiscount = this.modifiers.find((modifier) => modifier.id === docId);

			
			getDiscount.addedVia = "manualDiscountMenu";

			this.usedModifiers.push(getDiscount);

			this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
		},

		// LEGACY
		// posUseMembership(docId) {
		// 	let getMembership = this.validForUseMemberships.find((validMembership) => validMembership.id === docId);

		// 	//Add rules for POS module

		// 	// console.log("getMembership", getMembership)

		// 	//  let getDiscount = this.modifiers.find(modi => modi.id === "Pd9qQo4y3HAPfKrtIxgw")
		// 	//  getDiscount.byMembership = true;

		// 	//  console.log("Get Discount", getDiscount)

		// 	//LOGICA Daldagen

		// 	getMembership.toggle = true;

		// 	this.usedModifiers.push(getMembership);

		// 	// this.calculateModule(
		// 	//     this.selectedItems,
		// 	//     false,
		// 	//     this.modifiers,
		// 	//     this.modifiers,
		// 	//     this.usedModifiers,
		// 	//     this.deletedModifiers,
		// 	//     true
		// 	//   );

		// 	//  this.usedModifiers.push(getDiscount);
		// 	// console.log("getDiscount", getDiscount)

		// 	this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
		// },

		overrideMembership(docId) {


			let getRelatedMembershipModifier = this.validForUseMemberships.find((validMembership) => validMembership.id === docId);

			//Manipulate by turning set to Peak and Add a Override Boolen to update the Usage with an Override.
			console.log("overrideMembership", getRelatedMembershipModifier.peakStatus);

			//Add the Override to the Membership
			getRelatedMembershipModifier.peakStatus.peak = "peak";
			getRelatedMembershipModifier.override = true;
			getRelatedMembershipModifier.toggle = true;
			console.log("overrideMembership", getRelatedMembershipModifier.peakStatus, getRelatedMembershipModifier);

			let relatedMembershipDiscounts = new Array();

			if (getRelatedMembershipModifier.separatedDiscount.active) {
				if (getRelatedMembershipModifier.peakStatus.peak === "peak") {
						relatedMembershipDiscounts = this.modifiers.filter(
							(usedModifier) => getRelatedMembershipModifier.separatedDiscount.discountsInsideSubscriptionDays.includes(usedModifier.id)
						);
				}
			}

			// Set the peak status to peak and add override and toggle
			relatedMembershipDiscounts.forEach((discount) => {
				
				if (!discount.peakStatus) {
					discount.peakStatus = {};
				}
				discount.peakStatus.peak = "peak";
				discount.override = true;
				discount.toggle = true;
				discount.byMembership = true;
				discount.addedVia = "membershipOverride";
			});

			// Add the related discounts to the used modifiers
			this.usedModifiers = [...relatedMembershipDiscounts, ...this.usedModifiers];

			//Re push and Recalculate
			this.calculateModule(this.selectedItems, false, this.modifiers, this.modifiers, this.usedModifiers, this.deletedModifiers, true);
		},

		checkModifierUsage(id, type) {
			const used = this.usedModifiers.find((modifier) => modifier.id === id);

			switch (type) {
				case "list":
					if (used) {
						return true;
					} else {
						return false;
					}

				case "description":
					if (used) {
						return true;
					} else {
						return false;
					}
				default:
					break;
			}
		},

		checkResources() {
			/** Check if every selected item is a product and if it has a resourceId. */
			let checkResourceProduct = this.selectedItems.filter((selectedItem) => selectedItem.type === "product").every((product) => product.resourceId !== null);

			/** Check if the selected items have a resourceId for each treatment and subTreatment. */
			let checkResourceTreatment = this.selectedItems
				.filter((selectedItem) => selectedItem.type === "treatment")
				.map((treatment) => treatment.subTreatments)
				.flat()
				.map((subTreatment) => subTreatment)
				.every((subTreatment) => typeof subTreatment.resourceId != "undefined");

			// DEPOSIT PIN CASH SPLIT
			if (checkResourceProduct === true && checkResourceTreatment === true) {
				return true;
			}
			else {
				return false;
			}
		},

		checkDeposit() {
			// Deposit
			if (this.totalBruto > 0) {
				return true;
			} else {
				return false;
			}
		},

		checkFree() {
			// Deposit
			if (this.totalBruto == 0) {
				return true;
			} else {
				return false;
			}
		},

		checkWithdraw() {
			//Withdraw / Refund
			if (this.totalBruto < 0) {
				return true;
			} else {
				return false;
			}
		},

		validateBeforePay(clientId) {
			let vm = this;

			// For validation needs to be false or "memResourcesValidated" for memberships.
			if (typeof clientId === "undefined" || clientId.length === 0) return false; // Validate ClientId
			
			function validateResources() { // Check for resources and Subtreatment resources
				let product = vm.selectedItems.filter((item) => item.type == "product" || item.type == "customRule"); // Product or Custom Rule

				let treatment = vm.selectedItems.filter((item) => item.type == "treatment");

				if (product.length !== 0 && treatment.length !== 0) {
					if (product.some((item) => item.resourceId === null) === true) return false;
					if (
						treatment
							.map((subItem) => subItem.subTreatments)
							.flat()
							.some((subTreatment) => typeof subTreatment.resourceId === "undefined") === true
					)
						return false;
					return true;
				} else if (product.length !== 0) {
					if (product.some((item) => item.resourceId === null) === true) return false;
					return true;
				} else if (treatment.length !== 0) {
					if (
						treatment
							.map((subItem) => subItem.subTreatments)
							.flat()
							.some((subTreatment) => typeof subTreatment.resourceId === "undefined") === true
					)
						return false;
					return true;
				} else {
					return false;
				}
			}

			function validateMembershipResources() {
				if (vm.selectedItems.every((item) => item.usedMembershipId.length !== 0) === true) {
					if (validateResources() === true) {
						return "memResourcesValidated";
					} else {
						return true;
					}
				} else {
					return true;
				}
			}

			if (validateMembershipResources() === "memResourcesValidated") {
				//For Membership treatment / product found only
				return "memResourcesValidated";
			} else if (validateMembershipResources() !== "memResourcesValidated" && validateResources() === true && this.totalBruto > 0) {
				//No membershps or membership in combination with treatments or products
				return true;
			} else if (validateResources() === false && validateMembershipResources() === true) {
				//No treatments or Products with the right resources
				return false;
			} else if (validateResources() === true && validateMembershipResources() === true && this.totalBruto > 0) {
				//There are treatment or products and memberships.
				return true;
			} else if (this.totalBruto <= 0) {
				return true;
			}
		},

		membershipCompletionButton(selectedItems) {
			if (selectedItems.every((item) => item.usedMembershipId.length !== 0) === true) {
				// Only Membership Treatments and Products
				if (this.clientId !== "") {
					console.log("this.validateBeforePay(this.clientId)", this.validateBeforePay(this.clientId));
					if (this.validateBeforePay(this.clientId) === false) {
						return true;
					} else {
						// Resources are not Undefined
						return "memResourcesValidated";
					}
				} else {
					//Client ID available
					return true;
				}
			} else {
				// Other Treatments or products found
				return true;
			}
		},

		correctCashUpNumbers(type) {

			console.log("correctCashUpNumbers: ", type)
      if(!type || type != 'first'){
        this.cashStatementStayManualChanged = true;
      }
      if (this.cashStatementStay.value > this.cashUpSumCash + this.cashStatement) {
        this.cashStatementStay.value = this.cashUpSumCash + this.cashStatement;
        this.cashStatementStay.error = true;
        this.cashStatementStay.feedback = "Het ingevoerde bedrag is te hoog, bedrag is aangepast.";
        this.cashUpDepositToBank = this.cashStatementStay.value > 0 ? this.cashUpSumCash - this.cashStatementStay.value : 0 ;
      }
      else {
        this.cashStatementStay.error = false;
        this.cashStatementStay.feedback = "";
        this.cashUpDepositToBank = this.cashStatementStay.value > 0 ? this.cashUpSumCash - this.cashStatementStay.value : 0 ;
      }
    },

		calculateCashStatement(value, moneyName) {
			if (value < 0) {
				this.cashUp[moneyName] = Number(0);
			}

			for (const key in this.cashUp) {
				if (this.cashUp[key] !== 0) {
					this.cashUpFeedback.message = null;
					this.cashUpFeedback.box = null;
				}
			}

			this.cashUpSumTotal =
				this.cashUp.oneEuroCent * cashConv(0.01, "EUR", 2) +
				this.cashUp.twoEuroCent * cashConv(0.02, "EUR", 2) +
				this.cashUp.fiveEuroCent * cashConv(0.05, "EUR", 2) +
				this.cashUp.tenEuroCent * cashConv(0.1, "EUR", 2) +
				this.cashUp.twentyEuroCent * cashConv(0.2, "EUR", 2) +
				this.cashUp.fiftyEuroCent * cashConv(0.5, "EUR", 2) +
				this.cashUp.oneEuro * cashConv(1, "EUR", 2) +
				this.cashUp.twoEuro * cashConv(2, "EUR", 2) +
				this.cashUp.fiveEuro * cashConv(5, "EUR", 2) +
				this.cashUp.tenEuro * cashConv(10, "EUR", 2) +
				this.cashUp.twentyEuro * cashConv(20, "EUR", 2) +
				this.cashUp.fiftyEuro * cashConv(50, "EUR", 2) +
				this.cashUp.hundredEuro * cashConv(100, "EUR", 2) +
				this.cashUp.twoHundredEuro * cashConv(200, "EUR", 2) +
				this.cashUp.fiveHundredEuro * cashConv(500, "EUR", 2) +
				this.cashUp.pin +
				this.cashUp.creditcard;

			//TODO onderverdelen PIN en CREDITCARD

			this.cashUpSumByCard.byPIN = this.cashUp.pin;
			this.cashUpSumByCard.byCreditcard = this.cashUp.creditcard;

			//Maximum Cash Up

			//TODO Optellen zonder pin en creditcard

			this.cashUpSumCash =
        this.cashUp.oneEuroCent * cashConv(0.01, "EUR", 2) +
        this.cashUp.twoEuroCent * cashConv(0.02, "EUR", 2) +
        this.cashUp.fiveEuroCent * cashConv(0.05, "EUR", 2) +
        this.cashUp.tenEuroCent * cashConv(0.1, "EUR", 2) +
        this.cashUp.twentyEuroCent * cashConv(0.2, "EUR", 2) +
        this.cashUp.fiftyEuroCent * cashConv(0.5, "EUR", 2) +
        this.cashUp.oneEuro * cashConv(1, "EUR", 2) +
        this.cashUp.twoEuro * cashConv(2, "EUR", 2) +
        this.cashUp.fiveEuro * cashConv(5, "EUR", 2) +
        this.cashUp.tenEuro * cashConv(10, "EUR", 2) +
        this.cashUp.twentyEuro * cashConv(20, "EUR", 2) +
        this.cashUp.fiftyEuro * cashConv(50, "EUR", 2) +
        this.cashUp.hundredEuro * cashConv(100, "EUR", 2) +
        this.cashUp.twoHundredEuro * cashConv(200, "EUR", 2) +
        this.cashUp.fiveHundredEuro * cashConv(500, "EUR", 2);

      // Hoeveel blijft er in de kassa? (Maximaal 150 euro)
			console.log("cashStatementStayManualChanged: ", this.cashStatementStayManualChanged)

      if(!this.cashStatementStayManualChanged){
				console.log("Wat lbijft erin de kassa?")
        let smallChange = cashConv(
          (this.cashUp.oneEuroCent * 0.01) +
          (this.cashUp.twoEuroCent * 0.02) +
          (this.cashUp.fiveEuroCent * 0.05) +
          (this.cashUp.tenEuroCent * 0.1) +
          (this.cashUp.twentyEuroCent * 0.2) +
          (this.cashUp.fiftyEuroCent * 0.5) +
          (this.cashUp.oneEuro * 1) +
          (this.cashUp.twoEuro * 2),"EUR", 2);

        if((this.cashUpSumCash - smallChange) > 150){
          this.cashStatementStay.value = cashConv(150 + smallChange, "EUR", 2);
          
          this.cashUpDepositToBank = cashConv(this.cashUpSumCash - smallChange - 150, "EUR", 2);
          this.checkDepositNumbers(1, _.cloneDeep(this.cashUpDepositToBank)); // Kan het bedrag gestort worden met deze kasinhoud. Voorbeeld: Met 4 x 20 euro biljetten in de kassa kan je geen €50 storten
        }
        else{
          this.cashStatementStay.value = cashConv(this.cashUpSumCash, "EUR", 2);
          this.cashUpDepositToBank = cashConv(0, "EUR", 2);
        }
      }
      else{
        this.cashUpDepositToBank = this.cashStatementStay.value > 0 ? cashConv(this.cashUpSumCash - this.cashStatementStay.value, "EUR", 2) : 0;
      }
      
      let statements = this.pinIncomeStatement + this.cashStatement;


      this.differenceCash = 0;
      if(this.cashStatement >= 0){
        this.differenceCash = cashConv(this.cashUpSumCash - (this.cashStatement + this.latestCashRounding), "EUR", 2);
      }
      else{
        this.differenceCash = cashConv((this.cashStatement + this.latestCashRounding) + (this.cashUpSumCash - (this.cashStatement + this.latestCashRounding)), "EUR", 2);
      }

      this.differencePin = 0;
      if(this.pinIncomeStatement >= 0){
        this.differencePin = cashConv(this.cashUp.pin - this.pinIncomeStatement, "EUR", 2);
      }
      else{
        this.differencePin = cashConv(this.cashStatement + (this.cashUp.pin - this.pinIncomeStatement), "EUR", 2);
      }


      // let differencePin =  this.pinIncomeStatement - this.cashUp.pin;

      let difference = (this.differenceCash + this.differencePin);

			if (difference != 0 && difference >= -10 && difference <= 10) {
				this.cashUpFeedback.box = "neutral";
				if(difference < 0){
						this.cashUpFeedback.message = `<span style="font-size:18px; font-weight: bold"> €${Math.abs(difference).toFixed(2)}</span> kas tekort`
				}
				else{
						this.cashUpFeedback.message = `<span style="font-size:18px; font-weight: bold"> €${Math.abs(difference).toFixed(2)}</span> kas teveel`
				}
			}
			else if (difference > 10) {
				this.cashUpFeedback.message = `Kasverschil <BR> <span style="font-size:24px; font-weight: bold"> €${Math.abs(difference).toFixed(
					2
				)} Kas teveel </span> <BR> Zorg dat de kassalade volledig is geteld. Zijn alle bonnen en uitgaven goed ingevoerd? Is dit niet het geval, klik dan op annuleer en voer deze eerst in.`;
				this.cashUpFeedback.box = "red";
			}
			else if (difference < -10) {
				this.cashUpFeedback.message = `Kasverschil <BR> <span style="font-size:24px; font-weight: bold">  €${Math.abs(difference).toFixed(
					2
				)} Kas tekort </span> <BR> Zorg dat de kassalade volledig is geteld. Zijn alle bonnen en uitgaven goed ingevoerd? Is dit niet het geval, klik dan op annuleer en voer deze eerst in.`;
				this.cashUpFeedback.box = "red";
			}
			else if (difference === 0) {
				console.log("actief controlleren");
				let checkEverythingisFilledRight = false;

				for (const key in this.cashUp) {
					if (this.cashUp[key] !== 0) {
						checkEverythingisFilledRight = true;
						break;
					}
				}

				if (checkEverythingisFilledRight === true) {
					this.cashUpFeedback.message = `<span style="font-size: 18px; color: #ffffff"><strong>Geen kasverschil!</strong></span>`;
					this.cashUpFeedback.box = "green";
				}
			}
			
		},

		checkDepositNumbers(times, orginDeposit) { // Kan het bedrag gestort worden met deze kasinhoud. Voorbeeld: Met 4 x 20 euro biljetten in de kassa kan je geen €50 storten
      let cashNumbers = new Array();
      for(var i=0; i<(this.cashUp.fiveEuro); i++){cashNumbers.push(5)}
      for(var i=0; i<(this.cashUp.tenEuro); i++){cashNumbers.push(10)}
      for(var i=0; i<(this.cashUp.twentyEuro); i++){cashNumbers.push(20)}
      for(var i=0; i<(this.cashUp.fiftyEuro); i++){cashNumbers.push(50)}
      for(var i=0; i<(this.cashUp.hundredEuro); i++){cashNumbers.push(100)}
      for(var i=0; i<(this.cashUp.twoHundredEuro); i++){cashNumbers.push(200)}
      for(var i=0; i<(this.cashUp.fiveHundredEuro); i++){cashNumbers.push(500)}
      console.log("cashNumbers: ", cashNumbers)


      function getFirstSubset(array, sum) {

          function fork(i = 0, s = 0, t = []) {
              if (s === sum) {
                  return  t;
              }
              if (i === array.length) {
                  return;
              }
              return fork(i + 1, s + array[i], t.concat(array[i]))
                  || fork(i + 1, s, t);
          }

          return fork();
      }

      let depositCombination = getFirstSubset(cashNumbers, this.cashUpDepositToBank)


      if(!depositCombination || depositCombination.length == 0){
       
        if(this.cashStatementStay.value > 110){
          let addAmount = 10
          if(times == 1){
            addAmount = 5
          }
          if(times == 3){
            addAmount = 20
          }
          if(times == 4){
            addAmount = 50
          }
          if(times == 5){
            addAmount = 100
          }
          if(times == 6){
            addAmount = 200
          }
          if(times == 7){
            addAmount = 500
          }
          if(times < 8){
            console.log("Kan niet gestort worden. Poging: ", times, "Storten", this.cashUpDepositToBank)
            this.cashUpDepositToBank = orginDeposit + addAmount
            this.cashStatementStay.value = cashConv(this.cashUpSumCash - this.cashUpDepositToBank, "EUR", 2) 
            this.checkDepositNumbers(times+1, orginDeposit)
          }
          else{
            console.log("Alle hope is gone")
            this.cashUpDepositToBank = 0;
            this.cashStatementStay.value = cashConv(this.cashUpSumCash - this.cashUpDepositToBank, "EUR", 2) 
          }
        }
        else{
          this.cashUpDepositToBank = 0;
          this.cashStatementStay.value = cashConv(this.cashUpSumCash - this.cashUpDepositToBank, "EUR", 2) 
        }
        
      }
      else{
        console.log("checkDepositNumbers: ", orginDeposit, "Poging: ", times)
        console.log("Kan gestort worden. Poging: ", times)
      }
    },

		saveCashUp(type) {
			this.cashUpFeedback.loading = true;

			//When Forced
			let newCashStatement = 0;
			if (this.cashUpDifference > 0) {
				newCashStatement = this.cashStatementStay.value + this.cashUpDifference;
			}

			// 1. Update Cash Statement
			db.collection("cashRegister")
				.doc(`cash_${this.$store.state.activeCompany.id}_${this.$store.state.activeBranch.id}_${this.selectedCashRegister}`)
				.update({
					cash: this.cashStatementStay.value,
          cashUpCash: this.cashStatementStay.value,
          cashRounding: 0,
          cashUpTimestamp: new Date(),
          timestamp: new Date(),
				})
				.then(() => {
					db.collection("cashRegister") // 2. Add CashUp
						.add({
							postToTable: "thrive_cashRegister_v1",
							cashUp: JSON.stringify(this.cashUp), // Object to BQ as String: Object with all counted cash types, pin and creditcard
							cashUpSum: cashConv(this.cashUpSumCash,"EUR",2), // BQ : Total Cash amount
							cashUpSumTotal: cashConv(this.cashUpSumTotal,"EUR",2),  // BQ: Total income (cash + pin + creditcard)
							cashUpDepositToBank: this.cashUpDepositToBank, //  BQ: Deposit to bank
							companyId: this.$store.state.activeCompany.id,
							branchId: this.$store.state.activeBranch.id,
							byResource: this.userId,
							created: new Date(),
							type: "cashUp",
							register: this.selectedCashRegister,
							cashRounding: this.latestCashRounding, // BQ: Profit or loss by rounding cash amount in change
							previousCashUpSum: cashConv(this.latestCashUpCash, "EUR", 2), // BQ: Starting cash amount  in register
							cashStatement: cashConv(this.cashStatement - this.latestCashUpCash, "EUR", 2), // BQ: Payment amount in cash
							cardStatement: cashConv(this.cashUp.pin + this.cashUp.creditcard ,"EUR", 2), // BQ: Payment Amount by Cards (Pin and Creditcard)
							cashSurplus: this.differenceCash > 0 ? cashConv(this.differenceCash, "EUR", 2) : 0, // BQ
							cashDeficit: this.differenceCash < 0 ? cashConv(Math.abs(this.differenceCash), "EUR", 2) : 0, // BQ
							pinSurplus: this.differencePin > 0 ? cashConv(this.differencePin, "EUR", 2) : 0, // BQ
							pinDeficit: this.differencePin < 0 ? cashConv(Math.abs(this.differencePin), "EUR", 2) : 0, // BQ
							creditcardSurplus: this.differenceCreditcard > 0 ? cashConv(this.differenceCreditcard, "EUR", 2) : 0, // BQ
							creditcardDeficit: this.differenceCreditcard < 0 ? cashConv(Math.abs(this.differenceCreditcard), "EUR", 2) : 0 // BQ
						})
						.then((docRef) => {
							console.log("Cash Up =>", docRef.id);
							if (type === "force") {
								//With force to Promise
								let countForcePossibllity = 0;
								let countForcePossibllityDone = 0;
								new Promise((resolve) => {
									if (this.cashUpdifference > 0) {
										if (this.cashUpSumCash - this.cashStatement > 0) {
											countForcePossibllity++;
											db.collection("cashRegister")
												.add({
													postToTable: "thrive_cashRegister_v1",
													byResource: this.userId,
													depositName: "Forced Cash Surplus",
													deposit: this.cashUpSumCash - this.cashStatement,
													depositType: "cash",
													type: "deposit",
													register: this.selectedCashRegister,
													branchId: this.$store.state.activeBranch.id,
													companyId: this.$store.state.activeCompany.id,
													created: new Date(),
												})
												.then((docRefDeposit) => {
													console.log("Deposit saved to database =>", docRefDeposit.id);
													if (countForcePossibllityDone == countForcePossibllity) {
														resolve();
													}
												})
												.catch((err) => {
													console.error("Can't save to database", err);
												});
										}

										//CARD Deposit
										if (this.cashUpSumByCard.byPIN > 0) {
											//PIN
											countForcePossibllity++;
											db.collection("cashRegister")
												.add({
													postToTable: "thrive_cashRegister_v1",
													byResource: this.userId,
													depositName: "Forced Pin Surplus",
													deposit: this.cashUpSumByCard.byPIN,
													depositType: "card",
													type: "deposit",
													register: this.selectedCashRegister,
													branchId: this.$store.state.activeBranch.id,
													companyId: this.$store.state.activeCompany.id,
													created: new Date(),
												})
												.then((docRefDeposit) => {
													console.log("Deposit saved to database =>", docRefDeposit.id);
													if (countForcePossibllityDone == countForcePossibllity) {
														resolve();
													}
												})
												.catch((err) => {
													console.error("Can't save to database", err);
												});
										}
										if (this.cashUpSumByCard.byCreditcard > 0) {
											countForcePossibllity++;
											//Creditcard
											db.collection("cashRegister")
												.add({
													postToTable: "thrive_cashRegister_v1",
													byResource: this.userId,
													depositName: "Forced Creditcard Surplus",
													deposit: this.cashUpSumByCard.byCreditcard,
													depositType: "card",
													type: "deposit",
													register: this.selectedCashRegister,
													branchId: this.$store.state.activeBranch.id,
													companyId: this.$store.state.activeCompany.id,
													created: new Date(),
												})
												.then(() => {
													console.log("Deposit saved to database  =>", docRefDeposit.id);
													if (countForcePossibllityDone == countForcePossibllity) {
														resolve();
													}
												})
												.catch((err) => {
													console.error("Can't save to database", err);
												});
										}
									} else {
										//Expense negative
										db.collection("cashRegister")
											.add({
												postToTable: "thrive_cashRegister_v1",
												byResource: this.userId,
												expenseName: "Forced Cash Difference",
												typeExpenses: "cashDifference",
												type: "expense",
												expense: Math.abs(this.cashUpDifference),
												register: this.selectedCashRegister,
												branchId: this.$store.state.activeBranch.id,
												companyId: this.$store.state.activeCompany.id,
												created: new Date(),
											})
											.then((docRefExpense) => {
												console.log("Expense saved to database =>", docRefExpense.id);
												resolve();
											})
											.catch((err) => {
												console.error("Can't save to database", err);
											});
									}
								}).then(() => {
									// Wipe out
									console.log("Force done...");
									//Overall Deposit positive
									this.closeCashUp("close");
								});
								//Without Force
							} else {
								// . Wipe Out
								this.closeCashUp("close");
							}
						})
						.catch((err) => {
							console.error("Can't add Cash Up", err);
						});
				})
				.catch((err) => {
					console.error("Can't Update CashRegister", err);
				});
		},

		formatNumber(number){
			if(number){
				return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: 2}).format(number);
			}
			else{
				return "-"
			} 
		},

		getItemName(id, type) {
			if(type == 'treatment'){
				let treatment = this.viewReceiptData.data.selectedItems.find(item=> {return item.id == id});
				if(treatment){
					let internalUse = treatment.internalUse ? ` (${this.$t('payment.internalUse', this.$store.state.locale)})` : "";
					return treatment.name[this.$store.state.locale.toLowerCase()] + internalUse;
				}
			}
			else{
				let product = this.viewReceiptData.data.selectedItems.find(item=> {return item.id == id});
				if(product){
					let internalUse = product.internalUse ? ` (${this.$t('payment.internalUse', this.$store.state.locale)})` : "";
					return product.name + internalUse;
				}
			}
		},

		getItemType(type){

			if(type == 'treatment'){
				return this.$t('general.treatment', this.$store.state.locale);
			}
			else if(type == 'product'){
				return this.$t('general.product', this.$store.state.locale);
			}
			else{
				return this.$t('payment.unknown', this.$store.state.locale);
			}

			// let information = {
			// 	name: this.$t('payment.unknown', this.$store.state.locale),
			// 	type: type == 'product' ? this.$t('general.product', this.$store.state.locale) : this.$t('general.treatment', this.$store.state.locale),
			// 	// internalUse: 
			// };

			// this.productsByBranch.find(item=> {item.id = id})
			
			// if(type == 'treatment'){
			// 	let treatment = this.treatmentsByBranch.find(item=> {return item.id == id});
			// 	if(treatment){
			// 		information.name = treatment.name
			// 	}
			// }
			// else{
			// 	let product = this.productsByBranch.find(item=> {return item.id == id});
			// 	if(product){
			// 		information.name = product.name
			// 	}
			// }
			// return information
		},



		changeSplitCash(inputValue) {
		
			this.displayChange  = inputValue;

			clearTimeout(this.inputTimer);
			this.inputTimer = setTimeout(() => {
				const locale = 'nl-NL';
				const thousandSeparator = Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
				const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');

				let parsedInput = parseFloat(inputValue
					.replace(new RegExp('\\' + thousandSeparator, 'g'), '') // Verwijder duizendtalscheiders
					.replace(new RegExp('\\' + decimalSeparator), '.') // Vervang decimaalteken door punt
				);

				
				if (parsedInput > this.totalBruto) {
					this.splitPayment.splitByCash = this.totalBruto;
					this.splitPayment.splitByCard = 0;
				} else {
					this.splitPayment.splitByCash = cashConv(parsedInput, "EUR", 2);
					this.splitPayment.splitByCard = cashConv(this.totalBruto - parsedInput, "EUR", 2);

				}


				console.log("this.splitPayment.splitByCash: ", this.splitPayment.splitByCash)

			},300);
		},


		changeBrutoToClientFunc() {
			if (this.changeBrutoToClient >= this.totalBrutoAbsolute) {
				this.changeForRefund = cashConv(this.changeBrutoToClient - this.totalBrutoAbsolute, "EUR", 2);
			} else {
				this.changeForRefund = null;
			}
		},
		
		changeFunc(inputValue) {

			//De invoerwaarde eventueel aanpassen
			console.log("changeFunc: ", inputValue);

			this.displayChange = inputValue;
			
			clearTimeout(this.inputTimer);
			this.inputTimer = setTimeout(() => {
			const locale = 'nl-NL';

			const thousandSeparator = Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
			const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');

			this.change = parseFloat(inputValue
					.replace(new RegExp('\\' + thousandSeparator, 'g'), '') // Verwijder duizendtalscheiders
					.replace(new RegExp('\\' + decimalSeparator), '.') // Vervang decimaalteken door punt
			);

			if (this.change >= this.totalBruto) {
				this.changeCalculated = cashConv(this.change - this.totalBruto, "EUR", 2);
			} else {
				this.changeCalculated = null;
			}

			},300);

		
			
			
		
		},

		checkReturnedStatus(item) {
			let returnedItems = item.selectedItems.filter(
				(selectedItem) => this.validateHistoryItem(selectedItem.uniqueIdentifier, item) == true || selectedItem.returned
			);
			if (returnedItems && returnedItems.length == item.selectedItems.length) {
				return true; // Disabled is true, all items are returned
			} else {
				return false;
			}
		},

		getPaymentMethode(type){
			if(type == 'PIN'){
				return "PIN"
			}
			else if(type == 'CASH'){
				return "Contant"
			}
			else if(type == 'Creditcard'){
				return "Creditcard"
			}
			else{
				return "Onbekend"
			}
		},

		durationSuffix(item) {
			if (item.paymentPeriod == "daily") {
				if (item.duration == 1) {
					return "dag";
				} else {
					return "dagen";
				}
			} else if (item.paymentPeriod == "everyWeek") {
				if (item.duration == 1) {
					return "week";
				} else {
					return "weken";
				}
			} else if (item.paymentPeriod == "everyFourWeeks") {
				return "vierweken";
			} else if (item.paymentPeriod == "everyMonth") {
				if (item.duration == 1) {
					return "maand";
				} else {
					return "maanden";
				}
			} else if (item.paymentPeriod == "everyQuarter") {
				if (item.duration == 1) {
					return "kwartaal";
				} else {
					return "kwartalen";
				}
			} else if (item.paymentPeriod == "everyHalfYear") {
				if (item.duration == 1) {
					return "halfjaar";
				} else {
					return "halfjaren";
				}
			} else if (item.paymentPeriod == "everyYear") {
				if (item.duration == 1) {
					return "jaar";
				} else {
					return "jaren";
				}
			} else {
				return `${item.paymentPeriod}`;
			}
		},

		closeViewReceipt(){
			this.viewReceiptData = {
				index: null,
				data: null,
				dialog: false,
				loading:false
			}
		},

		async viewReceipt(index){
			if (window && window.platform && window.platform.name === "Electron") {
				this.checkPrinter();
			}

			this.viewReceiptData = {
				index: index,
				data: this.historyPayments[index],
				dialog: true,
				receipt: null,
				loading:true,
				resend: false,
				resendMessage: null
			};


			if(this.viewReceiptData.data.historyType == 'receipt'){
				this.viewReceiptData.data.receiptItems = new Array();
				let total = {
					priceNettoTotal: 0,
					priceBrutoTotal: 0,
					newPriceTotalNettoTotal: 0,
					newPriceTotalBrutoTotal: 0
				};

				if(this.viewReceiptData.data.selectedItems && this.viewReceiptData.data.selectedItems.length > 0){
					for (let i = 0; i < this.viewReceiptData.data.selectedItems.length; i++) {
						let item = this.viewReceiptData.data.selectedItems[i];
						
						this.viewReceiptData.data.receiptItems.push(item); 
						total.priceNettoTotal = total.priceNettoTotal + Number(item.treatmentPrice);
						total.priceBrutoTotal = total.priceBrutoTotal + Number(item.priceBruto);
						total.newPriceTotalNettoTotal = total.newPriceTotalNettoTotal + Number(item.newPriceTotalNetto);
						total.newPriceTotalBrutoTotal = total.newPriceTotalBrutoTotal + Number(item.newPriceTotal);
						console.log("total: ", total)

						if(item.discountValueByModifiers && item.discountValueByModifiers.length > 0){
							for(let i = 0; i < item.discountValueByModifiers.length; i++){
							let discountItem = item.discountValueByModifiers[i];
								let modifierData = this.viewReceiptData.data.usedModifiers.find((modifier)=> { return modifier.id == discountItem.modifierId})

								if(modifierData && modifierData.type == 'downPayment' && modifierData.id){
									await db.collection("prepayments").doc(modifierData.id).get()
      								.then((doc) => {
										modifierData.description = `${this.$t('payment.downPayment', this.$store.state.locale)} (${moment(doc.data().timestamp.seconds, "X").format("YYYY-MM-DD H:mm")}u)`; 
									})
								}
								console.log("modifierData.type: ", modifierData.type)
								let discountData = {
									type: !modifierData ? 'unknown' : (modifierData.type == 'all' ||  modifierData.type == 'product' ||  modifierData.type == 'treatment') ? 'discount' : modifierData.type ,
									priceBruto: -discountItem.givenDiscount,
									discountType: discountItem.discountType,
									discountValue: discountItem.discountValue,
									percentageVAT: discountItem.vatPercentage,
									typeOfPayment: item.typeOfPayment,
									description: modifierData && modifierData.description ?  modifierData.description : modifierData.name ?  modifierData.name : '',
								};
								this.viewReceiptData.data.receiptItems.push(discountData); 
							}
						}
					}
				}
				this.viewReceiptData.data.total = total;
				await this.resendReceipt(this.viewReceiptData.data, "history");
			}

			if(this.viewReceiptData.data.historyType == 'expense'){
				this.viewReceiptData.data.total = {
					priceNettoTotal: 0,
					priceBrutoTotal: -this.viewReceiptData.data.expense,
					newPriceTotalNettoTotal: 0,
					newPriceTotalBrutoTotal: -this.viewReceiptData.data.expense,
				};
			}
			
			this.viewReceiptData.loading = false;
		},

		openMembershipInfo(membership) {
			this.selectedMembership.info = membership;
			this.selectedMembership.dialog = true;
			setTimeout(() => {
				bus.$emit("openMembershipInformation");
			}, 2000);
		},

		closeMembershipInfo() {
			this.selectedMembership.dialog = null;
			this.selectedMembership.info = {
				name: new Object(),
				posDescription: new Object(),
			};
		},

		
		/**
		 * Searches and filters header receipts based on user input.
		 * @param {string} input - The search input string.
		 * @returns {Array} - The filtered array of header receipt drafts.
		 */
		searchHeaderReceipts(input) {
			this.isSearchingHeader = input.length > 0;
			console.log("isSearchingHeader: ", this.isSearchingHeader)
			if (input === "") {
				this.$refs.searchHeaderField.lazyValue = ""; //reset by ref
				this.headerReceiptDraftsSearch = this.headerReceiptDrafts;
				return;
			}
			
			if (input.length > 1) {
				this.headerReceiptDraftsSearch = this.headerReceiptDrafts.filter(receipt => 
					receipt.clientname.toLowerCase().includes(input.toLowerCase())
				);
				
				return this.headerReceiptDraftsSearch;
			} else {
				this.headerReceiptDraftsSearch = this.headerReceiptDrafts;
				console.log(this.headerReceiptDraftsSearch);
				
				return this.headerReceiptDraftsSearch;
			}
		}
	},



	watch: {
		total() {
			this.getTotal(); //Trigger calculation
		},
		selectedItems: {
			deep: true,

			// We have to move our method to a handler field
			handler() {
				if (this.selectedItems.length == 0) {
					//this.usedModifiers = new Array();
					this.customModifiers = new Array();
				}
			},
		},
		// 'viewReceiptData.data.selectedItems': {
		// 	handler() {
		// 		console.log("Ik ben veranderd")
		// 		this.getTotal(this.viewReceiptData.data.selectedItems, true);
		// 	},
		// 	deep: true,

		// },
	},

	computed:{

		activeReceiptId() {
			return this.selectedReceiptId;
		},
		

		theme() {
			return this.$vuetify.theme.dark ? "dark" : "light";
		},

		paymentBarFloat() {
			return this.$attrs.scrollStatus != "bottom" ? true : false;
		},

		beforeDeposit() {
			if (this.deposit.namePayInto.length > 1 && this.deposit.payInto !== 0) {
				return false;
			} else {
				return true;
			}
		},

		beforeExpense() {
			if (
				this.expenses.name !== "" &&
				this.expenses.feedback === null &&
				this.expenses.chosenTypeExpenses !== "" &&
				this.expenses.amountValue != 0 &&
				this.expenses.chosenVat !== ""
			) {
				return false;
			} else {
				return true;
			}
		},

		loadedMemberships() {
			if (this.validatedByMembershipModule.length === 1) {
				return this.$t('memberships.singleSubscription', this.$store.state.locale);
			} else {
				return this.$t('memberships.multiSubscriptions', this.$store.state.locale);
			}
		},

		customPaymenRuleValid() {
			if (this.customRule.name && this.customRule.resourceId && this.customRule.priceBruto) {
				return true;
			} else {
				return false;
			}
		},

		depositMoneyCalc() {
			this.cashUpDepositToBank = this.cashUpSumCash + this.cashStatement - this.cashStatementStay.value;
			return this.cashUpSumCash + this.cashStatement - this.cashStatementStay.value;
		},

		paymentDeviceHint() {
			let hint = {
				text: null,
				persistent: false,
			};
			this.availablePayDevices.forEach((device) => {
				let paymentDevice = this.$store.state.activeCompany.paymentOptions.find((findDevice) => findDevice.id == device);

				if (paymentDevice.id == this.paymentInfo.chosenPaymentDevice) {
					if (paymentDevice.intermediate) {
						let transactionCosts = null;
						if (paymentDevice.type == "amount") {
							transactionCosts = "€ " + String(paymentDevice.transactionCost);
						} else {
							transactionCosts = String(paymentDevice.transactionCost) + "%";
						}
						hint.text = "Kosten zijn " + transactionCosts + " per transactie.";
						hint.persistent = true;
					}
				}
			});
			return hint;
		},

		discountInfo() {
			if (this.customDiscount.typeDiscount.discountType == "percentage") {
				return {
					prefix: null,
					suffix: "%",
					label: "Kortingspercentage",
				};
			} else if (this.customDiscount.typeDiscount.discountType == "amount") {
				return {
					prefix: "€",
					suffix: null,
					label: "Kortingsbedrag",
				};
			} else {
				return {
					prefix: null,
					suffix: null,
					label: "-",
				};
			}
		},

		productsByBranchFiltered() {
			if (this.productSearch) {
				return this.productsByBranch.filter((products) => {
					return products.name.toLowerCase().indexOf(this.productSearch.toLowerCase()) >= 0;
				});
			} else {
				return this.productsByBranch;
			}
		},

		treatmentsByBranchFiltered() {
			if (this.treatmentSearch) {
				return this.treatmentsByBranch.filter((treatment) => {
					return this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, treatment.name ).toLowerCase().indexOf(this.treatmentSearch.toLowerCase()) >= 0
				});
			} else {
				return this.treatmentsByBranch;
			}
		},

		noEmployeeSelected() {
			let info = {
				icon: null,
				color: this.$vuetify.theme.themes[this.theme].background,
				dark: false,
			};
			if (!this.byResource) {
				info.icon = "mdi-alert-circle-outline";
				info.color = "warning";
				info.dark = true;
			}
			return info;
		},

		version() {
			return this.$store.state.version;
		},

		companyTheme() {
			return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
		},

		checkSelected() {
			function isTrue(o) {
				if (o.selected == true) return true;
			}
			return this.selectedItems.some(isTrue);
		},
		userLanguage() {
			return this.$store.state.locale ? this.$store.state.locale : "NL";
		},
		userCompanies() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
		},
		userCompany() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
		},
		userFirstName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
		},
		userLastName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
		},

		connectedEmployee() {
			return this.$store.state.connectedEmployee;
		},

		employeeConnected() {
			return this.$store.state.connectedEmployee ? true : false;
		},

		userRole() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
		},
		userId() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
		},
		companySelectDisabled() {
			return this.companies.length > 1 ? false : true;
		},

		companyBranchSelectDisabled() {
			return this.branches.length > 1 ? false : true;
		},

		clientSelector() {
			let clients = {
				items: 0,
				pages: 0,
				selectedPage: 1,
				selectionStart: 0,
				selectionEnd: 0,
				paginationItems: 0,
			};

			// lg3 md4 xs6
			if (this.clients.length > 0) {
				clients.items = this.clients;
				if (this.$vuetify.breakpoint.name == "lg") {
					clients.paginationItems = 4;
					clients.pages = Math.ceil(this.clients.length / clients.paginationItems);
					if (this.clientSelectedPage == 1) {
						clients.selectionStart = 0;
						clients.selectionEnd = clients.paginationItems - 1;
					} else {
						clients.selectionStart = this.clientSelectedPage * clients.paginationItems - clients.paginationItems;
						clients.selectionEnd = this.clientSelectedPage * clients.paginationItems - 1;
					}
					return clients;
				}
				if (this.$vuetify.breakpoint.name == "md") {
					clients.paginationItems = 3;
					clients.pages = Math.ceil(this.clients.length / clients.paginationItems);
					if (this.clientSelectedPage == 1) {
						clients.selectionStart = 0;
						clients.selectionEnd = clients.paginationItems - 1;
					} else {
						clients.selectionStart = this.clientSelectedPage * clients.paginationItems - clients.paginationItems;
						clients.selectionEnd = this.clientSelectedPage * clients.paginationItems - 1;
					}
					return clients;
				}
				if (this.$vuetify.breakpoint.name == "sm") {
					clients.paginationItems = 2;
					clients.pages = Math.ceil(this.clients.length / clients.paginationItems);
					if (this.clientSelectedPage == 1) {
						clients.selectionStart = 0;
						clients.selectionEnd = clients.paginationItems - 1;
					} else {
						clients.selectionStart = this.clientSelectedPage * clients.paginationItems - clients.paginationItems;
						clients.selectionEnd = this.clientSelectedPage * clients.paginationItems - 1;
					}
					return clients;
				}
			} else {
				return clients;
			}
		},

		selectSpaces() {
			if(this.activeRegisters.length > 1){
				return this.companies.length > 1 && this.$store.state.activeUserRoleNumber < 2 ? "md2": "md3";
			}
			else{
				return this.companies.length > 1 && this.$store.state.activeUserRoleNumber < 2 ? "md3": "md6";
			}	
		},
		companiesSpaces() {
			return this.activeRegisters.length > 1 ? "md2": "md3";
		},

		activeRegisters(){
			return this.$store.state.activeBranch.posSettings.locations.filter((item)=> { return !item.deleted && item.active})
		},

		filteredCompanies() {
			if (this.searchCompanies && this.searchCompanies.length > 0) {
				return this.companies.filter((company) => {
					return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
				});
			} else {
				return this.companies;
			}
		},
	},

	filters: {
		//For converting Unix
		timestampConvert: function (unixCode) {
			// return moment.unix(unixCode).format("HH:mm:ss");
			return moment.unix(unixCode).format("HH:mm") + "u";
		},
		clientNameConvert: function (clientName) {
			if (typeof clientName == "undefined") {
				return this.$t('payment.anonymousReceipt', this.$store.state.locale);
			} else {
				return clientName;
			}
		},
	},
};
</script>

<style>
.cash-field-wrapper{
    width: 100%;
    height: 50px;
  }
  .cash-field-wrapper:after {
    display: block;
    content: "";
    clear: both;
  }
  .cash-field-image{
    width: 60px;
    height: 40px;
    float: left
  }
  .cash-field-text{
    text-align: left;
    width: 70px;
    height: 40px;
    line-height: 40px;
    float: left
  }
  .cash-field-text.card{
    text-align: left;
    width: 100px;
    height: 40px;
    line-height: 40px;
    float: left
  }
  .cash-field-x{
    text-align: center;
    width: 30px;
    height: 40px;
    line-height: 40px;
    float: left
  }
  .cash-field-number{
    width: 60px;
    height: 40px;
    float: left
  }
  .cash-field-number.card{
    width: calc(100% - 160px);
    height: 40px;
    float: left
  }
  .cash-field-equals{
    text-align: center;
    width: 30px;
    height: 40px;
    line-height: 40px;
    float: left
  }
  .cash-field-sum{
    width: calc(100% - 250px);
    height: 40px;
    float: left;
  }

  .cash-field-image.euro-5-bill{
    background: url("../assets/money/euro-5-bill.jpg") no-repeat left center;
    background-size: 50px auto;
  }
  .cash-field-image.euro-10-bill{
    background: url("../assets/money/euro-10-bill.jpg") no-repeat left center;
    background-size: 50px auto;
  }
  .cash-field-image.euro-20-bill{
    background: url("../assets/money/euro-20-bill.jpg") no-repeat left center;
    background-size: 50px auto;
  }
  .cash-field-image.euro-50-bill{
    background: url("../assets/money/euro-50-bill.jpg") no-repeat left center;
    background-size: 50px auto;
  }
  .cash-field-image.euro-100-bill{
    background: url("../assets/money/euro-100-bill.jpg") no-repeat left center;
    background-size: 50px auto;
  }
  .cash-field-image.euro-200-bill{
    background: url("../assets/money/euro-200-bill.jpg") no-repeat left center;
    background-size: 50px auto;
  }
  .cash-field-image.euro-500-bill{
    background: url("../assets/money/euro-500-bill.jpg") no-repeat left center;
    background-size: 50px auto;
  }

  .cash-field-image.euro-1-cent-coin{
    background: url("../assets/money/euro-1-cent-coin.png") no-repeat left center;
    background-size: auto 50px;
  }
  .cash-field-image.euro-2-cent-coin{
    background: url("../assets/money/euro-2-cent-coin.png") no-repeat left center;
    background-size: auto 50px;
  }
  .cash-field-image.euro-5-cent-coin{
    background: url("../assets/money/euro-5-cent-coin.png") no-repeat left center;
    background-size: auto 42px;
  }
  .cash-field-image.euro-10-cent-coin{
    background: url("../assets/money/euro-10-cent-coin.png") no-repeat left center;
    background-size: auto 50px;
  }
  .cash-field-image.euro-20-cent-coin{
    background: url("../assets/money/euro-20-cent-coin.png") no-repeat left center;
    background-size: auto 45px;
  }
  .cash-field-image.euro-50-cent-coin{
    background: url("../assets/money/euro-50-cent-coin.png") no-repeat left center;
    background-size: auto 40px;
  }
  .cash-field-image.euro-1-coin{
    background: url("../assets/money/euro-1-coin.png") no-repeat left center;
    background-size: auto 40px;
  }
  .cash-field-image.euro-2-coin{
    background: url("../assets/money/euro-2-coin.png") no-repeat left center;
    background-size: auto 40px;
  }

  .cash-field-image.pin{
    background: url("../assets/money/pin.gif") no-repeat left center;
    background-size: 45px auto;
  }

  .cash-field-image.creditcard{
    background: url("../assets/money/creditcard.gif") no-repeat left center;
    background-size: 45px auto;
  }

  .cashUpSpan {
    float: left;
    margin: 4px 2px 0 8px;
    width: 66px;
    height: 57px;
  }

  .cashUpSpan.card {
    float: left;
    margin: 4px 2px 0 8px;
    width: 100px;
    height: 57px;
  }

  .cashUpSpanX {
    float: left;
    margin: 10px 8px 0 8px;
    width: 6px;
  }
  .cashUpCashImage {
    float: left;
    width: 52px;
    height: 52px;
    padding: 8px 0 12px 0;
    margin: -2px 0px 0 0px;
  }

  .cashUpTextField {
    float: left;
    margin: 6px 0 6px 0;
    width: 60px;
  }

  .cashUpTextField.card {
    float: left;

    width: 160px;
  }

  .cashUpTextField > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .cashUpTextField-calculation {
    float: left;
    width: 116px;
  }

  .descriptionCashup {
    clear: both;
    margin: 0 0 12px 0;
    border-bottom: 1px solid #dcdcdc;
  }

  .wrap-cashUpField:after {
    display: block;
    content: "";
    clear: both;
  }

  .wrap-stayInCash {
    clear: both;
    padding: 12px;
    border-radius: 5px;
    /* border: 1px solid #dcdcdc; */

    background: rgb(240, 242, 242);
    background: linear-gradient(
      90deg,
      rgba(240, 242, 242, 1) 20%,
      rgba(240, 242, 242, 1) 29%,
      rgba(247, 248, 248, 1) 41%,
      rgba(251, 251, 251, 1) 53%
    );
  }

  .cashDifference {
    border-radius: 5px;
    color: white;
    background-color: #6e6767;
    padding: 12px 12px 18px 12px;
  }

  .cashDifference.correct {
    background: rgb(65, 195, 47);
    background: linear-gradient(
      68deg,
      rgba(65, 195, 47, 1) 0%,
      rgba(76, 215, 57, 1) 81%,
      rgba(65, 195, 47, 1) 100%
    );
  }

  .cashDifference.red {
    background: rgb(183, 28, 28);
    background: linear-gradient(
      68deg,
      rgba(183, 28, 28, 1) 0%,
      rgba(224, 60, 60, 1) 67%,
      rgba(183, 28, 28, 1) 89%
    );
  }

  .textfieldCashStatement {
    margin: 0px 0 6px 0;
  }

  .custom-algolia .ais-Highlight-highlighted {
    color: var(--primary-color);
  }

  .custom-algolia .ais-Hits-item,
  .custom-algolia .ais-Hits-list {
    padding: 0 !important;
    box-shadow: none!important;
    -webkit-box-shadow: none!important;
    border-radius: 0;
    background: none!important;
  }

  .custom-algolia .ais-Hits-item {
    border-bottom: 1px solid var(--separator-color);
  }

  .payment-datatable td,
  .modiefier-datatable td {
    padding: 7px 5px !important;
  }

  .modiefier-datatable {
    border-radius: 0;
    border-bottom: 1px solid rgba(104, 115, 135, 0.2) !important;
  }

  .payment-datatable {
    border-radius: 0;
    border-top: 1px solid rgba(104, 115, 135, 0.2) !important;
    border-bottom: 1px solid rgba(104, 115, 135, 0.2) !important;
  }

  .payment-row-wrapper {
    border-bottom: 1px solid rgba(104, 115, 135, 0.2) !important;
  }

  .payment-client-information {
    margin: 0 0 15px 15px;
    padding: 15px;
  }

  .product-wrapper-card {
    box-shadow: none !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }

  /*

  <div class="discount-type-buttons-wrapper">
    <div class="discount-type-button percentage" :class="item.discountName">%</div>
    <div class="discount-type-button amount" :class="item.discountName" >&euro;</div>
    </div>

  */

.discount-type-buttons-wrapper {
	width: 80px;
	float: left;
}

.discount-type-button {
	width: 36px;
	height: 49px;
	margin: 0 4px 0 0;
	background-color: rgba(0, 0, 0, 0.19);
	color: #333;
	float: left;
	line-height: 50px;
	font-size: 16px;
	text-align: center;
}

.discount-type-button:hover {
	background-color: #687387;
	color: #fff;
	cursor: pointer;
}

.discount-type-button.percentage.Percentage,
.discount-type-button.ammount.Bedrag {
	background-color: #00b0ff;
	color: #fff;
	font-weight: bold;
}

/* .posInput {
    background-color: red;
  } */
.search-results {
	position: relative;
	max-width: 100%;
}

.client-list-item {
	background-color: #c6c6c6;
	padding: 5px;
}
.client-list-item.active {
	background-color: #00b0ff !important;
	padding: 5px;
}
.client-list-item:hover {
	background-color: rgba(104, 115, 135, 0.3);
}

.client-list-item .v-list__tile__title {
	color: #fff !important;
}
.client-list-item .v-list__tile__sub-title {
	color: #fff !important;
}

.post {
	/* background-color: rgba(104, 115, 135, 0.3) !important; */
	background-color: var(--background-color) !important;
}
.v-list-item--disabled {
	opacity: 0.3;
	cursor: not-allowed;
}

.v-list-item--disabled:hover {
	cursor: not-allowed !important;
}

.client-searchbox {
	position: absolute;
	z-index: 99;
	max-height: 260px;
	width: 100%;
	overflow-y: scroll;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.loaderSettings {
	background: url("../assets/loaders/wolfload.svg") no-repeat center center;
	height: 46px;
	width: 46px;
}

.avatarGen svg {
	width: 38px;
	height: 38px;
}
.header-avatar svg {
	width: 52px;
	height: 52px;
}



</style>

<template>
	<div style="position: relative" ref="paymentHeight" @scroll="getScrollInformation">
		<div v-if="dataloaded == false" style="padding: 200px">
			<div class="thrive-loader"></div>
		</div>

		<div v-if="dataloaded">
			<!-- / Payment Header \ -->
			<div class="payment-header">
        <v-container grid-list-md class="pa-0 ma-0" fluid>
          <v-layout row wrap>
			<companySelector :showBranches="true" @companyChanged="loadData" @branchChanged="loadData"></companySelector>

            <!-- <v-flex :class="companiesSpaces" xs12 style="position: relative" v-if="companies.length > 1 && $store.state.userRoleNumber < 2">
              <v-select
                :items="filteredCompanies" 
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"       
                item-text="name"
                item-value="id"
                hide-details
                rounded
                v-on:input="loadActiveCompData()"
                v-model="activeComp"
                :no-data-text="$t('settings.noCompaniesFound', $store.state.locale)"
                :label="$t('settings.selectCompany', $store.state.locale)"
                outlined
                dense>
                <template v-slot:prepend-item>
                  <div class="pa-2" style="margin: -8px 0 0 0">
                    <v-text-field
                      class="ma-0 pa-0"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :placeholder="$t('general.search', $store.state.locale)"
                      append-icon="mdi-magnify"
                      clearable
                      v-model="searchCompanies"
                      hide-details
                      dense
                      outlined
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"  
                    ></v-text-field>
                    </div>
                  <v-divider></v-divider>
                  <div v-if="searchCompanies && searchCompanies.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredCompanies.length }} {{ $t("settings.of", $store.state.locale) }} {{ companies.length }} {{ $t("settings.companies", $store.state.locale) }}</div>
                  <v-divider v-if="searchCompanies && searchCompanies.length > 0"></v-divider>
                </template>
              </v-select>
            </v-flex>

            <v-flex xs12 :class="selectSpaces">
              <v-select
                :read-only="branches.length == 1"
                :items="branches"
                item-text="name"
                item-value="id"
                v-model="activeBranch"
                :label="$t('payment.closeReceipt', $store.state.locale)"
                outlined
                dense
                rounded
                single-line
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                v-on:input="setSelectedBranchData()"
                hide-details
                :disabled="companyBranchSelectDisabled"
              ></v-select>
            </v-flex> -->
			<v-flex xs12 :class="selectSpaces" v-if="activeRegisters.length > 1">
              <v-select
                :read-only="activeRegisters.length == 1"
								:disabled="activeRegisters.length == 1"
                :items="activeRegisters"
								:item-text="(item) => item.name ? item.name : $t('general.general', $store.state.locale)"
                item-value="id"
                v-model="selectedCashRegister"
                :label="$t('payment.register', $store.state.locale)"
                outlined
                dense
                rounded
                single-line
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
              ></v-select>
            </v-flex>

			

            <v-flex md6 xs12 class="text-right">
				
              <v-menu
                transition="slide-y-transition"
                v-model="receiptMenu"
                :close-on-content-click="true"
                offset-y
                open-on-hover
                :content-class="`theme-shadow ${$store.state.companyTheme}`"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" fab dark small height="38" width="38" style="margin-top: -5px!important;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 mr-3">
                    <v-icon>mdi-receipt</v-icon>
                  </v-btn>
                </template>

                <v-card max-width="200px" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                  <v-list dense class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-list-item class="ma-0 px-2" @click="closeReceipt()" v-if="selectedReceiptId && activeTab == 'pay'">
                        <v-list-item-icon class="mr-2">
                          <v-icon small >mdi-close</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title> {{ $t('payment.closeReceipt', $store.state.locale) }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="ma-0 px-2" @click="deleteReceiptDraft(selectedReceiptId)" v-if="selectedReceiptId && activeTab == 'pay'">
                        <v-list-item-icon class="mr-2">
                          <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title> {{ $t('payment.deleteReceipt', $store.state.locale) }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider v-if="selectedReceiptId"></v-divider>

                      <v-list-item class="ma-0 px-2" @click="newReceiptDraft(null, null)">
                        <v-list-item-icon class="mr-2"> 
                          <v-icon small>mdi-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('payment.newReceipt', $store.state.locale) }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="ma-0 px-2" @click="openExpense()">
                        <v-list-item-icon class="mr-2">
                          <v-icon small>mdi-cash-minus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('payment.expences', $store.state.locale) }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="ma-0 px-2" @click="openDeposit()">
                        <v-list-item-icon class="mr-2">
                          <v-icon small>mdi-cash-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('payment.registerDeposit', $store.state.locale) }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="ma-0 px-2" @click="openCashRegister()">
                        <v-list-item-icon class="mr-2">
                          <v-icon small>mdi-cash-register</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('payment.registerCheck', $store.state.locale) }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>

              <v-container container class="ma-0 pa-0" style="display: inline-block; width: calc(100% - 80px)">
                <v-layout row wrap class="ma-0 pa-0">
                  <v-flex xs4 class="ma-0 pa-0">
                    <div class="multi-button left" :class="{ active: activeTab == 'pay' }" @click="loadTab('pay')">
                      <p class="multi-button-text">{{ $t('payment.pay', $store.state.locale) }}</p>
                      <div class="multi-button-background"></div> 
                    </div>
                  </v-flex>
                  <v-flex xs4 class="ma-0 pa-0">
                    <div class="multi-button middle" :class="{ active: activeTab == 'history' }" @click="loadTab('history'), getHistoryPayments()">
                      <p class="multi-button-text">{{ $t('payment.history', $store.state.locale) }}</p>
                      <div class="multi-button-background"></div> 
                    </div>
                  </v-flex>
                  <v-flex xs4 class="ma-0 pa-0">
                    <div class="multi-button right" :class="{ active: activeTab == 'open' }" @click="loadTab('open')">
                      <p class="multi-button-text">{{ $t('payment.open', $store.state.locale) }} </p>
                      <div class="multi-button-background"></div> 
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>

            </v-flex>
          </v-layout>
        </v-container>
      </div>

      	<!-- / PAY \ -->
        <div v-if="activeTab == 'pay'" >		
			<div class=" align-center">
				<v-row>
					<v-col cols="2">
						<!-- Search Header Receipt Drafts Search -->
						<v-card
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
							flat
							class="mt-3 2 pa-0 flex-shrink-0"
							height="72"
							width="100%"
							style="top: 4px;"
							>
							<v-text-field
								ref="searchHeaderField"
								:label="$t('payment.searchReceipts', $store.state.locale)"
								prepend-inner-icon="mdi-magnify"
								hide-details
								outlined
								clearable
								persistent-placeholder
							
								class="pa-2"
								@input="searchHeaderReceipts($event)"
								@click:clear="searchHeaderReceipts('')"
								
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
							></v-text-field>

						</v-card>
					</v-col>
					<v-col cols="10" class="px-0">
						<v-slide-group  v-model="selectedReceipt" class="pa-0 pt-2 ma-0 " show-arrows >
					
							<v-slide-item v-for="(headerReceiptDraft, index) in headerReceiptDraftsSearch" :key="index" v-slot="{ active }">
								<!-- :disabled="clientReceiptDataLoading === true || headerReceiptDraft.id === selectedReceiptId" -->
								<v-card
									@click="openReceipt(headerReceiptDraft.id, index); searchHeaderReceipts('');"
									:disabled="clientReceiptDataLoading === true"
									:color="headerReceiptDraft.id === activeReceiptId && !isSearchingHeader ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].card"
									:dark="headerReceiptDraft.id === activeReceiptId && !isSearchingHeader"
									flat
									class="my-2 mr-2 pa-0"
									height="70"
									width="260"
								>
									<v-list  :color="headerReceiptDraft.id === activeReceiptId && !isSearchingHeader ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].card"
											class="pa-0"
										>
										<v-list-item class="ma-0 pa-2">
											<v-list-item-avatar class="mr-3">
												<div v-html="genAvatar(headerReceiptDraft.clientname)" class="avatarGen"></div>
											</v-list-item-avatar>
											<v-list-item-content class="pa-0">
												<v-list-item-title>
													<!-- <v-icon v-if="headerReceiptDraft.uniqIdStamp" small>mdi-chip</v-icon>  -->
													{{ headerReceiptDraft.clientname | clientNameConvert }}
												</v-list-item-title>
												<v-list-item-subtitle>
													{{ getTotal(headerReceiptDraft.selectedItems) }}
													<br />
													{{ $t('payment.edited', $store.state.locale)}}:
													{{ headerReceiptDraft.modified.seconds | timestampConvert }}
												</v-list-item-subtitle>
											</v-list-item-content>

											<v-list-item-action>
												<v-icon>mdi-chevron-right</v-icon>
											</v-list-item-action>
										</v-list-item>
									</v-list>
								</v-card>
							</v-slide-item>
						</v-slide-group>
					</v-col>
				</v-row>
			</div>
			

			<!-- <v-menu
				v-model="moreReceiptsMenu"
				:close-on-content-click="false"
				:nudge-width="200"
				offset-y
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="primary"
						v-bind="attrs"
						v-on="on"
						class="mt-2"
					>
						{{ $t('payment.moreReceipts', $store.state.locale) }}
					</v-btn>
				</template>

				<v-card>
					<v-list>
						<v-list-item
							v-for="(receipt, index) in moreReceipts"
							:key="index"
							@click="openReceipt(receipt.id, index + headerReceiptDrafts.length)"
						>
							<v-list-item-content>
								<v-list-item-title>{{ receipt.clientname | clientNameConvert }}</v-list-item-title>
								<v-list-item-subtitle>
									{{ getTotal(receipt.selectedItems) }}
									<br />
									{{ $t('payment.edited', $store.state.locale) }}: {{ receipt.modified.seconds | timestampConvert }}
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-menu> -->

			<!-- \ Payment Header / --> 

			<div class="no-paymentdata-wrapper" v-if="dataloaded == true && !selectedReceiptId">
				<p style="padding: 60px 30px 0 30px; text-align: center" v-html="$t('payment.noReceiptSelected', $store.state.locale)"></p>
			</div>

			<div class="payment-wrapper" v-if="dataloaded == true && selectedReceiptId.length !== 0">
				<div class="special-content-box">
					<v-container class="pa-0 ma-0 pt-2" grid-list-md>
						<v-layout row wrap>
							<v-flex xs12 sm8 class="pr-3" style="padding-bottom: 80px">
								<v-tabs :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="tab" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
									<v-tab>{{ $t('payment.pay', $store.state.locale) }}</v-tab>
									<v-tab :disabled="!clientId" v-show="historyReceipts">{{ $t('payment.history', $store.state.locale) }}</v-tab>
									<v-tab :disabled="!clientId">{{ $t('payment.future', $store.state.locale)}}</v-tab>
									<v-tab :disabled="!clientId">{{ loadedMemberships }}</v-tab>
									<v-tab :disabled="!clientId">{{ $t('payment.deposits', $store.state.locale)}}</v-tab>
								</v-tabs>

								<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-4" :class="$store.state.companyTheme" style="overflow: visible!important">
									<v-tabs-items v-model="tab" style="background: none !important; overflow: visible!important">
										<!-- / TAB PAY \ -->
										<v-tab-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
											<v-container grid-list-md>
												<v-layout row wrap>
													<v-flex md6 xs12>
														<div v-if="!clientReceiptDataLoading">
															<!-- / Start Search Client \ -->
															<div v-if="selectedClientData.id">
																<v-container class="pa-0 ma-0">
																	<v-layout row wrap class="pa-0 ma-0">
																		<v-flex xs10>
																			<v-text-field
																				hide-details
																				outlined
																				:value="`${selectedClientData.name} ${selectedClientData.surname}`"
																				dense
																				readonly
																				single-line
																				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																				:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																				append-icon="mdi-eye"
																				@click:append="openCustomerDialog()"
																			></v-text-field>
																		</v-flex>
																		<v-flex xs2>
																			<!--Delete receipt -->
																			<v-btn
																				style="margin: 2px 0 0 0"
																				icon
																				color="error"
																				:disabled="selfGeneratedReceipt === true"
																				@click="deleteReceiptDraft(selectedReceiptId)"
																			>
																				<v-icon>mdi-delete</v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</v-container>
															</div>

															<div v-else style="position: relative">
																<v-text-field
																	dense
																	outlined
																	:placeholder="$t('payment.clientSearch', $store.state.locale)"
																	append-icon=""
																	prepend-inner-icon="mdi-magnify"
																	hide-details
																	clearable
																	v-model="clientSearch"
																></v-text-field>

																<div v-if="clientSearch && clientSearch.length > 2" style="position: absolute; left: 0; top: 40px; width: 100%">
																	<v-card
																		style="position: absolute; z-index: 99; max-height: 260px; overflow-y: scroll; left: 0; width: 100%"
																		tile
																		class="pa-0 ma-0 custom-algolia"
																		>
																		<ais-instant-search :search-client="algoSearch" index-name="thrive_clients" class="pa-0 ma-0">
																			<v-list class="pa-0 ma-0" style="overflow-y: scroll" :style="{'maxHeight': paymentAddItemsHeight + 'px'}">
																				<ais-hits class="pa-0 ma-0">
																					<template slot="item" slot-scope="{ item }">
																						<v-list-item
																							class="pa-2 py-1 ma-0"
																							@click="
																								getSelectedClientData(item.objectID, item.name + ' ' + item.surname);
																								clientId = item.objectID;
																								clientSearch = '';"
																							>
																							<v-list-item-avatar>
																								<div v-html="genAvatar(item.name + ' ' + item.surname)" class="avatarGen" ></div>
																							</v-list-item-avatar>

																							<v-list-item-content>
																								<v-list-item-title>
																									<ais-highlight attribute="name" :hit="item" highlightedTagName="mark" />&nbsp;<ais-highlight attribute="surname" :hit="item" highlightedTagName="mark" />&nbsp;
																									<v-chip x-small v-if="item.gender">{{ getGender(item.gender) }}</v-chip>
																								</v-list-item-title>
																								<v-list-item-subtitle>
																									<ais-highlight attribute="email" :hit="item" highlightedTagName="mark" />
																								</v-list-item-subtitle>
																								<v-list-item-subtitle v-if="item.phones">
																									<span v-for="(phone, index) in item.phones" :key="index">
																										<span v-if="phone.primary"><v-icon size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-star</v-icon> {{ phone.phone.international }}</span>
																									</span>
																								</v-list-item-subtitle>
																							</v-list-item-content>

																							<v-list-item-action>
																								<v-icon>mdi-chevron-right</v-icon>
																							</v-list-item-action>
																						</v-list-item>
																					</template>
																				</ais-hits>
																																																					
																			</v-list>
																			
																			<ais-state-results>
																				<p class="text-center pa-2 pt-4 pb-1 ma-0" slot-scope="{ state: { query }, results: { hits } }" v-show="!hits.length">
																					<i>
																						{{ $t("clients.noResultsFoundFor", $store.state.locale) }}
																						{{ query }}
																					</i>
																				</p>
																			</ais-state-results>
																			<div class="pa-4 text-center">
																					<v-btn class="ma-0" outlined small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="createNewClient()">{{ $t('payment.addNewClient', $store.state.locale) }}</v-btn>
																				</div>	
																			<ais-configure :query="clientSearch" :facetFilters="[`companyId:${$store.state.activeCompany.id}`]" />
																		</ais-instant-search>
																	</v-card>
																</div>

																<!--  ***** \ End Client Filter  / ***** -->
															</div>
															<!-- \ End Search Client /-->
														</div>
													</v-flex>

													

													<v-flex xs6 class="text-right">
														<div style="display: inline-block">
														
															<v-menu offset-y max-width="350" v-if="clientId != ''">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn
																		class="ma-1 mx-2"
																		:color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
																		:dark="modifiers.length > 0"
																		:disabled="!modifiers.length > 0"
																		v-bind="attrs"
																		v-on="on"
																		>
																		{{ $t('payment.promos', $store.state.locale) }}
																		<v-icon right small>mdi-ticket-percent</v-icon>
																	</v-btn>
																</template>
																<v-list class="ma-0 pa-0" dense avatar>
																	<v-list-item-group
																		:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																		class="ma-0 pa-0"
																		v-for="(modified, index) in modifiers"
																		:key="modified + index"
																	>
																		<v-list-item
																			v-if="(modified.type == 'product' || modified.type == 'treatment' || (modified.type == 'all' && !modified.custom)) && modified.modifierGroup == 'defaultModifier'"	
																			two-line
																			
																		>
																		<!-- :disabled="checkUsage(modified)" -->
																			<v-list-item-action class="ma-0 pa-0">
																				<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="checkModifierUsage(modified.id, 'description')">
																					mdi-checkbox-marked
																				</v-icon>
																				<v-icon color="text" v-else>mdi-checkbox-blank-outline</v-icon>
																			</v-list-item-action>
																			<v-list-item-avatar class="ma-0 pa-0 mx-2">
																				<v-img
																					src="https://firebasestorage.googleapis.com/v0/b/bookingapp-hogans/o/Hogans%20Beauty_Joe_1jqxkxgs9.jpg?alt=media&token="
																				></v-img>
																			</v-list-item-avatar>
																			<v-list-item-content @click="posManualAddDiscount(modified.id)">
																				<v-list-item-title v-html="modified.name"></v-list-item-title>
																				<v-list-item-subtitle v-html="modified.description"></v-list-item-subtitle>
																			</v-list-item-content>
																		</v-list-item>
																		<v-divider v-if="index + 1 < modifiers.length"></v-divider>
																	</v-list-item-group>
																</v-list>
																<v-list>
																	<v-list-item-group>
																		<v-list-item>
																			<v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="scanDiscountDialog = true">Scan Korting</v-btn>
																		</v-list-item>
																	</v-list-item-group>
																</v-list>
															</v-menu>

															<!-- Spaarpunten -->
															<v-menu offset-y max-width="350" v-if="clientId != ''">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn class="mx-2" color="secondary" :dark="modifiers.length > 0" :disabled="!modifiers.length > 0" v-bind="attrs" v-on="on">
																		{{ $t('payment.loyalty', $store.state.locale) }}
																		<v-icon small right>mdi-plus</v-icon>
																	</v-btn>
																</template>
																<v-list class="ma-0 pa-0" dense avatar>
																	<v-list-item-group
																		:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																		class="ma-0 pa-0"
																		v-for="(modified, index) in modifiers"
																		:key="modified + index"
																	>
																		 <v-list-item
																			v-if="
																				(modified.type == 'product' || modified.type == 'treatment' || (modified.type == 'all' && !modified.custom)) &&
																				modified.modifierGroup === 'loyaltyModifier'
																			"
																			:disabled="checkUsageLoyalty(modified.loyaltyPointsNeeded) === false"
																			two-line
																		>
																	
																			<v-list-item-action class="ma-0 pa-0">
																				<!-- <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="checkModifierUsage(modified.id, 'description')">
																					mdi-checkbox-marked
																				</v-icon> -->
																				<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="checkModifierUsage(modified.id, 'description')">
																					mdi-checkbox-marked
																				</v-icon>
																				<v-icon color="text" v-else>mdi-checkbox-blank-outline</v-icon>
																			</v-list-item-action>
																			<v-list-item-avatar class="ma-0 pa-0 mx-2">
																				<v-img
																					src="https://firebasestorage.googleapis.com/v0/b/bookingapp-hogans/o/Hogans%20Beauty_Joe_1jqxkxgs9.jpg?alt=media&token="
																				></v-img>
																			</v-list-item-avatar>
																			<v-list-item-content @click="posManualAddDiscount(modified.id)">
																				<v-list-item-title v-html="modified.name"></v-list-item-title>
																				<v-list-item-subtitle v-html="modified.description"></v-list-item-subtitle>
																			</v-list-item-content>
																		</v-list-item>
																		<v-divider v-if="index + 1 < modifiers.length"></v-divider>
																	</v-list-item-group>
																</v-list>
																<!-- <v-list>
																	<v-list-item-group>
																		<v-list-item>
																			<v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="scanDiscountDialog = true;"> Scan Korting </v-btn>
																		</v-list-item>
																	</v-list-item-group>
																</v-list> -->
															</v-menu>

															<!-- End spaarpunten -->

															<!-- <v-menu
																transition="slide-y-transition"
																v-model="refundMenu"
																:close-on-content-click="true"
																offset-y
																open-on-hover
																:content-class="`theme-shadow ${$store.state.companyTheme}`"
																:color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
															>
																<template v-slot:activator="{ on }">
																	<v-btn
																		class="mx-2"
																		v-on="on"
																		:depressed="userRoleNumber > 3"
																		:color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
																		:dark="userRoleNumber < 4"
																		:disabled="userRoleNumber > 3"
																	>
																		Retour
																		<v-icon right>mdi-cash-refund</v-icon>
																	</v-btn>
																</template>

																<v-card max-width="400px" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
																	<v-list dense class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
																		<v-list-item class="ma-0 px-2" @click="refundBoolean = true" :disabled="userRoleNumber > 2">
																			<v-list-item-icon class="mr-2">
																				<v-icon small>mdi-backburger</v-icon>
																			</v-list-item-icon>
																			<v-list-item-content>
																				<v-list-item-title> {{ $t('payment.selectProductTreatment', $store.state.locale) }} </v-list-item-title>
																			</v-list-item-content>
																		</v-list-item>

																		<v-divider v-if="selectedReceiptId"></v-divider>

																		<v-list-item class="ma-0 px-2" @click="tab = 1" :disabled="userRoleNumber > 3">
																			<v-list-item-icon class="mr-2">
																				<v-icon small>mdi-receipt</v-icon>
																			</v-list-item-icon>
																			<v-list-item-content>
																				<v-list-item-title>{{ $t('payment.receiptRefund', $store.state.locale) }}</v-list-item-title>
																			</v-list-item-content>
																		</v-list-item>
																	</v-list>
																</v-card>
															</v-menu> -->
														</div>

														<v-menu
															transition="slide-y-transition"
															v-model="receiptExtra"
															:close-on-content-click="true"
															offset-y
															left
															open-on-hover
															:content-class="`theme-shadow ${$store.state.companyTheme}`"
															:color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
														>
															<template v-slot:activator="{ on }">
																<v-btn v-on="on" fab dark small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 ml-2 pa-0">
																	<v-icon>mdi-menu</v-icon>
																</v-btn>
															</template>

															<v-card max-width="300px" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
																<v-list dense class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
																	<!-- <v-list-item class="ma-0 px-2" @click="addCustomDiscountDialog()" :disabled="$store.state.activeUserRoleNumber > 3 || !clientId || selectedItems.length == 0"> -->
																	<v-list-item class="ma-0 px-2" @click="addCustomDiscountDialog()" :disabled="!clientId || selectedItems.length == 0">
																		<v-list-item-icon class="mr-2">
																			<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-plus</v-icon>
																		</v-list-item-icon>
																		<v-list-item-content>
																			<v-list-item-title>{{ $t('payment.customDiscount', $store.state.locale) }}</v-list-item-title>
																		</v-list-item-content>
																		</v-list-item>

																		<v-divider></v-divider>

																		<v-list-item class="ma-0 px-2" @click="openCustomDialogFunc" :disabled="$store.state.activeUserRoleNumber > 3">
																		<v-list-item-icon class="mr-2">
																			<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-ticket-percent-outline</v-icon>
																		</v-list-item-icon>
																		<v-list-item-content>
																			<v-list-item-title>{{ $t('payment.customReceiptRow', $store.state.locale) }}</v-list-item-title>
																		</v-list-item-content>
																		</v-list-item>

																		<v-divider></v-divider>

																		<v-list-item class="ma-0 px-2" @click="refundBoolean = true" :disabled="$store.state.activeUserRoleNumber > 3">
																			<v-list-item-icon class="mr-2">
																				<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-backburger</v-icon>
																			</v-list-item-icon>
																			<v-list-item-content>
																				<v-list-item-title> {{ $t('payment.selectProductTreatment', $store.state.locale) }} </v-list-item-title>
																			</v-list-item-content>
																		</v-list-item>

																		<v-divider></v-divider>

																		<v-list-item class="ma-0 px-2" @click="tab = 1" :disabled="$store.state.activeUserRoleNumber > 3">
																			<v-list-item-icon class="mr-2">
																				<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-receipt</v-icon>
																			</v-list-item-icon>
																			<v-list-item-content>
																				<v-list-item-title>{{ $t('payment.receiptRefund', $store.state.locale) }}</v-list-item-title>
																			</v-list-item-content>
																		</v-list-item>

																		<v-divider></v-divider>

																		<v-list-item :disabled="headerReceiptDrafts.length <= 1" class="ma-0 px-2" @click="mergeReceiptDialog.dialog = !mergeReceiptDialog.dialog">
																			<v-list-item-icon class="mr-2">
																				<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-call-merge</v-icon>
																			</v-list-item-icon>
																			<v-list-item-content>
																				<v-list-item-title>{{ $t('payment.mergeReceipt', $store.state.locale) }}</v-list-item-title>
																			</v-list-item-content>
																		</v-list-item>

																	

																		<!-- <v-list-item class="ma-0 px-2" @click="printReceipt()">
																		<v-list-item-icon class="mr-2"> 
																			<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-printer</v-icon>
																		</v-list-item-icon>
																		<v-list-item-content>
																			<v-list-item-title> {{ $t('payment.printReceipt', $store.state.locale) }}</v-list-item-title>
																		</v-list-item-content>
																		</v-list-item>

																	<v-list-item
																		:disabled="window && window.platform && window.platform.name == 'Electron'"
																		class="ma-0 px-2"
																		@click="rollOutReceipt()"
																	>
																		<v-list-item-icon class="mr-2">
																			<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-printer</v-icon>
																		</v-list-item-icon>
																		<v-list-item-content>
																			<v-list-item-title>Print bon</v-list-item-title>
																		</v-list-item-content>
																	</v-list-item> -->
																</v-list>
															</v-card>
														</v-menu>
													</v-flex>
												</v-layout>
											</v-container>

											<div>
												<v-alert
													v-for="(feedback, index) in feedbackArray"
													:key="feedback + index"
													dense
													prominent
													:color="feedback.color"
													dark
													:icon="feedback.icon"
													:type="feedback.type"
													style="border-radius: 0px !important"
													class="ma-0"
													:dismissible="feedback.dismiss"
												>
													<strong>{{ feedback.modifierName }}</strong>
													: {{ feedback.explaination }}
												</v-alert>
											</div>
											<!-- POS input -->

											<div v-if="selectedReceiptId !== null">
												<div v-if="selectedItems.length !== 0">
													<div v-if="validForUseMemberships.length !== 0">
														<!-- Card for each valid membership -->
														<v-card
															v-for="(membership, index) in validForUseMemberships"
															:key="index"
															flat
															:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
															class="custom-card-style company-primary ma-0 pa-0"
															style="border-radius: 0 !important"
														>
															<v-row class="pa-2 ma-0">
																<!-- Checkbox column -->
																<v-col sm="1" class="text-center ma-0 pa-0">
																	<v-checkbox
																		class="pa-2 ma-0 mt-2"
																		:disabled="membership.status.code === '2A' || membership.status.code === '2B' || membership.override"
																		:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																		v-model="membership.toggle"
																		hide-details
																		@change="posToggleMembership(membership.id)"
																	></v-checkbox>
																</v-col>
																<!-- Membership details column -->
																<v-col sm="9" class="text-left ma-0 pa-0">
																	<div class="mb-1">
																		<!-- Membership name -->
																		<h3 class="ma-0 pa-0" style="float: left" :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].text }">
																			{{ membership.name[userLanguage.toLowerCase()] }}
																		</h3>
																		<!-- Chips for membership status -->
																		<!-- Off-Peak: Red chip indicating the membership is outside the valid period -->
																		<v-chip
																			small
																			label
																			class="mx-2 mb-0 custom-chip-style"
																			v-if="membership.peakStatus.peak === 'offPeak'"
																			color="red"
																			text-color="red"
																			dark
																		>
																			{{ $t('payment.outsidePeriod', $store.state.locale) }}
																		</v-chip>
																		<!-- Peak: Green chip indicating the membership is within the valid period -->
																		<span v-if="membership.status === true || membership.override == true">
																			<v-chip
																				small
																				label
																				class="mx-2 mb-0 custom-chip-style"
																				v-if="membership.peakStatus.peak === 'peak'"
																				color="green"
																				text-color="green"
																				dark
																			>
																				{{ $t('payment.insidePeriod', $store.state.locale) }}
																			</v-chip>
																		</span>
																		<!-- Override: Orange chip indicating the membership has been forcibly applied -->
																		<v-chip
																			small
																			label
																			class="mx-2 mb-0 custom-chip-style"
																			v-if="membership.override == true"
																			color="orange"
																			text-color="orange"
																			dark
																		>
																			{{ $t('payment.forced', $store.state.locale) }}
																		</v-chip>
																		<!-- Usage Exceeded: Red chip indicating the membership usage limit has been reached -->
																		<v-chip
																			small
																			label
																			class="mx-2 mb-0 custom-chip-style"
																			v-if="membership.status.code === '2A' || membership.status.code === '2B'"
																			color="red"
																			text-color="red"
																			dark
																		>
																			{{ $t('payment.usageExceeded', $store.state.locale) }}
																		</v-chip>
																	</div>
																	<!-- Membership description -->
																	<div :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].text }">
																		{{ membership.posDescription[userLanguage.toLowerCase()] }}
																	</div>
																
																</v-col>
																<!-- Force membership button column -->
																
																<v-col v-if="(membership.status !== true || membership.peakStatus.peak === 'offPeak') === true && $store.state.userRoleNumber > 4" sm="2" class="text-right ma-0 pa-0 d-flex align-center justify-end">
																	<v-btn :disabled="membership.override" dark color="orange" @click="overrideMembership(membership.id)"> {{ $t('payment.forceMembership', $store.state.locale) }}</v-btn>
																</v-col>
															</v-row>
														</v-card>
													</div>

													<!-- Selected Items -->
													<v-progress-linear
														v-if="loadingDataTable"
														class="ma-0 pa-0"
														absolute
														:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														indeterminate
													></v-progress-linear>

													<v-data-table :headers="paymentHeader" :items="selectedItems" class="payment-datatable" hide-default-footer hide-default-header :items-per-page='-1'>
														<template v-slot:item="{ item, index, isExpanded, expand }">
															<tr
																style="display: table-row; vertical-align: inherit; border-color: inherit"
																class="payment-row-wrapper"
																:class="{ post: item.selected }"
																@click="selector(index)"
																>
																<td style="width: 40px">

																	<!-- :disabled="!item.userAdded" -->
																	<v-btn
																		
																		class="ma-0 pa-0"
																		style="float: left"
																		depressed
																		icon
																		@click="
																			
																			posInputDelete(index, item.userAdded);
																		"
																	>
																		<v-icon size="20">mdi-delete</v-icon>
																	</v-btn>
																</td>
																<td>
																	<h3 style="display: inline-block; font-size: 16px" :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].text }">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) }}</h3>
																		<!-- Chip for InternalUse is true -->
																		<v-chip
																			small
																			label
																			class="mx-2 mb-0 custom-chip-style"
																			v-if="item.internalUse"
																			color="green"
																			text-color="green"
																			dark
																			>
																			{{ $t('payment.internalUse', $store.state.locale) }}
																		</v-chip>
																	<v-layout row wrap class="ma-0 pa-0">
																		<v-flex xs6 md3 class="pa-0 ma-0">
																			<!-- Verkoper of refund info -->
																			<span v-if="item.typeOfPayment === 'withdraw'">
																				<strong>{{ $t('payment.refund', $store.state.locale) }}</strong>
																				<div>
																					<v-btn
																						label
																						block
																						disabled
																						depressed
																						height="39px"
																						class="px-5 custom-button-style company-primary"
																						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																						:text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																						>
																						<v-icon left size="16">mdi-cash-refund</v-icon>
																						{{ $t('payment.return', $store.state.locale) }}
																					</v-btn>
																				</div>
																			</span>
																			<span v-else-if="item.type == 'product' || item.type == 'customRule'">
																				<strong>{{ $t('payment.seller', $store.state.locale) }}</strong>
																				<v-select
																					class="ma-0 pa-0"
																					outlined
																					:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																					:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																					background-color="background"
																					:items="resources"
																					item-value="id"
																					item-text="name"
																					hide-details
																					dense
																					v-model="item.resourceId"
																					@change="updateResourceProducts()"
																					>
																					<template v-slot:item="{ item }"">
																						<span :style="[{'color': item.present ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].text}, { 'fontWeight': item.present ? 600 : 400}]">{{ item.name }}</span>
																					</template>
																				</v-select>
																				
																			</span>
																			<span v-else>
																				<strong>{{ $t('payment.performedBy', $store.state.locale) }}</strong>
																				<br />
																				{{ subtreatmenStafmembers(item).names }}
																				<div v-if="subtreatmenStafmembers(item).missingNames">
																					<v-icon small color="error">mdi-alert</v-icon>
																					<span>
																						{{ subtreatmenStafmembers(item).missingNames }}
																						<span v-if="subtreatmenStafmembers(item).missingNames > 1">{{ $t('general.employees', $store.state.locale) }}</span>
																						<span v-else>{{ $t('general.employee', $store.state.locale) }}</span>
																						{{ $t('payment.missing', $store.state.locale) }}
																					</span>
																				</div>
																			</span>
																		</v-flex>
																		<v-flex xs2 md3 style="padding: 0 0 0 10px">
																			<!-- Normale prijs -->
																			<strong>{{ $t('payment.normal', $store.state.locale) }}</strong>
																			<v-text-field
																				dense
																				outlined
																				background-color="background"
																				prefix="€"
																				hide-details
																				disabled
																				readonly
																				:value="item.priceBruto.toFixed(2)"
																			></v-text-field>
																		</v-flex>
																		<v-flex xs2 md3 style="padding: 0 0 0 10px">
																			<!-- Prijs te betalen -->
																			<strong>{{ $t('general.price', $store.state.locale) }}</strong>
																			<!-- disabled
																				readonly -->
																		

																			<v-text-field
																			   v-money-input
																				dense
																				outlined
																				background-color="background"
																				prefix="€"
																				hide-details
																				:trim="false"
																				@input="manualInputDiscount($event, index, item.priceBruto)"
																			 
																				:value="item.newPriceTotal.toFixed(2)"
																			></v-text-field>
																		</v-flex>
																		<v-flex xs2 md3 style="padding: 0 0 0 10px">
																			<!-- Korting -->
																			<div v-if="item.priceBruto != item.newPriceTotal">
																				<strong>{{ $t('payment.discount', $store.state.locale) }}</strong>
																			
																				<v-text-field
																					dense
																					outlined
																					:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																					:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																					class="custom-textfield-style company-primary"
																					prefix="€"
																					hide-details
																					readonly
																					disabled
																					:value="(item.priceBruto - item.newPriceTotal).toFixed(2)"
																				></v-text-field>
																			</div>
																		</v-flex>
																	</v-layout>
																</td>
																<td style="width: 50px">
																	<v-btn icon @click="expand(true)" v-if="item.type == 'treatment' && !isExpanded">
																		<v-icon dark>mdi-menu-down</v-icon>
																	</v-btn>
																	<v-btn icon @click="expand(false)" v-if="item.type == 'treatment' && isExpanded">
																		<v-icon dark>mdi-menu-up</v-icon>
																	</v-btn>
																</td>
															</tr>
														</template>
														<template v-slot:expanded-item="{ headers, item }">
															<td :colspan="headers.length" style="padding: 0 !important">
																<v-card flat>
																	<v-card-text class="pa-0 ma-0">
																		<v-container
																			grid-list-md
																			class="pa-0 ma-0"
																			style="background-color: rgba(0, 0, 0, 0.03); border-bottom: 1px solid rgba(0, 0, 0, 0.12); padding: 0"
																			>
																			<p style="padding: 5px 15px; font-size: 14px; line-height: auto; font-weight: bold; margin: 0">
																				{{ $t('payment.selectTreatmentEmployees', $store.state.locale) }}
																			</p>
																		
																			<v-layout
																				row
																				wrap
																				class="ma-0"
																				style="padding: 5px 0"
																				v-for="(subtreatment, indexSub) in item.subTreatments"
																				:key="subtreatment + indexSub"
																				:class="{ white: indexSub % 2 === 0, 'light-grey': indexSub % 2 != 0 }"
																			>
																				<v-flex xs4>
																					<p class="pl-4" style="font-size: 14px; line-height: 48px; margin: 0">
																						{{ subtreatment.name[$store.state.locale.toLowerCase()] }}
																						<!-- indexSub
																						{{ indexSub }}
																						{{ checkDeleted(subtreatment.deleted) }} -->
																					</p>
																				</v-flex>
																				<v-flex xs4>
																					<v-select
																						:key="subtreatment + indexSub"
																						:items="resources"
																						:disabled="subtreatment.deleted == true || subtreatment.userAdded == false"
																						item-value="id"
																						item-text="name"
																						background-color="menu"
																						outlined
																						dense
																						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																						:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																						class="ma-0 pa-0"
																						hide-details
																						v-model="subtreatment.resourceId"
																						@change="changeResource(indexSub, subtreatment.resourceId)"
																						:label="$t('payment.employeeLabel', $store.state.locale)"
																						>
																						<template v-slot:item="{ item }"">
																							<span :style="[{'color': item.present ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].text}, { 'fontWeight': item.present ? 600 : 400}]">{{ item.name }}</span>
																						</template>
																					</v-select>
																				</v-flex>
																				<v-flex xs4></v-flex>
																			</v-layout>
																		</v-container>
																	</v-card-text>
																</v-card>
															</td>
														</template>
													</v-data-table>
												</div>
												<div v-else>
													<v-divider></v-divider>
													<p style="padding: 60px 30px; text-align: center" class="ma-0" v-html="$t('payment.receiptAddTreatmentsAndProducts', $store.state.locale)"></p>
												</div>

												<!-- Modifiers -->
												<div v-if="usedModifiers.length > 0">
													<v-progress-linear
														v-if="loadingDataTable"
														class="ma-0 pa-0"
														absolute
														:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														indeterminate
													></v-progress-linear>
												</div>

												<v-data-table
													v-if="usedModifiers.length > 0"
													:headers="paymentHeader"
													:items="usedModifiers"
													class="modiefier-datatable"
													hide-default-footer
													hide-default-header
												>
													<template v-slot:item="{ item, index }">
														<tr
															v-if="item.type !== 'membership'"
															style="display: table-row; vertical-align: inherit; border-color: inherit"
															class="payment-row-wrapper"
															:class="{ post: item.selected }"
															@click="selector(index, item.selected)"
														>
														
															<td style="width: 40px">
																<v-btn
																	:disabled="item.userAdded == false || item.modifierGroup == 'membershipModifier' || item.byPrePayment"
																	class="ma-0 pa-0"
																	style="float: left"
																	depressed
																	icon
																	@click="posDeleteModifier(item.id)"
																>
																	<v-icon size="20">mdi-delete</v-icon>
																</v-btn>
															</td>

															<td v-if="item.type == 'downPayment'">
																<h3 style="font-size: 16px">{{ item.name }} <span style="font-size: 14px">({{ item.timestamp.seconds | moment("D MMMM YYYY - H:mm") }}u)</span></h3>
							
																<span v-if="item.byPrePayment">{{ $t('payment.withValueOf', $store.state.locale) }} {{ item.amount.number }} {{ item.amount.currency }}</span>
																<span v-if="item.remainingAmount < 0">
																	{{ $t('payment.withRemainingAmount', $store.state.locale) }}: {{ item.remainingAmount.toFixed(2).slice(1) }} {{ item.amount.currency }}
																</span>
																<span v-if="item.remainingAmount > 0"><strong> {{ $t('payment.fullyPaid', $store.state.locale) }}</strong></span>

																<v-layout row wrap class="ma-0 pa-0">
																	<v-flex xs3>
																		<!-- OPTIONAL -->
																	</v-flex>
																</v-layout>
															</td>

															<!-- Pre Payment -->
															<td v-if="item.type == 'prePayment' && item.byPrePayment === true && item.prePaymentDiscount === false">
																
																<h3>{{ item.name }} {{ $t('general.at', $store.state.locale) }}: {{ item.timestamp.seconds | moment("DD, MMM YYYY HH:mm") }}</h3>
																<span v-if="item.byPrePayment">{{ $t('payment.withValueOf', $store.state.locale) }} {{ item.amount.number }} {{ item.amount.currency }}</span>
																<span v-if="item.remainingAmount < 0">
																	{{ $t('payment.withRemainingAmount', $store.state.locale) }}: {{ item.remainingAmount.toFixed(2).slice(1) }} {{ item.amount.currency }}
																</span>
																<span v-if="item.remainingAmount > 0"><strong>{{ $t('payment.fullyPaid', $store.state.locale) }}</strong></span>

																<v-layout row wrap class="ma-0 pa-0">
																	<v-flex xs3>
																		<!-- OPTIONAL -->
																	</v-flex>
																</v-layout>
															</td>

															<!-- Pre Payment Extra Discount -->
															<td v-if="item.type == 'prePayment' && item.byPrePayment === true && item.prePaymentDiscount === true">
																<v-icon style="float: left; height: 30px; margin: 12px 10px 0 0">mdi-arrow-expand-right</v-icon>

																<h3>{{ item.name }}</h3>
																<span>{{ $t('payment.prePaymentDiscount', $store.state.locale) }} {{ item.discount.number }} {{ item.discount.currency }}</span>

																<v-layout row wrap class="ma-0 pa-0">
																	<v-flex xs3>
																		<!-- OPTIONAL -->
																	</v-flex>
																</v-layout>
															</td>

															<!-- Normal -->
															<td v-if="item.type !== 'prePayment'">
																
																<h3 style="font-size: 16px">
																	<v-chip small label class="mx-2 mb-0 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark>
																		{{ item.name }}
																	</v-chip>
																	<span v-if="item.typeDiscount && item.typeDiscount.custom">
																		(
																		<span v-if="item.typeDiscount.discountType == 'amount'">&euro;</span>
																		{{ item.typeDiscount.discountValue }}
																		<span v-if="item.typeDiscount.discountType != 'amount'">%</span>
																		)
																	</span>
																</h3>
															</td>
															<td style="width: 40px">
																<v-chip
																	small
																	label
																	class="mx-2 mb-0 custom-chip-style"
																	v-if="item.type === 'membership'"
																	:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																	:text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																	dark
																	>
																	{{ $t('payment.membership', $store.state.locale) }}	
																</v-chip>
																<v-chip
																	small
																	label
																	class="mx-2 mb-0 custom-chip-style"
																	v-if="item.byMembership"
																	:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																	:text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																	dark
																	>
																	{{ $t('payment.membershipDiscountActive', $store.state.locale) }}	
																</v-chip>
															</td>
														</tr>
													</template>
												</v-data-table>
											</div>
										</v-tab-item>
										<!-- \ TAB PAY / -->

										<!-- / TAB HISTORY RECEIPTS \ -->
										<v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
											<v-card outlined>
												<div v-if="clientReceiptDataLoading" style="display: block; background-color: #fff; padding: 15px; height: 200px; position: relative">
													<div class="thrive-loader"></div>
												</div>

												<div v-else>
													<div v-if="historyReceipts.length == 0">
														<p style="padding: 60px 30px; text-align: center">
															{{ $t('payment.noHistoryOf', $store.state.locale) }}	
															<strong>{{ `${selectedClientData.name} ${selectedClientData.surname}` }}</strong>
														</p>
													</div>
													<div v-else>
														<v-layout class="mx-4 my-2 pa-0" d-flex>
															<!-- Afspraken -->
															<v-flex xs12 d-flex>
																<h3>{{ $t('payment.history', $store.state.locale) }}	 {{ `${selectedClientData.name} ${selectedClientData.surname}` }}</h3>
															</v-flex>
														</v-layout>

														<v-divider class="ma-0"></v-divider>

														<v-alert
															:value="feedbackHistory"
															class="ma-0 pa-2"
															style="border-radius: 0"
															icon="mdi-alert"
															dismissible
															dark
															:color="$themes[$store.state.companyTheme][$store.state.themeModus].warning"
															>
															{{ $t('payment.noRefundItemsAvailable', $store.state.locale) }}	
														</v-alert>

													

														<v-data-table :headers="historyHeader" :items="historyReceipts" :items-per-page="-1" class="modiefier-datatable dense-table mt-0" dense hide-default-footer>
															<template v-slot:item="{ item, index, isExpanded, expand }">
																<tr
																	:style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].text }"
																	:colspan="headers.length"
																	class="payment-row-wrapper"
																	:class="{ post: item.selected }"
																>
																	<td style="width: 30px" class="px-2" :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].text }">
																		<div v-if="item.paymentTableObj.receiptType == 'deposit'">
																			<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-check</v-icon>
																			{{ $t('payment.expence', $store.state.locale) }}	
																		</div>
																		<div v-else>
																			<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-cash-refund</v-icon>
																			{{ $t('payment.refund', $store.state.locale) }}	
																		</div>
																	</td>

																	<td>
																		<v-layout row wrap class="ma-0 pa-0">
																			<v-flex dark xs11 class="my-0 py-0">
																				<span>{{ item.created.seconds | moment("D MMMM YYYY - HH:mm") }}u</span>
																				<!-- TODO Dynamic Language -->
																			</v-flex>
																		</v-layout>
																	</td>
																	<td>
																		<v-layout row wrap class="ma-0 pa-0">
																			<v-flex xs11>
																				<span v-if="item.paymentTableObj.currency == 'EUR'">&euro;</span>
																				<span>{{ item.paymentTableObj.totalBruto.toFixed(2) }}</span>
																			</v-flex>
																		</v-layout>
																	</td>
																	<td>
																		<v-btn
																			v-if="$store.state.activeUserRoleNumber < 4"
																			block
																			x-small
																			outlined
																			:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																			:disabled="checkReturnedStatus(item)"
																			@click="posHistoryAdd(item.id, index, true)"
																			>
																			{{ $t('payment.fullRefund', $store.state.locale) }}	
																			<v-icon right small>mdi-cash-refund</v-icon>
																		</v-btn>
																	</td>
																	<td class="text-right">
																		<v-btn icon @click="expand(true)" v-if="!isExpanded">
																			<v-icon dark>mdi-menu-down</v-icon>
																		</v-btn>
																		<v-btn icon @click="expand(false)" v-if="isExpanded">
																			<v-icon dark>mdi-menu-up</v-icon>
																		</v-btn>
																	</td>
																</tr>
															</template>
															<template v-slot:expanded-item="{ headers, item }">
																<td :colspan="headers.length" style="padding: 0 !important">
																	<v-card flat>
																		<v-card-text class="pa-0 ma-0">
																			<v-container
																				class="pa-0 ma-0"
																				grid-list-md
																				style="padding: 0"
																				:style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardOption }"
																			>
																				<p style="padding: 5px 10px; font-size: 14px; line-height: auto; font-weight: bold; margin: 0">Samenvatting</p>
																				<v-divider></v-divider>

																				<v-layout
																					row
																					wrap
																					class="ma-0 pa-0"
																					v-for="(selectedItem, indexItem) in item.selectedItems"
																					:key="selectedItem + indexItem"
																					>
																					<v-flex xs4>
																						<p class="pl-2" style="font-size: 14px; margin: 0; line-height: 30px">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, selectedItem.name)}} </p>
																					</v-flex>
																					<v-flex xs2 style="line-height: 30px">
																						
																						<span v-if="selectedItem.type === 'product'">Product</span> <span v-if="selectedItem.internalUse === true"> ({{ $t('payment.internalUse', $store.state.locale) }}	)</span>
																						<span v-if="selectedItem.type === 'treatment'">{{ $t('general.treatment', $store.state.locale) }}	</span>
																					</v-flex>
																					<v-flex v-if="selectedItem.internalUse === false" xs2 style="line-height: 30px" >
																						<span v-if="selectedItem.typeOfPayment === 'withdraw'">-</span>
																						<span v-if="item.paymentTableObj.currency == 'EUR'"><strong>&euro;</strong></span>
																						<strong>{{ selectedItem.newPriceTotal.toFixed(2) }}</strong>
																					</v-flex>
																					<v-flex v-if="selectedItem.internalUse === true" xs2 style="line-height: 30px" >
																						<span v-if="item.paymentTableObj.currency == 'EUR'"><strong>&euro;</strong></span>
																						<strong>0</strong>
																					</v-flex>	

																					<v-flex xs3 class="pa-1">
																						<v-btn
																							v-if="$store.state.activeUserRoleNumber < 4 &&	validateHistoryItem(selectedItem.uniqueIdentifier, item) === false && selectedItem.typeOfPayment === 'deposit'"
																							block
																							small
																							dark
																							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																							@click="posHistoryAdd(item.id, indexItem, false)"
																							>
																							<span>{{ $t('payment.returnItem', $store.state.locale) }}</span>	
																							<v-icon v-show="selectedItem.internalUse === false" right small>mdi-cash-refund</v-icon>

																						</v-btn>
																						<v-btn
																							v-else
																							block
																							disabled
																							depressed
																							dark
																							small
																							:color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"
																							class="mr-1 mt-1 custom-button-style company-secondary"
																							>
																							{{ $t('payment.returned', $store.state.locale) }}	
																						</v-btn>
																					</v-flex>
																					<v-flex xs1></v-flex>
																					<v-flex xs12 class="ma-0 pa-0">
																						<v-divider></v-divider>
																					</v-flex>
																				</v-layout>
																			</v-container>
																		</v-card-text>
																	</v-card>
																</td>
															</template>
														</v-data-table>
													</div>
												</div>
											</v-card>
										</v-tab-item>
										<!-- \ TAB HISTORY RECEIPTS / -->

										<!-- / TAB FUTURE APPOINTMENTS \ -->
										<v-tab-item :value="2" :transition="tabTransition" :reverse-transition="tabTransition">
											<v-card outlined>
												<div v-if="clientLoading" style="display: block; background-color: #fff; padding: 15px; height: 200px; position: relative">
													<div class="thrive-loader"></div>
												</div>

												<div v-if="!clientLoading">
													<div v-if="selectedClientData.future && selectedClientData.future.length == 0">
														<p style="padding: 60px 30px; text-align: center" v-html="$t('payment.noFutureAppointments', $store.state.locale)"></p>
													</div>

													<div v-else>
														<v-layout class="mx-4 my-2 pa-0" d-flex>
															<!-- Afspraken -->
															<v-flex xs12 d-flex>
																<h3>{{ $t('payment.upcomingAppoinments', $store.state.locale) }} {{ `${selectedClientData.name} ${selectedClientData.surname}` }}</h3>
															</v-flex>
														</v-layout>

														<v-divider></v-divider>

														<v-data-table
															:style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].text }"
															class="mt-4 dense-table"
															dense
															:items-per-page="-1"
															:headers="clientFutureHeader"
															:items="selectedClientData.future"
															hide-default-footer
															>
															<template v-slot:item="{ item }">
																<tr class="payment-row-wrapper">
																	<td>{{ item.visit | moment("D MMMM YYYY") }} - {{ item.start | moment("H:mm") }}u</td>
																	<td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.treatmentTitle) }}</td>
																	<td>
																		
																		<span v-for="branch in $store.state.userBranches" :key="branch.id">
																			<v-chip v-if="branch.id == item.branchId" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style">{{ branch.name }}</v-chip>
																		</span>
																	</td>
																</tr>
															</template>
														</v-data-table>
													</div>
												</div>
											</v-card>
										</v-tab-item>
										<!-- \ TAB FUTURE APPOINTMENTS / -->

										<!-- / TAB SUBSCRIPTIONS \ -->
										<v-tab-item :value="3" :transition="tabTransition" :reverse-transition="tabTransition">
											<v-card outlined>
												<div v-if="loadMemberships" style="display: block; background-color: #fff; padding: 15px; height: 200px; position: relative">
													<div class="thrive-loader"></div>
												</div>

												<div v-else>
													<div v-if="userMemberships.length == 0">
														<p style="padding: 60px 30px; text-align: center" v-html="$t('payment.noClientMemberships', $store.state.locale)"></p>
													</div>

													<div v-else>
														<v-layout class="mx-4 my-2 pa-0" d-flex>
															<!-- Afspraken -->
															<v-flex xs12 d-flex>
																<h3>{{ $t('payment.membershipsOf', $store.state.locale) }} {{ `${selectedClientData.name} ${selectedClientData.surname}` }}</h3>
															</v-flex>
														</v-layout>

														<v-divider></v-divider>

														<v-data-table :headers="membershipHeader" :items="validatedByMembershipModule" hide-default-footer class="dense-table">
															<template v-slot:item="{ item }">
																<tr>
																	<td>
																		<strong>{{ item.name[userLanguage.toLowerCase()] }}</strong>
																	</td>
																	<td>
																		<v-chip
																			small
																			label
																			class="mx-2 mb-0 custom-chip-style"
																			v-if="item.peakStatus.peak === 'offPeak'"
																			color="#ff4c51"
																			text-color="#ff4c51"
																			dark
																		>

																			{{ $t('payment.outsidePeriod', $store.state.locale) }} 
																		</v-chip>
																		<span v-if="item.status === true || item.override == true">
																			<v-chip
																				small
																				label
																				class="mx-2 mb-0 custom-chip-style"
																				v-if="item.peakStatus.peak === 'peak'"
																				color="#56ca00"
																				text-color="#56ca00"
																				dark
																			>
																				{{ $t('payment.insidePeriod', $store.state.locale) }} 
																			</v-chip>
																		</span>
																		<v-chip
																			small
																			label
																			class="mx-2 mb-0 custom-chip-style"
																			v-if="item.override == true"
																			color="orange"
																			text-color="orange"
																			dark
																		>
																			{{ $t('payment.forced', $store.state.locale) }} 
																		</v-chip>
																		<v-chip
																			small
																			label
																			class="mx-2 mb-0 custom-chip-style"
																			v-if="item.status.code === '2A' || item.status.code === '2B'"
																			color="#ff4c51"
																			text-color="#ff4c51"
																			dark
																		>
																			{{ $t('payment.usageExeeded', $store.state.locale) }} 
																		</v-chip>
																	</td>
																	<td>
																		{{ item.startDate | moment("D-M-YYYY") }}
																	</td>
																	<td>{{ item.duration }} {{ durationSuffix(item) }}</td>
																	<td>
																		<v-chip
																			small
																			block
																			label
																			v-if="item.autoRenew"
																			color="#56ca00"
																			text-color="#56ca00"
																			light
																			class="mr-2 my-1 custom-chip-style"
																			>
																			{{ $t('payment.automaticallyRenewed', $store.state.locale) }} 
																		</v-chip>
																		<v-chip small block label v-else color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-0 custom-chip-style">
																			{{ $t('payment.stopsAfterPeriod', $store.state.locale) }} 
																		</v-chip>
																	</td>
																	<td>
																		<v-btn block small outlined class="my-2" @click="openMembershipInfo(item)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
																			{{ $t('payment.viewMembership', $store.state.locale) }} 
																		</v-btn>
																	</td>
																</tr>
															</template>
														</v-data-table>
													</div>
												</div>
											</v-card>
										</v-tab-item>
										<!-- \ TAB SUBSCRIPTIONS / -->

										<!-- / TAB DEPOSITS / PREPAYMENTS \ -->
										<v-tab-item :value="4" :transition="tabTransition" :reverse-transition="tabTransition">
											<v-card outlined>
												<div v-if="clientReceiptDataLoading === true" class="pa-5" style="display: block; height: 200px; position: relative">
													<div class="thrive-loader"></div>
												</div>

												<div v-else>
													<div v-if="prePayments.length === 0">
														<p style="padding: 60px 30px; text-align: center" v-html="$t('payment.noClientsDeposits', $store.state.locale)"></p>
													</div>

													<div v-else>
														<v-layout class="mx-4 my-2 pa-0">
															<v-flex xs12 d-flex>
																<h3 v-html="$t('payment.downPayments', $store.state.locale)"></h3>
																<!-- {{ `${selectedClientData.name} ${selectedClientData.surname}` }} -->
															</v-flex>
														</v-layout>

														<v-divider></v-divider>

														<v-data-table
															:style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].text }"
															:headers="prePaymentHeader"
															:items="clientPrePayments"
															hide-default-footer
															:items-per-page="-1"
															dense

                											class="dense-table"
														>
															<template v-slot:item="{ item, index }">
																<tr >
																	<td class="py-1">
																		{{ getPrepaymentType(item.type) }}
																	</td>
																	<td>{{ item.timestamp.seconds | moment("D MMMM YYYY - H:mm") }}u</td>
																	<td>
																		<span v-if="item.amount.currency == 'EUR'">&euro;</span>
																		{{ cashConvFilter( item.amount.number).toFixed(2).replace(".", ",") }}
																	</td>
																	<td>
																		{{ getPrepaymentStatus(item.paymentStatus, item.status).type.name }}
																	</td>
																	<td>
																		<div v-if="item.paymentStatus == 'paid' && (getPrepaymentStatus(item.paymentStatus, item.status).type.id == 'paidNotReturned' || getPrepaymentStatus(item.paymentStatus, item.status).type.id == 'remainingAmount')">
																			<v-btn
																				v-if="!usedModifiers.map((usedModifier) => usedModifier.id).includes(item.id)"
																				dark
																				x-small
																				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																				@click="addPrePaymentManual(item.id, index)"
																				>
																				{{ $t('payment.addPrepayment', $store.state.locale) }}
																				<v-icon>mdi-add</v-icon>
																			</v-btn>
																			<v-btn
																				v-if="usedModifiers.map((usedModifier) => usedModifier.id).includes(item.id)"
																				disabled
																				x-small
																				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
																				>
																				{{ $t('payment.prepaymentAdded', $store.state.locale) }}
																			</v-btn>
																		</div>
																	</td>
																</tr>
															</template>
														</v-data-table>
													</div>
												</div>
											</v-card>
										</v-tab-item>
										<!-- \ TAB DEPOSITS / PREPAYMENTS / -->
									</v-tabs-items>
								</v-card>
							</v-flex>

							<!--/ Products & Treatment Selector \ -->
							<v-flex xs12 sm4>
								<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
									<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-tab-header" :class="$store.state.companyTheme">
										<v-tabs :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="sidebarTab">
											<v-tab> {{ $t('general.products', $store.state.locale) }}</v-tab>
											<v-tab> {{ $t('general.treatments', $store.state.locale) }}</v-tab>
											<v-tab> {{ $t('payment.internalUse', $store.state.locale) }}</v-tab>
										</v-tabs>
									</v-card>

									<v-tabs-items v-model="sidebarTab">
										<v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
											<!-- Search Products for sale -->
											<v-card flat style="overflow: hidden" class="custom-algolia">
												<v-row v-if="refundBoolean" class="pa-2">
													<v-col sm="7">
														<h3 :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].secondary }">Product teruggave</h3>
													</v-col>
													<v-col sm="5" class="text-right">
														<v-btn small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="refundBoolean = false">
															<v-icon left small>mdi-close</v-icon>
															{{ $t('general.close', $store.state.locale) }}
														</v-btn>
													</v-col>
												</v-row>

												<h3 v-else class="ma-2">{{ $t('payment.addProduct', $store.state.locale) }}</h3>

												<v-divider></v-divider>

												<v-text-field
													class="ma-2"
													dense
													outlined
													:placeholder="$t('payment.searchProduct', $store.state.locale)"
													:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
													:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
													prepend-inner-icon="mdi-magnify"
													hide-details
													:append-icon="productSearch.length > 0 ? 'mdi-close' : ''"
													@click:append="productSearch = ''"
													v-model="productSearch"
													@input="resetProductFilter('all')"
												></v-text-field>

												<v-divider></v-divider>

												<div v-if="selectedSupplier" >
													<v-btn class="ma-2" small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="resetProductFilter()">
														{{ $t('general.back', $store.state.locale) }}
													</v-btn>
													<v-divider></v-divider>
												</div>

												<ais-instant-search v-if="productSearch && productSearch.length > 0" :search-client="algoSearch" index-name="thrive_products" class="pa-0 ma-0">
													<Insights/>
													<v-list class="pa-0 ma-0" :depressed="loadingDataTable" style="overflow-y: scroll" :style="{'maxHeight': paymentAddItemsHeight + 'px'}">
														<ais-hits class="pa-0 ma-0" :transform-items="transformHitsItems">
															<template slot="item" slot-scope="{ item }">
																<v-list-item dense class="pa-0 px-3 ma-0" @click="posInputAdd(item.objectID, 'product', false), algoliaItemClick(item, 'product')">
																	<v-list-item-content>
																		<v-list-item-title>
																			<ais-highlight attribute="name" :hit="item" highlightedTagName="mark" />
																		</v-list-item-title>
																		<v-list-item-subtitle>
																			<ais-highlight attribute="content" :hit="item" highlightedTagName="mark" />
																		</v-list-item-subtitle>
																	</v-list-item-content>
																	<v-list-item-action>
																		{{ calculateBrutoPrice(item.priceNetto, item.vat)}}
																	</v-list-item-action>
																	<v-list-item-action>
																		<v-icon v-if="refundBoolean === false" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-plus-circle</v-icon>
																		<v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-plus-circle</v-icon>
																	</v-list-item-action>
																</v-list-item>
															</template>
														</ais-hits>
													</v-list>
													<ais-state-results>
														<p class="text-center pa-2 pt-4 pb-1" slot-scope="{ state: { query }, results: { hits } }" v-show="!hits.length">
															<span v-if="query">
																<i>{{ $t("clients.noResultsFoundFor", $store.state.locale) }} {{ query }}</i>
															</span>
															<span v-else>
																<i>{{ $t("products.noProductsFound", $store.state.locale) }} {{ $store.state.activeBranch.name }}</i>
															</span>
														</p>
													</ais-state-results>
													<ais-configure :query="productSearch" :facetFilters="[`companyId:${$store.state.activeCompany.id}`, 'retail:true', 'active:true']" />
												</ais-instant-search>

												<div v-if="(!productSearch || productSearch.length == 0) && !selectedSupplier">
													<v-list class="pa-0 ma-0" dense >
														<div v-for="(supplier, index) in productSuppliers" :key="index">
															<v-list-item dense :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="selectSupplier(supplier.id)">
																<v-list-item-content>
																	<v-list-item-title style="font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ supplier.name }}</v-list-item-title>
																</v-list-item-content>
																<v-list-item-action class="pa-0 ma-0">
																	<v-btn icon>
																		<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-right</v-icon>
																	</v-btn>
																</v-list-item-action>
															</v-list-item>
															<v-divider></v-divider>
														</div>
													</v-list>
												</div>

												<div v-if="(!productSearch || productSearch.length == 0) && selectedSupplier && selectedSupplierGroups.length > 0 && !selectedSupplierGroup">
													<v-list class="pa-0 ma-0" dense >
														<div v-for="(group, index) in selectedSupplierGroups" :key="index">
															<v-list-item dense :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="selectedSupplierGroup = group.id; loadProductsBySelect(group.id)">
																<v-list-item-content>
																	<v-list-item-title style="font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ group.name }}</v-list-item-title>
																</v-list-item-content>
																<v-list-item-action class="pa-0 ma-0">
																	<v-btn icon>
																		<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-right</v-icon>
																	</v-btn>
																</v-list-item-action>
															</v-list-item>
															<v-divider></v-divider>
														</div>
													</v-list>
												</div>

		
												<div v-if="(!productSearch || productSearch.length == 0) && selectedSupplier && (selectedSupplierGroup || selectedSupplierGroups.length == 0)">
													<v-list class="pa-0 ma-0" v-if="productsFiltered && productsFiltered.length > 0" :depressed="loadingDataTable" style="overflow-y: scroll" :style="{'maxHeight': paymentAddItemsHeight + 'px'}">
														<div v-for="(product, index) in productsFiltered" :key="index">
															<v-list-item dense class="pa-0 px-3 ma-0" @click="posInputAdd(product.objectID, 'product', false)">
																<v-list-item-content>
																	<v-list-item-title>
																		{{ product.name }}
																	</v-list-item-title>
																	<v-list-item-subtitle>
																		{{ product.content }}
																	</v-list-item-subtitle>
																</v-list-item-content>
																<v-list-item-action>
																	{{ calculateBrutoPrice(product.priceNetto, product.vat)}}
																</v-list-item-action>
																<v-list-item-action>
																	<v-icon v-if="refundBoolean === false" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-plus-circle</v-icon>
																	<v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-plus-circle</v-icon>
																</v-list-item-action>
															</v-list-item>
															<v-divider></v-divider>
														</div>	
													</v-list>
													<div v-else class="pa-4 text-center">
														<v-avatar
															size="58"
															light
															class="ma-0 mr-2 custom-avatar-style" 
															rounded="lg"
															:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
															:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
															>
															<v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-spray-bottle</v-icon>
														</v-avatar>
														<p class="pa-4"> {{ $t('products.noProductsFoundPay', $store.state.locale) }}</p>
													</div> 
												</div>


											</v-card>
											<!-- End Search Products -->
										</v-tab-item>
										<v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
											<!-- Search Treatments -->
											<v-card flat style="overflow: hidden" class="custom-algolia">
												<v-row v-if="refundBoolean" class="pa-2">
													<v-col sm="7">
														<h3 :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].secondary }">Behandeling teruggave</h3>
													</v-col>
													<v-col sm="5" class="text-right">
														<v-btn small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="refundBoolean = false">
															<v-icon left small>mdi-close</v-icon>
															{{ $t('general.close', $store.state.locale) }}
														</v-btn>
													</v-col>
												</v-row>

												<h3 v-else class="ma-2">{{ $t('payment.addTreatment', $store.state.locale) }}</h3>

												<v-divider></v-divider>

												<v-text-field
													class="ma-2"
													dense
													outlined
													:placeholder="$t('payment.searchTreatment', $store.state.locale)"
													:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
													:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
													prepend-inner-icon="mdi-magnify"
													hide-details
													:append-icon="treatmentSearch.length > 0 ? 'mdi-close' : ''"
													@click:append="treatmentSearch = ''"
													v-model="treatmentSearch"
													@input="resetTreatmentFilter()"
												></v-text-field>

												<v-divider></v-divider>

												<div v-if="selectedTreatmentType" >
													<v-btn class="ma-2" small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="resetTreatmentFilter()">
														{{ $t('general.back', $store.state.locale) }}
													</v-btn>
													<v-divider></v-divider>
												</div>

								
												<ais-instant-search v-if="treatmentSearch && treatmentSearch.length > 0"  :search-client="algoSearch" index-name="thrive_treatments" class="pa-0 ma-0" ref="ais" >
													<Insights/>
													<v-list class="pa-0 ma-0" :depressed="loadingDataTable === true" style="overflow-y: scroll" :style="{'maxHeight': paymentAddItemsHeight + 'px'}">
														<ais-hits class="pa-0 ma-0">
															<template slot="item" slot-scope="{ item }">
																<v-list-item class="pa-0 px-3 ma-0" dense @click="posInputAdd(item.objectID, 'treatment', false), algoliaItemClick(item, 'treatment')">
																	<v-list-item-content>
																		<v-list-item-title> 
																			{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.treatmentTitle) }}
																		</v-list-item-title>
																		<v-list-item-subtitle>
																			<ais-highlight attribute="treatmentTime" :hit="item" highlightedTagName="mark" />min
																		</v-list-item-subtitle>
																	</v-list-item-content>
																	<v-list-item-action>
																		<i>{{ calculateBrutoPrice(item.treatmentPrice, item.vat)}}</i>
																	</v-list-item-action>
																	<v-list-item-action>
																		<v-icon v-if="refundBoolean === false" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-plus-circle</v-icon>
																		<v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-plus-circle</v-icon>
																	</v-list-item-action>
																</v-list-item>
															</template>
														</ais-hits>
													</v-list>
													<ais-state-results>
														<p class="text-center pa-2 pt-4 pb-1" slot-scope="{ state: { query }, results: { hits } }" v-show="!hits.length">
															<i>{{ $t("clients.noResultsFoundFor", $store.state.locale) }} {{ query }}</i>
														</p>
													</ais-state-results>
													<ais-configure  :query="treatmentSearch" :facetFilters="[`companyId:${$store.state.activeCompany.id}`, 'treatmentActive:true']" />
												</ais-instant-search>

												<div v-if="(!treatmentSearch || treatmentSearch.length == 0) && !selectedTreatmentType">
													<v-list class="pa-0 ma-0" dense >
														<div v-for="(treatmentType, index) in treatmentTypes" :key="index">
															<v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="loadTreatmentsByType(treatmentType.id)">
																<v-list-item-content>
																	<v-list-item-title v-if="filterType == 'customTreatmentType'" style="font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ treatmentType.name }}</v-list-item-title>
																	<v-list-item-title v-else style="font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatmentType.name) }}</v-list-item-title>

																</v-list-item-content>
																<v-list-item-action class="pa-0 ma-0">
																	<v-btn icon>
																		<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-right</v-icon>
																	</v-btn>
																</v-list-item-action>
															</v-list-item>
															<v-divider></v-divider>
														</div>
													</v-list>
												</div>

												<div v-if="(!treatmentSearch || treatmentSearch.length == 0) && selectedTreatmentType">
													<v-list v-if="treatmentsFiltered && treatmentsFiltered.length > 0" class="pa-0 ma-0" :depressed="loadingDataTable === true" style="overflow-y: scroll" :style="{'maxHeight': paymentAddItemsHeight + 'px'}">
														<div v-for="(treatment, index) in treatmentsFiltered" :key="index">
															
															<v-list-item class="pa-0 px-3 ma-0" dense @click="posInputAdd(treatment.objectID, 'treatment', false)">
																<v-list-item-content>
																	<v-list-item-title> 
																		{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatment.treatmentTitle) }}
																	</v-list-item-title>
																	<v-list-item-subtitle>
																		{{ treatment.treatmentTime }}min
																	</v-list-item-subtitle>
																</v-list-item-content>
																<v-list-item-action>
																	<i>{{ calculateBrutoPrice(treatment.treatmentPrice, treatment.vat)}}</i>
																</v-list-item-action>
																<v-list-item-action>
																	<v-icon v-if="refundBoolean === false" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-plus-circle</v-icon>
																	<v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-plus-circle</v-icon>
																</v-list-item-action>
															</v-list-item>
															<v-divider></v-divider>
														</div>
													</v-list>
													<div v-else class="pa-4 text-center">
														<v-avatar
															size="58"
															light
															class="ma-0 mr-2 custom-avatar-style" 
															rounded="lg"
															:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
															:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
															>
															<v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clipboard-text-outline</v-icon>
														</v-avatar>
														<p class="pa-4"> {{ $t('leadCampaign.noTreatmentFound', $store.state.locale) }}</p>
													</div> 
												</div>

											</v-card>

											<!-- End Search Treatments -->

											<v-card outlined style="display: none">
												<v-text-field
													style="margin: 14px 7px"
													dense
													outlined
													background-color="background"
													hide-details
													:label="$t('payment.searchTreatment', $store.state.locale)"
													prepend-inner-icon="mdi-magnify"
													clearable
													v-model="treatmentSearch"
												></v-text-field>
												<v-list style="padding: 0" dense>
													<div v-if="treatmentsByBranchFiltered.length > 0">
														<v-divider></v-divider>
														<div v-for="(treatment, index) in treatmentsByBranchFiltered" :key="treatment + index">
															<v-list-item @click="posInputAdd(treatment.id, treatment, false)">
																<v-list-item-content>
																	<v-list-item-title> {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatment.treatmentTitle) }} </v-list-item-title>
																	<v-list-item-subtitle></v-list-item-subtitle>
																</v-list-item-content>
																<v-list-item-action>
																	<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-plus-circle</v-icon>
																</v-list-item-action>
															</v-list-item>
															<v-divider v-if="index + 1 < treatmentsByBranch.length"></v-divider>
														</div>
													</div>
													<div v-else>
														<div style="text-align: center; padding: 15px 10px">Geen behandelingen gevonden.</div>
													</div>
												</v-list>
											</v-card>
										</v-tab-item>
										<v-tab-item :value="2" :transition="tabTransition" :reverse-transition="tabTransition">
											<!-- Search Products for extra use -->
											
											<v-card flat style="overflow: hidden" class="custom-algolia">
												<v-row v-if="refundBoolean" class="pa-2">
													<v-col sm="7">
														<h3 :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].secondary }">Product teruggave</h3>
													</v-col>
													<v-col sm="5" class="text-right">
														<v-btn small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="refundBoolean = false">
															<v-icon left small>mdi-close</v-icon>
															{{ $t('general.close', $store.state.locale) }}
														</v-btn>
													</v-col>
												</v-row>

												<h3 v-else class="ma-2">{{ $t('payment.addProduct', $store.state.locale) }}</h3>

												<v-divider></v-divider>

												<v-text-field
													class="ma-2"
													dense
													outlined
													:placeholder="$t('payment.searchProduct', $store.state.locale)"
													:background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
													:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
													prepend-inner-icon="mdi-magnify"
													hide-details
													:append-icon="productSearch.length > 0 ? 'mdi-close' : ''"
													@click:append="productSearch = ''"
													v-model="productSearch"
												></v-text-field>

												<v-divider></v-divider>

												<ais-instant-search :search-client="algoSearch" index-name="thrive_products" class="pa-0 ma-0">
													<v-list class="pa-0 ma-0" :depressed="loadingDataTable" style="overflow-y: scroll" :style="{'maxHeight': paymentAddItemsHeight + 'px'}">
														<ais-hits class="pa-0 ma-0" :transform-items="transformHitsItems">
															<template slot="item" slot-scope="{ item }">
																<v-list-item class="pa-0 px-3 ma-0" @click="posInputAdd(item.objectID, 'product', true)">
																	<!-- <v-list-item-avatar color="#f1f1f1">
																		<v-icon>mdi-account</v-icon>
																	</v-list-item-avatar> -->

																	<v-list-item-content>
																		<v-list-item-title>
																			<ais-highlight attribute="name" :hit="item" highlightedTagName="mark" />
																		</v-list-item-title>
																		<v-list-item-subtitle>
																			<ais-highlight attribute="price" :hit="item" highlightedTagName="mark" />
																		</v-list-item-subtitle>
																	</v-list-item-content>
																	<v-list-item-action>
																		<v-icon v-if="refundBoolean === false" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-plus-circle</v-icon>
																		<v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-plus-circle</v-icon>
																	</v-list-item-action>
																</v-list-item>
															</template>
														</ais-hits>
													</v-list>
													<ais-state-results>
														<p class="text-center pa-2 pt-4 pb-1" slot-scope="{ state: { query }, results: { hits } }" v-show="!hits.length">
															<span v-if="query">
																<i>{{ $t("clients.noResultsFoundFor", $store.state.locale) }} {{ query }}</i>
															</span>
															<span v-else>
																<i>{{ $t("products.noProductsFound", $store.state.locale) }} {{ $store.state.activeBranch.name }}</i>
															</span>
														</p>
													</ais-state-results>
													<ais-configure :query="productSearch" :facetFilters="[`companyId:${$store.state.activeCompany.id}`, 'active:true']" />
												</ais-instant-search>
											</v-card>
											<!-- End Search Products -->
										</v-tab-item>
									</v-tabs-items>
								</v-card>

								<div v-if="selectedItems.length > 0 && $store.state.activeUserRoleNumber == 0 && !$store.state.demoMode">
									<v-card
										flat
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
										class="theme-card theme-shadow mt-4"
										:class="$store.state.companyTheme"
										>
										<h4 class="pa-2">Developer data</h4>
										<v-divider></v-divider>
										<v-container grid-list-xs>
											<v-layout row wrap>
												<v-flex md6>
													<strong>Totaal (excl. BTW)</strong>
												</v-flex>
												<v-flex md6>
													<div style="text-align: right">&euro; {{ cashConvFilter(totalNetto).toFixed(2).replace(".", ",") }}</div>
												</v-flex>
											</v-layout>

											<!-- / All Vat \ -->
											<v-layout row wrap v-for="(totalVat, index) in totalVats" :key="index">
												<v-flex md6>
													<strong>BTW {{ totalVat.percentageVat }}%</strong>
												</v-flex>
												<v-flex md6>
													<div style="text-align: right">&euro; {{ cashConvFilter(totalVat.totalVat).toFixed(2).replace(".", ",") }}</div>
												</v-flex>
											</v-layout>
											<!-- \ All Vat / -->

											<v-layout row wrap>
												<v-flex md6><strong>Totaal BTW</strong></v-flex>
												<v-flex md6>
													<div style="text-align: right">&euro; {{ cashConvFilter(totalVAT).toFixed(2).replace(".", ",") }}</div>
												</v-flex>
											</v-layout>
										</v-container>
									</v-card>
								</div>

							</v-flex>
						</v-layout>
					</v-container>

					<!-- / Payment Bar \ -->
					<v-card  v-if="selectedItems.length > 0 && !loadingDataTable && paymentWrapperWidth > 0" flat class="theme-card theme-shadow payment-bar-card" :style="{'width': (paymentWrapperWidth  - 25) + 'px'}" :class="[{'theme-card': paymentBarFloat ,'theme-shadow': paymentBarFloat, 'scrolling' : paymentBarFloat}, companyTheme]">
						<v-container grid-list-md class="ma-0 pa-2" align-content-center>
							<v-layout row wrap>
							<v-flex xs3>
								<v-container grid-list-md class="ma-0 pa-0">
								<v-layout row wrap v-if="totalDiscountVAT > 0">
									<v-flex md6 class="pb-0" style="font-size: 12px">
									<strong>{{ $t('general.total', $store.state.locale) }}</strong>
									</v-flex>
									<v-flex md6 class="pb-0 text-right" style="font-size: 12px">
										&euro; {{cashConvFilter(totalBrutoWithoutDiscount).toFixed(2).replace(".", ",")}}
									</v-flex>
								</v-layout> 

								<v-layout row wrap v-if="totalDiscountVAT > 0">
									<v-flex md6 style="font-size: 12px" class="py-0"> {{ $t('payment.promotion', $store.state.locale) }}</v-flex>
									<v-flex md6 class="py-0 text-right" style="font-size: 12px">
									-  &euro;{{ cashConvFilter(totalDiscountVAT).toFixed(2).replace(".", ",")}}
									</v-flex>
								</v-layout>

								<v-layout row wrap :class="{ 'extraPadding' : totalDiscountVAT == 0}">
									<v-flex md6 class="pt-0" style="font-size: 14px">
									<strong> {{ $t('payment.totalAmount', $store.state.locale) }}</strong>     
									</v-flex>
									<v-flex md6 class="pt-0 text-right" style="font-size: 14px">
									<strong>&euro; {{cashConvFilter(totalBruto).toFixed(2).replace(".", ",")}}</strong>
									</v-flex>
								</v-layout>
								</v-container>
							</v-flex>
							<v-flex xs3 class="ma-0" v-if="checkWithdraw() == false && checkFree() == false">
								<v-btn
								:disabled="(checkResources() == false || checkDeposit() == false) == true"
								:dark="(checkResources() == false || checkDeposit() == false) == false"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"
								class="ma-0 pa-0"
								block
								@click="checkPaymentSettings('PIN')"
								>
								<v-icon left>mdi-credit-card</v-icon>
								<strong>{{ $t('payment.pin', $store.state.locale) }}</strong>
								</v-btn>
							</v-flex>
							<v-flex xs3 class="ma-0" v-if="checkWithdraw() == false && checkFree() == false">
								<v-btn
								:disabled="(checkResources() == false || checkDeposit() == false) == true"
								:dark="(checkResources() == false || checkDeposit() == false) == false" 
								class="ma-0 pa-0"
								block
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								@click="checkPaymentSettings('CASH')"
								>
								<v-icon left>mdi-cash</v-icon>
								<strong>{{ $t('payment.cash', $store.state.locale) }}</strong>
								</v-btn>
							</v-flex>
							<v-flex xs3 class="ma-0" v-if="checkWithdraw() == false && checkFree() == false">
								<v-btn
								:disabled="(checkResources() == false || checkDeposit() == false) == true"
								:dark="(checkResources() == false || checkDeposit() == false) == false"
								class="ma-0 pa-0"
								block
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
								@click="checkPaymentSettings('SPLIT')"
								>
								<v-icon left>mdi-call-split</v-icon>
								<strong>{{ $t('payment.splitPayment', $store.state.locale) }}</strong>
								</v-btn>
							</v-flex>
							<v-flex xs3 class="ma-0" v-if="selectedItems.length !== 0 && checkFree() === true">
								<v-btn
								:disabled="checkResources() == false"
								:dark="checkResources() == true"
								block
								class="ma-0 pa-0"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"
								@click="checkPaymentSettings('FREE')"
								>
								<v-icon left>mdi-check</v-icon>
								<strong>{{ $t('payment.complete', $store.state.locale) }}</strong>
								</v-btn>
							</v-flex>
							<v-flex xs3 class="ma-0" v-if="checkWithdraw() === true">
								<v-btn
								:disabled="checkResources() == false"
								:dark="checkResources() == true"
								block
								class="ma-0 pa-0"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								@click="checkPaymentSettings('REFUNDCASH')"
								>
								<v-icon left>mdi-cash-refund</v-icon>
								<strong>{{ $t('payment.refundCash', $store.state.locale) }}</strong>
								</v-btn>
							</v-flex>
							</v-layout>
						</v-container>
					</v-card>
					<!-- \ Payment Bar / -->

				</div>
			</div>
		</div>

		<!-- \ PAY / -->

		<!-- / HISTORY RECEIPTS \ -->
		<div v-if="activeTab == 'history'">
			<div v-if="receiptsLoading" style="padding: 200px">
			<div class="thrive-loader"></div>
			</div>

			<v-card v-else flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-4" :class="$store.state.companyTheme">
			<h3 class="pa-4">{{ $t('payment.history', $store.state.locale)}}</h3>
			<v-menu 
				v-model="historyPaymentsDateModal"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				min-width="auto"
				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
				>
				<template v-slot:activator="{ on, attrs }">
				<v-text-field
					outlined
					v-model="historyPaymentsDate"
					label="Datum"
					readonly
					dense
					hide-details
					prepend-inner-icon="mdi-calendar"
					class="pa-4 py-0 pb-3"
					style="max-width: 300px"
					:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
					v-bind="attrs"
					v-on="on"
				></v-text-field>
				</template>
				<v-date-picker
					no-title
					show-week
					locale-first-day-of-year="1" 
					first-day-of-week="1"
					v-model="historyPaymentsDate"
					@input="historyPaymentsDateModal = false, getHistoryPayments()"
					:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
					:max="now"
				></v-date-picker>
			</v-menu> 
			<v-divider></v-divider>
			<v-data-table
				v-if="historyPayments && historyPayments.length > 0"
				:headers="historyPaymentsHeader"
				:items-per-page="itemsPerPage"
				:items="historyPayments"
				hide-default-footer
				dense
				class="revenue-overview-table"
				:no-data-text="$t('reports.noDataFound', $store.state.locale)"
				>
				<template v-slot:item="{item, index}">
				<tr>
					<td>{{ item.created.seconds | moment("D MMMM YYYY")}} </td>
					<td>{{ item.created.seconds | moment("H:mm")}}u</td>
					<td>{{ item.clientName ? item.clientName : $t('payment.anonymous', $store.state.locale)}} </td>
					<td>{{ item.employeeName }}</td>
					<td>
						<span v-if="item.historyType == 'receipt'">
							{{ getPaymentMethode(item.paymentTableObj.payedBy) }}
						</span>
						<span v-else>
							{{ $t('general.cash', $store.state.locale) }} 
						</span>
					</td>
					<td v-if="item.historyType == 'receipt'" class="text-right">&euro; {{ item.paymentTableObj.totalBruto.toFixed(2) }}</td>
					<td v-else class="text-right">&euro; -{{ item.expense.toFixed(2) }}</td>
					<td><v-btn small icon @click="viewReceipt(index)"><v-icon size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-eye</v-icon></v-btn></td>
				</tr>
				</template>
			</v-data-table>
			</v-card>   

			<div v-if="!historyPayments || historyPayments.length == 0" class="text-center" style="padding: 100px;">
			<v-avatar 
				size="76"
				light
				class="ma-4 custom-avatar-style" 
				rounded="lg"
				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
				:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
				>
				<v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-plus</v-icon>
			</v-avatar>
			
			<div style="font-size: 18px;">{{ $t('payment.noPaymentsFound', $store.state.locale) }}</div>
			</div>

		</div>
		<!-- \ HISTORY RECEIPTS / -->

		<!-- / OPEN RECEIPTS \ -->
		<div v-if="activeTab == 'open'">
			<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-4" :class="$store.state.companyTheme">
			<h3 class="pa-4">{{ $t('payment.openReceipts', $store.state.locale) }}</h3>
			<v-divider></v-divider>
			<div class="text-center" style="padding: 100px;">
				<v-avatar 
				size="76"
				light
				class="ma-4 custom-avatar-style" 
				rounded="lg"
				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
				:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
				>
				<v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-clock</v-icon>
				</v-avatar>
				<div style="font-size: 18px;">{{ $t('payment.noOpenReceiptsFound', $store.state.locale) }}</div>
			</div>
			</v-card>
		</div>
		<!-- \ OPEN RECEIPTS / -->
		</div>

		<!-- / Anonymous client \ -->
		<v-dialog v-model="anonymousClient.dialog" scrollable max-width="400px" persistent height="300px">
			<v-card>
				<v-card-text class="pa-2 ma-0 mb-2 text-center">
					<v-avatar 
						size="76"
						light
						class="ma-4 custom-avatar-style" 
						rounded="lg"
						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
						:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
						>
						<v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-alert</v-icon>
					</v-avatar> 
					<h3 style="font-size: 20px;">{{ $t('payment.noClientSelected', $store.state.locale) }}</h3>
					<div style="font-size: 16px;">{{ $t('payment.noClientSelectedHint', $store.state.locale) }}</div>
				</v-card-text>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
								<v-btn outlined depressed color="tertiary" @click="anonymousClient.dialog = false">{{ $t('general.cancel', $store.state.locale) }}</v-btn>
							</v-flex>
							<v-flex xs12 md8 class="text-right">
								<v-btn
									dark
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									@click="injectBeforePay(anonymousClient.paymentType)"
									>
									{{ $t('general.continue', $store.state.locale) }}
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Anonymous client / -->

		<!-- / No Pin Devices for Payment \ -->
		<v-dialog v-model="noPaymentOptionsDialog" scrollable max-width="400px" persistent height="300px">
			<v-card>
				<v-card-text class="pa-2 ma-0 mb-2 text-center">
					<v-avatar 
						size="76"
						light
						class="ma-4 custom-avatar-style" 
						rounded="lg"
						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
						:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
						>
						<v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-credit-card-outline</v-icon>
					</v-avatar> 
					<div class="text-center px-4">
          				<h3 style="font-size: 20px;">{{ $t('payment.noPinDevices', $store.state.locale) }}</h3>
						<div style="font-size: 14px;">{{ $t('payment.noPinDevicesHint', $store.state.locale) }}</div>
					</div>
				</v-card-text>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md8 class="text-right">
								<v-btn
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									outlined depressed color="tertiary" 
									@click="noPaymentOptionsDialog = false"
									>
									{{ $t('general.close', $store.state.locale) }}
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Pin Devices for Payment / -->

		<!-- / Print and mail receipt  \ -->
		<v-dialog v-model="printAndMailDialog.dialog" scrollable max-width="600px" persistent height="300px">
			<v-card>
				<v-card-text class="pa-2 ma-0 mb-2">
					<div class="py-4 text-center"><h2>Bon</h2></div>
					<v-alert v-show="printerStateReason.printerStateReasons.some((reason) => reason.code == 3)" type="warning" prominent dense transition="fade-transition">
						{{ $t('payment.printerStatus', $store.state.locale) }}: {{ printerStateReason.printerStateReasons.map((reason) => reason.reason).toString() }}
					</v-alert>
					<v-container v-if="printAndMailDialog.loaderSettings">
						<v-row>
							<v-col align="center" class="ma-5 pa-5">
								<v-progress-circular
									indeterminate
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								></v-progress-circular>
							</v-col>
						</v-row>
					</v-container>

					<v-container grid-list-lg fluid class="ma-0 pa-0" v-else>
          				<v-layout row wrap  class="ma-0 pa-0 justify-center" v-if="!printAndMailDialog.busy">
            				<v-flex md3 d-flex v-for="(item, index) in printAndMailDialog.options" :key="index" class="justify-center">
								<v-card :disabled="item.value =='emailReceipt' && clientId == ''"  @click="rollOutReceipt(item.value)" :elevation="3" flat class="text-center pa-2 py-4 custom-card-style" :color="item.color ? item.color : $themes[$store.state.companyTheme][$store.state.themeModus].primary" :style="{'color': item.color ? item.color : $themes[$store.state.companyTheme][$store.state.themeModus].primary }" d-flex style="width:100%">
									<v-icon large :color="item.color ? item.color : $themes[$store.state.companyTheme][$store.state.themeModus].primary" >{{ item.icon }}</v-icon>
									<div class="pt-2" v-html="item.name[($store.state.locale).toLowerCase()]"></div>
								</v-card>
							</v-flex>
						</v-layout>
						<v-layout row wrap  class="ma-0 pa-0 justify-center" v-if="printAndMailDialog.busy">
							<v-flex md12 class="text-center">
								<v-progress-circular
									class="pa-4 pb-6"
									indeterminate
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
								></v-progress-circular>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- \ Print and mail receipt  / -->

		<!-- / Custom Disocunt \ -->
		<v-dialog v-model="customDiscount.modal" scrollable max-width="700px" height="700px" @click:outside="closeCustomDiscountDialog" content-class="custom-shape">
			<v-card>
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
					<h2>{{ $t('payment.addCustomDiscount', $store.state.locale) }}</h2>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeCustomDiscountDialog()">
						<v-icon dark color="white">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-alert v-if="customDiscount.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">
					{{ customDiscount.feedback }}
				</v-alert>

				<v-divider></v-divider>

				<v-card-text>
					<v-form ref="AddVatForm" lazy-validation>
						<v-container grid-list-md class="ma-0 pa-0">
							<v-layout row wrap>
								<v-flex md12>
									<v-text-field
										:label="$t('general.name', $store.state.locale)"
										v-model="customDiscount.name"
										required
										dense
										outlined
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
										class="mt-4"
									></v-text-field>
								</v-flex>

								<v-flex md6>
									<v-select
										class="mt-4"
										dense
										:items="typeDiscountTypes"
										item-text="name"
										item-value="id"
										:label="$t('payment.customDiscountType', $store.state.locale)"
										v-model="customDiscount.typeDiscount.discountType"
										outlined
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
									></v-select>
								</v-flex>

								<v-flex md6>
									<v-text-field
										class="mt-4"
										dense
										:label="discountInfo.label"
										v-model.number="customDiscount.typeDiscount.discountValue"
										:prefix="discountInfo.prefix"
										:suffix="discountInfo.suffix"
										hide-details
										type="number"
										outlined
										required
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									></v-text-field>
								</v-flex>

								<v-flex md12>
									<v-switch
										class="mt-4"
										:label="$t('payment.customDiscountLimit', $store.state.locale)"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										v-model="customDiscount.combinationLimit"
										hide-details
									></v-switch>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
								<v-btn outlined depressed color="tertiary" @click="closeCustomDiscountDialog()">Annuleren</v-btn>
							</v-flex>
							<v-flex xs12 md8 class="text-right">
								<v-btn
									dark
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									@click="addCustomDiscount()"
									>
									{{ $t('payment.addCustomDiscountButton', $store.state.locale) }}
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Custom Discount / -->

		<!-- / Expenses \ -->
		<v-dialog v-model="expensesDialog" scrollable max-width="700px" height="700px">
			<v-card>
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
					<h3>{{ $t('payment.addExpence', $store.state.locale) }}</h3>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeExpense()">
						<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-alert v-if="expenses.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">
					{{ expenses.feedback }}
					<v-btn color="error" @click="saveExpense()">Toch doorgaan</v-btn>
				</v-alert>

				<v-divider></v-divider>

				<v-card-text class="pa-0 ma-0">
					<v-form ref="addExpenseForm" lazy-validation>
						<v-container grid-list-md>
							<v-layout row wrap>
								<v-flex md12>
									<v-text-field
										:label="$t('general.name', $store.state.locale)"
										v-model="expenses.name"
										required
										dense
										outlined
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										class="mt-4"
									></v-text-field>
								</v-flex>

								<v-flex md6>
									<v-select
										class="mt-4"
										:items="expenses.typeExpenses"
										item-value="id"
										:label="$t('payment.expenceType', $store.state.locale)"
										v-model="expenses.chosenTypeExpenses"
										outlined
										dense
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
									>
										<template slot="item" slot-scope="data">
											{{ $t(`accountancyNumbersExpense.${data.item.id}`, $store.state.locale) }}
										</template>

										<template slot="selection" slot-scope="data">
											{{ $t(`accountancyNumbersExpense.${data.item.id}`, $store.state.locale) }}
										</template>
									</v-select>
								</v-flex>

								<!-- v-model.number="expenses.amountValue" -->
								<v-flex md6>
									<v-text-field
									   v-money-input
										class="mt-4"
										:label="$t('payment.expenceAmount', $store.state.locale)"
										:value="expenses.amountValue"
										prefix="€"
										@input="validateExpense"
										outlined
										dense
										required
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
									></v-text-field>
								</v-flex>

								<v-flex md6>
									<v-select
										class="mt-4"
										:items="vatItems"
										return-object
										:label="$t('general.vat', $store.state.locale)"
										v-model="expenses.chosenVat"
										outlined
										dense
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										hide-details
										>
										<template slot="item" slot-scope="data">
											{{ data.item.percentage }}% 
											{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, data.item.name )}} -
											{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, data.item.description )}}
										</template>

										<template slot="selection" slot-scope="data">{{ data.item.percentage }}%</template>
									</v-select>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
								<v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeExpense()">Annuleren</v-btn>
							</v-flex>
							<v-flex xs12 md8 class="text-right">
								<v-btn
									:dark="!beforeExpense"
									:disabled="beforeExpense"
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:loading="expenses.loading"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									@click="saveExpense()"
									>
									{{ $t('payment.addExpenceButton', $store.state.locale) }}
									<v-icon right dark>mdi-chevron-right</v-icon>
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Expenses / -->

		<!-- / Deposit \ -->
		<v-dialog v-model="depositDialog" scrollable max-width="700px" height="700px">
			<v-card>
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
					<h2> {{ $t('payment.addRegisterDeposit', $store.state.locale) }}</h2>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeDeposit()">
						<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-alert v-if="deposit.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">
					{{ deposit.feedback }}
				</v-alert>

				<v-divider></v-divider>

				<v-card-text class="pa-0 ma-0">
					<v-form ref="addDepositForm" lazy-validation>
						<v-container grid-list-md>
							<v-layout row wrap>
								<v-flex md12>
									<v-text-field
										:label="$t('general.name', $store.state.locale)"
										v-model="deposit.namePayInto"
										required
										dense
										outlined
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										class="mt-4"
									></v-text-field>
								</v-flex>

								<v-flex md6>
									<v-text-field
										class="mt-4"
										:label="$t('general.amount', $store.state.locale)"
										v-model.number="deposit.payInto"
										prefix="€"
										hide-details
										type="number"
										outlined
										dense
										required
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									></v-text-field>
								</v-flex>
								<v-flex md6>
									<v-text-field
										class="mt-4"
										:label="$t('payment.addRegisterDepositCurrent', $store.state.locale)"
										:value="Number(cashStatement).toFixed(2)"
										prefix="€"
										hint
										type="number"
										outlined
										dense
										disabled
										:rules="[cashStatement >= 0 || 'Er is kas te kort']"
										required
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									></v-text-field>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
								<v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeDeposit()">
									{{ $t("general.cancel", userLanguage) }}
								</v-btn>
							</v-flex>
							<v-flex xs12 md8 class="text-right">
								<v-btn
									:dark="!deposit.loading"
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:loading="deposit.loading"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									@click="saveDeposit()"
								>
									{{ $t('payment.addRegisterDepositButton', $store.state.locale) }}
									<v-icon right dark>mdi-chevron-right</v-icon>
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ End Deposit / -->

		<!-- / Pay Dialog \ -->
		<v-dialog v-model="payDialog" scrollable max-width="700px" height="700px">
			<v-card>
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
					<h2 v-if="splitPayment.splitActive == true">{{ $t('payment.splitCheckout', $store.state.locale) }}  {{ clientnameReceipt }}</h2>
					<h2 v-if="typePayment === 'CASH' || typePayment === 'PIN'">{{ $t('payment.checkout', $store.state.locale) }} {{ clientnameReceipt }}</h2>
					<h2 v-if="typePayment === 'REFUNDCASH'">{{ $t('payment.completeReturn', $store.state.locale) }} {{ clientnameReceipt }}</h2>

					<v-spacer></v-spacer>

					<v-btn icon dark @click="closePayDialog()">
						<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<!-- <v-alert v-if="customDiscount.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ customDiscount.feedback }}</v-alert> -->

				<v-divider></v-divider>

				<!-- REFUND CASH FEEDBACK -->
				<v-alert
					v-if="feedbackPayDialog.feedback && typePayment === 'REFUNDCASH'"
					class="ma-0 pa-2"
					style="border-radius: 0"
					icon="mdi-alert"
					dark
					:color="$themes[$store.state.companyTheme][$store.state.themeModus].warning"
					>
					{{ feedbackPayDialog.feedback }}
					<v-btn v-if="feedbackPayDialog.code === 100" color="error" @click="receiptToClientDialog()">{{ $t('payment.returnAnyway', $store.state.locale) }} </v-btn>
				</v-alert>

				
				<v-card-text class="pa-0 ma-0">
					<v-form ref="payForm" lazy-validation>
						<v-container grid-list-md>
							<v-layout row wrap>
								<v-flex v-if="splitPayment.splitActive == false" md12>
									<v-text-field
										v-if="typePayment !== 'REFUNDCASH'"
										:label="$t('payment.reveivePayment', $store.state.locale)"
										
										:value="formatToCurrency('nl-NL', 'EUR', totalBrutoTwoDigits)"
										required
										outlined
										dense
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
										class="mt-4"
										disabled
									></v-text-field>
								</v-flex>
								<v-flex v-if="splitPayment.splitActive == true" md12>
									<v-text-field
										v-money-input
										:label="$t('payment.reveivePaymentContant', $store.state.locale)"
										prefix="€"
										required
										:value="displayChange"
										dense
										outlined
										@input="changeSplitCash"
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
										class="mt-4"
									></v-text-field>
								</v-flex>

								<v-flex md12>
									<v-text-field
										v-if="splitPayment.splitActive == true"
									  :label="$t('payment.reveivePaymentPin', $store.state.locale)"
										dense
										:disabled="splitPayment.splitActive == true"
										:value="formatToCurrency('nl-NL', 'EUR', splitPayment.splitByCard)"
										outlined
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
										class="mt-4"
									></v-text-field>
								</v-flex>

								<v-flex md12>
									<v-text-field
										v-if="typePayment === 'PIN' || typePayment === 'CASH' && splitPayment.splitActive == false"
										v-money-input
										dense
										:label="$t('payment.receivedFromCustomer', $store.state.locale)"
										prefix="€"
										@input="changeFunc"
										:value="displayChange"
										outlined
										:rules="[() => changeCalculated > 0 && cashStatement < changeCalculated ? 'Insufficient funds' : '']"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
										class="mt-4"
									></v-text-field>
								</v-flex>

								<v-flex md12>
									<v-text-field
										dense
										v-if="typePayment === 'PIN' || typePayment === 'CASH' && splitPayment.splitActive == false"
										:value="formatToCurrency('nl-NL', 'EUR', changeCalculated)"
										:label="$t('payment.changeToCustomer', $store.state.locale)"
										placeholder="Wisselgeld"
										disabled
										outlined
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
										class="mt-4"
									></v-text-field>
								</v-flex>

								<v-flex md12>
									<v-select
										v-if="typePayment == 'PIN' || (splitPayment.splitActive == true && availablePayDevices.length > 0)"
										:items="availablePayDevices"
										item-text="name"
										dense
										item-value="id"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										v-model="paymentInfo.chosenPaymentDevice"
										:label="$t('payment.cardDevicePayment', $store.state.locale)"
										outlined
										:disabled="this.$store.state.activeCompany.paymentOptions.length == 1"
									></v-select>

									<!-- Refund -->

									<v-flex md12>
										<v-text-field
											v-if="typePayment === 'REFUNDCASH'"
											dense
											:label="$t('payment.returnPayment', $store.state.locale)"
											prefix="€"
											type="number"
											v-model.number="totalBrutoAbsolute"
											outlined
											hide-details
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											background-color="background"
											disabled
											class="mt-4"
										></v-text-field>
									</v-flex>

									<v-flex md12>
										<v-text-field
											v-if="typePayment === 'REFUNDCASH'"
											dense
											:label="$t('payment.changeReturnedToCustomer', $store.state.locale)"
											prefix="€"
											type="number"
											v-model.number="changeBrutoToClient"
											@input="changeBrutoToClientFunc()"
											outlined
											hide-details
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											background-color="background"
											class="mt-4"
										></v-text-field>
									</v-flex>

									<v-flex md12>
										<v-text-field
											v-if="typePayment === 'REFUNDCASH'"
											dense
											:label="$t('payment.toReceiveFromCustomer', $store.state.locale)"
											prefix="€"
											type="number"
											v-model.number="changeForRefund"
											outlined
											disabled
											hide-details
											:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											background-color="background"
											class="mt-4"
										></v-text-field>
									</v-flex>

									<!-- End Refund  -->
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
								<v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closePayDialog()">{{ $t('general.cancel', $store.state.locale) }}</v-btn>
							</v-flex>
							<v-flex v-if="splitPayment.splitActive == false" xs12 md8 class="text-right">
								<v-btn
									v-if="typePayment === 'CASH' || typePayment === 'PIN' || typePayment === 'SPLIT'"
									:disabled="(changeCalculated && changeCalculated <= 0) || change < totalBrutoTwoDigits || (changeCalculated > 0 && cashStatement < changeCalculated)"
									:dark="(!changeCalculated || changeCalculated >= 0) && change >= totalBrutoTwoDigits && ((cashStatement >= changeCalculated) || !changeCalculated)"
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									@click="payReceipt()"
									>
									{{ $t('payment.checkout', $store.state.locale) }}
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
								<v-btn
									v-if="typePayment === 'REFUNDCASH' && feedbackPayDialog.code === null"
									
									dark
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									@click="payReceipt()"
									>
									{{ $t('payment.finishReturn', $store.state.locale) }}
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</v-flex>
							<v-flex v-if="splitPayment.splitActive == true && splitPayment.splitByCash != 0 && splitPayment.splitByCard !== 0" xs12 md8 class="text-right">
								<v-btn
								   :disabled="changeCalculated > 0  && cashStatement < changeCalculated"
									dark
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									@click="payReceipt()"
									>
									{{ $t('payment.checkoutSplit', $store.state.locale) }}
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Pay Dialog / -->

		<!-- / Add Extra POS lines \ -->
		<v-dialog v-model="customDialog" scrollable max-width="700px" height="700px">
			<v-card>
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
					<h2>{{ $t('payment.addNewReceiptRow', $store.state.locale) }}</h2>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeCustomDialog()">
						<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<!-- <v-alert v-if="customDiscount.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ customDiscount.feedback }}</v-alert> -->
				<v-divider></v-divider>

				<v-card-text class="pa-0 ma-0">
					<v-form ref="payForm" lazy-validation>
						<v-container>
							<v-row>
								<v-col cols="6" sm="6" md="6">
									<v-text-field
										dense
										v-model="customRule.name"
										:label="$t('payment.addNewReceiptRowName', $store.state.locale)"
										outlined
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
									></v-text-field>
								</v-col>

								<v-cols cols="6" sm="6" md="6" >
									<v-btn-toggle class="mt-2" v-model="customRuleOption" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" background-color="background">
										<v-btn>Aankoop <v-icon>mdi-cash-plus</v-icon></v-btn>
										<v-btn>Retour <v-icon>mdi-cash-minus</v-icon></v-btn>
									</v-btn-toggle>
								</v-cols>

								<v-col cols="12" sm="12" md="12">
									<v-textarea label="Omschrijving" rows="3" outlined hide-details auto-grow v-model="customRule.description"></v-textarea>
								</v-col>

								<v-col cols="12" sm="6" md="6">
									<v-text-field
										dense
										v-model="customRule.priceBruto"
										:label="$t('payment.addNewReceiptPrice', $store.state.locale)"
										number
										outlined
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
									></v-text-field>
								</v-col>

								<v-col cols="12" sm="6" md="6">
									<v-select
										:items="vatItems"
										item-text="summary"
										dense
										item-value="percentage"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										v-model="customRule.vat"
										:label="$t('payment.addNewReceiptRowVat', $store.state.locale)"
										outlined
										hide-details
									></v-select>
								</v-col>

								<v-col cols="12" sm="6" md="6">
									<v-select
										:items="resources"
										item-text="name"
										dense
										hide-details
										item-value="id"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										v-model="customRule.resourceId"
										:label="$t('payment.addNewReceiptRowEmployee', $store.state.locale)"
										:hint="$t('payment.addNewReceiptRowEmployeeHint', $store.state.locale)"
										outlined
										>
										<template v-slot:item="{ item }"">
											<span :style="[{'color': item.present ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].text}, { 'fontWeight': item.present ? 600 : 400}]">{{ item.name }}</span>
										</template>
									</v-select>
								</v-col>

								<v-col cols="12" sm="6" md="6">
									<v-text-field
										dense
										v-model="customRule.amount"
										:label="$t('payment.addNewReceiptRowNumber', $store.state.locale)"
										number
										outlined
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
								<v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeCustomDialog()">Annuleren</v-btn>
							</v-flex>
							<v-flex xs12 md8 class="text-right">
								<v-btn
									:disabled="!customPaymenRuleValid"
									:depressed="!customPaymenRuleValid"
									:dark="customPaymenRuleValid"
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									@click="addCustomRule()"
									>
									{{ $t('payment.addNewReceiptRowButton', $store.state.locale) }}
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Add Extra POS lines / -->

		<!-- / Add Custom Korting \ -->
		<v-dialog v-model="scanDiscountDialog" scrollable max-width="700px" height="700px">
			<v-card>
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
					<h2>Korting toevoegen via Barcode</h2>

					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeDiscountDialog()">
						<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<!-- <v-alert v-if="customDiscount.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ customDiscount.feedback }}</v-alert> -->

				<v-divider></v-divider>

				<v-card-text class="pa-0 ma-0">
					<v-form ref="payForm" lazy-validation>
						<v-container>
							<v-row>
								<v-col cols="12" sm="12" md="12">
									<v-text-field
										dense
										v-model.number="scanDiscountDialogObject.barcode"
										label="Barcode Korting"
										prepend-inner-icon="mdi-barcode"
										outlined
										hide-details
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										background-color="background"
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
								<v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeDiscountDialog()">Annuleren</v-btn>
								<v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="validateBarcodeAndAdd()">toevoegen</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Add Custom Korting / -->

		<!-- / View Receipt or Expence \ --> 
		<v-dialog v-model="viewReceiptData.dialog" scrollable max-width="1000px" height="700px" @click:outside="closeViewReceipt()">
			<v-card v-if="viewReceiptData.data">
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
					<h3>{{ $t('payment.viewReceipt', $store.state.locale) }} {{ viewReceiptData.data.clientName }} </h3>

					<v-spacer></v-spacer>
					<v-btn outlined small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" class="mx-1" @click="viewReceipt(viewReceiptData.index - 1)" :disabled="viewReceiptData.index < 1" >
						<v-icon :dark="viewReceiptData.index > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-chevron-left</v-icon>
					</v-btn>
					<v-btn outlined small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" class="mx-1" @click="viewReceipt(viewReceiptData.index + 1)" :disabled="viewReceiptData.index == (historyPayments.length - 1)">
						<v-icon :dark="viewReceiptData.index < (historyPayments.length - 1)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-chevron-right</v-icon>
					</v-btn>
					<v-btn icon dark @click="closeViewReceipt()">
						<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<!-- {{ viewReceiptData.data.id }} -->
				<v-divider></v-divider>

				<v-card-text class="pa-0  ma-0" style="height: 650px">
					<div v-if="viewReceiptData.loading == false">
						<v-layout row wrap class="ma-0 pa-4">
							<v-flex md4 xs6 class="pr-3">
								<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
									<v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
										<v-list-item class="pa-0 pl-4 ma-0">
											<v-list-item-avatar
												size="56"
												light
												class="ma-4 custom-avatar-style" 
												rounded="lg"
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
												:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
												>
												<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-clock</v-icon>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title> 
													<h3>{{ $t('payment.viewReceiptDateTime', $store.state.locale) }}</h3>
												</v-list-item-title>
												<v-list-item-subtitle>{{ viewReceiptData.data.created.seconds | moment("D MMM YYYY - H:mm") }}u</v-list-item-subtitle>
											</v-list-item-content>	
										</v-list-item>
									</v-list>
								</v-card>
							</v-flex>
							<v-flex md4 xs6 class="px-3">
								<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
									<v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
										<v-list-item class="pa-0 pl-4 ma-0">
											<v-list-item-avatar
												size="56"
												light
												class="ma-4 custom-avatar-style" 
												rounded="lg"
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
												:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].secondary}" 
												>
												<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-account-cash</v-icon>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title>
													<h3>{{ $t('general.amount', $store.state.locale) }}</h3>
												</v-list-item-title>
												<v-list-item-subtitle>{{ formatNumber(viewReceiptData.data.total.newPriceTotalBrutoTotal) }}</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card>
							</v-flex>
							<v-flex md4 xs6 class="pl-3">
								<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
									<v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
										<v-list-item class="pa-0 pl-4 ma-0">
											<v-list-item-avatar
												size="56"
												light
												class="ma-4 custom-avatar-style" 
												rounded="lg"
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
												:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
												>
												<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-cash-fast</v-icon>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title>
													<h3>{{ $t('payment.paymentMethod', $store.state.locale) }}</h3>
												</v-list-item-title>
												<v-list-item-subtitle v-if="viewReceiptData.data.historyType == 'receipt'">{{ getPaymentMethode(viewReceiptData.data.paymentTableObj.payedBy) }}</v-list-item-subtitle>
												<v-list-item-subtitle v-else>{{ getPaymentMethode('CASH') }}</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card> 
							</v-flex>
						</v-layout>

						<v-divider></v-divider>

						<v-row class="ma-0 pa-0">
							<v-col v-if="viewReceiptData.receipt" cols="4" class="pl-0 pt-0 pb-0" :style="{backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader}">
								<div class="receipt-preview-wrapper ma-4" style="width: calc(100% - 20px)!important">
									<div class="receipt-preview-content">
										<div v-html="viewReceiptData.receipt"></div>
									</div>
								</div>
							</v-col>
							<v-col :cols="viewReceiptData.receipt ? 8 : 12" class="px-0" >
								<v-data-table
									v-if="viewReceiptData.data && viewReceiptData.data.selectedItems && viewReceiptData.data.historyType == 'receipt'"
									:headers="viewReceiptDataHeader"
									:items-per-page="itemsPerPage"
									:items="viewReceiptData.data.receiptItems"
									hide-default-footer
									dense
									class="revenue-overview-table"
									:no-data-text="$t('reports.noDataFound', $store.state.locale)"
									>
									<template v-slot:item="{item}">
										<tr>
											<td> 
												<span v-if="item.type != 'discount' && item.type != 'downPayment'">{{ getItemName(item.id, item.type) }}</span>
												<span v-else> -{{ item.description }}</span>
											</td>
											<td> {{ getItemType(item.type) }} </td>
											<td> <span v-if="item.percentageVAT">{{ item.percentageVAT }}%</span></td>
											<td> <span v-if="item.typeOfPayment">{{ $t(`payment.${item.typeOfPayment}`, $store.state.locale)}}</span></td>
											<!-- <td class="text-right">{{ item.priceNetto.toFixed(2) }}</td>
											<td class="text-right">{{ item.priceBruto.toFixed(2) }}</td> -->
											<!-- <td class="text-right">{{ formatNumber(item.newPriceTotalNetto) }}</td> -->
											<td class="text-right">{{ formatNumber(item.priceBruto) }}</td>
										</tr>
									</template>
									<template v-slot:body.append>
										<tr></tr>
									</template>
									<template v-slot:foot>
										<tr class="pb-2">
											<td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
											<td></td>
											<td></td>
											<td></td>
											<!-- <td class="text-right"><strong> {{ formatNumber(totalNetto) }}</strong></td> -->
											<td class="text-right"><strong> {{ formatNumber(viewReceiptData.data.total.newPriceTotalBrutoTotal) }}</strong></td>
										</tr>
									</template>
								</v-data-table>

								<v-data-table
									v-if="viewReceiptData.data.historyType == 'expense'"
									:headers="viewExpenseDataHeader"
									:items-per-page="itemsPerPage"
									hide-default-footer
									dense
									class="revenue-overview-table"
									>
									<template v-slot:body>
										<tbody>
											<tr>
												<td> {{ viewReceiptData.data.expenseName }}</td>
												<td> {{ getVAT(viewReceiptData.data.vatId) }}%</td>
												<td> {{ $t(`payment.expence`, $store.state.locale)}}  </td>
												<td class="text-right">{{ formatNumber(viewReceiptData.data.total.newPriceTotalBrutoTotal) }}</td>
											</tr>
											<tr></tr>
										</tbody>
									</template>
									<template v-slot:foot>
										<tr class="pb-2">
											<td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
											<td></td>
											<td></td>
											<td class="text-right"><strong> {{ formatNumber(viewReceiptData.data.total.newPriceTotalBrutoTotal) }}</strong></td>
										</tr>
									</template>
								</v-data-table>

								<v-divider></v-divider>

								<v-layout row wrap class="ma-0 pa-4" v-if="viewReceiptData.data.historyType == 'receipt' && !viewReceiptData.resendMessage">
									<v-flex v-if="viewReceiptData.data.historyType == 'receipt'" :class="[selectedPrinter && printerStatus ? 'xs6' : 'xs12', selectedPrinter && printerStatus ? 'text-right' : 'text-center']">
										<v-btn class="mr-2" :loading="viewReceiptData.resend" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" outlined small @click="resendReceipt(viewReceiptData.data, 'digital')">{{ $t(`payment.emailReceipt`, $store.state.locale) }}</v-btn>
									</v-flex>
									<v-flex class="text-left" v-if="selectedPrinter && printerStatus" :class="[viewReceiptData.data.historyType == 'receipt' ? 'xs6' : 'xs12']">
										<v-btn class="ml-2" :loading="viewReceiptData.resend" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" outlined small @click="resendReceipt(viewReceiptData.data, 'print')">{{ $t(`payment.rePrintReceipt`, $store.state.locale) }}</v-btn>
									</v-flex>
								</v-layout>

								<v-layout row wrap class="ma-0 pa-4" v-if="viewReceiptData.data.historyType == 'receipt' && viewReceiptData.resendMessage">
									<v-flex xs12 class="text-center">
										<i>{{viewReceiptData.resendMessage }}</i> <v-icon color="green" small>mdi-check-bold</v-icon> 
									</v-flex>
								</v-layout>

							</v-col>
						</v-row>
					</div>
					<div v-if="viewReceiptData.loading">
						<v-progress-circular
							style="position: absolute; top: 50%; left: 50%; margin: -18px 0 0 -18px"
							indeterminate
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						></v-progress-circular>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- \ View Receipt or Expence / --> 

		<!-- / Membership Information \ -->
		<v-dialog v-model="selectedMembership.dialog" fullscreen hide-overlay scrollable transition="dialog-bottom-transition" content-class="custom-shape">
			<v-card>
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].card }">
					<h2>{{ selectedMembership.info.name[userLanguage.toLowerCase()] }}</h2>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeMembershipInfo()">
						<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text class="pa-4 ma-0" style="position: relative" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }">
					<membership-information :selectedMembership="selectedMembership.info"></membership-information>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- \ Membership Information / -->

		<!-- / New Client Dialog \ -->
    	<add-client-form></add-client-form>
    	<!-- \ New Client Dialog / -->  

		<!-- / Customer info dialog \ -->
		<customer-information-dialog
			:selectedClientName="selectedClientName"
			:customerDialog="customerDialog"
			:activeBranch="$store.state.activeBranch.id"
			@customerDialogChange="customerDialog = $event; getSelectedClientData(selectedClientData.id);"
		></customer-information-dialog>
		<!-- \ Customer info dialog / -->
		
		<!-- / Merge Receipt Draft Dialog \ -->
		<v-dialog persistent v-model="mergeReceiptDialog.dialog" scrollable max-width="700px" height="700px">
			<v-card>
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
					<h3 >{{ $t('payment.selectReceipts', $store.state.locale) }}</h3>
					<v-spacer></v-spacer>
					<v-btn icon dark :disabled="mergeReceiptDialog.loading" @click="mergeReceiptDialog.dialog = false">
					<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" >mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-card-text>
					<v-select
					class="my-4 pa-0"
						v-model="mergeReceiptDialog.selectedReceipts"
						:items="localReceiptDrafts.filter(receipt => receipt.id !== selectedReceiptId)"
						item-text="clientname"
						item-value="id"
						:menu-props="{ maxHeight: '400' }"
						background-color="menu"  
						multiple
						chips
						small-chips
						:label="$t('payment.selectReceipts', $store.state.locale)"
						outlined
						return-object
						persistent-hint
						dense
						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						>
						<template v-slot:selection="{ item, index }">
							<v-chip v-if="index < 2 "class="custom-chip-style" small label :color="$themes[$store.state.companyTheme][$store.state.themeModus][item.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][item.color]"> 
								{{ item.clientname }} {{ getTotal(item.selectedItems) }} - ({{ item.selectedItems.length }} items) 
							</v-chip>
							<span v-if="index === 2" class="custom-chip-style" small label :color="$themes[$store.state.companyTheme][$store.state.themeModus][item.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][item.color]"> {{ mergeReceiptDialog.selectedReceipts.length - 2 }}+ more
							</span>
						</template>
						<template v-slot:item="{ item }">
							{{ item.clientname }} {{ getTotal(item.selectedItems) }} - ({{ item.selectedItems.length }} items) 
						</template>
					</v-select>
				</v-card-text>
				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">		
								<v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" text :loading="mergeReceiptDialog.loading" @click="mergeReceiptFunc()" v-if="mergeReceiptDialog.selectedReceipts.length > 0">{{ $t('payment.merge', $store.state.locale) }}</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Merge Receipt Draft Dialog / -->

	</div>
</template>
