<template>
  <v-dialog v-model="spellingData.dialog"  scrollable width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card class="dialog-round-wrapper" v-if="spellingData.dialog">
      <v-card-text class="ma-0 pt-4">
        <div class="text-center">
          <v-avatar 
            size="46"
            light
            class="ma-2 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alphabetical-variant</v-icon>
          </v-avatar>
          <h3 class="pb-2">{{  $t('general.spellingHelp', $store.state.locale) }}</h3>
          <v-chip class="mb-2 custom-chip-style" small :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" > {{ spellingData.email.email }}</v-chip>

          <!-- <p>{{ chosenEmployee.resourceName }} {{ $t('booking.noAutorisationText', $store.state.locale) }}</p> -->

          <div class="spelling-rows-wrapper mt-2">
            <v-row v-for="(letter, index) in spellingData.letters" :key="index" :class="[{'even-row': index % 2 }, {'spelling-special': letter.type == 'special'}]">
              <v-col cols="5" class="text-right character">{{ letter.character }} </v-col>
              <v-col cols="2" class="text-center">
                <span v-if="letter.type != 'special'" style="text-transform: lowercase"><i>{{ $t('general.from', $store.state.locale)}}</i></span> 
                <span v-else>-</span>
              </v-col>
              <v-col cols="5" class="text-left">{{ letter.word }}</v-col>
              </v-row>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 class="text-left">
              <v-btn outlined block depressed color="tertiary" @click="closeSpellingData()"> {{ $t('general.close', $store.state.locale) }} </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { bus } from "../../main";

  export default {
    name: "spelling-help-dialog",
    data() {
      return {
        spellingData: {
          dialog: false,
          email: null,
          letters: new Array()
        },
        phoneAlpabeth: {
          nl: {
            a: "Anna",
            b: "Bernard",
            c: "Cornelis",
            d: "Dirk",
            e: "Eduard",
            f: "Ferdinand",
            g: "Gerard",
            h: "Hendrik",
            i: "Izaak",
            j: "Jan",
            k: "Karel",
            l: "Lodewijk",
            m: "Marie",
            n: "Nico",
            o: "Otto",
            p: "Pieter",
            q: "Quinten",
            r: "Rudolf",
            s: "Simon",
            t: "Tinus",
            u: "Utrecht",
            v: "Victor",
            w: "Willem",
            x: "Xantippe",
            y: "Ypsilon",
            z: "Zaandam",
          },
          en: {
            a: "Arthur",
            b: "Benjamin",
            c: "Charles",
            d: "David",
            e: "Edward",
            f: "Frederick",
            g: "George",
            h: "Harry",
            i: "Isaac",
            j: "Jack",
            k: "King",
            l: "London",
            m: "Mary",
            n: "Nelly",
            o: "Oliver",
            p: "Peter",
            q: "Queen",
            r: "Robert",
            s: "Samuel",
            t: "Tommy",
            u: "Uncle",
            v: "Victor",
            w: "William",
            x: "X-ray",
            y: "Yellow",
            z: "Zebra",
          },
          de: {
            a: "Anton",
            b: "Berta",
            c: "Cäsar",
            d: "Dietrich",
            e: "Emil",
            f: "Friedrich",
            g: "Gustav",
            h: "Heinrich",
            i: "Ida",
            j: "Julius",
            k: "Kaufmann",
            l: "Ludwig",
            m: "Martha",
            n: "Nordpol",
            o: "Otto",
            p: "Paula",
            q: "Quelle",
            r: "Richard",
            s: "Siegfried",
            t: "Theodor",
            u: "Ulrich",
            v: "Victor",
            w: "Wilhelm",
            x: "Xanthippe",
            y: "Ypsilon",
            z: "Zacharias",
          }
        },
        specialCharacters: [
          {
            character: "@",
            word: this.$t('general.et', this.$store.state.locale)
          },
          {
            character: ".",
            word: this.$t('general.dot', this.$store.state.locale)
          },
          {
            character: "-",
            word: this.$t('general.dash', this.$store.state.locale)   
          },    
          {
            character: "_",
            word: this.$t('general.underscore', this.$store.state.locale)
          },
        ],
        numbers: [
          {
            character: "0",
            word: this.$t('general.zero', this.$store.state.locale)
          },
          {
            character: "1",
            word: this.$t('general.one', this.$store.state.locale)
          },
          {
            character: "2",
            word: this.$t('general.two', this.$store.state.locale)
          },
          {
            character: "3",
            word: this.$t('general.three', this.$store.state.locale)
          },
          {
            character: "4",
            word: this.$t('general.four', this.$store.state.locale)
          },
          {
            character: "5",
            word: this.$t('general.five', this.$store.state.locale)
          },
          {
            character: "6",
            word: this.$t('general.six', this.$store.state.locale)
          },
          {
            character: "7",
            word: this.$t('general.seven', this.$store.state.locale)
          },
          {
            character: "8",
            word: this.$t('general.eight', this.$store.state.locale)
          },
          {
            character: "9",
            word: this.$t('general.nine', this.$store.state.locale)
          }
        ]
      };
    },

    created(){
      bus.$on("spellingHelpModal", (email, type, source) => {
        if(type == 'open'){
          this.openSpellingData(email)
        }
        else{
          this.closeSpellingData();
        }
      });
    },

    methods: {
      closeSpellingData(){
        this.spellingData =  {
          dialog: false,
          email: null,
          letters: new Array()
        }
      },

      openSpellingData(email){
        this.spellingData.email = email;

        let emailArray = this.spellingData.email.email.split('');

        


        emailArray.forEach((character)=>{
          let locale = this.phoneAlpabeth[this.$store.state.nativeLanguage] ? this.$store.state.nativeLanguage : "en";
          let specialCharacter = this.phoneAlpabeth[locale][character.toLowerCase()] ? false : true;

          let spelling = {
            character: character,
            word: specialCharacter ? this.getSpecialCharacterWord(character) : this.phoneAlpabeth[locale][character.toLowerCase()],
            type: specialCharacter ? "special" : "normal"
            
          }
          this.spellingData.letters.push(spelling)
        })

        
        this.spellingData.dialog = true;
      },

      getSpecialCharacterWord(character){
        let specialCharacter = this.specialCharacters.find((item)=> {return item.character == character})

        if(specialCharacter == undefined){
          specialCharacter = this.numbers.find((item)=> {return item.character == character})
        }
        return specialCharacter != undefined ? specialCharacter.word : "-"
      }
    },
  };

  //   HOW TO USE THE GENERAL DELETE MODAL
  // 
  //   1. (Source.vue File) Collect data in function and send with bus.$emit
  // 
  //   showDeleteDialog(id, type, deleteFunction){                                                    --- EXAMPLE FUNCTION (find original in treatments.vue or products.vue)
  //     let deleteInformation = new Object();
  //     deleteInformation.id = id;                                                                   --- Specific Document ID to delete (optional)
  //     deleteInformation.emit = deleteFunction;                                                     --- What function to execute when delete is confirmed
  //
  //    if(this.selected.length > 1){                                                                 --- Set Modal Title and Info
  //       deleteInformation.title = this.$t('products.removeProducts', this.$store.state.locale);  
  //       deleteInformation.info = this.$t('products.removeProductsInfo', this.$store.state.locale);
  //    }
  //    else{
  //       deleteInformation.title = this.$t('products.removeProduct', this.$store.state.locale);
  //       deleteInformation.info = this.$t('products.removeProductInfo', this.$store.state.locale);
  //    }
  //
  //    bus.$emit('deletedModal', deleteInformation, 'open');                                         --- Send Data and Type 'open' to General Delete Modal
  //  }
  // 
  //   
  //   2. General Delete Modal is showing wit send data
  // 
  //
  //   3. Confirm Delete Action in General Delete Modal
  // 
  //   bus.$emit(this.deleteInformation.emit, this.deleteInformation.id);                            --- Send bus.$emit to Source.vue (with optional id)
  //
  //
  //   4. Listen to Bus Emit in Source.vue  File in created
  //
  //   created() {
  //     bus.$on("deleteProducts", (id) => {this.deleteProducts(id) });                              --- What function to execute when delete bus is emitted is confirmed?
  //   }
  //   
  //   5. Execute function in Source.vue file
  //
  //   async deleteProducts() {
  //       await new Promise((resolve)=>{
  //         let counter = 0; 
  //         this.selected.forEach(element => {
  //           db.collection("products")
  //           .doc(element.id)
  //           .delete()
  //           counter++
  //           if(counter == this.selected.length){
  //             resolve()
  //           }
  //         });
  //       })
  //       this.selected = new Array()
  //       this.loadProducts();
  //       bus.$emit('deletedModal', null, 'close');                                                 --- Send Type 'close' to close the General Delete Modal
  //   }
  //

</script>
