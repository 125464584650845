<template>
  <div v-if="!branchDataLoading">
    <v-container grid-list-md class=" ma-0 pa-0" fluid>
      <v-layout row wrap class="py-0">
        <!-- <v-flex md6 xs12>
          <h2>{{ $t('calenderSettings.calendarSettings', $store.state.locale) }}</h2>
        </v-flex> -->
        <v-flex xs12 class="text-right py-0">
            <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small :dark="changed" :loading="updateCalendarSettingsBusy" :disabled="!changed" :depressed="!changed" @click="updateSettings()">
              <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale)}} <v-icon right small>mdi-chevron-right</v-icon></div>
              <div  v-else>{{ $t('general.saved', $store.state.locale)}}   <v-icon right small>mdi-check</v-icon></div>  
            </v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container grid-list-md class="pa-0 pt-4 ma-0">
      <v-layout row wrap>
        <v-flex md6 xs12 class="pr-2">
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
            <div class="pa-3">
							<v-avatar
                size="36"
                light
                style="float: left"
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-month</v-icon>
              </v-avatar>
							<h3 class="pt-1 pb-4">{{ $t('calenderSettings.calendar', $store.state.locale) }}</h3>
							<v-divider></v-divider>

              <v-layout row wrap>
                <v-flex xs12>

                  <v-card flat class="pa-4 mt-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="$store.state.activeUserRoleNumber < 2">
                    <h4>Resource Cache gebruiken in de Calendar API</h4>
                    <v-switch
                      class="ma-0 mt-2 pa-0"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :label="$t('calenderSettings.useResourceCache', $store.state.locale)"
                      :hint="$t('calenderSettings.useResourceCacheHint', $store.state.locale)"
                      persistent-hint
                      v-model="activeBranchData.calendarSettings.useResourceCache"
                    ></v-switch>
                  </v-card>
                  
                  <v-switch
                    class="ma-0 my-4 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :label="$t('calenderSettings.useNotesInCalendar', $store.state.locale)"
                    v-model="activeBranchData.calendarSettings.showNotes"
                    hide-details
                  ></v-switch>
                  
                  <v-switch
                    class="ma-0 my-4 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :label="$t('calenderSettings.useEventsInCalendar', $store.state.locale)"
                    v-model="activeBranchData.calendarSettings.useEvents"
                    hide-details
                  ></v-switch>

                  <v-select
                    :items="bookingTreatmentFilters"
                    v-model="activeBranchData.calendarSettings.bookingTreatmentFilter"
                    :label="$t('calenderSettings.bookingTreatmentFilters', $store.state.locale)"
                    :item-text="item => $t(`${item.name}`, $store.state.locale)"
                    item-value="id"
                    prepend-inner-icon="mdi-sort"
                    required
                    dense
                    hide-details
                    outlined
                    class="mt-4"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                  ></v-select> 
                  
                  <v-select
                    :items="appointmentTimeIterations"
                    v-model="activeBranchData.calendarSettings.timeIteration"
                    :label="$t('calenderSettings.timeStepsCalendar', $store.state.locale)"
                    item-text="name"
                    item-value="time"
                    prepend-inner-icon="mdi-clock-outline"
                    required
                    dense
                    outlined
                    class="mt-4"
                    persistent-hint
                    :hint="$t('calenderSettings.timeStepsCalendarHint', $store.state.locale)"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                  ></v-select> 

                  <v-card flat class="pa-4 mt-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
                    <h4>{{ $t('calenderSettings.informClient', $store.state.locale) }}</h4>
                    <v-switch
                      class="ma-0 my-4 pa-0"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :label="$t('calenderSettings.showPopup', $store.state.locale)"
                      v-model="activeBranchData.calendarSettings.informClientCalendarModal"
                      hide-details
                    ></v-switch>
                    <v-select
                      v-if="!activeBranchData.calendarSettings.informClientCalendarModal"
                      :items="informClientOptions"
                      v-model="activeBranchData.calendarSettings.informClientCalendar"
                      :label="$t('calenderSettings.calenderEditAction', $store.state.locale)"
                      item-text="name"
                      item-value="value"
                      dense
                      prepend-inner-icon="mdi-email"
                      required
                      outlined
                      hide-details
                      class="mt-4"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-select>
                  </v-card>
                </v-flex>
              </v-layout>
            </div>
          </v-card>

          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-3" :class="$store.state.companyTheme">
            <div class="pa-3">
							<v-avatar
                size="36"
                light
                style="float: left"
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-format-color-fill</v-icon>
              </v-avatar>
							<h3 class="pt-1 pb-4">{{ $t('calenderSettings.calendarColors', $store.state.locale) }}</h3>
							<v-divider></v-divider>

              <v-layout row wrap>
                <v-flex xs12>
                  <h5 class="mt-3 mb-2">{{ $t('calenderSettings.treatmentColorWithPreference', $store.state.locale) }}</h5>
                  <swatches
                    v-model="activeBranchData.calendarSettings.colorWithPreference"
                    :swatches="treatmentColors"
                    :wrapper-style="wrapperStyle"
                    :swatch-style="swatchStyle"
                    swatch-size="36"
                    show-border
                    inline
                  ></swatches>
                  
                  <h5 class="mt-3 mb-2">{{ $t('calenderSettings.treatmentColorNoPreference', $store.state.locale) }}</h5>
                  <swatches
                    v-model="activeBranchData.calendarSettings.colorNoPreference"
                    :swatches="treatmentColorsNoPreference"
                    :wrapper-style="wrapperStyle"
                    :swatch-style="swatchStyle"
                    swatch-size="36"
                    show-border
                    inline
                  ></swatches>
                  
                  <h5 class="mt-3 mb-2">{{ $t('calenderSettings.timeColors', $store.state.locale) }}</h5>
                  <swatches
                    v-model="activeBranchData.calendarSettings.colorTime"
                    :swatches="timeColors"
                    :wrapper-style="wrapperStyle"
                    :swatch-style="swatchStyle"
                    swatch-size="36"
                    show-border
                    inline
                  ></swatches>

                </v-flex>
              </v-layout>
            </div>
          </v-card>
        </v-flex>

        <v-flex md6 xs12 class="pl-2">
           <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
            
            <div class="pa-3">
							<v-avatar
                size="36"
                light
                style="float: left"
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-cursor</v-icon>
              </v-avatar>

							<h3 class="pt-1 pb-4">{{ $t('calenderSettings.appointment', $store.state.locale) }}</h3>
							<v-divider></v-divider>

              <v-layout row wrap class="mt-4">
                <v-flex xs12>
                  <v-switch
                    class="ma-0 mb-4 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :label="$t('calenderSettings.planConsecutive', $store.state.locale)"
                    :hint="$t('calenderSettings.planConsecutiveHint', $store.state.locale)"
                    v-model="activeBranchData.calendarSettings.appointmentJoining"
                    persistent-hint
                  ></v-switch>
                  <v-select
                    :items="calendarTimeIterations"
                    v-model="activeBranchData.calendarSettings.timeIterationCalendar"
                    :label="$t('calenderSettings.calendarSteps', $store.state.locale)"
                    item-text="name"
                    item-value="time"
                    prepend-inner-icon="mdi-clock-outline"
                    required
                    dense
                    outlined
                    class="my-4"
                    hide-details
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  ></v-select>
                  <v-select
                    :items="cancelTimeLimitItems"
                    v-model="activeBranchData.calendarSettings.cancelTimeLimit"
                    :label="$t('calenderSettings.cancelPeriod', $store.state.locale)"
                    item-text="name"
                    item-value="value"
                    prepend-inner-icon="mdi-cancel"
                    required
                    dense
                    outlined
                    class="mt-4"
                    hide-details
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  ></v-select>
                  <v-select
                    :items="timeZones"
                    v-model="activeBranchData.calendarSettings.timezone"
                    item-text="name"
                    item-value="value"
                    prepend-inner-icon="mdi-earth"
                    required
                    dense
                    outlined
                    class="mt-4"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    persistent-hint
                    :label="$t('calenderSettings.timezone', $store.state.locale)"
                    :hint="$t('calenderSettings.timezoneHint', $store.state.locale)"
                  ></v-select>


                </v-flex>
              </v-layout>
            </div>
          </v-card>

          <v-card v-if="!$store.state.demoMode && $store.state.activeUserRoleNumber < 2" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme + ' mt-3'">
            <div class="pa-3">
							<v-avatar
                size="36"
                light
                style="float: left"
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-memory</v-icon>
              </v-avatar>

							<h3 class="pt-1 pb-4">{{ $t('calenderSettings.smartSwapping', $store.state.locale) }}</h3>
							<v-divider></v-divider>
              <p class="pt-4">{{ $t('calenderSettings.smartSwappingExplain', $store.state.locale) }}</p>
              <v-layout row wrap class="mt-4">
                <v-flex xs12>
                 <v-switch
                    class="ma-0  pa-0"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :label="$t('calenderSettings.smartSwappingSwitch', $store.state.locale)"
                    
                    v-model="activeBranchData.calendarSettings.resourceSwapping"
                    persistent-hint
                  ></v-switch>
                  <v-select
                    :items="smartShiftTimeLimitItems"
                    v-model="activeBranchData.calendarSettings.smartShiftTimeLimit"
                    :label="$t('calenderSettings.smartSwapTime', $store.state.locale)"
                    item-text="name"
                    item-value="value"
                    prepend-inner-icon="mdi-clock-outline"
                    required
                    dense
                    outlined
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  ></v-select>
                </v-flex>
              </v-layout>
            </div>
          </v-card>

          <v-card v-if="!$store.state.demoMode && $store.state.activeUserRoleNumber < 2"  flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme + ' mt-3'">
            <div class="pa-3">
							<v-avatar
                size="36"
                light
                style="float: left"
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-memory</v-icon>
              </v-avatar>

							<h3 class="pt-1 pb-4">{{ $t('calenderSettings.bsaa', $store.state.locale) }}</h3>
							<v-divider></v-divider>
              <p class="pt-4">{{ $t('calenderSettings.bsaaExplanation', $store.state.locale) }}</p>
              <v-layout row wrap class="mt-4">
                <v-flex xs12>
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(day, index) in daysOfWeek" :key="index">
                      <v-expansion-panel-header><div class="font-weight-medium">{{ $t('general.' + day, $store.state.locale) }} </div></v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-switch v-model="activeBranchData.calendarSettings.bsaaIntel.days[day].active" label="Active"></v-switch>
                        
                        <v-subheader> {{ $t('calenderSettings.bsaaRatioHint', $store.state.locale) }}</v-subheader>
                        <v-slider 
                          v-model="activeBranchData.calendarSettings.bsaaIntel.days[day].ratio" 
                          min="0" 
                          max="100"
                          thumb-label="always"
                          :thumb-size="24"                      
                        >
                        </v-slider>
                       
                        <v-subheader> {{ $t('calenderSettings.bsaaAggressiveHint', $store.state.locale) }}</v-subheader>
                        <v-slider 
                          v-model="activeBranchData.calendarSettings.bsaaIntel.days[day].aggressiveLevel" 
                          thumb-label="always"
                          :thumb-size="24" 
                          :color="colorAggressive(day)"
                          min="0" 
                          max="100">
                      </v-slider>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-layout>
            </div>
          </v-card>

        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>


<script>
import '@firebase/firestore';
import db from "../firebase/init";
import { bus } from "../main";
import Swatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css"

export default {
  components: {
    Swatches
  },

  name: "calendar-settings",
  data: () => ({
    activeBranchData: new Object(),
    activeBranchDataOriginal: new Object(),
    branchDataLoading: true,
    informClientOptions:[
      {
        name: "Altijd e-mail verzenden met wijzigingen",
        value: 'informClient'
      },
      {
        name: "Niets doen",
        value: 'doNothing'
      },
    ],
    treatmentColorsNoPreference: [
      "#cbf3f0",
      "#90caf9",
      "#BC8DD0",
      "#F39488",
      "#ffcb69",
      "#b2dbbf",
      "#b8e5ab"
    ],
    treatmentColors: [
      "#72D9C4",
      "#42a5f5",
      "#7b2cbf",
      "#ef233c",
      "#E89861",
      "#61C1AD",
      "#8ccc7a"
    ],
    timeColors: [
      "#222222", 
      "#555555", 
      "#7f7f7f", 
      "#cccccc"
    ],
    wrapperStyle: { paddingTop: "0", paddingLeft: "0" },
    swatchStyle: { marginBottom: "0" },

    smartShiftTimeLimitItems: [
      {
        name: "1 uur",
        value: 1
      },
      {
        name: "12 uur",
        value: 12
      },
      {
        name: "24 uur",
        value: 24
      },
      {
        name: "36 uur",
        value: 36
      },
      {
        name: "48 uur",
        value: 48
      },
    ],
    daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
     bsaaIntel: {
        thresholdPrice: 65,
        days: {
          Mon: { active: true, ratio: 75, aggressiveLevel: 60 },
          Tue: { active: true, ratio: 75, aggressiveLevel: 60 },
          Wed: { active: true, ratio: 75, aggressiveLevel: 60 },
          Thu: { active: true, ratio: 75, aggressiveLevel: 60 },
          Fri: { active: true, ratio: 75, aggressiveLevel: 60 },
          Sat: { active: true, ratio: 75, aggressiveLevel: 60 },
          Sun: { active: true, ratio: 75, aggressiveLevel: 60 }
        }
      },

    bookingTreatmentFilters: [
      {
        id: 'gender',
        name: 'general.gender'
      },
      {
        id: 'treatmentTypes',
        name: 'admin.treatmentTypes'
      },
      {
        id: 'customTreatmentTypes',
        name: 'treatments.headers.customTreatmentTypes'
      },
      {
        id: 'none',
        name: 'general.none'
      }
    ],

    cancelTimeLimitItems: [
      {
        name: "Geen",
        value: 0
      },
      {
        name: "12 uur",
        value: 12
      },
      {
        name: "24 uur",
        value: 24
      },
      {
        name: "36 uur",
        value: 36
      },
      {
        name: "48 uur",
        value: 48
      },
    ],
    appointmentTimeIterations: [
      {
        name: "15 minuten",
        time: 15
      },
      {
        name: "30 minuten",
        time: 30
      },
      {
        name: "45 minuten",
        time: 45
      },
      {
        name: "60 minuten",
        time: 60
      },
    ],
    timeZones: [
      {
        name: "Eniwetok (-12)",
        value: "Pacific/Wallis"
      },
      {
        name: "Midway Island (-11)",
        value: "Pacific/Midway"
      },
      {
        name: "Honolulue (-10)",
        value: "Pacific/Honolulue"
      },
      {
        name: "Alaska (-9)",
        value: "America/Nome"
      },
      {
        name: "Los Angeles (-8)",
        value: "America/Los_Angeles"
      },
      {
        name: "Phoenix (-7)",
        value: "America/Phoenix"
      },
      {
        name: "Chicago (-6)",
        value: "America/Chicago"
      },
      {
        name: "New York (-5)",
        value: "America/New_York"
      },
      {
        name: "Santiago (-4)",
        value: "America/Santiago"
      },
      {
        name: "Sao Paulo (-3)",
        value: "America/Sao_Paulo"
      },
      {
        name: "Mid-Atlantic (-2)",
        value: "America/Noronha"
      },
      {
        name: "Azores (-1)",
        value: "Atlantic/Azores"
      },
      {
        name: "London (GMT)",
        value: "Europe/London"
      },
      {
        name: "Amsterdam (GMT +1)",
        value: "Europe/Amsterdam"
      },
      {
        name: "Athens (GMT +2)",
        value: "Europe/Athens"
      },
      {
        name: "Moskow (GMT +3)",
        value: "Europe/Moscow"
      },
      {
        name: "Tehran (GMT +4)",
        value: "Asia/Tehran"
      },
      {
        name: "New Dehli (GMT +5)",
        value: "Asia/Thashkent"
      },
      {
        name: "Dhaka (GMT +6)",
        value: "Asia/Dhaka"
      },
      {
        name: "Bangkok (GMT +7)",
        value: "Asia/Bangkok"
      },
      {
        name: "Hong Kong (GMT +8)",
        value: "Asia/Hong_Kong"
      },
      {
        name: "Tokyo (GMT +9)",
        value: "Asia/Tokyo"
      },
      {
        name: "Sydney (GMT +10)",
        value: "Australia/Sydney"
      },
      {
        name: "Magadan (GMT +11)",
        value: "Asia/Magadan"
      },
      {
        name: "Auckland (GMT +12)",
        value: "Pacific/Auckland"
      },  
    ],
    calendarTimeIterations: [
      {
        name: "5 minuten",
        time: "00:05:00"
      },
      {
        name: "10 minuten",
        time: "00:10:00"
      },
      {
        name: "15 minuten",
        time: "00:15:00"
      },
      {
        name: "30 minuten",
        time: "00:30:00"
      },
    ],
    updateCalendarSettingsBusy: false,
  }),

  created() {
    bus.$on("loadBranchData", () => {
      this.loadBranchData();
    });
    this.loadBranchData();
  },


  methods: {
    loadBranchData(){
      this.branchDataLoading = true;
      this.activeBranchData = _.cloneDeep(this.$store.state.activeBranch);
      this.activeBranchDataOriginal = _.cloneDeep(this.activeBranchData);
      this.branchDataLoading = false;
    },
    colorAggressive(day){
    
      const level = this.activeBranchData.calendarSettings.bsaaIntel.days[day].aggressiveLevel;
      if (level < 24 ) return 'black';
      if(level <= 25) return 'blue'
      if(level <= 49) return 'green'
      if(level <= 74) return 'orange'
      if(level <= 99) return 'red'
      return 'red'; // Default color when level is exactly 100
    },

    async updateSettings() {
      this.updateCalendarSettingsBusy = true;

      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "updatingCalendarSettingsBranch",
        actionItem: this.activeBranchData.name,
        extra:[
          {
            name: "originalData",
            value: ""
          },
          {
            name: "widgetSettings",
            value: JSON.stringify(this.activeBranchDataOriginal.calendarSettings)
          },
          {
            name: "changedData",
            value: ""
          },
          {
            name: "widgetSettings",
            value: JSON.stringify(this.activeBranchData.calendarSettings)
          },
        ]
      };

    
        await db.collection("branches")
        .doc(this.activeBranchData.id)
        .set({
          calendarSettings: this.activeBranchData.calendarSettings,
        }, { merge: true })
        .then(() => {
          // Log Action
          db.collection("log").add({
            time: new Date(),
            logType: "error",
            software: String(platform.os),
            browser: `${platform.name} ${platform.version}`,
            user: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
            userType: this.$store.state.activeUserRole,
            company: this.$store.state.userCompany,
            actionType: "userAction",
            action: "updatingCalendarSettingsBranch",
            actionItem: this.activeBranchData.name,
            extra:[
              {
                name: "originalData",
                value: ""
              },
              {
                name: "widgetSettings",
                value: JSON.stringify(this.activeBranchDataOriginal.calendarSettings)
              },
              {
                name: "changedData",
                value: ""
              },
              {
                name: "widgetSettings",
                value: JSON.stringify(this.activeBranchData.calendarSettings)
              },
            ]
          });
        })
        .then(() => {
          this.activeBranchDataOriginal = _.cloneDeep(this.activeBranchData);
          this.updateCalendarSettingsBusy = false;
          this.$store.state.activeBranch = _.cloneDeep(this.activeBranchData); //Add new data to Vuex Store
        })
        .catch(error => {
          this.updateCalendarSettingsBusy = false;
          console.error("Error updating Calendar Branch Settings: ", error);
          db.collection("log").add({
            time: new Date(),
            logType: "error",
            software: String(platform.os),
            browser: `${platform.name} ${platform.version}`,
            user: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
            userType: this.$store.state.activeUserRole,
            company: this.$store.state.userCompany,
            actionType: "userAction",
            action: "errorUpdatingCalendarSettingsBranch",
            actionItem: this.activeBranchData.name,
            extra:[
              {
                name: "name",
                value: this.activeBranchData.name
              },
              {
                name: "companyId",
                value: this.$store.state.activeCompany.id
              },
              {
                name: "error",
                value: JSON.stringify(error)
              }
            ]
          });
        });
      }
  },

  computed: {
    changed(){
      if( _.isEqual(this.activeBranchData.calendarSettings, this.activeBranchDataOriginal.calendarSettings) == false){
        return true
      } else{
        return false
      }
    }
  
  }
};
</script>

