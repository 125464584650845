/**
 * Generates an SVG avatar based on a given name.
 * 
 * This function creates a unique, visually appealing avatar by generating a gradient
 * blurry mesh with random colors derived from the input name. The avatar is represented
 * as an SVG string, which can be directly used in HTML or further processed.
 * 
 * @param {string} name - The name used to generate the avatar. Different names will produce different avatars.
 * @returns {string} An SVG string representing the generated avatar.
 * 
 * @example
 * const avatarSvg = generateMeshAvatar("John Doe");
 * document.getElementById("avatar-container").innerHTML = avatarSvg;
 * 
 * @description
 * The generated SVG includes:
 * - A background color
 * - Two overlapping paths with different colors and transformations
 * - Gaussian blur effects applied to the paths
 * - A circular mask to create a round avatar
 * 
 * The visual characteristics (colors, paths, transformations, blur) are deterministically
 * generated based on the input name, ensuring consistency for the same name across multiple calls.
 */
export function generateMeshAvatar(name) {
  const colors = generateColors(name);
  const svgSize = 80;
  const meshData = generateMeshData(name);
  
  return `
    <svg viewBox="0 0 ${svgSize} ${svgSize}" fill="none" role="img" xmlns="http://www.w3.org/2000/svg" width="${svgSize}" height="${svgSize}">
      <mask id="mask" maskUnits="userSpaceOnUse" x="0" y="0" width="${svgSize}" height="${svgSize}">
        <rect width="${svgSize}" height="${svgSize}" rx="${svgSize * 2}" fill="#FFFFFF"></rect>
      </mask>
      <g mask="url(#mask)">
        <rect width="${svgSize}" height="${svgSize}" fill="${colors.background}"></rect>
        <path filter="url(#filter1)" d="${meshData.path1}" fill="${colors.primary}" transform="${meshData.transform1}"></path>
        <path filter="url(#filter2)" d="${meshData.path2}" fill="${colors.secondary}" transform="${meshData.transform2}" style="mix-blend-mode: overlay;"></path>
      </g>
      <defs>
        <filter id="filter1" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend><feGaussianBlur stdDeviation="${meshData.blur1}" result="effect1_foregroundBlur"></feGaussianBlur></filter>
        <filter id="filter2" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend><feGaussianBlur stdDeviation="${meshData.blur2}" result="effect2_foregroundBlur"></feGaussianBlur></filter>
      </defs>
    </svg>
  `.trim();
}

// Color range for the avatar
const colorRange = {
  color_one: "#FF6B35",    // Bright orange
  color_two: "#F7C59F",    // Light peach
  color_three: "#EFEFD0",  // Cream
  color_four: "#004E89",   // Deep blue
  color_five: "#1A659E",   // Medium blue
  color_six: "#7B2CBF",    // Deep purple
  color_seven: "#9D4EDD",  // Medium purple
  color_eight: "#C77DFF",  // Light purple
  color_nine: "#E0AAFF",   // Lavender
  color_ten: "#FF69B4"     // Hot pink
}

function generateColors(name) {
  const hash = hashCode(name);
  const colorKeys = Object.keys(colorRange);
  
  const backgroundIndex = Math.abs(hash) % colorKeys.length;
  const primaryIndex = (backgroundIndex + name.length) % colorKeys.length;
  const secondaryIndex = (backgroundIndex + name.split(' ').length) % colorKeys.length;
  
  const background = colorRange[colorKeys[backgroundIndex]];
  const primary = colorRange[colorKeys[primaryIndex]];
  const secondary = colorRange[colorKeys[secondaryIndex]];

  return { background, primary, secondary };
}

function generateMeshData(name) {
  const hash = hashCode(name);
  
  // Genereer willekeurige padgegevens
  const path1 = generateRandomPath(hash);
  const path2 = generateRandomPath(hash + 1);
  
  // Genereer willekeurige transformaties
  const transform1 = `translate(${rand(-10, 10, hash)} ${rand(-10, 10, hash + 2)}) rotate(${rand(-360, 360, hash + 4)} 40 40) scale(${rand(0.8, 1.5, hash + 6)})`;
  const transform2 = `translate(${rand(-10, 10, hash + 8)} ${rand(-10, 10, hash + 10)}) rotate(${rand(-360, 360, hash + 12)} 40 40) scale(${rand(0.8, 1.5, hash + 14)})`;
  
  // Genereer willekeurige blur-waardes
  const blur1 = rand(3, 15, hash + 16);
  const blur2 = rand(3, 15, hash + 18);
  
  return { path1, path2, transform1, transform2, blur1, blur2 };
}

function generateRandomPath(seed) {
  const points = [];
  const numPoints = rand(3, 7, seed); // Variabel aantal punten
  for (let i = 0; i < numPoints; i++) {
    points.push(`${rand(0, 80, seed + i * 2)} ${rand(0, 80, seed + i * 2 + 1)}`);
  }
  return `M${points[0]} ${points.slice(1).map(p => `L${p}`).join(' ')} Z`;
}

function rand(min, max, seed) {
  const x = Math.sin(seed) * 10000;
  return min + (x - Math.floor(x)) * (max - min);
}

function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}