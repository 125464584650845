<script>
import { bus } from "../main";
import "@firebase/firestore";
import db from "../firebase/init";

export default {
  components: {},
  name: "models",
  data: () => ({
    tab: 0,
    tabTransition: false,
    show: false,
    searchCompanies: "",
    activeComp: null,
    activeCompName: null,
    activeCompData: {},
    activeCompDataOriginal: null,
    companies: [],
    dataloaded: false,
    models: [{ 
      name: "Jacky",
      surname: "Oosterhuijs",
      rating: 4.5,
      reviews: [{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/JnFcmCYc/liveveland-261413062-957236008209109-2738033607861791572-n.jpg",
      description: "Ik sta open voor haar verandering. Ik hou van eten reizen en lekker uitgaan.",
      availableFor: [{id: "afgdsfas", name:"Coloring"}, {id: "afgdsfas", name:"Nails"}, {id: "afgdsfas", name:"PMU"}],
    },
    { 
      name: "Annika",
      surname: "Buhr",
      rating: 3.5,
      reviews: [{message:"superblij met Jacky"},{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/XvQkHCwP/annika-de-buhr-moderatorin-258467762-1330180917416416-8902884051460170199-n.jpg",
      description: "Volledig beschikbaar voor nieuwe coupe",
      availableFor: [{id: "afgdsfas", name:"Coloring"}, {id: "afgdsfas", name:"Nails"}, {id: "afgdsfas", name:"PMU"}],
    },
    { 
      name: "Jana",
      surname: "Beckman",
      rating: 3.8,
      reviews: [{message:"superblij met Jacky"},{message:"superblij met Jacky"},{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/pTrYgLCL/janabraeckman-262127760-128596756245190-1289631092374217762-n.jpg",
      description: "Ik wil graag model zitten. Niet teveel lengte ervan af. Ik sta open. ",
      availableFor: [ {id: "afgdsfas", name:"PMU"},{id: "afgdsfas", name:"Knippen"}],
    }
    ,
    { 
      name: "Lotte",
      surname: "van Raven",
      rating: 4.5,
      reviews: [{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/qgVshXTN/blue-poppy-girl-260321042-3191265674428075-3139125165385189486-n.jpg",
      description: "Ik sta open voor haar verandering. Ik hou van eten reizen en lekker uitgaan.",
      availableFor: [{id: "afgdsfas", name:"Coloring"}, {id: "afgdsfas", name:"Nails"}, {id: "afgdsfas", name:"PMU"}],
    },
    { 
      name: "Vianna",
      surname: "Oort",
      rating: 4.5,
      reviews: [{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/QCWgdYRL/valeinessi-185357758-1101926323551581-2932306603864283382-n.jpg",
      description: "Ik sta open voor haar verandering. Ik hou van eten reizen en lekker uitgaan.",
      availableFor: [ {id: "afgdsfas", name:"Nails"}, {id: "afgdsfas", name:"PMU"}],
    },
     { 
      name: "Natascha",
      surname: "Zilver",
      rating: 4.5,
      reviews: [{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/sDnp04gn/holy-knip-262908341-471726080958845-6121485490019633545-n.jpg",
      description: "Ik sta open voor haar verandering. Ik hou van eten reizen en lekker uitgaan.",
      availableFor: [{id: "afgdsfas", name:"Coloring"}, {id: "afgdsfas", name:"Nails"}, {id: "afgdsfas", name:"PMU"}],
    },
    { 
      name: "Anna",
      surname: "Dolveski",
      rating: 2.9,
      reviews: [{message:"superblij met Jacky"},{message:"superblij met Jacky"},{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/x1hL9jHM/kuaforoguzhankoc-263729496-633368644466605-5410848508754372186-n.jpg",
      description: "Ik sta open voor haar verandering. Ik hou van eten reizen en lekker uitgaan.",
      availableFor: [{id: "afgdsfas", name:"Coloring"}, {id: "afgdsfas", name:"Nails"}, {id: "afgdsfas", name:"PMU"}],
    },
    { 
      name: "Lisette",
      surname: "Heemskerk",
      rating: 3.9,
      reviews: [{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/7ZfgvJCq/model-mefit-165685579-3032348400421158-5583155097023660118-n.jpg",
      description: "Ik sta open voor haar verandering. Ik hou van eten reizen en lekker uitgaan.",
      availableFor: [{id: "afgdsfas", name:"Coloring"}, {id: "afgdsfas", name:"Nails"}, {id: "afgdsfas", name:"PMU"}],
    },
     { 
      name: "Samantha",
      surname: "Helvoorts",
      rating: 4.1,
      reviews: [{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/WbpGnHTF/natasjarommerts-24332278-123512695102295-1824147751064043520-n.jpg",
      description: "Ik sta open voor haar verandering. Ik hou van eten reizen en lekker uitgaan.",
      availableFor: [{id: "afgdsfas", name:"Coloring"},  {id: "afgdsfas", name:"PMU"}],
    },
    { 
      name: "Renske",
      surname: "Kroon",
      rating: 4.3,
      reviews: [{message:"superblij met Jacky"}],
      images: "https://i.postimg.cc/dQjRGYPc/renskekroom-119723730-791824691595859-6989391523846652098-n.jpg",
      description: "Ik sta open voor haar verandering. Ik hou van eten reizen en lekker uitgaan.",
      availableFor: [{id: "afgdsfas", name:"Coloring"}, {id: "afgdsfas", name:"Nails"}, {id: "afgdsfas", name:"PMU"}],
    }

    ],
  }),
  methods: {
    reserve() {
      console.log("Uitgenodigd")
    },
    
  },
  computed: {
    
  },
  filters: {
    lengthReviews: function(reviews) {
      return reviews.length;
    },
    availableFor: function(availableFor) {
      return availableFor.map((avail) => avail.name)
    }

  }
};
</script>

<template>
  <div>
    <section id="content">
      <div>
        <v-container>
          <v-row no-gutters>
            <v-col cols="12" sm="3" v-for="model,index in models"
                :key="model + index"> 
              <v-card
                
                class="ma-2"
                max-width="374"
              >
                <template slot="progress">
                  <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                  ></v-progress-linear>
                </template>

                <v-img 
                  height="250"
                  :src="model.images"
                ></v-img>

                <v-card-title>{{model.name + " " + model.surname}}</v-card-title>

                <v-card-text>
                  <v-row align="center" class="mx-0">
                    <v-rating
                      :value="model.rating"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="14"
                    ></v-rating>

                    <div class="grey--text ms-4">{{model.rating}} ( {{model.reviews | lengthReviews }} )</div>
                  </v-row>

                  <div class="my-4 text-subtitle-1"><span v-for="available,i in model.availableFor" :key="available + i"><span v-if="i != 0">, </span>{{available.name}}</span></div>

                  <div>
                    {{model.description}}
                  </div>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-title>Meer informatie</v-card-title>

                <v-card-text>
                  <div class="text-overline">
                  Voorkeur beschikbaarheid
                </div>
                 Maandag t/m Zaterdag. Voorkeur avond.
                </v-card-text>

                 
                 <v-card-text>
                   <div class="text-overline">
                  Huidige status
                </div>
                 Op dit moment een Bobline, met een donkere kleuring.
                </v-card-text>

                

                <v-card-actions>
                  <v-btn color="deep-purple" text @click="reserve">
                    Uitnodigen
                  </v-btn>

                    <v-btn color="deep-purple lighten-2" text @click="show = !show">
                    Extra
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="show">
                    <v-divider></v-divider>

                   <v-card-title>Meer informatie</v-card-title>

                <v-card-text>
                  <div class="text-overline">
                  Allergieën
                </div>
                 Niet tegen amonia.
                </v-card-text>

                 
                 <v-card-text>
                   <div class="text-overline">
                  Huidige status
                </div>
                 Op dit moment een Bobline, met een donkere kleuring.
                </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
            
          </v-row>
        </v-container>
      </div>
    </section>
  </div>
</template>
