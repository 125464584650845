<template>
  <div class="tab-component-wrapper">
    <div v-if="!loading">
      <div class="no-data-message" v-if="caoItems.length == 0">
        <p>{{ $t('admin.noCaos', $store.state.locale) }}</p>
        <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addCAO">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </div>

      <v-card v-else flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
        <v-container grid-list-md class=" ma-0 pa-3" fluid>
          <v-layout row wrap>
            <v-flex md4 xs12>
              <v-text-field
                class="mx-1"
                dense
                v-model="search"
                name="searchfield"
                :label="$t('general.search', $store.state.locale)"
                prepend-inner-icon="mdi-magnify"
                single-line
                hide-details
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex md8 xs12 class="text-right">
              <v-btn class="px-2" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addCAO()">
                <v-icon left>mdi-plus</v-icon> {{ $t('admin.newCao', $store.state.locale) }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="caoItems"
          :search="search"
          item-key="name"
          dense
          :itemsPerPage.sync="pages"
          hide-default-footer
          :no-results-text="`${$t('treatments.noResultsFoundFor', $store.state.locale)} ${search}`"
          class="dense-table"
          >
          <template v-slot:item="{item }">
            <tr>
              <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) }}</td>
              <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.description) }}</td>
              <td>
                <v-btn icon @click="editCAO(item)">
                  <v-icon small color="text">mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>

    <!-- / Add & Edit CAO Dialog \ -->
    <cao-form  @updateData="loadCaoItems()" ></cao-form>
    <!-- \ Add & Edit CAO Dialog / -->

  </div>
</template>

<script>
  import db from "../firebase/init";
  import CaoForm from "../components/modals/CaoForm.vue";
  import { bus } from "../main";

  export default {
    components: {
      "cao-form": CaoForm,
    },
    name: "CAO",
    data() {
      return {
        search: "",
        pages: -1,
        loading: true,
        caoItems: new Array(),
        headers: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "name"
          },
          {
            text: this.$t('general.description', this.$store.state.locale),
            align: "left",
            value: "percentage"
          },
          {
            text: "",
            align: "right",
            value: "edit",
            width: "60px"
          }
        ],
      }
    },

    created() {
      this.loadCaoItems();
    },

    methods: {
      addCAO() {
        bus.$emit("editCao",null, "new");
      },
      editCAO(item) {
        bus.$emit("editCao", item, "edit");
      },

      loadCaoItems() {
        this.loading = true;
        db.collection("cao")
        .get()
        .then(snap => {
          this.caoItems = new Array();
          snap.forEach(doc => {
            let cao = doc.data();
            cao.id = doc.id;
            this.caoItems.push(cao);
          });
        })
        .then(()=>{
          this.loading = false;
        })
        .catch(error=>{
          console.error("Error getting CAO's: ", error);
          this.loading = false;
        });
      }
    }
  };
</script>
