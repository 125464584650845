<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h3>{{ $t('branch.addNewBranch', $store.state.locale) }}</h3>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeAddBranchDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    
    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text class="ma-0 pa-0">
      <v-form ref="AddBranchForm" lazy-validation>
        <v-container grid-list-md >
          <v-layout row wrap>
            <v-flex xs12 md12>
              <v-text-field
                :label="$t('branch.nameBranch', $store.state.locale)"
                v-model="newBranch.name"
                prepend-inner-icon="mdi-domain"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                required
                dense
                outlined
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md9>
              <v-text-field
                :label="$t('general.address', $store.state.locale)"
                v-model="newBranch.address.address"
                prepend-inner-icon="mdi-map-marker"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                dense
                class="mt-4"
                required
                outlined
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field 
                :label="$t('general.number', $store.state.locale)"
                v-model="newBranch.address.nr" 
                required
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                dense
                class="mt-4"
                outlined
                ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3>
              <v-text-field
                :label="$t('general.zipcode', $store.state.locale)"
                v-model="newBranch.address.postalCode"
                prepend-inner-icon=""
                required
                dense
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                class="mt-4"
                outlined
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md9>
              <v-text-field 
                :label="$t('general.city', $store.state.locale)"
                v-model="newBranch.address.city" 
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                dense
                class="mt-4"
                required
                outlined
                ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-text-field
                :label="$t('general.province', $store.state.locale)"
                v-model="newBranch.address.province"
                prepend-inner-icon=""
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                dense
                class="mt-4"
                required
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field 
                :label="$t('general.country', $store.state.locale)"
                v-model="newBranch.address.country" 
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                dense
                class="mt-4"
                required
                outlined
                ></v-text-field>
            </v-flex>
          </v-layout>

          <div class="mt-2">
            <h5 class="pb-1">{{ $t('admin.logo',$store.state.locale)}}</h5>
            <v-row align="center" justify="center" class="ma-0 pa-0">
              <v-col xs3 style="max-width: 130px!important;" :style="{ backgroundColor: newBranch.logoUrl ? $themes[$store.state.companyTheme][$store.state.themeModus].background : 'none' }" class="ma-0 pa-0">
                <v-icon v-if="!newBranch.logoUrl" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" size="86" class="pa-0 ma-0">mdi-image</v-icon>
                <img v-else :src="newBranch.logoUrl" style="max-width: 100%; padding: 5px;"/>
              </v-col>
              <v-col xs9>
                <v-btn v-if="!newBranch.logoUrl" @click="openMediaLibrary()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark>{{ $t('library.selectFromLibrary', $store.state.locale) }}</v-btn>
                <div v-else>
                  <a @click="openMediaLibrary()">{{ $t('library.replace', $store.state.locale) }}</a>
                </div>
              </v-col>
            </v-row>
          </div>
  
          <v-text-field
            :label="$t('branch.senderName', $store.state.locale)"
            v-model="newBranch.senderName"
            required
            dense
            prepend-inner-icon="mdi-cellphone-basic"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            counter
            persistent-hint
            :hint="$t('branch.senderNameHint', $store.state.locale)"
            maxlength="11"
            outlined
            class="mt-4"
            @input="checkSenderName()"
          ></v-text-field>

          <v-switch 
            class="ma-0 pa-0 mt-4"
            :label="$t('general.active', $store.state.locale)"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            v-model="newBranch.active"
            hide-details 
          ></v-switch>

        </v-container>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
              @click="closeAddBranchDialog()"
              >
              {{ $t('general.cancel', $store.state.locale) }}
            </v-btn>
          </v-flex>
          <v-flex xs12 md8 class="text-right">
            <v-btn
              dark
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              @click="addBranch()"
              >
              {{ $t('branch.addBranch', $store.state.locale) }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

    <!-- / Image Library \ -->
    <v-dialog
      v-model="libraryDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('library.logoImages', $store.state.locale) }} </h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeLibraryDialog()">
            <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0" style="position: relative">
          <media-library @mediaFileSelect="setChosenImage"></media-library>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- \ Image Library / -->

  </v-card>
</template>


<script>
  import db from "../../firebase/init";
  import { bus } from "../../main";
  import MediaLibrary from "../../components/elements/MediaLibrary";
  import moment from "moment";
  import slugify from "slugify";


  export default {
    components:{
      "media-library" : MediaLibrary,
    },
    name: "add-branch-form",
    data() {
      return {
        feedback: null,
        libraryDialog: false,
        newBranch: {
          address: new Object(),
          email: new Object(),
          senderName: null,
          active: true,
          exception: new Array(),
          logoUrl: null,
          paymentOptions: new Array(),
          defaultDays: {
            Sun: false,
            Mon: false,
            Tue: false,
            Wed: false,
            Thu: false,
            Fri: false,
            Sat: false
          },
        },
      };
    },

    methods: {
      closeAddBranchDialog() {
        this.$emit("newBranchDialogChange", false);
      },

      openMediaLibrary(){
        this.libraryDialog = true;
        bus.$emit("openMediaLibrary");
      },

      setChosenImage(data){
        this.newBranch.logoUrl = data.url;
        this.closeLibraryDialog();
      },

      closeLibraryDialog(){
        this.libraryDialog = false;
        bus.$emit("clearMediaLibrarySelection");
      },

      checkSenderName(){
        if(this.newBranch.senderName){
          let data = _.cloneDeep(this.newBranch.senderName);
          let name = slugify(data, {
            replacement: "",
            remove: /[!@#$%^&*()"';:.,]/g,
            strict: true,
            lower: false,
          });

          this.newBranch.senderName = _.cloneDeep(name);
        }
      },

      async addBranch() {
        this.feedback = null;
        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "branchAdded",
          actionItem: this.newBranch.name,
          extra:[
            {
              name: "name",
              value: this.newBranch.name 
            },
            {
              name: "company",
              value: this.$store.state.activeCompany.name
            },
            {
              name: "address",
              value: this.newBranch.address
            },
            {
              name: "days",
              value: this.newBranch.defaultDays
            },
            {
              name: "exceptions",
              value: this.newBranch.exception
            },
            {
              name: "active",
              value: this.newBranch.active
            },
          ]
        };

        if (this.newBranch.name) {
          try {
            let docRef = await db.collection("branches")
            .add({
              // Basic Branch Settings
              name: this.newBranch.name,
              branchAddress: this.newBranch.address,
              companyId: this.$store.state.activeCompany.id,
              active: this.newBranch.active,
              exception: this.newBranch.exception,
              senderName: this.newBranch.senderName,
              logoUrl:  this.newBranch.logoUrl,
              defaultDays: this.newBranch.defaultDays,
              social: {
                facebook: false,
                facebookUrl: null,
                googleplus: false,
                googleplusUrl: null,
                instagram: false,
                instagramUrl: null,
                pinterest: false,
                pinterestUrl: null,
                twitter: false,
                twitterUrl: null
              },
              calendarSettings: {
                appointmentJoining: false,
                bsaaIntel: {
                  days: {
                    Mon: {},
                    Tue: {},
                    Wed: {},
                    Thu: {},
                    Fri: {},
                    Sat: {},
                    Sun: {}
                  },
                  thresholdPrice: null
                },
                cancelTimeLimit: 24,
                informClientCalendar: "informClient",
                informClientCalendarModal: true,
                timeIteration: 60,
                timeIterationCalendar: "00:10:00",
                timeItrationCalendar: "00:05:00",
                timezone: "Europe/Amsterdam",
                colorNoPreference: "",
                colorWithPreference: "",
                colortime: "",
                useEvents: false,
                showNotes: false,
                resourceSwapping: false,
              },
              created: moment().format("YYYY-MM-DD"),
              paymentOptions: [],
              posSettings: {
                locations: [{
                  name: null,
                  id: 'general',
                  deleted: false,
                  active: true
                }],
                receiptTemplate: null,
              },
              loyalty: {
                active: false
              },
              widgetSettings: {
                active: true,
                gtmTracking:{
                  useCompanyCode: true,
                  gtmCode: null
                },
                downPayments: {
                  active: false,
                  certainTreatments: false,
                  certainTreatmentsAmount: 150,
                  maximum: 500,
                  minimum: 10,
                  onlyNewClients: true,
                  percentage: 10
                },
                notification: {
                  show: false,
                  message: {
                    en: "",
                    nl: ""
                  },
                  messageTitle: {
                    en: "",
                    nl: ""
                  }
                },
                startDate: null,
                startDateActive: false,
                useCompanySettings: true
              }
            })

           await db.collection("branches")
            .doc(docRef.id)
            .collection("messages")
            .doc("settings")
            .set({
              appointmentCancelEmail: "general",
              appointmentCancelNoDownPaymentEmail: "general",
              appointmentCancelNoDownPaymentText: "general",
              appointmentCancelNoDownPaymentTextUse: false,
              appointmentCancelText: "general",
              appointmentCancelTextUse: false,
              appointmentChangesEmail: "general",
              appointmentChangesText: "general",
              appointmentChangesTextUse: false,
              appointmentConfirmDownPaymentEmail: "general",
              appointmentConfirmDownPaymentText: "general",
              appointmentConfirmDownPaymentTextUse: false,
              appointmentConfirmEmail: "general",
              appointmentConfirmText: "general",
              appointmentConfirmTextUse: false,
              appointmentReminderEmail: "general",
              appointmentReminderEmailTime: 24,
              appointmentReminderEmailUse: true,
              appointmentReminderText: "general",
              appointmentReminderTextTime: 1,
              appointmentReminderTextUse: false,
              downPaymentReminderEmail: "general",
              downPaymentReminderEmailUse: true,
              downPaymentReminderText: "general",
              downPaymentReminderTextUse: false,
              eventCancelEmail: "general",
              eventCancelText: "general",
              eventCancelTextUse: false,
              eventChangesEmail: "general",
              eventChangesText: "general",
              eventChangesTextUse: false,
              eventConfirmEmail: "general",
              eventConfirmText: "general",
              eventConfirmTextUse: false,
              eventReminderEmail: "general",
              eventReminderEmailTime: 24,
              eventReminderEmailUse: false,
              eventReminderText : "general",
              eventReminderTextTime: 1,
              eventReminderTextUse: false
            },{merge: true})
            .catch(error=>{
              console.error("Error saving Message Settings to Branch: ", error) 
            })

            this.$emit("updateData", docRef.id);
            this.closeAddBranchDialog();
            this.$refs.AddBranchForm.reset();
            await this.$createNewLog("info", logData);
            bus.$emit("companyUpdate");
          }
          catch (error) {
            this.feedback = this.$t("general.addError", this.$store.state.locale);
            console.error("Error adding branch: ", error);
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
          }
        }
        else {
          this.feedback = "Vul alle velden in om een nieuwe vestiging toe te voegen.";
        }
      }
    },
    computed: {
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        if(this.$store.state.companyTheme){
          return this.$store.state.companyTheme;
        }
        else{
          return "thrive"
        }
      }
    }
  };
</script>
