<template>
  <div class="menu-item-wrapper">
    <div :class="getEnviremontTopMenu()" style="-webkit-app-region: drag;">
      <div class="menu-top-icon" style="float: left; ">
        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText" v-if="!$store.state.menuOpen" @click="openMenu()">mdi-menu</v-icon>
        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText" v-if="$store.state.menuOpen" @click="closeMenu()">mdi-chevron-left</v-icon>
      </div>
      
      <div v-if="$store.state.menuOpen" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}" style="float: left; padding: 9px 0;">
        <div v-if="$store.state.activeCompany.id =='MCBLALIOcHHeEldQoE0a'" style="display: block; float: left; color: white; font-weight: bold; font-size: 24px; line-height: 30px; letter-spacing: 2px">MagnaMed</div>
        <div v-else>
          <div style="-webkit-app-region: drag;" class="thrive-logo-menu"></div>
          <div style="display: block; float: left; color: white; font-weight: bold; font-size: 24px; line-height: 30px; letter-spacing: 2px">
            THRIVE
            <span style="color: white; font-weight: bold; font-size: 11px; line-height: 30px; letter-spacing: 1px">{{ applicationVersion }}</span>
          </div>
        </div>
      </div>
    </div>
   
    <div v-show="!menuLoading" :class="getEnviremontNavScroll()" v-on:scroll="getWindowHeight()">
      <div class="pinned-items-wrapper" v-if="pinnedMenuItemsFiltered && pinnedMenuItemsFiltered.length > 0">
        <v-divider :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-divider>
        <div v-for="(item, index) in pinnedMenuItemsFiltered" :key="index">
          <div v-if="!item.permissionsRoles || (item.permissionsRoles && item.permissionsRoles >= $store.state.activeUserRoleNumber)  && (!item.packageLimit || $store.state.activeUserRoleNumber <= 1 || $store.state.companyPackages.list[item.packages]) ">
            <router-link :to="`/${item.url}`" custom v-slot="{ navigate, isActive, isExactActive }">
              <div @click="navigate" class="menu-group single small pinned" :class="[{'item-pinned': checkIfItemIsPinned(item.url)},{'menu-active':isActive},{ 'menu-exact-active':isExactActive}]">
                <div class="menu-group-summary">
                  
                  <v-tooltip dark  right>
                    <template v-slot:activator="{ on }">
                      <div class="menu-group-summary-icon" v-on=" !$store.state.menuOpen ? on: ''">
                      <v-icon dense small class="pa-0 pt-1" v-if="item.icon" :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText">{{ item.icon}}</v-icon>
                    </div>
                    </template>
                    <span  class="tooltip-text-white"> {{ item.name[$store.state.nativeLanguage] }}</span>
                  </v-tooltip>

                  <div class="menu-group-summary-text">
                    <p :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].menuText }" class="menu-group-name">{{ item.name[$store.state.nativeLanguage] }}</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <v-divider :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-divider>
      </div> 
      <div v-for="(item, index) in menuItems" :key="index">
        <div v-if="!item.permissionsRoles || (item.permissionsRoles && item.permissionsRoles >= $store.state.activeUserRoleNumber)  && (!item.packageLimit || $store.state.activeUserRoleNumber >= 1 || $store.state.companyPackages.list[item.packages]) ">
          <div v-if="item.type == 'group'">

            <div class="menu-group group" :class="{'collapsed' : item.collapsed}" v-if="(item.permissionsRoles && item.permissionsRoles < 2  && !$store.state.demoMode) || !item.permissionsRoles || item.permissionsRoles > 1 ">
              <router-link :to="`/${item.url}`" disabled custom v-slot="{isActive, isExactActive }" >
                <div @click.stop="collapse(index)" class="menu-group-summary" :class="[{'menu-active':isActive},{ 'menu-exact-active':isExactActive}]">
                  <v-tooltip dark  right :nudge-right="-20">
                    <template v-slot:activator="{ on }">
                      <div class="menu-group-summary-icon" v-on=" !$store.state.menuOpen ? on: ''">
                      <v-icon dense small class="pa-0 pt-1" v-if="item.icon" :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText">{{ item.icon}}</v-icon>
                    </div>
                    </template>
                    <span  class="tooltip-text-white"> {{ item.name[$store.state.nativeLanguage] }}</span>
                  </v-tooltip>

                  <!-- <div class="menu-group-summary-icon">
                    <v-icon dense class="pa-0 pt-1" v-if="item.icon" :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText">{{ item.icon}}</v-icon>
                  </div> -->
                  <div class="menu-group-summary-text">
                    <p :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].menuText }" class="menu-group-name">{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, item.name) }}</p>
                    <p :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].menuText }"  v-if="item.type == 'group'" class="menu-group-summary-items">{{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, item.description) }}</p>
                  </div>
                  <div class="menu-group-expand">
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText" v-if="menuSettingsCollapsed && $store.state.menuOpen">mdi-chevron-down</v-icon>
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText" v-else-if="!menuSettingsCollapsed && $store.state.menuOpen">mdi-chevron-down</v-icon>
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText" v-else>mdi-chevron-right</v-icon>
                  </div>
                </div>
              </router-link> 
              
              <div class="menu-group-items-wrapper">
                <div class="menu-group-items" :style="{backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].menuBackground}">
                  <div class="menu-mobile-header" v-if="!$store.state.menuOpen" :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].menuText }">{{ item.name[$store.state.nativeLanguage] }}</div>
                  <div v-for="(subItem, subIndex) in item.submenus" :key="subIndex">
                    <div v-if="!subItem.permissionsRoles || (subItem.permissionsRoles && subItem.permissionsRoles >= $store.state.activeUserRoleNumber) && (!item.packageLimit || $store.state.activeUserRoleNumber <= 1 || $store.state.companyPackages.list[item.package])">
                      <router-link :to="`/${subItem.url}`" custom v-slot="{ navigate, isActive, isExactActive }">
                        <div @click="navigate" @click.stop="checkForCollapse(index)" class="menu-group-item clear" :class="[{'item-pinned': checkIfItemIsPinned(subItem.url)}, {'menu-active':isActive},{ 'menu-exact-active':isExactActive}, {'first': subIndex == 0}]">
                          <div class="menu-group-item-summary">
                            <div class="menu-item-icon">
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText" small class="pa-0">{{subItem.icon }} </v-icon>
                            </div>
                            <div class="menu-item-text" :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].menuText }" >
                              {{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, subItem.name) }}
                            </div>
                            
                            <div v-if="!checkIfItemIsPinned(subItem.url)">
                              <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                <template v-slot:activator="{ on }">
                                  <div class="pin-item" v-on="on" @click.prevent="pinItem(subItem, item.weight)">
                                    <v-icon small class="pa-0 pt-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuBackground">mdi-pin</v-icon>
                                  </div> 
                                </template>
                                <span style="color: #fff; font-size: 11px">Pin item</span>
                              </v-tooltip>
                            </div>
                          </div>

                          <div v-if="checkIfItemIsPinned(subItem.url)">
                            <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                              <template v-slot:activator="{ on }">
                                <div class="unpin-item" v-on="on" @click.prevent="unpinItem(subItem)">
                                  <v-icon small class="pa-0 pt-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText">mdi-pin</v-icon>
                                </div> 
                              </template>
                              <span style="color: #fff; font-size: 11px">Unpin item</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div v-if="(!item.permissionsRoles || (item.permissionsRoles && item.permissionsRoles >= $store.state.activeUserRoleNumber)) && (!item.packageLimit || $store.state.activeUserRoleNumber <= 1 || $store.state.companyPackages.list[item.packages]) ">
              <router-link :to="`/${item.url}`" custom v-slot="{ navigate, isActive, isExactActive }" v-if="(item.permissionsRoles && item.permissionsRoles < 2  && !$store.state.demoMode) || !item.permissionsRoles || item.permissionsRoles > 1">
                <div @click="navigate" class="menu-group single small" :class="[{'item-pinned': checkIfItemIsPinned(item.url)},{'menu-active':isActive},{ 'menu-exact-active':isExactActive}]">
                  <div class="menu-group-summary">

                    <v-tooltip dark  right>
                      <template v-slot:activator="{ on }">
                        <div class="menu-group-summary-icon" v-on=" !$store.state.menuOpen ? on: ''">
                        <v-icon dense small class="pa-0 pt-1" v-if="item.icon" :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText">{{ item.icon}}</v-icon>
                      </div>
                      </template>
                      <span  class="tooltip-text-white"> {{ item.name[$store.state.nativeLanguage] }}</span>
                    </v-tooltip>

                    <!-- <div class="menu-group-summary-icon">
                      <v-icon dense class="pa-0 pt-1" v-if="item.icon" :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText">{{ item.icon}}</v-icon>
                    </div> -->
                    <div class="menu-group-summary-text">
                      <p :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].menuText }" class="menu-group-name">{{ item.name[$store.state.nativeLanguage] }}</p>
                    </div>
                  </div>
                  <div v-if="!checkIfItemIsPinned(item.url)">
                    <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                      <template v-slot:activator="{ on }">
                        <div class="pin-item" v-on="on" @click.prevent="pinItem(item, null)">
                          <v-icon small class="pa-0 pt-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuBackground">mdi-pin</v-icon>
                        </div> 
                      </template>
                      <span style="color: #fff; font-size: 11px">Pin item</span>
                    </v-tooltip>
                  </div>

                  <div v-if="checkIfItemIsPinned(item.url)">
                    <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                      <template v-slot:activator="{ on }">
                        <div class="unpin-item" v-on="on" @click.prevent="unpinItem(item)">
                          <v-icon small class="pa-0 pt-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].menuText">mdi-pin</v-icon>
                        </div> 
                      </template>
                      <span style="color: #fff; font-size: 11px">Unpin item</span>
                    </v-tooltip>
                  </div>
                </div>
              </router-link>
            </div>
          </div>

        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
  import db from "@/firebase/init";
  import { bus } from "@/main";
  import { version } from "../../../package.json"
  // const { ipcRenderer } = require('electron')

  export default {
    name: "sidemenu",
    data() {
      return {
        menuSettingsCollapsed: true,
        menuFit: true,
        menuLoading: true,
        menuItems: new Array(),
        submenuItems: new Array(),
        pinnedMenuItems: new Array(),
        pinnedMenuItemsLoading: true,
      }
    },

    watch: {
      activeUserId () { // Load pinned items of this user
        if(!this.pinnedMenuItemsLoading){ // Prevent dubble loading on refresh
          this.loadPinnedItems(); 
        }
      }
    },

    created(){
      this.applicationVersion = version;
      this.getMenuItems() // Load menuItems
      bus.$on("reloadMenu",()=>{
        this.getMenuItems();
      })
      
      // Remember menu openings choice in Local Storage
      if(window.localStorage.getItem('menuCollapsed') == 'true'){
        this.$store.dispatch("menuStatus", false);
      } 
      else {
        window.localStorage.setItem('menuCollapsed', false);
        this.$store.dispatch("menuStatus", true);
      }

    },
    mounted(){
      window.addEventListener('resize', this.getWindowHeight);
      this.getWindowHeight();
    },

    beforeDestroy(){
      window.removeEventListener('resize', this.getWindowHeight);
    },

    methods: {
      getEnviremontTopMenu(){
        if(window && window.platform && window.platform.name == "Electron"){
          return "menu-top app"
        } else {
          return "menu-top"
        }
      },

      getEnviremontNavScroll(){
        if(window && window.platform && window.platform.name == "Electron"){
          return "nav-scroll app"
        } else {
          return "nav-scroll"
        }
      },

      checkForCollapse(index){
        if(!this.$store.state.menuOpen){
          this.collapse(index)
        }
      },

      collapse(index){
        this.menuItems[index].collapsed =  this.menuItems[index].collapsed == true ? false : true;
      },

      getMenuItems(){
        this.menuLoading = true;
        this.menuItems = new Array();
        this.submenuItems = new Array();

        db.collection('menuItems')
        .where("active", "==", true)
        .get()
        .then(snap=>{
          snap.forEach(doc=>{
            let menuItem = doc.data()
            menuItem.id = doc.id;
            
            if(menuItem.parent){
              this.submenuItems.push(menuItem);
            }
            else{
              if(menuItem.type == 'group'){
                menuItem.submenus = new Array();
                menuItem.collapsed = true;
              }
              this.menuItems.push(menuItem);
            }
          })
        })
        .then(()=>{
          this.menuItems = _.orderBy(this.menuItems, ['weight','desc']);
          this.sortMenu();
        })
        .catch(err=>{
          this.menuLoading = false
          console.error("Error getting menu items:", err);
        });
      },

      async sortMenu(){
        await new Promise((resolve)=>{
          let counter = 0;
          this.menuItems.forEach(item=>{
            if(item.type == 'group'){
              this.submenuItems.forEach(subItem=>{
                if(subItem.parent == item.id){
                  item.submenus.push(subItem)
                  item.submenus = _.orderBy(item.submenus, ['weight','desc']);
                }
              })
            }
            counter++
            if(counter == this.menuItems.length){
              resolve()
            }
          })
        })
        await this.loadPinnedItems();
        this.menuLoading = false;
      },

      scrollToMenuBottom(){
        var objDiv = document.querySelector('.nav-scroll');
        objDiv.scrollTop = objDiv.scrollHeight;
        this.getWindowHeight()
      },

      getWindowHeight(){
        // if(window.innerHeight >= 700){
        //   this.menuFit = true;
        // } 
        // else{
          
        //   const navScrollWrapper = document.querySelector('.nav-scroll');
        //   const navScrollItems = document.querySelector('.nav-scroll-items');
        //   let spaceBehindScroll = (navScrollWrapper.clientHeight + navScrollWrapper.scrollTop) - navScrollItems.clientHeight;
        //   if(spaceBehindScroll < 0){
        //     this.menuFit = false;
        //   }
        //   else{
        //     this.menuFit = true;
        //   }
        // }
      },

      pinItem(item, parentWeight){
        let pinnedItem = _.cloneDeep(item);     
        pinnedItem.weight = parentWeight ? Number(String(parentWeight) + String(item.weight)): item.weight;
        this.pinnedMenuItems.push(pinnedItem);
        this.sortPinnedItems();
        this.savePinnedItems();
      },

      unpinItem(item){
        let index = _.findIndex(this.pinnedMenuItems, ['url', item.url]);
        if(index >= 0){
          this.pinnedMenuItems.splice(index, 1)
        }
        this.savePinnedItems();
      },

      sortPinnedItems(){
        this.pinnedMenuItems = this.pinnedMenuItems ? _.orderBy(this.pinnedMenuItems, ['weight','desc']) : this.pinnedMenuItems; 
      },

      async loadPinnedItems(){
        this.pinnedMenuItemsLoading = true;
        // this.pinnedMenuItems = new Array();
        let pinnedMenuItems = new Array();
        let savedMenuItems = null;
        await db.collection("users").doc(this.$store.state.activeUserId).get()
        .then(doc=>{
          savedMenuItems = doc.data().pinnedMenuItems ? doc.data().pinnedMenuItems : null
        })
        .then(()=>{
          if(savedMenuItems && savedMenuItems.length > 0){
            savedMenuItems.forEach(savedMenuItem=>{
              this.menuItems.forEach(item=>{
                if(item.type == 'group'){
                  item.submenus.forEach(subItem=>{
                    if(savedMenuItem == subItem.url){
                      let pinnedItem = _.cloneDeep(subItem);     
                      pinnedItem.weight = Number(String(item.weight) + String(subItem.weight));
                      pinnedMenuItems.push(pinnedItem)
                    }
                  })
                }
                else{
                  if(savedMenuItem == item.url){
                    pinnedMenuItems.push(item)
                  }
                }
              })
            })
          }
        })
        .then(()=>{
          this.pinnedMenuItems = pinnedMenuItems
        })

        this.sortPinnedItems();
        this.pinnedMenuItemsLoading = false;
        return 
      },

      savePinnedItems(){
        let pinnedMenuItems = new Array();
        this.pinnedMenuItems.forEach(item=>{
          pinnedMenuItems.push(item.url);
        })
        db.collection("users").doc(this.$store.state.activeUserId).set({
          pinnedMenuItems: pinnedMenuItems
        }, {merge: true})
      },

      checkIfItemIsPinned(url){
        let pinned = false;
        new Promise((resolve)=>{
          if(this.pinnedMenuItems && this.pinnedMenuItems.length > 0){
            let counter = 0;
            this.pinnedMenuItems.forEach(item=>{
              if(item.url == url){
                pinned = true;
              }
              counter++
              if(counter == this.pinnedMenuItems.length){
                resolve()
              }
            })
          }
          else{
            resolve()
          }       
        })
        return pinned;
      },

      openMenu() {
        this.$store.dispatch("menuStatus", true);
        window.localStorage.setItem('menuCollapsed', false);
      },

      closeMenu() {
        this.$store.dispatch("menuStatus", false);
        window.localStorage.setItem('menuCollapsed', true);
      },
    },

    computed: {
      activeUserId(){
        return this.$store.state.activeUserId
      },

      pinnedMenuItemsFiltered(){
        let filteredItems = new Array();
        if(this.pinnedMenuItems.length > 0){
          this.pinnedMenuItems.forEach(item=>{
            if((item.permissionsRoles && item.permissionsRoles < 2  && !this.$store.state.demoMode) || !item.permissionsRoles || item.permissionsRoles > 1 ){
              filteredItems.push(item)
            }
          })
        }
        return filteredItems;
      }
    }
  };
</script>

<style>
  .small-tooltip{
    padding: 2px 6px; 
    margin: 0;
  }

  .pinned-items-wrapper{
    background-color: rgba(255,255,225,0.12)
  }

  .pinned-items-wrapper .menu-group-name{
    font-weight: 500;
    font-size: 12px;
  }

  .thrive-logo-menu {
    height: 30px;
    width: 30px;
    display: block;
    float: left; 
    background: url("../../assets/thrive-logo.png") no-repeat center left;
    background-size: 30px auto;
    margin-right:1rem;
  }

  #menu-wrapper.menu-expanded .menu-group.single:hover .pin-item,
  #menu-wrapper.menu-expanded .menu-group-item:hover .pin-item{
    display: block;
  }

  .pin-item{
    display: none;
    position: absolute;
    right: 8px;
    top: 11px;
    border-radius: 3px;
    padding: 0 3px 3px 3px; 
    z-index: 9;
    background-color: rgba(255,255,255,.27);
  }

  .unpin-item{
    display: none;
  }

  #menu-wrapper.menu-expanded .unpin-item{
    display: block;
    position: absolute;
    right: 8px;
    top: 11px;
    border-radius: 3px;
    padding: 0 3px 3px 3px; 
    z-index: 9;
  }

  .menu-group-item .pin-item,
  #menu-wrapper.menu-expanded .menu-group-item .unpin-item{
    top: 5px;
  }

  .unpin-item:hover{
    cursor: pointer;
  }
  .pin-item:hover{
    cursor: pointer;
    background-color: rgba(255,255,255,.78);
  }

  .menu-top {
    height: 47px;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,.12);
  }

  .menu-top.app {
   margin: 16px 0 0 0;
   top:54px;
  }

  .menu-top-icon {
    padding: 14px 13px 0 23px;
    height: 44px;
  }

  .menu-mobile-header{
    font-weight: 600;
    font-size: 16px;
    padding: 5px 5px 5px 15px;
  }

  #menu-wrapper .menu-item {
    height: 44px;
    background: var(--v-menu-base);
    border-top: 1px solid var(--v-menuborder-base);
  }

  .menu-user-info{
    border-top: 1px solid var(--v-menuborder-base);
    padding: 0 10px;
    background-color: var(--v-menu-base);
  }

  .user-icon {
    float: left;
    width: 36px;
    height: 36px;
    border-radius: 40px;
    overflow: hidden;
    margin: 0 0 0 5px;
  }

  #menu-wrapper.menu-expanded .user-icon {
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
  }

  .user-icon p {
    font-family: "Sofia Pro", sans-serif;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    padding: 6px 0 0 0;
    height: 36px;
  }

  #menu-wrapper.menu-expanded .user-icon p {
    font-size: 20px;
    padding: 8px 0 0 0;
    height: 40px;
  }

  .user-info{
    float: left;
    width: calc(100% - 60px);
    display: none;
  }

  #menu-wrapper.menu-expanded .user-info{
    display: block;
  }

  .user-info-name{
    font-weight: 600;
  }
  .menu-group{
    position: relative;
  }

  .menu-group.first{
    border-top: 1px solid var(--v-menuborder-base);
  }

  .menu-group{
    border-bottom: 1px solid rgba(255,255,255,.12);
  }

  .menu-group-item.menu-active .menu-group-item-summary,
  .menu-group.menu-active .menu-group-summary{
    background: linear-gradient(98deg,var(--menuActiveSecondary-color), var(--menuActivePrimary-color) 94%);
    /* background-color: var(--menuActive-color); */
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .menu-group .menu-group-summary.menu-active:after{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    content: "";
    background: linear-gradient(98deg,rgba(255,255,255,.27), rgba(255,255,255,.0) 94%);
  }

  .menu-group-summary{
    position: relative;
    overflow: hidden;
    margin: 0 5px 0 0;
    z-index: 999;
  }

  #menu-wrapper.menu-expanded .menu-group-summary{
    margin: 0 10px 0 0;
  }

  .menu-group.single:hover,
  .menu-group-item:hover{
    cursor: pointer;
  }

  .menu-group.single:hover:after,
  .menu-group-item:hover:after{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    content: "";
    background-color: rgba(255,255,255,.27);
  }

  .menu-group-item.menu-exact-active:hover:after,
  .menu-group.single.menu-exact-active:hover:after{
    display: none;
  }

  .menu-group-summary,
  .menu-group.small .menu-group-summary,
  #menu-wrapper.menu-expanded .menu-group.small .menu-group-summary{
    padding: 7px 0 7px 7px;
  }

  #menu-wrapper.menu-expanded .menu-group.pinned.small.single .menu-group-summary{
    padding: 2px 0 2px 10px;
  }

  .menu-group.small .menu-group-summary-text{
    padding: 6px 0 0 0;
  }

  .menu-group-summary-text{
    display: none;
  }

  #menu-wrapper.menu-expanded .menu-group.group .menu-group-summary-icon{
    padding-top: 8px;
  }

  #menu-wrapper.menu-expanded .menu-group-summary-text{
    display: block;
    width: 175px;
    height: 30px;
    float: left;
  }

  #menu-wrapper.menu-expanded .menu-group-summary .menu-group-summary-text{
    height: 40px;
  } 

  #menu-wrapper.menu-expanded .menu-group.single .menu-group-summary-text{
    height: 30px;
  }

  #menu-wrapper .menu-group-summary-icon i.v-icon{
    padding-left: 10px!important;
  }

  .menu-group-summary:hover{
    cursor: pointer;
  }

  .menu-group-name{
    font-weight: 500;
    font-size: 13px;
    margin: 0!important;
  }


  .menu-group .menu-group-summary-items{
    display: block;
    font-weight: 300;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  } 


  .menu-group.collapsed .menu-group-summary-items{
    display: block;
    font-weight: 400;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .menu-group-item,
  .menu-group-summary:after{
    position: relative;
    overflow: hidden;
    display: block;
    content:"";
    clear: both;
  }

  .menu-group-summary-icon{
    display: block;
    width: 45px;
    height: 30px;
    float: left;
    padding: 3px 0 0 0;
    margin: 0 0 0 6px;
  }

  .menu-group.collapsed .menu-group-summary-text{
    height: 36px;
  }

  .menu-group-expand i.v-icon{
    font-size: 20px!important
  }

  #menu-wrapper.menu-expanded .menu-group-expand i.v-icon{
    font-size: 24px!important
  }

  #menu-wrapper .menu-group-expand{
    width: 15px;
    height: 30px;
    position: absolute;
    right: 10px;
    overflow: hidden;
    padding: 8px 5px 0 2px;
  }

  #menu-wrapper.menu-expanded .menu-group-expand{
    right: 15px;
  }

  #menu-wrapper.menu-expanded .menu-group-expand{
    overflow: visible;
  }

  #menu-wrapper.menu-expanded .menu-group.collapsed .menu-group-expand{
    height: 36px;
  }

  .menu-group.collapsed .menu-group-items,
  #menu-wrapper.menu-expanded .menu-group.collapsed .menu-group-items{
    display: none;
    height: 0;
    padding: 0;
  }
  .menu-group-items-wrapper{
    position: fixed;
  }

  #menu-wrapper.menu-expanded .menu-group-items-wrapper{
    position: relative;
  }

  .menu-group-items{
    position: absolute;
    height: auto;
    left: 80px;
    top: -45px;
    width: 250px;
    z-index: 10;
    background-color: var(--menuBackground-color);
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1);;
  }

  .menu-group-items:before{
    position: absolute;
    display: block;
    left: -16px;
    content: "";
    top: 14px;
    height: 10px;
    width: 10px;
    border: 8px solid var(--menuBackground-color);
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
  }
  
  #menu-wrapper.menu-expanded .menu-group-items{
    position: relative;
    height: auto;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* padding: 0 0 15px 0; */
    box-shadow: none;
  }

  #menu-wrapper.menu-expanded .menu-group-items::before{
    display: none;
  }

  .menu-group-items{
    position: relative;
  }

  #menu-wrapper.menu-expanded .menu-group-items{
    border-radius: 0;
  }
  #menu-wrapper.menu-expanded .menu-group-items::before{
    display: block;
    position: absolute;
    border: none;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(255,255,255,.07);
  }

  .menu-group-item{
    width: 100%;
    height: 36px;
    padding: 0 10px 0 0;
    border-top: 1px solid rgba(255,255,255,.12);
  }

  .menu-group-item-summary{
    width: 100%;
    height: 36px;
    padding: 6px 0;
  }

  #menu-wrapper.menu-expanded .menu-group-item.first{
    border-top: none;
  }

  .menu-group-item:hover{
    background-color: var(--v-menuhover-base);
    cursor: pointer;
  }
  .menu-group-item.router-link-exact-active,
  .menu-group.small.router-link-exact-active{
    background-color: var(--v-menuhover-base)
  }

  .menu-group-item .menu-item-icon{
    width: 40px;
    padding: 0 10px 0 15px;
    height: 20px;
    float: left;
  }

  .menu-group-item .menu-item-text{
    width: calc(100% - 40px);
    height: 20px;
    float: left;
    font-size: 13px;
    font-weight: 300;
    padding: 3px 0 0 0;
    color: var(--v-text-base);
  }

  .custom-menu-icon{
    width: 18px;
    height: 18px;
  }

  .menu-user-info.router-link-exact-active{
    background-color: var(--v-menuhover-base);
  }

  .menu-user-info,
  .menu-group,
  .menu-group-items,
  .menu-group-item,
  .menu-item-text{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
  }

  .nav-fixed-buttom{
    position: absolute;
    bottom: 0;
    height: 162px;
    width: 100%;
  }

  .nav-fixed-buttom.no-log{
    position: absolute;
    bottom: 0;
    height: 100px;
    width: 100%;
  }
  .more-menu-items{
    position: absolute;
    background-color: var(--v-background-base);
    width: 100%;
    height: 24px;
    top: 0;
    margin-top: -24px;
    z-index: 11;
    display: none;
  }

  .more-menu-items.no-space{
    display: block;
  }

  .nav-scroll{
    position: absolute;
    overflow-y: scroll;
    overflow-x: scroll;
    top: 47px;
    width: 100%;
    height: calc(100% - 47px);
    padding: 0 0 15px 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .nav-scroll.app{
    top:62px;
  }

  .nav-scroll::-webkit-scrollbar {
      display: none;
  }

  .menu-group.small .menu-group-expand{
    display: none;
  }
</style>