
<template>
  <div>
    <v-container v-if="dataloaded & !$store.state.demoMode" grid-list-md class="pa-0 pb-4" fluid>
      <v-layout row wrap>
        <companySelector :showBranches="false" @companyChanged="companyUpdate"></companySelector>
      </v-layout>
    </v-container>

    <div v-if="!dataloaded" class="thrive-loader"></div>

    <div v-if="dataloaded" >
      <v-tabs class="mb-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" show-arrows v-model="tab" @change="tabChanged()" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
        <v-tab href="#treatments"><v-icon left>mdi-clipboard-text-outline</v-icon>{{ $t('settings.treatments', $store.state.locale)}}</v-tab>
        <v-tab href="#employees"><v-icon left>mdi-account-multiple</v-icon>{{ $t('general.employees', $store.state.locale)}}</v-tab>
        <!-- <v-tab href="#packages"><v-icon left>mdi-animation-outline</v-icon>{{ $t('settings.packages', $store.state.locale)}}</v-tab> -->
        <v-tab href="#payment"><v-icon left>mdi-credit-card-outline</v-icon>{{ $t('settings.paymentMethods', $store.state.locale)}}</v-tab>
        <!-- <v-tab href="#calendar"><v-icon left>mdi-connection</v-icon>{{ $t('settings.calendarSettings', $store.state.locale)}}</v-tab> -->
        <v-tab href="#connections"><v-icon left>mdi-connection</v-icon>{{ $t('settings.connections', $store.state.locale)}}</v-tab>
        <v-tab href="#rules"><v-icon left>mdi-lock</v-icon>{{ $t('settings.userRules', $store.state.locale)}}</v-tab>
        <v-tab href="#accountancy"><v-icon left>mdi-cash-check</v-icon>{{ $t('settings.accountancySettings', $store.state.locale)}}</v-tab> 
        <v-tab href="#formoverview"><v-icon left>mdi-file-document-outline</v-icon>{{ $t('settings.formSettings', $store.state.locale)}}</v-tab> 
        <v-tab href="#tags"><v-icon left>mdi-tag</v-icon>{{ $t('general.tags', $store.state.locale)}}</v-tab> 
      </v-tabs>
      
        <v-tabs-items v-model="tab" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card}" class="transparant-tabs">
          <v-tab-item value="treatments" :transition="tabTransition" :reverse-transition="tabTransition">
              <treatments :activeCompData="activeCompData"></treatments>
          </v-tab-item>
          <v-tab-item value="employees" :transition="tabTransition" :reverse-transition="tabTransition">
            <employees :activeCompData="activeCompData"></employees>
          </v-tab-item>
          <v-tab-item value="packages" :transition="tabTransition" :reverse-transition="tabTransition">
            <packages-overview :activeCompData="activeCompData" :activeCompDataOriginal="activeCompDataOriginal"></packages-overview>
          </v-tab-item>
          <v-tab-item value="payment" :transition="tabTransition" :reverse-transition="tabTransition">
            <payment :activeCompData="activeCompData"></payment>
          </v-tab-item>
          <v-tab-item value="calendar" :transition="tabTransition" :reverse-transition="tabTransition">
            <calendarSettings :activeCompData="activeCompData" :activeCompDataOriginal="activeCompDataOriginal"></calendarSettings>
          </v-tab-item>
          <v-tab-item value="connections" :transition="tabTransition" :reverse-transition="tabTransition">
            <connections :type="'company'" :activeCompData="activeCompData" :activeCompDataOriginal="activeCompDataOriginal"></connections>
          </v-tab-item>
          <v-tab-item value="rules" :transition="tabTransition" :reverse-transition="tabTransition">
            <user-rules></user-rules>
          </v-tab-item>
          <v-tab-item value="accountancy" :transition="tabTransition" :reverse-transition="tabTransition">
            <accountancy-settings :activeCompData="activeCompData" :activeCompDataOriginal="activeCompDataOriginal" :typeCompanyOrBranch="'company'"></accountancy-settings>
          </v-tab-item>
           <v-tab-item value="formoverview" :transition="tabTransition" :reverse-transition="tabTransition">
            <form-overview :activeCompData="activeCompData" :activeCompDataOriginal="activeCompDataOriginal" :typeCompanyOrBranch="'company'"></form-overview>
          </v-tab-item>
          <v-tab-item value="tags" :transition="tabTransition" :reverse-transition="tabTransition">
            <client-tags :activeCompData="activeCompData"></client-tags >
          </v-tab-item>
        </v-tabs-items>
      <!-- </v-card> -->
    </div>
                     
  </div>
</template>

<script>
  import { bus } from "@/main";
  import '@firebase/firestore';
  import Treatments from "@/views/Treatments.vue";
  import CompanyPaymentSettings from "@/views/CompanyPaymentSettings.vue";
  import Social from "@/views/Social.vue";
  import Employees from "@/views/Employees.vue";
  import PackagesOverview from "@/views/PackagesOverview.vue";
  import CalendarSettings from "@/views/CalendarSettings.vue";
  import Connections from "@/views/Connections.vue";
  import userRules from "@/views/UserRulesSettingsCompany.vue";
  import accountancySettings from "@/views/accountancySettings.vue";
  import companySelector from "@/components/elements/companySelector.vue";
  import formOverview from "./formOverview.vue";
  import clientTags from "./ClientTags.vue";

  export default {
    components: {
      payment: CompanyPaymentSettings,
      calendarSettings: CalendarSettings,
      connections: Connections,
      treatments: Treatments,
      employees: Employees,
      social: Social,
      userRules: userRules,
      packagesOverview: PackagesOverview,
      accountancySettings: accountancySettings,
      companySelector: companySelector,
      formOverview: formOverview,
      clientTags: clientTags,
    },
    name: "Settings",
    data: () => ({
      tabTransition: false,
      searchCompanies: "",
      activeComp: null,
      activeCompName: null,
      activeCompData: {},
      activeCompDataOriginal: null,
      companies: [],
      dataloaded: false,
      tab: "treatments"
    }),

    created() {
      this.companyUpdate();
      if(this.$route.params.tab) {
        this.tab = this.$route.params.tab;
      }
    },

    methods: {
      companyUpdate(){
        this.activeCompDataOriginal = _.cloneDeep(this.$store.state.activeCompany);
        this.dataloaded = true;
        bus.$emit("companyUpdate");
      },

      tabChanged(){ // Query in URl aanpassen bij selecteren van de router.
        this.$router.push({ params: { tab: null, extra: null} });
        history.pushState({},null,this.$route.path + "/" + this.tab)
      }
    },

    computed: {
      version() {
        return this.$store.state.version;
      },
      userLanguage(){
        return this.$store.state.locale ? this.$store.state.locale : 'NL';
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
    }
  };
</script>
