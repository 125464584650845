<template>
  <v-dialog v-model="dialog" scrollable max-width="700px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('memberships.addNewSubscription', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-alert v-if="subscription.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ subscription.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text class="pa-0 px-4 pb-4 ma-0">
          <v-container grid-list-md class="pa-0 ma-0">
            <v-select
              class="mt-4"
              :label="$t('memberships.selectMembership', $store.state.locale)"
              :items="membershipPackages"
              :item-text="item => item.name[($store.state.locale).toLowerCase()]"
              item-value="id"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              dense
              v-model="subscription.subscription"
              outlined
              hide-details 
              >
            </v-select>

            <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="membershipStartDate.readable"
                  :label="$t('general.start', $store.state.locale)"
                  readonly
                  prepend-inner-icon="mdi-calendar"
                  hide-details
                  outlined
                  clearable
                  dense
                  class="mt-4"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  background-color="menu"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                no-title
                :min="membershipStartMinDate"
                v-model="subscription.startDate"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @input="menuStartDate = false"
              ></v-date-picker>
            </v-menu>

            <div class="client-selector">
              <clientSelector :source="'subscriptionModal'"></clientSelector>  
            </div>
            
          </v-container>
         </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="closeDialog()"
                  >
                  {{ $t("general.cancel", $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  :dark="membershipValid"
                  :disabled="!membershipValid"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  @click="addNewMembership()"
                  >
                    {{ $t('memberships.subscribeClient', $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>


<script>
  import '@firebase/firestore';
  import db from "@/firebase/init";
  import moment from "moment";
  import { bus } from "@/main";
  import clientSelector from "@/components/elements/clientSelector.vue";

  export default {
    name: "add-subscription-model",
    props:{
      membershipPackages: Array()
    },
    components: {
      clientSelector: clientSelector,
    },
    data() {
      return {
        dialog: false,

        menuStartDate: false,
        membershipStartMinDate: null,
        clientData: new Object(),
        subscription: {
          dialog: false,
          feedback: null,
          startDate: null,
          subscription: null,
          newclient: false,
        },
      };
    },

    created(){
      bus.$on('newSubscriptionModal', () =>{
        this.resetDialog();
        this.dialog = true;
      });
      bus.$on('subscriptionClientSelected', (clientData) =>{
        this.clientData = clientData;
      });
    },

    methods: {
      resetDialog(){
        this.subscription.feedback = null;
        this.subscription.subscription = null;
        this.subscription.startDate = _.cloneDeep(this.membershipStartMinDate);
        this.membershipStartMinDate = moment().format("YYYY-MM-DD");
        this.clientData = new Object()
      },

      async addNewMembership(){
        let subscriptionData = null;
        let index = _.findIndex(this.membershipPackages, {"id": this.subscription.subscription})
        
        if(index >= 0){
          subscriptionData = this.membershipPackages[index];
      
          if(this.subscription.newclient){
            await this.createClient();
          }

          db.collection("memberships")
          .add({
            created: new Date(),
            companyId: this.$store.state.activeCompany.id,
            clientId: this.clientData.id,
            startDate: this.membershipStartDate.formatted,
            subscription: this.subscription.subscription,
            freePeriod: subscriptionData.freePeriod,
            treatments: subscriptionData.treatments,
            name: subscriptionData.name,
            paymentDay: subscriptionData.paymentDay,
            duration: subscriptionData.duration,
            paymentPeriod: subscriptionData.paymentPeriod,
            paymentReminder: subscriptionData.paymentReminder,
            singlePaymentDiscount: subscriptionData.singlePaymentDiscount,
            trialPeriod: subscriptionData.trialPeriod,
            vat: subscriptionData.vat,
            terms: subscriptionData.terms,
            periodicPaymentDiscount: subscriptionData.periodicPaymentDiscount,
            registrationDescription: subscriptionData.registrationDescription,
            registrationUrl: subscriptionData.registrationUrl,
            sendInvoice: subscriptionData.sendInvoice,
            usage: new Array(),
            sepaAttempts: subscriptionData.sepaAttempts,
            amount: subscriptionData.amount,
            separatedDiscount: subscriptionData.separatedDiscount,
            branches: subscriptionData.branches,
            days: subscriptionData.days,

            offPeakMessage: subscriptionData.offPeakMessage,
            peakMessage: subscriptionData.peakMessage,
            posDescription: subscriptionData.posDescription,

            status: {
              endDate: null,
              status: "active",
              type: "doorlopend"
            }
          })
          .then(()=>{
            this.$emit("subscriptionAdded");
            this.closeDialog();
          })
        }
      },


      closeDialog(){
        this.dialog = false;
        this.resetDialog();
      },
    },
    computed: {
      membershipValid(){
        if(this.subscription.startDate && this.subscription.subscription  && ((!this.subscription.newclient && this.clientData.id) || (this.subscription.newclient && this.clientData.firstName && this.clientData.surname && this.clientData.email))){
          return true;
        }
        else{
          return false;
        }
      },

      membershipStartDate() {
        let date = {
          readable: null,
          formatted: "",
          firebase: null,
        };
        if (this.subscription.startDate) {
          date.readable = moment(this.subscription.startDate).format("D MMMM YYYY");
          date.formatted = moment(this.subscription.startDate).format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },
    }

  };
</script>
