
<template>
  <div>

    <v-dialog
      v-model="editSmsDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>Edit Sms</h2>
          <v-btn class="ml-3" @click="previewText()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark ><v-icon left>mdi-eye</v-icon>Preview</v-btn>
          <v-spacer></v-spacer>
          <div class="text-right px-4 pt-0" v-if="savingItem" :style="{'color' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>mdi-content-save</v-icon> Saving...
          </div>
          <div class="text-right px-4 pt-0" v-if="unsafedChanges" :style="{'color' : $themes[$store.state.companyTheme][$store.state.themeModus].text }">
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small>mdi-content-save</v-icon> Onopgeslagen wijzigingen
          </div>
          <v-btn small dark depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mx-2" @click="saveMessage()"><v-icon ledt>mdi-save</v-icon> Opslaan</v-btn>
          <v-btn small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" @click="closeSmsEditor()">Sluiten</v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="pa-4 ma-0" style="position: relative" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }">

          <div v-if="loading">
            <div style="position: relative; padding: 200px 0;">
              <div class="thrive-loader"></div>
            </div>
          </div>
        
          <div class="pa-0" v-if="!loading">
            <v-container grid-list-md class="pa-0 ma-0" fluid grow>
              <v-layout row wrap>
                <v-flex md6 xs12>

                <div class="text-preview-wrapper mobile">
                  <div class="text-preview-display mobile">
                    <div class="textmessage">
                      {{ selectedMessage.content[selectedLanguage] }}
                    </div>
                  </div>
                </div>

                </v-flex>
                <v-flex md6 xs12 class="fill-height">
  
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-2 ma-0" :class="$store.state.companyTheme" style="width: 100%">
                    <div class="pa-4"> <!-- Email Subject   -->
                    <div v-if="editorType == 'user'">
                      <h5 class="pb-1">Naam</h5>
                      <v-text-field
                      class="mb-4"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                        dense
                        outlined
                        hide-details
                        v-model="selectedMessage.title"
                      ></v-text-field>
                    </div>

                      <h5 class="pt-3 pb-1">Variables</h5>
                      <div style="display: inline-block; margin: 2px 2px;" v-for="(variable, index) in emailVariablesFiltered" :key="index">
                        <v-chip label small :color="variable.type == 'text' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  :text-color="variable.type == 'text' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mr-1 mt-1 custom-chip-style">{{ variable.name[($store.state.locale).toLowerCase()] }}</v-chip>
                        <div v-if="variable.copy == false" class="copy-tag-url" @click="copyVariableToClipboard(variable.name, index)"><v-icon small>mdi-content-copy</v-icon> {{ $t('emails.copy', $store.state.locale) }}</div>
                        <div v-else class="copy-tag-url copied">{{ $t('emails.copied', $store.state.locale) }}</div>
                      </div>

                      <h5 class="pb-1 pt-3">Tekst</h5>
                      <v-select
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :items="languages"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        hide-details
                        v-model="selectedLanguage"
                      ></v-select>

                      <v-textarea
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                        class="mt-4"
                        v-model="selectedMessage.content[selectedLanguage]"
                        outlined
                        counter
                        maxlength="150"
                      ></v-textarea>
                    </div>
                    
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- / Close Without Saving Dialog \ -->
    <v-dialog v-model="closeWithoutSaving.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].alert}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alert</v-icon>
          </v-avatar>
          <h2 class="mb-4">Wijzigingen niet opslaan?</h2> 
          <p class="ma-0">Er zijn niet opgeslagen wijzigingen.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                
                <v-btn dark :loading="savingItem" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="closeAndSave()">
                  Opslaan en sluiten
                   <!-- {{ $t('rooms.removeRoomButton', $store.state.locale) }} -->
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn outlined depressed :disabled="savingItem" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeWithoutSave()">
                  Sluiten
                  <!-- {{ $t('general.cancel', $store.state.locale) }} -->
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Item Dialog / -->

    <!-- / Save Item Dialog \ -->
    <v-dialog v-model="saveEmailItemData.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-content-save</v-icon>
          </v-avatar>
          <h2 class="mb-4">{{ saveEmailItemData.title }}</h2> 
          <p class="ma-0">{{ saveEmailItemData.text }}</p>
          <v-text-field
            class="mt-4"
            v-model="saveEmailItemData.name"
            fullWidth
            dense
            hide-details=""
            label="Name"
            outlined
            clearable
            ></v-text-field>
          <div style="margin: 0; display: block">
            <v-tooltip dark bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                <v-switch    
                  :color="saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  v-model="saveEmailItemData.general" 
                  inset
                  hide-details
                  label="General">
                </v-switch>
                </div>
              </template>
              <span style="font-size: 12px">Make general to stay updated in te future.</span>
            </v-tooltip>
          </div>        
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="saveEmailItemData.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn :disabled="!saveItemValid" :dark="saveItemValid" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="saveEmailItemData.general ? $themes[$store.state.companyTheme][$store.state.themeModus].tertiary : $themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="saveEmailItem()">
                   {{ $t('general.save', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Save Item Dialog / -->

  </div>
</template>

<script>
import _ from 'lodash'
import db from "../firebase/init";
import { bus } from "../main";
var uniqid = require("uniqid");
import axios from "axios";



export default {
  name:"editSmsTemplate",
  data(){
    return{
      selectedLanguage: 'nl',
      languages: [
        {
          id: "nl",
          name: "Nederlands"
        },
        {
          id: "en",
          name: "English"
        },
        {
          name: "Duits",
          id: 'de'
        }
      ],
      selectedMessage:{
        id: null
      },
      selectedMessageOriginal: new Object(),
      savingItem: false,
      editorType: 'client',
      companies: new Array(),

      htmlModal: {
        dialog: false,
        html: null
      },
      selectedPreviewType: 'desktop',
      libraryDialog: false,
      loading: true,
      templates: new Array(),
      emailItems: new Array(),
      drag: false,
      tab: "general",
      tabTransition: false,
      emailInformation: {
        type: 'appointmentConfirm',
        subject: {
          nl: "",
          en: ""
        }
      },
      emailVariables: [
        {
          id: 'branchName',
          name: {
            nl: '[vestigingNaam]',
            en: '[branchName]',
          },
          type: 'text',
          editorType: 'admin',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder"
          ],
          copy: false
        },
        {
          id: 'firstName',
          name: {
            nl: '[voornaam]',
            en: '[firstname]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder"
          ],
          copy: false
        },
        {
          id: 'lastname',
          name: {
            nl: '[achternaam]',
            en: '[lastname]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder"
          ],
          copy: false
        },
        {
          id: 'date',
          name: {
            nl: '[datum]',
            en: '[date]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder"
          ],
          copy: false
        },
        {
          id: 'daystogo',
          name: {
            nl: '[tijd te gaan]',
            en: '[time to go]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentReminder",
            "eventReminder"
          ],
          copy: false
        },
        {
          id: 'starttime',
          name: {
            nl: '[starttijd]',
            en: '[starttime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder"
          ],
          copy: false
        },
        {
          id: 'endtime',
          name: {
            nl: '[eindtijd]',
            en: '[endtime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder"
          ],
          copy: false
        },
        {
          id: 'starttimeold',
          name: {
            nl: '[oude starttijd]',
            en: '[old starttime ]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentChanges",
            "eventChanges",
          ],
          copy: false
        },
        {
          id: 'endtimeold',
          name: {
            nl: '[oude eindtijd]',
            en: '[old endtime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentChanges",
            "eventChanges",
          ],
          copy: false
        },
        {
          id: 'starttimenew',
          name: {
            nl: '[nieuwe starttijd]',
            en: '[new starttime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentChanges",
            "eventChanges",
          ],
          copy: false
        },
        {
          id: 'endtimenew',
          name: {
            nl: '[nieuwe eindtijd]',
            en: '[new endtime]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentChanges",
            "eventChanges",
          ],
          copy: false
        },
        {
          id: 'treatment',
          name: {
            nl: '[behandeling]',
            en: '[treatment]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirm",
            "appointmentConfirmDownPayment", 
            "appointmentReminder",
            "appointmentCancel",
            "appointmentChanges",
            "eventConfirm",
            "eventCancel",
            "eventChanges",
            "eventReminder"
          ],
          copy: false
        },
        {
          id: 'depositAmount',
          name: {
            nl: '[aanbetalingBedrag]',
            en: '[depositAmount]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirmDownPayment", 
          ],
          copy: false
        },
        {
          id: 'depositUrl',
          name: {
            nl: '[aanbetalingURL]',
            en: '[depositURL]',
          },
          type: 'text',
          editorType: 'all',
          include: [
            "appointmentConfirmDownPayment", 
          ],
          copy: false
        },
      ],
      editSmsDialog: false,
      emailTypes: [
        {
          name: {
            nl: "Afspraakbevestiging",
            en: "Appointment Confirmation"
          },
          id: 'appointmentConfirm',
        },
        {
          name: {
            nl: "Afspraakbevestiging met aanbetaling",
            en: "Appointment Confirmation with Downpayment"
          },
          id: 'appointmentConfirmDownPayment',
        },
        {
          name: {
            nl: "Afspraakherinnering",
            en: "Appointment reminder"
          },
          id: 'appointmentReminder',
        },
        {
          name: {
            nl: "Afspraak annulering ",
            en: "Appointment cancellation "
          },
          id: 'appointmentCancel',
        },
        {
          name: {
            nl: "Afspraak  aanpassingsbevestiging",
            en: "Appointment adjustment confirmation"
          },
          id: 'appointmentChanges',
        },
        {
          name: {
            nl: "Inschrijfbevestiging ",
            en: "Confirmation subscription"
          },
          id: 'eventConfirm',
        },
        {
          name: {
            nl: "Herinnering Eventinschrijving",
            en: "Event Subscription reminder"
          },
          id: 'eventReminder',
        },
        {
          name: {
            nl: "Ischrijving annulering ",
             en: "Subscription cancellation"
          },
          id: 'eventCancel',
        },
        {
          name: {
            nl: "Inschrijving aanpassingsbevestiging",
              en: "Inschrijving adjustment confirmation"
          },
          id: 'eventChanges',
        }
      ],
      saveEmailItemData: {
        dialog: false,
        item: null,
        text: null,
        name: null,
        title: null,
        general: false
      },
      changes: 0,
      closeWithoutSaving: {
        dialog: false
      }, 
    }
  },

  created(){
    bus.$on("editTextTemplate", (id, editorType) => { 
      this.selectedMessage.id = id;
      this.editorType = editorType;
      this.loadSmsTemplate();
    })
  },

  methods:{

    openEditSmsDialog(){
      this.editSmsDialog = true;
    },

    previewText(){
      bus.$emit("previewMessageTemplate", this.selectedMessage.id, null, this.selectedMessage.content, null, this.selectedMessage.type, 'text');
    },

    closeEditSmsDialog(){
      this.closeWithoutSaving.dialog = false;
      let savedChanges = true;
      this.editSmsDialog = false;
      this.$emit("updated", savedChanges);
    },

    closeSmsEditor(){
      if(this.unsafedChanges){
        this.closeWithoutSaving.dialog = true;
      }
      else{
        this.closeWithoutSave()
      }
    },

    async closeAndSave(){
      await this.saveMessage();
      this.closeEditSmsDialog();
    },

    closeWithoutSave(){
      this.closeEditSmsDialog();
      this.selectedItem = null;
      this.selectedColumn = null;
      this.selectedRow = null;
      this.tab = 'general';
    },

    async saveMessage(){
      this.savingItem = true;
      await db.collection('messages').doc(this.selectedMessage.id).set({
        title: this.selectedMessage.title ? this.selectedMessage.title : null,
        content: this.selectedMessage.content,
        lastChanges: new Date()
      }, {merge: true})
      .then(()=>{
        this.selectedMessageOriginal = _.cloneDeep(this.selectedMessage);
      })
      this.savingItem = false;
      return
    },

    async loadSmsTemplate(){
      this.loading = false;
      this.savingItem = false;
      this.openEditSmsDialog();
      await db.collection('messages').doc(this.selectedMessage.id).get()
      .then(doc => {
        this.selectedMessage =  doc.data();
        this.selectedMessage.id = doc.id;
        this.selectedMessageOriginal = _.cloneDeep(this.selectedMessage);
      });
      this.loading = false;
    },

    copyVariableToClipboard(text, index){
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text[(this.$store.state.locale).toLowerCase()]);
        return;
      }
      navigator.clipboard.writeText(text[(this.$store.state.locale).toLowerCase()]).then(()=> {
        this.emailVariablesFiltered.forEach(variable =>{
          variable.copy = false
        })
        this.emailVariablesFiltered[index].copy = true
        setTimeout(()=>{ 
          this.emailVariablesFiltered[index].copy = false
        }, 1000);

      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    
  },

  computed: {

    unsafedChanges(){
      if (_.isEqual(this.selectedMessage, this.selectedMessageOriginal) == false) {
        return true;
      } else {
        return false;
      }
    },

    emailVariablesFiltered(){
      // Filter email variables to show te right variables with the right email type
      let variables = new Array();
      let emailType = this.selectedMessage.type;

      this.emailVariables.forEach(variable=>{
        if(_.indexOf(variable.include, emailType) >= 0){
          if(this.editorType == 'admin' || (this.editorType == 'user' && variable.editorType == 'all')){
            variables.push(variable);
          }     
        }
      });
      return variables;
    },
    saveItemValid(){
      if(this.saveEmailItemData.name){
        return true
      }
      else{
        return false
      }
    }
    
  }
};
</script>


<style>
  .text-preview-wrapper{
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 50px;
    padding: 50px 10px 70px 10px;
    width: 320px;
    margin: 10px auto;
    border: 3px solid #333;
    border-radius: 30px;
    background-color: #000;
  }
  .text-preview-wrapper .text-preview-display{
    border: 1px solid #333;
    border-radius: 5px;
    height: 420px;
    overflow: scroll;
    margin: 0;
    padding: 0;
    background-color: #fff;
  }

  .text-preview-wrapper .textmessage{
    background-color: #f1f1f1;
    border-radius: 15px;
    max-width: 190px;
    padding: 10px;
    margin: 15px;
    position: relative;
  }
  .text-preview-wrapper .textmessage:after{
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    bottom: 5px;
    position: absolute;
    left: 0px;
    border-radius: 20px;
    border-bottom: 7px solid #f1f1f1;
    margin: 0 0 0 -12px;
    transform: rotate(-40deg);
  }
</style>