<template>
  <div>
    <v-dialog v-model="showDialog" persistent scrollable max-width="1200px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2 v-if="selectedLead.clientInfo">{{ selectedLead.clientInfo.firstName }} {{ selectedLead.clientInfo.lastName }}</h2>
          <h2 v-if="selectedLead.campaign">- {{ campaignData.name }}</h2>
          <v-spacer></v-spacer>
          <v-btn v-if="!savingLead" icon dark @click="deleteLeadModal = true" class="mr-4">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon>
          </v-btn>

          <v-btn v-if="!savingLead" icon dark @click="closeEditLeadDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="feedback" class="ma-0 pa-2" v-html="feedback" style="border-radius: 0" type="warning"></v-alert>

        <v-divider></v-divider>

        <v-card-text class="ma-0 pa-0" style="position: relative">
          <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.status" absolute top class="mt-1">
            <v-row>
              <v-col cols="4"
                ><v-icon color="white" class="mx-3">{{ snackbar.icon }}</v-icon></v-col
              >
              <v-col cols="8"
                ><span class="pt-3 white--text text-center" style="font-weight: bold">{{ snackbar.message }}</span></v-col
              >
            </v-row>
          </v-snackbar>

          <v-container grid-list-xl fluid>
            <v-layout row wrap>
              <v-flex md6 xs12>
                <v-row>
                  <v-col cols="6">
                    <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('leadCampaign.leadInfo', $store.state.locale) }}</h3>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      v-if="selectedLead.clientType != 'existingClient'"
                      class="mt-2"
                      :dark="clientDataChanged"
                      :depressed="!clientDataChanged"
                      :disabled="!clientDataChanged"
                      rounded
                      small
                      :loading="savingLead"
                      :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"
                      @click="updateClientData()"
                    >
                      <div v-if="clientDataChanged">{{ $t("general.saveChanged", $store.state.locale) }} <v-icon righy>mdi-chevron-right</v-icon></div>
                      <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-divider class="mt-1 mb-5"></v-divider>

                <div v-if="selectedLead.clientType && selectedLead.clientType == 'existingClient'">
                  <v-card flat color="background" class="pa-2" style="width: 100%">
                    <v-container grid-list-md class="pa-0" fluid>
                      <v-layout row wrap>
                        <v-flex xs6 class="pa-1">
                          <v-text-field
                            class="mt-1"
                            :label="$t('general.firstName', $store.state.locale)"
                            v-model="selectedLead.clientInfo.firstName"
                            outlined
                            readonly
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            dense
                            disabled
                            hide-details
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6 class="pa-1">
                          <v-text-field
                            class="mt-1"
                            :label="$t('general.lastName', $store.state.locale)"
                            v-model="selectedLead.clientInfo.lastName"
                            outlined
                            readonly
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            dense
                            disabled
                            hide-details
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6 class="pa-1">
                          <v-text-field
                            class="mt-1"
                            :label="$t('general.phone', $store.state.locale)"
                            v-model="selectedLead.clientInfo.phone"
                            autocomplete
                            readonly
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            disabled
                            required
                            outlined
                            hide-details
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6 class="pa-1">
                          <v-select
                            class="mt-1"
                            item-text="name"
                            item-value="id"
                            :items="availableLanguages"
                            v-model="selectedLead.language"
                            :label="$t('general.language', $store.state.locale)"
                            hide-details
                            outlined
                            readonly
                            dense
                            disabled
                            persistent-hint
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          >
                          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                            <country-flag :country="data.item.flagCode" size='small'/>
                            <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                          </template>
                          <template slot="item" slot-scope="data">
                            <country-flag :country="data.item.flagCode" size='small'/>
                            <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                          </template>
                          </v-select>
                        </v-flex>

                        <v-flex xs12 class="pa-1">
                          <v-text-field
                            class="mt-1"
                            :label="$t('general.email', $store.state.locale)"
                            v-model="selectedLead.clientInfo.email"
                            autocomplete
                            required
                            outlined
                            readonly
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            disabled
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card>

                  <v-alert type="info" dense text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  class="my-2" v-html="$t('leadCampaign.alreadyClient', $store.state.locale)"> </v-alert>
                  
                  <v-row>
                    <v-col cols="6">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('clients.basicInformation', $store.state.locale) }}</h3>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-btn
                        class="mt-2"
                        :dark="clientDataChanged"
                        :depressed="!clientDataChanged"
                        :disabled="!clientDataChanged"
                        rounded
                        small
                        :loading="savingLead"
                        :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary"
                        @click="updateClientData()"
                      >
                        <div v-if="clientDataChanged">{{ $t("general.saveChanged", $store.state.locale) }} <v-icon righy>mdi-chevron-right</v-icon></div>
                        <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>

                <v-divider v-if="selectedLead.clientType && selectedLead.clientType == 'existingClient'" class="mt-1 mb-5"></v-divider>

                <v-container grid-list-md class="pa-0" fluid>
                  <v-layout row wrap class="pa-0 px-2">
                    <v-flex xs6 class="pa-1">
                      <v-text-field
                        class="ma-0 pa-0 mt-1"
                        :label="$t('general.firstName', $store.state.locale)"
                        v-model="client.name"
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        hide-details
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 class="pa-1">
                      <v-text-field
                        class="ma-0 pa-0 mt-1"
                        :label="$t('general.lastName', $store.state.locale)"
                        v-model="client.surname"
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        hide-details
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 class="pa-1">
                      <v-select
                        class="ma-0 pa-0 mt-1"
                        :label="$t('general.gender', $store.state.locale)"
                        v-model="client.gender"
                        :items="genders"
                        item-text="name"
                        item-value="id"
                        required
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        hide-details
                      ></v-select>
                    </v-flex>

                    <v-flex xs6 class="pa-1">
                      <v-menu :close-on-content-click="false" v-model="birthdayDateDialog" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-on="on"
                            :value="birthdayDate.readable"
                            :label="$t('general.birthday', $store.state.locale)"
                            readonly
                            outlined
                            dense
                            hide-details
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            class="mt-1"
                          ></v-text-field>
                        </template>
                        <v-date-picker ref="picker" v-model="birthdayDateRaw" no-title :max="today" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="birthdayDateDialog = false"></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12 class="pa-1">
                      <v-select
                        class="mt-1"
                        item-text="name"
                        item-value="id"
                        :items="availableLanguages"
                        v-model="client.language"
                        :label="$t('general.language', $store.state.locale)"
                        :hint="$t('clients.languageHint', $store.state.locale)"
                        outlined
                        dense
                        hide-details
                        background-color="background"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      >
                      <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                        <country-flag :country="data.item.flagCode" size='small'/>
                        <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                      </template>
                      <template slot="item" slot-scope="data">
                        <country-flag :country="data.item.flagCode" size='small'/>
                        <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                      </template>
                      </v-select>
                    </v-flex>
                    <v-flex xs12 class="pa-1">
                      <v-textarea
                        class="ma-0 pa-0 mt-1"
                        :label="$t('clients.notes', $store.state.locale)"
                        v-model="client.notes"
                        outlined
                        dense
                        rows="2"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        hide-details
                      ></v-textarea>
                    </v-flex>

                    <v-flex xs12 v-if="client.emailSubscriptions" class="pa-1">
                      <v-checkbox class="ma-0 pa-0" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  :label="$t('clients.recieveNewsletter', $store.state.locale)" v-model="client.emailSubscriptions.newsletter"></v-checkbox>
                    </v-flex>

                    <v-flex xs12 class="pa-1">
                      <v-card outlined color="background" style="padding: 10px; width: 100%" v-if="client.type == 'company'">
                        <h3 class="client-overview-subtitle pt-0 pb-0">{{ $t("clients.companyInformation", $store.state.locale) }}</h3>
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-select
                              class="ma-0 pa-0 mt-1"
                              :label="$t('clients.selectCompany', $store.state.locale)"
                              v-model="client.company"
                              :items="compClients"
                              dense
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              item-text="name"
                              item-value="id"
                              required
                              outlined
                              background-color="menu"
                              hide-details
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>

                <!-- CLIENTGEGEVENS-->
                <div class="py-4">
                  <v-avatar
                    size="36"
                    light
                    style="float: left"
                    class="ma-0 mr-2 custom-avatar-style" 
                    rounded="lg"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                    >
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-map-marker</v-icon>
                  </v-avatar>
                  <h3 class="pt-1 pb-4">{{ $t('clients.contact', $store.state.locale) }}</h3>

                  <v-divider></v-divider>

                  <v-layout class="mt-3 px-2">
                    <v-flex xs12 class="pa-1">
                      <v-select
                        :label="$t('general.country', $store.state.locale)"
                        v-model="client.address.country"
                        :items="$countries"
                        :item-text="item => item.countryName[($store.state.locale).toLowerCase()]"
                        item-value="country"
                        required
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        hide-details
                      ></v-select>
                    </v-flex> 
                  </v-layout>
                  <v-layout class="px-2">
                    <v-flex xs12 md6 class="pa-1">
                      <v-text-field
                        :label="$t('general.zipcode', $store.state.locale)"
                        v-model="client.address.postalCode"
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="menu"
                        suffix="1234AA"
                        hide-details               
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3 class="pa-1">
                      <v-text-field
                        :label="$t('general.number', $store.state.locale)"
                        number
                        v-model.number="client.address.number"
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="menu"
                        hide-details
                      ></v-text-field>
                    </v-flex> 
                    <v-flex xs6 md3 class="pa-1">
                      <v-text-field
                        :label="$t('general.numberAdd', $store.state.locale)"
                        v-model="client.address.numberAddition"
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="menu"
                        hide-details
                        ></v-text-field>
                    </v-flex>
                  </v-layout>
                      
                  <v-layout class="px-2">
                    <v-flex xs6 class="pa-1">  
                      <v-text-field
                        :label="$t('general.street', $store.state.locale)"
                        v-model="client.address.street"
                        outlined
                        dense
                        :disabled="!addAddressManual"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="menu"
                        hide-details
                      ></v-text-field>
                    </v-flex> 
                    <v-flex xs6 class="pa-1">
                      <v-text-field
                        :label="$t('general.city', $store.state.locale)"
                        v-model="client.address.city"
                        outlined
                        dense
                        :disabled="!addAddressManual"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="menu"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-1" v-if="clientAddressChanged">
                    <v-flex xs12 class="text-center">
                      <v-alert v-if="!addAddressManual && addressNotFound && !addressChanged" outlined class="ma-0 pa-2" dense type="warning">{{ $t('clients.addressNotFound', $store.state.locale) }}</v-alert>
                      <p :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }" v-if="!client.address.postalCode || !client.address.number"> <v-icon size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.getAddressHint', $store.state.locale) }}</i></p>
                      <v-btn class="mt-2" v-if="!addAddressManual && ((!addressNotFound && addressChanged)|| (addressNotFound && addressChanged))" :disabled="!client.address.postalCode || !client.address.number" outlined block small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="checkAddress">{{ $t('clients.getAddress', $store.state.locale) }}</v-btn>
                      <v-btn class="mt-2" outlined small block v-if="!addAddressManual && (client.address.country != 'netherlands' || !addressChanged && addressNotFound)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="!addAddressManual ? addAddressManual = true : addAddressManual = false">{{ $t('clients.addAddressManual', $store.state.locale) }}</v-btn>
                      <p v-if="addAddressManual"><i>{{ $t('clients.addressFoundManual', $store.state.locale) }}</i> <v-btn small icon @click="addAddressManual = false;"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-undo-variant</v-icon></v-btn></p>
                      <p v-if="addressChecked && !addAddressManual && !addressNotFound"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.addressFound', $store.state.locale) }}</i></p>
                    </v-flex>    
                  </v-layout>
                </div>

                <div v-if="client.address.coordinates && client.address.coordinates.length == 2">
                  <GmapMap
                    :center="{lat:client.address.coordinates[1], lng:client.address.coordinates[0]}"
                    :zoom="14"
                    :options="{
                      zoomControl: true,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      disableDefaultUi: false
                    }"
                    map-type-id="terrain"
                    style="width: 100%; height: 200px"
                    >
                    <gmap-custom-marker 
                      :marker="{lat:client.address.coordinates[1], lng:client.address.coordinates[0]}"
                      :clickable="false"
                      :draggable="false"
                      >
                      <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-map-marker</v-icon>
                    </gmap-custom-marker>

                  </GmapMap>
                </div>

                <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :style="{borderColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardOption}" class="mt-4" :class="$store.state.companyTheme">
                    <div class="pa-3 pb-0">
                      <v-row class="pa-0 ma-0">
                        <v-avatar
                          size="36"
                          light
                          style="float: left"
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email</v-icon>
                        </v-avatar>
                        <h3 class="pt-1 pb-4">{{ $t('general.emailaddress', $store.state.locale) }}</h3>
                        <v-spacer></v-spacer>
                        <v-btn fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addEmailaddress()"><v-icon>mdi-plus</v-icon></v-btn>
                      </v-row>

                      <v-divider></v-divider>
                    </div>

                    <div v-if="!client.emails || client.emails.length == 0" class="text-center pa-1 pt-0">
                      <v-avatar 
                        size="46"
                        light
                        class="ma-4 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email-multiple-outline</v-icon>
                      </v-avatar>
                      <p>{{ $t('clients.noEmailsAdded', $store.state.locale) }}</p>
                    </div>

                    <div v-if="client.emails && client.emails.length > 0">
                      <v-data-table
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :headers="emailHeaders"
                        :items-per-page="-1"
                        :items="client.emails"
                        hide-default-footer
                        style="background: none"
                        >
                        <template v-slot:item="{item}">
                          <tr>
                            <td>{{ item.email }}</td>
                            <td class="text-center">
                              <v-icon v-if="item.primary" :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
                              <v-btn @click="setEmailPrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                            </td>
                            <td>
                              <v-btn icon small @click="removeEmail(item)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                </v-card>

                <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :style="{borderColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardOption}" class="mt-4" :class="$store.state.companyTheme">
                    <div class="pa-3">
                      <v-row class="pa-0 ma-0">
                        <v-avatar
                          size="36"
                          light
                          style="float: left"
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone</v-icon>
                        </v-avatar>
                        <h3 class="pt-1 pb-4">{{ $t('general.phones', $store.state.locale) }}</h3>
                        <v-spacer></v-spacer>
                        <v-btn fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addPhonenumber()"><v-icon>mdi-plus</v-icon></v-btn>
                      </v-row>

                      <v-divider></v-divider>
                    </div>

                    <div v-if="!client.phones || client.phones.length == 0" class="text-center pa-1 pt-0">
                      <v-avatar 
                        size="46"
                        light
                        class="ma-4 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone-ring</v-icon>
                      </v-avatar>
                      <p>{{ $t('clients.noPhonenumbersAdded', $store.state.locale) }}</p>
                    </div>

                    <div v-if="client.phones && client.phones.length > 0">
                      <v-data-table
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :headers="phoneHeaders"
                        :items-per-page="-1"
                        :items="client.phones"
                        hide-default-footer
                        style="background: none"
                        >
                        <template v-slot:item="{item}">
                          <tr>
                            <td class="text-center">
                              <v-icon :size="18" v-if="item.type == 'mobile'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-cellphone</v-icon>
                              <v-icon :size="18" v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-deskphone</v-icon>
                            </td>
                            <td v-if="item.region == 'NL'">{{ item.phone.national }}</td>
                            <td v-else>{{ item.phone.international }}</td>
                            <td class="text-center">
                              <v-icon v-if="item.primary" :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
                              <v-btn @click="setPhonePrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                            </td>
                            <td>
                              <v-btn icon small @click="removePhone(item)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </v-card>

              </v-flex>
              <v-flex md6 xs12>
                <v-card outlined class="pa-3" style="width: 100%">
                  <h3 v-if="selectedLead.campaign"><strong>{{ campaignData.name }}</strong></h3>
                  <p class="ma-0 pt-1" v-if="selectedLead.campaign"><b>{{ $t('leadCampaign.goal', $store.state.locale) }}: </b> {{ getCampgaignType(campaignData.type) }}</p>
                  <p class="ma-0 pt-1" v-if="selectedLead.created"><b>{{ $t('leadCampaign.subscribeDate', $store.state.locale)}}: </b> {{ selectedLead.created.seconds | moment('dddd D MMMM YYYY - H:mm') }}u </p>
                  
                  <p v-if="selectedLead.source" class="ma-0 pt-1">
                    <b>{{ $t('leadCampaign.source', $store.state.locale) }}: </b>

                    <v-chip v-if="getLeadSource(selectedLead.source)" small :color="getLeadSource(selectedLead.source).bgColor.hex">
                      <span :style="{ color: getLeadSource(selectedLead.source).textColor.hex }">{{ getLeadSource(selectedLead.source).name }}</span>
                    </v-chip>
    
                    <v-tooltip v-else right style="cursor: pointer;">
                      <template v-slot:activator="{ on }">
                        <v-chip small color="#333" text-color="white" v-on="on">
                         Onbekend <v-icon right small >mdi-help-circle</v-icon>
                        </v-chip>                                              
                      </template>
                      <span style="color: #fff"> {{ $t('conversion.usedSource', $store.state.locale) }} {{ selectedLead.source }}</span>
                    </v-tooltip>
                  </p>
                  <p v-if="selectedLead.executedFrom" class="ma-0 pt-1"><b>{{ $t('leadCampaign.webpage', $store.state.locale) }}: </b> {{ selectedLead.executedFrom }}</p>

                  <p v-if="selectedLead.extra" class="ma-0 pt-1"><b>{{ $t('general.extra', $store.state.locale) }}: </b> {{ selectedLead.extra }}</p>
                </v-card>

                <v-select
                  class="mt-4"
                  :label="$t('leadCampaign.customSource', $store.state.locale)"
                  v-model="selectedLead.customSource"
                  :items="customSources"
                  :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                  item-value="id"
                  required
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  dense
                  v-on:change="saveCustomSource()"
                  background-color="background"
                ></v-select>

                <div v-if="leadQuestionsAnswered()" class="text-left mb-4">
                  <h3 class="client-overview-subtitle pt-2 pb-2">{{ $t("general.extraLeadInfo", $store.state.locale) }}</h3>
                  <div v-for="(question, index) in selectedLead.questions" :key="index">
                    <v-container v-if="question.type == 'yesNo' && question.value" class="pa-0 ma-0" fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col cols="8" class="text-left">
                          {{ question[userLanguage.toLowerCase()] }}
                        </v-col>
                        <v-col cols="4" class="text-right">
                          <v-btn-toggle :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                            <v-btn small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                              <span v-if="question.value == 'yes'" style="color: #fff">{{ userLanguage.toLowerCase() == "en" ? "Yes" : "Ja" }}</span>
                              <span v-if="question.value == 'no'" style="color: #fff">{{ userLanguage.toLowerCase() == "en" ? "No" : "Nee" }}</span>
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                    </v-container>

                    <div v-if="question.type == 'open' && question.value" class="text-left">
                      <v-textarea
                        class="mt-4"
                        :label="question[userLanguage.toLowerCase()]"
                        v-model="question.value"
                        outlined
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        readonly
                        rows="2"
                        hide-details
                      ></v-textarea>
                    </div>
                  </div>
                </div>

                <div class="text-center" v-if="!selectedLead.assignedTo || (selectedLead.actions && selectedLead.actions.length > 0 && selectedLead.actions[0].id != 'endLeadTracking')">
                  <h3 class="client-overview-subtitle pt-2 pb-2">{{ $t("leadCampaign.leadActions", $store.state.locale) }}</h3>

                  <v-select
                    v-if="!selectedLead.assignedTo"
                    outlined
                    class="mt-4"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    dense
                    background-color="background"
                    :items="employees"
                    item-text="name"
                    item-value="id"
                    v-on:input="setAssignAction()"
                    v-model="selectedLead.assignedTo"
                    :menu-props="{ maxHeight: '300' }"
                    :label="$t('leadCampaign.assignLeadTo', $store.state.locale)"
                    hide-details
                  ></v-select>

                  <v-container grid-list-md fluid class="ma-0 pa-0">
                    <v-layout row wrap class="ma-0 pa-0" justify-center>
                      <v-flex md3 d-flex v-for="(action, index) in availableActions" :key="index">
                        <v-card outlined @click="addNewAction(action)" elevation="6" style="width: 100%" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" class="action-card-button py-4 px-1">
                          <div class="text-center">
                            <v-icon aria-hidden="false" color="white">
                              {{ action.icon }}
                            </v-icon>
                          </div>
                          <div class="text-center mt-2" style="color: #fff; font-size: 12px; line-height: 14px">
                            {{ action.name[userLanguage.toLowerCase()] }}
                            <span
                              v-if="action.id == 'appointmentExecuted' || action.id == 'appointmentExecuted' || action.id == 'appointmentNotExecuted'"
                              style="text-transform: lowercase; color: #fff"
                            >
                              {{ getCampgaignType(campaignData.type) }}
                            </span>
                          </div>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </div>

                <div class="text-center mt-4">
                  <h3 class="client-overview-subtitle pt-2 pb-1">
                    {{ $t("leadCampaign.timeline", $store.state.locale) }}
                    <v-tooltip bottom v-if="selectedLead.actions && selectedLead.actions.length > 0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn  :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" icon text v-bind="attrs" v-on="on" @click="actionGoBackModal()">
                          <v-icon>mdi-reply</v-icon>
                        </v-btn>
                      </template>
                      <span class="white--text">{{ $t("leadCampaign.stepBack", $store.state.locale) }}</span>
                    </v-tooltip>
                  </h3>
                </div>

                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" class="pa-0 mt-2 px-3" style="width: 100%">
                  <v-timeline class="pa-0" dense>
                    <div v-if="selectedLead.actions && selectedLead.actions.length > 0">
                      <v-timeline-item v-for="(action, i) in selectedLead.actions" :key="i" small right :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  class="py-4">
                        <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  :style="{'borderColor': $themes[$store.state.companyTheme][$store.state.themeModus].primary + '!important'}" outlined>
                          <v-card-title class="py-0 px-2">
                            <v-list two-line class="pa-0 ma-0" style="width: 100%" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  disabled>
                              <v-list-item class="pa-0 ma-0">
                                <v-list-item-action class="ma-0 mr-2">
                                  <v-icon color="white">{{ action.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="pa-0 ma-0">
                                  <v-list-item-title class="white--text" style="font-weight: bold">
                                    <!-- <span class="white--text" style="font-weight: bold" v-if="action.id == 'appointmentExecuted'">{{ getCampgaignType(campaignData.type) }}</span> -->
                                    {{ action.nameResult[userLanguage.toLowerCase()] }}
                                    <span class="white--text" style="font-weight: bold" v-if="action.id == 'leadAssigned'">{{ getAssignedEmployee(selectedLead.assignedTo) }}</span>
                                  </v-list-item-title>
                                  <v-list-item-subtitle
                                    ><span class="white--text" style="font-size: 12px; line-height: 14px">{{ action.timestamp | moment("D MMMM YYYY H:mm") }}u</span></v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-title>

                          <v-card-text class="ma-0 pa-2 white text--primary">
                            <p v-if="action.leadInterest" class="ma-0 mb-2">
                              <b>{{ $t("leadCampaign.interest", $store.state.locale) }}:</b><br />
                              {{ action.leadInterest }}
                            </p>
                            <p v-if="action.leadGoals" class="ma-0 mb-2">
                              <b>{{ $t("leadCampaign.goals", $store.state.locale) }}:</b><br />
                              {{ action.leadGoals }}
                            </p>
                            <p v-if="action.leadGoalsExplaination" class="ma-0 mb-2">
                              <b>{{ $t("leadCampaign.motivation", $store.state.locale) }}:</b><br />
                              {{ action.leadGoalsExplaination }}
                            </p>
                            <p v-if="action.extra" class="ma-0 mb-2">
                              <b>{{ $t("leadCampaign.notes", $store.state.locale) }}:</b><br />
                              {{ action.extra }}
                            </p>

                            

                            <p v-if="action.leadObjection" class="ma-0 mb-1"><b>{{ $t("leadCampaign.reason", $store.state.locale) }}:</b>{{ getLeadObjection(action.leadObjection) }}</p>
                            <p v-if="action.leadObjectionExplaination" class="ma-0 mb-2">
                              <b>{{ $t("leadCampaign.explaination", $store.state.locale) }}:</b><br />
                              {{ action.leadObjectionExplaination }}
                            </p>
                            <p v-if="action.date" class="ma-0"><b>{{ $t("leadCampaign.scheduledFor", $store.state.locale) }}:</b> {{ action.date | moment("dddd D MMMM - H:mm") }}u</p>
                            <p class="ma-0"><b>{{ $t("leadCampaign.executedBy", $store.state.locale) }}:</b> {{ action.userName }}</p>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </div>

                    <v-timeline-item v-if="selectedLead.created" small right :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" class="py-4">
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :style="{'borderColor': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary + '!important'}" outlined>
                        <v-card-title class="py-0 px-2">
                          <v-list two-line class="pa-0 ma-0" style="width: 100%" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" disabled>
                            <v-list-item class="pa-0 ma-0">
                              <v-list-item-action class="ma-0 mr-2">
                                <v-icon color="white">mdi-information</v-icon>
                              </v-list-item-action>
                              <v-list-item-content class="pa-0 ma-0">
                                <v-list-item-title class="white--text"><strong>{{ $t("leadCampaign.leadRequestMade", $store.state.locale) }}</strong></v-list-item-title>
                                <v-list-item-subtitle
                                  ><span class="white--text" style="font-size: 12px; line-height: 14px">{{ selectedLead.created.seconds | moment("D MMMM YYYY H:mm") }}u</span></v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-title>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <!-- / Action Dialog \ -->
    <v-dialog v-model="actionDialog.show" persistent scrollable max-width="800px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card v-if="actionDialog.actions && actionDialog.actions.length > 0">
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2 v-if="actionDialog.actions[0].name">
            {{ actionDialog.actions[0].name[userLanguage.toLowerCase()] }}
            <span v-if="actionDialog.actions[0].id == 'notReachable' || actionDialog.actions[0].id == 'conversationCallback'">
              {{ $t("leadCampaign.scheduleNewCall", $store.state.locale) }}
            </span>
            <span style="text-transform: lowercase" v-if="actionDialog.actions[0].id == 'appointmentNotExecuted' || actionDialog.actions[0].id == 'appointmentExecuted'">
              {{ getCampgaignType(campaignData.type) }}</span
            >
          </h2>
          <v-spacer></v-spacer>
          <v-btn v-if="!savingLead" icon dark @click="closeActionDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="actionDialog.feedback" class="ma-0 pa-2" v-html="actionDialog.feedback" style="border-radius: 0" type="warning"></v-alert>

        <v-divider></v-divider>

        <v-card-text class="ma-0 pa-0" style="overflow-x: hidden">
          <v-container v-if="actionDialog.actions[0].id == 'phoneCallSuccess'" grid-list-md class="pa-0" fluid>

          </v-container>

          <div
            v-else-if="
              actionDialog.actions[0].id == 'leadBecomesNoCustomer' ||
              actionDialog.actions[0].id == 'notInterested' ||
              actionDialog.actions[0].id == 'notReachableStop' ||
              actionDialog.actions[0].id == 'endLeadTracking'
            "
            class="pa-4"
          >
            <v-select
              outlined
              class="mt-4"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              dense
              background-color="background"
              :items="leadObjections"
              :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
              item-value="id"
              v-model="actionDialog.actions[0].leadObjection"
              :label="$t('leadCampaign.leadObjections', $store.state.locale)"
              hide-details
            ></v-select>

            <v-textarea
              class="mt-4"
              :label="$t('leadCampaign.leadObjectionsExtra', $store.state.locale)"
              v-model="actionDialog.actions[0].leadObjectionExplaination"
              background-color="background"
              outlined
              rows="3"
              hide-details
            ></v-textarea>
          </div>

          <div v-else-if="actionDialog.actions[0].id == 'appointmentExecuted'" class="pa-4">
            <v-text-field
              class="mt-4"
              :label="$t('leadCampaign.leadInterest', $store.state.locale)"
              v-model="actionDialog.actions[0].leadInterest"
              background-color="background"
              outlined
              rows="3"
              hide-details
            ></v-text-field>
            <v-textarea
              class="mt-4"
              :label="$t('leadCampaign.leadResults', $store.state.locale)"
              v-model="actionDialog.actions[0].leadGoals"
              background-color="background"
              outlined
              rows="3"
              hide-details
            ></v-textarea>
            <v-textarea
              class="mt-4"
              :label="$t('leadCampaign.leadResultMotication', $store.state.locale)"
              v-model="actionDialog.actions[0].leadGoalsExplaination"
              background-color="background"
              outlined
              rows="3"
              hide-details
            ></v-textarea>
            <v-textarea
              class="mt-4"
              :label="$t('leadCampaign.leadComments', $store.state.locale)"
              v-model="actionDialog.actions[0].extra"
              background-color="background"
              outlined
              rows="3"
              hide-details
            ></v-textarea>
          </div>

          <div
            v-else-if="
              actionDialog.actions[0].id == 'schedulePhoneCall' ||
              actionDialog.actions[0].id == 'scheduleFollowUpCall' ||
              actionDialog.actions[0].id == 'notReachable' ||
              actionDialog.actions[0].id == 'conversationCallback'"
            style="max-height: 600px"
          >
            <v-row>
              <v-col sm="3" class="text-left"></v-col>
              <v-col sm="6" class="text-center">
                <h3 class="pa-2">
                  <strong>{{ getAssignedEmployee(selectedLead.assignedTo) }}</strong>
                </h3>
              </v-col>
              <v-col sm="3" class="text-right">
                <v-btn class="ma-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  :disabled="selectedDate == today" icon text @click="previousDays()">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn class="ma-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  icon text @click="nextDays()">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-sheet height="400" class="custom-calendar">
              <v-calendar
                v-if="!employeeEventsLoading"
                ref="calendar"
                :now="today"
                :value="selectedDate"
                :events="employeeEvents"
                :event-color="getEventColor"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                type="4day"
                :first-interval="12"
                :interval-count="34"
                :interval-height="96"
                :interval-minutes="30"
                :interval-format="intervalFormat"
                @change="getEvents"
                @mousedown:event="startDrag"
                @mousedown:time="startTime"
                @mousemove:time="mouseMove"
                @mouseup:time="endDrag"
                @mouseleave.native="cancelDrag"
              >
                <template v-slot:day-label-header="{ date, present }">
                  <template class="text-center">
                    <v-row>
                      <v-col sm="12" class="text-center">
                        <div style="text-transform: uppercase; font-size: 11px" :class="{ 'custom-calendar-today': present }">
                          {{ date | moment("dddd") }}
                        </div>
                        <div style="font-weight: bold" :class="{ 'custom-calendar-today': present }">{{ date | moment("D MMMM") }}</div>
                      </v-col>
                    </v-row>
                  </template>
                </template>

                <template v-slot:day-body>
                  <div class="v-current-time" :style="{ top: nowY }"></div>
                </template>

                <template v-slot:event="{ event }">
                  <div class="event-info-wrapper">
                    <div class="event-info-banner">
                      <div class="event-info-banner-time">{{ event.start | moment("H:mm") }}</div>
                      <div class="event-info-banner-title">{{ event.title }}</div>
                      <div :class="{ 'preferred-by-employee': event.preferredEmployeeByClient }"></div>
                      <div v-if="event.source == 'widget'" class="event-source" :style="{ 'background-color': event.eventColor }">O</div>
                      <div v-if="event.source == 'app'" class="event-source" :style="{ 'background-color': event.eventColor }">A</div>
                      <div v-if="event.source == 'personal'" class="event-source" :style="{ 'background-color': event.eventColor }">P</div>
                      <div v-if="event.source == 'telephone'" class="event-source" :style="{ 'background-color': event.eventColor }">T</div>
                    </div>
                    <div class="event-info-description">
                      {{ event.description }}
                    </div>
                  </div>
                </template>
              </v-calendar>

              <div v-else class="thrive-loader" :class="$store.state.companyTheme"></div>
            </v-sheet>
          </div>

          <v-container v-else grid-list-md class="pa-4" fluid>
            <v-layout row wrap d-flex>
              <v-flex :class="leadInfoGrid">
                <v-card flat color="background" class="pa-2" style="width: 100%">
                  <h3 class="client-overview-subtitle pt-2 pb-2">{{ $t("leadCampaign.leadInfo", $store.state.locale) }}</h3>
                  <v-container grid-list-md class="pa-0" fluid>
                    <v-layout row wrap>
                      <v-flex xs6>
                        <v-text-field
                          class="ma-0 pa-0 mt-1"
                          :label="$t('general.firstName', $store.state.locale)"
                          v-model="selectedLead.clientInfo.firstName"
                          outlined
                          readonly
                          dense
                          disabled
                          background-color="menu"
                          hide-details
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs6>
                        <v-text-field
                          class="ma-0 pa-0 mt-1"
                          :label="$t('general.lastName', $store.state.locale)"
                          v-model="selectedLead.clientInfo.lastName"
                          outlined
                          readonly
                          dense
                          disabled
                          background-color="menu"
                          hide-details
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs6>
                        <v-text-field
                          class="ma-0 pa-0 mt-1"
                          :label="$t('general.phone', $store.state.locale)"
                          v-model="selectedLead.clientInfo.phone"
                          autocomplete
                          readonly
                          dense
                          disabled
                          required
                          outlined
                          background-color="menu"
                          hide-details
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs6>
                        <v-select
                          class="mt-1"
                          item-text="name"
                          item-value="id"
                          :items="languages"
                          v-model="selectedLead.language"
                          :label="$t('general.language', $store.state.locale)"
                          hide-details
                          outlined
                          readonly
                          dense
                          disabled
                          persistent-hint
                          background-color="menu"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        >
                          <template v-slot:prepend-inner>
                            <div class="dense" style="margin: 5px 0 0 0; display: inline-flex; height: 24px">
                              <div v-if="selectedLead.language == 'en'" class="language-flag uk"></div>
                              <div v-if="selectedLead.language == 'nl'" class="language-flag nl"></div>
                            </div>
                          </template>
                        </v-select>
                      </v-flex>

                      <v-flex xs12>
                        <v-text-field
                          class="ma-0 pa-0 mt-1"
                          :label="$t('general.email', $store.state.locale)"
                          v-model="selectedLead.clientInfo.email"
                          autocomplete
                          required
                          outlined
                          readonly
                          dense
                          disabled
                          background-color="menu"
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>

                <div v-if="leadQuestionsAnswered()" class="text-left mb-4">
                  <h3 class="client-overview-subtitle pt-2 pb-2">{{ $t("general.extraLeadInfo", $store.state.locale) }}</h3>
                  <div v-for="(question, index) in selectedLead.questions" :key="index">
                    <v-container v-if="question.type == 'yesNo' && question.value" class="pa-0 ma-0" fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col cols="8" class="text-left">
                          {{ question[userLanguage.toLowerCase()] }}
                        </v-col>
                        <v-col cols="4" class="text-right">
                          <v-btn-toggle :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                            <v-btn small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                              <span v-if="question.value == 'yes'" style="color: #fff">{{ userLanguage.toLowerCase() == "en" ? "Yes" : "Ja" }}</span>
                              <span v-if="question.value == 'no'" style="color: #fff">{{ userLanguage.toLowerCase() == "en" ? "No" : "Nee" }}</span>
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                    </v-container>

                    <div v-if="question.type == 'open' && question.value" class="text-left">
                      <v-textarea
                        class="mt-4"
                        :label="question[userLanguage.toLowerCase()]"
                        v-model="question.value"
                        background-color="background"
                        outlined
                        readonly
                        rows="2"
                        hide-details
                      ></v-textarea>
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-flex xs4 d-flex v-if="actionDialog.actions[0].id == 'directPhoneCall' && campaignData && campaignData.customCallScripts">
                <v-card outlined class="pa-2" style="width: 100%">
                  <b>{{ $t("leadCampaign.callscript", $store.state.locale) }}</b>
                  <div v-html="campaignData.customCallScripts.start"></div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0 text-center">
            <div>
              <h3 class="client-overview-subtitle px-3 py-2" style="display: inline-block">{{ $t("leadCampaign.leadActions", $store.state.locale) }}</h3>
              <v-tooltip bottom v-if="actionDialog.actions && actionDialog.actions.length > 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" icon text v-bind="attrs" v-on="on" @click="dialogActionGoBack()">
                    <v-icon>mdi-reply</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">{{ $t("leadCampaign.stepBack", $store.state.locale) }}</span>
              </v-tooltip>
            </div>

            <v-layout
              v-if="
                actionDialog.actions[0].id == 'schedulePhoneCall' ||
                actionDialog.actions[0].id == 'scheduleFollowUpCall' ||
                actionDialog.actions[0].id == 'notReachable' ||
                actionDialog.actions[0].id == 'conversationCallback' ||
                actionDialog.actions[0].id == 'phoneCallSuccess' ||
                actionDialog.actions[0].id == 'endLeadTracking'
              "
              row
              wrap
              class="ma-0 pa-0"
              justify-center
            >
              <v-flex md3 d-flex>
                <v-card :link="false" v-if="!newDatePlanned && !actionDialog.actions[0].id == 'endLeadTracking'" outlined elevation="0" style="width: 100%" color="#ccc" class="action-card-button py-4 px-1">
                  <div class="text-center">
                    <v-icon aria-hidden="false" color="white">mdi-calendar-check</v-icon>
                  </div>
                  <div class="text-center mt-2" style="color: #fff; font-size: 12px; line-height: 14px">{{ [userLanguage.toLowerCase()] == "en" ? "Save" : " Opslaan" }}</div>
                </v-card>
                <v-card :link="false" v-else outlined @click="addSaveAction(actionDialog.actions[0].id)" elevation="6" style="width: 100%" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" class="action-card-button py-4 px-1">
                  <div class="text-center">
                    <v-icon aria-hidden="false" color="white">mdi-calendar-check</v-icon>
                  </div>
                  <div class="text-center mt-2" style="color: #fff; font-size: 12px; line-height: 14px">{{ [userLanguage.toLowerCase()] == "en" ? "Save" : " Opslaan" }}</div>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout v-else row wrap class="ma-0 pa-0" justify-center>
              <v-flex md3 d-flex v-for="(action, index) in availableDialogActions" :key="index">
                <v-card outlined @click="addNewAction(action)" elevation="6" style="width: 100%" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" class="action-card-button py-4 px-1">
                  <div class="text-center">
                    <v-icon aria-hidden="false" color="white">
                      {{ action.icon }}
                    </v-icon>
                  </div>
                  <div class="text-center mt-2" style="color: #fff; font-size: 12px; line-height: 14px">
                    {{ action.name[userLanguage.toLowerCase()] }}
                    <span style="text-transform: lowercase; color: #fff" v-if="action.id == 'appointmentExecuted' || action.id == 'appointmentNotExecuted'">
                      {{ getCampgaignType(campaignData.type) }}
                    </span>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- \ Action Dialog / -->

    <!-- / Delete Action Dialog \ -->
    <v-dialog v-model="goBackModal" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t("leadCampaign.stepBack", $store.state.locale) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="goBackModal = false">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div class="text-center">{{ $t("leadCampaign.stepBackText", $store.state.locale) }}</div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed color="tertiary" @click="goBackModal = false">
                  {{ $t("general.cancel", $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="actionGoBack()">
                  {{ $t("leadCampaign.goBack", $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Action Dialog / -->

    <!-- / Delete Action Dialog \ -->
    <v-dialog v-model="deleteLeadModal" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pa-2 pl-4" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader}">
          <h3>{{ $t("leadCampaign.deleteLeadStepBack", $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="deleteLeadModal = false" :disabled="deletingLead">
            <v-icon dark color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div class="text-center">{{ $t("leadCampaign.deleteLeadStepBackText", $store.state.locale) }}</div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="deletingLead" color="tertiary" @click="deleteLeadModal = false">
                  {{ $t("general.cancel", $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark rounded depressed :loading="deletingLead" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleteLead()">
                  {{ $t("leadCampaign.deleteLead", $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Action Dialog / -->

    <!-- / Delete Action Dialog \ -->
    <v-dialog v-model="scheduleAppointmentModal" scrollable fullscreen width="400" content-class="custom-shape">
      <v-card>
        <v-card-title class="pa-1 pl-4" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs4 class="text-left"><h2>Afspraak plannen in agenda</h2></v-flex>
              <v-flex xs4 class="text-center"><span style="color: #fff; font-size: 14px">{{ calendarModalTime }}</span></v-flex>
              <v-flex xs4 class="text-right">
                <v-btn icon dark @click="closeScheduleAppointmentModal()" :disabled="deletingLead">
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" >mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="ma-0 pa-0" v-if="scheduleAppointmentModal">
          <component :is="dynamicComponent" 
          :viewType="'scheduleLead'" 
          :leadId="selectedLead.id"
          :trialTreatmentId="trialTreatmentId"
          :clientId="client.id"
          ></component>
        </v-card-text>

        <v-divider></v-divider>

        <!-- <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="deletingLead" color="tertiary" @click="deleteLeadModal = false">
                  {{ $t("general.cancel", $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark rounded :loading="deletingLead" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" @click="deleteLead()">
                  {{ $t("leadCampaign.deleteLead", $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <!-- \ Delete Action Dialog / -->

    <!-- / New Phonenumber Dialog \ -->
      <add-phonenumber-dialog :type="'leadInformation'" @newPhoneNumber="addNewPhonenumber($event)"></add-phonenumber-dialog>
    <!-- \ New Phonenumber Dialog / --> 
    
    <!-- / New Email Dialog \ -->
      <add-emailaddress-dialog :type="'leadInformation'" @newEmailaddress="addNewEmailaddress($event)"></add-emailaddress-dialog>
    <!-- \ New Email Dialog / -->    
  </div>
</template>

<script>
  import db from "../../firebase/init";
  import { bus } from "../../main";
  import moment from "moment";
  import axios from "axios";
  import GmapCustomMarker from 'vue2-gmap-custom-marker';
  import AddPhonenumber from "../modals/AddPhoneNumber.vue";
import AddEmailaddress from "../modals/AddEmailaddress.vue";

  export default {
    name: "EditLeadForm",
    props: {
      branches: Array,
      customSources: Array
    },
    components: {
      'gmap-custom-marker': GmapCustomMarker,
      "add-phonenumber-dialog": AddPhonenumber,
      "add-emailaddress-dialog": AddEmailaddress,
    },

    data() {
      return {
        snackbar: {
          active: false,
          timeout: 2500,
          message: null,
          status: null,
          icon: null,
        },
        scheduleAppointmentModal: false,
        showDialog: false,
        actionDialog: {
          show: false,
          type: null,
          actions: new Array(),
          feedback: null,
        },
        calendarModalTime: null,
        employees: new Array(),
        today: null,
        selectedDate: null,
        timeNow: null,
        actionType: null,
        feedback: null,
        savingLead: false,
        availableActions: new Array(),
        availableDialogActions: new Array(),
        addAddressManual: false,
        addressNotFound: false,
        clientAddressChecked: new Object(),
        addressChecked: false,
        campaignTypes: [
          {
            name: {
              en: "Trial lesson",
              nl: "Proefles",
              de: "Probe Lektion"
            },
            id: "trialLesson",
          },
          {
            name: {
              en: "Trial treatment",
              nl: "Proefbehandeling",
              de: "Probebehandlung"
            },
            id: "trialTreatment",
          },
          {
            name: {
              en: "consultation",
              nl: "Consult",
              de: "Beratung",
            },
            id: "consultation",
          },
          {
            name: {
              en: "Guided tour",
              nl: "Rondleiding",
              de: "Besichtigung"
            },
            id: "guidedTour",
          },
        ],
        phoneHeaders: [
          {
            text: this.$t('general.type', this.$store.state.locale),
            align: "center",
            value: "type",
            width: "50px",
            sortable: false,
          },
          {
            text: this.$t('general.phone', this.$store.state.locale),
            align: "left",
            value: "phone",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "40px",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "40px",
            sortable: false,
          }  
        ],
        emailHeaders: [
          {
            text: this.$t('general.email', this.$store.state.locale),
            align: "left",
            value: "email",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "40px",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "40px",
            sortable: false,
          }  
        ],
        leadObjections: [
          {
            id: "noContact",
            name: {
              nl: "Geen contact mee te krijgen",
              en: "Can't get in touch",
              de: "Kontakt nicht möglich"
            },
          },
          {
            id: "notAuthorized",
            name: {
              nl: "Lead niet bevoegd om te tekenen",
              en: "Lead not authorized to sign",
              de: "Lead nicht zeichnungsberechtigt"
            },
          },
          {
            id: "medicalReasons",
            name: {
              nl: "Medische redenen",
              en: "Medical reasons",
              de: "Medizinische Gründe"
            },
          },
          {
            id: "thinkAboutIt",
            name: {
              nl: "Nog even nadenken",
              en: "Need time to think about it",
              de: "Brauche Bedenkzeit"
            },
          },
          {
            id: "customerOtherCompany",
            name: {
              nl: "Nog lid/klant bij een ander bedrijf",
              en: "Still member/customer at another company",
              de: "Noch Mitglied/Kunde bei einer anderen Firma"
            },
          },
          {
            id: "toExpensive",
            name: {
              nl: "Te duur",
              en: "To expensive",
              de: "Zu teuer"
            },
          },
          {
            id: "needConsultation",
            name: {
              nl: "Moet overleggen met iemand anders",
              en: "Need to consult with someone else",
              de: "Brauchen Sie Rücksprache mit jemand anderem"
            },
          },
          {
            id: "noMotivation",
            name: {
              nl: "Geen of weinig motivatie",
              en: "Little or no motivation",
              de: "Wenig bis keine Motivation"
            },
          },
          {
            id: "noTime",
            name: {
              nl: "Geen tijd",
              en: "No time",
              de: "Keine Zeit"
            },
          },
          {
            id: "unknown",
            name: {
              nl: "Onbekend",
              en: "Unknown",
              de: "Unbekannt"
            },
          },
        ],
        deleteLeadModal: false,
        deletingLead: false,
        employeeEvents: new Array(),
        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
        nowY: "-10px",
        newDatePlanned: false,
        scheduleAppointmentType: null,
        employeeEventsLoading: true,
        goBackModal: false,
        today: moment().format("YYYY-MM-DD"),
        campaignData: {},
        selectedLead: {},
        selectedLeadOriginal: {},
        client: {
          emailSubscriptions: {},
          health: {
            macronutrients: {},
          },
          address: {},
          permissions: {},
        },
        clientOriginal: {
          emailSubscriptions: {},
          health: {
            macronutrients: {},
          },
          address: {},
          permissions: {},
        },
        birthdayDateDialog: false,
        birthdayDateRaw: null,
        clientTypes: [
          {
            name: "Privé",
            id: "person",
          },
          {
            name: "Zakelijk",
            id: "company",
          },
        ],
        genders: [
          {
            id: 1,
            name: "Man",
          },
          {
            id: 2,
            name: "Vrouw",
          },
        ],
        languages: [
          {
            name: "Nederlands",
            id: "nl",
          },
          {
            name: "Engels",
            id: "en",
          },
        ],

        // generalTags: [
        //   {
        //     name: "Adwords",
        //     query: "adw",
        //     copy: false,
        //     bgColor: {
        //       hex: "#1a73e8",
        //     },
        //     textColor: {
        //       hex: "#fff",
        //     },
        //   },
        //   {
        //     name: "Mailchimp",
        //     query: "mc",
        //     copy: false,
        //     color: {
        //       hex: "#ffe01b",
        //     },
        //     textColor: {
        //       hex: "#333",
        //     },
        //   },
        //   {
        //     query: "walkIn",
        //     name: "Walk-in",
        //     copy: false,
        //     bgColor: {
        //       hex: "#000000",
        //     },
        //     textColor: {
        //       hex: "#fff",
        //     },
        //   },
        //   {
        //     query: "callIn",
        //     name: "Call-in",
        //     copy: false,
        //     bgColor: {
        //       hex: "#000000",
        //     },
        //     textColor: {
        //       hex: "#fff",
        //     },
        //   },
        //   {
        //     query: "mailIn",
        //     name: "Mail-in",
        //     copy: false,
        //     bgColor: {
        //       hex: "#000000",
        //     },
        //     textColor: {
        //       hex: "#fff",
        //     },
        //   },
        // ],

        dialogType: null,

        // availabilityApiData
        cacheToken: null,
        availability: [],
        availabilityMonth: [],
        activeMonthRaw: null,
        activeMonth: null,
        showingMonthIsNotCurrentMonth: null,
        startFrom: null,
        availabilityLoading: true,
        availabilityLoadingExtra: true,
        numberOfDays: 0,
        selectedEmployeeApi: 0,
        employeesApi: new Array(),
        firstPossibleDay: null,
        timeslots: null,
        treatmentDateSelected: false,
        selectedTimeTreatment: null,
        selectedDateTreatment: null,
      };
    },

    created() {
      bus.$on("localTimeLeadScheduleChanged",(time)=>{
        this.calendarModalTime = time;
      })
      bus.$on("trialTreatmentBooked",(id, title, date)=>{
        this.addSaveAction('phoneCallSuccess', id, title, date)
      })

      bus.$on("editLead", (type, lead) => {
        let leadData = null;
        this.loadResources();
        new Promise((resolve) => {
          if (type == "home") {
            // Dialog is opened from Home page
            this.dialogType = "home";
            leadData = lead;
            resolve();
          } else if (type == "calendar") {
            // Dialog is opened from Calendar Page
            this.loadLeadData(lead).then((data) => {
              this.dialogType = "calendar";
              leadData = data;
              resolve();
            });
          }
        })
        .then(() => {
          this.selectedLead = _.cloneDeep(leadData);
          this.selectedLeadOriginal = _.cloneDeep(leadData);
          this.showDialog = true;
          this.savingLead = false;
          this.campaignData = {};
          this.loadCampaignData();
          this.loadClientData();
          this.getAvailableActions();
          this.selectedDate = moment().format("YYYY-MM-DD");
          this.timeNow = moment().format("HH:mm");
          this.today = moment().format("YYYY-MM-DD");
        });
      });
    },

    watch: {
      birthdayDateDialog(val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      },
    },

    methods: {
      closeEditLeadDialog() {
        this.showDialog = false;
        this.client = {
          emailSubscriptions: {},
          health: {
            macronutrients: {},
          },
          address: {},
          permissions: {},
        };
        this.clientOriginal = _.cloneDeep(this.client);
      },

      intervalFormat(interval) {
        return interval.time;
      },

      closeActionDialog() {
        this.actionDialog = {
          show: false,
          type: null,
          actions: new Array(),
        };
        this.availableDialogActions = new Array();
      },

      async loadLeadData(lead) {
        let data = null;
        await db
          .collection("leads")
          .doc(lead)
          .get()
          .then((doc) => {
            data = doc.data();
            data.id = doc.id;
          })
          .catch((error) => {
            console.error("Error bij laden leaddata: ", error);
          });
        return data;
      },

      loadResources() {
        this.employees = new Array();
        db.collection("employees")
          .where("branches", "array-contains", this.$store.state.activeBranch.id)
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let employee = doc.data();
              employee.id = doc.id;
              this.employees.push(employee);
            });
          })
          .catch((error) => {
            console.error("Error bij laden employees: ", error);
          });
      },

      loadCampaignData() {
        if (this.selectedLead.campaign) {
          db.collection("leadCampaigns")
          .doc(this.selectedLead.campaign)
          .get()
          .then((doc) => {
            this.campaignData = doc.data();
            this.campaignData.id = doc.id;
            this.campaignData.treatmentData = null;
          })
          .then(() => {
            if (this.campaignData.treatment) {
              db.collection("treatments")
              .doc(this.campaignData.treatment)
              .get()
              .then((doc) => {
                this.campaignData.treatmentData = doc.data();
              })
              .catch((error) => {
                console.error("Error bij laden campaign treatment: ", error);
              });
            }
          })
          .catch((error) => {
            console.error("Error bij laden campaign: ", error);
          });
        }
      },

      leadQuestionsAnswered() {
        if (this.selectedLead.questions && this.selectedLead.questions.length > 0) {
          let anwseredQuestions = 0;
          this.selectedLead.questions.forEach((question) => {
            if (question.value) {
              anwseredQuestions++;
            }
          });
          return anwseredQuestions > 0 ? true : false;
        } else {
          return false;
        }
      },

      loadClientData() {
        if(this.selectedLead.clientId){
          db.collection("clients")
          .doc(this.selectedLead.clientId)
          .get()
          .then((doc) => {
            this.client = doc.data();
            this.client.id = doc.id;
            this.clientOriginal = _.cloneDeep(this.client);
          })
          .catch((error) => {
            console.error("Error bij laden campaign: ", error);
          });
        }
        
      },

      checkAddress(){
      if(this.client.address.country && this.client.address.postalCode && this.client.address.number){
        this.addressChecked = false;
        this.addressNotFound = false;
        this.clientAddressChecked = {
          postalcode: _.cloneDeep(this.client.address.postalCode),
          number: _.cloneDeep(this.client.address.number )
        }
        let url = `${this.$store.state.locationsApi.url}/getLocation?country=${this.client.address.country}&postalcode=${this.client.address.postalCode}&number=${this.client.address.number}`;
        axios
        .get(url)
        .then((response) => {
          console.log("response: ", response, url)
          if(response.status == 200 && response.data){
            this.client.address.city = response.data.city;
            this.client.address.street = response.data.street;
            this.client.address.coordinates = response.data.coordinates;       
          }
          else{
            this.addressNotFound = true;
          }
        })
        .catch(error => {
          console.error("Postcode API error: ", error);
          this.addressNotFound = true;
        })
        this.addressChecked = true;
      }
    },

      getCampgaignType(id, lang, caseType) {
        let language = lang ? lang : this.userLanguage.toLowerCase();
        let type = id ? this.campaignTypes.find((element) => element.id == id) : null;
        if (caseType && caseType == "lowercase") {
          return type ? type.name[language].toLowerCase() : null;
        } else {
          return type ? type.name[language] : null;
        }
      },

      getLeadObjection(id) {
        let objection = id ? this.leadObjections.find((element) => element.id == id) : null;
        return objection ? objection.name[this.userLanguage.toLowerCase()] : null;
      },

      getAssignedEmployee(id) {
        let employee = id ? this.employees.find((element) => element.id == id) : null;
        return employee ? employee.name : null;
      },

      getTreatmentEmployee(id) {
        let employee = this.employeesApi.find((element) => element.id == id);
        return employee ? employee.name : null;
      },

      getBranchData(id) {
        let branch = id ? this.branches.find((branch) => branch.id == id) : null;
        return branch ? branch : new Object();
      },

      saveCustomSource() {
        db.collection("leads")
          .doc(this.selectedLead.id)
          .set(
            {
              customSource: this.selectedLead.customSource,
              updated: new Date()
            },
            { merge: true }
          )
          .then(() => {
            this.notify(this.$t("leadCampaign.saved", this.$store.state.locale), "success");
          })
          .catch((error) => {
            this.notify(this.$t("leadCampaign.savingError", this.$store.state.locale), "error");
            console.error("Error updating Lead : ", error);
          });
      },

      notify(message, status) {
        this.snackbar.active = true;
        this.snackbar.status = status == "success" ? "rgba(1, 230, 110, 0.85)" : "rgba(213, 0, 0, 0.85)";
        this.snackbar.icon = status == "success" ? "mdi-thumb-up" : "mdi-thumb-down";
        this.snackbar.message = message;
      },

      getLeadSource(source) {
        if (source) {
          let generalTagSource = _.find(this.$leadTags, ["query", source]);

          return generalTagSource ? generalTagSource : _.find(this.$store.state.activeCompany.conversion.sources, ["query", source]);
        } else {
          return null;
        }
      },

      addEmailaddress(){
      bus.$emit('addEmailDialog', this.client.emails, "leadInformation");
    },

    addNewEmailaddress(data){
      this.client.emails.push(data);
    },

    removeEmail(item){
      this.client.emails = this.client.emails.filter(email => {return email.email != item.email})
      this.checkForPrimaryEmail()
    },

    async checkForPrimaryEmail(){
      let primary = this.client.emails.filter(email => {return email.primary})
      if(!primary || primary.length == 0){
        this.setEmailPrimary(this.client.emails[0])
      }
    },
    
    setEmailPrimary(item){
      this.client.emails.map(email => {
        email.email == item.email ? email.primary = true : email.primary = false })
    },
    

    addPhonenumber(){
      bus.$emit('addPhonenumberDialog', this.client.phones, "leadInformation");
    },

    addNewPhonenumber(data){
      this.client.phones.push(data)
    },

    removePhone(item){
      this.client.phones = this.client.phones.filter(phone => {return phone.phone.e164 != item.phone.e164})
      this.checkForPrimaryNumber()
    },

    

    async checkForPrimaryNumber(){
      let primary = this.client.phones.filter(phone => {return phone.primary})
      if(!primary || primary.length == 0){
        let mobilePhones = this.client.phones.filter(phone => {return phone.type == 'mobile'})
        if(!mobilePhones || primary.mobilePhones == 0){
          this.setPhonePrimary(this.client.phones[0])
        }
        else{
          this.setPhonePrimary(mobilePhones[0])
        }
      }
    },
    
    setPhonePrimary(item){
      this.client.phones.map(phone => {
        phone.phone.e164 == item.phone.e164 ? phone.primary = true : phone.primary = false })
    },

      updateClientData() {
        this.savingLead = true;
        db.collection("clients")
          .doc(this.selectedLead.clientId)
          .set(
            {
              address: this.client.address,
              name: this.client.name,
              surname: this.client.surname,
              notes: this.client.notes ? this.client.notes : "",
              language: this.client.language ? this.client.language : null,
              gender: this.client.gender ? this.client.gender : null,
              birthday: this.birthdayDate.firebase ? this.birthdayDate.firebase : null,
            },
            { merge: true }
          )
          .then(() => {
            this.clientOriginal = _.cloneDeep(this.client);
            this.savingLead = false;
            this.notify(this.$t("leadCampaign.saved", this.$store.state.locale), "success");
            console.log("Lead succesfully updated");
          })
          .catch((error) => {
            this.savingLead = false;
            this.notify(this.$t("leadCampaign.savingError", this.$store.state.locale), "error");
            console.error("Error updating Lead : ", error);
          });
      },

      setAssignAction() {
        this.selectedLead.actions = this.selectedLead.actions ? this.selectedLead.actions : [];
        this.addNewAction({
          id: "leadAssigned",
          icon: "mdi-account-plus",
          save: true,
          nameResult: {
            nl: "Lead toegewezen aan",
            en: "Lead assigned to",
          },
        });
        this.getAvailableActions();
      },

      addNewAction(action) {
        this.actionDialog.feedback = null;
        let newAction = action;
        newAction.timestamp = moment().format("YYYY-MM-DDTHH:mm");
        newAction.userName = `${this.userFirstName} ${this.userLastName}`;

        if (newAction.id != "leadAssigned") {
          this.actionDialog.show = true;
          this.actionDialog.type = newAction.id;
          this.actionDialog.actions.unshift(newAction);
          this.getAvailableDialogActions();

          if (newAction.id == "schedulePhoneCall" || newAction.id == "scheduleFollowUpCall" || newAction.id == "notReachable" || newAction.id == "conversationCallback") {
            if (this.actionDialog.actions.length > 1 && this.actionDialog.actions[1].id == "leadBecomesNoCustomer") {
              if (this.actionDialog.actions[1].leadObjection) {
                this.getEmployeeEvents(this.today);
              }
              else {
                this.actionDialog.actions.shift();
                this.getAvailableDialogActions();
                this.actionDialog.feedback = this.$t("leadCampaign.feedbackLeadBecomesCustomer", this.$store.state.locale);
              }
            }
            else{
              this.getEmployeeEvents(this.today);
            }
          }
          // else if(newAction.id == "directPhoneCall" && this.campaignData.treatment){

          // }
          // else if(newAction.id == "directPhoneCall"){

          // }
          else if (newAction.id == "phoneCallSuccess" || newAction.id == "scheduleFollowUpAppointment") {
            // Schedule trough Calendar Popup
            if (this.actionDialog.actions.length > 1 && this.actionDialog.actions[1].id == "leadBecomesNoCustomer") {
              if (this.actionDialog.actions[1].leadObjection) {
                this.scheduleAppointmentModal = true;
              }
              else {
                this.actionDialog.actions.shift();
                this.getAvailableDialogActions();
                this.actionDialog.feedback = this.$t("leadCampaign.feedbackLeadBecomesCustomer", this.$store.state.locale);
              }
            }
            else{
              this.scheduleAppointmentModal = true;
            }
          }

          else if (newAction.id == "endLeadTracking" || (this.actionDialog.actions.length > 1 && this.actionDialog.actions[1].id == "leadBecomesNoCustomer")) {
            if (this.actionDialog.actions.length > 1 && this.actionDialog.actions[1].leadObjection) {
              this.selectedLead.actions = this.actionDialog.actions.concat(this.selectedLead.actions);
              this.actionDialog.show = false;
              this.actionDialog.type = null;
              this.actionDialog.actions = new Array();
              this.updateLeadActions();
              this.getAvailableActions();
            }
            else if(this.actionDialog.actions.length == 1){
              
            }
            else {
              this.actionDialog.actions.shift();
              this.getAvailableDialogActions();
              this.actionDialog.feedback = this.$t("leadCampaign.feedbackStopFollowUp", this.$store.state.locale);
            }
          }

          else if (newAction.id == "leadBecomesCustomer" || newAction.id == "leadBecomesNoCustomer") {
            if (this.actionDialog.actions.length > 1 && (this.actionDialog.actions[1].leadInterest || this.actionDialog.actions[1].leadGoals || this.actionDialog.actions[1].leadGoalsExplaination)) {
              this.selectedLead.actions = this.actionDialog.actions.concat(this.selectedLead.actions);
              this.actionDialog.show = false;
              this.actionDialog.type = null;
              this.actionDialog.actions = new Array();
              this.updateLeadActions();
              this.getAvailableActions();
            }
            else {
              this.getAvailableDialogActions();
              // this.actionDialog.feedback = this.$t("leadCampaign.feedbackLeadBecomesCustomer", this.$store.state.locale);
            }
          }
        }
        else {
          this.selectedLead.actions = this.selectedLead.actions ? this.selectedLead.actions : [];
          this.selectedLead.actions.unshift(newAction);
          if (newAction.save) {
            this.updateLeadActions();
          }
          this.getAvailableActions();
        }
      },

      getAvailableActions() {
        if (this.selectedLead.assignedTo) {
          let latestActionId = this.selectedLead.actions && this.selectedLead.actions.length > 0 ? this.selectedLead.actions[0].id : "leadAssigned";
          this.availableActions = this.actions.filter((action) => {
            if (_.includes(action.actionTriggers, latestActionId)) {
              return action;
            }
          });
        } else {
          this.availableActions = new Array();
        }
      },

      getAvailableDialogActions() {
        let latestActionId = this.actionDialog.actions && this.actionDialog.actions.length > 0 ? this.actionDialog.actions[0].id : "leadAssigned";
        this.availableDialogActions = this.actions.filter((action) => {
          if (_.includes(action.actionTriggers, latestActionId)) {
            return action;
          }
        });
      },
      closeScheduleAppointmentModal(){
        this.scheduleAppointmentModal = false;
        this.dialogActionGoBack();
      },
      
      // addSaveAction(actionDialog.actions[0].id)
      addSaveAction(id, docId, title, date) {
        // console.log("id: ", id, "docId: ", docId, "title: ", title, "date: ", date, )
        if (id == "schedulePhoneCall" || id == "scheduleFollowUpCall" || id == "conversationCallback") {
          // Add Booking
          let newLeadEventData = this.employeeEvents[this.employeeEvents.length - 1];
          console.log("newLeadEventData: ", newLeadEventData);

          db.collection("bookingen")
            .add({
              type: "lead",
              companyId: this.$store.state.activeCompany.id,
              branch: this.$store.state.activeBranch.id,
              created: moment().format("YYYY-MM-DDTHH:mm"),
              dateBooking: moment(newLeadEventData.start).format("YYYY-MM-DD"),
              title: newLeadEventData.title,
              resourceId: _.find(this.employees, ["id", this.selectedLead.assignedTo]).id,
              resourceDocId: this.selectedLead.assignedTo,
              description: newLeadEventData.description,
              clientname: _.find(this.employees, ["id", this.selectedLead.assignedTo]).name,
              start: moment(newLeadEventData.start).format("YYYY-MM-DDTHH:mm"),
              end: moment(newLeadEventData.end).format("YYYY-MM-DDTHH:mm"),
              clientId: newLeadEventData.clientId ? newLeadEventData.clientId : null,
              bookingOverride: false,
              eventColor: newLeadEventData.eventColor,
              leadExtra: {
                leadId: this.selectedLead.id,
                clientId: this.selectedLead.clientId,
              },
            })
            .then((docRef) => {
              this.selectedLead.actions = this.actionDialog.actions.concat(this.selectedLead.actions);
              this.selectedLead.actions[0].bookingId = docRef.id;
              this.selectedLead.actions[0].date = newLeadEventData.start;
              this.actionDialog.show = false;
              this.employeeEvents = new Array();
              this.actionDialog.type = null;
              this.actionDialog.actions = new Array();
              this.updateLeadActions();
              this.getAvailableActions();
              this.selectedDate = this.today;
            })
            .catch((error) => {
              console.error("Error scheduling phonecall for lead: ", error);
            });
        } 
        else if (id == "phoneCallSuccess") {
          // Add Booking
          if(docId && date){
            this.selectedLead.actions = this.actionDialog.actions.concat(this.selectedLead.actions);
            this.selectedLead.actions[0].bookingSummaryId = docId;
            this.selectedLead.actions[0].date = date;
            this.selectedLead.actions[0].nameResult = {
              nl: `${title} gepland`,
              en: `${title} scheduled`
            };
          }
          this.actionDialog.show = false;
          this.scheduleAppointmentModal = false;
          this.employeeEvents = new Array();
          this.actionDialog.type = null;
          this.actionDialog.actions = new Array();
          this.updateLeadActions();
          this.getAvailableActions();
          this.selectedDate = this.today;
        }
        else if(id == "endLeadTracking"){
          if (this.actionDialog.actions[0].leadObjection) {
            this.selectedLead.actions = this.actionDialog.actions.concat(this.selectedLead.actions);
            this.actionDialog.show = false;
            this.scheduleAppointmentModal = false;
            this.actionDialog.type = null;
            this.actionDialog.actions = new Array();
            this.updateLeadActions();
            this.getAvailableActions();
          }
          else {
            this.actionDialog.feedback = this.$t("leadCampaign.feedbackStopFollowUp", this.$store.state.locale);
          }
        } 
      },

      previousDays() {
        this.selectedDate = moment(this.selectedDate).subtract(4, "day").format("YYYY-MM-DD");
        this.getEmployeeEvents(this.selectedDate);
      },

      nextDays() {
        this.selectedDate = moment(this.selectedDate).add(4, "day").format("YYYY-MM-DD");
        this.getEmployeeEvents(this.selectedDate);
      },

      getEmployeeEvents(rangeStart) {     
        this.employeeEventsLoading = true;
        this.newDatePlanned = false;
        let rangeEnd = moment(rangeStart).add(3, "day").format("YYYY-MM-DD");
        let resource = _.find(this.employees, ["id", this.selectedLead.assignedTo]).id;

        console.log("getEmployeeEvents", rangeStart, rangeEnd, resource)
        this.employeeEvents = new Array();
        db.collection("bookingen")
          // .where("companyId", "==", this.activeCompData.id)
          .where("dateBooking", ">=", rangeStart)
          .where("dateBooking", "<=", rangeEnd)
          .where("branch", "==", this.$store.state.activeBranch.id)
          .where("resourceDocId", "==", resource)
          .get()
          .then((snap) => {
            snap.forEach((doc) => {
              console.log("Event")
              let event = {
                drag: false,
                title: doc.data().title,
                description: doc.data().description,
                clientname: doc.data().clientname,
                start: doc.data().start,
                end: doc.data().end,
                bookingOverride: doc.data().bookingOverride,
                eventColor: doc.data().eventColor,
                preferredEmployeeByClient: doc.data().PreferredEmployeeByClient ? doc.data().PreferredEmployeeByClient : null,
                source: doc.data().source ? doc.data().source : null,
              };
              this.employeeEvents.push(event);
            });
          })
          .then(() => {
            this.employeeEventsLoading = false;
          })
          .catch((error) => {
            this.employeeEventsLoading = false;
            console.error("Error bij laden events van employee: ", error);
          });
      },

      getEvents() {
        if (this.selectedDate == this.today) {
          this.$refs.calendar.scrollToTime(moment().subtract(30, "minutes").format("HH:mm"));
          this.nowY = this.$refs.calendar ? this.$refs.calendar.timeToY(moment().format("HH:mm")) + "px" : "-10px";
        } else {
          this.nowY = "-10px";
        }
      },

      startDrag({ event, timed }) {
        if (event && timed && event.drag) {
          this.dragEvent = event;
          this.dragTime = null;
          this.extendOriginal = null;
        }
      },

      startTime(tms) {
        const mouse = this.toTime(tms);

        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start;
          this.dragTime = mouse - start;
        } else {
          if (this.employeeEvents.length > 0 && this.employeeEvents[this.employeeEvents.length - 1].id == "leadEvent") {
            this.employeeEvents.pop();
          }
          
          
          /** 
           * Add extra minutes (15 minutes) for Click Event
           * use of function roundTime -> const roundTo = 15;
           * 
          */
          this.createStart = this.roundTime(mouse);
          const mouseRounded = this.roundTime(mouse, false); 
          const min = Math.min(mouseRounded, this.createStart);
          const max = Math.max(mouseRounded, this.createStart);
          //console.log("min", min, moment(min).format("YYYY-MM-DDTHH:mm"));
          //console.log("max", max, moment(max).format("YYYY-MM-DDTHH:mm"));

          if (this.actionDialog.actions[0].id == "phoneCallSuccess") {
            this.createEvent = {
              title: this.getCampgaignType(this.campaignData.type),
              drag: true,
              id: "leadEvent",
              description: `${this.selectedLead.clientInfo.firstName} ${this.selectedLead.clientInfo.lastName}`,
              start: min,
              end: max,
              timed: true,
              branch: this.$store.state.activeBranch.id,
              dateBooking: this.selectedDate,
              clientId: this.selectedLead.clientId,
              clientname: this.selectedLead.assignedTo,
              eventColor: "rgb(161, 161, 161)",
            };
          }
          else {
            this.createEvent = {
              title: "Leadopvolging",
              drag: true,
              id: "leadEvent",
              description: `Nabellen lead ${this.selectedLead.clientInfo.firstName} ${this.selectedLead.clientInfo.lastName}`,
              start: min,
              end: max,
              timed: true,
              branch: this.$store.state.activeBranch.id,
              dateBooking: this.selectedDate,
              clientname: this.selectedLead.assignedTo,
              eventColor: "rgb(161, 161, 161)",
            };
          }

          this.employeeEvents.push(this.createEvent);
          this.newDatePlanned = true;
        }
      },

      mouseMove(tms) {
        const mouse = this.toTime(tms);
        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start;
          const end = this.dragEvent.end;
          const duration = end - start;
          const newStartTime = mouse - this.dragTime;
          const newStart = this.roundTime(newStartTime);
          const newEnd = newStart + duration;

          this.dragEvent.start = newStart;
          this.dragEvent.end = newEnd;
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false);
          const min = Math.min(mouseRounded, this.createStart);
          const max = Math.max(mouseRounded, this.createStart);

          this.createEvent.start = min;
          this.createEvent.end = max;
        }
      },

      endDrag() {
        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
      },

      cancelDrag() {
        if (this.createEvent) {
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal;
          } else {
            const i = this.employeeEvents.indexOf(this.createEvent);
            if (i !== -1) {
              this.employeeEvents.splice(i, 1);
            }
          }
        }

        this.createEvent = null;
        this.createStart = null;
        this.dragTime = null;
        this.dragEvent = null;
      },

      roundTime(time, down = true) {
        const roundTo = 15; // minutes
        const roundDownTime = roundTo * 60 * 1000;

        return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
      },

      toTime(tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
      },

      getEventColor(event) {
        // Bestaande event color pakken en transparant maken ?
        return event === this.dragEvent ? `rgba(161, 161, 161, 0.7)` : event === this.createEvent ? `rgb(100, 100, 100)` : event.eventColor;
      },


      loaded(id) {
        this.employees.forEach((employee) => {
          if (employee.id == id) {
            employee.photoLoaded = true;
          }
        });
      },

      updateLeadActions() {
        // Status updaten als lead klant wordt of niet
        db.collection("leads")
          .doc(this.selectedLead.id)
          .set(
            {
              assignedTo: this.selectedLead.assignedTo,
              actions: this.selectedLead.actions,
              updated: new Date()
            },
            { merge: true }
          )
          .then(() => {
            this.$emit("leadEdited", this.selectedLead);
            console.log("Lead succesfully updated");
            this.notify(this.$t("leadCampaign.saved", this.$store.state.locale), "success");
          })
          .catch((error) => {
            console.error("Error updating Lead : ", error);
            this.notify(this.$t("leadCampaign.savingError", this.$store.state.locale), "error");
          });
      },

      actionGoBackModal() {
        this.goBackModal = true;
      },

      actionGoBack() {
        //Remove latest action from selected Lead
        if (this.selectedLead.actions[0].bookingId) {
          // Check if action as a Booking Id
          // Remove Booking first
          db.collection("bookingen")
            .doc(this.selectedLead.actions[0].bookingId)
            .delete()
            .catch((error) => {
              console.error("Error removing leadbooking : ", error);
            });
        }
        if (this.selectedLead.actions[0].bookingSummaryId) {
          // Check if action as a Booking Summary Id
          // Remove Booking Summary and connected Bookings
          db.collection("bookingsummary")
            .doc(this.selectedLead.actions[0].bookingSummaryId)
            .delete()
            .catch((error) => {
              console.error("Error removing leadbooking : ", error);
            });

          let bookings = [];
           db.collection("bookingen")
            .where("bookingId", "==", this.selectedLead.actions[0].bookingSummaryId) //Dynamisch maken binnen halen per branch
            .get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {
                bookings.push(doc.id)
              })
            })
            .then(()=>{
              bookings.forEach(booking=>{
                db.collection("bookingen")
                .doc(booking)
                .delete()
                .catch((error) => {
                  console.error("Error removing leadbooking : ", error);
                });
              })
            })
            .catch((error) => {
              console.error("Error getting leadbookings to remove: ", error);
            })
          
        }
        this.selectedLead.actions.shift();
        if (this.selectedLead.actions.length == 0) {
          this.selectedLead.assignedTo = null;
        }
        this.getAvailableActions();
        this.updateLeadActions();
        this.goBackModal = false;
      },

      dialogActionGoBack() {
        this.actionDialog.feedback = null;
        if (this.actionDialog.actions[0].id == "phoneCallSuccess" && this.treatmentDateSelected) {
          this.treatmentDateSelected = false;
          this.selectedTimeTreatment = null;
          this.selectedDateTreatment = null;
          this.newDatePlanned = false;
        } else {
          this.actionDialog.actions.shift();
          this.getAvailableDialogActions();
        }
      },

      deleteLead() {
        this.deletingLead = true;
        new Promise((resolve) => {
          let counter = 0;
          if (this.selectedLead.actions && this.selectedLead.actions.length > 0) {
            // Check if lead had actions
            this.selectedLead.actions.forEach((action) => {
              // Check for bookings in actions and remove them
              if (action.bookingId) {
                db.collection("bookingen")
                  .doc(action.bookingId)
                  .delete()
                  .catch((error) => {
                    console.error("Error removing leadbooking : ", error);
                  });
              }
              counter++;
              if (counter == this.selectedLead.actions.length) {
                resolve();
              }
            });
          } else {
            resolve();
          }
        })
        .then(() => {
          db.collection("leads")
            .doc(this.selectedLead.id)
            .set({
              updated: new Date(),
              deleted: {
                deleted: true,
                deletedTime: new Date(),
                deletedBy: this.userFirstName + ' ' + this.userLastName
              }
            },{merge: true})
            .then(() => {
              this.deletingLead = false;
              this.deleteLeadModal = false;
              this.closeEditLeadDialog();
            })
            .catch((error) => {
              this.deletingLead = false;
              console.error("Error removing leadbooking : ", error);
            });
        });
      },
    },

    computed: {
      availableLanguages(){
        let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
          if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
        });
        // Show languages in the same order as company languages
        return availableLanguages.sort((a, b) => this.$store.state.activeCompany.languages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
      },
      dynamicComponent: function() {
        if (this.showDialog) {
          return () => import(`../../views/agenda.vue`);
        }
        return null;
      },
      actions() {
        let actions = [
          {
            actionTriggers: ["leadAssigned", "schedulePhoneCall", "scheduleFollowUpCall", "scheduleFollowUpAppointment", "noShow", "conversationCallback"],
            id: "directPhoneCall",
            color: "primary",
            icon: "mdi-phone-outgoing",
            name: {
              nl: "Nu bellen",
              en: "Call lead",
              de: "Lead anrufen"
            },
            nameResult: {
              nl: "Lead gebeld",
              en: "Called Lead",
              de: "Lead genannt"
            },
          },
          {
            actionTriggers: ["leadBecomesNoCustomer"],
            id: "scheduleFollowUpCall",
            color: "primary",
            icon: "mdi-calendar",
            name: {
              nl: "Follow-up gesprek inplannen",
              en: "Schedule follow-up call",
              de: "Folgeanruf planen"
            },
            nameResult: {
              nl: "Follow-up gesprek ingepland",
              en: "Follow-up call scheduled",
              de: "Folgegespräch vereinbart"
            },
          },
          {
            actionTriggers: ["leadAssigned", "schedulePhoneCall", "leadCanceledAppointment", "noShow", "leadBecomesNoCustomer", "scheduleFollowUpCall"],
            id: "schedulePhoneCall",
            color: "primary",
            icon: "mdi-calendar",
            name: {
              nl: "Belmoment inplannen",
              en: "Schedule phone call",
              de: "Telefonat vereinbaren"
            },
            nameResult: {
              nl: "Belmoment ingepland",
              en: "Phone call scheduled",
              de: "Anruf geplant"
            },
          },
          // {
          //   actionTriggers: ["leadAssigned", "schedulePhoneCall", "phoneCallSuccess"],
          //   id: "executeAppointment",
          //   color: "primary",
          //   icon: "mdi-arrow-right-bold-box",
          //   name: {
          //     nl: "Uitvoeren",
          //     en: "Execute"
          //   },
          //   nameResult: {
          //     nl: "Afspraak uitgevoerd",
          //     en: "executed"
          //   }
          // },

          {
            actionTriggers: ["leadBecomesNoCustomer"],
            id: "scheduleFollowUpAppointment",
            color: "primary",
            icon: "mdi-calendar",
            name: {
              nl: "Follow-up afspraak inplannen",
              en: "Schedule follow-up appointment",
              de: "Folgetermin vereinbaren"
            },
            nameResult: {
              nl: "Follow-up afspraak ingepland",
              en: "Follow-up appointment scheduled",
              de: "Folgetermin angesetzt"
            },
          },

          {
            actionTriggers: ["directPhoneCall", "rescheduleAppointment", "noShow", "leadCanceledAppointment"],
            id: "phoneCallSuccess",
            color: "primary",
            icon: "mdi-calendar-arrow-right",
            actionType: "successfull",
            name: {
              nl: "Afspraak plannen",
              en: "Schedule appointment",
              de: "Termin vereinbaren"
            },
            nameResult: {
              nl: this.getCampgaignType(this.campaignData.type, "nl") ? this.getCampgaignType(this.campaignData.type, "nl") + " gepland" : "Gepland",
              en: this.getCampgaignType(this.campaignData.type, "en") ? this.getCampgaignType(this.campaignData.type, "en") + " scheduled" : "Scheduled",
              de: this.getCampgaignType(this.campaignData.type, "en") ? this.getCampgaignType(this.campaignData.type, "en") + " geplant" : "Geplant",
            },
          },
          {
            actionTriggers: ["directPhoneCall", "rescheduleAppointment"],
            id: "phoneCallNoSuccess",
            color: "primary",
            icon: "mdi-calendar-arrow-right",
            actionType: "unsuccessfull",
            name: {
              nl: "Geen afspraak gemaakt",
              en: "No appointment scheduled",
              de: "Kein Termin geplant"
            },
            nameResult: {
              nl: "Geen afspraak gemaakt",
              en: "No appointment scheduled",
              de: "Kein Termin geplant"
            },
          },
          // {
          //   actionTriggers: ["phoneCallNoSuccess"],
          //   id: "reschedulePhoneCall",
          //   color: "primary",
          //   icon: "mdi-calendar-arrow-right",
          //   actionType: "unsuccessfull",
          //   name: {
          //     nl: "Belmoment verplaatsen",
          //     en: "Reschedule phone call"
          //   },
          //   nameResult: {
          //     nl: "Geen afspraak gemaakt",
          //     en: "noAppointment scheduled"
          //   }
          // },

          {
            actionTriggers: ["phoneCallNoSuccess"],
            id: "conversationCallback",
            color: "primary",
            actionType: "unsuccessfull",
            icon: "mdi-calendar-arrow-right",
            name: {
              nl: "Gesproken en later terugbellen",
              en: "Conversation and called back later",
              de: "Gespräch und später zurückgerufen"
            },
            nameResult: {
              nl: "Gesproken en later terugbellen",
              en: "Conversation and called back later",
              de: "Gespräch und später zurückgerufen"
            },
          },
          {
            actionTriggers: ["phoneCallNoSuccess", "leadCanceledAppointment"],
            id: "notInterested",
            color: "primary",
            icon: "mdi-close-octagon",
            actionType: "unsuccessfull",
            name: {
              nl: "Persoon heeft geen interesse",
              en: "Person is not interested",
              de: "Person ist nicht interessiert"
            },
            nameResult: {
              nl: "Persoon heeft geen interesse",
              en: "Person is not interested",
              de: "Person ist nicht interessiert"
            },
          },
          {
            actionTriggers: ["phoneCallNoSuccess"],
            id: "notReachable",
            color: "primary",
            icon: "mdi-phone-remove",
            actionType: "unsuccessfull",
            name: {
              nl: "Onbereikbaar",
              en: "Unreachable",
              de: "Nicht erreichbar"
            },
            nameResult: {
              nl: "Onbereikbaar",
              en: "Unreachable",
              de: "Nicht erreichbar (Follow-up beenden)"
            },
          },
          {
            actionTriggers: ["phoneCallNoSuccess", "leadDropsOut"],
            id: "notReachableStop",
            color: "primary",
            icon: "mdi-close-octagon",
            actionType: "unsuccessfull",
            name: {
              nl: "Onbereikbaar (Leadopvolging stoppen)",
              en: "Unreachable (Stop follow-up)",
              de: "Nicht erreichbar (Follow-up beenden)"
            },
            nameResult: {
              nl: "Onbereikbaar (Leadopvolging stoppen)",
              en: "Unreachable (Stop follow-up)",
              de: "Nicht erreichbar (Follow-up beenden)"
            },
          },
          {
            actionTriggers: ["notInterested", "addReferral", "notReachableStop", "leadBecomesNoCustomer"],
            id: "endLeadTracking",
            color: "primary",
            icon: "mdi-close-octagon",
            actionType: "unsuccessfull",
            name: {
              nl: "Lead follow-up stoppen",
              en: "Stop lead follow-up",
              de: "Lead-Nachverfolgung Stoppen"
            },
            nameResult: {
              nl: "Lead follow-up gestopt",
              en: "Lead follow-up stopped",
              de: "Lead-Follow-up gestoppt"
            },
          },

          {
            actionTriggers: ["leadAssigned", "schedulePhoneCall", "phoneCallSuccess", "scheduleFollowUpCall", "scheduleFollowUpAppointment"],
            id: "appointmentExecuted",
            color: "primary",
            icon: "mdi-thumb-up",
            name: {
              nl: "Direct uitvoeren",
              en: "Execute now",
              de: "Jetzt ausführen"
            },
            nameResult: {
              nl: this.getCampgaignType(this.campaignData.type, "nl") ? this.getCampgaignType(this.campaignData.type, "nl") + " uitgevoerd" : "uitgevoerd",
              en: this.getCampgaignType(this.campaignData.type, "en") ? this.getCampgaignType(this.campaignData.type, "en") + "executed" : "executed",
              de: this.getCampgaignType(this.campaignData.type, "de") ? this.getCampgaignType(this.campaignData.type, "de") + "ausgeführt" : "Nicht ausgeführt"
            },
          },
          {
            actionTriggers: ["executeAppointment", "phoneCallSuccess"],
            id: "appointmentNotExecuted",
            color: "primary",
            icon: "mdi-thumb-down",
            name: {
              nl: "Lead kreeg geen",
              en: "Lead didn't had",
              de: "Blei hatte nicht"
            },
            nameResult: {
              nl: this.getCampgaignType(this.campaignData.type, "nl") ? this.getCampgaignType(this.campaignData.type, "nl") + " uitgevoerd" : "Niet uitgevoerd",
              en: this.getCampgaignType(this.campaignData.type, "en") ? this.getCampgaignType(this.campaignData.type, "en") + "executed" : "Not executed",
              de: this.getCampgaignType(this.campaignData.type, "de") ? this.getCampgaignType(this.campaignData.type, "de") + "ausgeführt" : "Nicht ausgeführt"
            },
          },

          {
            actionTriggers: ["appointmentNotExecuted", "scheduleFollowUpAppointment"],
            id: "noShow",
            color: "primary",
            icon: "mdi-clock-alert",
            name: {
              nl: "Lead is niet komen opdagen",
              en: "Lead did not show up",
              de: "Blei tauchte nicht auf"
            },
            nameResult: {
              nl: "Lead is niet komen opdagen",
              en: "Lead did not show up",
              de: "Blei tauchte nicht auf"
            },
          },
          {
            actionTriggers: ["appointmentNotExecuted"],
            id: "rescheduleAppointment",
            color: "primary",
            icon: "mdi-calendar-arrow-right",
            name: {
              nl: "Afspraak verplaatsen",
              en: "Reschedule appointement",
              de: "Termin neu ausmachen"
            },
            nameResult: {
              nl: "Afspraak verplaatsen",
              en: "Rescedule appointement",
              de: ""
            },
          },
          {
            actionTriggers: ["appointmentNotExecuted"],
            id: "leadCanceledAppointment",
            color: "primary",
            icon: "mdi-phone-cancel",
            name: {
              nl: "Lead belt af",
              en: "Lead canceled by phone",
              de: "Lead per Telefon abgebrochen"
            },
            nameResult: {
              nl: "Lead belt af",
              en: "Lead canceled by phone",
              de: "Lead per Telefon abgebrochen"
            },
          },
          {
            actionTriggers: ["appointmentNotExecuted"],
            id: "leadDropsOut",
            color: "primary",
            icon: "mdi-close-octagon",
            name: {
              nl: "Lead haakt af",
              en: "Lead drops out",
              de: "Blei fällt heraus"
            },
            nameResult: {
              nl: "Lead haakt af",
              en: "Lead drops out",
              de: "Blei fällt heraus"
            },
          },

          {
            actionTriggers: ["appointmentExecuted"],
            id: "leadBecomesCustomer",
            color: "primary",
            icon: "mdi-emoticon-excited",
            name: {
              nl: "Lead wordt klant",
              en: "Lead becomes customer",
              de: "Aus Lead wird Kunde"
            },
            nameResult: {
              nl: "Lead is klant geworden",
              en: "Lead has become a customer",
              de: "Aus Lead ist ein Kunde geworden"
            },
          },

          {
            actionTriggers: ["appointmentExecuted"],
            id: "leadBecomesNoCustomer",
            color: "primary",
            icon: "mdi-emoticon-sad",
            name: {
              nl: "Lead wordt (nog) geen klant",
              en: "Lead does not (yet) become a customer",
              de: "Aus Lead wird (noch) kein Kunde"
            },
            nameResult: {
              nl: "Lead is (nog) geen klant geworden",
              en: "Lead hasn't become a customer yet",
              de: "Lead ist noch kein Kunde geworden"
            },
          },
        ];
        return actions;
      },

      trialTreatmentId(){
        return this.campaignData.treatment ? this.campaignData.treatment : null;
      },
      
      leadInfoGrid() {
        if (this.actionDialog.actions[0].id == "directPhoneCall") {
          return "xs8";
        } else {
          return "xs12";
        }
      },

      clientDataChanged() {
        return _.isEqual(this.client, this.clientOriginal) ? false : true;
      },

      addressChanged(){
        let clientAddress = { postalCode: this.client.address.postalCode, number: this.client.address.number}
        let clientAddressChecked = this.clientAddressChecked;

        return _.isEqual(clientAddress, clientAddressChecked) || _.isEqual(this.client.address, this.clientOriginal.address)? false : true;
      },

      clientAddressChanged(){
        return _.isEqual(this.client.address, this.clientOriginal.address)? false : true;
      },

      birthdayDate() {
        let date = {
          readable: "",
          formatted: "",
          firebase: null,
        };
        if (this.birthdayDateRaw) {
          date.readable = moment(this.birthdayDateRaw).format("D MMMM YYYY");
          date.formatted = moment(this.birthdayDateRaw).format("YYYY/MM/DD");
          date.firebase = moment(this.birthdayDateRaw).format("DDMMYYYY");
          return date;
        } else if (this.client.birthday) {
          date.readable = moment(this.client.birthday, "DDMMYYYY").format("D MMMM YYYY");
          date.formatted = moment(this.client.birthday, "DDMMYYYY").format("YYYY/MM/DD");
          date.firebase = moment(this.client.birthday, "DDMMYYYY").format("DDMMYYYY");
          return date;
        } else {
          return date;
        }
      },
      editQuestionChanged() {
        return _.isEqual(this.editQuestion.question, this.editQuestionOriginal.question) && _.isEqual(this.editQuestion.type, this.editQuestionOriginal.type) ? true : false;
      },
      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      changed() {
        if (_.isEqual(this.selectedAbility, this.selectedAbilityOriginal) == false) {
          return true;
        } else {
          return false;
        }
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      }
    },
  };
</script>

<style>
  .custom-calendar .v-calendar-daily_head-weekday {
    display: none;
  }

  .custom-calendar-today {
    color: var(--primary-color);
  }

  .event-info-wrapper {
    padding: 4px;
  }

  .event-info-banner {
    color: #fff !important;
    padding: 2px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 2px 5px 0 5px;
    display: block;
    border-radius: 2px;
    position: relative;
  }

  .event-info-banner:after {
    clear: both;
    content: "";
    display: block;
  }

  .event-info-banner-time {
    float: left;
    width: 40px;
    font-weight: bold;
  }

  .event-info-banner-title {
    float: left;
    width: calc(100% - 60px);
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event-info-description {
    white-space: break-spaces;
    padding: 1px 3px;
  }

  .event-source {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 3px;
    font-weight: bold;
  }

  .v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;
  }

  .v-calendar-daily__day.v-present .v-current-time:before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }

  .widget-view-month {
    width: calc(100% - 66px);
    color: #0d1f31;
    font-weight: bold;
    float: left;
    text-align: center;
    text-transform: capitalize;
  }

  .widget-dates-monthview-dates {
    padding: 0 33px;
  }

  .date-item {
    background-color: #ffffff;
    width: 54px;
    float: left;
    position: relative;
    text-align: center;
    text-decoration: none;
    display: block;
    color: #0d1f31;
    box-shadow: 0 1px 5px rgba(193, 201, 215, 0.5);
    overflow: hidden;
    border: 1px solid rgba(193, 201, 215, 1);
    border-radius: 15px;
    padding: 6px 5px 2px 5px;
    margin: 6px 5px 4px 4px;
  }

  .date-item.today {
    background-color: #00adef;
  }

  .date-item.empty:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    padding: 12px 5px 8px 5px;
    width: 54px;
    height: 86px;
    background: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 3px, rgba(193, 201, 215, 0.7) 3px, rgba(193, 201, 215, 0.7) 6px);
  }

  .date-item.today.empty:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    padding: 12px 5px 8px 5px;
    width: 54px;
    height: 86px;
    background: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 3px, rgba(255, 255, 255, 0.7) 3px, rgba(255, 255, 255, 0.7) 6px);
  }
  .widget-date-time-wrapper .date-item.empty:after,
  .widget-date-time-wrapper .date-item.today.empty:after {
    border-radius: 15px;
  }

  .date-item.selected,
  .date-item.selected > *,
  .date-item:hover,
  .date-item:hover > * {
    background-color: #0d1f31;
    color: #fff !important;
    cursor: pointer !important;
  }

  .widget-date-time-wrapper .date-item.selected,
  .widget-date-time-wrapper .date-item.selected > *,
  .widget-date-time-wrapper .date-item:hover,
  .widget-date-time-wrapper .date-item:hover > * {
    background-color: #0d1f31;
    color: #fff !important;
    cursor: pointer !important;
  }

  .date-item.empty:hover {
    background-color: #ffffff;
    cursor: not-allowed !important;
  }

  .date-item.today.empty:hover {
    background-color: #00adef;
  }

  .date-item.empty:hover .day-text,
  .date-item.empty:hover .month {
    color: #c1c9d7 !important;
    background-color: #ffffff;
  }

  .widget-date-time-wrapper .date-item.empty:hover .day-text {
    color: #0d1f31 !important;
    z-index: 999;
  }

  .date-item.today.empty:hover .day-text,
  .date-item.today.empty:hover .day-number,
  .date-item.today.empty:hover .month {
    color: #ffffff !important;
    background-color: #00adef;
    cursor: not-allowed !important;
  }

  .date-item.empty:hover .day-number {
    color: #0d1f31 !important;
    background-color: #ffffff;
  }

  .date-item > * {
    cursor: pointer !important;
  }

  .date-item .day-text {
    font-size: 11px;
    color: #0d1f31;
    text-transform: uppercase;
  }
  .date-item .day-number {
    font-size: 22px;
    font-weight: bold;
    color: #0d1f31;
  }
  .date-item .month {
    font-size: 11px;
    color: #c1c9d7;
    text-transform: uppercase;
  }

  .date-item.today .day-text,
  .date-item.today .day-number,
  .date-item.today .month {
    color: #ffffff;
  }

  .widget-date-time-wrapper .date-item .day-text {
    font-size: 10px;
  }

  .widget-date-time-wrapper .date-item .day-number {
    font-size: 20px;
  }

  .widget-date-time-wrapper .date-item .month {
    display: none;
  }

  .date-select-header {
    padding: 24px 12px 80px 12px;
    background-color: #f6f7fb;
  }

  .available-times {
    margin: 10px 0 0 0;
    padding: 0 0 15px 0;
    background-color: #f6f7fb;
  }

  .widget-date-time-wrapper {
    margin: -75px 0 0 0;
  }

  .widget-dates-outer-wrapper {
    margin: 0 auto;
  }

  .widget-dates-outer-wrapper:after,
  .timeslots-wrapper:after {
    display: block;
    content: "";
    clear: both;
  }

  .time-item {
    border: 1px solid rgba(193, 201, 215, 1);
    background-color: #ffffff;
    border-radius: 50px;
    padding: 5px;
    width: 70px;
    margin: 6px;
    float: left;
    text-align: center;
    text-decoration: none;
    display: block;
    color: #0d1f31 !important;
    box-shadow: 0 1px 5px rgba(193, 201, 215, 0.5);
    overflow: hidden;
    cursor: pointer !important;
  }

  .time-item:hover {
    background-color: #0d1f31 !important;
    color: #ffffff !important;
    border: 1px solid #0d1f31;
    cursor: pointer !important;
  }

  .selected-treatments-label {
    font-size: 14px;
    font-style: italic;
  }

  .action-card-button.v-card--link:focus:before {
    opacity: 0 !important;
  }

  .custom-shape,
  .custom-shape .v-sheet.v-card{
    border-radius: 0px!important;
  }
</style>
