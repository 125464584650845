<template>
  <v-dialog v-model="dialog" scrollable @click:outside="closeNewEmailaddress" max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`"> 
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('clients.newEmailaddress', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeNewEmailaddress()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="modal-card ma-0 pa-3">
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12>
                <v-text-field
                :label="$t('general.email', $store.state.locale)"
                v-model="emailaddress"
                required
                outlined
                dense
                class="mt-3"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                >
                <template v-slot:append>        
                  <v-icon v-if="validEmail" color="green"> mdi-check-circle </v-icon> 
                </template>
              </v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="closeNewEmailaddress()"
                  >
                  {{ $t('general.cancel', $store.state.locale)}}
                </v-btn>
              </v-flex>
              <v-flex xs12 md8 class="text-right">
                <v-btn
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :disabled="!validEmail"
                  :dark="validEmail"
                  :loading="checkEmailValidation"
                  @click="addEmailaddress()"
                  >
                  {{ $t('general.add', $store.state.locale)}}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>


<script>
  import { bus } from "../../main";
  import axios from "axios";

  export default {
    name: "add-emailaddress-form",
    props:{
      type: String
    },
    data() {
      return {
        existingEmails: new Array(),
        dialog: false,
        emailaddress: null,
        validEmail: false,
        checkEmailValidation: false,
        emailRules: [
          v => !!v || "E-mail is verplicht",
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid"
        ],
      };
    },

    created(){
      bus.$on('addEmailDialog', (emails, componentType) =>{
        if(componentType == this.type){
          this.existingEmails = emails;
          this.dialog = true;
        }
      });
    },

    methods: {
      async addEmailaddress(){
        this.checkEmailValidation = true;
        let emailaddressData = {
          primary: !this.existingEmails || this.existingEmails.length == 0 ? true : false,
          email: this.emailaddress.toLowerCase()
        }

        let emailValidationObject = await emailValidation(this.emailaddress);
        emailaddressData.score = emailValidationObject.score;
        emailaddressData.error = emailValidationObject.error;
  
        async function emailValidation(emailClientCard) {
      
          const start = Date.now();

          const emailIntelScore = await axios
          .get(`https://mailvalidate.hogans.nl/validate-email?email=${emailClientCard}`, { timeout: 6000 })
          .then((response) => {
            // console.log(response.data);
            const durationMs = Date.now() - start; //?
            // console.log(durationMs);
            return rateScore(emailClientCard, response.data, durationMs, true);
          })
          .catch((error) => {
            console.log("Error validating email =>", error.message);
            const durationMs = Date.now() - start; //?
            return rateScore(emailClientCard, null, durationMs, false);
          });

          return emailIntelScore;

          /**
           * @param {*} emailClientCard | String email adress
           * @param {*} emailData  | object with email data
           * @param {*} performanceMs  | time in ms
           * @param {*} axiosSucces  | boolean
           * @returns
           */
          function rateScore(emailClientCard, emailData, performanceMs, axiosSucces) {
            //Domain DNS found
            if (axiosSucces === true) {
              //Domain is found but email is not active?
              if (typeof emailData.success !== "undefined") {
                //Email found check performance and set to primary true
                if (emailData.success == true) {
                  return { email: emailClientCard, score: performanceScore(performanceMs), primary: true, error: {} };
                }

                //Email not found
                if (emailData.success == false) {
                  console.log(chalk.red("Email Domain succes but not active:", emailClientCard));
                  return {
                    email: emailClientCard,
                    score: 0,
                    error: { en: "Email Domain succes but email is invalid", nl: "Domein bestaat maar email wordt niet herkend" },
                  };
                }
              } else {
                //Domain DNS is not found
                return { email: emailClientCard, score: 0, error: { en: "Domain DNS not found", nl: "Domein DNS niet gevonden" } };
              }
            } else {
              //Axios time out so no response and possibly bad email.

              return { email: emailClientCard, score: 0, error: { en: "Email does not response, time-out", nl: "Email reageert niet, time-out" } };
            }

            function performanceScore(performanceMs) {
              console.log(performanceMs, "performanceMs");
              if (performanceMs < 1000) {
                return 10;
              } else if (performanceMs > 1000 && performanceMs < 2000) {
                return 8;
              } else if (performanceMs > 2000 && performanceMs < 3000) {
                return 7;
              } else if (performanceMs > 3000 && performanceMs < 5000) {
                return 6;
              } else {
                return 5;
              }
            }
          }
        }

        this.checkEmailValidation = false;
        this.$emit("newEmailaddress", emailaddressData);
        this.closeNewEmailaddress();
      },

      closeNewEmailaddress(){
        this.dialog = false;
        this.emailaddress = "";
        this.existingEmails = new Array();
      },
    },
    watch: {
      emailaddress: function() { // Check if email is valid
        this.emailaddress = this.emailaddress.replace(/\s/g, '');
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailaddress))) {
          this.validEmail = true;
        }
        else {
          this.validEmail = false;
        }
      },
    },
    computed: {
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      }
    }
  };
</script>
