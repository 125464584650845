<template>
  <div>
    <v-overlay :value="dialog" opacity="0.7" z-index="88"></v-overlay>
    <v-dialog v-model="dialog" hide-overlay scrollable persistent max-width="600px" :content-class="`custom-dialog ${companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, selectedEvent.title) }}</h3> 
          <v-chip label class="my-1 ml-2 custom-chip-style" small v-if="bookingSummary.deleted" color="red" text-color="red">{{ $t('general.deleted', $store.state.locale) }}</v-chip> 
          <v-spacer></v-spacer>
          <v-btn v-if="!deleting" icon dark @click="closedialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
          <v-progress-circular v-else indeterminate :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" :size="25" :width="3"></v-progress-circular>
        </v-card-title>
        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-tab-header" :class="$store.state.companyTheme">
          <v-tabs
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            show-arrows
            v-model="tab"
            >
            <v-tab><v-icon left>mdi-calendar-today</v-icon> {{ $t('booking.appointmentInformation', $store.state.locale) }}</v-tab>
            <v-tab><v-icon left>mdi-history</v-icon> {{ $t('booking.appointmentAdjustments', $store.state.locale) }}</v-tab>
          </v-tabs>
        </v-card>

        <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text v-if="!loading" class="pa-0 ma-0">
          <v-tabs-items v-model="tab" style="height: 500px; overflow-y: scroll;" class="pa-4">
            <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">

                <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer +'20'" flat v-if="$store.state.userRoleNumber < 2 && selectedEventOriginal && !$store.state.demoMode" class="mb-3">
                  <v-btn @click="copyTextToClipboard(selectedEventOriginal.bookingId, 'bookingsummary')" class="ma-1 my-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="color: #ffffff; text-transform: none;"><strong>{{selectedEventOriginal.bookingId}}</strong></v-btn>
                  <div style="display: inline-block" v-if="selectedBookingSummaryIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(selectedEventOriginal.bookingId, 'bookingsummary')"><v-icon small color="#333">mdi-content-copy</v-icon> Kopieër Bookingsummary DocId</div>
                  <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
                </v-card>

                  <v-list class="ma-0 pa-0 no-hover-effect" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" >
                    <v-list-item class="pa-0 ma-0" style="min-height: 10px;" @click="customerCard()">
                      <v-list-item-avatar
                        size="26"
                        light
                        class="ma-1 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon> 
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title class="pb-1 pl-2">
                          <p class="ma-0 pa-0"><span style="text-decoration: underline; font-size: 14px">{{ selectedEvent.clientname }}</span> <v-icon v-if="!bookingSummary.deleted" small class="ma-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click.stop="changeClient()">mdi-repeat</v-icon></p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-list  class="ma-0 pa-0 no-hover-effect" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" >
                    <v-list-item class="pa-0 ma-0" style="min-height: 10px;" v-if="bookingSummary.downPayment && bookingSummary.downPayment.active && !bookingSummary.downPayment.paid">
                      <v-list-item-avatar
                        size="26"
                        light
                        class="ma-1 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-timer-sand</v-icon> 
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0">
                        <v-tooltip class="ma-0 pa-0" bottom >
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              <v-list-item-title class="pb-1 pl-2">
                                <p class="ma-0 pa-0"><strong>{{ $t('booking.unconfirmedAppointment', $store.state.locale) }}</strong> {{ $t('booking.appointmentWaitingForPayment', $store.state.locale) }})</p>
                              </v-list-item-title>
                              <v-list-item-subtitle class="pb-1 pl-2"  >
                                <v-icon
                                style="margin: -5px 0 0 0"
                                  size="14"
                                  :color="downPaymentStatus().color"
                                  >mdi-checkbox-blank-circle
                                </v-icon>
                                <span style="font-size: 13px"><i>{{ timeAgoCreatedText }}</i></span>
                                <v-icon
                                  style="margin: -5px 0 0 0"
                                  class="ma-0 pa-0 ml-1"
                                  size="14"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                                  >mdi-information
                                </v-icon>
                              </v-list-item-subtitle> 
                            </span>
                          </template>
                          <span style="color: #fff; font-size: 14px">{{ downPaymentStatus().text }}</span>
                        </v-tooltip>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0 ma-0" style="min-height: 10px;" v-if="bookingSummary.downPayment && bookingSummary.downPayment.active && bookingSummary.downPayment.paid">
                      <v-list-item-avatar
                        size="26"
                        light
                        class="ma-1 custom-avatar-style" 
                        rounded="lg"
                        color="#56ca00" 
                        style="color: #56ca00" 
                        >
                        <v-icon small color="#56ca00">mdi-cash-check</v-icon> 
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title class="pb-1 pl-2">
                          <p class="ma-0 pa-0">{{ $t('booking.appointmentConfirmedWithPayment', $store.state.locale) }}</p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                
                    <v-list-item class="pa-0 ma-0" style="min-height: 10px;" v-if="bookingSummary.bookingType || bookingSummary.createdBy">
                      <v-list-item-avatar
                        size="26"
                        light
                        class="ma-1 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <span v-if="bookingSummary.bookingType == 'widget' || bookingSummary.createdBy == 'postBookingWidget'"><strong>O</strong></span>
                        <span v-if="bookingSummary.bookingType == 'app'"><strong>A</strong></span>
                        <span v-if="bookingSummary.bookingType == 'personal'"><strong>P</strong></span>
                        <span v-if="bookingSummary.bookingType == 'telephone'"><strong>T</strong></span>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title class="pb-1 pl-2">
                          <p v-if="bookingSummary.bookingType" class="ma-0 pa-0">{{ $t('clients.bookingType'+ bookingSummary.bookingType, $store.state.locale)  }}</p>
                          <p v-if="bookingSummary.createdBy == 'postBookingWidget'" class="ma-0 pa-0">{{ $t('clients.bookingTypewidget', $store.state.locale)  }}</p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0 ma-0" style="min-height: 10px;" v-if="bookingSummary.treatmentId" >
                      <v-list-item-avatar
                        size="26"
                        light
                        class="ma-1 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-bookmark</v-icon> 
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title class="pb-1 pl-2">
                          <p class="ma-0 pa-0">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, bookingSummary.treatmentTitle) }}  <v-icon v-if="!bookingSummary.deleted" small class="ma-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="changeTreatment()">mdi-repeat</v-icon></p> 
                        </v-list-item-title>
                       
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0 ma-0" style="min-height: 10px;" v-if="!bookingSummary.paid && bookingSummary.status">
                      <v-list-item-avatar
                        size="26"
                        light
                        class="ma-1 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-progress-check</v-icon> 
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title class="pb-1 pl-2">
                          <p class="ma-0 pa-0">{{ $t('clients.'+ bookingSummary.status, $store.state.locale) }}</p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0 ma-0" style="min-height: 10px;" v-if="bookingSummary.paid ">
                      <v-list-item-avatar
                        size="26"
                        light
                        class="ma-1 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-register</v-icon> 
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title class="pb-1 pl-2">
                          <p class="ma-0 pa-0">{{ $t('clients.treatmentPaid', $store.state.locale) }}</p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-select
                      class="mt-4"
                      :label="$t('booking.preferedPractitioner', $store.state.locale)"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :items="preferredPractitionerOptions"
                      item-text="name"
                      item-value="id"
                      dense
                      v-model="preferredPractitioner"
                      outlined
                      :disabled="($store.state.userRoleNumber > 2 && !bookingSummary.paid) || bookingSummary.deleted"
                      background-color="background"
                    >
                      <template slot="selection" slot-scope="data">
                        <v-avatar
                        size="26"
                        light
                        class="ma-1 ml-0" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon small color="#ffffff">mdi-account-box</v-icon> 
                        </v-avatar>
                        <span>{{ data.item.name }}</span>
                      </template>
                    </v-select>
                  </v-list>

                  <!-- / Downpayment information \ -->

                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" v-if="bookingSummary.downPayment && bookingSummary.downPayment.active" class="pa-1 px-2 my-2">
                    <h4 style="font-size: 14px">{{ $t('payment.downPayment', $store.state.locale)  }}</h4>
                    <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer +'20'" flat v-if="$store.state.userRoleNumber < 2 && selectedEventOriginal && !$store.state.demoMode && downPaymentData" class="my-2">
                      <v-btn @click="copyTextToClipboard(downPaymentData.id, 'prepayment')" class="ma-1 mt-2 mb-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="color: #ffffff; text-transform: none;"><strong>{{downPaymentData.id}}</strong></v-btn>
                      <div style="display: inline-block" v-if="selectedPrepaymentIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(downPaymentData.id, 'prepayment')"><v-icon small color="#333">mdi-content-copy</v-icon> Kopieër Prepayment DocId</div>
                      <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
                    </v-card>
                    
                    <p class="ma-0" style="font-size: 13px"><span v-if="downPaymentData">{{ $t('branch.downPaymentAmount', $store.state.locale)  }}:  {{ numberWithCommas(downPaymentData.amount.number) }}</span><br>
                      <v-chip class="my-1 mr-2 custom-chip-style" small v-if="!bookingSummary.downPayment.paid" color="red" text-color="red">Niet betaald</v-chip> 
                      <v-chip class="my-1 mr-2 custom-chip-style" small v-if="bookingSummary.downPayment.paid"  color="#56ca00" text-color="#56ca00">Betaald</v-chip> 

                      <v-chip v-if="!downPaymentData" dark small light class="my-1 mr-2 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">
                        Betaallink nog niet geopend
                      </v-chip>

                      <v-chip dark v-if="downPaymentData && downPaymentData.testmode" small  light class="my-1 mr-2 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                        Testbetaling
                      </v-chip>

                      <v-chip dark v-if="downPaymentData && downPaymentData.status && downPaymentData.status.type =='compensation'" small  light class="my-1 mr-2 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">
                        Betaling gebruikt als compensatie
                      </v-chip>
    
                    </p>
                    <v-btn v-if="!bookingSummary.downPayment.paid" dark outlined @click="confirmAppointment(selectedEventOriginal.bookingId)" class="my-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="text-transform: none;">Bevestigen zonder aanbetaling</v-btn>
                    <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer +'20'"  v-if="$store.state.userRoleNumber < 2 && selectedEventOriginal && !$store.state.demoMode && downPaymentData" class="pa-2 my-1">
                      <strong>Mollie PaymentID:</strong> {{ downPaymentData.molliePaymentId }}<br>
                      <strong>Mollie Payments aangemaakt:</strong> {{ downPaymentData.molliePaymentCreationCount }}<br>
                      <strong>Conversion DocID:</strong> <span v-if="downPaymentData.conversionDocId">{{ downPaymentData.conversionDocId }}</span><span v-else>-</span>
                    </v-card>
                  </v-card>
                  <!-- \ Downpayment information / -->

                  <v-textarea 
                    class="mt-2"
                    :label="$t('clients.treatmentNotes', $store.state.locale)"
                    v-model="bookingSummary.notes"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    outlined
                    hide-details
                    rows="3"
                    no-resize
                  ></v-textarea>
                  

                  <v-card outlined class="my-4" style="overflow: hidden;" v-if="(bookingSummary && bookingSummary.created) || (messagesHistory && messagesHistory.length > 0)">
                    <div v-if="bookingSummary && bookingSummary.created">
                      <h3 class="pa-4 pt-2 pb-0"> {{ $t('clients.messages', $store.state.locale) }}</h3>  
                      <v-list disabled class="ma-0 pa-0">
                        <v-list-item class="ma-0 pl-0">
                          <v-list-item-avatar 
                            size="50"
                            light
                            class="ma-0 mx-4 mt-1 custom-avatar-style" 
                            rounded="lg"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                            >
                            <div><span style="font-size: 12px">{{ bookingSummary.created.seconds | moment("MMM")}}</span>
                            <p class="mb-0" style="font-size: 20px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].tertiary} "><strong>{{ bookingSummary.created.seconds | moment("D ")}}</strong></p></div>
                          </v-list-item-avatar>

                          <v-list-item-content class="pa-0">
                            <v-list-item-title class="ma-0 pa-0 pb-2" style="font-size: 14px">{{ $t('clients.bookingsScheduled', $store.state.locale) }}</v-list-item-title>
                            <v-list-item-subtitle class="ma-0 pa-0" style="font-size: 12px">
                              <div class="pb-1" style="font-size: 12px">
                                <v-icon size="14" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-calendar</v-icon> {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, bookingSummary.treatmentTitle) }}
                              </div>
                              <div v-if="bookingSummary.createdBy" style="font-size: 12px">
                                <v-icon size="14"  :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-account</v-icon> {{ $t('clients.bookingsScheduledBy', $store.state.locale) }} 
                                <span style="font-size: 12px" :class="{'customSender': getMessageSender(bookingSummary.createdBy).type == 'employee'}">{{ getMessageSender(bookingSummary.createdBy).name }}</span>
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon size="16" style="margin: 15px auto 0 auto" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clock-time-ten-outline</v-icon>
                            <v-list-item-action-text>{{ bookingSummary.created.seconds | moment("H:mm") }}u</v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </div>
                    <div v-if="messagesHistory && messagesHistory.length > 0">
                      <v-list  class="ma-0 pa-0">
                        <v-list-item v-for="(message, index) in messagesHistory" :key="index" class="ma-0 pl-0 py-1">
                          <v-list-item-avatar
                            size="50"
                            light
                            class="ma-0 mx-4 mt-1 pa-0 custom-avatar-style" 
                            rounded="lg"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                            >
                            <div><span style="font-size: 12px">{{ message.sendDate | moment("MMM")}}</span>
                            <p class="mb-0" style="font-size: 20px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary} "><strong>{{ message.sendDate | moment("D ")}}</strong></p></div>
                          </v-list-item-avatar>

        
                          <v-list-item-content class="pt-0">
                            <v-list-item-title class="ma-0 pa-0 pb-2" style="font-size: 14px"> {{ getMessageContentType(message.messageContentType) }} </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px;">
                              <div class="pb-1" style="font-size: 12px">
                                <v-icon size="14" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">{{ getMessageType(message).icon }}</v-icon> {{ getMessageType(message).text }} 
                              
                              </div>
                              <v-icon size="14" :color="status(message).color">mdi-checkbox-blank-circle</v-icon> {{ status(message).text }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon size="16" style="margin: 15px auto 0 auto" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clock-time-ten-outline</v-icon>
                            <v-list-item-action-text>{{ message.sendDate | moment("H:mm") }}u</v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </div>

                    <div class="text-center my-4" v-if="startTimeIsInFuture()">
                      
                      <v-btn v-if="!bookingSummary.downPayment || !bookingSummary.downPayment.active || (bookingSummary.downPayment.active && bookingSummary.downPayment.paid)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="resendMessage()" :loading="messagesResending">{{ $t('clients.resendMessage', $store.state.locale) }}</v-btn>
                      <v-btn v-if="bookingSummary.downPayment && bookingSummary.downPayment.active && !bookingSummary.downPayment.paid" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="resendDownPaymentReminderMessage()" :loading="messagesResending">{{ $t('clients.resendDownPaymentReminderMessage', $store.state.locale) }}</v-btn>
                     
                      <div v-if="clientPhone" class="pt-4">
                        <!--/ Offer to send text if this is default disabled \-->
                        <v-btn v-if="(!bookingSummary.downPayment || !bookingSummary.downPayment.active || (bookingSummary.downPayment.active && bookingSummary.downPayment.paid)) && !$store.state.brancheMessagesSettings.appointmentConfirmTextUse" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="resendMessage('text')" :loading="messagesResendingText">{{ $t('clients.resendMessageText', $store.state.locale) }}</v-btn>
                        <v-btn v-if="(bookingSummary.downPayment && bookingSummary.downPayment.active && !bookingSummary.downPayment.paid) && !$store.state.brancheMessagesSettings.downPaymentReminderTextUse" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="resendDownPaymentReminderMessage('text')" :loading="messagesResendingText">{{ $t('clients.resendDownPaymentReminderMessageText', $store.state.locale) }}</v-btn>
                        <!--\ Offer to send text if this is default disabled /-->
                      </div>
                    
                    </div>
                  </v-card>

                
                <div v-else class="text-center pa-4">
                  <i>{{ $t('clients.noMessagesSend', $store.state.locale) }}</i>

                  <div class="text-center mb-4 mt-2" v-if="startTimeIsInFuture() && clientEmail">
                    <v-btn v-if="!bookingSummary.downPayment || !bookingSummary.downPayment.active || (bookingSummary.downPayment.active && bookingSummary.downPayment.paid)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="resendMessage()" :loading="messagesResending">{{ $t('clients.sendConfirmationMessage', $store.state.locale) }} {{ clientEmail }}</v-btn>
                    <v-btn v-if="bookingSummary.downPayment && bookingSummary.downPayment.active && !bookingSummary.downPayment.paid" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="resendDownPaymentReminderMessage()" :loading="messagesResending">{{ $t('clients.resendDownPaymentReminderMessage', $store.state.locale) }}</v-btn>
                  </div>
                  <div class="pt-4" v-if="startTimeIsInFuture() && clientPhone">
                    <!--/ Offer to send text if this is default disabled \-->
                    <v-btn v-if="(!bookingSummary.downPayment || !bookingSummary.downPayment.active || (bookingSummary.downPayment.active && bookingSummary.downPayment.paid)) && !$store.state.appointmentConfirmTextUse" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="resendMessage('text')" :loading="messagesResendingText">{{ $t('clients.sendConfirmationMessage', $store.state.locale) }} {{ clientPhone }}</v-btn>
                    <v-btn v-if="(bookingSummary.downPayment && bookingSummary.downPayment.active && !bookingSummary.downPayment.paid) && !$store.state.brancheMessagesSettings.downPaymentReminderTextUse" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="resendDownPaymentReminderMessage('text')" outlined :loading="messagesResendingText">{{ $t('clients.resendDownPaymentReminderMessageText', $store.state.locale) }}</v-btn>
                    <!--\ Offer to send text if this is default disabled /-->
                  </div>
                </div>

           

                  
              </v-card>
            </v-tab-item>
            <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                <div v-if="!bookingSummary.deleted">
                  <v-checkbox :disabled="!changeBookingAllowed" class="mt-3 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="changeAll" :label="$t('booking.dynamicChanges', $store.state.locale)" hide-details> </v-checkbox>

                  <v-card outlined  class="pa-2 my-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader"  v-for="(bookingentime, index) in bookingen" :key="index">
                    <h4 class="pb-2">
                      {{ index + 1 }}. {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, bookingentime.description) }}
                      <v-icon small class="mx-1" v-if="getResourceType(bookingentime) == 'device'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-power-plug</v-icon>
                      <v-icon small class="mx-1" v-if="getResourceType(bookingentime) == 'room'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cube-outline</v-icon>
                      <v-icon small class="mx-1" v-if="getResourceType(bookingentime) == 'employee'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>
                      <span style="font-size: 14px">{{ getResourceName(bookingentime) }}</span> 
                      <v-chip class="mx-2" small v-if="bookingentime.deleted" color="red"><v-icon small color="white" left>mdi-delete</v-icon> <span style="color: #fff;">{{ $t('general.deleted', $store.state.locale) }}</span></v-chip> 
                    </h4>
                    <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" v-if="$store.state.userRoleNumber < 2 && bookingentime.subId && !$store.state.demoMode" class="mb-3">
                      <v-btn @click="copyTextToClipboard(bookingentime.subId)" class="ma-1 mb-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="color: #ffffff; text-transform: none;"><strong>{{bookingentime.subId}}</strong></v-btn>
                      <div style="display: inline-block" v-if="selectedBookingSummaryIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(bookingentime.subId)"><v-icon small color="#333">mdi-content-copy</v-icon> Kopieër Booking DocId</div>
                      <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
                    </v-card>
                    <v-container grid-list-md class="ma-0 pa-0">
                      <v-layout row wrap v-if="!bookingentime.deleted">
                        <v-flex xs6>
                          <v-text-field
                            dense
                            key="begin.index"
                            :ref="'inputStart' + index"
                            :label="$t('general.from', $store.state.locale)"
                            v-model="bookingentime.start"
                            type="time"
                            :suffix="$t('general.hour', $store.state.locale)"
                            outlined
                            :disabled="bookingentime.deleted || !changeBookingAllowed"
                            hide-details
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            v-on:input="
                              checkStartEnd(index);
                              changeAll && spreadChanges(bookingentime.start, index, 'start');
                              checkBalance(index);
                            "
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6>
                          <v-text-field
                            dense
                            :disabled="!changeBookingAllowed"
                            :label="$t('general.till', $store.state.locale)"
                            v-model="bookingentime.end"
                            :ref="'' + index"
                            type="time"
                            :suffix="$t('general.hour', $store.state.locale)"
                            outlined
                            hide-details
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            v-on:input="
                              checkStartEnd(index);
                              changeAll && spreadChanges(bookingentime.end, index, 'end');
                              checkBalance(index);
                            "
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card>
                </div>
                <!-- <v-checkbox class="ma-0 pa-0" v-model="sendmail" label="Verstuur client een mail met aangepaste tijden"> </v-checkbox> -->

                <v-card flat class="my-2" style="overflow: hidden;" v-if="bookingHistory.length > 0 && $store.state.userRoleNumber < 3">
                  <v-expansion-panels accordion flat hover >
                  <v-expansion-panel max-width="500px" class="ma-0 pa-0">
                    <v-card outlined>
                    <v-expansion-panel-header class="pa-2 py-1 title" style="font-size: 14px" >
                      <span style="font-size: 16px!important"><strong>{{ $t('booking.adjustmentHistory', $store.state.locale) }}</strong></span>
                    </v-expansion-panel-header>
                    </v-card>
                    <v-expansion-panel-content>
      
                      <v-card flat class="py-2" color="background">
                        <div class="historyTabel">
                          <v-card link v-for="(item, index) in bookingHistory" :key="index" class="mb-3" outlined :ripple="false" :hover="false">
                            <v-card-title class="ma-0 pa-2 pl-0" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader}"  >

                            
                              <v-row no-gutters>
                                <v-col cols="2" style="text-align: left">  
                  
                              <v-list-item-avatar 
                                size="50"
                                light
                                class="ma-0 ml-2 mt-1 pa-0 custom-avatar-style" 
                                rounded="lg"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                >
                                <div><span style="font-size: 12px">{{ item.timestamp.seconds| moment("MMM")}}</span>
                                <p class="mb-0" style="font-size: 20px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary} "><strong>{{ item.timestamp.seconds | moment("D ")}}</strong></p>
                              </div>


                              </v-list-item-avatar>
                                </v-col>
                                <v-col cols="10">
                                  
                                  <div v-if="item.user == 'Thrive'" style="font-size: 14px; font-weight: bold; line-height: 24px">Smart Swap - {{ $t('booking.editedOn', $store.state.locale) }} {{ item.timestamp.seconds | moment("dddd D MMMM YYYY - H:mm") }}u</div>

                                  <div v-else style="font-size: 14px; font-weight: bold; line-height: 24px">{{ $t('booking.editedOn', $store.state.locale) }} {{ item.timestamp.seconds | moment("dddd D MMMM YYYY - H:mm") }}u</div>

                                  <div style="display:flex; align-items: baseline; line-height: 12px">
                                  <span><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" left small>mdi-account</v-icon></span>
                                  <span v-if="item.user == 'Thrive'" class="caption" >
                                    {{ item.user }}<span class="ml-5" :style="'color:' + $themes[$store.state.companyTheme][$store.state.themeModus].primary + ';' " ><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" left small>mdi-memory</v-icon> <strong> {{ item.role }} </strong></span>
                                  </span>
                                  <span v-else class="caption" >
                                    {{ item.user }}<span class="ml-5" ><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" left small>mdi-crown</v-icon>{{ item.role }}</span>
                                  </span>
                                </div>
                                </v-col>  
                              </v-row>
                            </v-card-title>
                            <div v-for="(booking, i) in item.bookings" :key="i">
                              <div class="ma-0">
                                <v-card-text class="bookingsDataContainer pa-1 ma-0 text-center">
                                  <v-container v-if="booking.deleted == false" fluidgrid-list-md fluid class="ma-0 pa-0">
                                    <v-layout row wrap class="ma-0 pa-0">
                                      <v-flex xs12 class="text-left">
                                        <strong>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, getDescription(booking)) }}</strong> 
                                      </v-flex>
                                      <v-flex xs3 class="text-left">{{ $t('booking.resource', $store.state.locale) }}</v-flex>
                                      <v-flex xs4 class="text-left">
                                        <v-icon small class="mr-1" v-if="getResourceTypeHistory(booking) == 'device'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-power-plug</v-icon>
                                        <v-icon small class="mr-1" v-if="getResourceTypeHistory(booking) == 'room'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cube-outline</v-icon>
                                        <v-icon small class="mr-1" v-if="getResourceTypeHistory(booking) == 'employee'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>
                                        <span>{{ getResourceNameHistoryOld(booking) }}</span>
                                      </v-flex>
                                      <v-flex xs2 style="width: 20px;" class="text-left">
                                        <v-icon  v-if="item.user == 'Thrive'" color="text" small class="ma-1">mdi-lightning-bolt</v-icon>
                                        <v-icon  v-if="getResourceNameHistoryNew(booking) != getResourceNameHistoryOld(booking)" color="text" small class="ma-1">mdi-arrow-right-bold</v-icon>
                                      </v-flex>
                                      <v-flex xs3 class="text-left">
                                        <div v-if="getResourceNameHistoryNew(booking) != getResourceNameHistoryOld(booking)" >
                                        <v-icon small class="mr-1" v-if="getResourceTypeHistory(booking) == 'device'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-power-plug</v-icon>
                                        <v-icon small class="mr-1" v-if="getResourceTypeHistory(booking) == 'room'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cube-outline</v-icon>
                                        <v-icon small class="mr-1" v-if="getResourceTypeHistory(booking) == 'employee'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>
                                        <span>{{ getResourceNameHistoryNew(booking) }}</span>
                                        </div>
                                      </v-flex>

                                      <v-flex xs3 class="text-left">{{ $t('booking.startTime', $store.state.locale) }} </v-flex>
                                      <v-flex xs4 class="text-left">{{ booking.oldStart | moment("D MMMM YYYY - H:mm") }}u</v-flex>
                                      <v-flex xs1 class="text-left">
                                        <v-icon v-if="booking.newStart != booking.oldStart" color="text" small class="ma-1">mdi-arrow-right-bold</v-icon>
                                      </v-flex>
                                      <v-flex xs4 class="text-left">
                                        <span v-if="booking.newStart != booking.oldStart" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ booking.newStart | moment("D MMMM YYYY - H:mm") }}u</span>
                                      </v-flex>
                                    </v-layout>
                                    <v-layout row wrap class="ma-0 pa-0">
                                      <v-flex xs3 class="text-left">{{ $t('booking.endTime', $store.state.locale) }}  </v-flex>
                                      <v-flex xs4 class="text-left">{{ booking.oldEnd | moment("D MMMM YYYY - H:mm") }}u</v-flex>
                                      <v-flex xs1 class="text-left">
                                        <v-icon v-if="booking.newEnd != booking.oldEnd" color="text" small class="ma-1">mdi-arrow-right-bold</v-icon>
                                      </v-flex>
                                      <v-flex xs4 class="text-left">
                                        <span v-if="booking.newEnd != booking.oldEnd" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" >{{ booking.newEnd | moment("D MMMM YYYY - H:mm") }}u</span>
                                      </v-flex>
                                    </v-layout>
                                  </v-container>
                                  <v-container v-if="booking.deleted == true" fluidgrid-list-md fluid class="ma-0 pa-0">
                                    <v-layout row wrap class="ma-0 pa-0">
                                      <v-flex xs12 class="text-left">
                                        <strong>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, getDescription(booking)) }}</strong> 
                                      </v-flex>
                                      <v-flex xs3 class="text-left">{{ $t('booking.deletedOn', $store.state.locale) }} </v-flex>
                                      <v-flex xs4 class="text-left">
                                        <span v-if="booking.deleteTimestamp" style="color: #ff4c51">{{ booking.deleteTimestamp.seconds | moment("D MMMM YYYY - H:mm") }}u </span>
                                      </v-flex>
                                      <v-flex xs1 class="text-left">
                                        <v-icon color="text" small class="ma-1">mdi-delete</v-icon>
                                      </v-flex>
                                    </v-layout>
                                  </v-container>
                                </v-card-text>
                              </div>
                              <v-divider v-if="i < (item.bookings.length - 1)"></v-divider>
                            </div>
                          </v-card>
                        </div>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-text v-if="loading" style="min-height: 250px">
          <div class="thrive-loader" :class="$store.state.companyTheme"></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left pl-0 ml-0">
                <div class="text-left">
                  <div v-show="!loading && !deleting && changeBookingAllowed && !bookingSummary.deleted" style="display: inline-block; margin: 0 5px 0 0 ">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" text icon>
                          <v-icon>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item link v-for="(item, index) in treatmentOptions" :key="index" @click.native="deleterCheck(item.id)">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>

                  <v-btn v-if="!loading" outlined :disabled="deleting" depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closedialog()">
                    {{ $t("general.cancel", $store.state.locale) }}
                  </v-btn>
                </div>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  :dark="changed || changedSummary || changedPreferredPractitioner"
                  :depressed="!changed && !changedSummary && !changedPreferredPractitioner"
                  :disabled="!changed && !changedSummary && !changedPreferredPractitioner"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  @click="updateTime()"
                  >
                  <div v-if="changed || changedSummary || changedPreferredPractitioner">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                  <div v-else>{{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>  
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
      
      <!-- / Refund Downpayment Dialog \ -->
      <v-dialog v-model="refundDownpayment.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`" persistent>
        <v-card>
          <v-card-text class="text-center ma-0 pt-4">
            <v-avatar 
              size="56"
              light
              class="ma-4 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">{{ refundDownpayment.icon}}</v-icon>
            </v-avatar>
            <h3 class="mb-4" v-html="refundDownpayment.title"></h3> 
            <p class="ma-0" v-html="refundDownpayment.info"></p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn outlined depressed :disabled="refundDownpayment.loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeRefundDownpaymentDialog()">
                    {{ $t('general.cancel', $store.state.locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn v-if="!refundDownpayment.success || refundDownpayment.loading" dark :loading="refundDownpayment.loading" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleter(1)">
                    {{ $t('general.remove', $store.state.locale) }}
                  </v-btn>
                  <v-btn v-if="refundDownpayment.success && !refundDownpayment.loading" dark :loading="refundDownpayment.loading" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleterClose()">
                    {{ $t('general.close', $store.state.locale) }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- \ Refund Downpayment Dialog / -->

      <!-- / Change Booking Client \ -->
      <v-dialog v-model="changeBookingClientData.dialog" eager scrollable max-width="700px">
        <v-card>
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h3>{{ $t('calendar.changeBookingClient', $store.state.locale) }}</h3>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeChangeClient()">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-alert v-if="changeBookingClientData.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ changeBookingClientData.feedback }}</v-alert>

          <v-divider></v-divider>

          <v-card-text class="ma-0 pa-0">
            <clientSelector :source="'changTreatmentTime'"></clientSelector>  
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeChangeClient()">
                  {{ $t('general.cancel', $store.state.locale) }} 
                  </v-btn>
                </v-flex>

                <v-flex xs12 md8 class="text-right">
                <v-btn 
                    @click="saveChangeBookingClient()"
                    :disabled="!changeBookingClientValid"
                    :dark="changeBookingClientValid"
                    :loading="changeBookingClientData.loading"
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                    {{ $t('calendar.changeClient', $store.state.locale) }}
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn> 
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>

        </v-card> 
      </v-dialog>
      <!-- \ Change Booking Client / --> 

    </v-dialog>
  </div>
</template>

<style scoped>
  .account-edit-link span {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid var(--v-text-base);
    color: var(--v-text-base);
    cursor: pointer;
    display: inline-block;
  }

  .account-edit-link:hover span {
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    cursor: pointer;
  }
</style>

<script>
  import { bus } from "../../main";
  import moment from "moment";

  import axios from "axios";
  import _ from "lodash";
  import db from "../../firebase/init";
  import firebase from "firebase/app";
  import "firebase/firestore";
  import InformClientModal from "./InformClientModal.vue";
  import clientSelector from "@/components/elements/clientSelector.vue";

  export default {
    props: ["changeTreatmentTimeModal", "selectedEventOriginal", "activeCompData", "activeBranchData", "activeBranch", "resources"],

    components: { 
      "inform-client-modal": InformClientModal ,   
      clientSelector: clientSelector      
    },

    data() {
      return {
        dialog: false,
        tab: 0,
        tabTransition: false,
        bookingSummary: new Object(),
        bookingSummaryOriginal: new Object(),
        deleting: false,
        loading: true,
        feedback: null,
        balancError: false,
        sendmail: false,
        changeAll: true,
        selectedEvent: new Object(),
        selected_treatmentOption: "",
        treatmentOptions: new Array(),
        messagesResending: false,
        messagesResendingText: false,
        bookingen: new Array(),
        bookingenOriginal: new Array(),
        devicesLoadedFiltered: new Array(),
        changedTime: false, //Begin of eindtijd is veranderd
        showSave: false, //Laat opslaan button zien.
        preferredPractitioner: false,
        preferredPractitionerOriginal: false,
        clientID: "",
        clientname: null,
        clientObject: new Object(),
        title: null,
        selectedBookingSummaryIdCopy: false,
        selectedPrepaymentIdCopy: false,
        downPaymentData: null,
        refundDownpayment: {
          valid: false,
          success: false,
          icon: "",
          title: "",
          info: "",
          dialog: false,
          loading: false
        },
        emailShowAll: false,
        datepickerdialog: false,
        bookingHistory: new Array(),
        emailHistory: new Array(),
        timeAgoCreated: null,
        timeAgoCreatedText: null,
        messagesHistory: new Array(),
        messageTypes: [
          {
            type: 'email',
            name: {
              nl: 'E-mail',
              en: 'Email',
              de: 'Email'
            },
            icon: "mdi-email"
          },
          {
            type: 'text',
            name: {
              nl: 'SMS',
              en: 'Text',
              en: 'Text'
            },
            icon: "mdi-cellphone-text"
          }
        ],
        defaultMessages: [
          {
            name: {
              nl: 'Afspraakbevestiging',
              en: 'Appointment Confirmation'
            },
            type: "appointmentConfirm"
          },
          {
            name: {
              nl: 'Verzoek om aanbetaling als bevestiging',
              en: 'Request for deposit as confirmation'
            },
            type: "appointmentConfirmDownPayment"
          },
          {
            name: {
              nl: 'Afspraakherinnering',
              en: 'Appointment reminder'
            },
            type: "appointmentReminder"
          },
          {
            name: {
              nl: 'Aanbetaling herinnering',
              en: 'Desposit reminder'
            },
            type: "downPaymentReminder"
          },
          {
            name: {
              nl: 'Afspraak annulering (geen aanbetaling ontvangen)',
              en: 'Appointment cancelation (no deposit received)'
            },
            type: "appointmentCancelNoDownPayment",
          },
          {
            name: {
              nl: 'Afspraak wijziging',
              en: 'Appointment changes'
            },
            type: "appointmentChanges"
          },
          {
            name: {
              nl: 'Afspraak annulering',
              en: 'Appointment cancelation'
            },
            type: "appointmentCancel"
          },
          {
            name: {
              nl: 'Inschrijfbevestiging',
              en: 'Subscription Confirmation'
            },
            type: "eventConfirm"
          },
          {
            name: {
              nl: 'Herinnering inschrijving',
              en: 'Subscription reminder'
            },
            type: "eventReminder"
          },
          {
            name: {
              nl: 'Inschrijving wijziging',
              en: 'Event changes'
            },
            type: "eventChanges"
          },
          {
            name: {
              nl: 'Inschrijving annulering of uitschrijving',
              en: 'Subscription cancelation or unsubscription'
            },
            type: "eventCancel"
          },
        ],
         
        messagesSenders:['postBookingWidget'],
        
        preferredPractitionerOptions: [
          {
            id: false,
            name: this.$t('booking.clientNoPrefer', this.$store.state.locale)
          },
          {
            id: true,
            name: this.$t('booking.clientPrefer', this.$store.state.locale)
          },
        ],

        changeBookingClientData: {
          dialog: false,
          loading: false,
          orginalClientId: null,
          selectedClientId: null,
          selectedClientData: new Object(),
          newClientData: new Object(),
          feedback: null,
          newClientValid: false
        },
        changeBookingClientChanges: 0,
        clientEmail: null,
        clientPhone: null,
        minute: 0,
        fiveMinute: 0
      };
    },

    

    mounted() {

      bus.$on("changeClientSelected", (data) => {
        console.log("Client is geselecteerd")
        if(data){
          this.changeBookingClientData.selectedClientId = data.id
          this.changeBookingClientData.selectedClientData = data
        }
        else{
          this.changeBookingClientData.selectedClientId = null;
          this.changeBookingClientData.selectedClientData = new Object()
        }
        this.changeBookingClientChanges++
      })

      bus.$on("changeClientSelectedNewValid", (newClientData, valid) => {
        this.changeBookingClientData.newClientValid = valid;
        this.changeBookingClientData.newClientData = newClientData;
        this.changeBookingClientChanges++
      })
 
      bus.$on("editBooking", (data) => {
        this.loading = true;
        this.selectedEvent = data;
        this.loadBookingSummary();
        this.treatmentOptions = [
          { name: this.$t('booking.removeAppointment', this.$store.state.locale), id: "1" },
          { name: this.$t('booking.removeAppointmentPart', this.$store.state.locale),  id: "2" }
        ];

        this.minute = Number(moment().format("m"));
        this.fiveMinute = Number(moment().format("m")) % 5 === 0;
      });
    },

    methods: {
      startTimeIsInFuture(){
        console.log("this.bookingSummary.start: ", this.bookingSummary.start, )
        return this.bookingSummary.start > moment().format('YYYY-MM-DDTHH:mm') ? true : false;
      },

      getMessageSender(senderId){
        let senderData = {
          type: 'employee',
          name: this.$t(`general.employee`, this.$store.state.locale)
        }
        let sender = this.messagesSenders.find((sender) => sender == senderId);
        if(sender){
          senderData.type = 'generated';
          senderData.name =  this.$t(`messages.${sender}`, this.$store.state.locale);
        }
        return senderData
      },

      async confirmAppointment(id){
        await db.collection("bookingsummary")
        .doc(id)
        .set({
          downPayment: {
            active: false,
            paid: false,
            extra: 'overrideByUser',
            user: this.$store.state.activeUserId
          }
        },{merge: true})
        this.fetchHistoryData() // Refesh data in modal
      },

   
      async fetchHistoryData() {
        this.bookingSummary = new Object();
        this.bookingSummaryOriginal = new Object();
        this.bookingHistory = new Array();
        await db.collection("bookingsummary")
        .doc(this.selectedEvent.bookingId)
        .get()
        .then((doc) => {
          this.bookingSummary = doc.data();
          this.bookingSummary.id = doc.id;
          // If there is history in the booking then...
          this.bookingHistory = doc.data().history != null ? doc.data().history.reverse() : new Array();
          this.emailHistory = doc.data().emailHistory != null ? doc.data().emailHistory.reverse() : new Array();
          this.emailShowAll = false;

          this.bookingSummaryOriginal = _.cloneDeep(this.bookingSummary);
          let bookingTime = moment(this.bookingSummary.created.seconds, "X");
          let minutes = moment().diff(bookingTime, 'minutes', false);
          let hours = Math.floor(minutes / 60);
          let days = Math.floor(hours / 24);
          let remainingMinutes = minutes - (60 * hours)
          let remainingHours = hours - (24 * days)

          let daysText = days > 0 ? days == 1 ? `${days} ${this.$t('general.day', this.$store.state.locale)}` : `${days} ${this.$t('general.days', this.$store.state.locale)},` : "";
          let hoursText = remainingHours > 0 ? (remainingHours > 1 ? `${remainingHours} ${this.$t('general.hours', this.$store.state.locale)} ${this.$t('general.and', this.$store.state.locale)}` : `${hours} ${this.$t('general.hour', this.$store.state.locale)} ${this.$t('general.and', this.$store.state.locale)}`) : "";
          let minutesText = remainingMinutes == 1 ? `${remainingMinutes} ${this.$t('general.minute', this.$store.state.locale)}` : `${remainingMinutes} ${this.$t('general.minutes', this.$store.state.locale)}`;

          this.timeAgoCreatedText = `${daysText} ${hoursText} ${minutesText} ${this.$t('booking.agoCreated', this.$store.state.locale)}`;
          this.timeAgoCreated = minutes;
          this.dialog = true;
        })
        .catch((err) => {
          console.error("error while fetching history");
        });
        this.getDownPaymenyData()
        await this.getMessageHistory(this.selectedEvent.bookingId)

        this.clientEmail = null;
        this.clientPhone = null;

        if(this.messagesHistory.length == 0){
          // Check if client has email
          db.collection('clients').doc(this.bookingSummary.clientId)
          .get()
          .then(doc => {
            if(doc.exists){
              if(doc.data().emails && doc.data().emails.length > 0){
                this.clientEmail = doc.data().emails.find(email=> {return email.primary}) ? doc.data().emails.find(email=> {return email.primary}).email : doc.data().emails[0];
              }
              if(doc.data().phones && doc.data().phones.length > 0){
                this.clientPhone = doc.data().phones.find(phone=> {return phone.primary}) ? doc.data().phones.find(phone=> {return phone.primary}).phone : doc.data().phones[0];
              }
            }
          })
        }
      },

      getMessageHistory(id){
        this.messagesHistory = new Array();
        db.collection('sendMessages')
        .where("bookingSummaryId", "==", id)
        .orderBy("sendDate", "asc")
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let message = doc.data();
            message.id = doc.id;
            this.messagesHistory.push(message);
          });
        })
      },

      getDownPaymenyData(){
        if(this.bookingSummary.downPayment && this.bookingSummary.downPayment.active && this.bookingSummary.uniqIdStamp){
          db.collection("prepayments") // Get Payment information
          .where("bookingSummaryId", "==", this.bookingSummary.id)
          .get()
          .then((snap) => {
            if (snap.size > 0) {
              snap.forEach((doc) => {
                let prepayment = doc.data();
                prepayment.id = doc.id;
                this.downPaymentData = prepayment;
              });
            }
            else{
              this.downPaymentData = null;
            }
          })
        }
        else{
          this.downPaymentData = null;
        }
      },

      numberWithCommas(x) {
        let price = Number(Number(x).toFixed(2));
        return price.toLocaleString("nl-NL", { style: "currency", currency: "EUR" });
      },

      customerCard() {
        this.$emit("changeTreatmentTimeChange", false);
        this.$emit("disableHighlightTimeChange", this.selectedEvent);
        this.dialog = false;
        bus.$emit("openCustomerInformationDialog", this.selectedEvent.bookingId, this.bookingSummary, "timeChange");
      },

      getDescription(booking) {
        // if (booking.deleted == true) {
        //   return this.bookingen.find((findedBooking) => findedBooking.subId === booking.bookingId).description + " " +  this.$t('booking.alreadyDeleted', this.$store.state.locale);
        // } else {
        //   return this.bookingen.find((findedBooking) => findedBooking.subId === booking.bookingId).description;
        // }
        return this.bookingen.find((findedBooking) => findedBooking.subId === booking.bookingId).description;
      },

      changeClient(){
        this.changeBookingClientData.dialog = true;
        this.changeBookingClientData.orginalClientId = this.bookingSummary.clientId;
      },
      
      async saveChangeBookingClient(){
        this.changeBookingClientData.loading = true;
        this.changeBookingClientData.feedback = null;
        let clientId = null;
        let clientFirstName = null;
        let clientLastName = null;

        if(this.changeBookingClientData.newClientValid){
          clientFirstName = this.changeBookingClientData.newClientData.firstName;
          clientLastName = this.changeBookingClientData.newClientData.surname;
          clientId = await this.$createNewClient(this.changeBookingClientData.newClientData);
        }
        else{
          clientId = this.changeBookingClientData.selectedClientData.id;
          clientFirstName = this.changeBookingClientData.selectedClientData.firstName;
          clientLastName = this.changeBookingClientData.selectedClientData.surname;
        }

        if(clientId){ // Check if id is present (A failed $createNewClient retuns: null)
          await new Promise((resolve)=>{
            let counter = 0;
            this.bookingen.forEach(booking=>{
              db.collection("bookingen")
              .doc(booking.subId)
              .update({
                clientId: clientId,
                firstName: clientFirstName,
                surname: clientLastName,
                clientname: `${clientFirstName} ${clientLastName}`
                // email ??
              })
              .catch((error) => {     
                console.error("Error updating bookingdocument with new client: ", error); // The document probably doesn't exist.
              });
              counter++
              if(counter == this.bookingen.length){
                resolve()
              }
            })
          })

          await db.collection("bookingsummary")
          .doc(this.selectedEvent.bookingId)
          .set({
            clientId: clientId,
            clientname: `${clientFirstName} ${clientLastName}`
          },{merge: true})
          .catch((error) => {
            console.error("Document Bookingsummary updated error:", error);
          });

          this.selectedEvent.clientname = `${clientFirstName} ${clientLastName}`;
          this.selectedEventOriginal.clientname = `${clientFirstName} ${clientLastName}`;
          this.bookingSummary.clientname = `${clientFirstName} ${clientLastName}`;
          this.bookingSummary.clientId = clientId;

          this.closeChangeClient();
        }
        else{
          this.changeBookingClientData.loading = false;
          this.changeBookingClientData.feedback = this.$t('general.addError', this.$store.state.locale);
        }
      },

      closeChangeClient(){
        this.changeBookingClientData = {
          dialog: false,
          loading: false,
          orginalClientId: null,
          newClientId: null,
          newClientData: new Object(),
          feedback: null
        };
        bus.$emit("resetClientSelect");
      },

      // changeBookingTreatment

      changeTreatment(){ 
        bus.$emit("openChangeTreatmentDialog", this.bookingSummary, "changeTreatmentTime");
      },

      downPaymentStatus(){
        if(this.timeAgoCreated > 720){
          return {
            color: "#ff4c51",
            text: this.$t('booking.downPaymentRed', this.$store.state.locale)
          }
        }
        else if(this.timeAgoCreated >360){
          return {
            color: "#ffb400",
            text: this.$t('booking.downPaymentOrange', this.$store.state.locale)
          }
        }
        else{
          return {
            color: "#56ca00",
            text: this.$t('booking.downPaymentGreen', this.$store.state.locale)
          }
        }
      },

      getResourceName(booking) {
        if(booking.resourceDocId){
          if (this.resources.length > 0) {
            let index = _.findIndex(this.resources, { id: booking.resourceDocId });
            if (index >= 0) {
              return this.resources[index].title;
            } else {
              return null;
            }
          } else {
            return null;
          }
        }
        else{
          return null;
        }
      },

      getMessageContentType(id){
        let messageInfo = this.defaultMessages.find(message => message.type == id);
        if(messageInfo){
          return messageInfo.name[(this.$store.state.locale).toLowerCase()]
        }
        else{
          return null
        }
      },

      getMessageType(messageInfo){
        let to =  {
          en: 'to ',
          nl: 'naar ',
          de: 'Zu'
        }
        let messageData = this.messageTypes.find(message => message.type == messageInfo.messageType);
        if(messageData){
          return {
            icon: messageData.icon,
            text: `${messageData.name[(this.$store.state.locale).toLowerCase()]} ${to[(this.$store.state.locale).toLowerCase()]} ${messageData.type == 'email' ? messageInfo.email : messageInfo.phone}` 
          }
        }
        else{
          return new Object()
        }
      },

      status(item){
        if(item.status){
          if(item.status == 'send' || item.status == 'not_delivered' || item.status == 'processing'){
            return {
              color: this.$themes[this.companyTheme][this.theme].quaternary, 
              text: this.$t(`messages.status.${item.status}`, this.$store.state.locale)
            }
          }
          else if(item.status == 'delivered'){
            if(item.opens_count > 0){
              return {
                color: this.$themes[this.companyTheme][this.theme].quaternary, 
                text: this.$t(`messages.status.opened`, this.$store.state.locale) + ` (${item.opens_count}x)`
              }
            }
            else{
              return {
                color: this.$themes[this.companyTheme][this.theme].quaternary, 
                text: this.$t(`messages.status.${item.status}`, this.$store.state.locale)
              }
            }
            
          }
          else{
            return {
              color: this.$themes[this.companyTheme][this.theme].secondary,
              text: item.status,
               
            }
          }
        }
        else{
          return {
            color: this.$themes[this.companyTheme][this.theme].quaternary, 
            text: 'Verzonden'
          }
        }
      },

      getResourceType(booking) {
        if(booking.resourceDocId){
          if (this.resources.length > 0) {
            let index = _.findIndex(this.resources, { id: booking.resourceDocId });
            if (index >= 0) {
              return this.resources[index].resourceTypeSelect;
            } else {
              return null;
            }
          } else {
            return null;
          }
        }
        else{
          return null;
        }
      },

      getResourceTypeHistory(booking) {
        if (this.resources.length > 0 && booking.newResourceDocId) {
          let index = _.findIndex(this.resources, { id: booking.newResourceDocId });
          if (index >= 0) {
            return this.resources[index].resourceTypeSelect;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },

      getResourceNameHistoryNew(booking) {
        if (this.resources.length > 0 && booking.newResourceDocId) {
          let index = _.findIndex(this.resources, { id: booking.newResourceDocId });
          if (index >= 0) {
            return this.resources[index].title;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },

      getResourceNameHistoryOld(booking) {
        if (this.resources.length > 0 && booking.oldResourceDocId) {
          let index = _.findIndex(this.resources, { id: booking.oldResourceDocId });
          if (index >= 0) {
            return this.resources[index].title;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },

      copyTextToClipboard(text, type) {
        console.log("copyTextToClipboard", text, type)
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(()=> {
          this.selectedBookingSummaryIdCopy = type == 'bookingsummary' ? true : false;
          this.selectedPrepaymentIdCopy = type == 'prepayment' ? true : false;
          setTimeout(()=>{ 
            this.selectedBookingSummaryIdCopy = false;
            this.selectedPrepaymentIdCopy = false;
          }, 1000);

        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      },

      async loadBookingSummary() {
        this.loading = true;
        let activeBookings = new Array();
        this.bookingen = new Array();
        await db.collection("bookingen")
          .where("bookingId", "==", this.selectedEvent.bookingId) // Dynamisch maken binnen halen per branch
          .orderBy("start", "asc") // Sorted by Start
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              this.clientID = doc.data().clientId;
              if (doc.data().deleted == false || !doc.data().deleted) {
                activeBookings.push("activeBooking")
              }
              this.bookingen.push({
                bookingId: doc.data().bookingId,
                bookingOverride: doc.data().bookingOverride,
                branch: doc.data().branch,
                clientname: doc.data().clientname,
                dateBooking: doc.data().dateBooking,
                description: doc.data().description,
                deviceId: doc.data().deviceId,
                email: doc.data().email,
                end: moment(doc.data().end).format("HH:mm"),
                eventColor: doc.data().eventColor,
                resourceDocId: doc.data().resourceDocId,
                start: moment(doc.data().start).format("HH:mm"),
                startMoment: doc.data().start,
                endMoment: doc.data().end,
                subId: doc.id,
                deleted: doc.data().deleted,
                title: doc.data().title,
                type: doc.data().type,
                timetype: doc.data().timetype,
                deleted: doc.data().deleted,
                preferredPractitioner: doc.data().preferredPractitioner ? doc.data().preferredPractitioner: false
              });
            });
          })

          .then(() => {
            this.bookingenOriginal = _.cloneDeep(this.bookingen);
            this.clientname = this.bookingen[0].clientname;
            this.title = this.bookingen[0].title;

            this.checkForPreferredPractitioner()

            //When there is 1 booking item
            if (activeBookings.length == 1) {
              // console.log("Er is maar 1 booking")
              this.treatmentOptions.splice(1, 1);
            }

            this.loading = false; 
            this.fetchHistoryData();
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error getting Booking document: ", error);
          });
      },

      getEmailInformation(emailinfo){
        console.log("emailinfo: ", emailinfo)
        axios
        .get(`${this.$store.state.messagesApi.url}/messageStatus?id=${emailinfo.messageId}`)
        .then((response) => {
          if (response) {
            //Email succesfully sent!
            console.log("Message Statusresponse: ", response)
            if(response.data && response.data.status == "delivered" && response.data.opens_count > 0){
              if(response.data.opens_count > emailinfo.openedNumber){
                emailinfo.openedNumber = response.data.opens_count;
                emailinfo.openedTimestamp = moment(response.data.last_event_time).format("YYYY-MM-DDTHH:mm");
              }
              emailinfo.clickNumber = response.data.clicks_count
              
            }
            else if(response.data && response.data.status == "not_delivered"){
              emailinfo.succesfullSend = false;
            }  
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      },


      checkForPreferredPractitioner(){
        this.preferredPractitioner = false;
        this.bookingen.forEach(booking=>{
          if(booking.preferredPractitioner){
            this.preferredPractitioner = true;
            
          }
        }) 
        this.preferredPractitionerOriginal = _.cloneDeep(this.preferredPractitioner);
      },

      closeRefundDownpaymentDialog(){
        this.refundDownpayment = {
          valid: false,
          success: false,
          icon: "",
          title: "",
          info: "",
          dialog: false,
          loading: false
        }
      },

      deleterClose(){
        this.closeRefundDownpaymentDialog();
        this.closedialog();
        bus.$emit("calendarAction", "bookingDelete");
        this.updateCacheTrigger();
      },

      deleterCheck(selectie){

        this.closeRefundDownpaymentDialog();

        if (selectie == 1){
          if(this.bookingSummary.downPayment && this.bookingSummary.downPayment.active && this.bookingSummary.downPayment.paid){ // Check for downpayment
            if( moment(this.bookingSummary.start).subtract(this.$store.state.activeBranch.calendarSettings.cancelTimeLimit, 'hours').format("YYYY-MM-DDTHH:mm") > moment().format("YYYY-MM-DDTHH:mm")){ // Check if appointment is canceled in time
              this.refundDownpayment.valid = true;
              this.refundDownpayment.icon = 'mdi-cash-refund';
              this.refundDownpayment.title = this.$t('treatments.removeRemoveTreatmentsRefund', this.$store.state.locale);
              this.refundDownpayment.info = this.$t('treatments.removeRemoveTreatmentsRefundInfo', this.$store.state.locale);
            }
            else{
              // No downpayment Refund
              this.refundDownpayment.icon = 'mdi-cash-remove';
              this.refundDownpayment.title = this.$t('treatments.removeRemoveTreatmentsNoRefund', this.$store.state.locale);
              this.refundDownpayment.info = this.$t('treatments.removeRemoveTreatmentsNoRefundInfo', this.$store.state.locale);
            }
            this.refundDownpayment.dialog = true;
          }
          else{
            this.deleter(1)
          }
        }
        else{
          this.deleter(2)
        }
      },

      async deleter(selectie) {
        this.refundDownpayment.loading = true;
        let docref = this.selectedEvent.eventID;

        //Verwijderd gedeelte behandeling
        if (selectie == 2) {

          //Find Selected Booking and map the ResourceId
          let resIds = this.bookingen.filter((booking) => booking.bookingId == this.selectedEvent.bookingId).map((event) => event.resourceDocId);

          let remainingBookings = this.bookingen.filter((booking) => !booking.deleted && booking.subId != this.selectedEvent.eventID );
          let newStart = remainingBookings[0].dateBooking + "T" + remainingBookings[0].start;
          let newEnd = remainingBookings[remainingBookings.length - 1].dateBooking + "T" + remainingBookings[remainingBookings.length - 1].end;
          //Emit To Delete Cache by cacheUpdate

          this.deleting = true;

          //History Object
          let history = {
            bookings: [{ deleted: true, deletedTimestamp: new Date(), bookingId: this.selectedEvent.eventID }],
            role: this.userRole,
            timestamp: new Date(),
            user: this.userFirstName + " " + this.userLastName,
          };

          //Update bookingsummary
          db.collection("bookingsummary")
            .doc(this.selectedEvent.bookingId)
            .update({
              start: newStart,
              end: newEnd,
              history: history && history.length > 0 ? firebase.firestore.FieldValue.arrayUnion(history) : new Array(),
            })
            .then(() => {
              console.log("Document Bookingsummary updated!");
            })
            .catch((error) => {
              console.error("Document Bookingsummary updated error:", error);
            });

          // Update Lead Doc with changed time
          this.updateLeadDocument(this.selectedEvent, newStart);

          await db.collection("bookingen")
          .doc(docref)
          .update({
            dateBooking: "deleted",
            deleted: true,
            deletedInfo: {
              normalDatebooking: this.selectedEvent.eventDateBooking,
              normalStart: this.selectedEvent.eventStart,
              normalEnd: this.selectedEvent.eventEnd,
              deletedby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")", //Dynamisch maken via Rollen systeem
              deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
            },
            // start: event.start.format(),
          })
          .catch((error) => { // The document probably doesn't exis
            console.error("Error updating document: ", error);
          });
          this.dialog = false;
          this.deleting = false;
          this.$emit("changeTreatmentTimeChange", false);
          this.$emit("disableHighlightTimeChange", this.selectedEvent);
          bus.$emit("calendarAction", "bookingDelete");
          //  //Trigger UpdateCache door het verzamelen van Resource ID via Bookingen
          this.updateCacheTrigger();
         

        }
        //Verwijderd volledige behandeling
        if (selectie == 1) {
          //History Object
          let history = {
            bookings: new Array(),
            role: this.userRole,
            timestamp: new Date(),
            user: this.userFirstName + " " + this.userLastName,
          };

          this.deleting = true;
          let resIds = new Array();
          let countUpdatedBookingen = 0;

          this.removeBookingDataFromReceiptDraft(this.selectedEvent.bookingId,); // Remove Booking Data From ReceiptDraft


          if(this.bookingSummary.downPayment && this.bookingSummary.downPayment.active && !this.bookingSummary.downPayment.paid){ // Check for open downpayment (to cancel)
            await axios.post(`${this.$store.state.connectionsApi.url}/mollie/cancelPayment`, {
            //await axios.post(`https://connections.thrive365.nl/mollie/paymentRefund`, {
              id: this.bookingSummary.id,
              type: "bookingSummary",
              description: "bookingCanceled"
            })
            .then((response) => {
              console.log("Mollie Payment Canceled Success:", response.data);
            })
            .catch(error=>{
              this.refundDownpayment.success = false;
              console.error("Error Mollie Payment Canceled : ", error.response.status, error.response.data)
            })
          }

          if(this.bookingSummary.downPayment && this.bookingSummary.downPayment.active && this.bookingSummary.downPayment.paid && !this.refundDownpayment.valid){ // Use downpayment as compensation
            await axios.post(`${this.$store.state.connectionsApi.url}/mollie/paymentCompensation`, {
              id: this.bookingSummary.id,
              type: "bookingSummary",
              description: "bookingCanceledToLate"
            })
            .then((response) => {
              this.refundDownpayment.success = true;
              console.log("Mollie Payment Compensation Success:", response.data);
            })
            .catch(error=>{
              this.refundDownpayment.success = false;
              console.error("Error Mollie Payment Compensation : ", error.response.status, error.response.data)
            })
          }
          
          if(this.refundDownpayment.valid){
            await axios.post(`${this.$store.state.connectionsApi.url}/mollie/paymentRefund`, {
              id: this.bookingSummary.id,
              type: "bookingSummary",
              description: "bookingCanceled"
            })
            .then((response) => {
              this.refundDownpayment.success = true;
              console.log("Mollie booking Canceled + Refund Success:", response.data);
            })
            .catch(error=>{
              this.refundDownpayment.success = false;
              console.error("Error Mollie booking Canceled + Refund : ", error.response.status, error.response.data)
            })
          }


          for (let index = 0; index < this.bookingen.length; index++) {
            resIds.push(this.bookingen[index].resourceDocId);

            if (this.bookingen[index].deleted == true) {
              countUpdatedBookingen++;
              if (countUpdatedBookingen == this.bookingen.length) {
                this.deleting = true;
                //Remove Resource Duplicates
                resIds = [...new Set(resIds)];
                this.deleteBookingSummary(this.selectedEvent.bookingId, this.selectedEvent.bookingId, history);
              }
            }
            else {
              db.collection("bookingen")
              .doc(this.bookingen[index].subId)
              .update({
                dateBooking: "deleted",
                deleted: true,
                deletedInfo: {
                  normalDatebooking: this.selectedEvent.eventDateBooking,
                  normalStart: this.selectedEvent.eventStart,
                  normalEnd: this.selectedEvent.eventEnd,
                  deletedby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")", //Dynamisch maken via Rollen systeem
                  deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
                },
                // start: event.start.format(),
              })
              .then(() => {
                countUpdatedBookingen++;
                history.bookings.push({ deleted: true, deletedTimestamp: new Date(), bookingId: this.bookingen[index].subId });

                if (countUpdatedBookingen == this.bookingen.length) {
                  this.deleting = false;
                  //Remove Resource Duplicates
                  resIds = [...new Set(resIds)];
                  this.deleteBookingSummary(this.selectedEvent, this.selectedEvent.bookingId, history);
                }
              })
              .catch((error) => { // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });
            }
          } 
          
          if(this.refundDownpayment.success){
            this.refundDownpayment.icon = "mdi-cash-check"
            this.refundDownpayment.title = this.$t('treatments.removeRemoveTreatmentsRefunded', this.$store.state.locale);
            this.refundDownpayment.info = this.$t('treatments.removeRemoveTreatmentsRefundedInfo', this.$store.state.locale);
          }
          else{
            this.refundDownpayment.dialog = false
          }
          this.refundDownpayment.loading = false;
          if(!this.refundDownpayment.dialog){
            this.$emit("changeTreatmentTimeChange", false);
            this.$emit("disableHighlightTimeChange", this.selectedEvent);
            this.dialog = false;
            bus.$emit("calendarAction", "bookingDelete");
            //Trigger UpdateCache door het verzamelen van Resource ID via Bookingen
            this.updateCacheTrigger();
          }
          

          // Show Modal to Inform Client or Send Email (based on branch settings)
          if(this.$store.state.activeBranch.calendarSettings.informClientCalendarModal){
            bus.$emit('showInformClientModal', 'appointmentCancel', this.$store.state.activeBranch.id, this.selectedEvent.bookingId, this.selectedEvent.clientId, null)
          }
          else{
            if(this.$store.state.activeBranch.calendarSettings.informClientCalendar){
               //axios.get(`${this.$store.state.messagesApi.url}/cancelMail?doc=${this.selectedEvent.bookingId}&activebranch=${this.$store.state.activeBranch.id}&lang=nl`)
              axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, { doc: this.selectedEvent.bookingId, clientId: this.selectedEvent.clientId, branchId: this.$store.state.activeBranch.id, type: "appointmentCancel" })
              .then((response) => {
                if (response) {} //Email succesfully sent!
              })
              .catch((error) => {
                console.error("Error sending timeChanged email", error.message);
              });
            }
          }
        }
      },

      updateLeadDocument(booking, newStart){
        // console.log("updateLeadDocument: ", newStart)
        // Update Lead Doc with changed time
          if (booking.leadExtra && booking.leadExtra.leadId) {
            // Check if booking had ID of Lead Doc
            let leadData = null;
            db.collection("leads")
              .doc(this.selectedEvent.leadExtra.leadId)
              .get()
              .then((doc) => {
                leadData = doc.data();
                leadData.id = doc.id;
                if (leadData.actions && leadData.actions.length > 0) {
                  leadData.actions.forEach((action) => {
                    if (action.bookingSummaryId && action.bookingSummaryId == booking.bookingId) {
                      action.date = Number(moment(newStart).format("x"));
                    }
                  });
                }
              })
              .then(() => {
                db.collection("leads")
                  .doc(booking.leadExtra.leadId)
                  .set(
                    {
                      updated: new Date(),
                      actions: leadData.actions,
                    },
                    { merge: true }
                  )
                  .catch((error) => {
                    console.error("Error updating lead: ", error);
                  });
              })
              .catch((error) => {
                console.error("Error bij laden leaddata: ", error);
              });
            }
      },

      resendMessage(type){
        this.messagesResending = type == 'text' ? false: true;
        this.messagesResendingText =  type== 'text' ? true: false;

        axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, {doc: this.bookingSummary.id, branchId: this.$store.state.activeBranch.id, type: 'appointmentConfirm', force: type ? type : null})
        .then((response) => {
          if (response) { // Email succesfully sent!
            this.getMessageHistory(this.bookingSummary.id);
            this.messagesResending = false;
            this.messagesResendingText = false;
            // console.log("Message response: ", response)
          }
        })
        .catch((error) => {
          this.messagesResending = false;
          this.messagesResendingText = false;
          console.error(error.message);
        });
        
      },

      resendDownPaymentReminderMessage(type){
        this.messagesResending = true;
        axios.post(`${this.$store.state.messagesApi.url}/sendMessage`, { doc: this.bookingSummary.id, branchId: this.$store.state.activeBranch.id, type: "downPaymentReminder", amount: Number(this.downPaymentData.amount.number), force: type ? type : null})
        .then((response) => {
          if (response) { // Email succesfully sent!
            this.getMessageHistory(this.bookingSummary.id);
            this.messagesResending = false;
            // console.log("Message response: ", response)
          }
        })
        .catch((error) => {
          this.messagesResending = false;
          console.error(error.message);
        });
      },

      removeBookingDataFromReceiptDraft(bookingSummary){    
        new Promise((resolve, reject)=>{
          let receiptDraft = null;
          db.collection("receiptsDraft")
          .where("bookingSummaryIds","array-contains", bookingSummary)
          .get()
          .then(snap => {
            if(snap.size > 0){
              snap.forEach(doc => {
                receiptDraft = doc.data();
                receiptDraft.id = doc.id;
                resolve(receiptDraft)
              });
            }
            else{
              reject()
            }
          })
          .catch((error) => {
            console.error("Error getting Receipts Draft document  : ", error);
            reject()
          });
        })
        .then((receiptDraft)=>{
          if(receiptDraft.bookingSummaryIds.length > 1){ // Remove bookingSummaryId from receiptDraft
            let index = _.indexOf(receiptDraft.bookingSummaryIds, bookingSummary);
            receiptDraft.bookingSummaryIds.splice(index, 1)

            db.collection("receiptsDraft").doc(receiptDraft.id) // Update ReceiptDraft
            .set({
              bookingSummaryIds: receiptDraft.bookingSummaryIds,
              selectedItems: new Array(),
              modified: new Date()
            },{ merge: true})
            .catch((error) => {
              console.error("Error updating Receipts Draft document  : ", error);
            });
          }
          else{ // Remove receiptDraft
            console.log("Remove receiptDraft")
            db.collection("receiptsDraft").doc(receiptDraft.id).delete() // Delete ReceiptDraft
          }
        })
        .catch(()=>{
          console.log("No Receipts Draft found")
        })
      },
          

      deleteBookingSummary(booking, bookingSummaryId, history) {
        db.collection("bookingsummary")
          .doc(bookingSummaryId)
          .update({
            deleted: true,
            history: firebase.firestore.FieldValue.arrayUnion(history),
          })
          .then(() => {
            console.log("Document Bookingsummary updated!");
            this.updateCacheTrigger();
          })
          .catch((error) => {
            console.error("Document Bookingsummary updated error:", error);
          });


          if (booking.leadExtra && booking.leadExtra.leadId) {
          // Check if booking had ID of Lead Doc
          let leadData = null;
          db.collection("leads")
            .doc(booking.leadExtra.leadId)
            .get()
            .then((doc) => {
              leadData = doc.data();
              leadData.id = doc.id;
              if (leadData.actions && leadData.actions.length > 0) {
                leadData.actions = leadData.actions.filter(action => action.bookingSummaryId != bookingSummaryId); // Remove item with bookingSummaryId
              }
            })
            .then(() => {
              db.collection("leads")
                .doc(booking.leadExtra.leadId)
                .set(
                  {
                    updated: new Date(),
                    actions: leadData.actions,
                  },
                  { merge: true }
                )
                .catch((error) => {
                  console.error("Error updating lead: ", error);
                });
            })
            .catch((error) => {
              console.error("Error bij laden leaddata: ", error);
            });
        }
      },

      //Kijkt of de eerste waarde of laatste is veranderd
      checkStartEnd(indexnumber) {
        this.showSave = true;
        if (indexnumber === 0 || indexnumber === this.bookingen.length - 1) {
          this.changedTime = true;
        }
      },

      //Check if start time smaller than end time
      checkBalance(index) {
        let changedBooking = this.bookingen[index];
        let start = this.bookingen[index].start;
        let end = this.bookingen[index].end;
        //convert to date (example: 13:15  => HH:mm)
        /////////////////////new Date ("YYYY","MM","DD"   "H           H"     , "m           m")
        let startTimeObject = new Date(null, null, null, start[0] + start[1], start[3] + start[4]);
        let endTimeObject = new Date(null, null, null, end[0] + end[1], end[3] + end[4]);

        if (startTimeObject >= endTimeObject) {
          this.feedback = "starttijd moet kleiner zijn dan eindtijd!";
          this.balancError = true;
        } else {
          this.feedback = null;
          this.balancError = false;
        }
      },

      spreadChanges(newTime, index, status) {
        let oldTime,
          diffTime = null;
        //changed input at start value?
        if (status === "start") {
          oldTime = this.bookingenOriginal[index].start;
        }
        //changed input at end value?
        else if (status === "end") {
          oldTime = this.bookingenOriginal[index].end;
        }
        //Get different value between old and new time
        diffTime = this.getDifference(oldTime, newTime);

        //detect if changed time bigger or smaller than original

        let oldTimeObject = new Date(null, null, null, oldTime[0] + oldTime[1], oldTime[3] + oldTime[4]);
        let newTimeObject = new Date(null, null, null, newTime[0] + newTime[1], newTime[3] + newTime[4]);

        //detect if time added or removed
        let operator = oldTimeObject <= newTimeObject ? "sum" : "sub";

        //sum tow time string format HH:mm
        const addDiff = (time) => {
          return moment()
            .hours(time[0] + time[1])
            .minutes(time[3] + time[4])
            .add(diffTime[0] + diffTime[1], "hours")
            .add(diffTime[3] + diffTime[4], "minutes")
            .format("HH:mm");
        };
        //sub tow time string format HH:mm
        const removeDiff = (time) => {
          return moment()
            .hours(time[0] + time[1])
            .minutes(time[3] + time[4])
            .add(-(diffTime[0] + diffTime[1]), "hours")
            .add(-(diffTime[3] + diffTime[4]), "minutes")
            .format("HH:mm");
        };

        //Apply changes for all bookings
        this.bookingen.forEach((booking, i) => {
          booking.start = operator === "sum" ? addDiff(this.bookingenOriginal[i].start) : removeDiff(this.bookingenOriginal[i].start);
          booking.end = operator === "sum" ? addDiff(this.bookingenOriginal[i].end) : removeDiff(this.bookingenOriginal[i].end);
        }); //End foreach()...
      },

      //In format string HH:mm
      getDifference(time1, time2) {
        function toSeconds(time_str) {
          // Extract hours, minutes and seconds
          var parts = time_str.split(":");
          // compute  and return total seconds
          return (
            parts[0] * 3600 + // an hour has 3600 seconds
            parts[1] * 60
          ); // a minute has 60 seconds
        }

        var difference = Math.abs(toSeconds(time1) - toSeconds(time2));

        // format time differnece
        var result = [
          Math.floor(difference / 3600), // an hour has 3600 seconds
          Math.floor((difference % 3600) / 60), // a minute has 60 seconds
        ];
        // 0 padding and concatation
        result = result
          .map(function (v) {
            return v < 10 ? "0" + v : v;
          })
          .join(":");
        return result;
      },

      updateCacheTrigger() {
        let resIds = this.bookingen.map((booking) => booking.resourceDocId);
        resIds = [...new Set(resIds)];
        bus.$emit("cacheUpdate", resIds);
      },

      updateTime() {
        this.loading = true;

        // Update Bookingsummary & history

        let newStart = this.bookingen[0].dateBooking + "T" + this.bookingen[0].start;
        let newEnd = this.bookingen[this.bookingen.length - 1].dateBooking + "T" + this.bookingen[this.bookingen.length - 1].end;
        //History
        let TimeFormat = (booking, position) => booking.dateBooking + "T" + booking[position];

        let history = {
          bookings: new Array(),
          role: this.userRole,
          timestamp: new Date(),
          user: this.userFirstName + " " + this.userLastName,
        };

        for (let i = 0; i < this.bookingen.length; i++) {
          let newStart = TimeFormat(this.bookingen[i], "start");
          let oldStart = TimeFormat(this.bookingenOriginal[i], "start");

          let newEnd = TimeFormat(this.bookingen[i], "end");
          let oldEnd = TimeFormat(this.bookingenOriginal[i], "end");

          //Alleen bookingen die aangepast zijn
          if (newStart !== oldStart || newEnd !== oldEnd) {
            history.bookings.push({
              deleted: false,
              bookingId: this.bookingen[i].subId,
              newStart: newStart,
              oldStart: oldStart,
              newEnd: newEnd,
              oldEnd: oldEnd,
              newResourceId: this.bookingen[i].resourceDocId,
              oldResourceId: this.bookingen[i].resourceDocId,
            });
          }
        }

        // Update Lead Doc with changed time
        this.updateLeadDocument(this.selectedEvent, newStart);

        db.collection("bookingsummary")
          .doc(this.selectedEvent.bookingId)
          .set({
            start: newStart,
            end: newEnd,
            notes: this.bookingSummary.notes ? this.bookingSummary.notes : null,
            history: this.changedTime ? firebase.firestore.FieldValue.arrayUnion(history) : this.bookingSummary.history ? this.bookingSummary.history : new Array(),
          },{merge: true})
          .catch((error) => {
            console.error("Document Bookingsummary updated error:", error);
          });
        
        if(this.changedTime || this.changedPreferredPractitioner){
          new Promise((resolve)=>{
            let counter = 0;
            this.bookingen.forEach(booking=>{
              db.collection("bookingen")
              .doc(booking.subId)
              .update({
                start: booking.dateBooking + "T" + booking.start,
                end: booking.dateBooking + "T" + booking.end,
                preferredPractitioner: this.preferredPractitioner
              })
              .catch((error) => {     
                console.error("Error updating bookingdocument: ", error); // The document probably doesn't exist.
              });
              counter++
              if(counter == this.bookingen.length){
                resolve()
              }
            })
          })
          .then(()=>{
            this.$emit("changeTreatmentTimeChange", false);
            this.$emit("disableHighlightTimeChange", this.selectedEvent);
            this.dialog = false;
            this.loading = false;
            bus.$emit("calendarAction", "bookingChanged");
          })
          

          // for (let index = 0; index < this.bookingen.length; index++) {
          //   db.collection("bookingen")
          //     .doc(this.bookingen[index].subId)
          //     .update({
          //       start: this.bookingen[index].dateBooking + "T" + this.bookingen[index].start,
          //       end: this.bookingen[index].dateBooking + "T" + this.bookingen[index].end,
          //     })
          //     .then(() => {
          //       if (this.changedTime === true) {
          //         this.$emit("changeTreatmentTimeChange", false);
          //         this.loading = false;
          //         bus.$emit("calendarAction", "bookingChanged");
          //       }
          //     })

          //     .catch((error) => {     
          //       console.error("Error updating document: ", error); // The document probably doesn't exist.
          //     });
          // }
        }
        else{
          this.$emit("changeTreatmentTimeChange", false);
          this.$emit("disableHighlightTimeChange", this.selectedEvent);
          this.dialog = false;
          this.loading = false;
          bus.$emit("calendarAction", "bookingChanged");
        }
        
        //Trigger UpdateCache door het verzamelen van Resource ID via Bookingen
        this.updateCacheTrigger();
      },

      closedialog() {
        this.$emit("changeTreatmentTimeChange", false);
        this.$emit("disableHighlightTimeChange", this.selectedEvent);
        this.dialog = false;
        this.loading = true;
        this.tab = 0;
      },
    },
    computed: {

      changeBookingClientValid(){ // Check if saveChangeBookingClient can be submitted
        this.changeBookingClientChanges
        console.log("changeBookingClientValid Check")
        if(!this.changeBookingClientData.loading){  // Not loading
          if(this.changeBookingClientData.selectedClientId){ // New Client Selected
            return this.changeBookingClientData.orginalClientId == this.changeBookingClientData.selectedClientId ? false : true; // Check if selected client is not the same as existing Client
          }
          else if(this.changeBookingClientData.newClientValid){ // New client and valid
            return true;
          }
          else{ // No valid data
            return false;
          }
        } 
        else{ // Loading
          return false;
        }
      },

      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRoleNumber(){
        return this.employeeConnected ? this.connectedEmployee.userRoleNumber : this.$store.state.userRoleNumber;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
      changeBookingAllowed(){
        if((this.bookingSummary.end >  moment().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm")) || this.userRoleNumber < 2){
          return true;
        }
        else{
          return false;
        }
      },
      changedSummary() {
        if (_.isEqual(this.bookingSummaryOriginal, this.bookingSummary) == false) {
          return true;
        } else {
          return false;
        }
      },
      changed() {
        if (_.isEqual(this.bookingenOriginal, this.bookingen) == false && !this.balancError) {
          return true;
        } else {
          return false;
        }
      },
      changedPreferredPractitioner() {
        if (_.isEqual(this.preferredPractitioner, this.preferredPractitionerOriginal) == false) {
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>
