import Vue from "vue";
import Vuetify from "vuetify/lib";
import 'vuetify/src/styles/main.sass';
import '@mdi/font/css/materialdesignicons.css'
import { mdiCubeOutline } from '@mdi/js';
import { mdiCubeOffOutline } from '@mdi/js';


Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        light: {
            primary: "#00ADEF", 
            secondary: "#F48C00",
            tertiary: "#687387",
            background: "#f6f7fb",
            text: "#251433",
            menu: "#ffffff",
            menuhover: "#f6f7fb",
            menuborder: "#e8e9ee", 
            
        },
        dark: {
            primary: "#00ADEF",
            secondary: "#F48C00",
            tertiary: "#687387", 
            background: "#000000",
            text: "#f9f9f9",
            menu: "#292929",
            menuhover: "#3a3a3a",
            menuborder: "#434343", 
        },
    },

});
