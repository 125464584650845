<template>
	<v-app :style="[{ background: $themes[$store.state.companyTheme][$store.state.themeModus].background }, cssProps]" :class="$store.state.companyTheme">
		<v-snackbar height="50px" top v-model="$store.state.snackbarInfo.show" :timeout="$store.state.snackbarInfo.timeout" :color="$store.state.snackbarInfo.color">
			<v-btn class="ml-0 pa-0" color="white" @click="copyToClipboard($store.state.snackbarInfo.errorId);" icon>
				<v-icon left class="ml-0 pl-0" color="white" small>mdi-content-copy</v-icon>
			</v-btn>

			<span v-html="$store.state.snackbarInfo.text"></span>
			<template v-slot:action="{ attrs }">
				<v-btn color="white" text v-bind="attrs" @click="closeSnackbarDynamically">Close</v-btn>
			</template>
		</v-snackbar>

		<v-snackbar height="50px" top v-model="logout.active" :timeout="logout.timeout">
			<v-icon color="#8BC34A" class="mr-3">mdi-check-circle</v-icon>
			<span v-html="logout.name" ></span> {{ $t("general.loggedOut", $store.state.locale) }}
		</v-snackbar>

		<v-snackbar height="50px" v-model="keypad.show" top :timeout="keypad.timeout">
			<v-icon color="#8BC34A" class="mr-3">mdi-check-circle</v-icon> 
			<span v-html="keypad.success"></span>
		</v-snackbar>

		<!-- <v-overlay :value="keypad.active"></v-overlay> -->
		<v-card v-show="keypad.active" class="keypad-wrapper" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :class="{ large: keypad.success }" elevation="20">
			<div class="pincode-header" v-if="!keypad.success">
				<h3>{{ $t("general.enterPincode", $store.state.locale) }}</h3>
				<div class="close-button">
					<v-btn icon x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closePincode()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</div>

			<div class="pincode-feedback" v-if="!keypad.success">{{ keypad.warning }}</div>

			<div v-show="keypad.checking" style="position: relative; width: 100%; height: 86px">
				<div class="thrive-loader" :class="$store.state.companyTheme"></div>
			</div>

			<div v-if="!keypad.success">
				<v-container v-show="!keypad.checking" grid-list-md class="ma-0 py-0" :class="{ 'pincode-warning': keypad.warning }">
					<v-layout row wrap>
						<v-flex xs12>
							<v-form>
								<v-otp-input id="pincodeOTP" autofocus length="4" type="password" v-model="keypad.input" :disabled="this.keypad.checking" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @finish="checkPincode"></v-otp-input>
							</v-form>
						</v-flex>
						
						<!-- <v-flex xs3>
							<div class="pincode-number-input">
								<input
									hide-details
									class="ma-0 pa-0"
									type="number"
									id="pincodeOne"
									@input="pincodeEntered('one', 'input')"
									@keydown.backspace="pincodeEntered('one', 'backspace')"
									v-model="keypad.numbers.one"
								/>
							</div>
						</v-flex>
						<v-flex xs3>
							<div class="pincode-number-input">
								<input
									hide-details
									class="ma-0 pa-0"
									type="number"
									id="pincodeTwo"
									@input="pincodeEntered('two', 'input')"
									@keydown.backspace="pincodeEntered('two', 'backspace')"
									v-model="keypad.numbers.two"
								/>
							</div>
						</v-flex>
						<v-flex xs3>
							<div class="pincode-number-input">
								<input
									hide-details
									class="ma-0 pa-0"
									type="number"
									id="pincodeThree"
									@input="pincodeEntered('three', 'input')"
									@keydown.backspace="pincodeEntered('three', 'backspace')"
									v-model="keypad.numbers.three"
								/>
							</div>
						</v-flex>
						<v-flex xs3>
							<div class="pincode-number-input">
								<input
									hide-details
									class="ma-0 pa-0"
									type="number"
									id="pincodeFour"
									@input="pincodeEntered('four', 'input')"
									@keydown.backspace="pincodeEntered('four', 'backspace')"
									v-model="keypad.numbers.four"
								/>
							</div>
						</v-flex> -->
					</v-layout>
				</v-container>
			</div> 

			<div class="clear-pincode" v-if="!keypad.success">
				<span @click="clearPincode()" v-if="!keypad.checking">{{ $t("general.clearPincode", $store.state.locale) }}</span>
			</div>
		</v-card>

		<section id="page-wrapper">
			<section 
				v-if="navigation && $store.state.userCompanies && $store.state.userCompanies.length > 0"
				id="menu-wrapper"
				:class="{ 'menu-expanded': $store.state.menuOpen }"
				:style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].menuBackground }"
				>
				<side-menu ></side-menu>
			</section>

			<section :id="contentWrapperId" :class="{ 'menu-expanded': $store.state.menuOpen }">
				<div style="z-index: 9" v-if="navigation && userCompany" :class="[{ 'theme-card': menuShadow, 'theme-shadow': menuShadow }, $store.state.companyTheme]">
					<v-app-bar
						class="mx-3 custom-menu-header-bar"
						absolute
						:color="menuBarColor"
						dense
						flat
						style="-webkit-app-region: drag; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; z-index: 5"
						:class="[{ 'theme-card': menuShadow, 'theme-shadow': menuShadow }, $store.state.companyTheme, { 'transparant-menu': !menuShadow }]"
						>
						<v-toolbar-title>{{ title }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<div v-if="$route.path == '/agenda'" class="local-time">{{ localetime }}</div>

						<v-spacer></v-spacer>

						<v-tooltip dark bottom v-if="$store.state.clientSaveData"> 
							<template v-slot:activator="{ on }">
								<v-chip v-on="on" small close label close-icon="mdi-close" @click:close="$store.state.clientSaveData = null" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style" > <v-icon small left>mdi-content-save</v-icon>{{ $store.state.clientSaveData.firstName }} {{ $store.state.clientSaveData.lastName }}</v-chip>
							</template>
							<span class="tooltip-text-white">{{ $t('conversion.clientCopied', $store.state.locale) }}</span>
						</v-tooltip>

						<!-- / API DEVELOP MENU \ -->
						<div v-if="userRoleNumber == 0 && development && !$store.state.demoMode">
							<v-menu
								transition="slide-y-transition"
								v-model="developMenu"
								:close-on-content-click="false"
								open-on-hover
								offset-y
								:content-class="`theme-shadow ${$store.state.companyTheme}`"
								:color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
								>
								<template v-slot:activator="{ on }">
									<v-badge :color="developStatus" overlap dot offset-x="10" offset-y="10">
										<v-btn small icon color="text" class="mx-2" v-on="on">
											<v-icon>mdi-api</v-icon>
										</v-btn>
									</v-badge>
								</template>

								<v-card max-width="430px" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
									<div class="pa-3 py-2">
										<p class="ma-0">
											<strong>API Developer Settings</strong>
											<br />
											Alleen zichtbaar voor developer
										</p>
									</div>
									<v-divider></v-divider>
									<div v-for="(api, index) in apis" :key="index">
										<v-list dense class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
											<v-list-item class="ma-0 px-2">
												<v-list-item-icon class="pt-5 mt-2">
													<v-icon :color="api.color">mdi-checkbox-blank-circle</v-icon>
												</v-list-item-icon>

												<v-list-item-content>
													<v-list-item-title>{{ api.name }}</v-list-item-title>
													<v-list-item-subtitle v-if="!$store.state[api.id].live">Develop server</v-list-item-subtitle>
													<v-list-item-subtitle v-else>Live server</v-list-item-subtitle>
													<v-list-item-subtitle>
														<i>{{ $store.state[api.id].url }}</i>
													</v-list-item-subtitle>
												</v-list-item-content>
												<v-list-item-action>
													<v-switch
														:input-value="$store.state[api.id].live ? true : false"
														inset
														hide-details
														:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														@click="setToDevelop(api.id)"
													></v-switch>
												</v-list-item-action>
											</v-list-item>
										</v-list>
										<v-divider></v-divider>
									</div>
									<div>
										<v-list dense class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
											<v-list-item class="ma-0 px-2">
												<v-list-item-icon class="pt-5 mt-2">
													<v-icon :color="$store.state.calendarApiCache ? 'green': 'red'">mdi-checkbox-blank-circle</v-icon>
												</v-list-item-icon>

												<v-list-item-content>
													<v-list-item-title>Calendar API Cache</v-list-item-title> 
													<v-list-item-subtitle>Caching van resources voor extra snelheid</v-list-item-subtitle>
													<v-list-item-subtitle v-if="$store.state.calendarApiCache">
														<i>fetchResourceCache wordt <strong>WEL</strong> gebruikt</i>
													</v-list-item-subtitle>
													<v-list-item-subtitle v-else>
														<i>fetchResourceCache wordt <strong>NIET</strong> gebruikt</i>
													</v-list-item-subtitle>
												</v-list-item-content>
												<v-list-item-action>
													<v-switch
														:input-value="$store.state.calendarApiCache"
														inset
														hide-details
														:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
														@click="setCalendarApiCache()"
													></v-switch>
												</v-list-item-action>
											</v-list-item>
										</v-list>
										
									</div>
								</v-card>
							</v-menu>
						</div>
						<!-- \ API DEVELOP MENU / -->

						

						<v-tooltip dark bottom v-if="development && !$store.state.demoMode"> 
							<template v-slot:activator="{ on }">
								<v-btn small v-on="on" class="mx-2" icon @click="throwError()">
									<v-icon>mdi-bug</v-icon>
								</v-btn>
							</template>
							<span class="tooltip-text-white">Error genereren voor development</span>
						</v-tooltip>

						<v-tooltip dark bottom v-if="development === true && recordDevelopment === false && !$store.state.demoMode"> 
							<template v-slot:activator="{ on }">
								<v-btn small v-on="on" icon @click="startRecordLog" >
									<v-icon :color="recordDevelopment ? 'red' : 'grey'">mdi-record</v-icon>
								</v-btn>
							</template>
							<span class="tooltip-text-white">Recordings aanzetten</span>
						</v-tooltip>

						<v-tooltip dark bottom v-if="development === true && recordDevelopment === true && !$store.state.demoMode"> 
							<template v-slot:activator="{ on }">
								<v-btn small v-if="recordDevelopment === true" v-on="on" class="mx-2" icon @click="stopRecordDevelopment">
									<v-icon :color="recordDevelopment ? 'red' : 'grey'">mdi-stop</v-icon>
								</v-btn>
							</template>
							<span class="tooltip-text-white">Recordings uitzetten</span>
						</v-tooltip>
						
						<div v-if="userRoleNumber < 2">
							<v-tooltip dark bottom v-if="!$store.state.demoMode"> 
								<template v-slot:activator="{ on }">
									<v-btn small icon color="text" v-on="on" @click="setDemoMode(true)" class="mx-2">
										<v-icon>mdi-play</v-icon>
									</v-btn>
								</template>
								<span class="tooltip-text-white">Superadmin weergave verbergen</span>
							</v-tooltip>
							

							<v-tooltip dark bottom v-if="$store.state.demoMode"> 
								<template v-slot:activator="{ on }">
									<v-btn small icon color="text" v-on="on" @click="setDemoMode(false)" class="mx-2">
										<v-icon color="#FF4500">mdi-stop</v-icon>
									</v-btn>
								</template>
								<span class="tooltip-text-white">Superadmin weergave tonen</span>
							</v-tooltip>
						</div>

						<v-btn small icon v-if="$store.state.themeModus == 'light'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="setDarkTheme(true)" class="mx-2">
							<v-icon>mdi-brightness-7</v-icon>
						</v-btn>

						<v-btn small icon v-if="$store.state.themeModus == 'dark'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="setDarkTheme(false)" class="mx-2">
							<v-icon>mdi-brightness-4</v-icon>
						</v-btn>

						<!-- <v-btn small icon class="mx-2">
						<v-icon>mdi-bell</v-icon>
						</v-btn> -->

						<v-avatar
							class="ml-2"
							v-if="!employeeConnected && $store.state.activeUserRole == 'branch'"
							@click="showKeypad()"
							size="38"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
							style="cursor: pointer"
						>
							<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-dialpad</v-icon>
						</v-avatar>

						<v-avatar
							class="ml-2"
							v-if="employeeConnected"
							@click="connectedEmployeeLogout()"
							size="38"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
							style="cursor: pointer"
						>
							<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-lock</v-icon>
						</v-avatar>

						<!-- / USER MENU \ -->
						<v-menu
							transition="slide-y-transition"
							v-model="accountMenu"
							:close-on-content-click="true"
							offset-y
							:content-class="`theme-shadow ${$store.state.companyTheme}`"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
						>
							<template v-slot:activator="{ on }">
								<v-avatar v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="employeeConnected" size="38" style="cursor: pointer">
									<span v-if="countDown.active" class="white--text" style="font-size: 20px">{{ countDown.seconds }}</span>
									<img v-else-if="$store.state.activeUserPhoto" :src="$store.state.activeUserPhoto" />
									<span v-else class="white--text" style="font-size: 20px">{{ $store.state.activeUserAbb }}</span>
								</v-avatar>

								<v-avatar v-else size="38" class="ml-2" v-on="on" style="cursor: pointer" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">
									<img v-if="$store.state.activeUserPhoto" :src="$store.state.activeUserPhoto" />
									<span v-else class="white--text" style="font-size: 20px">{{ $store.state.activeUserAbb  }}</span>
								</v-avatar>
							</template>

							<v-card max-width="200px" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
								<v-list dense disabled class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
									<!-- <v-list-item class="ma-0 px-2" v-if="connectedEmployee">
										<v-list-item-avatar size="36" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
											<img v-if="connectedEmployee.photo" :src="connectedEmployee.photo" />
											<span v-else class="white--text" style="font-size: 18px">{{ connectedEmployee.userAbb }}</span>
										</v-list-item-avatar>

										<v-list-item-content>
											<v-list-item-title>{{ connectedEmployee.name }} {{ connectedEmployee.surname }}</v-list-item-title>
											<v-list-item-subtitle>{{ companyName }}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item> -->

									<v-list-item class="ma-0 px-2">
										<v-list-item-avatar size="36" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">
											<img v-if="$store.state.activeUserPhoto" :src="$store.state.activeUserPhoto" />
											<span v-else class="white--text" style="font-size: 18px">{{ $store.state.activeUserAbb }}</span>
										</v-list-item-avatar>

										<v-list-item-content>
											<v-list-item-title>{{ $store.state.activeUserFirstName }} {{ $store.state.activeUserLastName }}</v-list-item-title>
											<v-list-item-subtitle>{{ $store.state.activeCompany.name  }}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list>

								<v-divider></v-divider>

								<v-list dense class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
									<router-link to="/account" custom v-slot="{ navigate }" v-if="connectedEmployee && connectedEmployee.uid">
										<v-list-item class="ma-0 px-2" @click="navigate">
											<v-list-item-icon>
												<v-icon>mdi-cog</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ $t("general.account", $store.state.locale) }}</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</router-link>

									<router-link v-else to="/account" custom v-slot="{ navigate }">
										<v-list-item class="ma-0 px-2" @click="navigate">
											<v-list-item-icon>
												<v-icon>mdi-cog</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ $t("general.account", $store.state.locale) }}</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</router-link>

									<v-list-item class="ma-0 px-2" @click="connectedEmployeeLogout()" v-if="connectedEmployee">
										<v-list-item-icon>
											<v-icon>mdi-logout</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>{{ $t("general.logout", $store.state.locale) }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item class="ma-0 px-2" v-else @click="logoutUser()">
										<v-list-item-icon>
											<v-icon>mdi-logout</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>{{ $t("general.logout", $store.state.locale) }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-card>
						</v-menu>
						<!-- \ USER MENU / -->
					</v-app-bar>
				</div>

				<section :id="contentId" :class="{ 'agenda-view': $route.path == '/agenda' }" @scroll="handleScroll">
					<router-view v-if="$route.path == '/login' || $store.state.critcalDataLoaded" class="router-view" :key="$route.fullPath" :scrollStatus="scrollStatus" :contentWidth="contentWidth" />
				</section>
			</section>
		</section>

		<v-dialog v-model="dialogconnection" scrollable max-width="360" width="360" :content-class="`custom-dialog ${$store.state.companyTheme}`">
			<app-modalconnectionlost @newQuestionDialogChange="dialogconnection = $event"></app-modalconnectionlost>
		</v-dialog>

		<cash-up-modal></cash-up-modal>
		<general-delete-modal></general-delete-modal>
	</v-app>
</template>

<script>
import modalconnectionlost from "./components/modals/connectionlost.vue";
import cashUpModal from "./components/modals/CashUpModal.vue";
import generalDeleteModal from "./components/modals/GeneralDeleteModal.vue";
import db from "./firebase/init";
import { bus } from "./main";
import axios from "axios";
import moment from "moment";
import * as rrweb from "rrweb";
import uniqid from "uniqid";
import "firebase/storage";
import platform from "platform";
import { version } from "../package.json"
import firebase from "firebase/app";
import Vue from "vue";
import VueRouter from "vue-router";
// Solve the error when accessing the same routing address twice
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

export default {
	name: "App",
	components: {
		"app-modalconnectionlost": modalconnectionlost, //Verbinding verbroken
		"cash-up-modal" :cashUpModal,
		"general-delete-modal" :generalDeleteModal
	},

	data() {
		return {
			development: false,
			lastError: null,
			lastErrors: new Array(),
			errorId: null,
			recordDevelopment: false,
			recordedEvents: new Object(),
			lastEvents: new Array(),
			recorder: null,
			sessionErrorCount: 0,
			errorTimer: null,
			uniqVideoId: null,
			errorHandled: false,
			contentWidth: 0,
			nfcActive: false,
			dialogconnection: false,
			adminActive: false,
			navigation: false,
			removeConnectedEmployeeInterval: null,
			contentWrapperId: "content-wrapper-full",
			contentId: "content-full",
			accountMenu: false,
			developMenu: false,
			localetime: null,
			countDown: {
				active: false,
				seconds: 0,
			},

			scrollStatus: null,
			menuShadow: false,
			keypad: {
				input: null,
				active: false,
				checking: false,
				timeout: 3000,
				show: false,
				warning: null,
				numbers: {
					one: null,
					two: null,
					three: null,
					four: null,
				},
			},
			logout: {
				active: false,
				name: null,
				timeout: -1
			},
			apis: [
				{
					name: "Connections API",
					id: "connectionsApi",
					color: null,
				},
				{
					name: "Messages API",
					id: "messagesApi",
					color: null,
				},
				{
					name: "Reports API",
					id: "reportsApi",
					color: null,
				},
				{
					name: "Locations API",
					id: "locationsApi",
					color: null,
				},
				{
					name: "Rest API",
					id: "restApi",
					color: null,
				},
				{
					name: "Calendar API",
					id: "calendarApi",
					color: null,
				},
				{
					name: "Upload Bridge API",
					id: "uploadApi",
					color: null,
				},
			],
			overallApiStatus: {
				live: 0,
				develop: 0,
				error: 0,
				checking: true,
			},
		};
	},

	async created() {
		this.uniqVideoId = uniqid();
		let recordScript = ["../modules/consoleRecord.js"];

		recordScript.forEach((script) => {
			let tag = document.createElement("script");
			tag.setAttribute("src", script);
			document.head.appendChild(tag);
		});

		this.showNavigation();
		// console.log("App created: ",  window.platform.name, process.env.IS_ELECTRON )

		bus.$on("connectedEmployeeLogout", () => {
			this.removeConnectedEmployee();
		});

		bus.$on("localTimeChanged", (time) => {
			this.localetime = time;
		});

		if (process.env.NODE_ENV == "development") {
			this.development = true;
		}
		else {
			setTimeout(() => {
				this.startRecordLog();
			}, 5000);
		}

		if (window && window.platform && window.platform.name == "Electron") {
			// listens to the main process 'changeRouteTo' event and changes the route from
			// inside this Vue instance, according to what path the main process requires.
			// responds to Menu click() events at the main process and changes the route accordingly.
			const { ipcRenderer } = require("electron");
			ipcRenderer.on("changeRouteTo", (event, path) => {
				console.log("ipcRenderer router: ", path);
				this.$router.push(path);
			});

			this.checkforNFC();
			this.getPosPresets();
		}
		if (window.localStorage.getItem("thriveDemoMode") == "true") {
			this.$store.dispatch("demoMode", true);
		} else {
			window.localStorage.setItem("thriveDemoMode", false);
			this.$store.dispatch("demoMode", false);
		}

		if (window.localStorage.getItem("calendarApiCacheDevelop") == "true") {
			this.$store.dispatch(`calendarApiCacheSet`, true);	
		} else {
			this.$store.dispatch(`calendarApiCacheSet`, false);
		}

		if (window.localStorage.getItem("thriveDarkmode") == "true") {
			this.$vuetify.theme.dark = true;		
		} else {
			window.localStorage.setItem("thriveDarkmode", false);
			this.$vuetify.theme.dark = false;
		}

		this.$store.dispatch("setThemeModus", this.$vuetify.theme.dark ? 'dark' : 'light');


		if(this.development){ // Only check API's in development modus
			this.apis.forEach((api) => {
				if (window.localStorage.getItem(`${api.id}Develop`) == "true") {
					this.$store.dispatch(`${api.id}Set`, "develop");
				}
			});
			this.checkApis();
		}
		

		setInterval(() => {
			if (navigator.onLine == false) {
				// alert("Browser is offline");
				this.dialogconnection = true;
				// console.log("Ik ben offline");
			} else {
				this.dialogconnection = false;
			}
		}, 3000);

	
		
		
		
		// setTimeout(() => {
		// 	let contentDiv = document.getElementById("content")
		// 	let routerView = document.querySelector(".router-view")
		// 	console.log("contentDiv && routerView: ", contentDiv , routerView)
		// 	if(contentDiv && routerView){
		// 		setTimeout(() => {
		// 			contentDiv.addEventListener("scroll", this.handleScroll);
		// 		}, 1000);
				
		// 		setInterval(() => {
		// 			new ResizeObserver(this.handleScroll).observe(document.querySelector(".router-view"));
		// 			new ResizeObserver(this.handleScroll).observe(document.querySelector("#content"));
		// 			this.handleScroll();
		// 		}, 3000);
		// 	}
		// }, 1000);
	},
	watch: {
		// call again the method if the route changes
		$route: "showNavigation",
	},
	mounted() {
		// Load external rrweb script
		// let recordConsoleScript = document.createElement('script')
		//  recordConsoleScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/rrweb@2.0.0-alpha.2/dist/plugins/console-record.js')
		//  document.head.appendChild(recordConsoleScript)

		

		// if(!development) {
			//Send Global Errors to errorEventHandler
			window.onerror = (message, source, line, col, error) => {
				console.log("ERROR FOUND: Window.onerror: ", message, source, line, col, error);
				this.errorEventHandler(error, { message, source, line, col }, null);
			};

			bus.$on("eventError", (err, info, vm) => {
				console.log("ERROR FOUND: Bus eventError");
				this.errorEventHandler(err, info, vm);
			});

			//To catch unhandled promise rejections
			window.addEventListener("unhandledrejection",  (event) => {
				console.log("ERROR FOUND: window.addEventListener");
				this.errorEventHandler(event.reason, null, null);
			});
		// }

		


	},
	unmounted () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		setToDevelop(api) {
			if (this.$store.state[api].live) {
				console.log("Set to develop: ", `${api}Develop`);
				this.$store.dispatch(`${api}Set`, "develop");
				window.localStorage.setItem(`${api}Develop`, true);
			} else {
				this.$store.dispatch(`${api}Set`, "live");
				window.localStorage.setItem(`${api}Develop`, false);
			}
			this.checkApis();
		},

		setCalendarApiCache(){
			console.log("setCalendarApiCache", this.$store.state.calendarApiCache)
			if (this.$store.state.calendarApiCache == true) {
				window.localStorage.setItem(`calendarApiCacheDevelop`, false);
				this.$store.dispatch(`calendarApiCacheSet`, false);
			}
			else{
				window.localStorage.setItem(`calendarApiCacheDevelop`, true);
				this.$store.dispatch(`calendarApiCacheSet`, true);
				console.log("setCalendarApiCache set local to true")
			}
			
		},

		showKeypad() {
			this.keypad.active = true;
			this.makePinOneActive();
		},

		getPosPresets() {
			//get all docs from firebase colleciton "printerSettings" then push to array then push array to vuex "printerSettings"
			db.collection("posPresets")
				.get()
				.then((snapshot) => {
					snapshot.forEach((doc) => {
						let newPresetObj = {};
						newPresetObj = doc.data();
						newPresetObj.id = doc.id;
						this.$store.commit("addPosPreset", newPresetObj);
					});
				})
				.catch((err) => {
					console.log("Error getting posPresets documents", err);
				});
		},

		handleScroll() { // Check scrollheight and  make menu bar background blur
			let contentWrapper = document.querySelector("#content");
			let routerWrapper = document.querySelector(".router-view")
			let scrollHeight = document.querySelector("#content");

			let contentWrapperHeight = contentWrapper ? contentWrapper.clientHeight : 0;
			this.contentWidth = contentWrapper ? contentWrapper.clientWidth: 0;
			let routerWrapperHeight = routerWrapper ? routerWrapper.clientHeight : 0;

			
			if (contentWrapper ) {
				if(contentWrapper.scrollTop > 20){
					this.menuShadow = true;
				}
				else {
					this.menuShadow = false;
				}
			}
			else {
				this.menuShadow = false;
			}
			if (routerWrapperHeight && contentWrapperHeight > 0 && routerWrapperHeight > contentWrapperHeight) {

				if (scrollHeight.scrollTop >= contentWrapperHeight + 50) {
					this.scrollStatus = "bottom";
				} else {
					this.scrollStatus = "scrolling";
				}
			} else {
				this.scrollStatus = "bottom";
			}
		},

		logoutUser() {
			// ipcRenderer.send('toggle-subwindow')
			this.$store.dispatch("userLogout");
			console.log("companyTheme: ", this.$store.state.companyTheme)
			console.log("themeModus: ", this.$store.state.themeModus)
		},

		shutdown() {
			ipcRenderer.send("shutdown");
		},

		showNavigation() {
			if (this.$route.path != "/login") {
				this.navigation = true;
				this.contentWrapperId = "content-wrapper";
				this.contentId = "content";
			} else {
				this.navigation = false;
				this.contentWrapperId = "content-wrapper-full";
				this.contentId = "content-full";
			}
		},

		throwError() {
			throw new Error("Random Error" + uniqid());
			// throw new Error("Joe Error");
		},

		sendVidLogToBucket(stringifiedEvents, uniqLogId) {
			let vm = this;
			var metadata = {
				contentType: "application/json",
			};

			// .makePublic();
			let ref = firebase.storage().ref("thrive-error-logging/" + this.company + "_" + uniqLogId + ".json");
			ref.putString(stringifiedEvents).then((snapshot) => {
				console.log("Uploaded LOG");
			});
		},

		errorLogToBQ(errorId, errorElement, err, type) {
			let errorObj = {
				app: "thrive",
				version: version,
				browser: platform.name + " " + platform.version,
				companyId: this.$store.state.userCompany,
				branchId: "dynamic", //TODO get branch
				userId: this.$store.state.userId,
				userType: this.$store.state.userRole,
				userRole: this.$store.state.userRoleNumber,
				routerPath: this.$route.path,
				logType: type,
				recordingId: this.uniqVideoId,
				errorId: errorId ? errorId : "", //errorId is uniqid
				platform: JSON.stringify(platform.os),
				timestamp: new Date(),
				component: errorElement ? errorElement : "Global Error", //errorElement contains the component name
				errorMessage: err ? err.message : "", //error message contains the error message
				errorStack: err ? err.stack : "", //error stack is not always available
			};

			//axios post to stack.thrive365.nl/stream with headers cors localhost and content-type application/json
			axios
				.post("https://stack.thrive365.nl/stream", errorObj, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response) => {
					console.log("Log Send to stream", response);
				})
				.catch((err) => {
					console.log("Error logging to Thrive Error Stack ", err);
				});
		},

		/**
		 * @description
		 * This code is an error event handler.
		 * It is designed to record and log errors that occur in the application.
		 * It checks if the application is in production mode, and if so,
		 * it will increment the sessionErrorCount and create a unique ID for the error.
		 * It then sends the error to BigQuery (BQ) and adds a custom event to rrweb.record.
		 * The code also creates a video log of the error by calling getRecordGroup().
		 * Finally, it sets thisvm.lastError to err.message to prevent looping errors.
		 *
		 * @param {Error} err | The error object
		 * @param {Vue} vm | The Vue instance
		 * @param {string} info | Not used yet
		 * @returns {void}
		 *
		 */
		 copyToClipboard(errorId) {
			let dummy = document.createElement("input");
			document.body.appendChild(dummy);
			dummy.setAttribute("id", "dummy_id");
			document.getElementById("dummy_id").value = errorId;
			dummy.select();
			document.execCommand("copy");
			document.body.removeChild(dummy);
		},

		errorEventHandler(err, info, vm) {
			let thisvm = this;

			console.error("errorEventHandler ", err);

			/**
			 * Are we in production?
			 * thisvm.development === false
			 * In production mode (thisvm.development === false), the error will be logged to BQ and a video log will be created.
			 *
			 * thisvm.recordDevelopment === true
			 * If recordDevelopment is true by Record Button, the error will be logged to BQ and a video log will be created.
			 *
			 */

			if (thisvm.development === false || thisvm.recordDevelopment === true) {
				if(this.$store.state.userCompany){
					if (thisvm.sessionErrorCount < 20) {
						thisvm.sessionErrorCount++;

						// Not the same error over and over again.
						// console.log("thisvm.lastError: ", thisvm.lastError)
						// console.log("err.message;", err.message)

						let knowErrorInSessions = this.lastErrors.find(error => {return error == err.message})

						if (knowErrorInSessions == undefined) {
							this.lastErrors.push(err.message)
							//Create an Unique Error ID for this particular error
							let errorId = uniqid();
							thisvm.errorId = errorId;

							// Error elements means where the error is thrown
							let errorElement = vm !== null ? formatComponentName(vm) : "global Error";

							// Send Error Immediately to BQ
							thisvm.errorLogToBQ(errorId, errorElement, err, vm === null ? "globalError" : "vueError");

							rrweb.record.addCustomEvent("thriveError", {
								name: "some-error",
								uniqIdError: errorId,
							});

							//Create a Video Log of the Error
							getRecordGroup();

							//Copy the error Video log id thisvm.uniqVideoId to the clipboard
							if(this.$store.state.userRoleNumber == 0) {
								
								thisvm.$store.commit("setSnackBar", {
									show: true,
									color: "error",
									text: ` New error logged: <strong>${errorId}</strong>.`,
									errorId: errorId,
									timeout: 5000,
								});
							}
						}
						else{
							console.log("known error")
						}
					} else {
						window.location.reload();
					}
				}
				
			}

			function formatComponentName(vm) {
				if (vm.$root === vm) {
					return "root instance > app";
				}

				let name = vm._isVue ? vm.$options.name || vm.$options._componentTag : vm.name;

				let editedName = (name ? "component <" + name + ">" : "anonymous component") + (vm._isVue && vm.$options.__file ? " at " + vm.$options.__file : "");

				//Recursive path
				let path = new String();
				getRecursivePath(vm.$parent);
				// Getting the path of the component in the Vue HTML by recursing through the parents
				function getRecursivePath(parent) {
					if (parent.$parent) {
						path = path + " < " + parent.$vnode.tag;
						getRecursivePath(parent.$parent);
					} else {
						//stops here
						return;
					}
				}

				return editedName + path;
			}

			function timerVideoSessionBQ() {
				console.log("timerVideoSessionBQ")
				thisvm.errorTimer = setTimeout(() => {
					console.log("timerVideoSessionBQ setTimeout")
					if (thisvm.development) console.log("Send log with " + thisvm.sessionErrorCount + " to BQ Via TIMER with ID:", thisvm.uniqVideoId);

					if (thisvm.development) console.log("Delete Events with Error ID:", thisvm.uniqVideoId);

					let events = thisvm.recordedEvents[thisvm.uniqVideoId];
					let stringifiedEvents = JSON.stringify({ events });

					// console.log("stringifiedEvents: ", stringifiedEvents)
					//send log to bucket
					thisvm.sendVidLogToBucket(stringifiedEvents, thisvm.uniqVideoId);
					//Delete the events from the array

					thisvm.errorHandled = false;

					//Send to BQ
					thisvm.errorTimer = null;

					thisvm.sessionErrorCount = 0;
					//Create new Checkout
					rrweb.record.takeFullSnapshot({ isCheckout: true });
				}, 5000);
			}

			//Cluster by Time
			async function getRecordGroup() {
				console.log("getRecordGroup")
				thisvm.errorHandled = true;

				if (thisvm.errorTimer === null) {
					//First or no new error in 5 seconds
					console.log("Start Timer Null");
					timerVideoSessionBQ();
				} else {
					//Wipe-old timer and set new timer
					clearTimeout(thisvm.errorTimer);
					timerVideoSessionBQ();
				}
			}
			// Add error as last error to prevent looping errors
			// thisvm.lastError = _.cloneDeep(err.message);
		},

		startRecordLog() {
			console.log("startRecordLog")
			this.uniqVideoId = uniqid();
			let vm = this;
			this.recordDevelopment = true;

			// vm.$store.commit("updateRecordEventsClean");

			this.recorder = rrweb.record({
				emit: function emit(event, isCheckout) {
					// you should use console.log in this way to avoid errors.
					const defaultLog = console.log["__rrweb_original__"] ? console.log["__rrweb_original__"] : console.log;
					// defaultLog(event);
			
					if (isCheckout) {
						//Errors found check out
						vm.recordedEvents[vm.uniqVideoId] = new Array();

						

						if (vm.errorHandled) {
							console.log("Send log with " + vm.sessionErrorCount + " to BQ Via CHECKOUT with ID:", vm.uniqVideoId);
							//Send to BQ
							let events = vm.recordedEvents[vm.uniqVideoId];
							let stringifiedEvents = JSON.stringify({ events });

							vm.sendVidLogToBucket(stringifiedEvents, vm.uniqVideoId);

							console.log("Error Handler Delete Events with Error ID:", vm.uniqVideoId);
							delete vm.recordedEvents[vm.uniqVideoId];

							clearTimeout(vm.errorTimer);
							vm.errorTimer = null;
							vm.errorHandled = false;
							vm.sessionErrorCount = 0;

							//Send to BQ
						} else {
							//Remove from events from vm.recordedEvents by uniqVideoId
							console.log("No errors found, remove checkout events");
							
							delete vm.recordedEvents[vm.uniqVideoId];
						}

						vm.uniqVideoId = uniqid();
					}

					if (typeof vm.recordedEvents[vm.uniqVideoId] === "undefined") {
						vm.recordedEvents[vm.uniqVideoId] = [];
					}
					// Push First Element with URL, WIDTH and HEIGHT
					if(vm.recordedEvents[vm.uniqVideoId].length > 0 && vm.recordedEvents[vm.uniqVideoId][0].data && !vm.recordedEvents[vm.uniqVideoId][0].data.href){
						vm.recordedEvents[vm.uniqVideoId].unshift({
							data: {
								height: window.innerHeight,
								href: window.location.href,
								width: window.innerWidth,
							},
							timestamp: Number(moment().format("x")),
							type: 4
						})
					}
				
					vm.recordedEvents[vm.uniqVideoId].push(event);
				}, // customized options
				checkoutEveryNth: 100,

				plugins: [
					rrweb.getRecordConsolePlugin({
						level: ["error"],
						lengthThreshold: 1000,
						stringifyOptions: {
							stringLengthLimit: 1000,
							numOfKeysLimit: 100,
							depthOfLimit: 1,
						},
						logger: window.console,
					}),
				],
			});
		},

		stopRecordDevelopment() {
			this.recordDevelopment = false;
			this.recorder();
		},

		connectedEmployeeLogout() {
			bus.$emit("connectedEmployeeLogout");
		},

		setDemoMode(value) {
			window.localStorage.setItem("thriveDemoMode", value);
			this.$store.dispatch("demoMode", value);
		},

		makePinOneActive() {
			setTimeout(()=> {
				// document.getElementById("pincodeOne").focus();
				document.getElementById("pincodeOTP--0").focus();
			}, 100);
		},

		closePincode() {
			this.keypad.active = false;
			this.keypad.show = false;
			this.clearPincode();
		},

		closeSnackbarDynamically() {
			this.$store.commit("setSnackBar", {
				show: false,
			});
		},

		pincodeEntered(number, type) {
			if (number == "one") {
				if (type == "input") {
					if (this.keypad.numbers.one && this.keypad.numbers.one.length) {
						document.getElementById("pincodeTwo").focus();
					}
				} else {
					this.keypad.numbers.one == null;
				}
			} else if (number == "two") {
				if (type == "input") {
					if (this.keypad.numbers.two && this.keypad.numbers.two.length) {
						document.getElementById("pincodeThree").focus();
					}
				} else {
					this.keypad.numbers.one == null;
					document.getElementById("pincodeOne").focus();
				}
			} else if (number == "three") {
				if (type == "input") {
					if (this.keypad.numbers.three && this.keypad.numbers.three.length) {
						document.getElementById("pincodeFour").focus();
					}
				} else {
					this.keypad.numbers.two == null;
					document.getElementById("pincodeTwo").focus();
				}
			} else if (number == "four") {
				if (type == "input") {
					if (this.keypad.numbers.four && this.keypad.numbers.four.length) {
						this.checkPincode();
					}
				} else {
					this.keypad.numbers.three == null;
					document.getElementById("pincodeThree").focus();
				}
			}
		},

		finished(){

		},

		checkPincode() {
			console.log("checkPincode: ", this.keypad.input)
			this.keypad.checking = true;
			// let code = this.keypad.numbers.one + this.keypad.numbers.two + this.keypad.numbers.three + this.keypad.numbers.four;
			let code = this.keypad.input;
			let employeeId = null;

			

			db.collection("companies")
			.doc(this.$store.state.activeCompany.id)
			.collection("authentication")
			.doc("pincodes")
			.get()
			.then((doc) => {
				if (doc.exists) {
					let index = _.findIndex(doc.data().codes, ["pin", code]);
					console.log("index: ", index)
					if (index >= 0) {
						employeeId = doc.data().codes[index].employeeId;
						this.checkForConnectedEmployeePincode(employeeId);
					} else {
						this.pincodeNotFound();
					}
				} else {
					this.pincodeNotFound();
				}
			});
		},

		pincodeNotFound() {
			this.keypad.checking = false;
			this.keypad.warning = this.$t("general.pincodeIncorrect", this.$store.state.locale);
			setTimeout(() => {
				this.clearPincode();
			}, 3500);
		},

		clearPincode() {
			this.keypad.numbers.one = null;
			this.keypad.numbers.two = null;
			this.keypad.numbers.three = null;
			this.keypad.numbers.four = null;
			this.keypad.checking = false;
			this.keypad.warning = null;
			this.keypad.success = null;
			this.keypad.input = null;
			this.makePinOneActive();
		},

		checkForConnectedEmployeePincode(id) {
			console.log("checkForConnectedEmployeePincode: ", id);
			let resource = null;
			db.collection("employees")
				.doc(id)
				.get()
				.then((doc) => {
					if (doc.exists) {
						resource = {
							id: doc.id,
							type: "pincode",
							tagId: id,
							branches: doc.data().branches,
							name: doc.data().firstName,
							surname: doc.data().lastName,
							photo: doc.data().employeePhoto,
							uid: doc.data().uid,
							userAbb: (doc.data().firstName.charAt(0) + doc.data().lastName.charAt(0)).toUpperCase(),
							userRole: null,
							language: "NL",
							userId: null,
						};
						if (doc.data().uid && doc.data().userId) {
							db.collection("users")
								.doc(doc.data().userId)
								.get()
								.then((doc) => {
									let userRoleNumbers = {
										developer: 0,
										superadmin: 1,
										admin: 2,
										manager: 3,
										branch: 4,
										employee: 5,
									};

									resource.userId = doc.id;
									resource.userRole = doc.data().userRole;
									resource.userRoleNumber = userRoleNumbers[doc.data().userRole] ? userRoleNumbers[doc.data().userRole] : 10;
									resource.company = doc.data().company;
									resource.accessCompanies = doc.data().accessCompanies;
									resource.language = doc.data().language ? doc.data().language : "NL";

									this.$store.dispatch("connectEmployee", resource);
									this.keypad.checking = false;
									this.keypad.show = true;
									this.keypad.success = `${this.$t("general.loggedInAs", this.$store.state.locale)} <strong>${resource.name} ${resource.surname}</strong>`;
									this.checkMouseMovement();
									this.logout.name = "<strong>" + _.cloneDeep(this.$store.state.activeUserFirstName) + " " + _.cloneDeep(this.$store.state.activeUserLastName) + "</strong>";
									setTimeout(() => {
										this.closePincode();
										
									}, 2000);
								});
						} else {
							// No uid en userId
							resource.userId = null;
							resource.userRole = "employee";
							resource.userRoleNumber = 5;
							resource.company = doc.data().company;
							resource.accessCompanies = [doc.data().company];
							resource.language = doc.data().language ? doc.data().language : "NL";

							this.$store.dispatch("connectEmployee", resource);
							this.keypad.checking = false;
							this.keypad.success = `${this.$t("general.loggedInAs", this.$store.state.locale)} <strong>${resource.name} ${resource.surname}</strong>`;
							this.checkMouseMovement();
							setTimeout(() => {
								this.closePincode();
							}, 2000);
						}
					} else {
						// No connected employee found
						this.pincodeNotFound();
					}
				});
		},

		checkMouseMovement() {
			console.log("checkMouseMovement");
			let vm = this;
			let countdown = this.logoutTime;
			let timeout;
			let seconds;
			let counter;
			timer();

			function timer() {
				window.addEventListener("mousemove", mouseListener, false);
				timeout = setTimeout(() => {
					vm.removeConnectedEmployee();
				}, countdown);
				seconds = countdown / 1000;
				counter = setInterval(() => {
					vm.countDown.seconds = seconds;
					if (seconds <= 0) {
						resetTimer();
					}
					if (seconds == 10) { // Start countdown in browser
						let time = seconds * 1000;
						vm.countDown.active = true;
					}
					seconds -= 1;
				}, 1000);
			}

			function resetTimer() {
				vm.countDown.active = false;
				window.removeEventListener("mousemove", mouseListener, false);
				clearTimeout(timeout);
				clearInterval(counter);
				seconds = 20;
			}

			function mouseListener() {
				resetTimer();
				timer();
			}
		},

		checkForConnectedEmployee(id) {
			this.$store.dispatch("connectNFC", id);
			if (this.adminActive) {
				bus.$emit("stopCountDown");
				clearInterval(this.removeConnectedEmployeeInterval);
			}

			db.collection("employees")
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.where("nfcTags", "array-contains", id)
				.get()
				.then((snap) => {
					if (snap.empty) {
						console.log("leeg");
						if (
							this.$store.state.userRoleNumber < 3 ||
							(this.$store.state.connectedEmployee &&
								this.$store.state.connectedEmployee.userRole &&
								((this.$store.state.connectedEmployee && this.$store.state.activeUserRoleNumber < 2) ||
									(this.$store.state.connectedEmployee && this.$store.state.activeUserRoleNumber < 3)))
						) {
							bus.$emit("refreshNFC");
						}
					} else {
						if (!this.adminActive) {
							snap.forEach((doc) => {
								let resource = {
									id: doc.id,
									type: "nfc",
									tagId: id,
									branches: doc.data().branches,
									name: doc.data().firstName,
									surname: doc.data().lastName,
									photo: doc.data().employeePhoto,
									uid: doc.data().uid,
									userAbb: doc.data().firstName.charAt(0) + doc.data().lastName.charAt(0),
									userRole: null,
									language: "NL",
									userId: null,
								};
								if (doc.data().uid && doc.data().userId) {
									// console.log("UserId gevonden")
									let userRoleNumbers = {
										developer: 0,
										superadmin: 1,
										admin: 2,
										manager: 3,
										branch: 4,
										employee: 5,
									};
									db.collection("users")
										.doc(doc.data().userId)
										.get()
										.then((doc) => {
											resource.userId = doc.id;
											resource.userRole = doc.data().userRole;
											resource.company = doc.data().company;
											resource.accessCompanies = doc.data().accessCompanies;
											resource.language = doc.data().language ? doc.data().language : "NL";
											resource.userRoleNumber = userRoleNumbers[doc.data().userRole] ? userRoleNumbers[doc.data().userRole] : 10;
										})
										.then(() => {
											this.$store.dispatch("connectEmployee", resource);
										});
								} else {
									this.$store.dispatch("connectEmployee", resource);
								}
							});
						}
					}
				});
		},

		removeConnectedEmployee() {
			this.logout.active = true;

			setTimeout(() => {
				this.logout.active = false;
				this.logout.name = null;
			}, 2000);

			this.adminActive = false;
			this.$store.dispatch("disconnectNFC");
			this.$router.push("/home");
		},

		checkforNFC() {
			const { ipcRenderer } = require("electron");
			this.nfcActive = true;
			let vm = this;

			ipcRenderer.on("nfcDevice", function (event, data) {
				if (data) {
					console.log("Device gekoppeld");
				} else {
					console.log("Device ontkoppeld");
					vm.removeConnectedEmployee();
				}
			});

			ipcRenderer.on("nfcCard", (event, data) => {
				console.log("ipcRenderer.on (nfcCard)");
				if (this.$store.state.activeUserRole == "branch") {
					if (data == "removed") {
						if (
							this.adminActive ||
							(this.$store.state.connectedEmployee &&
								this.$store.state.connectedEmployee.userRole &&
								(this.$store.state.activeUserRoleNumber < 2 ||
									(this.$store.state.connectedEmployee && this.$store.state.activeUserRoleNumber < 3)))
						) {
							// Superadmin of admin even de tijd gunnen
							this.adminActive = true;
							let countdown = 10000;
							bus.$emit("startCountDown", countdown);
							this.removeConnectedEmployeeInterval = setInterval(() => {
								bus.$emit("stopCountDown");
								clearInterval(vm.removeConnectedEmployeeInterval);
								this.removeConnectedEmployee();
							}, countdown);
						} else {
							this.removeConnectedEmployee();
						}
					} else {
						// console.log("Tag geplaats: ", data)
						if (data.uid) {
							this.checkForConnectedEmployee(data.uid);
						}
					}
				}
				if (this.$store.state.userRoleNumber < 3) {
					if (data.uid) {
						this.checkForConnectedEmployee(data.uid);
					}
				}
			});
		},

		setDarkTheme(value) {
			this.$vuetify.theme.dark = value;
			this.$store.dispatch("setThemeModus", this.$vuetify.theme.dark ? 'dark' : 'light');
			window.localStorage.setItem("thriveDarkmode", value);
		},

		async checkApis() {
			this.overallApiStatus = {
				live: 0,
				develop: 0,
				error: 0,
				checking: true,
			};
			let counter = 0;
			await new Promise((resolve) => {
				this.apis.forEach((api) => {
					this.individualApiStatus(api.id).then((data) => {
						if (data == "live") {
							this.overallApiStatus.live++;
							api.color = "green";
						} else if (data == "develop") {
							this.overallApiStatus.develop++;
							api.color = "warning";
						} else {
							this.overallApiStatus.error++;
							api.color = "error";
						}
						counter++;
						if (counter == this.apis.length) {
							resolve();
						}
					});
				});
			});
			this.overallApiStatus.checking = false;
		},

		async individualApiStatus(apiName) {
			let apiError = false;
			var config = {
				method: "get",
				url: `${this.$store.state[apiName].url}/alive`,
				headers: {
					"Content-Type": "application/json",
				},
			};
			await axios(config).catch((error) => {
				apiError = true;
				console.error(`${apiName} error: ${error}`);
			});
			if (apiError) {
				return "error";
			} else {
				return this.$store.state[apiName].live ? "live" : "develop";
			}
		},
	},
	computed: {
		title() {
			if (this.$route.name) {
				return this.$t("routes." + this.$route.name, this.$store.state.locale) ? this.$t("routes." + this.$route.name, this.$store.state.locale) : "";
			} else {
				return "";
			}
		},
		// version() {
		// 	return this.$store.state.version;
		// },
		// theme() {
		// 	return this.$vuetify.theme.dark ? "dark" : "light";
		// },
		userCompany() {
			return this.$store.state.userCompany;
		},
		company() {
			return this.$store.state.userCompany;
		},

		connectedEmployee() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee : null;
		},

		menuBarColor() {
			return this.menuShadow ? this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].card + "85" : null;
		},
		userFirstName() {
			return this.$store.state.userFirstName;
		},
		userLastName() {
			return this.$store.state.userLastName;
		},
		userPhoto() {
			return this.$store.state.userPhoto;
		},
		userAbb() {
			return this.$store.state.userAbb;
		},
		userRole() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
		},
		developStatus() {
			return this.overallApiStatus.error == 0 ? (this.overallApiStatus.develop == 0 ? "green" : "warning") : "error";
		},
		userRoleNumber() {
			return this.employeeConnected ? this.connectedEmployee.userRoleNumber : this.$store.state.userRoleNumber;
		},
		userId() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
		},
		companyName() {
			return this.$store.state.companyName;
		},
		employeeConnected() {
			return this.$store.state.connectedEmployee ? true : false;
		},
		connectedEmployee() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee : null;
		},
		demoMode() {
			return this.$store.state.demoMode;
		},
		cssProps() {
			return {
				"--primary-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary,
				"--secondary-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary,
				"--tertiary-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].tertiary,
				"--quaternary-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].quaternary,
				"--background-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].background,
				"--card-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].card,
				"--cardOption-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].cardOption,
				"--warning-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].warning,
				"--cardHeader-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].cardHeader,
				"--heading-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].text,
				"--text-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].text,
				"--textSmooth-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].textSmooth,
				"--menuBackground-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].menuBackground,
				"--menuActivePrimary-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].menuActivePrimary,
				"--menuActiveSecondary-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].menuActiveSecondary,
				"--separator-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].separator,
				"--editor-row-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].emailEditorRow,
				"--editor-column-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].emailEditorColumn,
				"--editor-module-color": this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].emailEditorModule,
			};
		},

		logoutTime() {
			return this.$store.state.userRules && this.$store.state.userRules.logoutTime ? this.$store.state.userRules.logoutTime * 1000 : 60000;
		},
	},
};
</script>

<style>

.spelling-rows-wrapper .row.even-row{
	background-color: var(--background-color);
}
.spelling-rows-wrapper .row .col{
	padding: 2px!important
}

.spelling-rows-wrapper .spelling-special div{
	font-weight: 600!important;
}

.spelling-rows-wrapper .character{
	text-transform: uppercase
}

.floating-save-button {
	position: absolute;
	bottom: 50px;
	right: 50px;
	z-index: 999999;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
	background: var(--background-color);
}

.payment-bar-card {
	margin: 14px 0 0 0;
	position: fixed;
	bottom: 10px;
	background-color: rgba(255, 255, 255, 0.8);
}

.payment-bar-card .extraPadding {
	padding: 13px 0 0 0;
}

.payment-bar-card.scrolling {
	transition: 1s;
	border: 1px solid #eeeeee;
	z-index: 1;
	background-color: rgba(255, 255, 255, 0.8) !important;
	-webkit-backdrop-filter: blur(2px);
	backdrop-filter: blur(2px);
}

.custom-menu-header-bar {
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
}
.v-app-bar.v-toolbar.transparant-menu.v-sheet {
	background-color: transparent !important;
	-webkit-backdrop-filter: blur(0px);
	backdrop-filter: blur(0px);
}

.custom-measurement-label {
	color: var(--text-color);
}

.skinfold-progress-wrapper p {
	font-weight: 500;
	font-size: 12px;
	color: var(--text-color);
	padding: 0;
	text-align: center;
}

.theme--dark.v-data-table,
.theme--light.v-data-table,
.theme--dark.v-list,
.theme--light.v-list,
.theme--dark.v-card,
.theme--light.v-card,
.theme--light.v-tabs > .v-tabs-bar,
.theme--dark.v-tabs > .v-tabs-bar,
.theme--light.v-tabs-items,
.theme--dark.v-tabs-items,
.theme--dark.v-expansion-panels .v-expansion-panel,
.theme--light.v-expansion-panels .v-expansion-panel {
	background: var(--card-color);
}

/* tr,
  .theme--light.v-sheet,
  .theme--dark.v-sheet{
    border-color:  var(--separator-color)!important;
  } */

tr {
	border-color: var(--separator-color) !important;
}

.v-input fieldset {
	border-color: var(--separator-color) !important;
}

.v-input.v-input--is-focused fieldset {
	border-color: var(--primary-color) !important;
}

.v-input.v-input--is-focused .v-input__icon,
.v-input.v-input--is-focused .v-input__icon .v-icon {
	color: var(--primary-color) !important;
}

.vue-swatches__container.vue-swatches--inline {
	background: var(--card-color) !important;
}

.copy-tag-url {
	display: block;
	cursor: pointer !important;
	pointer-events: auto;
	text-align: center;
	font-size: 12px;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.copy-tag-url .v-icon {
	font-size: 12px !important;
}

.copy-tag-url.copied {
	font-style: italic;
}

.copy-tag-url:hover {
	cursor: pointer !important;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
.v-card > .v-card__subtitle {
	font-family: "Inter", sans-serif;
	line-height: 1.5;
	color: var(--heading-color);
	font-weight: 600;
	-webkit-font-smoothing: antialiased;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

h1 {
	font-size: 32px;
}
h2 {
	font-size: 24px;
}
h3 {
	font-size: 18px;
}
h4 {
	font-size: 16px;
}
h5 {
	font-size: 14px;
}
h6 {
	font-size: 11px;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span,
div span,
label span{
	font-size: revert-layer;
	font-weight: revert-layer;
}

p,
span,
label,
select,
div {
	font-family: "Inter", sans-serif;
	font-weight: 400;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.v-tabs > .v-tabs-bar .v-tab--disabled,
.v-card > .v-card__text {
	color: var(--text-color) !important;
}

p,
td {
	color: var(--text-color);
	line-height: 1.5;
}

.v-avatar.custom-avatar-style,
.v-chip.custom-chip-style,
.v-card.custom-card-style {
	background-color: transparent !important;
}
.v-chip.custom-chip-style .v-chip__content {
	font-weight: 600 !important;
	font-size: 12px;
}

.v-chip.custom-chip-style:before {
	opacity: 0.12;
}
.v-card.custom-card-style:before,
.v-avatar.custom-avatar-style:before {
	background-color: currentColor;
	border-radius: inherit;
	bottom: 0;
	content: "";
	left: 0;
	opacity: 0.12;
	position: absolute;
	pointer-events: none;
	right: 0;
	top: 0;
}

.v-card.custom-card-style.company-primary {
	color: var(--primary-color) !important;
}
.v-card.custom-card-style.company-secondary {
	color: var(--secondary-color) !important;
}
.v-card.custom-card-style.company-tertiary {
	color: var(--tertiary-color) !important;
}

.custom-textfield-style .v-input__slot {
	background-color: transparent !important;
}

.custom-textfield-style.company-primary .v-input__slot,
.custom-textfield-style.company-primary input {
	color: var(--primary-color) !important;
}

.custom-button-style.company-primary,
.custom-button-style.company-primary.v-btn--disabled,
.custom-button-style.company-primary.v-btn--disabled.v-btn--has-bg,
.custom-button-style.company-primary.v-btn--disabled .v-btn__content,
.custom-button-style.company-primary.v-btn--disabled .v-btn__content i,
.custom-button-style.company-primary .v-btn__content i,
.custom-button-style.company-primary .v-btn__content {
	background-color: transparent !important;
	color: var(--primary-color) !important;
}

.custom-button-style.company-secondary,
.custom-button-style.company-secondary.v-btn--disabled,
.custom-button-style.company-secondary.v-btn--disabled.v-btn--has-bg,
.custom-button-style.company-secondary.v-btn--disabled .v-btn__content,
.custom-button-style.company-secondary.v-btn--disabled .v-btn__content i,
.custom-button-style.company-secondary .v-btn__content i,
.custom-button-style.company-secondary .v-btn__content {
	background-color: transparent !important;
	color: var(--secondary-color) !important;
}

.custom-button-style.company-tertiary,
.custom-button-style.company-tertiary.v-btn--disabled,
.custom-button-style.company-tertiary.v-btn--disabled.v-btn--has-bg,
.custom-button-style.company-tertiary.v-btn--disabled .v-btn__content,
.custom-button-style.company-tertiary.v-btn--disabled .v-btn__content i,
.custom-button-style.company-tertiary .v-btn__content i,
.custom-button-style.company-tertiary .v-btn__content {
	background-color: transparent !important;
	color: var(--tertiary-color) !important;
}

.custom-button-style:before {
	background-color: currentColor;
	border-radius: inherit;
	bottom: 0;
	content: "";
	left: 0;
	opacity: 0.12;
	position: absolute;
	pointer-events: none;
	right: 0;
	top: 0;
}

.custom-textfield-style .v-input__control .v-input__slot:before {
	background-color: currentColor;
	border-radius: inherit;
	bottom: 0;
	content: "";
	left: 0;
	display: block;
	opacity: 0.12;
	position: absolute;
	pointer-events: none;
	right: 0;
	top: 0;
}

.dense-table td div,
.dense-table td span,
.dense-table td {
	font-size: 12px !important;
}
.dense-table td{
	height: auto !important;
	padding: 0 10px!important;
}

.dense-table th {
	padding-left:10px!important;
	padding-right:10px!important;
}

tr.future{
	opacity: 0.7;
}

.multi-button {
	width: 100%;
	text-align: center;
	vertical-align: middle;
	font-size: 16px;
	padding: 8px 0;
	position: relative;
	color: var(--separator-color);
	font-weight: bold;
	border-bottom: 1px solid var(--separator-color);
	border-top: 1px solid var(--separator-color);
	border-left: 1px solid var(--separator-color);
	border-right: 1px solid var(--separator-color);
	cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	overflow: hidden;
}

.multi-button p.multi-button-text {
	margin: 0;
	font-size: 14px;
	text-transform: uppercase;
	
	font-weight: 500;
	color: var(--text-color);
}

.multi-button:hover {
	cursor: pointer;
}

.multi-button.left {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.multi-button.middle {
	border-left: none;
	border-right: none;
}

.multi-button.middle.last {
	border-left: 1px solid var(--separator-color);
	border-right: none;
}

.multi-button.right {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}
.multi-button.active {
	color: var(--primary-color);
}

.multi-button.active p.multi-button-text {
	color: var(--primary-color);
}

.multi-button.active .multi-button-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: currentColor;
	opacity: 0.12;
	font-weight: bold;
}

.theme-shadow {
	overflow: hidden;
}
.chart-divider.jameda,
.chart-divider.thrive {
	border: none;
}

.custom-dialog,
.v-dialog.custom-dialog {
	border-radius: 10px !important;
}

.custom-dialog.square,
.v-dialog.custom-dialog.square {
	border-radius: 0px !important;
}

.theme-shadow.jameda{
	box-shadow: 0 2px 10px 0 rgba(94, 86, 105, 0.1) !important;
}
.theme-shadow.thrive {
	box-shadow: 0 2px 10px 0 rgba(94, 86, 105, 0.1) !important;
}

.theme-tab-header {
	z-index: 1;
	border-radius: 0 !important;
}

.theme-tab-header .v-tab {
	font-family: "Inter", sans-serif;
	letter-spacing: 1.5px;
	font-weight: 500 !important;
}

.theme-tab-header.jameda {
	box-shadow: 0 4px 8px -4px rgba(94, 86, 105, 0.42) !important;
}

.theme-tab-header.thrive {
	box-shadow: 0 4px 8px -4px rgba(94, 86, 105, 0.42) !important;
}

.v-card.obvious {
	border: 2px solid var(--primary-color) !important;
}

.v-timeline.custom-dense-timeline:before {
	top: 60px;
}

.theme-card {
	border-radius: 6px !important;
}

.v-item-group.theme--dark.v-tabs-items.transparant-tabs,
.v-item-group.theme--light.v-tabs-items.transparant-tabs {
	background: none !important;
	overflow: visible !important;
}

.pincode-card {
	border-radius: 15px;
}

.pincode-header {
	padding: 8px 0 0 0;
	text-align: center;
	position: relative;
}

.pincode-header .close-button {
	position: absolute;
	top: 10px;
	right: 10px;
}

.clear-pincode {
	height: 24px;
	padding: 5px 0 0 0;
	margin: 0 0 10px 0;
	text-align: center;
}

.clear-pincode span {
	font-size: 14px;
	color: var(--primary-color);
}
.clear-pincode:hover {
	font-weight: bold;
	cursor: pointer;
}

.pincode-success {
	width: 100%;
	padding: 10px;
	text-align: center;
}

.pincode-feedback {
	width: 100%;
	height: 30px;
	margin: 8px 0 0 0;
	font-size: 14px;
	text-align: center;
	font-weight: bold;
	color: #ff5252;
}

.keypad-wrapper {
	position: absolute;
	top: 10px;
	left: 50%;
	width: 250px;
	margin-left: -125px;
	z-index: 999;
	border-radius: 15px !important;
	box-shadow: 0 0 12px 5px rgba(139, 147, 166, 0.28);
}
.keypad-wrapper.large {
	width: 450px;
	margin-left: -225px;
}

.pincode-number-input input {
	border: 2px solid var(--text-color);
	background-color: var(--background-color);
	color: var(--text-color);
	font-size: 28px;
	border-radius: 10px;
	overflow: hidden;
	text-align: center;
	font-size: 24px;
	padding: 15px 0;
	display: block;
	width: 100%;
}

.pincode-warning .pincode-number-input input {
	border-color: #ff5252 !important;
}
.fc-col-header-cell.fc-resource .fc-col-header-cell-cushion {
	color: var(--text-color);
	font-weight: bold;
}

.fc .fc-col-header-cell-cushion {
	color: var(--primary-color);
}

.pincode-warning {
	/* also need animation and -moz-animation */
	-webkit-animation: shake 0.5s linear;
}
/* also need keyframes and -moz-keyframes */
@-webkit-keyframes shake {
	8%,
	41% {
		-webkit-transform: translateX(-10px);
	}
	25%,
	58% {
		-webkit-transform: translateX(10px);
	}
	75% {
		-webkit-transform: translateX(-5px);
	}
	92% {
		-webkit-transform: translateX(5px);
	}
	0%,
	100% {
		-webkit-transform: translateX(0);
	}
}

.pincode-number-input .v-input__slot {
	padding: 0 !important;
	min-height: 0px !important;
	border: none !important;
}

.pincode-number-input .v-text-field__slot {
	display: block !important;
}
.pincode-number-input .v-text-field--outlined fieldset {
	border: none !important;
}

.pincode-number-input input:focus {
	outline: none;
	caret-color: transparent;
	border: 2px solid var(--primary-color);
}

/* Chrome, Safari, Edge, Opera */
.pincode-number-input input::-webkit-outer-spin-button,
.pincode-number-input input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.pincode-number-input input[type="number"] {
	-moz-appearance: textfield;
}

*:focus {
	outline: none;
}
html {
	overflow: hidden;
}
body {
	width: 100%;
	position: relative;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: var(--background-color);
}

/* h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span, 
input,
select,
label {
  font-family: "Sofia Pro", sans-serif !important;
  color: var(--text-color);
  font-weight: 400;
  margin: 0;
} */

/*
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
select,
label {
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}

h2{
  font-size: 22px;
} */

/* div {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
} */

.login-wrapper-test {
	width: 300px;
	padding: 20px;
	border-radius: 3px;
	margin: 50px auto;
	background-color: #ffffff;
}

.theme--light.application {
	background: none !important;
}

a,
div,
span,
input,
textarea,
select:focus {
	outline: none;
}

h1.tab-title {
	padding: 0 0 10px 0;
	font-size: 24px;
	font-weight: bold;
}

.clear::after {
	display: block;
	content: "";
	clear: both;
}

#page-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: var(--background-color);
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

#header-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #ffffff;
	height: 80px;
	border-bottom: 1px solid #e8e9ee;
}

#menu-wrapper {
	left: 0;
	z-index: 5;
	position: absolute;
	width: 74px;
	height: 100%;
	border-right: 1px solid var(--v-menuborder-base);
	background-color: var(--v-menu-base);
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
#page-wrapper::-webkit-scrollbar,
#menu-wrapper::-webkit-scrollbar {
	display: none;
}

#menu-wrapper.menu-expanded {
	width: 275px;
}

.menu-item:hover .theme--light.v-icon {
	color: var(--primary-color);
	cursor: pointer;
}

.menu-item:hover {
	cursor: pointer;
}

.menu-item.router-link-active .theme--light.v-icon {
	color: var(--primary-color);
}

.header-toolbar {
	position: fixed;
	top: 0;
	left: 74px;
	z-index: 9;
	width: calc(100% - 74px);
}

.header-toolbar.menu-expanded {
	left: 275px;
	width: calc(100% - 275px);
}

#content-wrapper {
	position: absolute;
	left: 74px;
	top: 0;
	height: 100%;
	width: calc(100% - 74px);
	background-color: var(--background-color);
}

#content-wrapper.menu-expanded {
	left: 275px;
	width: calc(100% - 275px);
}

#content {
	position: relative;
	left: 0px;
	top: 0;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 60px 15px 15px 15px;
	overflow-y: auto;
	/* overflow-y: scroll; */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

#content.agenda-view {
	padding: 60px 0 0 0;
	overflow: hidden !important;
	overflow-y: hidden !important;
}

#content::-webkit-scrollbar {
	display: none;
}

#content.full-width {
	position: absolute;
	left: 0px;
	top: 0;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 50px 0 0 0;
	overflow-y: scroll;
}

#logo-box {
	height: 34px;
	width: 150px;
	/* background: url("./assets/img/thrive-logo@2x.png") no-repeat left center; */
	background-size: auto 34px;
	margin: 26px 0 0 30px;
	float: left;
}

.custom-selector,
.custom-selector.v-input__control,
.custom-selector.v-text-field__slot,
.custom-selector:hover .custom-selector label,
.custom-selector input,
.v-text-field.custom-selector > .v-input__control > .v-input__slot {
	cursor: pointer !important;
}

.custom-selector-item,
.custom-selector-item .item-text {
	background-color: var(--card-color);
	color: var(--v-text-base);
}

.custom-selector-item:hover,
.custom-selector-item:hover .v-list-item,
.custom-selector-item:hover .item-text {
	background-color: var(--cardHeader-color);
	color: var(--v-text-base);
}
.custom-selector-item.chosen,
.custom-selector-item.chosen .item-text {
	background-color: var(--primary-color);
	color: white;
}
.product-selected-active {
	color: var(--primary-color) !important;
}

.product-selected-active::before {
	opacity: 0.12;
}

/* HEADER MENU */

.header-client-profile {
	width: 286px;
	padding: 15px 10px 5px 20px;
	height: 55px;
	border-left: 1px solid #e8e9ee;
	float: left;
	height: 80px;
}

.header-client-name h4 {
	font-weight: 300;
	text-align: center;
	font-size: 16px;
	height: 75px;
	line-height: 75px;
}

.header-client-name .initials {
	font-weight: 300;
	text-align: center;
	font-size: 14px;
	color: var(--primary-color);
	padding: 10px 5px 5px 5px;
	border-radius: 5px;
	margin: 0 5px 0 0;
}

.header-client-name {
	width: calc(100% - 466px);
	float: left;
	height: 75px;
}

.client-photo {
	float: left;
	width: 45px;
	height: 45px;
	border-radius: 5px;
	overflow: hidden;
	background-color: #dfd2eb;
}
.client-photo p {
	/* font-family: "Sofia Pro", sans-serif !important; */
	font-size: 18px;
	color: #5f1d9a;
	text-align: center;
	text-transform: uppercase;
	padding: 11px 0 0 0;
}

.client-photo img {
	width: 100%;
	height: auto;
}

.user-menu {
	display: block;
	float: left;
	margin: 0 0 0 15px;
	width: 170px;
}

.user-menu-item-wrapper {
	display: none;
	width: 170px;
	background-color: #fff;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 7px 12px 0 rgba(139, 147, 166, 0.28);
}

.user-menu:hover > .user-menu-item-wrapper {
	position: relative;
	display: block;
	z-index: 99999;
}

.user-menu-item {
	display: block;
	color: #8b93a6;
	font-size: 14px;
	padding: 10px;
	text-decoration: none;
}

.user-menu:hover {
	cursor: pointer;
}

.user-menu-item:hover {
	cursor: pointer;
	background-color: var(--primary-color);
	color: #fff;
}

.client-name {
	display: block;
	float: left;
	height: 45px;
	line-height: 45px;
	margin: 0;
	font-weight: 600;
	color: #2a0c44;
	float: left;
}

.user-menu-arrow {
	margin-top: 22px;
	float: right;
	border: 6px solid #8b93a6;
	border-left-color: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent;
}

.custom-select-hogans {
	width: 480px;
	height: 48px;
	margin: 0 20px 0 0;
	float: left;
	position: relative;
}

.custom-select-hogans.float {
	float: left;
}

.custom-select-hogans.medium {
	width: 380px;
}

.custom-select-hogans.small {
	width: 280px;
}

.custom-select-hogans:hover > .custom-select-hogans-items-wrapper {
	display: block;
}

.custom-select-hogans:hover > .custom-select-hogans-value {
	border-color: var(--primary-color);
}

.custom-select-hogans-arrow {
	position: absolute;
	margin-top: 22px;
	right: 20px;
	border: 6px solid #8b93a6;
	border-left-color: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent;
}

.custom-select-hogans-value {
	width: calc(100% - 30px);
	height: 44px;
	line-height: 48px;
	font-size: 18px;
	color: var(--primary-color);
	padding: 0 15px;
	border: 2px solid #e8e9ee;
	border-radius: 50px;
	background-color: #fff;
}
.custom-select-hogans-items-wrapper {
	display: none;
	max-height: 350px;
	background-color: #ffffff;
	box-shadow: 0 7px 12px 0 rgba(139, 147, 166, 0.28);
	border-radius: 5px;
	position: absolute;
	width: 100%;
	top: 40px;
	left: 25px;
	overflow-y: scroll;
	cursor: pointer;
	z-index: 999;
}

.custom-select-hogans-item {
	display: block;
	width: calc(100% - 20px);
	color: #8b93a6;
	font-size: 14px;
	padding: 15px 10px;
	cursor: pointer;
}

.custom-select-hogans-item:hover {
	background-color: var(--primary-color);
	color: #fff;
	cursor: pointer;
}

/* AMDIN MENU */

.admin-menu-add {
	display: block;
	float: right;
	margin: 0 0 0 15px;
	width: 50px;
	position: relative;
}

.admin-menu-item-wrapper {
	display: none;
	position: absolute;
	width: 170px;
	top: 30px;
	right: 30px;
	z-index: 99;
	background-color: #fff;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 7px 12px 0 rgba(139, 147, 166, 0.28);
}
.admin-menu-item-wrapper a {
	color: rgba(139, 147, 166, 1) !important;
}

.admin-menu-add:hover > .admin-menu-item-wrapper {
	display: block;
}

.admin-menu-item {
	display: block;
	color: #8b93a6;
	font-size: 14px;
	padding: 10px;
	text-decoration: none;
}

.admin-menu-add:hover {
	cursor: pointer;
}

.admin-menu-item:hover {
	cursor: pointer;
	background-color: var(--primary-color);
	color: #fff !important;
}

/* PROMOTION MENU */

.promotion-menu-add {
	display: block;

	margin: 0 0 0 15px;
	width: 50px;
	position: relative;
}

.promotion-menu-item-wrapper {
	display: none;
	position: absolute;
	min-width: 200px;
	max-width: 600px;
	top: 37px;
	left: 30px;
	z-index: 99;
	background-color: #fff;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 7px 12px 0 rgba(139, 147, 166, 0.28);
}

.promotion-menu-add:hover > .promotion-menu-item-wrapper {
	display: block;
}

.promotion-menu-item {
	display: block;
	color: #8b93a6;
	font-size: 14px;
	padding: 10px;
	text-decoration: none;
}

.promotion-menu-add:hover {
	cursor: pointer;
}

.promotion-menu-item:hover {
	cursor: pointer;
	background-color: var(--primary-color);
	color: #fff;
}

.content-header {
	width: 100%;
	height: 65px;
}

.content-header-extra {
	width: 100%;
}

.header-select {
	width: 50%;
	float: left;
}

.header-select-select {
	width: 350px;
	float: left;
	margin: 0 20px 0 0;
}

.header-select-button {
	width: auto;
	float: left;
	margin: 0;
}

.content-header-extra .header-title {
	float: none;
}

.content-header-extra .header-edit {
	float: right;
}

.content-header-extra .header-content {
	margin: 0 0 15px 0;
}

.content-header.border {
	border-bottom: 1px solid #e8e9ee;
}

.sub-header h3 {
	float: left;
	margin: 0 20px 0 0;
}

.sub-header.border {
	border-bottom: 1px solid #e8e9ee;
}

.tab-component-header {
	padding: 0 0 15px 0;
}

.tab-component-header-title,
.header-title {
	width: 50%;
	float: left;
	min-height: 1px;
}

.header-title h1 {
	margin: 0;
	padding: 0;
	font-size: 26px;
	color: #251433;
}

.tab-component-header-edit,
.header-edit {
	width: 50%;
	float: left;
}
.tab-component-header-edit-search,
.header-edit-search {
	float: right;
	width: 250px;
	margin: 0 0 0 10px;
}
.tab-component-header-edit-button,
.header-edit-button {
	float: right;
	width: auto;
	margin: 0 0 0 20px;
}

input.hogans-input {
	float: right;
	width: 260px;
	padding: 10px 15px;
	border-radius: 44px;
	font-size: 16px;
	border: 2px solid #e8e9ee;
}

input.hogans-input-square {
	float: right;
	width: 260px;
	padding: 10px 15px;
	border-radius: 5px;
	font-size: 16px;
	border: 2px solid #e8e9ee;
}

input.hogans-input-square:focus {
	border: 2px solid var(--primary-color);
}

.small-button {
	float: right;
	border-radius: 44px;
	display: block;
	width: 44px;
	height: 44px;
	margin: 0 0 0 15px;
	background-color: #fff;
	border: 1px solid #e8e9ee;
}

.small-button.purple {
	float: right;
	border-radius: 44px;
	display: block;
	width: 44px;
	height: 44px;
	margin: 0 0 0 15px;
	background-color: #fff;
	border: 1px solid var(--primary-color);
	background: var(--primary-color);
	box-shadow: 0 4px 10px 0 rgba(0, 173, 239, 0.3);
}

.employee-default-times-wrapper {
	padding: 10px 20px;
}

.employee-default-times-wrapper .flex {
	height: 40px;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.54);
}

.employee-default-times-wrapper .flex .v-input {
	margin: 0 !important;
	padding: 0 !important;
}

.branch-default-times-expantion-header {
	font-size: 16px;
	font-weight: 600;
	color: var(--background-color);
}

.branch-default-times-wrapper .flex {
	height: 40px;
	font-size: 15px;
	color: var(--text-color);
	width: 100% !important;
}

.branch-day-active {
	padding: 0 0 0 15px;
}

.branch-default-times-wrapper .table-header {
	font-weight: 600;
}

.branch-default-times-wrapper .flex .v-input {
	margin: 0 !important;
	padding: 0 !important;
}

#branch-settings-container {
	padding: 10px !important;
}

.branch-time-exeptions-wrapper {
	padding: 10px 30px 10px 0;
}

.branch-settings-box-header {
	padding: 15px 15px 0 15px;
}

.branch-start-hours {
	padding: 0 20px 0 0;
}

.branch-settings-box {
	padding: 15px;
	width: 100% !important;
}

.branch-settings-box.no-padding {
	padding: 0;
}

#branch-settings-container .branch-settings-flex {
	padding: 10px;
}

#branch-settings-container .theme--light.v-table {
	background-color: rgba(246, 247, 251, 0.3) !important;
}

.table-list-item.active.true .circle {
	border-color: #73db02;
}

.grap {
	cursor: move !important;
	cursor: -webkit-grabbing !important;
}

/* HOGANS MODALBOX */

.hogans-modal-header {
	position: relative;
	padding: 20px 25px;
	/* background-color: var(--primary-color); */
	background-color: #687387;
	/* font-family: Sofia Pro,sans-serif; */
}

.hogans-modal-header h2 {
	font-size: 18px;
	color: #ffffff;
}

.hogans-modal-header-close {
	position: absolute;
	background: url("./assets/icons/close-icon-white.svg") no-repeat center center;
	background-size: 16px;
	width: 20px;
	height: 20px;
	right: 20px;
	top: 50%;
	margin: -10px 0 0 0;
}
.hogans-modal-header-loading {
	position: absolute;
	background-size: 16px;
	width: 20px;
	height: 20px;
	right: 20px;
	top: 50%;
	margin: -10px 0 0 0;
}

.hogans-modal-header-close:hover {
	cursor: pointer;
}

.hogans-modal-footer {
	padding: 20px;
	width: 100%;
	display: block;
}

.hogans-modal-footer.booker {
	padding: 20px 0 0 0;
}

.modal-button-next {
	display: inline-block;
	float: right;
	text-decoration: none;
	padding-right: 20px;
	height: 44px;
	line-height: 14px;
	color: #fff;
	font-weight: 600;
	border-radius: 60px;
	text-transform: inherit !important;
	background: var(--primary-color) !important;
}

.modal-button-time {
	display: inline-block;

	text-decoration: none;
	padding: 14px 55px 14px 15px;
	height: 44px;
	line-height: 14px;
	color: #fff;
	font-weight: 600;
	border-radius: 60px;
	text-transform: inherit !important;
	background: url("./assets/icons/next-button-white.svg") no-repeat center right 4px;
}

.modal-button-edit {
	display: inline-block;
	float: left;
	padding: 5px 5px 0 0;
}

.modal-button-cancel {
	display: inline-block;
	float: left;
	text-decoration: none;
	padding: 14px 15px;
	height: 44px;
	font-size: 14px;
	line-height: 12px;
	color: #8b93a6;
	font-weight: 600;
	border-radius: 5px;
	border: 2px solid #e8e9ee;
	margin: 7px 15px 0 0;
	text-transform: inherit !important;
}

/* TABS */

.special-content-box ul.tabs-component-tabs {
	display: inline-block;
	list-style: none;
	height: 34px;
}

.special-content-box ul.tabs-component-tabs:after {
	display: block;
	content: "";
	clear: both;
}

.special-content-box ul.tabs-component-tabs li {
	float: left;
	display: block;
	margin: 0 10px 0 0;
}

.special-content-box ul.tabs-component-tabs li a {
	padding: 10px 12px 6px 12px;
	background-color: var(--background-color);
	color: var(--text-color);
	font-size: 16px;
	text-decoration: none;
	display: block;
	border: 1px solid var(--v-menuborder-base);
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.special-content-box ul.tabs-component-tabs li.is-active a {
	background-color: var(--v-menu-base);
	font-weight: bold;
	border-bottom: 1px solid var(--v-menu-base);
}

.tabs-component-panels {
	padding: 0;
	background-color: var(--v-menu-base);
	border: 1px solid var(--v-menuborder-base);
}

.tabsdialog .tabs-component-panels {
	border-bottom: none !important;
	border-left: none !important;
	border-right: none !important;
	margin: -1px 0 0 0;
}

.tab-component-header {
	padding: 25px 25px 0 25px;
}

.tab-component-content {
	padding: 20px;
}

.tab-component-content.no-padding {
	padding: 0;
}

.subtreatments-wrapper {
	border: 1px solid var(--v-menuborder-base);
}

.subtreatments-wrapper .subtreatment {
	border-bottom: 1px solid var(--v-menuborder-base);
}

.tab-branches-table {
	padding: 0 0 25px 0;
}

/* .subtreatments-wrapper .handle{
	width: 40px;
	height: 40px;
	float: left;
	background: url('../img/icons/drag-icon-dark.svg') no-repeat center center;
	background-size: 20px;
	opacity: 0.5;
	
} */

.subtreatments-wrapper .color {
	width: 40px;
	height: 44px;
	float: left;
}

.subtreatments-wrapper .color .color-square {
	width: 24px;
	height: 24px;
	margin: 10px 8px;
}

.subtreatments-wrapper .description{
	width: calc(100% - 500px);
	font-size: 12px;
	line-height: 14px;
	height: 44px;
	padding: 2px 10px;
	float: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.subtreatments-wrapper .handle,
.subtreatments-wrapper .edit,
.subtreatments-wrapper .close {
	width: 40px;
	height: 44px;
	float: left;
	padding: 10px 8px;
}

.subtreatments-wrapper .handle {
	cursor: move !important;
	cursor: -webkit-grabbing !important;
}

.subtreatments-wrapper .type {
	width: 340px;
	height: 44px;
	padding: 13px 10px;
	float: left;
}

.subtreatments-wrapper .edit,
.subtreatments-wrapper .close {
	width: 40x;
	height: 44px;
	float: left;
	padding: 10px 8px;
	cursor: pointer;
}

.select-color-field {
	padding: 15px 0 0 0;
}

.select-color-field label {
	font-size: 16px;
	line-height: 1;
	min-height: 8px;
	transition: 0.3s;
	color: rgba(0, 0, 0, 0.54);
	padding: 0 0 0 5px;
}

/* WOLFLOAD */

.wolfload {
	width: 80px;
	height: 80px;
	position: fixed;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateX(-50%);
	transform: translateY(-50%);
	z-index: 90;
}

.wolfload .blackbox {
	position: relative;
	display: block !important;
	background-color: rgba(0, 0, 0, 0.45);
	padding: 16px;
	height: 80px;
	width: 80px;
	border-radius: 5px;
}

.v-dialog {
	border-radius: 20px !important;
}

.add-room-button {
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
}

.select-calendar-date-wrapper {
	padding: 5px 0 0 0;
	float: right;
}

.calendar-company-select {
	width: calc(50% - 10px);
	margin: 0 20px 0 0;
	float: left;
}

.calendar-branch-select {
	width: calc(50% - 10px);
	margin: 0;
	float: left;
}

.loading-dots {
	margin: 150px auto 0 auto;
	width: 350px;
	min-height: 80px;
}

.loading-dots-loader {
	margin: 150px auto 0 auto;
	width: 80px;
	height: 80px;
	background: url("./assets/icons/loading-dots.svg") no-repeat center center;
	background-size: 80px auto;
}

p.loading-dots-text {
	display: block;
	/* font-family: "Sofia Pro", sans-serif; */
	font-size: 14px;
	color: #251433;
	width: 100%;
	padding: 5px 0;
	text-align: center;
}

.clients-table {
	background-color: #fff;
	border: 1px solid #e8e9ee;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04);
	border-radius: 5px;
	overflow: hidden;
}

.extra-loading-dots {
	margin: 20px auto 0 auto;
	width: 60px;
	height: 60px;
	background: url("./assets/icons/loading-dots.svg") no-repeat center center;
	background-size: 60px auto;
}

.custom-checkbox-medewerker {
	margin: 0;
	padding: 0;
}

.custom-checkbox-medewerker .v-input__slot {
	margin: 0;
	padding: 0;
}

.tooltip-text-white {
	color: white;
}

.booking-treatment-wrapper {
	margin: 5px 3px;
}

.thrive-logo-toolbar {
	height: 30px;
	width: 190px;
	/* background: url("./assets/thrive-logo-white.png") no-repeat center left; */
	background-size: auto 30px;
	margin-right: 1rem;
}

/* LOADER */

.thrive-loader {
	width: 50px;
	height: 50px;
	background: url("assets/thrive-loader.svg") no-repeat center center;
	background-size: 50px auto;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
}
.longevity .thrive-loader {
	background: url("assets/thrive-loader.svg") no-repeat center center;
}

#custom-container {
	padding: 0 !important;
}

.container {
	max-width: none;
}

.no-data-message {
	width: 400px;
	margin: 0 auto;
	padding: 80px 0 100px 0;
	text-align: center;
}

.no-data-message p {
	padding: 20px 0;
}

.circle-wrapper {
	position: relative;
}
.circle {
	border: 3px solid #f94500;
	width: 12px;
	height: 12px;
	border-radius: 15px;
	margin: -5px 0 0 -5px;
	position: absolute;
	top: 50%;
	left: 50%;
}
.circle.active {
	border: 3px solid #73db02;
	width: 12px;
	height: 12px;
	border-radius: 15px;
	margin: -5px 0 0 -5px;
	position: absolute;
	top: 50%;
	left: 50%;
}

.circle.active.disabled {
	border: 3px solid #f48c00;
	width: 12px;
	height: 12px;
	border-radius: 15px;
	margin: -5px 0 0 -5px;
	position: absolute;
	top: 50%;
	left: 50%;
}
.devicesicon {
	margin-bottom: 2rem;
	margin: 2%;
}
.roomsicon {
	margin-bottom: 2rem;
	margin: 2%;
}

.v-sheet {
	border-radius: 0px;
}
.v-expansion-panel-header {
	font-weight: bold;
	font-size: 1.25rem;
}
/* .v-btn.v-size--large {
    font-size: 1rem;
    letter-spacing: 0ch;
    margin-right: 15px;
    text-transform: lowercase;
    text-align: right;
    padding: 5%;
} */

.v-card__text {
	margin-top: 15px;
}
.v-expansion-panel-content__wrap {
	padding: 0 !important;
}
.date-picker-custom {
	margin: 0;
	padding: 0;
}
.booking-datepicker table,
.custom-date-picker table {
	min-width: 0 !important;
}
/* .v-data-table-wrapper table{
  min-width: 0 !important;
} */
.custom-list-item-content {
	cursor: pointer;
	padding-left: 10px;
}
.v-icon {
	font-size: 30px;
}
.select-language-flag {
	float: left;
	margin: 20px 15px 0 0;
}
.language-flag {
	width: 30px;
	height: 20px;
	background-color: #f1f1f1;
}

.fc .fc-timegrid-body.auto-z-index,
.fc .fc-timegrid-col-events.auto-z-index,
.auto-z-index .fc .fc-timegrid-col-bg{
	z-index: auto!important
}


/* .fc .fc-timegrid-body{
	z-index: auto!important
} */
/* .fc .fc-timegrid-col-events{
	z-index: auto!important
} */

.fc-timegrid-event-harness {
	z-index: 89!important
}

.highlight-event{
	z-index:100;
	box-shadow:none!important;
	-webkit-box-shadow:none!important;
}

.no-hover-effect .v-list-item:hover {
	background-color: none !important;
}

.language-flag.nl {
	background: url("./assets/flags/dutch-flag.png") no-repeat center center;
	background-size: 30px 20px;
}
.language-flag.uk {
	background: url("./assets/flags/uk-flag.png") no-repeat center center;
	background-size: 30px 20px;
}

.dense .language-flag,
.dense .language-flag.nl,
.dense .language-flag.en {
	width: 20px;
	height: 13px;
	background-size: 20px 13px;
}

/* .v-image__image{
  background-size: contain;
  background-color: darkgray;
} */
.historyTabel {
	margin: 0 auto;
}
.bookingsDataContainer {
	display: flex;
	width: 100%;
	font-size: 14px;
}
.bookingData {
	width: 50%;
}
.oldBooking {
	color: red !important;
}
.newBooking {
	color: lightseagreen;
}
.bookingsheader {
	width: 50%;
	padding: 2%;
	color: white;
}
.bookingTreatmentTitle {
	margin-left: 20px;
}
.gradient {
	background-image: linear-gradient(to right, rgba(255, 0, 10, 0.3), rgb(114, 217, 196)); /* Standard syntax (must be last) */
}
.bookingTreatmentTitle:hover {
	cursor: pointer;
}
.subInfo {
	text-align: left;
	width: 100%;
}
a,
.v-application a {
	color: var(--primary-color);
}
</style>
