<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2 v-if="importImages.length == 1"> {{importImages.length}} foto importeren</h2>
      <h2 v-if="importImages.length > 1">{{importImages.length}} foto's importeren</h2>
      <h2 v-if="importImages.length == 0">Foto's importeren</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeImportPicturesDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-alert
      class="ma-0 pa-2"
      style="border-radius: 0"
      v-model="successfulUpload"
      type="success"
      dark
    >
      Alle afbeeldingen zijn succesvol geupload.
    </v-alert>

    <v-divider></v-divider>
    
    <v-card-text style="background-color: #f5f5f5;" class="pa-0 ma-0">
      <div v-if="uploadLoading == false" class="image-upload-wrapper">
        <div v-for="(selectedImage, index) in importImages" :key="index" class="image-upload-item clear" :class="{ invalid: selectedImage.validFile  == false }">
          <div v-if="selectedImage.uploading == true" class="image-uploading-overlay"></div>
          <div v-if="selectedImage.validFile == true">
            <div class="image-upload-item-name">{{selectedImage.name}}</div>
            <v-alert v-if="!selectedImage.cropped" border="left" outlined dense class="ma-0 pa-1 pl-3 my-2" :value="true" type="warning" icon="mdi-crop">Crop afbeelding naar het juist formaat om te importeren.</v-alert>
            <div class="image-upload-item-bodypart-icon" :class="selectedImage.selectedBodypart"></div>
            <div class="image-upload-item-inputs">
              <div class="image-upload-item-input-bodypart">
                <v-select
                  v-model="selectedImage.selectedBodypart"
                  :items="bodyPartItems"
                  class="mt-3 mb-1"
                  outlined
                  dense
                  hide-details
                  background-color="background"
                  item-text="name"
                  item-value="id"
                  label="Lichaamsdeel"
                  @change="bodypartSelected(index)">
                </v-select>
              </div>
              <div class="image-upload-item-input-date">
                <v-menu
                  ref="dateDialog"
                  :close-on-content-click="false"
                  v-model="selectedImage.dateDialog"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  >
                  <template v-slot:activator="{on}">
                    <v-text-field
                      v-on="on"
                      class="mt-4"
                      v-model="selectedImage.dateFormatted"
                      label="Datum"
                      readonly
                      outlined
                      dense
                      hide-details
                      background-color="background"
                      append-icon="mdi-calendar"
                  ></v-text-field>
                  </template>
                  <v-date-picker :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :max="max" v-model="selectedImage.date" no-title @input="selectedImage.dateDialog = false" v-on:input="formatDate(index)" ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <div class="image-upload-item-preview" @click="openImportPictureDetails(index)">
              <div v-if="selectedImage.cropped">
                <v-btn
                  class="image-cropped-button"
                  fab
                  dark
                  x-small
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                >
                  <v-icon dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <!-- <div class="image-cropped yes"></div> -->
                  <v-img
                  :src="selectedImage.image"
                  aspect-ratio="1"
                  height="110px"
                  width="110px"
                  class="grey lighten-2"
                >
                </v-img>
              </div>
              <div v-else>
                <v-btn
                  class="image-cropped-button"
                  fab
                  dark
                  x-small
                  color="secondary"
                >
                  <v-icon dark>
                    mdi-crop
                  </v-icon>
                </v-btn>
                <!-- <div class="image-cropped no"></div> -->
                  <v-img
                    :src="selectedImage.image"
                    aspect-ratio="1"
                    height="110px"
                    width="110px"
                    class="grey lighten-2"
                  >
                  </v-img>
              </div>
            </div>
            <div class="image-upload-item-remove" @click="removeImportImage(index)">
              <v-container fill-height fluid class="ma-0 pa-0">
                <v-row align="center" justify="center" class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0 text-center"><v-icon class="remove-image-icon ma-0 pa-0" color="#333">mdi-delete</v-icon></v-col>
                </v-row>
              </v-container>
            </div>

            <div v-if="selectedImage.uploading == true" class="image-import-progress-wrapper">
              <div class="image-import-progress" :style="{width: selectedImage.uploadProgress + '%'}"></div>
            </div>
          </div>

          <div v-else>
            <v-toolbar dark dense color="error">
              <v-toolbar-side-icon>
                <v-icon dark>mdi-alert</v-icon>
              </v-toolbar-side-icon>

              <v-toolbar-title class="toolbar-alert-text">Afbeelding is niet goed geupload. Probeer opnieuw.</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn v-if="!uploading" icon @click="removeImportImage(index)">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
        </div>
      </div>
      
      <div v-if="!uploading" class="select-images-button-wrapper text-center">
        <v-btn raised class="primary my-4" :disabled="uploading" :depresssed="uploading"  @click="selectImagesForUpload()">Selecteer afbeeldingen</v-btn>
        <input
          class="upload-input"
          type="file"
          style="display: none;"
          ref="fileInput"
          multiple
          accept="image/*"
          @change="onFilePicked">
      </div>

      <div v-if="superAdminSupport">
        <v-alert type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-2 custom-alert">
          <h3><strong>Selecteer gebruiker voor upload</strong></h3>
          <span>Je upload nu voor een ander bedrijf. Ga vertrouwelijk met deze gegevens om. Voorkom verwarring bij cliënten en selecteer je juist persoon van dit bedrijf voor de upload. </span>
        </v-alert>

        <v-select
          class="ma-2 my-4"
          :items="filteredUsers"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          background-color="menu"
          item-text="nameBrand"
          item-value="name"
          v-model="activeUser"
          no-data-text="Geen gebruikers gevonden."
          label="Select de juiste gebruiker voor upload"
          outlined
          hide-details
          prepend-inner-icon="mdi-check"
          :rules="requiredRules"
          >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content  class="pt-0" >
                <v-text-field
                  outlined
                  background-color="background"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  placeholder="Zoeken"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  v-model="searchUsers"
                  hide-details
                  dense
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div v-if="searchUsers && searchUsers.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); text-align: center; font-size: 12px">{{ filteredUsers.length }} van {{ users.length }} gebruikers</div>
            <v-divider v-if="searchUsers && searchUsers.length > 0"></v-divider>
          </template>
        </v-select>
      </div>

    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
              @click="closeImportPicturesDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              :disabled="!valid" 
              :depressed="!valid"  
              :dark="valid"  
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              @click="uploadImages()"
              >
              Afbeeldingen importeren
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

    <!-- / PICTURE DETAILS DIALOG \ -->
    <v-dialog v-model="importPictureDetails" scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="hogans-modal-header">
          <h2>Foto details {{ clientInfo.name }} {{ clientInfo.surname }}</h2>
          <div class="hogans-modal-header-close" @click="closeImportPictureDetails()"></div>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0" style="margin: -1px 0 0 0">
          <div id="import-details-wrapper" class="content bg-grey" v-show="importImages.length > 0 && importPictureDetailsInfo" >
            <div class="image-details-wrapper" >
              
              <div class="image-preview-wrapper" v-if="importPictureDetailsInfo && importImages[selectedImage].croppedImage">
                <v-container grid-list-md fluid class="ma-0 pa-0">
                  <v-layout row wrap class="ma-0 pa-0">
                    <v-flex xs12>
                      <v-btn @click="resetCrop()" outlined block depressed class="ma-1" color="secondary">
                      <v-icon
                        left
                        dark
                        >
                        mdi-crop
                      </v-icon>
                      <strong>Opnieuw instellen</strong>
                    </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="image-detail-picture">
                  <!-- <img :src="importImages[selectedImage].croppedImage" class="grey lighten-2" /> -->
                  <v-img :src="importImages[selectedImage].croppedImage" class="grey lighten-2" max-height="400" contain>
                    <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-layout>
                  </v-img>

                  <div class="main-picture-date"> {{ importImages[selectedImage].date | moment("D MMM YYYY")}}</div>
                </div>
              </div>

              <div class="image-preview-wrapper" v-if="importPictureDetailsInfo && !importImages[selectedImage].croppedImage">
                <v-container grid-list-md fluid class="ma-0 pa-0">
                  <v-layout row wrap class="ma-0 pa-0">
                    <v-flex xs4>
                      <div class="text-left pt-1">
                        <v-btn icon dark @click="rotate(-90)">
                          <v-icon dark color="text">mdi-rotate-left</v-icon>
                        </v-btn>    
                        <v-btn icon dark @click="rotate(90)">
                          <v-icon dark color="text">mdi-rotate-right</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs4>
                      <div class="text-center pt-1">
                        <v-btn icon dark @click="zoom(-0.5)">
                          <v-icon dark color="text">mdi-minus-circle-outline</v-icon>
                        </v-btn>
                        <v-btn icon dark @click="zoom(0.5)">
                          <v-icon dark color="text">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs4>
                      <v-btn @click="crop()" class="ma-1 " depressed block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                        <v-icon
                          left
                          dark
                          >
                          mdi-crop
                        </v-icon><strong>Crop</strong></v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>

      
       
                <div class="image-detail-picture">
                  <vue-cropper
                    ref="cropper"
                    :src="importImages[selectedImage].image"
                    :viewMode="1"
                    :responsive="true"
                    :dragMode="'move'"
                    :center="false"
                    :guides="false"
                    :aspectRatio="0.7515"
                    :autoCropArea="1"
                    :movable="true"
                    :highlight="false"
                    :cropBoxResizable="false"
                    :zoomOnWheel="false"
                    :options="cropOptions"
                  >
                  </vue-cropper>
                  <div class="main-picture-date"> {{ importImages[selectedImage].date | moment("D MMM YYYY")}}</div>
                </div>
          
              
                
              </div>
    
              <div class="image-detail-notes" v-if="importPictureDetailsInfo">
                <h2>Notitie</h2>
                <p class="ma-0">Schrijf een korte notitie</p>
                <v-textarea outlined class="notes-textarea ma-0 pa-0" background-color="background" no-resize counter="180" rows="3" maxlength="180" v-model="importImages[selectedImage].notes"></v-textarea>   
            
              </div>
              <div class="image-detail-specs" v-if="importPictureDetailsInfo">
                <h2>Foto specificaties</h2>
                <p class="subtitle ma-0">Gedetaileerde specificaties per foto</p>
                <div class="image-spec-item-wrapper clear">
                  <div class="image-spec-item-icon client"></div>
                  <div class="image-spec-item-text">
                    <p class="image-spec-item-text-title ma-0">Clientnaam</p>
                    <p class="image-spec-item-text-data ma-0">{{ clientInfo.name }} {{ clientInfo.surname }}</p>
                  </div>
                </div>

                <div class="image-spec-item-wrapper clear">
                  <div class="image-spec-item-icon email"></div>
                  <div class="image-spec-item-text">
                    <p class="image-spec-item-text-title ma-0" >Client e-mail</p>
                    <p class="image-spec-item-text-data ma-0">{{ clientInfo.email}} </p>
                  </div>
                </div>

                <div class="image-spec-item-wrapper clear">
                  <div class="image-spec-item-icon prof-id"></div>
                  <div class="image-spec-item-text">
                    <p class="image-spec-item-text-title ma-0">Professional</p>
                    <p class="image-spec-item-text-data ma-0"><span v-if="activeUser">{{activeUser}}</span><span v-else>{{ userFirstName }} {{ userLastName }}</span> - {{ companyName }}</p>
                  </div>
                </div>

                <div class="image-spec-item-wrapper clear">
                  <div class="image-spec-item-icon bodypart"></div>
                  <div class="image-spec-item-text">
                    <p class="image-spec-item-text-title ma-0">Lichaamsdeel</p>
                    <p class="image-spec-item-text-data ma-0">
                      <span v-for="bodypart in bodyparts" :key="bodypart.name">
                        <span v-if="bodypart.id == importImages[selectedImage].bodypart "> {{ bodypart.name }}</span>
                      </span>
                      -
                      <span v-for="position in positions" :key="position.name">
                        <span v-if="position.id == importImages[selectedImage].position "> {{ position.name }}</span>
                      </span>
                    </p>
                  </div>
                </div>

                <div class="image-spec-item-wrapper clear">
                  <div class="image-spec-item-icon image-size"></div>
                  <div class="image-spec-item-text">
                    <p class="image-spec-item-text-title ma-0">Afmetingen foto</p>
                    <p class="image-spec-item-text-data ma-0">{{importImages[selectedImage].width}} x {{importImages[selectedImage].height}}</p>
                  </div>
                </div>

                <div class="image-spec-item-wrapper clear">
                  <div class="image-spec-item-icon device"></div>
                  <div class="image-spec-item-text">
                    <p class="image-spec-item-text-title ma-0">Device</p>
                    <p class="image-spec-item-text-data ma-0">{{importImages[selectedImage].device}}</p>
                  </div>
                </div>

                <div class="image-spec-item-wrapper clear">
                  <div class="image-spec-item-icon software"></div>
                  <div class="image-spec-item-text">
                    <p class="image-spec-item-text-title ma-0">Software versie</p>
                    <p class="image-spec-item-text-data ma-0">{{importImages[selectedImage].software}}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  color="tertiary"
                  @click="closeImportPictureDetails()"
                  >
                  Annuleren
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  :disabled="!cropValid" 
                  :depressed="!cropValid"  
                  :dark="cropValid"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  rounded 
                  color="secondary" 
                  @click="saveImportPictureDetails()"
                  >
                  Opslaan
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- \ PICTURE DETAILS DIALOG / -->
  </v-card>

</template>


<script>
import db from "../../firebase/init";
import firebase from "firebase/app";
import "firebase/storage";
import moment from "moment";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import resizebase64 from 'resize-base64';
import imageFileToBase64 from "image-file-to-base64-exif"; 
import platform from 'platform'; 

export default {
  name: "import-pictures-dialog",
  props: {
    clientInfo: Object,
    activeCompData: Object
  },
  components: { 
    'vue-cropper': VueCropper
  },
  data() {
    return {
      searchUsers: "",
      bodyPartItems: [
        {
          name: 'Gelaat - Voor',
          id: 'headfront'
        },
        {
          name: 'Gelaat - Links',
          id: 'headleft'
        },
        {
          name: 'Gelaat - Rechts',
          id: 'headright'
        },
        {
          name: 'Bovenlichaam - Voor',
          id: 'upperbodyfront'
        },
        {
          name: 'Bovenlichaam - Achter',
          id: 'upperbodyback'
        },
        {
          name: 'Bovenlichaam - Links',
          id: 'upperbodyleft'
        },
        {
          name: 'Bovenlichaam - Rechts',
          id: 'upperbodyright'
        },
        {
          name: 'Onderlichaam - Voor',
          id: 'lowerbodyfront'
        },
        {
          name: 'Onderlichaam - Achter',
          id: 'lowerbodyback'
        },
        {
          name: 'Onderlichaam - Links',
          id: 'lowerbodyleft'
        },
        {
          name: 'Onderlichaam - Rechts',
          id: 'lowerbodyright'
        }
      ],
      selectedImageOriginal: null,
      successfulUpload: false,
      uploading: false,
      uploadLoading: false,
      selectedImage: null,
      importPictureDetails: false,
      importPictureDetailsInfo: false,
      importImages: [],
      users: [],
      activeUser: null,
      feedback: null,
      cropOptions: {
        maxWidth: 786,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high'
      },
      requiredRules: [v => !!v || "Dit veld is verplicht"],
      bodyparts: [
        {name: "Gezicht", id: "head"},
        {name: "Bovenlichaam", id: "upperbody"},
        {name: "Onderlichaam", id: "lowerbody"}
      ],
      positions: [
        {id: "front", name: "Voorzijde"},
        {id: "back", name: "Achterzijde"},
        {id: "left", name: "Linkerzijde"},
        {id: "right", name: "Rechterzijde"}
      ],
    };
  },

  created() {
    // Maximale datum instellen. Je kan geen datum in de toekomst kiezen ;)
    this.max = moment().format("YYYY-MM-DD");
    if(this.$store.state.activeUserRoleNumber < 2){
      this.loadAllUsers()
    }
  },

  methods: {
    loadAllUsers() {
      db.collection("users")
        .get()
        .then(snap => {
          this.users = [];
          snap.forEach(doc => {
            let userCollection = doc.data();
            userCollection.id = doc.id;
            userCollection.nameBrand = doc.data().firstName + ' ' + doc.data().lastName + ' - ' + doc.data().company;
            userCollection.name = doc.data().firstName + ' ' + doc.data().lastName;
            this.users.push(userCollection);
          });
        })
        .then(()=>{
          this.activeUser = this.userFirstName + " " + this.userLastName;
        })
        .catch(error=>{
          console.error("Fout bij binnenladen van users: ", error)
        })
    },
  
    // Foto import Dialog sluiten en data resetten
    closeImportPicturesDialog() {
      this.$emit("closeImportPicturesDialogChange", false);
      this.importImages = []
      this.successfulUpload = false
      this.selectedImage = null
      this.feedback = null
    },
    
    // Dialog met Detail en Cropinformatie van te uploaden foto openen
    openImportPictureDetails(index){
      this.selectedImage = index
      if(this.importImages[index].cropped){
         this.selectedImageOriginal = _.cloneDeep(this.importImages[index]);
         this.selectedImageOriginal.index = index;
      }
      else{
        this.selectedImageOriginal = null;
      }
      this.importPictureDetails = true;
      this.importPictureDetailsInfo = true;
    },  

    // Dialog met Detail en Cropinformatie van te uploaden foto sluiten
    closeImportPictureDetails(){
      if(this.selectedImageOriginal){
        this.importImages[this.selectedImageOriginal.index].croppedImage = this.selectedImageOriginal.croppedImage;
        this.importImages[this.selectedImageOriginal.index].croppedImageBlob = this.selectedImageOriginal.croppedImageBlob;
      }
      else{
        this.resetCrop()
        this.selectedImageOriginal = null;
      }
      this.importPictureDetails = false;
      this.importPictureDetailsInfo = false;
      this.selectedImage = null;
      this.selectedImageOriginal = null;
    },

    saveImportPictureDetails(){
      this.importPictureDetails = false
      this.importPictureDetailsInfo = false
      this.selectedImage = null
    },

    selectImagesForUpload(){
      this.$refs.fileInput.click();
      this.successfulUpload = false;
    },


    removeImportImage(index){
      this.importImages.splice(index, 1);
    },

    // Vul de juiste position en bodypart in bij selectie van lichaamsdeel en positie 
    // De selectie input bevat beide keuze in 1x hieronder wordt deze gesplit.
    bodypartSelected(index){
      if(this.importImages[index].selectedBodypart == 'headfront'){
        this.importImages[index].bodypart = 'head'
        this.importImages[index].position = 'front'
      }
      else if(this.importImages[index].selectedBodypart == 'headleft'){
        this.importImages[index].bodypart = 'head'
        this.importImages[index].position = 'left'
      }
      else if(this.importImages[index].selectedBodypart == 'headright'){
        this.importImages[index].bodypart = 'head'
        this.importImages[index].position = 'right'
      }
      else if(this.importImages[index].selectedBodypart == 'upperbodyfront'){
        this.importImages[index].bodypart = 'upperbody'
        this.importImages[index].position = 'front'
      }
      else if(this.importImages[index].selectedBodypart == 'upperbodyback'){
        this.importImages[index].bodypart = 'upperbody'
        this.importImages[index].position = 'back'
      }
      else if(this.importImages[index].selectedBodypart == 'upperbodyleft'){
        this.importImages[index].bodypart = 'upperbody'
        this.importImages[index].position = 'left'
      }
      else if(this.importImages[index].selectedBodypart == 'upperbodyright'){
        this.importImages[index].bodypart = 'upperbody'
        this.importImages[index].position = 'right'
      }
      else if(this.importImages[index].selectedBodypart == 'lowerbodyfront'){
        this.importImages[index].bodypart = 'lowerbody'
        this.importImages[index].position = 'front'
      }
      else if(this.importImages[index].selectedBodypart == 'lowerbodyback'){
        this.importImages[index].bodypart = 'lowerbody'
        this.importImages[index].position = 'back'
      }
      else if(this.importImages[index].selectedBodypart =='lowerbodyleft'){
        this.importImages[index].bodypart = 'lowerbody'
        this.importImages[index].position = 'left'
      }
      else if(this.importImages[index].selectedBodypart == 'lowerbodyright'){
        this.importImages[index].bodypart = 'lowerbody'
        this.importImages[index].position = 'right'
      }
    },

    // Gecropte foto herstellen naar originele status
    resetCrop(){
      if(this.importImages[this.selectedImage].croppedImage){
        this.importImages[this.selectedImage].croppedImage = null
        this.importImages[this.selectedImage].cropped = false
      } else {
        this.$refs.cropper.reset();
      }
    },

    // Gecropte foto opslaan en preview weergeven
    crop() {
      this.$refs.cropper.getCroppedCanvas(this.cropOptions).toBlob((blob) => {
        let vm = this
        vm.importImages[vm.selectedImage].croppedImageBlob = blob
        var reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
          let base64data = reader.result;                   
          vm.importImages[vm.selectedImage].croppedImage = base64data
          vm.importImages[vm.selectedImage].cropped = true
          var image = new Image(base64data)

          var img = new Image();
          img.src = base64data;

          img.onload = function(){
            vm.importImages[vm.selectedImage].width = img.width
            vm.importImages[vm.selectedImage].height = img.height
          };
        }
      })
    },

    // Foto roteren
    rotate(rotationAngle) {
      this.$refs.cropper.rotate(rotationAngle);
    },

    zoom(distance) {
      this.$refs.cropper.relativeZoom(distance);
    },

    // Gekozen datum omzetten naar europees formaat
    formatDate(index) {
      let date = this.importImages[index].date
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.importImages[index].dateFormatted =`${day}-${month}-${year}`;
    },

    // Gekozen afbeeldingen selecteren en juiste velden instellen
    onFilePicked(event) {
      this.successfulUpload = false
      this.uploadLoading = true
      let files = null;
      files = event.target.files;
      // console.log("files: ", files)
      let filesLength = Object.keys(files).length
      let counter = 0
      new Promise((resolve, reject)=>{
        Object.keys(files).map(key => {
          let importItem = {};
          const fileReader = new FileReader();
          const PromiseFileReader = require('promise-file-reader');

          PromiseFileReader.readAsDataURL(files[key])
          .then(newImage=>{
            importItem.name = files[key].name
            importItem.image = newImage
            // importItem.bodyImage = files[key];
            importItem.bodypart = null;
            importItem.position = null;
            importItem.selectedBodypart = null;
            importItem.croppedImage = null;
            importItem.croppedImageBlob = null;
            importItem.width = null;
            importItem.height = null;
            importItem.notes = "";
            importItem.uploaded = false;
            importItem.uploading = false;
            importItem.uploadProgress = 0
            importItem.cropped = false;
            importItem.date = moment(files[key].lastModifiedDate).format("YYYY-MM-DD H:mm");
            importItem.dateFormatted = moment(files[key].lastModifiedDate).format("DD-MM-YYYY");
            importItem.dateDialog  = false;
            importItem.validFile = true;
            importItem.device = "Desktop - " + platform.os,
            importItem.software = platform.name + ' ' + platform.version; 

            this.importImages.push(importItem)
            counter++
            if(counter == filesLength){
              resolve()
            }
          })
          .catch(err => {
            console.error(err)
            importItem.validFile = false;
            this.importImages.push(importItem)
            counter++
            if(counter == filesLength){
              resolve()
            }
          });
        });
      })
      .then(()=>{
        this.uploadLoading = false;
        document.querySelector(".upload-input").value = "";
      })
    },

    // Geselecteerde foto uploaden
    uploadImages() {
      // console.log("uploadImages Started");
      this.successfulUpload = false
      this.feedback = null
      this.uploading = true
      let selectedBodyparts = 0
      let croppedImages = 0
      let bodypartCheckCounter = 0
      new Promise((resolve, reject) =>{
        this.importImages.forEach((image)=>{
          if(image.validFile == true && image.bodypart == null){
            selectedBodyparts++
          }
          if(image.validFile == true && image.cropped == false){
            croppedImages++
          }
          bodypartCheckCounter++
          if(bodypartCheckCounter == this.importImages.length){
            resolve()
          }
        })
      })
      .then(()=>{        
        if(selectedBodyparts == 0 && croppedImages == 0){
          console.log("Benodigde items zijn geselecteerd voor de valide bestanden")
          let importCounter = 0

          let professional
          if(this.activeUser){
            professional = this.activeUser
          } else {
            professional = this.userFirstName + " " + this.userLastName
          }

          new Promise((resolve, reject) => {

            this.importImages.forEach((image, index)=> {

              if(image.uploaded == false){
                console.log("Bestand is nog niet geupload")
                this.importImages[index].uploading = true;
                let documentProgress = 0
                let uploadImageProgress = 0 // Word berekend tijdens het uploaden
                let uploadThumbProgress = 0 // Word berekend tijdens het uploaden
                let updateProgress = 0
                // let photoThumb = resizebase64(this.importImages[index].croppedImage, 350, 350); 
                imageFileToBase64(this.importImages[index].croppedImageBlob, 350, 350)
                
                .then(photoThumb=>{
                  updateProgress = 5;
                  documentProgress = 5;

                  db.collection("bodypictures")
                    .add({
                      companyId: this.userCompany,
                      companyName: this.companyName,
                      clientName: this.clientInfo.name + " " + this.clientInfo.surname,
                      clientEmail: this.clientInfo.email,
                      clientId: this.clientInfo.id,
                      professional: professional,
                      notes: image.notes,
                      bodypart: image.bodypart,
                      position: image.position,
                      dateAdded: new Date(image.date),
                      photoType: "imported",
                      device: image.device,
                      software: image.software,
                      deleted: {
                        deleted: false
                      }
                    }) 
                    .then(doc =>{
                      
                      console.log("Image posten");

                      this.importImages[index].uploadProgress = documentProgress

                      let ref = firebase.storage().ref("bodypictures/" + this.clientInfo.id + "/" + doc.id + ".jpeg");
                      let uploadTask = ref.putString(this.importImages[index].croppedImage.split(",")[1], "base64");
                      // let uploadTask = ref.putString(this.importImages[index].image, "base64");

                      uploadTask.on(
                        "state_changed",
                        taskSnapshot => {
                          uploadImageProgress = Math.ceil((taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 70);
                          this.importImages[index].uploadProgress = documentProgress + uploadImageProgress
                        },
                        error => {
                          console.error("Error uploading image", error);
                        },() => {

                          console.log("The image upload is compleet");
                          uploadTask.snapshot.ref.getDownloadURL().then(url => {
                            let refThumb = firebase.storage().ref("bodypictures/" + this.clientInfo.id + "/" + doc.id + "_thumb.jpeg");
                            let uploadTaskTumb = refThumb.putString(photoThumb.split(",")[1], "base64");

                            uploadTaskTumb.on(
                              "state_changed",
                              taskSnapshot => {
                                uploadThumbProgress = Math.ceil((taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 20);
                                this.importImages[index].uploadProgress = documentProgress + uploadImageProgress + uploadThumbProgress
                              },
                              error => {
                                this.feedback = "Het opslaan van de foto is niet gelukt. Probeer het opnieuw";
                                console.error("Error uploading thumb", error);
                              },
                              () => {
                                console.log("The thumb upload is compleet");
                                uploadTaskTumb.snapshot.ref.getDownloadURL().then(thumbUrl => { 
                                  db.collection("bodypictures")
                                    .doc(doc.id)
                                    .update({
                                      imageUrl: url,
                                      imageThumbUrl: thumbUrl,
                                      imageSize: image.width + " x " + image.height,
                                    }).then(()=>{
                                      this.importImages[index].uploadProgress = documentProgress  + uploadImageProgress + updateProgress  + uploadThumbProgress
                                      this.importImages[index].uploaded = true
                                    })
                                    .then(() => {
                                      console.log("Posten gelukt");
                                      importCounter++
                                      if(importCounter == this.importImages.length){
                                        resolve()
                                      }
                                    })
                                })
                            })

                          });

                      });
                    })
                    .catch(error => {
                      console.error(error);
                      importCounter++
                      if(importCounter == this.importImages.length){
                        resolve()
                      }
                    });


                })
              
              } 
              
            });
          })
          .then(()=>{
            console.log("Promise klaar");
            console.log("this.importImages.length voor removen: ", this.importImages);
            let counter = 0
            let images = _.cloneDeep(this.importImages)
            let importImagesLength = images.length
            
            new Promise((resolve, reject) => {
              // Backwards loopen omdat de items a.d.h.v. indexen worden verwijderd.
              for (var i = images.length - 1; i >= 0; --i) {
                if (images[i].uploaded == true || images[i].validFile == false) { 
                  this.importImages.splice(i, 1);
                  console.log("Is geupload of niet geldig dus removen");
                }
                counter++
                console.log("Counter: ", counter);
                console.log("this.importImages.length: ", this.importImages.length);
                if(counter == importImagesLength ){
                  resolve()
                }
              }
            })

            .then(()=>{
              console.log("this.importImages.length na removen: ", this.importImages);
              console.log("this.importImages.length in THEN: ", this.importImages.length);
              this.uploading = false

              if(this.importImages.length > 0){
                console.log("Promise klaar, Niet alle foto's zijn geimporteerd :(");
                this.feedback = "Niet alle foto's zijn geimporteerd. Probeer het opnieuw";
              } else {
                this.successfulUpload = true
                this.$emit("updateData");
              }

              // else {
              //   console.log("Promise klaar. Alle foto's zijn geimporteerd :)");
              //   this.$router.push("/bodypictures-overview");
              //   this.$toasted.global.success({
              //     message : "Foto's zijn succesvol geīmporteerd"
              //   }).goAway(3000)
              // }
              
            })
          })
        } 
        else{
          this.uploading = false
          this.feedback = "<strong>Selecteer lichaamsdeel</strong> en <strong>crop elke foto</strong> bij importeren.";
        }
      })
    },
  },
  computed: {

    superAdminSupport(){
      if(this.$store.state.activeUserRoleNumber < 2 && this.userCompany != this.activeCompData.id){
        return true;
      }
      else{
        return false;
      }
    },

    
    filteredUsers(){
      if (this.searchUsers && this.searchUsers.length > 0){
        return this.users.filter(user => {
          return user.name.toLowerCase().includes(this.searchUsers.toLowerCase());
        });
      } else {
        return this.users;
      }
    },
    valid(){
      if(this.importImages.length > 0){
        return true;
      }
      else {
        return false;
      }
    },
    cropValid(){
      if(this.selectedImage != null && this.importImages[this.selectedImage].croppedImage){
        return true;
      }
      else {
        return false;
      }
    },
    companyName(){
      return this.$store.state.companyName;
    },
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
  }
};
</script>
<style>
.image-upload-item{
  border-bottom: 1px solid #DBE5FF;
  background-color: #ffffff;
  /* height: 145px; */
  overflow: hidden;
  padding: 10px;
  position: relative;
}

.image-upload-item-name{
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.image-upload-item.invalid{
  border-bottom: 1px solid #ffffff;
  background-color: #ffffff;
  height: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.toolbar-alert-text{
  font-size: 14px;
  color: #fff;
}

.image-upload-item-bodypart-icon{
  width: 45px;
  float: left;
  height: 110px;
  background: url("../../assets/bodyparts-small/front-unselected.png") no-repeat center center;
  background-size: auto 90px;
}
.image-upload-item-inputs{
  width: calc(100% - 190px);
  padding: 0 10px;
  float: left;
  height: 110px;
}
.image-upload-item-preview{
  position: relative;
  width: 110px;
  float: left;
  height: 110px;
}

.image-upload-item-remove{
  width: 35px;
  float: left;
  height: 110px;
}

.image-upload-item-remove:hover{
  cursor: pointer;
}

.image-upload-item-remove:hover .remove-image-icon{
  color: #FF5248!important;

}


.select-images-button-wrapper{
  padding: 20px;
}

.image-cropped{
  position: absolute;
  top: 6px;
  right: 6px;
  width: 28px;
  height: 28px;
  border-radius: 20px;
  z-index: 99;
}

.image-cropped-button{
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 99;
}

.image-remove{
  display: none;
}

.image-upload-item-preview:hover,
.image-remove:hover{
  cursor: pointer;
}

.image-upload-item-preview:hover .image-remove{
  display: block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 28px;
  height: 28px;
  border-radius: 20px;
  z-index: 99;
  background: #FF5248 url('../../assets/icons/delete-icon-white.svg') no-repeat center center;
  background-size: 15px auto; 
}

.image-cropped.no{
  background: #F48C00 url('../../assets/icons/edit-icon-white.svg') no-repeat center center;
  background-size: 15px auto; 
}

.image-cropped.yes{
  background:  url('../../assets/icons/check-icon-blue.svg') no-repeat center center;
  background-size: 28px auto; 
}

.image-upload-item-bodypart-icon.headfront {
  background: url("../../assets/bodyparts-small/head-front-selected.png") no-repeat center center;
  background-size: auto 90px;
}
.image-upload-item-bodypart-icon.headleft {
  background: url("../../assets/bodyparts-small/head-left-selected.png") no-repeat center center;
  background-size: auto 90px;
}
.image-upload-item-bodypart-icon.headright {
  background: url("../../assets/bodyparts-small/head-right-selected.png") no-repeat center center;
  background-size: auto 90px;
}

.image-upload-item-bodypart-icon.upperbodyfront {
  background: url("../../assets/bodyparts-small/upperbody-front-selected.png") no-repeat center center;
  background-size: auto 90px;
}

.image-upload-item-bodypart-icon.upperbodyback {
  background: url("../../assets/bodyparts-small/upperbody-back-selected.png") no-repeat center center;
  background-size: auto 90px;
}

.image-upload-item-bodypart-icon.upperbodyleft {
  background: url("../../assets/bodyparts-small/upperbody-left-selected.png") no-repeat center center;
  background-size: auto 90px;
}

.image-upload-item-bodypart-icon.upperbodyright {
  background: url("../../assets/bodyparts-small/upperbody-right-selected.png") no-repeat center center;
  background-size: auto 90px;
}

.image-upload-item-bodypart-icon.lowerbodyfront {
  background: url("../../assets/bodyparts-small/lowerbody-front-selected.png") no-repeat center center;
  background-size: auto 90px;
}

.image-upload-item-bodypart-icon.lowerbodyback {
  background: url("../../assets/bodyparts-small/lowerbody-back-selected.png") no-repeat center center;
  background-size: auto 90px;
}

.image-upload-item-bodypart-icon.lowerbodyleft {
  background: url("../../assets/bodyparts-small/lowerbody-left-selected.png") no-repeat center center;
  background-size: auto 90px;
}

.image-upload-item-bodypart-icon.lowerbodyright {
  background: url("../../assets/bodyparts-small/lowerbody-right-selected.png") no-repeat center center;
  background-size: auto 100px;
}


.image-crop-settings-wrapper{
  height: 45px;
  background-color: #fff;
  margin-top: -5px;
  position: relative;
}

.image-crop-button{
  cursor: pointer;
}

.image-crop-button.reset{
  position: absolute;
  top: 0;
  left: 0;
}

.image-crop-button.reset-crop{
  position: absolute;
  top: 0;
  left: 0px;
}

.image-crop-button.crop{
  position: absolute;
  top: 0;
  right: 0;
}

.image-crop-settings-rotate-buttons{
  width: 102px;
  height: 36px;
  position: absolute;
  left: 50%;
  padding: 5px 0;
  margin: 0 0 0 -46px;
}

.image-crop-button.rotate-left{
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background:  url('../../assets/icons/rotate-left-icon-black.svg') no-repeat center center;
  background-size: 18px auto; 
  float: left;
  margin: 0 5px;
}

.image-crop-button.rotate-right{
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background:  url('../../assets/icons/rotate-right-icon-black.svg') no-repeat center center;
  background-size: 18px auto;
  float: left;
  margin: 0 5px; 
}

.image-detail-picture{
  overflow: hidden;
  position: relative;
}

.image-detail-picture .cropper-view-box {
  display: block;
  height: 100%;
  outline-color: #fff;
  outline: none;
  overflow: hidden;
  width: 100%;
}

.image-preview-wrapper{
  background-color: #fff;
  margin: 0;
  border-bottom: 1px solid #dbe5ff;
}

.image-detail-notes-button {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  margin: 0 0 20px 0;
}

.image-spec-item-wrapper {
  padding: 10px;
  border-bottom: 1px solid #dbe5ff;
}

.image-spec-item-icon {
  width: 35px;
  height: 35px;
  margin: 0 10px 0 0;
  float: left;
}

.image-spec-item-text {
  width: calc(100% - 45px);
  height: 30px;
  float: left;
}

p.image-spec-item-text-title {
  font-family: "Sofia Pro", Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0;
  padding: 0;
}

p.image-spec-item-text-data {
  font-family: "Sofia Pro", Helvetica, Arial, sans-serif;
  font-size: 10px;
  color: #797979;
  letter-spacing: 0;
  padding: 0;
}

.image-spec-item-icon.client {
  background: url("../../assets/icons/user-icon-black.svg") no-repeat center center;
  background-size: 22px auto;
}
.image-spec-item-icon.email {
  background: url("../../assets/icons/user-email-icon-black.svg") no-repeat center center;
  background-size: 22px auto;
}
.image-spec-item-icon.prof-id {
  background: url("../../assets/icons/prof-icon-black.svg") no-repeat center center;
  background-size: 22px auto;
}
.image-spec-item-icon.bodypart {
  background: url("../../assets/icons/bodypart-icon-black.svg") no-repeat center center;
  background-size: auto 24px;
}
.image-spec-item-icon.image-size {
  background: url("../../assets/icons/image-icon-black.svg") no-repeat center center;
  background-size: 22px auto;
}
.image-spec-item-icon.device {
  background: url("../../assets/icons/phone-icon-black.svg") no-repeat center center;
  background-size: auto 26px;
}
.image-spec-item-icon.software {
  background: url("../../assets/icons/software-icon-black.svg") no-repeat center center;
  background-size: 22px auto;
}
.image-detail-notes {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #dbe5ff;
}

.image-detail-specs {
  background-color: #fff;
  padding: 10px 0 0 0;
  margin: 0 0 20px 0;
}

.image-detail-notes h2,
.image-detail-specs h2 {
  font-family: "TT Norms", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 19px;
}

.image-detail-notes p{
  font-family: "Sofia Pro", Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: #757575;
  letter-spacing: 0;
  padding: 5px 0;
}
.image-detail-specs p {
  /* Scrhijf een korte no: */
  font-family: "Sofia Pro", Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: #757575;
  letter-spacing: 0;
}

.image-spec-item-text-title{
  font-weight: 700;
}

.image-detail-specs h2{
  padding: 10px 10px 0 10px;
}

.image-detail-specs p.subtitle {
  padding: 10px;
}

.image-import-progress-wrapper{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: rgba(150,150,150,0.5);
  width: 100%;
  z-index: 99;
}

.image-import-progress{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: #00adef;
  z-index: 999;
}

.image-uploading-overlay{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 155px;
  width: 100%;
  background-color: rgba(0,0,0,0);
  z-index: 999;
}


</style>
