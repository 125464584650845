<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>Omvangsmeting bewerken</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeEditBodyMeasurementDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-form>
        <div class="input-content-wrapper padding-top space" v-bind:class="activeGender">
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6>
                <v-menu
                  ref="dateDialog"
                  :close-on-content-click="false"
                  v-model="dateDialog"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  >
                  <template v-slot:activator="{on}">
                    <v-text-field
                      class="mt-4"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      v-on="on"
                      prepend-inner-icon="mdi-calendar-today"
                      v-model="addedDateFormatted"
                      label="Datum meting"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="date" :max="max" :show-current="true" no-title @input="dateDialog = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md6 >
                <v-text-field
                  class="mt-4"
                  label="Tijdstip"
                  v-model="selectedMeasurement.time"
                  prepend-inner-icon="mdi-clock-outline"
                  type="time"
                  suffix="uur"
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>

          <div class="body-measurement-item-wrapper clear">
            <div  class="body-measurement-icon upper-arm-left" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Bovenarm Links (midden)"
                v-model.number="selectedMeasurement.upperArmLeft"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div  class="body-measurement-icon upper-arm-right" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Bovenarm Rechts (midden)"
                v-model.number="selectedMeasurement.upperArmRight"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon chest" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Borstomvang"
                v-model.number="selectedMeasurement.chest"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon upper-belly" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Buik boven navel"
                v-model.number="selectedMeasurement.upperBelly"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon lower-belly" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Buik onder navel"
                v-model.number="selectedMeasurement.lowerBelly"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div v-bind:class="activeGender" class="body-measurement-icon hip" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Heup"
                v-model.number="selectedMeasurement.hip"
                number
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon buttocks" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Billen"
                v-model.number="selectedMeasurement.buttocks"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon upperleg-left" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Bovenbeen Links (midden)"
                v-model.number="selectedMeasurement.upperLegLeft"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon upperleg-right" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Bovenbeen Rechts (midden)"
                v-model.number="selectedMeasurement.upperLegRight"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon calf-left" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Kuit Links (midden)"
                v-model.number="selectedMeasurement.calfLeft"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon calf-right" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Kuit Rechts (midden)"
                v-model.number="selectedMeasurement.calfRight"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon ankle-left" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Enkel Links"
                v-model.number="selectedMeasurement.ankleLeft"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon ankle-right" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Enkel Rechts"
                v-model.number="selectedMeasurement.ankleRight"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

        </div>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left">
            <v-btn
              outlined
              depressed
              color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
              @click="closeEditBodyMeasurementDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              :dark="changed"
              :depressed="!changed"
              :disabled="!changed"
              color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              @click="updateMeasurement()"
              >
              <div v-if="changed">Wijzigingen opslaan <v-icon right>mdi-chevron-right</v-icon></div>
              <div v-else>Opgeslagen <v-icon right>mdi-check</v-icon></div>  
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>
  </v-card>
</template>


<script>
import db from "../../firebase/init";
import '@firebase/firestore';
import moment from "moment";
// import _ from "lodash";

export default {
  name: "edit-body-composition-form",
  props: {
    clientInfo: Object, 
    selectedMeasurement: Object,
    selectedMeasurementOriginal: Object,
    date: String,
    dateOriginal: String,
  },

  data() {
    return {
      max: null,
      retryLoading: false,
      feedback: null,
      dateDialog: false,
    };
  },

  created() {
    this.max = moment().format("YYYY-MM-DD");
  },

  methods: {
    // Dialog sluiten
    closeEditBodyMeasurementDialog() {
      this.$emit("closeEditBodyMeasurementDialogChange", false);
      this.$emit("clearEditMeasurementData");
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    // Aangepaste omvangsmeting updaten
    updateMeasurement() {
      this.feedback = null
      db.collection("measurements")
        .doc(this.selectedMeasurement.id)
        .update({
          dateAdded: new Date(this.firebaseDateFormatted),
          dateUpdated: new Date(),
          upperArmLeft: this.selectedMeasurement.upperArmLeft,
          upperArmRight: this.selectedMeasurement.upperArmRight,
          chest: this.selectedMeasurement.chest,
          upperBelly: this.selectedMeasurement.upperBelly,
          lowerBelly: this.selectedMeasurement.lowerBelly,
          hip: this.selectedMeasurement.hip,
          buttocks: this.selectedMeasurement.buttocks,
          upperLegLeft: this.selectedMeasurement.upperLegLeft,
          upperLegRight: this.selectedMeasurement.upperLegRight,
          calfLeft: this.selectedMeasurement.calfLeft,
          calfRight: this.selectedMeasurement.calfRight,
          ankleLeft: this.selectedMeasurement.ankleLeft,
          ankleRight: this.selectedMeasurement.ankleRight,
          time: this.selectedMeasurement.time
        })
        .then(() => {
          this.$emit("updateData");
          this.closeEditBodyMeasurementDialog()
          console.log("Body Measurement Successfully Updated");
        })
        .catch(error => {
          this.feedback = "Opslaan is niet gelukt. Probeer opnieuw";
          console.error("Error adding skinfold measurement: ", error);
        });
    }
  },

  computed: {
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    activeGender() {
      return this.clientInfo.gender == 1 ? "man" : "woman";
    },
    addedDateFormatted() {
      return this.formatDate(this.date);
    },
    firebaseDateFormatted() {
      return new Date(this.date);
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },
    changed() {
      if (
        _.isEqual(this.selectedMeasurement, this.selectedMeasurementOriginal) == false ||
        this.date != this.dateOriginal
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

