const moment = require("moment");



const frequencyObject = [
    {
      name: 'Dagelijks',
      id: 'daily',
      objTime: {multiplier: 1, name: "days"}
    },
    {
      name: 'Wekelijks',
      id: 'everyWeek',
      objTime: {multiplier: 1, name: "weeks"}
    },
    {
      name: 'Vierwekelijks',
      id: 'everyFourWeeks',
      objTime: {multiplier: 4, name: "weeks"}

    },
    {
      name: 'Maandelijks',
      id: 'everyMonth',
      objTime: {multiplier: 1, name: "months"}
    },
    {
      name: 'Per kwartaal',
      id: 'everyQuarter',
      objTime: {multiplier: 3, name: "months"}
    },
    {
      name: 'Halfjaarlijks',
      id: 'everyHalfYear',
      objTime: {multiplier: 6, name: "months"}
    },
    {
      name: 'Jaarlijks',
      id: 'everyYear',
      objTime: {multiplier: 1, name: "year"}
    }
  ]

let selectedItems = [
    // {id: "AAA"},
    // {id: "BBB"}
]

//Loaded by Where
let memberships = [

    // {   
    //     startDate: "2022-01-01",
    //     usage: [
    //       {date: "2022-01-05"},
    //     // {date: "2022-01-05"}
    //   ],
    //     paymentPeriod: "everyMonth",
    //     duration: 12, //minimale looptijd
    //     treatments: { 
    //         limitNumber: 1, 
    //         limitType: "period",
    //         treatments: [{id: "AAA", title: "Wassen Knippen Stylen"}]
    //     },
    //     days: {
    //       Tue: {
    //         allDay: true
    //       }
    //     }
    // }
        
   
    // {   //everyFourthWeeks
    //     startDate: "2024-06-01",
    //     usage: [{date: "2020-11-17"}],
    //     paymentPeriod: "everyYear",
    //     duration: 1, //minimale looptijd
    //     treatments: { 
    //         limitNumber: 3, 
    //         limitType: "total",
    //         treatments: [],
    //         // treatments: [{id: "BSa98sDnYbB3E611vXmf", title: "Huidverstrakking"}]
    //     },
    //     days: {
    //       Fri: {
    //         allDay: true
    //       }
    //     }
    // }
    ]
    

let fourthWeekArray = new Array(); 
//1.valid by frequenty and time
let validForUseMemberships =  new Array();
let notValidForUseMemberships = new Array();
//2. correspondingMemberships
let correspondingMemberships = new Array();

/**
 * 
 * Status Codes
 * 1a: not started yet || Override
 */

generateFourthWeeks(moment().format("YYYY"));


//  membershipValidation(memberships, selectedItems)

export function membershipValidation(memberships, selectedItems, clientId, receiptObj, bookingSumArr) {

  console.log("memberships", memberships, "selectedItems", selectedItems, "clientId", clientId, "receiptObj", receiptObj);

    //Staticly manipulate data for modifiers
    //TODO Only for Treatments Yet...

    memberships = memberships.map((membership) => {

      console.log("membership", membership);


      membership.toggle = getHistoryStatus(receiptObj, membership, "toggle"); //Toggle Membership ON true or OFF alse
      membership.override = getHistoryStatus(receiptObj, membership, "override"); //For forcing the membership. True / False
      membership.type = "membership"; //legacy
      membership.modifierGroup = "membershipModifier"

      
      membership.typeDiscount = {
        type: "membership",
        discountType: "amount",
        uniqueItems: true,
        discountValue: 0,
      }
      
      membership.numberLimit = {
        active: false
      }
      membership.combinationLimit = false; //TODO Create restriction if needed
      membership.active = true;
      membership.useLimit = {
        active: true,
        number: 1
      }
      membership.filterTreatments = {
        active: true,
        items: membership.treatments.treatments.map(treatment => treatment.id),
        itemsAction: "include",
        type: "specific"
      }
      membership.filterProducts = {
        active: false,
        
      }
      
      
      membership.peakStatus = peakSubscriptionCheck(membership.days[moment().locale("en").format("ddd")]);
        
        /**
         * 
         * @param {*} receiptObj 
         * @param {*} membership 
         * @param {*} type | could be Either toggle or override
         * @returns 
         */
        function getHistoryStatus(receiptObj, membership, type) {

          if(!receiptObj) return false;

          let foundMembershipHistory = 'validForUseMemberships' in receiptObj ? receiptObj.validForUseMemberships.find(validForUse => validForUse.id === membership.id) : false;

          if(foundMembershipHistory) {


            if(type === "toggle") {
             return foundMembershipHistory.toggle;
            }

            if(type === "override") {
              return foundMembershipHistory.override;
            }


          } else {
            //No history found
            return false;
          }

        
        }
 

        //Peak Or Off-Peak
        function peakSubscriptionCheck(byDay) {
          // { end: null,
          //   name: 'saturday',
          //   active: false,
          //   allDay: null,
          //   start: null }

          //first check if active is true,

                
            if (byDay.active) {
              //then check if its Allday is true else look at start and end.
              if (byDay.allDay) {
                //its allDay
              
                return { peak: "peak" };
              } else {
                // Its not allDay check start and End time

                //get bookingSumArr sort by the earliest date
                let sortedBookingSumArr = bookingSumArr.sort((a, b) => new Date(a.date) - new Date(b.date));

                if(sortedBookingSumArr.length > 0) {
                  //get the first start date format is YYYY-MM-DDTHH:mm
                  let firstStartDate = sortedBookingSumArr[0].start;

              
                  // compare with byDay.start
                  if(moment(firstStartDate).locale("en").format("HH:mm") >= byDay.start) {
                    return { peak: "peak" };
                  } else {
                    return { peak: "offPeak" };
                  }
                } else {
                  // No Booking Summaries found Meaning its a new Receipt

                  if (
                    moment().locale("en").format("HH:mm") >= byDay.start &&
                    moment().locale("en").format("HH:mm") <= byDay.end
                  ) {
                    return { peak: "peak" };
                  } else {
                    return { peak: "offPeak" };
                  }

                }
        
             
              }
            } else {
              return { peak: "offPeak" };
            }
      
            
          
          
        
        }

      return membership;
   })

    validForUseMemberships =  new Array();
    notValidForUseMemberships = new Array();
    correspondingMemberships = new Array();



    memberships.some(membership => {

        //membership started?
        membership.status = checkStartDate(membership);
    
        if(membership.status.code === "1a") {
        
            notValidForUseMemberships.push(membership)
            correspondingMembership(membership, selectedItems, clientId); 
            return false;
        }
    
        //membership in range of end date?
        membership.status = checkEndDate(membership);
    
        if(membership.status.code === "1b") {
        
            notValidForUseMemberships.push(membership)
            correspondingMembership(membership, selectedItems, clientId); 
            return false;
        }
    
        //Period or Total
    
        if(membership.treatments.limitType === "period") {
           membership.status = periodValidation(membership);
           periodValidation(membership); //?
           if(membership.status.code === "2A") {
    
            notValidForUseMemberships.push(membership)
            correspondingMembership(membership, selectedItems, clientId)
           }
    
        } else {
            //Total
            membership.status = totalValidation(membership);

            if(membership.status.code === "2B") {

                notValidForUseMemberships.push(membership)
                correspondingMembership(membership, selectedItems, clientId)
            }

        }
    
        if(membership.status === true) {
            validForUseMemberships.push(membership)
            correspondingMembership(membership, selectedItems, clientId);
    
        }
    
    })

    return {
        validForUseMemberships: validForUseMemberships,
        notValidForUseMemberships: notValidForUseMemberships,
        correspondingMemberships: correspondingMemberships
    }
}


function checkStartDate(membership) {

    if(moment().format("YYYY-MM-DD") >= membership.startDate) {
        return true;
    } else {
        return {code: "1a", feedback: "Membership is not started yet", override: true, extend: false};
    }

}

//Membership Expired
function checkEndDate(membership) {

  const frequenty = frequencyObject.find(frequenty => frequenty.id === membership.paymentPeriod);

  /* The start date of the membership is converted to a moment object. 
  The moment object is then added to the duration of the membership multiplied by the multiplier of
  the frequenty object. 
  The moment object is then converted back to a string and assigned to the calculatedEndDate
  variable. */
  let calculatedEndDate = moment(membership.startDate, "YYYY-MM-DD").add(frequenty.objTime.multiplier * membership.duration, frequenty.objTime.name).format("YYYY-MM-DD"); 

  if(moment().format("YYYY-MM-DD") <= calculatedEndDate) {
      return true;
  } else {
      return {code: "1b", feedback: "Membership is expired", override: true, extend: true};
  }

}


function periodValidation(membership) {

    const frequency = frequencyObject.find(frequency => frequency.id === membership.paymentPeriod);
    
    let getUsage = new Array();


    //daily, everyWeek, everyMonth, everyYear
    if(frequency.objTime.multiplier === 1) {


     if(frequency.id === "daily") {

        getUsage = membership.usage.filter((usage) =>  usage.date.includes(moment().format("YYYY-MM-DD")));  //?


     } else {

        getUsage = membership.usage.filter((usage) => 
        moment().startOf(frequency.objTime.name).format("YYYY-MM-DD") 
        <= usage.date && moment().endOf(frequency.objTime.name).format("YYYY-MM-DD") >= usage.date); //?


     }

    //everyQuarter
    } else if (frequency.objTime.multiplier === 3) {

        function quarterYear() {
            if (moment().format("MM") <= 3) {

                return {start: moment(1, "MM").startOf("months").format("YYYY-MM-DD"), 
                        end: moment(3, "MM").endOf("months").format("YYYY-MM-DD") }

            } else if (moment().format("MM") >= 3 && moment().format("MM") <= 6) {

                return {start: moment(4, "MM").startOf("months").format("YYYY-MM-DD"), 
                        end: moment(6, "MM").endOf("months").format("YYYY-MM-DD") }

            } else if (moment().format("MM") >= 6 && moment().format("MM") <= 9) {

                return {start: moment(7, "MM").startOf("months").format("YYYY-MM-DD"), 
                        end: moment(9, "MM").endOf("months").format("YYYY-MM-DD") }
 
            } else if (moment().format("MM") > 9) {

                return {start: moment(10, "MM").startOf("months").format("YYYY-MM-DD"), 
                        end: moment(12, "MM").endOf("months").format("YYYY-MM-DD") }
            }
        }

        const getQuarterYear = quarterYear();

        getUsage = membership.usage.filter((usage) => getQuarterYear.start <= usage.date && usage.date <= getQuarterYear.end);

    //everyFourWeeks
    } else if (frequency.objTime.multiplier === 4) {

        let thisIsoWeek = moment().isoWeek() //?
        let rangeFourthWeek = fourthWeekArray.find(fourthWeek => fourthWeek.week[0] <= thisIsoWeek && thisIsoWeek <= fourthWeek.week[1])
    
        getUsage = membership.usage.filter((usage) => rangeFourthWeek.dateRange.start <= usage.date && usage.date <= rangeFourthWeek.dateRange.end);
    
        //everyHalfYear
    } else if (frequency.objTime.multiplier === 6) {

        function halfYear() {
            if (moment().format("MM") > 6) {
                return {start: moment(7, "MM").startOf("months").format("YYYY-MM-DD"), 
                        end: moment(12, "MM").endOf("months").format("YYYY-MM-DD") }
            } else {
                return {start: moment(1, "MM").startOf("months").format("YYYY-MM-DD"), 
                        end: moment(6, "MM").endOf("months").format("YYYY-MM-DD") }
            }
        }

        const getHalfYear = halfYear();

        getUsage = membership.usage.filter((usage) => getHalfYear.start <= usage.date && usage.date <= getHalfYear.end);


    }

    getUsage.length //?

    if(getUsage.length < membership.treatments.limitNumber) {
        return true;
    } else {
        return {code: "2A", feedback: "Period frequenty limit reached", override: true, extend: false};
    }
    
}



function totalValidation(membership) {

   const frequenty = frequencyObject.find(frequenty => frequenty.id === membership.paymentPeriod);

   let calculatedEndDate = moment(membership.startDate, "YYYY-MM-DD").add(frequenty.objTime.multiplier * membership.duration, frequenty.objTime.name).format("YYYY-MM-DD"); 

   let getUsage = membership.usage.filter((usage) => membership.startDate <= usage.date && usage.date <= calculatedEndDate); 

   if(getUsage.length <= membership.treatments.limitNumber) {
    return true;
    } else {
        return {code: "2B", feedback: "Total frequenty limit reached", override: true, extend: false};
    }


}




/**
 * output: true or false and pushes to correspondingMemberships
 * @param {*} membership 
 * @param {*} selectedItems 
 */
function correspondingMembership(membership, selectedItems, clientId) {

    // const checkTreatment = (treatment) => {
        
    //   //Check if the selectedItems array contains an object with an id property that matches the id of the treatment.
    //     if(selectedItems.find(selectedItem => selectedItem.id === treatment.id)) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    // if(membership.treatments.treatments.some(checkTreatment) && clientId === membership.clientId ) {
    //     correspondingMemberships.push(membership);
    // }

    if(clientId === membership.clientId) {
      console.log("membership", membership);
        correspondingMemberships.push(membership);
    }

}

// generateFourthWeeks(2022);
//Generate fourthWeekRange
function generateFourthWeeks(yearNumber) {

    let getYearWeeks = moment(yearNumber, "YYYY").isoWeeksInYear(); //?

    let startWeek = 1;
    let endWeek = 4;
    for (let index = 1; index < 13; index++) {
        
        
        if(startWeek == 1) {
            pushFourthWeek(startWeek, endWeek)
        } else {

        }


        startWeek += 4; //?
        endWeek += 4; //?

        pushFourthWeek(startWeek, endWeek)

        
        //Add an extra week
        if(getYearWeeks === 53 && index === 12) {
            pushFourthWeek(53, 53)
        }


        
    }

    function pushFourthWeek(startWeek, endWeek) {
        fourthWeekArray.push({
            week: [startWeek,endWeek],
            dateRange: {
                start: moment(startWeek + "-" + yearNumber, "WW-YYYY").year(yearNumber).startOf("isoweeks").format("YYYY-MM-DD"), 
                end: moment(endWeek + "-" + yearNumber, "WW-YYYY").year(yearNumber).endOf("isoweeks").format("YYYY-MM-DD")
            }
        })
    }

}

fourthWeekArray //?

// { week: [ 41, 44 ],
//     dateRange: { start: '2021-10-11', end: '2021-11-07' } },
//   { week: [ 45, 48 ],
//     dateRange: { start: '2021-11-08', end: '2021-12-05' } },
//   { week: [ 49, 52 ],
//     dateRange: { start: '2021-12-06', end: '2022-01-02' } } 

validForUseMemberships //?
notValidForUseMemberships //?
correspondingMemberships //?