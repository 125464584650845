<template v-slot:activator="{ on }">
	<div>
		<div v-if="$store.state.activeUserCompanies.length > 1" class="pb-2">
			<v-container grid-list-md class="ma-0 pa-0 pb-2" fluid>
				<v-layout row wrap>
					<companySelector :showBranches="true" @companyChanged="loadData" @branchChanged="loadData"></companySelector>
					<v-flex md3 xs12>
						<period-select :dataType="'dashboard'" :typeRounded="true" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
					</v-flex>
					<v-flex md3 xs12>
						<v-btn icon v-if="selectedLogs.length > 0" @click="removeLogsDialog = true">
							<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
						</v-btn>
						<v-switch v-model="showErrorInConsole" inset class="ma-0 pa-0 py-2"
							label="Show errors in console.log" hide-details
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="float: right"></v-switch>
					</v-flex>
				</v-layout>
			</v-container>
		</div>

		<div v-if="loading">
			<div style="position: relative; padding: 200px 0;">
				<div class="thrive-loader"></div>
			</div>
		</div>

		<div v-if="removing.loading">
			<div class="loading-dots">
				<div class="thrive-loader"></div>
			</div>
		</div>

		<v-card v-if="!loading && !removing.loading && logRecords.length > 0" flat
			:color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
			<v-data-table 
				:headers="headers" 
				:items="logRecords" 
				single-expand 
				show-expand 
				dense 
				hide-default-footer
				item-key="errorId" :page.sync="logPage" 
				:items-per-page="itemsPerPage"
				@page-count="logPageCount = $event" 
				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
				:style="{ 'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }" 
				class="dense-table"
				v-model="selectedLogs">
				<template v-slot:item="{ item, isExpanded, expand, isSelected, select }">
					<tr>
						<td>
							<v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
							<v-icon @click="select(false)" v-if="isSelected" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
						</td>
						<td>{{ item.timestamp.value | moment("DD-MM-YYYY - H:mm") }}u</td>
						<td>
							<v-btn icon @click="getVideoRecord(item, item.recordingId)" v-if="item.recordingId">
								<v-icon	:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark>mdi-play-box</v-icon>
							</v-btn>
						</td>
						<td>{{ item.errorId }}</td>
						<td>{{ item.logType }}</td>
						<td>{{ item.errorMessage }}</td>
						<td>{{ item.app }} v{{ item.version }}</td>
						<td>{{ item.browser }} - {{ JSON.parse(item.platform).family }}</td>
						<td>
							<v-tooltip dark bottom> 
								<template v-slot:activator="{ on }">
									<span v-on="on">{{ getUserName(item.userId) }}</span>
								</template>
								<span class="tooltip-text-white" style="font-size: 12px"><strong>UserID:</strong> {{  item.userId }}</span>
							</v-tooltip>
						</td>
						<td>
							<v-tooltip dark bottom> 
								<template v-slot:activator="{ on }">
									<span v-on="on">{{ getUserRole(item.userRole) }}</span>
								</template>
								<span class="tooltip-text-white" style="font-size: 12px"><strong>RoleID:</strong> {{  item.userRole }}</span>
							</v-tooltip>
						</td>
						<td>
							<v-btn icon @click="expand(true)" v-if="!isExpanded">
								<v-icon dark>mdi-menu-down</v-icon>
							</v-btn>
							<v-btn icon @click="expand(false)" v-if="isExpanded">
								<v-icon dark>mdi-menu-up</v-icon>
							</v-btn>
						</td>
					</tr>
				</template>
				<template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length" style="padding: 0;">
						<v-card class="ma-0 pa-0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background">
							<v-card-text class="ma-0">
								<strong>{{ $t("admin.component", $store.state.locale) }}</strong><br>
								{{ item.component }}
								<br><br>
								<strong>{{ $t("admin.error", $store.state.locale) }}</strong><br>
								{{ item.errorStack }}
							</v-card-text>
						</v-card>
						<v-divider></v-divider>
					</td>
				</template>
			</v-data-table>
		</v-card>

		<div class="no-data-message" v-if="!loading && !removing.loading && logRecords.length == 0" >
			<v-avatar size="58" light class="ma-0 mr-2 custom-avatar-style" rounded="lg"
				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :style="{ 'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
				<v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-video-off</v-icon>
			</v-avatar>
			<p>{{ $t('admin.noErrorLogs', $store.state.locale) }} {{ $store.state.activeCompany.name }}.</p>
		</div>

		<div v-if="logRecords.length > itemsPerPage" class="text-center pt-4">
			<v-pagination :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :total-visible="9" v-model="logPage"
				:length="logPageCount"></v-pagination>
		</div>

		<!-- / Show Recording Dialog \ -->
		<v-dialog v-model="recordingDialog" scrollable :content-class="`custom-dialog square ${$store.state.companyTheme}`" fullscreen
			hide-overlay transition="dialog-bottom-transition">
			<v-card v-if="selectedLog && selectedLog.data && selectedLog.data.timestamp" style="border-radius: 0px!important">
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
					<h2>Error {{ selectedLog.errorMessage }}</h2>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeRecordingDialog()">
						<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text class="ma-0 pa-0">
					<v-container class="ma-0 pa-0" fluid style="height: 100%">
						<v-layout row wrap class="ma-0 pa-0" style="height: 100%">
							<v-flex md7 xs12 id="video-wrapper" style="height: 100%; position: relative"
								:key="selectedLog.data.recordingId">
								<div v-if="selectedLog.loading && !selectedLog.noFile"
									style="position: absolute; top: 50%; left: 50%; margin: -25px 0 0 -25px">
									<div class="thrive-loader"></div>
								</div>
								<div v-if="!selectedLog.loading && selectedLog.noFile" class="text-center"
									style="padding-top: 250px">
									<v-avatar size="56" light class="ma-4 custom-avatar-style" rounded="lg"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
										:style="{ 'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
										<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-file-question</v-icon>
									</v-avatar>
									<h3 class="mb-4">{{ $t('admin.noVideoFileFound', $store.state.locale) }}</h3>
								</div>
								<div v-show="!selectedLog.loading" id="logPlayerThrive" style="display: flex;"></div>

							</v-flex>
							<v-flex md5 xs12 class="video-log-column">
								<div class="pa-2">
									<v-list-item class="pa-0 ma-0" style="min-height: 10px;">
										<v-list-item-avatar size="26" light class="ma-1 custom-avatar-style"
											rounded="lg" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											:style="{ 'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
											<v-icon small
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar</v-icon>
										</v-list-item-avatar>
										<v-list-item-content class="pa-0">
											<v-list-item-title class="pb-1 pl-2">
												<p class="ma-0 pa-0"><span
														style="width: 80px; display: inline-block"><strong>{{
															$t("general.date", $store.state.locale)
														}}:</strong></span> {{ selectedLog.data.timestamp.value | moment("DD-MM-YYYY") }}</p>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item class="pa-0 ma-0" style="min-height: 10px;">
										<v-list-item-avatar size="26" light class="ma-1 custom-avatar-style"
											rounded="lg" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											:style="{ 'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
											<v-icon small
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>
										</v-list-item-avatar>
										<v-list-item-content class="pa-0">
											<v-list-item-title class="pb-1 pl-2">
												<p class="ma-0 pa-0"><span
														style="width: 80px; display: inline-block"><strong>{{
															$t("admin.user", $store.state.locale)
														}}:</strong></span> {{ getUserName(selectedLog.data.userId ) }}</p>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item class="pa-0 ma-0" style="min-height: 10px;">
										<v-list-item-avatar size="26" light class="ma-1 custom-avatar-style"
											rounded="lg" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											:style="{ 'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
											<v-icon small
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-check</v-icon>
										</v-list-item-avatar>
										<v-list-item-content class="pa-0">
											<v-list-item-title class="pb-1 pl-2">
												<p class="ma-0 pa-0"><span
														style="width: 80px; display: inline-block"><strong>{{
															$t("admin.userRole", $store.state.locale)
														}}:</strong></span> {{ getUserRole(selectedLog.data.userRole) }}</p>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item class="pa-0 ma-0" style="min-height: 10px;">
										<v-list-item-avatar size="26" light class="ma-1 custom-avatar-style"
											rounded="lg" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											:style="{ 'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
											<v-icon small
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-source-branch</v-icon>
										</v-list-item-avatar>
										<v-list-item-content class="pa-0">
											<v-list-item-title class="pb-1 pl-2">
												<p class="ma-0 pa-0"><span
														style="width: 80px; display: inline-block"><strong>{{
															$t("admin.app", $store.state.locale)
														}}:</strong></span> {{ selectedLog.data.app }} v{{ selectedLog.data.version }}</p>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

									<v-list-item class="pa-0 ma-0" style="min-height: 10px;">
										<v-list-item-avatar size="26" light class="ma-1 custom-avatar-style"
											rounded="lg" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
											:style="{ 'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
											<v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-web</v-icon>
										</v-list-item-avatar>
										<v-list-item-content class="pa-0">
											<v-list-item-title class="pb-1 pl-2">
												<p class="ma-0 pa-0"><span
														style="width: 80px; display: inline-block"><strong>{{
															$t("admin.browser", $store.state.locale)
														}}:</strong></span>
													{{ selectedLog.data.browser }}</p>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</div>

								<v-divider></v-divider>

								<v-data-table v-if="linkedLogRecords.length > 0" :headers="headersLinked"
									:items="linkedLogRecords" :items-per-page="-1" single-expand show-expand dense
									sort-by="timestamp.value" hide-default-footer item-key="errorId"
									:key="activeArrowInPlay" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									style="background-color: transparant; border-collapse: collapse;"
									class="dense-table">
									<template v-slot:item="{ item, isExpanded, expand, index }">
										<tr :class="{ 'log-highlight': activeArrowInPlay == item.errorId }"
											:key="activeArrowInPlay + index">
											<td>{{ item.timestamp.value | moment("H:mm:ss") }}</td>
											<td>{{ item.logType }}</td>
											<td>{{ item.errorMessage }}</td>
											<td>
												<v-btn icon @click="expand(true)" v-if="!isExpanded"><v-icon
														dark>mdi-menu-down</v-icon></v-btn>
												<v-btn icon @click="expand(false)" v-if="isExpanded"><v-icon
														dark>mdi-menu-up</v-icon></v-btn>
											</td>
										</tr>
									</template>
									<template v-slot:expanded-item="{ headers, item }">
										<td :colspan="headers.length" style="padding: 0;">
											<v-card class="ma-0 pa-0" flat
												:color="$themes[$store.state.companyTheme][$store.state.themeModus].background">
												<v-card-text class="ma-0">
													<strong>{{
														$t("admin.component", $store.state.locale)
													}}</strong><br>
													{{ item.component }}
													<br><br>
													<strong>{{ $t("admin.error", $store.state.locale) }}</strong><br>
													{{ item.errorStack }}
												</v-card-text>
											</v-card>
											<v-divider></v-divider>
										</td>
									</template>
								</v-data-table>
								<v-divider></v-divider>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- \ Show Recording Dialog / -->

		<!-- / Delete Video Logs Dialog \ -->
		<v-dialog v-model="removeLogsDialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
			<v-card>
				<v-card-text class="text-center ma-0 pt-4">
					<v-avatar size="56" light class="ma-4 custom-avatar-style" rounded="lg"
						:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						:style="{ 'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
						<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
					</v-avatar>
					<h3 class="mb-4">{{ $t('admin.removeVideoLogs', $store.state.locale) }}</h3>
					<p class="ma-0">{{ $t('admin.removeVideoLogsInfo', $store.state.locale) }}</p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 md6 class="text-left"
								v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
								<v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
									@click="removeLogsDialog = false">
									{{ $t('general.cancel', $store.state.locale) }}
								</v-btn>
							</v-flex>
							<v-flex xs12 md6 class="text-right">
								<v-btn dark
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeLogs()">
									{{ $t('admin.removeLogsButton', $store.state.locale) }}
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ Delete Video Logs Dialog / -->

	</div>
</template>

<script>
import db from "../firebase/init";
import moment from "moment";
import axios from "axios";
import { bus } from "../main";
import * as rrweb from "rrweb";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";
import firebase from "firebase/app";
import "firebase/storage";
import periodSelector from "../components/elements/periodSelector.vue";
import companySelector from "@/components/elements/companySelector.vue";

export default {
	name: "logPlayer",
	components: {
		"period-select": periodSelector,
		companySelector: companySelector
	},

	data: function () {
		return {
			dialog: false,
			player: null,
			loading: true,
			replayerEvents: null,
			buildUpEvents: new Array(),
			logPage: 1,
			pageCount: 0,
			logPageCount: 0,
			itemsPerPage: 28,
			users: new Array(),
			branches: new Array(),
			replayEventBuildUp: new Array(),
			logRecords: new Array(),
			linkedLogRecords: new Array(),
			recordingDialog: false,
			logDataFilter: {
				type: "week",
				year: moment().year(),
				number: moment().isoWeek(),
				loaded: false
			},
			userRoleNumbers: [
				{
					number: 0,
					name: "Developer"
				},
				{
					number: 1,
					name: "Superadmin"
				},
				{
					number: 2,
					name: "Admin"
				},
				{
					number: 3,
					name: "Manager"
				},
				{
					number: 4,
					name: "Branch"
				},
				{
					number: 5,
					name: "employee"
				}
			],
			headersLinked: [
				{
					text: this.$t("admin.time", this.$store.state.locale),
					align: "left",
					value: "date",
					width: "150px"
				},
				{
					text: this.$t("admin.logType", this.$store.state.locale),
					align: "left",
					value: "logType",
					width: "140px"
				},
				{
					text: this.$t("admin.error", this.$store.state.locale),
					align: "left",
					value: "errorMessage",
					sortable: false
				},
				{
					text: "",
					value: "data-table-expand",
					align: "right",
					width: "60px",
					sortable: false
				}
			],
			headers: [
				{
					text: "",
					value: "",
					align: "right",
					width: "40px",
					sortable: false
				},
				{
					text: this.$t("general.date", this.$store.state.locale),
					align: "left",
					value: "date",
					width: "160px"
				},
				{
					text: "",
					align: "right",
					value: "",
					width: "60px"
				},
				{
					text: this.$t("admin.errorId", this.$store.state.locale),
					align: "left",
					value: "id",
					width: "120px"
				},
				{
					text: this.$t("admin.logType", this.$store.state.locale),
					align: "left",
					value: "logType",
					width: "140px"
				},
				{
					text: this.$t("admin.error", this.$store.state.locale),
					align: "left",
					value: "errorMessage",
					sortable: false
				},
				{
					text: this.$t("admin.app", this.$store.state.locale),
					align: "left",
					value: "app",
					width: "100px"
				},
				{
					text: this.$t("admin.platform", this.$store.state.locale),
					align: "left",
					value: "platform",
					width: "200px"
				},
				{
					text: this.$t("admin.userId", this.$store.state.locale),
					align: "left",
					value: "userId",
					width: "150px"
				},
				{
					text: this.$t("admin.userRole", this.$store.state.locale),
					align: "left",
					value: "userRole",
					width: "80px"
				},
				{
					text: "",
					value: "data-table-expand",
					align: "right",
					width: "60px",
					sortable: false
				}
			],
			selectedLog: {
				loading: true,
				data: null,
				noFile: false
			},
			selectedLogs: new Array(),
			activeArrowInPlay: null,
			showErrorInConsole: false,
			removeLogsDialog: false,
			removing: {
				loading: false,
				items: 0,
				removed: 0
			}
		};
	},
	created() {
		this.loadUsers();
		this.loadData();	
	},

	methods: {
		loadData(){
			this.getLogData();
		},

		updateDataPeriodRefresh(filter) {
			this.logDataFilter.type = filter.type;
			this.logDataFilter.year = filter.year;
			this.logDataFilter.number = filter.number;
			this.getLogData()
		},

		loadUsers(){
			db.collection("users")
			.get()
			.then((snap) => {
				snap.forEach((doc) => {
					let user = doc.data();
					user.id = doc.id;
					this.users.push(user);
				});
			})
			.catch(error=>{
				console.error("Error loading users:", error )
			})
		},

		getUserName(id) {
			return this.users.find(item => { return item.id == id }) ? this.users.find(item => { return item.id == id }).firstName + " " + this.users.find(item => { return item.id == id }).lastName : "Onbekend";
		},

		getUserRole(id) {
			return this.userRoleNumbers.find(item => { return item.number == id }) ? this.userRoleNumbers.find(item => { return item.number == id }).name : "Onbekend";
		},

		closeRecordingDialog() {
			this.recordingDialog = false;
			this.player = null;
			document.getElementById("logPlayerThrive").innerHTML = "";
			this.selectedLog = {
				loading: true,
				data: null,
				noFile: false
			};
		},

		async getLogData() {
			this.loading = true;
			this.logRecords = new Array();

			let token = null;
			await firebase.auth().currentUser.getIdToken(true)
				.then(idToken => { token = idToken })

			let data = JSON.stringify({
				token: token,
				companyId: this.$store.state.activeCompany.id,
				year: this.logDataFilter.year,
				periodType: this.logDataFilter.type,
				periodNumber: this.logDataFilter.number
			})

			var config = {
				method: 'post',
				url: `${this.$store.state.reportsApi.url}/getLogData`,
				headers: {
					'Content-Type': 'application/json'
				},
				data: data
			};

			await axios(config)
				.then(response => {
					this.logRecords = response.data
				})

			this.loading = false;
		},

		getLinkedLogs(recordId) {
			this.linkedLogRecords = this.logRecords.filter(log => { return log.recordingId == recordId })
		},

		async removeLogs() {
			this.removeLogsDialog = false;
			this.removing.loading = true;
			this.removing.items = this.selectedLogs.length;


			const uniqueRecords = [...new Set(this.selectedLogs.map(item => item.recordingId))];

			await new Promise((resolve) => { // Remove recordings
				let counter = 0;
				uniqueRecords.forEach(log => {
					firebase.auth().currentUser.getIdToken(true)
						.then(idToken => {
							let data = JSON.stringify({
								token: idToken,
								recordingId: log,
								companyId: this.$store.state.activeCompany.id,
							})
							var config = {
								method: 'post',
								url: `${this.$store.state.reportsApi.url}/editLogData`,
								headers: {
									'Content-Type': 'application/json'
								},
								data: data
							};

							axios(config)
								.then(response => {
								})
						})
					let storage = firebase.storage();
					let ref = storage.ref(`thrive-error-logging/${this.$store.state.activeCompany.id}_${log}.json`);
					ref.delete()
						.catch((error) => {
							console.error(`Error removing log video ${log}: ${error}`)
						})
					counter++
					if (counter == uniqueRecords.length) {
						resolve()
					}
				});
			})
			this.removing.loading = false;
			this.selectedLogs = new Array();
			this.getLogData()
		},

		async getVideoRecord(item, recordId) {
			this.selectedLog.data = _.cloneDeep(item)
			this.selectedLog.loading = true;
			this.selectedLog.noFile = false;
			this.linkedLogRecords = new Array();
			this.recordingDialog = true;

			await this.getLinkedLogs(recordId)
			let vm = this;
			let downloadURL = null;
			let theLog = this.$store.state.activeCompany.id + "_" + recordId + ".json";
			let storage = firebase.storage();
			let ref = storage.ref("thrive-error-logging/" + theLog);

			await new Promise((resolve, reject) => {
				ref.getDownloadURL()
					.then((url) => {
						downloadURL = url;
						resolve();
					})
					.catch((error) => {
						switch (error.code) {
							case "storage/object-not-found": // File doesn't exist
								console.error("File doesn't exist: ", error.message);
								break;
							case "storage/unauthorized": // User doesn't have permission to access the object
								console.error("User doesn't have permission to access the object", error.message);
								break;
							case "storage/canceled": // User canceled the upload
								console.error("User canceled the upload", error.message);
								break;
							case "storage/unknown": // Unknown error occurred, inspect the server response
								console.error("Unknown error occurred, inspect the server response", error.message);
								break;
						}
						resolve();
					});
			});
			console.log("downloadURL", downloadURL)
			if (downloadURL !== null) { // Get the download url via axios and json parse it to vm.replayerEvents
				await axios
					.get(downloadURL)
					.then((response) => {
						console.log("response.data", response.data)
						vm.replayerEvents = response.data.events;
						this.playRecorder(); // Error is found start playRecorder
					})
					.catch((error) => {
						console.error("downloadURL error: ", error);
					});
			}
			else {
				this.selectedLog.noFile = true;
				this.selectedLog.loading = false;
				console.log("Log not found: ", this.selectedLog.noFile);
			}
		},

		playRecorder() {
			let vm = this;
			let plugins = null;

			if (this.showErrorInConsole) {
				plugins = [
					rrweb.getReplayConsolePlugin({
						level: ["warn", "error"],
						// replayLogger: (type, args) => {
						//   console.log(type, args);
						// }, // optional om de logs ergens anders aan te sturen
					}),
				]
			}

			// Calculate available width and height
			let width = document.getElementById('video-wrapper').clientWidth;
			let height = document.getElementById('video-wrapper').clientHeight - 80;

			this.player = new rrwebPlayer({
				target: document.getElementById("logPlayerThrive"),
				props: {
					events: vm.replayerEvents,
					autoPlay: false,
					width: width,
					height: height,
					maxScale: 1,
					plugins: plugins,
					tags: { thriveError: 'red' },
				},
			});
			this.selectedLog.loading = false
			//Load the custom events to the player with the tag - ThriveError via custom-events payload
			this.player.addEventListener("custom-event", (event) => {
				this.highlightError(event.data.payload.uniqIdError)
				// if(this.showErrorInConsole){
				// 	console.log("Sven:", event.data.payload);
				// }
				// console.log("error ID: " + event.data.payload.uniqIdError);
				// console.log("Where the error is Thrown: " + event.data.payload.errorElement);
			});
		},
		highlightError(errorId) {
			this.activeArrowInPlay = errorId;
			// setTimeout(() => {
			// 	this.activeArrowInPlay = null;
			// }, 2000);
		}
	},

	computed: {
		version() {
			return this.$store.state.version;
		},
		Companies() {
			return this.$store.state.userCompanies;
		},
		userLanguage() {
			return this.$store.state.locale ? this.$store.state.locale : "NL";
		},
		userCompanies() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
		},
		userCompany() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
		},
		userFirstName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
		},
		userLastName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
		},
		userRole() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
		},
		userId() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
		},
		theme() {
			return this.$vuetify.theme.dark ? "dark" : "light";
		},
		companyTheme() {
			if (this.$store.state.companyTheme) {
				return this.$store.state.companyTheme;
			} else {
				return "thrive";
			}
		},
		filteredCompanies() {
			if (this.searchCompanies && this.searchCompanies.length > 0) {
				return this.companies.filter((company) => {
					return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
				});
			} else {
				return this.companies;
			}
		},
	},
};
</script>
<style>
#video-wrapper {
	background-color: var(--background-color)
}

.rr-player {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	display: block;
	border-radius: 0;
	float: none;
	background-color: var(--background-color)
}

.rr-controller {
	border-top: 1px solid var(--separator-color) !important;
	background-color: var(--card-color) !important;
}

.rr-controller .rr-controller__btns button.active,
.rr-progress__handler {
	background-color: var(--primary-color) !important;
}

.rr-controller .rr-progress {
	border-top: 4px solid var(--card-color) !important;
	border-bottom: 4px solid var(--card-color) !important;
	background: var(--text-color) !important;
}

.rr-progress__step {
	background: var(--text-color) !important;
}

.video-log-column {
	border-left: 1px solid var(--separator-color);
}

.log-highlight {
	background-color: var(--primary-color) !important;
	/* box-shadow: 0px 0px 5px 0px var(--primary-color)!important; */
}

.log-highlight td,
.log-highlight span,
.log-highlight p {
	color: #ffffff
}
</style>

