<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>Nieuwe omvangsmeting</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeAddBodyMeasurementDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text>
      <v-form>
        <div class="input-content-wrapper padding-top space" v-bind:class="activeGender">

          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6>
                <v-menu
                  ref="dateDialog"
                  :close-on-content-click="false"
                  v-model="dateDialog"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  >
                  <template v-slot:activator="{on}">
                    <v-text-field
                      class="mt-4"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      v-on="on" 
                      prepend-inner-icon="mdi-calendar-today"
                      v-model="addedDateFormatted"
                      label="Datum meting"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="date" :max="max" :show-current="true" no-title @input="dateDialog = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md6 >
                <v-text-field
                  class="mt-4"
                  label="Tijdstip"
                  v-model="newMeasurement.time"
                  prepend-inner-icon="mdi-clock-outline"
                  type="time"
                  suffix="uur"
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
   
          <div class="body-measurement-item-wrapper clear">
            <div  class="body-measurement-icon upper-arm-left" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Bovenarm Links (midden)"
                v-model.number="newMeasurement.upperArmLeft"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>
          <div class="body-measurement-item-wrapper clear">
            <div  class="body-measurement-icon upper-arm-right" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Bovenarm Rechts (midden)"
                v-model.number="newMeasurement.upperArmRight"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon chest" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Borstomvang"
                v-model.number="newMeasurement.chest"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon upper-belly" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Buik boven navel"
                v-model.number="newMeasurement.upperBelly"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon lower-belly" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Buik onder navel"
                v-model.number="newMeasurement.lowerBelly"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div v-bind:class="activeGender" class="body-measurement-icon hip" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Heup"
                v-model.number="newMeasurement.hip"
                number
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon buttocks" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Billen"
                v-model.number="newMeasurement.buttocks"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon upperleg-left" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Bovenbeen Links (midden)"
                v-model.number="newMeasurement.upperLegLeft"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon upperleg-right" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Bovenbeen Rechts (midden)"
                v-model.number="newMeasurement.upperLegRight"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon calf-left" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Kuit Links (midden)"
                v-model.number="newMeasurement.calfLeft"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>
          
          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon calf-right" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Kuit Rechts (midden)"
                v-model.number="newMeasurement.calfRight"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon ankle-left" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Enkel Links"
                v-model.number="newMeasurement.ankleLeft"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>

          <div class="body-measurement-item-wrapper clear">
            <div class="body-measurement-icon ankle-left" ></div>
            <div class="body-measurement-item">
              <v-text-field
                label="Enkel Rechts"
                v-model.number="newMeasurement.ankleRight"
                type="number"
                suffix="cm"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
              ></v-text-field>
            </div>
          </div>
        </div> 
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left">
            <v-btn
              outlined
              depressed
               :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
              @click="closeAddBodyMeasurementDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              dark
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              @click="addMeasurement()"
              >
              Toevoegen
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>


<script>
import db from "../../firebase/init";
import moment from "moment";
import { bus } from "../../main";
// import _ from "lodash";

export default {
  name: "add-body-measurement-form",
  props: { 
    clientInfo: Object,
    activeCompData: Object,
  },
  data() {
    return {
      date: null,
      loading: true,
      newMeasurement: {
        time: null,
        upperArmLeft: null,
        upperArmRight: null,
        chest: null,
        upperBelly: null,
        lowerBelly: null,
        hip: null,
        buttocks: null,
        upperLegLeft: null,
        upperLegRight: null,
        calfLeft: null,
        calfRight: null,
        ankleLeft: null,
        ankleRight: null
      },
      feedback: null,
      dateDialog: false
    };
  },

  created() {
    this.date = moment().format("YYYY-MM-DD");
    this.max = moment().format("YYYY-MM-DD");
    this.getCurrentTime();
    bus.$on("setCurrentTime",()=>{
      this.getCurrentTime();
    })
  },

  methods: {
    getCurrentTime(){
      this.newMeasurement.time = moment().format("HH:mm");
    },

    closeAddBodyMeasurementDialog() {
      this.$emit("closeAddBodyMeasurementDialogChange", false);
      this.newMeasurement = {
        time: null,
        upperArmLeft: null,
        upperArmRight: null,
        chest: null,
        upperBelly: null,
        lowerBelly: null,
        hip: null,
        buttocks: null,
        upperLegLeft: null,
        upperLegRight: null,
        calfLeft: null,
        calfRight: null,
        ankleLeft: null,
        ankleRight: null
      };
    },

    // Datum omvormen naar Europese leesbare datum
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    // Voeg Omvangsmeting toe aan database en log deze actie. Ook eventuele errors worden gelogd
    addMeasurement() {
      this.feedback = null;
      db.collection("measurements")
        .add({
          companyId: this.activeCompData.id,
          clientId: this.clientInfo.id,
          dateAdded: new Date(this.firebaseDateFormatted),
          time: this.newMeasurement.time,
          upperArmLeft: this.newMeasurement.upperArmLeft,
          upperArmRight: this.newMeasurement.upperArmRight,
          chest: this.newMeasurement.chest,
          upperBelly: this.newMeasurement.upperBelly,
          lowerBelly: this.newMeasurement.lowerBelly,
          hip: this.newMeasurement.hip,
          buttocks: this.newMeasurement.buttocks,
          upperLegLeft: this.newMeasurement.upperLegLeft,
          upperLegRight: this.newMeasurement.upperLegRight,
          calfLeft: this.newMeasurement.calfLeft,
          calfRight: this.newMeasurement.calfRight,
          ankleLeft: this.newMeasurement.ankleLeft,
          ankleRight: this.newMeasurement.ankleRight
        })
        .then(() => {
          this.$emit("updateData");
          this.closeAddBodyMeasurementDialog()
          console.log("Body Measurement Added Successfull");
        })
        .catch(error => {
          this.feedback = "Opslaan is niet gelukt. Probeer opnieuw";
          console.error("Error adding skinfold measurement: ", error);
        });
    }
  },
  computed: {
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    // Kijken naar het geslacht van de geselecteerde klant. Op basis hiervan worden de iconen voor man of vrouw weergegeven
    activeGender() {
      return this.clientInfo.gender == 1 ? "man" : "woman";
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },
    addedDateFormatted() {
      return this.formatDate(this.date);  // Datum omvormen naar Europese leesbare datum
    },
    firebaseDateFormatted() {
      return new Date(this.date);
    }
  }
};
</script>

<style>
  .choose-measurement-type-wrapper {
  padding: 5px 15px 20px 15px;
}

.choose-measurement-type-buttons {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.measurement-type-button {
  width: calc(50% - 1px);
  float: left;
  height: 32px;
  text-align: center;
  color:  var(--primary-color);
  font-size: 13px;
  line-height: 32px;
  background-color: var(--card-color);
  border-bottom: 1px solid  var(--primary-color);
  border-top: 1px solid  var(--primary-color);
  cursor: pointer;
}

.measurement-type-button:hover{
  cursor: pointer;
}

.measurement-type-button.active {
  background-color:  var(--primary-color);
  color: var(--card-color);
}

.measurement-type-button.one {
  border-left: 1px solid  var(--primary-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.measurement-type-button.two {
  border-right: 1px solid  var(--primary-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.custom-measurement-label {
  font-size: 12px;
}
.measurement-input-field-one {
  width: calc(50% - 10px);
  margin: 0 20px 0 0;
  float: left;
}

.measurement-input-field-two {
  width: calc(50% - 10px);
  float: left;
}

.body-measurement-item.no-label .v-text-field {
  margin: 0;
  padding: 0;
}

.body-measurements-wrapper {
  position: relative;
  padding: 10px;
  background-color: var(--card-color);
}

.body-measurement-icon {
  width: 56px;
  height: 56px;
  background-color: var(--card-color);
  margin: 0 10px 0 0;
  float: left;
}

.body-measurement-item {
  width: calc(100% - 66px);
  float: left;
}

.skinfold-progress-wrapper {
  width: 100%;
}

.skinfold-progress-bar {
  width: 100%;
  height: 12px;
  background-color: var(--cardOption-color);
  margin: 10px 0;
  border-radius: 20px;
  overflow: hidden;
}


/* BODY MEASUREMENT ICONS */

.input-content-wrapper.man .body-measurement-icon.upper-arm-left {
  background: url("../../assets/body-icons/upper-arm-left-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.man .body-measurement-icon.upper-arm-right {
  background: url("../../assets/body-icons/upper-arm-right-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.man .body-measurement-icon.chest {
  background: url("../../assets/body-icons/chest-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.man .body-measurement-icon.upper-belly {
  background: url("../../assets/body-icons/upper-belly-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.man .body-measurement-icon.lower-belly {
  background: url("../../assets/body-icons/lower-belly-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.man .body-measurement-icon.hip {
  background: url("../../assets/body-icons/hip-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.man .body-measurement-icon.buttocks {
  background: url("../../assets/body-icons/buttocks-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.man .body-measurement-icon.upperleg-left {
  background: url("../../assets/body-icons/upper-leg-left-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.man .body-measurement-icon.upperleg-right {
  background: url("../../assets/body-icons/upper-leg-right-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.calf-left,
.input-content-wrapper.man .body-measurement-icon.calf-left {
  background: url("../../assets/body-icons/calf-left-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.calf-right,
.input-content-wrapper.man .body-measurement-icon.calf-right {
  background: url("../../assets/body-icons/calf-right-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.ankle-left,
.input-content-wrapper.man .body-measurement-icon.ankle-left {
  background: url("../../assets/body-icons/ankle-left-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.ankle-right,
.input-content-wrapper.man .body-measurement-icon.ankle-right {
  background: url("../../assets/body-icons/ankle-right-male.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.upper-arm-left {
  background: url("../../assets/body-icons/upper-arm-left-female.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.upper-arm-right {
  background: url("../../assets/body-icons/upper-arm-right-female.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.chest {
  background: url("../../assets/body-icons/chest-female.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.upper-belly {
  background: url("../../assets/body-icons/upper-belly-female.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.lower-belly {
  background: url("../../assets/body-icons/lower-belly-female.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.hip {
  background: url("../../assets/body-icons/hip-female.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.buttocks {
  background: url("../../assets/body-icons/buttocks-female.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.upperleg-left {
  background: url("../../assets/body-icons/upper-leg-right-female.png") no-repeat center center;
  background-size: 56px;
}

.input-content-wrapper.woman .body-measurement-icon.upperleg-right {
  background: url("../../assets/body-icons/upper-leg-right-female.png") no-repeat center center;
  background-size: 56px;
}
</style>
