<template>
  <div>
    <div>
      <div v-if="loading">
        <div style="position: relative; padding: 200px 0;">
          <div class="thrive-loader"></div>
        </div>
      </div>
      <div class="no-data-message" v-if="loading == false && memberships.length == 0">
        <p> {{ $t('memberships.noMembershipsFoundFor', $store.state.locale) }} {{ activeCompData.name }}.</p>
      </div>

      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%" v-if="!loading && memberships.length > 0 && overview">
        <v-container grid-list-md class="px-3 pa-0 mt-4 pb-3" fluid>
          <v-layout row wrap>
            <v-flex md6 xs12>
              <v-text-field
                class="mx-1"
                v-model="membershipSearch"
                dense
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                name="searchfield"
                :label="$t('general.search', $store.state.locale)"
                prepend-inner-icon="mdi-magnify"
                single-line
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex md6 xs12>
              <div class="text-right">
                <v-btn
                  class="mx-1" 
                  dark
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  @click="newMembershipDialog()">
                  <v-icon left>mdi-plus</v-icon>{{ $t('memberships.subscribeClient', $store.state.locale) }}
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="memberships"
          :no-data-text="$t('memberships.noPacakgesFound', $store.state.locale)"
          :items-per-page="-1"
          :width="1"
          hide-default-footer
          :search="membershipSearch"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          dense
          class="dense-table"
          >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.clientName }}</td>
              <td>
                <span v-if="item.name[($store.state.locale).toLowerCase()]">
                  {{ item.name[($store.state.locale).toLowerCase()] }}
                </span>
                <span v-else>
                  {{ item.name }}
                </span>
              </td>
              <td>{{ item.start | moment("D MMMM YYYY")}}</td>
              <td>{{ item.expireDate }}</td>
              <td><v-icon class="px-2" :color="activeColor(item.status.status)" small>mdi-checkbox-blank-circle</v-icon></td>
                <td>
                <v-btn style="float: right;" icon class="mx-0" @click="arrowClick(item)">
                  <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-chevron-right</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <div v-if="!overview && selectedMembership.id" class="pa-4">
        <v-container grid-list-md class="pa-0 ma-0" fluid>
          <v-layout row wrap>
            <v-flex xs6 class="pb-4">
              <v-btn
                fab dark small
                style="float: left;"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                depressed
                @click="backToMemberships()"
                >
                <v-icon darken>mdi-chevron-left</v-icon>
              </v-btn>
              <h3 style="float: left; padding: 5px 0 0 20px;" @click="openCustomerDialog(selectedMembership.clientId)">{{selectedMembership.clientName}}</h3><v-icon @click="openCustomerDialog(selectedMembership.clientId)" style="margin:7px 0 0 5px; padding: 5px" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-open-in-new</v-icon>
            </v-flex>
            <v-flex xs6 class="pb-4 text-right">
              <v-btn
                fab
                dark
                small
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                depressed
                @click="backToMemberships()"
                >
                <v-icon small darken>mdi-pencil</v-icon>
              </v-btn>
            </v-flex>

            <v-flex md12>
              <membership-information :activeCompData="activeCompData" :selectedMembership="selectedMembership"></membership-information> 
            </v-flex>
          </v-layout>
        </v-container>
            
      </div>
    </div>

    <!-- / New Subscription Dialog \ -->
    <AddSubscriptionModal :membershipPackages="membershipPackages" @subscriptionAdded="getMemberships" ></AddSubscriptionModal>
    <!-- \ New Subscription Dialog / -->

    <!-- / Customer info dialog \ -->
    <customer-information-dialog
      :client="selectedClient"
      :customerDialog="customerDialog"
      :selectedClientOriginal="selectedClientOriginal"
      :selectedClient="selectedClient"
      :activeCompData="activeCompData"
      @customerDialogChange="customerDialog = $event; wolfload = false;"
    ></customer-information-dialog>
    <!-- \ Customer info dialog / -->

  </div>
</template>

<script>
  import '@firebase/firestore';
  import db from "../firebase/init";
  import CustomerInformationDialog from "../components/modals/CustomerInformationDialog.vue";
  import NewSubscription from "../views/Subscription.vue";
  import AddSubscriptionModal from "@/components/modals/AddSubscriptionModal.vue";
  import Moment from "moment";
  import { extendMoment } from "moment-range";
  import { bus } from "./../main";
  import algoliasearch from 'algoliasearch/lite';
  import 'instantsearch.css/themes/satellite-min.css';
  import membershipInformation from "./membershipInformation.vue"

  const moment = extendMoment(Moment);

  export default {
    name: "Memberships",
    components: {
      "new-subscription": NewSubscription,
      "customer-information-dialog": CustomerInformationDialog,
      "membership-information" : membershipInformation,
      AddSubscriptionModal
    },

    props:{
      activeCompData: Object
    },

    data(){
      return{
        searchClient: algoliasearch('B4XU2Z5BAW', 'a29d0e9160e36dc8ee83d5888c28cdcc'),
        overview: true,
        memberships:[],
        selectedMembership: null,
        membershipsPerCompany:[],
        loading: true,
        search: null,
        membershipSearch: null,
        clientsFetched:false,
        newSubscription: {
          dialog: false,
          feedback: null,
          startDate: null,
          subscription: null,
          newclient: false,
        },
        selectedClient: new Object(),
        selectedClientOriginal: new Object(),
        customerDialog: false,
        genderSelect: 0,
        menuStartDate: false,
        valid: false,
        packagesLoading: true,
        membershipPackages: new Array(),
        clientData: {
          firstName: "",
          firstNameRules: [(v) => !!v || this.$t('booking.firstnameRequired', this.$store.state.locale)],
          surname: "",
          surnameRules: [(v) => !!v || this.$t('booking.lastnameRequired', this.$store.state.locale)],
          email: "",
          id: null,
          phone: "",
          emailRules: [(v) => !!v || this.$t('booking.emailRequired', this.$store.state.locale), (v) => /.+@.+/.test(v) || this.$t('booking.emailInvalid', this.$store.state.locale)],
        },
        membershipStartMinDate: null,
        headers:[
          { 
            text: this.$t('memberships.client', this.$store.state.locale),
            value: 'clientName'
          },
          { 
            text: this.$t('memberships.plan', this.$store.state.locale),
            value: 'ClientId'
          },
          { 
            text: this.$t('general.startDate', this.$store.state.locale),
            value: 'start'
          },
          { 
            text: this.$t('memberships.expiresOn', this.$store.state.locale),
            value: 'expireDate'
          },
          { 
            text: this.$t('general.status', this.$store.state.locale),
            value: 'status.status'
          },
          { 
            text: '', 
            value: 'edit'
          }
        ],
        price: {
          bruto: 0,
          netto: 0,
          periodicPaymentDiscount: {
            periodic: {
              bruto: 0,
              netto: 0,
            },
            total: {
              bruto: 0,
              netto: 0,
            }
          },
          singlePaymentDiscount: {
            bruto: 0,
            netto: 0,
          },
          periodicPaymentBruto: 0,
          periodicPaymentNetto: 0,
          singlePaymentBruto: 0,
          singlePaymentNetto: 0,
        },
        trialTypes:[
          {
            id:'general',
            name:'Algemeen'
          },
          {
            id:'individual',
            name:'Instellen per cliënt'
          }
        ],
        vatItems: new Array(),
        treatments: new Array(),
        discounts: new Array(),
        treatmentTypesLoading: true,
        discountsLoading: true,
        vatItemsLoading: true,
      }
    },

    created(){
      // bus.$on("companyUpdate", this.getMemberships);
      

    
      this.loadData();
    },

    methods:{
      async loadData(){
        await Promise.all([this.getTreatments(), this.getVat(), this.loadDiscounts(), this.getMemberships(), this.getSubscriptionPackage()]);
      },

      async getMemberships(){ // Load memberships from DB
        console.log("getMemberships()")
        this.memberships = new Array();
        this.loading = true;
        this.overview = true;
        this.selectedMembership = null;

        await db.collection('memberships')
        .where("companyId","==",this.$store.state.activeCompany.id)
        .get()
        .then(querySnapshot=>{
          querySnapshot.forEach(doc=>{
            let item = doc.data();
            item.id = doc.id;
            item.start = moment(item.startDate).format("YYYY-MM-DD");
            item.expireDate = item.expireDate ? item.expireDate : "Niet gespecificeerd";
            this.memberships.push(item);
          })
        })
        .catch(err=>{
          console.error(err);
        })

        // Client Naam opzoeken met Algolia
        if(this.memberships.length > 0){

          await new Promise(resolve=>{
            let counter = 0;
            this.memberships.forEach(membership=>{
              db
              .collection('clients')
              .doc(membership.clientId)
              .get()
              .then(doc=>{
                let client = doc.data();
                membership.clientName = client.name + " " + client.surname;
                counter++
                if(counter == this.memberships.length){
                  resolve()
                }
              })
              .catch(error=>{
                console.error("error while fetching client...", error)
              })
            })
          })
        }
        this.loading = false;
        return 
      },

      backToMemberships(){
        this.overview = true;
        this.selectedMembership = null;
      },

      newMembershipDialog(){
        bus.$emit("newSubscriptionModal")
        // this.newSubscription.feedback = null;
        // this.newSubscription.subscription = null;
        // this.newSubscription.startDate = _.cloneDeep(this.membershipStartMinDate);
        // this.getSubscriptionPackage();
        // this.newSubscription.dialog = true;
      },

      addNewSubscription(){
        this.newSubscriptionDialog = true;
      },

      openCustomerDialog(clientId) {
        db.collection("clients")
        .doc(clientId)
        .get()
        .then((doc) => {
          this.selectedClient = doc.data();
          this.selectedClient.id = doc.id;
          this.selectedClientOriginal = _.cloneDeep(this.selectedClient);
          this.customerDialog = true;
        })
        .catch((error) => {
          console.error("error while fechting client: ", error);
        });
      },

      usageColor(usage){
        if(usage.override){
          return 'secondary';
        }
        else{
          return 'primary';
        }
      },

      getDiscountName(discount){
        let discountItem =  discount && this.discounts.find(({ id }) => id === discount) ? this.discounts.find( ({ id }) => id === discount ): null;
        if(discountItem){
          return {
            name : discountItem.name,
            color: 'tertiary'
          }
        }
        else{
          return {
            name : 'Niet gevonden',
            color: 'secondary'
          }
        }
      },

      getPeriodName(paymentPeriod){
        let paymentPeriods = {
          daily: {nl: 'dag', en: 'a day'},
          everyWeek: {nl: 'per week', en: ' week'},
          everyFourWeeks: {nl: 'per vier weken', en: 'four weeks'},
          everyMonth: {nl: 'maand', en: 'month'},
          everyQuarter: {nl: 'kwartaal', en: 'quarter'},
          everyHalfYear: {nl: 'half jaar', en: 'half year'},
          everyYear: {nl: 'jaarlijk', en: 'year'},
        }    
        return paymentPeriods[paymentPeriod] ? paymentPeriods[paymentPeriod][(this.$store.state.locale).toLowerCase()] : null;
      },

      activeColor(active){
        return active ? this.$themes[this.companyTheme][this.theme].quaternary : this.$themes[this.companyTheme][this.theme].secondary; 
      },

      arrowClick(membership){
        this.overview = false;
        this.selectedMembership = membership;
        this.calculateBrutoPrice();
      },

      async addNewMembership(){
        let subscriptionData = null;
        let index = _.findIndex(this.membershipPackages, {"id": this.newSubscription.subscription})
        
        if(index >= 0){
          subscriptionData = this.membershipPackages[index];
      
          if(this.newSubscription.newclient){
            await this.createClient();
          }

          db.collection("memberships")
          .add({
            created: new Date(),
            companyId: this.$store.state.activeCompany.id,
            clientId: this.clientData.id,
            startDate: this.membershipStartDate.formatted,
            subscription: this.newSubscription.subscription,
            freePeriod: subscriptionData.freePeriod,
            treatments: subscriptionData.treatments,
            name: subscriptionData.name,
            paymentDay: subscriptionData.paymentDay,
            duration: subscriptionData.duration,
            paymentPeriod: subscriptionData.paymentPeriod,
            paymentReminder: subscriptionData.paymentReminder,
            singlePaymentDiscount: subscriptionData.singlePaymentDiscount,
            trialPeriod: subscriptionData.trialPeriod,
            vat: subscriptionData.vat.id,
            terms: subscriptionData.terms,
            periodicPaymentDiscount: subscriptionData.periodicPaymentDiscount,
            registrationDescription: subscriptionData.registrationDescription,
            registrationUrl: subscriptionData.registrationUrl,
            sendInvoice: subscriptionData.sendInvoice,
            usage: new Array(),
            sepaAttempts: subscriptionData.sepaAttempts,
            amount: subscriptionData.amount,
            separatedDiscount: subscriptionData.separatedDiscount,
            branches: subscriptionData.branches,
            days: subscriptionData.days,
            status: {
              endDate: null,
              status: "active",
              type: "doorlopend"
            }
          })
          .then(()=>{
            this.getMemberships();
            this.newSubscription.dialog = false;
          })
        }

      },

      async createClient() {
          console.log("Client Check");
          const configAxiosPOST = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          let jsonOBJ = {
            companyId: this.$store.state.activeCompany.id,
            clientEmail: this.clientData.email,
            clientFirstName: this.clientData.firstName,
            clientLastName: this.clientData.surname,
            clientPhone: this.clientData.phone ? this.clientData.phone : "",
          };

          // console.log("jsonOBJ: ", jsonOBJ);

          await new Promise((resolve) => {
            axios
              .post(`${this.$store.state.restApi.url}/postClientExist`, jsonOBJ, configAxiosPOST)
              .then((response) => {
                console.log(response.data);
                // Client does not exist
                if (response.data.typeExist == 2) {
                  // Create Client
                  let companies = [this.$store.state.activeCompany.id];
                  db.collection("clients")
                    .add({
                      name: this.clientData.firstName,
                      surname: this.clientData.surname,
                      email: this.clientData.email,
                      phone: this.clientData.phone,
                      companies: companies,
                      gender: this.genderSelect,
                      emailSubscriptions: {
                        newsletter: false,
                      },
                      health: {
                        macronutrients: {},
                      },
                      address: {},
                      permissions: {},
                    })
                    .then((docRef) => {
                      this.clientData.id = docRef.id;
                      console.log("Client check finished");
                      resolve();
                      return;
                    });
                }

                // Client bestaat al, maar nog niet bij dit bedrijf
                else if (response.data.typeExist == 1 && response.data.companySet == false) {
                  // Set company in client doc
                  let companies = null;
                  db.collection("clients")
                    .doc(response.data.clientId)
                    .get()
                    .then((doc) => {
                      companies = doc.data().companies;
                      companies.push(this.$store.state.activeCompany.id);
                    })
                    .then(() => {
                      db.collection("clients").doc(response.data.clientId).set(
                        {
                          companies: companies,
                        },
                        { merge: true }
                      );
                    })
                    .then(() => {
                      this.clientData.id = response.data.clientId;
                      console.log("Client check finished");
                      resolve();
                      return;
                    });
                }

                // Client bestaat al, ook bij dit bedrijf
                else if (response.data.typeExist == 0 && response.data.companySet == true) {
                  // Set client Id
                  this.clientData.id = response.data.clientId;
                  console.log("Client check finished");
                  resolve();
                  return;
                }
              })
              .catch((err) => {
                console.error("Error axios postClientExist =>", err);
                resolve();
                return;
              });
          });
        },

      getGender(gender){
        if(gender == 1){
          return "Man"
        }
        else if(gender == 2){
          return "Vrouw"
        }
        else{
          return "Onbekend"
        }
      },

      selectClient(selectedClient) {
        this.clientData.firstName = selectedClient.name;
        this.clientData.surname = selectedClient.surname;
        this.clientData.email = selectedClient.email;
        this.clientData.id = selectedClient.objectID;
      },

      getVatPercentage(vat){
        let vatPercentage =  vat && this.vatItems.find(({ id }) => id === vat) ? this.vatItems.find( ({ id }) => id === vat ): null;
        if(vatPercentage && vatPercentage.percentage){
          return vatPercentage.percentage
        }
        else{
          return " - "
        }

      },

      getSubscriptionPackage(){
        this.packagesLoading = true;
        db.collection('membershipPackages')
          .where("companyId","==",this.$store.state.activeCompany.id)
          .where("deleted","==",false)
          .get()
          .then(querySnapshot=>{
            querySnapshot.forEach(doc=>{
              let packageItem = doc.data()
              packageItem.id = doc.id,
              this.membershipPackages.push(packageItem);
            })
          })
          .then(()=>{
            this.packagesLoading = false;
          })
      },

      calculateBrutoPrice(){
        let vatPercentage =  this.selectedMembership.vat && this.vatItems.find(({ id }) => id === this.selectedMembership.vat) ? this.vatItems.find( ({ id }) => id === this.selectedMembership.vat ): null;

        if(vatPercentage && vatPercentage.percentage){
            let extraVat = (vatPercentage.percentage * this.selectedMembership.amount) / 100;
            this.selectedMembership.totalPrice = Number((extraVat + this.selectedMembership.amount).toFixed(2));
        }
        this.calculateTotalPrice() 
      },

      calculateTotalPrice(){

        let vatPercentage =  this.selectedMembership.vat && this.vatItems.find(({ id }) => id === this.selectedMembership.vat) ? this.vatItems.find( ({ id }) => id === this.selectedMembership.vat ): null;

        this.price.bruto = (this.selectedMembership.duration * this.selectedMembership.totalPrice).toFixed(2);
        this.price.netto = (this.selectedMembership.duration * this.selectedMembership.amount).toFixed(2);
        
        
        if(this.selectedMembership.periodicPaymentDiscount.active && this.selectedMembership.periodicPaymentDiscount.number > 0){
          if(this.selectedMembership.periodicPaymentDiscount.type == 'percentage'){
            this.price.periodicPaymentDiscount.periodic.bruto = ((this.selectedMembership.amount) /100 * this.selectedMembership.periodicPaymentDiscount.number).toFixed(2)
            if(this.price.periodicPaymentDiscount.periodic.bruto > 0){
              this.price.periodicPaymentDiscount.periodic.netto =  (this.price.periodicPaymentDiscount.periodic.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2)
              this.price.periodicPaymentDiscount.total.bruto = (this.price.periodicPaymentDiscount.periodic.bruto * this.selectedMembership.duration).toFixed(2)
              this.price.periodicPaymentDiscount.total.netto = (this.price.periodicPaymentDiscount.total.netto * this.selectedMembership.duration).toFixed(2)
            }
            else{
              this.price.periodicPaymentDiscount.periodic.bruto = 0;
              this.price.periodicPaymentDiscount.periodic.netto = 0;
              this.price.periodicPaymentDiscount.total.bruto = 0;
              this.price.periodicPaymentDiscount.total.netto = 0;
            }
          }
          else{
            this.price.periodicPaymentDiscount.periodic.bruto = (this.selectedMembership.periodicPaymentDiscount.number).toFixed(2)
            if(this.price.periodicPaymentDiscount.periodic.bruto > 0){
              this.price.periodicPaymentDiscount.periodic.netto = (this.price.periodicPaymentDiscount.periodic.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2)
              this.price.periodicPaymentDiscount.total.bruto = (this.price.periodicPaymentDiscount.periodic.bruto * this.selectedMembership.duration).toFixed(2)
              this.price.periodicPaymentDiscount.total.netto = (this.price.periodicPaymentDiscount.total.netto * this.selectedMembership.duration).toFixed(2)
            }
            else{
              this.price.periodicPaymentDiscount.periodic.bruto = 0;
              this.price.periodicPaymentDiscount.periodic.netto = 0;
              this.price.periodicPaymentDiscount.total.bruto = 0;
              this.price.periodicPaymentDiscount.total.netto = 0;
            }
          }
          this.price.periodicPaymentBruto = (this.price.bruto - this.price.periodicPaymentDiscount.total.bruto).toFixed(2);
          this.price.periodicPaymentNetto = Number((this.price.netto - this.price.periodicPaymentDiscount.total.netto).toFixed(2));
        }
        else{
          this.price.periodicPaymentBruto = this.price.bruto;
          this.price.periodicPaymentNetto = this.price.netto;
          this.price.periodicPaymentDiscount.total.netto = 0;
          this.price.periodicPaymentDiscount.total.bruto = 0;
          this.price.periodicPaymentDiscount.periodic.netto = 0;
          this.price.periodicPaymentDiscount.periodic.bruto = 0;
        }

        if(this.selectedMembership.singlePaymentDiscount.active && this.selectedMembership.singlePaymentDiscount.number > 0){
          if(this.selectedMembership.singlePaymentDiscount.type == 'percentage'){
            this.price.singlePaymentDiscount.bruto = ((this.selectedMembership.totalPrice * this.selectedMembership.duration) /100 * this.selectedMembership.singlePaymentDiscount.number).toFixed(2)
            if(this.price.singlePaymentDiscount.bruto > 0){
              this.price.singlePaymentDiscount.netto =  Number((this.price.singlePaymentDiscount.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2));
            }
            else{
              this.price.singlePaymentDiscount.bruto = 0;
              this.price.singlePaymentDiscount.netto = 0;
            }
          }
          else{
            this.price.singlePaymentDiscount.bruto = (this.selectedMembership.singlePaymentDiscount.number).toFixed(2)
            if(this.price.singlePaymentDiscount.bruto > 0){
              this.price.singlePaymentDiscount.netto =  Number((this.price.singlePaymentDiscount.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2));
            }
            else{
              this.price.singlePaymentDiscount.bruto = 0;
              this.price.singlePaymentDiscount.netto = 0;
            }
          }
          this.price.singlePaymentBruto = (this.price.bruto - this.price.singlePaymentDiscount.bruto).toFixed(2);
          this.price.singlePaymentNetto = Number((this.price.netto - this.price.singlePaymentDiscount.netto).toFixed(2));
        }
        else{
          this.price.singlePaymentBruto = this.price.bruto;
          this.price.singlePaymentNetto = this.price.netto;
          this.price.singlePaymentDiscount.bruto = 0;
          this.price.singlePaymentDiscount.netto = 0;
        }
      },

      getCategories(){ // Load categories from DB and fetch it into select element
        db.collection('membershipCategories')
        .get()
        .then(querySnapshot=>{
            querySnapshot.forEach(doc=>{
                this.categories.push({
                  id:doc.id,
                  name: doc.data().name
                });
            })
        })
        .then(()=>{
            this.categories_Loading = false;
        })
        .catch(err=>{
            consle.error(err);
        })
      },
    
      async getTreatments(){ // Load treatments from DB and fetch it into select element
        this.treatments = new Array();
        await db.collection('treatments')
        .where("companyId","==", this.$store.state.activeCompany.id)
        .get()
        .then(snap=>{
          snap.forEach(doc=>{
            this.treatments.push({
              id: doc.id,
              title: doc.data().treatmentTitle
            });
          })
        })
        .catch(err=>{
          console.error("Error getting treatments", err);
        })
        this.treatmentTypesLoading = false;
        return
      },

      async getVat(){ //Load BTW from DB and fetch it into select element
        this.vatItems = new Array();
        await db.collection('vat').get()
        .then(snap=>{
          snap.forEach(doc=>{
            this.vatItems.push({
              id:doc.id,
              percentage: doc.data().percentage
            });
          })
        })
        .catch(err=>{
          console.error("Error getting vat items", err);
        })
        this.vatItemsLoading = false;
        return
      },

      async loadDiscounts(){
        this.discounts = new Array();
        await db.collection('modifiers')
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("active", "==", true)
        .get()
        .then(snap=>{
          snap.forEach(doc=>{
            let discount = doc.data();
            discount.id = doc.id;
            this.discounts.push(discount);
          })
        })
        .catch(err=>{
          console.error("Error getting discounts", err);
        })
        this.discountsLoading = false;   
        return  
      }
    },

    computed: {
      membershipValid(){
        if(this.newSubscription.startDate && this.newSubscription.subscription  && ((!this.newSubscription.newclient && this.clientData.id) || (this.newSubscription.newclient && this.clientData.firstName && this.clientData.surname && this.clientData.email))){
          return true;
        }
        else{
          return false;
        }
      },

      membershipStartDate() {
        let date = {
          readable: null,
          formatted: "",
          firebase: null,
        };
        if (this.newSubscription.startDate) {
          date.readable = moment(this.newSubscription.startDate).format("D MMMM YYYY");
          date.formatted = moment(this.newSubscription.startDate).format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },

      paymentPeriode(){
        if(this.selectedMembership.paymentPeriod == 'daily'){
          return "dag"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyWeek'){
          return "week"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyFourWeeks'){
          return "vierweken"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyMonth'){
          return "maand"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyQuarter'){
          return "kwartaal"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyHalfYear'){
          return "halfjaar"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyYear'){
          return "jaar"
        }
      },

      durationSuffix() {
        if(this.selectedMembership.paymentPeriod == 'daily'){
          if(this.selectedMembership.duration == 1){
            return "dag"
          }
          else{
            return "dagen"
          }
        }

        else if(this.selectedMembership.paymentPeriod == 'everyWeek'){
          if(this.selectedMembership.duration == 1){
            return "week"
          }
          else{
            return "weken"
          }
        }

        else if(this.selectedMembership.paymentPeriod == 'everyFourWeeks'){
          return "vierweken"
        }

        else if(this.selectedMembership.paymentPeriod == 'everyMonth'){
          if(this.selectedMembership.duration == 1){
            return "maand"
          }
          else{
            return "maanden"
          }
        }
        else if(this.selectedMembership.paymentPeriod == 'everyQuarter'){
          if(this.selectedMembership.duration == 1){
            return "kwartaal"
          }
          else{
            return "kwartalen"
          }
        }
        else if(this.selectedMembership.paymentPeriod == 'everyHalfYear'){
          if(this.selectedMembership.duration == 1){
            return "halfjaar"
          }
          else{
            return "halfjaren"
          }
        }
        else if(this.selectedMembership.paymentPeriod == 'everyYear'){
          if(this.selectedMembership.duration == 1){
            return "jaar"
          }
          else{
            return "jaren"
          }
        }
        else{
          return `${this.selectedMembership.paymentPeriod}`;
        }
      },

      singleDiscountInfo(){
        if(this.selectedMembership.singlePaymentDiscount.type == "amount"){
          return {
            prefix: "€",
            suffix: null,
            label: "Kortingsbedrag",
            mask: "####"
          }
        }
        else {
          return {
            prefix: null,
            suffix: "%",
            label: "Kortingspercentage",
            mask: "##"
          }
        }
      },
      periodicDiscountInfo(){
        if(this.selectedMembership.singlePaymentDiscount.type == "amount"){
          return {
            prefix: "€",
            suffix: null,
            label: "Kortingsbedrag",
            mask: "####"
          }
        } 
        else {
          return {
            prefix: null,
            suffix: "%",
            label: "Kortingspercentage",
            mask: "##"
          }
        }
      },

      userLanguage(){
        return this.$store.state.locale ? this.$store.state.locale : 'NL';
      },
      
      version() {
        return this.$store.state.version;
      },
      userCompanies() {
        return this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.userRole;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
        companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
    }
  };
</script>
