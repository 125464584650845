<template>
  <v-card>
    <v-card-title class="pr-2" :style=" { backgroundColor: $vuetify.theme.themes[theme].tertiary }">
      <h2 class="white--text">Pakketten toevoegen</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeUpgradeDialog()">
        <v-icon dark color="white">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    
    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text class="pa-0">
      <div>
        <div v-if="packages" class="upgrade-packages-wrapper">
          <h2>Selecteer pakketten</h2>

          <div v-for="(thrivePackage, index) in packages" :key="index">
            <!-- Pakketten die beschikbaar zijn -->
            <div
              class="upgrade-package-wrapper"
              v-if="thrivePackage.active == true && !thrivePackage.alreadyPurchased"
              :class="[{ active : packages[index].added},{ collapsed : packages[index].collapsed}]"
            >
              <div class="clear">
                <div class="package-check" @click="addPackage(index)"></div>
                <div class="package-text">
                  <h4>{{thrivePackage.name}}</h4>
                  <p>
                    <span
                      v-if="thrivePackage.action.active == true && thrivePackage.alreadyPurchased == false && thrivePackage.action.startDate.seconds <= today && thrivePackage.action.endDate.seconds > today"
                    >
                      <span
                        class="new-price"
                      >&euro;{{thrivePackage.action.priceNetto.toFixed(2) }}</span>
                      <span class="old-price">&euro;{{thrivePackage.priceNetto.toFixed(2) }}</span> / mnd
                    </span>
                    <span v-else>&euro;{{thrivePackage.priceNetto.toFixed(2) }} / mnd</span>
                  </p>
                  <p v-if="thrivePackage.activationFee" class="package-startcosts"><span class="label">Eenmalige kosten:</span> &euro;{{ thrivePackage.activationFee.toFixed(2) }}</p>
                </div>
                <div class="package-arrow" @click="collapsePackage(index)"></div>
                <div
                  v-if="groupDiscountEnabled && combinationdiscount > 0"
                  class="payment-discount"
                >
                  <div class="payment-discount-bubble">-{{combinationdiscount}}%</div>
                </div>
              </div>
              <div class="package-extra">
                <p>Dit pakket bevat:</p>
                <ul>
                  <li
                    v-for="(functionality, index) in functionalities"
                    :key="index"
                    v-if="functionality.package == thrivePackage.id"
                  >
                    <span>{{ functionality.title }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <!-- Pakketten die al afgesloten zijn -->
            <div
              class="upgrade-package-wrapper already-purchased active"
              v-if="thrivePackage.alreadyPurchased"
              :class="[{ collapsed : packages[index].collapsed}]"
            >
              <div class="clear">
                <div class="package-check"></div>
                <div class="package-text">
                  <h4>
                    {{thrivePackage.name}}
                    <span class="already-purchased-label">Actief pakket</span>
                  </h4>
                  <p>
                    <span v-if="thrivePackage.price != thrivePackage.priceOriginal">
                      <span class="new-price">&euro;{{thrivePackage.price.toFixed(2) }}</span>
                      <span class="old-price">&euro;{{thrivePackage.priceOriginal.toFixed(2) }}</span> / mnd
                    </span>
                    <span v-else>&euro;{{thrivePackage.priceOriginal.toFixed(2) }} / mnd</span>
                  </p>
                </div>
                <div class="package-arrow" @click="collapsePackage(index)"></div>
                <div
                  v-if="groupDiscountEnabled && combinationdiscount > 0"
                  class="payment-discount"
                >
                  <div class="payment-discount-bubble">-{{combinationdiscount}}%</div>
                </div>
              </div>
              <div class="package-extra">
                <p>Dit pakket bevat:</p>
                <ul>
                  <li
                    v-for="(functionality, index) in functionalities"
                    :key="index"
                    v-if="functionality.package == thrivePackage.id"
                  >
                    <span>{{ functionality.title }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="combinationdiscount > 0" class="payment-groupdiscount">
            <p>Selecteer meerdere pakketten en ontvang maandelijks {{ combinationdiscount }}% korting.</p>
          </div>
        </div>

        <div class="payment-options-outer-wrapper">
          <h2>Selecteer periode</h2>
          <div v-if="paymentOptions" class="payment-options-wrapper">
            <div class="payment-options">
              <div
                class="payment-option clear"
                v-for="(payment, index) in paymentOptions"
                :key="index"
                v-bind:class="[{active: payment.active}]"
              >
                <div class="payment-check" @click="addPeriod(index)"></div>
                <div class="payment-period">{{ payment.period }} maanden</div>
                <div class="payment-discount">
                  <div
                    class="payment-discount-bubble"
                    v-if="payment.discountPercentage > 0"
                  >-{{payment.discountPercentage}}%</div>
                </div>
              </div>
            </div>
            <!-- <p class="vat-text">Prijzen zijn excl. btw</p> -->
          </div>
        </div>

        <div class="payment-periode-outer-wrapper">
          <h2>Selecteer betaalperiode</h2>
          <div class="payment-periode-wrapper">
            <div class="payment-options">
              <div class="payment-option clear" v-bind:class="[{active: yearlyPayment}]" >
                <div class="payment-check" @click="setYearlyPayment()"></div>
                <div class="payment-period">Ik wil jaarlijks betalen</div>
                <div class="payment-discount" v-if="periodPaymenyDiscountAmount > 0">
                  <div class="payment-discount-bubble" >- &euro;{{periodPaymenyDiscountAmount}}</div>
                </div>
              </div>
              <div class="payment-option clear" v-bind:class="[{active: monthlyPayment}]">
                <div class="payment-check" @click="setMonthlyPayment()"></div>
                <div class="payment-period">Ik wil maandelijks betalen</div>
      
              </div>
            </div>
          </div>
        </div>

        <div class="payment-summary">
          <h2>Samenvatting pakketten</h2>

          <div class="summary-table-wrapper">
            <div v-if="chosenPackagesOriginal.length > 0">
              <div class="summary-table-row row-title clear">
                <div class="summary-table-label">Bestaande pakketten</div>
                <div class="summary-table-data"></div>
              </div>

              <div class="summary-table-row even clear">
                <div class="summary-table-label">Maandelijkse afschrijving</div>
                <div class="summary-table-data">&euro; {{ existingPacakgesTotalPriceMonthly.toFixed(2) }}  / mnd</div>
              </div>
              <div class="summary-table-row even clear">
                <div class="summary-table-label">Jaarlijkse afschrijving</div>
                <div class="summary-table-data">&euro; {{ existingPacakgesTotalPriceYearly.toFixed(2) }}  / jaar</div>
              </div>
            </div>

            <div class="summary-table-row space clear"></div>

            <div class="summary-table-row row-title clear">
              <div class="summary-table-label">Toegevoegde pakketten</div>
              <div class="summary-table-data"></div>
            </div>

            <div class="summary-table-row space clear"></div>


            <div class="summary-table-row row-title clear">
              <div class="summary-table-label">Eenmalige kosten</div>
              <div class="summary-table-data"></div>
            </div>

            <div class="summary-table-row even clear">
              <div class="summary-table-label">Kosten exclusief btw</div>
              <div class="summary-table-data">&euro; {{ activationFeeAmount }}</div>
            </div>
            <div class="summary-table-row clear">
              <div class="summary-table-label">BTW 21%</div>
              <div class="summary-table-data">&euro; {{ (activationFeeAmount * 0.21).toFixed(2) }}</div>
            </div>


            <div class="summary-table-row even total-bruto clear">
              <div class="summary-table-label">Kosten inclusief btw</div>
              <div class="summary-table-data">&euro; {{ (activationFeeAmount * 1.21).toFixed(2) }}</div>
            </div>

            <div class="summary-table-row space clear"></div>

            <div class="summary-table-row even row-title clear">
              <div v-if="yearlyPayment" class="summary-table-label">Jaarlijkse kosten</div>
              <div v-else class="summary-table-label">Maandelijkse kosten</div>
              <div class="summary-table-data"></div>
            </div>


            <div class="summary-table-row clear">
              <div class="summary-table-label">Kosten</div>
              <div class="summary-table-data">&euro; {{selectedPacakgesTotalPrice.toFixed(2) }}  / mnd</div>
            </div>

            <div class="summary-table-row even clear">
              <div class="summary-table-label">Contractperiode</div>
              <div class="summary-table-data">{{ chosenPeriod}} maanden</div>
            </div>

            <div class="summary-table-row  clear">
              <div class="summary-table-label">Abonnementsperiode korting</div>
              <div
                v-if="chosenPeriodDiscount > 0"
                class="summary-table-data"
              >&euro; {{ chosenPeriodDiscount }} / mnd</div>
              <div v-else class="summary-table-data">-</div>
            </div>

            <div class="summary-table-row even clear">
              <div class="summary-table-label">Betaalperiode korting</div>
              <div
                v-if="yearlyPayment"
                class="summary-table-data"
              >&euro; {{ periodPaymenyDiscountAmount }} / mnd</div>
              <div v-else class="summary-table-data">-</div>
            </div>

            <div class="summary-table-row  clear">
              <div v-if="groupDiscountEnabled" class="summary-table-label">Groepskorting {{ combinationdiscount }}%</div>
              <div v-else class="summary-table-label">Groepskorting</div>
              <div class="summary-table-data">
                <span v-if="groupDiscountEnabled">&euro; {{ totalGroupDiscount.toFixed(2) }} / mnd</span>
                <span v-else>-</span>
              </div>
            </div>

            

            <div class="summary-table-row even total-count clear">
              <div class="summary-table-label">Totale pakketkosten</div>
              <div class="summary-table-data">&euro; {{ totalAddedPackagesPrice.toFixed(2) }} / mnd</div>
            </div>

            <div class="summary-table-row space clear"></div>

            <!-- <div class="summary-table-row even clear">
              <div class="summary-table-label">Aantal pakketten</div>
              <div class="summary-table-data">{{ selectedPackages }}</div>
            </div> -->

            


            <div class="summary-table-row even total-netto clear">
              <div class="summary-table-label">Totale kosten exclusief btw</div>
              <div v-if="monthlyPayment" class="summary-table-data">&euro; {{ totalPriceNetto }}</div>
              <div v-if="yearlyPayment" class="summary-table-data">&euro; {{ (totalPriceNetto * 12).toFixed(2) }}</div>
            </div>

            <div class="summary-table-row btw clear">
              <div class="summary-table-label btw">BTW
                <span class="summary-table-label-extra">21%</span>
              </div>
              <div v-if="monthlyPayment" class="summary-table-data">&euro; {{ totalPriceBtw }}</div>
              <div v-if="yearlyPayment" class="summary-table-data">&euro; {{ (totalPriceBtw * 12).toFixed(2) }}</div>
            </div>

            <div class="summary-table-row even total-bruto clear">
              <div class="summary-table-label">Totale kosten inclusief btw</div>
              <div v-if="monthlyPayment" class="summary-table-data">&euro; {{ totalPriceBruto }} / mnd</div>
              <div v-if="yearlyPayment" class="summary-table-data">&euro; {{ (totalPriceBruto * 12).toFixed(2) }} / jaar</div>
            </div>


             

          </div>

          <div class="payment-usercosts">
            <p>Prijzen excl. gebruikerskosten. Voor meer dan 3 actieve gebruikers worden kosten gerekend.</p>
          </div>
        </div>

        <div class="order-upgrade-wrapper">
          <h2>Betalen met automatische incasso</h2>

          <div class="upgrade-fields">
            <div class="input-wrapper">
              <v-text-field readonly label="Bedrijfsnaam" v-model="activeCompData.name"></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field
                label="Rekeninghouder"
                number
                v-model.number="subscription.accountHolder"
              ></v-text-field>
            </div>
            <div class="input-wrapper">
              <v-text-field label="IBAN-nummer" :mask="ibanMask" v-model="subscription.iban"></v-text-field>
            </div>
          </div>

          <p
            class="payment-info"
          >Maandelijks ontvangt u een factuur van de afgeschreven kosten. Het bedrag wordt maandelijks rond de 15e automatisch afgeschreven. Dit is een doorlopend contact, na {{ chosenPeriod }} maanden maandelijks opzegbaar.</p>
          <div class="upgrade-conditions-wrapper clear">
            <div
              class="upgrade-conditions-check"
              @click="checkTerms()"
              :class="{ checked : termsAndConditionsAgreed}"
            ></div>
            <div class="upgrade-conditions-text">Ik ga akkoord met de
              <a @click="conditionsDialog = true">voorwaarden</a>
            </div>
          </div>
        </div>
      </div>

      <v-dialog v-model="conditionsDialog" scrollable :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
          <v-card-title class="hogans-modal-header">
            <h2>Algemene Voorwaarden Thrive</h2>
            <div class="hogans-modal-header-close" @click="conditionsDialog = false"></div>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <div id="terms-wrapper" class="content" v-if="termsAndConditions">
              <p
                v-if="termsAndConditions.version"
                class="thrive-terms-version"
              >Version {{termsAndConditions.version}}</p>
              <div
                v-if="termsAndConditions.terms"
                class="thrive-terms"
                v-html="termsAndConditions.terms"
              ></div>
            </div>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn
              outlined
              depressed
              color="tertiary"
              @click="closeUpgradeDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              :disabled=" !changed" 
              :depressed="!changed"  
              :dark=" changed"  
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              rounded 
              color="secondary" 
              @click="upgrade()"
              >
              Upgraden
              <v-icon right dark>mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>
<style>
.room-defaultdays-wrapper {
  background-color: #f6f7fb;
}

.room-defaultdays-label {
  padding: 10px 0 0 10px;
  color: #251433;
}
/*

 <div class="room-defaultdays-wrapper">
          <div class="room-defaultdays-label">Standaard dagen beschikbaar</div>

*/
</style>

<script>
import db from "../../firebase/init";
import '@firebase/firestore';
import moment from "moment";
import { bus } from "../../main";

export default {
  props: {
    today: Number,
    activeCompData: Object,
    paymentOptions: Array,
    combinationdiscount: Number,
    chosenPackages: Array,
    chosenPackagesOriginal: Array,
    functionalities: Array,
    packages: Array,
    termsAndConditions: Object,
    subscription: Object,
    chosenPeriod: Number,
    chosenPeriodDiscountPercentage: Number
  },
  name: "upgrade-form",
  data() {
    return {
      conditionsDialog: false,
      feedback: null,
      packageCombinations: [],
      existingPacakgesTotalPriceYearly: 0.0,
      existingPacakgesTotalPriceMonthly: 0.0,
      selectedPacakgesTotalPrice: 0.0,
      termsAndConditionsAgreed: false,
      ibanMask: "NN## NNNN ##########",
      groupDiscountEnabled: false,
      selectedPackages: 0,
      chosenPeriodDiscount: 0,
      totalPriceNoDiscount: 0,
      totalPriceNetto: 0,
      totalPriceBruto: 0,
      totalPriceBtw: 0,
      totalAddedPackagesPrice: 0.0,
      monthlyPayment: false,
      yearlyPayment: true,
      totalGroupDiscount: 0.0
    };
  },

  created() {
    bus.$on("updateCalculation", this.checkActivePackages);
    this.checkActivePackages();
  },

  methods: {
    closeUpgradeDialog() {
      this.$emit("upgradeDialogChange", false);
    },

    objectSize(obj) {
      let size = 0,
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    },

    checkTerms() {
      if (this.termsAndConditionsAgreed == true) {
        this.termsAndConditionsAgreed = false;
      } else {
        this.termsAndConditionsAgreed = true;
      }
    },

    setMonthlyPayment(){
      this.yearlyPayment = false;
      this.monthlyPayment = true;
      this.calculateTotalPrice()
    },

    setYearlyPayment(){
      this.yearlyPayment = true;
      this.monthlyPayment = false;
      this.calculateTotalPrice()
    },

    collapsePackage(index) {
      console.log("Collapse");
      if (this.packages[index].collapsed == true) {
        this.packages[index].collapsed = false;
      } else {
        this.packages[index].collapsed = true;
      }
    },

    calculateTotalPrice() {
      if (this.chosenPeriodDiscountPercentage > 0) {
        this.chosenPeriodDiscount = (
          (this.selectedPacakgesTotalPrice / 100) *
          this.chosenPeriodDiscountPercentage
        ).toFixed(2);
      } else {
        this.chosenPeriodDiscount = 0;
        // this.selectedPacakgesTotalPrice = _.sum(price);
      }

      this.totalAddedPackagesPrice = Number((this.selectedPacakgesTotalPrice - this.totalGroupDiscount - this.periodPaymenyDiscountAmount - (this.selectedPacakgesTotalPrice / 100) * this.chosenPeriodDiscountPercentage ).toFixed(2)
      );

      // totalPriceNoDiscount
      let totalPackagesPrice = this.selectedPacakgesTotalPrice;

      if (this.selectedPackages > 0) {
        let groupDiscount = 0;
        let totalPrice = this.totalAddedPackagesPrice;


        

        this.totalPriceNetto = Number(totalPrice).toFixed(2);
        this.totalPriceBtw = Number(this.totalPriceNetto * 0.21).toFixed(2);
        this.totalPriceBruto = Number(this.totalPriceNetto * 1.21).toFixed(2);
      } else {
        this.totalPriceNetto = 0.0;
        this.totalPriceBtw = 0.0;
        this.totalPriceBruto = 0.0;
      }
    },

    addPackage(index) {
      console.log("addPackage");

      let packageId = this.packages[index].id;
      if (this.packages[index].added == true) {
        this.packages[index].added = false;
        this.chosenPackages = this.packageRemove(
          this.chosenPackages,
          packageId
        );
        delete this.subscription.packages[packageId];
      } else if (this.packages[index].added == false) {

        
        this.packages[index].added = true;
        this.subscription.packages[packageId] = {};
        this.subscription.packages[packageId].name = this.packages[index].name;
        this.subscription.packages[packageId].paymentPeriodDiscount = this.packages[index].discountYearlyPayment
        if (
          this.packages[index].action.active == true &&
          this.packages[index].action.startDate.seconds <= this.today &&
          this.packages[index].action.endDate.seconds > this.today
        ) {
          this.subscription.packages[packageId].priceOriginal = this.packages[
            index
          ].priceNetto;
          this.subscription.packages[packageId].price = this.packages[
            index
          ].action.priceNetto;
        } else {
          this.subscription.packages[packageId].priceOriginal = this.packages[
            index
          ].priceNetto;
          this.subscription.packages[packageId].price = this.packages[
            index
          ].priceNetto;
        }
        this.chosenPackages.push(packageId);
      }
    },

    packageRemove(arr, value) {
      return arr.filter(function(ele) {
        return ele != value;
      });
    },

    addPeriod(itemIndex) {
      this.chosenPeriod = this.paymentOptions[itemIndex].period;
      if (this.paymentOptions[itemIndex].discount == true) {
        this.chosenPeriodDiscountPercentage = this.paymentOptions[
          itemIndex
        ].discountPercentage;
      } else {
        this.chosenPeriodDiscountPercentage = 0;
      }

      this.paymentOptions.forEach((item, index) => {
        if (itemIndex == index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },

    upgrade() {
      const ibantools = require("ibantools");
      this.feedback = null;
      if (this.objectSize(this.subscription.packages) >= 1) {
        if (this.subscription.accountHolder && this.subscription.iban) {
          if (this.termsAndConditionsAgreed) {
            if (ibantools.isValidIBAN(this.subscription.iban) == true) {
              this.feedback = null;
              let counter = 0;
              new Promise((resolve, reject) => {
                for (let key in this.subscription.packages) {
                  let chosenPackage = _.find(this.packages, function(o) { return o.id == key; });
          
                  if (!this.subscription.packages[key].startDate && !this.subscription.packages[key].endDate) {
                    this.subscription.packages[key].contractMonths = this.chosenPeriod;
                    this.subscription.packages[key].chosenPeriodDiscountPercentage = this.chosenPeriodDiscountPercentage;
                    this.subscription.packages[key].startDate = new Date(moment.unix(this.today));
                    this.subscription.packages[key].endDate = null;
                  }
                  if(this.subscription.packages[key].paymentPeriod){
                    console.log("Dit pakket heeft al een periode");
                    
                  }
                  else{
                    if(this.yearlyPayment){
                      this.subscription.packages[key].paymentPeriod = 'yearly';
                      this.subscription.packages[key].paymentPeriodDiscount = chosenPackage.discountYearlyPayment;
                    } else{
                      this.subscription.packages[key].paymentPerid = 'monthly';
                      this.subscription.packages[key].paymentPeriodDiscount = null;
                    }

                    if(chosenPackage.activationFee){
                      this.subscription.packages[key].activationFeeAmount = chosenPackage.activationFee;
                      this.subscription.packages[key].activationFeePayed = false;
                    } else {
                      this.subscription.packages[key].activationFeeAmount = 0
                      this.subscription.packages[key].activationFeePayed = true;
                    }
                  }
                  
                  counter++;
                  if (counter == this.objectSize(this.subscription.packages)) {
                    console.log("resolved: " + counter);
                    resolve();
                  }
                }
              }).then(() => {
                this.subscription.groupDiscount = this.combinationdiscount;
                let companySubscription = {};
                companySubscription.subscription = this.subscription;
                if (this.objectSize(this.subscription.packages) >= 2) {
                  companySubscription.subscription.groupDiscount = this.combinationdiscount;
                } else {
                  companySubscription.subscription.groupDiscount = 0;
                }
                db.collection("companies")
                  .doc(this.activeCompData.id)
                  .update(companySubscription)
                  
                  .then(() => {
                    this.$emit("updateData");
                    this.closeUpgradeDialog();
                    console.log("Subscription succesfull updated");
                  })
                  .catch(error => {
                    console.error("Error updating Subscription: ", error);
                  });
              });
            } else {
              this.feedback = "IBAN-nummer is niet correct.";
            }
          } else {
            this.feedback = "Ga akkoord met de voorwaarden om door te gaan.";
          }
        } else {
          this.feedback = "Vul alle velden in om door te gaan.";
        }
      } else {
        this.feedback = "Selecteer minimaal één pakket.";
      }
    },

    checkActivePackages() {
      let price = [];
      let existingPriceMonthly = [];
      let existingPriceYearly = [];
      let groupDiscounts = []
      this.selectedPackages = this.objectSize(this.subscription.packages);
      if (this.objectSize(this.subscription.packages) > 1) {
        this.groupDiscountEnabled = true;
      } else {
        this.groupDiscountEnabled = false;
      }

      this.chosenPackages.forEach(chosenItem => {
        this.packages.forEach(thrivePackage => {
          if (chosenItem == thrivePackage.id) {
         
            let groupDiscount = 0
            if(!thrivePackage.alreadyPurchased && this.groupDiscountEnabled){
              groupDiscount = Number((this.subscription.packages[chosenItem].price / 100) * this.combinationdiscount);
              groupDiscounts.push(groupDiscount)
            }
            let chosenPackage = this.subscription.packages[chosenItem]

            if (thrivePackage.action.active == true && thrivePackage.alreadyPurchased == false && thrivePackage.action.startDate.seconds <= this.today && thrivePackage.action.endDate.seconds > this.today){
              price.push(thrivePackage.action.priceNetto);
            } else if (thrivePackage.alreadyPurchased == true) {
              let packagePrice = Number(thrivePackage.price)
              let paymentPeriodDiscountAmount = Number(chosenPackage.paymentPeriodDiscount)
              let chosenPeriodDiscountAmount =  Number((chosenPackage.price / 100) * chosenPackage.chosenPeriodDiscountPercentage)
              let chosenPackagePrice = Number(packagePrice - (paymentPeriodDiscountAmount + chosenPeriodDiscountAmount))

              if(chosenPackage.paymentPeriod == 'yearly'){
                existingPriceYearly.push(chosenPackagePrice);
              } else {
                existingPriceMonthly.push(chosenPackagePrice);
              }
              
            } else {
              price.push(thrivePackage.priceNetto);
            }
          }
        });
      });

      this.existingPacakgesTotalPriceYearly = Number(_.sum(existingPriceYearly));
      this.existingPacakgesTotalPriceMonthly = Number(_.sum(existingPriceMonthly));
      this.totalGroupDiscount = Number(_.sum(groupDiscounts));

      this.selectedPacakgesTotalPrice = Number(_.sum(price));
      this.calculateTotalPrice();
    }
  },
  watch: {
    chosenPeriod: {
      handler: function() {
        this.calculateTotalPrice();
      }
    },
    chosenPackages: {
      handler: function() {
        console.log("chosenPackages Watcher Running");
        this.checkActivePackages();
      },
      deep: true
    }
  },

  computed: {
    activationFeeAmount(){ 
      let activationFeeAmount = 0
      this.packages.forEach((selectedPackage) => {
        if(selectedPackage.added == true){
          if(selectedPackage.activationFee && !selectedPackage.alreadyPurchased){            
            activationFeeAmount += selectedPackage.activationFee 
          }
        }
      })
      return Number(activationFeeAmount).toFixed(2)
    },

    periodPaymenyDiscountAmount(){
      if(this.yearlyPayment == true){
        let discountAmount = 0
        this.packages.forEach((selectedPackage) => {
          if(selectedPackage.added == true && !selectedPackage.alreadyPurchased){          
            discountAmount += selectedPackage.discountYearlyPayment
          }
        })
        
        return Number(discountAmount).toFixed(2)
      } else {
        return 0
      }
    },
    userCompanies() {
      return this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    changed() {
      if (
        _.isEqual(this.chosenPackages, this.chosenPackagesOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.upgrade-page-wrapper {
  background-color: #fff;
  padding: 0 0 20px 0;
  margin: 0 0 30px 0;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
}
.thrive-terms-version {
  font-family: "TT-Norms", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #030303;
  padding: 15px 0 0 0;
  text-align: center;
}

.thrive-terms {
  padding: 15px;
  font-size: 12px;
}

.upgrade-intro {
  padding: 15px 15px 5px 15px;
}

.upgrade-intro h2 {
  font-family: "TT-Norms", sans-serif;
  font-weight: 900;
  font-size: 19px;
  color: #030303;
  padding: 0 0 5px 0;
}

.upgrade-intro p {
  font-family: "Sofia Pro", sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.upgrade-functionalities-slider {
  width: 100%;
  position: relative;
}
.upgrade-functionalities-slide {
  width: 100%;
  position: relative;
  display: block;
}

.functionality-slide-image {
  width: 200px;
  height: 110px;
  margin: 10px auto 0 auto;
  display: block;
}

.upgrade-functionalities-slider .functionality-slide-text {
  height: 140px;
  padding: 10px 15px 35px 15px;
  display: block;
}

.upgrade-functionalities-slider .functionality-slide-text h3 {
  font-family: "TT Norms", sans-serif;
  font-weight: 800;
  font-size: 15px;
  color: #030303;
  letter-spacing: 0.79px;
  text-align: center;
  padding: 5px 0;
}

.upgrade-functionalities-slider .functionality-slide-text p {
  font-family: "Sofia Pro", sans-serif;
  font-size: 12px;
  color: #030303;
  text-align: center;
}

.upgrade-packages-wrapper {
  padding: 0 16px;
}

.upgrade-package-wrapper {
  position: relative;
  border: 1px solid #00adef;
  background-color: #fff;
  padding: 10px;
  margin: 0 0 10px 0;
  border-radius: 5px;
}

.package-check {
  width: 24px;
  height: 24px;
  border: 1px solid #00adef;
  background-color: #fff;
  border-radius: 24px;
  float: left;
  margin: 7px 13px 7px 5px;
}

.upgrade-package-wrapper.active .package-check {
  background: #00adef url("../../assets/icons/check-icon-white.svg") center
    center no-repeat;
  background-size: 12px auto;
}

.upgrade-package-wrapper .payment-discount {
  display: none;
}

.upgrade-package-wrapper.active .payment-discount {
  display: block;
  position: absolute;
  top: 5px;
  right: 51px;
  width: 70px;
  height: 52px;
  float: none;
}

.package-text {
  width: calc(100% - 80px);
  float: left;
}

.package-text h4 {
  font-family: "TT Norms", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 0 2px 0;
  max-width: 380px;
}

.package-text p {
  font-family: "Sofia Pro", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.4px;
  max-width: 380px;
}

.upgrade-package-wrapper .package-text p,
.upgrade-package-wrapper .package-text span {
  color: #000;
}

.package-arrow {
  width: 24px;
  height: 24px;
  float: left;
  margin: 10px 5px 0 5px;
  background: url("../../assets/icons/dropdown-icon-grey.svg") center center
    no-repeat;
  background-size: 14px auto;
}

.upgrade-package-wrapper .package-extra {
  display: none;
  max-width: 380px;
  padding: 5px 0 0 45px;
  font-family: "Sofia Pro", sans-serif;
  font-size: 12px;
}

.upgrade-package-wrapper .package-extra ul {
  padding: 0 0 0 15px;
}

.upgrade-package-wrapper .package-extra p {
  color: #000;
}

.upgrade-package-wrapper.collapsed .package-arrow {
  background: url("../../assets/icons/dropdown-reverse-icon-grey.svg") center
    center no-repeat;
  background-size: 14px auto;
}

.upgrade-package-wrapper.collapsed .package-extra {
  display: block;
}

span.old-price {
  text-decoration: line-through;
}

span.new-price {
  font-weight: 600;
  padding: 0 5px 0 0;
}

.payment-options-wrapper {
  padding: 20px 15px;
  background-color: #00adef;
}

.payment-periode-wrapper{
  padding: 20px 15px;
  background-color: #f6f9fb;
}

.payment-options {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.payment-option {
  border-bottom: 1px solid #dbe7ff;
}

.payment-option.last {
  border: none;
}

.payment-option.active .payment-check {
  background: #00adef url("../../assets/icons/check-icon-white.svg") center
    center no-repeat;
  background-size: 12px auto;
}

.payment-check {
  width: 24px;
  height: 24px;
  border: 1px solid #00adef;
  background-color: #fff;
  border-radius: 24px;
  float: left;
  margin: 13px;
}

.payment-period {
  width: calc(100% - 174px);
  height: 52px;
  line-height: 52px;
  float: left;
  font-family: "Sofia Pro", sans-serif;
  font-size: 14px;
  color: rgba(104, 115, 135, 0.87);
  letter-spacing: 0;
}

.payment-ammount-number {
  padding: 10px 5px 0 0;
  font-family: "Sofia Pro", sans-serif;
  font-size: 14px;
  color: rgba(104, 115, 135, 0.87);
  text-align: right;
}

.payment-ammount-desc {
  padding: 0 5px 0 0;
  font-family: "Sofia Pro", sans-serif;
  font-size: 12px;
  line-height: 12px;
  color: rgba(104, 115, 135, 0.87);
  text-align: right;
}

.payment-discount {
  width: 70px;
  height: 52px;
  float: left;
}
.payment-discount-bubble {
  background-color: #f48c00;
  color: #ffffff;
  padding: 5px 3px;
  width: 65px;
  margin: 10px 6px 6px 0;
  float: right;
  text-align: center;
  position: relative;
  border-radius: 3px;
  font-family: "Sofia Pro", sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.payment-discount-bubble:before {
  position: absolute;
  left: -16px;
  top: 8px;
  width: 0px;
  height: 0px;
  border: 8px solid #f48c00;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid transparent;
  z-index: 99;
  content: "";
}

.vat-text {
  padding: 12px 0;
  color: #ffffff;
  text-align: center;
  font-family: "Sofia Pro", sans-serif;
  font-size: 12px;
}

.payment-groupdiscount p {
  text-align: center;
  padding: 0 10px;
}

.payment-groupdiscount h2,
.payment-options-outer-wrapper h2,
.upgrade-packages-wrapper h2,
.order-upgrade-wrapper h2,
.payment-periode-outer-wrapper h2,
.payment-summary h2  {
  font-family: "TT Norms", sans-serif;
  font-size: 15px;
  font-weight: 700;
  padding: 16px 0;
  color: #030303;
  letter-spacing: 0.79px;
  text-align: center;
}

.order-upgrade-wrapper {
  padding: 16px;
}

p.payment-info {
  padding: 15px;
  font-family: "Sofia Pro", sans-serif;
  font-size: 12px;
  color: rgba(18, 20, 24, 0.67);
  line-height: 16px;
}

.upgrade-conditions-wrapper {
  padding: 10px 15px;
}

.upgrade-conditions-check {
  width: 24px;
  height: 24px;
  margin: 0 14px 0 7px;
  border: 1px solid #00adef;
  background-color: #fff;
  border-radius: 24px;
  float: left;
}

.upgrade-conditions-check.checked {
  background: #00adef url("../../assets/icons/check-icon-white.svg") center
    center no-repeat;
  background-size: 12px auto;
}

.upgrade-conditions-text {
  font-family: "Sofia Pro", sans-serif;
  float: left;
  width: calc(100% - 47px);
  font-size: 14px;
  padding: 3px 0 0 0;
  color: rgba(18, 20, 24, 0.87);
}

.summary-table-wrapper {
  padding: 0;
}

.summary-table-row {
  border-top: 1px solid #d3ddef;
  padding: 5px 30px;
  min-height: 28px;
}

.summary-table-row.total-bruto {
  border-bottom: 1px solid #d3ddef;
}

.summary-table-row.even {
  background-color: #f6f9fb;
}

.summary-table-label,
.summary-table-data {
  width: 50%;
  float: left;
}

.summary-table-label {
  font-weight: 600;
}

.summary-table-row.space {
  height: 20px;
  min-height: 20px;
}

.summary-table-row.row-title {
  border-top: none;
}

.summary-table-row.total-bruto .summary-table-label,
.summary-table-row.total-bruto .summary-table-data,
.summary-table-row.row-title .summary-table-label,
.summary-table-row.total-count .summary-table-label {
  font-weight: 800;
}

.summary-table-row.btw .summary-table-label,
.summary-table-row.btw .summary-table-data {
  font-size: 800;
}

.summary-table-data {
  text-align: right;
}

.payment-usercosts p {
  font-size: 12px;
  padding: 20px;
}

span.already-purchased-label {
  padding: 2px 8px 0 8px;
  margin: -4px 0 0 4px;
  display: inline-block;
  border-radius: 2px;
  background-color: #f48c00;
  font-size: 12px;
  color: #ffffff !important;
}

.summary-table-label-extra {
  font-size: 12px;
}

.package-startcosts{
  color: rgba(104, 115, 135, 0.87)!important;
  font-size: 13px!important;
}

.package-startcosts .label{
  font-style: italic;
  color: rgba(104, 115, 135, 0.87)!important;
}
</style>
