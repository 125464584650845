<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
      <h2>{{selectedContentType}} bewerken</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeEditNewsDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-divider></v-divider>

    <v-card-text>
      <v-select
        class="mt-4"
        hide-details
        v-model="selectedNewsItem.chooseType"
        :items="chooseType"
        label="Bericht categorie"
        item-text="name"
        item-value="id"
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        required
      ></v-select>

      <v-text-field
        class="mt-4"
        hide-details
        v-model="selectedNewsItem.title" 
        label="Titel" 
        :rules="requiredRules" 
        required
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
        :readonly="update"
        :disable="update"
      ></v-text-field>

      <v-text-field
        class="mt-4"
        hide-details
        v-if="selectedNewsItem.chooseType == 'update'"
        label="Versienummer"
        v-model="selectedNewsItem.version"
        :rules="requiredRules"
        required
        outlined
        background-color="background"
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        v-on:input="createTitle()"
      ></v-text-field>

      <v-container v-if="selectedNewsItem.chooseType == 'update'" grid-list-md fluid class="pa-1" style="background-color: #eee; margin: 15px 0;" :style="{background: $vuetify.theme.themes[theme].background}">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex md6>
            <span style="font-size: 16px; font-weight: bold;">Versienummering Policy</span>
          </v-flex>
          <v-flex md6>
            <span style="font-size: 16px; font-weight: bold;">Laatste versies Thrive</span>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex md6 style="font-size: 12px;">
            <span style="font-size: 14px; font-weight: bold;">Major</span> - <span style="font-size: 14px;"><strong>1</strong>.2.5</span><br>
              Ingrijpende aanpassing zoals:<br>
              -Toevoeging van nieuwere en betere API's<br>
              -Afschrijving van verschillende functies<br><br>
            <span style="font-size: 14px; font-weight: bold;">Minor</span> - <span style="font-size: 14px;">1.<strong>2</strong>.5</span><br>
              Kleinere aanpassing zoals:<br>
              -Release van nieuwe functionaliteit(en)<br><br>
            <span style="font-size: 14px; font-weight: bold;">Release</span> - <span style="font-size: 14px;">1.2.<strong>5</strong></span><br>
              Aanpassing zoals:<br>
              -Kleine aanpassingen of verbeteringen<br>
              -Bugfixes
          </v-flex>

          <v-flex md6 style="font-size: 11px;">
            <div v-for="(version, index) in versions" :key="index" class="software-version">
              <span class="software-version-number">{{version.version}}</span>
              <span class="software-version-date">{{version.date}}</span>
              <span class="software-version-author">{{version.author}}</span>
            </div>
          </v-flex>
        </v-layout>
      </v-container>

      <v-textarea
        rows="1"
        auto-grow
        v-model="selectedNewsItem.intro"
        label="intro"
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
        :rules="requiredRules"
        required
        class="mt-4"
        hide-details
      ></v-textarea>

      <div v-if="selectedNewsItem.chooseType != 'update'" class="upload-news-image-wrapper clear">
        <div class="upload-image">
          <p>Upload afbeelding</p>
          <div v-if="selectedNewsItem.imageUrl" class="logo-upload-preview">
            <v-img 
              :src="selectedNewsItem.imageUrl"
              aspect-ratio="1"
              class="grey lighten-2"
              >
              <template v-slot:placeholder>
                <v-layout
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                </v-layout>
              </template>
            </v-img>
            <div v-if="uploading == true" class="upload-bar">
              <p>{{ uploadProgress }} %</p>
              <div class="upload-bar-inner" style="background-color: #00adef;"></div>
            </div>
          </div>
          <v-btn class="ma-0" raised style="color:#00adef" dark @click="onFileSelect">Kies afbeelding</v-btn>
          <input
            type="file"
            style="display: none;"
            ref="fileInput"
            accept="image/*"
            @change="onFilePicked"
          >
        </div>
      </div>

      <div style="max-width: 100%; overflow: hidden;">
        <v-tooltip right>
          <template v-slot:activator="{on}">
            <label>
              {{selectedContentType}}
              <v-icon v-on="on" small right>mdi-help-circle</v-icon>
            </label>
          </template>
          <span style="color: #fff;">Omschrijving van {{selectedContentType}} voor gebruikers.</span>
        </v-tooltip>
        <ckeditor :editor="editor" v-model="selectedNewsItem.description" :config="editorConfig"></ckeditor>
      </div>

      <div v-if="selectedNewsItem.chooseType == 'update'" style="margin: 0 0 15px 0; max-width: 100%; overflow: hidden;">
        <v-tooltip right>
          <template v-slot:activator="{on}">
            <label class="custom-label">
              Interne opmerkingen
              <v-icon v-on="on" small right>mdi-help-circle</v-icon>
            </label>
          </template>
          <span style="color: #fff;">Extra informatie of uitleg voor intern gebruik.</span>
        </v-tooltip>
        <ckeditor :editor="editor" v-model="selectedNewsItem.extra" :config="editorConfig"></ckeditor>
      </div>

      <v-text-field
        v-if="selectedNewsItem.chooseType == 'update'"
        style="margin: 0 0 15px 0;"
        label="Auteur Update"
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
        v-model="selectedNewsItem.updateAuthor"
        :rules="requiredRules"
        hint="Wie heeft de update gedaan?"
        persistent-hint
        required
      ></v-text-field>

      <v-text-field
        style="margin: 15px 0;"
        type="number"
        outlined
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        background-color="background"
        label="Aantal fictieve likes"
        v-model.number="selectedNewsItem.fictionalLikes"
        v-on:input="updateFictionalLikes()"
        hint="Fictieve likes toevoegen om bericht imposanter te doen liken."
        persistent-hint
        ></v-text-field>
      
        <v-switch
          v-model="selectedNewsItem.published"
          class="pa-0"
          hide-details
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          >
          <template v-slot:label>
            <span style="margin-right: 5px;">Publiceren</span>
         </template>
        </v-switch>     
  
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left">
            <v-btn
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
              @click="closeEditNewsDialog()"
              >
              Annuleren
            </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn
              :disabled="!changed"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              @click="updateNews()"
              >
              <div v-if="changed">Wijzigingen opslaan</div>
              <div  v-else>Opgeslagen</div>  
              <v-icon v-if="changed">mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

  </v-card>
</template>

<script>
import db from "../../firebase/init";
import '@firebase/firestore';
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "edit-news-form",
  props: {
    selectedNewsItem: Object,
    selectedNewsItemOriginal: Object,
    versions: Array,
  },
  data: function() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the rich-text editor.
      },
      chooseType: [
        {
          name: "Newsbericht",
          id: "news"
        },
        {
          name: "Tip",
          id: "tip"
        },
        {
          name: "Actie",
          id: "action"
        },
        {
          name: "Succesverhaal",
          id: "casestudie"
        },
        {
          name: "Software update",
          id: "update"
        },
      ],
      valid: false,
      requiredRules: [v => !!v || "Dit veld is verplicht"],
      valid: true,
      feedback: null,
      filename: null,
      image: null,
      uploading: false,
      uploadProgress: null,
      file: {}
    };
  },

  created() {},

  methods: {
    closeEditNewsDialog() {
      // Close the dialog.
      this.$emit("editNewsDialogChange", false);
      this.feedback = null;
    },

    createTitle() {
      // Generating the title if the (news) type software update is.
      this.selectedNewsItem.title = "Thrive versie " + this.selectedNewsItem.version;      
    },

    onFileSelect() {
      // Opening selection folder in the browser.
      this.$refs.fileInput.click();
    },

    onFilePicked(event) {
      // Selecting picture.
      const files = event.target.files;
      this.file = files[0];
      let filename = files[0].name;
      this.filename = filename;
      console.log("files: " + filename);
      if (filename.lastIndexOf(".") <= 0) {
        this.feedback = "Voeg een geldig bestand toe.";
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.selectedNewsItem.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      this.feedback = null;
    },

    updateFictionalLikes(){
      // Updating fictional likes
      _.pull(this.selectedNewsItem.likes, 'fictionalLike');
      let fictionalLikes = _.times(this.selectedNewsItem.fictionalLikes , _.constant("fictionalLike"))

      fictionalLikes.map(like=> {
        this.selectedNewsItem.likes.push(like)
      })
    },

    updateNews() {
      this.feedback = null
      if(this.selectedNewsItem.chooseType == 'update'){
        // type is software update. Picture will is pre-fixed. 
        if(this.selectedNewsItem.version && this.selectedNewsItem.intro && this.selectedNewsItem.description && this.selectedNewsItem.updateAuthor && this.selectedNewsItem.extra){
          db.collection("news").doc(this.selectedNewsItem.id).update({
            chooseType: this.selectedNewsItem.chooseType,
            title: this.selectedNewsItem.title,
            intro: this.selectedNewsItem.intro,
            description: this.selectedNewsItem.description,
            extra: this.selectedNewsItem.extra,
            likes: this.selectedNewsItem.likes,
            fictionalLikes: Number(this.selectedNewsItem.fictionalLikes), 
            published: this.selectedNewsItem.published,
            updateAuthor: this.selectedNewsItem.updateAuthor,
            user: this.userFirstName + " " + this.userLastName,
            dateAdded: this.selectedNewsItem.dateAdded,
            user: this.userFirstName + " " + this.userLastName,
            version: this.selectedNewsItem.version,
          })
          .then(() => {
            this.$emit("updateData");
            this.closeEditNewsDialog();
            console.log(this.selectedContentType + " succesfully updated");
          })
        } else {
          this.feedback = "Vul alle velden in om de wijzigingen op te slaan."
        }
      }else {
      // type isn't software update. Selecting a picture is obligated.
      if(this.selectedNewsItem.imageUrl && this.selectedNewsItem.description && this.selectedNewsItem.intro && this.selectedNewsItem.title){
        db.collection("news").doc(this.selectedNewsItem.id).update({
          chooseType: this.selectedNewsItem.chooseType,
          title: this.selectedNewsItem.title,
          intro: this.selectedNewsItem.intro,
          description: this.selectedNewsItem.description,
          extra: this.selectedNewsItem.extra,
          likes: this.selectedNewsItem.likes,
          fictionalLikes: Number(this.selectedNewsItem.fictionalLikes), 
          published: this.selectedNewsItem.published,
          user: this.userFirstName + " " + this.userLastName,
          dateAdded: this.selectedNewsItem.dateAdded,
          user: this.userFirstName + " " + this.userLastName,
        })
        .then(async(docRef) => {
          if (this.filename) {
            await this.uploadNewsImage(this.selectedNewsItem.id);
          }
        })
        .then(() => {
          this.$emit("updateData");
          this.closeEditNewsDialog();
          console.log(this.selectedContentType + " succesfully updated");
        })
        } else {
          this.feedback = "Vul alle velden in om de wijzigingen op te slaan."
        }
      }
    },

    uploadNewsImage(docId) {
      // Uploading Image.
      return new Promise((resolve, reject) => {
        console.log("start storen");
        const file = this.file;
        const filename = this.filename;
        const ext = filename.slice(filename.lastIndexOf("."));
        const fileUrl =  "newsimages/" + docId + ext;
        this.uploading = true;
        let uploadTask = firebase
          .storage()
          .ref(fileUrl)
          .put(file);

        uploadTask.on(
          "state_changed",
          taskSnapshot => {
            var progress = Math.ceil((taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100);
            this.uploadProgress = progress;
          },
          error => {
            console.log(error);
            reject();
          },
          () => {
          console.log("The upload is compleet");
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(url => {
            db.collection("news")
              .doc(docId)
              .update({
                imageUrl: url
              })
              .then(() => {
                resolve();
                this.uploading = false;
                this.uploadProgress = null;
              })
              .catch(error => {
                reject();
                this.uploading = false;
                this.uploadProgress = null;
              });
            })
            .catch(error => {
              reject();
              this.uploading = false;
              this.uploadProgress = null;
            });
          }
        );
      });
    },
  },

  computed: {
    update(){
      // Is de geselecteerde contenttype een update? Dite wordt gebruikt voor de disable prop in de title.
      return this.selectedNewsItem.chooseType == 'update' ? true : false;
    },

    selectedContentType() {
      // This is getting used for the disable prop in the title.
      let contentType
      this.chooseType.map(type => {
        if (type.id == this.selectedNewsItem.chooseType) {
          contentType = type.name;
        }
      });
      return contentType;
    },
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },
    changed() {
      // Function that looks for change in the form.
      if (
        _.isEqual(this.selectedNewsItem, this.selectedNewsItemOriginal) == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};

</script>

<style scoped>
</style>

