<template>
  <v-dialog v-model="newCaoDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type == 'new'">{{ $t('admin.addNewCao', $store.state.locale) }} </h3>
        <h3 v-if="type == 'edit'">{{ $t('admin.editCao', $store.state.locale) }} </h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeCaoDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>

        <v-layout row wrap v-if="caoData">
          <!-- / Select input language \ -->
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="pa-2 pt-5 mb-4">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12> 
                <v-avatar
                  size="54"
                  light
                  style="float: left"
                  class="ma-0" 
                  rounded="lg"
                  color="#ffffff" 
                  >
                  <country-flag class="ma-0 pa-0" :country="getFlagCode(selectedLanguage)" />
                </v-avatar>
                <v-select
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :disabled="availableLanguages.length == 1"
                  :items="availableLanguages"
                  :item-text="(item) => item.languageName[$store.state.locale.toLowerCase()]"
                  :label="$t('general.language', $store.state.locale)"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                  class="mt-2 pl-2"
                  v-model="selectedLanguage"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-card>
          <!-- \ Select input language / -->

          <v-flex md12>
            <v-text-field
              class="mt-1"
              :label="$t('admin.caoName', $store.state.locale)"
              v-model="caoData.name[selectedLanguage]"
              autocomplete
              outlined
              required
              dense
              hide-details
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              >
            </v-text-field>
            <v-textarea
              class="mt-4"
              :label="$t('general.description', $store.state.locale)"
              v-model="caoData.description[selectedLanguage]"
              autocomplete
              outlined
              rows="2"
              required
              dense
              hide-details
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              >
            </v-textarea>

            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-4">
              <div v-if="caoData.agreements && caoData.agreements.length > 0" class="pa-1">
                <div v-for="(agreement, index) in caoData.agreements" :key="index" style="border: 1px solid #eee; background-color: #fff; margin: 10px; padding: 15px; border-radius: 5px" :class="[{'active-agreement' : (currentCaoAgreement == agreement.startDate)}]">                  
                  <p class="mb-1"><strong>{{ $t('admin.caoStart', $store.state.locale) }}:</strong> {{agreement.startDate | moment("D MMMM YYYY  ")}}</p>
                  <p class="mb-1"><strong>{{ $t('admin.fullTimeHours', $store.state.locale) }}:</strong> {{ agreement.fullTimeHours }}</p>
                  <p class="mb-1"><strong>{{ $t('admin.caoFreeDays', $store.state.locale) }}:</strong> {{ agreement.freeDays }} <i>({{ $t('admin.caoFreeDaysHint', $store.state.locale) }})</i></p>

                  <div class="mt-2" v-if="agreement.sicknessLeaveRelation">
                    <v-divider class="my-4"></v-divider>
                    <h4 class="mb-1">{{ $t('admin.caoLoseFreeDaysWhenSick', $store.state.locale) }}</h4>

                    <h5>{{ $t('admin.caoLeaveBuildup', $store.state.locale) }}</h5>
                    <p class="mb-0">{{ agreement.sicknessLeaveBuildupDays }} {{ $t('general.days', $store.state.locale) }} {{ $t('admin.caoA', $store.state.locale) }} {{ agreement.sicknessLeaveBuildupPeriod }}</p>
                    <p><i>{{ $t('admin.caoLeaveBuildupHint', $store.state.locale) }}</i></p>

                    <h5>{{ $t('admin.caoSurrenderBuildup', $store.state.locale) }}</h5>
                    <p class="mb-0">{{ agreement.sicknessLeaveSurrenderDays }} {{ $t('general.days', $store.state.locale) }} {{ $t('admin.caoOn', $store.state.locale) }} {{ agreement.sicknessLeaveSurrenderEvent }}</p>
                    <p class="ma-0"><i>{{ $t('admin.caoSurrenderBuildupHint', $store.state.locale) }}</i></p>

                    <v-card flat class="my-2 pa-2" v-if="agreement.extraFreeDaysAge && agreement.extraFreeDaysAge.length > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
                      <h5>{{ $t('admin.caoExtraDaysAge', $store.state.locale) }}</h5>
                      <v-divider class="my-2"></v-divider>
                      <v-layout class="pa-0 ma-0">
                        <v-flex md3><p class="ma-0"><strong>{{ $t('general.days', $store.state.locale) }}</strong></p></v-flex>
                        <v-flex md9><p class="ma-0"><strong>{{ $t('admin.caoAge', $store.state.locale) }}</strong></p></v-flex>
                      </v-layout>
                      <v-layout row wrap v-for="(ageItem, index) in agreement.extraFreeDaysAge" class="pa-0 ma-0">
                        <v-flex md3>
                          {{ ageItem.days }}
                        </v-flex>
                        <v-flex md9>
                          {{ ageItem.startAge }} - {{ ageItem.endAge }}
                        </v-flex>
                      </v-layout>
                    </v-card>


                    <v-card flat class="my-2 pa-2" v-if="agreement.extraFreeDaysEmployment && agreement.extraFreeDaysEmployment.length > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
                      <h5>{{ $t('admin.caoExtraDaysEmployment', $store.state.locale) }}</h5>
                      <v-divider class="my-2"></v-divider>
                      <v-layout class="pa-0 ma-0">
                        <v-flex md3><p class="ma-0"><strong>{{ $t('general.days', $store.state.locale) }}</strong></p></v-flex>
                        <v-flex md9><p class="ma-0"><strong>{{ $t('admin.employmentContractYears', $store.state.locale) }}</strong></p></v-flex>
                      </v-layout>
                      <v-layout row wrap v-for="(item, index) in agreement.extraFreeDaysEmployment" class="pa-0 ma-0">
                        <v-flex md3>
                          {{ item.days }}
                        </v-flex>
                        <v-flex md9>
                          {{ item.employmentYearsStart }} - {{ item.employmentYearsEnd }}
                        </v-flex>
                      </v-layout>
                    </v-card>
                          
                  </div>
                </div>
              </div>

              <div v-else class="text-center">
                <p class="pa-4 pb-2 map-0">{{ $t('admin.noCaoAgreements', $store.state.locale) }}</p>
              </div>

              <div class="text-center pa-4 pt-0 map-0">
                <v-btn fab dark small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addCaoAgreement()">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                @click="closeCaoDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn
                :dark="changed"
                :depressed="!changed"
                :disabled="!changed"
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="saveCao()"
                >
                <span v-if="type== 'new'">{{ $t('admin.addNewCao', $store.state.locale) }}</span> 
                <span v-else>{{ $t('general.save', $store.state.locale) }}</span>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>

    <!-- / Add CAO Agreement Dialog \ -->
    <v-dialog v-model="caoAgreementDialog" scrollable max-width="500px" persistent :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3 v-if="type == 'new'">{{ $t('admin.addNewCaoAgreement', $store.state.locale) }} </h3>
          <h3 v-if="type == 'edit'">{{ $t('admin.editCaoAgreement', $store.state.locale) }} </h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeCaoAgreementDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-alert v-if="caoAgreement.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" v-html="caoAgreement.feedback " dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning"></v-alert>

        <v-divider></v-divider>

        <v-card-text class="mt-2 pa-5">
          <v-layout row wrap v-if="caoData" class="pa-2">
            <v-flex md12>
              <v-menu v-model="chosenDateDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="caoAgreementStart.readable"
                    :label="$t('admin.caoStart', $store.state.locale)"
                    prepend-inner-icon="mdi-calendar-today"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" no-title scrollable :first-day-of-week="1" v-model="caoAgreement.startDate" @input="chosenDateDialog = false"></v-date-picker>
              </v-menu>

              <v-text-field
                class="mt-4"
                :label="$t('admin.fullTimeHours', $store.state.locale)"
                v-model.number="caoAgreement.fullTimeHours"
                autocomplete
                outlined
                required
                dense
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                >
              </v-text-field>

              <v-text-field
                class="mt-4"
                :label="$t('admin.caoFreeDays', $store.state.locale)"
                v-model.number="caoAgreement.freeDays"
                autocomplete
                outlined
                required
                dense
                persistent-hint
                :hint="$t('admin.caoFreeDaysHint', $store.state.locale)"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                >
              </v-text-field>

              <v-switch
                inset 
                class="ma-0 ml-2 mt-4 pa-0"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :label="`${$t('admin.caoLoseFreeDaysWhenSick', $store.state.locale)}?`"
                v-model="caoAgreement.sicknessLeaveRelation"
                hide-details
              ></v-switch>

              <v-card v-if="caoAgreement.sicknessLeaveRelation" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-4 pa-4">
                <div>
                  <h4>{{ $t('admin.caoLeaveBuildup', $store.state.locale) }}</h4>
                  <p>{{ $t('admin.caoLeaveBuildupHint', $store.state.locale) }}</p>

                  <v-layout row wrap v-if="caoData" class="pa-2">
                    <v-flex md3>
                      <v-text-field
                        :label="$t('general.days', $store.state.locale)"
                        v-model.number="caoAgreement.sicknessLeaveBuildupDays"
                        autocomplete
                        outlined
                        required
                        dense
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        >
                      </v-text-field>
                    </v-flex>
                    <v-flex md2 class="text-center pt-2">{{ $t('admin.caoA', $store.state.locale) }}</v-flex>
                    <v-flex md7>
                      <v-select
                        outlined
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        dense
                        :items="leaveBuildupPeriod"
                        :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                        item-value="id"
                        v-model="caoAgreement.sicknessLeaveBuildupPeriod"
                        :menu-props="{ maxHeight: '300' }"
                        :label="$t('admin.caoLeaveBuildupPeriod', $store.state.locale)"
                        hide-details
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </div>

                <div class="mt-4">
                  <h4>{{ $t('admin.caoSurrenderBuildup', $store.state.locale) }}</h4>
                  <p>{{ $t('admin.caoSurrenderBuildupHint', $store.state.locale) }}</p>

                  <v-layout row wrap v-if="caoData" class="pa-2">
                    <v-flex md3>
                      <v-text-field
                        :label="$t('general.days', $store.state.locale)"
                        v-model.number="caoAgreement.sicknessLeaveSurrenderDays"
                        autocomplete
                        outlined
                        required
                        dense
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        >
                      </v-text-field>
                    </v-flex>
                    <v-flex md2 class="text-center pt-2">{{ $t('admin.caoOn', $store.state.locale) }}</v-flex>
                    <v-flex md7>
                      <v-select
                        outlined
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        dense
                        :items="leaveSurrenderEvents"
                        :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                        item-value="id"
                        v-model="caoAgreement.sicknessLeaveSurrenderEvent"
                        :menu-props="{ maxHeight: '300' }"
                        :label="$t('admin.caoEvent', $store.state.locale)"
                        hide-details
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card>

              <v-switch
                inset 
                class="ma-0 ml-2 mt-4 pa-0"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :label="`${$t('admin.caoExtraDaysAge', $store.state.locale)}`"
                v-model="caoAgreement.ageRelation"
                hide-details
              ></v-switch>

              <v-card v-if="caoAgreement.ageRelation" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-4 pa-4">
                <div>
                  <h4>{{ $t('admin.caoExtraDaysAge', $store.state.locale) }}</h4>
                  <p>{{ $t('admin.caoExtraDaysAgeHint', $store.state.locale) }}</p>

                  <v-card flat class="mb-2" v-if="caoAgreement.extraFreeDaysAge && caoAgreement.extraFreeDaysAge.length > 0">
                    <v-layout row wrap v-for="(ageItem, index) in caoAgreement.extraFreeDaysAge" class="pa-2 ma-0">
                      <v-flex md3>
                        <v-text-field
                          class="pr-1"
                          :label="$t('general.days', $store.state.locale)"
                          v-model.number="ageItem.days"
                          autocomplete
                          outlined
                          required
                          dense
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          >
                        </v-text-field>
                      </v-flex>
                      <v-flex md4>
                        <v-select
                          class="pr-1"
                          outlined
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          dense
                          :items="ages"
                          v-model="ageItem.startAge"
                          :menu-props="{ maxHeight: '300' }"
                          :label="$t('admin.caoStartAge', $store.state.locale)"
                          hide-details
                        ></v-select>
                      </v-flex>
                      <v-flex md4>
                        <v-select
                          outlined
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          dense
                          :items="ages"
                          v-model="ageItem.endAge"
                          :menu-props="{ maxHeight: '300' }"
                          :label="$t('admin.caoEndAge', $store.state.locale)"
                          hide-details
                        ></v-select>
                      </v-flex>
                      <v-flex md1 class="text-center pt-2">
                        <v-btn icon small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="removeAgeSlot(index)"><v-icon size="20">mdi-delete</v-icon></v-btn>

                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div class="text-center">
                  <v-btn fab small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="extraAgeSlot()"><v-icon>mdi-plus</v-icon></v-btn>
                </div>
              </v-card>

              <v-switch
                inset 
                class="ma-0 ml-2 mt-4 pa-0"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :label="`${$t('admin.caoExtraDaysEmployment', $store.state.locale)}`"
                v-model="caoAgreement.employmentRelation"
                hide-details
              ></v-switch>

              <v-card v-if="caoAgreement.employmentRelation" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-4 pa-4">
                <div>
                  <h4>{{ $t('admin.caoExtraDaysEmployment', $store.state.locale) }}</h4>
                  <p>{{ $t('admin.caoExtraDaysEmploymentHint', $store.state.locale) }}</p>

                  <v-card flat class="mb-2" v-if="caoAgreement.extraFreeDaysEmployment && caoAgreement.extraFreeDaysEmployment.length > 0">
                    <v-layout row wrap v-for="(employmentItem, index) in caoAgreement.extraFreeDaysEmployment" class="pa-2 ma-0">
                      <v-flex md3>
                        <v-text-field
                          class="pr-1"
                          :label="$t('general.days', $store.state.locale)"
                          v-model.number="employmentItem.days"
                          autocomplete
                          outlined
                          required
                          dense
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          >
                        </v-text-field>
                      </v-flex>
                      <v-flex md4>
                        <v-select
                          class="pr-1"
                          outlined
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          dense
                          :items="employmentYears"
                          v-model="employmentItem.employmentYearsStart"
                          :menu-props="{ maxHeight: '300' }"
                          :label="$t('admin.caoStartEmploymentYears', $store.state.locale)"
                          hide-details
                        ></v-select>
                      </v-flex>
                      <v-flex md4>
                        <v-select
                          outlined
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          dense
                          :items="employmentYears"
                          v-model="employmentItem.employmentYearsEnd"
                          :menu-props="{ maxHeight: '300' }"
                          :label="$t('admin.caoEndEmploymentYears', $store.state.locale)"
                          hide-details
                        ></v-select>
                      </v-flex>
                      <v-flex md1 class="text-center pt-2">
                        <v-btn icon small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="removeEmploymentSlot(index)"><v-icon size="20">mdi-delete</v-icon></v-btn>

                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div class="text-center">
                  <v-btn fab small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="extraEmploymentSlot()"><v-icon>mdi-plus</v-icon></v-btn>
                </div>
              </v-card>


            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                  @click="closeCaoAgreementDialog()"
                  >
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md8 class="text-right">
                <v-btn
                  dark
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  @click="saveCaoAgreement()"
                  >
                  {{ $t('admin.addCaoAgreements', $store.state.locale) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Add CAO Agreement Dialog / -->

  </v-dialog>
</template>

<script>
import db from "../../firebase/init";
import '@firebase/firestore';
import { bus } from "../../main";
import moment from "moment";

export default {
  name: "cao-form",
  data() {
    return {
      newCaoDialog: false,
      chosenDateDialog: false,
      chosenDate: null,
      type: "new",
      selectedLanguage: null,
      caoData: null,
      caoDataFields: {
        name: new Object(),
        description: new Object(),
        agreements: new Array()
      },
      caoAgreement: {
        feedback: null,
        freeDays: null,
        type: null,
        fullTimeHours: null,
        startDate:null,
        sicknessLeaveRelation: false,
        ageRelation: false,
        extraFreeDaysAge:new Array(),
        employmentRelation: false,
        extraFreeDaysEmployment:new Array(),
        sicknessLeaveBuildupDays: null,
        sicknessLeaveBuildupPeriod: "month",
        sicknessLeaveSurrenderDays: null,
        sicknessLeaveSurrenderEvent: "firstSickReport",
        officialFreeDays:new Array()
      },
      ages:[16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,,68,69,70],
      employmentYears: [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,26,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60],
      leaveSurrenderEvents: [
        {
          id: 'firstSickReport',
          name: {
            nl: "Eerste ziekmelding",
            en: "First Sick Report"
          }
        },
        {
          id: 'secondSickReport',
          name: {
            nl: "Tweede ziekmelding",
            en: "Second Sick Report"
          }
        },
      ],
      leaveBuildupPeriod: [
        {
          id: 'month',
          name: {
            en: "Month",
            nl: "Maand"
          }
        },
        {
          id: 'quarter',
          name: {
            en: "Quarter",
            nl: "Kwartaal"
          }
        },
        {
          id: 'halfYear',
          name: {
            en: "Half year",
            nl: "Half jaar"
          }
        },
        {
          id: 'year',
          name: {
            en: "Year",
            nl: "Jaar"
          }
        }
      ],
      caoAgreementDialog: false,
      caoDataOriginal: null,
      feedback: null
    };
  },

  created(){
    bus.$on("editCao", (data, type) => {
      this.selectedLanguage = this.$store.state.locale.toLowerCase(); // Set language to user default language
      this.type = type; // Set type edit or new
      this.caoData = data ? data : _.cloneDeep(this.caoDataFields); 
      this.caoDataOriginal = _.cloneDeep(this.caoData); 
      this.newCaoDialog = true;
    });
  },

  methods: {
    closeCaoDialog() { // Close main dialog en reset data
      this.$emit("updateData");
      this.newCaoDialog = false;
      this.caoData = _.cloneDeep(this.caoDataFields);
    },

    addCaoAgreement(){ // Open dialog
      this.caoAgreement.type = "new";
      this.caoAgreementDialog = true;
    },

    closeCaoAgreementDialog(){ // Close dialog and reset data
      this.caoAgreementDialog = false;
      this.caoAgreement.feedback = null;
      this.caoAgreement.freeDays = null;
      this.caoAgreement.fullTimeHours = null;
      this.caoAgreement.startDate = null;
      this.caoAgreement.sicknessLeaveRelation = false;
      this.caoAgreement.sicknessLeaveBuildupDays = null;
      this.caoAgreement.sicknessLeaveBuildupPeriod = "month";
      this.caoAgreement.sicknessLeaveSurrenderDays = null;
      this.caoAgreement.sicknessLeaveSurrenderEvent = "firstSickReport";
    },


    extraAgeSlot(){
      this.caoAgreement.extraFreeDaysAge.push({
        days:0,
        startAge: null,
        endAge: null,
      })
    },

    removeAgeSlot(index){
      if (index >= 0) this.caoAgreement.extraFreeDaysAge.splice(index, 1)
    },

    extraEmploymentSlot(){
      this.caoAgreement.extraFreeDaysEmployment.push({
        days:null,
        employmentYearsStart: null,
        employmentYearsEnd: null,
      })
    },

    removeEmploymentSlot(index){
      if (index >= 0) this.caoAgreement.extraFreeDaysEmployment.splice(index, 1)
    },


    saveCaoAgreement(){
      this.caoAgreement.feedback = null;
      let validate = ['freeDays','fullTimeHours', 'startDate', 'sicknessLeaveBuildupDays','sicknessLeaveSurrenderDays']
      let invalid = new Array()
      
      validate.forEach(field=>{ // Check required field
        if(!this.caoAgreement.sicknessLeaveRelation){
          if(field != 'sicknessLeaveBuildupDays' && field != 'sicknessLeaveSurrenderDays'){
            if(!this.caoAgreement[field]){
              invalid.push("<strong>" + this.$t(`admin.${field}Label`, this.$store.state.locale) + "</strong>");
            }
          }
        }
        else{
          if(!this.caoAgreement[field]){
            invalid.push("<strong>" + this.$t(`admin.${field}Label`, this.$store.state.locale) + "</strong>");
          }
        }
      })
      
      if(invalid.length == 0){
        let caoAgreementData = _.cloneDeep(this.caoAgreement)
        this.caoData.agreements.push(caoAgreementData);
        this.caoData.agreements = this.caoData.agreements.sort((a,b) => b.startDate > a.startDate ? 1 : -1);
        this.closeCaoAgreementDialog();
      }
      else{
        const formatter = new Intl.ListFormat(this.$store.state.locale.toLowerCase(), { style: 'long', type: 'conjunction' });
        this.caoAgreement.feedback = this.$t("admin.caoAgreementMissingField", this.$store.state.locale) + formatter.format(invalid);
      }
    },

    async saveCao() { // Add or update Firebase Document
      if (this.caoData.name && this.caoData.description && this.caoData.agreements && this.caoData.agreements.length > 0) {
        if(this.type == 'new'){
          await this.addCao()
        }
        else{
          await this.updateCao()
        }
        this.$emit("updateData");
        this.closeCaoDialog();
        
      }
      else {
        this.feedback = this.$t("admin.saveCaoIncomplete", this.$store.state.locale) ;
      }
    },
    addCao(){ // Add new Firebase document for CAO
      db.collection("cao").add({
        name: this.caoData.name,
        description: this.caoData.description,
        agreements: this.caoData.agreements,
        created: new Date(),
        lastUpdated: new Date(),
      })
      .catch(error => {
        console.error("Error adding CAO", error);
      });
      return
    },

    updateCao(){ // Update existing CAO doc in Firebase
      db.collection("cao").doc(this.caoData.id).set({
        name: this.caoData.name,
        description: this.caoData.description,
        agreements: this.caoData.agreements,
        lastUpdated: new Date(),
      },{merge: true})
      .catch(error => {
        console.error("Error adding CAO", error);
      });
      return
    },

    getFlagCode(selectedLanguage){
      return selectedLanguage ? this.$countries.find(language => { return language.id == selectedLanguage }).flagCode : "";
    }
  },

  computed: {
    selectedLanguageObject(){
      return this.selectedLanguage ? this.$countries.find(language => { return language.id == this.selectedLanguage }) : {languageName:new Object()};
    },

    availableLanguages(){
      return this.$countries.filter(language => { // Filter rich languages data from user languages
        if(language.userLanguage){return language.id }
      });
    },

    currentCaoAgreement(){ // Highlight current active CAO agreement based on startDate
      let today = moment().format("YYYY-MM-DD");
      let activeAgreements = new Array();

      if(this.caoData.agreements && this.caoData.agreements.length > 0){
        this.caoData.agreements.forEach(agreement=>{
          if(agreement.startDate < today){
            activeAgreements.push(agreement.startDate)
          }
        })
        let sortedAgreements = activeAgreements.sort(function(a,b){
          return b.startDate - a.startDate;
        });
        return sortedAgreements[0]
      }
      else{
        return null
      }
      
    },

    caoAgreementStart() {
      let date = {
        readable: null,
        formatted: "",
      };
      if (this.caoAgreement.startDate) {
        date.readable = moment(this.caoAgreement.startDate).format("D MMMM YYYY");
        date.formatted = moment(this.caoAgreement.startDate).format("YYYY-MM-DD");
        return date;
      } else {
        return date;
      }
    },

    companyLanguages() {
      return this.$store.state.companyLanguages ? this.$store.state.companyLanguages : "none";
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },
    changed() {
      if (_.isEqual(this.caoData, this.caoDataOriginal) == false && this.type == 'edit') {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>

<style>
.active-agreement{
  border: 2px solid var(--primary-color) !important;
}
</style>
