
<template>
  <div>
		<v-container grid-list-md class="pa-0 pt-4 ma-0">
      <v-layout row wrap>
        <v-flex xs12 class="pa-0">
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">

            <div v-if="loading">
              <div style="position: relative; padding: 200px 0;">
                <div class="thrive-loader"></div>
              </div>
            </div>

            <div v-else>   
              <div class="pa-0 pt-4 ma-0">
                <v-data-table
                    :headers="headers"
                    single-expand
                    :items="connectionItems"
                    hide-default-footer
                    :itemsPerPage.sync="connectionPages"
                    :style="{'background-color' : $themes[$store.state.companyTheme][$store.state.themeModus].card}"
                    dense
                    class="dense-table"
                  >
                  <template v-slot:item="{ item }">
                    <tr style="height: 55px">
                      <td>
                        <v-tooltip dark bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="px-2" :color="connectionHealth(item.id).color" small>mdi-checkbox-blank-circle</v-icon>
                          </template>
                          <span v-if="connectionHealth(item.id).active == 'healthy'">De koppeling is goed</span>
                          <span v-if="connectionHealth(item.id).active == 'unhealthy'">Er is iets mis met de koppeling</span>
                          <span v-if="connectionHealth(item.id).active == 'inactive'">De koppeling is niet actief</span>
                        </v-tooltip>
                      </td>
                      <td v-if="type == 'branch'">
                        <v-tooltip dark right>
                          <template v-slot:activator="{ on }">
                            <div v-on="on" style="display: inline-block">
                            <div style="display: inline-block;" class="mt-1">
                              <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="item.useBranchConnection" @change="changeConnectionType(item.id)" hide-details class="ma-0 mt-1 pa-0"></v-switch>
                            </div>
                            <v-chip v-if="item.useBranchConnection" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 mb-1 custom-chip-style">Vestiging</v-chip>
                            <v-chip v-else small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mr-2 mb-1 custom-chip-style">Algemeen</v-chip>
                            </div>
                          </template>
                          <span>Gebruik de algemene koppeling van het bedrijf of maak een eigen koppeling voor deze vestiging.</span>
                        </v-tooltip>
                      </td>
                      
                      <td>
                        <div class="connection-logo" :class="item.id"></div>
                        <!-- <strong>{{ item.name }}</strong> -->
                      </td>
                      <td>
                        <div v-if="item.accountname">
                          <b>{{ item.accountname }}</b>
                        </div>
                      </td>
                      <td>
                        <div v-if="connectionHealth(item.id).active != 'inactive' && item.timestamp" class="py-1">
                          <i>Gekoppeld op:</i><br>
                          <b>{{ item.timestamp }}u</b>
                        </div>
                      </td>  
                      <td>
                        <div v-if="item.id == 'mailchimp' && connectionHealth(item.id).active == 'healthy'">
                          <i>Deze koppeling kan verwijderd worden in Mailchimp.</i>
                        </div>

                        <div v-if="item.id == 'mollie' && connectionHealth(item.id).active == 'healthy'">
                          <i>Deze koppeling kan verwijderd worden in Mollie.</i>
                        </div>
                      </td>
                      <td>
                        <div v-if="item.id == 'mailchimp' && (type != 'branch'|| item.useBranchConnection)">
                          <v-btn class="my-2" block small depressed dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="!connections.mailchimp.accessToken || !connections.mailchimp.serverCode" @click="mailchimpAuth()">{{ $t('connections.connectMailchimp', $store.state.locale) }}</v-btn>
                          <!-- <v-btn class="my-2"  block small depressed  outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" v-else @click="clearConnection('mailchimp')">{{ $t('connections.removeConnections', $store.state.locale) }}</v-btn>        -->
                        </div> 
                        <div v-if="item.id == 'mollie' && (type != 'branch'|| item.useBranchConnection)">
                          <v-btn class="my-2" block small depressed dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="!connections.mollie.refreshToken" @click="mollieAuth()">{{ $t('connections.connectMollie', $store.state.locale) }}</v-btn>
                          <v-select
                            v-if="connections.mollie.refreshToken" 
                            :items="connections.mollie.profiles" 
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"    
                            item-text="name"
                            item-value="id"
                            hide-details
                            v-on:input="saveMollieProfile()"
                            v-model="connections.mollie.profileId"
                            label="Profile"
                            outlined
                            dense
                          >
                          </v-select>
                          <!-- <v-btn class="my-2"  block small depressed outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" v-else @click="clearConnection('mollie')">{{ $t('connections.removeConnections', $store.state.locale) }}</v-btn> -->
                        </div>      
                      </td>
                    </tr>

                  </template>
                </v-data-table>

          
                  <!-- EXAMPLE OTHER API -->
                  <!-- <v-layout row wrap>
                    <v-flex md4 xs12>
                      <div class="connection-status" :class="connectionHealth('example').active"></div>
                      <div class="connection-logo example"></div>
                    </v-flex>
                    <v-flex md8 xs12>
                      <v-text-field 
                        label="API-key" 
                        v-model.trim="connection.apiKey" 
                        :append-icon="showApi ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showApi = !showApi"
                        :type="showApi ? 'text' : 'password'"
                        autocomplete 
                        required
                        outlined
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        background-color="background"
                        hide-details
                        clearable
                        clear-icon="mdi-delete"
                      ></v-text-field>
                    </v-flex>
                  
                    <v-flex xs12>
                      <v-alert
                        dense
                        border="left"
                        type="warning"
                        :value="!connectionHealth('example').healthy"
                        >
                        {{ $t('settings.connectionWarning', $store.state.locale) }} 
                      </v-alert>
                    </v-flex>
                  </v-layout> -->
              </div>
            </div> 
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

  </div>
</template>

<script>
  import db from "@/firebase/init";
  import { bus } from "@/main";
  import slugify from "slugify";
  import axios from "axios";
  import moment from "moment";
  import firebase from "firebase/app";

  export default {
    props: {
      type: String,
    },

    name: "connections",
    data: () => ({
      activeCompData: new Object(),
      activeCompDataOriginal: new Object(), 
      activeBranchData: new Object(),
      activeBranchDataOriginal: new Object(), 
      updateConnectionSettingsBusy: false,
      loading: true,
      showApi: false,
      connections: new Array(),
      connectionsHealth: new Array(),
      changes: 0,
      connectionItems: new Array(),
      connectionPages: -1,
    }),

    created(){
      bus.$on("companyUpdate", this.checkConnectionData());
      this.checkConnectionData();
      // this.checkConnectionData(this.activeCompData)
    },

    methods: {
      mailchimpAuth(){
        let id = this.type == "company" ? this.activeCompData.id : this.activeBranchData.id;
        firebase.auth().currentUser.getIdToken(true)
        .then((idToken) => {
          axios.post(`${this.$store.state.connectionsApi.url}/auth/mailchimp`, {id: id, token: idToken})
          .then((response) => {
            window.open(response.data, "", "width=600,height=800,top=100,left=200");
          })
          .catch(response=>{
            console.error("Error connecting to Mailchimp", response);
          })
        })
      },

      mollieAuth(){
        let id = this.type == "company" ? this.activeCompData.id : this.activeBranchData.id;
        firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          axios.post(`${this.$store.state.connectionsApi.url}/auth/mollie`, {id: id, token: idToken})
          .then((response) => {
            window.open(response.data, "", "width=600,height=800,top=100,left=200");
          })
          .catch(response=>{
            console.error("Error connecting to Mollie", response);
          })
        });
      },

      checkConnectionData(){
        this.activeCompData = _.cloneDeep(this.$store.state.activeCompany);
        this.activeCompDataOriginal = _.cloneDeep(this.$store.state.activeCompany);
        this.activeBranchData = _.cloneDeep(this.$store.state.activeBranch);
        this.activeBranchDataOriginal = _.cloneDeep(this.$store.state.activeBranch);

        this.updateConnectionSettingsBusy = false;
        this.loading = true;
        this.connections = {
            mailchimp: {
              id: "mailchimp",
              name: "Mailchimp",
              accessToken: null,
              accountname: null,
              serverCode: null,
              timestamp: null,
              useBranchConnection: false
            },
            mollie: {
              id: "mollie",
              name: "Mollie",
              accountname: null,
              refreshToken: null,
              timestamp: null,
              profiles: new Array(),
              profileId: null,
              useBranchConnection: false
            }
        };
        
        // CHECK AND COMPARE COMPANY CONNECTIONS (SO NEW CONNECTIONS CAN BE CREATED)
        
        if((this.activeCompData.connections && (Object.keys(this.activeCompData.connections).length > 0))){
          new Promise((resolve)=>{
            let counter = 0;

            for (const [key] of Object.entries(this.activeCompData.connections)) {
              if(this.connections[key]){
                
                if(this.type == "branch"&& this.activeBranchData.connections && this.activeBranchData.connections[key] && this.activeBranchData.connections[key].useBranchConnection){ // LOAD BRANCH CONNECTION SETTINGS
                  this.connections[key].timestamp = this.activeBranchData.connections[key].timestamp && this.activeBranchData.connections[key].timestamp.seconds ? moment(this.activeBranchData.connections[key].timestamp.seconds, "X").format("D MMM YYYY H:mm") : null;
                  this.connections[key].accountname = this.activeBranchData.connections[key].accountname ? this.activeBranchData.connections[key].accountname : null;
                  this.connections[key].useBranchConnection = true;
                  if(this.activeBranchData.connections[key].serverCode){
                    this.connections[key].serverCode = this.activeBranchData.connections[key].serverCode
                  }

                  if(this.activeBranchData.connections[key].accessToken){
                    this.connections[key].accessToken = this.activeBranchData.connections[key].accessToken
                  }
                  else if(this.activeBranchData.connections[key].refreshToken){
                    this.connections[key].refreshToken = this.activeBranchData.connections[key].refreshToken
                  }

                  if(this.activeBranchData.connections[key].profileId){
                    this.connections[key].profileId = this.activeBranchData.connections[key].profileId
                  }
                }
                else{ // LOAD COMPANY CONNECTION SETTINGS
                  this.connections[key].timestamp = this.activeCompData.connections[key].timestamp && this.activeCompData.connections[key].timestamp.seconds ? moment(this.activeCompData.connections[key].timestamp.seconds, "X").format("D MMM YYYY H:mm") : null;
                  this.connections[key].accountname = this.activeCompData.connections[key].accountname ? this.activeCompData.connections[key].accountname : null;
                  if(this.activeCompData.connections[key].serverCode){
                    this.connections[key].serverCode = this.activeCompData.connections[key].serverCode
                  }

                  if(this.activeCompData.connections[key].accessToken){
                    this.connections[key].accessToken = this.activeCompData.connections[key].accessToken
                  }
                  else if(this.activeCompData.connections[key].refreshToken){
                    this.connections[key].refreshToken = this.activeCompData.connections[key].refreshToken
                  }

                  if(this.activeCompData.connections[key].profileId){
                    this.connections[key].profileId = this.activeCompData.connections[key].profileId
                  }
                }
              }
              counter++
              if(counter == Object.keys(this.activeCompData.connections).length){
                resolve()
              }
            }
          })
          .then(()=>{
            this.connectionItems = new Array();
            for (const [key] of Object.entries(this.connections)) {
              this.connectionItems.push(this.connections[key])
            }
            this.checkHealthStatus();
          })
        }
        else{ // No Connectionfs found 
          this.connectionItems = new Array();
          for (const [key] of Object.entries(this.connections)) {
            this.connectionItems.push(this.connections[key])
          }
          this.loading = false;
        }
      },

      checkHealthStatus(){
        let id = this.type == "company" ? this.activeCompData.id : this.activeBranchData.id;
        this.connectionsHealth = new Array();
        let connections = Object.keys(this.connections)

        if(connections.length > 0){
          let counter = 0;
          new Promise(resolve=>{
            connections.forEach(key => {
              let information = {
                id: key,
                healthy: true,
                active: false
              }

              // API KEY IS PROVIDED SO CONNECTIONS IS ACTIVE
              if(key == "mailchimp" && this.connections[key].accessToken && this.connections[key].serverCode){
                axios.post(`${this.$store.state.connectionsApi.url}/mailchimp/health`, {id: id, type: this.type, accessToken: (this.connections[key].accessToken), server: this.connections[key].serverCode})
                .then((response) => {
                  if(response.this.activeCompData.health_status == "Everything's Chimpy!"){
                    information.active = true;
                    information.healthy = true;
                  }
                  else{
                    information.active = true;
                    information.healthy = false;
                  }
                  this.connectionsHealth.push(information)
                  counter++
                  if(counter == connections.length){
                    resolve();
                  }
                })
                .catch(error=> {  
                  console.error("Error getting Mailchimps Health ", error)
                  this.connectionsHealth.push(information)
                  counter++
                  if(counter == connections.length){
                    resolve();
                  }
                })
              }
              else if(key == "mollie" && this.connections[key].refreshToken){
                firebase
                .auth()
                .currentUser.getIdToken(true)
                .then((idToken) => {
                  axios.post(`${this.$store.state.connectionsApi.url}/mollie/refresh`, {id: id, token: idToken, type: this.type, refreshToken:this.connections[key].refreshToken})
                  .then((response) => {
                  // console.log("Mollie Refresh Response: ", response)
                    if(response.status == 200){
                      information.active = true;
                      information.healthy = true;
                    }
                    else{
                      information.active = true;
                      information.healthy = false;
                    }
                    this.connectionsHealth.push(information)
                    counter++
                    if(counter == connections.length){
                      resolve();
                    }
                  })
                  .catch(error=> {  
                    console.error("Error refreshing Mollie", error)
                    this.connectionsHealth.push(information)
                    counter++
                    if(counter == connections.length){
                      resolve();
                    }
                  })
                })

                this.mollieProfiles()
              }
          
              else{
                // NO API KEY IS PROVIDED
                this.connectionsHealth.push(information)
                counter++
                if(counter == connections.length){
                  resolve();
                }
              }

            });
          })
          .then(()=>{
            // console.log("checkHealthStatus resolved")
            this.changes++;
            this.loading = false;
          })
        }
        else{
          this.changes++;
          this.loading = false;
        }
      },

      mollieProfiles(){
        this.connections.mollie.profiles = new Array()
        let id = this.type == "company" ? this.activeCompData.id : this.activeBranchData.id;
        firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          axios.post(`${this.$store.state.connectionsApi.url}/mollie/profiles`, {token: idToken, refreshToken: this.connections.mollie.refreshToken})
          .then((response) => {
            if(response.data._embedded.profiles && response.data._embedded.profiles.length > 0){
              let counter = 0;
              new Promise((resolve)=>{
                
                response.data._embedded.profiles.forEach((profile)=>{
                let data = {
                  name: profile.name,
                  id: profile.id,
                }
                this.connections.mollie.profiles.push(data)
                counter++
                if(counter == response.data._embedded.profiles.length){
                  resolve()
                }
              })
              })
              .then(()=>{
                if(!this.connections.mollie.profileId){
                  this.connections.mollie.profileId = this.connections.mollie.profiles[0].id;
                  this.saveMollieProfile();
                }
              })
            }
          })
          .catch(response=>{
            console.error("Error getting Mollie Profiles", response);
          })
        });
      },

      saveMollieProfile(){
        let type = this.type == "company" ? "companies" : "branches";
        let id = this.type == "company" ? this.activeCompData.id : this.activeBranchData.id;
        db.collection(type) // Update database document
        .doc(id)
        .set({
          connections: {
            mollie: {
              profileId: this.connections.mollie.profileId 
            }
          }
        },{ merge: true})
        .catch(error=>{
          console.error("Error updating doc", error)
        })
      },


      async clearConnection(key){
        let id = this.type == "company" ? this.activeCompData.id : this.activeBranchData.id;
        // console.log("Clearing")
        this.changes++
        if(key == "mollie"){
          firebase.auth().currentUser.getIdToken(true)
          .then((idToken) => {
            axios.post(`${this.$store.state.connectionsApi.url}/mollie/revoke`, {id: id, token: idToken, type: this.type, refreshToken:this.connections[key].refreshToken})
            .then((response) => {
                console.log("Mollie connection revoked")
            })
            .catch(error=> {  
              console.error("Error revoking Mollie", error)
            })
          })
        }
        // this.connections[key].accessToken = null;
        // if(this.connections[key].serverCode ){
        //   this.connections[key].serverCode = null;
        // }
        // if(this.connections[key].accessToken ){
        //   this.connections[key].accessToken = null;
        // }
        // if(this.connections[key].refreshtoken ){
        //   this.connections[key].refreshtoken = null;
        // }

        // db.collection("companies")
        // .doc(this.activeCompData.id)
        // .set({
        //   connections: this.connections,
        // }, { merge: true })
        // .then(()=>{
        //   this.checkHealthStatus();
        // });

        await this.$store.dispatch("updateActiveCompanyData", this.activeCompanyData.id);
        this.activeCompDataOriginal = _.cloneDeep(this.$store.state.activeCompany);
      },
      
      connectionHealth(id){
        let information = {
          active: "inactive",
          color: "#cccccc",
          healthy: true
        }

        let connection = _.find(this.connectionsHealth, ['id', id]);
        if(connection && connection.active){
          if(connection.healthy){
            information.active = "healthy";
            information.color = "#56ca00";
            return information;
          }
          else{
            information.active = "unhealthy";
            information.color = "#ff4c51"

            information.healthy = false;
            return information;
          }
        }
        else{
          return information;
        }
      },

      async changeConnectionType(id){
        // console.log("changeConnectionType: ", id)
        if(!this.activeBranchData.connections){
          this.activeBranchData.connections = new Object();
        }
        if(!this.activeBranchData.connections[id]){
          this.activeBranchData.connections[id] = new Object();
        }

        if(this.activeBranchData.connections[id].useBranchConnection){
          this.activeBranchData.connections[id].useBranchConnection = false
        }
        else{
          this.activeBranchData.connections[id].useBranchConnection = true
        }
        this.loading = true;

        await db.collection("branches").doc(this.activeBranchData.id)
        .set({
          connections: this.activeBranchData.connections,
        }, { merge: true });

        await this.$store.dispatch("updateActiveBranchData", this.activeBranchData.id);
        this.activeBranchDataOriginal = _.cloneDeep(this.$store.state.activeBranch);

        this.checkConnectionData()
      }
      
    },
    computed: {
      
      headers(){
        let connectionHeaders = [
          {
            text: "",
            align: "left",
            width: "80px",
            sortable: false,
          },
          {
            text: "Koppeling",
            align: "left",
            sortable: false,
            width: "200px",
          },
          {
            text: "Account",
            align: "left",
            sortable: false,
            width: "160px",
          },
          {
            text: "",
            align: "left",
            sortable: false,
            width: "160px",
          },
          {
            text: "",
            align: "left",
            sortable: false,
          },
          {
            text: "Acties",
            align: "left",
            sortable: false,
            width: "300px"
          }
        ];
        
        if(this.type == 'branch'){
          connectionHeaders.splice(1,0, {
            text: "Type koppeling",
            align: "left",
            sortable: false,
            width: "180px",
          })
        }
        return connectionHeaders
      }, 
      changed(){
        if( _.isEqual(this.connections, this.activeCompDataOriginal.connections) == false){
          return true;
        } else{
          return false;
        }
      },

    }
  };
</script>

<style scoped>
  .connection-logo{
    float: left;
    width: 100px;
    height: 33px;
  }
  .connection-logo.mailchimp{
      background: url("../assets/connections/mailchimp-logo-horizontal.png") no-repeat left center;
      background-size: 100px auto;
  }

  .connection-logo.mollie{
      background: url("../assets/connections/mollie-logo.png") no-repeat left center;
      background-size: 50px auto;
  }

  .connection-status{
    float: left;
    margin: 16px 20px 0 0;
    width: 18px;
    height: 18px;
    border: 3px solid #eeee;
    border-radius: 20px;
  }

  .connection-status.healthy{
    border-color: #73db02;
  }

  .connection-status.unhealthy{
    border-color: #f48c00;
  }
</style>
