import axios from 'axios';
import store from "../store";
import db from "../firebase/init";

// GENERAL FUNCTION CREATING NEW CLIENT OR FINDING EXISTING CLIENT (AND RETURNING ID)

export function createNewClient(clientData, type) {

  async function createClient(clientData){
    let clientId = null;

    const configAxiosPOST = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let jsonOBJ = {
      companyId: store.state.activeCompany.id,
      clientEmail: clientData.email,
      clientFirstName: clientData.firstName,
      clientLastName: clientData.surname,
      clientPhone: clientData.phones.length > 0 ? clientData.phones[0].phone.significant : "",
    };

    console.log("jsonOBJ: ", jsonOBJ)

    await new Promise((resolve) => {
      axios.post(`${store.state.restApi.url}/postClientExist`, jsonOBJ, configAxiosPOST)
      .then((response) => {
        // Client does not exist
        console.log("response.data.typeExist :", response.data.typeExist)
        if (response.data.typeExist == 2) {
          // Create Client
          if(!type || type != "dryrun"){
            db.collection("clients")
            .add({
              name: clientData.firstName,
              surname: clientData.surname,
              emails: clientData.emails,
              phones: clientData.phones,
              language: clientData.language,
              companyId: store.state.activeCompany.id,
              gender: clientData.gender,
              emailSubscriptions: {
                newsletter: false,
              },
              address: {},
              permissions: {},
            })
            .then((docRef) => {
              clientId = docRef.id;
              resolve();
            });
          } 
          else{
            clientId = 'newClient'
          }  
        }
        
        else if (response.data.typeExist == 0) { // Client bestaat al,
          clientId = response.data.clientId; // Set client Id
          resolve();
        }
      })
      .catch((err) => {
        console.error("Error axios postClientExist =>", err);
        resolve();
      });
    });
    return clientId
  }

  return createClient(clientData, type)
}