<script>
import moment from "moment";
import db from "../firebase/init";
import { bus } from "../main";
import addClientForm from "@/components/modals/addClientForm.vue";
// import editClientForm from "@/components/modals/editClientForm.vue";


export default {
	name: "client-form-overview",
	components: {
		"add-client-form": addClientForm,
    	// "edit-form": editClientForm,
	},
	props: {
		clientInfo: Object,
		loadedVia: String, //Via Client Card Calendar or Via other Menu?
	},

	data: () => ({
		addFormDialog: false,
    	formDialog: false,
		loadingTemplate: false,

		loadedTemplates: [
			{
				name: {
					nl: "Alles",
					en: "All",
				},
				id: "all",
			},
		],

		chosenFormTemplateId: "all",
		clientForms: [],

		chosenTemplate: {},
		existingDoc: null,
		clientFormsHeader: [
			{
				text: "Naam",
				align: "left",
				sortable: false,
				value: "name",
			},

			{
				text: "Laatste wijziging",
				align: "left",
				sortable: true,

				value: "updated",
			},
			{
				text: "Toegevoegd door",
				align: "left",
				sortable: false,
				value: "created",
			},
			{
				text: "Type",
				align: "left",
				sortable: true,
				value: "typeForm",
			},
			{
				text: "",
				width:"50px",
				align: "left",
				sortable: false,
				value: "delete",
			},
			{
				text: "",
				width:"50px",
				align: "left",
				sortable: false,
				value: "edit",
			}
		],
    templateHeader: [
			{
				text: "Naam",
				align: "left",
				sortable: false,
				value: "name",
			},

			{
				text: "Laatste wijziging",
				align: "left",
				sortable: true,

				value: "updated",
			},
			{
				text: "Door",
				align: "left",
				sortable: false,
				value: "created",
			},
			{
				text: "Type",
				align: "left",
				sortable: false,
				value: "typeForm",
			},
      	{
				text: "Filialen",
				align: "left",
				sortable: false,
				value: "branches",
			},
			{
				text: "",
				width:"50px",
				align: "left",
				sortable: true,
				value: "edit",
			}
		],
	}),

	created() {
		
	},
  mounted() {

    if(this.loadedVia !== "companySettings"){
      bus.$on("loadClientForms", (empty) => {
			console.log("REFRESH!!");
			this.getFormTemplateAndClientForms();
		});
    } else {
      this.getFormTemplateAndClientForms();
    }
    
  },

	methods: {
		//Change the chosen template
		changeFormTemplate() {
			this.chosenTemplate = this.loadedTemplates.find((loadedTemplate) => loadedTemplate.id === this.chosenFormTemplateId);
			console.log("Change", this.chosenFormTemplateId, this.chosenTemplate);
		},

		openAddForm(newOrExisiting) {
			this.addFormDialog = true;

			//New or Existing so addClientForm knows what to do with it.
			if (newOrExisiting === "new") {
				this.existingDoc = null;

				this.chosenTemplate = new Object();
				//Find the chosen template by id via this.existingDoc and match id
				this.chosenTemplate = this.loadedTemplates.find((loadedTemplate) => loadedTemplate.id === this.chosenFormTemplateId);

				bus.$emit("newOrExisting", this.chosenTemplate, null);
			} else {
				//ExistingDoc

				this.chosenTemplate = new Object();

				//Find the chosen template by id via this.existingDoc and match id
				this.chosenTemplate = this.loadedTemplates.find((loadedTemplate) => loadedTemplate.id === this.existingDoc.chosenTemplateId);

				//If chosenTemplate is not undefined, bus emit. If undefined change this.chosenTemplate to existingDoc;
				if (typeof this.chosenTemplate !== "undefined") {
					console.log("chosenTemplate is defined");
					bus.$emit("newOrExisting", this.chosenTemplate, this.existingDoc);
				} else {
					console.log("chosenTemplate", this.chosenTemplate);
					this.chosenTemplate = this.existingDoc;
					console.log("chosenTemplate is undefined Switch to Existing Doc");
					bus.$emit("newOrExisting", this.chosenTemplate, this.existingDoc);
				}
			}
		},

    openEditForm(newOrExisiting, existingTemplate) {
			this.formDialog = true;

			//New or Existing so addClientForm knows what to do with it.
			
				console.log(newOrExisiting, existingTemplate)
				bus.$emit("newOrExistingEditForm", existingTemplate, null); //Just load Template


		
		},

		deleteForm(item) {
			console.log("Delete Form", item);
			//Delete from Firebase by item.id
			db.collection("forms")
				.doc(item.id)
				.delete()
				.then(() => {
					console.log("Document successfully deleted!");
					//remove from this.clientForms
					this.clientForms = this.clientForms.filter((clientForm) => clientForm.id !== item.id);
				})
				.catch((error) => {
					console.error("Error removing document: ", error);
				});
		},

		async getFormTemplateAndClientForms() {
			this.clientForms = new Array();
      this.loadedTemplates = new Array();
      if(this.loadedVia !== "companySettings") {
        this.loadedTemplates = [
				{
					name: {
						nl: "Alles",
						en: "All",
					},
					id: "all",
				},
			];
      }
			

			this.loadingTemplate = true;

			//All branches True

			console.log("Get All Companies Form Templates");
			let templateCompanies = 0;
			const formTemplates = await db
				.collection("formTemplates")
				.where("allCompanies", "==", true)
				.where("active", "==", true)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						let objTemplate = doc.data();
						objTemplate.id = doc.id;

						this.loadedTemplates.push(objTemplate);
						templateCompanies++;
					});
				})
				.then(() => {
					console.log("All Companies Form Templates loaded:", templateCompanies);
					return true;
				})
				.catch(function (error) {
					console.error("Error getting document Form Templates:", error);
				});

      if(this.loadedVia !== "companySettings") {

			console.log("Get Client Forms", this.clientInfo.id, this.$store.state.activeCompany.id)
        const clientForms = await db
				.collection("forms")
				.where("clientId", "==", this.clientInfo.id)
				.where("companyId", "==", this.$store.state.activeCompany.id)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						let objForm = doc.data();
						objForm.id = doc.id;

						this.clientForms.push(objForm);
					});
				})
				.then(() => {
					console.log("All Client Forms loaded:", this.clientForms.length);

					return true;
				})
				.catch(function (error) {
					console.error("Error getting document Client Forms:", error);
				});

			Promise.all([formTemplates, clientForms]).then(() => {
				this.loadingTemplate = false;
				console.log("All Forms and Form Templates loaded");
			});

      } else {
        Promise.all([formTemplates]).then(() => {
				this.loadingTemplate = false;
				console.log("All Form Templates loaded");
			});
      }

			
		},
    getBranchName(arrayOfBranchIds, allBranches) {

      if(allBranches === true) {
        return this.feedback = this.$t('all', this.$store.state.locale);
      
      }

      //Return a array of branch names
      let arrayOfBranchNames = new Array();
      arrayOfBranchIds.forEach((branchId) => {
        let branchName = this.$store.state.activeCompany.branches.find((branch) => branch.id === branchId).name;
        arrayOfBranchNames.push(branchName);
      });

      //array of branch names to string 
      let branchNames = arrayOfBranchNames.join(", ");
      return branchNames;

    },
	},

	computed: {
		userLanguage() {
			return this.$store.state.locale ? this.$store.state.locale : "NL";
		},
		userCompanies() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
		},
		userCompany() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
		},
		userFirstName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
		},
		userLastName() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
		},
		userRole() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
		},
		userId() {
			return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
		}
	},
};
</script>

<template>
	<div>
		<v-layout >
			<v-flex xs6></v-flex>
			<v-flex xs6 style="min-height: 40px;"></v-flex>
		</v-layout>
		<v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-1" :class="$store.state.companyTheme">
			<div class="pa-3">
				<v-avatar
				size="36"
				light
				style="float: left"
				class="ma-0 mr-2 custom-avatar-style" 
				rounded="lg"
				:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
				:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
				>
				<v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon>
				</v-avatar>
				<h3 class="pt-1 pb-4" v-if="loadedVia == 'companySettings'">Formulieren bewerken</h3>
				<h3 class="pt-1 pb-4" v-else>Formulieren</h3>

				<v-divider></v-divider>
			</div>


			<v-container class="mt-3 pa-0">
				<v-row justify="end" class="px-3 pb-3">
					<v-col v-if="loadedVia !== 'companySettings'" cols="8">
						<v-select
							v-model="chosenFormTemplateId"
							:items="loadedTemplates"
							:item-text="(item) => [$autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name)]"
							item-value="id"
							v-on:change="changeFormTemplate()"
							outlined
							label="Rapport Templates"
							dense
							hide-details
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
							:item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
						></v-select>
					</v-col>

					<v-col cols="4" align="right">
						<v-btn
							v-show="chosenFormTemplateId !== 'all'"
							fab 
							x-small 
							depressed
							dark
							:loading="loadingTemplate"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
							@click="openAddForm('new')"
						>
							<v-icon>mdi-plus</v-icon>
					
						</v-btn>
					</v-col>
				</v-row>
				
				<!-- / Via Client \ -->
				<v-col cols="12" v-if="loadedVia !== 'companySettings' && clientForms.length !== 0" class="mx-0 px-0 mb-0 pa-0">
					<v-data-table 
						:headers="clientFormsHeader" 
						:items="clientForms"  
						hide-default-footer 
						:loading="loadingTemplate" dense
						class="dense-table">
						<template v-slot:item="{ item, index }">
							<tr>
								<td>
									{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) }}	
								</td>

								<td>
									{{ item.updated.seconds | moment("D MMMM YYYY - H:mm") }}u
								</td>

								<td>
									{{ item.updatedBy[item.updatedBy.length - 1].name }}
								</td>

								<td>
									{{ $t(`clientForm.${item.typeForm}`, $store.state.locale) }}
								</td>
								<td>
									<v-btn
										small
										icon
										@click="existingDoc = item; deleteForm(item);"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									>
										<v-icon small>mdi-delete</v-icon>
									</v-btn>
								</td>
								<td>
									<v-btn
										small
										icon
										@click="existingDoc = item; openAddForm('existing');"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									>
										<v-icon small>mdi-eye</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-col>
				<!-- \ Via Client / -->

				<!-- / Via CompanySettings or Branch Settings \ -->
				<v-col v-if="loadedVia == 'companySettings' && loadedTemplates.length !== 0" cols="12" class="ma-0 pa-0">
					<v-data-table 
						:headers="templateHeader" 
						:items="loadedTemplates" 
						dense
						class="dense-table"
						hide-default-footer 
						:loading="loadingTemplate">
						<template v-slot:item="{ item, index }">
							<tr>
								<td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name) }}</td>
								<td > <span v-if="item.modified">{{ item.modified.seconds | moment("D MMMM YYYY - H:mm") }}u</span></td>
								<td>{{ item.lastEditBy.username }}</td>
								<td>{{ $t(`clientForm.${item.typeForm}`, $store.state.locale) }}</td>
								<td>{{	getBranchName(item.branches, item.allBranches) }}</td>
								<td>
									<v-btn
										small
										icon
										@click="existingDoc = item; openEditForm('existing', item);"
										:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
									>
										<v-icon small>mdi-pencil</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-col>
				<!-- \ Via CompanySettings or Branch Settings / -->

			</v-container>
		</v-card>

		<!-- ADD and EDIT Clientform -->
		<v-dialog v-if="loadedVia !== 'companySettings'" v-model="addFormDialog" :retain-focus="true" content-class="custom-shape" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
			<add-client-form
				@closeDialog="addFormDialog = $event"
				@reloadForms="getFormTemplateAndClientForms()"
				:typeForm="'clientCardCalendar'"
				:clientInfo="clientInfo"
				:chosenTemplate="chosenTemplate"
				:existingDoc="existingDoc"
			></add-client-form>
		</v-dialog>
    	<v-dialog  v-if="loadedVia == 'companySettings'" v-model="formDialog" content-class="custom-shape" :retain-focus="false" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
			<edit-form
				@closeDialog="formDialog = $event"
				@reloadForms="getFormTemplateAndClientForms()"
				:typeForm="'companySettings'"
				:clientInfo="null"
				:chosenTemplate="chosenTemplate"
				:existingDoc="null"
			></edit-form>
		</v-dialog>


	</div>
</template>
