
<template>
  <div>
    <v-container grid-list-md class="pa-0 pb-4" fluid>
      <v-layout row wrap>
        <companySelector :showBranches="true" @companyChanged="loadData" @branchChanged="loadData"></companySelector>
      </v-layout>
    </v-container>

    <v-tabs class="mb-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" show-arrows v-model="tab" @change="tabChange()" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
      <v-tab>{{ $t('memberships.memberships', $store.state.locale) }}</v-tab>
      <v-tab>{{ $t('memberships.subscriptions', $store.state.locale) }}</v-tab> 
    </v-tabs>
    
    <v-tabs-items v-model="tab" class="transparant-tabs">
      <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
        <memberships></memberships>
      </v-tab-item>
      <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
        <subscriptions></subscriptions>
      </v-tab-item>        
    </v-tabs-items>
  </div>
</template>

<script>
import { bus } from "../main";
import '@firebase/firestore';
import db from "../firebase/init";
import MembershipCategories from "./membershipCategories.vue";
import subscriptions from "./Subscriptions.vue";
import Memberships from "./memberships.vue";
import companySelector from "@/components/elements/companySelector.vue";


export default {
  components: {
    membershipCategories: MembershipCategories,
    subscriptions: subscriptions,
    memberships: Memberships,
    companySelector: companySelector
  },
  name: "SubscriptionsOverview",
  data: () => ({
    tab: 0,
    tabTransition: false,
    searchCompanies: "",
    activeComp: null,
    activeCompName: null,
    activeCompData: {},
    activeCompDataOriginal: null,
    companies: [],
    dataloaded: false,
  }),

  created() {

  },

  methods: {
    loadData(){
      bus.$emit("companyUpdate");
    },
    tabChange(){
      if(this.tab == 0){
        bus.$emit("companyUpdate");
      }
    },
  },
};
</script>

