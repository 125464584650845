<template>
  <v-card>
    <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }" @click:outside="closeAddEmployeeDialog">
      <h2>{{ $t('employees.addEmployee', $store.state.locale) }}</h2>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeAddEmployeeDialog()">
        <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

    <v-tabs :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="tab">
      <v-tab><v-icon left>mdi-account</v-icon> {{ $t('treatments.basic', $store.state.locale)}}</v-tab>
      <v-tab><v-icon left>mdi-note</v-icon>{{ $t('employees.employment', $store.state.locale) }}</v-tab>
      <v-tab><v-icon left>mdi-cellphone</v-icon>{{ $t('employees.userAccountAndRegistration', $store.state.locale) }}</v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-card-text v-show="newEmployee" style="height: 600px; padding: 0; margin: 0;" >
      <v-tabs-items v-model="tab">
        <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
          <v-container grid-list-md class="ma-0 py-0">
              <v-layout row wrap>
                <v-flex xs12>
                  <div class="mt-2">
                    <h5 class="pb-1">{{ $t('employees.employeeImage', $store.state.locale) }}</h5>
                    <v-row align="center" justify="center" class="my-0 pa-0">
                      <v-col xs3 style="max-width: 80px!important; max-height: 80px!important;" :style="{ backgroundColor: newEmployee.employeePhoto ? $themes[$store.state.companyTheme][$store.state.themeModus].background : 'none' }" class=" pa-0 ma-0 ml-3 ">
                        <v-icon v-if="!newEmployee.employeePhoto" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" size="86" class="pa-0 ma-0">mdi-image</v-icon>
                        <img v-else :src="newEmployee.employeePhoto" style="max-width: 100%; padding: 3px; margin: 0" />
                      </v-col>
                      <v-col xs9>
                        <v-btn v-if="!newEmployee.employeePhoto" @click="openMediaLibrary()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark>{{ $t('library.selectFromLibrary', $store.state.locale) }}</v-btn>
                        <div v-else>
                          <a :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}" @click="openMediaLibrary()">{{ $t('library.replace', $store.state.locale) }}</a>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    :label="$t('general.firstName', $store.state.locale)"
                    v-model.trim="newEmployee.firstName"
                    autocomplete
                    outlined
                    required
                    dense
                    class="mt-2"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  ></v-text-field>
                  </v-flex>
                  <v-flex xs6>

                  <v-text-field
                    :label="$t('general.lastName', $store.state.locale)"
                    v-model.trim="newEmployee.lastName"
                    autocomplete
                    outlined
                    required
                    dense
                    hide-details
                    class="mt-2"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                    :label="$t('employees.widgetName', $store.state.locale)"
                    v-model.trim="newEmployee.widgetName"
                    autocomplete
                    outlined
                    required
                    dense
                    hide-details
                    class="mt-2"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  ></v-text-field>
                  </v-flex>

                  <v-flex xs6>
                  <v-select
                    class="mt-3"
                    :label="$t('general.gender', $store.state.locale)"
                    v-model="newEmployee.gender"
                    :items="genders"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details 
                  ></v-select>
                  </v-flex>
                  <v-flex xs6>

                  <v-menu ref="menuBirthDate" v-model="menuBirthDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="mt-3"
                      v-on="on"
                      v-model="employeeBirthDate.readable"
                      :label="$t('general.birthday', $store.state.locale)"
                      readonly
                      prepend-inner-icon="mdi-calendar"
                      hide-details
                      dense
                      outlined
                      clearable
                      @click:clear="clearBirthDate()"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    active-picker="YEAR"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="newEmployee.birthday"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    @input="menuBirthDate = false"
                  ></v-date-picker>
                </v-menu>
                </v-flex>

                <v-flex xs12>
                  <v-switch class="pa-0 ma-0 mt-2" hide-details :label="$t('general.active', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  v-model="newEmployee.active"></v-switch>
                </v-flex>
                
              </v-layout>
              <v-divider class="my-4"></v-divider>

               <p class="ma-0 my-2"><strong>{{ $t('general.address', $store.state.locale) }}</strong></p> 

               <v-layout>
              <v-flex xs12>
                <v-select
                  :label="$t('general.country', $store.state.locale)"
                  v-model="newEmployee.address.country"
                  :items="$countries"
                  :item-text="item => item.countryName[($store.state.locale).toLowerCase()]"
                  item-value="country"
                  required
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-select>
              </v-flex> 
            </v-layout>

            <v-layout  class="mt-3">
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('general.zipcode', $store.state.locale)"
                  v-model="newEmployee.address.postalCode"
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  suffix="1234AA"
                  hide-details               
                ></v-text-field>
              </v-flex>
              <v-flex xs6 md3>
                <v-text-field
                  :label="$t('general.number', $store.state.locale)"
                  number
                  v-model.number="newEmployee.address.number"
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-text-field>
              </v-flex> 
              <v-flex xs6 md3>
                <v-text-field
                  :label="$t('general.numberAdd', $store.state.locale)"
                  v-model="newEmployee.address.numberAddition"
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                  ></v-text-field>
              </v-flex>
            </v-layout>
              
            <v-layout  class="mt-3">
              <v-flex xs6>
                <v-text-field
                  :label="$t('general.street', $store.state.locale)"
                  v-model="newEmployee.address.street"
                  outlined
                  dense
                  :disabled="!addAddressManual"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-text-field>
              </v-flex> 
              <v-flex xs6>
                <v-text-field
                  :label="$t('general.city', $store.state.locale)"
                  v-model="newEmployee.address.city"
                  outlined
                  dense
                  :disabled="!addAddressManual"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout  class="mt-1">
              <v-flex xs12 class="text-center">
                <v-alert v-if="!addAddressManual && addressNotFound && !addressChanged" outlined class="ma-0 pa-2" dense type="warning">{{ $t('clients.addressNotFound', $store.state.locale) }}</v-alert>
                <p :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }" v-if="!newEmployee.address.postalCode || !newEmployee.address.number"> <v-icon size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.getAddressHint', $store.state.locale) }}</i></p>
                <v-btn class="mt-2" v-if="!addAddressManual && ((!addressNotFound && addressChanged)|| (addressNotFound && addressChanged))" :disabled="!newEmployee.address.postalCode || !newEmployee.address.number" outlined block small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="checkAddress">{{ $t('clients.getAddress', $store.state.locale) }}</v-btn>
                <v-btn class="mt-2" outlined small block v-if="!addAddressManual && (newEmployee.address.country != 'netherlands' || !addressChanged && addressNotFound)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="!addAddressManual ? addAddressManual = true : addAddressManual = false">{{ $t('clients.addAddressManual', $store.state.locale) }}</v-btn>
                <p v-if="addAddressManual"><i>{{ $t('clients.addressFoundManual', $store.state.locale) }} </i> <v-btn small icon @click="addAddressManual = false;"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-undo-variant</v-icon></v-btn></p>
                <p v-if="addressChecked && !addAddressManual && !addressNotFound"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.addressFound', $store.state.locale) }}</i></p>
              </v-flex>    
            </v-layout>

            <v-layout>
              <v-flex xs12>
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-4 mb-2">

                  <div class="pa-3 pb-0">
                    <v-row class="pa-0 ma-0">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('general.phones', $store.state.locale) }}</h3>
                      <v-spacer></v-spacer>
                      <v-btn fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addPhonenumber()"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-row>

                    <v-divider></v-divider>
                  </div>

                  <div v-if="!newEmployee.phones || newEmployee.phones.length == 0" class="text-center pa-1 pt-0">
                    <v-avatar 
                      size="46"
                      light
                      class="ma-4 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone-ring</v-icon>
                    </v-avatar>
                    <p>{{ $t('clients.noPhonenumbersAdded', $store.state.locale) }}</p>
                  </div>

                  <div v-if="newEmployee.phones && newEmployee.phones.length > 0">
                    <v-data-table
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :headers="phoneHeaders"
                      :items-per-page="-1"
                      :items="newEmployee.phones"
                      hide-default-footer
                      style="background: none"
                      >
                      <template v-slot:item="{item}">
                        <tr>
                          <td class="text-center">
                            <v-icon :size="18" v-if="item.type == 'mobile'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-cellphone</v-icon>
                            <v-icon :size="18" v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-deskphone</v-icon>
                          </td>
                          <td v-if="item.region == 'NL'">{{ item.phone.national }}</td>
                          <td v-else>{{ item.phone.international }}</td>
                          <td class="text-center">
                            <v-icon v-if="item.primary" :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
                            <v-btn @click="setPrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                          </td>
                          <td>
                            <v-btn icon small @click="removePhone(item)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-divider></v-divider>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs12>
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-4" :class="$store.state.companyTheme" :key="changes">
                  <div class="pa-3 pb-0">
                    <v-row class="pa-0 ma-0">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('general.emailaddress', $store.state.locale) }}</h3>
                      <v-spacer></v-spacer>
                      <v-btn fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addEmailaddress()"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-row>

                    <v-divider></v-divider>
                  </div>

                  <div v-if="!newEmployee.emails || newEmployee.emails.length == 0" class="text-center pa-1 pt-0">
                    <v-avatar 
                      size="46"
                      light
                      class="ma-4 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email-multiple-outline</v-icon>
                    </v-avatar>
                    <p>{{ $t('clients.noEmailsAdded', $store.state.locale) }}</p>
                  </div>

                  <div v-if="newEmployee.emails && newEmployee.emails.length > 0">
                    <v-data-table
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :headers="emailHeaders"
                      :items-per-page="-1"
                      :items="newEmployee.emails"
                      hide-default-footer
                      style="background: none"
                      >
                      <template v-slot:item="{item}">
                        <tr>
                          <td>{{ item.email }}</td>
                          <td :style="'color:'+ emailScoreColor(item.score) + ';'"
                          @click="emailScoreDialog(item.score, item.error)">{{ item.score }}</td>
                          <td class="text-center">
                            <v-icon v-if="item.primary" :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
                            <v-btn @click="setEmailPrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                          </td>
                          <td>
                            <v-btn icon small @click="removeEmail(item)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-divider></v-divider>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>

          </v-container>
        </v-tab-item>
        
  

        <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
          <v-container grid-list-md class="ma-0 py-0">

            <p class="ma-0 mt-3 mb-2 ml-1"><strong>{{ $t('general.branches', $store.state.locale) }}</strong></p>
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12>
                 <v-select
                  :items="branches"
                  item-text="name"
                  item-value="id"
                  v-model="newEmployee.branches"
                  v-on:input="createEmployeeDefaultHours()"
                  multiple
                  outlined
                  chips
                  small-chips
                  dense
                  hide-details
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :label="$t('employees.selectBranches', $store.state.locale)"
                >
                </v-select>
              </v-flex>
            </v-layout>

            <p class="ma-0 mt-3 mb-2 ml-1"><strong>{{ $t('employees.startEndEmployment', $store.state.locale) }}</strong></p>
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs6>
                <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="employeeContractStart.readable"
                      :label="$t('employees.startContract', $store.state.locale)"
                      readonly
                      prepend-inner-icon="mdi-calendar"
                      hide-details
                      dense
                      outlined
                      clearable
                      @click:clear="clearContractStartDate()"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    no-title
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="newEmployee.employeeContractStart"
                    :max="employeeContractStartMax"
                    min="1970-01-01"
                    @input="menuStartDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="employeeContractEnd.readable"
                      :label="$t('employees.endContract', $store.state.locale)"
                      readonly
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      dense
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      @click:clear="clearContractEndDate()"
                      clearable
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    no-title
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    v-model="newEmployee.employeeContractEnd"
                    :max="employeeContractEndMax"
                    min="1970-01-01"
                    @input="menuEndDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>

             <p class="ma-0 mt-3 mb-2 ml-1"><strong>{{ $t('employees.functionsAndDevices', $store.state.locale) }}</strong></p>
            <v-layout row wrap class="ma-0 pa-0">         
              <v-flex xs6>
                <v-select
                  chips
                  deletable-chips
                  small-chips
                  :items="filteredAbilities"
                  item-text="ability"
                  item-value="abilityId"
                  :label="$t('employees.functions', $store.state.locale)"
                  v-model="newEmployee.employeeAbilities"
                  multiple
                  dense
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  hide-details
                  :no-data-text="$t('employees.noCompaniesFound', $store.state.locale)"
                >
                  <template v-slot:prepend-item>
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                      <v-list-item-content class="pt-0">
                        <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  placeholder="Zoeken" prepend-inner-icon="mdi-magnify" clearable v-model="searchAbilities" hide-details dense></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <div
                      v-if="searchAbilities && searchAbilities.length > 0"
                      style="padding: 4px 17px 3px 17px; background-color: rgba(0, 0, 0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px"
                    >
                      {{ filteredAbilities.length }} van {{ abilities.length }} functies
                    </div>
                    <v-divider v-if="searchAbilities && searchAbilities.length > 0"></v-divider>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs6>
                <v-select
                  chips
                  deletable-chips
                  small-chips
                  :items="filteredDeviceTypes"
                  item-text="deviceName"
                  item-value="deviceId"
                  :label="$t('employees.deviceTypes', $store.state.locale)"
                  v-model="newEmployee.deviceTypes"
                  multiple
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  hide-details
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content class="pt-0">
                        <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  placeholder="Zoeken" prepend-inner-icon="mdi-magnify" clearable v-model="searchDeviceTypes" hide-details dense></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <div v-if="searchDeviceTypes && searchDeviceTypes.length > 0" style="padding: 4px 17px 3px 17px; background-color: rgba(0, 0, 0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">
                      {{ filteredDeviceTypes.length }} van {{ deviceTypes.length }} functies
                    </div>
                    <v-divider v-if="searchDeviceTypes && searchDeviceTypes.length > 0"></v-divider>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12>
                <v-switch :label="$t('employees.employeeIsEmployee', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  hide-details class="ma-0 my-4 pa-0" v-model="newEmployee.trainee"></v-switch>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item :value="2" class="pa-4" :transition="tabTransition" :reverse-transition="tabTransition">
          <v-alert type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mb-0" v-html="$t('employees.userAccountAndRegistrationInfo', $store.state.locale)"></v-alert>
          <div v-if="newEmployee.loginAccount && newEmployee.userId" class="pt-4 text-center">
            <v-progress-circular class="ma-4" v-if="userAccountLoading" :size="35" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" indeterminate></v-progress-circular>
            <div v-else>
              <h3><strong>{{ $t('employees.employeeConnectToUseraccount', $store.state.locale) }}</strong></h3>
              <div style="font-size: 18px" class="pa-4 pb-2 ma-0">
                <div class="circle-wrapper pa-0 px-2" style="display: inline-block">
                  <div class="circle" style="position: relative" v-bind:class="{ active: newEmployee.loginAccount, disabled: !newEmployee.loginAccountActive }"></div>
                </div>
                {{ userAccount.email }}
              </div>
              <p v-if="newEmployee.loginAccountActive" class="pb-2 ma-0"><i>{{ $t('employees.useraccountActive', $store.state.locale) }}</i></p>
              <p v-else class="pb-2 ma-0"><i>{{ $t('employees.useraccountNotActive', $store.state.locale) }}</i></p>

              <v-btn v-if="newEmployee.loginAccountActive" class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" block depressed @click="deactivateUserAccount()">{{ $t('employees.deactivateUseraccount', $store.state.locale) }}</v-btn>
              <v-btn v-else class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed @click="activateUserAccount()">{{ $t('employees.activateUseraccount', $store.state.locale) }}</v-btn>
              <v-btn v-if="newEmployee.userId" class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" outlined block depressed @click="disconnectUserAccount()">{{ $t('employees.disconnectUseraccount', $store.state.locale) }}</v-btn>
            </div>
          </div>

          <div v-else>
            <div class="pt-4 text-center">
              <v-btn class="mt-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed dark :outlined="!newUserAccount" @click="setUserAccountType('new')">{{ $t('employees.createNewUseraccount', $store.state.locale) }}</v-btn>
              <v-btn class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed :outlined="!existingUserAccount" @click="setUserAccountType('existing')">{{ $t('employees.connectToExistingUser', $store.state.locale) }}</v-btn>
            </div>
            <div v-if="newUserAccount">
              <v-select
                :items="userRoles"
                v-model="employeeUserRole"
                :label="$t('general.role', $store.state.locale)"
                item-text="name"
                item-value="id"
                prepend-inner-icon="mdi-shield-check"
                required
                outlined
                dense
                class="mt-4"
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              ></v-select>
              <v-select
                :label="$t('general.language', $store.state.locale)"
                outlined
                :hint="$t('user.languageHint', $store.state.locale)"
                persistent-hint
                class="mt-4"
                dense
                content-class="pa-0 ma-0 mr-4"
                :items="availableLanguages"
                v-model="employeeUserLanguage"
                item-text="languageCode"
                >
                <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                  <country-flag :country="data.item.flagCode" size='small'/>
                  <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <country-flag :country="data.item.flagCode" size='small'/>
                  <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                </template>
              </v-select>
              <v-text-field
                :label="$t('general.email', $store.state.locale)"
                v-model="employeeUserEmail"
                :rules="[rules.email]"
                :autocomplete="false"
                required
                prepend-inner-icon="mdi-email"
                outlined
                dense
                class="mt-4"
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              ></v-text-field>
              <v-text-field
                :label="$t('employees.password', $store.state.locale)"
                v-model="employeeUserPassword"
                prepend-inner-icon="mdi-lock"
                hint="Minimaal 6 characters"
                counter
                dense
                :rules="[rules.required, rules.min]"
                required
                outlined
                class="mt-4"
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              ></v-text-field>
              <v-switch class="ma-0 mt-4 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('user.emailNewAccount', $store.state.locale)" v-model="employeeUserSendEmail" hide-details></v-switch>
            </div>
            <div v-if="existingUserAccount">
              <v-text-field
                :label="$t('general.email', $store.state.locale)"
                v-model="searchEmail"
                :autocomplete="false"
                required
                dense
                prepend-inner-icon="mdi-email"
                outlined
                class="mt-4"
                hide-details
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              ></v-text-field>
              <v-btn class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed :loading="findUserLoading" :disabled="searchUserDisabled" @click="findUsers()">{{ $t('general.search', $store.state.locale) }}</v-btn>

              <div class="mt-3" v-if="foundUsers && foundUsers.length > 0 && userSearched">
                <v-list class="py-0" dense>
                  <v-subheader>{{ $t('employees.availableUsers', $store.state.locale) }}</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item-group v-for="(user, i) in foundUsers" :key="i">
                    <v-list-item :disabled="user.employeeId ? true : false" @click="selectUser(user)">
                      <v-list-item-content>
                        <v-list-item-title>{{ user.email }}</v-list-item-title>
                        <v-list-item-subtitle v-if="user.employeeId">{{ $t('employees.userConnectedToEmployees', $store.state.locale) }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon v-if="!user.employeeId">
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-chevron-right</v-icon>
                      </v-list-item-icon>
                      <v-list-item-icon v-if="user.employeeId">
                        <v-icon color="secondary">mdi-account-lock</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider></v-divider>
                  </v-list-item-group>
                </v-list>
              </div>
              <div class="mt-3 text-center" v-if="foundUsers.length == 0 && userSearched">
                <p>{{ $t('employees.noUserFound', $store.state.locale) }}</p>
              </div>
            </div>
          </div>
        </v-tab-item>

      </v-tabs-items>

    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container grid-list-md fluid class="ma-0 pa-0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
            <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeAddEmployeeDialog()"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
          </v-flex>
          <v-flex xs12 md6 class="text-right">
            <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :loading="saveDisabled" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addEmployee()">
              {{ $t('employees.addEmployeeButton', $store.state.locale) }}
              <v-icon right dark>mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>

    <!-- / New Phonenumber Dialog \ -->
    <add-phonenumber-dialog :type="'addEmployeeForm'"  @newPhoneNumber="addNewPhonenumber($event)"></add-phonenumber-dialog>
    <!-- \ New Phonenumber Dialog / -->   

    <!-- / New Email Dialog \ -->
    <add-emailaddress-dialog :type="'addEmployeeForm'" @newEmailaddress="addNewEmailaddress($event)"></add-emailaddress-dialog>
    <!-- \ New Email Dialog / --> 

    
    <!-- / Image Library \ -->
    <v-dialog
      v-model="libraryDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('library.employee', $store.state.locale) }} </h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeLibraryDialog()">
            <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0" style="position: relative">
          <media-library
          @mediaFileSelect="setChosenImage" 
          :assetGroup="'companyAsset'" 
          :assetType="'employee'" 
          :fileRules="{accept: ['image/jpg', 'image/jpeg', 'image/png'], fileSize: 2000000}" ></media-library>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- \ Image Library / -->
             
  </v-card>
</template>

<script>
  import slugify from "slugify";
  import db from "../../firebase/init";
  import "@firebase/firestore";
  import firebase from "firebase/app";
  import "firebase/auth";
  import { bus } from "../../main";
  import axios from "axios";
  import moment from "moment";
  import uniqid from "uniqid";
  import AddPhonenumber from "../modals/AddPhoneNumber.vue";
  import AddEmailaddress from "../modals/AddEmailaddress.vue";
  import MediaLibrary from "../elements/MediaLibrary";

  export default {
    name: "add-employee",
    props: {
      nextResourceWeight: Number,
      branches: Array,
      activeBranches: Object,
    },
    components: {
      "add-phonenumber-dialog": AddPhonenumber,
      "add-emailaddress-dialog": AddEmailaddress,
      "media-library" : MediaLibrary,
    },
    data() {
      return {
        tab: 0,
        changes: 0,
        tabTransition: false,
        employeeContractStartMax: moment().add(1, "year").format("YYYY-MM-DD"),
        employeeContractEndMax: moment().add(5, "year").format("YYYY-MM-DD"),
        feedback: null,
        libraryDialog: false,
        saveDisabled: false,
        menuStartDate: false,
        menuEndDate: false,
        searchAbilities: "",
        searchDeviceTypes: "",
        abilities: [],
        deviceTypes: [],
        imageUrl: null,
        imageProgress: null,
        findUserLoading: false,
        userSearched: false,
        searchEmail: "",
        searchEmailOriginal: "",
        selectedUser: null,
        userAccount: null,
        userAccountLoading: true,
        existingUserAccount: false,
        newUserAccount: false,
        employeeUserRole: "employee",
        employeeUserEmail: "",
        employeeUserLanguage: null,
        employeeUserSendEmail: false,
        employeeUserPassword: "",
        foundUsers: [],
        menuBirthDate: false,
        phoneHeaders: [
          {
            text: this.$t('general.type', this.$store.state.locale),
            align: "center",
            value: "type",
            width: "50px",
            sortable: false,
          },
          {
            text: this.$t('general.phone', this.$store.state.locale),
            align: "left",
            value: "phone",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "50px",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "50px",
            sortable: false,
          }  
        ],
        emailHeaders: [
          {
            text: this.$t('general.email', this.$store.state.locale),
            align: "left",
            value: "email",
            sortable: false,
          },
          {
            text: "Score",
            align: "center",
            value: "primary",
            width: "40px",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "40px",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "40px",
            sortable: false,
          }  
        ],
        addAddressManual: false,
        addressNotFound: false,
        clientAddressChecked: new Object(),
        addressChecked: false,
        newEmployee: {
          loginAccount: false,
          loginAccountActive: false,
          userId: null,
          uid: null,
          gender: null,
          phones: new Array(),
          emails: new Array(),
          birthday: null,
          address: {
            postalCode: null,
            number: null,
            city: null,
            street: "",
            country: "netherlands",
            numberAddition: null
          },
          contractType: null,
          cao: null,
          branches: [],
          basicSchedule: {},
          employeeAbilities: [],
          deviceTypes: [],
          trainee: false,
          active: true,
          defaultHours: {},
          exception: {},
          exceptionAvailable: {},
          contractStart: moment().format("YYYY-MM-DD"),
          contractEnd: "",
        },
        newEmployeeOriginal: new Object(),
        newEmployeeInfo: new Object(),
        genders: [{ name: "Man", id: 1 }, { name: "Vrouw", id: 2 }, { name: "Anders", id: 3 }],
        userRoles: [
          {
            name: this.$t("general.roleAdmin", this.$store.state.locale),
            id: "admin",
          },
          {
            name: this.$t("general.roleManager", this.$store.state.locale),
            id: "manager",
          },
          {
            name: this.$t("general.roleEmployee", this.$store.state.locale),
            id: "employee",
          },
        ],
        recordId: null,
        rules: {
          email: (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          required: (value) => !!value || this.$t("general.required", this.$store.state.locale),
          min: (v) => v.length >= 6 || this.$t("user.sixCharactersPassword", this.$store.state.locale),
        },
      };
    },

    created() {
      this.newEmployeeOriginal = _.cloneDeep(this.newEmployee)
      this.loadDeviceTypes();
      this.loadAbilities();
    },

    methods: {
      loadDeviceTypes() {
        this.deviceTypes = new Array();
        db.collection("deviceTypes")
        .get()
        .then((snap) => {
          this.deviceTypes = [];
          snap.forEach((doc) => {
            let device = doc.data();
            this.deviceTypes.push(device);
          });
        });
      },

      loadAbilities() {
        this.abilities = [];
        db.collection("abilities")
          .get()
          .then((snap) => {
            this.abilities = [];
            snap.forEach((doc) => {
              let ability = doc.data();
              ability.id = doc.id;
              this.abilities.push(ability);
            });
          });
      },

      checkUserAccount(newEmployeeInfo) {
        this.userAccountLoading = true;
        if (newEmployeeInfo.loginAccount && newEmployeeInfo.userId) {
          db.collection("users")
            .doc(newEmployeeInfo.userId)
            .get()
            .then((doc) => {
              this.userAccount = doc.data();
              this.userAccount.id = doc.id;
            })
            .then(() => {
              this.userAccountLoading = false;
            });
        }
      },

      addPhonenumber(){
        bus.$emit('addPhonenumberDialog', this.newEmployee.phones, 'addEmployeeForm');
      },

      addNewPhonenumber(data){
        this.newEmployee.phones.push(data)
      },

      removePhone(item){
        this.newEmployee.phones = this.newEmployee.phones.filter(phone => {return phone.phone.e164 != item.phone.e164})
        this.checkForPrimaryNumber()
      },

      async checkForPrimaryNumber(){
        let primary = this.newEmployee.phones.filter(phone => {return phone.primary})
        if(!primary || primary.length == 0){
          let mobilePhones = this.newEmployee.phones.filter(phone => {return phone.type == 'mobile'})
          if(!mobilePhones || primary.mobilePhones == 0){
            this.setPrimary(this.newEmployee.phones[0])
          }
          else{
            this.setPrimary(mobilePhones[0])
          }
        }
      },
      
      setPrimary(item){
        this.newEmployeeInfo.phones.map(phone => {
          phone.phone.e164 == item.phone.e164 ? phone.primary = true : phone.primary = false })
      },

      addEmailaddress(){
        let emails = this.newEmployee.emails ? this.newEmployee.emails : new Array();
        bus.$emit('addEmailDialog', emails, "addEmployeeForm");
      },

      addNewEmailaddress(data){
        this.newEmployee.emails.push(data);
        this.changes++
      },

      removeEmail(item){
        this.newEmployee.emails = this.newEmployee.emails.filter(email => {return email.email != item.email})
        this.checkForPrimaryEmail()
      },

      async checkForPrimaryEmail(){
        let primary = this.newEmployee.emails.filter(email => {return email.primary})
        if(!primary || primary.length == 0){
          this.setEmailPrimary(this.newEmployee.emails[0])
        }
      },
      
      setEmailPrimary(item){
        this.newEmployee.emails.map(email => {
          email.email == item.email ? email.primary = true : email.primary = false })
      },

      emailScoreColor(score) {
        //Score from 10 to 8 "green"; 8 to 5 "yellow"; 5 to 0 "red"
        if (score >= 8) {
          return "green";
        } else if (score >= 5) {
          return "yellow";
        } else {
          return "red";
        }
      },


      findUsers() {
        this.findUserLoading = true;
        this.searchEmailOriginal = _.cloneDeep(this.searchEmail);

        db.collection("users")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("email", "==", this.searchEmail)
          .get()
          .then((snap) => {
            this.foundUsers = [];
            snap.forEach((doc) => {
              let user = doc.data();
              user.id = doc.id;
              this.foundUsers.push(user);
            });
          })
          .then(() => {
            this.userSearched = true;
            this.findUserLoading = false;
          });
      },

      deactivateUserAccount() {
        this.newEmployee.loginAccountActive = false;
      },

      disconnectUserAccount() {
        this.newEmployee.loginAccount = false;
        this.newEmployee.userId = null;
        this.searchEmailOriginal = "";
        this.employeeUserLanguage = this.availableLanguages[0].languageCode;
        this.searchEmail = "";
        this.foundUsers = [];
        this.userSearched = false;
      },

      setUserAccountType(type) {
        if (type == "existing") {
          this.employeeUserEmail = "";
          this.employeeUserPassword = "";
          (this.employeeUserSendEmail = false), (this.existingUserAccount = true);
          this.newUserAccount = false;
        }
        else {
          this.newUserAccount = true;
          this.existingUserAccount = false;
          this.employeeUserLanguage = this.availableLanguages[0].languageCode;
          this.searchEmailOriginal = "";
          this.searchEmail = "";
          this.foundUsers = [];
          this.userSearched = false;
          this.newEmployee.loginAccount = true;
          this.newEmployee.loginAccountActive = true;
        }
      },

      activateUserAccount() {
        this.newEmployee.loginAccountActive = true;
      },

      selectUser(user) {
        this.selectedUserForAccount = true;
        this.newEmployee.userId = user.id;
        this.newEmployee.uid = user.user_id;
        this.newEmployee.loginAccountActive = false;
        this.newEmployee.loginAccount = true;
        if (user.active) {
          this.newEmployee.loginAccountActive = true;
        }
        this.userAccount = _.cloneDeep(user);
        this.userAccountLoading = false;
        this.searchEmailOriginal = "";
        this.searchEmail = "";
        this.foundUsers = [];
        this.userSearched = false;
      },

      onPickFile() {
        this.$refs.fileInput.click();
      },

      onFilePicked(event) {
        var re = /(?:\.([^.]+))?$/; //Pak laatste extensie
        const files = event.target.files;
        this.selectedFile = event.target.files[0];
        this.selectedFileExt = re.exec(event.target.files[0].name)[0]; // ".jpg"
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          //Luistert of het bestand geupload is.
          this.imageUrl = fileReader.result; // Stuur image naar imageURL
        });
        fileReader.readAsDataURL(files[0]); //Bestand omzetten naar BASE64 en dan de eerste file pakken.
        this.image = files[0];
      },

      closeAddEmployeeDialog() {
        this.$emit("newEmployeeDialogChange", false);
        this.newEmployee = _.cloneDeep(this.newEmployeeOriginal)
        this.tab = 0;
        this.imageUrl = "";
        this.image = null;
        this.feedback = null;
        this.saveDisabled = false;
        this.searchEmail = "";
        this.foundUsers = [];
        this.findUserLoading = false;
      },

      checkAddress(){
        if(this.newEmployee.address.country && this.newEmployee.address.postalCode && this.newEmployee.address.number){
          this.addressChecked = false;
          this.addressNotFound = false;
          this.clientAddressChecked = {
            postalcode: _.cloneDeep(this.newEmployee.address.postalCode),
            number: _.cloneDeep(this.newEmployee.address.number )
          }
          let url = `${this.$store.state.locationsApi.url}/getLocation?country=${this.newEmployee.address.country}&postalcode=${this.newEmployee.address.postalCode}&number=${this.newEmployee.address.number}`;
          axios
          .get(url)
          .then((response) => {
            if(response.status == 200 && response.data){
              this.newEmployee.address.city = response.data.city;
              this.newEmployee.address.street = response.data.street;
              this.newEmployee.address.coordinates = response.data.coordinates;       
            }
            else{
              this.addressNotFound = true;
            }
          })
          .catch(error => {
            console.error("Postcode API error: ", error);
            this.addressNotFound = true;
          })
          this.addressChecked = true;
        }
      },

      createEmployeeDefaultHours() {
        this.newEmployee.defaultHours = {};
        this.newEmployee.exception = {};
        this.newEmployee.exceptionAvailable = {};
        let employeeBranches = this.newEmployee.branches;
        employeeBranches.forEach((branch) => {
          this.newEmployee.defaultHours[branch] = {};

          this.newEmployee.defaultHours[branch].Sun = {};
          this.newEmployee.defaultHours[branch].Sun.start = "00:00";
          this.newEmployee.defaultHours[branch].Sun.end = "00:00";
          this.newEmployee.defaultHours[branch].Sun.active = false;

          this.newEmployee.defaultHours[branch].Mon = {};
          this.newEmployee.defaultHours[branch].Mon.start = "00:00";
          this.newEmployee.defaultHours[branch].Mon.end = "00:00";
          this.newEmployee.defaultHours[branch].Mon.active = false;

          this.newEmployee.defaultHours[branch].Tue = {};
          this.newEmployee.defaultHours[branch].Tue.start = "00:00";
          this.newEmployee.defaultHours[branch].Tue.end = "00:00";
          this.newEmployee.defaultHours[branch].Tue.active = false;

          this.newEmployee.defaultHours[branch].Wed = {};
          this.newEmployee.defaultHours[branch].Wed.start = "00:00";
          this.newEmployee.defaultHours[branch].Wed.end = "00:00";
          this.newEmployee.defaultHours[branch].Wed.active = false;

          this.newEmployee.defaultHours[branch].Thu = {};
          this.newEmployee.defaultHours[branch].Thu.start = "00:00";
          this.newEmployee.defaultHours[branch].Thu.end = "00:00";
          this.newEmployee.defaultHours[branch].Thu.active = false;

          this.newEmployee.defaultHours[branch].Fri = {};
          this.newEmployee.defaultHours[branch].Fri.start = "00:00";
          this.newEmployee.defaultHours[branch].Fri.end = "00:00";
          this.newEmployee.defaultHours[branch].Fri.active = false;

          this.newEmployee.defaultHours[branch].Sat = {};
          this.newEmployee.defaultHours[branch].Sat.start = "00:00";
          this.newEmployee.defaultHours[branch].Sat.end = "00:00";
          this.newEmployee.defaultHours[branch].Sat.active = false;

          this.newEmployee.exception[branch] = [];
          this.newEmployee.exceptionAvailable[branch] = [];
        });
      },

      addEmployee() {
        this.saveDisabled = true;
        if (this.newEmployee.firstName && this.newEmployee.lastName) {
          if (this.newUserAccount) {
            if (this.employeeUserEmail && this.employeeUserPassword) {
              this.recordId = slugify(this.employeeUserEmail, {
                replacement: "",
                remove: /[!@#$%^&*()"';:.,]/g,
                lower: true,
              });
              let ref = db.collection("users").doc(this.recordId);
              ref.get().then((doc) => {
                if (doc.exists) {
                  this.feedback = this.$t("employees.userWithEmailAlreadyExists", this.$store.state.locale);
                }
                else {
                  firebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then((idToken) => {
                      axios // API Call to create user account
                      .post("https://europe-west1-thrive-365.cloudfunctions.net/auth", {
                        token: idToken,
                        type: "createUser",
                        email: this.employeeUserEmail,
                        password: this.employeeUserPassword,
                        sendEmail: this.employeeUserSendEmail,
                        company: this.$store.state.activeCompany.name,
                        language: this.employeeUserLanguage,
                        status: true,
                        name: this.newEmployee.firstName + " " + this.newEmployee.lastName,
                      })
                      .then((response) => {
                        this.newEmployee.uid = response.data;
                        ref.set({
                          firstName: this.newEmployee.firstName,
                          lastName: this.newEmployee.lastName,
                          userRole: this.newEmployee.userRole,
                          user_id: response.data,
                          employeeId: null,
                          lanuguage: this.employeeUserLanguage,
                          accessCompanies: [this.$store.state.activeCompany.id],
                          companyId: this.$store.state.activeCompany.id,
                          email: this.employeeUserEmail,
                          active: true,
                        });
                      })
                    });
                }
              });
            }
            else {
              this.feedback = this.$t("employees.fieldRequiredForUserAccount", this.$store.state.locale);
              this.saveDisabled = false;
            }
          }
          else {
            this.postEmployeeData();
          }
        } else {
          this.feedback = this.$t("employees.fieldRequiredForAdding", this.$store.state.locale);
          this.saveDisabled = false;
        }
      },

      openMediaLibrary(){
        this.libraryDialog = true;
        bus.$emit("openMediaLibrary");
      },

      setChosenImage(data){
        this.newEmployee.employeePhoto = data.url;
        this.closeLibraryDialog();
      },

      closeLibraryDialog(){
        this.libraryDialog = false;
        bus.$emit("clearMediaLibrarySelection");
      },

      async postEmployeeData() {
        this.feedback = null;
        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "employeeAdded",
          actionItem: this.newEmployee.name,
          extra: [
            {
              name: "name",
              value: `${this.newEmployee.name} ${this.newEmployee.employeeSurname}`
            },
            {
              name: "active",
              value: this.newEmployee.active 
            },
            {
              name: "loginAccount",
              value: this.newEmployee.loginAccount
            },
            {
              name: "branches",
              value: this.newEmployee.branches
            },
            {
              name: "contractStart",
              value: this.employeeContractStart
            },
            {
              name: "contractEnd",
              value: this.employeeContractEnd
            },
            {
              name: "defaultHours",
              value: this.newEmployee.defaultHours
            },
          ]
        };

        if (this.recordId) {
          this.newEmployee.userId = this.recordId;
        }
        let basicSchedule = {};
        this.newEmployee.branches.forEach((branch) => {
          basicSchedule[branch] = {};
          basicSchedule[branch].startDate = null;
          basicSchedule[branch].endDate = null;
          basicSchedule[branch].basicScheduleLastChanged = null;
          basicSchedule[branch].Sun = [];
          basicSchedule[branch].Mon = [];
          basicSchedule[branch].Tue = [];
          basicSchedule[branch].Wed = [];
          basicSchedule[branch].Thu = [];
          basicSchedule[branch].Fri = [];
          basicSchedule[branch].Sat = [];
        });

        try {
          let docRef = await db.collection("employees").add({
            firstName: this.newEmployee.firstName,
            lastName: this.newEmployee.lastName,
            name: this.newEmployee.firstName + " " + this.newEmployee.lastName,
            userId: this.newEmployee.userId,
            publicName: this.newEmployee.widgetName,
            uid: this.newEmployee.uid,
            basicSchedule: basicSchedule,
            active: this.newEmployee.active,
            deleted: {
              deleted: false,
            },
            companyId: this.$store.state.activeCompany.id,
            employeeAbilities: this.newEmployee.employeeAbilities,
            deviceTypes: this.newEmployee.deviceTypes,
            trainee: this.newEmployee.trainee,
            branches: this.newEmployee.branches,
            defaultHours: this.newEmployee.defaultHours,
            employeeContractEnd: this.employeeContractEnd.formatted,
            employeeContractStart: this.employeeContractStart.formatted,
            loginAccount: this.newEmployee.loginAccount,
            loginAccountActive: this.newEmployee.loginAccountActive,
            exception: this.newEmployee.exception,
            exceptionAvailable: this.newEmployee.exceptionAvailable,
            employeePhoto: this.newEmployee.employeePhoto ? this.newEmployee.employeePhoto : null,
            employeePhotoThumb: null,
            gender: this.newEmployee.gender,
            birthday: this.newEmployee.birthday,
            address: this.newEmployee.address,
            phones: this.newEmployee.phones,
            weight: this.nextResourceWeight,
            useInWidget: false
          })

          if (this.existingUserAccount && this.newEmployee.userId) {
            db.collection("users").doc(this.newEmployee.userId).set({employeeId: docRef.id }, { merge: true }); // Update User Doc
          }
          if (this.recordId) {
            db.collection("users").doc(this.recordId).set({employeeId: docRef.id }, { merge: true }); // Update User Doc
          }
          this.$emit("updateData");
          this.closeAddEmployeeDialog();
          this.saveDisabled = false;
          console.log("Employee succesfully added");

          this.newEmployee = {
            loginAccount: false,
            loginAccountActive: false,
            deviceTypes: [],
            role: "employee",
            branches: [],
            userId: null,
            uid: null,
            address: {
              postalCode: null,
              number: null,
              city: null,
              street: "",
              country: "netherlands",
              numberAddition: null
            },
            basicSchedule: {},
            employeeAbilities: [],
            active: true,
            defaultHours: {},
            exception: {},
            trainee: false,
            exceptionAvailable: {},
            contractStart: moment().format("YYYY-MM-DD"),
            contractEnd: "",
          };
        }
        catch (error) {
          this.feedback = this.$t('general.addError', this.$store.state.locale);
          console.error("Error adding employee: ", error);
          this.saveDisabled = false;
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      },
    },

    computed: {
      selectedLanguageObject(){
        return this.selectedLanguage ? this.$countries.find(language => { return language.id == this.selectedLanguage }) : {languageName:new Object()};
      },
      
      availableLanguages(){
        let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
          if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
        });
        // Show languages in the same order as company languages
        return availableLanguages.sort((a, b) => this.$store.state.activeCompany.languages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
      },

      employeeContractStart() {
        let date = {
          readable: null,
          formatted: "",
          firebase: null,
        };
        if (this.newEmployee.contractStart) {
          date.readable = moment(this.newEmployee.contractStart).format("D MMMM YYYY");
          date.formatted = moment(this.newEmployee.contractStart).format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },
      employeeContractEnd() {
        let date = {
          readable: null,
          formatted: "noEndDate",
        };
        if (this.newEmployee.contractEnd) {
          date.readable = moment(this.newEmployee.contractEnd).format("D MMMM YYYY");
          date.formatted = moment(this.newEmployee.contractEnd).format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },

      addressChanged(){
        let clientAddress = {postalCode: this.newEmployee.address.postalCode, number: this.newEmployee.address.number}
        let clientAddressChecked = this.clientAddressChecked;

        return _.isEqual(clientAddress, clientAddressChecked) ? false : true;
      },

      employeeBirthDate() {
        let date = {
          readable: null,
          formatted: "",
        };
        if (this.newEmployee.birthday) {
          date.readable = moment(this.newEmployee.birthday, "YYYY-MM-DD").format("D MMMM YYYY");
          date.formatted = moment(this.newEmployee.birthday, "YYYY-MM-DD").format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
      filteredAbilities() {
        if (this.searchAbilities && this.searchAbilities.length > 0) {
          return this.abilities.filter((ability) => {
            return ability.ability.toLowerCase().includes(this.searchAbilities.toLowerCase());
          });
        } else {
          return this.abilities;
        }
      },
      searchUserDisabled() {
        if (this.searchEmail == this.searchEmailOriginal) {
          return true;
        } else {
          return false;
        }
      },
      
      filteredDeviceTypes() {
        if (this.searchDeviceTypes && this.searchDeviceTypes.length > 0) {
          return this.deviceTypes.filter((device) => {
            return device.deviceName.toLowerCase().includes(this.searchDeviceTypes.toLowerCase());
          });
        } else {
          return this.deviceTypes;
        }
      },
    },
  };
</script>
