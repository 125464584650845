// const preferredLanguage = "nl";
// const companyLanguages = ["nl", "en", "sp"];
// const fieldObject = {
// 	nl: "Dit is Nederlands",
// 	sp: "Esto es español",
// 	en: "Dit Engels",
// };

// autoLanguageSelector(preferredLanguage, companyLanguages, fieldObject);

/**
 * It takes a preferred language, a list of company languages and a field object. 
 * It returns the value of the field object in the preferred language, or the first company language,
 * or the first key of the field object, or "No name"
 * @param preferredLanguage - The language that the user has selected in the app.
 * @param companyLanguages - An array of languages that the company supports.
 * @param fieldObject - The object that contains the field you want to get the value of.
 * @returns the value of the key that matches the preferredLanguage or the first key of the object.
 */
export function autoLanguageSelector(preferredLanguage, companyLanguages, fieldObject) {

	// console.log(preferredLanguage, companyLanguages, fieldObject);
	
	if (!preferredLanguage || !fieldObject) return false;
	else if(typeof fieldObject === 'object'){
		if (fieldObject.hasOwnProperty(preferredLanguage.toLowerCase())) {
			return fieldObject[preferredLanguage.toLowerCase()]; //?
		}
		else {
			let orFoundCompanyLanguage = companyLanguages.find((language) => fieldObject.hasOwnProperty(language)); //?
	
			if (orFoundCompanyLanguage) {
				return fieldObject[orFoundCompanyLanguage];
			}
			else { // Try to get  the first key of object
				let firstKeyLanguage = Object.keys(fieldObject)[0];
	
				if (firstKeyLanguage) {
					return fieldObject[firstKeyLanguage];
				}
				else { // Nothing found..
					return "No name";
				}
			}
		}
	}
	else{
		return fieldObject
	}

	
}
