
<template>
  <div>
    <v-dialog
      v-model="editReceiptDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t('payment.editReceiptTemplate', $store.state.locale) }}</h2>
          <v-btn class="ml-3" @click="previewReceiptTemplate()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark ><v-icon left>mdi-eye</v-icon>{{ $t('general.preview', $store.state.locale) }}</v-btn>
          
          <v-spacer></v-spacer>
          
          <div class="text-right px-4 pt-0" v-if="savingItem" :style="{'color' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }">
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>mdi-content-save</v-icon> Saving...
          </div>
          <div class="text-right px-4 pt-0" v-if="unsavedChanges" :style="{'color' : $themes[$store.state.companyTheme][$store.state.themeModus].text }">
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small>mdi-content-save</v-icon> Onopgeslagen wijzigingen
          </div>
          <v-btn small dark depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mx-2" @click="saveReceipt()"><v-icon ledt>mdi-save</v-icon> {{ $t('general.save', $store.state.locale) }}</v-btn>
          <v-btn small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" @click="closeReceiptEditor()">{{ $t('general.close', $store.state.locale) }}</v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-4 ma-0" style="position: relative" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }">
          <div v-if="loading">
            <div style="position: relative; padding: 200px 0;">
              <div class="thrive-loader"></div>
            </div>
          </div>
        
          <div class="pa-0" v-if="!loading">
            <v-container grid-list-md class="pa-0 ma-0" fluid grow>
              <v-layout row wrap>
                <!-- / Receipt Preview \ -->
                <v-flex md6 xs12>
                  <div class="receipt-preview-wrapper">
                    <div class="receipt-preview-content">

                      <!-- / Receipt Table \ -->
                      <table border="0" cellpadding="0" width="99%" style="width:100%">
                        <tr v-if="selectedTemplate.receiptLogo.show">
                          <td>&nbsp;</td>
                        </tr>
                        <tr v-if="selectedTemplate.receiptLogo.show">
                          <td>
                            <p class="ma-0 pa-0"  :style="[{'text-align': selectedTemplate.receiptLogo.align}]">
                              <img :style="[{'width': selectedTemplate.receiptLogo.width + '%'}]" :src="selectedTemplate.receiptLogo.url"/>
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td>&nbsp;</td>
                        </tr>

                        <tr v-if="selectedTemplate.receiptHeader.show">
                          <td v-html="selectedTemplate.receiptHeader.content[selectedLanguage]"></td>
                        </tr>

                        <tr v-if="selectedTemplate.receiptHeader.show">
                          <td>&nbsp;</td>
                        </tr>

                        <tr>
                          <td>
                            <table>
                              <tr>
                                <td style="padding: 4px 0">{{ today }}</td>
                                <td style="padding: 4px 0" align="right">{{ now }}u</td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td>&nbsp;</td>
                        </tr>

                        <tr>
                          <td>
                            <table  border="0" cellpadding="0" width="99%" style="width:100%">
                              <tr style="border-bottom: 1px dashed #5e5669de!important; border-top: 1px dashed #5e5669de!important;">
                                <td style="padding: 4px 0"><strong>Artikelen</strong></td>
                                <td style="padding: 4px 0" align="right"><strong>Prijs</strong></td>
                              </tr>

                              <tr>
                                <td style="padding: 4px 0">Behandeling</td>
                                <td style="padding: 4px 0" align="right">€ 150.00</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 0">Product</td>
                                <td style="padding: 4px 0" align="right">€ 21.95</td>
                              </tr>
                              <tr style="border-top: 1px dashed #5e5669de!important;">
                                <td style="padding: 8px 0"><strong>Totaal</strong></td> 
                                <td style="padding: 8px 0" align="right"> <strong>€ 171,95</strong></td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <tr >
                          <td>
                            <table border="0" cellpadding="0" width="99%" style="width:100%">
                              <tr>
                                <td style="padding: 8px 0 4px 0"><strong>BTW</strong></td>
                                <td style="padding: 8px 0 4px 0" align="right"></td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 0 0 0">BTW 21%: </td>
                                <td style="padding: 4px 0 0 0" align="right">€ 26,03</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 0 0 0">BTW 9%: </td>
                                <td style="padding: 4px 0 0 0" align="right">€ 1,81</td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td>&nbsp;</td>
                        </tr>

                        <tr v-if="selectedTemplate.receiptFooter.show">
                          <td v-html="selectedTemplate.receiptFooter.content[selectedLanguage]"></td>
                        </tr>

                        <tr v-if="selectedTemplate.barcode.show">
                          <td>&nbsp;</td>
                        </tr>

                        <tr v-if="selectedTemplate.barcode.show">
                          <td style="text-align: center">
                            <img style="width:85%" src="../assets/receipt-barcode.jpg"/>
                          </td>
                        </tr>
                      </table>
                      <!-- \ Receipt Table / -->

                    </div>
                  </div>
                </v-flex>
                <!-- \ Receipt Preview / -->

                <!-- / Receipt Template Editor \ -->
                <v-flex md6 xs12 class="fill-height">
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-2 ma-0" :class="$store.state.companyTheme" style="width: 100%">
                    <div class="pa-4"> <!-- Email Subject   -->
                      <div v-if="editorType == 'user'">
                        <h5 class="pb-1">{{ $t('general.name', $store.state.locale) }}</h5>
                        <v-text-field
                          class="mb-4"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                          dense
                          outlined
                          hide-details
                          v-model="selectedTemplate.title"
                        ></v-text-field>
                      </div>

                      <h5 class="pb-1 pt-3">{{ $t('general.language', $store.state.locale) }}</h5>
                      <v-select
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :items="languages"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        hide-details
                        v-model="selectedLanguage"
                      ></v-select>

                      <h5 class="pt-3 pb-1">Variables</h5>
                      <div style="display: inline-block; margin: 2px 2px;" v-for="(variable, index) in receiptVariables" :key="index">
                        <v-chip label small :color="variable.type == 'text' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].tertiary"  :text-color="variable.type == 'text' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mr-1 mt-1 custom-chip-style">{{ variable.name[($store.state.locale).toLowerCase()] }}</v-chip>
                        <div v-if="variable.copy == false" class="copy-tag-url" @click="copyVariableToClipboard(variable.name, index)"><v-icon small>mdi-content-copy</v-icon> {{ $t('emails.copy', $store.state.locale) }}</div>
                        <div v-else class="copy-tag-url copied">{{ $t('emails.copied', $store.state.locale) }}</div>
                      </div>


                      <!-- / Logo \-->
                      <div class="mt-4 pb-2">
                        <h5 class="pb-0">{{ $t('general.logo', $store.state.locale) }}</h5>
                        <v-switch  
                          class="mb-4"  
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          v-model="selectedTemplate.receiptLogo.show" 
                          inset
                          hide-details
                          :label="$t('payment.showLogo', $store.state.locale)">
                        </v-switch>

                        <div v-if="selectedTemplate.receiptLogo.show">
                          <v-row align="center" justify="center" class="ma-0 pa-0">
                            <v-col xs3 style="max-width: 130px!important;" :style="{ backgroundColor: selectedTemplate.receiptLogo.url ? $themes[$store.state.companyTheme][$store.state.themeModus].background : 'none' }" class="ma-0 pa-0">
                              <v-icon v-if="!selectedTemplate.receiptLogo.url" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" size="86" class="pa-0 ma-0">mdi-image</v-icon>
                              <img v-else :src="selectedTemplate.receiptLogo.url" style="max-width: 100%; padding: 5px;"/>
                            </v-col>
                            <v-col xs9>
                              <v-btn v-if="!selectedTemplate.receiptLogo.url" @click="openMediaLibrary()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark>Select from library</v-btn>
                              <div v-else>
                                <a :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}"  @click="openMediaLibrary()">Replace</a>
                              </div>
                            </v-col>
                          </v-row>
                          <div v-if="selectedTemplate.receiptLogo.url">
                            <h5 class="pb-1 mt-1">{{ $t('payment.align', $store.state.locale) }}</h5>
                            <v-select
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              style="max-width: 200px; width: 50%"
                              :items="alignTypes"
                              item-text="name"
                              item-value="id"
                              dense
                              outlined
                              hide-details
                              v-model="selectedTemplate.receiptLogo.align"
                            ></v-select>

                            <h5 class="pb-1 mt-4">{{ $t('payment.width', $store.state.locale) }}</h5>
                            <v-slider
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                              dense
                              class="ma-0 pa-0"
                              style="max-width: 300px"
                              v-model="selectedTemplate.receiptLogo.width"
                              track-color="#cccccc"
                              max="100"
             
                              min="0"
                              hide-details
                            ></v-slider>
                          </div>
                        </div>
                      </div>
                      <!-- \ Logo /-->

                      <!-- / Header Content \-->
                      <div class="mt-4 pb-2">
                        <h5 class="pb-0">{{ $t('payment.header', $store.state.locale) }}</h5>
                        <v-switch  
                          class="mb-4"  
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          v-model="selectedTemplate.receiptHeader.show" 
                          inset
                          hide-details
                          :label="$t('payment.showHeader', $store.state.locale)">
                        </v-switch>
                        <ckeditor v-if="selectedTemplate.receiptHeader.show" :editor="editor" v-model="selectedTemplate.receiptHeader.content[selectedLanguage]" :config="editorConfig"></ckeditor>
                      </div>
                      <!-- \ Header Content /-->

                      <!-- / Footer Content \-->
                      <div class="mt-4 pb-2">
                        <h5 class="pb-0">{{ $t('payment.footer', $store.state.locale) }}</h5>
                        <v-switch  
                          class="mb-4"  
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          v-model="selectedTemplate.receiptFooter.show" 
                          inset
                          hide-details
                          :label="$t('payment.showFooter', $store.state.locale)">
                        </v-switch>
                        <ckeditor v-if="selectedTemplate.receiptFooter.show" :editor="editor" v-model="selectedTemplate.receiptFooter.content[selectedLanguage]" :config="editorConfig"></ckeditor>
                      </div>
                      <!-- \ Footer Content /--> 

                      <!-- / Barcode \-->
                      <div class="mt-4 pb-2">
                        <h5 class="pb-0">{{ $t('payment.barcode', $store.state.locale) }}</h5>
                        <v-switch  
                          class="mb-4"  
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          v-model="selectedTemplate.barcode.show" 
                          inset
                          hide-details
                          :label="$t('payment.showBarcode', $store.state.locale)">
                        </v-switch>
                      </div>
                      <!-- \ Barcode /--> 
                      
                    </div>
                    
                  </v-card>
                </v-flex>
                <!-- \ Receipt Template Editor / -->
              </v-layout>
            </v-container>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- / Image Library \ -->
    <v-dialog
      v-model="libraryDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t('payment.logoUpload', $store.state.locale) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeLibraryDialog()">
            <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0" style="position: relative">
          <media-library
          @mediaFileSelect="setChosenImage" 
          :assetGroup="'companyAsset'" 
          :assetType="'receiptLogo'" 
          :fileRules="{accept: ['image/jpg', 'image/jpeg', 'image/png'], fileSize: 2000000}" ></media-library>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- \ Image Library / -->

    <!-- / Close Without Saving Dialog \ -->
    <v-dialog v-model="closeWithoutSaving.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].alert}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alert</v-icon>
          </v-avatar>
          <h2 class="mb-4">{{ $t('general.dontSaveChanges', $store.state.locale) }}</h2> 
          <p class="ma-0">{{ $t('general.unaveChanges', $store.state.locale) }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">

                <v-btn dark :loading="savingItem" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="closeAndSave()">
                  {{ $t('general.saveAndClose', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn outlined depressed :disabled="savingItem" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeWithoutSave()">
                 {{ $t('general.close', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Close Without Saving Dialog / -->

  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment';
import db from "@/firebase/init";
import { bus } from "@/main";
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Font from '@ckeditor/ckeditor5-font/src/font';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import MediaLibrary from "@/components/elements/MediaLibrary";

export default {
  name:"editReceiptTemplate",
  components:{
    "media-library" : MediaLibrary,
  },

  data(){
    return{
      editReceiptDialog: false,
      selectedLanguage: 'nl',
      libraryDialog: false,
      editorType: 'user',
      loading: true,
      languages: [
        { id: "nl", name: "Nederlands"},
        { id: "en", name: "English"}
      ],
      savingItem: false,     
      selectedTemplate:{
        id: null,
        receiptLogo: {
          show: false,
          content: new Object()
        },
        receiptHeader: {
          show: false,
          content: new Object()
        },
        receiptFooter: {
          show: false,
          content: new Object()
        },
        barcode: {
          show: false,
        }  
      },
      selectedTemplateOriginal: new Object(),
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Essentials,
          UploadAdapter,
          Autoformat,
          Bold,
          Font,
          Italic,
          BlockQuote,
          // EasyImage,
          Heading,
          // Image,
          // ImageCaption,
          // ImageStyle,
          // ImageToolbar,
          // ImageUpload,
          Link,
          List,
          Paragraph,
          Alignment    
        ],
        fontSize: {
            options: [
                8,
                10,
                12,
                'default',
                16,
                18,
                20
            ]
          },
        toolbar: {
          items: [
            'heading',
            'undo',
            'alignment',
            'redo',
            'bold',
            'italic',
            'link',
            'fontSize',
            'fontColor', 
            'fontBackgroundColor' , 
            'fontFamily'
          ],

          alignment: {
            options: ['left', 'center', 'right', 'justify']
          },
          heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
            ]
          },
          fontColor: {
            colors: [
                // 9 colors defined here...
            ],
            columns: 3, // so, you can display them in 3 columns.
          },
          fontBackgroundColor: {
              columns: 6,
          },
        }
      },
      receiptVariables: [
        {
          id: 'branchName',
          name: {
            nl: '[vestigingNaam]',
            en: '[branchName]',
          },
          type: 'text',
          editorType: 'user',
          copy: false
        },
        {
          id: 'branchAddress',
          name: {
            nl: '[vestigingAdres]',
            en: '[branchAddress]',
          },
          type: 'text',
          editorType: 'user',
          copy: false
        },
        {
          id: 'branchUrl',
          name: {
            nl: '[website]',
            en: '[website]',
          },
          type: 'text',
          editorType: 'user',
          copy: false
        },
        {
          id: 'openingHours',
          name: {
            nl: '[openingstijden]',
            en: '[openingHours]',
          },
          type: 'text',
          editorType: 'user',
          copy: false
        },

      ],
      alignTypes: [
        {
          name: 'Left',
          id: 'left'
        },
        {
          name: 'Center',
          id: 'center'
        },
        {
          name: 'Right',
          id: 'right'
        }
      ],
      today: moment().format("DD/MM/YYYY"),
      now: moment().format("H:mm"),
      closeWithoutSaving: {
        dialog: false
      }, 
    }
  },

  created(){
    bus.$on("editReceiptTemplate", (id, editorType) => { 
      this.selectedTemplate.id = id;
      this.editorType = editorType;
      this.loadReceiptTemplate();
    })
  },

  methods:{
    openEditReceiptTemplateDialog(){
      this.editReceiptDialog = true;
    },

    openMediaLibrary(){
      this.libraryDialog = true;
      bus.$emit("openMediaLibrary");
    },

    setChosenImage(data){
      this.selectedTemplate.receiptLogo.url = data.url;
      this.closeLibraryDialog();
    },

    closeLibraryDialog(){
      this.libraryDialog = false;
      bus.$emit("clearMediaLibrarySelection");
    },

    closeEditReceiptDialog(){
      this.closeWithoutSaving.dialog = false;
      let savedChanges = true;
      this.editReceiptDialog = false;
      this.$emit("updated", savedChanges);
    },

    closeReceiptEditor(){
      if(this.unsavedChanges){
        this.closeWithoutSaving.dialog = true;
      }
      else{
        this.closeWithoutSave()
      }
    },

    async closeAndSave(){
      await this.saveReceipt();
      this.closeEditReceiptDialog();
    },

    closeWithoutSave(){
      this.closeEditReceiptDialog();
    },

    previewReceiptTemplate(){ // Open ReceiptPreview.vue in Dialog with bus
			bus.$emit("previewReceiptTemplate", this.selectedTemplate.id); // Send Receipt Template Id to ReceiptPreview.vue
		},

    async saveReceipt(){
      this.savingItem = true;
      await db.collection('receiptTemplates').doc(this.selectedTemplate.id).set({
        title: this.selectedTemplate.title ? this.selectedTemplate.title : null,
        receiptLogo: this.selectedTemplate.receiptLogo,
        receiptHeader: this.selectedTemplate.receiptHeader,
        receiptFooter: this.selectedTemplate.receiptFooter,
        barcode: this.selectedTemplate.barcode,
        lastChanges: new Date()
      }, {merge: true})
      .then(()=>{
        this.selectedTemplateOriginal = _.cloneDeep(this.selectedTemplate);
      })
      this.savingItem = false;
      return
    },

    async loadReceiptTemplate(){
      this.loading = true;
      this.savingItem = false;
      this.openEditReceiptTemplateDialog();
      await db.collection('receiptTemplates').doc(this.selectedTemplate.id).get()
      .then(doc => {
        this.selectedTemplate =  doc.data();
        this.selectedTemplate.id = doc.id;
        this.selectedTemplateOriginal = _.cloneDeep(this.selectedTemplate);
      });
      this.loading = false;
    },

    copyVariableToClipboard(text, index){
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text[(this.$store.state.locale).toLowerCase()]);
        return;
      }
      navigator.clipboard.writeText(text[(this.$store.state.locale).toLowerCase()]).then(()=> {
        this.receiptVariables.forEach(variable =>{
          variable.copy = false
        })
        this.receiptVariables[index].copy = true
        setTimeout(()=>{ 
          this.receiptVariables[index].copy = false
        }, 1000);

      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
  },

  computed: {
    unsavedChanges(){
      if (_.isEqual(this.selectedTemplate, this.selectedTemplateOriginal) == false) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>


<style>
  .receipt-preview-wrapper{
    width: 300px;
    margin: 40px auto;
    height: auto;
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0px rgba(0,0,0,0.1);
  }
  .receipt-preview-wrapper::after {
    content: ""; height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #ffffff; 
    bottom: -2px;
    -webkit-clip-path: polygon(0% 0%, 5%  100%, 10% 0%, 15%  100%, 20% 0%, 25% 100%, 30% 0%, 35%  100%, 40% 0%, 45%  100%, 50% 0%, 55%  100%, 60% 0%, 65%  100%, 70% 0%, 75%  100%, 80% 0%, 85%  100%, 90% 0%, 95%  100%, 100% 0%);
  }

  .receipt-preview-wrapper::before {
    content: ""; 
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    background: var(--background-color);
    top: 0px;
    z-index: 99;
    -webkit-clip-path: polygon(0% 0%, 5%  100%, 10% 0%, 15%  100%, 20% 0%, 25% 100%, 30% 0%, 35%  100%, 40% 0%, 45%  100%, 50% 0%, 55%  100%, 60% 0%, 65%  100%, 70% 0%, 75%  100%, 80% 0%, 85%  100%, 90% 0%, 95%  100%, 100% 0%);
  }


  .receipt-preview-content{
    padding: 15px;
  }

  .receipt-preview-content tr,
  .receipt-preview-content p,
  .receipt-preview-content p span{
    font-size: 10px!important
  }

  .receipt-preview-content p{
    margin: 0!important;
    padding: 0!important;
  }

  .receipt-preview-content tr {
    font-family: Menlo, Consolas, "Lucida Console", monospace;
    font-size: 10pt;
  }

  .receipt-preview-content table {
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: monospace, cursive;
  }

  .receipt-preview-content table p {
    width: 100%;
    display: block
  }
</style>