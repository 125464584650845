<template>
  <v-dialog v-model="customerDialog" scrollable persistent max-width="1200px" style="height:600px;" eager :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card v-show="customerDialog" style="height:600px;">
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <div class="d-flex align-center">
          <div  v-if="selectedClientName" class="avatarGenHeader mr-1" style="height: 55px;" v-html="genAvatar(selectedClientName)"></div>
          <h2 class="ml-1">{{loading ? "Gegevens ophalen..." : selectedClientName }}</h2>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closedialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text class="ma-0 pa-3" :style="{'backgroundColor': $themes[$store.state.companyTheme][$store.state.themeModus].background}">
        <client-information type="modal" :activeBranch="activeBranch" :componentType="'CustomerInformationDialog'"></client-information>
      </v-card-text>
      
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import lodash from "lodash";
import db from "../../firebase/init";
import { bus } from "../../main";
import clientInformation from "../../views/ClientInformation.vue";
import { generateMeshAvatar } from "../../modules/avatarGen";

export default {
  components: {
    "client-information" : clientInformation,
  },
    props: {
      selectedClientName: String,
      customerDialog: Boolean,
      activeBranch: String,
    },

  data() {
    return {
        feedback: null,
    }
  },

  methods: {

    genAvatar(name){
      console.log("genAvatar", name)
      return generateMeshAvatar(name);
    },

    closedialog(){
      bus.$emit("resetClientInformationView")
      this.$emit("customerDialogChange", false);
      
      console.log("closedialog - customerDialogChange")
    },
  },
  computed: {
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    loading(){
        return this.selectedClient === null ? true : false;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  }
};
</script>
<style >

.avatarGenHeader svg {
  
  width: 52px;
  height: 52px;
}
</style>