
<template>
  <div>
    <section id="content">

      <div v-if="dataloaded && companies.length > 1" class="pt-2 ma-0 pa-0">
        <div class="clear">
          <v-container grid-list-md class="pa-0" fluid>
            <v-layout row wrap>
              <v-flex md3 xs12>
                <v-select
                  v-if="dataloaded && companies.length > 1"
                  :items="filteredCompanies"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  background-color="menu"       
                  item-text="name"
                  item-value="id"
                  hide-details
                  v-on:input="loadActiveCompData()"
                  v-model="activeComp"
                  no-data-text="Geen bedrijven gevonden."
                  label="Selecteer bedrijf"
                  outlined
                  dense>
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content  class="pt-0" >
                        <v-text-field
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          placeholder="Zoeken"
                          prepend-inner-icon="mdi-magnify"
                          clearable
                          v-model="searchCompanies"
                          hide-details
                          dense
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <div v-if="searchCompanies && searchCompanies.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredCompanies.length }} van {{ companies.length }} bedrijven</div>
                    <v-divider v-if="searchCompanies && searchCompanies.length > 0"></v-divider>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </div>

      <div v-if="dataloaded == false" class="thrive-loader"></div>

      <div v-if="activeComp && dataloaded == true" class="special-content-box">
        <v-tabs background-color="background" show-arrows v-model="tab">
          <v-tab>{{ $t('digitalAwareness.awarenessSettings', $store.state.locale)}}</v-tab>
          <v-tab>{{ $t('digitalAwareness.timeline', $store.state.locale)}}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
            <v-card outlined>
              <digitalAwareness-Jobs :activeCompData="activeCompData" :activeCompDataOriginal="activeCompDataOriginal"></digitalAwareness-Jobs>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
            <v-card outlined>
              <digitalAwareness-Timeline :activeCompData="activeCompData" :activeCompDataOriginal="activeCompDataOriginal"></digitalAwareness-Timeline>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>

    </section>
  </div>
</template>

<script>
import { bus } from "../main";
import '@firebase/firestore';
import db from "../firebase/init";

import digitalAwarenessJobs from "./DigitalAwarenessJobs.vue";
import digitalAwarenessTimeline from "./DigitalAwarenessTimeline.vue";


export default {
  components: {
    digitalAwarenessJobs: digitalAwarenessJobs,
    digitalAwarenessTimeline: digitalAwarenessTimeline,
  },
  name: "digitalAwareness",
  data: () => ({
    tab: 0,
    tabTransition: false,
    searchCompanies: "",
    activeComp: null,
    activeCompName: null,
    activeCompData: {},
    activeCompDataOriginal: null,
    companies: [],
    dataloaded: false,
  }),

  created() {
    this.loadUserComp();
  },

  methods: {
    loadUserComp() {
      let userCompanies = this.userCompanies;
      this.companies = [];
      let vm = this;
      let getUserCompData = getUserComp();

      async function getUserComp() {
        for (const company of userCompanies) {
          await db
            .collection("companies")
            .doc(company)
            .get()
            .then(doc => {
              let company = {};
              company.name = doc.data().name;
              company.id = doc.id;
              vm.companies.push(company);
            });
        }

        if(window.localStorage.getItem('selectedThriveCompany')){
          vm.activeComp = window.localStorage.getItem('selectedThriveCompany');
        }
        else{
          vm.activeComp = vm.companies[0].id;
        }
        vm.loadActiveCompData();
      }
    },

    loadActiveCompData() {
      window.localStorage.setItem('selectedThriveCompany', this.activeComp);
      db.collection("companies")
        .doc(this.activeComp)
        .get()
        .then(doc => {
          this.activeCompData = doc.data();
          this.activeCompData.id = doc.id;
          this.activeCompDataOriginal = _.cloneDeep(this.activeCompData);
        })
        .then(() => {
          this.dataloaded = true;
        })
        .then(() => {
          bus.$emit("conversionUpdate");
        });
    }
  },

  computed: {
    version() {
      return this.$store.state.version;
    },
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    filteredCompanies(){
      if (this.searchCompanies && this.searchCompanies.length > 0){
        return this.companies.filter(company => {
          return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
        });
      } else {
        return this.companies;
      }
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  }
};
</script>

