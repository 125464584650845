<template>
  <div>

    <v-container grid-list-md class=" ma-0 pa-0" fluid>
      <v-layout row wrap class="py-0">
        <v-flex xs12 class="text-right py-0">
          <v-btn small :color="$themes[companyTheme][theme].primary" :dark="changed" :loading="updateUserRulesSettingsBusy" :disabled="!changed" :depressed="!changed" @click="updateSettings()">
            <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale)}} <v-icon righy>mdi-chevron-right</v-icon></div>
            <div  v-else>{{ $t('general.saved', $store.state.locale)}}<v-icon small right>mdi-check</v-icon></div>  
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>

     <client-forms
              :clientInfo="null"
              :activeCompData="activeCompData"
             
              :loadedVia="'companySettings'"
            ></client-forms>

    <v-container grid-list-md class="pa-0 pt-4 ma-0">
      <v-layout row wrap>
        <v-flex md6 xs12 class="pr-2">
          <v-card flat :color="$themes[companyTheme][theme].card" class="theme-card theme-shadow pa-0" :class="companyTheme">
            <div class="pa-3">
			
            </div>
          </v-card>

        </v-flex>
        <v-flex md6 xs12 class="pl-2">
        </v-flex>
      </v-layout>
    </v-container>
  </div>
  
</template>


<script>
import '@firebase/firestore';
import db from "../firebase/init";
import { bus } from "../main";
import clientForms from "@/views/clientForms.vue";



export default {
  components: {
    "client-forms": clientForms
  },
  props: {
    activeCompData: Object,
    activeCompDataOriginal: Object,
    typeCompanyOrBranch: String
  },
 

  name: "form-overview-company",
  data(){
    return{

      valid: true,
      ledgerRules: [v => !!v || 'Voer een grootboeknummer in'],

      
      accountancyObjOriginal: new Object(),
      
      useBranch: false, //For Branch Settings
  
      saveChanges: true,
      updateUserRulesSettingsBusy: false,
    
    }
  },

  mounted() {
    console.log("typeCompanyOrBranch", this.typeCompanyOrBranch);
    //TODO BRANCH APPARTE SETUP

  },

  methods: {
  },



  computed: {

    

  
    changed() {
			// if (this.branchInfoChanged || this.branchTimesChanged) {
			// return true;
			// } else {
			// return false;
			// }
			return false;
		},
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
    
  }
};
</script>

